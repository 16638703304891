<div class="min-vh-100 flex-column flex-md-row" [ngClass]="safari ? 'safari-tbl':''">
    <div class="main-content">
        <header class="">
            <a class="logo" href="https://flippos.com" target="_blank">
                <img src="assets/components/images/flip.png" alt="Logo" class="img-fluid">
            </a>
            <div class="toolbar"></div>
            <div class="hierachy_wrap" *ngIf="dealerHeaderName" [style.display]="dealerHeaderName ? 'block' : 'none'">
                <div class="nav nav-tabs breadcrumb" role="tablist">
                    <li class="nav-item breadcrumb-item" *ngIf="dealerHeaderName" [style.display]="dealerHeaderName ? 'flex' : 'none'">
                        <a class="nav-link black-text" [class.disabled]="dealerHierarchyHide">
                            <img src="assets/components/images/dealer.png" alt="Dealer" class="img-fluid topHieImage">
                            <p>{{dealerHeaderName}}</p>
                        </a>
                        <i class="fa fa-angle-right  icons" *ngIf="companyHeaderName" [style.display]="dealerHeaderName ? 'block' : 'none'" aria-hidden="true"></i>

                    </li>
                    <li class="nav-item breadcrumb-item" *ngIf="companyHeaderName" [style.display]="companyHeaderName ? 'flex' : 'none'">
                        <a class="nav-link black-text" [class.disabled]="companyHierarchyHide">
                            <img src="assets/components/images/site.png" alt="Company" class="img-fluid topHieImage">
                            <p>{{companyHeaderName}}</p>
                        </a>
                        <i class="fa fa-angle-right icons" aria-hidden="true" *ngIf="storeHeaderName" [style.display]="storeHeaderName ? 'block' : 'none'"></i>
                    </li>
                    <li class="nav-item breadcrumb-item" *ngIf="storeHeaderName" [style.display]="storeHeaderName ? 'flex' : 'none'">
                        <a class="nav-link black-text" [class.disabled]="storeHierarchy">
                            <img src="assets/components/images/home.png" alt="Store" class="img-fluid topHieImage">
                            <p>{{storeHeaderName}}</p>
                        </a>
                    </li>
                    <div *ngIf="layoutPage" class="item_wrap">
                        <li class="nav-item breadcrumb-item">
                            <i class="fa fa-angle-right icons" aria-hidden="true"></i>
                            <a class="nav-link black-text" [class.disabled]="dealerHierarchyHide">
                                <img src="assets/components/images/icons-wrench.png" alt="Dealer" class="img-fluid topHieImage">
                                <p>{{Area}}</p>
                            </a>
                            <i class="fa fa-angle-right  icons" aria-hidden="true"></i>

                        </li>
                        <li class="nav-item breadcrumb-item">
                            <a class="nav-link black-text" [class.disabled]="companyHierarchyHide">
                                <img src="assets/components/images/areas-off-thanks@3x.png" alt="Company" class="img-fluid topHieImage">
                                <p>{{group}}</p>
                            </a>
                            <i class="fa fa-angle-right icons" aria-hidden="true"></i>
                        </li>
                        <li class="nav-item breadcrumb-item">
                            <a class="nav-link black-text" [class.disabled]="storeHierarchy">
                                <img src="assets/components/images/icons-receipt@3x.png" alt="Store" class="img-fluid topHieImage">
                                <p>{{page}}</p>
                            </a>
                        </li>
                    </div>
                    <div *ngIf="emvPage" class="item_wrap">
                        <li class="nav-item breadcrumb-item">
                            <i class="fa fa-angle-right icons" aria-hidden="true"></i>
                            <a class="nav-link black-text" [class.disabled]="dealerHierarchyHide">
                                <img src="assets/components/images/mobile.png" alt="Dealer" class="img-fluid topHieImage">
                                <p>Devices</p>
                            </a>
                            <i class="fa fa-angle-right  icons" aria-hidden="true" *ngIf="!receipt"></i>
                        </li>
                        <li class="nav-item breadcrumb-item">
                            <a class="nav-link black-text" [class.disabled]="companyHierarchyHide">
                                <img src="assets/components/images/areas-off-thanks@3x.png" alt="Company" class="img-fluid topHieImage">
                                <p>{{emvName}}</p>
                            </a>

                        </li>
                    </div>
                    <div *ngIf="tablet" class="item_wrap">
                        <li class="nav-item breadcrumb-item">
                            <i class="fa fa-angle-right icons" aria-hidden="true"></i>
                            <a class="nav-link black-text" [class.disabled]="dealerHierarchyHide">
                                <img src="assets/components/images/mobile.png" alt="Dealer" class="img-fluid topHieImage">
                                <p>Devices</p>
                            </a>
                            <i class="fa fa-angle-right  icons" aria-hidden="true" *ngIf="!receipt"></i>
                        </li>
                        <li class="nav-item breadcrumb-item">
                            <a class="nav-link black-text" [class.disabled]="companyHierarchyHide">
                                <img src="assets/components/images/shape.png" alt="Company" class="img-fluid topHieImage">
                                <p>{{tabletName}}</p>
                            </a>

                        </li>
                    </div>
                    <div *ngIf="printer" class="item_wrap">
                        <li class="nav-item breadcrumb-item">
                            <i class="fa fa-angle-right icons" aria-hidden="true"></i>
                            <a class="nav-link black-text" [class.disabled]="dealerHierarchyHide">
                                <img src="assets/components/images/mobile.png" alt="Dealer" class="img-fluid topHieImage">
                                <p>Devices</p>
                            </a>
                            <i class="fa fa-angle-right  icons" aria-hidden="true" *ngIf="!receipt"></i>
                        </li>
                        <li class="nav-item breadcrumb-item">
                            <a class="nav-link black-text" [class.disabled]="companyHierarchyHide">
                                <img src="assets/components/images/printer-card@3x.png" alt="Company" class="img-fluid topHieImage">
                                <p>{{printerName}}</p>
                            </a>
                        </li>
                    </div>
                    <div *ngIf="reasons" class="item_wrap">
                        <li class="nav-item breadcrumb-item">
                            <i class="fa fa-angle-right  icons" aria-hidden="true"></i>
                            <a class="nav-link black-text" [class.disabled]="companyHierarchyHide">
                                <img src="assets/components/images/reasons-bc@2x.png" alt="Company" class="img-fluid topHieImage">
                                <p>Reasons</p>
                            </a>
                        </li>
                    </div>
                </div>
            </div>
        </header>
        <div class="theme_titlecard flex-grow-1">
            <app-title-card [Dataname]="employeeName" [DataCom]="employee" [DataIcon]="employeeIcon" [iconClick]="iconClick" [titleClick]="titleClick"></app-title-card>
        </div>
        <div class="person-wrap ">
            <div class="toolbar">
                <mat-toolbar class="mattoolbar">
                    <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()" class="btn  btn-sm float-right submit_button theme_menu">
                      <i class="fa fa-bars" aria-hidden="true"></i>
                  </button>
                </mat-toolbar>
            </div>
            <div class="user-detail-wrap mb-1">
                <i class="fa fa-lg fa-user" id="user-icon-wrap" aria-hidden="true"></i> {{UserFname}} {{UserLname}}
            </div>
        </div>
    </div>
    <div class="layout-content">

        <mat-sidenav #sidenav="matSidenav" mode="side" [(opened)]="opened" (opened)="events.push('open!')" (closed)="events.push('close!')" class="">
            <aside class="sidebar flex-shrink-1">
                <nav class="navbar navbar-expand navbar-light flex-md-column  align-items-start">
                    <div class="collapse navbar-collapse ">
                        <ul class="flex-md-column  navbar-nav w-100 justify-content-between">
                            <li class="nav-item dropright" [ngClass]="{'activemenu' : activeItem == true}" [style.display]="dealerHierarchyHide ? 'none' : 'block'">
                                <mat-nav-list>
                                    <a class="nav-link side_img" mat-list-item [ngClass]="{'img_purple' : dealers_img == true, 'disabled': (dealers_img == true  && dealerPage == true)}" title="Dealer" (click)="toggle(sidenav)" (click)="onImgChange('dealers',1,0,'Dealer');">
                                        <img src="assets/components/images/dealer-1.png" alt="Dealer" class="img-fluid img_gray">
                                        <img src="assets/components/images/dealer-1-active.png" alt="Dealer" class="img-fluid img_purple dealer-img">
                                    </a>
                                </mat-nav-list>
                                <div class="dropdown-menu drop-item">
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('dealers',2,0,'Dealer Employee'); toggle(sidenav)" [style.display]="dealerShow == 2 ? 'block' : 'none'" *ngIf="companyHierarchyHide ? 'none' : 'block'" [ngClass]="{'disabled': (dealers_img == true  && dealerEmp == true)}">Dealer
                                          Employee</a>
                                    </mat-nav-list>
                                </div>
                            </li>
                            <li class="nav-item dropright" [ngClass]="{'activemenu' : activeItem == true}" [style.display]="companyHierarchyHide ? 'none' : 'block'" [style.display]="dealerShow == 2 ? 'block' : 'none'">
                                <mat-nav-list>
                                    <a class="nav-link side_img" mat-list-item (click)="onImgChange('company',1,0,'Company'); toggle(sidenav)" [ngClass]="{'img_purple' : site_img == true, 'disabled': (site_img == true && companyPage == true)}" title="Company">
                                        <img src="assets/components/images/company-1.png" alt="Company" class="img-fluid img_gray">
                                        <img src="assets/components/images/company-1-active.png" alt="Company" class="img-fluid img_purple">
                                    </a>
                                </mat-nav-list>
                                <div class="dropdown-menu drop-item" [ngClass]="{'dealerShow' : dealerShow == 2}">
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('company',2,0,'Company Employee'); toggle(sidenav)" [style.display]="compShow == 3 ? 'block' : 'none'" [ngClass]="{'disabled': (site_img == true && compEmp == true)}" *ngIf="storeHierarchyHide ? 'none' : 'block'">Company Employee</a>
                                    </mat-nav-list>
                                </div>
                            </li>
                            <li class="nav-item dropright" [ngClass]="{'activemenu' : activeItem == true}" [style.display]="storeHierarchyHide ? 'none' : 'block'" [style.display]="compShow == 3 ? 'block' : 'none'">
                                <mat-nav-list>
                                    <a class="nav-link side_img dropdown-toggle" mat-list-item (click)="onImgChange('store',1,0,'Store'); toggle(sidenav)" [ngClass]="{'img_purple' : menu_img == true, 'disabled': (menu_img == true && storePage == true)}" data-toggle="dropdown" title="Store">
                                        <img src="assets/components/images/store-1.png" alt="store" class="img-fluid img_gray">
                                        <img src="assets/components/images/store-1-active.png" alt="Store" class="img-fluid img_purple">
                                    </a>
                                </mat-nav-list>
                                <ul class="dropdown-menu drop-item dropright">
                                    <li class="dropdown-submenu">
                                        <!-- (click)="onImgChange('store',2,0,'Payment')" -->
                                        <mat-nav-list>
                                            <a class="dropdown-item  dropdown-toggle" data-toggle="dropdown" id="dropdownMenuButton" [style.display]="storeShow == 4 ? 'block' : 'none'">Payment </a>
                                        </mat-nav-list>
                                        <ul class="dropdown-menu drop-item dropright">
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('store',2,1,'Custom Payments'); toggle(sidenav)" [ngClass]="{'disabled': (menu_img == true && CustomPayments == true)}">Custom
                                                  Payments</a>
                                            </mat-nav-list>
                                            <!-- <a class="dropdown-item" (click)="onImgChange('store',2,2,'House Accounts')"
                                              [ngClass]="{'disabled': (menu_img == true && HouseAccounts == true)}">House
                                              Accounts</a> -->
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('store',2,3,'Gifts Cards'); toggle(sidenav)" [ngClass]="{'disabled': (menu_img == true && GiftCard == true)}">Gift
                                                  Cards</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('store',2,4,'Credit Card Gateway'); toggle(sidenav)" [ngClass]="{'disabled': (menu_img == true && CreditCard == true)}">Credit
                                                  Card
                                                  Gateway</a>
                                            </mat-nav-list>
                                        </ul>
                                    </li>
                                    <li class="dropdown-submenu">
                                        <!-- (click)="onImgChange('store',3,0,'Devices')" -->
                                        <mat-nav-list>
                                            <a class="dropdown-item  dropdown-toggle" data-toggle="dropdown" id="dropdownMenuButton" [style.display]="storeShow == 4 ? 'block' : 'none'" mat-list-item (click)="toggle(sidenav)">Devices </a>
                                        </mat-nav-list>
                                        <ul class="dropdown-menu drop-item dropright">
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('store',3,1,'EMV'); toggle(sidenav)" [ngClass]="{'disabled': (menu_img == true && emvPage == true)}">EMV</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('store',3,2,'Tablets'); toggle(sidenav)" [ngClass]="{'disabled': (menu_img == true && tablet == true)}">Tablets</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('store',3,3,'Printer'); toggle(sidenav)" [ngClass]="{'disabled': (menu_img == true && printer == true)}">Printer</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('store',3,4,'Receipt Config'); toggle(sidenav)" [ngClass]="{'disabled': (menu_img == true && receipt == true)}">Receipt
                                                  Config</a>
                                            </mat-nav-list>
                                        </ul>
                                    </li>
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('store',4,0,'Tags'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" [ngClass]="{'disabled': (menu_img == true && tagsPage == true)}">Tags</a>
                                    </mat-nav-list>
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('store',5,0,'Reasons'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" [ngClass]="{'disabled': (menu_img == true && reasons == true)}">Reasons</a>
                                    </mat-nav-list>
                                </ul>
                            </li>
                            <li class="nav-item dropright" [ngClass]="{'activemenu' : activeItem == true}" [style.display]="storeShow == 4 ? 'block' : 'none'">
                                <!-- (click)="onImgChange('emps',0,0,'Store Employee')" -->
                                <a class="nav-link side_img dropdown-toggle" title="Employee" [ngClass]="{'img_purple' : selected_img == true, 'disabled': selected_img == true}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="assets/components/images/employee-1.png" alt="Employee" class="img-fluid img_gray">
                                    <img src="assets/components/images/employee-1-active.png" alt="Employee" class="img-fluid img_purple">
                                </a>
                                <div class="dropdown-menu drop-item">
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('emps',8,0,'Job Position'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" [ngClass]="{'disabled': (selected_img == true && jobPosition == true)}">Job
                                          Position</a>
                                    </mat-nav-list>
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('emps',1,0,'Store Employee'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" [ngClass]="{'disabled': (selected_img == true && GeneralPage == true)}">Store
                                          Employee</a>
                                    </mat-nav-list>
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('emps',2,0,'Time'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" [ngClass]="{'disabled': (selected_img == true && TimePage == true)}">Time</a>
                                    </mat-nav-list>
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('emps',3,0,'Break Type'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" [ngClass]="{'disabled': (selected_img == true && BreakPage == true)}">Break
                                          Type</a>
                                    </mat-nav-list>
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('emps',4,0,'Payroll'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" [ngClass]="{'disabled': (selected_img == true && payroll == true)}">Payroll</a>
                                    </mat-nav-list>
                                </div>
                            </li>
                            <li class="nav-item dropright" [ngClass]="{'activemenu' : activeItem == true}" [style.display]="storeShow == 4 ? 'block' : 'none'">
                                <!-- (click)="onImgChange('menu',0,0,'Menu Item')" -->
                                <a class="nav-link side_img dropdown-toggle" title="Menu" [ngClass]="{'img_purple' : home_img == true, 'disabled': home_img == true}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="assets/components/images/menu-1.png" alt="mobile" class="img-fluid img_gray">
                                    <img src="assets/components/images/menu-1-active.png" alt="Menu" class="img-fluid img_purple ">
                                </a>
                                <div class="dropdown-menu drop-item">
                                     <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('menu',2,0,'Menu Item'); toggle(sidenav)" [style.display]="storeShow == 4 && (!isDisplaymnu) ? 'none' : 'block'" [ngClass]="{'disabled': (selected_img == true && MenuItemPage == true)}">Menu
                                          Item</a>
                                    </mat-nav-list>
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('menu',1,0,'Menu Size'); toggle(sidenav)" [style.display]="storeShow == 4 && (!isDisplaymnu) ? 'none' : 'block'" [ngClass]="{'disabled': (selected_img == true && MenuSizePage == true)}">Menu
                                          Size</a>
                                    </mat-nav-list>
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('menu',3,0,'Menu Modifier'); toggle(sidenav)" [ngClass]="{'disabled': (selected_img == true && MenuModifierPage == true)}" [style.display]="storeShow == 4 && (!isDisplaymnu) ? 'none' : 'block'">Menu
                                          Modifier</a>
                                    </mat-nav-list>
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('menu',4,0,'Area Builder'); toggle(sidenav)" [style.display]="storeShow == 4 && (!isDisplaymnu) ? 'none' : 'block'" [ngClass]="{'disabled': (selected_img == true && SalesAreaPage == true)}">Area
                                          Builder</a>
                                    </mat-nav-list>
                                    <ng-container *ngIf="tenanttype!=4;else StoreCheck">
                                        <mat-nav-list>
                                            <a class="dropdown-item" mat-list-item (click)="onImgChange('menu',5,0,'Layout'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" [ngClass]="{'disabled': (selected_img == true && layoutPage == true)}">Layout</a>
                                        </mat-nav-list>
                                    </ng-container>
                                    <ng-template #StoreCheck>
                                        <ng-container *ngIf="SalesLayoutPermissionVal==12">
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('menu',5,0,'Layout'); toggle(sidenav)" [style.display]="storeShow == 4 && (!isDisplaymnu) ? 'none' : 'block'" [ngClass]="{'disabled': (selected_img == true && layoutPage == true)}">Layout</a>
                                            </mat-nav-list>
                                        </ng-container>
                                    </ng-template>
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('menu',6,0,'Adjustment'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" [ngClass]="{'disabled': (selected_img == true && adjustmentPage == true)}">Adjustment</a>
                                    </mat-nav-list>
                                    <mat-nav-list>
                                        <a class="dropdown-item" mat-list-item (click)="onImgChange('menu',7,0,'Event Pricing'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" [ngClass]="{'disabled': (selected_img == true && eventPage == true)}">Event
                                          Pricing</a>
                                    </mat-nav-list>
                                </div>
                            </li>
                            <li class="nav-item dropright" [ngClass]="{'activemenu' : activeItem == true}" [style.display]="storeShow == 4 ? 'block' : 'none'">
                                <!-- (click)="onImgChange('report',0,0,'Sales Report')" -->
                                <a class="nav-link side_img dropdown-toggle" title="Report" [ngClass]="{'img_purple' : report_img == true, 'disabled': report_img == true}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="assets/components/images/report-1.png" alt="mobile" class="img-fluid img_gray">
                                    <img src="assets/components/images/report-1-active.png" alt="mobile" class="img-fluid img_purple ">
                                </a>
                                <div class="dropdown-menu drop-item">
                                    <li class="dropdown-submenu">
                                        <!-- (click)="onImgChange('report',1,0,'Sales')" -->
                                        <mat-nav-list>
                                            <a class="dropdown-item  dropdown-toggle" mat-list-item (click)="toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" data-toggle="dropdown" id="dropdownMenuButton">Sales </a>
                                        </mat-nav-list>
                                        <ul class="dropdown-menu drop-item dropright">
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',1,2,'Sales Reconciliation'); toggle(sidenav)">Sales
                                          Reconciliation</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',1,1,'Summary'); toggle(sidenav)">
                                          Summary</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" (click)="onImgChange('report',1,3,'Sales by Daypart'); toggle(sidenav)">Sales
                                          by Daypart</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',1,4,'Sales by Hour'); toggle(sidenav)">Sales
                                          by Hour</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',1,5,'Tax Report'); toggle(sidenav)">Tax
                                          Report</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',1,6,'Discounts'); toggle(sidenav)">
                                          Discounts</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',1,7,'Gift Card Liability'); toggle(sidenav)">
                                                    Gift Card Liability</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',1,8,'Gift Card Liability'); toggle(sidenav)">
                                                    Refund Report</a>
                                            </mat-nav-list>
                                        </ul>
                                    </li>
                                    <li class="dropdown-submenu">
                                        <!-- (click)="onImgChange('report',2,0,'Accounting')" -->
                                        <mat-nav-list>
                                            <a class="dropdown-item  dropdown-toggle" mat-list-item (click)="toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" data-toggle="dropdown" id="dropdownMenuButton">Accounting </a>
                                        </mat-nav-list>
                                        <ul class="dropdown-menu drop-item dropright">
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',2,1,'Credit/Batch report'); toggle(sidenav)">Credit/Batch
                                          report</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',2,2,'Tax history'); toggle(sidenav)">Tax
                                          History Report</a>
                                            </mat-nav-list>
                                        </ul>
                                    </li>
                                    <li class="dropdown-submenu">
                                        <!-- (click)="onImgChange('report',3,0,'Audit')" -->
                                        <mat-nav-list>
                                            <a class="dropdown-item  dropdown-toggle" mat-list-item (click)="toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" data-toggle="dropdown" id="dropdownMenuButton">Audit </a>
                                        </mat-nav-list>
                                        <ul class="dropdown-menu drop-item dropright">
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',3,1,'No Sales Report'); toggle(sidenav)">No
                                          Sales Report</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',3,2,'Voids Report'); toggle(sidenav)">Voids Report</a>
                                            </mat-nav-list>
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',3,3,'Pre-Voids Report'); toggle(sidenav)">Pre-Voids Report</a>
                                            </mat-nav-list>
                                        </ul>
                                    </li>
                                    <li class="dropdown-submenu">
                                        <!-- (click)="onImgChange('report',4,0,'Audit')" -->
                                        <mat-nav-list>
                                            <a class="dropdown-item  dropdown-toggle" mat-list-item (click)="toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" data-toggle="dropdown" id="dropdownMenuButton">Labor </a>
                                        </mat-nav-list>
                                        <ul class="dropdown-menu drop-item dropright">
                                            <mat-nav-list>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('report',4,1,'Payroll'); toggle(sidenav)">Payroll</a>
                                            </mat-nav-list>
                                             <mat-nav-list>
                                      <a class="dropdown-item" mat-list-item
                                          (click)="onImgChange('report',4,2,'Labor Summary'); toggle(sidenav)">Labor Summary</a>
                                  </mat-nav-list> 
                                        </ul>
                                    </li>
                                </div>
                            </li>
                            <!-- <li class="nav-item dropright" [ngClass]="{'activemenu' : activeItem == true}">

                                      <a class="nav-link side_img dropdown-toggle" (click)="onImgChange('master')" title="Master"
                                          [ngClass]="{'img_purple' : master_img == true, 'disabled': master_img == true}" data-toggle="dropdown" aria-haspopup="true"
                                          aria-expanded="false">
                                          <img src="assets/components/images/master-1.png" alt="employee" class="img-fluid img_gray">
                                          <img src="assets/components/images/master-1-active.png" alt="Employee"
                                              class="img-fluid img_purple">
                                      </a>
                                      <div class="dropdown-menu drop-item devSec">
                                          <a class="dropdown-item" (click)="onImgChange('master',8,0,'ISV Employee')"
                                              [style.display]="dealerHierarchyHide ? 'none' : 'block'">ISV Employee</a>
                                          <a class="dropdown-item" (click)="onImgChange('master',2,0,'Type of Business')"
                                              [style.display]="compShow == 3 ? 'block' : 'none'">Type
                                              of Business</a>
                                          <a class="dropdown-item" (click)="onImgChange('master',9,0,'Labor Category')"
                                              [style.display]="storeShow == 4 ? 'block' : 'none'">Labor Category</a>

                                          <a class="dropdown-item" (click)="onImgChange('master',10,0,'taxMaster')"
                                              [style.display]="storeShow == 4 ? 'block' : 'none'">Tax Master</a>
                                          <a class="dropdown-item" (click)="onImgChange('master',3,0,'State Fields')"
                                              [style.display]="storeShow == 4 ? 'block' : 'none'">State
                                              Fields</a>
                                      </div>
                                  </li> -->

                            <!-- (click)="onImgChange('sync', 0,0, 'Sync')" -->
                            <li class="nav-item dropright" [ngClass]="{'activemenu' : activeItem == true}">
                                <a class="nav-link side_img dropdown-toggle" [ngClass]="{'img_purple' : syno_img == true, 'disabled': syno_img == true}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="assets/components/images/new-seta.png" alt="Sync Config" class="img-fluid img_gray" />
                                    <img src="assets/components/images/new-seta-active.png" alt="Sync Config" class="img-fluid img_purple" />
                                </a>

                                <div class="dropdown-menu drop-item">
                                    <li class="dropdown-submenu">
                                        <!-- (click)="onImgChange('sync',1,0,'master')" -->
                                        <mat-list-item>
                                            <a class="dropdown-item  dropdown-toggle" mat-list-item (click)="toggle(sidenav)" data-toggle="dropdown" id="dropdownMenuButton">
                              Masters</a>
                                        </mat-list-item>
                                        <div class="dropdown-menu drop-item devSec">
                                            <mat-list-item>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('sync',1,1,'ISV Employee'); toggle(sidenav)" [style.display]="dealerHierarchyHide ? 'none' : 'block'">ISV Employee</a>
                                            </mat-list-item>
                                            <mat-list-item>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('sync',1,2,'Type of Business'); toggle(sidenav)" [style.display]="compShow == 3 ? 'block' : 'none'">Type
                                  of Business</a>
                                            </mat-list-item>
                                            <mat-list-item>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('sync',1,3,'Labor Category'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'">Labor Category</a>
                                            </mat-list-item>
                                            <mat-list-item>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('sync',1,4,'taxMaster'); toggle(sidenav)" [style.display]="storeShow == 4 && isCanRemoveTax ? 'block' : 'none'">Tax Master</a>
                                            </mat-list-item>
                                            <mat-list-item>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('sync',1,5,'State Fields'); toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'">State
                                  Fields</a>
                                            </mat-list-item>
                                        </div>
                                    </li>
                                    <li class="dropdown-submenu">
                                        <!-- (click)="onImgChange('sync',2,0,'Sync Config')" -->
                                        <mat-list-item>
                                            <a class="dropdown-item  dropdown-toggle" mat-list-item (click)="toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" data-toggle="dropdown" id="dropdownMenuButton">Sync Config </a>
                                        </mat-list-item>
                                        <ul class="dropdown-menu drop-item dropright">
                                            <mat-list-item>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('sync',2,1,'Currency Config'); toggle(sidenav)">Currency
                                  Config</a>
                                            </mat-list-item>
                                        </ul>
                                    </li>
                                    <li class="dropdown-submenu">
                                        <!-- (click)="onImgChange('sync',3,0,'Clock Config')" -->
                                        <mat-list-item>
                                            <a class="dropdown-item  dropdown-toggle" mat-list-item (click)="toggle(sidenav)" [style.display]="storeShow == 4 ? 'block' : 'none'" data-toggle="dropdown" id="dropdownMenuButton">Clock Config </a>
                                        </mat-list-item>
                                        <ul class="dropdown-menu drop-item dropright">
                                            <mat-list-item>
                                                <a class="dropdown-item" mat-list-item (click)="onImgChange('sync',3,1,'Orchestration Sync'); toggle(sidenav)">Orchestration
                                  Sync</a>
                                            </mat-list-item>
                                            <mat-list-item>
                                                <a class="dropdown-item" (click)="onImgChange('sync',3,2,'Error Message Frequency Config'); toggle(sidenav)">Error
                                  Message
                                  Frequency Config</a>
                                            </mat-list-item>
                                        </ul>
                                    </li>
                                </div>
                            </li>
                            <!-- <li class="nav-item publish-disp-none" [ngClass]="{'activemenu' : activeItem == true}">
                                      <a class="nav-link side_img" (click)="onImgChange('push')"
                                          [ngClass]="{'img_purple' : push_img == true, 'disabled': push_img == true}">
                                          <img src="assets/components/images/publish-1.png" alt="Publish"
                                              class="img-fluid img_gray" />
                                          <img src="assets/components/images/publish-1-active.png" class="img-fluid img_purple" />
                                      </a>
                                  </li> -->
                            <li class="nav-item nav-logout">
                                <a class="nav-link" (click)="onImgChange('exit')">
                                    <img src="assets/components/images/logout.png" alt="logout" class="img-fluid">
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </aside>
        </mat-sidenav>
        <div class="sidenav_div" *ngIf="sidenav.opened" (click)="sidenav.toggle()"></div>
        <main class="nav content">
            <div class="container-fluid">
                <div class="topbar">
                    <div *ngIf="dealerPage">
                        <app-dealer (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" (dealerData_)="getdealerData($event)" (DealerSessData)="getSessionVal($event)"></app-dealer>
                    </div>
                    <div *ngIf="companyPage">
                        <app-company (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" (compData)="getdealerData($event)" [buttonDisable]="buttonDisable" (CompanySessData)="getSessionVal($event)"></app-company>
                    </div>
                    <div *ngIf="storePage">
                        <app-store (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" (storeData)="getdealerData($event)" [buttonDisable]="buttonDisable" (StoreSessData)="getSessionVal($event)"></app-store>
                    </div>
                    <div *ngIf="GeneralPage">
                        <app-employee-user (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" [companyHierarchyHide]="companyHierarchyHide" [dealerHierarchyHide]="dealerHierarchyHide" (storeempsessData)="getSessionVal($event)">
                        </app-employee-user>
                    </div>
                    <div *ngIf="TimePage">
                        <app-time-edit (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (timeeditsessData)="getSessionVal($event)">
                        </app-time-edit>
                    </div>
                    <div *ngIf="BreakPage">
                        <app-break-type (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (brksessData)="getSessionVal($event)"></app-break-type>
                    </div>
                    <div *ngIf="MenuSizePage">
                        <app-menu-size (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (mnusizesessData)="getSessionVal($event)"></app-menu-size>
                    </div>
                    <div *ngIf="MenuItemPage">
                        <app-menu-item [MenuEmit]="MenuModEmit" (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (mnuitmsessData)="getSessionVal($event)"></app-menu-item>
                    </div>
                    <div *ngIf="MenuModifierPage">
                        <app-menu-modifier [itemEmit]="ItemAssignedEmit" (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (mnumodsessData)="getSessionVal($event)">
                        </app-menu-modifier>
                    </div>
                    <div *ngIf="SalesAreaPage">
                        <app-sales-area-builder (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (areabuildsessData)="getSessionVal($event)">
                        </app-sales-area-builder>
                    </div>
                    <div *ngIf="layoutPage">
                        <app-layout-builder (areaIdEmit)="getAreaBuilder($event)" (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (areaIdempty)="getEmptyid($event)" (pageIdempty)="getEmptyid($event)"
                            (groupIdempty)="getEmptyid($event)" [saveLayoutBtn]="layoutPage" (layoutsessData)="getSessionVal($event)"></app-layout-builder>
                    </div>
                    <div *ngIf="CustomPayments">
                        <app-custom-payment (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (CustomPaymentsessData)="getSessionVal($event)">
                        </app-custom-payment>
                    </div>
                    <div *ngIf="HouseAccounts">
                        <app-house-accounts (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (houseaccsessData)="getSessionVal($event)">
                        </app-house-accounts>
                    </div>
                    <div *ngIf="GiftCard">
                        <app-gift-card (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (giftcardsessData)="getSessionVal($event)">
                        </app-gift-card>
                    </div>
                    <div *ngIf="CreditCard">
                        <app-credit-card (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (ccsessData)="getSessionVal($event)"></app-credit-card>
                    </div>
                    <div *ngIf="RolepermissionPage">
                        <app-role-and-permission (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" (rolepermissionsessData)="getSessionVal($event)">
                        </app-role-and-permission>
                    </div>
                    <div *ngIf="typeofbusinessPage">
                        <app-type-of-business (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" (typeofbussessData)="getSessionVal($event)">
                        </app-type-of-business>
                    </div>
                    <div *ngIf="statefield">
                        <app-state-fields (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" (statesessData)="getSessionVal($event)"></app-state-fields>
                    </div>
                    <div *ngIf="adjustmentPage">
                        <app-adjustment (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (adjsessData)="getSessionVal($event)"></app-adjustment>
                    </div>
                    <div *ngIf="signal">
                        <app-receiver></app-receiver>
                    </div>
                    <div *ngIf="chatPage">
                        <app-chat></app-chat>
                    </div>
                    <div *ngIf="emvPage">
                        <app-emv (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (emvData)="getemvName($event)" (evmsessData)="getSessionVal($event)"></app-emv>
                    </div>
                    <div *ngIf="contractPage">
                        <app-contract></app-contract>
                    </div>
                    <div *ngIf="tablet">
                        <app-tablets (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (tabletdata)="gettabletName($event)" (tabletsessData)="getSessionVal($event)"></app-tablets>
                    </div>
                    <div *ngIf="printer">
                        <app-printer (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (printerName)="getprinterName($event)" (printerDataEmit)="getprinterData($event)" (pritergensessData)="getSessionVal($event)">
                        </app-printer>
                    </div>
                    <div *ngIf="receipt">
                        <app-receipt-config (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (recconfsessData)="getSessionVal($event)">
                        </app-receipt-config>
                    </div>
                    <div *ngIf="socket">
                        <app-time-clock-websocket></app-time-clock-websocket>
                    </div>
                    <div *ngIf="IsvPage">
                        <app-isv (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" [isvEmp]="IsvPage" (isvsessData)="getSessionVal($event)">
                        </app-isv>
                    </div>
                    <div *ngIf="dealerEmp">
                        <app-hierachy-employee (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" [dealerEmp]="dealerEmp" (hieemp_sessData)="getSessionVal($event)" [dealerHierarchyHide]="dealerHierarchyHide"
                            [companyHierarchyHide]="companyHierarchyHide">
                        </app-hierachy-employee>
                    </div>
                    <div *ngIf="compEmp">
                        <app-hierachy-employee (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" [compEmp]="compEmp" (hieemp_sessData)="getSessionVal($event)" [dealerHierarchyHide]="dealerHierarchyHide"
                            [companyHierarchyHide]="companyHierarchyHide">
                        </app-hierachy-employee>
                    </div>
                    <div *ngIf="report">
                        <app-report (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)"></app-report>
                    </div>
                    <div *ngIf="tagsPage">
                        <app-tags-main (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (tagmainsessData)="getSessionVal($event)">
                        </app-tags-main>
                    </div>
                    <div *ngIf="payroll">
                        <app-payroll (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (payrollsessData)="getSessionVal($event)"></app-payroll>
                    </div>
                    <div *ngIf="reasons">
                        <app-reasons (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (ReasonsessData)="getSessionVal($event)"></app-reasons>
                    </div>
                    <div *ngIf="salesReconcial">
                        <app-sales-reconciliation (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (salrecsessData)="getSessionVal($event)"></app-sales-reconciliation>
                    </div>
                    <div *ngIf="jobPosition">
                        <app-role-and-permission (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (rolepermissionsessData)="getSessionVal($event)">
                        </app-role-and-permission>
                    </div>
                    <div *ngIf="eventPage">
                        <app-event-pricing (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (eventprisessData)="getSessionVal($event)">
                        </app-event-pricing>
                    </div>
                    <div *ngIf="labor">
                        <app-labor-category (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (labcatsessData)="getSessionVal($event)">
                        </app-labor-category>
                    </div>
                    <div *ngIf="taxMaster">
                        <app-tax-master (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [buttonDisable]="buttonDisable" (taxName)="getemvName($event)" (taxmasterData)="getSessionVal($event)"></app-tax-master>
                    </div>
                    <div *ngIf="creditBatch">
                        <app-credit-batch (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)"></app-credit-batch>
                    </div>
                    <div *ngIf="taxhistory">
                        <app-tax-history-report (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)"></app-tax-history-report>
                    </div>
                    <div *ngIf="noSales">
                        <app-no-sales (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [noSales]="noSales"></app-no-sales>
                    </div>
                    <div *ngIf="voids">
                        <app-voids-report (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [voids]="voids"></app-voids-report>
                    </div>
                    <div *ngIf="prevoids">
                        <app-pre-voids-report (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [voids]="prevoids"></app-pre-voids-report>
                    </div>
                    <div *ngIf="payrollReport">
                        <app-payroll-report (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)"></app-payroll-report>
                    </div>
                    <div *ngIf="salesByDaypart">
                        <app-sales-daypart (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)"></app-sales-daypart>
                    </div>
                    <div *ngIf="salesByHour">
                        <app-sales-by-hour (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)"></app-sales-by-hour>
                    </div>
                    <div *ngIf="taxReport">
                        <app-tax-report (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)"></app-tax-report>
                    </div>
                    <div *ngIf="discountsReport">
                        <app-discounts (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [discountsReport]="discountsReport"></app-discounts>
                    </div>
                    <div *ngIf="giftCardReport">
                        <app-giftcard-liability-report (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)"></app-giftcard-liability-report>
                    </div>
                    <div *ngIf="refundReport">
                        <app-refund-report (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)" [voids]="refundReport"></app-refund-report>
                    </div>
                    <div *ngIf="currencyConfig">
                        <app-currency-config (titleName)="getTitleName($event)" (title)="getTitle($event)" (currconfigsessData)="getSessionVal($event)" (titleIcon)="getTitleIcon($event)">
                        </app-currency-config>
                    </div>
                    <div *ngIf="errorMessageFrequency">
                        <app-error-message-frequency-config (titleName)="getTitleName($event)" (title)="getTitle($event)" (errmsgsessData)="getSessionVal($event)" (titleIcon)="getTitleIcon($event)">
                        </app-error-message-frequency-config>
                    </div>
                    <div *ngIf="OrchestrationSync">
                        <app-orchestration-sync (titleName)="getTitleName($event)" (title)="getTitle($event)" (orchsyncsessData)="getSessionVal($event)" (titleIcon)="getTitleIcon($event)">
                        </app-orchestration-sync>
                    </div>
                    <div *ngIf="laborReport">
                        <app-labor-summary (titleName)="getTitleName($event)" (title)="getTitle($event)" (titleIcon)="getTitleIcon($event)"></app-labor-summary>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
