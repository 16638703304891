<div class="loginForm">
  <div class="form-group mt-2 text-right">
    <button class="btn btn-sm submit_button button-none" type="submit" (click)="OnSave(saveId)"
      [disabled]="buttonDisable ? true : null">
      <img src="assets/components/images/save.png" alt="employee" class="">
      <label class="label_config">Save</label>
    </button>
  </div>
  <div class="dynamic_body">
    <table mat-table [dataSource]="data" class="mat-elevation-z8 dynamic_table">
        <ng-container *ngFor="let columnName of columnNames;let i = index" [matColumnDef]="columnName">
          <th mat-header-cell *matHeaderCellDef class="dynamic_head">{{ capitalize(columnName) }}</th>
          <td mat-cell *matCellDef="let row"  class="dynamic_Item">
            <mat-form-field floatLabel="never">
              <lable class="priceLabel" *ngIf="row[columnName] != 'NA'">$</lable>
               <input matInput [value]="row[columnName]" [(ngModel)]="row[columnName]" (change)="onChange()"
               appDecimalLimit [readonly]="row[columnName] == 'NA' ? 'true' : 'false'" #priceInput>
            </mat-form-field>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
      <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
    </table>
  </div>
</div>