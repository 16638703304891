<div class="time-body">
    <div class="loginForm form-switch table_forms">
        <app-report-filter (ReceivedData)="GetVoidDetails($event)" [voidsReport]="voids"></app-report-filter>
        <div class="footer-table Communication_tab mb-2">
            <section class="d-flex justify-content-between outshadow p-1">
                <div class="back-img" *ngIf="firstTableShow == true">
                    <!-- <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(1)"
                        class="img-fluid align-self-center"> -->
                    <!-- <span>{{firstTableName}}</span> >> <span>{{auditName}}</span> -->
                </div>
                <div class="back-img align-self-center" *ngIf="secondTableShow == true">
                    <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(2)"
                        class="img-fluid align-self-center">
                    <p><span class="txt-transform">Back to pre-voids summary</span></p>
                </div>
                <h6 class="mb-0 align-self-center">
                    <span *ngIf="firstTableShow == true">SUMMARY</span>
                </h6>

                <div class="d-flex no-gutter mb-0">
                    <div class="salesreconciliation"><img src="assets/components/images/gen-icon-export.png" alt="sales"
                            class="img-fluid cash_icon"></div>
                    <div class="salesreconciliation"><img src="assets/components/images/gen-icon-dashboard.png"
                            alt="sales" class="img-fluid cash_icon_w90"></div>
                </div>
            </section>
            <div *ngIf="firstTableShow == true ">
                <app-audit-void-collapse [dataSource]="dataSource1" [displayedColumns]="displayedColumns1"
                    [columns]="columns1" [groupByColumns]="groupByColumns1" [_alldata]="alldata1"
                    (cardnumber)="getAuditTable($event)"></app-audit-void-collapse>
            </div>
            <div *ngIf="secondTableShow == true ">
                <app-void-audit-report [transactions]="transactions" [displayedColumns]="displayedColumns"
                    [columns3]="auditcolumns" [dataSource3]="auditdataSource" [displayedColumns3]="auditTable"
                    [auditName]="auditName" [Filtertransactions]="Filtertransactions" [netTotal]="netTotal"
                    [subTotal]="subTotal" [TipsAmount]="TipsAmount" [discountTotal]="discountTotal"
                    [thirdTableShow]="thirdTableShow" [CustomerDetails]="CustomerDetails"
                    [auditEmployee]="auditEmployee" [auditDate]="auditDate" [auditTime]="auditTime"
                    [auditMove]="auditMove"></app-void-audit-report>
            </div>

        </div>
    </div>
</div>