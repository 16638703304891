<div class="adjustment_detail">
  <form
    class="SaleArea_general loginForm"
    [formGroup]="adjustmentDetailForm"
    (change)="onchange(adjustmentDetailForm.value)"
  >
    <div class="row">
      <div class="col-sm-6">
        <div class="row w-100 custom_Uber_eats">
          <div class="col-sm-8">
            <div class="form-group">
              <label class="customlable m-0">After Tax</label>
              <p class="uber_para">Will include Tax in the discount</p>
              <div class="checkbox-table accordion-checkbox">
                <div class="custom-control custom-checkbox custom-control item">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="isAfterTax"
                    formControlName="isAfterTax"
                    id="Allowsome"
                  />
                  <label class="custom-control-label mb-0"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row w-100 custom_Uber_eats">
          <div class="col-sm-8">
            <div class="form-group">
              <label class="customlable m-0"
                >include Side Dishes/Modifier?</label
              >
              <p class="uber_para">
                Applies to all items part of the main item.
              </p>
              <div class="checkbox-table accordion-checkbox">
                <div class="custom-control custom-checkbox custom-control item">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="isSideDish_Modifier"
                    formControlName="isSideDish_Modifier"
                    id="Allowsome"
                  />
                  <label class="custom-control-label mb-0"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row w-100 custom_Uber_eats">
          <div class="col-sm-8">
            <div class="form-group">
              <label class="customlable m-0">Allow Tips</label>
              <p class="uber_para">
                Applies to all items part of the main item.
              </p>
              <div class="checkbox-table accordion-checkbox">
                <div class="custom-control custom-checkbox custom-control item">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="isAllowtips"
                    formControlName="isAllowtips"
                    id="Allowsome"
                  />
                  <label class="custom-control-label mb-0"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row w-100 custom_Uber_eats">
          <div class="col-sm-6 col-6">
            <div class="form-group">
              <label class="col-form-label">Min.</label>
              <input
                type="text"
                class="form-control modify_gen"
                placeholder="$0.00"
                formControlName="minAmount"
                (keypress)="numberOnly($event)"
                appInputRestriction
                (keydown)="checkInput($event, inputText)"
                #inputText
                (input)="onChangeMinEvent($event)"
              />
              <div class="error">{{ errorMessage }}</div>
            </div>
          </div>
          <div class="col-sm-6 col-6">
            <div class="form-group">
              <label class="col-form-label">Max.</label>
              <input
                type="text"
                class="form-control modify_gen"
                placeholder="$0.00"
                formControlName="maxAmount"
                (keypress)="numberOnly($event)"
                appInputRestriction
                (keydown)="checkInput($event, inputText1)"
                #inputText1
                (input)="onChangeMaxEvent($event)"
              />
              <div class="error">{{ errorMessage1 }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="row w-100 custom_Uber_eats">
          <div class="col-sm-8">
            <div class="form-group">
              <label class="customlable m-0">Required # of Guests</label>
              <p class="uber_para">
                Requires a specific number of guests to be available
              </p>
              <div class="checkbox-table accordion-checkbox">
                <div class="custom-control custom-checkbox custom-control item">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="isGuestRequired"
                    formControlName="isGuestRequired"
                    id="Allowsome"
                  />
                  <label class="custom-control-label mb-0"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row w-100 custom_Uber_eats">
          <div class="col-sm-8">
            <div class="form-group">
              <label>Limit to Sales Area(s)?</label>
              <ng-multiselect-dropdown
                [settings]="SalesSettings"
                [data]="SalesAreaFilters"
                (onSelect)="onchange(adjustmentDetailForm.value)"
                [(ngModel)]="salesselectedItems"
                [formControl]="salesAreaLimit"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        <div class="row w-100 custom_Uber_eats">
          <div class="col-sm-8">
            <div class="form-group">
              <label>Limit to Menu Group(s)?</label>
              <ng-multiselect-dropdown
                [settings]="SalesSettings"
                [data]="menuGroupFilters"
                (onSelect)="onchange(adjustmentDetailForm.value)"
                [(ngModel)]="groupselectedItems"
                [formControl]="menuGroupLimit"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        <div class="row w-100 custom_Uber_eats">
          <div class="col-sm-8">
            <div class="form-group">
              <label>Limit to Menu page(s)?</label>
              <div class="dropdown-up">
              <ng-multiselect-dropdown
                [settings]="SalesSettings"
                [data]="menuPageFilters"
                (onSelect)="onchange(adjustmentDetailForm.value)"
                [(ngModel)]="pageselectedItems"
                [formControl]="menuPageLimit"
              >
              </ng-multiselect-dropdown>
            </div>
            </div>
          </div>
        </div>
        <div class="row w-100 custom_Uber_eats">
          <div class="col-sm-8">
            <div class="form-group">
              <label>Limit to Specific Item(s) Tags?</label>
              <div class="dropdown-up">
              <ng-multiselect-dropdown
                [settings]="SalesSettings"
                [data]="specificItemsFilters"
                (onSelect)="onchange(adjustmentDetailForm.value)"
                [(ngModel)]="specificselectedItems"
                [formControl]="specificItems"
              >
              </ng-multiselect-dropdown>
            </div>
            </div>
          </div>
        </div>
        <div class="row w-100">
          <div class="col-sm-6">
            <div class="form-group">
              <label>Minimum # of Items</label>
              <input
                type="text"
                class="form-control input-lg inputbox_login"
                formControlName="itemCount"
                placeholder="#"
                appInputRestriction
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <button
          class="btn btn-sm float-right submit_button button-none"
          type="submit"
          (click)="onSaveDetails()"
          [disabled]="buttonDisable ? true : null"
        >
          <!-- [disabled]="buttonState ? true : null" -->
          <img
            src="assets/components/images/save.png"
            alt="employee"
            class=""
          />
          <label class="label_config">Save</label>
        </button>
      </div>
    </div>
  </form>
</div>
