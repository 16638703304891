import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  TemplateRef,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { HelperService } from 'src/app/helper/helper.service';
import { EmployeeService } from 'src/app/services/employee.service';
import {
  moveItemInArray,
  CdkDragEnd,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { IrowUpdate } from 'src/app/interfaces/iadmin';
import { FilterTablePopupComponent } from '../filter-table-popup/filter-table-popup.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { GetCodeMaster } from 'src/app/interfaces/imenu';
import { MenuService } from 'src/app/services/menu.service';
import { AdminService } from 'src/app/services/admin.service';
import { IshiftValue } from 'src/app/interfaces/itime';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-fliptable-items',
  templateUrl: './fliptable-items.component.html',
  styleUrls: ['./fliptable-items.component.css'],
})
export class FliptableItemsComponent implements OnInit {
  @Input() displayedColumns;
  @Input() dataSource;
  @Input() columns;
  @Input() rowColor;
  @Input() EmployeeTableData: any = [];
  @Input() BreakTypeData;
  @Input() layout_Data: boolean = true;
  @Input() _itemid: any;
  employeeName: any = 'Employee Name';
  SavedRowID: any;
  removeRow: any;
  rowEmp: any;
  rowClicked: any;
  public isActiveInactivePerm: boolean;
  idx: any;
  idxName: any;
  idxStore: any;
  idxStoreName: any;
  //public ActivePermission: any;
  loginInfo: any;
  idxComp: any;
  idxCompName: any;
  changeDrop: boolean = false;
  dropping: boolean = false;
  checkboxValue: boolean = true;
  public salesAreabit: any;
  // render: any;
  activeDataSource = [];
  dataValueSource: any = [];
  @ViewChild(MatSort) sort: MatSort;
  public modifierItemsData: any;
  public dataRowArea: any;
  @Output() public dataEmitArea = new EventEmitter();
  @Output() public toggleemit = new EventEmitter();

  @Output() public tableItemId = new EventEmitter();
  @Output() public tableEmpName = new EventEmitter();
  @Output() public hierarchy = new EventEmitter();
  @Output() public menusize = new EventEmitter();
  @Output() public menuItem = new EventEmitter();
  @Output() public timeEdit = new EventEmitter();
  @Output() public hierarchyActive = new EventEmitter();
  @Output() public breakActive = new EventEmitter();
  @Output() public clearDetails = new EventEmitter();
  //@Output() public dragItememit = new EventEmitter();
  @Output() public hierarchyData = new EventEmitter();
  @Output() public modalPopup = new EventEmitter();
  @Output() public clearPayment = new EventEmitter();
  @Output() public btnActive = new EventEmitter();
  @Output() public hideCopyFromGiftCard = new EventEmitter();
  @Output() public typeID = new EventEmitter();
  @Output() public typenotID = new EventEmitter();
  @Input() public dealerRow: any;
  @Input() public companyRow: any;
  @Input() public storeRow: any;
  @Input() public empColor: any;
  @Input() public employee;
  @Input() public menuItemData;
  @ViewChild('table') table: MatTable<any>;
  @ViewChild('filter', { static: false }) filter: ElementRef;
  bsModalRef?: BsModalRef;
  datalenngth = [];
  searchField;
  hideCard: boolean = false;
  hideCopyFromGiftCardColor: boolean = false;
  data: { item_id: number; item_text: string }[];
  name = 'Angular';
  dropdownSettings: IDropdownSettings = {};
  menuFilterData = [];
  typeArray = [];

  // filter : any
  constructor(
    private helper: HelperService, private tableDetails: EmployeeService,
    private modalService: BsModalService,
    private menuTypeService: AdminService, private permser: PermissionService
  ) { }

  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');

    this.SavedRowID = this.helper.getValue('SaveId');
    this.idx = this.SavedRowID;
    this.toggleIsActive(this.checkboxValue);

    // this.ActivePermission = this.loginInfo.primaryRolePermissionListInfo.filter(
    //   (x) => x.id == 8
    // );

    // if (this.loginInfo.tenantType == 4) {
    //   if (this.ActivePermission.length != 0) {
    //     if (this.ActivePermission[0].id == 8) {
    //       // Active /Inactive Permission
    //       this.isDisabled = false;
    //     }
    //   } else {
    //     this.isDisabled = true;
    //   }
    // } else {
    //   this.isDisabled = false;
    // }

    this.isActiveInactivePerm = this.permser.isActiveInactive;

    this.dataSource.filterPredicate = (data, filtersJson: string) => {
      const matchFilter = [];
      const filters = JSON.parse(filtersJson);

      filters.forEach((filter) => {
        const val = data[filter.id] === null ? '' : data[filter.id];
        matchFilter.push(
          val.toLowerCase().includes(filter.value.toLowerCase())
        );
      });
      return matchFilter.every(Boolean);
    };

    this.itemtypeDropdown();
    this.dropdownSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 2,
      selectAllText: 'Select All',
      unSelectAllText: 'Select All'
    };
  }

  async itemtypeDropdown() {
    const tagList: IshiftValue = {
      parentCode: "MF1"
    }
    this.menuTypeService
      .GetCodeMaster(tagList)
      .subscribe((res) => {
        var ItemsData = res;
        var itemSalesArea;
        this.menuFilterData.push;
        var jsonToBeUsedSalesArea = [];
        for (var type in ItemsData) {
          itemSalesArea = {};
          itemSalesArea.key = ItemsData[type].typeId.toString();
          itemSalesArea.value = ItemsData[type].typeName;
          jsonToBeUsedSalesArea.push(itemSalesArea);
        }
        this.menuFilterData = jsonToBeUsedSalesArea;
      });
  }

  public onItemSelect(item: any) {
    if (item.key == "MF2") {
      this.typeID.emit("main");
    }
    else if (item.key == "MF3") {
      this.typeID.emit("sideDish");
    }
    else if (item.key == "MF4") {
      this.typeID.emit("remark");
    }
    else if (item.key == "MF5") {
      this.typeID.emit("ingredient");
    }
    else if (item.key == "MF6") {
      this.typeID.emit("Modifier");
    }
  }

  onitemDeSelect(item) {
    if (item.key == "MF2") {
      this.typenotID.emit("main");
    }
    else if (item.key == "MF3") {
      this.typenotID.emit("sideDish");
    }
    else if (item.key == "MF4") {
      this.typenotID.emit("remark");
    }
    else if (item.key == "MF5") {
      this.typenotID.emit("ingredient");
    }
    else if (item.key == "MF6") {
      this.typenotID.emit("Modifier");
    }
  }

  onSelectAll() {
    this.typeID.emit("All");
  }

  onDeSelectAll() {
    this.typeID.emit("All");
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
  // public doFilter = (value: string) => {
  //   this.dataSource.filter = value.trim().toLocaleLowerCase();

  // }
  applyFilter(filterValue: string): void {
    const tableFilters = [];
    tableFilters.push({
      id: 'name',
      value: filterValue,
    });

    this.dataSource.filter = JSON.stringify(tableFilters);
    // if(filterValue != ''){
    //   this.searchField = true
    // }

    // this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }
  clearSearchField() {
    this.searchField = '';
    this.applyFilter('');
  }
  // applyFilter(filterValue: string) {
  //   // filterValue = filterValue.trim(); // Remove whitespace
  //   // filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
  //   this.dataSource.filter = filterValue;
  // }
  // filterTable() {
  //   this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
  //     return (
  //       data.name.toLocaleLowerCase().includes(filter)
  //     )
  //   }
  // }
  @Input()
  public set dealerItem(dealerRow) {
    this.rowClicked = dealerRow;
  }
  @Input()
  public set companyItem(companyRow) {
    this.rowClicked = companyRow;
  }
  @Input()
  public set storeItem(storeRow) {
    this.rowClicked = storeRow;
  }
  @Input()
  public set CompanyColour(tableRowColor) {
    this.rowClicked = tableRowColor;
  }
  @Input()
  public set dealerColour(tableRowColor) {
    this.rowClicked = tableRowColor;
  }
  @Input()
  public set storeColour(tableRowColor) {
    this.rowClicked = tableRowColor;
  }

  @Input()
  public set employeeItem(tableRowIdcolor) {
    if (
      this.rowClicked != undefined &&
      this.rowClicked != null &&
      this.rowClicked != ''
    ) {
      this.rowClicked = tableRowIdcolor;
    }
  }

  @Input()
  public set DataCom(employee) {
    this.employee = employee;
  }

  @Input()
  public set DataDealerId(dealerIdItem) {
    if (
      dealerIdItem != undefined &&
      dealerIdItem != null &&
      dealerIdItem != ''
    ) {
      this.idx = dealerIdItem.dealer.id;
      this.idxName = dealerIdItem.dealer.name;
      this.rowClicked = this.idx;
      //this.changeTableRowColor(this.idx, this.idx, this.idxName)
    }
  }

  @Input()
  public set DataStoreId(storeIdItem) {
    if (storeIdItem != undefined && storeIdItem != null && storeIdItem != '') {
      this.idxStore = storeIdItem.store.id;
      this.idxStoreName = storeIdItem.store.name;
      this.rowClicked = this.idxStore;
      //this.changeTableRowColor(this.idxStore, this.idxStore, this.idxStoreName)
    }
  }

  @Input()
  public set DataCompId(CompIdItem) {
    if (CompIdItem != undefined && CompIdItem != null && CompIdItem != '') {
      this.idxComp = CompIdItem.company.id;
      this.idxCompName = CompIdItem.company.name;
      this.rowClicked = this.idxComp;
      //this.changeTableRowColor(this.idxComp, this.idxComp, this.idxCompName)
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  @Input()
  public set dataLayout(Modifier_Layout) {
    this.layout_Data = Modifier_Layout;
  }

  @Input()
  public set titleCardItem(item) {
    var days = item.message.split('-');
    this.changeTableRowColor(item.id, item.id, days[0]);
  }

  changeTableRowColor(
    idx?: any,
    empid?: any,
    name?: any,
    row?: any,
    event?: any
  ) {
    this.rowClicked = '';
    this.rowClicked = this.rowClicked === idx ? -1 : idx;
    this.tableItemId.emit(empid);
    this.employeeName = name;
    this.tableEmpName.emit(this.employeeName);
    this.hierarchy.emit({ id: empid, name: name, idx: idx });
    this.timeEdit.emit({ id: empid, name: name, idx: idx });
    this.hierarchyData.emit(row);
    this.menuItem.emit({ event });
    if (row != undefined) {
      if (
        row.sessionId != undefined &&
        row.sessionId != null &&
        row.sessionId != ''
      ) {
        if (row.sessionId == 1) {
          this.dataRowArea = row;
          localStorage.setItem('deal_ID', row.id);
          localStorage.setItem('deal_name', row.name);
          this.dataEmitArea.emit(this.dataRowArea);
        }
        if (row.sessionId == 2) {
          this.dataRowArea = row;
          localStorage.setItem('comp_ID', row.id);
          localStorage.setItem('comp_name', row.name);
          this.dataEmitArea.emit(this.dataRowArea);
        }
        if (row.sessionId == 3) {
          this.dataRowArea = row;
          localStorage.setItem('str_ID', row.id);
          localStorage.setItem('str_name', row.name);
          this.dataEmitArea.emit(this.dataRowArea);
        }
      } else {
        this.dataRowArea = row;
        this.dataEmitArea.emit(this.dataRowArea);
      }
    }

    const clickValues: IrowUpdate = {
      clickItem: row,
    };
    this.tableDetails.clickUpdate(clickValues);
    this.hideCopyFromGiftCard.emit(true);
  }

  selectTableRowColor(
    idx?: any,
    empid?: any,
    name?: any,
    row?: any,
    event?: any
  ) {
    this.rowClicked = '';
    this.rowClicked = this.rowClicked === idx ? -1 : idx;
    this.employeeName = name;
    this.tableEmpName.emit(this.employeeName);
    this.hierarchy.emit({ id: empid, name: name, idx: idx });
    this.timeEdit.emit({ id: empid, name: name, idx: idx });
    this.hierarchyData.emit(row);
    this.menuItem.emit({ event });
    if (row != undefined) {
      if (
        row.sessionId != undefined &&
        row.sessionId != null &&
        row.sessionId != ''
      ) {
        if (row.sessionId == 1) {
          this.dataRowArea = row;
          this.dataEmitArea.emit(this.dataRowArea);
        }
        if (row.sessionId == 2) {
          this.dataRowArea = row;
          this.dataEmitArea.emit(this.dataRowArea);
        }
        if (row.sessionId == 3) {
          this.dataRowArea = row;
          this.dataEmitArea.emit(this.dataRowArea);
        }
      } else {
        this.dataRowArea = row;
        this.dataEmitArea.emit(this.dataRowArea);
      }
    }

    const clickValues: IrowUpdate = {
      clickItem: row,
    };
    this.tableDetails.clickUpdate(clickValues);
  }

  checkValue(e: any, id) {
    this.hierarchyActive.emit({ e: e, id: id });
    this.breakActive.emit({ e: e, id: id });
    if (e.target.checked == true) {
      this.toggleIsActive(true);
    }
  }
  // modal_popup(template: TemplateRef<any>){
  //   this.modalPopup.emit(template)
  // }

  openModalWithComponent() {
    this.bsModalRef = this.modalService.show(FilterTablePopupComponent, {
      class: 'filterTableModal modal-xl',
      backdrop: 'static',
      keyboard: true,
    });
    // modal-dialog-centered
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  addEmployee(employee: any, hierarchy: any, area: any, payment: any) {
    this.clearDetails.emit({ 1: employee, 2: hierarchy, 3: area });
    this.clearPayment.emit({ 4: payment });
  }

  drop(event) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data.filteredData,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.table.renderRows();
  }

  public onDragEnd(event: CdkDragEnd<string[]>) {
    this.dropping = true;
    //this.dragItememit.emit(this.dropping)
  }

  toggleIsActive(e) {
    this.btnActive.emit(e);
    if (
      this.EmployeeTableData != undefined &&
      this.EmployeeTableData != null &&
      this.EmployeeTableData != ''
    ) {
      this.activeDataSource = this.EmployeeTableData.filter(
        (x) => x.isActive == e
      );
      this.dataSource.data = this.activeDataSource;
      this.checkboxValue = e;
    }
  }

  @Input()
  public set DatasalesArea(salesArea) {
    this.salesAreabit = salesArea;
  }
}
