import { Injectable } from '@angular/core';
import { HelperService } from '../helper/helper.service';
import { BaseService } from '../base/base.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SaveMenuPages, GetMenuPages, DeleteMenuPages, EditMenuPages, SaveMenuPageLayout } from '../interfaces/imenu';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })

  export class MenuPageService {
    constructor(public http: HttpClient,
      private Helper: HelperService, private base: BaseService, private router: Router) { }

      MenuPageSave(objpageSave: SaveMenuPages) {
        return this.base.post('Menu/SaveMenuPages', objpageSave).pipe(map((response: any) => {
          return response;
        }));

    }
    MenuPageSaveLayout(objpageSaveLayout: SaveMenuPageLayout) {
      return this.base.post('Menu/SaveMenuPageLayout', objpageSaveLayout).pipe(map((response: any) => {
        return response;
      }));
    }
    MenuPageGet(objpageget: GetMenuPages) {
        return this.base.post('Menu/GetMenuPages', objpageget).pipe(map((response: any) => {
          return response;
        }));
    }

    MenuPageEdit(objpageEdit: EditMenuPages) {
      return this.base.post('Menu/EditMenuPages', objpageEdit).pipe(map((response: any) => {
        return response;
      }));
  }
    MenuPageDelete(objpageDelete: DeleteMenuPages) {
        return this.base.post('Menu/DeleteMenuPages', objpageDelete).pipe(map((response: any) => {
          return response;
        }));
    }
}