import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { DragDrop, GetSaleAreaGeneral, layoutBuilderData, menuItemList } from 'src/app/interfaces/imenu';
import { MenuService } from 'src/app/services/menu.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { SalesAreaLayoutComponent } from '../sales-area-layout/sales-area-layout.component';
import { MenuGroupLayoutComponent } from '../menu-group-layout/menu-group-layout.component';
import { viewClassName } from '@angular/compiler';
import { MenuPageLayoutComponent } from '../menu-page-layout/menu-page-layout.component';
import { DragDropBuilderComponent } from 'src/app/elements/drag-drop-builder/drag-drop-builder.component';
import { TraceLoggerService } from 'src/app/services/trace-logger.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-layout-builder',
  templateUrl: './layout-builder.component.html',
  styleUrls: ['./layout-builder.component.css']
})
export class LayoutBuilderComponent implements OnInit {
  @ViewChild(SalesAreaLayoutComponent) salesAreaLayoutComp: SalesAreaLayoutComponent;
  @ViewChild(MenuGroupLayoutComponent) menuGrpLayoutComp: MenuGroupLayoutComponent;
  @ViewChild(MenuPageLayoutComponent) menuPageLayoutComp: MenuPageLayoutComponent;
  @ViewChild(DragDropBuilderComponent) dragDropComp: DragDropBuilderComponent;
  public dataSourceArea = new MatTableDataSource<any>();
  public dataSourceAreaTable = '';//ELEMENT_AREA;
  public displayedColumns = [];
  public columns = [];
  public rowclickedData: any;
  loginInfo: any;
  isItemLayoutClick: boolean = false;
  public SalesAreaLayout: any;
  public SalesAreaList = [];
  public MenuGroupList: any;
  isSave: boolean = false;
  accordianDropStatus: any;
  public isDropEvent: any;
  public MenuPageList: any;
  public ItemList: any;
  public MenuGroupLayout: any;
  public MenuPageLayout: any;
  public ItemLayout: any;
  public SalesAreaMenuGroup: any;
  public Layout: any;
  public MenuGroupMenuPage: any;
  public MenuPageMenuItem: any;
  public SalesAreaParentID: any;
  public MenuGroupParentID: any;
  public MenuPagesParentID: any;

  public Area = "";
  public group = "";
  public page = "";
  public AreaId: any = 1;
  public ParentID: any;
  public pageId: any;
  menuName: any;
  menuItem: any = {
    tenantId: 0,
    salesAreaId: 0,
    menuGroupId: 0,
    menuPageId: 0,
    areaObject: "",
  };
  @Output() public areaIdEmit = new EventEmitter()
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public areaIdempty = new EventEmitter();
  @Output() public groupIdempty = new EventEmitter();
  @Output() public pageIdempty = new EventEmitter();
  @Input() public buttonDisable;
  globalBit: boolean;
  layoutBtn: boolean;
  PageName: any;
  @Output() public layoutsessData = new EventEmitter;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private ItemLog: MenuService, private objItem: MenuService,
    private helper: HelperService, private toaster: ToasterService, private LoggerService: TraceLoggerService,
    private dialogser: DialogService) { }

  buttonStatus: any;
  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.layoutPage");

    this.PageName = "layoutPage";
    this.buttonStatus = false;
    this.employeeName = "Sales Area";
    this.employee = "Sales Area Layout";
    this.employeeIcon = "assets/components/images/menu-item.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.loginInfo = this.helper.getValue('LoginInfo');

    this.getLayoutList();
    this.areaIdempty.emit(this.Area);
    this.groupIdempty.emit(this.group);
    this.pageIdempty.emit(this.page);
  }

  @Input()
  public set saveLayoutBtn(layoutPage) {
    this.layoutBtn = layoutPage;
  }

  getRowData(e?) {
    // this.helper.setValue('OnLayoutSelected', e);
    //console.log(e, 'rowclickedData')

    this.titleName.emit(e.name);
    this.rowclickedData = e;
    this.areaIdEmit.emit(this.rowclickedData);

    if (e.SessionId == 1) // sales area
    {
      this.SalesAreaParentID = e.id
      this.getMenuGroupList(e.id, this.MenuGroupMenuPage, this.Layout);
      this.menuGrpLayoutComp.rowClicked = null;
      this.helper.setValue('MnuGrpLayout_ID', null);
      this.MenuPageLayout = [];
      this.ItemLayout = [];
    }
    else if (e.SessionId == 2)  // maneu group
    {
      this.MenuGroupParentID = e.id
      this.menuPageLayoutComp.rowClicked = null;
      this.helper.setValue('MnuPageLayout_ID', null)
      this.getMenuPageList(e.id, this.MenuPageMenuItem, this.Layout);
      this.ItemLayout = [];
    }
    else if (e.SessionId == 3)  // menu pages
    {
      this.MenuPagesParentID = e.id;
      this.getItemList(e.id, this.MenuPageMenuItem, this.Layout, this.SalesAreaParentID, this.MenuGroupParentID);
    }
  }

  getLayoutList(data?) {
    const menuItemList: GetSaleAreaGeneral = {
      tenantId: this.loginInfo.tenantId,
    }

    //  this.LoggerService.SaveLoggerInformation(this.loginInfo.tenantId, 'info', 'Sales Area Layout-getLayoutList', JSON.stringify(menuItemList), this.loginInfo.userId)
    this.objItem.getSalesAreaDetails(menuItemList)
      .pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.MenuPageMenuItem = res.MenuPages;
        this.MenuGroupMenuPage = res.MenuGroups;
        this.SalesAreaMenuGroup = res.SalesAreaGeneral;
        this.Layout = res.SalesAreaLayout;

        this.getSalesAreaList(res.SalesAreaGeneral, this.Layout);


      }), err => console.error('Error', err), () => console.log('HTTP request completed.')


  }

  getSalesAreaList(SalesAreaGeneral, ItmSalesAreaLay) {
    this.SalesAreaLayout = [];
    this.SalesAreaList = [];
    const result3 = SalesAreaGeneral;
    result3.forEach(element => {
      let reportdata1 = {
        id: element.id,
        name: element.name,
        SessionId: 1
      }
      this.SalesAreaList.push(reportdata1);
    })

    if (ItmSalesAreaLay.length > 0) {
      let SalesLayout = ItmSalesAreaLay.find(i => i.TenantId === this.loginInfo.tenantId && i.SalesAreaId === 0 &&
        i.MenugroupId === 0 && i.MenuPageId === 0);

      if (SalesLayout.JsonObject != '' && SalesLayout.JsonObject != null && SalesLayout.JsonObject != undefined) {
        this.SalesAreaList = [];
        this.SalesAreaLayout = JSON.parse(SalesLayout.JsonObject);
        const result2 = this.SalesAreaLayout;
        this.helper.setValue('GetSessionData1', JSON.stringify(result2));
        //this.helper.setValue('OnDirty', true);
        this.layoutsessData.emit({ data: this.SalesAreaLayout, event: "layoutPage", val: true });
        const result1 = SalesAreaGeneral;
        const uniqueResultOne = result1.filter(function (obj) {
          return !result2.some(function (obj2) {
            return obj.id == obj2.id;
          });
        });

        uniqueResultOne.forEach(element => {
          let reportdata1 = {
            id: element.id,
            name: element.name,
            SessionId: 1
          }
          this.SalesAreaList.push(reportdata1);
        })

        if (this.helper.getValue('OnDirty') == true) {

          this.salesAreaLayoutComp.rowClicked = this.helper.getValue('SalesLayout_ID');
          this.menuGrpLayoutComp.rowClicked = this.helper.getValue('MnuGrpLayout_ID');
          this.menuPageLayoutComp.rowClicked = this.helper.getValue('MnuPageLayout_ID');
          if (this.isSave == false) {
            this.salesAreaLayoutComp.rowClicked = null;
          }

        }
      }
    }
  }

  getMenuGroupList(Id, MenuGroupGeneral, MenuGroup) {
    // this.ParentID=Id;
    //   console.log('b')
    this.MenuGroupLayout = [];
    let MenuGroupLayout = MenuGroup.find(i => i.TenantId == this.loginInfo.tenantId && i.SalesAreaId === Id &&
      i.MenugroupId === 0 && i.MenuPageId === 0);

    this.MenuGroupList = [];

    if (MenuGroupLayout != undefined) {
      if (MenuGroupLayout.JsonObject != '' && MenuGroupLayout.JsonObject != null && MenuGroupLayout.JsonObject != undefined) {
        // this.MenuGroupList = [];
        this.MenuGroupLayout = JSON.parse(MenuGroupLayout.JsonObject);
        const result2 = this.MenuGroupLayout;
        this.helper.setValue('GetSessionData2', JSON.stringify(result2));
        this.helper.setValue('OnDirty', true);
        const menuGroupList = MenuGroupGeneral;
        const getUniqueGroupList = menuGroupList.filter(function (obj) {
          return !result2.some(function (obj2) {
            return obj.id == obj2.id;
          });
        });

        this.MenuGroupList = getUniqueGroupList;
      }
      else {
        this.MenuGroupList = MenuGroupGeneral;
      }
    }
    else {
      this.MenuGroupList = MenuGroupGeneral;
    }
  }

  getMenuPageList(Id, MenuPageGeneral, MenuPage) {
    this.MenuPageLayout = [];
    let MenuPageLayout = MenuPage.find(i => i.TenantId == this.loginInfo.tenantId && i.SalesAreaId === this.SalesAreaParentID &&
      i.MenugroupId === Id && i.MenuPageId === 0);

    this.MenuPageList = [];

    if (MenuPageLayout != undefined) {
      if (MenuPageLayout.JsonObject != '' && MenuPageLayout.JsonObject != null && MenuPageLayout.JsonObject != undefined) {
        //   this.MenuPageList = [];
        this.MenuPageLayout = JSON.parse(MenuPageLayout.JsonObject);
        const result2 = this.MenuPageLayout;
        this.helper.setValue('GetSessionData3', JSON.stringify(result2));
        this.helper.setValue('OnDirty', true);
        const manuPageList = MenuPageGeneral;
        const getUniquePageList = manuPageList.filter(function (obj) {
          return !result2.some(function (obj2) {
            return obj.id == obj2.id;
          });
        });
        this.MenuPageList = getUniquePageList;

      }
      else {
        this.MenuPageList = MenuPageGeneral;
      }

    }
    else {
      this.MenuPageList = MenuPageGeneral;
    }
  }

  getItemList(Id, MenuItem, layoutvalues, salesAreaId, menugroupid) {
    this.pageId = Id;
    this.ParentID = this.MenuGroupParentID
    const menuItemList: GetSaleAreaGeneral = {
      id: this.pageId,
      tenantId: this.loginInfo.tenantId,
      salesareaid: this.SalesAreaParentID,
      menugroupid: this.MenuGroupParentID,
    }
    //this.LoggerService.SaveLoggerInformation(this.loginInfo.tenantId, 'info', 'SalesArea-ItemList', JSON.stringify(menuItemList), this.loginInfo.userId)
    if (this.pageId != '' && this.MenuGroupParentID != '') {
      this.objItem.getMenuItemPages(menuItemList)
        .pipe(takeUntil(this.onDestroy$)).subscribe(res => {
          this.ItemList = res;
          let ItemDetails = layoutvalues.find(i => i.SalesAreaId === this.SalesAreaParentID && i.TenantId == this.loginInfo.tenantId &&
            i.MenugroupId === this.MenuGroupParentID && i.MenuPageId === this.MenuPagesParentID);

          this.ItemLayout = [];
          if (ItemDetails != undefined) {
            if (ItemDetails.JsonObject != '' && ItemDetails.JsonObject != null && ItemDetails.JsonObject != undefined) {
              this.ItemLayout = JSON.parse(ItemDetails.JsonObject);
            }
          }
        }), err => console.error('Error', err), () => console.log('HTTP request completed.')
    }
  }

  onChangeAccordion(e) {
    if (this.isDropEvent == true) {
      this.dragDropComp.OnSave('');
    }
    if (e.currentTarget.innerText == "Sales Area Layout") {
      this.employeeName = "Sales Area"
      // this.menuGrpLayoutComp.rowClicked=null;
      this.salesAreaLayoutComp.onSaveSaleLayout();
      this.menuGrpLayoutComp.OnSaveMenuGroup();
      this.menuPageLayoutComp.OnSavepageGroup();

      this.employee = e.currentTarget.innerText
      this.titleName.emit(this.employeeName);
      this.title.emit(this.employee)
      //  this.AreaId = 1;
    }
    if (e.currentTarget.innerText == "Menu Group Layout") {
      this.salesAreaLayoutComp.onSaveSaleLayout();
      this.menuGrpLayoutComp.OnSaveMenuGroup();
      this.menuPageLayoutComp.OnSavepageGroup();

      this.employeeName = "Menu Group"
      this.employee = e.currentTarget.innerText
      this.titleName.emit(this.employeeName);
      this.title.emit(this.employee)
      //   this.AreaId = 2;

      if (this.helper.getValue('OnDirty') == true) {
        this.SalesAreaParentID = this.helper.getValue('SalesLayout_ID');
      }
    }
    if (e.currentTarget.innerText == "Menu Page Layout") {
      this.salesAreaLayoutComp.onSaveSaleLayout();
      this.menuGrpLayoutComp.OnSaveMenuGroup();
      this.menuPageLayoutComp.OnSavepageGroup();

      this.employeeName = "Menu Page"
      this.employee = e.currentTarget.innerText
      this.titleName.emit(this.employeeName);
      this.title.emit(this.employee)

      if (this.helper.getValue('OnDirty') == true) {
        this.MenuGroupParentID = this.helper.getValue('MnuGrpLayout_ID');


      }
    }
    if (e.currentTarget.innerText == "Items Layout") {
      this.salesAreaLayoutComp.onSaveSaleLayout();
      this.menuGrpLayoutComp.OnSaveMenuGroup();
      this.menuPageLayoutComp.OnSavepageGroup();

      this.isItemLayoutClick = true;

      this.employeeName = "Items"
      this.employee = e.currentTarget.innerText
      this.titleName.emit(this.employeeName);
      this.title.emit(this.employee)


      if (this.helper.getValue('OnDirty') == true) {
        this.MenuPagesParentID = this.helper.getValue('MnuPageLayout_ID');

      }
    }
  }

  async onSaveItem(event) {
    // alert(this.dragDropComp.SalesAreaItem)
    // let ItemDetailsSave = this.MenuPageMenuItem.find(i => i.id === this.MenuPagesParentID);
    this.salesAreaLayoutComp.onSaveSaleLayout();
    this.menuGrpLayoutComp.OnSaveMenuGroup();
    this.menuPageLayoutComp.OnSavepageGroup();

    let itemlistevent = event.itemObject
    this.menuItem.tenantId = this.loginInfo.tenantId;
    this.menuItem.salesAreaId = this.SalesAreaParentID;
    this.menuItem.menuGroupId = this.MenuGroupParentID;
    this.menuItem.menuPageId = this.MenuPagesParentID;
    this.menuItem.areaObject = itemlistevent;
    console.log(this.menuItem, 'SaveItemLayout');
    if (this.SalesAreaParentID != '' && this.MenuGroupParentID != '' && this.MenuPagesParentID != '') {
      if (itemlistevent != '[]' && event != undefined) {
        this.objItem.saveAreaMapping(this.menuItem).pipe(takeUntil(this.onDestroy$)).subscribe(
          async (response) => {
            if (this.isDropEvent == false) {
              this.toaster.successPopUp(response.message);
            }
            this.isDropEvent = false;
            this.getLayoutList();
            await this.dialogser.ClearSession();
          }), err => console.error('Error', err), () => console.log('HTTP request completed.')
      }
    }
  }

  @Input()
  public set buttonState(btnst) {
    this.buttonStatus = !btnst;
  }

  OnGlobalSave() {
    this.isSave = true;
    this.isDropEvent = false;
    this.dragDropComp.OnSave('');
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  GetDropEvent(e) {
    this.isDropEvent = e;
    //console.log(e, 'GetDropEvent')
  }

  AccordianDropValue(e) {
    const _item: layoutBuilderData = {
      id: '',
      name: '',
      SessionId: e
    }

    this.areaIdEmit.emit(_item)
    if (e == 1) {
      this.salesAreaLayoutComp.rowClicked = null;
      this.MenuGroupLayout = [];
      this.MenuPageLayout = [];
      this.ItemLayout = [];

    }
    else if (e == 2) {
      this.menuGrpLayoutComp.rowClicked = null;
      //   this.MenuGroupLayout = [];
      this.MenuPageLayout = [];
      this.ItemLayout = [];
    }
    else if (e == 3) {
      this.menuPageLayoutComp.rowClicked = null;
      // this.MenuGroupLayout = [];
      // this.MenuPageLayout = [];
      this.ItemLayout = [];
    }
    //console.log(e, 'GetSalesAreaDropable')
  }

  getPopupmsg(e) {
    this.helper.setValue('OnDirty', true);
    this.layoutsessData.emit({ data: this.SalesAreaLayout, event: "layoutPage", val: true });
  }
}