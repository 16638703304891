<div class="table-container">

    <table mat-table #table [dataSource]="dataSource" matSort
      class="mat-elevation-z8 table employee-table employee dataTable full-width-table table_center" style="width: 100%;">
      <ng-container *ngFor="let item of columns " matColumnDef="{{item.columnDef}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="emp_name"> {{item.header}} </th>
  
        <td mat-cell *matCellDef="let element; let idx=index;">
          <div
            *ngIf=" item.columnDef != 'amount' && item.columnDef != 'name' && item.columnDef != 'check' && item.columnDef != 'taxAmount' && item.columnDef != 'netSales'">
            {{ element[item.columnDef] }}</div>
          <div *ngIf="item.columnDef == 'amount'">
            {{ element[item.columnDef] | currency}}
          </div>
          <div *ngIf="item.columnDef == 'taxAmount'">
            {{ element[item.columnDef] | currency}}
          </div>
          <div *ngIf="item.columnDef == 'netSales'">
            {{ element[item.columnDef] | currency}}
          </div>
          <span *ngIf="item.columnDef == 'name' || item.columnDef == 'check'"
            [ngClass]="element[item.columnDef] == '' ? '' : 'emp_name'">
            <!-- [style.background-color]="rowClicked == element.id ? '#341a46' : 'transparent'" -->
            {{ element[item.columnDef] }}
          </span>
  
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <div *ngIf="item.columnDef == 'name' || item.columnDef == 'fName'">Totals
          </div>
          <!-- <div *ngIf="item.columnDef == 'quantity'" class="footerData">
              <div [style.display]="tableFooDisplay == true ? 'none':'block'">
                {{getTotalCost()}}
              </div>
            </div> -->
          <div *ngIf="item.columnDef == 'name'" class="footerData">
            <h6>Total Devices</h6>
            {{getTotalCost()}}
          </div>
          <div *ngIf="item.columnDef == 'quantity'" class="footerData">
            <h6>Total Transactions</h6>
            {{getTotalCost13()}}
          </div>
          <div *ngIf="item.columnDef == 'amount'" class="footerData">
            <h6 *ngIf="firstTableShow == true">Batch Amount Sent</h6>
            <h6 *ngIf="secondTableShow == true">Device Amount Sent</h6>
            {{getTotalCost2() | currency}}
          </div>
          <div *ngIf="item.columnDef == 'check'" class="footerData">
            <h6>Total Check</h6>
            {{getTotalCost1()}}
          </div>
  
  
          <div *ngIf="item.columnDef == 'regPay'" class="footerData">
            {{getTotalCost3()}}
          </div>
          <div *ngIf="item.columnDef == 'totalHours'" class="footerData">
            {{getTotalCost4()}}
          </div>
          <div *ngIf="item.columnDef == 'totalPay'" class="footerData">
            {{getTotalCost5()}}
          </div>
          <div *ngIf="item.columnDef == 'ccTip'" class="footerData">
            {{getTotalCost6()}}
          </div>
          <div *ngIf="item.columnDef == 'tipReceive'" class="footerData">
            {{getTotalCost7()}}
          </div>
          <div *ngIf="item.columnDef == 'charges'" class="footerData">
            {{getTotalCost8()}}
          </div>
          <div *ngIf="item.columnDef == 'tipOuts'" class="footerData">
            {{getTotalCost9()}}
          </div>
          <div *ngIf="item.columnDef == 'tipPer'" class="footerData">
            {{getTotalCost10()}}
          </div>
          <div *ngIf="item.columnDef == 'taxAmount'" class="footerData">
            {{getTotalCost11() | currency}}
          </div>
          <div *ngIf="item.columnDef == 'netSales'" class="footerData">
            {{getTotalCost12() | currency}}
          </div>
        </td>
  
      </ng-container>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell employeenodata" [attr.colspan]="displayedColumns.length">No data matching the search</td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row (click)="changeTableRowColor(row.id,row)" *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'discountadded': row.move == 'Check Discount' || row.move == 'discountAdded' }"></tr>
      <!-- [style.background-color]="rowClicked == row.id ? '#393939!important' : 'transparent'" -->
      <div *ngIf="!thirdTableShow && !nofooter">
        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="footer"
          [attr.colspan]="displayedColumns.length"></tr>
      </div>
  
    </table>
  </div>