import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ISelectTagDetails } from 'src/app/interfaces/i-tags';
import { TagsService } from 'src/app/services/tags.service';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-tag-print-job',
  templateUrl: './tag-print-job.component.html',
  styleUrls: ['./tag-print-job.component.css']
})
export class TagPrintJobComponent implements OnInit {
  public table_render: boolean = true;
  public displayedColumns = [];
  public columns = [];
  public dataSource = new MatTableDataSource<any>();
  @Input() tagsID
  loginInfo: any
  JobList: any = [];
  tags_id: any = 0;
  public FinalJobList = [];
  @ViewChild('table') table: MatTable<any>;
  @Output() public generalItem = new EventEmitter();
  // @Input() JobList

  constructor(private changeDetector: ChangeDetectorRef, private tagService: TagsService,
    private helper: HelperService) { }

  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');
  }

  drop(event) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        // event.previousContainer.data,
        event.previousContainer.data.filteredData,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.table_render = false
      this.changeDetector.detectChanges();
      this.table_render = true
    }
    this.FinalJobList = event.container.data;
    this.generalItem.emit(this.FinalJobList);
    // this.table.renderRows();
  }

  getTableValue(e) {
    this.FinalJobList = e
    this.generalItem.emit(this.FinalJobList);
  }

  getRolelist(rowId) {
    const ItemTypeList: ISelectTagDetails = {
      id: rowId,
      tenantId: this.loginInfo.tenantId
    }
    this.tagService.SelectAvailableJobs(ItemTypeList)
      .subscribe(res => {
        this.dataSource.data = res;
        this.displayedColumns = ['name'];
        this.columns = [
          { columnDef: 'name', header: 'Jobs' }
        ];
      })
  }

  @Input()
  public set DataJobList(joblistval) {
    this.JobList = joblistval;
  }

  @Input()
  public set getrefreshjobval(ev) {
    if (ev == "refreshjp") {
      this.JobList = [];
    }
  }
}