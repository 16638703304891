import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { AdminService } from 'src/app/services/admin.service';
import { ISelectCategory } from 'src/app/interfaces/iadmin';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-labor-category-general',
  templateUrl: './labor-category-general.component.html',
  styleUrls: ['./labor-category-general.component.css']
})
export class LaborCategoryGeneralComponent implements OnInit {
  LaborcategoryForm: FormGroup;
  buttonDisable: boolean = false;
  submittedGeneral: any;
  loginInfo: any;
  id: any = 0;
  hirarcyvalue: any;

  @Output() public category_general = new EventEmitter();
  errorValue: any;
  requiredError: boolean = false;

  @Output() public isInvalid = new EventEmitter();
  @Output() public LaborcatData_child = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private dialogser: DialogService,
    private toaster: ToasterService, private adminService: AdminService) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.LaborcategoryForm = this.formBuilder.group({
      categoryName: ['', Validators.required],
      storename: ['']
    })

    this.adminService.HierachyValue.subscribe(data => {
      this.hirarcyvalue = data
      if (this.hirarcyvalue.store != undefined && this.hirarcyvalue.store != null &&
        this.hirarcyvalue.store != "") {
        this.LaborcategoryForm.patchValue({
          storename: this.hirarcyvalue.store.name,
        });
      }
      else {
        if (localStorage.getItem('str_ID') !== undefined && localStorage.getItem('str_ID') !== null &&
          localStorage.getItem('str_ID') !== "") {
          this.LaborcategoryForm.patchValue({
            storename: localStorage.getItem('str_name'),
          });
        }
      }
    });
  }
  get GeneralData() { return this.LaborcategoryForm.controls; }

  @Input()
  public set DataIdx(tableRowId) {
    this.errorValue = "";
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.id = tableRowId;
      this.adminService.HierachyValue.subscribe(data => {
        this.hirarcyvalue = data
        if (this.hirarcyvalue.store != undefined && this.hirarcyvalue.store != null &&
          this.hirarcyvalue.store != "") {
          if (tableRowId > 0) {
            this.GetCategoryDetails(tableRowId);
          }
        }
        else {
          if (localStorage.getItem('str_ID') !== undefined && localStorage.getItem('str_ID') !== null &&
            localStorage.getItem('str_ID') !== "") {
            if (tableRowId > 0) {
              this.GetCategoryDetails(tableRowId);
            }
          }
        }
      });
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  onSaveGeneral() {
    this.errorValue = "";
    this.submittedGeneral = true;
    if (this.LaborcategoryForm.invalid) {
      return;
    }

    this.LaborcategoryForm.value.id = this.id;
    this.LaborcategoryForm.value.isActive = true;
    this.LaborcategoryForm.value.tenantId = this.loginInfo.tenantId;
    this.LaborcategoryForm.value.createdBy = this.loginInfo.userId;
    this.LaborcategoryForm.value.modifiedBy = this.loginInfo.userId;

    this.adminService.SaveLabourCategory(this.LaborcategoryForm.value).subscribe(
      async (response) => {
        this.id = response.id;
        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
          await this.dialogser.ClearSession();
          this.helper.setValue('GetSessionData1', this.LaborcategoryForm.value);
          this.LaborcatData_child.emit({ data: this.LaborcategoryForm.value, event: "labor", val: false });
        }
        this.submittedGeneral = false;
        this.category_general.emit({ res: response, name: this.LaborcategoryForm.value.categoryName });
      })
  }

  GetCategoryDetails(rowId) {
    const categorySelect: ISelectCategory = {
      id: rowId,
      tenantId: this.loginInfo.tenantId
    }
    this.adminService.SelectLabourCategory(categorySelect)
      .subscribe(res => {
        this.id = res[0].id == null ? 0 : res[0].id;
        this.LaborcategoryForm.patchValue(res[0]);
      })
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  LaborCategoryValid() {
    if (this.LaborcategoryForm.invalid) {
      this.requiredError = true;
      this.isInvalid.emit(true);
      return;
    }
    else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.LaborcategoryForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.LaborcategoryForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.LaborcatData_child.emit(this.LaborcategoryForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.LaborcatData_child.emit({ data: data, event: "labor", val: true });
  }
}