import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuSizeComponent } from './menu-size/menu-size.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
// import { ThemeComponent } from 'src/app/theme/theme.component';

const routes: Routes = [
  // {
  //   path: '', component: ThemeComponent,
  //   children: [
  //     { path: 'menu-item', component: MenuItemComponent},
  //     { path: 'menu-size', component: MenuSizeComponent},
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes) ],
  exports: [RouterModule]
})
export class MenuRoutingModule { }
