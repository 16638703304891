<div class="row loginForm">
  <div
    class="col-sm-4 left-table"
    [style.display]="hideField ? 'none' : 'block'"
  >
    <div class="table-container">
      <table
        #tableItems
        mat-table
        [dataSource]="dataSourceTableItems"
        matSort
        class="mat-elevation-z8 table employee-table employee dataTable"
        style="width: 100%"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <div class="title">Items (Ingredient & Side)</div>
            <div class="dragdrop-search">
              <input
                class="form-control"
                type="text"
                placeholder="Search here"
                (keyup)="onReciepSearch($event.target.value)"
                [(ngModel)]="searchValue"
                (keydown)="handleInput($event)"
                appInputRestriction
              />
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            ngxDroppable
            class="root-container menuwithscroll"
            [copy]="true"
            [model]="dataSourceTableItems"
          >
            <div
              class="emp_name customization"
              ngxDraggable
              [model]="element"
              [moves]="true"
              (drag)="builderDrag($event)"
              [dropZones]="['builder-target']"
            >
              {{ element.name }}
            </div>
          </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell employeenodata" colspan="4">
            No data matching the search
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="recipe-dragdrop" [ngClass]="hideField ? 'col-sm-12' : 'col-sm-8'">
    <div id="content" class="builder-target">
      <div class="row">
        <div class="col-sm-6">
          <a
            role="button"
            (click)="addLayoutMulti($event)"
            [style.display]="hideField ? 'none' : 'block'"
          >
            <span
              class="plus_btn items_layout_plus_btn inshadow"
              aria-hidden="true"
            >
              <i class="fa fa-plus"></i
            ></span>
          </a>
        </div>
        <div class="col-sm-6" *ngIf="globalVal">
          <div
            class="form-group"
            [style.display]="hideField ? 'none' : 'block'"
          >
            <button
              class="btn btn-sm float-right submit_button button-none"
              type="submit"
              [disabled]="buttonDisable ? true : null"
              (click)="OnSave(saveId)"
            >
              <img
                src="assets/components/images/save.png"
                alt="employee"
                class=""
              />
              <label class="label_config">Save</label>
            </button>
          </div>
        </div>
      </div>

      <section
        ngxDroppable
        class="items_layout_tablet"
        [model]="targetBuilderTools"
        [removeOnSpill]="false"
      >
        <div
          *ngFor="let item of targetBuilderTools; let i = index"
          ngxDraggable
          [model]="item"
        >
          <div class="row no-gutters">
            <div class="col-sm-2 col column6width">
              <section
                ngxDroppable
                dropZone="builder-target"
                class="drop-container drop_wrapper"
                [model]="item.children"
                [removeOnSpill]="false"
                (drop)="drop(item.children)"
              >
                <div
                  *ngFor="let dropitem of item.children"
                  ngxDraggable
                  [model]="dropitem"
                >
                  <div class="dropzone-placeholder">
                    {{ dropitem.name }}
                    <i
                      (click)="removeJson(item.children, dropitem)"
                      class="fa fa-close deleteItem"
                    ></i>
                  </div>
                </div>
              </section>
            </div>

            <div class="col-sm-2 col column6width">
              <section
                ngxDroppable
                dropZone="builder-target"
                class="drop-container drop_wrapper"
                [model]="item.children1"
                [removeOnSpill]="false"
                (drop)="drop(item.children1, item)"
              >
                <div
                  *ngFor="let dropitem of item.children1"
                  ngxDraggable
                  [model]="dropitem"
                >
                  <div class="dropzone-placeholder">
                    {{ dropitem.name }}
                    <i
                      (click)="removeJson(item.children1, dropitem)"
                      class="fa fa-close deleteItem"
                    ></i>
                  </div>
                </div>
              </section>
            </div>

            <div class="col-sm-2 col column6width">
              <section
                ngxDroppable
                dropZone="builder-target"
                class="drop-container drop_wrapper"
                [model]="item.children2"
                [removeOnSpill]="false"
                (drop)="drop(item.children2, item)"
              >
                <div
                  *ngFor="let dropitem of item.children2"
                  ngxDraggable
                  [model]="dropitem"
                >
                  <div class="dropzone-placeholder">
                    {{ dropitem.name }}
                    <i
                      (click)="removeJson(item.children2, dropitem)"
                      class="fa fa-close deleteItem"
                    ></i>
                  </div>
                </div>
              </section>
            </div>

            <div class="col-sm-2 col column6width">
              <section
                ngxDroppable
                dropZone="builder-target"
                class="drop-container drop_wrapper"
                [model]="item.children3"
                [removeOnSpill]="false"
                (drop)="drop(item.children3, item)"
              >
                <div
                  *ngFor="let dropitem of item.children3"
                  ngxDraggable
                  [model]="dropitem"
                >
                  <div class="dropzone-placeholder">
                    {{ dropitem.name }}
                    <i
                      (click)="removeJson(item.children3, dropitem)"
                      class="fa fa-close deleteItem"
                    ></i>
                  </div>
                </div>
              </section>
            </div>

            <div class="col-sm-2 col column6width">
              <section
                ngxDroppable
                dropZone="builder-target"
                class="drop-container drop_wrapper"
                [model]="item.children4"
                [removeOnSpill]="false"
                (drop)="drop(item.children4, item)"
              >
                <div
                  *ngFor="let dropitem of item.children4"
                  ngxDraggable
                  [model]="dropitem"
                >
                  <div class="dropzone-placeholder">
                    {{ dropitem.name }}
                    <i
                      (click)="removeJson(item.children4, dropitem)"
                      class="fa fa-close deleteItem"
                    ></i>
                  </div>
                </div>
              </section>
            </div>

            <div class="col-sm-1 col" style="margin: auto">
              <i
                (click)="removeJson1(targetBuilderTools, i)"
                class="fa fa-close deleteicon"
              ></i>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
