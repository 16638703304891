import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gift-card-back',
  templateUrl: './app-gift-card-back.component.html',
  styleUrls: ['./app-gift-card-back.component.css']
})
export class AppGiftCardBackComponent implements OnInit {

  @Input() public cardBackTest;
  @Input() public cardNumber;
  businessName: any;
  address: any;
  cityname: any;
  stateCode: any;
  zipCd: any;
  
   
  constructor() { }

  ngOnInit(): void {
  }
  @Input()
  public set DataGiftSlogan(cardBackTest) {
    this.cardBackTest = cardBackTest;
  }
  @Input()
  public set businessNameAssign(bName) {
    this.businessName = bName;
  }
  @Input()
  public set adrressAssign(addr) {
    this.address = addr;
  }
  @Input()
  public set cityName(city) {
    this.cityname = city;
  }
  @Input()
  public set stateCd(state) {
    this.stateCode = state;
  }
  @Input()
  public set zipCode(zip) {
    this.zipCd = zip;
  }
}
