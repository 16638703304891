import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { FormBuilder } from '@angular/forms';
import { ToasterService } from '../helper/toaster.service';
import { IListInfo, ISalesdetails, IHierachydetails } from '../interfaces/iadmin';
import { HelperService } from '../helper/helper.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { MenuService } from '../services/menu.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { browserRefresh } from '../app.component';
import { PushSocketsService } from '../services/push-sockets.service';
import { iFrontEndMsg } from '../interfaces/iWebSocket';
import { DealerComponent } from '../views/hierachy/dealer/dealer.component';
import { ConfirmBoxPopupComponent } from '../elements/confirm-box-popup/confirm-box-popup.component';
import { DialogService } from '../services/dialog.service';
import { FliptableComponent } from '../elements/fliptable/fliptable.component';
import { EmployeeService } from '../services/employee.service';
import { AutotimerService } from '../services/autotimer.service';
import { PermissionService } from '../services/permission.service';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.css'],
})
export class ThemeComponent implements OnInit {
  browserPag: any;
  deal_ID: any;
  comp_ID: any;
  str_ID: any;
  deal_name: any;
  comp_name: any;
  str_name: any;

  public SalesLayoutPermission: any;
  public SalesLayoutPermissionVal: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public tenanttype: any;
  selected_img = false;
  master_img = false;
  menu_img = false;
  home_img = false;
  site_img = false;
  dealers_img = false;
  push_img = false;
  syno_img = false;
  dealerHierarchyHide: boolean = false;
  storeHierarchyHide: boolean = false;
  companyHierarchyHide: boolean = false;
  breakTypeHide: boolean = false;
  dealerList = [];
  companyList = [];
  storeList = [];
  sidebarValue;
  loginInfo: any;
  dealerHeaderName: any;
  companyHeaderName: any;
  storeHeaderName: any;
  UserFname: any;
  UserLname: any;
  rolename: [];
  BusinessPosition: any;
  isvParentName: any;
  headerDeatils: any;
  isvParentData: any;
  isv: any;
  dealer: any;
  company: any;
  store: any;
  dealerPage: boolean = false;
  companyPage: boolean = false;
  storePage: boolean = false;
  EmpPage: boolean = false;
  GeneralPage: boolean = false;
  TimePage: boolean = false;
  BreakPage: boolean = false;
  MenuItemPage: boolean = false;
  MenuSizePage: boolean = false;
  MenuModifierPage: boolean = false;
  SalesAreaPage: boolean = false;
  activeItem: boolean = false;
  RolepermissionPage: boolean = false;
  typeofbusinessPage: boolean = false;
  statefield: boolean = false;
  signal: boolean = false;
  chatPage: boolean = false;
  contractPage: boolean = false;
  emvPage: boolean = false;
  // daypartPage :  boolean = false;
  SalesArea: any;
  SalesGroup: any;
  SalesPages: any;
  CreditCard: boolean;
  CustomPayments: any;
  HouseAccounts: any;
  GiftCard: any;
  device: any;
  tablet: any;
  printer: any;
  receipt: any;
  layoutPage: any;
  socket: any;
  adjustmentPage: any;
  eventPage: any;
  public IsvPage: any;
  public dealerEmp: any;
  public compEmp: any;
  tagsPage: boolean = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  public salesAreaName: any;
  public menuGroupName: any;
  public menuPageName: any;
  Area: any;
  group: any;
  page: any;
  ItemAssignedEmit: any;
  public hierachy: any;
  public buttonDisable: boolean = false;
  deviceName: any;
  tabletName: any;
  emvName: any;
  taxName: any;
  printerName: any;
  report_img: boolean = false;
  report: any;
  salesReconcial: any;
  salesByDaypart: any;
  salesByHour: any;
  taxReport: any;
  discountsReport: any;
  payroll: any;
  printerData: any;
  reasons: any;
  iconClick: any;
  titleClick: any;
  toastrPop: boolean;
  toasterMgs: any;
  jobPosition: any;
  dealerShow: any;
  isvShow: any;
  compShow: any;
  storeShow: any;
  compLogin: any;
  labor: any;
  taxMaster: any;
  creditBatch: any;
  taxhistory: any;
  currencyConfig: any;
  errorMessageFrequency: any;
  OrchestrationSync: any;
  public noSales: any;
  payrollReport: any;
  changedData: boolean;
  //@ViewChild(CompanyTabDirective) tabHost: CompanyTabDirective;
  bsModalRef?: BsModalRef;
  dff: boolean;
  checkdata: boolean;
  uncheckdata: boolean;
  events: string[] = [];
  opened: boolean;
  engine: any;
  safari: boolean;
  public isDisplaymnu: boolean;
  public isCanRemoveTax: boolean;

  @ViewChild(DealerComponent) DealerComp: DealerComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  laborReport: boolean = false;
  giftCardReport: boolean;
  voids: boolean;
  prevoids: boolean;
  refundReport: boolean;

  constructor(
    private modalService: BsModalService,
    private empService: EmployeeService,
    private HieDetails: AdminService,
    private toaster: ToasterService,
    private helper: HelperService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private objMenu: MenuService,
    private observer: BreakpointObserver,
    private obj_push: PushSocketsService,
    private dialogser: DialogService,
    private breakpointObserver: BreakpointObserver,
    private appService: AutotimerService,
    private permser: PermissionService,
  ) { }

  @ViewChild(DataTableDirective)
  public MenuModEmit: any;
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit(): void {
    // this.dealerHeaderName = "";
    // this.companyHeaderName = "";
    // this.storeHeaderName = "";
    //this.dealers_img = true;
    //this.dialogser.ClearSession();
    //this.flipcomp.rowClicked = "transparent";
    //this.router.navigate(['/']);
    //this.router.ngOnDestroy();
    this.dialogser.ClearSession();
    this.browserPag = localStorage.getItem('browserPage');
    this.detectbrowser();
    this.employeeName = 'Welcome to FLiP';
    this.employee = 'FLiP';
    this.employeeIcon = 'assets/components/images/path.png';
    this.dtOptions = { paging: false, info: false };
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.helper.setValue('ISVInfo', this.loginInfo.tenantId); // use in hierachy employee save
    this.UserFname = this.loginInfo.userFname;
    this.UserLname = this.loginInfo.userLname;
    this.rolename = this.loginInfo.employeeRoleList;

    this.SalesLayoutPermission = this.loginInfo.primaryRolePermissionListInfo.filter((x) => x.id == 12);
    this.tenanttype = this.loginInfo.tenantType;
    if (this.SalesLayoutPermission.length != 0) {
      this.SalesLayoutPermissionVal = this.SalesLayoutPermission[0].id;
    }
    this.HieDetails.hierarchydealerTransferValue.subscribe(
      (data) => (this.dealerList = data)
    );
    this.HieDetails.hierarchycompanyTransferValue.subscribe(
      (data) => (this.companyList = data)
    );
    this.HieDetails.hierarchystoreTransferValue.subscribe(
      (data) => (this.storeList = data)
    );
    this.HieDetails.hieHeaderData.subscribe(
      (data) => (this.headerDeatils = data)
    );
    this.HieDetails.hieDealerParentData.subscribe(
      (data) => (this.isvParentData = data)
    );
    this.HieDetails.HierachyValue.subscribe(
      (data) => (this.sidebarValue = data)
    );

    if (this.sidebarValue.isv != undefined && this.sidebarValue.isv != null && this.sidebarValue.isv != '') {
      this.isvParentName = this.sidebarValue.isv.name;
      this.breakTypeHide = true;
      this.isvShow = this.sidebarValue.isv.typeId;
    } else if (this.sidebarValue.dealer != undefined && this.sidebarValue.dealer != null && this.sidebarValue.dealer != '') {
      this.dealerHeaderName = this.sidebarValue.dealer.name;
      this.companyHeaderName = '';
      this.storeHeaderName = '';
      this.dealerHierarchyHide = true;
      this.breakTypeHide = true;
      this.dealerShow = this.sidebarValue.dealer.typeId;

      localStorage.setItem('deal_ID', this.sidebarValue.dealer.id);
      localStorage.setItem('deal_name', this.sidebarValue.dealer.name);
    } else if (
      this.sidebarValue.company != undefined && this.sidebarValue.company != null && this.sidebarValue.company != '') {
      this.dealerHeaderName = this.sidebarValue.company.parentHierarchyInfo[0].name;
      this.companyHeaderName = this.sidebarValue.company.name;
      this.storeHeaderName = '';
      this.dealerHierarchyHide = true;
      this.companyHierarchyHide = true;
      this.breakTypeHide = true;
      this.compShow = this.sidebarValue.company.typeId;

      localStorage.setItem('deal_ID', this.sidebarValue.company.parentHierarchyInfo[0].id);
      localStorage.setItem('deal_name', this.sidebarValue.company.parentHierarchyInfo[0].name);
      localStorage.setItem('comp_ID', this.sidebarValue.company.id);
      localStorage.setItem('comp_name', this.sidebarValue.company.name);
      // this.companyHierarchyHide = this.sidebarValue.companyHierarchyHide;
    } else if (this.sidebarValue.store != undefined && this.sidebarValue.store != null && this.sidebarValue.store != '') {
      this.dealerHeaderName = this.sidebarValue.store.parentHierarchyInfo[1].name;
      this.companyHeaderName = this.sidebarValue.store.parentHierarchyInfo[0].name;
      this.storeHeaderName = this.sidebarValue.store.name;
      this.loginInfo.tenantId = this.sidebarValue.store.id;
      this.dealerHierarchyHide = true;
      this.companyHierarchyHide = true;
      this.storeHierarchyHide = true;
      this.storeShow = this.sidebarValue.store.typeId;

      localStorage.setItem('deal_ID', this.sidebarValue.store.parentHierarchyInfo[1].id);
      localStorage.setItem('deal_name', this.sidebarValue.store.parentHierarchyInfo[1].name);
      localStorage.setItem('comp_ID', this.sidebarValue.store.parentHierarchyInfo[0].id);
      localStorage.setItem('comp_name', this.sidebarValue.store.parentHierarchyInfo[0].name);
      localStorage.setItem('str_ID', this.sidebarValue.store.id);
      localStorage.setItem('str_name', this.sidebarValue.store.name);
    }

    this.getDetailedData(0);
    this.helper.setValue('Companyflag', 0);
    this.helper.setValue('Dealerflag', 0);
    this.helper.setValue('Storeflag', 0);

    this.getRefreshPage();
    this.appService.setUserLoggedIn(true);

    this.isDisplaymnu = this.permser.isBuildMenu;
    this.isCanRemoveTax = this.permser.isCanRemoveTax;
  }
  //browser detect fn for safari css
  detectbrowser() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        this.engine = 'edge'
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        this.engine = 'opr'
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        this.engine = 'chrome'
        return 'chrome';
      case agent.indexOf('trident') > -1:
        this.engine = 'trident'
        return 'ie';
      case agent.indexOf('firefox') > -1:
        this.engine = 'firefox'
        return 'firefox';
      case agent.indexOf('safari') > -1:
        this.engine = 'safari'
        this.safari = true;
        return 'safari';
      default:
        return 'other';
    }
  }
  getRefreshPage() {
    //----------REFRESH STARTS-----------
    if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true") {
      if (this.browserPag == 'this.dealerPage') {
        this.dealerPage = true;
      } else if (this.browserPag == 'this.companyPage') {
        this.companyPage = true;
      } else if (this.browserPag == 'this.storePage') {
        this.storePage = true;
      } else if (this.browserPag == 'this.dealerEmp') {
        this.dealerEmp = true;
      } else if (this.browserPag == 'this.compEmp') {
        this.compEmp = true;
      } else if (this.browserPag == 'this.CustomPayments') {
        this.CustomPayments = true;

      } else if (this.browserPag == 'this.GiftCard') {
        this.GiftCard = true;

      } else if (this.browserPag == 'this.CreditCard') {
        this.CreditCard = true;

      } else if (this.browserPag == 'this.emvPage') {
        this.emvPage = true;

      } else if (this.browserPag == 'this.tablet') {
        this.tablet = true;

      } else if (this.browserPag == 'this.printer') {
        this.printer = true;

      } else if (this.browserPag == 'this.receipt') {
        this.receipt = true;

      } else if (this.browserPag == 'this.tagsPage') {
        this.tagsPage = true;

      } else if (this.browserPag == 'this.reasons') {
        this.reasons = true;

      } else if (this.browserPag == 'this.RolepermissionPage') {
        this.RolepermissionPage = true;

      } else if (this.browserPag == 'this.GeneralPage') {
        this.GeneralPage = true;

      } else if (this.browserPag == 'this.TimePage') {
        this.TimePage = true;

      } else if (this.browserPag == 'this.BreakPage') {
        this.BreakPage = true;

      } else if (this.browserPag == 'this.payroll') {
        this.payroll = true;

      } else if (this.browserPag == 'this.jobPosition') {
        this.jobPosition = true;

      } else if (this.browserPag == 'this.MenuItemPage') {
        this.MenuItemPage = true;

      } else if (this.browserPag == 'this.MenuSizePage') {
        this.MenuSizePage = true;

      } else if (this.browserPag == 'this.MenuModifierPage') {
        this.MenuModifierPage = true;

      } else if (this.browserPag == 'this.SalesAreaPage') {
        this.SalesAreaPage = true;

      } else if (this.browserPag == 'this.layoutPage') {
        this.layoutPage = true;

      } else if (this.browserPag == 'this.adjustmentPage') {
        this.adjustmentPage = true;

      } else if (this.browserPag == 'this.eventPage') {
        this.eventPage = true;

      } else if (this.browserPag == 'this.salesReconcial') {
        this.salesReconcial = true;

      } else if (this.browserPag == 'this.report') {
        this.report = true;

      } else if (this.browserPag == 'this.voids') {
        this.voids = true;

      }else if (this.browserPag == 'this.prevoids') {
        this.prevoids = true;

      } else if (this.browserPag == 'this.salesByDaypart') {
        this.salesByDaypart = true;

      } else if (this.browserPag == 'this.salesByHour') {
        this.salesByHour = true;

      } else if(this.browserPag =='this.laborReport'){
        this.laborReport = true;

      }else if (this.browserPag == 'this.taxReport') {
        this.taxReport = true;        
      } else if (this.browserPag == 'this.giftCardReport') {
        this.giftCardReport = true;

      } else if (this.browserPag == 'this.refundReport') {
        this.refundReport = true;

      }else if (this.browserPag == 'this.discountsReport') {
        this.discountsReport = true;

      } else if (this.browserPag == 'this.creditBatch') {
        this.creditBatch = true;

      } else if (this.browserPag == 'this.laborReport') {
        this.laborReport = true;

      } else if (this.browserPag == 'this.taxhistory') {
        this.taxhistory = true;

      } else if (this.browserPag == 'this.noSales') {
        this.noSales = true;

      } else if (this.browserPag == 'this.payrollReport') {
        this.payrollReport = true;

      } else if (this.browserPag == 'this.IsvPage') {
        this.IsvPage = true;

      } else if (this.browserPag == 'this.typeofbusinessPage') {
        this.typeofbusinessPage = true;

      } else if (this.browserPag == 'this.labor') {
        this.labor = true;

      } else if (this.browserPag == 'this.taxMaster') {
        this.taxMaster = true;

      } else if (this.browserPag == 'this.statefield') {
        this.statefield = true;

      } else if (this.browserPag == 'this.currencyConfig') {
        this.currencyConfig = true;

      } else if (this.browserPag == 'this.errorMessageFrequency') {
        this.errorMessageFrequency = true;

      } else if (this.browserPag == 'this.OrchestrationSync') {
        this.OrchestrationSync = true;
      }

      if (this.browserPag != undefined && this.browserPag != null && this.browserPag != "") {
        this.ShowRefreshPage(this.browserPag);
      }
      else {
        this.ShowRefreshPage('');
      }
    }
    //----------REFRESH ENDS-----------
  }

  ShowRefreshPage(bPag) {
    this.MenuImageSet(bPag);

    if (this.tenanttype == 1) {
      this.deal_ID = localStorage.getItem('deal_ID');
      this.deal_name = localStorage.getItem('deal_name');
      this.comp_ID = localStorage.getItem('comp_ID');
      this.comp_name = localStorage.getItem('comp_name');
      this.str_ID = localStorage.getItem('str_ID');
      this.str_name = localStorage.getItem('str_name');

      if (this.deal_ID != undefined && this.deal_ID != null && this.deal_ID != '') {
        this.dealerHeaderName = this.deal_name;
        this.dealerShow = 2;
      }
      if (this.comp_ID != undefined && this.comp_ID != null && this.comp_ID != '') {
        this.companyHeaderName = this.comp_name;
        this.compShow = 3;
      }
      if (this.str_ID != undefined && this.str_ID != null && this.str_ID != '') {
        this.storeHeaderName = this.str_name;
        this.storeShow = 4;
      }
    }
    else if (this.tenanttype == 2) {
      this.deal_ID = localStorage.getItem('deal_ID');
      this.deal_name = localStorage.getItem('deal_name');

      if (this.deal_ID != undefined && this.deal_ID != null && this.deal_ID != '') {
        this.dealerHeaderName = this.deal_name;
        this.dealerShow = 2;
      }
    }
    else if (this.tenanttype == 3) {
      this.compShow = 3;
      this.deal_ID = localStorage.getItem('deal_ID');
      this.deal_name = localStorage.getItem('deal_name');
      this.comp_ID = localStorage.getItem('comp_ID');
      this.comp_name = localStorage.getItem('comp_name');

      if (this.deal_ID != undefined && this.deal_ID != null && this.deal_ID != '') {
        this.dealerHeaderName = this.deal_name;
      }
      if (this.deal_ID != undefined && this.deal_ID != null && this.deal_ID != '') {
        this.companyHeaderName = this.comp_name;
      }

      if (this.str_ID != undefined && this.str_ID != null && this.str_ID != '') {
        this.storeHeaderName = this.str_name;
        this.storeShow = 4;
      }
    }
    else if (this.tenanttype == 4) {
      this.storeShow = 4;
      this.deal_ID = localStorage.getItem('deal_ID');
      this.deal_name = localStorage.getItem('deal_name');
      this.comp_ID = localStorage.getItem('comp_ID');
      this.comp_name = localStorage.getItem('comp_name');
      this.str_ID = localStorage.getItem('str_ID');
      this.str_name = localStorage.getItem('str_name');

      if (this.deal_ID != undefined && this.deal_ID != null && this.deal_ID != '') {
        this.dealerHeaderName = this.deal_name;
      }

      if (this.comp_ID != undefined && this.comp_ID != null && this.comp_ID != '') {
        this.companyHeaderName = this.comp_name;
      }

      if (this.str_ID != undefined && this.str_ID != null && this.str_ID != '') {
        this.storeHeaderName = this.str_name;
      }
    }
  }

  toggle(nav: MatSidenav) {
    const isSmallScreen =
      this.breakpointObserver.isMatched('(max-width: 574px)');
    if (isSmallScreen) {
      nav.toggle();
    }
  }

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h =>
    h.test(window.location.host)
  );

  ngAfterViewInit() {
    let self = this;
    self.changeDetector.detectChanges();

    this.observer.observe(['(max-width: 574px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onImgChange(e, submenu?, childsubmenu?, L1Name?) {
    if (
      this.helper.getValue('OnDirty') == true ||
      this.helper.getValue('OnDirty2') == true ||
      this.helper.getValue('OnDirty3') == true ||
      this.helper.getValue('OnDirty4') == true
    ) {
      this.changedData = true;
    } else {
      this.changedData = false;
    }

    if (e == 'emps') {
      this.selected_img = true;
      this.home_img = false;
      this.site_img = false;
      this.report_img = false;
      this.dealers_img = false;
      this.menu_img = false;
      this.push_img = false;
      this.dealerPage = false;
      this.companyPage = false;
      this.storePage = false;
      this.MenuSizePage = false;
      this.MenuItemPage = false;
      this.MenuModifierPage = false;
      this.SalesAreaPage = false;
      this.CustomPayments = false;
      this.HouseAccounts = false;
      this.layoutPage = false;
      this.GiftCard = false;
      this.CreditCard = false;
      this.RolepermissionPage = false;
      this.master_img = false;
      this.typeofbusinessPage = false;
      this.statefield = false;
      this.adjustmentPage = false;
      this.eventPage = false;
      this.signal = false;
      this.contractPage = false;
      this.chatPage = false;
      this.socket = false;
      this.report = false;
      this.reasons = false;
      this.emvPage = false;
      this.device = false;
      this.tablet = false;
      this.printer = false;
      this.receipt = false;
      this.tagsPage = false;
      this.titleClick = false;
      this.salesReconcial = false;
      this.salesByDaypart = false;
      this.salesByHour = false;
      this.taxReport = false;
      this.discountsReport = false;
      this.refundReport = false;
      this.giftCardReport = false;
      this.creditBatch = false;
      this.taxhistory = false;
      this.noSales = false;
      this.voids = false;
      this.prevoids = false;
      this.payrollReport = false;
      this.jobPosition = false;
      // this.daypartPage = false
      this.labor = false;
      this.taxMaster = false;
      this.currencyConfig = false;
      this.errorMessageFrequency = false;
      this.OrchestrationSync = false;
      this.syno_img = false;
      this.laborReport = false;

      if (this.loginInfo.tenantId == 0) {
        // this.toastrPop = true;
        // if(this.toastrPop = true){
        //   this.toasterMgs = "Before Selecting " + L1Name,"Please Select in Sequence,  Dealer > Company > Store "
        // }
        // this.toaster.popUp(L1Name, 'hi');
        this.buttonDisable = true;
        this.GeneralPage = false;
        this.TimePage = false;
        this.BreakPage = false;
        this.IsvPage = false;
        this.dealerEmp = false;
        this.compEmp = false;
        this.titleClick = false;
        this.EmpPage = false;
        this.jobPosition = false;
        if (this.changedData == true) {
          // this.openModal("eventPage");
          this.openModal(this.helper.getValue('SessionPageName'), '');
        }
      } else {
        if (submenu == 0) {
          this.GeneralPage = false;
          this.TimePage = false;
          this.BreakPage = false;
          this.IsvPage = false;
          this.dealerEmp = false;
          this.compEmp = false;
          this.titleClick = false;
          this.jobPosition = false;

          // this.changeDetector.detectChanges();
          //this.EmpPage = true;
          if (this.changedData == true) {
            //this.openModal("GeneralPage");
          } else {
            this.EmpPage = true;
          }
        }
        if (submenu == 1) {
          this.EmpPage = false;
          this.TimePage = false;
          this.BreakPage = false;
          this.IsvPage = false;
          this.dealerEmp = false;
          this.compEmp = false;
          this.payroll = false;
          this.titleClick = false;
          this.jobPosition = false;

          // this.changeDetector.detectChanges();
          //this.GeneralPage = true;
          if (this.changedData == true) {
            //  this.openModal("GeneralPage");
            this.openModal(this.helper.getValue('SessionPageName'), 'GeneralPage');
          } else {
            this.GeneralPage = true;
          }
        } else if (submenu == 2) {
          this.EmpPage = false;
          this.GeneralPage = false;
          this.BreakPage = false;
          this.IsvPage = false;
          this.dealerEmp = false;
          this.compEmp = false;
          this.payroll = false;
          this.titleClick = false;
          this.jobPosition = false;

          //this.changeDetector.detectChanges();
          //this.TimePage = true;
          if (this.changedData == true) {
            //  this.openModal("TimePage");
            this.openModal(this.helper.getValue('SessionPageName'), 'TimePage');
          } else {
            this.TimePage = true;
          }
        } else if (submenu == 3) {
          this.EmpPage = false;
          this.GeneralPage = false;
          this.TimePage = false;
          this.IsvPage = false;
          this.dealerEmp = false;
          this.compEmp = false;
          this.payroll = false;
          this.titleClick = false;
          this.jobPosition = false;

          //this.changeDetector.detectChanges();
          //this.BreakPage = true;
          if (this.changedData == true) {
            // this.openModal("BreakPage");
            this.openModal(this.helper.getValue('SessionPageName'), 'BreakPage');
          } else {
            this.BreakPage = true;
          }
        } else if (submenu == 4) {
          this.EmpPage = false;
          this.GeneralPage = false;
          this.TimePage = false;
          this.IsvPage = false;
          this.dealerEmp = false;
          this.compEmp = false;
          this.BreakPage = false;
          this.titleClick = false;
          this.jobPosition = false;

          // this.changeDetector.detectChanges();
          //this.payroll = true;
          if (this.changedData == true) {
            // this.openModal("payroll");
            this.openModal(this.helper.getValue('SessionPageName'), 'payroll');
          } else {
            this.payroll = true;
          }
        } else if (submenu == 8) {
          this.EmpPage = false;
          this.GeneralPage = false;
          this.TimePage = false;
          this.IsvPage = false;
          this.dealerEmp = false;
          this.compEmp = false;
          this.BreakPage = false;
          this.titleClick = false;
          this.payroll = false;
          // this.changeDetector.detectChanges();
          //this.jobPosition = true
          if (this.changedData == true) {
            // this.openModal("jobPosition");
            this.openModal(this.helper.getValue('SessionPageName'), 'jobPosition');
          } else {
            this.jobPosition = true;
          }
        } else {
          if (this.changedData == true) {
            // this.openModal("eventPage");
            this.openModal(this.helper.getValue('SessionPageName'), '');
          }
        }
      }
    }
    if (e == 'store') {
      this.menu_img = true;
      this.selected_img = false;
      this.push_img = false;
      this.site_img = false;
      this.dealers_img = false;
      this.home_img = false;
      this.report_img = false;
      this.RolepermissionPage = false;
      this.master_img = false;
      this.typeofbusinessPage = false;
      this.statefield = false;
      this.adjustmentPage = false;
      this.eventPage = false;
      this.signal = false;
      this.chatPage = false;
      this.socket = false;
      this.IsvPage = false;
      this.dealerEmp = false;
      this.compEmp = false;
      this.report = false;
      this.payroll = false;
      this.titleClick = false;
      this.contractPage = false;
      this.dealerPage = false;
      this.companyPage = false;
      this.EmpPage = false;
      this.GeneralPage = false;
      this.TimePage = false;
      this.BreakPage = false;
      this.MenuSizePage = false;
      this.MenuItemPage = false;
      this.MenuModifierPage = false;
      this.SalesAreaPage = false;
      this.activeItem = false;
      this.layoutPage = false;
      this.printer = false;
      this.receipt = false;
      this.salesReconcial = false;
      this.salesByDaypart = false;
      this.salesByHour = false;
      this.taxReport = false;
      this.discountsReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.creditBatch = false;
      this.taxhistory = false;
      this.voids = false;
      this.prevoids = false;
      this.noSales = false;
      this.payrollReport = false;
      // this.tagsPage = false;
      // this.getTitleName()
      // this.getTitle()
      this.tablet = false;
      this.reasons = false;
      this.jobPosition = false;
      // this.daypartPage = false
      this.labor = false;
      this.taxMaster = false;
      this.currencyConfig = false;
      this.errorMessageFrequency = false;
      this.OrchestrationSync = false;
      this.syno_img = false;
      this.laborReport = false;


      // this.GiftCard = false;
      // this.HouseAccounts = false;
      // this.CustomPayments = false;
      // this.CreditCard = false;
      // this.emvPage = false
      // this.tagsPage = false;
      // this.device =  false
      // this.changeDetector.detectChanges();
      // this.storePage = true;
      if (this.compShow != 3) {
        if (this.changedData == true) {
          this.openModal(this.helper.getValue('SessionPageName'), 'storePage');
        } else {
          this.toaster.hierarchyPopUp('Please Select Company Before Selecting Store');
        }
      } else {
        if (submenu == 1) {
          this.tablet = false;
          this.reasons = false;
          this.GiftCard = false;
          this.HouseAccounts = false;
          this.CustomPayments = false;
          this.CreditCard = false;
          this.emvPage = false;
          this.tagsPage = false;
          this.device = false;
          this.titleClick = false;
          //this.changeDetector.detectChanges();
          //this.storePage = true;
          // this.daypartPage = false

          if (this.changedData == true) {
            // this.openModal("storePage")
            this.openModal(this.helper.getValue('SessionPageName'), 'storePage');
          } else {
            this.storePage = true;
          }
        } else {
          if (this.loginInfo.tenantId == 0) {
            // this.toaster.error("Before Selecting " + L1Name,"Please Select in Sequence,  Dealer > Company > Store ");
            this.buttonDisable = true;
            this.storePage = false;
            this.emvPage = false;
            this.tablet = false;
            this.printer = false;
            this.receipt = false;
            this.tagsPage = false;
            this.device = false;
            this.reasons = false;
            this.titleClick = false;
            this.GiftCard = false;
            this.HouseAccounts = false;
            this.CreditCard = false;
            this.titleClick = false;
            this.CustomPayments = false;
            // this.daypartPage = false
          } else {
            if (submenu == 2) {
              this.storePage = false;
              this.emvPage = false;
              this.tablet = false;
              this.printer = false;
              this.receipt = false;
              this.tagsPage = false;
              this.device = false;
              this.reasons = false;
              this.titleClick = false;
              // this.daypartPage = false
              //  this.changeDetector.detectChanges()

              if (childsubmenu == 1) {
                this.GiftCard = false;
                this.HouseAccounts = false;
                this.CreditCard = false;
                this.titleClick = false;
                // this.changeDetector.detectChanges();
                //this.CustomPayments = true;

                if (this.changedData == true) {
                  //   this.openModal("CustomPayments")
                  this.openModal(this.helper.getValue('SessionPageName'), 'CustomPayments');
                } else {
                  this.CustomPayments = true;
                }
              } else if (childsubmenu == 2) {
                this.GiftCard = false;
                this.CustomPayments = false;
                this.CreditCard = false;
                this.titleClick = false;
                //this.changeDetector.detectChanges();
                //this.HouseAccounts = true;
                if (this.changedData == true) {
                  // this.openModal("HouseAccounts")
                  this.openModal(this.helper.getValue('SessionPageName'), 'HouseAccounts');
                } else {
                  this.HouseAccounts = true;
                }
              } else if (childsubmenu == 3) {
                this.CustomPayments = false;
                this.HouseAccounts = false;
                this.CreditCard = false;
                this.titleClick = false;
                //  this.changeDetector.detectChanges();
                //this.GiftCard = true;
                if (this.changedData == true) {
                  //  this.openModal("GiftCard")
                  this.openModal(this.helper.getValue('SessionPageName'), 'GiftCard');
                } else {
                  this.GiftCard = true;
                }
              } else if (childsubmenu == 4) {
                this.CustomPayments = false;
                this.HouseAccounts = false;
                this.GiftCard = false;
                this.titleClick = false;
                //  this.changeDetector.detectChanges();
                //this.CreditCard = true;
                if (this.changedData == true) {
                  //this.openModal("CreditCard")
                  this.openModal(this.helper.getValue('SessionPageName'), 'CreditCard');
                } else {
                  this.CreditCard = true;
                }
              } else {
                if (this.changedData == true) {
                  //this.openModal("CreditCard")
                  this.openModal(this.helper.getValue('SessionPageName'), '');
                }
              }
            } else if (submenu == 3) {
              this.storePage = false;
              this.GiftCard = false;
              this.HouseAccounts = false;
              this.CreditCard = false;
              this.CustomPayments = false;
              this.tagsPage = false;
              this.reasons = false;
              // this.daypartPage = false
              this.titleClick = false;
              // this.changeDetector.detectChanges()
              this.device = true;
              if (childsubmenu == 1) {
                this.tablet = false;
                this.printer = false;
                this.receipt = false;
                this.titleClick = false;
                //  this.changeDetector.detectChanges()
                //this.emvPage = true
                if (this.changedData == true) {
                  // this.openModal("emvPage");
                  this.openModal(this.helper.getValue('SessionPageName'), 'emvPage');
                } else {
                  this.emvPage = true;
                }
              } else if (childsubmenu == 2) {
                this.emvPage = false;
                this.printer = false;
                this.receipt = false;
                this.titleClick = false;
                //  this.changeDetector.detectChanges()
                //this.tablet = true
                if (this.changedData == true) {
                  //  this.openModal("tablet");
                  this.openModal(this.helper.getValue('SessionPageName'), 'tablet');
                } else {
                  this.tablet = true;
                }
              } else if (childsubmenu == 3) {
                this.emvPage = false;
                this.tablet = false;
                this.receipt = false;
                this.titleClick = false;
                //  this.changeDetector.detectChanges()
                //this.printer = true
                if (this.changedData == true) {
                  //  this.openModal("printer");
                  this.openModal(this.helper.getValue('SessionPageName'), 'printer');
                } else {
                  this.printer = true;
                }
              } else if (childsubmenu == 4) {
                this.emvPage = false;
                this.tablet = false;
                this.printer = false;
                this.titleClick = false;
                // this.changeDetector.detectChanges()
                //this.receipt = true;
                if (this.changedData == true) {
                  // this.openModal("receipt");
                  this.openModal(this.helper.getValue('SessionPageName'), 'receipt');
                } else {
                  this.receipt = true;
                }
              } else {
                if (this.changedData == true) {
                  //this.openModal("CreditCard")
                  this.openModal(this.helper.getValue('SessionPageName'), '');
                }
              }
            } else if (submenu == 4) {
              this.tablet = false;
              this.storePage = false;
              this.GiftCard = false;
              this.HouseAccounts = false;
              this.CustomPayments = false;
              this.CreditCard = false;
              this.emvPage = false;
              this.printer = false;
              this.receipt = false;
              this.reasons = false;
              // this.daypartPage = false
              this.titleClick = false;
              //   this.changeDetector.detectChanges();
              //this.tagsPage = true
              this.device = false;
              if (this.changedData == true) {
                //   this.openModal("tagsPage");
                this.openModal(
                  this.helper.getValue('SessionPageName'),
                  'tagsPage'
                );
              } else {
                this.tagsPage = true;
              }
            } else if (submenu == 5) {
              this.tablet = false;
              this.storePage = false;
              this.GiftCard = false;
              this.HouseAccounts = false;
              this.CustomPayments = false;
              this.CreditCard = false;
              this.emvPage = false;
              this.printer = false;
              this.receipt = false;
              this.tagsPage = false;
              this.device = false;
              // this.daypartPage = false
              // this.changeDetector.detectChanges();
              this.reasons = true;
              if (this.changedData == true) {
                // this.openModal("reasons");
                this.openModal(
                  this.helper.getValue('SessionPageName'),
                  'reasons'
                );
              } else {
                this.reasons = true;
              }
            } else if (submenu == 6) {
              this.tablet = false;
              this.storePage = false;
              this.GiftCard = false;
              this.HouseAccounts = false;
              this.CustomPayments = false;
              this.CreditCard = false;
              this.emvPage = false;
              this.printer = false;
              this.receipt = false;
              this.tagsPage = false;
              this.device = false;
              this.reasons = false;
              this.changeDetector.detectChanges();
              // this.daypartPage = true
            }
          }
        }
      }

      // if(submenu == 3){
      //   this.storePage = false;
      //   this.GiftCard = false;
      //   this.HouseAccounts = false;
      //   this.CreditCard = false;
      //   this.CustomPayments =false;
      //  this.changeDetector.detectChanges()
      // }
      // if(submenu == 8){
      //   this.changeDetector.detectChanges()
      //   this.emvPage = true
      // }
    }
    if (e == 'company') {
      this.site_img = true;
      this.menu_img = false;
      this.push_img = false;
      this.home_img = false;
      this.report_img = false;
      this.selected_img = false;
      this.dealers_img = false;
      this.RolepermissionPage = false;
      this.adjustmentPage = false;
      this.eventPage = false;
      this.IsvPage = false;
      this.dealerEmp = false;
      this.compEmp = false;
      this.report = false;
      this.salesReconcial = false;
      this.salesByDaypart = false;
      this.salesByHour = false;
      this.taxReport = false;
      this.discountsReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.creditBatch = false;
      this.taxhistory = false;
      this.noSales = false;
      this.voids = false;
      this.prevoids = false;
      this.payrollReport = false;
      this.payroll = false;
      this.reasons = false;
      this.dealerPage = false;
      this.storePage = false;
      this.EmpPage = false;
      this.GeneralPage = false;
      this.TimePage = false;
      this.BreakPage = false;
      this.MenuSizePage = false;
      this.MenuModifierPage = false;
      this.SalesAreaPage = false;
      this.MenuItemPage = false;
      this.CustomPayments = false;
      this.HouseAccounts = false;
      this.layoutPage = false;
      this.CreditCard = false;
      this.master_img = false;
      this.typeofbusinessPage = false;
      this.statefield = false;
      this.signal = false;
      this.chatPage = false;
      this.socket = false;
      this.titleClick = false;
      this.contractPage = false;
      this.emvPage = false;
      this.activeItem = false;
      this.device = false;
      this.tablet = false;
      this.printer = false;
      this.receipt = false;
      this.GiftCard = false;
      this.jobPosition = false;
      // this.daypartPage = false
      this.labor = false;
      this.taxMaster = false;
      this.currencyConfig = false;
      this.errorMessageFrequency = false;
      this.OrchestrationSync = false;
      this.tagsPage = false;
      this.syno_img = false;
      this.laborReport = false;


      if (this.dealerShow != 2) {
        if (this.changedData == true) {
          this.openModal(
            this.helper.getValue('SessionPageName'),
            'companyPage'
          );
        } else {
          this.toaster.hierarchyPopUp(
            'Please Select Dealer Before Selecting Company1'
          );
        }
      } else {
        if (submenu == 1) {
          this.compEmp = false;
          this.activeItem = false;
          this.dealerPage = false;
          this.dealers_img = false;
          if (this.changedData == true) {
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'companyPage'
            );
          } else {
            this.companyPage = true;
          }
        }
        if (submenu == 2) {
          this.companyPage = false;
          this.activeItem = false;
          //  this.changeDetector.detectChanges();

          this.buttonDisable = false;
          if (this.changedData == true) {
            // this.openModal("compEmp")
            this.openModal(this.helper.getValue('SessionPageName'), 'compEmp');
          } else {
            this.compEmp = true;
          }
        }
      }
    }
    if (e == 'dealers') {
      this.dealers_img = true;
      this.site_img = false;
      this.home_img = false;
      this.report_img = false;
      this.selected_img = false;
      this.menu_img = false;
      this.push_img = false;
      this.master_img = false;
      this.adjustmentPage = false;
      this.eventPage = false;
      this.device = false;
      this.tablet = false;
      this.companyPage = false;
      this.storePage = false;
      this.EmpPage = false;
      this.GeneralPage = false;
      this.TimePage = false;
      this.CreditCard = false;
      this.RolepermissionPage = false;
      this.typeofbusinessPage = false;
      this.statefield = false;
      this.signal = false;
      this.chatPage = false;
      this.socket = false;
      this.IsvPage = false;
      this.dealerEmp = false;
      this.compEmp = false;
      this.report = false;
      this.salesReconcial = false;
      this.salesByDaypart = false;
      this.salesByHour = false;
      this.taxReport = false;
      this.discountsReport = false;
      this.creditBatch = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.taxhistory = false;
      this.noSales = false;
      this.voids = false;
      this.prevoids = false;
      this.payrollReport = false;
      this.payroll = false;
      this.reasons = false;
      this.emvPage = false;
      this.contractPage = false;
      this.BreakPage = false;
      this.MenuSizePage = false;
      this.MenuItemPage = false;
      this.MenuModifierPage = false;
      this.SalesAreaPage = false;
      this.CustomPayments = false;
      this.HouseAccounts = false;
      this.layoutPage = false;
      this.GiftCard = false;
      this.activeItem = false;
      this.printer = false;
      this.receipt = false;
      this.titleClick = false;
      this.jobPosition = false;
      this.tagsPage = false;
      this.labor = false;
      this.taxMaster = false;
      this.currencyConfig = false;
      this.errorMessageFrequency = false;
      this.OrchestrationSync = false;
      this.syno_img = false;
      this.laborReport = false;


      //  this.dealerPage = true;
      //this.changeDetector.detectChanges();

      if (submenu == 1) {
        this.dealerEmp = false;
        // this.changeDetector.detectChanges();
        this.activeItem = true;
        if (this.changedData == true) {
          //this.openModal("dealerPage");
          // this.dealerPage = false;
          this.openModal(this.helper.getValue('SessionPageName'), 'dealerPage');
        } else {
          this.dealerPage = true;
        }
      }
      if (submenu == 2) {
        // this.EmpPage = false;
        // this.GeneralPage = false;
        // this.TimePage = false;
        // this.BreakPage = false;
        // this.IsvPage = false;
        // this.compEmp = false
        // this.payroll = false;
        // this.titleClick = false
        // this.jobPosition = false
        this.dealerPage = false;
        this.activeItem = false;
        // this.changeDetector.detectChanges();

        this.buttonDisable = false;
        if (this.changedData == true) {
          // this.openModal("dealerEmp");
          this.openModal(this.helper.getValue('SessionPageName'), 'dealerEmp');

          this.dealerEmp = false;
        } else {
          this.dealerEmp = true;
        }
      }
    }
    if (e == 'menu') {
      this.home_img = true;
      this.report_img = false;
      this.dealers_img = false;
      this.site_img = false;
      this.selected_img = false;
      this.menu_img = false;
      this.push_img = false;
      this.dealerPage = false;
      this.companyPage = false;
      this.storePage = false;
      this.EmpPage = false;
      this.GeneralPage = false;
      this.TimePage = false;
      this.BreakPage = false;
      this.CustomPayments = false;
      this.HouseAccounts = false;
      this.GiftCard = false;
      this.CreditCard = false;
      this.RolepermissionPage = false;
      this.master_img = false;
      this.typeofbusinessPage = false;
      this.statefield = false;
      this.signal = false;
      this.chatPage = false;
      this.socket = false;
      this.IsvPage = false;
      this.dealerEmp = false;
      this.compEmp = false;
      this.report = false;
      this.salesReconcial = false;
      this.salesByDaypart = false;
      this.salesByHour = false;
      this.taxReport = false;
      this.discountsReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.creditBatch = false;
      this.taxhistory = false;
      this.noSales = false;
      this.voids = false;
      this.prevoids = false;
      this.payrollReport = false;
      this.payroll = false;
      this.reasons = false;
      this.emvPage = false;
      this.device = false;
      this.tablet = false;
      this.printer = false;
      this.receipt = false;
      this.tagsPage = false;
      this.contractPage = false;
      this.titleClick = false;
      this.jobPosition = false;
      // this.daypartPage = false
      this.labor = false;
      this.taxMaster = false;
      this.currencyConfig = false;
      this.errorMessageFrequency = false;
      this.OrchestrationSync = false;
      this.syno_img = false;
      this.laborReport = false;

      if (this.loginInfo.tenantId == 0) {
        if (this.changedData == true) {
          // this.openModal("eventPage");
          this.openModal(this.helper.getValue('SessionPageName'), '');
        }
        //   this.toaster.popUp(L1Name, 'hi');
        // this.toaster.error("Before Selecting " + L1Name,"Please Select in Sequence,  Dealer > Company > Store ");
        this.buttonDisable = true;
      } else {
        if (submenu == 1) {
          this.MenuModifierPage = false;
          this.MenuItemPage = false;
          this.SalesAreaPage = false;
          this.layoutPage = false;
          this.adjustmentPage = false;
          this.eventPage = false;
          this.titleClick = false;
          //this.changeDetector.detectChanges();
          //this.MenuSizePage = true;
          if (this.changedData == true) {
            //  this.openModal("MenuSizePage");
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'MenuSizePage'
            );
          } else {
            this.MenuSizePage = true;
          }
        } else if (submenu == 2) {
          this.MenuModifierPage = false;
          this.MenuSizePage = false;
          this.SalesAreaPage = false;
          this.layoutPage = false;
          this.adjustmentPage = false;
          this.eventPage = false;
          this.titleClick = false;
          // this.changeDetector.detectChanges();
          //this.MenuItemPage = true;
          this.MenuModEmit = this.MenuItemPage;

          if (this.changedData == true) {
            // this.openModal("MenuItemPage");
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'MenuItemPage'
            );
          } else {
            this.MenuItemPage = true;
            this.MenuModEmit = this.MenuItemPage;
          }
        } else if (submenu == 3) {
          this.MenuSizePage = false;
          this.MenuItemPage = false;
          this.SalesAreaPage = false;
          this.layoutPage = false;
          this.adjustmentPage = false;
          this.eventPage = false;
          this.titleClick = false;
          // this.changeDetector.detectChanges();
          //this.MenuModifierPage = true;
          this.ItemAssignedEmit = this.MenuModifierPage;
          if (this.changedData == true) {
            //    this.openModal("MenuModifierPage");
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'MenuModifierPage'
            );
          } else {
            this.MenuModifierPage = true;
            this.ItemAssignedEmit = this.MenuModifierPage;
          }
        } else if (submenu == 4) {
          this.MenuSizePage = false;
          this.MenuItemPage = false;
          this.MenuModifierPage = false;
          this.layoutPage = false;
          this.adjustmentPage = false;
          this.eventPage = false;
          this.titleClick = false;
          //this.changeDetector.detectChanges();
          //this.SalesAreaPage = true;

          if (this.changedData == true) {
            // this.openModal("SalesAreaPage");
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'SalesAreaPage'
            );
          } else {
            this.SalesAreaPage = true;
          }
        } else if (submenu == 5) {
          this.MenuSizePage = false;
          this.MenuItemPage = false;
          this.MenuModifierPage = false;
          this.SalesAreaPage = false;
          this.adjustmentPage = false;
          this.eventPage = false;
          this.titleClick = false;
          //this.changeDetector.detectChanges();
          //this.layoutPage = true;

          if (this.changedData == true) {
            //  this.openModal("layoutPage");
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'layoutPage'
            );
          } else {
            this.layoutPage = true;
          }
        } else if (submenu == 6) {
          this.MenuSizePage = false;
          this.MenuItemPage = false;
          this.MenuModifierPage = false;
          this.SalesAreaPage = false;
          this.layoutPage = false;
          this.eventPage = false;
          this.titleClick = false;
          //  this.changeDetector.detectChanges();
          //this.adjustmentPage = true

          if (this.changedData == true) {
            // this.openModal("adjustmentPage");
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'adjustmentPage'
            );
          } else {
            this.adjustmentPage = true;
          }
        } else if (submenu == 7) {
          this.MenuSizePage = false;
          this.MenuItemPage = false;
          this.MenuModifierPage = false;
          this.SalesAreaPage = false;
          this.layoutPage = false;
          this.adjustmentPage = false;
          this.titleClick = false;
          //  this.changeDetector.detectChanges();
          //this.eventPage = true

          if (this.changedData == true) {
            // this.openModal("eventPage");
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'eventPage'
            );
          } else {
            this.eventPage = true;
          }
        } else {
          if (this.changedData == true) {
            // this.openModal("eventPage");
            this.openModal(this.helper.getValue('SessionPageName'), '');
          }
        }
      }
    }
    if (e == 'report') {
      this.report_img = true;
      this.selected_img = false;
      this.home_img = false;
      this.site_img = false;
      this.dealers_img = false;
      this.menu_img = false;
      this.push_img = false;
      this.dealerPage = false;
      this.companyPage = false;
      this.storePage = false;
      this.MenuSizePage = false;
      this.MenuItemPage = false;
      this.MenuModifierPage = false;
      this.SalesAreaPage = false;
      this.CustomPayments = false;
      this.HouseAccounts = false;
      this.layoutPage = false;
      this.GiftCard = false;
      this.CreditCard = false;
      this.RolepermissionPage = false;
      this.master_img = false;
      this.typeofbusinessPage = false;
      this.statefield = false;
      this.adjustmentPage = false;
      this.eventPage = false;
      this.signal = false;
      this.contractPage = false;
      this.chatPage = false;
      this.socket = false;
      this.emvPage = false;
      this.device = false;
      this.tablet = false;
      this.printer = false;
      this.receipt = false;
      this.GeneralPage = false;
      this.TimePage = false;
      this.BreakPage = false;
      this.IsvPage = false;
      this.dealerEmp = false;
      this.compEmp = false;
      this.EmpPage = false;
      this.tagsPage = false;
      this.payroll = false;
      this.reasons = false;
      this.titleClick = false;
      this.jobPosition = false;
      // this.daypartPage = false
      this.labor = false;
      this.taxMaster = false;
      this.currencyConfig = false;
      this.errorMessageFrequency = false;
      this.OrchestrationSync = false;
      this.syno_img = false;
      this.laborReport = false;


      if (this.loginInfo.tenantId == 0) {
        if (this.changedData == true) {
          // this.openModal("eventPage");
          this.openModal(this.helper.getValue('SessionPageName'), '');
        }
        // this.toaster.error("Before Selecting " + L1Name,"Please Select in Sequence,  Dealer > Company > Store ");
        this.buttonDisable = true;
      } else {
        if (submenu == 1) {
          this.salesReconcial = false;
          this.salesByDaypart = false;
          this.salesByHour = false;
          this.taxReport = false;
          this.discountsReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.titleClick = false;
          this.creditBatch = false;
          this.taxhistory = false;
          this.noSales = false;
          this.voids = false;
          this.prevoids = false;
          this.payrollReport = false;
          //   this.changeDetector.detectChanges();
          // this.report = true;
          if (childsubmenu == 1) {
            this.salesReconcial = false;
            this.salesByDaypart = false;
            this.salesByHour = false;
            this.taxReport = false;
            this.discountsReport = false;
            this.refundReport = false;
            this.giftCardReport = false;
      //  this.changeDetector.detectChanges();

            if (this.changedData == true) {
              this.openModal(this.helper.getValue('SessionPageName'), 'report');
            } else {
              this.report = true;
            }
          }
          if (childsubmenu == 2) {
            this.report = false;
            this.salesByDaypart = false;
            this.salesByHour = false;
            this.taxReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.discountsReport = false;
            // this.changeDetector.detectChanges();
            if (this.changedData == true) {
              // this.openModal("salesReconcial");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'salesReconcial'
              );
            } else {
              this.salesReconcial = true;
            }
          }
          if (childsubmenu == 3) {
            this.report = false;
            this.salesReconcial = false;
            this.taxReport = false;
            this.discountsReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.salesByHour = false;
            //  this.changeDetector.detectChanges();
            if (this.changedData == true) {
              // this.openModal("salesByDaypart");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'salesByDaypart'
              );
            } else {
              this.salesByDaypart = true;
            }
          }
          if (childsubmenu == 4) {
            this.report = false;
            this.salesReconcial = false;
            this.salesByDaypart = false;
            this.taxReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.discountsReport = false;
            // this.changeDetector.detectChanges();
            if (this.changedData == true) {
              //this.openModal("salesByHour");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'salesByHour'
              );
            } else {
              this.salesByHour = true;
            }
          }
          if (childsubmenu == 5) {
            this.report = false;
            this.salesReconcial = false;
            this.salesByDaypart = false;
            this.salesByHour = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.discountsReport = false;
            //  this.changeDetector.detectChanges();
            if (this.changedData == true) {
              //  this.openModal("taxReport");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'taxReport'
              );
            } else {
              this.taxReport = true;
            }
          }
          if (childsubmenu == 6) {
            this.report = false;
            this.salesReconcial = false;
            this.salesByDaypart = false;
            this.salesByHour = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.taxReport = false;
            //  this.changeDetector.detectChanges();
            if (this.changedData == true) {
              //  this.openModal("discountsReport");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'discountsReport'
              );
            } else {
              this.discountsReport = true;
            }
          }if (childsubmenu == 7) {
            this.report = false;
            this.salesReconcial = false;
            this.salesByDaypart = false;
            this.salesByHour = false;
            this.taxReport = false;
            this.discountsReport = false;
      this.refundReport = false;
      //  this.changeDetector.detectChanges();
            if (this.changedData == true) {
              //  this.openModal("discountsReport");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'giftCardReport'
              );
            } else {
              this.giftCardReport = true;
            }
          }
          if (childsubmenu == 8) {
            this.report = false;
            this.salesReconcial = false;
            this.salesByDaypart = false;
            this.salesByHour = false;
            this.taxReport = false;
            this.giftCardReport = false;
            this.discountsReport = false;
            //  this.changeDetector.detectChanges();
            if (this.changedData == true) {
              //  this.openModal("discountsReport");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'refundReport'
              );
            } else {
      this.refundReport = true;
            }
          }
        } else if (submenu == 2) {
          this.report = false;
          this.salesReconcial = false;
          this.salesByDaypart = false;
          this.salesByHour = false;
          this.taxReport = false;
          this.discountsReport = false;
          this.refundReport = false;
          this.giftCardReport = false;
      this.noSales = false;
      this.voids = false;
      this.prevoids = false;
      this.payrollReport = false;
          this.titleClick = false;
          //   this.changeDetector.detectChanges();
          if (childsubmenu == 1) {
            
            this.taxhistory = false;
            if (this.changedData == true) {
              //    this.openModal("creditBatch");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'creditBatch'
              );
            } else {
              this.creditBatch = true;
            }
          }
          if (childsubmenu == 2) {
            this.creditBatch = false;
            if (this.changedData == true) {
              //   this.openModal("taxhistory");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'taxhistory'
              );
            } else {
              this.taxhistory = true;
            }
          }
        } else if (submenu == 3) {
          this.report = false;
          this.salesReconcial = false;
          this.salesByDaypart = false;
          this.salesByHour = false;
          this.taxReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.discountsReport = false;
          this.creditBatch = false;
          this.taxhistory = false;
          this.payrollReport = false;
      this.voids = false;
      this.prevoids = false;
      this.titleClick = false;
          //  this.changeDetector.detectChanges();
          if (childsubmenu == 1) {
            this.voids = false;
            this.prevoids = false;
            if (this.changedData == true) {
              //   this.openModal("noSales");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'noSales'
              );
            } else {
              this.noSales = true;
            }
          }
          if (childsubmenu == 2) {
            this.noSales = false;
            this.prevoids = false;
            if (this.changedData == true) {
              //   this.openModal("noSales");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'voids'
              );
            } else {
              this.voids = true;
            }
          }
          if (childsubmenu == 3) {
            this.noSales = false;
            this.voids = false;
            if (this.changedData == true) {
              this.noSales = false;
              //   this.openModal("noSales");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'prevoids'
              );
            } else {
              this.prevoids = true;
            }
          }
        } else if (submenu == 4) {
          this.report = false;
          this.salesReconcial = false;
          this.salesByDaypart = false;
          this.salesByHour = false;
          this.taxReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.discountsReport = false;
          this.creditBatch = false;
          this.taxhistory = false;
          this.titleClick = false;
          this.noSales = false;
      this.voids = false;
      this.prevoids = false;
      this.creditBatch = false;
          //  this.changeDetector.detectChanges();
          if (childsubmenu == 1) {
            if (this.changedData == true) {
              //  this.openModal("payrollReport");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'payrollReport'
              );
            } else {
              this.payrollReport = true;
            }
          }
          if (childsubmenu == 2) {
            this.report = false;
            this.salesReconcial = false;
            this.salesByDaypart = false;
            this.salesByHour = false;
            this.taxReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.discountsReport = false;
            this.creditBatch = false;
            this.taxhistory = false;
            this.titleClick = false;
            this.noSales = false;
      this.voids = false;
      this.prevoids = false;
      this.creditBatch = false;
            this.payrollReport = false;
            if (this.changedData == true) {
              //    this.openModal("creditBatch");
              this.openModal(
                this.helper.getValue('SessionPageName'),
                'laborReport'
              );
            } else {
              this.laborReport = true;
            }
          }
        } else {
          if (this.changedData == true) {
            // this.openModal("eventPage");
            this.openModal(this.helper.getValue('SessionPageName'), '');
          }
        }
      }
    }
    if (e == 'sync') {
      this.syno_img = true;
      this.master_img = false;
      this.home_img = false;
      this.report_img = false;
      this.report = false;
      this.site_img = false;
      this.dealers_img = false;
      this.menu_img = false;
      this.push_img = false;
      this.dealerPage = false;
      this.companyPage = false;
      this.storePage = false;
      this.MenuSizePage = false;
      this.MenuItemPage = false;
      this.MenuModifierPage = false;
      this.SalesAreaPage = false;
      this.CustomPayments = false;
      this.HouseAccounts = false;
      this.layoutPage = false;
      this.GiftCard = false;
      this.CreditCard = false;
      this.EmpPage = false;
      this.GeneralPage = false;
      this.selected_img = false;
      this.adjustmentPage = false;
      this.eventPage = false;
      this.TimePage = false;
      this.emvPage = false;
      this.device = false;
      this.tablet = false;
      this.printer = false;
      this.receipt = false;
      this.IsvPage = false;
      this.dealerEmp = false;
      this.compEmp = false;
      this.tagsPage = false;
      this.payroll = false;
      this.reasons = false;
      this.titleClick = false;
      this.salesReconcial = false;
      this.salesByDaypart = false;
      this.salesByHour = false;
      this.taxReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.discountsReport = false;
      this.creditBatch = false;
      this.taxhistory = false;
      this.jobPosition = false;
      this.labor = false;
      this.taxMaster = false;
      this.laborReport = false;

      this.RolepermissionPage = false;

      if (submenu == 1) {
        this.currencyConfig = false;
        this.errorMessageFrequency = false;
        this.OrchestrationSync = false;
        this.titleClick = false;

        if (childsubmenu == 1) {
          this.typeofbusinessPage = false;
          this.statefield = false;
          this.labor = false;
          this.taxMaster = false;
          this.titleClick = false;
          this.noSales = false;
          this.voids = false;
      this.prevoids = false;
      this.payrollReport = false;
          if (this.changedData == true) {
            this.openModal(this.helper.getValue('SessionPageName'), 'IsvPage');
          } else {
            this.IsvPage = true;
          }
        } else if (childsubmenu == 2) {
          this.IsvPage = false;
          this.statefield = false;
          this.labor = false;
          this.taxMaster = false;
          this.titleClick = false;
          this.noSales = false;
      this.voids = false;
      this.prevoids = false;
      this.payrollReport = false;

          if (this.changedData == true) {
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'typeofbusinessPage'
            );
          } else {
            this.typeofbusinessPage = true;
          }
        } else if (childsubmenu == 3) {
          this.IsvPage = false;
          this.statefield = false;
          this.typeofbusinessPage = false;
          this.taxMaster = false;
          this.titleClick = false;
          this.noSales = false;
      this.voids = false;
      this.prevoids = false;
      this.payrollReport = false;

          if (this.changedData == true) {
            this.openModal(this.helper.getValue('SessionPageName'), 'labor');
          } else {
            this.labor = true;
          }
        } else if (childsubmenu == 4) {
          this.IsvPage = false;
          this.statefield = false;
          this.typeofbusinessPage = false;
          this.labor = false;
          this.titleClick = false;
          this.noSales = false;
      this.voids = false;
      this.prevoids = false;
      this.payrollReport = false;

          if (this.changedData == true) {
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'taxMaster'
            );
          } else {
            this.taxMaster = true;
          }
        } else if (childsubmenu == 5) {
          this.IsvPage = false;
          this.taxMaster = false;
          this.typeofbusinessPage = false;
          this.labor = false;
          this.titleClick = false;
          this.noSales = false;
      this.voids = false;
      this.prevoids = false;
      this.payrollReport = false;
          if (this.changedData == true) {
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'statefield'
            );
          } else {
            this.statefield = true;
          }
        }
      } else if (submenu == 2) {
        this.typeofbusinessPage = false;
        this.statefield = false;
        this.labor = false;
        this.taxMaster = false;
        this.IsvPage = false;
        this.titleClick = false;
        this.noSales = false;
        this.payrollReport = false;
        if (childsubmenu == 1) {
          this.OrchestrationSync = false;
          this.errorMessageFrequency = false;
          if (this.changedData == true) {
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'currencyConfig'
            );
          } else {
            this.currencyConfig = true;
          }
        }
      } else if (submenu == 3) {
        this.typeofbusinessPage = false;
        this.statefield = false;
        this.labor = false;
        this.taxMaster = false;
        this.IsvPage = false;
        this.titleClick = false;
        if (childsubmenu == 1) {
          this.currencyConfig = false;
          this.errorMessageFrequency = false;
          if (this.changedData == true) {
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'OrchestrationSync'
            );
          } else {
            this.OrchestrationSync = true;
          }
        } else if (childsubmenu == 2) {
          this.currencyConfig = false;
          this.OrchestrationSync = false;
          if (this.changedData == true) {
            this.openModal(
              this.helper.getValue('SessionPageName'),
              'errorMessageFrequency'
            );
          } else {
            this.errorMessageFrequency = true;
          }
        }
      }
    }
    if (e == 'push') {
      this.push_img = true;
      this.master_img = false;
      this.home_img = false;
      this.report_img = false;
      this.report = false;
      this.site_img = false;
      this.dealers_img = false;
      this.menu_img = false;
      this.dealerPage = false;
      this.companyPage = false;
      this.storePage = false;
      this.MenuSizePage = false;
      this.MenuItemPage = false;
      this.MenuModifierPage = false;
      this.SalesAreaPage = false;
      this.CustomPayments = false;
      this.HouseAccounts = false;
      this.layoutPage = false;
      this.GiftCard = false;
      this.CreditCard = false;
      this.EmpPage = false;
      this.GeneralPage = false;
      this.selected_img = false;
      this.adjustmentPage = false;
      this.eventPage = false;
      this.TimePage = false;
      this.emvPage = false;
      this.device = false;
      this.tablet = false;
      this.printer = false;
      this.receipt = false;
      this.IsvPage = false;
      this.dealerEmp = false;
      this.compEmp = false;
      this.tagsPage = false;
      this.payroll = false;
      this.reasons = false;
      this.titleClick = false;
      this.salesReconcial = false;
      this.salesByDaypart = false;
      this.salesByHour = false;
      this.taxReport = false;
      this.discountsReport = false;
      this.giftCardReport = false;
      this.refundReport = false;
      this.creditBatch = false;
      this.taxhistory = false;
      this.jobPosition = false;
      this.labor = false;
      this.laborReport = false;

      this.RolepermissionPage = false;
      this.obj_push.connect();
      //this.SendPushRequest();
    }
    if (e == 'exit') {
      localStorage.clear();
      this.permser.RefreshPermission();
      this.dialogser.ClearSession();
      this.empService.clickUpdate(null);
      this.router.navigate(['/']);
    }
  }

  SendPushRequest() {
    const fondEndcls: iFrontEndMsg = {
      entityType: 'Publish',
      frameId: Date.now,
      tenantId: this.loginInfo.tenantId.toString(),
      attributes: 'Update Data',
      timestamp: Date,
      connectionId: '',
    };
    this.obj_push.sendMessageToHub(fondEndcls).subscribe({
      // next: _ => this.entityType = '',
      error: (err) => console.error(err),
    });
  }

  getDetailedData(id?: any) {
    const login: IListInfo = {
      loginUserId: this.loginInfo.userId,
      tenantId: id > 0 ? id : 0,
    };
    this.HieDetails.TenantHierarchyData(login).subscribe((response) => {
      if (response.typeName == 'ISV') {
        this.isvParentName = response.name;
        this.dealerList = response.childHierarchyList;
        this.breakTypeHide = true;
        this.isv = response;
      } else if (response.typeName == 'Dealer') {
        if (id == 0) {
          this.dealerHeaderName = response.name;
          this.dealerHierarchyHide = true;
          this.breakTypeHide = true;
          this.onChangeBusiness(response.id);
          this.dealer = response;
        }
        this.companyList = response.childHierarchyList;
      } else if (response.typeName == 'Company') {
        if (id == 0) {
          if (response.parentHierarchyInfo[0].typeName == 'Dealer') {
            this.dealerHeaderName = response.parentHierarchyInfo[0].name;
          }
          this.companyHeaderName = response.name;

          this.dealerHierarchyHide = true;
          this.companyHierarchyHide = true;
          this.breakTypeHide = true;
          this.company = response;
        }
        this.storeList = response.childHierarchyList;
      } else if (response.typeName == 'Store') {
        if (id == 0) {
          this.dealerHierarchyHide = true;
          this.companyHierarchyHide = true;
          this.storeHierarchyHide = true;

          if (response.parentHierarchyInfo[0].typeName == 'Dealer') {
            this.dealerHeaderName = response.parentHierarchyInfo[0].name;
          }
          else if (response.parentHierarchyInfo[0].typeName == 'Company') {
            this.companyHeaderName = response.parentHierarchyInfo[0].name;
          }

          if (response.parentHierarchyInfo[1].typeName == 'Dealer') {
            this.dealerHeaderName = response.parentHierarchyInfo[1].name;
          } else if (response.parentHierarchyInfo[1].typeName == 'Company') {
            this.companyHeaderName = response.parentHierarchyInfo[1].name;
          }
          this.store = response;
          this.storeHeaderName = response.name;
        }
      }
    });
  }

  onChangeBusiness(id) {
    const isTenant: IListInfo = {
      loginUserId: this.loginInfo.userId,
      tenantId: id,
    };
    this.HieDetails.GetTenantBusiness(isTenant).subscribe((response) => {
      this.BusinessPosition = response;
    });
  }

  getAreaBuilder(event) {
    this.salesAreaName = event;
    if (event.SessionId == 1) {
      this.Area = this.salesAreaName.name;
      this.page = '';
      this.group = '';
      this.SalesArea = this.salesAreaName;
      const salesValue: ISalesdetails = {
        area: this.SalesArea,
        group: this.SalesGroup,
        page: this.SalesPages,
      };
      this.objMenu.SalesvalueDatas(salesValue);
    }
    if (event.SessionId == 2) {
      this.group = this.salesAreaName.name;
      // this.Area="";
      this.page = '';
      this.SalesGroup = this.salesAreaName;
      const salesValue: ISalesdetails = {
        area: this.SalesArea,
        group: this.SalesGroup,
        page: this.SalesPages,
      };
      this.objMenu.SalesvalueDatas(salesValue);
    }
    if (event.SessionId == 3) {
      this.page = this.salesAreaName.name;
      // this.Area="";
      // this.page="";
      this.SalesPages = this.salesAreaName;
      const salesValue: ISalesdetails = {
        area: this.SalesArea,
        group: this.SalesGroup,
        page: this.SalesPages,
      };
      this.objMenu.SalesvalueDatas(salesValue);
    }
  }

  getdealerData(e) {
    if (e != undefined) {
      if (e.name == "DealerName") {
        this.dealerShow = 0;
        this.compShow = 0;
        this.storeShow = 0;

        this.dealerHeaderName = '';
        this.companyHeaderName = '';
        this.storeHeaderName = '';
      }
      else if (e.name == "CompanyName") {
        this.compShow = 0;
        this.storeShow = 0;

        this.companyHeaderName = '';
        this.storeHeaderName = '';
      }
      else if (e.name == "StoreName") {
        this.storeShow = 0;

        this.storeHeaderName = '';
      }

      if (e.typeId == 1) {
        this.isvShow = e.typeId;
      } else if (e.typeId == 2) {
        this.dealerShow = e.typeId;
        this.compShow = 0;
        this.storeShow = 0;
      } else if (e.typeId == 3) {
        this.compShow = e.typeId;
        this.storeShow = 0;
      } else if (e.typeId == 4) {
        this.storeShow = e.typeId;
        this.loginInfo.tenantId = e.id;
        this.helper.setValue('LoginInfo', this.loginInfo);
      }
    }
    if (e != undefined && e != null) {
      this.getTitleName(e.name);
    }
    this.hierachy = e;
    if (e != undefined && e != null && e != '' && e != '[]') {
      if (e.typeId == 1) {
        this.isv = this.hierachy;
        const HierachyValue: IHierachydetails = {
          isv: this.isv,
          dealer: this.dealer,
          company: this.company,
          store: this.store,
        };
        this.helper.setValue('ISVInfo', HierachyValue.isv.id);
        this.HieDetails.HierachyDetails(HierachyValue);
      }
      if (e.typeId == 2) {
        this.dealerHeaderName = this.hierachy.name;
        this.companyHeaderName = '';
        this.storeHeaderName = '';
        this.dealer = this.hierachy;
        this.loginInfo.tenantId = '';
        const HierachyValue: IHierachydetails = {
          isv: this.isv,
          dealer: this.dealer,
          company: '',
          store: '',
        };
        this.helper.setValue('ISVInfo', HierachyValue.isv.id);
        this.HieDetails.HierachyDetails(HierachyValue);
        this.helper.setValue('CompanyData_Help', null);
        this.helper.setValue('StoreData_Help', null);
      }
      if (e.typeId == 3) {
        this.companyHeaderName = this.hierachy.name;
        this.storeHeaderName = '';
        this.company = this.hierachy;
        this.loginInfo.tenantId = '';
        const HierachyValue: IHierachydetails = {
          isv: this.isv,
          dealer: this.dealer,
          company: this.company,
          store: '',
        };
        if (HierachyValue.isv != undefined) {
          this.helper.setValue('ISVInfo', HierachyValue.isv.id);
        }
        this.HieDetails.HierachyDetails(HierachyValue);
        this.helper.setValue('StoreData_Help', null);
      }
      if (e.typeId == 4) {
        this.storeHeaderName = this.hierachy.name;
        this.store = this.hierachy;
        this.loginInfo.tenantId = '';
        const HierachyValue: IHierachydetails = {
          isv: this.isv,
          dealer: this.dealer,
          company: this.company,
          store: this.store,
        };
        this.loginInfo.tenantId = this.hierachy.id;
        this.HieDetails.HierachyDetails(HierachyValue);
        this.helper.setValue('ISVInfo', HierachyValue.isv.id);
        this.helper.setValue('LoginInfo', this.loginInfo);
      }
    }
  }

  getTitleName(e?) {
    //console.log(this.employeeName);
    if (e == 'Dealer Name' && this.dealerHeaderName != undefined) {
      this.employeeName = this.dealerHeaderName;
    }
    else if (e == 'Company Name' &&
      this.companyHeaderName != undefined && this.companyHeaderName != null && this.companyHeaderName != '') {
      this.employeeName = this.companyHeaderName;
    }
    else if (e == 'Store Name' &&
      this.storeHeaderName != undefined && this.storeHeaderName != null && this.storeHeaderName != '') {
      this.employeeName = this.storeHeaderName;
    }
    else {
      this.employeeName = e;
    }
  }

  getTitle(e?) {
    this.employee = e;
  }

  getTitleIcon(e) {
    this.employeeIcon = e;
  }

  getEmptyid(e) {
    this.Area = e;
    this.group = e;
    this.page = e;
  }

  getemvName(e) {
    this.emvName = e.name;
  }

  gettabletName(e) {
    this.tabletName = e.name;
  }

  getprinterName(e) {
    this.printerName = e.name;
  }

  getprinterData(e) {
    this.printerData = e;
    if (this.printerData == true) {
      this.onImgChange('store', 3, 4);
    }
  }

  gettaxName(e) {
    this.taxName = e.name;
  }

  getSessionVal(e) {
    if (e.itmname != undefined && e.itmname != null && e.itmname != '') {
      this.employeeName = e.itmname;
    }
    if (this.helper.getValue('OnDirty') == true || this.helper.getValue('OnDirty2') == true ||
      this.helper.getValue('OnDirty3') == true || this.helper.getValue('OnDirty4') == true || e.val == true) {
      this.changedData = true;
      if (e.event != undefined && e.event != null && e.event != '') {
        this.helper.setValue('SessionPageName', e.event);
      }
    } else {
      if (e.event == "dealerPage") {
        this.dealerHeaderName = e.itmname;
        this.dealerShow = 2;
      }
      else if (e.event == "companyPage") {
        this.companyHeaderName = e.itmname;
        this.compShow = 3;
      }
      else if (e.event == "storePage") {
        this.storeHeaderName = e.itmname;
        this.storeShow = 4;
      }
    }
  }

  openModal(e, currentPage) {
    let confrmObj = new ConfirmBoxPopupComponent(this.bsModalRef);
    this.bsModalRef = this.modalService.show(ConfirmBoxPopupComponent, {
      class: 'modalComp modal-popUp modal-l',
      backdrop: 'static',
      keyboard: true,
    });
    this.bsModalRef.content.messageEvent.subscribe((data) => {
      this.dff = data;
      if (this.dff == true) {
        this.changedData = false;
        this.dialogser.ClearSession();
        this.helper.setValue('NewSession', 'this.' + currentPage);
        this.checkdata = this.helper.getValue('NewSession');
        this.helper.setValue('OldSession', 'this.' + e);
        this.uncheckdata = this.helper.getValue('OldSession');
        let t = 'this.' + e;
        let closePage = '' + this.uncheckdata + '' + '=false';
        t = eval(closePage);
        if (currentPage != '') {
          let a = '' + this.checkdata + '' + '=true';
          let s = 'this.' + currentPage;
          s = eval(a);
          this.changedData = false;
        }
        if (this.dealerShow != 2) {
          if (currentPage == 'companyPage') {
            this.toaster.hierarchyPopUp(
              'Please Select Dealer Before Selecting Company2'
            );
            this.companyPage = false;
            this.dealerPage = false;
          }
        }
        if (this.compShow != 3) {
          if (currentPage == 'storePage') {
            this.toaster.hierarchyPopUp('Please Select Company Before Selecting Store');
            this.storePage = false;
          }
        }
        if (currentPage == 'MenuItemPage') {
          this.MenuItemPage = true;
          this.MenuModEmit = this.MenuItemPage;
        }
        return 'ok';
      } else {
        this.changedData = true;
        let cancelData = 'this.' + this.helper.getValue('SessionPageName');
        this.getThemeData(cancelData);
        if (currentPage == 'MenuItemPage') {
          this.MenuItemPage = false;
          this.MenuModEmit = this.MenuItemPage;
        }
        return 'cancel';
      }
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.helper.setValue('Closession', 'this.' + this.helper.getValue('SessionPageName')
    );
    this.checkdata = this.helper.getValue('Closession');
    let a = '' + this.checkdata + '' + '=true';
    let s = 'this.' + this.checkdata;
    s = eval(a);
  }

  getThemeData(e) {
    this.changeDetector.detectChanges();
    let a = '' + e + '' + '=true';
    let s = 'this.' + e;
    s = eval(a);
    this.MenuImageSet(e);
  }

  MenuImageSet(menuName) {
    if (menuName == 'this.dealerPage' || menuName == 'this.dealerEmp') {
      this.dealers_img = true;
    } else {
      this.dealers_img = false;
    }

    if (menuName == 'this.companyPage' || menuName == 'this.compEmp') {
      this.site_img = true;
    } else {
      this.site_img = false;
    }

    if (
      menuName == 'this.storePage' || menuName == 'this.CustomPayments' || menuName == 'this.HouseAccounts' ||
      menuName == 'this.GiftCard' || menuName == 'this.CreditCard' || menuName == 'this.emvPage' ||
      menuName == 'this.tablet' || menuName == 'this.printer' || menuName == 'this.receipt' ||
      menuName == 'this.tagsPage' || menuName == 'this.reasons') {
      this.menu_img = true;
    } else {
      this.menu_img = false;
    }

    if (menuName == 'this.GeneralPage' || menuName == 'this.TimePage' || menuName == 'this.BreakPage' ||
      menuName == 'this.payroll' || menuName == 'this.jobPosition') {
      this.selected_img = true;
    } else {
      this.selected_img = false;
    }

    if (menuName == 'this.MenuSizePage' || menuName == 'this.MenuItemPage' || menuName == 'this.MenuModifierPage' ||
      menuName == 'this.SalesAreaPage' || menuName == 'this.layoutPage' || menuName == 'this.adjustmentPage' ||
      menuName == 'this.eventPage') {
      this.home_img = true;
    } else {
      this.home_img = false;
    }

    if (menuName == 'this.IsvPage' || menuName == 'this.typeofbusinessPage' || menuName == 'this.RolepermissionPage' ||
      menuName == 'this.statefield' || menuName == 'this.signal' || menuName == 'this.chatPage' ||
      menuName == 'this.contractPage' || menuName == 'this.socket' || menuName == 'this.labor' ||
      menuName == 'this.taxMaster') {
      this.master_img = true;
    } else {
      this.master_img = false;
    }

    if (menuName == 'report') {
      this.report_img = true;
    } else {
      this.report_img = false;
    }

    if (menuName == 'sync') {
      this.syno_img = true;
    } else {
      this.syno_img = false;
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
}
