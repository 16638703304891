import { ChangeDetectorRef, Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { menuItemList, GetItemtype, SaveModifierAssigned, SelectModifierAssigned } from 'src/app/interfaces/imenu';
import { MenuService } from 'src/app/services/menu.service';
import { ModifierService } from 'src/app/services/modifier.service';
import { CdkDragDrop, CdkDragExit, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FliptableLayoutComponent } from 'src/app/elements/fliptable-layout/fliptable-layout.component';

@Component({
  selector: 'app-items-assigned-modifier',
  templateUrl: './items-assigned-modifier.component.html',
  styleUrls: ['./items-assigned-modifier.component.css']
})
export class ItemsAssignedModifierComponent implements OnInit {
  buttonDisable: boolean = false;
  loginInfo: any;
  modifierid: any;
  Jsonstring: any;
  public variables: any = []
  public mainItem: any = [];

  public displayedColumns = [];
  columns = [];
  public FinalItemList = [];

  public dataSource = new MatTableDataSource<any>();
  itemAssignedList: any = [];
  menu_Group: boolean = true;
  public menuData: any;
  public table_render: boolean = true;
  private onDestroy$: Subject<void> = new Subject<void>();
  @Output() public ModifierItmData_child = new EventEmitter();
  @ViewChild(FliptableLayoutComponent) FliterableComp: FliptableLayoutComponent;

  //@Input() public buttonState;
  constructor(private objModifier: ModifierService, private objItem: MenuService, private changeDetector: ChangeDetectorRef,
    private helper: HelperService, private toaster: ToasterService) { }

  ngOnInit(): void {
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getMenuItemList(0);
  }

  CleanSeachBox() {
    this.FliterableComp.ngOnInit();
  }

  @Input() saveId: any;
  @Input()
  public set menuItemIn(ItemAssignData) {
    this.menuData = ItemAssignData;
  }

  @Input()
  public set MenuRowItem(tableRowData) {
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      this.modifierid = tableRowData;
      this.getMenuItemList(this.modifierid);
      this.GetItemAssignedList(this.modifierid);
    }
  }

  getMenuItemList(ModifierID) {
    const ItemTypeList: GetItemtype = {
      itemId: ModifierID,
      itemType: 'ItemAssign',
      //itemType: ' where Main_Item=1',   // Only itemtype Main_Item true record is fetched
      tenantId: this.loginInfo.tenantId,
    }
    this.objItem.GetItemTypeList(ItemTypeList)
      .pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.dataSource.data = res.filter(x => x.IsActive == true);
        this.variables = res.filter(x => x.IsActive == true).slice();
        this.displayedColumns = ['name'];
        this.columns = [
          { columnDef: 'name', header: 'Items (Main & Side) ' },
        ];
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  drop(event) {
    this.helper.setValue('GetSessionData2', JSON.stringify(event.container.data));
    this.helper.setValue('OnDirty2', true);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data.filteredData,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.table_render = false
      this.changeDetector.detectChanges();
      this.table_render = true
    }
    this.FinalItemList = event.container.data;
    this.variables = this.variables.filter(item => item.id !== event.item.data.id);

    this.ModifierItmData_child.emit({ data: JSON.stringify(event.container.data), event: "MenuModifierPage", val: true });
  }

  async SaveItemAssignedModifier(modifierId): Promise<any> {

    return new Promise((resolve, reject) => {
      this.FinalItemList = this.itemAssignedList;
      const ItemAssignList: SaveModifierAssigned = {
        id: modifierId,
        ItemObj: JSON.stringify(this.FinalItemList),
        userid: this.loginInfo.userId,
        tenantId: this.loginInfo.tenantId,
      }
      this.objModifier.SaveModifierAssigned(ItemAssignList).
        subscribe(res => {

          this.toaster.successPopUp(res.message);
          resolve({ res: res });
        });
    });
  }

  GetItemAssignedList(ModifierID) {
    const ItemList: SelectModifierAssigned = {
      modifierID: ModifierID
    }
    this.objModifier.SelectModifierAssigned(ItemList)
      .subscribe(res => {
        if (res != undefined && res != null && res != "") {
          this.itemAssignedList = null;
          this.itemAssignedList = res;
        }
        else {
          this.itemAssignedList = null;
          this.itemAssignedList = res;
        }
      })
  }

  searchMainItem(e) {
    this.dataSource.data = this.searchItems(e);

    this.displayedColumns = ['name'];
    this.columns = [
      { columnDef: 'name', header: 'Items ' },
    ];
  }

  searchItems(value: string) {
    let filteredList = this.variables.filter((unit) =>
      unit.name.toUpperCase().includes(value.toUpperCase()));
    if (filteredList.length == 0) {
      return [...filteredList];
    }
    return filteredList;
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}