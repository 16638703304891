import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { AdminService } from 'src/app/services/admin.service';
import { IDeleteState, IRole } from 'src/app/interfaces/iadmin';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TaxMasterGeneralComponent } from '../tax-master-general/tax-master-general.component';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-tax-master',
  templateUrl: './tax-master.component.html',
  styleUrls: ['./tax-master.component.css']
})

export class TaxMasterComponent implements OnInit {
  TaxMasterForm: FormGroup;
  public modifierItemsData: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public tableRowId: any;
  public tableRowColor: any;
  public activeDeactiveValue: any;
  private buttonDisable: boolean = false;
  loginInfo: any;
  public dataSource = new MatTableDataSource<any>();
  public EmployeeTableData: any;
  public displayedColumns = [];
  columns = [];
  submittedGeneral: any;
  //parentID: any
  id: any = 0;
  isValidated = false;
  hirarcyvalue: any;
  isInvalid: boolean = false;
  breadcrumbsData: any

  //@Input() public tableRowId;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public generalBusiness = new EventEmitter();
  @Output() public taxName = new EventEmitter();
  @Output() public taxmasterData = new EventEmitter();

  @ViewChild(TaxMasterGeneralComponent) taxgenComp: TaxMasterGeneralComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private adminser: AdminService,
    private toaster: ToasterService, private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.taxMaster");

    this.buttonDisable = false;
    this.employeeName = "Tax Master";
    this.employee = "Tax Master";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
    this.loginInfo = this.helper.getValue('LoginInfo');
    // this.adminser.HierachyValue.subscribe(data => {
    //   this.parentID = data.company.id
    // });
    this.getTaxMaster();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }

  getTaxMaster() {
    const login: IRole = {
      id: 0,
      tenantId: this.loginInfo.tenantId //this.parentID
    }
    this.adminser.GetTaxMaster(login).subscribe(
      (response) => {
        this.dataSource.data = response;
        this.EmployeeTableData = response;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)

        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.ClearData(clearForm);
    }
  }

  async ClearData(event?) {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    }
    else {
      var conf = await this.dialogser.confirm("taxMaster");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
      }
      else {
        this.taxgenComp.TaxMasterGenForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        if (!this.taxgenComp.TaxMasterGenForm.invalid) {
          this.taxgenComp.dateMgs = '';
          this.taxgenComp.isInvalid = false;
          return;
        }
      }
    }
  }

  ClearGenData() {
    this.taxgenComp.id = 0;
    this.employeeName = "Tax Master";
    this.tableRowColor = 'transparent';
    this.taxgenComp.submittedGeneral = false;
    this.tableRowId = 0;
    this.isInvalid = false;
    this.gethierarchyData(this.employeeName);
    this.taxgenComp.TaxMasterGenForm.reset();
    this.taxgenComp.ngOnInit();
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.taxgenComp.isInvalid = false;
    this.taxgenComp.dateMgs = '';
    this.flipcomp.selectTableRowColor(0, 0, this.employeeName);
    this.titleName.emit(this.employeeName);
  }

  gethierarchyData(e) {
    this.taxName.emit(e)
    this.breadcrumbsData = true
  }

  checkValue(event) {
    this.activeDeactiveValue = event
    let currentTarget = event.e.currentTarget.checked
    const active: IDeleteState = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked
    }
    this.adminser.DeleteTaxMaster(active).subscribe(
      (response) => {
        if (currentTarget) {
          this.toaster.successPopUp("Tax is Active");
        } else {
          this.toaster.successPopUp("Tax is Deactive");
        }
        this.getTaxMaster();
      },
    )
  }

  async getTableIdx(event) {
    //this.gentab.isFormPageDirty();
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("taxMaster");
      if (conf == 'success') { // Navigate Away
        this.dialogser.ClearSession();
        this.taxgenComp.TaxMasterGenForm.reset();

        //General Data
        this.dialogser.ModifiedId = event;
        this.helper.setValue('Sessionid_help', event)
        this.isInvalid = false;
        this.tableRowId = event;
        this.tableRowColor = event;
        this.flipcomp.rowClicked = event;
        this.taxgenComp.isInvalid = false;
        this.taxgenComp.dateMgs = '';
      }
      else // Cancel
      {
        //General Data
        this.taxgenComp.TaxMasterGenForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.taxmasterData.emit({
          data: this.taxgenComp.TaxMasterGenForm.value, event: "taxMaster", val: true,
          itmname: this.taxgenComp.TaxMasterGenForm.value.name
        });

        if (!this.taxgenComp.TaxMasterGenForm.invalid) {
          this.taxgenComp.dateMgs = '';
          this.taxgenComp.isInvalid = false;
          return;
        }
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event);
      this.isInvalid = false;
      this.tableRowId = event;
      this.tableRowColor = event;
    }
  }

  getTableName(name) {
    this.employeeName = name;
    this.employee = "Tax Master";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  getGeneralDetail(event) {
    this.tableRowId = event.id;
    this.getTaxMaster();
    //this.ClearData();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name)
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  OnSave() {
    this.taxgenComp.onSaveGeneral();
    if (this.taxgenComp.TaxMasterGenForm.invalid) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }
  }

  getmnuitmsessData(e) {
    this.taxmasterData.emit(e)
  }
}
