import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModifierService } from 'src/app/services/modifier.service';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ModifierPrice, EditModifierPrice } from 'src/app/interfaces/imenu';
import { ToasterService } from 'src/app/helper/toaster.service';

@Component({
  selector: 'app-dynamic-editable-table',
  templateUrl: './dynamic-editable-table.component.html',
  styleUrls: ['./dynamic-editable-table.component.css']
})
export class DynamicEditableTableComponent implements OnInit {
  buttonDisable: boolean = false;
  public dataSource = new MatTableDataSource<any>();
  id: any;
  columnNames: any;
  data = [];
  loginInfo: any;
  sourceBuilderTools = {
  };
  @Input() saveId: any;
  constructor(private helper: HelperService) { }

  @Output() public layoutSave = new EventEmitter();
  @Output() public generalItem = new EventEmitter();
  @Output() public DynItmData_child = new EventEmitter();

  //@Input() public buttonState;
  @Input()
  public set MenuRowItem(tableRowData) {
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      this.id = tableRowData;
    }
  }

  @Input()
  public set GetSaveId(tableRowData) {
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      if (tableRowData.id != undefined && tableRowData.id != null && tableRowData.id != "") {
        this.id = tableRowData.id;
      }
    }
  }

  ngOnInit(): void {
    this.buttonDisable = false;
    this.id = this.helper.getValue('ModifierIDValue');
    this.loginInfo = this.helper.getValue('LoginInfo');
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  OnSave(e) {
    this.layoutSave.emit({ id: this.id, itemObject: JSON.stringify(this.data) });
  }

  onChange(e) {
    this.helper.setValue('OnDirty4', true);
    this.DynItmData_child.emit({ data: "", event: "MenuModifierPage", val: true });
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }
}