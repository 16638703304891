<form class="loginForm form-switch table_forms" [formGroup]="filterForm">
    <div class="row">
        <div class="col-sm col-6">
            <div class="emp-time">
                <div class="form-group">
                    <label>From Date</label>
                    <mat-form-field appearance="fill" class="custom-date">
                        <input matInput [matDatepicker]="picker" placeholder="From Date" class="form-control datepicker appInputRestriction" formControlName="fromDate" (click)="picker.open()" />
                        <mat-datepicker #picker class="example-header"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-sm col-6">
            <div class="emp-time">
                <div class="form-group">
                    <label>To Date</label>
                    <mat-form-field appearance="fill" class="custom-date">
                        <input matInput [matDatepicker]="pickerA" placeholder="To Date" class="form-control datepicker appInputRestriction" formControlName="toDate" (click)="pickerA.open()" />
                        <mat-datepicker #pickerA class="example-header"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="col-sm col-6">
            <div class="emp-time">
                <div class="form-group mat-box">
                    <label>Employee</label>
                    <ng-multiselect-dropdown class="multi-select-dd" [settings]="salestaxSettings" [data]="employeFilters" [formControl]="f.employeAreaObj" [(ngModel)]="employeeselectedItems" (onSelect)="OnselectEmp($event)" (onDeSelect)="OnselectEmp($event)" (onDeSelectAll)="OnselectAllEmp($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>

        <div class="col-sm col-6 disp-check">
            <div *ngIf = "tableFirstShow == true"> <label class="hide_report">xxx</label> </div>

            <div class="col-3 col-sm-5 col-md-5 col-lg-5 pl-md-0" *ngIf = "tableFirstShow == true">
                <label class="uber_label">Labor Category</label>
                <!-- <p class="uber_para">This allows this tender type to be charged by swiping or manually entering
                        the
                        associated card number or code from the 'pay' screen in the POS </p> -->
            </div>
            <div class="col-2 col-sm-1 col-md-1 col-lg-1" *ngIf = "tableFirstShow == true">
                <div class="checkbox-table accordion-checkbox">
                    <div class="custom-control custom-checkbox custom-control item">
                        <input
                        (change)="categoryCheck($event)"
                         type="checkbox"
                         class="custom-control-input"
                         name="customCheckbox" 
                       />
                        <label class="custom-control-label mb-0"></label>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-sm-2 col-md-2 col-6">
            <div class="emp-time">
                <div class="form-group mat-box">
                    <label class="hide_report">xxx</label>
                    <button class="btn submit_button form-control timeEditBtn" type="submit" (click)="onSubmit()">
              Submit
            </button>
                </div>
            </div>
        </div>
    </div>
</form>