<form class="loginForm" [formGroup]="orchsyncForm" (change)="onchange(orchsyncForm.value)">
    <div class="row top-sec">
        <div class="col-sm-12">
            <div class="form-group global-form">
                <button [ngClass]="{'submit_button_error': isInvalid==true}"
                    class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="onSaveGeneral();"
                    [disabled]="buttonDisable ? true : null">
                    <img src="assets/components/images/save.png" alt="employee" class="">
                    <label class="label_config">Save</label>
                </button>
            </div>
        </div>
    </div>
    <div class="row second-sec">
        <div class="col-sm-12">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst" [expanded]="isFirst"
                    class="outshadow">
                    <mat-expansion-panel-header [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                        <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> Orchestraion
                            Sync </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row w-100 ">
                        <div class="col-12 setting-error-msg">
                            <div class="form-group d-flex pl-0 col-6 align-items-center">
                                <label for="test" class=" pr-3 customlable mb-0">Time Interval for Orchestration</label>
                                <input type="text" (keypress)="numberOnly($event)"
                                    formControlName="timeIntervalforBackendSync"
                                    class="form-control input-lg inputbox_login col-2" />
                                <span class="pl-2">Seconds</span>
                            </div>
                        </div>
                    </div>

                    <div class="row w-100 ">
                        <div class="col-12 setting-error-msg">
                            <div class="form-group d-flex pl-0 col-6 align-items-center">
                                <label for="test" class="pr-3 customlable mb-0">Time Interval for iPad Redundancy</label>
                                <input type="text" (keypress)="numberOnly($event)"
                                    formControlName="timeIntervalforIPadSync"
                                    class="form-control input-lg inputbox_login col-2" />
                                <span class="pl-2">Seconds</span>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</form>