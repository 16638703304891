import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TagsRoutingModule } from './tags-routing.module';
import { TagsGeneralComponent } from './tags-general/tags-general.component';
import { TagsMainComponent } from './tags-main/tags-main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { EmployeeModule } from '../employee/employee.module';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MenuModule } from '../menu/menu.module';
import { VerticalDragDropComponent } from 'src/app/elements/vertical-drag-drop/vertical-drag-drop.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TagPrintJobComponent } from './tag-print-job/tag-print-job.component';
import { TagPrintPrinterComponent } from './tag-print-printer/tag-print-printer.component';
import { TagFilterComponent } from './tag-filter/tag-filter.component';
import { DayPartScheduleComponent } from './day-part-schedule/day-part-schedule.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';


@NgModule({
  declarations: [
    TagsGeneralComponent,
    TagsMainComponent,
    VerticalDragDropComponent,
    TagPrintJobComponent,
    TagPrintPrinterComponent,
    TagFilterComponent,
    DayPartScheduleComponent
  ],
  imports: [
    CommonModule,
    TagsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    DragDropModule,
    MenuModule,
    EmployeeModule,
    MatCheckboxModule,
    NgMultiSelectDropDownModule,
    NgxMatTimepickerModule,

  ],
  exports: [TagsMainComponent, VerticalDragDropComponent, TagPrintJobComponent,
    TagPrintPrinterComponent, TagFilterComponent, DayPartScheduleComponent]
})
export class TagsModule { }
