import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { MenuPageService } from 'src/app/services/menupage.service';
import { GetMenuPages, EditMenuPages } from 'src/app/interfaces/imenu';
import { DialogService } from 'src/app/services/dialog.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-menu-page-general',
  templateUrl: './menu-page-general.component.html',
  styleUrls: ['./menu-page-general.component.css']
})

export class MenuPageGeneralComponent implements OnInit {
  MenuPageGeneralForm: FormGroup;
  buttonDisable: boolean = false;
  submittedGeneral: any;
  loginInfo: any;
  errorValue: any;
  requiredError: boolean = false;
  AreaBuildmnupageId: any = 0;
  public isActiveInactivePerm: boolean;

  @Output() public isInvalid = new EventEmitter();
  @Output() public generalItem = new EventEmitter();
  @Output() public mnupageData_subchild = new EventEmitter();
  @Input() public buttonDis;
  constructor(private formBuilder: FormBuilder, private helper: HelperService, private objPage: MenuPageService,
    private toaster: ToasterService, private dialogser: DialogService,private permser: PermissionService) {
    this.MenuPageGeneralForm = this.formBuilder.group({})
  }

  ngOnInit(): void {
    this.isActiveInactivePerm = this.permser.isActiveInactive;

    this.helper.setValue('GetSessionData3', null);
    this.AreaBuildmnupageId = 0;
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.MenuPageGeneralForm = this.formBuilder.group({
      id: 0,
      name: ['', Validators.required],
      isActive: [true]
    })
  }

  get GeneralData() { return this.MenuPageGeneralForm.controls; }

  @Input()
  public set EditPagedata(event) {
    if (event != undefined && event != null && event != "") {
      this.AreaBuildmnupageId = event.id;
      this.MenuPageGeneralForm.patchValue(event)
    }
  }

  async OnSaveMenuPage() {
    this.errorValue = ""
    this.submittedGeneral = true;
    if (this.MenuPageGeneralForm.invalid) {
      return;
    }

    let menuPage;
    menuPage = this.MenuPageGeneralForm.value;
    menuPage.userID = this.loginInfo.userId;
    menuPage.tenantId = this.loginInfo.tenantId;
    menuPage.id = this.AreaBuildmnupageId;
    menuPage.isActive = true;
    this.objPage.MenuPageSave(menuPage).subscribe(async res => {
      if (res.id == 0) {
        this.toaster.errorPopUp(res.message);
        return;
      } else {
        this.AreaBuildmnupageId = res.id;
        this.toaster.successPopUp(res.message);
        await this.dialogser.ClearSession();
        this.helper.setValue('GetSessionData3', menuPage);
        this.mnupageData_subchild.emit({ data: menuPage, event: "SalesAreaPage", val: false });
      }
      //this.MenuPageGeneralForm.reset();
      this.submittedGeneral = false;
      this.generalItem.emit({ res: res, name: menuPage.name });
      //this.MenuPageGeneralForm.patchValue({ isActive: true });  //This is commented because After Uncheck and save its getting check
    });
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  MenuPageValid() {
    if (this.MenuPageGeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }
    else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.MenuPageGeneralForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.MenuPageGeneralForm.patchValue(this.helper.getValue('GetSessionData3'));
    this.mnupageData_subchild.emit(this.MenuPageGeneralForm.value)
  }

  onchange(data) {
    this.helper.setValue('GetSessionData3', data);
    this.helper.setValue('OnDirty3', true);
    this.mnupageData_subchild.emit({ data: data, event: "SalesAreaPage", val: true });
  }
}