import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';

@Component({
  selector: 'app-tax-report',
  templateUrl: './tax-report.component.html',
  styleUrls: ['./tax-report.component.css']
})
export class TaxReportComponent implements OnInit {
  loginInfo: any;
  total?: any;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  public employeeName: any;
  public employeeIcon: any;
  //public titleIcon: any;
  public employee: any;
  public displayedColumns1 = [];
  columns1 = [];
  public dataSource1: any;
  public firstTableShow: boolean = false

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private reportService: ReportWebSocketService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.taxReport");

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Tax Reports";
    this.employee = "Reports";
    this.employeeIcon = "assets/components/images/reports-icon.png";
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.getFirstTable()
  }
  getFirstTable() {
    this.firstTableShow = true;
    this.displayedColumns1 = ['name', 'rate', 'taxAmount', 'netSales',];
    this.dataSource1 = ELEMENT_DATA1;
    this.columns1 = [
      { columnDef: 'name', header: "Name" },
      { columnDef: 'rate', header: "Rate" },
      { columnDef: 'taxAmount', header: "Tax Amount" },
      { columnDef: 'netSales', header: "Net Sales" },
    ]
  }
}
export interface PeriodicElement {
  name?: any;
  rate?: any;
  taxAmount?: any;
  netSales?: any;
}
const ELEMENT_DATA1: PeriodicElement[] = [
  { name: 'MT Resort Tax', rate: '3%', taxAmount: 407.55, netSales: 13585.00, },
  { name: 'Alochol Tax', rate: '8.25%', taxAmount: 147.47, netSales: 1787.50, },
  { name: 'Tax Exempt', rate: '0%', taxAmount: 0.00, netSales: 137.50, },
  { name: 'Non Taxable', rate: 'N/A', taxAmount: 0.00, netSales: 798.25, },
  { name: 'Tax Inclusive', rate: '2%', taxAmount: 9.00, netSales: 450, }
]


