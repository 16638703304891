<div *ngIf="detector" class="layout_container menu-item-searchfield">
  <table mat-table #table [dataSource]="dataSource" matSort
    class="mat-elevation-z8 table employee-table employee dataTable" style="width: 100%" cdkDropList id="{{ SalesID }}"
    #done="cdkDropList" [cdkDropListData]="dataSource" [cdkDropListConnectedTo]="[
      'todo',
      'menuGroup',
      'menuPage',
      'ipadFilter',
      'jobs',
      'printer'
    ]" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let item of columns" matColumnDef="{{ item.columnDef }}">
      <th mat-header-cell *matHeaderCellDef>
        <div>{{ item.header }}</div>
        <div class="dragdrop-search">
          <input class="form-control" type="text" placeholder="Search here" [(ngModel)]="searchValue"
            (keyup)="onMainSearch($event.target.value)" />
        </div>
      </th>

      <td mat-cell *matCellDef="let element; let idx = index">
        <div class="emp_name" *ngIf="item.columnDef == 'name'" cdkDragPlaceholder>
          {{ element[item.columnDef] }}
        </div>
      </td>
    </ng-container>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell employeenodata" colspan="4">
        No data matching the search
      </td>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row (click)="changeTableRowColor(row)" *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ highlight: selectedRowIndex == row.id }" (cdkDragEnded)="onDragEnd($event)" cdkDrag
      [cdkDragData]="row" cdkDragPlaceholder></tr>
  </table>
</div>