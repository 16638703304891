import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-card',
  templateUrl: './title-card.component.html',
  styleUrls: ['./title-card.component.css']
})
export class TitleCardComponent implements OnInit {
  @Input() public employeeName;
  @Input() public employee;
  @Input() public employeeIcon;
  @Input() public getSalesTitle;
  @Input() public iconClick;
  @Input() public titleClick;
   
  constructor() { }

  ngOnInit(): void {
  }
  @Input()
  public set Dataname(employeeName) {
    this.employeeName = employeeName;
  }
  @Input()
  public set DataCom(employee) {
    this.employee = employee;
  }
  @Input()
  public set DataIcon(employeeIcon) {
    this.employeeIcon = employeeIcon;
  }
  @Input()
  public set DataSalesTitle(employeeName) {
    this.employeeName = employeeName;
  }

}
