<form class="MenuItemGeneral loginForm" [formGroup]="MenuItemForm" (change)="onchange(MenuItemForm.value)">
    <div class="row">
        <div class="col-sm-4">
            <div class="form-group">
                <label class="mb-0">Name</label> <label class="text-danger">*</label>
                <input type="text" class="form-control input-lg inputbox_login" formControlName="name"
                    placeholder="Name" [ngClass]="{
            'is-invalid':
              (submittedGeneral && MenuItemData.name.errors) ||
              (requiredError && MenuItemData.name.errors)
          }" appInputRestriction maxlength="50" (input)="getMaxValue($event)" (keypress)="Highlight()"
                    (focusout)="MenuItemsValid()" />
                <div class="error">{{ errorValue }}</div>
                <div *ngIf="submittedGeneral && MenuItemData.name.errors" class="invalid-feedback">
                    <div *ngIf="MenuItemData.name.errors.required">Name is required</div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <!-- <div class="form-group">
                <label class=" mb-0">Short Name</label> <label class="text-danger">*</label>
                <input type="text" class="form-control input-lg inputbox_login" formControlName="shortname"
                    [ngClass]="{ 'is-invalid': submittedGeneral && MenuItemData.shortname.errors }"
                    appInputRestriction />
                <div *ngIf="submittedGeneral && MenuItemData.shortname.errors" class="invalid-feedback">
                    <div *ngIf="MenuItemData.shortname.errors.required">Short Name is required</div>
                </div>
            </div> -->
            <div class="form-group">
                <label class="mb-0">Short Name</label>
                <input type="text" class="form-control input-lg inputbox_login" formControlName="shortname"
                    appInputRestriction maxlength="50" (input)="getMaxValueSName($event)" />
                <div class="error">{{ errorValue1 }}</div>
            </div>
        </div>
        <div class="col-sm-4" *ngIf="
        MenuItemForm.get('main').value ||
        (MenuItemForm.get('ingredient').value &&
          !MenuItemForm.get('ingredient').value &&
          !MenuItemForm.get('remark').value)
      ">
            <div class="form-group">
                <label class="mb-0">Item Description</label>
                <textarea type="text" class="form-control input-lg inputbox_login" formControlName="description"
                    appInputRestriction></textarea>
                <div *ngIf="submittedGeneral && MenuItemData.description.errors" class="invalid-feedback">
                    <div *ngIf="MenuItemData.description.errors.required">
                        Item Description is required
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- check box -->
    <div class="row">
        <div class="col-5">
            <label class="custom-title custom-color">Item type</label><label class="text-danger custom-color">*</label>
            <div class="main-item row form-group border-bottom">
                <div class="col-sm-1 col-md-4 col-lg-4" *ngIf="!MenuItemForm.get('remark').value">
                    <div class="checkbox-table accordion-checkbox">
                        <label class="customlable mb-0 menu-lbl-gap">Main</label>
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                formControlName="main" id="Mainid" (change)="mainclick($event)" [attr.disabled]="
                  MenuItemForm.get('remark').value
                    ? ''
                    : null || MenuItemForm.get('ingredient').value
                    ? ''
                    : null
                " />
                            <label class="custom-control-label" for="Mainid"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 col-md-4 col-lg-4" *ngIf="!MenuItemForm.get('remark').value">
                    <div class="checkbox-table accordion-checkbox">
                        <label class="customlable mb-0 menu-lbl-gap">Side Dish</label>
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                formControlName="sideDish" id="SideDish" [attr.disabled]="
                  MenuItemForm.get('remark').value
                    ? ''
                    : null || MenuItemForm.get('ingredient').value
                    ? ''
                    : null
                " />
                            <label class="custom-control-label" for="SideDish"></label>
                        </div>
                    </div>
                </div>

                <div class="col-sm-2 col-md-4 col-lg-4" *ngIf="
            !MenuItemForm.get('ingredient').value &&
            !MenuItemForm.get('sideDish').value &&
            !MenuItemForm.get('main').value
          ">
                    <div class="checkbox-table accordion-checkbox">
                        <label class="customlable mb-0 menu-lbl-gap">Remark</label>
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                formControlName="remark" id="Remark" [attr.disabled]="
                  MenuItemForm.get('main').value
                    ? ''
                    : null || MenuItemForm.get('sideDish').value
                    ? ''
                    : null || MenuItemForm.get('ingredient').value
                    ? ''
                    : null
                " />
                            <label class="custom-control-label" for="Remark"></label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="card-header border-bottom-0 bg-transparent" *ngIf="MenuItemForm.get('remark').value">
                        <label>Style</label>
                        <ul class="pl-0">
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                                [value]="remarkStylesDefvalue[0].value" (change)="onChange($event)">
                                <mat-button-toggle class="card-header form-control input-lg inputbox_login"
                                    forControlName="remarkStyle" value="Remarks">Remark
                                </mat-button-toggle>
                                <mat-button-toggle class="card-header form-control input-lg inputbox_login"
                                    forControlName="remarkStyle" value="Comments">
                                    Command</mat-button-toggle>
                            </mat-button-toggle-group>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="
              submittedGeneral &&
              MenuItemData.main.errors &&
              MenuItemData.sideDish.errors &&
              MenuItemData.ingredient.errors &&
              MenuItemData.remark.errors
            " class="invalid-feedback">
                        <div *ngIf="
                MenuItemData.main.errors.required &&
                MenuItemData.sideDish.errors.required &&
                MenuItemData.ingredient.errors.required &&
                MenuItemData.remark.errors.required
              ">
                            At least one checkbox is required to check
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div *ngIf="!MenuItemForm.get('remark').value">
                        <div class="checkbox-table accordion-checkbox">
                            <label class="customlable mb-0 menu-lbl-gap">Ingredient</label>
                            <div class="custom-control custom-checkbox custom-control item">
                                <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                    formControlName="ingredient" id="Ingredient" (change)="OnIngredientCheck($event)"
                                    [attr.disabled]="
                    MenuItemForm.get('remark').value
                      ? ''
                      : null || MenuItemForm.get('main').value
                      ? ''
                      : null || MenuItemForm.get('sideDish').value
                      ? ''
                      : null
                  " />
                                <label class="custom-control-label" for="Ingredient"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="remarkbehavior mt-1" *ngIf="
          MenuItemForm.get('main').value ||
          MenuItemForm.get('sideDish').value ||
          MenuItemForm.get('ingredient').value ||
          MenuItemForm.get('remark').value
        ">
                <!-- <label *ngIf="MenuItemForm.get('remark').value">Pricing style</label> -->
                <div class="row">
                    <div class="col-sm-2 col-md-4 col-lg-6" *ngIf="!MenuItemForm.get('pricingStyle').value">
                        <div *ngIf="!MenuItemForm.get('openPrice').value">
                            <div class="form-group price-textbox">
                                <label class="pricepadding">Price</label>
                                <!-- <input type="text" class="form-control" placeholder="$0.00" formControlName="price"
                                    appInputRestriction currencyMask  [options]="options" (keydown)="onKeyDown($event)" /> -->
                                <i class="fa fa-usd" aria-hidden="true"></i>
                                <input class="form-control" type="text" appDecimalLimit placeholder="0.00"
                                    formControlName="price" list="dataType" appInputRestriction #searchField />
                            </div>
                        </div>
                        <div *ngIf="MenuItemForm.get('openPrice').value">
                            <div class="form-group">
                                <!-- <label class="pricepadding">Price</label> -->
                                <!-- <input type="text" class="form-control" placeholder="$0.00" formControlName="price "
                                    appInputRestriction readonly/> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-6 menu-size-price"
                        *ngIf="!MenuItemForm.get('pricingStyle').value">
                        <div *ngIf="!MenuItemForm.get('openPrice').value">
                            <div class="form-group price-textbox">
                                <label class="pricepadding">Cost</label>
                                <i class="fa fa-usd" aria-hidden="true"></i>
                                <input class="form-control" type="text" appDecimalLimit placeholder="0.00"
                                    formControlName="cost" list="dataType" appInputRestriction #searchField />
                            </div>
                        </div>
                        <div *ngIf="MenuItemForm.get('openPrice').value">
                            <div class="form-group">
                                <!-- <label class="pricepadding">Cost</label> -->
                                <!-- <input type="text" class="form-control" placeholder="$0.00" formControlName="price "
                                    appInputRestriction readonly/> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-6" *ngIf="
              MenuItemForm.get('remark').value ||
              (MenuItemForm.get('ingredient').value &&
                !MenuItemForm.get('pricingStyle').value) ||
              (MenuItemForm.get('main').value &&
                !MenuItemForm.get('pricingStyle').value)
            ">
                        <div class="checkbox-table accordion-checkbox">
                            <label class="customlable mb-0 menu-lbl-gap">Open Price</label>
                            <div class="custom-control custom-checkbox custom-control item">
                                <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                    formControlName="openPrice" id="openPrice" />
                                <label class="custom-control-label" for="openPrice"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-6 menu-size-price">
                        <div class="checkbox-table accordion-checkbox">
                            <label class="customlable mb-0 menu-lbl-gap">RollUp Price</label>
                            <div class="custom-control custom-checkbox custom-control item">
                                <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                    formControlName="rollUpPrice" id="rollUpPrice" />
                                <label class="custom-control-label" for="rollUpPrice"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-6 col-lg-6 menu-size-price pr-0">
                        <div class="checkbox-table accordion-checkbox">
                            <label class="customlable mb-0 menu-lbl-gap">Show On receipt </label>
                            <div class="custom-control custom-checkbox custom-control item">
                                <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                    formControlName="showsOnReceipt" id="showsOnReceipt" />
                                <label class="custom-control-label" for="showsOnReceipt"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-2 col-lg-4 menu-size-price" *ngIf="!MenuItemForm.get('remark').value">
                        <div class="checkbox-table accordion-checkbox">
                            <label class="customlable mb-0 menu-lbl-gap">Pizzeria Pricing</label>
                            <div class="custom-control custom-checkbox custom-control item">
                                <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                    formControlName="pizzeriaPricing" id="pizzeriaPricing" />
                                <label class="custom-control-label" for="pizzeriaPricing"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-8 col-lg-8 pb-3">
                        <div *ngIf="
                MenuItemForm.get('pizzeriaPricing').value &&
                !MenuItemForm.get('remark').value
              ">
                            <label class="customlable mb-0 menu-lbl-gap">Pizzeria Pricing</label>
                            <mat-form-field appearance="fill">
                                <mat-select (openedChange)="matstate($event)" type="text" class="form-control"
                                    formControlName="pizzeriaPricingDD"
                                    (selectionChange)="onchange(MenuItemForm.value)">
                                    <mat-option *ngFor="let item of pricingList" [value]="item.typeId">{{ item.typeName
                                        }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!-- end first row -->

                <!-- second row -->
                <div class="second-div">
                    <label *ngIf="
              MenuItemForm.get('main').value ||
              MenuItemForm.get('sideDish').value ||
              MenuItemForm.get('remark').value
            " class="custom-title custom-color">Behavior</label>
                    <div class="main-item row" *ngIf="
              MenuItemForm.get('main').value ||
              MenuItemForm.get('sideDish').value ||
              MenuItemForm.get('remark').value
            ">
                        <div class="col-sm-2 col-md-4 col-lg-6">
                            <div class="checkbox-table accordion-checkbox">
                                <label class="customlable mb-0 menu-lbl-gap">Discountable</label>
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                        formControlName="discountable" id="discountable" />
                                    <label class="custom-control-label" for="discountable"></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-md-4 col-lg-6">
                            <div class="checkbox-table accordion-checkbox">
                                <label class="customlable mb-0 menu-lbl-gap">Non-Sales</label>
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                        formControlName="nonSalesItem" id="nonSalesItem" />
                                    <label class="custom-control-label" for="nonSalesItem"></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-md-4 col-lg-6">
                            <div class="checkbox-table accordion-checkbox">
                                <label class="customlable mb-0 menu-lbl-gap">Tippable</label>
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                        formControlName="tippable" id="tippable" />
                                    <label class="custom-control-label" for="tippable"></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-md-4 col-lg-6">
                            <div class="checkbox-table accordion-checkbox">
                                <label class="customlable mb-0 menu-lbl-gap">Open Name</label>
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input" name="customCheckbox"
                                        formControlName="openName" id="openName" />
                                    <label class="custom-control-label" for="openName"></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-md-4 col-lg-6" *ngIf="!MenuItemForm.get('remark').value">
                            <div class="checkbox-table accordion-checkbox">
                                <label class="customlable mb-0 menu-lbl-gap">Appetizer</label>
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input" name="appetizer"
                                        formControlName="appetizer" id="appetizer" />
                                    <label class="custom-control-label" for="appetizer"></label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-sm-2 col-md-2 col-lg-4">
                            <div class="checkbox-table accordion-checkbox">
                                <label class="customlable mb-0">Taxable </label>
                                <div class="custom-control custom-checkbox custom-control item" for="taxable">
                                    <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                        formControlName="taxable" id="taxable">
                                    <label class="custom-control-label" for="taxable"></label>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-sm-2 col-md-4 col-lg-6">
                            <label class="menu-lbl-gap">Tax Rules</label>
                            <div class="tax-slide">
                                <span class="customlable customlable-bold" *ngIf="checkboxValue == true">Global</span>
                                <span class="customlable customlable-bold"
                                    *ngIf="checkboxValue == false">Override</span>
                                <mat-slide-toggle class="example-margin" (change)="toggle($event)"
                                    formControlName="taxRules" [disabled]="disabled">
                                </mat-slide-toggle>
                            </div>
                            <div class="tax-select menu-sel dropdown-up"
                                *ngIf="MenuItemForm.get('taxRules').value == false">
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="taxableData"
                                    [(ngModel)]="taxselectedItem" (onSelect)="onItemSelect(MenuItemForm.value)"
                                    (onDeSelect)="onDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                    (onDeSelectAll)="onDeSelectAll($event)" [formControl]="taxdetails">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end second row -->
            </div>
        </div>
        <div class="col-7" *ngIf="!MenuItemForm.get('remark').value">
            <label class="custom-title custom-color">Pricing Type</label>
            <div class="row">
                <div class="col-lg-3">
                    <div class="pricingToggle" style="padding-left: 5px">
                        <div class="form-group">
                            <label>Use Sizes</label>
                            <label class="switch d-block switch-width">
                                <input type="checkbox" formControlName="pricingStyle" [attr.disabled]="
                    MenuItemForm.get('remark').value
                      ? ''
                      : null || MenuItemForm.get('ingredient').value
                      ? ''
                      : null
                  " (click)="OnchangePrice()" disabled="true" [ngClass]="{ pricingStyle: PriceToggle == false }" />
                                <span class="slider round"></span>
                                <div class="toggle-button-text outshadow employee_onoff">
                                    <div class="toggle-button-text-on"></div>
                                    <div class="toggle-button-text-off"></div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 pl-0">
                    <div [style.display]="
              MenuItemForm.get('remark').value ? 'none' : 'block'
            " class="size-price">
                        <div class="job_wrap menuitemgeneralmulti" *ngIf="MenuItemForm.get('pricingStyle').value">
                            <p class="itemgeneralsizesandprices mb-2">Sizes and Prices</p>
                            <div formArrayName="sizedetails">
                                <div class="row no-gutters">
                                    <div class="col-sm-1 col-2">
                                        <button class="plus_btn inshadow" [ngClass]="{
                        'no-pointers':
                          addMulti == false || addMultiPrice == false
                      }" (click)="addItemMulti()">
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div class="col-sm-4 col-3">
                                        <label>Name</label>
                                    </div>
                                    <div class="col-sm-3 col-3">
                                        <label>Price</label>
                                    </div>
                                    <div class="col-sm-3 col-3">
                                        <label>Cost</label>
                                    </div>
                                    <div class="col-sm-1 col-1"></div>
                                </div>
                                <div class="inline-form-group">
                                    <div class="row no-gutters" *ngFor="
                      let $sizedetails of MenuItemForm.get('sizedetails')[
                        'controls'
                      ];
                      let i = index
                    " [formGroup]="$sizedetails" autocomplete="off">
                                        <div class="row no-gutters" id="{{ 'pricing' + i }}">
                                            <div class="col-sm-1 col-1"></div>
                                            <div class="col-sm-4 col-4">
                                                <!-- <input class="form-control" type="text" formControlName="sizeName" list="dataType" appInputRestriction/>
                                            <datalist id="dataType">
                                                <option *ngFor="let size of SizeList" [value]="size.name"></option>
                                            </datalist> -->

                                                <div class="input-group">
                                                    <input class="form-control" type="text" formControlName="sizeName"
                                                        list="dataType" appInputRestriction #searchField />
                                                    <datalist id="dataType">
                                                        <option class="form-control" *ngFor="let size of SizeList"
                                                            [value]="size.name"></option>
                                                    </datalist>
                                                    <div class="input-group-append">
                                                        <i class="fa fa-close close_Filter"
                                                            (click)="clearSearchField(i)" *ngIf="searchField.value"></i>
                                                    </div>
                                                </div>

                                                <div *ngIf="
                            submittedGeneral &&
                            $sizedetails.controls.sizeName.errors
                          " class="invalid-feedback price">
                                                    <div *ngIf="
                              $sizedetails.controls.sizeName.errors.required
                            ">
                                                        Name is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-3 col-3">
                                                <input type="text" class="form-control" placeholder="$0.00"
                                                    formControlName="price" appInputRestriction appDecimalLimit
                                                    (input)="onPriceChange($event.target.value, i)" />
                                                <div *ngIf="
                            submittedGeneral &&
                            $sizedetails.controls.price.errors
                          " class="invalid-feedback price">
                                                    <div *ngIf="$sizedetails.controls.price.errors.required">
                                                        Price is required
                                                    </div>
                                                </div>
                                                <div *ngIf="errorMsg != undefined">
                                                    <div *ngIf="
                              MenuItemForm.get('sizedetails').controls[i].value
                                .price != null ||
                              MenuItemForm.get('sizedetails').controls[i].value
                                .price != undefined
                            ">
                                                        <div class="error err" *ngIf="i == errorMsgPrice.index">
                                                            {{ errorMsgPrice.value }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-3 col-3">
                                                <input type="text" class="form-control" placeholder="$0.00"
                                                    formControlName="cost" appInputRestriction appDecimalLimit
                                                    (input)="onCostChange($event.target.value, i)" />
                                                <div *ngIf="errorMsg != undefined">
                                                    <div *ngIf="
                              MenuItemForm.get('sizedetails').controls[i].value
                                .cost != null ||
                              MenuItemForm.get('sizedetails').controls[i].value
                                .cost != undefined
                            ">
                                                        <div class="error" *ngIf="i == errorMsg.index">
                                                            {{ errorMsg.value }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-3 col-3" style="display: none">
                                                <input type="text" class="form-control" placeholder="$0.00"
                                                    appDecimalLimit appInputRestriction formControlName="costpercent" />
                                            </div>
                                            <div class="col-sm-1 col-1">
                                                <i class="fa fa-remove" aria-hidden="true" (click)="DeleteItem(i)"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group mt-2">
        <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveMenuItem()"
            [disabled]="buttonDisable ? true : null">
            <!-- [disabled]="buttonState ? true : null" -->
            <img src="assets/components/images/save.png" alt="employee" class="" />
            <label class="label_config">Save</label>
        </button>
    </div>
    <!-- End Ingredient(hide&&show) -->
    <!-- End Remark(hide&&show) -->
    <!--  (click)="onItemGeneralCheck('ingredient',$event)" -->
</form>