import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuRoutingModule } from './menu-routing.module';
import { MenuSizeComponent } from './menu-size/menu-size.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuSizeGeneralComponent } from './menu-size-general/menu-size-general.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MenuItemGeneralComponent } from './menu-item-general/menu-item-general.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MenuItemLogisticsComponent } from './menu-item-logistics/menu-item-logistics.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatCardModule} from '@angular/material/card';
import { EmployeeModule } from '../employee/employee.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MenuModifierComponent } from './menu-modifier/menu-modifier.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import {MatGridListModule} from '@angular/material/grid-list';
import { SalesAreaComponent } from './sales-area/sales-area.component';
import { SalesAreaLayoutComponent } from './sales-area-layout/sales-area-layout.component';
import { SalesAreaGeneralComponent } from './sales-area-general/sales-area-general.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { DragScrollModule } from 'ngx-drag-scroll';
import { SaleAreaLogisticComponent } from './sale-area-logistic/sale-area-logistic.component';
import { MenuGroupComponent } from './menu-group/menu-group.component';
import { MenuPageComponent } from './menu-page/menu-page.component';
import { ItemsComponent } from './items/items.component';
import { MenuPageGeneralComponent } from './menu-page-general/menu-page-general.component';
import { MenuGroupGeneralComponent } from './menu-group-general/menu-group-general.component';
import { ItemsLayoutComponent } from './items-layout/items-layout.component';
import { MenuPageLayoutComponent } from './menu-page-layout/menu-page-layout.component';
import { MenuGroupLayoutComponent } from './menu-group-layout/menu-group-layout.component';
import { MenuModifierGeneralComponent } from './menu-modifier-general/menu-modifier-general.component';
import { FliptableLayoutComponent } from 'src/app/elements/fliptable-layout/fliptable-layout.component';

import { DragDropBuilderComponent } from 'src/app/elements/drag-drop-builder/drag-drop-builder.component';
import { SalesAreaBuilderComponent } from './sales-area-builder/sales-area-builder.component';
import { MenuItemModifierComponent } from './menu-item-modifier/menu-item-modifier.component';
import { DynamicEditableTableComponent } from 'src/app/elements/dynamic-editable-table/dynamic-editable-table.component';
import { ItemsAssignedModifierComponent } from './items-assigned-modifier/items-assigned-modifier.component';
import { LayoutBuilderComponent } from './layout-builder/layout-builder.component';
import { AdjustmentComponent } from './adjustment/adjustment.component';
import { AdjustmentGeneralComponent } from './adjustment-general/adjustment-general.component';
import { AdjustmentDetailsComponent } from './adjustment-details/adjustment-details.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import { EventPricingComponent } from './event-pricing/event-pricing.component';
import { EventPricingGeneralComponent } from './event-pricing-general/event-pricing-general.component';
import { NgxCurrencyModule } from "ngx-currency"; 
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FliptableItemsComponent } from 'src/app/elements/fliptable-items/fliptable-items.component';


@NgModule({
  declarations: [
    MenuSizeComponent,
    MenuItemComponent,
    MenuSizeGeneralComponent,
    MenuItemGeneralComponent,
    MenuItemLogisticsComponent,
    MenuModifierComponent,
    SalesAreaComponent,
    SalesAreaLayoutComponent,
    SalesAreaGeneralComponent,
    SaleAreaLogisticComponent,
    MenuGroupComponent,
    MenuPageComponent,
    ItemsComponent,
    MenuPageGeneralComponent,
    MenuGroupGeneralComponent,
    ItemsLayoutComponent,
    MenuPageLayoutComponent,
    MenuGroupLayoutComponent,
    MenuModifierGeneralComponent,
    FliptableLayoutComponent,
    DragDropBuilderComponent,
    SalesAreaBuilderComponent,
    MenuItemModifierComponent,
    DynamicEditableTableComponent,
    ItemsAssignedModifierComponent,
    LayoutBuilderComponent,
    AdjustmentComponent,
    AdjustmentGeneralComponent,
    AdjustmentDetailsComponent,
    EventPricingComponent,
    EventPricingGeneralComponent,
    FliptableItemsComponent
  ],
  imports: [
    CommonModule,
    MenuRoutingModule,
    MatExpansionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatCardModule,
    FormsModule,
    MatSortModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    ColorSketchModule,
    EmployeeModule,
    DragDropModule,
    MatButtonToggleModule,
    MatGridListModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDnDModule.forRoot(),
    DragScrollModule,
    NgxCurrencyModule,
    MatSlideToggleModule
   
  ],
  exports:[MenuSizeComponent,
    MenuItemComponent,
    MenuSizeGeneralComponent,
    MenuItemGeneralComponent,
    MenuItemLogisticsComponent,
    MenuModifierComponent,
    FliptableLayoutComponent,
    DragDropBuilderComponent,
    SalesAreaBuilderComponent,
    DynamicEditableTableComponent,
    LayoutBuilderComponent,
    AdjustmentComponent,
    EventPricingComponent,
    EventPricingGeneralComponent,
    ]
})
export class MenuModule { }
