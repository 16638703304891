<form class="loginForm form-switch table_forms" [formGroup]="filterForm">
  <div class="row">
    <div class="col-sm col-6">
      <div class="emp-time">
        <div class="form-group">
          <label>From Date</label>
          <mat-form-field appearance="fill" class="custom-date">
            <input matInput [matDatepicker]="picker" placeholder="From Date"
              class="form-control datepicker appInputRestriction" formControlName="fromDate" (click)="picker.open()" />
            <mat-datepicker #picker class="example-header"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-sm col-6">
      <div class="emp-time">
        <div class="form-group">
          <label>To Date</label>
          <mat-form-field appearance="fill" class="custom-date">
            <input matInput [matDatepicker]="pickerA" placeholder="To Date"
              class="form-control datepicker appInputRestriction" formControlName="toDate" (click)="pickerA.open()" />
            <mat-datepicker #pickerA class="example-header"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-sm col-6" *ngIf="!discountsItem">
      <div class="emp-time">
        <div class="form-group">
          <label>Sales Areas</label>
          <ng-multiselect-dropdown [settings]="salestaxSettings" [data]="AreasFilters" [formControl]="f.AreasAreaObj"
            [disabled]="auditShow ? true : null">
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div>
    <div class="col-sm col-6" *ngIf="voidsReport">
      <div class="emp-time">
        <div class="form-group mat-box">
          <label>Approver</label>
          <ng-multiselect-dropdown [settings]="salestaxSettings" [data]="approverFilters"
            (onSelect)="OnselectApr($event)" (onDeSelect)="OnselectApr($event)" (onDeSelectAll)="OnselectAllEmp($event)"
            [(ngModel)]="approverSelectedItems" [formControl]="approverObj" [disabled]="disableSelect">
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div>
    <div class="col-sm col-6" *ngIf="discountsItem">
      <div class="emp-time">
        <div class="form-group">
          <label>Sales Areas</label>
          <ng-multiselect-dropdown [settings]="salestaxSettings" [data]="AreasFilters" [formControl]="f.AreasAreaObj"
            [disabled]="dropdown ? true : null">
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div>
    <div class="col-sm col-6" *ngIf="!discountsItem">
      <div class="emp-time">
        <div class="form-group mat-box">
          <label>Tags</label>
          <ng-multiselect-dropdown [settings]="salestaxSettings" [data]="tagFilters" [formControl]="f.tagAreaObj">
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div>
    <div class="col-sm col-6" *ngIf="discountsItem">
      <div class="emp-time">
        <div class="form-group mat-box">
          <label>Approver</label>
          <ng-multiselect-dropdown [settings]="salestaxSettings" [data]="approverFilters"
            (onSelect)="OnselectApr($event)" (onDeSelect)="OnselectApr($event)" (onDeSelectAll)="OnselectAllEmp($event)"
            [(ngModel)]="approverSelectedItems" [formControl]="approverObj" [disabled]="disableSelect">
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div>
    <div class="col-sm col-6">
      <div class="emp-time">
        <div class="form-group mat-box">
          <label>Employee</label>
          <ng-multiselect-dropdown [settings]="salestaxSettings" [data]="employeFilters"
            [formControl]="f.employeAreaObj" [(ngModel)]="employeeselectedItems" (onSelect)="OnselectEmp($event)"
            (onDeSelect)="OnselectEmp($event)" (onDeSelectAll)="OnselectAllEmp($event)"
            [disabled]="auditShow || disableInput ? true : null">
          </ng-multiselect-dropdown>
        </div>
      </div>
    </div>

    <div class="col-sm col-6">
      <div class="emp-time">
        <div class="form-group mat-box">
          <label class="hide_report">xxx</label>
          <button class="btn submit_button form-control timeEditBtn" type="submit" [disabled]="auditShow ? true : null"
            (click)="onSubmit()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</form>