import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import { BaseService } from '../base/base.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { IListInfo } from '../interfaces/iadmin';
import { GetAllTimeList, GetEmployeeClockDetails, IshiftValue, IGetAllTimeDetails, iGetNonSalEmployee, iSaveTimeClock } from '../interfaces/itime';
import { iTimeClockSocket } from '../interfaces/iWebSocket';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) { }

  GetTimeClockDetails(employee: GetAllTimeList) {
    return this.base.post('Time/GetTimeClockDetails', employee).pipe(map((response: any) => {
      return response;
    }));
  }

  GetClockListType(isTime: IshiftValue) {
    return this.base.post('Time/GetClockTypeList', isTime).pipe(map((response: any) => {
      return response;
    }));
  }
  GetAllEmployeeList(isTenantlst: iGetNonSalEmployee) {
    return this.base.post('Time/GetNonSalariedEmployee', isTenantlst).pipe(map((response: any) => {
      return response;
    }));
  }
  GetClockDetails(getEmp: GetEmployeeClockDetails) {
    return this.base.post('Time/GetTimeClockList', getEmp).pipe(map((response: any) => {
      return response;
    }));
  }
  SaveTimeClockDetails(employee: IGetAllTimeDetails) {
    return this.base.post('Time/SaveTimeClockDetails', employee).pipe(map((response: any) => {
      return response;
    }));
  }


  SaveTimeClockOrchetrtionDetails(employee: iSaveTimeClock) {
    return this.base.post('Time/SaveTimeClockDetails', employee).pipe(map((response: any) => {
      return response;
    }));
  }


  CallTimeClockWebSockets(socketVal: iTimeClockSocket) {
    return this.base.post('Handshake/SendSocketTimeClock', socketVal).pipe(map((response: any) => {
      return response;
    }));
  }
  // GetAllTimeClockDetails(employee: IGetAllTimeDetails) {
  //   return this.base.post('Time/SaveTimeClockDetails', employee).pipe(map((response: any) => {
  //     return response;
  //   }));
  // }

}
