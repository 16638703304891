import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from 'src/app/helper/helper.service';
import { iTaxHostoryReport } from 'src/app/interfaces/iadmin';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';


@Component({
  selector: 'app-tax-history-report',
  templateUrl: './tax-history-report.component.html',
  styleUrls: ['./tax-history-report.component.css']
})
export class TaxHistoryReportComponent implements OnInit {

  loginInfo:any
  @Output() public titleName = new EventEmitter();
  public employeeName: any;
 
  public firstTableShow:boolean = false
  public displayedColumns1 = [];
  public dataSource1: any;
  columns1 = [];
  private onDestroy$: Subject<void> = new Subject<void>();
  @Output() public ReceivedData = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private reportService: ReportWebSocketService) { }
  
  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.taxhistory");
    
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Tax History Reports";
    this.titleName.emit(this.employeeName)
   // this.GetTaxHistoryDetails(e)
  }

  GetTaxHistoryDetails(e?){
   
     this.reportService.TaxHistoryReport(e).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        console.log(response,'history')
        this.dataSource1 = response;
        this.firstTableShow = true;
        this.displayedColumns1 = ['effectivefrom','effectiveto','taxname','taxtype','rate', 'lastUpdatedDateTime'];
       // this.dataSource1 = ELEMENT_DATA1;
        this.columns1 = [
          { columnDef: 'effectivefrom', header: "Effective From" },
          { columnDef: 'effectiveto', header: "Effective To" },
          { columnDef: 'taxname', header: "Tax Name" },
          { columnDef: 'taxtype', header:"Tax Type"},
          { columnDef: 'rate', header:"Rate"},
          { columnDef: 'lastUpdatedDateTime', header:"Last Updated Time"},
          
        ]

      }), err => console.error('Error', err), () => console.log('HTTP request completed.');

   
  }

  // getFirstTable() {
  //   // const _History: iTaxHostoryReport = {
  //   //   fromDate:this.filterForm.value.fromDate,
  //   //   toDate:"",
  //   //   tenantId: this.loginInfo.tenantId
    
  //   // }
  //   this.reportService.TaxHistoryReport(_History).pipe(takeUntil(this.onDestroy$)).subscribe(
  //     (response) => {
  //       console.log(response,'history')
  //       this.dataSource1 = response;
  //       this.firstTableShow = true;
  //       this.displayedColumns1 = ['effectivefrom','effectiveto','taxname','taxtype','rate', 'lastUpdatedDateTime'];
  //      // this.dataSource1 = ELEMENT_DATA1;
  //       this.columns1 = [
  //         { columnDef: 'effectivefrom', header: "Effective From" },
  //         { columnDef: 'effectiveto', header: "Effective To" },
  //         { columnDef: 'taxname', header: "Tax Name" },
  //         { columnDef: 'taxtype', header:"Tax Type"},
  //         { columnDef: 'rate', header:"Rate"},
  //         { columnDef: 'lastUpdatedDateTime', header:"Last Updated Time"},
          
  //       ]

  //     }), err => console.error('Error', err), () => console.log('HTTP request completed.');

   
  // }

  //  Realese the memory
ngOnDestroy(): void {
  this.onDestroy$.next();
}
}
