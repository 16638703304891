import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
export interface PeriodicElement {
  id?: number;
  name?:any;
  quantity?:any;
  amount?:any;
  trasType?:any;
  check?:any;
  employee?:any;
  timeDate?:any;
  item?:any;
  move?:any;
  total?:any;
  discountname?:any;
  ofdiscounts?:any;
  category?:any;
  groupHeader?:any;
  grossSales?:any;
  netSales?:any;
  categorySale?:any;
  employeeName?: any;
  sales?: any;
  totalCheck?:any
  address?: any;
  city?: any;
  email?: any;
  firstName?: any;
  lastName?: any;
  notes?: any;
  phone?: any;
  state?: any;
  zip?: any;
}

@Component({
  selector: 'app-giftcard-audit-report',
  templateUrl: './giftcard-audit-report.component.html',
  styleUrls: ['./giftcard-audit-report.component.css']
})
export class GiftcardAuditReportComponent implements OnInit {
  @Input() displayedColumns;
  @Input() Filtertransactions;
  @Input() transactions;
  @Input() dataSource3;
  @Input() displayedColumns3;
  @Input() columns3;
  @Input() auditName;
  @Input() thirdTableShow;
  // @Input() CustomerDetails;
  @Input() subTotal;
  @Input() TipsAmount;
  @Input() discountTotal;
  @Input() netTotal;
  @Input() auditEmployee;
  @Input() auditDate;
  @Input() auditTime;

  @Output() public tableRowvalue = new EventEmitter;
  dyn_img : any = 'signature1.png'
  discountValue :any = 0.00;
  taxValue : any = 0.00;
  constructor() { }
  CustomerDetails: PeriodicElement[] = [
    { address: '137 N Corneria Blvd', city: 'Kichina', email: 'FalcoisaButt@aqua.ucom', firstName: 'Fox', lastName: 'McCloud', notes: 'kjkjk', phone: '505-334-855', state: 'UN', zip: '54', check: '0.8F04F6AB-DA4B'}
   ];
  ngOnInit(): void {
  
   // console.log(this.CustomerDetails,"CustomerDetails");
   // this.CustomerDetails = this.CustomerDetails.filter(x => x.check == this.auditName)
     // console.log(this.CustomerDetails,"bbbb");

    
  }
  /** Gets the total cost of all transactions. */
  // getTotalCost() {
  //   return this.transactions.map(t => t.cost).reduce((acc, value) => acc + value, 0);
  // }
  // getTotalCost1() {
  //   let value =  this.transactions.map(t => t.cost).reduce((acc, value) => acc + value, 0) - (this.discountValue + this.taxValue);
  //   return value
  // }
  onChangeRowValue(e) {
    this.tableRowvalue.emit(e)
  }

}