<div class="row top-sec sales-area-builder-sec">
  <div class="form-group global-form col-sm-12">
    <button [ngClass]="{'submit_button_error': isInvalid==true}"
      class="btn  btn-sm float-right submit_button global_submit mt-0" type="submit" (click)="OnSave();">

      <!-- [disabled]="buttonStatus ?  true : null"  -->
      <img src="assets/components/images/save.png" alt="employee" class="">
      <label class="label_config">Save</label>
    </button>
  </div>
</div>

<div class=" second-sec">
  <div class="row sales-area-section">
    <div class="col-sm-4 left-sec">
      <mat-accordion class="sales-top-space">
        <mat-expansion-panel *ngFor="let item of ['Sales Area', 'Menu Group', 'Menu Page']; first as isFirst"
          [expanded]="isFirst" class="outshadow ">
          <mat-expansion-panel-header (click)="onChangeAccordion($event)">
            <mat-panel-title>
              <span class="mt-1">{{item}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf="item == 'Sales Area'">
            <div *ngIf="salesShow">
              <div class="Table_Toggle">
                <div>
                  <div class="flip-table" *ngIf="!modifierItemsData">
                    <span class="text-box">{{employee}}</span> &nbsp;
                    <button class="plus_btn outshadow" (click)="addEmployee(1,2,3,4); scrollToTop()"><i
                        class="fa fa-plus"></i></button>
                  </div>
                  <hr class="linebar" *ngIf="!modifierItemsData">
                </div>
                <app-fliptable [dataSource]="dataSourceArea" #trigger [displayedColumns]="displayedAreaColumns"
                  [dealerItem]="tableRowColor" [columns]="columnsArea" (tableEmpName)="getTableName($event)"
                  (dataEmitArea)="getDataArea($event)" (clearDetails)="addEmployee($event)" [DatasalesArea]="salesArea"
                  [DataCom]="employee" [EmployeeTableData]="SalesTableData" (breakActive)="checkValueArea($event)"
                  (hierarchyData)="getTableDet($event)" (btnActive)="btnActiveStatus($event)"></app-fliptable>
              </div>
            </div>
          </div>
          <div *ngIf="item == 'Menu Group'">
            <div *ngIf="groupShow">
              <div class="Table_Toggle">
                <div>
                  <div class="flip-table" *ngIf="!modifierItemsData">
                    <span class="text-box">{{employee}}</span> &nbsp;
                    <button class="plus_btn outshadow" (click)="addEmployee(1,2,3,4)"><i
                        class="fa fa-plus"></i></button>
                  </div>
                  <hr class="linebar" *ngIf="!modifierItemsData">
                </div>
                <app-fliptable [dataSource]="dataSourceGroup" #trigger [displayedColumns]="displayedGroupColumns"
                  [dealerItem]="tableRowColor" [columns]="columnsGroup" (tableEmpName)="getTableName($event)"
                  (dataEmitArea)="getDataArea($event)" (breakActive)="checkValueGroup($event)"
                  (hierarchyData)="getTableDet($event)" (clearDetails)="addEmployee($event)" [DatasalesArea]="salesArea"
                  [DataCom]="employee" [EmployeeTableData]="GroupTableData" (btnActive)="btnActiveStatus($event)">
                </app-fliptable>
              </div>
            </div>
          </div>
          <div *ngIf="item == 'Menu Page'">
            <div *ngIf="pageShow">
              <div class="Table_Toggle">
                <div>
                  <div class="flip-table" *ngIf="!modifierItemsData">
                    <span class="text-box">{{employee}}</span> &nbsp;
                    <button class="plus_btn outshadow" (click)="addEmployee(1,2,3,4)"><i
                        class="fa fa-plus"></i></button>
                  </div>
                  <hr class="linebar" *ngIf="!modifierItemsData">
                </div>
                <app-fliptable [dataSource]="dataSourcePage" #trigger [displayedColumns]="displayedPageColumns"
                  [dealerItem]="tableRowColor" [columns]="columnsPage" (tableEmpName)="getTableName($event)"
                  [DataCom]="employee" (dataEmitArea)="getDataArea($event)" (hierarchyData)="getTableDet($event)"
                  (breakActive)="checkvaluePage($event)" (clearDetails)="addEmployee($event)"
                  [EmployeeTableData]="PageTableData" [DatasalesArea]="salesArea" (btnActive)="btnActiveStatus($event)">
                </app-fliptable>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="col-sm-8 right-sec" #scroll>
      <div *ngIf="salesShow">
        <app-sales-area [getDataSource]="getAreaDetails" (generalValueemit)="getTableValue($event)"
          (MenuNameEmit)="onChangeAccordion($event)" [buttonState]="buttonDisableVal"
          (saleareasessData)="getareabuildsessData($event)">
        </app-sales-area>
      </div>
      <div *ngIf="groupShow">
        <app-menu-group [DatasalesArea]="salesArea" [getdatagroup]="getAreaDetails"
          (MenuNameEmit)="onChangeAccordion($event)" (generalgrpemit)="getGroupTableValue($event)"
          [buttonState]="buttonDisableVal" (mnugrpsessData)="getareabuildsessData($event)"></app-menu-group>
      </div>
      <div *ngIf="pageShow">
        <app-menu-page [DatasalesArea]="salesArea" [getdatapage]="getAreaDetails"
          (MenuNameEmit)="onChangeAccordion($event)" (generalpageemit)="getPageTableValue($event)"
          [buttonState]="buttonDisableVal" (mnupagesessData)="getareabuildsessData($event)"></app-menu-page>
      </div>
    </div>

    <!-- <div *ngIf="salesArea == ('Items') && salesArea != ('Group') && salesArea != ('Page') && salesArea != ('Area')">
        <app-items [DatasalesArea]="salesArea" [DataClearForm]="clearForm" (MenuNameEmit)="onChangeAccordion($event)">
        </app-items>
      </div> -->
  </div>
</div>
<!-- [DataSourceInput]="getDataValue" -->