import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { IGetPermission, IPermission } from 'src/app/interfaces/iadmin';
import { GetEmployee, IRolePermission } from 'src/app/interfaces/iemployee';
import { AdminService } from 'src/app/services/admin.service';
import { DialogService } from 'src/app/services/dialog.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { TraceLoggerService } from 'src/app/services/trace-logger.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PermissionsModalComponent } from 'src/app/elements/permissions-modal/permissions-modal.component';

@Component({
  selector: 'app-employee-permission',
  templateUrl: './employee-permission.component.html',
  styleUrls: ['./employee-permission.component.css'],
})
export class EmployeePermissionComponent implements OnInit {
  PermissionForm: FormGroup;
  buttonDisable: boolean = false;
  submittedPermission = false;
  loginInfo: any;
  roleId: any;
  userId: any;
  count: any = 1;
  RolePermissionList: any = [];
  checkedPermission: any = [];
  isTimeClockValue: boolean = false;
  permissionData: any;
  permissionValues = [];
  jsonArrayValue: any = [];
  Permissionactive = false;
  rolePosition: any;
  checkPositionValue: any;
  defaultIndicator: any;
  jobPositionarray: any = [];
  defaultroleId: any;
  wagesMultiList = [];
  empValue: any;
  empRoleID: any;
  permissionValue: any;
  OverrideCurrent: any;
  disabled: boolean;
  isOverride: boolean = false;
  rolePosID: any;
  rolePermissionDD: boolean = false;
  roleData: any;
  timeCLockData: any;
  AllCheckBox: any;
  AllCheckboxValid: boolean = false;
  addClick: any = 0;
  bsModalRef?: BsModalRef;
  isDisabled: boolean = false;
  isAllPermission: boolean = false;
  isPermission: boolean = false;

  employeeTimeclockdata: any = [];
  @Output() public permissionItemValue = new EventEmitter();
  @Output() public StoreempperData_child = new EventEmitter();

  @Input() public pDataemit = [];
  @Input() public roleDataemit;
  @Input() public wagesDataemit;

  @Input() public getRoleData;
  @Input() public permissionDropItem;
  @Input() public tableRowId;

  //@Input() public buttonState;
  allPermission = [];
  isSelectedCount: any;
  isNotSelectedCount: any;
  private onDestroy$: Subject<void> = new Subject<void>();
  dff: any;
  @Output() matopen: EventEmitter<any> = new EventEmitter()
  matstate($event) {
    this.matopen.emit($event)
  }
  constructor(
    private permissionDetails: EmployeeService,
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private helper: HelperService,
    private adminService: AdminService,
    private LoggerService: TraceLoggerService,
    private dialogser: DialogService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData3', null);
    this.buttonDisable = false;
    this.defaultIndicator = 1;
    this.PermissionForm = this.formBuilder.group({
      primaryRoleId: [0],
      primaryRolePermissionList: [0],
      OverrideCurrent: [false],
      timeClockOnly: [false],
      allPermission: [false]
    });
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.addClick = 1;

    this.permissionDetails.wagesCheckData
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => (this.wagesMultiList = data)),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
    this.permissionDetails.empParentData
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((empdata) => (this.empValue = empdata)),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
    this.permissionDetails.wagesRoleData
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((roledata) => (this.empRoleID = roledata)),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
    this.onGetdropdown();
  }
  get PermissionData() {
    return this.PermissionForm.controls;
  }

  @Input()
  public set DataPermission(perDataemit) {
    this.pDataemit = perDataemit;

  }

  @Input()
  public set DataRole(rDataemit) {
    this.roleDataemit = rDataemit;
    if (
      this.roleDataemit != undefined &&
      this.roleDataemit != null &&
      this.roleDataemit != ''
    ) {
      this.PermissionForm.controls['primaryRoleId'].setValue(
        this.roleDataemit,
        { onlySelf: true }
      );
      //this.OnRolePositionValue();
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set DataWages(wagesDataemit) {
    this.wagesDataemit = wagesDataemit;
  }

  @Input()
  public set DataGetRole(getRData) {
    this.roleData = 0;
    if (getRData != undefined && getRData != null && getRData != '') {
      this.getRoleData = getRData;
      this.AllCheckBox = false;
      this.roleDataemit = getRData;
      this.PermissionForm.controls['primaryRoleId'].setValue(getRData, {
        onlySelf: true,
      });
      this.rolePermissionDD = false;
      this.OnRolePositionValue();
    } else {
      this.jsonArrayValue = [];
    }
  }

  GetRoleUsingAccordian(roleId) {
    if (roleId != undefined && roleId != null && roleId != '') {
      this.getRoleData = roleId;
      this.roleDataemit = roleId;
      this.PermissionForm.controls['primaryRoleId'].setValue(roleId, {
        onlySelf: true,
      });
      this.rolePermissionDD = false;
      this.OnRolePositionValue();
    } else {
      this.jsonArrayValue = [];
    }
  }

  @Input()
  public set DataDropValue(pDropItem) {
    this.permissionDropItem = pDropItem;
    if (
      this.permissionDropItem != undefined &&
      this.permissionDropItem != null &&
      this.permissionDropItem != ''
    ) {
      this.onGetdropdown();
      this.onEditChangeRole(pDropItem[0].value);
    }
  }

  @Input()
  public set DataIdx(tableRowId) {
    this.tableRowId = tableRowId;
    if (
      this.tableRowId != undefined &&
      this.tableRowId != null &&
      this.tableRowId != ''
    ) {

      this.getEmployee(this.tableRowId);
    }
  }

  onGetdropdown() {
    this.pDataemit = this.permissionDropItem;
  }

  onGetdropdownUsingAccordian(roleList) {
    //console.log(roleList, 'roleList_33');
    this.pDataemit = roleList;
    this.PermissionForm.value.primaryRoleId = this.pDataemit[0].label;
    this.PermissionForm.patchValue({
      primaryRoleId: this.pDataemit[0].label
    });

    //console.log(this.PermissionForm, "onPatch2");

  }

  onSavePermission(userId) {
    this.submittedPermission = true;
    // stop here if form is invalid
    if (this.PermissionForm.invalid) {
      return;
    }
    //  if (this.RolePermissionList.length > 0) {
    this.onSaveCompleteForm(this.PermissionForm.value, 3, userId);
    //}
  }

  onSaveCompleteForm(employee, sectionId: any, userId): void {
    if (this.wagesDataemit == undefined || this.wagesDataemit == null) {
      this.wagesDataemit = '';
    }
    if (sectionId == 3) {
      this.wagesDataemit = userId;
      employee.userId = this.wagesDataemit
        ? this.wagesDataemit
        : this.tableRowId;
      employee.sectionId = 3;
      employee.createdby = this.loginInfo.userId;
      employee.roleId = this.roleData;
      employee.tenantId = this.loginInfo.tenantId;
      employee.employeeRolePermissionList = this.RolePermissionList;
    }
    // if (this.RolePermissionList.length > 0) {

    console.log(employee, 'employee');
    this.permissionDetails
      .UserEmployee(employee)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (response) => {
        if (sectionId == 3) {
          this.permissionValue = response;
          this.submittedPermission = false;

          await this.dialogser.ClearSession();
          this.helper.setValue('GetSessionData3', employee);
          this.StoreempperData_child.emit({
            data: employee,
            event: 'GeneralPage',
            val: false,
          });
        }
        this.permissionItemValue.emit(this.permissionValue.id);
      }),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
    // }
  }

  onChangeRole(e) {
    this.rolePosID = Number(e.value);
    if (e.value > 0) {
      this.rolePermissionDD = true;
      this.OnRolePositionValue();
    }
  }

  onEditChangeRole(e) {
    this.rolePosID = Number(e);
    if (e > 0) {
      this.rolePermissionDD = true;
      this.OnRolePositionValue();
    }
  }

  onRolePatchFunction(roleVal) {

    this.permissionData = roleVal.filter((x) => x.permissionId != 1);
    this.isSelectedCount = this.permissionData.filter(
      (x) => x.isSelected == true
    ).length;
    this.isNotSelectedCount = this.permissionData.filter(
      (x) => x.isSelected == false
    ).length;
    this.RolePermissionList = [];

    const map = new Map();
    this.jsonArrayValue = [];
    for (const item of this.permissionData) {
      if (!map.has(item.categoryId)) {
        map.set(item.categoryId, true); // set any value to Map
        this.jsonArrayValue.push({
          categoryId: item.categoryId,
          categoryName: item.categoryName,
          categoryImage: item.categoryImage,
          permissionId: item.permissionId,
          permissionName: item.permissionName,
          isSelected: item.isSelected,
        });
      }
      if (item.isSelected) {
        this.RolePermissionList.push(item.permissionId);
      }
      if (item.isSelected == false) {
        this.checkedPermission.push(item);
      }
    }

    // console.log(this.permissionData,'permissionData')
    // this.permissionValues = this.permissionData.filter(x => x.permissionId != 52)
    this.permissionValues = this.permissionData;
    if (this.defaultIndicator == 1) {
      this.onIndicatorChange(1, 0);
    }
  }
  OnRolePositionValue() {
    try {
      this.permissionData = [];
      this.permissionValues = [];
      this.RolePermissionList = [];
      this.jsonArrayValue = [];
      if (this.rolePermissionDD == true) {
        this.roleData = this.rolePosID;
      } else {
        this.roleData = this.roleDataemit;
        this.rolePermissionDD == false;
      }


      const role: IRolePermission = {
        roleId: this.roleData,
        employeeId: this.wagesDataemit ? this.wagesDataemit : this.tableRowId,
        tenantId: this.loginInfo.tenantId,
      };

      const rolepermission: IGetPermission = {
        id: this.roleData,
        tenantId: this.loginInfo.tenantId,
      };

      if (this.roleData > 0) {
        this.permissionDetails
          .ValidateRolePermission(role)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((response) => {
            if (response[0].isData == 0) {
              this.adminService
                .GetDefaultPermission(rolepermission)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((res) => {

                  this.PermissionForm.patchValue({
                    timeClockOnly: res.timeClockOnly[0].timeClockOnly

                  });
                  if (res.timeClockOnly[0].timeClockOnly == true) {
                    this.PermissionForm.controls['allPermission'].disable();
                  }
                  else {
                    this.PermissionForm.controls['allPermission'].enable();
                  }

                  this.onRolePatchFunction(res.permission);

                }),
                (err) => console.error('Error', err),
                () => console.log('HTTP request completed.');
            } else {
              this.permissionDetails
                .GetRolePermissionDefault(role)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((res1) => {
                  this.onRolePatchFunction(res1.filter(x => x.isActive == true));
                }),
                (err) => console.error('Error', err),
                () => console.log('HTTP request completed.');
            }
          }),
          (err) => console.error('Error', err),
          () => console.log('HTTP request completed.');
      }
    } catch (error) {
      console.error(error);
      console.log(error);
    } finally {
    }
  }

  onIndicatorChange(e, index) {
    if (this.isSelectedCount == 52) {
      this.AllCheckBox = true;
    } else if (this.isSelectedCount == 0) {
      if (this.addClick == 0) {
        this.AllCheckBox = true;
      }
    } else {
      this.AllCheckBox = false;
    }
    this.permissionValues = this.permissionData
      .filter((x) => x.categoryId == e);
    if (this.isTimeClockValue == true) {
      for (let i = 0; i < this.permissionData.length; i++) {
        this.permissionData[i].isSelected = false;
        this.RolePermissionList = [];
        this.isSelectedCount = 0;
      }
    }
    this.defaultIndicator = 1;
  }

  onCheckChangeAll(event, id) {
    if (id == 1 && event.target.checked == true) {
      this.count = 1;
      this.AllCheckboxValid = true;
      this.AllCheckBox = true;
      this.isNotSelectedCount = 0;
      for (let i = 0; i < this.permissionData.length; i++) {
        this.permissionData[i].isSelected = true;
        if (this.permissionData[i].isSelected == true) {
          this.RolePermissionList.push(this.permissionData[i].permissionId);
          this.isSelectedCount = this.count++;
        }
      }
    } else if (id == 1 && event.target.checked == false) {
      this.AllCheckboxValid = false;
      this.AllCheckBox = false;
      this.isNotSelectedCount = this.permissionData.filter(
        (x) => x.isSelected == false
      ).length;
      for (let i = 0; i < this.permissionData.length; i++) {
        this.permissionData[i].isSelected = false;
        this.RolePermissionList = [];
        this.isSelectedCount = 0;
      }
    }
  }

  onCheckChange(event, id) {
    if (event.target.checked) {
      this.RolePermissionList.push(Number(event.target.value));
      let a = this.permissionValues.find(
        (x) => x.permissionId == event.target.value
      );
      a.isSelected = true;
      this.count--;
      this.AllCheckboxValid = true;
      this.isNotSelectedCount--;
      this.isSelectedCount++;
    } else {
      this.isSelectedCount--;
      this.isNotSelectedCount++;
      this.RolePermissionList.forEach((value, index) => {
        if (value == event.target.value)
          this.RolePermissionList.splice(index, 1);
      });
      let a = this.permissionValues.find(
        (x) => x.permissionId == event.target.value
      );
      a.isSelected = false;
      this.count++;
      this.AllCheckboxValid = false;
    }

    if (id == 1 && event.target.checked == true) {
      for (let i = 0; i < this.permissionData.length; i++) {
        this.permissionData[i].isSelected = true;
        if (this.permissionData[i].isSelected == true) {
          this.RolePermissionList.push(this.permissionData[i].permissionId);
        }
      }
    } else if (id == 1 && event.target.checked == false) {
      for (let i = 0; i < this.permissionData.length; i++) {
        this.permissionData[i].isSelected = false;
        this.RolePermissionList = [];
      }
    }
    if (
      (this.AllCheckboxValid == true && this.count == 1) ||
      this.isNotSelectedCount == 0
    ) {
      this.AllCheckBox = true;
    } else if (
      (this.AllCheckboxValid == false && this.count > 1) ||
      this.isNotSelectedCount > 0
    ) {
      this.AllCheckBox = false;
    }
  }

  getEmployee(empid) {
    this.defaultIndicator = 1;
    const employee: GetEmployee = {
      employeeId: empid,
    };
    this.permissionDetails.GetEmployee(employee).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      this.employeeTimeclockdata = response[0].employeeJobPositionList;
      console.log(response[0], 'empper')
      var datax = response[0].employeeJobPositionList.filter(x => x.primaryPosition == true);

      if (datax[0].timeClockOnly != undefined) {
        this.PermissionForm.patchValue({
          timeClockOnly: datax[0].timeClockOnly
        });
        if (datax[0].timeClockOnly == true) {
          this.isDisabled = true
          this.PermissionForm.controls['allPermission'].disable();

        }
        else {
          this.isDisabled = false
          this.PermissionForm.controls['allPermission'].enable();

        }
      }
      this.PermissionForm.controls['OverrideCurrent'].setValue(false);


    }),
      (err) => console.error('Error', err), () => console.log('HTTP request completed.');
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.PermissionForm.patchValue(this.helper.getValue('GetSessionData3'));
    this.StoreempperData_child.emit(this.PermissionForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData3', data);
    this.helper.setValue('OnDirty3', true);
    this.StoreempperData_child.emit({
      data: data,
      event: 'GeneralPage',
      val: true,
    });
  }

  onCheckTimeOnly(e?) {
    if (e.currentTarget.checked == true) {
      this.openModal();
    } else {
      this.isDisabled = false;
      this.isPermission = false;
      this.PermissionForm.controls['allPermission'].enable();

    }
  }

  openModal() {
    this.bsModalRef = this.modalService.show(PermissionsModalComponent, {
      class: 'modalComp modal-popUp modal-l',
      backdrop: 'static',
      keyboard: true,
    });
    this.bsModalRef.content.messageEvent.subscribe((data) => {
      this.dff = data;
      if (this.dff == false) {
        this.isOverride = false
        this.isDisabled = false;
        this.isPermission = false;
        this.PermissionForm.controls['timeClockOnly'].setValue(false);
      } else {
        this.isAllPermission = true;
        this.isOverride = true
        this.AllCheckboxValid = false;
        this.AllCheckBox = false;
        this.isNotSelectedCount = this.permissionData.filter(
          (x) => x.isSelected == false
        ).length;
        for (let i = 0; i < this.permissionData.length; i++) {
          this.permissionData[i].isSelected = false;
          this.RolePermissionList = [];
          this.isSelectedCount = 0;
        }
        this.isDisabled = true;
        this.isPermission = true;
        this.PermissionForm.controls['OverrideCurrent'].setValue(false);
        this.PermissionForm.controls['allPermission'].disable();
      }
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
