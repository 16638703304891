<div class="credit_tsys">
    <form class="tsysForm loginForm" [formGroup]="CreditCardTsysForm" (change)="onchange(CreditCardTsysForm.value)">
        <div class="row  mb-2">
            <div class="col-sm-4">
                <div class="card  credit-header ">
                    <div class="card-body p-2">
                        <h6 class="card-text mb-0 ">TSYS</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Authentication code</label><label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="totalPassword" (input)="getAuthCodeValue($event)"
                        placeholder="15 digits" maxlength="15" (keypress)="numberOnly($event)" appInputRestriction
                        [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.totalPassword.errors) || (requiredError && GeneralData.totalPassword.errors)}" (keypress)="Highlight()" (focusout)="CreditCardValid()"/>
                        <div *ngIf="submittedGeneral && GeneralData.totalPassword.errors" class="invalid-feedback">
                            <div *ngIf="(submittedGeneral && GeneralData.totalPassword.errors) || (requiredError && GeneralData.totalPassword.errors)">Authentication code is required</div>
                        </div>
                        <div class="error">{{errorValue}}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Host Capture POS ID</label><label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="hostCapture_POSID" 
                    maxlength="10" (keypress)="numberOnly($event)" appInputRestriction
                        placeholder="1-10 digits" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.hostCapture_POSID.errors) || (requiredError && GeneralData.hostCapture_POSID.errors)}" (keypress)="Highlight()" (focusout)="CreditCardValid()"/>
                        <div *ngIf="(submittedGeneral && GeneralData.hostCapture_POSID.errors) || (requiredError && GeneralData.hostCapture_POSID.errors)" class="invalid-feedback">
                            <div *ngIf="GeneralData.hostCapture_POSID.errors.required">Host Capture POS ID is required</div>
                        </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Auto Batch Time</label><label class="text-danger">*</label>
                    <ngx-mat-timepicker-field [controlOnly]="true" formControlName="autoBatchTime" class="ngxMatTime"
                    [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.autoBatchTime.errors) || (requiredError && GeneralData.autoBatchTime.errors)}" (keypress)="Highlight()" (focusout)="CreditCardValid()">
                    <!-- <div *ngIf="submittedGeneral && GeneralData.autoBatchTime.errors" class="invalid-feedback">
                        <div *ngIf="GeneralData.autoBatchTime.errors.required">timeIn is required</div>
                    </div> -->
                    </ngx-mat-timepicker-field>
                    <div *ngIf="(submittedGeneral && GeneralData.autoBatchTime.errors) || (requiredError && GeneralData.autoBatchTime.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.autoBatchTime.errors.required">Auto Batch Time is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8" >
                <!-- <label class="customlable mt-2">#Of</label>
                <div class="row">
                    <div class="col-sm-2 col">
                        <div class="form-group">
                            <label class="customlable mt-0">fixed</label>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input " name="of_Fixed"
                                        formControlName="of_Fixed" id="Allowsome">
                                    <label class="custom-control-label mb-0"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col">
                        <div class="form-group">
                            <label class="customlable mt-0">Mobile</label>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input " name="mobile"
                                        formControlName="mobile" id="Allowsome">
                                    <label class="custom-control-label mb-0"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mt-0">Total Lanes</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="total_Lanes"
                            appInputRestriction/>
                          
                        </div>
                    </div>
                </div> -->
                <div class="row"   >
                <div class="col-sm-4">
                    <div class="form-group">
                        <label># of Wired Devices</label>
                        <input type="text" class="form-control input-lg inputbox_login" (keydown)="checkInput($event, inputText)" #inputText  
                        appInputRestriction formControlName="wired_Devices" placeholder="0-100" (keypress)="numberOnly($event)"
                        (input)="onSum()"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label># of Wireless Devices</label>
                        <input type="text" class="form-control input-lg inputbox_login" (keydown)="checkInput($event, inputText1)" #inputText1
                            formControlName="wireless_Devices" placeholder="0-100" (keypress)="numberOnly($event)" appInputRestriction
                            (input)="onSum()"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="customlable mt-0">Total Lanes</label>
                        <input type="text" class="form-control input-lg inputbox_login" formControlName="total_Lanes"
                        appInputRestriction readonly/>
                      
                    </div>
                </div>
                </div>
            </div>

        </div>

        <label *ngIf="emvClicked == true">Store and Forward(EMV only)</label>

        <div class="row" *ngIf="emvClicked == true">
            <div class="col-sm-3 mt-1">
                <div class="form-group">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="isStoreandForward_EMVonly"
                                formControlName="isStoreandForward_EMVonly" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="storeValue"
                        placeholder="$0-00" appInputRestriction/>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="form-group col-sm-12">
                <button class="btn btn-sm float-right submit_button" type="submit" (click)="onSaveGeneral()">
                        
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
                </button>
            </div>
        </div> -->
    </form>
</div>
