import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HierachyStoreComponent } from './hierachy-store/hierachy-store.component';
import { HierachyDealerComponent } from './hierachy-dealer/hierachy-dealer.component';
import { HierachyCompanyComponent } from './hierachy-company/hierachy-company.component';

import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion'
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { MatSelectFilterModule } from 'mat-select-filter';

import { HierachyRoutingModule } from './hierachy-routing.module';
import { MatSelectModule } from '@angular/material/select';
import { EmployeeModule } from '../employee/employee.module';
import { DealerComponent } from './dealer/dealer.component';
import { CompanyComponent } from './company/company.component';
import { StoreComponent } from './store/store.component';
import { NgxCurrencyModule } from "ngx-currency"; 
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';

const appRoutes: Routes = []

@NgModule({
  declarations: [
    HierachyStoreComponent,
    HierachyDealerComponent,
    HierachyCompanyComponent,
    DealerComponent,
    CompanyComponent,
    StoreComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    DataTablesModule,
    HierachyRoutingModule, 
    EmployeeModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    NgxIntlTelInputModule,
    FlexLayoutModule,
    FormsModule,
    RouterModule.forChild(appRoutes),
    MatSelectFilterModule,
    NgxCurrencyModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule
  ],
  exports:[HierachyStoreComponent, HierachyDealerComponent, HierachyCompanyComponent,
    DealerComponent,CompanyComponent,StoreComponent],
})
export class HierachyModule { }
 