import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { MenuService } from 'src/app/services/menu.service';
import { GetSaleAreaGeneral, GetSaleAreaGeneralDD } from 'src/app/interfaces/imenu';
import { TraceLoggerService } from 'src/app/services/trace-logger.service';
import { DialogService } from 'src/app/services/dialog.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { SelectAdjustGeneral } from 'src/app/interfaces/iadjustment';
import { adjustmentService } from 'src/app/services/adjustment.service';
import { IRole } from 'src/app/interfaces/iadmin';
import { AdminService } from 'src/app/services/admin.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-sales-area-general',
  templateUrl: './sales-area-general.component.html',
  styleUrls: ['./sales-area-general.component.css']
})

export class SalesAreaGeneralComponent implements OnInit {
  SaleAreaGeneralForm: FormGroup;
  buttonDisable: boolean = false;
  public SaleAreaGenDD: GetSaleAreaGeneralDD[]; // create a variable of type tbl_language object
  submittedSaveGeneral = false;
  isSubmitted = false;
  isValidated = false;
  loginInfo: any;
  RadioButton: any;
  RadioButton1: any;
  TicketViewRef: any;
  errorMessage: string;
  tickref: any;
  payref: any;
  sendref: any;
  taxref: any;
  errorValue: any;
  requiredError: boolean = false;
  tenantId: any;
  areaValue = [];
  checked = true;
  disabled = false;
  taxdetailsdata: Array<any> = [];
  selectedItem: Array<string> = [];
  dropdownSettings: any = {};
  adjustData = [];
  TaxableRefDetails: FormControl = new FormControl('');
  checkboxValue: boolean = true;
  // matopen:any = false;
  public isActiveInactivePerm: boolean;

  @Output() public isInvalid = new EventEmitter();
  @Output() public generalItem = new EventEmitter();
  @Output() public saleareagenData_subchild = new EventEmitter();
  @Output() matopen:EventEmitter<any> = new EventEmitter()


  public variables: any = []
  filteredListret: any;

  //@Input() public buttonDis;
  @Input()
  public set MenuRowItem(tableRowData) {
    if (tableRowData.id != undefined && tableRowData.id != null && tableRowData.id != ""
      && tableRowData.id != 0) {
      const ItemList: GetSaleAreaGeneral = {
        id: tableRowData.id,
        tenantId: this.helper.getValue('LoginInfo').tenantId
      }
      // this.LoggerService.SaveLoggerInformation(this.loginInfo.tenantId, 'info', 'SalesAreaGeneral_GetSalesAreaGeneral',
      // JSON.stringify(ItemList), this.loginInfo.userId)
      this.ItemLog.GetSalesAreaGeneral(ItemList)
        .subscribe(res => {
          if (res[0] != undefined && res[0] != null && res[0] != "") {
            this.SaleAreaGeneralForm.patchValue(res[0]);

            if (res[0].AreaStyle_Bar == true) {
              this.RadioButton = "Bar";
            }
            else if (res[0].AreaStyle_QSR == true) {
              this.RadioButton = "QRS";
            }
            else if (res[0].AreaStyle_DriveThru == true) {
              this.RadioButton = "DriveThru";
            }

            if (res[0].TaxableRef == 'Taxable') {
              this.SaleAreaGeneralForm.get('TaxableRef').setValue(true);
              this.checkboxValue = true;
            }
            else {
              this.SaleAreaGeneralForm.get('TaxableRef').setValue(false);
              this.checkboxValue = false;
            }

            var taxjsonObject = res[0].TaxableRefDetails;
            if (taxjsonObject != null && taxjsonObject != '') {
              this.selectedItem = JSON.parse(taxjsonObject);
            }
            else {
              this.selectedItem = [];
            }
          }
        })
    }
  }

  constructor(
    private objItem: MenuService,
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private ItemLog: MenuService,
    private helper: HelperService,
    private objAdj: adjustmentService,
    private adminser: AdminService,private permser: PermissionService
  ) { }

  @ViewChild('select') select: MatSelect;

  ngOnInit(): void {
    this.isActiveInactivePerm = this.permser.isActiveInactive;

    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.SaleAreaGeneralForm = this.formBuilder.group({
      id: [0],
      name: [null, Validators.required],
      isActive: [true],
      AreaStyle_Bar: [false],
      AreaStyle_QSR: [false],
      AreaStyle_DriveThru: [false],
      TicketViewRef: [null],
      OnPaymentRef: [null],
      OnSendingRef: [null],
      TaxableRef: [null],
      Cash_Receipt: [''],
      CC_Receipt: [''],
      Auto_Adjustment: [false],
      ItemSize_Credit: [false],
      RadioButton: [null, Validators.required],
      CreatedBy: [0],
      userid: [0],
      TaxableRefDetails: new FormControl(''),
      Adjustment_ID: [0]
    })
    this.ItemLog.SalesValue.subscribe(data => this.areaValue = data);
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getTicketView();
    this.getPaymentView();
    this.getOnsendingView();
    this.getTaxableView();

    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Select All',
      allowSearchFilter: true,
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 3,
    };
    this.GetAdjustmentGeneralList();
    this.GetTaxRefList();
    this.SaleAreaGeneralForm.get('TaxableRef').setValue(true);
  }
  matstate($event){
    this.matopen.emit($event)
  }
  public onItemSelect(item: any) {
    //console.log('onItemSelect', item);
    //console.log('selectedItem', this.selectedItem);
    this.onchange(this.SaleAreaGeneralForm.value);
  }

  public onDeSelect(item: any) {
    this.onchange(this.SaleAreaGeneralForm.value);
  }

  public onSelectAll(items: any) {
    this.onchange(this.SaleAreaGeneralForm.value);
  }

  public onDeSelectAll(items: any) {
    this.onchange(this.SaleAreaGeneralForm.value);
  }

  get SaveAreaData() { return this.SaleAreaGeneralForm.controls; }

  allSelected = false;

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  public async onSaveSaleGeneral() {
    return new Promise((resolve, reject) => {
      this.errorValue = "";
      this.submittedSaveGeneral = true;
      this.isValidated = true;
      this.isSubmitted = true;
      if (this.SaleAreaGeneralForm.invalid) {
        return;
      }

      this.SaleAreaGeneralForm.value.AreaStyle_Bar = false;
      this.SaleAreaGeneralForm.value.AreaStyle_QSR = false;
      this.SaleAreaGeneralForm.value.AreaStyle_DriveThru = false;

      const itm = this.SaleAreaGeneralForm.value;
      if (itm.RadioButton == "Bar") {
        this.SaleAreaGeneralForm.value.AreaStyle_Bar = true;
      }
      else if (itm.RadioButton == "QRS") {
        this.SaleAreaGeneralForm.value.AreaStyle_QSR = true;
      }
      else if (itm.RadioButton == "DriveThru") {
        this.SaleAreaGeneralForm.value.AreaStyle_DriveThru = true;
      }

      if (this.SaleAreaGeneralForm.get('TaxableRef').value == true) {
        this.SaleAreaGeneralForm.value.TaxableRef = 'Taxable';
      }
      else {
        this.SaleAreaGeneralForm.value.TaxableRef = 'NonTaxable';
      }

      this.SaleAreaGeneralForm.value.Cash_Receipt = 0 ? null : this.SaleAreaGeneralForm.value.Cash_Receipt
      this.SaleAreaGeneralForm.value.CC_Receipt = 0 ? null : this.SaleAreaGeneralForm.value.CC_Receipt
      this.SaleAreaGeneralForm.value.tenantId = this.loginInfo.tenantId;
      this.SaleAreaGeneralForm.value.userid = this.loginInfo.userId;
      this.SaleAreaGeneralForm.value.isActive = true;

      if (this.selectedItem != null) {
        var Arraygroup = [];
        var clientsgroup: any = [] = this.selectedItem;
        for (var i = 0, len = clientsgroup.length; i < len; i++) {
          Arraygroup.push(clientsgroup[i].key);
        }
        this.SaleAreaGeneralForm.value.TaxableRefDetails = Arraygroup.toString();
      }

      // this.LoggerService.SaveLoggerInformation(this.loginInfo.tenantId, 'info', 'SalesAreaGeneral_saveSalesAreaGeneral',
      // JSON.stringify(this.SaleAreaGeneralForm.value), this.loginInfo.userId)
      this.ItemLog.saveSalesAreaGeneral(this.SaleAreaGeneralForm.value).subscribe(
        async (response) => {
          this.submittedSaveGeneral = false;
          if (response.id == 0) {
            this.toaster.errorPopUp(response.message);
            return;
          } else {
            this.SaleAreaGeneralForm.patchValue({
              id: response.id,
            });
            this.helper.setValue('GetSessionData1', this.SaleAreaGeneralForm.value);
            this.toaster.successPopUp(response.message);
            this.saleareagenData_subchild.emit({ data: this.SaleAreaGeneralForm.value, event: "SalesAreaPage", val: false });
          }
          this.isSubmitted = false;
          //this.SaleAreaGeneralForm.patchValue({ isActive: true });  //This is commented because After Uncheck and save its getting check
          this.generalItem.emit({ res: response, name: this.SaleAreaGeneralForm.value.name });
          resolve(Response);
        })
    });
  }

  get myForm() {
    return this.SaleAreaGeneralForm.get('RadioButton');
  }

  get SavegeneralGroup() {
    return this.SaleAreaGeneralForm.get('selectfirst');
  }

  get MenuItemData() { return this.SaleAreaGeneralForm.controls; }

  getTicketView() {
    const menuItemList: GetSaleAreaGeneralDD = {
      parentCode: "T10",
    }
    // this.LoggerService.SaveLoggerInformation(this.loginInfo.tenantId, 'info', 'SalesAreaGeneral_GetSalesAreaTicketGeneral',
    //   JSON.stringify(menuItemList), this.loginInfo.userId)
    this.objItem.GetSalesAreaTicketGeneral(menuItemList)
      .subscribe((response) => { this.tickref = response; })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkInput(e, input) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];
    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }
    const keyValue = +e.key;
    if (isNaN(keyValue) && e.keyCode != 9) {
      e.preventDefault();
      return;
    }

    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if ((+newValue > 60 || newValue.length > 2) && e.keyCode != 9) {
      e.preventDefault();
    }
  }

  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }

  getPaymentView() {
    const menuItemList: GetSaleAreaGeneralDD = {
      parentCode: "P13",
    }
    // this.LoggerService.SaveLoggerInformation(this.loginInfo.tenantId, 'info', 'SalesAreaGeneral_GetSalesAreaTicketGeneral',
    //   JSON.stringify(menuItemList), this.loginInfo.userId)
    this.objItem.GetSalesAreaTicketGeneral(menuItemList)
      .subscribe((response) => {
        this.payref = response;
      })
  }

  getOnsendingView() {
    const menuItemList: GetSaleAreaGeneralDD = {
      parentCode: "S21",
    }
    // this.LoggerService.SaveLoggerInformation(this.loginInfo.tenantId, 'info', 'SalesAreaGeneral_GetSalesAreaTicketGeneral',
    //   JSON.stringify(menuItemList), this.loginInfo.userId)
    this.objItem.GetSalesAreaTicketGeneral(menuItemList)
      .subscribe((response) => { this.sendref = response; })
  }

  getTaxableView() {
    const menuItemList: GetSaleAreaGeneralDD = {
      parentCode: "T28",
    }
    // this.LoggerService.SaveLoggerInformation(this.loginInfo.tenantId, 'info', 'SalesAreaGeneral_GetSalesAreaTicketGeneral',
    //   JSON.stringify(menuItemList), this.loginInfo.userId)
    this.objItem.GetSalesAreaTicketGeneral(menuItemList)
      .subscribe((response) => { this.taxref = response; })
  }

  @Input()
  public set buttonDis(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = btnst;
    }
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  SalesAreaValid() {
    if (this.SaleAreaGeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }
    else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.SaleAreaGeneralForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  RadioChange(param) {
    if ((param != null || param != "") && (this.SaleAreaGeneralForm.value.name != null && this.SaleAreaGeneralForm.value.name != "")) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.SaleAreaGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.saleareagenData_subchild.emit(this.SaleAreaGeneralForm.value)
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.saleareagenData_subchild.emit({ data: data, event: "SalesAreaPage", val: true });
  }

  GetAdjustmentGeneralList() {
    const adjustList: SelectAdjustGeneral = {
      id: 0,
      tenantid: this.loginInfo.tenantId,
    };

    this.objAdj.EditAdjustmentGeneral(adjustList) // select method
      .subscribe((res) => {
        var jsonAdjustData = res.AdjustmentDetails;
        var itemSalesArea
        var jsonToBeUsedSalesArea = [];
        for (var type in jsonAdjustData) {
          itemSalesArea = {};
          itemSalesArea.key = jsonAdjustData[type].Detailsid;
          itemSalesArea.value = jsonAdjustData[type].name;
          this.variables.push(itemSalesArea);
          jsonToBeUsedSalesArea.push(itemSalesArea);
        }
        this.adjustData = jsonToBeUsedSalesArea;
      });
  }

  GetTaxRefList() {
    const login: IRole = {
      id: 0,
      tenantId: this.loginInfo.tenantId //this.parentID
    }
    this.adminser.GetTaxMaster(login).subscribe(
      (response) => {
        var jsonTaxbaleData = response;
        var itemSalesArea
        var jsonToBeUsedSalesArea = [];
        for (var type in jsonTaxbaleData) {
          itemSalesArea = {};
          itemSalesArea.key = jsonTaxbaleData[type].id.toString();
          itemSalesArea.value = jsonTaxbaleData[type].name;
          jsonToBeUsedSalesArea.push(itemSalesArea);
        }
        this.taxdetailsdata = jsonToBeUsedSalesArea;
      });

    // this.taxdetailsdata = [
    //   { item_id: 1, item_text: 'New Delhi' },
    //   { item_id: 2, item_text: 'Mumbai' },
    //   { item_id: 3, item_text: 'Bangalore' },
    //   { item_id: 4, item_text: 'Pune' },
    //   { item_id: 5, item_text: 'Chennai' },
    //   { item_id: 6, item_text: 'Navsari' }
    // ];
  }

  search(value) {
    this.filteredListret = this.variables.filter((unit) =>
      unit.value.toUpperCase().includes(value.toUpperCase()));
    if (this.filteredListret.length == 0) {
      this.adjustData = [...this.filteredListret];
    }
    this.adjustData = this.filteredListret.sort((a, b) => 0 - (a.value > b.value ? -1 : 1)); // asending order
  }

  public toggle(event: MatSlideToggleChange) {
    this.checkboxValue = event.checked;
    this.onchange(this.SaleAreaGeneralForm.value);
  }
}
