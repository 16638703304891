<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{ employee }}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="addMenuItem()" (click)="stepper.reset()">
          <i class="fa fa-plus"></i>
        </button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData" />
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{
          submit_button_error: isInvaliderr == true,
          'hide-btn': btnstate == false
        }" class="btn btn-sm float-right submit_button global-submit" type="submit" (click)="onSaveGeneral($event)">
        <img src="assets/components/images/save.png" alt="employee" class="" />
        <label class="label_config">Save</label>
      </button>
            <button [ngClass]="{ 'hide-btn': ccStep4 == false }" class="btn submit_button form-control timeEditBtn credit_card_button cc3-submit" (click)="goForward(stepper)">
        Submit
      </button>
        </div>
    </div>
</div>
<div class="row second-sec">
    <div class="col-sm-4">
        <app-fliptable [DataCom]="employee" [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" (clearPayment)="addMenuItem($event)" (hierarchyData)="getTableIdx($event)" (btnActive)="btnActiveStatus($event)" (tableEmpName)="getTableName($event)"
            (tableItemId)="getTableId($event)" (click)="stepper.reset()" [employeeItem]="tableRowColor" [EmployeeTableData]="EmployeeTableData" (breakActive)="checkValue($event)">
        </app-fliptable>
    </div>
    <div class="col-sm-8">
        <div class="card credit_card">
            <div class="card-body" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-horizontal-stepper #stepper [linear]="matstep" (selectionChange)="stepperChange($event)">
                    <mat-step [stepControl]="CreditCardForm" label="CreditCardForm">
                        <div class="house_wrap">
                            <form class="creditForm loginForm" [formGroup]="CreditCardForm" (change)="onchangeGeneral(CreditCardForm.value)">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Business Name</label
                      ><label class="text-danger req_label">*</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="businessName" placeholder="Business Name" appInputRestriction [ngClass]="{
                          'is-invalid':
                            (submittedGeneral &&
                              GeneralData.businessName.errors) ||
                            (requiredError && GeneralData.businessName.errors)
                        }" maxlength="50" (input)="getMaxValueBName($event)" (focusout)="ValidateCreditCard()" />
                                            <div class="error">{{ errorValueName }}</div>
                                            <div *ngIf="
                          (submittedGeneral &&
                            GeneralData.businessName.errors) ||
                          (requiredError && GeneralData.businessName.errors)
                        " class="invalid-feedback">
                                                <div *ngIf="GeneralData.businessName.errors.required">
                                                    Business Name is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Phone</label
                      ><label class="text-danger req_label">*</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1">
                            <img
                              src="assets/components/images/USA.png"
                              alt="employee"
                              class="flag_img"
                            />+1
                          </span>
                                                </div>
                                                <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="phone" placeholder="(xxx) xxx-xxxx" maxLength="14" (focusout)="ValidateCreditCard()" (keypress)="numberOnly2($event)" appInputRestriction [ngClass]="{
                            'is-invalid':
                              (submittedGeneral && GeneralData.phone.errors) ||
                              (requiredError && GeneralData.phone.errors) ||
                              mgs != ''
                          }" (keydown)="onDeletePhone($event)" (focusout)="phoneNumberCheck($event)" />
                                                <div class="error">{{ mgs }}</div>
                                                <!-- <div *ngIf="CreditCardForm.controls['phone'].errors && submittedGeneral && !isValidFormSubmitted"
                                                        [ngClass]="'error'">
                                                        <div *ngIf="CreditCardForm.controls['phone'].errors.pattern">
                                                            Mobile Number not valid.
                                                        </div>
                                                    </div> -->
                                                <div *ngIf="
                            (submittedGeneral && GeneralData.phone.errors) ||
                            (requiredError && GeneralData.phone.errors)
                          " class="invalid-feedback">
                                                    <div *ngIf="GeneralData.phone.errors.required">
                                                        Phone Number is required
                                                    </div>
                                                    <div *ngIf="
                              CreditCardForm.controls['phone'].errors &&
                              submittedGeneral &&
                              !isValidFormSubmitted
                            " [ngClass]="'error'"></div>
                                                    <div *ngIf="
                              CreditCardForm.controls['phone'].errors.pattern &&
                              mgs == ''
                            ">
                                                        Phone Number not valid.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="customlable mb-0">First Name</label
                      ><label class="text-danger req_label">*</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="contactFirstName" placeholder="First Name" appInputRestriction [ngClass]="{
                          'is-invalid':
                            (submittedGeneral &&
                              GeneralData.contactFirstName.errors) ||
                            (requiredError &&
                              GeneralData.contactFirstName.errors)
                        }" maxlength="50" (input)="getMaxValueFName($event)" (focusout)="ValidateCreditCard()" />
                                            <div class="error">{{ errorValue1 }}</div>
                                            <div *ngIf="
                          (submittedGeneral &&
                            GeneralData.contactFirstName.errors) ||
                          (requiredError && GeneralData.contactFirstName.errors)
                        " class="invalid-feedback">
                                                <div *ngIf="GeneralData.contactFirstName.errors.required">
                                                    First Name is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Last Name</label
                      ><label class="text-danger req_label">*</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="contactLastName" placeholder="Last Name" appInputRestriction [ngClass]="{
                          'is-invalid':
                            (submittedGeneral &&
                              GeneralData.contactLastName.errors) ||
                            (requiredError &&
                              GeneralData.contactLastName.errors)
                        }" maxlength="50" (input)="getMaxValueLName($event)" (focusout)="ValidateCreditCard()" />
                                            <div class="error">{{ errorValue2 }}</div>
                                            <div *ngIf="
                          (submittedGeneral &&
                            GeneralData.contactLastName.errors) ||
                          (requiredError && GeneralData.contactLastName.errors)
                        " class="invalid-feedback">
                                                <div *ngIf="GeneralData.contactLastName.errors.required">
                                                    Last Name is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Contact Email</label
                      ><label class="text-danger req_label">*</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="contactEmail" placeholder="&#x2709; Example@myemailaddress.com" appInputRestriction [ngClass]="{
                          'is-invalid':
                            (submittedGeneral &&
                              GeneralData.contactEmail.errors) ||
                            (requiredError && GeneralData.contactEmail.errors)
                        }" maxlength="100" (input)="getMaxValueEmail($event)" (focusout)="ValidateCreditCard()" />
                                            <div class="error">{{ errorValue3 }}</div>

                                            <div *ngIf="
                          (submittedGeneral &&
                            GeneralData.contactEmail.errors) ||
                          (requiredError && GeneralData.contactEmail.errors)
                        " class="invalid-feedback">
                                                <div *ngIf="GeneralData.contactEmail.errors.required">
                                                    Contact Email is required
                                                </div>
                                                <div *ngIf="GeneralData.contactEmail.errors.email">
                                                    Email must be a valid email address
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Address</label
                      ><label class="text-danger req_label">*</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="address" placeholder="Address" appInputRestriction [ngClass]="{
                          'is-invalid':
                            (submittedGeneral && GeneralData.address.errors) ||
                            (requiredError && GeneralData.address.errors)
                        }" (focusout)="ValidateCreditCard()" />
                                            <div *ngIf="
                          (submittedGeneral && GeneralData.address.errors) ||
                          (requiredError && GeneralData.address.errors)
                        " class="invalid-feedback">
                                                <div *ngIf="GeneralData.address.errors.required">
                                                    Address is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label class="customlable mb-0">City</label
                          ><label class="text-danger req_label">*</label>
                                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="city" placeholder="City" appInputRestriction [ngClass]="{
                              'is-invalid':
                                (submittedGeneral && GeneralData.city.errors) ||
                                (requiredError && GeneralData.city.errors)
                            }" maxlength="50" (input)="getMaxValueCity($event)" (focusout)="ValidateCreditCard()" />
                                                    <div class="error">{{ errorValue4 }}</div>
                                                    <div *ngIf="
                              (submittedGeneral && GeneralData.city.errors) ||
                              (requiredError && GeneralData.city.errors)
                            " class="invalid-feedback">
                                                        <div *ngIf="GeneralData.city.errors.required">
                                                            City is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label class="customlable mb-0">State</label
                          ><label class="text-danger req_label">*</label>
                                                    <mat-form-field class="state-placeholder" (focusout)="ValidateCreditCard()">
                                                        <mat-select (openedChange)="matstate($event)" class="form-control" [disableOptionCentering]="true" #select1 formControlName="state" placeholder="Select State" [ngClass]="{
                                'is-invalid':
                                  (submittedGeneral &&
                                    GeneralData.state.errors) ||
                                  (requiredError && GeneralData.state.errors)
                              }">
                                                            <input class="form-control input-lg inputbox_login" placeholder="Search here" (keyup)="onKey($event.target.value)" maxlength="50" />
                                                            <mat-option *ngFor="let item of filteredList2" [value]="item.id">
                                                                {{ item.id }}
                                                            </mat-option>
                                                            <!-- <mat-option *ngIf="filterNodata" [value]="">No data Found
                                                            </mat-option> -->
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-error *ngIf="
                              (submittedGeneral && GeneralData.state.errors) ||
                              (requiredError && GeneralData.state.errors)
                            " class="invalid-feedback">
                                                        <mat-error *ngIf="
                                (submittedGeneral &&
                                  GeneralData.state.errors) ||
                                (requiredError && GeneralData.state.errors)
                              ">
                                                            State is required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label class="customlable mb-0">Zip Code</label
                          ><label class="text-danger req_label">*</label>
                                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="zip" placeholder="Zip" [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" [ngClass]="{
                              'is-invalid':
                                (submittedGeneral && GeneralData.zip.errors) ||
                                (requiredError && GeneralData.zip.errors)
                            }" (keypress)="numberOnly($event)" (focusout)="zipCodeCheck($event)" (keydown)="onDeleteZipCode($event)" [ngClass]="{ 'is-invalid': zipCodeMsg != '' }" (focusout)="ValidateCreditCard()" />
                                                    <div class="error">{{ zipCodeMsg }}</div>
                                                    <div *ngIf="
                              (submittedGeneral && GeneralData.zip.errors) ||
                              (requiredError && GeneralData.zip.errors)
                            " class="invalid-feedback">
                                                        <div *ngIf="GeneralData.zip.errors.required">
                                                            Zip is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4 pl-2 pr-2 pt-2 pb-0">
                                        <div class="form-groupcard-header border-bottom-0 bg-transparent">
                                            <label class="customlable mb-0">GateWay Selection</label
                      ><label class="text-danger req_label">*</label>
                                            <mat-form-field appearance="fill" (focusout)="ValidateCreditCard()">
                                                <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="gatewaySelection" [ngClass]="{
                            'is-invalid':
                              (submittedGeneral &&
                                GeneralData.gatewaySelection.errors) ||
                              (requiredError &&
                                GeneralData.gatewaySelection.errors)
                          }">
                                                    <mat-option value="true" id="1" selected>DataCap</mat-option>
                                                    <mat-option value="false" id="2">BridgePay</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-error *ngIf="
                          (submittedGeneral &&
                            GeneralData.gatewaySelection.errors) ||
                          (requiredError && GeneralData.gatewaySelection.errors)
                        " class="invalid-feedback">
                                                <mat-error *ngIf="
                            (submittedGeneral &&
                              GeneralData.gatewaySelection.errors) ||
                            (requiredError &&
                              GeneralData.gatewaySelection.errors)
                          ">
                                                    GateWay Selection is required</mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 pl-2 pr-2 pt-2 pb-0">
                                        <div class="form-group">
                                            <label>Platform</label
                      ><label class="text-danger req_label">*</label>
                                            <mat-form-field appearance="fill">
                                                <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="platformPulldown" (selectionChange)="onDropDown($event.value)" [ngClass]="{
                            'is-invalid':
                              (submittedGeneral &&
                                GeneralData.platformPulldown.errors) ||
                              (requiredError &&
                                GeneralData.platformPulldown.errors)
                          }">
                                                    <mat-option value="tsys" selected>TSYS</mat-option>
                                                    <mat-option value="firstdata" disabled>FirstData</mat-option>
                                                    <mat-option value="vantiv" disabled>Vantiv</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-error *ngIf="
                          (submittedGeneral &&
                            GeneralData.platformPulldown.errors) ||
                          (requiredError && GeneralData.platformPulldown.errors)
                        " class="invalid-feedback">
                                                <mat-error *ngIf="
                            (submittedGeneral &&
                              GeneralData.platformPulldown.errors) ||
                            (requiredError &&
                              GeneralData.platformPulldown.errors)
                          ">
                                                    Pulldown is required</mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pl-2 pr-2 pt-2 pb-0" *ngIf="CreditCardForm.get('gatewaySelection').value">
                                    <div class="col-sm-8 credit_toggle">
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <label>Swipe</label>
                                                <div class="checkbox-table accordion-checkbox">
                                                    <div class="custom-control custom-checkbox custom-control item">
                                                        <input type="checkbox" class="custom-control-input" name="swipe" formControlName="swipe" id="Allowsome" />
                                                        <label class="custom-control-label mb-0"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <label>TranCloud</label>
                                                <div class="checkbox-table accordion-checkbox">
                                                    <div class="custom-control custom-checkbox custom-control item">
                                                        <input type="checkbox" class="custom-control-input" name="tranCloud" formControlName="tranCloud" id="Allowsome" #tranCheckBox />
                                                        <label class="custom-control-label mb-0"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-sm-2">
                                                <label>EMV</label>
                                                <div class="checkbox-table accordion-checkbox" (change)="onEmv($event)">
                                                    <div class="custom-control custom-checkbox custom-control item">
                                                        <input type="checkbox" class="custom-control-input " name="emv"
                                                            formControlName="emv" id="Allowsome">
                                                        <label class="custom-control-label mb-0"></label>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="col-sm-2">
                                                <label>App</label>
                                                <div class="checkbox-table accordion-checkbox">
                                                    <div class="custom-control custom-checkbox custom-control item">
                                                        <input type="checkbox" class="custom-control-input" name="app" formControlName="app" id="Allowsome" />
                                                        <label class="custom-control-label mb-0"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <label>OLO</label>
                                                <div class="checkbox-table accordion-checkbox">
                                                    <div class="custom-control custom-checkbox custom-control item">
                                                        <input type="checkbox" class="custom-control-input" name="olo" formControlName="olo" id="Allowsome" />
                                                        <label class="custom-control-label mb-0"></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-2">
                                                <label>Kiosk</label>
                                                <div class="checkbox-table accordion-checkbox">
                                                    <div class="custom-control custom-checkbox custom-control item">
                                                        <input type="checkbox" class="custom-control-input" name="kiosk" formControlName="kiosk" id="Allowsome" />
                                                        <label class="custom-control-label mb-0"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="row">
                                            <div class="col-sm-2">
                                                <label>TranCloud</label>
                                                <div class="checkbox-table accordion-checkbox">
                                                    <div class="custom-control custom-checkbox custom-control item">
                                                        <input type="checkbox" class="custom-control-input"
                                                            name="tranCloud" formControlName="tranCloud" id="Allowsome"
                                                            #tranCheckBox>
                                                        <label class="custom-control-label mb-0"></label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div> -->
                                    </div>
                                    <!-- <div class="col-sm-4">
                                        <div class="form-group">
                                            <label>Platform</label><label class="text-danger req_label">*</label>
                                            <mat-form-field appearance="fill" (focusout)="ValidateCreditCard()">
                                                <mat-select (openedChange)="matstate($event)" type="text" class="form-control"
                                                    formControlName="platformPulldown"
                                                    (selectionChange)="onDropDown($event.value)" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.platformPulldown.errors) || (requiredError && GeneralData.platformPulldown.errors)}" >
                                                    <mat-option value="tsys" selected>TSYS</mat-option>
                                                    <mat-option value="firstdata" disabled>FirstData</mat-option>
                                                    <mat-option value="vantiv" disabled>Vantiv</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-error
                                            *ngIf="(submittedGeneral && GeneralData.platformPulldown.errors) || (requiredError && GeneralData.platformPulldown.errors)"
                                            class="invalid-feedback">
                                            <mat-error
                                                *ngIf="(submittedGeneral && GeneralData.platformPulldown.errors) || (requiredError && GeneralData.platformPulldown.errors)">
                                                Pulldown is required</mat-error>
                                        </mat-error>
                                        </div>
                                    </div> -->
                                </div>
                            </form>
                            <div class="text-right step_button">
                                <button mat-button matStepperNext class="credit_card_button" (click)="onNextPage($event)" (click)="ValidateCreditCard()">
                  <div class="arrows-bar">
                    <span class="arrow_right right"
                      >Move to Step <br />
                      2 of 4</span
                    >
                  </div>
                </button>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step [stepControl]="CreditCardTsysForm" label="CreditCardTsysForm">
                        <div class="credit_tsys" (nextfirstStepButton)="getTsysSaveData($event)">
                            <div *ngIf="isSelectedPlatform == 'tsys'">
                                <form class="tsysForm loginForm" [formGroup]="CreditCardTsysForm" (change)="onchangeTSYS(CreditCardTsysForm.value)">
                                    <div class="row mb-2">
                                        <div class="col-sm-4">
                                            <div class="tsys-header p-2">
                                                <h6 class="card-text mb-0">TSYS</h6>
                                            </div>
                                            <!-- <div class="card  credit-header ">
                                                <div class="card-body p-2">
                                                    <h6 class="card-text mb-0 ">TSYS</h6>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="customlable mb-0">Authentication Code</label
                        ><label class="text-danger">*</label>
                                                <input type="text" class="form-control input-lg inputbox_login" formControlName="totalPassword" (input)="getAuthCodeValue($event)" placeholder="1-10 char" min="1" maxlength="10" (keypress)="keyPressAlphaNumeric($event)" appInputRestriction [ngClass]="{
                            'is-invalid':(submittedGeneral && GeneralTsysData.totalPassword.errors)
                          }" />
                          <div *ngIf="
                          (submittedGeneral &&
                            GeneralTsysData.totalPassword.errors) ||
                          (requiredError &&
                            GeneralTsysData.totalPassword.errors)
                        " class="invalid-feedback">
                                                  <div *ngIf="
                            GeneralTsysData.totalPassword.errors.required
                          ">
                                                        Authentication code is required
                                                    </div>
                                                </div>
                                                <!-- <div class="error">{{ errorValue }}</div> -->
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="customlable mb-0">Host Capture POS ID</label
                        ><label class="text-danger">*</label>
                                                <input type="text" class="form-control input-lg inputbox_login" formControlName="hostCapture_POSID" minlength="10" (keypress)="keyPressAlphaNumeric($event)" appInputRestriction placeholder="Min 10 digits" [ngClass]="{
                            'is-invalid':
                              (submittedGeneral &&
                                GeneralTsysData.hostCapture_POSID.errors) ||
                              (requiredError &&
                                GeneralTsysData.hostCapture_POSID.errors)
                          }" />
                                                <div *ngIf="
                            (submittedGeneral &&
                              GeneralTsysData.hostCapture_POSID.errors) ||
                            (requiredError &&
                              GeneralTsysData.hostCapture_POSID.errors)
                          " class="invalid-feedback">
                                                    <div *ngIf="
                              GeneralTsysData.hostCapture_POSID.errors.required
                            ">
                                                        Host Capture POS ID is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="customlable mb-0">Auto Batch Time</label
                        ><label class="text-danger">*</label>
                                                <ngx-mat-timepicker-field [controlOnly]="true" [defaultTime]="'12:00'" formControlName="autoBatchTime" class="ngxMatTime" [ngClass]="{
                            'is-invalid':
                              (submittedGeneral &&
                                GeneralTsysData.autoBatchTime.errors) ||
                              (requiredError &&
                                GeneralTsysData.autoBatchTime.errors)
                          }">
                                                </ngx-mat-timepicker-field>
                                                <div *ngIf="
                            (submittedGeneral &&
                              GeneralTsysData.autoBatchTime.errors) ||
                            (requiredError &&
                              GeneralTsysData.autoBatchTime.errors)
                          " class="invalid-feedback">
                                                    <div *ngIf="
                              GeneralTsysData.autoBatchTime.errors.required
                            ">
                                                        Auto Batch Time is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-8">
                                            <div class="row">
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <label># of Wired Devices</label>
                                                        <input type="text" class="form-control input-lg inputbox_login" (keydown)="checkInput($event, inputText)" #inputText appInputRestriction formControlName="wired_Devices" placeholder="0-100" (keypress)="numberOnly($event)" (input)="onSum()" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <label># of Wireless Devices</label>
                                                        <input type="text" class="form-control input-lg inputbox_login" (keydown)="checkInput($event, inputText1)" #inputText1 formControlName="wireless_Devices" placeholder="0-100" (keypress)="numberOnly($event)" appInputRestriction (input)="onSum()" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <label class="customlable mt-0">Total Lanes</label>
                                                        <input type="text" class="form-control input-lg inputbox_login" formControlName="total_Lanes" appInputRestriction readonly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <label *ngIf="emvClicked == true">Store and Forward(EMV only)</label
                  >
                  <div class="row" *ngIf="emvClicked == true">
                    <div class="col-sm-3 mt-1">
                      <div class="form-group">
                        <div class="checkbox-table accordion-checkbox">
                          <div
                            class="custom-control custom-checkbox custom-control item"
                          >
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              name="isStoreandForward_EMVonly"
                              formControlName="isStoreandForward_EMVonly"
                              id="Allowsome"
                            />
                            <label class="custom-control-label mb-0"></label>
                            </div>
                        </div>
            </div>
        </div>
        <div class="col-sm-2">
            <div class="form-group">
                <input type="text" class="form-control input-lg inputbox_login" formControlName="storeValue" placeholder="$0-00" appInputRestriction />
            </div>
        </div>
    </div>
    </form>
</div>
<div *ngIf="isSelectedPlatform == 'firstdata'" class="credit_firstData">
    <form [formGroup]="FirstDataForm" class="loginForm" (change)="onchangeFirstData(FirstDataForm.value)">
        <div class="row mb-2">
            <div class="col-sm-4">
                <div class="card credit-header">
                    <div class="card-body p-2">
                        <h6 class="card-text mb-0">FirstData</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Merchant ID</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="merchantId" appInputRestriction placeholder="1-16 Digits" maxlength="16" (keypress)="numberOnly($event)" />
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Total Passward</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="totalPassword" placeholder="6 digits" maxLength="6" (keypress)="numberOnly($event)" appInputRestriction (input)="getAuthCodeValueFirstData($event)" />
                    <div class="error">{{ errorValue }}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Terminal ID</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="terminalId" placeholder="8 characters/Digits" maxlength="8" appInputRestriction (input)="getAuthCodeValue2($event)" />
                    <div class="error">{{ errorValue2 }}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Token ID Type</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="tokenIdType" placeholder="6 characters/digits" maxlength="6" appInputRestriction (input)="getAuthCodeValue1($event)" />
                    <div class="error">{{ errorValue1 }}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Group ID</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="groupId" placeholder="5-13 characters/Digits" maxlength="13" appInputRestriction (input)="getAuthCodeValue3($event)" />
                    <div class="error">{{ errorValue3 }}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">CatCode/SLC</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="catCode_SIC" placeholder="0-4 characters/Digits" maxlength="4" appInputRestriction />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group">
                <div class="Communication_Form">
                    <!-- <div class="custom-control custom-radio custom-control item">
                                                    <input formControlName="autoAcquire_DID"  type="radio" id="AreaStyle_SMS" name="autoAcquire_DID"
                                                        class="custom-control-input">
                                                    <label class="custom-control-label QR" for="AreaStyle_SMS"></label>
                                                </div> -->

                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input" name="autoAcquire_DID" formControlName="autoAcquire_DID" id="AreaStyle_SMS" />
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                    <p class="uber_label">Auto Acquire DID</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Auto Batch Time</label
                        ><label class="text-danger">*</label>
                    <ngx-mat-timepicker-field [controlOnly]="true" formControlName="autoBatchTime" [defaultTime]="'12:00'" class="ngxMatTime" [ngClass]="{
                            'is-invalid':
                              submittedGeneral &&
                              GeneralData.autoBatchTime.errors
                          }">
                        <!-- <div *ngIf="submittedGeneral && GeneralData.autoBatchTime.errors" class="invalid-feedback">
                                                        <div *ngIf="GeneralData.autoBatchTime.errors.required">timeIn is required</div>
                                                    </div> -->
                    </ngx-mat-timepicker-field>
                    <div *ngIf="
                            submittedGeneral && GeneralData.autoBatchTime.errors
                          " class="invalid-feedback">
                        <div *ngIf="GeneralData.autoBatchTime.errors.required">
                            Auto Batch Time is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <label class="customlable mt-2">#Of</label>
                <div class="row">
                    <div class="col-sm-2 col">
                        <div class="form-group">
                            <label class="customlable mt-0">fixed</label>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input" name="of_Fixed" formControlName="of_Fixed" id="Allowsome" />
                                    <label class="custom-control-label mb-0"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col">
                        <div class="form-group">
                            <label class="customlable mt-0">Mobile</label>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input" name="mobile" formControlName="mobile" id="Allowsome" />
                                    <label class="custom-control-label mb-0"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mt-0">Total Lanes</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="total_Lanes" appInputRestriction />
                            <!-- [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.total_Lanes.errors }"
                                                        <div *ngIf="submittedGeneral && GeneralData.total_Lanes.errors" class="invalid-feedback">
                                                            <div *ngIf="GeneralData.total_Lanes.errors.required">Total Lanes is required</div>
                                                        </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <label class="customlable mb-2">Store and Forward(EMV only)</label
                      >
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <div class="checkbox-table accordion-checkbox">
                              <div
                                class="custom-control custom-checkbox custom-control item"
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  name="isStoreandForward_EMVonly"
                                  formControlName="isStoreandForward_EMVonly"
                                  id="Allowsome"
                                />
                                <label
                                  class="custom-control-label mb-0"
                                ></label>
            </div>
        </div>
</div>
</div>
<div class="col-sm-8">
    <div class="form-group">
        <input type="text" class="form-control input-lg inputbox_login" formControlName="storeValue" placeholder="$0-00" appInputRestriction />
    </div>
</div>
</div>
</div>
<div class="col-sm-8">
    <div class="form-group">
        <div class="Communication_Form">
            <div class="custom-control custom-radio custom-control item">
                <input formControlName="of_Fixed_Details" value="retail" type="radio" id="AreaStyle_retail" name="of_Fixed_Details" class="custom-control-input" />
                <label class="custom-control-label QR" for="AreaStyle_retail"></label>
            </div>
            <p class="uber_label">Retail/Grocery</p>
        </div>
    </div>
    <div class="form-group">
        <div class="Communication_Form">
            <div class="custom-control custom-radio custom-control item">
                <input formControlName="of_Fixed_Details" value="restaurant" type="radio" id="AreaStyle_restaurant" name="of_Fixed_Details" class="custom-control-input" />
                <label class="custom-control-label QR" for="AreaStyle_restaurant"></label>
            </div>
            <p class="uber_label">Restaurant</p>
        </div>
    </div>
</div>
</div>
<!-- <div class="row ">
                                        <div class="col-sm-2">
                                            <div class="form-group">
                                                <div class="checkbox-table accordion-checkbox">
                                                    <div class="custom-control custom-checkbox custom-control item">
                                                        <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                                            formControlName="IsAllow_SameItem" id="Allowsome">
                                                        <label class="custom-control-label mb-0"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-group">
                                                <input type="text" class="form-control input-lg inputbox_login" formControlName="retailnumber"
                                                    placeholder="$0-00" />
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-group">
                                                <div class="Communication_Form">
                                                    <div class="custom-control custom-radio custom-control item">
                                                        <input formControlName="Radio" value="restaurant" type="radio" id="AreaStyle_restaurant" name="Radio"
                                                            class="custom-control-input">
                                                        <label class="custom-control-label QR" for="AreaStyle_restaurant"></label>
                                                    </div>
                                                    <p class="uber_label">Restaurant</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
<div class="row">
    <div class="form-group col-sm-12">
        <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral()">
                        <!-- [disabled]="buttonState ? true : null" -->
                        <img
                          src="assets/components/images/save.png"
                          alt="employee"
                          class=""
                        />
                        <label class="label_config">Save</label>
                      </button>
    </div>
</div>
</form>
</div>
<div *ngIf="isSelectedPlatform == 'vantiv'" class="credit_firstData">
    <!-- <app-credit-card-vantiv [buttonState]="buttonDisable"></app-credit-card-vantiv> -->
    <form [formGroup]="CreditCardVantivForm" class="loginForm" (change)="onchangeVantiv(CreditCardVantivForm.value)">
        <div class="row mb-2">
            <div class="col-sm-4">
                <div class="card credit-header">
                    <div class="card-body p-2">
                        <h6 class="card-text mb-0">Vantiv</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Merchant ID</label
                        ><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="merchantId" placeholder="1-16 digits" maxlength="16" (keypress)="numberOnly($event)" appInputRestriction [ngClass]="{
                            'is-invalid':
                              submittedGeneral && GeneralData.merchantId.errors
                          }" />
                    <div *ngIf="
                            submittedGeneral && GeneralData.merchantId.errors
                          " class="invalid-feedback">
                        <div *ngIf="GeneralData.merchantId.errors.required">
                            Merchant ID is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Terminal ID</label
                        ><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="terminalId" maxlength="6" placeholder="6 digits" [maxLength]="6" (keypress)="numberOnly($event)" appInputRestriction [ngClass]="{
                            'is-invalid':
                              submittedGeneral && GeneralData.terminalId.errors
                          }" />
                    <div *ngIf="
                            submittedGeneral && GeneralData.terminalId.errors
                          " class="invalid-feedback">
                        <div *ngIf="GeneralData.terminalId.errors.required">
                            Terminal ID is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Merchant BIN</label
                        ><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="merchantBin" maxlength="8" appInputRestriction placeholder="8 characters/Digits" [ngClass]="{
                            'is-invalid':
                              submittedGeneral && GeneralData.merchantBin.errors
                          }" />
                    <div *ngIf="
                            submittedGeneral && GeneralData.merchantBin.errors
                          " class="invalid-feedback">
                        <div *ngIf="GeneralData.merchantBin.errors.required">
                            Merchant BIN is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Auto Batch Time</label
                        ><label class="text-danger">*</label>
                    <ngx-mat-timepicker-field [controlOnly]="true" formControlName="autoBatchTime" [defaultTime]="'12:00'" class="ngxMatTime" [ngClass]="{
                            'is-invalid':
                              submittedGeneral &&
                              GeneralData.autoBatchTime.errors
                          }">
                        <!-- <div *ngIf="submittedGeneral && GeneralData.autoBatchTime.errors" class="invalid-feedback">
                                                    <div *ngIf="GeneralData.autoBatchTime.errors.required">timeIn is required</div>
                                                </div> -->
                    </ngx-mat-timepicker-field>
                    <div *ngIf="
                            submittedGeneral && GeneralData.autoBatchTime.errors
                          " class="invalid-feedback">
                        <div *ngIf="GeneralData.autoBatchTime.errors.required">
                            Auto Batch Time is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Store and Forward(EMV only)</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input" name="isStoreandForward_EMVonly" formControlName="isStoreandForward_EMVonly" id="Allowsome" />
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 mt-3">
                <div class="form-group">
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="storeValue" placeholder="$0-00" appInputRestriction />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <label class="customlable m-0">#Of</label>
                <label class="text-danger req_label">*</label>
                <div class="row">
                    <div class="col-sm-2 col">
                        <div class="form-group">
                            <label class="customlable m-0">fixed</label>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input" name="of_Fixed" formControlName="of_Fixed" id="Allowsome" />
                                    <label class="custom-control-label mb-0"></label>
                                </div>
                            </div>
                            <div *ngIf="
                                submittedGeneral && GeneralData.of_Fixed.errors
                              " class="invalid-feedback">
                                <div *ngIf="GeneralData.of_Fixed.errors.required">
                                    fixed is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col">
                        <div class="form-group">
                            <label class="customlable m-0">Mobile</label>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input" name="mobile" formControlName="mobile" id="Allowsome" />
                                    <label class="custom-control-label mb-0"></label>
                                </div>
                            </div>
                            <div *ngIf="
                                submittedGeneral && GeneralData.mobile.errors
                              " class="invalid-feedback">
                                <div *ngIf="GeneralData.mobile.errors.required">
                                    Mobile is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <label class="customlable m-0">Total Lanes</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="total_Lanes" [ngClass]="{
                                'is-invalid':
                                  submittedGeneral &&
                                  GeneralData.total_Lanes.errors
                              }" appInputRestriction />
                            <div *ngIf="
                                submittedGeneral &&
                                GeneralData.total_Lanes.errors
                              " class="invalid-feedback">
                                <div *ngIf="GeneralData.total_Lanes.errors.required">
                                    Total Lanes is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 col">
                <label class="customlable m-0">Contactless Capable</label
                      ><label class="text-danger req_label">*</label>
                <div class="form-group Communication_Form">
                    <div class="custom-control custom-radio custom-control item">
                        <input formControlName="contactlessCapable" value="NO" type="radio" id="AreaStyle_capable" name="contactlessCapable" class="custom-control-input" [ngClass]="{
                              'is-invalid':
                                submittedGeneral &&
                                GeneralData.contactlessCapable.errors
                            }" />
                        <label class="custom-control-label QR" for="AreaStyle_capable">NO</label
                          >
                        </div>
                      </div>
                      <div class="form-group Communication_Form">
                        <div class="Communication_Form">
                          <div
                            class="custom-control custom-radio custom-control item"
                          >
                            <input
                              formControlName="contactlessCapable"
                              value="Yes"
                              type="radio"
                              id="AreaStyle_Contactless"
                              name="contactlessCapable"
                              class="custom-control-input"
                              [ngClass]="{
                                'is-invalid':
                                  submittedGeneral &&
                                  GeneralData.contactlessCapable.errors
                              }"
                            />
                            <label
                              class="custom-control-label QR"
                              for="AreaStyle_Contactless"
                              >YES</label
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          submittedGeneral &&
                          GeneralData.contactlessCapable.errors
                        "
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="GeneralData.contactlessCapable.errors.required"
                        >
                          Contactless Capable is required
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 col">
                      <label class="customlable m-0">Verify SSL</label
                      ><label class="text-danger req_label">*</label>
                        <div class="form-group Communication_Form">
                            <div class="custom-control custom-radio custom-control item">
                                <input formControlName="verify_SSL" value="Yes" type="radio" id="AreaStyle_ssl" name="verify_SSL" class="custom-control-input" [ngClass]="{
                              'is-invalid':
                                submittedGeneral &&
                                GeneralData.verify_SSL.errors
                            }" />
                                <label class="custom-control-label QR" for="AreaStyle_ssl">YES</label
                          >
                        </div>
                      </div>
                      <div class="form-group Communication_Form">
                        <div class="Communication_Form">
                          <div
                            class="custom-control custom-radio custom-control item"
                          >
                            <input
                              formControlName="verify_SSL"
                              value="No"
                              type="radio"
                              id="AreaStyle_ssl2"
                              name="verify_SSL"
                              class="custom-control-input"
                              [ngClass]="{
                                'is-invalid':
                                  submittedGeneral &&
                                  GeneralData.verify_SSL.errors
                              }"
                            />
                            <label
                              class="custom-control-label QR"
                              for="AreaStyle_ssl2"
                              >NO</label
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          submittedGeneral && GeneralData.verify_SSL.errors
                        "
                        class="invalid-feedback"
                      >
                        <div *ngIf="GeneralData.verify_SSL.errors.required">
                          Verify SSL is required
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 col">
                      <label class="customlable m-0">Merchant Category</label
                      ><label class="text-danger req_label">*</label>
                                <div class="form-group Communication_Form">
                                    <div class="custom-control custom-radio custom-control item">
                                        <input formControlName="merchantCategory" value="merchant" type="radio" id="AreaStyle_merchant" name="merchantCategory" class="custom-control-input" [ngClass]="{
                              'is-invalid':
                                submittedGeneral &&
                                GeneralData.merchantCategory.errors
                            }" />
                                        <label class="custom-control-label QR" for="AreaStyle_merchant">Retail/Grocery</label
                          >
                        </div>
                      </div>
                      <div class="form-group Communication_Form">
                        <div class="Communication_Form">
                          <div
                            class="custom-control custom-radio custom-control item"
                          >
                            <input
                              formControlName="merchantCategory"
                              value="merchant2"
                              type="radio"
                              id="AreaStyle_merchant2"
                              name="merchantCategory"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label QR"
                              for="AreaStyle_merchant2"
                              >Restaurant</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group Communication_Form">
                        <div class="Communication_Form">
                          <div
                            class="custom-control custom-radio custom-control item"
                          >
                            <input
                              formControlName="merchantCategory"
                              value="merchant3"
                              type="radio"
                              id="AreaStyle_merchant3"
                              name="merchantCategory"
                              class="custom-control-input"
                              [ngClass]="{
                                'is-invalid':
                                  submittedGeneral &&
                                  GeneralData.merchantCategory.errors
                              }"
                            />
                            <label
                              class="custom-control-label QR"
                              for="AreaStyle_merchant3"
                              >Marketiing</label
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          submittedGeneral &&
                          GeneralData.merchantCategory.errors
                        "
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="GeneralData.merchantCategory.errors.required"
                        >
                          Merchant Category is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <label class="customlable m-0">Duplicate Checking</label>
                                        <div class="form-group Communication_Form">
                                            <div class="custom-control custom-radio custom-control item">
                                                <input formControlName="duplicateChecking" value="Diasabled" type="radio" id="AreaStyle_Diasabled" name="duplicateChecking" class="custom-control-input" />
                                                <label class="custom-control-label QR" for="AreaStyle_Diasabled">Diasabled</label
                          >
                        </div>
                      </div>
                      <div class="form-group Communication_Form">
                        <div class="Communication_Form">
                          <div
                            class="custom-control custom-radio custom-control item"
                          >
                            <input
                              formControlName="duplicateChecking"
                              value="Match"
                              type="radio"
                              id="AreaStyle_Match"
                              name="duplicateChecking"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label QR"
                              for="AreaStyle_Match"
                              >Match Amount and Acct</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group Communication_Form">
                        <div class="Communication_Form">
                          <div
                            class="custom-control custom-radio custom-control item"
                          >
                            <input
                              formControlName="duplicateChecking"
                              value="invoice"
                              type="radio"
                              id="AreaStyle_invoice"
                              name="duplicateChecking"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label QR"
                              for="AreaStyle_invoice"
                              >Match Amount and Acct, & invoice</label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="form-group Communication_Form">
                        <div class="Communication_Form">
                          <div
                            class="custom-control custom-radio custom-control item"
                          >
                            <input
                              formControlName="duplicateChecking"
                              value="invoiceReturn"
                              type="radio"
                              id="AreaStyle_invoiceReturn"
                              name="duplicateChecking"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label QR"
                              for="AreaStyle_invoiceReturn"
                              >Match Amount and Acct, & invoice- Return 200 on
                              match</label
                            >
                          </div>
                        </div>
                      </div>
                      <!-- <div *ngIf="submittedGeneral && GeneralData.duplicateChecking.errors" class="invalid-feedback">
                                                <div *ngIf="GeneralData.duplicateChecking.errors.required">Duplicate Checking is required</div>
                                            </div> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-sm-12">
                      <button
                        class="btn btn-sm float-right submit_button button-none"
                        type="submit"
                        (click)="onSaveGeneral()"
                      >
                        <!-- [disabled]="buttonState ? true : null" -->
                        <img
                          src="assets/components/images/save.png"
                          alt="employee"
                          class=""
                        />
                        <label class="label_config">Save</label>
                                                </button>
                                            </div>
                                        </div>
    </form>
    </div>
    <div class="tsys_button">
        <button mat-button matStepperPrevious (click)="On1of4ndMoveNext()">
                  <div class="arrows-bar">
                    <span class="arrow_right left"
                      >Move to Step <br />1 of 4</span
                    >
                  </div>
                </button>
        <button mat-button matStepperNext class="credit_card_button" (click)="On2ndMoveNext()">
                  <div class="arrows-bar">
                    <span class="arrow_right right"
                      >Move to Step <br />3 of 4</span
                    >
                  </div>
                </button>
    </div>
    </div>
    </mat-step>
    <mat-step [stepControl]="SubmitParameterForm" label="SubmitParameterForm">
        <div class="submit_parameter">
            <form class="parameterForm loginForm" [formGroup]="SubmitParameterForm" (change)="onchangeParam(SubmitParameterForm.value)">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <div class="form-group">
                            <label><span class="ccgateway-custom-color">Submit Parameters</span></label>
                            <p>Please Double Check Your Fields For Accuracy</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-4 col-md-5 col-4">
                                <div class="form-group">
                                    <label>Business Name</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-7 col-8">
                                <div class="form-group">
                                    <label class="center-align" for="businessName">: {{ GeneralData.businessName.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4 col-md-5 col-4">
                        <div class="form-group">
                          <label>Contact Name</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-7 col-8">
                                <div class="form-group">
                                    <label class="center-align" for="contactFirstName">:
                            {{ GeneralData.contactFirstName.value }}
                            {{ GeneralData.contactLastName.value }}
                          </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-md-5 col-4">
                                <div class="form-group">
                                    <label>Contact Email</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-7 col-8">
                                <div class="form-group">
                                    <label class="center-align" for="contactEmail">: {{ GeneralData.contactEmail.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4 col-md-5 col-4">
                        <div class="form-group">
                          <label>Address</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-7 col-8">
                                <div class="form-group">
                                    <label class="center-align" for="address">: {{ GeneralData.address.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4 col-md-5 col-4">
                        <div class="form-group">
                          <label>City</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-7 col-8">
                                <div class="form-group">
                                    <label class="center-align" for="city">: {{ GeneralData.city.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4 col-md-5 col-4">
                        <div class="form-group">
                          <label>State</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-7 col-8">
                                <div class="form-group">
                                    <label class="center-align" for="state">: {{ GeneralData.state.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4 col-md-5 col-4">
                        <div class="form-group">
                          <label>Zip Code</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-7 col-8">
                                <div class="form-group">
                                    <label class="center-align">: {{ GeneralData.zip.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4 col-md-5 col-4">
                        <div class="form-group">
                          <label>Phone</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-7 col-8">
                                <div class="form-group">
                                    <label class="center-align">: {{ GeneralData.phone.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-5 col-md-5 col-5">
                        <div class="form-group">
                          <label>Online Ordering</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-7 col-md-7 col-7">
                                <div class="form-group">
                                    <label class="center-align" for="emv">:
                            {{ GeneralData.olo.value }}
                          </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-5 col-md-5 col-5">
                                <div class="form-group">
                                    <label>EMV</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-7 col-md-7 col-7">
                                <div class="form-group">
                                    <label class="center-align" for="emv">: {{ GeneralData.emv.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-5 col-5">
                        <div class="form-group">
                          <label>TranCloud</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-7 col-7">
                                <div class="form-group">
                                    <label class="center-align" for="tranCloud">: {{ GeneralData.tranCloud.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-5 col-5">
                        <div class="form-group">
                          <label>#of Devices</label
                          ><label class="text-danger req_label">*</label>
                                </div>
                            </div>
                            <div class="col-sm-7 col-7">
                                <div class="form-group">
                                    <label class="center-align" for="noofdevices">: {{ GeneralTsysData.total_Lanes.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-5 col-5">
                        <div class="form-group">
                          <label>Authentication Code*</label>
                                </div>
                            </div>
                            <div class="col-sm-7 col-7">
                                <div class="form-group">
                                    <label class="center-align" for="totalPassword">: {{ GeneralTsysData.totalPassword.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-5 col-5">
                        <div class="form-group">
                          <label>Host Capture POS ID*</label>
                                </div>
                            </div>
                            <div class="col-sm-7 col-7">
                                <div class="form-group">
                                    <label class="center-align" for="totalPassword">:
                            {{ GeneralTsysData.hostCapture_POSID.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="tsys_button">
                <button
                  mat-button
                  matStepperPrevious
                  (click)="On2of4ndMoveNext()"
                >
                  <div class="arrows-bar">
                    <span class="arrow_right left"
                      >Move to Step <br />2 of 4</span
                    >
                  </div>
                </button>
                <div class="float-right">
                  <!-- <button mat-button matStepperNext (click)="On3ndMoveNext();">
                                        <div class="arrows-bar">
                                            <span class="arrow_right right">Move to Step
                                                <br>4 of 4</span>
                                        </div>
                                    </button> -->
                  <!-- <button mat-button matStepperNext class="credit_card_button form-control" (click)="On3ndMoveNext()">
                    <div>
                      <span>Submit</span>
                    </div>
                  </button> -->
                </div>
              </div>
            </div>
          </mat-step>
          <mat-step
            [stepControl]="CreditCardStep4Form"
            label="CreditCardStep4Form"
          >
            <form
              class="loginForm CreditCardStep4Form"
              [formGroup]="CreditCardStep4Form"
              (change)="onchangeStep(CreditCardStep4Form.value)"
            >
              <div class="row w-100">
                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-4">
                        <label class="customlable mb-0">Swipe MID:</label>
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control input-lg inputbox_login" placeholder="Swipe MID" appInputRestriction formControlName="swipeMID" readonly />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-sm-4">
                                    <label class="customlable mb-0">Swipe Token Key:</label>
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control input-lg inputbox_login" placeholder="Swipe Token Key" appInputRestriction formControlName="swipeTokenKey" readonly />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-sm-4">
                                    <label class="customlable mb-0">TranCloud MID:</label>
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control input-lg inputbox_login" placeholder="TranCloud MID" appInputRestriction formControlName="trancloudMID" readonly />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-sm-4">
                                    <label class="customlable mb-0">TranCloud Token Key:</label
                        >
                      </div>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class="form-control input-lg inputbox_login"
                          placeholder="TranCloud Token Key"
                          appInputRestriction
                          formControlName="trancloudTokenKey"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row w-100">
                <div class="col-sm-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-4">
                        <label class="customlable mb-0">PrePaid Gift MID:</label>
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control input-lg inputbox_login" placeholder="PrePaid Gift MID" appInputRestriction formControlName="prepaidGiftMID" readonly />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-sm-4">
                                    <label class="customlable mb-0">PrePaid Token Key:</label>
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control input-lg inputbox_login" placeholder="PrePaid Token Key" appInputRestriction formControlName="prepaidGiftTokenKey" readonly />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row w-100">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-sm-4">
                                    <label class="customlable mb-0">TranCloud ID:</label>
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control input-lg inputbox_login" placeholder="TranCloud ID" appInputRestriction formControlName="tranCloudId" readonly />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-6">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="customlable mb-0">PrePaid (Gift):</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control input-lg inputbox_login"
                                                    placeholder="PrePaid (Gift)" appInputRestriction
                                                    formControlName="prePaidGift" readonly/>

                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                </div>
            </form>
            <!-- <div class="tsys_button">
              <button mat-button matStepperPrevious (click)="On4ndMoveNext()">
                <div class="arrows-bar">
                  <span class="arrow_right left"
                    >Move to Step <br />3 of 4</span
                  >
                </div>
              </button>
            </div> -->

            <!-- <div class="form-group card-body cc-card"> -->
            <div class="form-group">
                <p class="custom-ft disp-none">
                    DataCap Response - BoardMerchant
                </p>
                <p class="mb-0 mt-15" [innerHTML]="xmlDatDisplay" [ngClass]="{
                  'success-green': successval == true,
                  'fail-red': successval == false
                }"></p>
                <mat-tab-group class="cc-disp-none">
                    <mat-tab label="XML"> {{ xmlDatDisplay }}</mat-tab>
                    <mat-tab label="JSON"> {{ jsonDatDisplay }}</mat-tab>
                </mat-tab-group>
            </div>
    </mat-step>
    </mat-horizontal-stepper>
    </div>
    </div>
    <div class="d-flex justify-content-center ccgateway-preloader" *ngIf="isLoader == true">
        <div class="spinner-border text-white" role="status"></div>
    </div>
    </div>
    </div>
