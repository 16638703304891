<div class="modal-body">
    <div class=" text-right">
        <button type="button" class="btn-close close" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <!-- <div class="inner-div" style="margin-top: 15px;"> -->
    <div style="margin-top: 15px;">
        <div [hidden]="roleItem">
            <app-fliptable-filter-header></app-fliptable-filter-header>
        </div>
        <div *ngIf="roleItem">
            <app-employee-general [empPosId]="detailItem" [isReadonly] = "readonlyVal"></app-employee-general>
        </div>
    </div>
</div>