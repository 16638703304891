import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Injectable, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { EmployeeService } from 'src/app/services/employee.service';
import { GetEmployee, IGetRoleID } from 'src/app/interfaces/iemployee';
import { AdminService } from 'src/app/services/admin.service';
import { TimeService } from 'src/app/services/time.service';
import { GetAllTimeList, IshiftValue, iGetNonSalEmployee } from 'src/app/interfaces/itime';
// import { formatDate } from '@angular/common';
//import * as moment from 'moment'
import { ToasterService } from 'src/app/helper/toaster.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { TimeEditModelComponent } from '../time-edit-model/time-edit-model.component';
import { BreakService } from 'src/app/services/break.service';
import { FliptableAccordionComponent } from 'src/app/elements/fliptable-accordion/fliptable-accordion.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },

};
@Component({
  selector: 'app-time-edit',
  templateUrl: './time-edit.component.html',
  styleUrls: ['./time-edit.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class TimeEditComponent implements OnInit {
  positionArrayList: any = [];
  JobPositionList: any;
  public rolePosition: any;
  ClockList: any;
  IsSave: boolean = false;
  public menuSizeRow: any;
  tenantId: any;
  TimeForm: FormGroup;
  public addModalTable: boolean;
  public tableValueId: any;
  public tableModelId: any;
  EmpByJobID: any;
  jobPositionarray: any = [];
  timeEditList = [];
  loginInfo: any;
  public displayedColumns = [];
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  EmpJobName: any = [];
  isInvalid: boolean = false;
  rowClicked;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  changedVar: boolean;

  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public timeeditsessData = new EventEmitter();
  @Input() public buttonDisable;

  //@ViewChild(TimeEditModelComponent) timeComp : TimeEditModelComponent;
  // @ViewChildren(TimeEditModelComponent ) childrenComponent: QueryList<TimeEditModelComponent >;
  @ViewChild(FliptableAccordionComponent) flipacc_Comp: FliptableAccordionComponent;

  timeChild: boolean
  constructor(private empService: EmployeeService, private timeEditRole: AdminService, private timeClockService: TimeService,
    private http: HttpClient, private formBuilder: FormBuilder, private toaster: ToasterService,
    private helper: HelperService, private changeDetector: ChangeDetectorRef, public ObjService: WebSocketService,
    private BreakService: BreakService, private timeEditDetails: TimeService) { }


  ngOnInit(): void {
    //this.ObjService.connect();
    //this.dataSource.data=this.ObjService.PunchList;

    localStorage.setItem('browserPage', "this.TimePage");

    this.employeeName = "Time Punch Edit";
    this.employee = "Timeclock";
    this.employeeIcon = "assets/components/images/path.png"
    this.TimeForm = this.formBuilder.group({
      employeeId: [0],
      fromDate: new FormControl(new Date()),
      toDate: new FormControl(new Date()),
      jobPositionId: [0],
      clockType: [0],
      tenantId: [0]
    });
    this.loginInfo = this.helper.getValue('LoginInfo');

    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)

    this.onSaveTimeFilter();
    this.GetClockListType();
    this.onChangeTimePosition();
    this.onChangeRolePosition();
  }
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }
  get TimeEditValue() { return this.TimeForm.controls; }

  onChangeTimePosition() {
    const tenantIDList: iGetNonSalEmployee = {
      tenantId: this.loginInfo.tenantId
    }
    this.timeClockService.GetAllEmployeeList(tenantIDList).subscribe((response) => {
      this.EmpJobName = response;
    })
  }
  onChangeRolePosition() {
    const roleList: IGetRoleID = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.timeEditRole.GetAllRoles(roleList).subscribe((response) => {
      this.rolePosition = response;
      this.JobPositionList = this.rolePosition
    })
  }

  GetClockListType() {
    const time: IshiftValue = {
      parentCode: "T01"
    }
    this.timeClockService.GetClockListType(time).subscribe((response) => {
      this.ClockList = response;
    })
  }

  getModelDetails(event) {
    this.tableModelId = event
    this.TimeEditValue.employeeId.setValue(this.tableModelId.id);
    this.TimeEditValue.fromDate.setValue(moment(this.tableModelId.FromDate).format('MM-DD-YYYY'));
    this.TimeEditValue.toDate.setValue(moment(this.tableModelId.ToDate).format('MM-DD-YYYY'));
    this.getJobByEmp(this.tableModelId.id)
    this.onSaveTimeFilter();
  }

  onSaveTimeFilter() {
    const employee = this.TimeForm.value;
    // if(this.TimeEditValue.fromDate.value instanceof Date) {
    employee.fromDate = moment(new Date(this.TimeEditValue.fromDate.value), "DD-MM-YYYY").format("YYYY-MM-DD");
    // }
    // if(this.TimeEditValue.toDate.value instanceof Date) {
    employee.toDate = moment(new Date(this.TimeEditValue.toDate.value), "DD-MM-YYYY").format("YYYY-MM-DD");

    this.TimeForm.patchValue({ fromDate: employee.fromDate, toDate: employee.toDate });
    // }
    const TimeList: GetAllTimeList = {
      clockType: this.TimeEditValue.clockType.value == 0 ? null : this.TimeEditValue.clockType.value,
      employeeId: this.TimeEditValue.employeeId.value >= 0 ? this.TimeEditValue.employeeId.value : this.tableModelId.id,
      jobPositionId: this.TimeEditValue.jobPositionId.value,
      fromDate: employee.fromDate != "" ? employee.fromDate : this.tableModelId.FromDate,
      toDate: employee.toDate != "" ? employee.toDate : this.tableModelId.ToDate,
      tenantId: this.loginInfo.tenantId
    }
    console.log(TimeList, 'TimeList')

    this.timeClockService.GetTimeClockDetails(TimeList)
      .subscribe(res => {
        this.dataSource.data = res.sort((a, b) => (a.punchInDate > b.punchInDate ? -1 : 1)); // decending order;
        console.log(res, 'res')
        this.changeDetector.detectChanges();
        this.displayedColumns = ['employeeName', 'roleName', 'punchIn', 'punchOut', 'timeInTime',
          'timeOutTime', 'typeName', 'wage', 'tips'];
        this.columns = [
          { columnDef: 'employeeName', header: 'Employee Name' },
          // { columnDef: 'employeeLastName', header: 'Last Name' },
          { columnDef: 'roleName', header: 'Position' },
          { columnDef: 'punchIn', header: 'In Date' },
          { columnDef: 'punchOut', header: 'Out Date' },
          { columnDef: 'timeInTime', header: 'Time In' },
          { columnDef: 'timeOutTime', header: 'Time Out' },
          { columnDef: 'typeName', header: 'Type' },
          // { columnDef: 'hours', header: 'Hours' },
          // { columnDef: 'regPay', header: 'Reg Pay' },
          // { columnDef: 'rate', header: 'Rate' },
          { columnDef: 'wage', header: 'Wages' },
          // { columnDef: 'otPay', header: 'OT Pay' },
          { columnDef: 'tips', header: 'Tips' },
        ];
      })
  }

  getJobByEmp(value) {
    this.EmpByJobID = value
    this.getEmployeeJob(value)
  }

  getEmployeeJob(empid) {
    this.JobPositionList = []
    const employee: GetEmployee = {
      employeeId: empid
    }
    if (empid == 0) {
      this.JobPositionList = this.rolePosition;
    }
    else {
      this.empService.GetEmployee(employee).subscribe((response) => {
        this.jobPositionarray = response[0].employeeJobPositionList;
        this.positionArrayList = []
        if (this.jobPositionarray != undefined && this.jobPositionarray != null && this.jobPositionarray != "") {
          if (this.jobPositionarray.length > 0) {
            for (let j = 0; j < this.jobPositionarray.length; j++) {
              let rolename;
              rolename = this.rolePosition.find(x => x.roleId == this.jobPositionarray[j].empPositionId);
              let name = {};
              name = rolename.roleName
              let rolePermission = {
                roleId: this.jobPositionarray[j].empPositionId,
                roleName: name,
              }
              this.positionArrayList.push(rolePermission);
              this.JobPositionList = this.positionArrayList
            }
          }
        }
      })
    }
  }

  onSaveTimeEdit(): void {
    this.flipacc_Comp.onsavetimeclock();
  }

  gettimeeditsessData(e) {
    this.timeeditsessData.emit(e)
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.timeeditsessData.emit({ data: data, event: "TimePage", val: true });
  }
}
