<div class="tags_gen adjustment_detail">
    <form [formGroup]="reasonsGeneralForm" class="loginForm" (change)="onchange(reasonsGeneralForm.value)">

        <div class="row mb-2">
            <div class="col-sm-4 col-12">
                <!-- <h6 *ngIf="(data != 'transparent') && iconShowSec">{{employeeName}}</h6> -->
                <div class="form-group">
                    <label>Name</label> <label class="text-danger">*</label>
                    <input type="text" formControlName="name" (keypress)="focusOutFunction()" (focusout)="ReasonsValid()" class="form-control input-lg inputbox_login" appInputRestriction placeholder="Name" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.name.errors) || requiredErrors}"
                        maxlength="50" (input)="getMaxValue($event)" />
                    <div class="error">{{errorValue}}</div>
                    <div *ngIf="(submittedGeneral && GeneralData.name.errors) || requiredErrors" class="invalid-feedback">
                        <div *ngIf="GeneralData.name.errors.required">Name is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-8">
                <div class="row" [style.display]="iconShowSec ? 'none' : 'flex'">
                    <div class="col-sm-3 col">
                        <a class="tags_img">
                            <img src="assets/components/images/voids-unselected@2x.png" alt="Reports" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-sm-3 col">
                        <a class="tags_img">
                            <img src="assets/components/images/paid-in-unselected@2x.png" alt="print" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-sm-3 col">
                        <a class="tags_img">
                            <img src="assets/components/images/paid-out@2x.png" alt="filter" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-sm-3 col">
                        <a class="tags_img">
                            <img src="assets/components/images/refund-button@2x.png" alt="filter" class="img-fluid">
                        </a>
                    </div>
                </div>
                <div class="row" [style.display]="iconShowSec ? 'flex' : 'none'">
                    <div class="col-sm-3 col">
                        <a class="tags_img" (click)="onImgChange('voids')" [ngClass]="{'img_purple' : voids == true}">
                            <img src="assets/components/images/voids-selected@2x.png" alt="Reports" class="img-fluid img_gray outshadow">
                            <img src="assets/components/images/voids-active@2x.png" alt="Reports" class="img-fluid img_purple">
                        </a>
                    </div>
                    <div class="col-sm-3 col">
                        <a class="tags_img" (click)="onImgChange('Paid-in')" [ngClass]="{'img_purple' : paidIn == true}">
                            <img src="assets/components/images/paid-in-selected@2x.png" alt="Prints" class="img-fluid img_gray outshadow">
                            <img src="assets/components/images/paid-in-active@2x.png" alt="Prints" class="img-fluid img_purple">
                        </a>
                    </div>
                    <div class="col-sm-3 col">
                        <a class="tags_img" (click)="onImgChange('Paid-out')" [ngClass]="{'img_purple' : paidOut == true}">
                            <img src="assets/components/images/paid-out-selected@2x.png" alt="Filter" class="img-fluid img_gray outshadow">
                            <img src="assets/components/images/paid-out-active@2x.png" alt="Prints" class="img-fluid img_purple">
                        </a>
                    </div>
                    <div class="col-sm-3 col">
                        <a class="tags_img" (click)="onImgChange('Refund')" [ngClass]="{'img_purple' : refund == true}">
                            <img src="assets/components/images/refund-selected@2x.png" alt="Filter" class="img-fluid img_gray outshadow">
                            <img src="assets/components/images/refund-active@2x.png" alt="Prints" class="img-fluid img_purple">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 col-4 text-right">
                <div class="form-group">
                    <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral();" [disabled]="buttonDisable ? true : null">
                        <!-- [disabled]="buttonState ? true : null" -->
                        <img src="assets/components/images/save.png" alt="employee" class="">
                        <label class="label_config">Save</label>
                    </button>
                </div>
            </div>
        </div>
        <div class="row mb-2" *ngIf="voids == true || paidIn == true || paidOut == true || refund == true">
            <div class="col-sm-4 col-12">
                <div class="form-group">
                    <label>Void/Refund Category</label>
                    <ng-multiselect-dropdown [settings]="voidSettings" [data]="voidrefundFilters" (click)="onchange(reasonsGeneralForm.value)" [(ngModel)]="voidrefundItems" [formControl]="voidrefundLimit">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="row mb-2" *ngIf="voids == true || paidIn == true || paidOut == true || refund == true">
            <div class="col-sm-4 col-12">
                <div class="form-group">
                    <label>Security Level</label>
                    <div class="dropdown-hight dropdown-up">
                        <ng-multiselect-dropdown [settings]="voidSettings" [data]="securityFilters" (click)="onchange(reasonsGeneralForm.value)" [(ngModel)]="securityItems" [formControl]="securityLimit">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-2" *ngIf="voids == true">
            <div class="col-sm-3 col-md-5 col-6">
                <div class="form-group">
                    <label>Print to Kitchen/Remote Printer</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="printToKitchen" id="checkprint" formControlName="printToKitchen">
                            <label class="custom-control-label" for="checkprint"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-md-5">
                <div class="form-group">
                    <label>Opens Cash Drawer</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="opensCashDrawer" id="checkdrawer" formControlName="opensCashDrawer">
                            <label class="custom-control-label" for="checkdrawer"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-md-5">
                <div class="form-group">
                    <label>Restore Stock Count</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="restoreStockCount" id="checkstock" formControlName="restoreStockCount">
                            <label class="custom-control-label" for="checkstock"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-md-5">
                <div class="form-group">
                    <label>Allow Custom Reasons</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="allowCustomReason" id="checkcustom" formControlName="allowCustomReason">
                            <label class="custom-control-label" for="checkcustom"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-2" *ngIf="paidIn == true">
            <div class="col-sm-4 col-md-6 col-7">
                <div class="form-group">
                    <label>Print to Kitchen/Remote Printer</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="printToKitchen" id="checkprint" formControlName="printToKitchen">
                            <label class="custom-control-label" for="checkprint"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 col-md-4 col-5">
                <div class="form-group">
                    <label>Opens Cash Drawer</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="opensCashDrawer" id="checkdrawer" formControlName="opensCashDrawer">
                            <label class="custom-control-label" for="checkdrawer"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 col-md-4 col-5">
                <div class="form-group">
                    <label>Fast Access</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="fastAcess" id="checkaccess" formControlName="fastAcess">
                            <label class="custom-control-label" for="checkaccess"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-2" *ngIf="paidOut == true">
            <div class="col-sm-3 col">
                <div class="form-group">
                    <label>Print to Kitchen/Remote Printer</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="printToKitchen" id="checkprint" formControlName="printToKitchen">
                            <label class="custom-control-label" for="checkprint"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col">
                <div class="form-group">
                    <label>Opens Cash Drawer</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="opensCashDrawer" id="checkdrawer" formControlName="opensCashDrawer">
                            <label class="custom-control-label" for="checkdrawer"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col">
                <div class="form-group">
                    <label>Fast Access</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="fastAcess" id="checkaccess" formControlName="fastAcess">
                            <label class="custom-control-label" for="checkaccess"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row mb-2" *ngIf="refund == true">
            <div class="col-sm-3 col">
                <div class="form-group">
                    <label>Print to Kitchen/Remote Printer</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="printToKitchen" id="checkprint" formControlName="printToKitchen">
                            <label class="custom-control-label" for="checkprint"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col">
                <div class="form-group">
                    <label>Opens Cash Drawer</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="opensCashDrawer" id="checkdrawer" formControlName="opensCashDrawer">
                            <label class="custom-control-label" for="checkdrawer"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col">
                <div class="form-group">
                    <label>Fast Access</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="fastAcess" id="checkaccess" formControlName="fastAcess">
                            <label class="custom-control-label" for="checkaccess"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>