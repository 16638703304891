import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DataTablesModule } from 'angular-datatables'
import { ModalModule } from 'ngx-bootstrap';
import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ThemeComponent } from './theme/theme.component';
import { Routes, RouterModule } from '@angular/router';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvServiceProvider } from './env.service.provider';
import { ErrorInterceptor } from './Interceptor/errorInterceptor';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion'
import {MatIconModule} from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

import { HierachyModule } from './views/hierachy/hierachy.module';
import { EmployeeModule } from './views/employee/employee.module';
import { MatSelectModule } from '@angular/material/select';
import { MenuModule } from './views/menu/menu.module';
import {DragDropModule} from '@angular/cdk/drag-drop'
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { PaymentModule } from './views/payment/payment.module';
import { MasterModule } from './views/master/master.module';
import { SignalModule } from './views/Signal/signal.module';
import { DevicesModule } from './views/Devices/devices.module';
import { ReportModule } from './views/Report/report.module';
import { TagsModule } from './views/Tags/tags.module';
import { CurrencyPipe } from '@angular/common';
import { ReasonsModule } from './views/Reasons/reasons.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import { ToasterPopupComponent } from './elements/toaster-popup/toaster-popup.component';
import {MatTreeModule} from '@angular/material/tree';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { GiftcardService } from './services/giftcard.service';
import { CcgatewayModalComponent } from './elements/ccgateway-modal/ccgateway-modal.component';
import { SettingModule } from './views/settings/setting.module';
import { CcgatewaySubmitModalComponent } from './elements/ccgateway-submit-modal/ccgateway-submit-modal.component';
import { GiftCardModalComponent } from './elements/gift-card-modal/gift-card-modal.component';
import { MenuLogisticsModalComponent } from './elements/menu-logistics-modal/menu-logistics-modal.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { PermissionsModalComponent } from './elements/permissions-modal/permissions-modal.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
const appRoutes: Routes = []

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ThemeComponent,
    ToasterPopupComponent,
    CcgatewayModalComponent,
    CcgatewaySubmitModalComponent,
    GiftCardModalComponent,
    MenuLogisticsModalComponent,
    PermissionsModalComponent       
    
  ],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    RouterModule.forRoot(appRoutes),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    DataTablesModule,
    MatSelectModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    MatRadioModule,
    HierachyModule,
    EmployeeModule,
    MenuModule,
    PaymentModule,
    MasterModule,
    SignalModule,
    DevicesModule,
    ReportModule,
    MatIconModule,
    ReasonsModule,
    NgxIntlTelInputModule, 
    DragDropModule,
    ModalModule.forRoot(),
    TagsModule,
    MatSidenavModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    SettingModule,
    InputMaskModule
  ],
  providers: [EnvServiceProvider,CurrencyPipe,GiftcardService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})


export class AppModule { }
