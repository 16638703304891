import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ToasterService } from 'src/app/helper/toaster.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { HelperService } from 'src/app/helper/helper.service';
import { AdminService } from 'src/app/services/admin.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatTableDataSource } from '@angular/material/table';
import { GetAllUserList, GetEmployee, IGetRoleID } from 'src/app/interfaces/iemployee';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FilterTablePopupComponent } from 'src/app/elements/filter-table-popup/filter-table-popup.component';
import { GetRole, IRole, ISelectCategory } from 'src/app/interfaces/iadmin';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-role-permission-general',
  templateUrl: './role-permission-general.component.html',
  styleUrls: ['./role-permission-general.component.css']
})
export class RolePermissionGeneralComponent implements OnInit {
  roleGeneralForm: FormGroup;
  buttonDisable: boolean = false;
  submittedGeneral = false;
  loginInfo: any;
  roleId: any = 0;
  categoryid: any;
  selectedJobRole = []
  public variables: any = []
  filteredList2: any = []
  filterNodata: any;
  inputSearch: { value: string; index: any; };

  errorValue: any;
  requiredError: boolean = false;

  @Output() public isInvalid = new EventEmitter();

  SalesSettings: IDropdownSettings = {};
  roleFilters = [];
  public dataSource = new MatTableDataSource<any>();
  public EmployeeTableData: any;
  public displayedColumns = [];
  columns = [];
  roleEmployeearray: any = [];
  empDetails: any = [];
  //@Input() public buttonState;
  category: any;
  empData: any;
  bsModalRef?: BsModalRef;
  tableRowId: any;
  roleJobPositionDetails: [];
  empArray: any = [];
  userId: any;
  salValue: boolean = false
  searchField: any;
  private onDestroy$: Subject<void> = new Subject<void>();
  @Output() public rolepersessData_child = new EventEmitter();
  filteredListret: any;
  empValues: any;
  dataAll: { index: any, arr: string[] };
  public isActiveInactivePerm: boolean;
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }
  constructor(private formBuilder: FormBuilder, private modalService: BsModalService,
    private toaster: ToasterService, private helper: HelperService, private adminService: AdminService,
    private empService: EmployeeService, private dialogser: DialogService,
    private permser: PermissionService) {

    this.roleGeneralForm = this.formBuilder.group({
      rolePermission: this.formBuilder.array([this.addUserFormGroup()]),
    });
  }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.isActiveInactivePerm = this.permser.isActiveInactive;

    this.roleGeneralForm = this.formBuilder.group({
      roleName: [null, Validators.required],
      // roledescription: [null],
      isActive: [true],
      // rolelabor: [true],
      categoryid: [null],
      // roleFilters: [null],
      // selectedJobRole: [null],
      rolePermission: this.formBuilder.array([
        this.formBuilder.group({
          //userid: [],
          userid: [null],
          empWage: [null],
          empOtCharges: [null],
          // createdBy : this.loginInfo.userId,
          // empPositionId:this.roleId
        }),
      ]),
    });
    this.SalesSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 2,
    };
    this.getAllCatogoriesList();
    this.getAllEmployees();
    this.inputSearch = { value: '', index: 0 };
    this.dataAll = { index: null, arr: [] };
  }

  @Output() public role_general = new EventEmitter();
  @Output() public rolepermissId = new EventEmitter();
  @Output() public roleJobPositionList = new EventEmitter();
  @Output() public cashmgtdetails = new EventEmitter();

  get GeneralData() { return this.roleGeneralForm.controls; }

  @Input()
  public set DataIdx(tableRowId) {
    this.errorValue = "";
    this.helper.setValue("roleId", tableRowId);
    this.roleId = tableRowId;
    this.tableRowId = tableRowId;
    if (this.tableRowId != undefined && this.tableRowId != null && this.tableRowId != "") {
      this.getRole(this.tableRowId);
    }
  }
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }
  addUserFormGroup(): FormGroup {
    return this.formBuilder.group({
      roleId: [0],
      userid: [null],
      empWage: [null],
      empOtCharges: [null],

    });
  }
  addUserMulti(e) {
    const dynFormArray = this.roleGeneralForm.controls["rolePermission"] as FormArray;
    dynFormArray.push(this.addUserFormGroup());
    this.inputSearch.value = '';
    this.inputSearch.index = dynFormArray.length - 1;

  }
  DeleteItem(index: number): void {
    if (!((<FormArray>this.roleGeneralForm.get('rolePermission')).length == 1)) {
      (<FormArray>this.roleGeneralForm.get('rolePermission')).removeAt(index);
    }

  }
  clearSearchField() {
    this.searchField = ''
  }

  numberOnly(event: any) {
    // const charCode = (event.which) ? event.which : event.keyCode;
    // if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
    //   return false;
    // }
    // return true;
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  openModalWithComponent(e) {
    //this.getEmployee(this.tableRowId);
    let initialState = { roleItem: true, detailItem: e };
    this.bsModalRef = this.modalService.show(FilterTablePopupComponent, { initialState, class: 'filterTableModal modal-lg', keyboard: true, backdrop: 'static' });
    // modal-dialog-centered
    this.bsModalRef.content.closeBtnName = 'Close';
  }


  async onSaveGeneral(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.submittedGeneral = true;
      this.errorValue = "";
      if (this.roleGeneralForm.invalid) {
        return;
      }

      let roleDetails;
      roleDetails = this.roleGeneralForm.value;
      roleDetails.tenantid = this.loginInfo.tenantId;
      roleDetails.userid = this.loginInfo.userId;
      roleDetails.createdBy = this.loginInfo.userId;
      roleDetails.roleId = this.roleId;
      this.adminService.SaveRole(roleDetails).subscribe(async res => {
        this.rolepermissId.emit({ res: res, name: roleDetails.roleName });
        if (res.id == 0) {
          if (res.message == "Employee is already assigned") {
            this.toaster.errorPopUp("Can't allow same Role multiple to same Employee.");
          }
          else {
            this.toaster.errorPopUp(res.message);
          }
          return
        } else {

          this.helper.setValue('GetSessionData1', roleDetails);
          this.rolepersessData_child.emit({ data: roleDetails, event: "jobPosition", val: false });
          await this.dialogser.ClearSession();
        }
        this.submittedGeneral = false;
        resolve({ res: res });

      });
    });
  }

  getAllCatogoriesList() {
    this.category = [];
    const Categoryinput: ISelectCategory = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.adminService.SelectLabourCategory(Categoryinput)
      .pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.category = res;
      })
  }

  getAllEmployees() {
    const employee: GetAllUserList = {
      loginUserId: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.empService.GetAllEmployees(employee)
      .pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.empData = res.filter(x => x.isActive == true && x.salaried == false && x.primaryRoleId != null);

        //this.filteredList2 = response
        this.empValues = this.empData;
        this.variables = this.empData.slice();
        for (let i = 0; i < this.empData.length; i++) {
          this.dataAll.arr[i] = this.empData;
        }
        // this.dataAll.arr= this.empData;
      })
  }

  onChangeEmp(event: any, e) {
    if (event.source.selected) {
      if (e.salaried == true) {
        this.salValue = true
      } else {
        this.salValue = false
      }
    }
  }

  onselectemp(e) {
    this.helper.setValue('GetSessionData1', this.roleGeneralForm.value);
    this.helper.setValue('OnDirty', true);
    this.rolepersessData_child.emit({ data: this.roleGeneralForm.value, event: "jobPosition", val: true });
  }

  getRole(e) {
    const roleDetails: IRole = {
      id: e,
      tenantId: this.loginInfo.tenantId
    }
    this.adminService.GetRole(roleDetails).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      // this.empData = response.roleDetails.

      this.roleEmployeearray = response.rolePermission.filter(x => x.salaried == false);
      const dynFormArray = this.roleGeneralForm.controls["rolePermission"] as FormArray;
      dynFormArray.controls = [];
      this.roleGeneralForm.controls.roleName.setValue(response.roleDetails[0].roleName);
      this.roleGeneralForm.controls.categoryid.setValue(response.roleDetails[0].categoryid);
      //this.roleGeneralForm.controls.userid.setValue(response.rolePermission[j].userid);
      if (this.roleEmployeearray != undefined && this.roleEmployeearray != null && this.roleEmployeearray != ""
        && this.roleEmployeearray != "[]") {
        if (this.roleEmployeearray.length > 0) {
          for (let j = 0; j < this.roleEmployeearray.length; j++) {
            //this.empData.setValue(response.rolePermission[j].userid);
            // this.getAllEmployees();
            //this.empData = response.rolePermission;
            dynFormArray.push(this.addUserFormGroup());
          }
        }
      }
      else if (this.roleEmployeearray.length == 0) {
        dynFormArray.push(this.addUserFormGroup());
      }
      this.roleGeneralForm.patchValue(response);
      this.roleGeneralForm.patchValue({ isActive: response.roleDetails[0].isActive });
      this.cashmgtdetails.emit(response.cashManagment[0]);
    }),
      err => console.error('Error', err),
      () => console.log('HTTP request completed.')
  }


  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  RolePermValid() {
    if (this.roleGeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }

    else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.roleGeneralForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.roleGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.rolepersessData_child.emit(this.roleGeneralForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.rolepersessData_child.emit({ data: data, event: "jobPosition", val: true });
  }

  async onKey(value, e) {
    this.inputSearch.index = e;
    const dynFormArray = this.roleGeneralForm.controls["rolePermission"] as FormArray;
    for (let i = 0; i < dynFormArray.length; i++) {
      if (dynFormArray.at(i) == dynFormArray.at(e)) {
        this.dataAll.arr[i] = await this.search(value, i);
      }
      else {
        this.dataAll.arr[i] = this.empData;
      }
    }
  }

  search(value, e) {
    this.filteredListret = this.variables.filter((unit) =>
      unit.name.toUpperCase().includes(value.toUpperCase()));
    if (this.filteredListret.length == 0) {
      return [...this.filteredListret];
    }
    return this.filteredListret.sort((a, b) => 0 - (a.name > b.name ? -1 : 1)); // asending order
    // }
    // }
  }

  onKeypressEvent(elem): boolean {

    const charCode = (elem.which) ? elem.which : elem.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (elem.target.value.length < 3 || (elem.target.value.length > 3 || elem.target.value.length < 11)) {
      if (elem.key === "-") elem.preventDefault();
    }
    // this.GeneralCompanyForm.get("zipCode").patchValue(elem.target.value.toUpperCase());
    if (elem.target.value.length <= 10) {
      if (elem.target.value.length === 5) {
        elem.target.value = `${elem.target.value}-`;
      }
    } else {
      elem.target.value = elem.target.value.slice(0, -1);
    }
    return true;
  };

  handleInput(event: KeyboardEvent): void {
    event.stopPropagation();
  }
  // isFormPageDirty() {
  //   let d = this.roleGeneralForm.dirty;
  //   this.helper.setValue('GetSessionData1', this.roleGeneralForm.value);
  //   if (d == true) {
  //     this.helper.setValue('OnDirty', true);
  //   }
  //   return d;
  // }
}
