<table mat-table [dataSource]="employee" class="mat-elevation-z8 full-width-table dataTable flip-group-table"
  style="width: 100%;">
  <ng-container matColumnDef="groupHeader">
    <th mat-header-cell *matHeaderCellDef>
      <span> Sales Area </span>
    </th>
    <td mat-cell *matCellDef="let data">
      <span (click)="treeControl.toggle(data)" (click)="itemClick(data.groupHeader)"
        [ngClass]="data.groupHeader!=null?'emp': '' "> {{data.groupHeader}} </span>
      <span class="group-total"> {{data.grouptotal}} </span>
      <span class="report-totals"> {{data.reporttotal}} </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="employee">
    <th mat-header-cell *matHeaderCellDef>Employee</th>
    <td mat-cell *matCellDef="let data">
      <span (click)="treeControl.toggle(data)" [ngClass]="data.employee!=null?'emp': '' "
        (click)="itemClick(data.employee)">{{data.employee}}</span>
      <span class="employee-total"> {{data.employeetotal}} </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="discountName">
    <th mat-header-cell *matHeaderCellDef>Discount Name</th>
    <td mat-cell *matCellDef="let data" [ngClass]="data.discountName != null ? 'disc_ul' : ''"
      (click)="itemClick(data.discountName)">{{data.discountName}}</td>
  </ng-container>
  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef># of Discounts</th>
    <td mat-cell *matCellDef="let data">{{data.quantity}}</td>
  </ng-container>
  <ng-container matColumnDef="discountTotal">
    <th mat-header-cell *matHeaderCellDef>Total $</th>
    <td mat-cell *matCellDef="let data">
      <span>{{data.discountTotal}}</span>
      <span class="total-amout">{{data.totalamount}}</span>
    </td>
  </ng-container>
  <!-- <ng-container matColumnDef="groupHeader">
    <th mat-header-cell *matHeaderCellDef>
      <span> Sales Area </span>
    </th>
    <td mat-cell *matCellDef="let data">
      <span (click)="treeControl.toggle(data)" (click)="itemClick(data.groupHeader)"
        [ngClass]="data.groupHeader!=null?'emp': '' "> {{data.groupHeader}} </span>
      <span class="group-total"> {{data.grouptotal}} </span>
      <span class="report-totals"> {{data.reporttotal}} </span>
    </td>
  </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row (click)="changeTableRowColor(row.id,row)" *matRowDef="let row; columns: displayedColumns;"></tr>
</table>