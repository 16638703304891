import { Component, OnInit, ChangeDetectorRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ItemService } from 'src/app/services/item.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { IActiveUser, IListInfo, GetAllUserList } from 'src/app/interfaces/iadmin';
import { MenuService } from 'src/app/services/menu.service';
import { menuEdit, menusave, IMenuSize, ActivateMenuSize } from 'src/app/interfaces/imenu';
import { MenuSizeGeneralComponent } from '../menu-size-general/menu-size-general.component';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-menu-size',
  templateUrl: './menu-size.component.html',
  styleUrls: ['./menu-size.component.css']
})

export class MenuSizeComponent implements OnInit {
  private buttonDisable: boolean;
  public displayedColumns = [];
  columns = [];

  public dataSource = new MatTableDataSource<any>();
  public menuSizeRow: any;
  loginInfo: any;
  tenantId: any;
  tableEnable: boolean = true;
  public tableRowId: any;
  public tableRowColor: any;
  public menuSizeItem: {};
  public activeDeactiveValue: any;
  sizeid: any = 0;
  menusize: any;
  public EmployeeTableData: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  isInvalid: boolean = false;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public mnusizesessData = new EventEmitter();

  //@Input() public buttonDisable
  submittedGeneral: any;
  public modifierItemsData: any;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  @ViewChild(MenuSizeGeneralComponent) generalComponent: MenuSizeGeneralComponent;
  matopen:boolean = false;
  matstate($event){
    this.matopen =$event
  }
  constructor(private menuSizeDetails: ItemService, private toaster: ToasterService, private formBuilder: FormBuilder,
    private helper: HelperService, private objMenu: MenuService, private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.MenuSizePage");

    this.buttonDisable = false;
    this.employeeName = "Menu Sizes";
    this.employee = "Menu-Sizes";
    this.employeeIcon = "assets/components/images/menu-item.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.tableEnable = true;
    this.loginInfo = this.helper.getValue('LoginInfo');
    // this.GetMenuSizeList();
    // if(this.loginInfo.tenantId == 0){
    //   this.toaster.error("Please Select store");
    //   this.buttonDisable = true;
    // }else{
    //   this.GetMenuSizeList();
    // }
    this.GetMenuSizeList();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  async getTableIdx(event) {
    //this.gentab.isFormPageDirty();
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("MenuSizePage");
      if (conf == 'success') { // Navigate Away
        if (event == this.dialogser.ModifiedId) {
          this.generalComponent.GetMenuSizeList(event);
        }
        this.dialogser.ClearSession();
        this.generalComponent.MenuSizeForm.reset();

        //General Data
        this.dialogser.ModifiedId = event;
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.isInvalid = false;
        this.generalComponent.requiredError = false;
        this.generalComponent.errorValue = "";
        this.flipcomp.rowClicked = event;
      }
      else // Cancel
      {
        //General Data
        this.generalComponent.MenuSizeForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.mnusizesessData.emit({
          data: this.generalComponent.MenuSizeForm.value, event: "MenuSizePage", val: true,
          itmname: this.generalComponent.MenuSizeForm.value.name
        });
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event)
      this.tableRowId = event;
      this.tableRowColor = event;
      this.isInvalid = false;
      this.generalComponent.requiredError = false;
      this.generalComponent.errorValue = "";
    }
  }

  GetMenuSizeList() {
    const menuList: menuEdit = {
      sizeid: this.sizeid,
      tenantid: this.loginInfo.tenantId,
    }

    this.objMenu.MenuSizeList(menuList) // select method
      .subscribe(res => {
        this.dataSource.data = res;
        this.EmployeeTableData = res
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true).sort((a, b) => 0 - (a.id < b.id ? -1 : 1));
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Size Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
      this.menuSizeRow = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  // SaveManuSize() // method name
  // {
  //   const MenuSaveList: menusave = {
  //     id: 0,
  //     name: "",
  //     userid: 1,
  //     availableloc: true,
  //     scheduleAvail: true,
  //     tenantId: 0,
  //     sizedetails: 0
  //   }

  //   this.objMenu.MenuSave(MenuSaveList)
  //     .subscribe(res => {
  //     }
  //     )
  // }

  getGeneralDetail(event) {
    this.GetMenuSizeList();
    //this.addMenuSize();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name)
  }

  getTableName(name) {
    this.employeeName = name;
    this.employee = "Menu-Sizes";
    this.employeeIcon = "assets/components/images/menu-item.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  // getTableId(event) {
  //   this.helper.setValue('Sessionid_help', event)
  //   this.tableRowId = event;
  //   this.tableRowColor = event;
  // }

  async addMenuSize() {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    }
    else {
      var conf = await this.dialogser.confirm("MenuSizePage");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
      }
      else {
        this.generalComponent.MenuSizeForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.generalComponent.errorValue = "";
    this.isInvalid = false;
    this.generalComponent.requiredError = false;
    this.employeeName = "Menu Size";
    this.tableRowColor = 'transparent';
    this.generalComponent.MenuSizeForm.reset();
    this.generalComponent.ngOnInit();
    this.titleName.emit(this.employeeName)
    this.generalComponent.submittedGeneral = false;

    let dynControl = this.generalComponent.MenuSizeForm.controls['menuItemSizeList'] as FormArray;
    for (let i = 0; i < dynControl.length; i++) {
      if (!((<FormArray>this.generalComponent.MenuSizeForm.get('menuItemSizeList')).length == 1)) {
        (<FormArray>this.generalComponent.MenuSizeForm.get('menuItemSizeList')).removeAt(i);
      }
    }
    this.tableRowId = 0;
  }

  checkValue(event) {
    this.activeDeactiveValue = event
    let currentTarget = event.e.currentTarget.checked
    const active: ActivateMenuSize = {
      userid: this.loginInfo.userId,
      id: event.id,
      isActive: event.e.currentTarget.checked
    }
    this.objMenu.ActivateMenu(active).subscribe(
      (response) => {
        if (currentTarget) {
          this.toaster.successPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
        }
        this.GetMenuSizeList();
      },
    )
  }

  changeTableRowColor(event) {
    this.menuSizeRow = this.menuSizeRow === event.idx ? -1 : event.idx;
    this.getDealer(event.id)
  }

  getDealer(empid) {
    this.tenantId = empid
    const employee: GetAllUserList = {
      tenantId: empid
    }
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  onSaveMenuSize() {
    this.generalComponent.onSaveMenuSize();
    if (this.generalComponent.MenuSizeForm.invalid) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getmnusizesessData(e) {
    this.mnusizesessData.emit(e)
  }
}
