<div class="menu_size_general">
    <form class="menu_size_form loginForm" [formGroup]="MenuSizeForm" (change)="onchange(MenuSizeForm.value)">
        <div class="row">
            <div class="col-sm-12">
                <div class="pull-right-Permission">
                    <!-- <span class="marR5">Active</span> -->
                    <span class="marR5" *ngIf="MenuSizeData.isActive.value == true">Active</span>
                    <span class="marR5" *ngIf="MenuSizeData.isActive.value == false">Inactive</span>
                    <label class="switch switch-width">
                                <input type="checkbox" id="IsActive" formControlName="isActive"
                                [attr.disabled]="isActiveInactivePerm ? true : null"/>
                                <span class="slider round"></span>
                                <div class="toggle-button-text outshadow employee_onoff">
                                  <div class="toggle-button-text-on"></div>
                                  <div class="toggle-button-text-off"></div>
                                </div>
                              </label>
                    <!-- <div class="switchToggle">
                                <input type="checkbox" id="IsActive" formControlName="isActive">
                                <label class="toggle-button-switch" for="IsActive"></label>
                                <div class="toggle-button-text outshadow employee_onoff permissionButton menuToggle">
                                    <div class="toggle-button-text-on"></div>
                                    <div class="toggle-button-text-off"></div>
                                </div>
                            </div> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Name*</label>
                    <input type="text" class="form-control" formControlName="name" placeholder="Name" [ngClass]="{ 'is-invalid': (submittedGeneral && MenuSizeData.name.errors) || (requiredError && MenuSizeData.name.errors)}" appInputRestriction maxlength="50" (input)="getMaxValue($event)"
                        (keypress)="Highlight()" (focusout)="MenuSizeValid()" />
                    <div class="error">{{errorValue}}</div>
                    <div *ngIf="submittedGeneral && MenuSizeData.name.errors" class="invalid-feedback">
                        <div *ngIf="MenuSizeData.name.errors.required">Name is required</div>
                    </div>
                </div>
                <div class="form-group" style="display: flex;">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="customCheckbox" formControlName="availableloc">
                            <label class="custom-control-label" for="textMsg"></label>
                        </div>
                    </div>
                    <label class="customlable mb-0">Available All Locations</label>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="row">
                    <div class="menuSizeGeneral col-sm-12">
                        <button class="plus_btn inshadow marR5 mt-2" (click)="addItemSizeMulti()">
                            <i class="fa fa-plus"></i></button>
                        <label>
                            <p>Items Assigned to this size</p>
                        </label>
                        <ng-container formArrayName="menuItemSizeList">
                            <div class="row no-gutters">
                                <div class="col-sm-3 col-3">
                                    <!-- <label>Item</label><label class="text-danger">*</label> -->
                                    <label>Item</label>
                                </div>
                                <div class="col-sm-3 col-3">
                                    <!-- <label>Price</label><label class="text-danger">*</label> -->
                                    <label>Price</label>
                                </div>
                                <div class="col-sm-3 col-3">
                                    <!-- <label>Cost</label><label class="text-danger">*</label> -->
                                    <label>Cost</label>
                                </div>
                                <div class="col-sm-2 col-2">
                                    <label>Cost%</label>
                                </div>
                                <div class="col-sm-1 col-1">
                                    <label>Remove</label>
                                </div>
                            </div>
                            <div class="inline-form-group job_wrap">
                                <div class="row no-gutters" *ngFor="let $menuItemSizeList of MenuSizeForm.get('menuItemSizeList')['controls']  ; let i=index " [formGroup]="$menuItemSizeList" autocomplete="off">
                                    <div class="col-sm-3 col-3 padR5">

                                        <!-- <select type="text" class="form-control" formControlName="itemID"
                                            [ngClass]="{ 'is-invalid': submittedGeneral && $menuItemSizeList.controls.itemID.errors }">
                                            <option value="" selected disabled>Select Item</option>
                                            <option *ngFor="let item of ItemsList" [value]="item.id">{{item.name}}
                                            </option>
                                        </select>
                                        <div *ngIf="submittedGeneral && $menuItemSizeList.controls.itemID.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="$menuItemSizeList.controls.itemID.errors.required">Item is
                                                required</div>
                                        </div> -->

                                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="itemID" (selectionChange)="onchange(MenuSizeForm.value)">
                                            <mat-option value="" selected disabled>Select Item</mat-option>
                                            <mat-option *ngFor="let item of ItemsList" [value]="item.id">{{item.name}}
                                            </mat-option>
                                        </mat-select>

                                    </div>
                                    <div class="col-sm-3 col-3 padR5">
                                        <!-- <input type="text" class="form-control" placeholder="$0.00" appInputRestriction
                                            formControlName="price" (input)="onPriceChange($event.target.value,i)"
                                            [ngClass]="{ 'is-invalid': submittedGeneral && $menuItemSizeList.controls.price.errors }" />
                                        <div *ngIf="submittedGeneral && $menuItemSizeList.controls.price.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="$menuItemSizeList.controls.price.errors.required">Price is
                                                required</div>
                                        </div> -->
                                        <input type="text" class="form-control" placeholder="$0.00" appInputRestriction formControlName="price" (input)="onPriceChange($event.target.value,i)" appDecimalLimit/>
                                    </div>
                                    <div class="col-sm-3 col-3 padR5">
                                        <!-- <input type="text" class="form-control" placeholder="$0.00" appInputRestriction
                                            formControlName="cost" (input)="onCostChange($event.target.value ,i)"
                                            [ngClass]="{ 'is-invalid': submittedGeneral && $menuItemSizeList.controls.cost.errors }" />
                                        <div *ngIf="submittedGeneral && $menuItemSizeList.controls.cost.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="$menuItemSizeList.controls.cost.errors.required">Cost is
                                                required</div>
                                        </div> -->
                                        <input type="text" class="form-control" placeholder="$0.00" appInputRestriction formControlName="cost" (input)="onCostChange($event.target.value ,i)" appDecimalLimit/>
                                    </div>
                                    <div class="col-sm-2 col-2 padR5">
                                        <input type="text" class="form-control" placeholder="$0.00" readonly formControlName="costpercent" appDecimalLimit appInputRestriction/>
                                    </div>

                                    <div class="col-sm-1 col-1" style="text-align: center;">
                                        <i class="fa fa-remove" aria-hidden="true" style="font-size: 19px;" (click)="DeleteItem(i)"></i>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group mt-2">
            <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveMenuSize();" [disabled]="buttonDisable ? true : null">
                    <!-- [disabled]="buttonState ? true : null" -->
                 <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
            </button>
        </div>

    </form>
</div>
