<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="addMenuItem()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global_submit" type="submit" (click)="onSave();">
                <!-- [disabled]="buttonStatus ?  true : null" -->
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
            </button>
        </div>
    </div>
</div>
<div class="row second-sec">
    <div class="col-sm-4">
        <div *ngIf="tableEnable">
            <app-fliptable-items [dealerItem]="menuSizeRow" [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" [DataCom]="employee" (clearDetails)="addMenuItem()" (tableEmpName)="getTableName($event)" (tableItemId)="getTableId($event)"
                [employeeItem]="tableRowColor" [EmployeeTableData]="EmployeeTableData" (breakActive)="checkValue($event)" [menuItemData]="menuItemData" [_itemid]="itmId" (typeID)="menuFilterAssign($event)" (typenotID)="menuFilterOut($event)" (btnActive)="btnActiveStatus($event)">
            </app-fliptable-items>
        </div>
    </div>
    <div class="col-sm-8">

        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of ['General','Logistics','Recipe','Modifiers']; let i = index" [expanded]="indexExpanded == i" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-expansion-panel-header (click)="expandPanel(item,index)" [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                    <mat-panel-title (click)="onChangeAccordion($event,index)" [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}}</mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General'">
                    <app-menu-item-general [MenuRowItem]="tableRowId" (menuId)="getMenuId($event)" (ItemIdEvent)="getMenuItemID($event)" (isInvalid)="isInvalidAccordion($event)" (getMenuPricingList)="getPricingList($event)" [buttonState]="buttonDisable" (mainEmit)="getmainEmit($event)"
                        (mnuitmsessData_child)="getmnuitmsessData($event)" (MainDishCheck)="GetMainDishStatus($event)" (matopen)="matstate($event)">
                    </app-menu-item-general>
                </div>
                <div *ngIf="item == 'Logistics'">
                    <app-menu-item-logistics [MenuRowItem]="tableRowId" [ItemId]="menuId" [menuPriceList]="menuPriceSizeList" [colorBtn]="btnColorChange" [KDScolorBtn]="KDSbtnColor" [buttonState]="buttonDisable" [DataCom]="employeeName" (mnuitmlogsessData_child)="getmnuitmsessData($event)">
                    </app-menu-item-logistics>
                </div>
                <div *ngIf="item == 'Recipe'">
                    <app-drag-drop-builder [ItemList]="ItemListData" [MenuRowItem]="tableRowId" [SelectedPageInd]="PageName" (generalItem)="getGeneralDetail($event)" saveId="Recipe" (layoutSave)="onSaveItem($event)" [buttonState]="buttonDisable"></app-drag-drop-builder>
                </div>
                <div *ngIf="item == 'Modifiers'">
                    <app-menu-item-modifier [menuItemIn]="menuItemData" [menuItemMatrix]="ItemMatrix" [SelectedPageInd]="PageName" [buttonState]="buttonDisable" [MenuRowItem]="tableRowId" [mainCheck]="checkValueMain">
                    </app-menu-item-modifier>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="form-group">
            <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="onSave();">

                <!-- [disabled]="buttonStatus ?  true : null" -->
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
            </button>
        </div>
    </div>
</div>
