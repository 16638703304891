
<form class="loginForm" [formGroup]="MenuPageGeneralForm" (change)="onchange(MenuPageGeneralForm.value)">
    <div class="pull-right-active">
        <span>Active</span>
        <div class="checkbox-table accordion-checkbox" id="checkbox-box">
            <div class="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" class="custom-control-input" name="customCheckbox" id="activeForm"
                    formControlName="isActive" checked="isActive" 
                    [attr.disabled]="isActiveInactivePerm ? true : null"/>
                <label class="custom-control-label" for="activeForm"></label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <div class="form-group">
                <label>Name</label> <label class="text-danger">*</label>
                <input type="text" formControlName="name" class="form-control input-lg inputbox_login" placeholder="Name"
                [ngClass]="{'is-invalid': (submittedGeneral && GeneralData.name.errors) || (requiredError && GeneralData.name.errors)}" appInputRestriction maxlength="50" (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="MenuPageValid()"/>
                <div class="error">{{errorValue}}</div>
                <div *ngIf=" (submittedGeneral && GeneralData.name.errors) || (requiredError && GeneralData.name.errors)" class="invalid-feedback">
                    <div *ngIf="GeneralData.name.errors.required">Name is required</div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group mt-2">
        <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="OnSaveMenuPage();">
                <!-- [disabled]="buttonDis ? true : null" -->
            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label> 
        </button>
    </div>
</form>