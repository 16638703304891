import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Group } from 'src/app/interfaces/i-report';
import { MatSort } from '@angular/material/sort';
import { FormBuilder } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
import { FliptableLaborCascadeComponent } from 'src/app/elements/fliptable-labor-cascade/fliptable-labor-cascade.component';

interface FoodNode {
  regHours?: number;
  regWage?: number;
  overtimeHour?: number;
  grosssales?: number;
  groupHeader?: string;
  laborCost?: number;
  overtimeWage?: any;
}

interface Periodic2 {
  regHours?: number;
  regWage?: number;
  overtimeHour?: number;
  sales?: number;
  category?: any;
  groupHeader?: string;
  laborCost?: number;
  overtimeWage?: any;
}

interface Periodic3 {
  period?: number;
  regHours?: number;
  regWage?: number;
  overtimeHour?: number;
  sales?: number;
  employee?: any;
  groupHeader?: string;
  laborCost?: number;
  overtimeWage?: any;
  position?: any;
}

interface Periodic4 {
  laborCategory?: any;
  regHours?: number;
  regWage?: number;
  overtimeHour?: number;
  sales?: number;
  employee?: any;
  groupHeader?: string;
  laborCost?: number;
  overtimeWage?: any;
  jobPosition?: any;
}

interface Periodic5 {
  laborCategory?: any;
  regHours?: number;
  regWage?: number;
  overtimeHour?: number;
  sales?: number;
  employee?: any;
  groupHeader?: string;
  laborCost?: number;
  overtimeWage?: any;
  jobPosition?: any;
}

const EMPLOYEE_DATA1: FoodNode[] = [
  {
    groupHeader: "10:00 am - 10:59 am",
    regHours: 10,
    regWage: 200,
    overtimeHour: 2,
    overtimeWage: 1,
    grosssales: 1,
    laborCost: 3
  },
  {
    groupHeader: "10:00 am - 10:59 am",
    regHours: 12,
    regWage: 200,
    overtimeHour: 3,
    overtimeWage: 1,
    grosssales: 3,
    laborCost: 2
  },
  {
    groupHeader: "10:00 am - 10:59 am",
    regHours: 13,
    regWage: 200,
    overtimeHour: 5,
    overtimeWage: 1,
    grosssales: 2,
    laborCost: 4
  },
  {
    groupHeader: "11:00 am - 11:59 am ",
    regHours: 18,
    regWage: 11,
    overtimeHour: 3,
    overtimeWage: 2,
    grosssales: 6,
    laborCost: 3
  },
  {
    groupHeader: "11:00 am - 11:59 am ",
    regHours: 20,
    regWage: 400,
    overtimeHour: 1,
    overtimeWage: 1,
    grosssales: 1,
    laborCost: 1
  },
  {
    groupHeader: "11:00 am - 11:59 am ",
    regHours: 1,
    regWage: 400,
    overtimeHour: 1,
    overtimeWage: 1,
    grosssales: 4,
    laborCost: 2
  }
];


const LABOR_DETAILS_DATA1: Periodic2[] = [
  {
    groupHeader: "10:00 am - 10:59 am",
    category: 'Bartender',
    regHours: 10.00,
    regWage: 80.00,
    overtimeHour: 0.00,
    overtimeWage: 0.00,
    sales: 100.00,
    laborCost: 80
  },
  {
    groupHeader: "10:00 am - 10:59 am",
    category: 'Cook',
    regHours: 20.00,
    regWage: 60.00,
    overtimeHour: 0.00,
    overtimeWage: 0.00,
    sales: 150.00,
    laborCost: 15
  },
  {
    groupHeader: "10:00 am - 10:59 am",
    category: 'Chef',
    regHours: 10.00,
    regWage: 40.00,
    overtimeHour: 1.00,
    overtimeWage: 10.00,
    sales: 150.00,
    laborCost: 15
  },
  {
    groupHeader: "10:00 am - 10:59 am",
    category: 'Totals',
    regHours: 40.00,
    regWage: 180.00,
    overtimeHour: 1.00,
    overtimeWage: 1.00,
    sales: 400.00,
    laborCost: 10
  }
];

const EMPLOYEE_LABOR_DETAILS_DATA: Periodic3[] = [
  {
    position: "Bar Tender",
    groupHeader: "10:00 am - 10:59 am",
    employee: "Totals",
    regHours: 1.00,
    regWage: 10.00,
    overtimeHour: 2.00,
    overtimeWage: 20.00,
    sales: 1.00,
    laborCost: 3
  }
];

const LABOR_CATEGORY_DATA: Periodic4[] = [
  {
    laborCategory: "FOH",
    groupHeader: "10:00 am - 10:59 am",
    jobPosition: "Server",
    regHours: 1.00,
    regWage: 10.00,
    overtimeHour: 2.00,
    overtimeWage: 20.00,
    sales: 1.00,
    laborCost: 3
  },
  {
    laborCategory: "FOH",
    groupHeader: "10:00 am - 10:59 am",
    jobPosition: "Host",
    regHours: 3.00,
    regWage: 20.00,
    overtimeHour: 2.00,
    overtimeWage: 10.00,
    sales: 5.00,
    laborCost: 4
  },
  {
    laborCategory: "FOH",
    groupHeader: "10:00 am - 10:59 am",
    jobPosition: "Totals",
    regHours: 4.00,
    regWage: 30.00,
    overtimeHour: 4.00,
    overtimeWage: 40.00,
    sales: 6.00,
    laborCost: 7
  },
  {
    laborCategory: "BOH",
    groupHeader: "10:00 am - 10:59 am",
    jobPosition: "Server",
    regHours: 1.00,
    regWage: 10.00,
    overtimeHour: 2.00,
    overtimeWage: 20.00,
    sales: 1.00,
    laborCost: 3
  },
  {
    laborCategory: "BOH",
    groupHeader: "10:00 am - 10:59 am",
    jobPosition: "Busser",
    regHours: 3.00,
    regWage: 20.00,
    overtimeHour: 2.00,
    overtimeWage: 10.00,
    sales: 5.00,
    laborCost: 4
  },
  {
    laborCategory: "BOH",
    groupHeader: "10:00 am - 10:59 am",
    jobPosition: "Totals",
    regHours: 4.00,
    regWage: 30.00,
    overtimeHour: 4.00,
    overtimeWage: 40.00,
    sales: 6.00,
    laborCost: 7
  }
];

const LABOR_CATEGORY_DATA2: Periodic5[] = [
  {
    laborCategory: "FOH",
    groupHeader: "10:00 am - 10:59 am",
    jobPosition: "Server",
    employee: 'Susan B',
    regHours: 1.00,
    regWage: 10.00,
    overtimeHour: 2.00,
    overtimeWage: 20.00,
    sales: 1.00,
    laborCost: 3
  },
  {
    laborCategory: "FOH",
    groupHeader: "10:00 am - 10:59 am",
    jobPosition: "Server",
    employee: 'Mike J',
    regHours: 3.00,
    regWage: 20.00,
    overtimeHour: 2.00,
    overtimeWage: 10.00,
    sales: 5.00,
    laborCost: 4
  },
  {
    laborCategory: "BOH",
    groupHeader: "10:00 am - 10:59 am",
    employee: 'Dishy Dan',
    jobPosition: "Server",
    regHours: 1.00,
    regWage: 10.00,
    overtimeHour: 2.00,
    overtimeWage: 20.00,
    sales: 1.00,
    laborCost: 3
  },
  {
    laborCategory: "BOH",
    groupHeader: "10:00 am - 10:59 am",
    employee: 'Calvin Cook',
    jobPosition: "Server",
    regHours: 3.00,
    regWage: 20.00,
    overtimeHour: 2.00,
    overtimeWage: 10.00,
    sales: 5.00,
    laborCost: 4
  },
  {
    laborCategory: "BOH",
    groupHeader: "10:00 am - 10:59 am",
    jobPosition: 'Server',
    employee: "Totals",
    regHours: 4.00,
    regWage: 30.00,
    overtimeHour: 4.00,
    overtimeWage: 40.00,
    sales: 6.00,
    laborCost: 7
  }
];

@Component({
  selector: 'app-labor-summary',
  templateUrl: './labor-summary.component.html',
  styleUrls: ['./labor-summary.component.css']
})
export class LaborSummaryComponent implements OnInit {

  loginInfo: any
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public backSalesItem = new EventEmitter
  public employeeName: any;
  public employee: any;
  public employeeIcon: any
  @Input() public noSales
  firstTableName: any

  public firstTableShow: boolean = false
  public secondTableShow: boolean = false
  public backToSales: boolean = false
  public dataSource1 = new MatTableDataSource<any | Group>([]);
  public alldata1: any[];

  public columns1: any[];
  public displayedColumns1: string[];
  public groupByColumns1: string[] = [];
  public displayedColumns2 = [];
  columns2 = [];
  public dataSource2 = new MatTableDataSource<any | Group>([]);
  public alldata2: any[];
  public groupByColumns2: string[] = [];
  columns3 = [];
  public dataSource3 = new MatTableDataSource<any | Group>([]);
  public alldata3: any[];
  public groupByColumns3: string[] = [];
  public displayedColumns3 = [];
  columnsLabor1 = [];
  public dataSourceLabor1 = new MatTableDataSource<any | Group>([]);
  public alldataLabor1: any[];
  public groupByColumnsLabor1: string[] = [];
  public displayedColumnsLabor1 = [];
  columnsLabor2 = [];
  public dataSourceLabor2 = new MatTableDataSource<any | Group>([]);
  public alldataLabor2: any[];
  public groupByColumnsLabor2: string[] = [];
  public displayedColumnsLabor2 = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FliptableLaborCascadeComponent) tablecollapse: FliptableLaborCascadeComponent
  laborFirstShow: boolean = true;
  laborSecondCategoryShow: boolean = false;
  laborSecondShow: boolean = false;
  laborThirdShow: boolean = false;
  secondTableName: any;
  tableDisplay: any;
  laborThirdCategoryShow: boolean;
  isChecked: any;

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private reportService: ReportWebSocketService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.laborReport");

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Labor Summary";
    this.employee = "Reports";
    this.employeeIcon = "assets/components/images/reports-icon.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  GetLaborData(e?) {
    this.laborFirstShow = true;
    this.laborSecondShow = false;
    this.tableDisplay = false;
    this.laborThirdShow = false;
    this.laborThirdCategoryShow = false;
    this.laborSecondCategoryShow = false;
    this.reportService.SelectLaborCostReport(e).subscribe(res => {
      this.alldata1 = res.Summary;
      this.columns1 = [
        {
          field: 'regHours', header: 'Regular Hours'
        }, {
          field: 'regWage', header: 'Regular Wage'
        }, {
          field: 'overtimeHour', header: 'Overtime Hours'
        }, {
          field: 'overtimeWage', header: 'Overtime Wage'
        }, {
          field: 'grosssales', header: 'Gross Sales'
        }, {
          field: 'laborCost', header: 'Labor Cost'
        }
      ];
      this.displayedColumns1 = this.columns1.map(column => column.field);
      this.groupByColumns1 = ['groupHeader'];
      //this.alldata1 = EMPLOYEE_DATA1;
      this.dataSource1.data = this.tablecollapse.addGroups(this.alldata1, this.groupByColumns1);

    });


  }
  LaborCheck(e) {
    if (this.isChecked == true) {
      this.GetLaborCategoryData(e);
    }
    else {
      this.GetLaborDetails(e);
    }
  }

  GetEmployeeLaborDetails(e) {
    this.secondTableName = e;
    this.laborFirstShow = false;
    this.laborSecondShow = false;
    this.tableDisplay = false;
    this.laborThirdShow = true;
    this.laborSecondCategoryShow = false;
    this.laborThirdCategoryShow = false;

    this.columns3 = [{
      field: 'employee', header: 'Employee'
    }, {
      field: 'period', header: 'Period Hours'
    }, {
      field: 'regHours', header: 'Regular Hours'
    }, {
      field: 'regWage', header: 'Regular Wage'
    }, {
      field: 'overtimeHour', header: 'Overtime Hours'
    }, {
      field: 'overtimeWage', header: 'Overtime Wage'
    }, {
      field: 'sales', header: 'Sales'
    }, {
      field: 'laborCost', header: 'Labor Cost'
    }
    ];
    this.displayedColumns3 = this.columns3.map(column => column.field);
    this.groupByColumns3 = ['groupHeader'];
    this.alldata3 = EMPLOYEE_LABOR_DETAILS_DATA;
    this.dataSource3.data = this.tablecollapse.addGroups(this.alldata3, this.groupByColumns3);
  }


  GetLaborDetails(e) {
    this.firstTableName = e;
    this.laborFirstShow = false;
    this.laborSecondShow = true;
    this.laborThirdShow = false;
    this.laborThirdCategoryShow = false;
    this.laborSecondCategoryShow = false;
    this.columns2 = [{
      field: 'category', header: 'Job'
    }, {
      field: 'regHours', header: 'Regular Hours'
    }, {
      field: 'regWage', header: 'Regular Wage'
    }, {
      field: 'overtimeHour', header: 'Overtime Hours'
    }, {
      field: 'overtimeWage', header: 'Overtime Wage'
    }, {
      field: 'sales', header: 'Sales'
    }, {
      field: 'laborCost', header: 'Labor Cost'
    }
    ];
    this.displayedColumns2 = this.columns2.map(column => column.field);
    this.groupByColumns2 = ['groupHeader'];
    this.alldata2 = LABOR_DETAILS_DATA1;
    this.dataSource2.data = this.tablecollapse.addGroups(this.alldata2, this.groupByColumns2);
  }

  isCheckBoxchecked(e) {
    this.isChecked = e;
  }

  GetLaborCategoryData(e) {
    this.firstTableName = e;
    this.laborFirstShow = false;
    this.laborSecondCategoryShow = true;
    this.laborThirdCategoryShow = false;
    this.laborThirdShow = false;
    this.tableDisplay = false;
    this.columnsLabor1 = [{
      field: 'jobPosition', header: 'Job'
    }, {
      field: 'regHours', header: 'Regular Hours'
    }, {
      field: 'regWage', header: 'Regular Wage'
    }, {
      field: 'overtimeHour', header: 'Overtime Hours'
    }, {
      field: 'overtimeWage', header: 'Overtime Wage'
    }, {
      field: 'sales', header: 'Sales'
    }, {
      field: 'laborCost', header: 'Labor Cost'
    }
    ];
    this.displayedColumnsLabor1 = this.columnsLabor1.map(column => column.field);
    this.groupByColumnsLabor1 = ['laborCategory', 'groupHeader'];
    this.alldataLabor1 = LABOR_CATEGORY_DATA;
    this.dataSourceLabor1.data = this.tablecollapse.addGroups(this.alldataLabor1, this.groupByColumnsLabor1);
  }

  GetLaborCategoryDataEmployees(e) {
    this.secondTableName = e;
    this.laborFirstShow = false;
    this.laborSecondCategoryShow = false;
    this.laborThirdCategoryShow = true;
    this.laborThirdShow = false;
    this.tableDisplay = false;
    this.columnsLabor2 = [{
      field: 'employee', header: 'Employee'
    }, {
      field: 'regHours', header: 'Regular Hours'
    }, {
      field: 'regWage', header: 'Regular Wage'
    }, {
      field: 'overtimeHour', header: 'Overtime Hours'
    }, {
      field: 'overtimeWage', header: 'Overtime Wage'
    }, {
      field: 'sales', header: 'Sales'
    }, {
      field: 'laborCost', header: 'Labor Cost'
    }
    ];
    this.displayedColumnsLabor2 = this.columnsLabor2.map(column => column.field);
    this.groupByColumnsLabor2 = ['laborCategory', 'groupHeader'];
    this.alldataLabor2 = LABOR_CATEGORY_DATA2;
    this.dataSourceLabor2.data = this.tablecollapse.addGroups(this.alldataLabor2, this.groupByColumnsLabor2);
  }
  onBackClick(x) {
    if (x == 1) {
      this.laborFirstShow = true;
      this.laborSecondShow = false;
    }
    if (x == 2) {
      this.laborSecondShow = true;
      this.laborThirdShow = false;
    }
    if (x == 3) {
      this.laborSecondCategoryShow = false;
      this.laborFirstShow = true;
    }
    if (x == 4) {
      this.laborSecondCategoryShow = true;
      this.laborThirdCategoryShow = false;
    }
  }
}
