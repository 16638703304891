import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { IBindReportFilter, iSalesDayPartReport } from 'src/app/interfaces/i-report';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import { iTaxHostoryReport } from 'src/app/interfaces/iadmin';


const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-tax-history-filter',
  templateUrl: './tax-history-filter.component.html',
  styleUrls: ['./tax-history-filter.component.css'],

  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class TaxHistoryFilterComponent implements OnInit {

  taxfilterForm: FormGroup;
  @Input() auditShow;
  loginInfo: any;
  ItemTags: any = [];
  EmployeeList: any = [];
  SalesArea: any = [];
  disableInput: boolean = false;
  disableInput1: boolean = false;

  @Output() public ReceivedData = new EventEmitter();

  employeeselectedItems = [];
  approverSelectedItems = [];
  salestaxSettings: IDropdownSettings = {};

  employeFilters = [];
  employeAreaObj: FormControl = new FormControl('');

  AreasFilters = [];
  AreasAreaObj: FormControl = new FormControl('');

  HierarchyFilters = [];
  HierarchyObj: FormControl = new FormControl('');

  employeeArr: any;

  constructor(private formBuilder: FormBuilder, private helper: HelperService,private reportService: ReportWebSocketService) { }

  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.taxfilterForm = this.formBuilder.group({
      fromDate: new FormControl(new Date()),
      toDate: new FormControl(new Date()),
    });
    this.salestaxSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 1,
      selectAllText: 'Select All',
      unSelectAllText: 'Select All'
    };
    this.bindFilterFields();
  }

  setForms() {
    this.taxfilterForm = this.formBuilder.group({
      fromDate: new FormControl(new Date()),
      toDate: new FormControl(new Date()),
      employeFilters: [null],
      employeselectedItems: [null],
      employeAreaObj:  new FormControl(this.employeFilters),
      salestaxSettings: [null],
      AreasFilters: [null],
      AreasAreaObj: new FormControl(this.AreasFilters),
    })
  }

  bindFilterFields() {
    const itemFilter: IBindReportFilter = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.reportService.BindFiltersFields(itemFilter).subscribe((response) => {
      this.EmployeeList = response.Employee;
      this.ItemTags = response.TagItems;
      this.SalesArea = response.SalesArea;

      //----------------BINDING MULTI SELECT----------------------

      var itemSales
      var jsonToBeUsedSales = [];
      for (var salestype in this.SalesArea) {
        itemSales = {};
        itemSales.key = this.SalesArea[salestype].id.toString();
        itemSales.value = this.SalesArea[salestype].name;
        jsonToBeUsedSales.push(itemSales);
      }
      this.AreasFilters = jsonToBeUsedSales;


      var itemItmTag
      var jsonToBeUsedItmTag = [];
      for (var itmtagtype in this.ItemTags) {
        itemItmTag = {};
        itemItmTag.key = this.ItemTags[itmtagtype].id.toString();
        itemItmTag.value = this.ItemTags[itmtagtype].name;
        jsonToBeUsedItmTag.push(itemItmTag);
      }

      var itemEmp
      var jsonToBeUsedEmp = [];
      for (var emptype in this.EmployeeList) {
        itemEmp = {};
        itemEmp.key = this.EmployeeList[emptype].id.toString();
        itemEmp.value = this.EmployeeList[emptype].name;
        jsonToBeUsedEmp.push(itemEmp);
      }
      this.employeFilters = jsonToBeUsedEmp;
      this.setForms();
      this.onSubmit();
    })
  }

  get f() {
    return this.taxfilterForm.controls;
  }




  OnselectEmp(item: any) {
    if(item.value != ""){
      if(this.employeeselectedItems.length > 0){
        this.disableInput1 = true;
        this.approverSelectedItems = []
      }else{
        this.disableInput1 = false;
      }
    }
  }

  OnselectAllEmp(item: any){
    if(item.length > 0){
      this.disableInput1 = true;
      this.approverSelectedItems = []
    }else{
      this.disableInput = false;
      this.disableInput1 = false;
    }
  }

   onSubmit() {


    if (this.taxfilterForm.invalid) {
      return;
    }

    const itemFilter: iTaxHostoryReport = {
      fromDate: this.taxfilterForm.value.fromDate,
      toDate: this.taxfilterForm.value.toDate,
      tenantId: this.loginInfo.tenantId
    };

    this.ReceivedData.emit(itemFilter);
   }

  getTaxHistoryRepotTable() {
    const getdata: iTaxHostoryReport = {
      fromDate: this.taxfilterForm.value.fromDate,
      toDate: this.taxfilterForm.value.toDate,
      tenantId: this.loginInfo.tenantId
    };
    this.ReceivedData.emit(getdata);
    console.log("getFirstTable_ReceivedData", getdata);
  }

}
