export class MessageDto {
    public user: any = '';
    public msgText: any = '';
  }

  export interface chatMesage {
    text: string;
    connectionId: string;
    dateTime: Date;
  }


  export interface iFrontEndMsg {
    entityType: any;
    frameId: any;
    tenantId:any;
    timestamp:any;
    attributes: any;
    connectionId: any;
  }

  export interface iTimeClockSocket
  {
    employeeName?:any;
    roleName?:any;
    punchIn?:any;
    punchOut?:any;
    timeInTime?:any;
    timeOutTime?:any;
    typeName?:any;
    wage?:any;
    tips?:any;
    clockInImage?:any;
    
    
  }
  export interface iDeviceTablet
  {
    frameId:any;
    deviceImage:any;
    DeviceName:any;
    captureTimestamp:any;
    connectionId: any;
  }