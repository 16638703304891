import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { MenuGroupService } from 'src/app/services/menugroup.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { MenuPageGeneralComponent } from '../menu-page-general/menu-page-general.component';


@Component({
  selector: 'app-menu-page',
  templateUrl: './menu-page.component.html',
  styleUrls: ['./menu-page.component.css']
})
export class MenuPageComponent implements OnInit {
  public PageData: any;
  public salesAreabit: any;
  public PageEdit: any;
  isInvalid: boolean = false;
  @Input() clearItemForm: any;
  public generalDataemit: any;
  employee: any;
  @Output() public MenuNameEmit = new EventEmitter();
  @Output() public generalpageemit = new EventEmitter();
  @Output() public mnupagesessData = new EventEmitter;

  @ViewChild(MenuPageGeneralComponent) menuPagegenComp: MenuPageGeneralComponent;
  public getDataValue: any;
  @Input() public buttonState;
  submittedGeneral: any
  constructor(private formBuilder: FormBuilder, private helper: HelperService, private objgroup: MenuGroupService,
    private toaster: ToasterService) { }

  ngOnInit(): void {

  }

  getGeneralDetail(event) {
    this.generalDataemit = event;
    this.generalpageemit.emit({ res: event.res.id, name: event.name });
  }

  @Input()
  public set getdatapage(e) {
    this.PageEdit = e;
    this.isInvalid = false;
    //this.menuPagegenComp.requiredError = false;
    //this.menuPagegenComp.errorValue = ""
  }

  @Input()
  public set DatasalesArea(salesArea) {
    this.salesAreabit = salesArea;
  }

  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.addEmployee()
    }
  }

  public addEmployee(): void {
    this.menuPagegenComp.AreaBuildmnupageId = 0;
    this.menuPagegenComp.errorValue = ""
    this.menuPagegenComp.submittedGeneral = false;
    this.menuPagegenComp.MenuPageGeneralForm.reset();
    this.menuPagegenComp.MenuPageGeneralForm.patchValue({
      isActive: true,
    }); // should reset the form in child component
  }

  onMenuName() {
    this.employee = "Menu Page";
    this.MenuNameEmit.emit(this.employee)
    this.isInvalid = false;
    this.menuPagegenComp.requiredError = false;
  }

  OnSave() {
    this.menuPagegenComp.OnSaveMenuPage();
    if (this.menuPagegenComp.MenuPageGeneralForm.invalid) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }
  }

  OnFormReset() {
    this.menuPagegenComp.MenuPageGeneralForm.reset();
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getmnupagesessData(e) {
    this.mnupagesessData.emit(e)
  }

  DataBindonNavigate(mnuid) {
    this.menuPagegenComp.MenuPageGeneralForm.patchValue(mnuid);
  }

  DataBindonCancel() {
    this.menuPagegenComp.MenuPageGeneralForm.patchValue(this.helper.getValue('GetSessionData3'));
  }
}
