import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToasterPopupComponent } from '../elements/toaster-popup/toaster-popup.component';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  bsModalRef?: BsModalRef;

  constructor(public toastr: ToastrManager,private modalService: BsModalService) { }
  showToast(position: any = 'top-full-width') {
    this.toastr.infoToastr('This is a toast.', 'Toast', {
        position: position
    });
}

  success = (message?: string, title?: string) => {
    this.toastr.successToastr(message, title, {toastLife: 5000,position: 'top-center'});
  }

  error = (message?: string, title?: string) => {
    this.toastr.errorToastr(message, title,  {toastLife: 5000000,position: 'top-center',colour : 'purple'});
  }

  warning = (message?: string, title?: string) => {
    this.toastr.warningToastr(message, title,  {toastLife: 5000,position: 'top-center'});
  }

  info = (message?: string, title?: string) => {
    this.toastr.infoToastr(message, title,  {toastLife: 5000,position: 'top-center'});
  }
  
  clearAllToasts = () => {
    this.toastr.dismissAllToastr();
  }

  popUp(title?: string, message?: string) {
    const initialState = {
      title: title,
    };
    this.bsModalRef = this.modalService.show(ToasterPopupComponent, {initialState, class: 'popUpModal modal-md' , animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false} );
    // modal-dialog-centered
    this.bsModalRef.content.delay = 'Close';
}

hierarchyPopUp( message?: string) {
  const initialState = {
    message: message,
  };
  this.bsModalRef = this.modalService.show(ToasterPopupComponent, {initialState, class: 'popUpModal modal-md' , animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false} );
  // modal-dialog-centered
  this.bsModalRef.content.delay = 'Close';
}
successPopUp( message?: string) {
  const initialState = {
    message: message,
  };
  this.bsModalRef = this.modalService.show(ToasterPopupComponent, {initialState, class: 'popUpModal success  modal-md' , animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false} );
  // modal-dialog-centered
  this.bsModalRef.content.delay = 'Close';
}
errorPopUp(message?: string) {
  const initialState = {
    message: message,
  };
  
  this.bsModalRef = this.modalService.show(ToasterPopupComponent, {initialState, class: 'popUpModal errorpop  modal-md' , animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false} );
  // modal-dialog-centered
  this.bsModalRef.content.delay = 'Close';
}
}
