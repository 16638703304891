import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PermissionService {
  public EnableTrainiModePerm: any;
  public ActiveInactivePerm: any;
  public BuildMnuPerm: any;
  public CanRemoveTax: any;
  public WagesPermission: any;
  public isActiveInactive: boolean = false;
  public isBuildMenu: boolean = true;
  public isCanRemoveTax: boolean = true;
  public isWagesPermission: boolean = false;
  constructor() { }

  public InitialPermission(result) {
    if (result.tenantType == 4) {
      if (result.primaryRolePermissionListInfo != [] && result.primaryRolePermissionListInfo != undefined &&
        result.primaryRolePermissionListInfo != null && result.primaryRolePermissionListInfo != '' &&
        result.primaryRolePermissionListInfo.length > 0) {
        this.EnableTrainiModePerm = result.primaryRolePermissionListInfo.filter(
          (x) => x.id == 8
        );
        this.ActiveInactivePerm = result.primaryRolePermissionListInfo.filter(
          (x) => x.id == 5
        );
        this.BuildMnuPerm = result.primaryRolePermissionListInfo.filter(
          (x) => x.id == 4
        );
        this.CanRemoveTax = result.primaryRolePermissionListInfo.filter(
          (x) => x.id == 7
        );

        this.WagesPermission = result.primaryRolePermissionListInfo.filter(
          (x) => x.id == 52
        );

        if (this.EnableTrainiModePerm.length > 0 || this.ActiveInactivePerm.length == 0) {
          this.isActiveInactive = true;//Disable Active Inactive checkbox
        } else {
          this.isActiveInactive = false;//Enable Active Inactive checkbox
        }

        if (this.EnableTrainiModePerm.length > 0 || this.BuildMnuPerm.length == 0) {
          this.isBuildMenu = false; //Hide SubMenu Icons
        } else {
          this.isBuildMenu = true; //Display SubMenu Icons 
        }

        if (this.EnableTrainiModePerm.length > 0 || this.CanRemoveTax.length == 0) {
          this.isCanRemoveTax = false; //Hide Tax Master Icons
        } else {
          this.isCanRemoveTax = true; //Display Tax Master Icons 
        }

        if (this.EnableTrainiModePerm.length > 0 || this.WagesPermission.length == 0) {
          this.isWagesPermission = true;//Disable Wages Icons
        } else {
          this.isWagesPermission = false;//Enable Wages Icons 
        }
      }
    }
  }

  RefreshPermission() {
    this.isActiveInactive = false;
    this.isBuildMenu = true;
    this.isCanRemoveTax = true;
    this.isWagesPermission = false;
  }
}