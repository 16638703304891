import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
import { ICustomer } from '../discounts/discounts.component';
export interface PeriodicElement {
  id?: number;
  name?:any;
  quantity?:any;
  amount?:any;
  trasType?:any;
  check?:any;
  employee?:any;
  timeDate?:any;
  item?:any;
  move?:any;
  total?:any;
}
export interface Transaction {
  item: string;
  cost: number;
}
// const ELEMENT_DATA1: PeriodicElement[] = [
//   { id: 1, name: 'EMV1', quantity: 20, amount: 853.23},
//   { id: 2, name: 'EMV2', quantity: 15, amount: 465.36},
//   { id: 3, name: 'Swipes', quantity: 140, amount: 2564},
// ];

// const ELEMENT_DATA2: PeriodicElement[] = [
//   { id: 4, name: 'EMV1', trasType: 'MC', check: 'PAT65', employee: 'Garrett H', timeDate: '1/18/2021 09:23 AM'},
//   { id: 5, name: '', trasType: 'VISA', check: 'OLO432', employee: 'SAM J', timeDate: '1/18/2021 09:23 AM'},
//   { id: 6, name: '', trasType: 'AMEX', check: 'OLO400', employee: 'SAM J', timeDate: '1/18/2021 09:23 AM'},
// ];

// const ELEMENT_DATA3: PeriodicElement[] = [
//   { id: 4, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: 'Diet Pepsi', move: 'Added', total: '$3.50'},
//   { id: 5, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: '', move: 'Order Sent', total: ''},
//   { id: 6, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: 'Cheeseburger', move: 'Added', total: '$13.99'},
//   { id: 7, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: 'French Dip', move: 'Added', total: '$10.99'},
//   { id: 8, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: 'Mountain Dew', move: 'Added', total: '$3.50'},
//   { id: 9, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: '', move: 'Order Sent', total: ''},
// ];



@Component({
  selector: 'app-credit-batch',
  templateUrl: './credit-batch.component.html',
  styleUrls: ['./credit-batch.component.css']
})
export class CreditBatchComponent implements OnInit {
 
  loginInfo:any
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public backSalesItem = new EventEmitter
  public employeeName: any;
  public employee: any;
  public employeeIcon: any
  public Filtertransactions:any=[];
  ELEMENT_DATA1=[];
  ELEMENT_DATA2=[];
  ELEMENT_DATA3=[];
  transactions=[];
  CustomerDetails=[];
  closedData=[];
  public discountTotal:any;
  public subTotal:any;
  public netTotal:any;

  public CustomerList: ICustomer[] = [
    {firstName:'',lastName:'',city:'',email:'',address:'',phone:'',notes:'',state:'',zip:''}
  ]

  public displayedColumns1 = [];
  columns1 = [];
  public dataSource1: any
  public displayedColumns2 = [];
  columns2 = [];
  public dataSource2: any
  public displayedColumns3 = [];
  columns3 = [];
  public dataSource3: any

  public firstTableShow:boolean = false
  public secondTableShow:boolean = false
  public thirdTableShow:boolean = false
  public auditShow:boolean = false
  public backToSales:boolean = false
  signature: any;
  public auditName:any
  public auditDate:any
  public auditTime:any
  public auditEmployee:any
  firstTableName:any;
  public summartItems:any=[];

  public displayedColumns: string[] = ['item', 'cost'];
  TipsAmount: any;
  // public transactions: Transaction[] = [
  //   {item: 'Diet Pepsi', cost: 3.50},
  //   {item: 'Cheeseburger', cost: 13.99},
  //   {item: 'French Dip', cost: 10.99},
  //   {item: 'Mountain Dew', cost: 3.50},
  // ];

  constructor(private formBuilder: FormBuilder,private helper: HelperService,private reportService:ReportWebSocketService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.creditBatch");
    
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Credit/Batch Reports";
    this.employee = "Reports";
    this.employeeIcon = "assets/components/images/reports-icon.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    //this.getFirstTable()
  }
 
  GetCreditCardDetails(e?)
  {   
    this.reportService.SelectCreditCardReport(e).subscribe(res => {
     
     // this.ELEMENT_DATA1 = res.Summary;
       this.dataSource1 = res.Summary;
      this.ELEMENT_DATA2= res.DeviceDetails;
      this.ELEMENT_DATA3= res.MoveDetails;
      this.CustomerDetails= res.CustomerDetails;
      this.summartItems=res.BillDetails;
    //  this.signature= res.DeviceDetails.signature;
    //   console.log(this.signature);
    });

    this.firstTableShow = true;
    this.secondTableShow = false;
    this.thirdTableShow = false;
    this.displayedColumns1 = ['name','quantity', 'amount'];
  
    this.columns1 = [
      { columnDef: 'name', header: "Device Name" },
      { columnDef: 'quantity', header: "# of Transactions" },
      { columnDef: 'amount', header: "Total Amount" },
    ]
  
  }
  getFirstTable() {
    this.firstTableShow = true;
    this.secondTableShow = false;
    this.thirdTableShow = false;
    this.displayedColumns1 = ['name','quantity', 'amount'];
    this.dataSource1 =[];
    this.columns1 = [
      { columnDef: 'name', header: "Device Name" },
      { columnDef: 'quantity', header: "# of Transactions" },
      { columnDef: 'amount', header: "Total Amount" },
    ]
  }

  getSecondTable(e?) {
    this.firstTableShow = false;
    this.secondTableShow = true;
    this.thirdTableShow = false;
    this.displayedColumns2 = ['name','trasType', 'check','employee', 'timeDate'];
    //this.dataSource2 = this.ELEMENT_DATA2;

    if (e.sessionid == 1) {
      this.dataSource2 = this.ELEMENT_DATA2;
      this.dataSource2 = this.dataSource2.filter(x => x.name == e.name)

    }
    else {
    //  this.dataSource2 = this.dataSource2.filter(x => x.TicketId == e.name && x.employee == e.employee)

    }
    this.columns2 = [
      { columnDef: 'name', header: "Device Name" },
      { columnDef: 'trasType', header: "Transactions Type" },
      { columnDef: 'check', header: "Check #" },
      { columnDef: 'employee', header: "Employee" },
      { columnDef: 'timeDate', header: "Time/Date" },
    ]
  }

  getthirdTable(e?) {
    this.auditShow = true;
    this.displayedColumns3 = ['employee','timeDate', 'item','move', 'total'];
  //  this.dataSource3 = ELEMENT_DATA3;

  if (e.sessionid == 2) {
    this.dataSource3 = this.ELEMENT_DATA3;
    this.transactions=this.dataSource3;
    this.dataSource3 = this.dataSource3.filter(x => x.ticketid==e.TicketID && x.move!='closed').sort((a, b) => a.timeDate < b.timeDate ? -1 : a.timeDate > b.timeDate ? 1 : 0)
    this.closedData=this.ELEMENT_DATA3.filter(x => x.ticketid==e.TicketID && x.move=='closed')
    this.transactions=[ ...this.dataSource3, ...this.closedData];
    this.dataSource3=this.transactions;
    console.log( this.transactions," this.transactions")
  }
  else {
  //  this.dataSource2 = this.dataSource2.filter(x => x.TicketId == e.name && x.employee == e.employee)

  }
    this.columns3 = [
      { columnDef: 'employee', header: "Employee" },
      { columnDef: 'timeDate', header: "Time/Date" },
      { columnDef: 'item', header: "Item" },
      { columnDef: 'move', header: "Move" },
      { columnDef: 'total', header: "Total" },
      //{ columnDef: 'ticketid', header: "ticketid" },
    ]
  }

  onChangeRow(e){
    if(e.sessionid == 1){
      this.firstTableName = e.name
      this.firstTableShow = false;
      this.secondTableShow = true;
      this.getSecondTable(e)
    }else if(e.sessionid == 2){
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.thirdTableShow = true;
      this.auditName = e.check;
      this.auditEmployee = e.employee;

      let dateAssign= e.timeDate.substring(0, 8);
      let timeAssign= e.timeDate.substring(9, e.timeDate.length);

      this.auditDate= dateAssign;
      this.auditTime = timeAssign;

      if (this.CustomerDetails.filter(x=>x.ticketid==e.TicketID).length!=0)
      {
      this.CustomerDetails= this.CustomerDetails.filter(x=>x.ticketid==e.TicketID)
      }
      else

      {
      this.CustomerDetails = this.CustomerList;
      }
      
      this.getthirdTable(e);
      this.GetSummaryItems(e);

    }
  }

  GetSummaryItems(e?)
  {   
    this.transactions=this.summartItems.filter(x=>x.ticketid==e.TicketID)
    console.log(e,'this.transactions')
 
    this.discountTotal=this.summartItems.filter(x=>x.ticketid==e.TicketID).reduce((sum, current) => sum + current.discount, 0);
    this.subTotal=this.summartItems.filter(x=>x.ticketid==e.TicketID).reduce((sum, current) => sum + Number(current.cost), 0);
    this.TipsAmount=this.summartItems[0].TipsAmount;

    this.netTotal=Number(this.subTotal)+Number(this.TipsAmount)-Number(this.discountTotal);
    this.Filtertransactions=this.transactions.filter(y=>y.itemType=='Main');  
  }


  onBackClick(x){
    if(x == 1){
      this.backToSales = true
      // this.backSalesItem.emit(this.backToSales)
    }else 
    if(x == 2){
      this.firstTableShow = true;
      this.secondTableShow = false;
      this.thirdTableShow = false;
    }else if(x == 3){
      this.firstTableShow = false;
      this.thirdTableShow = false;
      this.secondTableShow = true;
      this.auditShow = false;
      
    }
  }



}
