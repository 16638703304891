<div class="credit_firstData">
    <form [formGroup]="FirstDataForm" class="loginForm">
        <div class="row  mb-2">
            <div class="col-sm-4">
                <div class="card  credit-header ">
                    <div class="card-body p-2">
                        <h6 class="card-text mb-0 ">FirstData</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Merchant ID</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="merchantId" appInputRestriction
                        placeholder="1-16 Digits" maxlength="16" (keypress)="numberOnly($event)"/>

                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Total Passward</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="totalPassword"
                        placeholder="6 digits" maxLength="6" (keypress)="numberOnly($event)" appInputRestriction (input)="getAuthCodeValue($event)"/>
                        <div class="error">{{errorValue}}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Terminal ID</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="terminalId"
                        placeholder="8 characters/Digits" maxlength="8" appInputRestriction (input)="getAuthCodeValue2($event)"/>
                        <div class="error">{{errorValue2}}</div>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Token ID Type</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="tokenIdType"
                        placeholder="6 characters/digits" maxlength="6" appInputRestriction (input)="getAuthCodeValue1($event)"/>
                        <div class="error">{{errorValue1}}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Group ID</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="groupId"
                        placeholder="5-13 characters/Digits" maxlength="13" appInputRestriction (input)="getAuthCodeValue3($event)"/>
                        <div class="error">{{errorValue3}}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">CatCode/SLC</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="catCode_SIC"
                        placeholder="0-4 characters/Digits" maxlength="4" appInputRestriction/>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="form-group">
                <div class="Communication_Form">
                    <!-- <div class="custom-control custom-radio custom-control item">
                        <input formControlName="autoAcquire_DID"  type="radio" id="AreaStyle_SMS" name="autoAcquire_DID"
                            class="custom-control-input">
                        <label class="custom-control-label QR" for="AreaStyle_SMS"></label>
                    </div> -->

                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="autoAcquire_DID"
                                formControlName="autoAcquire_DID" id="AreaStyle_SMS">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                    <p class="uber_label">Auto Acquire DID</p>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Auto Batch Time</label><label class="text-danger">*</label>
                    <ngx-mat-timepicker-field [controlOnly]="true" formControlName="autoBatchTime" class="ngxMatTime"
                        [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.autoBatchTime.errors }">
                        <!-- <div *ngIf="submittedGeneral && GeneralData.autoBatchTime.errors" class="invalid-feedback">
                            <div *ngIf="GeneralData.autoBatchTime.errors.required">timeIn is required</div>
                        </div> -->
                    </ngx-mat-timepicker-field>
                    <div *ngIf="submittedGeneral && GeneralData.autoBatchTime.errors" class="invalid-feedback">
                        <div *ngIf="GeneralData.autoBatchTime.errors.required">Auto Batch Time is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <label class="customlable mt-2">#Of</label>
                <div class="row">
                    <div class="col-sm-2 col">
                        <div class="form-group">
                            <label class="customlable mt-0">fixed</label>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input " name="of_Fixed"
                                        formControlName="of_Fixed" id="Allowsome">
                                    <label class="custom-control-label mb-0"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col">
                        <div class="form-group">
                            <label class="customlable mt-0">Mobile</label>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input " name="mobile"
                                        formControlName="mobile" id="Allowsome">
                                    <label class="custom-control-label mb-0"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mt-0">Total Lanes</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="total_Lanes" 
                            appInputRestriction/>
                                <!-- [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.total_Lanes.errors }" 
                            <div *ngIf="submittedGeneral && GeneralData.total_Lanes.errors" class="invalid-feedback">
                                <div *ngIf="GeneralData.total_Lanes.errors.required">Total Lanes is required</div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row ">
            <div class="col-sm-4">
                <label class="customlable mb-2">Store and Forward(EMV only)</label>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input " name="isStoreandForward_EMVonly"
                                        formControlName="isStoreandForward_EMVonly" id="Allowsome">
                                    <label class="custom-control-label mb-0"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="storeValue"
                                placeholder="$0-00" appInputRestriction/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="form-group">
                    <div class="Communication_Form">
                        <div class="custom-control custom-radio custom-control item">
                            <input formControlName="of_Fixed_Details" value="retail" type="radio" id="AreaStyle_retail" name="of_Fixed_Details"
                                class="custom-control-input">
                            <label class="custom-control-label QR" for="AreaStyle_retail"></label>
                        </div>
                        <p class="uber_label">Retail/Grocery</p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="Communication_Form">
                        <div class="custom-control custom-radio custom-control item">
                            <input formControlName="of_Fixed_Details" value="restaurant" type="radio" id="AreaStyle_restaurant" name="of_Fixed_Details"
                                class="custom-control-input">
                            <label class="custom-control-label QR" for="AreaStyle_restaurant"></label>
                        </div>
                        <p class="uber_label">Restaurant</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row ">
            <div class="col-sm-2">
                <div class="form-group">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="IsAllow_SameItem" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="retailnumber"
                        placeholder="$0-00" />
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <div class="Communication_Form">
                        <div class="custom-control custom-radio custom-control item">
                            <input formControlName="Radio" value="restaurant" type="radio" id="AreaStyle_restaurant" name="Radio"
                                class="custom-control-input">
                            <label class="custom-control-label QR" for="AreaStyle_restaurant"></label>
                        </div>
                        <p class="uber_label">Restaurant</p>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="form-group col-sm-12">
                <button class="btn btn-sm float-right submit_button button-none" type="submit"  (click)="onSaveGeneral()">
                        <!-- [disabled]="buttonState ? true : null" -->
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
                </button>
            </div>
        </div>
    </form>
</div>