import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GiftCardModalComponent } from 'src/app/elements/gift-card-modal/gift-card-modal.component';
import { EnvService } from 'src/app/env.service';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { iAWSFile, iGiftCardSelect } from 'src/app/interfaces/icustompayment';
import { DialogService } from 'src/app/services/dialog.service';
import { GiftcardService } from 'src/app/services/giftcard.service';
import { GiftCardFrontComponent } from '../gift-card-front/gift-card-front.component';

@Component({
  selector: 'app-gift-card-designer',
  templateUrl: './gift-card-designer.component.html',
  styleUrls: ['./gift-card-designer.component.css'],
})
export class GiftCardDesignerComponent implements OnInit {
  GiftCardDesignerForm: FormGroup;
  format: string;
  id: any = 0;
  ImageFile: File;
  url: string | ArrayBuffer;
  loginInfo: any;
  isHundread: boolean = false;
  isTwoFifty: boolean = false;
  isFiveHundread: boolean = false;
  isThousand: boolean = false;
  @ViewChild(GiftCardFrontComponent) GiftFrontcomp: GiftCardFrontComponent;
  clicked: boolean = false;
  whiteBg: boolean = false;
  private onDestroy$: Subject<void> = new Subject<void>();
  logoUrl: any;
  bsModalRef?: BsModalRef;
  @ViewChild('stepper') stepper: MatStepper;

  @Output() public giftcard_dessess_child = new EventEmitter();
  base64Data: any;
  dff: any;
  bName: any;
  addrss: any;
  city: any;
  state: any;
  zip: any;
  constructor(
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private helper: HelperService,
    private objGiftCard: GiftcardService,
    private dialogser: DialogService,
    private modalService: BsModalService,
    private env: EnvService
  ) { }

  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.GiftCardDesignerForm = this.formBuilder.group({
      id: [0],
      logoUpload: [''],
      logoText: [''],
      logoSmallText: [''],
      chooseBackground: [''],
      cardCustomText: [''],
      cardNumbers: [''],
      cardText: [''],
      hundred: false,
      twoFifty: false,
      fiveHundred: false,
      thousand: false,
    });
  }

  onImgUpload(event:any) {
    console.log(event);
    this.ImageFile =  event.target.files && event.target.files[0];
    if (event.target.files[0]) {
      const file = event.target.files[0];
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        this.base64Data = reader.result.split(',').pop();
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const width = rs.currentTarget['width'];
          const height = rs.currentTarget['height'];
          const dimensions = {
            width,
            height,
          };
          if (dimensions.height <= '228' && dimensions.width <= '348') {
            console.log(true);
            this.GiftFrontcomp.onSelectFile(event);
          } else {
            console.log(false);
            event.target.value = null;
            this.openModal();
            this.GiftFrontcomp.onSelectFile('');
          }
        };
      };
    }

  }
  dltimage() {
    // this.img = false
    // this.imgUrl = 'assets/components/images/180_180.png'
  }
  openModal() {
    this.bsModalRef = this.modalService.show(GiftCardModalComponent, {
      class: 'modalComp modal-popUp modal-l',
      backdrop: 'static',
      keyboard: true,
    });
    this.bsModalRef.content.messageEvent.subscribe((data) => {
      this.dff = data;
      if (this.dff == true) {
        this.stepper.selectedIndex = 1;
      }
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  // FileNameUrl(e){
  //   this.GiftCardDesignerForm.value.logoUpload = 'https://flippos.s3.us-west-1.amazonaws.com/StoreLogo/' + e;
  // }

  customOnClick(event) {
    this.clicked = true;
    this.isHundread = false;
    this.isTwoFifty = false;
    this.isFiveHundread = false;
    this.isThousand = false;
  }

  changeBg(event) {
    if (event.target != undefined) {
      if (event.target.value == 'whiteBg') {
        this.whiteBg = true;
      } else {
        this.whiteBg = false;
      }
    }
  }

  cardsCount(event) {
    if (
      event.target.id == 100 ||
      event.target.id == 250 ||
      event.target.id == 500 ||
      event.target.id == 1000
    ) {
      this.clicked = false;
      this.isHundread = event.target.id == 100 ? true : false;
      this.isTwoFifty = event.target.id == 250 ? true : false;
      this.isFiveHundread = event.target.id == 500 ? true : false;
      this.isThousand = event.target.id == 1000 ? true : false;
    }

    if (event.target.id == 100) {
      this.isHundread = true;
      this.isTwoFifty = false;
      this.isFiveHundread = false;
      this.isThousand = false;
    }

    if (event.target.id == 250) {
      this.isHundread = false;
      this.isTwoFifty = true;
      this.isFiveHundread = false;
      this.isThousand = false;
    }

    if (event.target.id == 500) {
      this.isHundread = false;
      this.isTwoFifty = false;
      this.isFiveHundread = true;
      this.isThousand = false;
    }

    if (event.target.id == 1000) {
      this.isHundread = false;
      this.isTwoFifty = false;
      this.isFiveHundread = false;
      this.isThousand = true;
    }
  }

  async onSaveGiftDesign(res) {
    let giftdata;
    giftdata = this.GiftCardDesignerForm.value;
    giftdata.id = this.id;
    giftdata.giftCardGeneralID = res.id;
    giftdata.createdBy = this.loginInfo.userId;
    giftdata.tenantId = this.loginInfo.tenantId;
    giftdata.hundred = this.isHundread;
    giftdata.twoFifty = this.isTwoFifty;
    giftdata.fiveHundred = this.isFiveHundread;
    giftdata.thousand = this.isThousand;
    giftdata.logoUpload =
      this.ImageFile == undefined ? '' : this.ImageFile.name;

    if (this.ImageFile != undefined) {
      const formData = new FormData();
      formData.append('image', this.ImageFile, this.ImageFile.name);
      formData.append('folderName', 'GiftCardDesign');

      this.objGiftCard.LogoAWSUpload(formData).subscribe((imgres) => {
        giftdata.logoUpload = imgres['body'].message;
        console.log(imgres, 'AWS S3 GiftCardDesign');
      }),
        (err) => console.error('Error', err),
        () => console.log('HTTP request completed.');
    }

    this.objGiftCard
      .SaveGiftCardDesign(giftdata)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (res) => {
        this.helper.setValue('GetSessionData2', giftdata);
        this.toaster.successPopUp(res.message);
      }),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
  }

  //  Realese the memory
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  @Input()
  public set bNameDesign(bName) {
    this.bName = bName;
  }

  @Input()
  public set addressDesign(address) {
    this.addrss = address;
  }

  @Input()
  public set cityDesign(cityName) {
    this.city = cityName;
  }

  @Input()
  public set stateDesign(stateName) {
    this.state = stateName;
  }

  @Input()
  public set zipDesign(zipCd) {
    this.zip = zipCd;
  }

  @Input()
  public set GetDesign(tableRowData) {
    if (tableRowData != undefined && tableRowData != null && tableRowData != '') {
      if (tableRowData[0] != undefined && tableRowData[0] != null && tableRowData[0] != '') {
        console.log(tableRowData[0], 'Design UI');
         this.GiftCardDesignerForm.patchValue({ logoUpload: tableRowData[0].logoUpload});
        this.GiftCardDesignerForm.patchValue(tableRowData[0]);        // this.id = tableRowData[0].id;

        this.logoUrl =
          tableRowData[0].filename == null
            ? ''
            : tableRowData[0].filename == ''
              ? ''
              : this.env.awsS3_environment + 'GiftCardDesign/' + tableRowData[0].filename;

        this.GiftFrontcomp.displayFile(this.logoUrl);
        if (this.GiftCardDesignerForm.value.chooseBackground == 'whiteBg') {
          this.whiteBg = true;
        } else if (
          this.GiftCardDesignerForm.value.chooseBackground == '' ||
          this.GiftCardDesignerForm.value.chooseBackground == null
        ) {
          this.whiteBg = false;
        } else {
          this.whiteBg = false;
        }
        // this.getGneralDetail(tableRowData)

        if (tableRowData[0].hundred == true) {
          this.isHundread = true;
          this.isTwoFifty = false;
          this.isFiveHundread = false;
          this.clicked = false;
          this.isThousand = false;
        } else if (tableRowData[0].twoFifty == true) {
          this.isHundread = false;
          this.isTwoFifty = true;
          this.isFiveHundread = false;
          this.clicked = false;
          this.isThousand = false;
        } else if (tableRowData[0].fiveHundred == true) {
          this.isHundread = false;
          this.isTwoFifty = false;
          this.isFiveHundread = true;
          this.clicked = false;
          this.isThousand = false;
        } else if (tableRowData[0].thousand == true) {
          this.isHundread = false;
          this.isTwoFifty = false;
          this.isFiveHundread = false;
          this.clicked = false;
          this.isThousand = true;
        } else if (
          tableRowData[0].cardNumbers != '' &&
          tableRowData[0].cardNumbers != null
        ) {
          this.clicked = true;
          this.isHundread = false;
          this.isTwoFifty = false;
          this.isFiveHundread = false;
          this.isThousand = false;
        } else if (
          tableRowData[0].cardNumbers == '' ||
          tableRowData[0].cardNumbers == null
        ) {
          this.clicked = false;
          this.isHundread = false;
          this.isTwoFifty = false;
          this.isFiveHundread = false;
          this.isThousand = false;
        } else {
          this.isHundread = false;
          this.isTwoFifty = false;
          this.isFiveHundread = false;
          this.isThousand = false;
          this.clicked = false;
        }
      }
      else {
        this.whiteBg = false;
        this.logoUrl = '';
        this.isHundread = false;
        this.isTwoFifty = false;
        this.isFiveHundread = false;
        this.isThousand = false;
        //this.GiftCardDesignerForm.patchValue({ chooseBackground: 'blackBg' });
      }
    } else {
      this.whiteBg = false;
      this.logoUrl = '';
      this.isHundread = false;
      this.isTwoFifty = false;
      this.isFiveHundread = false;
      this.isThousand = false;
      //this.GiftCardDesignerForm.patchValue({ chooseBackground: 'blackBg' });

      //this.GiftFrontcomp.displayFile(this.logoUrl);
      //this.GiftCardDesignerForm.patchValue(tableRowData[0]);
    }
  }

  deleteimage() {
    this.ImageFile = null;
    // this.img = false
    this.GiftCardDesignerForm.value.logoUpload = '';
    this.GiftCardDesignerForm.patchValue({ logoUpload: ''})
    this.logoUrl = '';
    this.GiftFrontcomp.displayFile(this.logoUrl);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  clearURL() {
    this.logoUrl = '';
    this.GiftFrontcomp.displayFile(this.logoUrl);
  }

  getGeneralDesignDetail(Giftid) {
    //  this.getDataValue=Giftid;
    const ItemTypeList: iGiftCardSelect = {
      id: Giftid,
      tenantId: this.loginInfo.tenantId,
    };
    this.objGiftCard
      .EditGiftCard(ItemTypeList)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        console.log(res, 'ress');
        if (res != undefined && res != null && res != '') {
          this.GiftCardDesignerForm.patchValue(res.design[0]);
          if (res.design[0].filename != '' || res.design[0].filename != null) {
            this.logoUrl =
              'https://flippos.s3.us-west-1.amazonaws.com/StoreLogo/' +
              res.design[0].filename;
            this.GiftFrontcomp.displayFile(this.logoUrl);
          } else {
            this.logoUrl = '';
            this.GiftFrontcomp.displayFile(this.logoUrl);
          }

          if (this.GiftCardDesignerForm.value.chooseBackground == 'whiteBg') {
            this.whiteBg = true;
          } else if (
            this.GiftCardDesignerForm.value.chooseBackground == '' ||
            this.GiftCardDesignerForm.value.chooseBackground == null
          ) {
            this.whiteBg = false;
          } else {
            this.whiteBg = false;
          }

          if (this.GiftCardDesignerForm.value.hundred == true) {
            this.isHundread = true;
            this.isTwoFifty = false;
            this.isFiveHundread = false;
            this.clicked = false;
            this.isThousand = false;
          } else if (this.GiftCardDesignerForm.value.twoFifty == true) {
            this.isHundread = false;
            this.isTwoFifty = true;
            this.isFiveHundread = false;
            this.clicked = false;
            this.isThousand = false;
          } else if (this.GiftCardDesignerForm.value.fiveHundred == true) {
            this.isHundread = false;
            this.isTwoFifty = false;
            this.isFiveHundread = true;
            this.clicked = false;
            this.isThousand = false;
          } else if (this.GiftCardDesignerForm.value.thousand == true) {
            this.isHundread = false;
            this.isTwoFifty = false;
            this.isFiveHundread = false;
            this.clicked = false;
            this.isThousand = true;
          } else if (
            this.GiftCardDesignerForm.value.cardNumbers != '' &&
            this.GiftCardDesignerForm.value.cardNumbers != null
          ) {
            this.clicked = true;
            this.isHundread = false;
            this.isTwoFifty = false;
            this.isFiveHundread = false;
            this.isThousand = false;
          } else if (
            this.GiftCardDesignerForm.value.cardNumbers == '' ||
            this.GiftCardDesignerForm.value.cardNumbers == null
          ) {
            this.clicked = false;
            this.isHundread = false;
            this.isTwoFifty = false;
            this.isFiveHundread = false;
            this.isThousand = false;
          } else {
            this.isHundread = false;
            this.isTwoFifty = false;
            this.isFiveHundread = false;
            this.isThousand = false;
            this.clicked = false;
          }
        }
      }),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
  }

  ngAfterViewInit() {
    this.GiftCardDesignerForm.patchValue(
      this.helper.getValue('GetSessionData2')
    );
    this.giftcard_dessess_child.emit(this.GiftCardDesignerForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData2', data);
    this.helper.setValue('OnDirty2', true);
    this.giftcard_dessess_child.emit({
      data: data,
      event: 'GiftCard',
      val: true,
    });
  }
}
