<div class="custom_Uber_eats">
  <form
    class="customPayment loginForm"
    [formGroup]="CustomPaymentForm"
    (change)="onchange(CustomPaymentForm.value)"
  >
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="customlable mb-0">Name</label
          ><label class="text-danger">*</label>
          <input
            type="text"
            class="form-control input-lg inputbox_login"
            formControlName="name"
            appInputRestriction
            placeholder="Name"
            [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.name.errors) || requiredErrors
            }"
            maxlength="50"
            (input)="getMaxValue($event)"
            (keypress)="Highlight()"
            (focusout)="CustomNameValid()"
          />
          <div class="error">{{ errorValue }}</div>
          <div
            *ngIf="
              (submittedGeneral && GeneralData.name.errors) || requiredErrors
            "
            class="invalid-feedback"
          >
            <div *ngIf="requiredErrors || GeneralData.name.errors.required">
              Name is required
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-item">
      <div class="row">
        <div class="form-group col-sm-12">
          <button
            class="btn btn-sm float-right submit_button button-none"
            type="submit"
            (click)="onSaveGeneral()"
            [disabled]="buttonDisable ? true : null"
          >
            <img
              src="assets/components/images/save.png"
              alt="employee"
              class=""
            />
            <label class="label_config">Save</label>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-1 col-md-1 col-lg-1">
          <div class="checkbox-table accordion-checkbox">
            <div class="custom-control custom-checkbox custom-control item">
              <input
                type="checkbox"
                class="custom-control-input"
                name="customCheckbox"
                formControlName="PLU_CardNumber_Code"
                id="Allowsome"
              />
              <label class="custom-control-label mb-0"></label>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-11 col-md-11 col-lg-11 pl-md-0">
          <label class="uber_label">PLU Card Number/Code</label>
          <p class="uber_para">
            This allows this tender type to be charged by swiping or manually
            entering the associated card number or code from the 'pay' screen in
            the POS
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-1 col-md-1 col-lg-1">
          <div class="checkbox-table accordion-checkbox">
            <div class="custom-control custom-checkbox custom-control item">
              <input
                type="checkbox"
                class="custom-control-input"
                name="customCheckbox"
                formControlName="isCurrency"
                id="isCurrency"
              />
              <label class="custom-control-label mb-0"></label>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-11 col-md-11 col-lg-11 pl-md-0">
          <label class="uber_label">Is Currency</label>
          <!-- <p class="uber_para">This allows this tender type to be charged by swiping or manually entering
                        the
                        associated card number or code from the 'pay' screen in the POS </p> -->
        </div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-1 col-md-1 col-lg-1">
          <div class="checkbox-table accordion-checkbox">
            <div class="custom-control custom-checkbox custom-control item">
              <input
                type="checkbox"
                class="custom-control-input"
                name="customCheckbox"
                formControlName="RollUpPayment"
                id="Allowsome"
              />
              <label class="custom-control-label mb-0"></label>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-11 col-md-11 col-lg-11 pl-md-0">
          <label class="uber_label">Roll up payment</label>
          <p class="uber_para">
            If enabled, this payment type will be consolidated on the cash out
            reports
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-1 col-md-1 col-lg-1">
          <div class="checkbox-table accordion-checkbox">
            <div class="custom-control custom-checkbox custom-control item">
              <input
                type="checkbox"
                class="custom-control-input"
                name="customCheckbox"
                formControlName="OverrideTipApportions"
                id="Allowsome"
              />
              <label class="custom-control-label mb-0"></label>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-11 col-md-11 col-lg-11 pl-md-0">
          <label class="uber_label">Override Tip apportions</label>
          <p class="uber_para">
            Override for percentage of tips to be paid on servers paycheck by
            default the location Payout Apportions are used
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-1 col-md-1 col-lg-1">
          <div class="checkbox-table accordion-checkbox">
            <div class="custom-control custom-checkbox custom-control item">
              <input
                type="checkbox"
                class="custom-control-input"
                name="customCheckbox"
                formControlName="RequireSignature"
                id="Allowsome"
              />
              <label class="custom-control-label mb-0"></label>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-11 col-md-11 col-lg-11 pl-md-0">
          <label class="uber_label">Require Signature</label>
        </div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-1 col-md-1 col-lg-1">
          <div class="checkbox-table accordion-checkbox">
            <div class="custom-control custom-checkbox custom-control item">
              <input
                type="checkbox"
                class="custom-control-input"
                name="customCheckbox"
                formControlName="AllowTipping"
                id="Allowsome"
              />
              <label class="custom-control-label mb-0"></label>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-11 col-md-11 col-lg-11 pl-md-0">
          <label class="uber_label">Allow Tipping</label>
        </div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-1 col-md-1 col-lg-1">
          <div class="checkbox-table accordion-checkbox">
            <div class="custom-control custom-checkbox custom-control item">
              <input
                type="checkbox"
                class="custom-control-input"
                name="customCheckbox"
                formControlName="OpenCashDrawer"
                id="Allowsome"
              />
              <label class="custom-control-label mb-0"></label>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-11 col-md-11 col-lg-11 pl-md-0">
          <label class="uber_label">Open Cash Drawer</label>
          <p class="uber_para">
            Upon accepting payment, the cash drawer will open
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-1 col-md-1 col-lg-1">
          <div class="checkbox-table accordion-checkbox">
            <div class="custom-control custom-checkbox custom-control item">
              <input
                type="checkbox"
                class="custom-control-input"
                name="customCheckbox"
                formControlName="RequireMarketingCode"
                id="Allowsome"
              />
              <label class="custom-control-label mb-0"></label>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-11 col-md-11 col-lg-11 pl-md-0">
          <label class="uber_label">Require Marketing Code</label>
          <p class="uber_para">
            Require Code to be appiled to use the payment type
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-2 col-sm-1 col-md-1 col-lg-1">
          <div class="checkbox-table accordion-checkbox">
            <div class="custom-control custom-checkbox custom-control item">
              <input
                type="checkbox"
                class="custom-control-input"
                name="customCheckbox"
                formControlName="HotelRoomPayment"
                id="Allowsome"
              />
              <label class="custom-control-label mb-0"></label>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-11 col-md-11 col-lg-11 pl-md-0">
          <label class="uber_label">Hotel room Payment</label>
          <p class="uber_para">
            Allow the payment to charge directly to a hotel
          </p>
        </div>
      </div>
      <div class="row" *ngIf="CustomPaymentForm.get('HotelRoomPayment').value">
        <div class="col-sm-4">
          <div class="form-group">
            <label class="customlable mb-0">PMS Server IP</label
            ><label class="text-danger req_label">*</label>
            <input
              type="text"
              class="form-control input-lg inputbox_login"
              formControlName="PMS_ServerIP"
              [inputMask]="ipAddressMask"
              [formControl]="PMS_ServerIP"
              placeholder="IP Address"
              (keypress)="onKeypressIpEvent($event)"
              (input)="IPValid($event.target.value)"
              [ngClass]="{ 'is-invalid': GeneralData.PMS_ServerIP.errors  || (submittedGeneral==true && GeneralData.PMS_ServerIP.errors)}"
              appInputRestriction
              (input)="CustomNameValidIp()"
            />
            <div class="error">{{mesage}}</div>
            <!-- <div class="invalid-feedback">{{ mgs }}</div> -->
            <div *ngIf="(GeneralData.PMS_ServerIP.errors) || (submittedGeneral==true && GeneralData.PMS_ServerIP.errors)" class="invalid-feedback">
              <div class="error" *ngIf="GeneralData.PMS_ServerIP.errors.pattern ">
                Please Enter Valid Ip Address
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
