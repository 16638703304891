<div class="row">
  <div class="col-sm-6">
    <app-fliptable-layout
      [dataSource]="dataSource"
      [displayedColumns]="displayedColumns"
      [columns]="columns"
      [DatatableRender]="table_render"
      [SalesID]="tagsID"
      (tableValueItem)="getTableValue($event)"
    >
    </app-fliptable-layout>
  </div>
  <div class="col-sm-6">
    <label class="item_assigned_label">Jobs Ignored</label>
    <div class="itemAssignedmodify menu_wrapper item_main">
      <div
        cdkDropList
        cdkDropListGroup
        id="jobs"
        #todo="cdkDropList"
        [cdkDropListData]="JobList"
        [cdkDropListConnectedTo]="tagsID"
        (cdkDropListDropped)="drop($event)"
        cdkDragBoundary=".tablet"
        class="example-list"
      >
        <div
          class="item_assigned_box"
          *ngFor="let item of JobList; let i = index"
          cdkDrag
          cdkDragDropZone
        >
          <div [ngClass]="{ emp_name: item.id || DragId == true }">
            <div class="outshadow">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
