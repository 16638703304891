import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { AdminService } from 'src/app/services/admin.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
import { IRole } from 'src/app/interfaces/iadmin';

@Component({
  selector: 'app-orchestration-sync',
  templateUrl: './orchestration-sync.component.html',
  styleUrls: ['./orchestration-sync.component.css']
})
export class OrchestrationSyncComponent implements OnInit {
  orchsyncForm: FormGroup;
  buttonDisable: boolean = false;
  submittedGeneral: any;
  loginInfo: any;
  errorValue: any;
  requiredError: boolean = false;
  id: any = 0;

  public employeeName: any;
  public employeeIcon: any;
  public employee: any;
  isInvalid: boolean = false;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public generalValueemit = new EventEmitter;
  @Output() public orchsyncsessData = new EventEmitter;

  constructor(private formBuilder: FormBuilder, private helper: HelperService,
    private reportService: ReportWebSocketService, private admserv: AdminService,
    private toaster: ToasterService, private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.OrchestrationSync");
    
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Orchestration Sync";
    this.employee = "Setting";
    this.employeeIcon = "assets/components/images/setting.png";
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.orchsyncForm = this.formBuilder.group({
      id: 0,
      timeIntervalforBackendSync: [null],
      timeIntervalforIPadSync: [null]
    })

    this.getOrchConfData();
  }

  async getOrchConfData() {
    const ItemTypeList: IRole = {
      id: 0,
      tenantId: this.loginInfo.tenantId                 // Only itemtype Ingredient true record is fetched
    }
    this.admserv.SelectOrchConf(ItemTypeList)
      .subscribe(res => {
        this.orchsyncForm.patchValue(res[0]);
        this.id = res[0].id;
      })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  btnActive(event) {
    this.buttonDisable = event;
    if (event == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !event;
    }
  }

  onSaveGeneral() {
    this.errorValue = ""
    this.submittedGeneral = true;
    if (this.orchsyncForm.invalid) {
      return;
    }

    let OrchConf;
    OrchConf = this.orchsyncForm.value;
    OrchConf.createdBy = this.loginInfo.userId;
    OrchConf.tenantId = this.loginInfo.tenantId;
    OrchConf.id = this.id;
    this.admserv.SaveOrchConf(OrchConf).subscribe(async res => {
      if (res.id == 0) {
        this.toaster.errorPopUp(res.message);
        return;
      } else {
        this.id = res.id;
        this.toaster.successPopUp(res.message);
        await this.dialogser.ClearSession();
        this.helper.setValue('GetSessionData1', OrchConf);
        this.orchsyncsessData.emit({
          data: this.orchsyncForm.value,
          event: "OrchestrationSync", val: false
        });
      }
      this.submittedGeneral = false;
    });
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.orchsyncsessData.emit({ data: data, event: "OrchestrationSync", val: true });
  }
}