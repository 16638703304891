<form [formGroup]="MenuItemFormlogistic" class="logistics loginForm" (change)="onchange(MenuItemFormlogistic.value)">
  <div class="row logisticfirstrow">
    <div class="col-sm-7">
      <!-- Print Row -->
      <div class="row no-gutters">
        <div class="col-sm">
          <div class="row logisticslider no-gutters">
            <div class="col-sm-8 col-md-5 col-lg-6" style="padding-top: 10px">
              <label>Print Order</label>
              <mat-slider color="primary" #printOrder (change)="updateSetting($event)" step="5" min="0" max="100"
                [value]="printOrder" style="padding-left: 12px" formControlName="printOrder">
              </mat-slider>
            </div>
            <div class="col-sm-4 col-md-5 col-lg-6" style="padding-top: 28px">
              <b class="gridSizeValue col-4 form-control input-lg inputbox_login">{{ printOrder.value }}</b>
            </div>
          </div>
        </div>
      </div>

      <!-- cook time row -->
      <div class="row no-gutters">
        <div class="col-sm">
          <div class="row logisticslider no-gutters mb-2">
            <div class="col-sm-8 col-md-5 col-lg-6">
              <label>Cook Time</label>
              <mat-slider color="primary" #cookTime (change)="updateSetting($event)" step="5" min="0"
                formControlName="cookTime" max="100" [value]="cookTime"></mat-slider>
            </div>
            <div class="col-sm-4 col-md-5 col-lg-6" style="padding-top: 28px">
              <b class="gridSizeValue col-6 form-control input-lg inputbox_login">{{ cookTime.value }}Mins</b>
            </div>
          </div>
        </div>
      </div>

      <!-- Button options -->
      <div class="row menuitemlogisticrow">
        <div class="col-sm-4">
          <div class="checkbox-table accordion-checkbox">
            <label class="customlable mb-0">Custom Button</label>
            <div class="custom-control custom-checkbox custom-control item">
              <input type="checkbox" class="custom-control-input" name="customCheckbox" formControlName="customButton"
                id="customButton" />
              <label class="custom-control-label" for="customButton"></label>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <label style="text-align: center">Button Color</label>
          <color-sketch width="100%" [color]="menucolor" [(ngModel)]="button.PrimaryBgColor"
            [ngModelOptions]="{ standalone: true }" (onChange)="handleChange($event)">
          </color-sketch>
        </div>
        <div class="col-sm-4">
          <label>Button Text</label>
          <p [style.background]="picker.PrimaryBgColor" class="custom-button-text logisticcanvascolor"></p>
        </div>
      </div>
    </div>
    <!-- item image -->
    <div class="col-sm-5">
      <h6 class="custom-color item-card-style" style="text-align: center">Item Card Review</h6>
      <div class="row media">
        <div class="col-sm-12 mat-elevation-z8 outshadow">
          <div class="row mt-1">
            <div class="col-sm-12">
              <h5 class="logisticitemname">{{ employeeName }}</h5>
            </div>
          </div>
          <div class="menu-del-icon-div">
            <input type="file" accept=".png, .jpg, .jpeg" id="upload" hidden (change)="onSelectFile($event)" />
            <label class="img-label-logistics" for="upload">Upload</label>
            <div>
              <button (click)="deleteimage()" class="dlt-btn img-label-logistics">
                <i class="fa fa-trash-o dlt-i" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              <div class="logisticcanvas">
                <img class="img-fluid" [src]="imgUrl" *ngIf="format == 'image' && imgUrl"
                  style="max-width: 80px; max-height: 60px" />
              </div>
            </div>
            <div class="col-sm-7" style="text-align: left">
              <table class="table table-borderless"
                *ngIf="(menuPriceSizeList != undefined && menuPriceSizeList.length == 0)">
                <tbody>
                  <tr>
                    <td class="p-0">Item Price </td>
                    <td class="p-0">${{ itemCost}}</td>
                  </tr>
                  <tr>
                    <td class="p-0">Item Cost </td>
                    <td class="p-0">${{ itemPrice}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-borderless" *ngIf="(menuPriceSizeList != undefined)">
                <tbody *ngIf="menuPriceSizeList != []">
                  <tr *ngFor="let item of menuPriceSizeList">
                    <td class="p-0">{{ item.sizeName }}</td>
                    <td class="p-0">${{ item.price }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row ">
    <div class="col-sm-2">
      <div class="checkbox-table accordion-checkbox">
        <label class="customlable mb-0">Always Print Red</label>
        <div class="custom-control custom-checkbox custom-control item">
          <input type="checkbox" class="custom-control-input" name="customCheckbox" formControlName="alwaysPrintReal"
            id="alwaysPrintReal" />
          <label class="custom-control-label" for="alwaysPrintReal"></label>
        </div>
      </div>
    </div>
    <div class="col-sm-9 menuitemlogisticrow">
      <div class="row">
        <div class="col-sm-3">
          <div class="checkbox-table accordion-checkbox">
            <label class="customlable mb-0">Kitchen Display</label>
            <div class="custom-control custom-checkbox custom-control item">
              <input type="checkbox" class="custom-control-input" name="customCheckbox" formControlName="kitchenDisplay"
                id="kitchenDisplay" />
              <label class="custom-control-label" for="kitchenDisplay"></label>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="row">
            <label class="logisticslabel">Warning Time</label>
            <mat-slider color="primary" #WarningTime (change)="updateSetting($event)" step="5" min="1"
              formControlName="wamingTime" max="60" [value]="WarningTime"></mat-slider>
          </div>
          <div class="row">
            <b class="gridSizeValue col-6 form-control input-lg inputbox_login logisticsmins">{{ WarningTime.value
              }}Mins</b>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="row">
            <label class="logisticslabel">Late Time</label>
          </div>
          <div class="row">
            <mat-slider color="primary" #lateTime (change)="updateSetting($event)" step="5" min="1" max="60"
              formControlName="lateTime" [value]="lateTime"></mat-slider>
          </div>
          <div class="row">
            <b class="gridSizeValue col-6 form-control input-lg inputbox_login logisticsmins">{{ lateTime.value
              }}Mins</b>
          </div>
        </div>
        <div class="col-sm-3 text-center">
          <div class="colorp">
            <label>KDS color</label>
            <color-sketch width="200px" [color]="kdsclr" class="colorpick kds col-4" name="buttonColor"
              [(ngModel)]="pickerbox.PrimaryBgColor" [ngModelOptions]="{ standalone: true }"
              (onChange)="changecolor($event)">
            </color-sketch>
          </div>
          <p [style.background]="pickerbox.PrimaryBgColor" class="logisticcanvascolor"></p>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group mt-2">
    <button class="btn btn-sm float-right submit_button button-none" type="submit"
      [disabled]="buttonDisable ? true : null">
      <!-- [disabled]="buttonState ? true : null" -->
      <img src="assets/components/images/save.png" alt="employee" class="" />
      <label class="label_config">Save</label>
    </button>
  </div>
</form>