import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { ISelectPayrollDetails } from 'src/app/interfaces/iemployee';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-payroll-general',
  templateUrl: './payroll-general.component.html',
  styleUrls: ['./payroll-general.component.css']
})
export class PayrollGeneralComponent implements OnInit {
  payrollGeneralForm: FormGroup;
  submittedGeneral = false;
  id: any = 0;
  loginInfo: any;
  isActive: any;
  @Output() public isInvalid = new EventEmitter();
  @Input() public buttonState;
  @Output() public payroll_general = new EventEmitter();
  @Output() public payroll_child = new EventEmitter();
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private empService: EmployeeService,
    private toaster: ToasterService, private dialogser: DialogService) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.payrollGeneralForm = this.formBuilder.group({
      id: [0],
      startDay: [null, Validators.required],
      daillyHoursBefore_OT: [null],
      weeklyHoursBefore_OT: [null],
      isActive: [false],
      createdBy: [0],
      modifiedBy: [0]
    })
    this.PatchPayrollDetails();
  }
  get GeneralData() { return this.payrollGeneralForm.controls; }
  // @Input()
  // public set DataIdx(tableRowId) {
  //   if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
  //     this.id = tableRowId;
  //     this.PatchPayrollDetails(tableRowId);
  //   }
  // }
  PatchPayrollDetails() {
    const tagList: ISelectPayrollDetails = {
      tenantId: this.loginInfo.tenantId
    }
    this.empService.SelectPayrollDetails(tagList)
      .subscribe(res => {
        // this.id = res[0].id == null ? 0 : res[0].id;
        // let emvVal = res[0];
        this.payrollGeneralForm.patchValue(res[0]);
      })
  }

  async onSaveGeneral() {
    this.submittedGeneral = true;
    if (this.payrollGeneralForm.invalid) {
      return;
    }
    this.payrollGeneralForm.value.isActive = true;
    // this.payrollGeneralForm.value.id = this.id;
    this.payrollGeneralForm.value.tenantId = this.loginInfo.tenantId;
    this.payrollGeneralForm.value.createdBy = this.loginInfo.userId;
    this.payrollGeneralForm.value.modifiedBy = this.loginInfo.userId;
    this.empService.SavePayroll(this.payrollGeneralForm.value).subscribe(
      async (response) => {
        // this.payrollGeneralForm.reset();
        this.submittedGeneral = false;
        this.id = response.id;
        // this.payroll_general.emit(response.id);
        if (response.id == 0) {
          this.toaster.info(response.message);
        } else {
          this.toaster.successPopUp(response.message);
          await this.dialogser.ClearSession();
          this.helper.setValue('GetSessionData1', this.payrollGeneralForm.value);
          this.payroll_child.emit({ data: this.payrollGeneralForm.value, event: "payroll", val: false });

        }
      })
  }

  checkInput(e, input) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];
    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue) && e.keyCode != 9) {
      e.preventDefault();
      return;
    }

    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if ((+newValue > 24 || newValue.length > 2) && e.keyCode != 9) {
      e.preventDefault();
    }
  }

  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }

  checkInputs(e, input1) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];
    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue) && e.keyCode != 9) {
      e.preventDefault();
      return;
    }

    const hasSelection = input1.selectionStart !== input1.selectionEnd && input1.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelections(input1, e.key)
    } else {
      newValue = input1.value + keyValue.toString();
    }

    if ((+newValue > 168 || newValue.length > 3) && e.keyCode != 9) {
      e.preventDefault();
    }
  }
  private replaceSelections(input1, key) {
    const inputValue = input1.value;
    const start = input1.selectionStart;
    const end = input1.selectionEnd || input1.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }

  checkVal(event) {
    this.onchange(this.payrollGeneralForm.value);

    if (event != null || event != "") {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.payrollGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.payroll_child.emit(this.payrollGeneralForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.payroll_child.emit({ data: data, event: "payroll", val: true });
  }
}
