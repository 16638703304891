export interface IBindReportFilter {
	id: any;
	tenantId: any;

}

export interface ISalesReport {
	employee: any;
	jobPosition: any;
	salesArea: any;
	menuGroup: any;
	reportCategory: any;
	fromDate: any;
	toDate: any;
	tenantId: any;
}
export class Group {
	level = 0;
	parent: Group;
	expanded = true;
	totalCounts = 0;
	get visible(): boolean {
		return !this.parent || (this.parent.visible && this.parent.expanded);
	}
}

export class itableReport {
	quantity: any;
	category: any;
	groupHeader: any;
	grossSales: any;
	netSales: any;
	categorySale: any;
	time: any;
	reason: any;
	terminal: any;
	name: any;
	dateTime: any;
	employeeName: any;
	check: any;
	station: any;
	approvedBy: any;
	amount: any;
	byGross: any;
}

export class iSalesDayPartReport {
	employee: any;
	salesArea: any;
	reportCategory: any;
	fromDate: any;
	toDate: any;
	tenantId: any;
}

export class iLoborCostReport {
	employee: any;
	fromDate: any;
	toDate: any;
	tenantId: any;
}

export class iDiscountReport {
	employee: any;
	salesArea: any;
	approver: any;
	fromDate: any;
	toDate: any;
	tenantId: any;
}
export class iSalesReconsilation {
	fromDate: any;
	toDate: any;
	tenantId: any;
}

export class iSalesReconsilationDetails {
	category: any;
	reconcileParameter: any;
	reconcileAmount: any;
	reconcileDate: any;
	tenantId: any;
	createdby: any
}

export class iSalesReconsilationAmount {
	expostedDeposit: any;
	actualDeposit: any;
	overShort: any;
	reconcileDate: any;
	tenantId: any;
	createdby: any
}
export class iSelectVoidReport {
	employee: any;
	salesArea: any;
	reportCategory: any;
	fromDate: any;
	toDate: any;
	tenantId: any;
}