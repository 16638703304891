import { Injectable } from '@angular/core';
import { HelperService } from '../helper/helper.service';
import { BaseService } from '../base/base.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { GetDevice, SavePrinterDevice, SaveTabletDevice,
  DeleteDevice, iEMVDeviceDetailsSave, iEMVDevicSelect, ActivateEMVDevice,SaveReceiptConfigDevice, iEMVDeviceDetailsRemove, SaveTabletSnapShot, iTabletDeviceDetailsRemove } from '../interfaces/idevices';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})


export class DeviceService {
  //portNumber = ':8087/'
  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) { }

   
  //----------------Tablet---------------------

  TabletDeviceGet(e: GetDevice) {
    return this.base.post('Device/SelectTabletDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  TabletDeviceGeneralSave(e: SaveTabletDevice) {
    return this.base.post('Device/SaveTabletDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  TabletDeviceDelete(e: DeleteDevice) {
    return this.base.post('Device/DeleteTabletDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveTabletSnapshotDetails(e: SaveTabletSnapShot) {
    return this.base.post('Device/SaveTabletSnapshotDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  RemoveTabletDeviceDetails(TabletRemove: iTabletDeviceDetailsRemove) {
    return this.base.post('Device/RemoveTablet', TabletRemove).pipe(map((response: any) => {
      return response;
    }));
  }
  selectTablet(getTablet:GetDevice){
    return this.base.post('Device/SelectTabletSnapshotDetails', getTablet).pipe(map((response: any) => {
      return response;
    }));
  }
  //----------------Printer---------------------

  PrinterDeviceGet(e: GetDevice) {
    return this.base.post('Device/SelectPrinterDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  PrinterDeviceGeneralSave(e: SavePrinterDevice) {
    return this.base.post('Device/SavePrinterDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  PrinterDeviceDelete(e: DeleteDevice) {
    return this.base.post('Device/DeletePrinterDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  //----------------EMV---------------------

  SaveEMVDeviceDetails(EMVDetails: iEMVDeviceDetailsSave) {
    return this.base.post('Device/SaveEMVDeviceDetails', EMVDetails).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectEMVDeviceDetails(EMVSelect: iEMVDevicSelect) {
    return this.base.post('Device/SelectEMVDeviceDetails', EMVSelect).pipe(map((response: any) => {
      return response;
    }));
  }

  ActivateEMVDeviceDetails(EMVUpdate: ActivateEMVDevice) {
    return this.base.post('Device/DeleteEMVDeviceDetails', EMVUpdate).pipe(map((response: any) => {
      return response;
    }));
  }

  RemoveEMVDeviceDetails(EMVRemove: iEMVDeviceDetailsRemove) {
    return this.base.post('Device/RemoveEMVDevice', EMVRemove).pipe(map((response: any) => {
      return response;
    }));
  }

   //----------------RECEIPT---------------------

   ReceiptDeviceGet(e: GetDevice) {
    return this.base.post('Device/SelectReceiptDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  ReceiptDeviceGeneralSave(e: SaveReceiptConfigDevice) {
    return this.base.post('Device/SaveReceiptDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  ReceiptDeviceDelete(e: DeleteDevice) {
    return this.base.post('Device/DeleteReceiptDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }
}