import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetTypeOfBus, IActivateDeactivateCategory } from 'src/app/interfaces/iadmin';
import { AdminService } from 'src/app/services/admin.service';
import { LaborCategoryGeneralComponent } from '../labor-category-general/labor-category-general.component';
import { IGetRoleID } from 'src/app/interfaces/iemployee';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-labor-category',
  templateUrl: './labor-category.component.html',
  styleUrls: ['./labor-category.component.css']
})
export class LaborCategoryComponent implements OnInit {
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public tableRowId: any;
  public tableRowColor: any;
  public activeDeactiveValue: any;
  private buttonDisable: boolean;
  loginInfo: any;
  public dataSource = new MatTableDataSource<any>();
  public EmployeeTableData: any;
  public displayedColumns = [];
  columns = [];
  submittedGeneral: any;
  isInvalid: boolean = false;
  public modifierItemsData: any;

  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public labcatsessData = new EventEmitter();

  @ViewChild(LaborCategoryGeneralComponent) genComp: LaborCategoryGeneralComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;

  constructor(private helper: HelperService, private toaster: ToasterService, private adminService: AdminService,
    private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.labor");

    this.buttonDisable = false;
    this.employeeName = "Labor Category Name";
    this.employee = "Labor Category";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getAllCategories();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  getAllCategories() {
    const Categoryinput: IGetRoleID = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.adminService.SelectLabourCategory(Categoryinput).subscribe(
      (response) => {

        console.log(response[0], 'response')
        this.dataSource.data = response;
        this.EmployeeTableData = response;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Labor Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  async addEmployee(event?) {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    }
    else {
      var conf = await this.dialogser.confirm("labor");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
      }
      else {
        this.genComp.LaborcategoryForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.genComp.errorValue = "";
    this.isInvalid = false;
    this.genComp.requiredError = false;
    this.employeeName = "Labor Category Name";
    this.tableRowColor = 'transparent';
    this.tableRowId = 0;
    this.genComp.LaborcategoryForm.reset();
    this.genComp.ngOnInit();
    this.genComp.id = 0;
    this.genComp.submittedGeneral = false;
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.titleName.emit(this.employeeName);
    this.flipcomp.selectTableRowColor(0, 0, this.employeeName);
  }

  async getTableIdx(event) {
    //this.gentab.isFormPageDirty();
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("labor");
      if (conf == 'success') { // Navigate Away
        this.dialogser.ClearSession();
        this.genComp.LaborcategoryForm.reset();

        //General Data
        this.helper.setValue('Sessionid_help', event);
        this.tableRowId = event;
        this.tableRowColor = event;
        this.getAllCategories();
        this.genComp.requiredError = false;
        this.isInvalid = false;
        this.genComp.errorValue = "";
        this.flipcomp.rowClicked = event;
        this.dialogser.ModifiedId = event;
      }
      else // Cancel
      {
        //General Data
        this.genComp.LaborcategoryForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.labcatsessData.emit({
          data: this.genComp.LaborcategoryForm.value, event: "labor", val: true,
          itmname: this.genComp.LaborcategoryForm.value.categoryName
        });
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event);
      this.tableRowId = event;
      this.tableRowColor = event;
      this.genComp.requiredError = false;
      this.isInvalid = false;
      this.genComp.errorValue = "";
      this.getAllCategories();
    }
  }

  getGeneralDetail(event) {
    this.tableRowId = event.res.id;
    this.getAllCategories();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name)
    //this.addEmployee()
  }

  getTableName(name) {
    this.employeeName = name;
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  ActivateDeactivate(event) {
    this.activeDeactiveValue = event
    let currentTarget = event.e.currentTarget.checked
    const active: IActivateDeactivateCategory = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked
    }
    this.adminService.ActivateDeactivateCategory(active).subscribe(
      (response) => {
        //this.ClearData(0);
        if (currentTarget) {
          this.toaster.successPopUp("Category is Active");
        } else {
          this.toaster.successPopUp("Category is Deactive");
        }
        this.getAllCategories();
      },
    )
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  OnSave() {
    this.genComp.onSaveGeneral();
    if (this.genComp.LaborcategoryForm.invalid) {
      if ((this.genComp.LaborcategoryForm.value.name == null) || (this.genComp.LaborcategoryForm.value.name == "")) {
        this.isInvalid = true;
      }
      else {
        this.isInvalid = false;
      }
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getlabcatsessData(e) {
    this.labcatsessData.emit(e)
  }

  changeTableRowColor(event) {
    this.tableRowId = event.idx;
  }
}
