import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { SalesAreaGeneralComponent } from '../sales-area-general/sales-area-general.component';
import { SaleAreaLogisticComponent } from '../sale-area-logistic/sale-area-logistic.component';

@Component({
  selector: 'app-sales-area',
  templateUrl: './sales-area.component.html',
  styleUrls: ['./sales-area.component.css']
})

export class SalesAreaComponent implements OnInit {
  buttonDisableVal: boolean = false;
  public displayedColumns = [];
  columns = [];
  tableEnable: boolean = true;
  loginInfo: any;
  areaValue = [];
  public dataSource = new MatTableDataSource<any>();
  public salesAreabit: any;
  public getDataValue: any;
  public dragDataItem: any;
  public activeDeactiveValue: any;
  public salesColor: any;
  public salesLayout: any;
  indexExpanded: number = 0;
  matopen:boolean = false;
  @Input() clearItemForm: any;
  isInvalid: boolean = false;
  employeeName: any = "Menu";
  employee: any;
  public tableRowColor: any;
  @Output() public MenuNameEmit = new EventEmitter();
  @Output() public generalValueemit = new EventEmitter;
  @Output() public saleareasessData = new EventEmitter;

  //@Input() public buttonState;
  @ViewChild(SalesAreaGeneralComponent) salesAreagenComp: SalesAreaGeneralComponent;
  @ViewChild(SaleAreaLogisticComponent) salesAreaLogComp: SaleAreaLogisticComponent;

  submittedSaveGeneral: any;
  submittedGeneral: any;


  constructor(private helper: HelperService) { }

  buttonStatus: any;
  ngOnInit(): void {
    this.buttonStatus = false;
    this.buttonDisableVal = false;
    this.tableEnable = true;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.onMenuName();

  }
  matstate($event){
    this.matopen =$event
  }

  getGeneralDetail(event) {
    this.generalValueemit.emit({ res: event.res.id, name: event.name });
    this.salesAreaLogComp.onSaveSalesAreaLogistic(event.res.id);
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisableVal = false;
    }
    else {
      this.buttonDisableVal = !btnst;
    }
  }

  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null && clearForm != "") {
      this.addEmployee();
    }
  }

  expandPanel(index, e?) {
    this.indexExpanded = index;
  }
  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }
  }
  onChangeAccordion(e, index) {
    this.indexExpanded = index;
  }

  public addEmployee(): void {
    this.salesAreagenComp.submittedSaveGeneral = false;
    this.salesAreaLogComp.submittedGeneral = false;
    this.salesAreagenComp.errorValue = "";
    this.salesAreagenComp.SaleAreaGeneralForm.reset(); // should reset the form in child component
    this.salesAreagenComp.ngOnInit();
    this.salesAreagenComp.selectedItem = [];
    this.salesAreagenComp.checkboxValue = true;
    this.salesAreaLogComp.SaleAreaLogistic.reset();
    this.salesAreagenComp.SaleAreaGeneralForm.patchValue({
      isActive: true,
    });// should reset the form in child component
  }

  @Input()
  public set getDataSource(getAreadet) {
    this.getDataValue = getAreadet;
    this.isInvalid = false;

    //this.salesAreagenComp.SaleAreaGeneralForm.patchValue(getAreadet);
    //this.salesAreagenComp.errorValue = "";
    //this.salesAreagenComp.requiredError = false;
  }

  getDragItem(event) {
    this.dragDataItem = event;
  }

  onMenuName() {
    this.employee = "Sales Area";
    this.MenuNameEmit.emit(this.employee)
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  public async OnSaveSalesAreaGeneral() {
    let salesareaidval = await this.salesAreagenComp.onSaveSaleGeneral();
    if (this.salesAreagenComp.SaleAreaGeneralForm.invalid) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }

    //this.salesAreagenComp.SaleAreaGeneralForm.reset();
    // this.salesAreaLogComp.SaleAreaLogistic.reset();
  }

  public OnResetForm() {
    this.salesAreagenComp.SaleAreaGeneralForm.reset(); // should reset the form in child component
    // this.salesAreagenComp.ngOnInit();
    this.salesAreaLogComp.salesarealogdata = [];
    this.salesAreaLogComp.SaleAreaLogistic.reset();
    // this.salesAreaLogComp.ngOnInit();
  }

  getsaleareasessData(e) {
    this.saleareasessData.emit(e);
  }

  DataBindonNavigate(saleareadet) {
    this.salesAreagenComp.SaleAreaGeneralForm.patchValue(saleareadet);
    this.salesAreaLogComp.SaleAreaLogistic.patchValue(saleareadet);
  }

  DataBindonCancel() {
    this.salesAreagenComp.SaleAreaGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.salesAreaLogComp.SaleAreaLogistic.patchValue(this.helper.getValue('GetSessionData2'));
  }
}
