<div class="Permission_wrap">
    <form class="carouse_wrapper loginForm" [formGroup]="PermissionForm" (change)="onchange(PermissionForm.value)">
        <div class="row">
            <div class="col-sm-4">
                <div class="pull-let-DD">
                    <label class="customlable mb-0">Role</label>

                    <mat-form-field appearance="fill">
                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control"
                            formControlName="primaryRoleId" (selectionChange)="onChangeRole($event)">
                            <mat-option *ngFor="let item of pDataemit" [value]="item.value">{{ item.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="pull-right-Permission">
                    <div class="form-group">
                        <label>Override Current Permission</label>&nbsp;
                        <label class="switch emp-switch" [ngClass]="{ 'switch-disable': isDisabled == true }">
                            <input type="checkbox" formControlName="OverrideCurrent" />
                            <span class="slider round emp"></span>
                            <div class="toggle-button-text outshadow employee_onoff">
                                <div class="toggle-button-text-on"></div>
                                <div class="toggle-button-text-off"></div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div id="carousel-2" class="carousel slide mt-2" data-ride="carousel" data-interval="0">
            <div class="row w-100 mb-2 inside_wrap">
                <div class="col-sm-3">
                    <ol class="carousel-indicators" [ngClass]="{ 'no-pointers': isDisabled == true }">
                        <li data-target="#carousel-2" [attr.data-slide-to]="i"
                            *ngFor="let item of jsonArrayValue; let i = index" [ngClass]="{ active: i == 0 }"
                            (click)="onIndicatorChange(item.categoryId, i)">
                            <div class="row">
                                <div class="col-sm-4">
                                    <img src="assets/components/images/admin-icon.png" alt="" class="img-fluid"
                                        width="45px" />
                                </div>
                                <div class="col-sm-8">
                                    <h6 class="mb-0">{{ item.categoryName }}</h6>
                                </div>
                            </div>
                        </li>
                    </ol>
                    <div class="checkbox-table perm-all" *ngIf="roleData > 0">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input (change)="onCheckChangeAll($event, 1)" type="checkbox" [checked]="AllCheckBox"
                                formControlName="allPermission" class="custom-control-input" value="AllCheckBox"
                                name="1" id="1" [disabled]="isAllPermission" />
                            <label class="custom-control-label" for="1">All Permissions</label>
                        </div>
                    </div>
                    <div class="checkbox-table perm-all" *ngIf="roleData > 0">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input type="checkbox" class="custom-control-input" name="timeClock" id="timeClock"
                                formControlName="timeClockOnly" checked="timeClockCheckBox"
                                (change)="onCheckTimeOnly($event)" />
                            <label class="custom-control-label">Time Clock Only</label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-9">
                    <div class="carousel-inner row w-100 mx-auto" role="listbox">
                        <div class="carousel-item col-12 col-sm-12" *ngFor="let item of jsonArrayValue; let k = index"
                            [ngClass]="{ active: k == 0 }">
                            <div class="card" *ngIf="item.categoryId">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="acclabel_wrap col-sm-3">
                                            <img src="assets/components/images/admin-icon.png" alt=""
                                                class="img-fluid" />
                                            <p class="card-title mb-0 pl-2">
                                                {{ item.categoryName }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-sm-12" *ngIf="item.categoryName == 'Admin'">
                                            <div *ngFor="let item of timeCLockData">
                                                <div class="checkbox-table checkbox-table-time">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input (change)="
                                onCheckChange($event, item.permissionId)
                              " type="checkbox" [checked]="item.isSelected == true ? true : false"
                                                            class="custom-control-input" value="{{ item.permissionId }}"
                                                            name="{{ item.permissionId }}" id="{{ item.permissionId }}"
                                                            [disabled]="
                                !PermissionForm.get('OverrideCurrent').value
                              " />

                                                        <label class="custom-control-label"
                                                            for="{{ item.permissionId }}">{{ item.permissionName
                                                            }}</label>
                                                    </div>
                                                    <hr class="solid" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6" *ngFor="let item of permissionValues">
                                            <div class="checkbox-table">
                                                <div class="custom-control custom-checkbox custom-control-inline">
                                                    <input (change)="onCheckChange($event, item.permissionId)"
                                                        type="checkbox"
                                                        [checked]="item.isSelected == true ? true : false"
                                                        class="custom-control-input" value="{{ item.permissionId }}"
                                                        name="{{ item.permissionId }}" id="{{ item.permissionId }}"
                                                        [disabled]="
                              !PermissionForm.get('OverrideCurrent').value
                            " />
                                                    <label class="custom-control-label" for="{{ item.permissionId }}">{{
                                                        item.permissionName }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="PermissionForm.get('OverrideCurrent').value">
            <div class="col-sm-12 text-right">
                <div class="form-group">
                    <button class="btn btn-sm float-right submit_button button-none" type="submit"
                        (click)="onSavePermission()" [disabled]="buttonDisable ? true : null">
                        <img src="assets/components/images/save.png" alt="employee" class="" />
                        <label class="label_config">Save</label>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>