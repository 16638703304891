<div class="Gen_wrap">
    <form class="loginForm GeneralForm" [formGroup]="GeneralForm" (change)="onchange(GeneralForm.value)">
        <div class="pull-right-active">
            <span>Active</span>
            <div class="checkbox-table accordion-checkbox" id="checkbox-box">
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" class="custom-control-input" name="customCheckbox" id="activeForm" formControlName="isActive" checked="isActive" [attr.disabled]="readOnlyValue? true : undefined" [attr.disabled]="isActiveInactivePerm ? true : null" />
                    <label class="custom-control-label" for="activeForm"></label>
                </div>
            </div>
        </div>
        <!-- <br> -->
        <!-- <div class="row w-100">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="customlable mb-0">Employee of Store</label>
          <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="store">
            <mat-option [value]="0" disabled>Select Store</mat-option>
            <mat-option [value]="1"> Store</mat-option>
            <mat-option [value]="2"> Store</mat-option>
          </mat-select>
        </div>
      </div>
    </div> -->
        <div class="row w-100 m-0">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">First Name</label> <label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="firstName" appInputRestriction placeholder="First Name" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.firstName.errors) || (requiredError && GeneralData.firstName.errors)}"
                        maxlength="50" (input)="getMaxValueFName($event)" (keypress)="Highlight()" (focusout)="EmpGenValid()" [readonly]="readOnlyValue" />
                    <div class="error">{{errorValue}}</div>
                    <div *ngIf="(submittedGeneral && GeneralData.firstName.errors) || (requiredError && GeneralData.firstName.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.firstName.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Last Name</label><label class="text-danger">*</label>
                    <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.lastName.errors) || (requiredError && GeneralData.lastName.errors)}" maxlength="50"
                        (input)="getMaxValueLName($event)" (keypress)="Highlight()" (focusout)="EmpGenValid()" [readonly]="readOnlyValue" />
                    <div class="error">{{errorValue1}}</div>
                    <div *ngIf="(submittedGeneral && GeneralData.lastName.errors) || (requiredError && GeneralData.lastName.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.lastName.errors.required">Last Name is required</div>
                    </div>
                </div>
                <div class="form-group" *ngIf="!(companyHierarchyHide || dealerHierarchyHide )">
                    <label class="customlable mb-0">POS Name</label>
                    <input type="text" class="form-control" formControlName="posName" placeholder="POS Name" appInputRestriction maxlength="50" (input)="getMaxValue($event)" [readonly]="readOnlyValue" />
                    <div class="error">{{errorValue2}}</div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Login ID</label><label class="text-danger">*</label>
                    <input type="text" class="form-control" formControlName="loginId" maxlength="7" (keypress)="numberOnly($event)" placeholder="Login ID" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.loginId.errors) || (requiredError && GeneralData.loginId.errors)}"
                        (focusout)="EmpGenValid()" (keypress)="Highlight()" [readonly]="readOnlyValue" />
                    <div *ngIf="(submittedGeneral && GeneralData.loginId.errors) || (requiredError && GeneralData.loginId.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.loginId.errors.required">login ID is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Hire Date</label><label class="text-danger" *ngIf="!(companyHierarchyHide || dealerHierarchyHide )">*</label>
                    <mat-form-field appearance="fill" class="custom-date">
                        <input matInput [matDatepicker]="pickerA" placeholder="MM-DD-YYYY" class="form-control datepicker appInputRestriction" formControlName="employeeHireDate" (click)="pickerA.open()" (dateChange)="Highlight()" [min]="minDate" [disabled]="readOnlyValue">
                        <mat-datepicker #pickerA class="example-header"></mat-datepicker>
                    </mat-form-field>
                    <div class="error">{{dateMgsHire}}</div>
                    <!-- <input type="text" placeholder="MM-DD-YYYY" class="form-control" formControlName="employeeHireDate" appInputRestriction
            bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"
            [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.employeeHireDate.errors)}" (keypress)="Highlight()" (focusout)="EmpGenValid()"> -->
                    <!-- <div *ngIf="submittedGeneral && GeneralData.employeeHireDate.errors" class="invalid-feedback">
            <div *ngIf="(GeneralData.employeeHireDate.errors.required)">Hire Date is required</div>
          </div> -->
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Date Of Birth</label>
                    <mat-form-field appearance="fill" class="custom-date">
                        <input matInput [matDatepicker]="picker" placeholder="MM-DD-YYYY" class="form-control datepicker appInputRestriction" formControlName="employeeBirthday" (click)="picker.open()" [max]="maxDate" (dateChange)="Highlight()" [min]="minDate" [disabled]="readOnlyValue">
                        <mat-datepicker #picker class="example-header"></mat-datepicker>
                    </mat-form-field>
                    <!-- <input type="text" placeholder="MM-DD-YYYY" class="form-control" formControlName="employeeBirthday" appInputRestriction
            bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" [maxDate]="maxDate"> -->
                    <div class="error">{{dateMgs}}</div>
                    <div class="error">{{errorMessage}}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Phone</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                <img src="assets/components/images/USA.png" alt="employee" class="flag_img">+1
              </span>
                        </div>
                        <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="phone" placeholder="(xxx) xxx-xxxx" maxLength="14" [ngClass]="{ 'is-invalid': GeneralForm.controls['phone'].errors && submittedGeneral && !isValidFormSubmitted || mgs!=''}"
                            (keydown)="onDeletePhone($event)" (focusout)="phoneNumberCheck($event)" (keypress)="numberOnly2($event)" appInputRestriction [readonly]="readOnlyValue" />
                    </div>
                    <!-- <div *ngIf="submittedGeneral && GeneralData.phone.errors" class="invalid-feedback">
            <div *ngIf="submittedGeneral && GeneralData.phone.errors.maxLength">phone</div>
            <div *ngIf="GeneralForm.controls['phone'].errors && GeneralForm.controls['phone'].errors.max">phone</div>
          </div> -->
                    <div class="error">{{mgs}}</div>
                    <div *ngIf="GeneralForm.controls['phone'].errors && submittedGeneral && !isValidFormSubmitted" [ngClass]="'error'">
                        <div *ngIf="GeneralForm.controls['phone'].errors.pattern && mgs==''">
                            Mobile Number not valid.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="customlable mb-0">Address</label>
                    <input type="text" class="form-control" formControlName="address" placeholder="Address" appInputRestriction [readonly]="readOnlyValue" />
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">City</label>
                    <input type="text" class="form-control" formControlName="city" placeholder="City" appInputRestriction maxlength="50" (input)="getMaxValueCity($event)" [readonly]="readOnlyValue" />
                    <div class="error">{{errorValue3}}</div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">State</label>
                    <!-- <input type="text" class="form-control" formControlName="state" placeholder="MT"/> -->
                    <div class="StateWrap">
                        <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" #select1 formControlName="state" placeholder="Select State" (selectionChange)="getSubtier($event.value)" [disabled]="readOnlyValue">
                            <!-- <mat-select-filter (keyup)="onKey($event.target.value)"> </mat-select-filter> -->
                            <!-- <mat-select-filter [array]="variables" [displayMember]="'name'" (filteredReturn)="filteredList2 = $event"></mat-select-filter> -->
                            <input class="form-control input-lg inputbox_login" placeholder="Search here" (keyup)="onKey($event.target.value)" maxlength="50" />
                            <mat-option *ngFor="let item of filteredList2" [value]="item.id">
                                {{item.id}}
                            </mat-option>
                            <!-- <mat-option *ngIf="filterNodata">No data Found</mat-option> -->
                        </mat-select>
                    </div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Zip Code</label>
                    <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code" [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" (focusout)="zipCodeCheck($event)" (keydown)="onDeleteZipCode($event)" [ngClass]="{ 'is-invalid': zipCodeMsg!=''}"
                        [readonly]="readOnlyValue" />
                    <div class="error">{{zipCodeMsg}}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <!-- <div class="form-group">
          <label>Back Office Access</label><br>
          <div class="switchToggle">
            <input type="checkbox" id="backofficeAccess" formControlName="backofficeAccess"
              (change)="togglebackoffice(GeneralForm.get('backofficeAccess').value)">
            <label class="toggle-button-switch" for="backofficeAccess"></label>
            <div class="toggle-button-text outshadow employee_onoff permissionButton employeeToggle">
              <div class="toggle-button-text-on"></div>
              <div class="toggle-button-text-off"></div>
            </div>
          </div>
        </div> -->
                <div class="form-group">
                    <label>Back Office Access</label><br>
                    <label class="switch store-switch" [ngClass]="{'no-pointers': readOnlyValue == true}">
            <input type="checkbox" formControlName="backofficeAccess"
              (change)="togglebackoffice(GeneralForm.get('backofficeAccess').value)">
            <span class="slider round emp"></span>
            <div class="toggle-button-text store-switch outshadow employee_onoff">
              <div class="toggle-button-text-on"></div>
              <div class="toggle-button-text-off"></div>
            </div>
          </label>
                </div>

                <div class="form-group">
                    <label class="customlable mb-0">Email</label><label class="text-danger" *ngIf="GeneralForm.get('backofficeAccess').value">*</label>
                    <input (keypress)="HighlightEmailPass()" type="text" class="form-control" formControlName="emailId" appInputRestriction placeholder="&#x2709; Example@myemailaddress.com" autocomplete="on" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.emailId.errors)}"
                        maxlength="100" (input)="getMaxValueEmail($event)" (focusout)="EmpGenValid()" [readonly]="readOnlyValue" />
                    <div class="error">{{errorValue4}}</div>

                    <div *ngIf="(submittedGeneral && GeneralData.emailId.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.emailId.errors.email">Email must be a valid email address</div>
                        <div *ngIf="GeneralForm.get('backofficeAccess').value">
                            <div *ngIf="GeneralData.emailId.errors.required">Email is required</div>
                        </div>

                    </div>
                </div>

                <div class="form-group" *ngIf="GeneralForm.get('backofficeAccess').value">
                    <label class="customlable mb-0">Password</label><label class="text-danger">*</label>
                    <input type="text" class="form-control" formControlName="password" placeholder="Password" appInputRestriction (keypress)="HighlightEmailPass()" (focusout)="EmpGenValid()" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.password.errors)}" [readonly]="readOnlyValue"
                    />
                    <strength-checker barLabel="Strength: " class="password-strength" [passwordToVerify]="GeneralForm.value.password" (passwordStrength)="passwordValid($event)">
                    </strength-checker>
                    <div *ngIf="(submittedGeneral && GeneralData.password.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.password.errors.required">Password is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-right">
                <div class="form-group">
                    <button class="btn  btn-sm float-right submit_button  button-none" type="submit" (click)="onSaveGeneral();" [disabled]="buttonDisable ? true : false">
            <img src="assets/components/images/save.png" alt="employee" class="img-fluid">
            <label class="label_config">Save</label>
          </button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- ngx-intl-tel-input -->
<!-- <ngx-intl-tel-input [cssClass]="'form-control country-tel-code'" [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true" [preferredCountries]="preferredCountries" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [tooltipField]="TooltipLabel.Name"
            [phoneValidation]="false" [separateDialCode]="separateDialCode" formControlName="phone"
            [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.phone.errors }">
          </ngx-intl-tel-input>
          <div *ngIf="submittedGeneral && GeneralData.phone.errors" class="invalid-feedback">
            <div *ngIf="submittedGeneral && GeneralData.phone.errors.required">Email must be a valid email address</div>
          </div> -->
<!-- [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.phone.errors }" -->
<!-- <div *ngIf="submittedGeneral && GeneralData.phone.errors" class="invalid-feedback">
            <div *ngIf="GeneralForm.controls['phone'].errors">Phone number must be at least 10 numbers</div>
          </div> -->
<!-- ngx-intl-tel-input -->
