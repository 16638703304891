import { Component, OnInit } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { MessageDto, iFrontEndMsg } from 'src/app/interfaces/iWebSocket';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
 // styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  title = 'chat-ui';
  entityName: string = "";
  frameId:any;
  attributes:any;
  constructor(public ObjChat: ChatService) {

  }

  ngOnInit(): void {
    this.ObjChat.connect();
  }

  sendMessage(): void {

    //    this.ObjChat.sendMessageToApi(this.text).subscribe({
    //   next: _ => this.text = '',
    //   error: (err) => console.error(err)
    // });
    const fondEndcls: iFrontEndMsg = {
      entityType: this.entityName,
      frameId:this.frameId,
      tenantId:'12',
      attributes:this.attributes,
      timestamp:Date,
      connectionId:''
      
    }
    this.ObjChat.sendMessageToHub(fondEndcls).subscribe({
      next: _ => this.entityName = '',
      error: (err) => console.error(err)
    });
  }
}