import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  Host,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from 'ngx-intl-tel-input';
import { ISelectState, IListInfo } from 'src/app/interfaces/iadmin';
import { AdminService } from 'src/app/services/admin.service';
import { IshiftValue } from 'src/app/interfaces/itime';
import { TimeService } from 'src/app/services/time.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GiftcardService } from 'src/app/services/giftcard.service';
import { Console } from 'console';
import {
  iDataCapAPI,
  iGiftCardSave,
  iGiftCardSelect,
  iSelectCreditCard,
} from 'src/app/interfaces/icustompayment';
import { threadId } from 'worker_threads';
import { GiftCardDesignerComponent } from '../gift-card-designer/gift-card-designer.component';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-gift-card-general',
  templateUrl: './gift-card-general.component.html',
  styleUrls: ['./gift-card-general.component.css'],
})
export class GiftCardGeneralComponent implements OnInit {
  GiftCardForm: FormGroup;
  buttonDisable: boolean = false;
  mobNumberPattern = '^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$';
  isValidFormSubmitted = false;
  GatewaySelection: any;
  loginInfo: any;
  submittedGeneral = false;
  isSubmitted = false;
  isValidated = false;
  requiredError: boolean = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  storeid:any;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  //@Output() public generalGift = new EventEmitter();
  @Output() public giftcardsess_child = new EventEmitter();
  @Output() public copySelectedCard = new EventEmitter();
  @Output() public hideCopyColor = new EventEmitter();
  @Output() public giftDesFuncCall = new EventEmitter();

  @Input() public copyFromDropDown;
  mgs: any = '';
  filterNodata: any;
  primarystoreSel: any;
  gatewayList: any;
  hirarcyvalue: any;
  zipCodeMsg: any = '';
  public get2ndTipValue: any;
  errorValue: any;
  errorValue1: any;
  errorValue2: any;
  errorValue3: any;
  errorValue4: any;
  errorValue5: any;
  marked = false;
  theCheckbox = false;
  checked = false;
  giftCardarray: any = [];
  hideCopyFrom: boolean = false;

  @Output() public giftCardDesigner = new EventEmitter();
  @Output() public isInvalid = new EventEmitter();
  @Output() messageEvent = new EventEmitter();
  private onDestroy$: Subject<void> = new Subject<void>();
  // public variables = ['One', 'Two', 'County', 'Three', 'Zebra', 'XiOn'];
  // public filteredList2 = this.variables.slice();
  filteredList2: any = [];
  public variables: any = [];
  tipList: any[];
  isTip2Checked: boolean = false;
  isLoader: boolean = false;

  @ViewChild(GiftCardDesignerComponent)
  GiftDesigncomp: GiftCardDesignerComponent;
  xmlDatDisplay: string;
  successval: boolean;
  @Output() matopen: EventEmitter<any> = new EventEmitter()
  matstate($event) {
    this.matopen.emit($event)
  }

  constructor(
    private formBuilder: FormBuilder,
    private objGiftCard: GiftcardService,
    private helper: HelperService,
    private toaster: ToasterService,
    private admin: AdminService,
    private CodeMaster: TimeService,
    private objPayment: PaymentService,
  ) {
    this.GiftCardForm = this.formBuilder.group({
      secondTip: this.formBuilder.array([this.add2TipLineGroup()]),
    });
  }

  ReadOnlyStyleGuideNotes: boolean;
  ngOnInit(): void {
    //this.helper.setValue('GetSessionData1', null);
    this.ReadOnlyStyleGuideNotes = true;
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.GiftCardForm = this.formBuilder.group({
      id: [0],
      name: [null],
      BusinessName: [null, Validators.required],
      phone: [null],
      //phone: ['', [Validators.required, Validators.pattern("^((\\+91 ?)|0)?[0-9]{10}$")]],
      //phone : "^((\\+91-?)|0)?[0-9]{10}$",
      ContactFirstName: [null, Validators.required],
      ContactLastName: [null, Validators.required],
      //ContactEmail: [null, [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%#!$%^&*+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]{2,4}$')]],
      ContactEmail: ['', [Validators.required, Validators.email]],
      Address: [null, Validators.required],
      City: [null, Validators.required],
      State: [null, Validators.required],
      Zip: [null, Validators.required],
      GatewaySelection: [null],
      prepaidGiftMID: [null],
      prepaidGiftTokenKey: [null],
      IsAllow_SameItem: [null],
      Add2ndTipLine: [false],
      AllowTipping: [false],
      MultiStore: [false],
      E_GIFT: [false],
      primaryStore: [null],
      offLineMode: [false],
      autometicFailover: [false],
      riskRestriction: [false],
      noOfTranscation: [null],
      amount: [null],
      createdBy: [0],
      copyFrom: [null],
      secondTip: this.formBuilder.array([
        this.formBuilder.group({
          textonReceipt: [null],
          gratuity: [null],
        }),
      ]),
    });
    this.onSelectState();
    this.admin.HierachyValue.pipe(takeUntil(this.onDestroy$)).subscribe(
      (data) => {
        this.hirarcyvalue = data;
        if (
          this.hirarcyvalue.company != undefined &&
          this.hirarcyvalue.company != null &&
          this.hirarcyvalue.company != ''
        ) {
          this.storeid = this.hirarcyvalue.store.id;
          this.getPrimaryStoreData(this.hirarcyvalue.company.id,this.storeid);
        }
      }
    ),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');

    this.GetGatewaySelection();
    this.xmlDatDisplay = '';
  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
  }

  //----------For Gateway Selection ---Take data from Code master------

  GetGatewaySelection() {
    const time: IshiftValue = {
      parentCode: 'GTW1',
    };

    this.CodeMaster.GetClockListType(time)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        this.gatewayList = response;
      }),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
  }

  //--------------------------End Gateway Selection --------------------------------

  get GeneralData() {
    return this.GiftCardForm.controls;
  }

  onKeypressEvent(elem): boolean {
    const charCode = elem.which ? elem.which : elem.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (
      elem.target.value.length < 3 ||
      elem.target.value.length > 3 ||
      elem.target.value.length < 11
    ) {
      if (elem.key === '-') elem.preventDefault();
    }
    // this.GeneralCompanyForm.get("zipCode").patchValue(elem.target.value.toUpperCase());
    if (elem.target.value.length <= 10) {
      if (elem.target.value.length === 5) {
        elem.target.value = `${elem.target.value}-`;
      }
      this.zipCodeMsg = '';
    } else {
      elem.target.value = elem.target.value.slice(0, -1);
      this.zipCodeMsg = '';
    }
    return true;
  }

  //@Input() public buttonState;
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set hideCopyFromInitial(hideCopyFrom) {
    if (hideCopyFrom == true) {
      this.hideCopyFrom = true;
    } else {
      this.hideCopyFrom = false;
    }
  }

  @Input()
  public set MenuRowItem(tableRowData) {
    this.errorValue = '';
    this.errorValue1 = '';
    this.errorValue2 = '';
    this.errorValue3 = '';
    this.errorValue4 = '';
    this.errorValue5 = '';
    if (
      tableRowData != undefined &&
      tableRowData != null &&
      tableRowData != ''
    ) {
      this.GiftCardForm.patchValue(tableRowData[0]);
      // this.getGeneralDetail(tableRowData)
    }
  }

  Bind2ndTips(tableRowData) {
    if (
      tableRowData != undefined &&
      tableRowData != null &&
      tableRowData != ''
    ) {
      let tip2nd = tableRowData;
      let menuItemArray = tip2nd;

      const dynFormArray = this.GiftCardForm.controls['secondTip'] as FormArray;
      dynFormArray.controls = [];
      if (tableRowData.length > 0) {
        for (let j = 0; j < tableRowData.length; j++) {
          dynFormArray.push(
            this.add3TipLineGroup(
              menuItemArray[j].textonReceipt,
              menuItemArray[j].gratuity
            )
          );
        }
      } else {
        dynFormArray.push(this.add2TipLineGroup());
      }
    }
  }

  async CreditCardValidation(): Promise<any> {
    return new Promise((resolve, reject) => {
      const CreditCardList: iSelectCreditCard = {
        id: 0,
        tenantId: this.loginInfo.tenantId,
      };
      this.objPayment.EditCreditCard(CreditCardList).subscribe((res) => {
        resolve({ res: res });

      });
    });
  }

  async onApiCall() {
    var CreditResult = await this.CreditCardValidation();

    // console.log(CreditResult.res[0].total_Lanes, 'CreditResult')
    this.DataCapFieldRest();
    this.isLoader = true;
    var laneCount = CreditResult.res.length == 0 ? "" : CreditResult.res[0].total_Lanes.toString()

    var ResGift = await this.GetDataCapGiftCardAPIData(laneCount);

    this.xmlDatDisplay = '';
    const dataxml = JSON.parse(ResGift.res.jsonData);
    if (dataxml.Response.Status == 'Error') {
      this.xmlDatDisplay =
        `Status: ` +
        dataxml.Response.Status +
        `<br/> Description: ` +
        dataxml.Response.ErrorList.Error.ErrorDescription;
      this.successval = false;
      this.isLoader = false;
      return;
    } else {
      this.GiftCardForm.patchValue({
        prepaidGiftMID:
          dataxml.Response.HostedIntegration.MerchantID__PrePaid__InStore,
        prepaidGiftTokenKey:
          dataxml.Response.HostedIntegration.TokenKey__PrePaid__InStore,
      });
      this.isLoader = false;
      this.successval = true;
      this.xmlDatDisplay =
        'Congratulations! Datacap account is successfully setup.';
    }
  }

  DataCapFieldRest() {
    this.GiftCardForm.patchValue({
      prepaidGiftMID: null,
      prepaidGiftTokenKey: null,
    });
  }

  async GetDataCapGiftCardAPIData(laneCount): Promise<any> {
    return new Promise((resolve, reject) => {
      const GiftCard: iDataCapAPI = {
        businessName: this.GiftCardForm.value.BusinessName,
        contactFirstName: this.GiftCardForm.value.ContactFirstName,
        contactLastName: this.GiftCardForm.value.ContactFirstName,
        contactEmail: this.GiftCardForm.value.ContactEmail,
        phone: this.GiftCardForm.value.phone,
        address: this.GiftCardForm.value.Address,
        city: this.GiftCardForm.value.City,
        state: this.GiftCardForm.value.State,
        zip: this.GiftCardForm.value.Zip,
        gatewaySelection: this.GiftCardForm.value.GatewaySelection,
        total_Lanes: laneCount,
      };
      this.objGiftCard.DataCapGiftCardTest(GiftCard).subscribe((res) => {
        console.log(res, 'dataCap Gift');
        resolve({ res: res });
      });
    });
  }
  async getGeneralDetail(Giftid): Promise<any> {
    return new Promise((resolve, reject) => {
      const ItemTypeList: iGiftCardSelect = {
        id: Giftid,
        tenantId: this.loginInfo.tenantId,
      };
      this.objGiftCard
        .EditGiftCard(ItemTypeList)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          if (res != undefined && res != null && res != '') {
            this.GiftCardForm.patchValue(res.general[0]);
            this.get2ndTipValue = res.tips;
            this.Bind2ndTips(this.get2ndTipValue);
            resolve(res);
          }
        }),
        (err) => console.error('Error', err),
        () => console.log('HTTP request completed.');
    });
  }
  @Input()
  public set GetTipValue(tableRowData) {
    if (
      tableRowData != undefined &&
      tableRowData != null &&
      tableRowData != ''
    ) {
      let tip2nd = tableRowData;
      let menuItemArray = tip2nd;

      const dynFormArray = this.GiftCardForm.controls['secondTip'] as FormArray;
      dynFormArray.controls = [];
      if (tableRowData.length > 0) {
        for (let j = 0; j < tableRowData.length; j++) {
          dynFormArray.push(
            this.add3TipLineGroup(
              menuItemArray[j].textonReceipt,
              menuItemArray[j].gratuity
            )
          );
        }
      } else {
        dynFormArray.push(this.add2TipLineGroup());
      }
    }
  }

  addTip2Multi() {
    const dynFormArray = this.GiftCardForm.controls['secondTip'] as FormArray;
    dynFormArray.push(this.add2TipLineGroup());
  }

  add2TipLineGroup() {
    return this.formBuilder.group({
      textonReceipt: [null],
      gratuity: [null],
    });
  }

  add3TipLineGroup(tip, grat) {
    return this.formBuilder.group({
      textonReceipt: tip,
      gratuity: grat,
    });
  }

  async onSaveGeneral(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.errorValue = '';
      this.errorValue1 = '';
      this.errorValue2 = '';
      this.errorValue3 = '';
      this.errorValue4 = '';
      this.errorValue5 = '';
      this.submittedGeneral = true;
      if (this.GiftCardForm.invalid) {
        this.isInvalid.emit(true);
        return;
      } else {
        if (
          this.GeneralData.phone.value != undefined &&
          this.GeneralData.phone.value != null &&
          this.GeneralData.phone.value != ''
        ) {
          if (this.GeneralData.phone.value.length <= 12) {
            this.isInvalid.emit(true);
            this.mgs = 'Phone number must be at least 10 digit';
            return;
          } else {
            this.mgs = '';
          }
        } else {
          this.mgs = '';
        }
      }
      if (
        this.GiftCardForm.controls.Zip.value != undefined &&
        this.GiftCardForm.controls.Zip.value != null &&
        this.GiftCardForm.controls.Zip.value != ''
      ) {
        if (this.GiftCardForm.controls.Zip.value.length === 10) {
          if (this.GiftCardForm.controls.Zip.value.slice(5, 6) != '-') {
            this.zipCodeMsg = 'Invalid Zip Code';
            return;
          }
        }
        if (this.GiftCardForm.controls.Zip.value.length <= 4) {
          this.isInvalid.emit(true);
          this.zipCodeMsg = 'Please enter min 5 digits zipcode';
          return;
        } else {
          this.zipCodeMsg = '';
        }
      } else {
        this.zipCodeMsg = '';
      }
      let paymentserv;
      paymentserv = this.GiftCardForm.value;
      paymentserv.createdBy = this.loginInfo.userId;
      paymentserv.tenantId = this.loginInfo.tenantId;
      paymentserv.isActive = true;

      const objphone = paymentserv.phone;
      if (objphone != undefined && objphone != '' && objphone != null) {
        let trimphone = objphone.toString().match(/\d/g);
        //const isAllZero1 = trimphone.some(item => item !== "0");
        const isAllZero = trimphone.every((val, i, arr) => val === '0');
        if (parseInt(trimphone.length) != 10) {
          this.toaster.errorPopUp('Phone number must be at least 10 digit');
          return;
        } else if (isAllZero == true) {
          this.toaster.errorPopUp('Invalid Mobile Number');
          return;
        } else {
          paymentserv.phone = objphone.replace(/[^-()\d ]/g, '');
        }
      }

      this.objGiftCard
        .SaveGiftCard(paymentserv)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          this.GiftCardForm.patchValue({
            id: res.id,
          });
          this.helper.setValue('GetSessionData1', paymentserv);
          this.isSubmitted = false;
          this.submittedGeneral = false;
          this.requiredError = false;
          if (res.id == 0) {
            this.toaster.errorPopUp(res.message);
            return;
          }
          //else {
          //this.generalGift.emit({ res: res, name: paymentserv.name });
          //this.toaster.successPopUp(res.message);
          //}
          resolve(res);
        }),
        (err) => console.error('Error', err),
        () => console.log('HTTP request completed.');
    });
  }

  onSelectState() {
    const ObjState: ISelectState = {
      id: 0,
    };
    this.admin
      .SelectStateMaster(ObjState)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        this.filteredList2 = response;
        this.variables = this.filteredList2.slice();
      }),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
  }

  onKey(value) {
    this.filteredList2 = this.search(value);
  }

  search(value: string) {
    let filteredList2 = this.variables.filter(
      (unit) => unit.id.indexOf(value.toUpperCase()) > -1
    );
    if (value.length == 1) {
      let filterdata = filteredList2.filter(
        (x) => x.id.substring(0, 1) == value.toUpperCase()
      );
      return [...filterdata];
    } else if (value.length == 2) {
      let filterSecdata = filteredList2.filter(
        (y) => y.id.substring(0, 2) == value.toUpperCase()
      );
      return [...filterSecdata];
    } else {
      this.filterNodata = true;
      return [...filteredList2];
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberOnly2(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (event.target.value.length <= 14) {
      if (event.target.value.length === 3) {
        event.target.value = `(${event.target.value}) `;
      }
      if (event.target.value.length === 9) {
        event.target.value = `${event.target.value}-`;
      }
      if (event.target.value.length === 11) {
        event.target.value = `${event.target.value}`;
      }
      if (event.target.value.length >= 13) {
        this.isInvalid.emit(false);
        this.mgs = '';
      }
    } else {
      event.target.value = event.target.value.slice(0, -1);
    }
    return true;
  }

  phoneNumberCheck(event) {
    if (event.target.value.length == 0) {
      this.mgs = '';
    }
    if (event.target.value.slice(0, 1) != '(') {
      if (event.target.value.length > 10) {
        this.mgs = 'Invalid mobile number';
        return;
      } else if (
        event.target.value.length < 3 &&
        event.target.value.length > 0
      ) {
        event.target.value = `(${event.target.value.slice(0, 3)})`;
        if (event.target.value.length < 15) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = '';
      } else if (
        event.target.value.length < 6 &&
        event.target.value.length > 0
      ) {
        event.target.value = `(${event.target.value.slice(
          0,
          3
        )}) ${event.target.value.slice(3, 6)}-`;
        if (event.target.value.length < 15) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = '';
      } else if (
        event.target.value.length >= 6 &&
        event.target.value.length > 0
      ) {
        event.target.value = `(${event.target.value.slice(
          0,
          3
        )}) ${event.target.value.slice(3, 6)}-${event.target.value.slice(
          6,
          event.target.value.length
        )}`;
        if (event.target.value.length < 14) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = '';
      }
    } else {
      if (event.target.value.length < 14) {
        this.mgs = 'Invalid mobile number';
        return;
      }
      this.mgs = '';
    }
  }

  onDeletePhone(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.mgs = '';
      }
    }
  }

  getPrimaryStoreData(id,storeid) {
    const login: IListInfo = {
      loginUserId: this.loginInfo.userId,
      tenantId: id,
    };
    this.admin
      .TenantHierarchyData(login)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        this.primarystoreSel = response.childHierarchyList;
        this.primarystoreSel =this.primarystoreSel.filter(x=>x.id != storeid)
      }),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
  }
  getMaxValueName(e) {
    if (e.target.value.length == 50) {
      this.errorValue = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue = '';
    }
  }

  getMaxValueBName(e) {
    if (e.target.value.length == 25) {
      this.errorValue1 = 'Max-Limit 25 characters reached';
    } else {
      this.errorValue1 = '';
    }
  }

  getMaxValueFName(e) {
    if (e.target.value.length == 50) {
      this.errorValue2 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue2 = '';
    }
  }

  getMaxValueLName(e) {
    if (e.target.value.length == 50) {
      this.errorValue3 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue3 = '';
    }
  }

  getMaxValueEmail(e) {
    if (e.target.value.length == 100) {
      this.errorValue4 = 'Max-Limit 100 characters reached';
    } else {
      this.errorValue4 = '';
    }
  }

  getMaxValueCity(e) {
    if (e.target.value.length == 50) {
      this.errorValue5 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue5 = '';
    }
  }

  GiftValid() {
    if (this.GiftCardForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    } else {
      if (
        this.GeneralData.phone.value != undefined &&
        this.GeneralData.phone.value != null &&
        this.GeneralData.phone.value != ''
      ) {
        if (this.GeneralData.phone.value.length <= 12) {
          this.mgs = 'Phone number must be at least 10 digit';
          this.isInvalid.emit(true);
          this.requiredError = true;
          return;
        }
      }
      if (
        this.GiftCardForm.controls.Zip.value != undefined &&
        this.GiftCardForm.controls.Zip.value != null &&
        this.GiftCardForm.controls.Zip.value != ''
      ) {
        if (this.GiftCardForm.controls.Zip.value.length <= 4) {
          this.zipCodeMsg = 'Please enter min 5 digits zipcode';
          this.requiredError = true;
          this.isInvalid.emit(true);
          return;
        }
      }
      this.requiredError = false;
    }
  }
  Highlight() {
    this.onchange(this.GiftCardForm.value);

    if (!this.GiftCardForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  //  Realese the memory
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.GiftCardForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.giftcardsess_child.emit(this.GiftCardForm.value);
  }

  DeleteTip(index: number): void {
    if (!((<FormArray>this.GiftCardForm.get('secondTip')).length == 1)) {
      (<FormArray>this.GiftCardForm.get('secondTip')).removeAt(index);
    }
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.giftcardsess_child.emit({ data: data, event: 'GiftCard', val: true });
  }

  tiptoggleIsActive(e) {
    this.isTip2Checked = e;
    if (this.isTip2Checked == false) {
      this.GiftCardForm.get('secondTip').reset();
    }
  }

  editGiftCard(e) {
    this.giftCardDesigner.emit(true);
  }

  async onCategorySelected(e) {
    this.onchange(this.GiftCardForm.value);
    await this.getGeneralDetail(e.value);

    this.giftDesFuncCall.emit(e.value);

    this.GiftCardForm.controls['id'].setValue(0);
    this.hideCopyFrom = false; //comment for now
    // this.hideCopyColor.emit(true);  //comment for now
  }

  onDeleteZipCode(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.zipCodeMsg = '';
      }
    }
  }

  zipCodeCheck(event) {
    if (event.target.value.length === 6) {
      if (event.target.value.slice(5, 6) == '-') {
        event.target.value = `${event.target.value.slice(0, 5)}`;
      }
    } else if (
      event.target.value.length > 5 &&
      event.target.value.length < 10
    ) {
      if (event.target.value.slice(5, 6) != '-') {
        event.target.value = `${event.target.value.slice(
          0,
          5
        )}-${event.target.value.slice(6, event.target.value.length)}`;
        this.zipCodeMsg = '';
      }
    } else if (event.target.value.length === 9) {
      event.target.value = `${event.target.value.slice(
        0,
        5
      )}-${event.target.value.slice(6, 9)}`;
    } else if (event.target.value.length === 10) {
      if (event.target.value.slice(5, 6) != '-') {
        this.zipCodeMsg = 'Invalid Zip Code';
      } else {
        this.zipCodeMsg = '';
      }
    }
  }
  async SelectGateway(e?) {
    if (e.value == 'GTW3') {
      this.onApiCall();
    }
  }
}
