import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import { BaseService } from '../base/base.service';
import { Router } from '@angular/router';
import { SaveAdjustGeneral, SelectAdjustGeneral,DeleteAdjust, GetAdjustment, SaveAdjustmentDetails,EditAdjustment} from '../interfaces/iadjustment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class adjustmentService {
  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) { }

  SelectAdjustmentGeneral(employee: SelectAdjustGeneral) {
    return this.base.post('Adjustment/SelectGenaralAdjustment', employee).pipe(map((response: any) => {
      return response;
    }));
  }
  SaveAdjustmentGeneral(hierachy: SaveAdjustGeneral) {
    return this.base.post('Adjustment/SaveAdjustmentGeneral', hierachy).pipe(map((response: any) => {
      return response;
    }));
  }
  DeleteAdjustmentGeneral(isActive: DeleteAdjust) {
    return this.base.post('Adjustment/DeleteGeneralAdjustment', isActive).pipe(map((response: any) => {
      return response;
    }));
  }
  
  GetAdjustmentGeneral(adjustment: GetAdjustment) {
    return this.base.post('Admin/GetCodeMaster', adjustment).pipe(map((response: any) => {
      return response;
    }));
  }

  EditAdjustmentGeneral(adjustment: SelectAdjustGeneral) {
    return this.base.post('Adjustment/EditGenaralAdjustment', adjustment).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveAdjustmentDetails(adjustment: SaveAdjustmentDetails) {
    return this.base.post('Adjustment/SaveAdjustmentDetails', adjustment).pipe(map((response: any) => {
      return response;
    }));
  }

  EditAdjustmentDetails(adjustment: EditAdjustment) {
    return this.base.post('Adjustment/EditGenaralAdjustment', adjustment).pipe(map((response: any) => {
      return response;
    }));
  }
}
