import { CdkDragDrop, CdkDragExit, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ɵEMPTY_ARRAY } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { MenuGroupService } from 'src/app/services/menugroup.service';
import { GetMenuGroup } from 'src/app/interfaces/imenu';
import { MatTableDataSource } from '@angular/material/table';
import { MenuService } from 'src/app/services/menu.service';
@Component({
  selector: 'app-menu-group-layout',
  templateUrl: './menu-group-layout.component.html',
  styleUrls: ['./menu-group-layout.component.css']
})
export class MenuGroupLayoutComponent implements OnInit {
  loginInfo: any;
  public dataSourceTableGroup = new MatTableDataSource<any>();
  public displayedColumns = [];
  public columns = [];
  dataSourceGroup: any;
  public menu_Group: boolean = true;
  tableEnable: boolean = true;

  public table_render: boolean = true;

  @Input('connectedTo')
  connectedTo: string[]
  @Input() public todoList;
  @Input() public DragId;
  @Input() dataSource;
  @Input() ParentID;
  @Input() ParentGroup;
  render: any;
  areaValue: any;
  public salesAreaflip: any;
  rowClicked: any;
  salesAreaList: any = [];
  salesAreaLayout: any = [];
  menuGroupList: any = [];
  menuGroupLayout: any = [];
  @Output() public rowDataEmit = new EventEmitter();
  @Output() public menuGroupDrop = new EventEmitter();
  @Input() public buttonState;
  menuItem: any = {
    tenantId: 0,
    salesAreaId: 0,
    menuGroupId: 0,
    menuPageId: 0,
    areaObject: "",
  };
  @Input() layoutId;
  @Input() SalesAreaID;
  constructor(private changeDetector: ChangeDetectorRef, private formBuilder: FormBuilder, private helper: HelperService,
    private toaster: ToasterService, private objLayotService: MenuGroupService, private objItem: MenuService) { }
  @Input()
  public set DragDrop(dragDataItem) {
    this.DragId = dragDataItem


  }
  @Input()
  public set DataLayoutAccordian(LayoutAccordian) {
    this.salesAreaflip = LayoutAccordian;
  }
  // @Input()
  // public set ParentID(ParentID) {
  //   this.ParentId=ParentID;
  // }

  @Input()
  public set MenuGroupList(list) {
    this.menuGroupList = list;
    this.dataSourceTableGroup.data = list;
    this.displayedColumns = ['name'];
    this.columns = [
      { columnDef: 'name', header: 'Menu Group Name' }
    ];
  }

  @Input()
  public set MenuGroupLayout(list) {
    this.menuGroupLayout = list;
    this.rowClicked = "transparent";
  }

  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');
  }

  cdkDropListExited(event: CdkDragExit<string[]>) {
    const preview = new ElementRef<HTMLElement>(document.querySelector('.cdk-drag.cdk-drag-preview'));
    this.render.removeClass(preview.nativeElement, 'dropzone-placeholder');
  }

  drop(event) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data.filteredData,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
   
      this.table_render = false
      this.changeDetector.detectChanges();
      this.table_render = true
    }
  }

 

  OnSaveMenuGroup() {
    this.menuItem.tenantId = this.loginInfo.tenantId;
    this.menuItem.salesAreaId = this.SalesAreaID;
    this.menuItem.menuGroupId = 0;
    this.menuItem.menuPageId = 0;
    let menuObject = JSON.stringify(this.menuGroupLayout);
    this.menuItem.areaObject = menuObject
    console.log(this.menuItem,'SaveMenuGroupLayout');
    if (this.SalesAreaID!='' && menuObject!="[]" && menuObject!=undefined)
    {
      this.objItem.saveAreaMapping(this.menuItem).subscribe(
        (response) => {
          // this.toaster.successPopUp(response.message);
        })
    }
  
  }

  onClickItem(e) {
    this.helper.setValue('OnDirty', true);
    this.helper.setValue('MnuGrpLayout_ID', e.id);
    this.helper.setValue('SessionPageName', "layoutPage");
    this.rowClicked = e.id;
    this.rowDataEmit.emit(e)
  }
  
  GetSessionId(e)
  {
    this.menuGroupDrop.emit(e);
  //  console.log(e,'menugroupseeiosnid')
  }
}