<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{ employee }}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="addStore()">
          <i class="fa fa-plus"></i>
        </button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData" />
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{ submit_button_error: isInvalid == true }" class="btn btn-sm float-right submit_button global-submit" type="submit" (click)="onSaveGeneral()" [disabled]="buttonDisable ? true : null">
        <img src="assets/components/images/save.png" alt="employee" class="" />
        <label class="label_config">Save</label>
      </button>
        </div>
    </div>
</div>
<div class="row second-sec">
    <div class="col-sm-4 time-index">
        <div>
            <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" [DataCom]="employee" (hierarchy)="changeTableRowColor($event)" (hierarchyActive)="checkValue($event)" [storeItem]="storeRow" [storeColour]="tableRowColor"
                (clearDetails)="addStore()" (tableEmpName)="getTableName($event)" [EmployeeTableData]="EmployeeTableData" [DataStoreId]="storeIdItem" (hierarchyData)="gethierarchyData($event)" (btnActive)="btnActiveStatus($event)">
            </app-fliptable>
        </div>
    </div>
    <div class="col-sm-8">
        <mat-accordion class="dashboard-img">
            <mat-expansion-panel *ngFor="let item of ['Dashboard', 'General']; let i = index" [expanded]="indexExpanded == i" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-expansion-panel-header (click)="expandPanel(item, index)" [ngClass]="{ box_error: isInvalid == true && item == 'General' }">
                    <mat-panel-title (click)="onChangeAccordion($event, index)" [ngClass]="{ title_error: isInvalid == true && item == 'General' }">
                        {{ item }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="item == 'Dashboard'">
                    <img src="assets/components/images/dashboard-graph.png" alt="" class="img-fluid" />
                </div>
                <div *ngIf="item == 'General'">
                    <form class="loginForm GeneralForm" [formGroup]="GeneralStoreForm" (change)="onchange(GeneralStoreForm.value)">
                        <div class="d-flex justify-content-end">
                            <span>Active</span>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" name="customCheckbox" id="2" formControlName="isActive" checked="isActive" />
                                    <label class="custom-control-label" for="2"></label>
                                </div>
                            </div>
                        </div>
                         <!-- for mobile view  -->
                        <div class="row">
                            <div class="store-logo-sec">
                                <div class="d-block d-sm-block d-md-none">
                                    <div class="img-position">
                                        <label>Store Logo</label>
                                        <div class="col-sm-12 mat-elevation-z8 outshadow">
                                            <div class="del-icon-div">
                                                <input type="file" accept=".png, .jpg, .jpeg" id="upload" hidden (change)="onSelectFile($event)" />
                                                <label class="img-del-label img-label-logistics" for="upload">Upload</label>
                                                <div>
                                                    <button (click)="deleteimage()" class="dlt-btn img-label-logistics">
                            <i class="fa fa-trash-o dlt-i" aria-hidden="true"></i>
                          </button>
                                                </div>
                                            </div>
                                            <div class="store-logisticcanvas">
                                                <img class="img-fluid img-size" [src]="imgUrl" *ngIf="format == 'image' && imgUrl" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <!-- for mobile view end -->
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Store Name</label>
                                    <label class="text-danger">*</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="tenantName" placeholder="Store Name" appInputRestriction [ngClass]="{
                      'is-invalid':
                        (submittedGeneral && GenStoreData.tenantName.errors) ||
                        (requiredErrors && GenStoreData.tenantName.errors)
                    }" maxlength="50" (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="StoreNameValid()" />
                                    <div class="error">{{ errorValue }}</div>
                                    <div *ngIf="
                      (submittedGeneral && GenStoreData.tenantName.errors) ||
                      (requiredErrors && GenStoreData.tenantName.errors)
                    " class="invalid-feedback">
                                        <div *ngIf="GenStoreData.tenantName.errors.required">
                                            Store Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Phone</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                        <img src="assets/components/images/USA.png" alt="employee" class="flag_img" />+1
                      </span>
                                        </div>
                                        <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="contactPhone" placeholder="(xxx) xxx-xxxx" maxLength="14" (keypress)="numberOnly2($event)" (keydown)="onDeletePhone($event)" (focusout)="phoneNumberCheck($event)" [ngClass]="{
                        'is-invalid':
                          (GeneralStoreForm.controls['contactPhone'].errors &&
                            submittedGeneral &&
                            !isValidFormSubmitted) ||
                          mgs != ''
                      }" appInputRestriction />
                                    </div>
                                    <div class="error">{{ mgs }}</div>
                                    <div *ngIf="
                      GeneralStoreForm.controls['contactPhone'].errors &&
                      submittedGeneral &&
                      !isValidFormSubmitted
                    " [ngClass]="'error'">
                                        <div *ngIf="
                        GeneralStoreForm.controls['contactPhone'].errors
                          .pattern && mgs == ''
                      ">
                                            Mobile Number not valid.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Contact First Name</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactFirstName" placeholder="Contact First Name" appInputRestriction maxlength="50" (input)="getMaxValueFName($event)" />
                                    <div class="error">{{ errorValue1 }}</div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Contact Last Name</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactLastName" placeholder="Contact Last Name" appInputRestriction maxlength="50" (input)="getMaxValueLName($event)" />
                                    <div class="error">{{ errorValue2 }}</div>
                                </div>
                            </div>
                            <div class="col-sm-12 store-logo-sec">
                                <div class="d-none d-md-block d-lg-block d-xl-block d-sm-none">
                                    <div class="store-logo">
                                        <label>Store Logo</label>
                                        <div class="col-sm-12 mat-elevation-z8 outshadow">
                                            <div class="del-icon-div">
                                                <input type="file" accept=".png, .jpg, .jpeg" id="upload" hidden (change)="onSelectFile($event)" />
                                                <label class="img-del-label img-label-logistics" for="upload">Upload</label>
                                                <div>
                                                    <button (click)="deleteimage()" class="dlt-btn img-label-logistics">
                            <i class="fa fa-trash-o dlt-i" aria-hidden="true"></i>
                          </button>
                                                </div>
                                            </div>
                                            <div class="store-logisticcanvas">
                                                <img class="img-fluid img-size" [src]="imgUrl" *ngIf="format == 'image' && imgUrl" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Contact Email</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactEmail" appInputRestriction placeholder="&#x2709; Example@myemailaddress.com" [ngClass]="{
                      'is-invalid':
                        submittedGeneral && GenStoreData.contactEmail.errors
                    }" maxlength="100" (input)="getMaxValueEmail($event)" />
                                    <div class="error">{{ errorValue3 }}</div>
                                    <div *ngIf="submittedGeneral && GenStoreData.contactEmail.errors" class="invalid-feedback">
                                        <div *ngIf="GenStoreData.contactEmail.errors.email">
                                            Email must be a valid email address
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Address</label>
                                    <input type="text" class="form-control" formControlName="address" placeholder="Address" appInputRestriction />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">City</label>
                                    <input type="text" class="form-control" formControlName="city" placeholder="City" appInputRestriction maxlength="50" (input)="getMaxValueCity($event)" />
                                    <div class="error">{{ errorValue5 }}</div>
                                </div>
                            </div>
                            <div class="col-sm-2 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">State</label>
                                    <div class="StateWrap">
                                        <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" #select1 formControlName="state" (selectionChange)="onchange(GeneralStoreForm.value)" placeholder="Select State">
                                            <input class="form-control input-lg inputbox_login" placeholder="Search here" (keyup)="onKey($event.target.value)" maxlength="50" (input)="getMaxValueTerritory($event)" />
                                            <div class="error">{{ errorValue6 }}</div>
                                            <mat-option *ngFor="let item of filteredList2" [value]="item.id">
                                                {{ item.id }}
                                            </mat-option>
                                            <mat-option *ngIf="filterNodata" [value]="">No data Found</mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0 label-width">Zip Code</label>
                                    <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code" [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" (focusout)="zipCodeCheck($event)" (keydown)="onDeleteZipCode($event)" [ngClass]="{ 'is-invalid': zipCodeMsg != '' }"
                                    />
                                    <div class="error">{{ zipCodeMsg }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Time Zone</label>
                                    <div class="StateWrap">
                                        <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" panelclass="search-bg" #select1 formControlName="timeZone" (selectionChange)="onchange(GeneralStoreForm.value)" placeholder="Select Time Zone">
                                            <input class="form-control input-lg inputbox_login" (keyup)="onTimeZoneKey($event.target.value)" maxlength="100" (input)="getMaxValueTerritory($event)" placeholder="Search here" />
                                            <div class="error">{{ errorValue6 }}</div>
                                            <mat-option *ngFor="let item of filteredTimeZoneList" [value]="item.displayName">
                                                {{ item.displayName }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Live Date</label>
                                    <mat-form-field appearance="fill" class="custom-date">
                                        <input matInput [matDatepicker]="picker" placeholder="MM-DD-YYYY" class="form-control datepicker appInputRestriction" formControlName="liveDate" (click)="picker.open()" (dateChange)="Highlight()" [min]="minDate" />
                                        <mat-datepicker #picker class="example-header"></mat-datepicker>
                                    </mat-form-field>
                                    <div class="error">{{ dateMgs }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-12 store-lbl-pad">
                                <label class="customlable mb-0">SMS Messaging?</label>
                                <div class="checkbox-table accordion-checkbox store-lbl-gap">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" name="smsMessaging" id="smsMessaging" formControlName="smsMessaging" />
                                        <label class="custom-control-label" for="smsMessaging"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12 store-lbl-pad">
                                <label class="customlable mb-0">Offline Transactions?</label>
                                <div class="checkbox-table accordion-checkbox store-lbl-gap">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" name="offlineTransaction" id="offlineTransaction" formControlName="offlineTransaction" />
                                        <label class="custom-control-label" for="offlineTransaction"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12 store-lbl-pad">
                                <label class="customlable mb-0">Swipe Cards?</label>
                                <div class="checkbox-table accordion-checkbox store-lbl-gap">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" name="swipeCards" id="swipeCards" formControlName="swipeCards" />
                                        <label class="custom-control-label" for="swipeCards"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-12 store-lbl-pad">
                                <label class="customlable mb-0">Time Punch Prints?</label>
                                <div class="checkbox-table accordion-checkbox store-lbl-gap">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" name="timePunchPrints" id="timePunchPrints" formControlName="timePunchPrints" />
                                        <label class="custom-control-label" for="timePunchPrints"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12 store-lbl-pad">
                                <label class="customlable mb-0">Allow Future Orders?</label>
                                <div class="checkbox-table accordion-checkbox store-lbl-gap">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" name="allowFutureOrders" id="allowFutureOrders" formControlName="allowFutureOrders" />
                                        <label class="custom-control-label" for="allowFutureOrders"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12 store-lbl-pad">
                                <label class="customlable mb-0">Signature Capture</label>
                                <div class="checkbox-table accordion-checkbox store-lbl-gap">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" name="signatureCapture" id="signatureCapture" formControlName="signatureCapture" />
                                        <label class="custom-control-label" for="signatureCapture"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-12 store-lbl-pad">
                                <label class="customlable mb-0">Email Receipts</label>
                                <div class="checkbox-table accordion-checkbox store-lbl-gap">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" name="emailedReceipts" id="emailedReceipts" formControlName="emailedReceipts" />
                                        <label class="custom-control-label" for="emailedReceipts"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12 store-lbl-pad">
                                <label class="customlable mb-0">Daylight Savings</label>
                                <div class="checkbox-table accordion-checkbox store-lbl-gap">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" name="daylightSavings" id="daylightSavings" formControlName="daylightSavings" />
                                        <label class="custom-control-label" for="daylightSavings"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12 store-lbl-pad">
                                <label class="customlable mb-0">Round Tax</label>
                                <div class="checkbox-table accordion-checkbox store-lbl-gap">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" name="roundTax" id="roundTax" formControlName="roundTax" />
                                        <label class="custom-control-label" for="roundTax"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-md-6 store-lbl-pad">
                                <label class="customlable mb-0 store-lbl-gap">End of Day</label><br />
                                <ngx-mat-timepicker-field [controlOnly]="true" formControlName="eodTime" (timeChanged)="onchange(GeneralStoreForm.value)" class="ngxMatTime">
                                </ngx-mat-timepicker-field>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div id="accordion">
            <!-- <div class="card">
        <div class="card-header outshadow" id="heading-1">
          <h5 class="mb-0">
            <a role="button" data-toggle="collapse" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
              Dashboard
            </a>
          </h5>
        </div>
        <div id="collapse-1" class="collapse" data-parent="#accordion" aria-labelledby="heading-1">
          <div class="card-body" style="padding: 0!important;">
            <img src="assets/components/images/dashboard-graph.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header outshadow" id="heading-2" [ngClass]="{'box_error': (isInvalid==true)}">
          <h5 class="mb-0">
            <a [ngClass]="{'title_error':(isInvalid==true)}" class="collapsed" role="button" data-toggle="collapse"
              href="#collapse-2" aria-expanded="true" aria-controls="collapse-2">
              General
            </a>
          </h5>
        </div>
        <div id="collapse-2" class="collapse show" data-parent="#accordion" aria-labelledby="heading-2">

          <div class="card-body">

            <div class="Gen_wrap">

              <form class="loginForm GeneralForm" [formGroup]="GeneralStoreForm"
                (change)="onchange(GeneralStoreForm.value)">
                <div class="d-flex justify-content-end">
                  <span>Active</span>
                  <div class="checkbox-table accordion-checkbox">
                    <div class="custom-control custom-checkbox custom-control-inline">
                      <input type="checkbox" class="custom-control-input" name="customCheckbox" id="2"
                        formControlName="isActive" checked="isActive">
                      <label class="custom-control-label" for="2"></label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Store Name</label> <label class="text-danger">*</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="tenantName"
                        placeholder="Store Name" appInputRestriction
                        [ngClass]="{ 'is-invalid': (submittedGeneral && GenStoreData.tenantName.errors) || (requiredErrors && GenStoreData.tenantName.errors)}"
                        maxlength="50" (input)="getMaxValue($event)" (keypress)="Highlight()"
                        (focusout)="StoreNameValid()" />
                      <div class="error">{{errorValue}}</div>
                      <div *ngIf="(submittedGeneral && GenStoreData.tenantName.errors)|| (requiredErrors && GenStoreData.tenantName.errors)"
                        class="invalid-feedback">
                        <div *ngIf="GenStoreData.tenantName.errors.required">Store Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Phone</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            <img src="assets/components/images/USA.png" alt="employee" class="flag_img">+1
                          </span>
                        </div>
                        <input type="text" class="form-control" [pattern]="mobNumberPattern"
                          formControlName="contactPhone" placeholder="(xxx) xxx-xxxx" maxLength="14"
                          (keypress)="numberOnly2($event)" (keydown)="onDeletePhone($event)" (focusout)= "phoneNumberCheck($event)" [ngClass]="{ 'is-invalid': GeneralStoreForm.controls['contactPhone'].errors && submittedGeneral && !isValidFormSubmitted || mgs!=''}"
                          appInputRestriction />
                      </div>
                      <div class="error">{{mgs}}</div>
                      <div
                        *ngIf="GeneralStoreForm.controls['contactPhone'].errors && submittedGeneral && !isValidFormSubmitted"
                        [ngClass]="'error'">
                        <div *ngIf="GeneralStoreForm.controls['contactPhone'].errors.pattern && mgs==''">
                          Mobile Number not valid.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Contact First Name</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="contactFirstName"
                        placeholder="Contact First Name" appInputRestriction maxlength="50"
                        (input)="getMaxValueFName($event)" />
                      <div class="error">{{errorValue1}}</div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Contact Last Name</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="contactLastName"
                        placeholder="Contact Last Name" appInputRestriction maxlength="50"
                        (input)="getMaxValueLName($event)" />
                      <div class="error">{{errorValue2}}</div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Contact Email</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="contactEmail"
                        appInputRestriction placeholder="&#x2709; Example@myemailaddress.com"
                        [ngClass]="{ 'is-invalid': (submittedGeneral && GenStoreData.contactEmail.errors)}"
                        maxlength="100" (input)="getMaxValueEmail($event)" />
                      <div class="error">{{errorValue3}}</div>
                      <div *ngIf="submittedGeneral && GenStoreData.contactEmail.errors" class="invalid-feedback">
                        <div *ngIf="GenStoreData.contactEmail.errors.email">Email must be a valid email address</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">Address</label>
                      <input type="text" class="form-control" formControlName="address" placeholder="Address"
                        appInputRestriction />
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">City</label>
                      <input type="text" class="form-control" formControlName="city" placeholder="City"
                        appInputRestriction maxlength="50" (input)="getMaxValueCity($event)" />
                      <div class="error">{{errorValue5}}</div>
                    </div>
                  </div>
                  <div class="col-sm-2 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">State</label>
                      <div class="StateWrap">
                        <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" #select1 formControlName="state"
                          placeholder="Select State">
                          <input class="form-control input-lg inputbox_login" placeholder="Search here" (keyup)="onKey($event.target.value)"
                            maxlength="50" (input)="getMaxValueTerritory($event)" />
                          <div class="error">{{errorValue6}}</div>
                          <mat-option *ngFor="let item of filteredList2" [value]="item.id">
                            {{item.id}}
                          </mat-option>
                          <mat-option *ngIf="filterNodata" [value]="">No data Found</mat-option>

                        </mat-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">Zip Code</label>
                      <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code"
                        [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" (focusout)= "zipCodeCheck($event)" (keydown)="onDeleteZipCode($event)" [ngClass]="{ 'is-invalid': zipCodeMsg!=''}"/>
                      <div class="error">{{zipCodeMsg}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">Time Zone</label>


                        <mat-form-field class="StateWrap">
                          <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" #select1 formControlName="timeZone"
                            placeholder="Select Time Zone">
                            <input class="form-control input-lg inputbox_login" (keyup)="onTimeZoneKey($event.target.value)"
                              maxlength="100" (input)="getMaxValueTerritory($event)" placeholder="Search here"/>
                            <div class="error">{{errorValue6}}</div>
                            <mat-option *ngFor="let item of filteredTimeZoneList" [value]="item.displayName">
                              {{item.displayName}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">Live Date</label>
                      <mat-form-field appearance="fill" class="custom-date">
                        <input matInput [matDatepicker]="picker" placeholder="MM-DD-YYYY"
                          class="form-control datepicker appInputRestriction"  formControlName="liveDate"
                          (click)="picker.open()" (dateChange)="Highlight()" [min]="minDate">
                        <mat-datepicker #picker class="example-header"></mat-datepicker>
                      </mat-form-field>
                        <div class="error">{{dateMgs}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">SMS Messaging?</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="smsMessaging" id="smsMessaging"
                          formControlName="smsMessaging">
                        <label class="custom-control-label" for="smsMessaging"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Offline Transactions?</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="offlineTransaction"
                          id="offlineTransaction" formControlName="offlineTransaction">
                        <label class="custom-control-label" for="offlineTransaction"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Swipe Cards?</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="swipeCards" id="swipeCards"
                          formControlName="swipeCards">
                        <label class="custom-control-label" for="swipeCards"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Time Punch Prints?</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="timePunchPrints" id="timePunchPrints"
                          formControlName="timePunchPrints">
                        <label class="custom-control-label" for="timePunchPrints"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Allow Future Orders?</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="allowFutureOrders"
                          id="allowFutureOrders" formControlName="allowFutureOrders">
                        <label class="custom-control-label" for="allowFutureOrders"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Signature Capture</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="signatureCapture"
                          id="signatureCapture" formControlName="signatureCapture">
                        <label class="custom-control-label" for="signatureCapture"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Email Receipts</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="emailedReceipts" id="emailedReceipts"
                          formControlName="emailedReceipts">
                        <label class="custom-control-label" for="emailedReceipts"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Daylight Savings</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="daylightSavings" id="daylightSavings"
                          formControlName="daylightSavings">
                        <label class="custom-control-label" for="daylightSavings"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Round Tax</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="roundTax" id="roundTax"
                          formControlName="roundTax">
                        <label class="custom-control-label" for="roundTax"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="customlable mb-0">End of Day</label><br>
                    <ngx-mat-timepicker-field [controlOnly]="true" formControlName="eodTime" class="ngxMatTime">
                    </ngx-mat-timepicker-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> -->
        </div>
    </div>
</div>
<!-- ngx-intel -->
<!-- <div>
  <ngx-intl-tel-input [cssClass]="'form-control country-tel-code'" [enableAutoCountrySelect]="true"
    [enablePlaceholder]="true" [preferredCountries]="preferredCountries" [searchCountryFlag]="true"
    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10"
    [tooltipField]="TooltipLabel.Name" [phoneValidation]="false"
    [separateDialCode]="separateDialCode" formControlName="contactPhone"
    >
  </ngx-intl-tel-input>
</div> -->
<!-- [ngClass]="{ 'is-invalid': submittedGeneral && GenStoreData.contactPhone.errors }"/'' -->
<!-- <div *ngIf="submittedGeneral && GenStoreData.contactPhone.errors" class="invalid-feedback">
    <div *ngIf="GeneralStoreForm.controls['contactPhone'].errors">Phone number must be at least 10
      numbers</div>
  </div> -->
<!-- ngx-intl -->
