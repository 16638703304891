import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import { iFrontEndMsg } from '../interfaces/iWebSocket';


@Injectable({
  providedIn: 'root'
})
export class PushSocketsService {

  private hubConnection: HubConnection
 
   public TimeClockMsg: iFrontEndMsg[] = [];
   public connectionId : string;
   loginInfo:any;
 
   //private connectionUrl = 'http://204.236.184.97/Handshake/FlipSocketPushRequest';
   private connectionUrl = 'http://54.219.25.112/Handshake/FlipSocketPushRequest';
 
   

  constructor(private http: HttpClient,private helper: HelperService,
    ) { }


    public connect = () => {
      // this.loginInfo = this.helper.getValue('LoginInfo');
       
       this.startConnection();
       this.addListeners();
     }
     private getConnection(): HubConnection {
       return new HubConnectionBuilder()
         .withUrl(this.connectionUrl)
       //  .withHubProtocol(new MessagePackHubProtocol())  
        //   .configureLogging(LogLevel.Trace)
           //.withAutomaticReconnect([2])
         .build();
     }
   
     private startConnection() {
       this.hubConnection = this.getConnection();
   
       this.hubConnection.start()
         .then(() => console.log('connection started'))
         .then(() => this.getConnectionId())
         .catch((err) => console.log('error while establishing signalr connection: ' + err))
     }
   
     private addListeners() {
       this.loginInfo = this.helper.getValue('LoginInfo');
      
       this.hubConnection.on("TransferData", (data: iFrontEndMsg) => {
        console.log('Send addListeners establishing signalr connection');
       })
   
       
       this.hubConnection.on("BroadcastData", (data: iFrontEndMsg) => {
       
        console.log('addListeners s establishing signalr connection');
       
      })
       this.hubConnection.on("newUserConnected", _ => {
        // this.PunchList=[];
         console.log("new user connected")
       })
     }
       
     public getConnectionId = () => {
       this.hubConnection.invoke('GetConnectionId').then(
         (data) => {
           console.log(data,' :ConnectionID1');
             this.connectionId = data;
           }
       ); 
     }

     public sendMessageToHub(TimeClockMsg) {
      var promise = this.hubConnection.invoke("BroadcastData",TimeClockMsg,this.connectionId)
        .then(() => { console.log('message sent successfully to hub'); })
        .catch((err) => console.log('error while sending a message to hub: ' + err));
  
      return from(promise);
    }

    public addBroadcastListener = () => {
      this.hubConnection.on('TransferData', (data) => {
        console.log('addBroadcastListener message sent successfully to hub'); 
      })
    }
}
