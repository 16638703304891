import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { ModifierService } from 'src/app/services/modifier.service';
import { GetModifier, GetSaleAreaGeneralDD } from 'src/app/interfaces/imenu';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DragDropBuilderComponent } from 'src/app/elements/drag-drop-builder/drag-drop-builder.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-menu-modifier-general',
  templateUrl: './menu-modifier-general.component.html',
  styleUrls: ['./menu-modifier-general.component.css']
})
export class MenuModifierGeneralComponent implements OnInit {
  buttonDisable: boolean = false;
  isSubmitted = false;
  isValidated = false;
  submittedGeneral: any;
  loginInfo: any;
  menuId: any;
  modifyid: any = 0;
  Category: any;
  ModifierGeneralForm: FormGroup;
  public displayedColumns = [];
  public dataSource = new MatTableDataSource<any>();
  generalDataemit: any;
  @Output() public generalItem = new EventEmitter();
  prevMinVal: any;
  errorMessage: any;
  errorMessage1: any;
  minValue: any;
  maxValue: any;
  errorValue: any;
  //@Input() public buttonState;
  requiredError: boolean = false;
  @Output() public ModifierData_child = new EventEmitter();
  @Output() public isInvalid = new EventEmitter();

  public modifierGenData: any;
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }
  constructor(public formBuilder: FormBuilder, private helper: HelperService,
    private objmodifier: ModifierService, private dialogser: DialogService, private toaster: ToasterService) { }

  @ViewChild(MenuModifierGeneralComponent) MenuItemGenComp: MenuModifierGeneralComponent;
  @ViewChild(DragDropBuilderComponent) DragDropComp: DragDropBuilderComponent;
  //@ViewChild(MenuModifierComponent) ModifierChild:MenuModifierComponent;
  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.ModifierGeneralForm = this.formBuilder.group({
      name: [null, Validators.required],
      Min_Choices: [null],
      Max_Choices: [null],
      FreeChoices: [null],
      IsAllow_SameItem: [false],
      Category: [null, Validators.required]
    })
    this.menuId = 0;
    // this.ModifierGeneralForm.setValidators(this.minMaxValidator());
    this.getCategory();
    //this.GetSaveAPI();
  }

  get SavegeneralGroup() { return this.ModifierGeneralForm.controls; }

  tenantId: any;
  areaValue = [];
  columns = [];

  @Input()
  public set MenuRowItem(tableRowData) {
    this.errorValue = "";
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      this.menuId = tableRowData;
      this.GetModifierGeneralEditList(this.menuId);
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  checkInput(e, input) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];

    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue)  && e.keyCode != 9) {
      e.preventDefault();
      return;
    }

    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if ((+newValue > 100 || newValue.length > 2)  && e.keyCode != 9) {
      e.preventDefault();
    }
  }

  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }

  onChangeMinEvent(event: any) {
    if (event.target.value == '' || event.target.value == null || event.target.value == undefined || event.target.value == NaN) {
      this.errorMessage = 'Please enter the minimum choice.';
      this.isInvalid.emit(true);
      this.submittedGeneral = false;
    }
    else {
      this.minValue = parseInt(event.target.value)
      if (this.minValue > parseInt(this.SavegeneralGroup.Max_Choices.value)) {
        this.errorMessage = 'Min value should be lesser than Max value';
        this.submittedGeneral = false;
      }
      else {
        this.submittedGeneral = true;
        this.errorMessage1 = '';
        this.errorMessage = '';
      }
    }
  }

  onChangeMaxEvent(event: any) {
    if (this.maxValue == '' || this.maxValue == null || this.maxValue == undefined || this.maxValue == NaN) {
      this.errorMessage1 = 'Max value should be greater than Min value';
      this.isInvalid.emit(true);
      this.submittedGeneral = false;
    }
    this.maxValue = parseInt(event.target.value)
    if (this.maxValue < this.minValue || event.target.value == '') {
      this.errorMessage1 = 'Max value should be greater than Min value';
      this.isInvalid.emit(true);
      this.submittedGeneral = false;
    }
    else {
      this.errorMessage = '';
      this.errorMessage1 = ''
      this.submittedGeneral = true;
      this.isInvalid.emit(false);

    }
  }

  getCategory() {
    const menuItemList: GetSaleAreaGeneralDD = {
      parentCode: "MC1",
    }
    this.objmodifier.ModifierGroupGetCategory(menuItemList)
      .subscribe((response) => {
        this.Category = response;
      })
  }

  GetModifierGeneralEditList(ModifierIDVal) {
    const ItemList: GetModifier = {
      id: ModifierIDVal,
      tenantId: this.loginInfo.tenantId
    }
    this.objmodifier.ModifierGroupEdit(ItemList)
      .subscribe(res => {
        this.ModifierGeneralForm.patchValue(res[0]);
        this.modifierGenData = res[0];
        if (res[0].MenuGroupDetail != null && res[0].MenuGroupDetail != '') {
          let menuGroupList = JSON.parse(res[0].MenuGroupDetail);
          this.helper.setValue(ModifierIDVal + "_Menugroup", JSON.stringify({ menugroup: menuGroupList }));
        }
      })
  }

  async OnSaveModifierGroup() {
    this.errorValue = "";
    this.submittedGeneral = true;
    this.isValidated = true;
    this.isSubmitted = true;

    if (this.ModifierGeneralForm.controls['Min_Choices'].value == null || this.ModifierGeneralForm.controls['Min_Choices'].value == undefined || this.ModifierGeneralForm.controls['Min_Choices'].value == NaN || this.ModifierGeneralForm.controls['Min_Choices'].value == '') {
      this.isInvalid.emit(true);
      this.errorMessage = 'Please enter the minimum choice.';
      return;
    }
    if (this.ModifierGeneralForm.invalid) {
      this.isInvalid.emit(true);
      return;
    } else {
      const min = parseInt(this.ModifierGeneralForm.controls['Min_Choices'].value == null ? 0 : this.ModifierGeneralForm.controls['Min_Choices'].value);
      const max = parseInt(this.ModifierGeneralForm.controls['Max_Choices'].value == null ? 0 : this.ModifierGeneralForm.controls['Max_Choices'].value);

      if (max == null || max == undefined || max == NaN) {
        this.isInvalid.emit(true);
        this.errorMessage1 = 'Max value should be greater than Min value';
        return;
      }
      if (max < min) {
        this.isInvalid.emit(true);
        this.errorMessage1 = 'Max value should be greater than Min value';
        return;
      }
      else {
        this.errorMessage = '';
        this.errorMessage1 = '';
        this.isInvalid.emit(false);

      }

      // if (min > max) {
      //   return
      // }

      // if (max < min) {
      //   return
      // }
    }
    let modifiergroup;
    modifiergroup = this.ModifierGeneralForm.value;
    if (this.menuId != undefined && this.menuId != null && this.menuId != "") {
      modifiergroup.id = this.menuId;
      this.modifyid = this.menuId;
    }
    else {
      modifiergroup.id = 0;
    }
    modifiergroup.tenantId = this.loginInfo.tenantId;
    modifiergroup.createdBy = this.loginInfo.userId;
    modifiergroup.Max_Choices=this.ModifierGeneralForm.controls['Max_Choices'].value == ''?null:this.ModifierGeneralForm.controls['Max_Choices'].value ;
    this.ModifierGeneralForm.value.General = this.menuId > 0 ? this.menuId : this.generalDataemit;

    // var strVal = JSON.stringify(this.modifierGenData.Item_Object);

    if (modifiergroup.id != 0) {
      this.objmodifier.ModifierGeneralSave(modifiergroup).subscribe(res => {
        this.isSubmitted = false;
        this.submittedGeneral = false;
        this.helper.setValue('Sessionid_help', res.id);
        this.helper.setValue('GetSessionData1', modifiergroup);
        this.menuId = res.id;
        this.generalItem.emit({ res: res, name: modifiergroup.name });
        //  this.toaster.successPopUp(res.message);
      });
    }
    else if (modifiergroup.id == 0) {
      this.objmodifier.ModifierGeneralSave(modifiergroup).subscribe(res => {
        this.isSubmitted = false;
        this.submittedGeneral = false;
        this.helper.setValue('Sessionid_help', res.id);
        this.helper.setValue('GetSessionData1', modifiergroup);
        this.menuId = res.id;
        this.generalItem.emit({ res: res, name: modifiergroup.name });
        this.ModifierData_child.emit({ data: modifiergroup, event: "MenuModifierPage", val: false });
        //   this.toaster.successPopUp(res.message);
      });
    }
  }

  OnConfirm(id) {
    let modifiergroup;
    modifiergroup = this.ModifierGeneralForm.value;
    modifiergroup.id = id;
    modifiergroup.isReset = true;
    //console.log(modifiergroup, 'Modifier grp')
    this.objmodifier.ModifierGeneralSave(modifiergroup).subscribe(res => {
      // this.GetModifierGeneralEditList(id);
      this.helper.setValue('Sessionid_help', res.id)
      // this.toaster.successPopUp(res.message);
    });
    // this.ModifierChild.ItemObj(id);
    this.DragDropComp.targetBuilderTools = null;
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  MenuModifierValid() {

    if (this.ModifierGeneralForm.invalid) {
      this.requiredError = true;
      this.isInvalid.emit(true);
      return;
    }
    else {
      this.isInvalid.emit(false);
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.ModifierGeneralForm.invalid) {
      this.isInvalid.emit(false);
    }
    else if (this.ModifierGeneralForm.controls['Min_Choices'].value != null || this.ModifierGeneralForm.controls['Min_Choices'].value != undefined || this.ModifierGeneralForm.controls['Min_Choices'].value != NaN || this.ModifierGeneralForm.controls['Min_Choices'].value != '') {
      this.isInvalid.emit(false);
    }
  }

  checkVal(event) {
    if ((event != null || event != "") && (this.ModifierGeneralForm.value.name != null && this.ModifierGeneralForm.value.name != "")) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.ModifierGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.ModifierData_child.emit(this.ModifierGeneralForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.ModifierData_child.emit({ data: data, event: "MenuModifierPage", val: true });
  }
}
