import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { IBindReportFilter, iSalesDayPartReport } from 'src/app/interfaces/i-report';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class ReportFilterComponent implements OnInit {
  filterForm: FormGroup
  submittedGeneral: any;
  ItemTags: any = [];
  EmployeeList: any = [];
  SalesArea: any = [];
  loginInfo: any;
  salestaxSettings: IDropdownSettings = {};
  AreasFilters = [];
  AreasAreaObj: FormControl = new FormControl('');

  tagFilters = [];
  tagAreaObj: FormControl = new FormControl('');

  approverFilters = [];
  approverObj: FormControl = new FormControl('');

  employeFilters = [];
  employeAreaObj: FormControl = new FormControl('');

  employeeArr: any;
  employeeselectedItems = [];
  areaArr: any;
  areaselectedItems = [];
  tagArr: any;
  tagselectedItems = [];
  approverSelectedItems = []
  @Input() auditShow
  @Input() dropdown
  @Input() discountsItem
  @Input() voidsReport
  disableInput: boolean = false;
  disableInput1: boolean = false;


  @Output() public ReceivedData = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private reportService: ReportWebSocketService) { }

  ngOnInit(): void {
   // console.log(this.auditShow,"ReportFilterComponentStart......");
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.filterForm = this.formBuilder.group({
      fromDate: new FormControl(new Date()),
      toDate: new FormControl(new Date()),
     
    });
    this.salestaxSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 1,
      selectAllText: 'Select All',
      unSelectAllText: 'Select All'
    };
    this.bindFilterFields();
    //this.getFirstTable();
  }

  get f() {
    return this.filterForm.controls;
  }

  setForms() {
    this.filterForm = this.formBuilder.group({
      fromDate: new FormControl(new Date()),
      toDate: new FormControl(new Date()),
      tagId: [null],
      employeeId: [null],
      salesarea: [null],
      salestaxSettings: [null], 
      AreasFilters: [null],
      areasselectedItems: [null],
      AreasAreaObj: new FormControl(this.AreasFilters),
      tagFilters: [null],
      tagselectedItems: [null],
      tagAreaObj:  new FormControl(this.tagFilters),
      employeFilters: [null],
      employeselectedItems: [null],
      employeAreaObj:  new FormControl(this.employeFilters),
      approverSelectedItems:[null],
      approverFilters:[null],
      approverObj:[null]
    });
  }

  bindFilterFields() {
    const itemFilter: IBindReportFilter = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.reportService.BindFiltersFields(itemFilter).subscribe((response) => {
      this.EmployeeList = response.Employee;
      this.ItemTags = response.TagItems;
      this.SalesArea = response.SalesArea;

      //----------------BINDING MULTI SELECT----------------------

      var itemSales
      var jsonToBeUsedSales = [];
      for (var salestype in this.SalesArea) {
        itemSales = {};
        itemSales.key = this.SalesArea[salestype].id.toString();
        itemSales.value = this.SalesArea[salestype].name;
        jsonToBeUsedSales.push(itemSales);
      }
      this.AreasFilters = jsonToBeUsedSales;


      var itemItmTag
      var jsonToBeUsedItmTag = [];
      for (var itmtagtype in this.ItemTags) {
        itemItmTag = {};
        itemItmTag.key = this.ItemTags[itmtagtype].id.toString();
        itemItmTag.value = this.ItemTags[itmtagtype].name;
        jsonToBeUsedItmTag.push(itemItmTag);
      }
      this.tagFilters = jsonToBeUsedItmTag;

      var itemItmApprover
      var jsonToBeUsedItmApprover = [];
      for (var itmtagtype in this.EmployeeList) {
        itemItmApprover = {};
        itemItmApprover.key = this.EmployeeList[itmtagtype].id.toString();
        itemItmApprover.value = this.EmployeeList[itmtagtype].name;
        jsonToBeUsedItmApprover.push(itemItmApprover);
      }
      this.approverFilters = jsonToBeUsedItmApprover;


      var itemEmp
      var jsonToBeUsedEmp = [];
      for (var emptype in this.EmployeeList) {
        itemEmp = {};
        itemEmp.key = this.EmployeeList[emptype].id.toString();
        itemEmp.value = this.EmployeeList[emptype].name;
        jsonToBeUsedEmp.push(itemEmp);
      }
      this.employeFilters = jsonToBeUsedEmp;
      this.setForms();
      this.onSubmit();
    })
  }

  getFirstTable() {
    
    const getdata: iSalesDayPartReport = {
      employee: "string",
      salesArea: "string",
      reportCategory: "string",
      fromDate: null,
      toDate: null,
      tenantId: this.loginInfo.tenantId
    };

    this.ReceivedData.emit(getdata);
    //console.log("getFirstTable_ReceivedData", getdata);
  }

  onSubmit() {
    this.submittedGeneral = true;
    if (this.filterForm.invalid) {
      return;
    }
    this.areaArr = this.filterForm.value.AreasAreaObj.map(i => i.key);
    this.tagArr = this.filterForm.value.tagAreaObj.map(i => i.key);
    this.employeeArr = this.filterForm.value.employeAreaObj.map(i => i.key);

    const itemFilter: iSalesDayPartReport = {
      employee: this.employeeArr.toString() == "" ? null : this.employeeArr.toString(),
      salesArea: this.areaArr.toString() == "" ? null : this.areaArr.toString(),
      reportCategory: this.tagArr.toString() == "" ? null : this.tagArr.toString(),
      fromDate: moment(new Date(this.filterForm.value.fromDate)).format('YYYY-MM-DD'), 
      toDate:moment(new Date(this.filterForm.value.toDate)).format('YYYY-MM-DD') ,
      tenantId: this.loginInfo.tenantId
    };

    this.ReceivedData.emit(itemFilter);
  }

  OnselectApr(item: any) {
    
    if(item.value != ""){
      if(this.approverSelectedItems.length > 0){
        this.disableInput = true;
        this.employeeselectedItems = [];
      }else{
        this.disableInput = false;
      }
    }
  }

  OnselectEmp(item: any) {
    if(item.value != ""){
      if(this.employeeselectedItems.length > 0){
        this.disableInput1 = true;
        this.approverSelectedItems = []
      }else{
        this.disableInput1 = false;
      }
    }
  }

  OnselectAllEmp(item: any){
      if(item.length > 0){
        this.disableInput1 = true;
        this.approverSelectedItems = []
      }else{
        this.disableInput = false;
        this.disableInput1 = false;
      }
    }
}