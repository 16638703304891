
<div class="time-body">
    <div class="loginForm form-switch table_forms">
        <app-report-filter (ReceivedData)="FinalReceivedData($event)"></app-report-filter>
        <div class="footer-table Communication_tab mb-2">
                <div class="d-flex no-gutter mb-0 right_image">
                    <div class="salesreconciliation"><img src="assets/components/images/gen-icon-export.png" alt="sales"
                            class="img-fluid cash_icon"></div>
                    <div class="salesreconciliation"><img src="assets/components/images/gen-icon-dashboard.png"
                            alt="sales" class="img-fluid cash_icon_w90"></div>
                </div>
            <mat-tab-group dynamicHeight mat-align-tabs="center">
                <mat-tab label="SUMMARY">
                    <div class="example-small-box mat-elevation-z4">
                        <app-fliptable-center-footer [dataSource]="dataSource1" [displayedColumns]="displayedColumns1"
                    [columns]="columns1" [firstTableShow]="firstTableShow" [nofooter]="noSales">
                </app-fliptable-center-footer>
                    </div>
                </mat-tab>
                <mat-tab label="DETAILS">
                    <div class="example-large-box mat-elevation-z4">
                        <!-- <app-fliptable-center-footer [dataSource]="dataSource2" [displayedColumns]="displayedColumns2"
                    [columns]="columns2" [secondTableShow]="secondTableShow"  [nofooter]="noSales" (tableItem)="onChangeRow($event)">
                </app-fliptable-center-footer> -->
                <app-table-collapse [dataSource]="dataSource2" [displayedColumns]="displayedColumns2"
                     [columns]="columns2" [_alldata]="alldata2" [groupByColumns]="groupByColumns2" [noReportshow]="noReportshow"></app-table-collapse>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>