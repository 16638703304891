<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="addEmployee()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}"
                class="btn  btn-sm float-right submit_button global_submit" type="submit"
                [disabled]="buttonStatus ?  true : null" (click)="onSave();">
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label></button>
        </div>
    </div>
</div>
<div class="row second-sec adjustment-sec">
    <div class="col-sm-4">
        <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns"
            [dealerItem]="tableRowColor" (tableItemId)="getTableIdx($event)" (tableEmpName)="getTableName($event)"
            [employeeItem]="tableRowColor" (breakActive)="checkValue($event)" [EmployeeTableData]="AdjustmentTableData"
            [DataCom]="employee" (clearDetails)="addEmployee($event)" (btnActive)="btnActiveStatus($event)">
        </app-fliptable>
    </div>
    <div class="col-sm-8">
        <!-- <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global_submit" type="submit" 
            [disabled]="buttonStatus ?  true : null" (click)="onSave();">
              <img src="assets/components/images/save.png" alt="employee" class="">
              <label class="label_config">Save</label></button>
          </div> -->
        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of ['General','Details']; let i = index"
            [expanded]="indexExpanded == i" class="outshadow">
                <mat-expansion-panel-header (click)="expandPanel(item,index)" [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                    <mat-panel-title (click)="onChangeAccordion($event,index)" [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General'">
                    <app-adjustment-general [DataIdx]="tableRowId" (generalAdjust)="getGeneralDetail($event)"
                        [checkTarget]="activeDeactiveValue" [buttonState]="buttonDisable"
                        (isInvalid)="isInvalidAccordion($event)" (adjgenData_child)="getadjsessData($event)">
                    </app-adjustment-general>
                </div>
                <div *ngIf="item == 'Details'">
                    <app-adjustment-details [DataIdx]="tableRowId" (generalAdjust)="getGeneralDetail($event)"
                        [buttonState]="buttonDisable" (adjdetData_child)="getadjsessData($event)"></app-adjustment-details>
                </div>
                <!-- <div *ngIf="item == 'Schedule'">
                    <app-scheduling-widget></app-scheduling-widget>
                </div> -->
            </mat-expansion-panel>
        </mat-accordion>
        <div class="form-group">
            <button class="btn  btn-sm float-right submit_button button-none" type="submit"
                [disabled]="buttonStatus ?  true : null" (click)="onSave();">
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label></button>
        </div>
    </div>
</div>