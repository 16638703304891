<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{receipt}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="ClearData()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="OnSave();">
        <!-- [disabled]="buttonState ? true : null" -->
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label>
      </button>
        </div>
    </div>
</div>
<div class="row second-sec">
    <div class="col-sm-4">
        <div>
            <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" [dealerItem]="tableRowColor" (tableItemId)="getTableIdx($event)" (tableEmpName)="getTableName($event)" [employeeItem]="tableRowColor" (breakActive)="checkvaluePage($event)"
                (clearDetails)="ClearData($event)" [EmployeeTableData]="TabletData" [DataCom]="receipt" (btnActive)="btnActiveStatus($event)"></app-fliptable>
        </div>
    </div>
    <div class="col-sm-8 max-accordian">
        <!-- <div class="row">
        <div class="col-sm-6">
          <app-title-card [Dataname]="employeeName" [DataCom]="employee" [DataIcon]="employeeIcon"></app-title-card>
        </div>
      </div> -->
        <!-- <div class="form-group global-form">
        <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="OnSave();" >

            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label>
        </button>
      </div> -->
        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst" [expanded]="isFirst" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-expansion-panel-header [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                    <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}} </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General'">
                    <app-receipt-config-general class="receipt-resonsive" (isInvalid)="isInvalidAccordion($event)" [buttonState]="buttonDisable" [DataIdx]="tableRowId" (GeneralDetailReceipt)="getGeneralDetail($event)" (receiptData_child)="getreceiptsessData($event)" (matopen)="matstate($event)"></app-receipt-config-general>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
