import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { EmployeeService } from 'src/app/services/employee.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetEmployee } from 'src/app/interfaces/iemployee';
import { HelperService } from 'src/app/helper/helper.service';
import { IempId, IActiveUser, ISelectState } from 'src/app/interfaces/iadmin';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { AdminService } from 'src/app/services/admin.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TraceLoggerService } from 'src/app/services/trace-logger.service';
import { DialogService } from 'src/app/services/dialog.service';
import { PermissionService } from 'src/app/services/permission.service';

//---------------------------------Calendar Format Begin---------------------------------//
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
//---------------------------------Calendar Format End---------------------------------//


@Component({
  selector: 'app-employee-general',
  templateUrl: './employee-general.component.html',
  styleUrls: ['./employee-general.component.css'],
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true },
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class EmployeeGeneralComponent implements OnInit {
  @Output() public GeneralIDEmit = new EventEmitter();
  submittedGeneral = false;
  GeneralForm: FormGroup;
  loginInfo: any;
  mobNumberPattern = "^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$";
  isValidFormSubmitted = false;
  employeeName: any = "Employee Name";
  RolePermissionList: any = [];
  generalValue: any;
  GeneralPatchValue: any;
  backofficeAccess: any;
  target: boolean;
  errorMessage: any;
  zipCodeMsg: any = '';
  passwordIsValid = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  conuter = 0;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  // public variables = ['One','Two','County', 'Three', 'Zebra', 'XiOn'];
  // public filteredList2 = this.variables.slice();
  filteredList2: any = []
  public variables: any = []
  buttonDisable: boolean = false;
  @Output() public generalItem = new EventEmitter();
  @Output() public RestIdEmit = new EventEmitter();
  @Input() public tableRowId;
  @Input() public currentTarget;
  // @Input() public buttonState;
  @Input() public companyHierarchyHide;
  @Input() public dealerHierarchyHide;
  mgs: any = '';
  filterNodata: any
  maxDate = moment(new Date());
  requiredError: boolean = false; //FocusOut Accordion Invaild Color//
  @Output() public isInvalid = new EventEmitter(); //Accordion Invaild Color//
  @Output() public StoreempData_child = new EventEmitter();
  dateMgs: any;
  dateMgsHire: any;
  minDate: any;
  errorValue: any; //First Name MAX CHARACTER LIMIT//
  errorValue1: any; //Last Name MAX CHARACTER LIMIT//
  errorValue2: any; //POS Name MAX CHARACTER LIMIT//
  errorValue3: any; //Address MAX CHARACTER LIMIT//
  errorValue4: any; //Email MAX CHARACTER LIMIT//
  readOnlyValue: any;
  public isActiveInactivePerm: boolean;
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private employeeDetails: EmployeeService, private toaster: ToasterService,
    private formBuilder: FormBuilder, private helper: HelperService, private admin: AdminService,
    private LoggerService: TraceLoggerService, private dialogser: DialogService,
    private permser: PermissionService) { }

  ngOnInit(): void {
    this.isActiveInactivePerm = this.permser.isActiveInactive;

    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.minDate = new Date(1900, 0, 1);
    this.GeneralForm = this.formBuilder.group({
      userId: [null],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      posName: [null],
      loginId: [null, Validators.required],
      password: [null],
      phone: [null],
      //emailId: [null, [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]{2,4}$')]],
      emailId: [null, Validators.email],
      address: [null],
      city: [null],
      state: [null],
      zipCode: [null],
      // employeeHireDate: [moment(new Date()), Validators.required],
      employeeHireDate: [moment(new Date()), Validators.required],
      employeeBirthday: [null],
      isActive: [true],
      backofficeAccess: [false],
    });
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.GeneralPatchValue = this.helper.getValue('idx');
    this.onSelectState()
    if (this.dealerHierarchyHide == true || this.companyHierarchyHide == true) {
      this.GeneralForm.controls.employeeHireDate.clearValidators()
      this.GeneralForm.controls.employeeHireDate.updateValueAndValidity()
    }
  }

  get GeneralData() { return this.GeneralForm.controls; }

  @Input()
  public set buttonState(btnst) {
    this.buttonDisable = !btnst;
  }

  onKeypressEvent(elem): boolean {
    const charCode = (elem.which) ? elem.which : elem.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (elem.target.value.length < 3 || (elem.target.value.length > 3 || elem.target.value.length < 11)) {
      if (elem.key === "-") elem.preventDefault();
    }
    // this.GeneralCompanyForm.get("zipCode").patchValue(elem.target.value.toUpperCase());
    if (elem.target.value.length <= 10) {
      if (elem.target.value.length === 5) {
        elem.target.value = `${elem.target.value}-`;
      }
      this.zipCodeMsg = ''
    } else {
      elem.target.value = elem.target.value.slice(0, -1);
      this.zipCodeMsg = ''
    }
    return true;
  };

  togglebackoffice(e) {
    if (e == false) {
      this.GeneralForm.get('emailId').clearValidators();
      this.GeneralForm.get('emailId').updateValueAndValidity({ onlySelf: true });
      this.GeneralForm.get('password').clearValidators();
      this.GeneralForm.get('password').updateValueAndValidity({ onlySelf: true });
      this.GeneralForm.get('password').reset();
    } else {
      this.GeneralForm.controls['emailId'].setValidators([Validators.required, Validators.email]);
      this.GeneralForm.controls['emailId'].updateValueAndValidity({ onlySelf: true });
      this.GeneralForm.controls['password'].setValidators([Validators.required]);
      this.GeneralForm.controls['password'].updateValueAndValidity({ onlySelf: true });
    }
    if (e == true && this.requiredError == true && this.GeneralForm.invalid) {
      this.submittedGeneral = true;
      this.isInvalid.emit(true);
      return;
    }
    else {
      if (this.GeneralForm.invalid) {
        this.submittedGeneral = true;
        this.isInvalid.emit(true);
        return;
      }
      else {
        this.isInvalid.emit(false);
        this.submittedGeneral = false;
      }
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    else if (charCode < 58 || charCode > 64) {
      if (charCode == 46) {
        return false;
      }
      return true;

    }

    else if (charCode < 106 || charCode > 111) {
      return true;
    }
    else if (charCode < 160 || charCode > 226) {
      return false;
    }
    return true;
  }

  @Input()
  public set DataIdx(tId) {
    this.errorValue = ""; //First Name MAX CHARACTER LIMIT//
    this.errorValue1 = ""; //Last Name MAX CHARACTER LIMIT//
    this.errorValue2 = ""; //POS Name MAX CHARACTER LIMIT//
    this.errorValue3 = ""; //Address MAX CHARACTER LIMIT//
    this.errorValue4 = ""; //Email MAX CHARACTER LIMIT//
    this.tableRowId = tId;
    if (this.tableRowId != undefined && this.tableRowId != null && this.tableRowId != "") {
      this.getEmployee(this.tableRowId);
    }

  }

  @Input()
  public set empPosId(eId) {
    if (eId != undefined && eId != null && eId != "") {
      this.getEmployee(eId);
    }

  }

  @Input()
  public set isReadonly(readVal){
    this.readOnlyValue = readVal;
  }

  @Input()
  public set checkTarget(currentTarget) {
    this.currentTarget = currentTarget;
    if (this.currentTarget != undefined && this.currentTarget != null && this.currentTarget != "") {
      this.checkValue(this.currentTarget)
    }
  }

  async onSaveCompleteForm(employee, sectionId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (sectionId == 1) {

        employee.sectionId = sectionId;
        employee.tenantId = this.loginInfo.tenantId;
        employee.createdby = this.loginInfo.userId;
        employee.isemployee = true;
        employee.userImg = null;

        if (this.GeneralData.employeeBirthday.value == "" || this.GeneralData.employeeBirthday.value == null) {
          employee.employeeBirthday = null;
        }

        const objphone = employee.phone;
        if (objphone != undefined && objphone != "" && objphone != null) {
          let trimphone = objphone.toString().match(/\d/g);
          //const isAllZero1 = trimphone.some(item => item !== "0");
          const isAllZero = trimphone.every((val, i, arr) => val === "0");
          if (parseInt(trimphone.length) != 10) {
            this.toaster.errorPopUp('Phone number must be at least 10 digit');
            return;
          }
          else if (isAllZero == true) {
            this.toaster.errorPopUp('Invalid Mobile Number');
            return;
          }
          else {
            employee.phone = objphone.replace(/[^-()\d ]/g, '');
          }
        }
      }

      this.employeeDetails.UserEmployee(employee).subscribe(async response => {

        if (sectionId == 1) {
          this.generalValue = response;
          if (response.id == 0) {
            this.toaster.errorPopUp(response.message)
            return;
          } else {
            this.GeneralForm.patchValue({
              userId: response.id
            });

            this.helper.setValue('GetSessionData1', employee);
            await this.dialogser.ClearSession();
            this.StoreempData_child.emit({ data: employee, event: "GeneralPage", val: false });
            //this.toaster.successPopUp(response.message)
          }
          //this.GeneralForm.patchValue({ isActive: true });  //This is commented because After Uncheck and save its getting check
          this.onGeneralData();
          this.submittedGeneral = false;
        }
        this.generalItem.emit({ res: this.generalValue.id, name: employee.firstName });
        resolve({ res: response, name: employee.firstName + ' ' + employee.lastName });
      });
    })
  }

  onGeneralData() {
    const parentValue: IempId = {
      id: 0,
    }
    this.employeeDetails.checkIdvalue(parentValue);
  }

  //---------------------------------Save---------------------------------//
  async onSaveGeneral() {
    this.errorValue = ""; //First Name MAX CHARACTER LIMIT//
    this.errorValue1 = ""; //Last Name MAX CHARACTER LIMIT//
    this.errorValue2 = ""; //POS Name MAX CHARACTER LIMIT//
    this.errorValue3 = ""; //Address MAX CHARACTER LIMIT//
    this.errorValue4 = ""; //Email MAX CHARACTER LIMIT//
    this.submittedGeneral = true;

    if (this.GeneralForm.value.employeeBirthday == "" || this.GeneralForm.value.employeeBirthday == null) {
      this.GeneralForm.value.employeeBirthday = null
    }
    else {
      this.GeneralForm.value.employeeBirthday = moment(new Date(this.GeneralForm.value.employeeBirthday)).format('YYYY-MM-DD');
    }

    if (this.GeneralForm.value.employeeHireDate == "" || this.GeneralForm.value.employeeHireDate == null) {
      this.GeneralForm.value.employeeHireDate = null
    }
    else {
      this.GeneralForm.value.employeeHireDate = moment(new Date(this.GeneralForm.value.employeeHireDate)).format('YYYY-MM-DD');
    }
    if (this.GeneralForm.invalid) {
      this.isInvalid.emit(true);
      if (this.GeneralForm.controls.employeeBirthday.invalid) {
        this.dateMgs = 'Invalid Date';
        return;
      }
      else if (this.GeneralForm.controls.employeeHireDate.invalid) {
        this.dateMgsHire = 'Invalid Date';
        return;
      }
      else {
        this.dateMgs = ''
        this.dateMgsHire = ''
      }
      return;
    } else {
      const HireDate = moment(new Date(this.GeneralData.employeeHireDate.value), 'DD-MM-YYYY').format('YYYY-MM-DD');
      const Birthday = moment(new Date(this.GeneralData.employeeBirthday.value), 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (this.GeneralData.employeeBirthday.value != "" && this.GeneralData.employeeBirthday.value != null && this.GeneralData.employeeBirthday.value != undefined) {
        if (Birthday > HireDate) {
          this.errorMessage = 'Date of Birth should be lesser than Hire date';
          this.isInvalid.emit(true);
          this.requiredError = true;
          return
        } else {
          this.errorMessage = '';
        }
      }
      if (this.GeneralData.phone.value != undefined && this.GeneralData.phone.value != null && this.GeneralData.phone.value != "") {
        if (this.GeneralData.phone.value.length <= 12) {
          this.mgs = 'Phone number must be at least 10 digit';
          this.isInvalid.emit(true);
          this.requiredError = true;
          return
        } else {
          this.mgs = ''
        }
      } else {
        this.mgs = ''

      }
    }
    if (this.GeneralForm.controls.zipCode.value != undefined && this.GeneralForm.controls.zipCode.value != null && this.GeneralForm.controls.zipCode.value != "") {
      if (this.GeneralForm.controls.zipCode.value.length <= 4) {
        this.zipCodeMsg = 'Please enter min 5 digits zipcode';
        this.isInvalid.emit(true);
        this.requiredError = true;
        return
      } else {
        this.zipCodeMsg = ''
      }
    } else {
      this.zipCodeMsg = ''
    }
    // this.LoggerService.SaveLoggerInformation(this.loginInfo.tenantId, 'Info', 'emp general', JSON.stringify(this.GeneralForm.value), this.loginInfo.userId)

    let res = await this.onSaveCompleteForm(this.GeneralForm.value, 1);
    return res;
  }


  //--------------------------------- On Save check first Accordion VALIDATION Starts--------------------------------- //
  GenValidation() {
    this.submittedGeneral = true;
    if (this.GeneralForm.invalid) {
      this.isInvalid.emit(true);
      return;
    } else {
      const HireDate = moment(new Date(this.GeneralData.employeeHireDate.value)).format('YYYY-MM-DD');
      const Birthday = moment(new Date(this.GeneralData.employeeBirthday.value)).format('YYYY-MM-DD');
      if (this.GeneralData.employeeBirthday.value != "" && this.GeneralData.employeeBirthday.value != null && this.GeneralData.employeeBirthday.value != undefined) {
        if (Birthday > HireDate) {
          this.errorMessage = 'Date of Birth should be lesser than Hire date';
          return
        } else {
          this.errorMessage = '';
        }
      }
      if (this.GeneralData.phone.value != undefined && this.GeneralData.phone.value != null && this.GeneralData.phone.value != "") {
        if (this.GeneralData.phone.value.length <= 12) {
          this.mgs = 'Phone number must be at least 10 digit'
          return
        } else {
          this.mgs = ''
        }
      } else {
        this.mgs = ''

      }
    }
    if (this.GeneralForm.controls.zipCode.value != undefined && this.GeneralForm.controls.zipCode.value != null && this.GeneralForm.controls.zipCode.value != "") {
      if (this.GeneralForm.controls.zipCode.value.length <= 4) {
        this.zipCodeMsg = 'Please enter min 5 digits zipcode'
        return
      } else {
        this.zipCodeMsg = ''
      }
    } else {
      this.zipCodeMsg = ''
    }
  }
  //--------------------------------- FOn Save check first Accordion VALIDATION Ends--------------------------------- //

  checkValue(e?: any) {
    let currentTarget = e.e.currentTarget.checked
    const active: IActiveUser = {
      userId: this.loginInfo.userId,
      employeeId: e.id,
      isActive: currentTarget,
    }
    this.employeeDetails.IsActiveEmpUser(active).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        this.getEmployee(this.tableRowId)
      }), err => console.error('Error', err),
      () => console.log('HTTP request completed.')
  }

  //--------------------------------- Fetch Row Details Start--------------------------------- //
  getEmployee(empid) {
    const employee: GetEmployee = {
      employeeId: empid
    }
    this.employeeDetails.GetEmployee(employee).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      this.GeneralForm.patchValue(response[0]), err => console.error('Error', err),
        () => console.log('HTTP request completed.');

      this.GeneralForm.patchValue({ employeeHireDate: response[0].employeeHireDate == null ? '' : moment(response[0].employeeHireDate).format('YYYY-MM-DD') });

      this.GeneralForm.patchValue({ employeeBirthday: response[0].employeeBirthday == null ? '' : response[0].employeeBirthday == '' ? '' : moment(response[0].employeeBirthday).format('YYYY-MM-DD') });
    })
  }
  //--------------------------------- Fetch Row Details End--------------------------------- //

  onSelectState() {
    const ObjState: ISelectState = {
      id: 0
    }
    this.admin.SelectStateMaster(ObjState).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      this.filteredList2 = response
      this.variables = this.filteredList2
    }), err => console.error('Error', err),
      () => console.log('HTTP request completed.')
  }

  onKey(value) {
    let result = this.search(value)
    this.filteredList2 = result;
  }

  getSubtier(value) {
    this.onchange(this.GeneralForm.value);
  }

  search(value: string) {
    // let filter = this.variables.filter(item =>
    //   item.name.toLowerCase().includes(value.toLowerCase())
    // );
    // return [...filter];

    let filteredList2 = this.variables.filter((unit) =>
      unit.id.indexOf(value.toUpperCase()) > -1);
    if (value.length == 1) {
      let filterdata = filteredList2.filter((x) =>
        x.id.substring(0, 1) == value.toUpperCase());
      return [...filterdata];
    } else if (value.length == 2) {
      let filterSecdata = filteredList2.filter((y) =>
        y.id.substring(0, 2) == value.toUpperCase());
      return [...filterSecdata];
    } else {
      this.filterNodata = true
      return [...filteredList2];
    }

    //
    // unit.id.substring(0,1)

    // let filteredList2: string[] = [];
    // for(let a of this.variables){
    //   if(a.id.toLowerCase().indexOf(value) > -1){
    //     filteredList2.push(a)
    //   }
    // }
    // return filteredList2
  }

  numberOnly2(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (event.target.value.length <= 14) {

      if (event.target.value.length === 3) {
        event.target.value = `(${event.target.value}) `;
      }
      if (event.target.value.length === 9) {
        event.target.value = `${event.target.value}-`;
      }
      if (event.target.value.length === 11) {
        event.target.value = `${event.target.value}`;
      }
      if (event.target.value.length >= 13) {
        this.mgs = '';
        this.isInvalid.emit(false);
      }
      // if (event.target.value.length <= 12) {
      //   this.mgs = 'Phone number must be at least 10 numbers'
      //   return true
      // } else if(event.target.value.length == 0) {
      //   this.mgs = ''
      // }
      // else  {
      //   this.mgs = ''
      // }

    } else {
      event.target.value = event.target.value.slice(0, -1)
    }
    return true;
  }

  phoneNumberCheck(event) {
    if (event.target.value.length == 0) {
      this.mgs = '';
    }
    if (event.target.value.slice(0, 1) != '(') {
      if (event.target.value.length > 10) {
        this.mgs = 'Invalid mobile number';
        return;
      }
      else if (event.target.value.length < 3 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)})`;
        if (event.target.value.length < 15) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = ''
      }
      else if (event.target.value.length < 6 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}-`;
        if (event.target.value.length < 15) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = ''
      }
      else if (event.target.value.length >= 6 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}-${event.target.value.slice(6, event.target.value.length)}`;
        if (event.target.value.length < 14) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = ''
      }
    }
    else {
      if (event.target.value.length < 14) {
        this.mgs = 'Invalid mobile number';
        return;
      }
      this.mgs = '';
    }
  }

  onDeletePhone(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.mgs = ''
      }
    }
  }

  passwordValid(event) {
    this.passwordIsValid = event;
  }


  //---------------------------------MAX CHARS VALIDATION Start--------------------------------------//
  getMaxValueFName(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  getMaxValueLName(e) {
    if (e.target.value.length == 50) {
      this.errorValue1 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue1 = ""
    }
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue2 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue2 = ""
    }
  }

  getMaxValueEmail(e) {
    if (e.target.value.length == 100) {
      this.errorValue4 = "Max-Limit 100 characters reached";
    }
    else {
      this.errorValue4 = ""
    }
  }

  getMaxValueCity(e) {
    if (e.target.value.length == 50) {
      this.errorValue3 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue3 = ""
    }
  }
  //---------------------------------MAX CHARS VALIDATION End--------------------------------------//


  HighlightEmailPass() {
    if (!this.GeneralForm.invalid && !this.GeneralData.password.errors && !this.GeneralData.emailId.errors) {
      this.isInvalid.emit(false);
      this.dateMgs = '';
      this.dateMgsHire = '';
    }
  }
  //---------------------------------Focus Out VALIDATION Start--------------------------------------//
  EmpGenValid() {
    if (this.GeneralForm.get('backofficeAccess').value == true) {
      if (this.GeneralForm.invalid) {
        this.submittedGeneral = true;
        this.isInvalid.emit(true);
        this.requiredError = true;
        return;
      }
    }

    if (this.GeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }

    else if (this.GeneralData.phone.value != undefined && this.GeneralData.phone.value != null && this.GeneralData.phone.value != "") {
      if (this.GeneralData.phone.value.length <= 12) {
        this.mgs = 'Phone number must be at least 10 digit';
        this.requiredError = true;
        this.isInvalid.emit(true);
        return
      }
    }
    else if (this.GeneralForm.controls.zipCode.value != undefined && this.GeneralForm.controls.zipCode.value != null && this.GeneralForm.controls.zipCode.value != "") {
      if (this.GeneralForm.controls.zipCode.value.length <= 4) {
        this.zipCodeMsg = 'Please enter min 5 digits zipcode';
        this.requiredError = true;
        this.isInvalid.emit(true);
        return
      }
    }
    else {
      this.requiredError = false;
      this.isInvalid.emit(false);
    }
  }
  //---------------------------------Focus Out VALIDATION End--------------------------------------//


  //---------------------------------KEYPRESS VALIDATION COLOR---------------------------------//
  Highlight() {
    this.onchange(this.GeneralForm.value);

    if (!this.GeneralForm.invalid) {
      this.isInvalid.emit(false);
      this.dateMgs = '';
      this.dateMgsHire = '';
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  //---------------------------------KEYPRESS VALIDATION COLOR---------------------------------//

  ngAfterViewInit() {
    this.StoreempData_child.emit(this.GeneralForm.value)
    this.GeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.StoreempData_child.emit({ data: data, event: "GeneralPage", val: true });
  }

  onDeleteZipCode(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.zipCodeMsg = ''
      }
    }
  }

  zipCodeCheck(event) {
    if (event.target.value.length === 6) {
      if (event.target.value.slice(5, 6) == '-') {
        event.target.value = `${event.target.value.slice(0, 5)}`;
      }
    }
    else if (event.target.value.length > 5 && event.target.value.length < 10) {
      if (event.target.value.slice(5, 6) != '-') {
        event.target.value = `${event.target.value.slice(0, 5)}-${event.target.value.slice(6, event.target.value.length)}`;
        this.zipCodeMsg = "";
      }
    }
    else if (event.target.value.length === 9) {
      event.target.value = `${event.target.value.slice(0, 5)}-${event.target.value.slice(6, 9)}`;
    }

    else if (event.target.value.length === 10) {
      if (event.target.value.slice(5, 6) != '-') {
        this.zipCodeMsg = "Invalid Zip Code";
      }
      else {
        this.zipCodeMsg = "";
      }
    }
  }
}
