import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-gift-card-center-footer',
  templateUrl: './gift-card-center-footer.component.html',
  styleUrls: ['./gift-card-center-footer.component.css']
})
export class GiftCardCenterFooterComponent implements OnInit {

  @Input() displayedColumns;
  @Input() dataSource;
  @Input() columns;
  @Input() footerHide;
  @Input() firstTableShow;
  @Input() secondTableShow;
  @Input() thirdTableShow;
  @Input() nofooter
  @ViewChild(MatSort) sort: MatSort;
  rowClicked : any;
  @Output() public cardnumber = new EventEmitter();
  constructor() { }

  ngOnInit(): void {

  }

  categoryClick(row) {
		this.cardnumber.emit(row.cardno);
		// row.expanded = !row.expanded;
		// this.dataSource.filter = performance.now().toString();  // bug here need to fix
	}

  
  checkClick(row) {
		this.cardnumber.emit(row.checkno);
		// row.expanded = !row.expanded;
		// this.dataSource.filter = performance.now().toString();  // bug here need to fix
	}

  @Input()
  public set employeeItem(tableRowIdcolor) {
      this.rowClicked = tableRowIdcolor;
  }
  getTotalCost() {
    let value = this.dataSource.map(t => t.name).filter(x => x !== '')
    return value.length
  }
  getTotalCost1() {
    let value = this.dataSource.map(t => t.check).filter(x => x !== '')
    return value.length
  }
  getTotalCost2(){
    return this.dataSource.map(t => t.amount).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost3(){
    return this.dataSource.map(t => t.regPay).reduce((acc, value) => acc + Number(value), 0);
  }
  getTotalCost4(){
    return this.dataSource.map(t => t.totalHours).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost5(){
    return this.dataSource.map(t => t.totalPay).reduce((acc, value) => acc + Number(value), 0);
  }
  getTotalCost6(){
    return this.dataSource.map(t => t.ccTip).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost7(){
    return this.dataSource.map(t => t.tipReceive).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost8(){
    return this.dataSource.map(t => t.charges).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost9(){
    return this.dataSource.map(t => t.tipOuts).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost10(){
    return this.dataSource.map(t => t.tipPer).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost11(){
    return this.dataSource.map(t => t.taxAmount).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost12() {
    return this.dataSource.map(t => t.netSales).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost13() {
    return this.dataSource.map(t => t.quantity).reduce((acc, value) => acc + value, 0);
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  // changeTableRowColor(id?: any, row?: any) {

  //   this.rowClicked = ""
  //   this.rowClicked = this.rowClicked === id? -1 : id;
  //   this.tableItem.emit(row)
  // }

}
