<div class="row">
    <div class="col-sm-4 time-index">

        <div *ngIf="tableEnable">
            <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" (hierarchy)="changeTableRowColor($event)" (hierarchyActive)="checkValue($event)" [dealerItem]="dealerRow" (clearDetails)="adddealer()" (tableEmpName)="getTableName($event)"
                [DataCom]="employee" [DataDealerId]="dealerIdItem" [EmployeeTableData]="EmployeeTableData" (hierarchyData)="gethierarchyData($event)"></app-fliptable>

        </div>
    </div>
    <div class="col-sm-8">
        <div id="accordion">
            <div class="card">
                <div class="card-header outshadow" id="heading-1">
                    <h5 class="mb-0">
                        <a role="button" data-toggle="collapse" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
              Dashboard
            </a>
                    </h5>
                </div>
                <div id="collapse-1" class="collapse" data-parent="#accordion" aria-labelledby="heading-1">
                    <div class="card-body" style="padding: 0!important;">
                        <img src="assets/components/images/dashboard-graph.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header outshadow" id="heading-2">
                    <h5 class="mb-0">
                        <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-2" aria-expanded="true" aria-controls="collapse-2">
              General
            </a>
                    </h5>
                </div>
                <div id="collapse-2" class="collapse show" data-parent="#accordion" aria-labelledby="heading-2">
                    <div class="card-body">
                        <div class="Gen_wrap">
                            <form class="loginForm" [formGroup]="GeneralDealerForm">
                                <div class="pull-right-active">
                                    <span>Active</span>
                                    <div class="checkbox-table accordion-checkbox" id="checkbox-box">
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" name="customCheckbox" id="ActiveHie" formControlName="isActive" checked="isActive">
                                            <label class="custom-control-label" for="ActiveHie"></label>
                                        </div>
                                    </div>
                                </div>
                                <br>

                                <div class="row w-100">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Dealer Name</label> <label class="text-danger">*</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="tenantName" placeholder="Dealer Name" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.tenantName.errors }" />
                                            <div *ngIf="submittedGeneral && GeneralDealerData.tenantName.errors" class="invalid-feedback">
                                                <div *ngIf="GeneralDealerData.tenantName.errors.required">Dealer Name is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Phone</label>
                                            <ngx-intl-tel-input [cssClass]="'form-control country-tel-code'" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [preferredCountries]="preferredCountries" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true" [separateDialCode]="separateDialCode" formControlName="contactPhone">
                                            </ngx-intl-tel-input>
                                            <!-- [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.contactPhone.errors }" -->
                                            <!-- <div *ngIf="submittedGeneral && GeneralDealerData.contactPhone.errors" class="invalid-feedback">
                        <div *ngIf="GeneralDealerForm.controls['contactPhone'].errors">Phone number must be at least 10
                          numbers</div>
                      </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Contact First Name</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="contactFirstName" placeholder="Contact First Name" />
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Contact Last Name</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="contactLastName" placeholder="Contact Last Name" />
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Contact Email</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="contactEmail" placeholder="&#x2709; Example@myemailaddress.com" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Address</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="address" placeholder="Address" />
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">City</label>
                                            <input type="text" class="form-control" formControlName="city" placeholder="City" />
                                        </div>
                                    </div>
                                    <div class="col-sm-2 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">State</label>
                                            <input type="text" class="form-control" formControlName="state" placeholder="MT" />
                                        </div>
                                    </div>
                                    <div class="col-sm-2 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Zip Code</label>
                                            <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code" (keypress)="numberOnly($event)" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Reseller Start Date</label>
                                            <input type="text" placeholder="YYYY-MM-DD" class="form-control" formControlName="resellerStartDate" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false }">
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Projected Volume in $$</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="projectedVolume" placeholder="Projected Volume in $$" />
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Projected Volume LPM</label>
                                            <input type="text" class="form-control" formControlName="projectedVolLpm" placeholder="Projected Volume LPM" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Territory</label>
                                            <input type="text" class="form-control input-lg inputbox_login" formControlName="territory" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Protected?</label>
                                            <div class="checkbox-table">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input active" name="projected" id="protected" formControlName="projected">
                                                    <label class="custom-control-label active" for="protected"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-12">
                                        <div class="form-group">
                                            <label class="customlable mb-0">Vested?</label>
                                            <div class="checkbox-table">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input active" name="vested" id="vested" formControlName="vested">
                                                    <label class="custom-control-label active" for="vested"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <button class="btn  btn-sm float-right submit_button" type="submit" (click)="onSaveGeneral();">
                    Save
                  </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
