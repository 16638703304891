import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { } from 'rxjs';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { MenuService } from 'src/app/services/menu.service';
import { menuItemList } from 'src/app/interfaces/imenu';
import { MenuItemGeneralComponent } from '../menu-item-general/menu-item-general.component';
import { DialogService } from 'src/app/services/dialog.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { MatStepper } from '@angular/material/stepper';
import { MenuLogisticsModalComponent } from 'src/app/elements/menu-logistics-modal/menu-logistics-modal.component';
import { GiftcardService } from 'src/app/services/giftcard.service';
import { EnvService } from 'src/app/env.service';

@Component({
  selector: 'app-menu-item-logistics',
  templateUrl: './menu-item-logistics.component.html',
  styleUrls: ['./menu-item-logistics.component.css'],
})
export class MenuItemLogisticsComponent implements OnInit {
  MenuItemFormlogistic: FormGroup;
  buttonDisable: boolean = false;
  loading = false;
  submitted = false;
  hexColor: String;
  menucolor: any;
  kdsclr: any;
  buttonColor: any;
  loginInfo: any;
  picker: any;
  pickerbox: any;
  button: any;
  ImageFile: any;
  fileInfo: any;
  format: string;
  base64Data: any;
  bsModalRef?: BsModalRef;
  itemLogoName: any;
  dff: any;
  @ViewChild('stepper') stepper: MatStepper;
  imgUrl: any;
  imageUrl: any = 'assets/components/images/picture-upload-2-x.png';
  img: boolean;
  menuId: any;
  employeeName: any;
  @Output() public mnuitmlogsessData_child = new EventEmitter();
  itemCost: any;
  itemPrice: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toaster: ToasterService,
    private ItemLog: MenuService,
    private helper: HelperService,
    private dialogser: DialogService,
    private modalService: BsModalService,
    private objImageUpload: GiftcardService,
    private env: EnvService
  ) { }

  @Input() public menuPriceSizeList;

  @ViewChild(MenuItemGeneralComponent)
  MenuItemGeneralComponent: MenuItemGeneralComponent;
  //@Input() public buttonState;
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !btnst;
    }
  }

  ngOnInit(): void {
    if (this.imageUrl) {
      this.format = 'image';
    }
    this.imgUrl = 'assets/components/images/picture-upload-2-x.png';
    this.picker = {
      PrimaryBgColor: '#666769',
    };
    this.picker = {
      PrimaryBgColor: '#666769',
    };
    this.pickerbox = {
      PrimaryBgColor: '#32353b',
    };
    this.button = {
      PrimaryBgColor: 'none',
    };
    this.helper.setValue('GetSessionData2', null);
    this.buttonDisable = false;
    if (this.picker) {
      this.setTheme(this.picker);
    }
    this.menucolor = '#4d1919';
    this.kdsclr = '#4d1919';
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.MenuItemFormlogistic = this.formBuilder.group({
      logisticsID: [0],
      itemID: [0],
      printOrder: [0],
      cookTime: [0, { disabled: true }],
      customButton: [false, { disabled: true }],
      buttonColor: ['', { disabled: true }],
      buttonText: [''],
      alwaysPrintReal: [false],
      kitchenDisplay: [false],
      wamingTime: [0],
      lateTime: [0, { disabled: true }],
      kdsColor: [''],
      menuItemLogo: [''],
      tenantId: [null],
    });
    this.picker.PrimaryBgColor = this.hexColor;


  }
  private setTheme(picker) {
    //console.log(picker);
    Object.keys(picker).forEach((k) =>
      document.documentElement.style.setProperty(`--${k}`, picker[k])
    );
  }
  @Input()
  public set DataCom(employee) {
    this.employeeName = employee;
  }

  @Input()
  public set menuItemId(Id) {
    if (Id != undefined && Id != null && Id != '') {
      this.menuId = Id;
      this.GetItemGeneralList(Id);
    }
  }

  @Input()
  public set MenuRowItem(tableRowId) {
    if (tableRowId != undefined && tableRowId != null && tableRowId != '') {
      this.menuId = tableRowId;
      this.GetItemGeneralList(this.menuId);
    }
  }

  @Input()
  public set menuPriceList(e) {

    if (e != undefined) {
      if (e.sizedetails.length != 0) {
        if (e.sizedetails[0].sizeName == null) {
          this.menuPriceSizeList = []
          this.itemCost = e.cost
          this.itemPrice = e.price
        }
        else if (e.pricingStyle == true) {
          this.menuPriceSizeList = e.sizedetails;
        }
      }
      else {
        this.menuPriceSizeList = []
      }
    }
  }
  printOrder: number = 30;
  cookTime: number = 30;
  Warning: number = 30;
  lateTime: number = 30;

  updateSetting(event) {
    this.helper.setValue('GetSessionData2', this.MenuItemFormlogistic.value);
    this.helper.setValue('OnDirty2', true);
    this.printOrder = event.value;
    this.cookTime = event.value;
    this.Warning = event.value;
    this.lateTime = event.value;
    this.mnuitmlogsessData_child.emit({
      data: this.MenuItemFormlogistic.value,
      event: 'MenuItemPage',
      val: true,
    });
  }

  handleChange($event: ColorEvent) {
    this.helper.setValue('GetSessionData2', this.MenuItemFormlogistic.value);
    this.helper.setValue('OnDirty2', true);
    this.hexColor = $event.color.hex;
    this.picker.PrimaryBgColor = this.hexColor;
    this.buttonColor = this.hexColor;
    this.menucolor = this.hexColor;
    this.setTheme(this.picker);
    this.mnuitmlogsessData_child.emit({
      data: this.MenuItemFormlogistic.value,
      event: 'MenuItemPage',
      val: true,
    });
    // alert(this.hexColor)
  }

  changecolor($event: ColorEvent) {
    this.helper.setValue('GetSessionData2', this.MenuItemFormlogistic.value);
    this.helper.setValue('OnDirty2', true);
    this.hexColor = $event.color.hex;
    this.pickerbox.PrimaryBgColor = this.hexColor;
    this.kdsclr = this.hexColor;
    this.mnuitmlogsessData_child.emit({
      data: this.MenuItemFormlogistic.value,
      event: 'MenuItemPage',
      val: true,
    });
  }

  get f() {
    return this.MenuItemFormlogistic.controls;
  }

  onSelectFile(event: any) {
    console.log(event);
    if (event.target.files.length != 0) {

      this.imageUrl = null;
      this.ImageFile = event.target.files && event.target.files[0];
      // this.fileInfo = file;

      if (this.ImageFile) {
        var reader = new FileReader();
        reader.readAsDataURL(this.ImageFile);
        if (this.ImageFile.type.indexOf('image') > -1) {
          this.format = 'image';
        }
        reader.onload = (event) => {
          this.imgUrl = (<FileReader>event.target).result;
        };
        this.img = true
      }
    }
    else {
      this.imageUrl = 'assets/components/images/picture-upload-2-x.png'
    }
  }

  deleteimage() {

    this.ImageFile = null;
    this.imgUrl = 'assets/components/images/picture-upload-2-x.png';
    this.img = false;

    this.onchange(this.MenuItemFormlogistic.value);
  }

  openModal() {
    this.bsModalRef = this.modalService.show(MenuLogisticsModalComponent, {
      class: 'modalComp modal-popUp modal-l',
      backdrop: 'static',
      keyboard: true,
    });
    this.bsModalRef.content.messageEvent.subscribe((data) => {
      this.dff = data;
      if (this.dff == true) {
        this.stepper.selectedIndex = 1;
      }
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  onSubmit(eventid): void {
    this.submitted = true;
    if (this.MenuItemFormlogistic.invalid) {
      return;
    }
    this.menuId = eventid;
    const itm = this.MenuItemFormlogistic.value;
    this.MenuItemFormlogistic.value.itemID = this.menuId;
    this.MenuItemFormlogistic.value.CreatedBy = this.loginInfo.userId;
    this.MenuItemFormlogistic.value.IsDeleted = false;
    this.MenuItemFormlogistic.value.buttonColor = this.picker.PrimaryBgColor;
    this.MenuItemFormlogistic.value.kdsColor = this.pickerbox.PrimaryBgColor;
    this.MenuItemFormlogistic.value.tenantid = this.loginInfo.tenantId;
    this.MenuItemFormlogistic.value.menuItemLogo =
      this.ImageFile == undefined ? '' : this.ImageFile.name;
    this.submitted = true;

    if (this.ImageFile != undefined) {
      const formData = new FormData();
      formData.append('image', this.ImageFile, this.ImageFile.name);
      formData.append('folderName', 'MenuItemLogo');

      this.objImageUpload.LogoAWSUpload(formData).subscribe((imgres) => {
        //console.log(imgres, 'AWS S3 MenuItemLogo');
      }),
        (err) => console.error('Error', err), () => console.log('HTTP request completed.');
    }
    // console.log(this.MenuItemFormlogistic.value, 'this.MenuItemFormlogistic')
    this.ItemLog.saveItmLogistics(this.MenuItemFormlogistic.value).subscribe(
      async (response) => {
        this.submitted = false;
        this.helper.setValue(
          'GetSessionData2',
          this.MenuItemFormlogistic.value
        );
        await this.dialogser.ClearSession();
        this.mnuitmlogsessData_child.emit({
          data: this.MenuItemFormlogistic.value,
          event: 'MenuItemPage',
          val: false,
        });
      }
    );
  }

  GetItemGeneralList(itemId) {
    const ItemList: menuItemList = {
      itemID: itemId,
      tenantId: this.loginInfo.tenantId,
    };
    this.ItemLog.MenuItemListID(ItemList).subscribe((res) => {
      this.MenuItemFormlogistic.patchValue(res[0]);
      this.imgUrl =
        res[0].menuItemLogo == null
          ? 'assets/components/images/picture-upload-2-x.png'
          : res[0].menuItemLogo == ''
            ? 'assets/components/images/picture-upload-2-x.png'
            : this.env.awsS3_environment + 'MenuItemLogo/' + res[0].menuItemLogo;
      this.menucolor =
        res[0].buttonColor == '' ? '#73767900' : res[0].buttonColor;
      this.kdsclr = res[0].kdsColor == '' ? '#73767900' : res[0].kdsColor;
      this.pickerbox.PrimaryBgColor = res[0].kdsColor;
      this.button.PrimaryBgColor = res[0].buttonColor;
      this.picker.PrimaryBgColor = this.menucolor;
      this.setTheme(this.picker);
      this.itemCost = res[0].cost;
      this.itemPrice = res[0].price;
    });


  }

  ngAfterViewInit() {
    this.MenuItemFormlogistic.patchValue(
      this.helper.getValue('GetSessionData2')
    );
    this.mnuitmlogsessData_child.emit(this.MenuItemFormlogistic.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData2', this.MenuItemFormlogistic.value);
    this.helper.setValue('OnDirty2', true);
    this.mnuitmlogsessData_child.emit({
      data: data,
      event: 'MenuItemPage',
      val: true,
    });
  }

  btnColor(btnColorChange) {
    this.picker.PrimaryBgColor = btnColorChange;
    this.setTheme(this.picker);
  }
  KDSbtnColor(btnColorChange) {
    this.pickerbox.PrimaryBgColor = btnColorChange;
    this.setTheme(this.pickerbox);
  }
}
