import { CdkDragDrop, CdkDragExit, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { MenuPageService } from 'src/app/services/menupage.service';
import { GetMenuPages } from 'src/app/interfaces/imenu';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-menu-page-layout',
  templateUrl: './menu-page-layout.component.html',
  styleUrls: ['./menu-page-layout.component.css']
})
export class MenuPageLayoutComponent implements OnInit {
  loginInfo: any;

  public table_render: boolean = true;

  public dataSourceTablePage = new MatTableDataSource<any>();
  tableEnable: boolean = true;
  public displayedColumns = [];
  public columns = [];
  public menu_Page: boolean = true;
  dataSourcePage: any;
  @ViewChild('table') table: MatTable<any>;
  menuItem: any = {
    tenantId: 0,
    salesAreaId: 0,
    menuGroupId: 0,
    menuPageId: 0,
    areaObject: "",
  };
  @Input() SalesAreaID;
  @Input() MenuGroupID;

  rowClicked: any;
  @Output() public rowDataEmit = new EventEmitter();
  @Output() public menuPagesDrop = new EventEmitter();
  public salesAreaflip: any;
  areaValue: any;
  @Input() public DragId;
  render: any;
  menuPageList: any = [];
  @Input() layoutId;
  @Input() ParentID;
  @Input() ParentGroup;
  menuPageLayout: any = [];
  @Input() public buttonState;
  constructor(private changeDetector: ChangeDetectorRef, private formBuilder: FormBuilder, private helper: HelperService,
    private toaster: ToasterService, private objpagelayout: MenuPageService, private objItem: MenuService) { }



  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');

    // this.getMenuPageList();

  }
  drop(event) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data.filteredData,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.table_render = false
      this.changeDetector.detectChanges();
      this.table_render = true

    }
  }
  @Input()
  public set EditPagedata(event) {
  }
  @Input()
  public set MenuPageLayout(list) {

    this.menuPageLayout = list
    this.rowClicked = "transparent"
  }
  @Input()
  public set MenuPageList(list) {
    this.menuPageList = list;
    this.dataSourceTablePage.data = list;
    this.displayedColumns = ['name'];
    this.columns = [
      { columnDef: 'name', header: 'Menu Page Name' }
    ];

  }
  cdkDropListExited(event: CdkDragExit<string[]>) {
    const preview = new ElementRef<HTMLElement>(document.querySelector('.cdk-drag.cdk-drag-preview'));
    this.render.removeClass(preview.nativeElement, 'dropzone-placeholder');
  }
  @Input()
  public set DataLayoutAccordian(LayoutAccordian) {
    this.salesAreaflip = LayoutAccordian;
  }
 
  OnSavepageGroup() {
    // let MenuLayout = this.ParentGroup.find(i => i.id === this.ParentID);
    this.menuItem.tenantId = this.loginInfo.tenantId;
    this.menuItem.salesAreaId = this.SalesAreaID;
    this.menuItem.menuGroupId = this.MenuGroupID;
    this.menuItem.menuPageId = 0;
    let menuObject = JSON.stringify(this.menuPageLayout);
    this.menuItem.areaObject = menuObject
    console.log(this.menuItem,'SaveMenuPageLayout');
    if (this.MenuGroupID!='' && this.SalesAreaID!='' && menuObject!='[]' &&  this.MenuGroupID!=undefined && menuObject!=undefined)
    {
      this.objItem.saveAreaMapping(this.menuItem).subscribe(
        (response) => {
          //    this.toaster.successPopUp(response.message);
        })
    }
 
  }

  getMenuPageList() {
    const menuPageList: GetMenuPages = {
      tenantId: 0
    }
    this.objpagelayout.MenuPageGet(menuPageList)
      .subscribe(res => {
        this.dataSourceTablePage.data = res;
        this.displayedColumns = ['name'];
        this.columns = [
          { columnDef: 'name', header: 'Item Name' }
        ];
        this.tableEnable = false;
        this.changeDetector.detectChanges();
        this.tableEnable = true;
      })
  }

  onClickItem(e) {
    this.helper.setValue('OnDirty', true);
    this.helper.setValue('MnuPageLayout_ID',  e.id);
    this.helper.setValue('SessionPageName', "layoutPage");
    this.rowClicked = e.id;
    this.rowDataEmit.emit(e)
  }
  GetSessionId(e)
  {
    this.menuPagesDrop.emit(e)
   // console.log(e,'menuPageseeiosnid')
  }
}
