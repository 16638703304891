<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="ReSetForm()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global_submit" type="submit" [disabled]="buttonStatus ?  true : null" (click)="OnSave();">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label></button>
        </div>
    </div>

</div>
<div class="row second-sec">
    <div class="col-sm-4">
        <app-fliptable (hierarchy)="getTableRowid($event)" [dealerItem]="tableRowColor" [DataCom]="employee" [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" (tableEmpName)="getTableName($event)" [employeeItem]="tableRowColor"
            (btnActive)="btnActiveStatus($event)" [EmployeeTableData]="EmployeeTableData" (tableItemId)="getTableId($event)" (clearDetails)="ReSetForm()" (breakActive)="checkValue($event)">
        </app-fliptable>
    </div>
    <div class="col-sm-8" #scroll>

        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of ['General','Logistics']; let i = index" [expanded]="indexExpanded == i" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-expansion-panel-header (click)="expandPanel(item,index)" [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                    <mat-panel-title (click)="onChangeAccordion($event,index)" [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}} </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General'">
                    <app-house-accounts-general [MenuRowItem]="getDataValue" [Dataname]="employeeName" (EmitHosueAccount)="HouseList($event)" [buttonState]="buttonDisable" (isInvalid)="isInvalidAccordion($event)" (HouseAccData_child)="gethouseaccsessData($event)" (matopen)="matstate($event)">
                    </app-house-accounts-general>
                </div>
                <div *ngIf="item == 'Logistics'">
                    <app-house-accounts-logitics [MenuRowItem]="getDataValue" [Dataname]="employeeName" [tableRowIdx]="tableRowId" [buttonState]="buttonDisable" (HouseAccLogData_child)="gethouseaccsessData($event)">
                    </app-house-accounts-logitics>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
