import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { DeleteMenuGroup, DeleteMenuPages, DeleteSaleArea, GetMenuGroup, GetMenuPages, GetSaleAreaGeneral, menuItemList } from 'src/app/interfaces/imenu';
import { MenuService } from 'src/app/services/menu.service';
import { MenuGroupService } from 'src/app/services/menugroup.service';
import { MenuPageService } from 'src/app/services/menupage.service';
import { JsonPipe } from '@angular/common';
import { EmployeeService } from 'src/app/services/employee.service';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { SalesAreaComponent } from '../sales-area/sales-area.component';
import { MenuGroupComponent } from '../menu-group/menu-group.component';
import { MenuPageComponent } from '../menu-page/menu-page.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-sales-area-builder',
  templateUrl: './sales-area-builder.component.html',
  styleUrls: ['./sales-area-builder.component.css']
})
export class SalesAreaBuilderComponent implements OnInit {
  private buttonDisableVal: boolean;
  public displayedAreaColumns = [];
  columnsArea = [];
  public displayedGroupColumns = [];
  columnsGroup = [];
  public displayedPageColumns = [];
  columnsPage = [];
  public displayedItemColumns = [];
  columnsItem = [];
  isInvalid: boolean = false;
  public dataSourceArea = new MatTableDataSource<any>();
  public dataSourceGroup = new MatTableDataSource<any>();
  public dataSourcePage = new MatTableDataSource<any>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(SalesAreaComponent) SalesAreaComp: SalesAreaComponent;
  @ViewChild(MenuGroupComponent) menuGrp: MenuGroupComponent;
  @ViewChild(MenuPageComponent) menuPages: MenuPageComponent;
  @ViewChild('trigger', { static: false }) flipcomp: FliptableComponent;

  loginInfo: any;
  tenantId: any;
  salesArea: any;
  menuGroup: any;
  menuPage: any;

  public isFirst: any = 0;
  public getAreaDetails: any;
  public getSalesTitle: any;
  public tableRowId: any;
  public tableRowColor: any;
  public activeDeactiveValue: any;
  public getTableEvent: any;

  @Output() public areaIdEmit = new EventEmitter()
  @Output() public groupIdEmit = new EventEmitter()
  @Output() public PageIdEmit = new EventEmitter()
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public areabuildsessData = new EventEmitter();

  public SalesTableData: any;
  public GroupTableData: any;
  public PageTableData: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  salesShow: boolean = true
  groupShow: boolean = false
  pageShow: boolean = false
  onclickname: any;
  CurrentOpenAccordian: any = 'Sales Area';

  //@Input() public buttonDisableVal
  clickUpdated: any
  buttonStatus: any;

  constructor(private helper: HelperService, private toaster: ToasterService, private objItem: MenuService, private changeDetector: ChangeDetectorRef,
    private objGroup: MenuGroupService, private objPage: MenuPageService, private repoService: EmployeeService, private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.SalesAreaPage");

    this.buttonDisableVal = false;
    this.employeeName = "Sales Area";
    this.employee = "Sales Area";
    this.employeeIcon = "assets/components/images/menu-item.png"
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getDataArea(this.isFirst);
    this.buttonStatus = false;
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.getSalesArea();
    this.getMenuGroupList();
    this.getMenuPageList();

    this.repoService.clickValues.subscribe(data => this.clickUpdated = data);
    if (this.helper.getValue('OnDirty') == false && this.helper.getValue('OnDirty2') == false
      && this.helper.getValue('OnDirty3') == false && this.helper.getValue('OnDirty4') == false) {
      this.tableRowColor = 'transparent';
    }
  }

  getTableValue(event) {
    this.getTableEvent = event.res
    this.getSalesArea();
    this.tableRowColor = event.res;
    this.flipcomp.selectTableRowColor(event.res, event.res, event.name)
  }

  getGroupTableValue(event) {
    this.getMenuGroupList();
    this.tableRowColor = event.res;
    this.flipcomp.selectTableRowColor(event.res, event.res, event.name)
  }

  getPageTableValue(event) {
    this.getMenuPageList();
    this.tableRowColor = event.res;
    this.flipcomp.selectTableRowColor(event.res, event.res, event.name)
  }

  getMenuGroupList() {
    const menuGroupList: GetMenuGroup = {
      tenantId: this.loginInfo.tenantId
    }
    this.objGroup.MenuGroupget(menuGroupList)
      .subscribe(res => {
        this.dataSourceGroup.data = res;
        this.GroupTableData = res;
        this.dataSourceGroup.data = this.dataSourceGroup.data.filter(x => x.isActive == true)
        this.displayedGroupColumns = ['name', 'isActive'];
        this.columnsGroup = [
          { columnDef: 'name', header: 'Menu Group Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      //this.menuSizeRow = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  getMenuPageList() {
    const menuPageList: GetMenuPages = {
      tenantId: this.loginInfo.tenantId
    }
    this.objPage.MenuPageGet(menuPageList)
      .subscribe(res => {
        this.dataSourcePage.data = res;
        this.PageTableData = res;
        this.dataSourcePage.data = this.dataSourcePage.data.filter(x => x.isActive == true)
        this.displayedPageColumns = ['name', 'isActive'];
        this.columnsPage = [
          { columnDef: 'name', header: 'Menu Page Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      //this.menuSizeRow = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  getTableName(name) {
    this.employeeName = name;
    this.employee;
    this.employeeIcon;
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
  }
  // getSaleAreaAccordion(e) {
  //   this.salesArea = e
  // }

  getSalesArea() {
    const menuItemList: GetSaleAreaGeneral = {
      id: 0,
      tenantId: this.loginInfo.tenantId,
    }
    this.objItem.GetSalesAreaGeneral(menuItemList)
      .subscribe(res => {
        this.dataSourceArea.data = res;
        this.SalesTableData = res;
        this.dataSourceArea.data = this.dataSourceArea.data.filter(x => x.isActive == true)
        this.displayedAreaColumns = ['name', 'isActive'];
        this.columnsArea = [
          { columnDef: 'name', header: 'Sales Area Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      //this.menuSizeRow = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  // getFirstPannel(event) {
  //   this.isFirst = event
  // }

  getDataArea(event) {
    this.getAreaDetails = event;
    this.areaIdEmit.emit(this.getAreaDetails)
  }

  getSalesName(event) {
    this.employeeName = event
  }

  onChangeAccordion(e) {
    if (e.currentTarget != undefined && e.currentTarget != null && e.currentTarget != "") {
      this.CurrentOpenAccordian = e.currentTarget.innerText;
      if (e.currentTarget.innerText == "Sales Area") {
        this.salesArea = ('Area');
        this.salesShow = true;
        this.groupShow = false;
        this.pageShow = false;
        this.ClearCompFun(e);
        this.tableRowColor = 'transparent';
      }
      if (e.currentTarget.innerText == "Menu Group") {
        this.salesArea = ('Group');
        this.groupShow = true;
        this.pageShow = false;
        this.salesShow = false;
        this.ClearCompFun(e);
        this.tableRowColor = 'transparent';
      }
      if (e.currentTarget.innerText == "Menu Page") {
        this.salesArea = ('Page');
        this.pageShow = true;
        this.salesShow = false;
        this.groupShow = false;
        this.ClearCompFun(e);
        this.tableRowColor = 'transparent';
      }

      this.employeeName = e.currentTarget.innerText;
      this.employee = e.currentTarget.innerText;
      this.onclickname = this.employee;
      this.titleName.emit(this.employeeName);
      this.title.emit(this.employee);
    }
    // if (e.currentTarget.innerText == "Items") {
    //   this.salesArea = ('Items');
    //   this.employeeName = e.currentTarget.innerText
    //   this.employee = e.currentTarget.innerText
    //   this.titleName.emit(this.employeeName);
    //   this.title.emit(this.employee)
    // }
  }

  checkValueArea(event) {
    let currentTarget = event.e.target.checked //event.target.checked
    const Active: DeleteSaleArea = {
      id: event.id,
      status: event.e.target.checked,
      modifiedBy: this.loginInfo.userId,
    }
    this.objItem.DeleteSalesArea(Active).subscribe(
      (response) => {
        this.getSalesArea()
        if (currentTarget) {
          this.toaster.successPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
        }
        this.ClearCompFun(event);
      })
  }

  checkValueGroup(event) {
    let currentTarget = event.e.target.checked //event.target.checked
    const active: DeleteMenuGroup = {
      userId: this.loginInfo.userId,
      id: event.id,
      isActive: event.e.target.checked
    }
    this.objGroup.MenuGroupDelete(active).subscribe(
      (response) => {
        this.getMenuGroupList();
        if (currentTarget) {
          this.toaster.successPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
        }
        this.ClearCompFun(event);
      },
    )
  }

  checkvaluePage(event) {
    let currentTarget = event.e.target.checked //event.target.checked
    const active: DeleteMenuPages = {
      userId: this.loginInfo.userId,
      id: event.id,
      isActive: event.e.target.checked
    }
    this.objPage.MenuPageDelete(active).subscribe(
      (response) => {
        this.getMenuPageList();
        if (currentTarget) {
          this.toaster.successPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
        }
        this.ClearCompFun(event);
      },
    )
  }

  public addEmployee(event): void {
    this.ClearCompFun(event);
  }

  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;
  public scrollToTop() {
    this.scroll.nativeElement.scrollTop = 0;
  }

  async ClearCompFun(event) {
    if (this.helper.getValue('OnDirty') == false && this.helper.getValue('OnDirty2') == false
      && this.helper.getValue('OnDirty3') == false && this.helper.getValue('OnDirty4') == false) {
      if (this.CurrentOpenAccordian == "Sales Area") {
        this.SalesAreaComp.addEmployee();
        this.SalesAreaComp.expandFirst('General', 0);
        this.getSalesArea();
      }
      else if (this.CurrentOpenAccordian == "Menu Group") {
        this.menuGrp.addEmployee();
        this.getMenuGroupList();
      }
      else if (this.CurrentOpenAccordian == "Menu Page") {
        this.menuPages.addEmployee();
        this.getMenuPageList();
      }

      this.employeeName = this.employee;
      this.tableRowColor = 'transparent';
      this.flipcomp.rowClicked = "transparent";
      this.titleName.emit(this.employeeName);
      this.getAreaDetails = [];
      await this.dialogser.ClearSession();
    }
    else {
      var conf_clear = await this.dialogser.confirm("SalesAreaPage");
      if (conf_clear == 'success') { // Navigate Away
        this.flipcomp.rowClicked = "transparent";
        this.tableRowColor = 'transparent';
        if (this.SalesAreaComp.indexExpanded != undefined && this.SalesAreaComp.indexExpanded != 0) {
          this.SalesAreaComp.expandFirst('General', 0);
        }
        this.SalesAreaComp.expandFirst('General', 0);
        this.dialogser.ClearSession();

        if (this.CurrentOpenAccordian == "Sales Area") {
          this.SalesAreaComp.addEmployee();
        }
        else if (this.CurrentOpenAccordian == "Menu Group") {
          this.menuGrp.addEmployee();
        }
        else if (this.CurrentOpenAccordian == "Menu Page") {
          this.menuPages.addEmployee();
        }
      }
      else {
        if (this.CurrentOpenAccordian == "Sales Area") {
          this.SalesAreaComp.DataBindonCancel();
        }
        else if (this.CurrentOpenAccordian == "Menu Group") {
          this.menuGrp.DataBindonCancel();
        }
        else if (this.CurrentOpenAccordian == "Menu Page") {
          this.menuPages.DataBindonCancel();
        }
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        this.tableRowColor = this.dialogser.ModifiedId;
      }
    }
  }

  async getTableDet(eventDet) {
    //this.gentab.isFormPageDirty();
    if (this.helper.getValue('OnDirty') == true || this.helper.getValue('OnDirty2') == true
      || this.helper.getValue('OnDirty3') == true || this.helper.getValue('OnDirty4') == true) {
      var conf = await this.dialogser.confirm("SalesAreaPage");
      if (conf == 'success') { // Navigate Away
        if (this.SalesAreaComp.indexExpanded != undefined && this.SalesAreaComp.indexExpanded != 0) {
          this.SalesAreaComp.expandFirst('General', 0);
        }
        if (this.CurrentOpenAccordian == "Sales Area") {
          this.SalesAreaComp.DataBindonNavigate(eventDet);
        }
        else if (this.CurrentOpenAccordian == "Menu Group") {
          this.menuGrp.DataBindonNavigate(eventDet);
        }
        else if (this.CurrentOpenAccordian == "Menu Page") {
          this.menuPages.DataBindonNavigate(eventDet);
        }
        this.dialogser.ClearSession();

        //General Data
        this.dialogser.ModifiedId = eventDet.id;
        this.helper.setValue('Sessionid_help', eventDet.id);
        this.tableRowId = eventDet.id;
        this.tableRowColor = eventDet.id;
        this.flipcomp.rowClicked = eventDet.id;
        this.isInvalid = false;
      }
      else // Cancel
      {
        //General Data
        if (this.CurrentOpenAccordian == "Sales Area") {
          this.SalesAreaComp.DataBindonCancel();
          this.flipcomp.rowClicked = this.dialogser.ModifiedId;
          this.tableRowColor = this.dialogser.ModifiedId;

          this.areabuildsessData.emit({
            data: this.SalesAreaComp.salesAreagenComp.SaleAreaGeneralForm.value,
            event: "SalesAreaPage", val: true,
            itmname: this.SalesAreaComp.salesAreagenComp.SaleAreaGeneralForm.value.name
          });
        }
        else if (this.CurrentOpenAccordian == "Menu Group") {
          this.menuGrp.DataBindonCancel();
          this.flipcomp.rowClicked = this.helper.getValue('GetSessionData2').id;
          this.tableRowColor = this.helper.getValue('GetSessionData2').id;

          this.areabuildsessData.emit({
            data: this.menuGrp.menuGrpgenComp.MenuGroupGeneralForm.value,
            event: "SalesAreaPage", val: true,
            itmname: this.menuGrp.menuGrpgenComp.MenuGroupGeneralForm.value.name
          });
        }
        else if (this.CurrentOpenAccordian == "Menu Page") {
          this.menuPages.DataBindonCancel();
          this.flipcomp.rowClicked = this.helper.getValue('GetSessionData3').id;
          this.tableRowColor = this.helper.getValue('GetSessionData3').id;

          this.areabuildsessData.emit({
            data: this.menuPages.menuPagegenComp.MenuPageGeneralForm.value,
            event: "SalesAreaPage", val: true,
            itmname: this.menuPages.menuPagegenComp.MenuPageGeneralForm.value.name
          });
        }
      }
    }
    else {
      if (eventDet != undefined && eventDet != null && eventDet != '') {
        if (eventDet.id != undefined && eventDet.id != null && eventDet.id != '') {
          if (this.SalesAreaComp.indexExpanded != undefined && this.SalesAreaComp.indexExpanded != 0) {
            this.SalesAreaComp.expandFirst('General', 0);
          }
          this.dialogser.ModifiedId = eventDet.id;
          this.helper.setValue('Sessionid_help', eventDet.id);
          this.tableRowId = eventDet.id;
          this.tableRowColor = eventDet.id;
        }
      }
    }
  }

  btnActiveStatus(event) {
    this.buttonDisableVal = event;
  }

  OnSave() {
    if (this.CurrentOpenAccordian == "Sales Area") {
      this.SalesAreaComp.OnSaveSalesAreaGeneral();
      // if (this.SalesAreaComp.SaleAreaGeneralForm.invalid) {
      //   this.isInvalid = true;
      // }
      // else {
      // this.isInvalid = false;
      // }
    }
    else if (this.CurrentOpenAccordian == "Menu Group") {
      this.menuGrp.OnSave();
    }
    else if (this.CurrentOpenAccordian == "Menu Page") {
      this.menuPages.OnSave();
    }
    this.dialogser.ClearSession();
  }

  getareabuildsessData(e) {
    this.areabuildsessData.emit(e)
  }
}
