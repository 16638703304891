import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import { BaseService } from '../base/base.service';
import { Router } from '@angular/router';
import { IbreakValue, IGetBreak } from '../interfaces/iemployee';
import { map } from 'rxjs/operators';
import { IListInfo, IActiveUser } from '../interfaces/iadmin';

@Injectable({
  providedIn: 'root'
})
export class BreakService {
  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) { }

    GetBreakList(isBreak: IGetBreak) {
      return this.base.post('Time/GetBreakList', isBreak).pipe(map((response: any) => {
        return response;
      }));
    }
    UserBreakEmployee(e: IbreakValue) {
      return this.base.post('Time/SaveBreak', e).pipe(map((response: any) => {
        return response;
      }));
    }
    IsActiveBreakUser(getvalue: IActiveUser) {
      return this.base.post('Time/ActivateDeActivateBreak', getvalue).pipe(map((response: any) => {
        return response;
      }));
    }
    
}
