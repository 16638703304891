import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ThemeComponent } from 'src/app/theme/theme.component';
import { HierachyCompanyComponent } from './hierachy-company/hierachy-company.component';
import { HierachyStoreComponent } from './hierachy-store/hierachy-store.component';
import { HierachyDealerComponent } from './hierachy-dealer/hierachy-dealer.component';

const routes: Routes = [
  // { path: '', component: ThemeComponent,
  //   children: [
  //     { path: 'company', component: HierachyCompanyComponent},
  //     { path: 'store', component: HierachyStoreComponent},
  //     { path: 'dealer', component: HierachyDealerComponent},
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HierachyRoutingModule { }
 