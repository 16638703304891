import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { deleteEventData, IGetEventPrice } from 'src/app/interfaces/imenu';
import { EventPricingService } from 'src/app/services/event-pricing.service';
import { EventPricingGeneralComponent } from '../event-pricing-general/event-pricing-general.component';
import { SchedulingWidgetComponent } from 'src/app/elements/scheduling-widget/scheduling-widget.component';
import * as moment from 'moment';
import { PricingBuilderComponent } from 'src/app/elements/pricing-builder/pricing-builder.component';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-event-pricing',
  templateUrl: './event-pricing.component.html',
  styleUrls: ['./event-pricing.component.css']
})
export class EventPricingComponent implements OnInit {
  private buttonDisable: boolean;
  public displayedColumns = [];
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  loginInfo: any
  public tableRowId: any;
  public tableRowColor: any;
  //@Input() public buttonDisable;
  public activeDeactiveValue: any;
  public EmployeeTableData: any;
  submittedGeneral: any;
  onSaveScheduleId: any;
  pricingbuildid: any = 0;
  isInvalid: boolean = false;
  isInvalidSchedule: boolean = false;
  public modifierItemsData: any;
  indexExpanded: number = 0;

  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public eventprisessData = new EventEmitter();

  @ViewChild(EventPricingGeneralComponent) generalComponent: EventPricingGeneralComponent;
  @ViewChild(SchedulingWidgetComponent) scheduleComponent: SchedulingWidgetComponent;
  @ViewChild(PricingBuilderComponent) pricingComponent: PricingBuilderComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  selectedItems: any[];
  dropDownClicked: boolean = false;

  constructor(private helper: HelperService, private toaster: ToasterService, private eventService: EventPricingService,
    private dialogser: DialogService) { }
  buttonStatus: any;

  ngOnInit(): void {
    this.buttonStatus = false;
    this.buttonDisable = false;
    this.employeeName = "Event Pricing Name";
    this.employee = "Event Pricing";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.GetEventList();
    this.pricingbuildid = 0;
    this.selectedItems = [null];
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
    }
  }
  matopen:boolean = false;
  matstate($event){
    this.matopen =$event
  }
  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  GetEventList() {
    const eventData: IGetEventPrice = {
      id: 0,
      tenantId: this.loginInfo.tenantId,
    }

    this.eventService.selectEventPricing(eventData) // select method
      .subscribe(res => {
        this.EmployeeTableData = res
        this.dataSource.data = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Event Pricing Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  getGeneralDetail(event) {
    this.tableRowId = event.res.id;
    this.scheduleComponent.onSaveScheduler();
    this.pricingComponent.savePricingBuilder();
    this.GetEventList();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name);
  }

  saveIdValue(e) {
    this.onSaveScheduleId = e;
  }

  async getTableIdx(event) {
    //this.gentab.isFormPageDirty();
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("eventPage");
      if (conf == 'success') { // Navigate Away
        this.dialogser.ClearSession();
        this.generalComponent.eventPricingForm.reset();

        //General Data
        this.dialogser.ModifiedId = event;
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.isInvalid = false;
        this.generalComponent.requiredError = false;
        this.isInvalidSchedule = false;
        this.generalComponent.errorValue = "";
        this.flipcomp.rowClicked = event;
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
      }
      else // Cancel
      {
        //General Data
        this.generalComponent.eventPricingForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.eventprisessData.emit({
          data: this.generalComponent.eventPricingForm.value, event: "eventPage", val: true,
          itmname: this.generalComponent.eventPricingForm.value.name
        });
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event);
      this.tableRowId = event;
      this.tableRowColor = event;
      this.isInvalid = false;
      this.generalComponent.requiredError = false;
      this.isInvalidSchedule = false;
      this.generalComponent.errorValue = "";
      this.pricingComponent.PatchPriceBuilder(this.tableRowId);
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
    }
  }

  getTableName(name) {
    this.employeeName = name;
    this.employee = "Event Pricing";
    this.employeeIcon = "assets/components/images/path.png";
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
  }

  expandPanel(index, e?) {
    this.indexExpanded = index;
  }
  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }
  }
  onChangeAccordion(e, index) {
    this.indexExpanded = index;
  }

  async clearData(event?) {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenForm();
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }

      //let wagesGroup = this.pricingComponent.pricingForm;
      // let dynControl = wagesGroup.controls['filters'] as FormArray;
      // for (let j = 0; j < dynControl.length; j++) {
      //   if (!((<FormArray>this.pricingComponent.pricingForm.get('filters')).length == 1)) {
      //     (<FormArray>this.pricingComponent.pricingForm.get('filters')).removeAt(i);
      //   }
      // }
    }
    else {
      var conf = await this.dialogser.confirm("eventPage");
      if (conf == 'success') { // Navigate Away
        this.ClearGenForm();
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
      }
      else {
        this.generalComponent.eventPricingForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenForm() {
    this.dialogser.ClearSession();
    this.generalComponent.errorValue = "";
    this.generalComponent.requiredError = false;
    this.isInvalid = false;
    this.isInvalidSchedule = false;
    this.generalComponent.eventPricingForm.reset();
    this.generalComponent.ngOnInit();
    this.employeeName = "Event Pricing Name";
    //this.tableRowColor = 'transparent';
    this.flipcomp.selectTableRowColor(0, 0, this.employeeName);
    this.titleName.emit(this.employeeName);
    this.generalComponent.submittedGeneral = false;
    this.generalComponent.salesAreaObj.reset();
    this.scheduleComponent.ScheduleForm.reset();
    this.pricingComponent.pricingForm.reset();
    this.scheduleComponent.ngOnInit();
    this.pricingComponent.ngOnInit();
    this.scheduleComponent.submittedGeneral = false;
    this.pricingComponent.submittedGeneral = false;
    this.scheduleComponent.repeatWeeks.reset();
    this.scheduleComponent.daysOfTheWeek.reset();
    this.scheduleComponent.checkedMonthList = [];
    this.scheduleComponent.checkedWeekDaysList = [];
    this.scheduleComponent.checkedMonthDaysList = [];
    this.scheduleComponent.checkedDaysOfTheWeekList = [];
    this.scheduleComponent.checkedRepeatList = [];
    this.onSaveScheduleId = 0;
    this.pricingbuildid = 0;
    //this.scheduleComponent.specificselectedItems = [];

    for (var i = 0; i < this.scheduleComponent.weekDays.length; i++) {
      this.scheduleComponent.weekDays[i].isSelected = false;
    }
    for (var i = 0; i < this.scheduleComponent.calenderDates.length; i++) {
      this.scheduleComponent.calenderDates[i].isSelected = false;
    }
    for (var i = 0; i < this.scheduleComponent.yearMonth.length; i++) {
      this.scheduleComponent.yearMonth[i].isSelected = false;
    }

    this.tableRowId = 0;
    this.generalComponent.eventPricingForm.patchValue({
      isActive: true,
    });
  }

  checkValue(event) {
    this.activeDeactiveValue = event
    let currentTarget = event.e.currentTarget.checked
    const active: deleteEventData = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked
    }
    this.eventService.activateDeactivate(active).subscribe(
      (response) => {
        // this.clearData(0);
        if (currentTarget) {
          this.toaster.successPopUp("Event Pricing is Active");
        } else {
          this.toaster.successPopUp("Event Pricing is Deactive");
        }
        this.GetEventList();
      },
    )
  }

  SaveScheduler(e) {
    this.scheduleComponent.ScheduleForm.value.isActive = true;
    this.scheduleComponent.ScheduleForm.value.id = this.onSaveScheduleId == null ? 0 : this.onSaveScheduleId;
    this.scheduleComponent.ScheduleForm.value.tenantId = this.loginInfo.tenantId;
    this.scheduleComponent.ScheduleForm.value.createdBy = this.loginInfo.userId;
    this.scheduleComponent.ScheduleForm.value.modifiedBy = this.loginInfo.userId;
    this.scheduleComponent.ScheduleForm.value.parentID = this.tableRowId == null ? 0 : this.tableRowId;
    this.scheduleComponent.ScheduleForm.value.uom = this.scheduleComponent.ScheduleForm.value.scheduleRadio;
    this.scheduleComponent.ScheduleForm.value.noEnd = this.scheduleComponent.ScheduleForm.value.dateRadio == "noEnd" ? true : false;
    this.scheduleComponent.ScheduleForm.value.isWholeDay = this.scheduleComponent.ScheduleForm.value.timeDays == "wholeDay" ? true : false;
    this.scheduleComponent.ScheduleForm.value.repeatDays = JSON.stringify(this.scheduleComponent.ScheduleForm.value.repeatDays);
    //this.scheduleComponent.ScheduleForm.value.repeatWeeks = JSON.stringify(this.scheduleComponent.ScheduleForm.value.repeatWeeks);
    this.scheduleComponent.ScheduleForm.value.repeatMonths = JSON.stringify(this.scheduleComponent.ScheduleForm.value.repeatMonths);
    //this.scheduleComponent.ScheduleForm.value.daysOfTheWeek = JSON.stringify(this.scheduleComponent.ScheduleForm.value.daysOfTheWeek);
    this.scheduleComponent.ScheduleForm.value.moduleID = null;
    this.scheduleComponent.ScheduleForm.value.startDate = moment(new Date(this.scheduleComponent.ScheduleForm.value.startDate)).format('YYYY-MM-DD');
    this.scheduleComponent.ScheduleForm.value.endDate = moment(new Date(this.scheduleComponent.ScheduleForm.value.endDate)).format('YYYY-MM-DD');

    //this.scheduleComponent.ScheduleForm.controls.startDate.setValue(this.scheduleComponent.ScheduleForm.value.startDate);
    this.scheduleComponent.ScheduleForm.value.daysOfTheMonth = JSON.stringify(this.scheduleComponent.ScheduleForm.value.daysOfTheMonth);

    this.eventService.saveScheduler(this.scheduleComponent.ScheduleForm.value).subscribe(
      async (response) => {
        this.onSaveScheduleId = response.id;
        this.scheduleComponent.submittedGeneral = false;

        //await this.dialogser.ClearSession();
        //this.toaster.successPopUp(response.message);
        //this.GetEventList();
        //this.scheduleComponent.ScheduleForm.reset();
        //this.clearData()
        //this.scheduleComponent.ScheduleForm.value.emit(response.id);
        // this.scheduleComponent.ngOnInit();
      })
  }

  async SavePricingBuilder(e) {
    this.pricingComponent.pricingForm.value.isActive = true;
    this.pricingComponent.pricingForm.value.id = this.pricingbuildid == null ? 0 : this.pricingbuildid;
    this.pricingComponent.pricingForm.value.eventPricingID = this.tableRowId == null ? 0 : this.tableRowId;
    this.pricingComponent.pricingForm.value.tenantId = this.loginInfo.tenantId;
    this.pricingComponent.pricingForm.value.createdBy = this.loginInfo.userId;
    this.pricingComponent.pricingForm.value.modifiedBy = this.loginInfo.userId;

    this.eventService.SaveEventBuilder(this.pricingComponent.pricingForm.value).subscribe(
      async (response) => {
        this.pricingbuildid = response.id;
        this.toaster.successPopUp(response.message);
        //await this.dialogser.ClearSession();
        this.submittedGeneral = false;
        this.pricingComponent.PatchPriceBuilder(this.tableRowId);
        return;
      })
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  @Input()
  public set buttonState(btnst) {
    this.buttonStatus = !btnst;
  }

  async onSaveGeneral() {
    var schedulevalue = this.scheduleComponent.ScheduleForm.controls;
    var freqvalue = schedulevalue.frequency.value;
    var scheduleRadiovalue = schedulevalue.scheduleRadio.value;
    var endAftervalue = schedulevalue.endAfter.value;
    var startDatevalue = schedulevalue.startDate.value;

    if (this.scheduleComponent.checkedMonthDaysList != undefined && this.scheduleComponent.checkedMonthDaysList != null) {
      if (this.scheduleComponent.checkedMonthDaysList.length > 0) {
        for (var a = 0; a < this.scheduleComponent.checkedMonthDaysList.length; a++) {
          if (this.scheduleComponent.checkedMonthDaysList[a] == "") {
            this.scheduleComponent.checkedMonthDaysList.splice(a, 1);
          }
        }
      }
    }

    if (this.scheduleComponent.checkedMonthList != undefined && this.scheduleComponent.checkedMonthList != null) {
      if (this.scheduleComponent.checkedMonthList.length > 0) {
        for (var b = 0; b < this.scheduleComponent.checkedMonthList.length; b++) {
          if (this.scheduleComponent.checkedMonthList[b] == "") {
            this.scheduleComponent.checkedMonthList.splice(b, 1);
          }
        }
      }
    }

    if (freqvalue == undefined || freqvalue == null || freqvalue == "" || freqvalue == "0") {
      freqvalue = 0;
    }

    if (endAftervalue == undefined || endAftervalue == null || endAftervalue == "" || endAftervalue == "0") {
      endAftervalue = 0;
    }


    if (this.generalComponent.eventPricingForm.invalid) {
      if ((this.generalComponent.eventPricingForm.value.name == null) || (this.generalComponent.eventPricingForm.value.name == "") || (this.generalComponent.eventPricingForm.value.displayName == null) || (this.generalComponent.eventPricingForm.value.displayName == "")) {
        this.isInvalid = true;
        this.generalComponent.requiredError = true;
      }
      else {
        this.isInvalid = false;
      }
    }


    else if (startDatevalue != undefined && startDatevalue != null && startDatevalue != "") {
      this.isInvalid = false;
      if (scheduleRadiovalue == "optDaily" && freqvalue == 0) {
        this.isInvalidSchedule == true;
        this.toaster.errorPopUp("Please Enter No. of Frequency Every Days");
        return;
      }
      else if (scheduleRadiovalue == "optWeekly" && freqvalue == 0) {
        this.isInvalidSchedule == true;
        this.toaster.errorPopUp("Please Enter No. of Frequency Every Week");
        return;
      }
      else if (scheduleRadiovalue == "optWeekly" && this.scheduleComponent.checkedWeekDaysList.length == 0) {
        this.isInvalidSchedule == true;
        this.toaster.errorPopUp("Please select the specific days in the week");
        return;
      }
      else if (scheduleRadiovalue == "optMonthly" && freqvalue == 0) {
        this.isInvalidSchedule == true;
        this.toaster.errorPopUp("Please Enter No. of Frequency Every Month");
        return;
      }
      else if ((scheduleRadiovalue == "optMonthly" || scheduleRadiovalue == "optCustom")
        && this.scheduleComponent.ScheduleForm.value.weekDates == "specificMonth" &&
        this.scheduleComponent.checkedMonthDaysList.length == 0) {
        this.isInvalidSchedule == true;
        this.toaster.errorPopUp("Please Select Specific day(s) of the month");
        return;
      }
      else if ((scheduleRadiovalue == "optMonthly" || scheduleRadiovalue == "optCustom")
        && this.scheduleComponent.ScheduleForm.value.weekDates == "repeatsEvery" &&
        (this.scheduleComponent.specificdaysOfTheWeek.length == 0 || this.scheduleComponent.specificselectedItems.length == 0)) {
        this.isInvalidSchedule == true;
        this.toaster.errorPopUp("Please select the specific weeks and days");
        return;
      }
      else if (scheduleRadiovalue == "optYearly" && freqvalue == 0) {
        this.isInvalidSchedule == true;
        this.toaster.errorPopUp("Please Enter No. of Frequency Every Year");
        return;
      }
      else if ((scheduleRadiovalue == "optYearly" || scheduleRadiovalue == "optCustom")
        && this.scheduleComponent.checkedMonthList.length == 0) {
        this.isInvalidSchedule == true;
        this.toaster.errorPopUp("Please Select the Month");
        return;
      }

      if (this.scheduleComponent.ScheduleForm.value.dateRadio == "EndDate") {
        if (schedulevalue.endDate.value == undefined || schedulevalue.endDate.value == null || schedulevalue.endDate.value == "") {
          this.isInvalidSchedule == true;
          this.toaster.errorPopUp("Please Select End Date");
          return;
        }
        else {
          const startdatecons = moment(new Date(schedulevalue.startDate.value), 'DD-MM-YYYY').format('YYYY-MM-DD');
          const endDatecons = moment(new Date(schedulevalue.endDate.value), 'DD-MM-YYYY').format('YYYY-MM-DD');
          if (startdatecons > endDatecons) {
            this.isInvalidSchedule == true;
            this.toaster.errorPopUp("Start Date should be lesser than or Equal to End Date");
            return;
          }
        }
      }

      if (this.scheduleComponent.ScheduleForm.value.timeDays == "specificTime") {
        if (schedulevalue.startTime.value == undefined || schedulevalue.startTime.value == null || schedulevalue.startTime.value == "") {
          this.isInvalidSchedule == true;
          this.toaster.errorPopUp("Please Select Start Time");
          return;
        }
        if (schedulevalue.endTime.value == undefined || schedulevalue.endTime.value == null || schedulevalue.endTime.value == "") {
          this.isInvalidSchedule == true;
          this.toaster.errorPopUp("Please Select End Time");
          return;
        }
      }

      if (this.scheduleComponent.ScheduleForm.value.dateRadio == "EndAfter" && endAftervalue == 0) {
        this.isInvalidSchedule == true;
        this.toaster.errorPopUp("Please Enter End After Occurances");
        return;
      }
      this.generalComponent.onSaveGeneral();
      await this.dialogser.ClearSession();
    }
    else {
      this.toaster.errorPopUp("Please Select Schedule Start Date");
      this.isInvalidSchedule = true;
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  geteventprisessData(e) {
    this.eventprisessData.emit(e)
  }

  DropDownClicked(e) {
    this.dropDownClicked = e;
  }

  DetectPanel(event) {
    this.dropDownClicked = false;
  }
}
