import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Ilogger } from '../interfaces/ilogger';
import { BaseService } from '../base/base.service';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import { Router } from '@angular/router';
import { ILogger } from '@aspnet/signalr';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TraceLoggerService {
  
  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) { }

  TraceLogger(logg: Ilogger) {
    return this.http.post('http://204.236.184.97/TraceLogger/SaveTraceLog', logg).pipe(map((response: any) => {
      return response;
    }));
  }

public SaveLoggerInformation(tenant,logType,logSource,logDetails,createdBy)
{
  const logg: Ilogger={
    projectName:'FLip',
    logSource:logSource,
    logType:logType,
    logDetails:logDetails,
    tenantid:tenant,
    createdby:createdBy,
    serverAddress:'204'
  }

  this.TraceLogger(logg).subscribe(
    (response) => {
         
      console.log(response.message);
   
    }),err=>console.error('Logg Error',err),
    ()=>console.log('HTTP request completed.')

}
}
  

