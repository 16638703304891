import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployeeRoutingModule } from './employee-routing.module';
import { Routes, RouterModule } from '@angular/router';

import { TimeEditComponent } from './time-edit/time-edit.component';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { MatSelectFilterModule } from 'mat-select-filter';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsDatepickerModule, TabsModule } from 'ngx-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { EmployeeUserComponent } from './employee-user/employee-user.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion'
import { FlexLayoutModule } from '@angular/flex-layout';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { EmployeeGeneralComponent } from './employee-general/employee-general.component';
import { EmployeePermissionComponent } from './employee-permission/employee-permission.component';
import { EmployeeWagesComponent } from './employee-wages/employee-wages.component';
import { BreakTypeComponent } from './break-type/break-type.component';
import { EmployeeGeneralBreakComponent } from './employee-general-break/employee-general-break.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TitleCardComponent } from 'src/app/elements/title-card/title-card.component';

import { TimeEditModelComponent } from './time-edit-model/time-edit-model.component';
import { FliptableAccordionComponent } from 'src/app/elements/fliptable-accordion/fliptable-accordion.component'
import { DragDropModule } from '@angular/cdk/drag-drop';

import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { HierachyEmployeeComponent } from './hierachy-employee/hierachy-employee.component';
import { HierachyEmployeeGeneralComponent } from './hierachy-employee-general/hierachy-employee-general.component';
import { PayrollComponent } from './payroll/payroll.component';
import { PayrollGeneralComponent } from './payroll-general/payroll-general.component'
import { SpaceValidatorPipe } from 'src/app/space-validator.pipe';
import { SchedulingWidgetComponent } from 'src/app/elements/scheduling-widget/scheduling-widget.component';
import { PricingBuilderComponent } from 'src/app/elements/pricing-builder/pricing-builder.component';
import { FilterTablePopupComponent } from 'src/app/elements/filter-table-popup/filter-table-popup.component';
import { FliptableFilterHeaderComponent } from 'src/app/elements/fliptable-filter-header/fliptable-filter-header.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxCurrencyModule } from "ngx-currency"; 
import { StrengthCheckerComponent } from './hierachy-employee-general/strength-checker.component';
// import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
// import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { DecimalLimitDirective } from 'src/app/directives/decimal-limit.directive';

const appRoutes: Routes = []

@NgModule({
  declarations: [
    TimeEditComponent,
    EmployeeUserComponent,
    FliptableComponent,
    EmployeeGeneralComponent,
    EmployeePermissionComponent,
    EmployeeWagesComponent,
    BreakTypeComponent,
    EmployeeGeneralBreakComponent,
    FilterTablePopupComponent,
    TitleCardComponent,
    TimeEditModelComponent,
    FliptableAccordionComponent,
    HierachyEmployeeComponent,
    HierachyEmployeeGeneralComponent,
    PayrollComponent,
    PayrollGeneralComponent,
    SpaceValidatorPipe,
    SchedulingWidgetComponent,
    PricingBuilderComponent,
    FliptableFilterHeaderComponent,
    StrengthCheckerComponent,
    DecimalLimitDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule,
    DataTablesModule,
    EmployeeRoutingModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    BrowserModule,
    MatSelectModule,
    DragDropModule,
    MatSelectFilterModule,
    RouterModule.forChild(appRoutes),
    NgxIntlTelInputModule,
    RouterModule.forChild(appRoutes),
    TimepickerModule.forRoot(),
    MatCheckboxModule,
    // NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    TabsModule.forRoot(),
    NgMultiSelectDropDownModule,
    MatAutocompleteModule,
    NgxCurrencyModule,
    // NgxMatMomentModule,    
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule
  ],
  exports:[TimeEditComponent,EmployeeUserComponent,EmployeeGeneralComponent,SpaceValidatorPipe,
    EmployeePermissionComponent,EmployeeWagesComponent,BreakTypeComponent,EmployeeGeneralBreakComponent,
    FliptableComponent,TitleCardComponent,FliptableAccordionComponent,HierachyEmployeeComponent,PayrollComponent,
    SchedulingWidgetComponent,PricingBuilderComponent, FilterTablePopupComponent,FliptableFilterHeaderComponent,DecimalLimitDirective]
  

})
export class EmployeeModule { }
