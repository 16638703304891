import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { IshiftValue } from 'src/app/interfaces/itime';
import { AdminService } from 'src/app/services/admin.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { iGetIngnorePrintGroup } from 'src/app/interfaces/iemployee';
import { EmployeeService } from 'src/app/services/employee.service';
import { ToasterService } from 'src/app/helper/toaster.service';

interface Transaction {
  value: any;
  viewValue: string;
}

@Component({
  selector: 'app-cash-management',
  templateUrl: './cash-management.component.html',
  styleUrls: ['./cash-management.component.css']
})

export class CashManagementComponent implements OnInit {
  CashManagementForm: FormGroup;
  id: any = 0;
  roleId: any = 0;
  buttonDisable: boolean = false;
  submittedSaveGeneral = false;
  isSubmitted = false;
  isValidated = false;
  name = 'Angular';
  selectedItems = [];
  isActive = false;

  Transactions: Transaction[] = [
    { value: [], viewValue: 'Cash Drawer' },
    { value: [], viewValue: 'Server Banking' },
    { value: [], viewValue: 'No Cash Access' }
  ];

  public cashTransaction: any = [];
  public noSaleTransaction: any = [];
  public paidIn: any = [];
  public paidOut: any = [];
  public cashRefund: any = [];
  isShown: boolean = true;
  dropdownSettings: IDropdownSettings = {};
  dropdownList = [];
  loginInfo: any;
  ignorePrintGrp: FormControl = new FormControl('');
  @Output() public CashMgtData_child = new EventEmitter();
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }
  constructor(private formBuilder: FormBuilder, private ObjAdmService: AdminService, private objemp: EmployeeService,
    private toaster: ToasterService, private helper: HelperService) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData2', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.CashManagementForm = this.formBuilder.group({
      id: [0],
      roleId: [0],
      cashTransaction: [null],
      noSaleTransaction: [null],
      paidIn: [null],
      paidOut: [null],
      cashRefund: [null],
      printTagsObj: [null],
      isActive: [false],
      ignorePrintGrp: new FormControl(''),
    })
    this.GetCashMasterList('CT');
    this.dropdownSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 2,
    };
  }

  @Input()
  public set getRoleID(RID) {
    this.roleId = RID;
  }

  @Input()
  public set DataIdx(tableRowId) {
    this.ngOnInit();
    this.roleId = tableRowId;
    this.BindIgnorePrintGrp(this.roleId);
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set getcashmgtdetailslist(e1) {
    if (e1 != undefined && e1 != null) {
      if (e1.cashTransaction != "CT3") {
        this.isShown = true;
        this.Bind_ddlData(e1.cashTransaction);
      }
      else {
        this.noSaleTransaction = null,
          this.paidIn = null,
          this.paidOut = null,
          this.cashRefund = null,
          this.isShown = false;
      }
      this.id = e1.id == null ? 0 : e1.id;

      //this.adjustmentDetailForm.patchValue(adjustVal);
      //this.controls[name].patchValue(value[name], {onlySelf: true, emitEvent});

      this.CashManagementForm.patchValue({
        cashTransaction: e1.cashTransaction,
        noSaleTransaction: e1.noSaleTransaction,
        paidIn: e1.paidIn,
        paidOut: e1.paidOut,
        cashRefund: e1.cashRefund,
      });

      var ignorePrintGrpObject = e1.printTagsObj;
      if (ignorePrintGrpObject != null && ignorePrintGrpObject != '') {
        this.selectedItems = JSON.parse(ignorePrintGrpObject);
      }
      else {
        this.selectedItems = [];
      }
    }
    else {
      this.id = 0;
      this.ngOnInit();
      this.selectedItems = [];
    }
  }

  onSelectAll(items: any) {
    // console.log(items);
  }

  onItemDeSelect(item: any) {
    // console.log(item);
  }

  GetCashMasterList(masterCode) {
    const CashList: IshiftValue = {
      parentCode: masterCode
    }

    this.ObjAdmService.GetCodeMaster(CashList)
      .subscribe(res => {
        if (masterCode == 'CT') {
          this.cashTransaction = res;
        }
      })
  }

  GetCashTransList(value) {
    this.onchange(this.CashManagementForm.value);

    if (value != "CT3") {
      this.isShown = true;
      this.Bind_ddlData(value);
    }
    else {
      this.noSaleTransaction = null,
        this.paidIn = null,
        this.paidOut = null,
        this.cashRefund = null,
        this.isShown = false;
    }
  }

  Bind_ddlData(masterCode) {
    const CashList: IshiftValue = {
      parentCode: masterCode
    }
    this.ObjAdmService.GetCodeMaster(CashList)
      .subscribe(res => {
        this.noSaleTransaction = res;
        this.paidIn = res;
        this.paidOut = res;
        this.cashRefund = res;
      })
  }

  BindIgnorePrintGrp(roleidvalue) {
    const ipg: iGetIngnorePrintGroup = {
      id: roleidvalue,
      tenantid: this.loginInfo.tenantId,
    }
    this.objemp.GetIngnorePrintGroup(ipg)
      .subscribe(res => {
        var jsonipg = res.filter(x => x.IsActive == true);
        var itemipg;
        var jsonToBeIPG = [];
        for (var type in jsonipg) {
          itemipg = {};
          itemipg.key = jsonipg[type].tagId.toString();
          itemipg.value = jsonipg[type].tagName;
          jsonToBeIPG.push(itemipg);
        }
        this.dropdownList = jsonToBeIPG;
      })
  }

  onSaveDetails(RoleID) {
    if (this.CashManagementForm.invalid) {
      return;
    }
    this.submittedSaveGeneral = true;
    this.isValidated = true;
    this.isSubmitted = true;
    this.roleId = RoleID;// this.helper.getValue('roleId')

    this.CashManagementForm.value.isActive = true;
    this.CashManagementForm.value.id = this.id;
    this.CashManagementForm.value.roleId = this.roleId;
    this.CashManagementForm.value.tenantId = this.loginInfo.tenantId;
    this.CashManagementForm.value.createdby = this.loginInfo.userId;

    if (this.selectedItems != null) {
      var Arrayipg = [], JsonArrayipg = [];
      var clientsipg = this.selectedItems;
      for (var a = 0, len = clientsipg.length; a < len; a++) {
        Arrayipg.push(clientsipg[a].key);
        JsonArrayipg.push(clientsipg[a]);
      }
      this.CashManagementForm.value.ignorePrintGrp = Arrayipg.toString();
      this.CashManagementForm.value.printTagsObj = JSON.stringify(JsonArrayipg).toString();
    }

    this.objemp.SaveCashManagement(this.CashManagementForm.value).subscribe(
      (response) => {
        this.isSubmitted = false;
        this.id = response.id;
        this.helper.setValue('GetSessionData2', this.CashManagementForm.value);
        this.CashMgtData_child.emit({ data: this.CashManagementForm.value, event: "RolepermissionPage", val: false });
        //this.toaster.successPopUp(response.message);
      })
  }

  ngAfterViewInit() {
    this.CashManagementForm.patchValue(this.helper.getValue('GetSessionData2'));
    this.CashMgtData_child.emit(this.CashManagementForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData2', data);
    this.helper.setValue('OnDirty2', true);
    this.CashMgtData_child.emit({ data: data, event: "RolepermissionPage", val: true });
  }
}
