import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
} from '@angular/core';
import { GiftCardGeneralComponent } from '../gift-card-general/gift-card-general.component';
import {
  iGiftCardSelect,
  ActivatePayment,
} from 'src/app/interfaces/icustompayment';
import { PaymentService } from 'src/app/services/payment.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { GiftcardService } from 'src/app/services/giftcard.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GiftCardDesignerComponent } from '../gift-card-designer/gift-card-designer.component';
import { DialogService } from 'src/app/services/dialog.service';
import { GiftCardFrontComponent } from '../gift-card-front/gift-card-front.component';
import { AppGiftCardBackComponent } from '../app-gift-card-back/app-gift-card-back.component';

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.css'],
})
export class GiftCardComponent implements OnInit {
  private buttonDisable: boolean;
  loginInfo: any = 0;
  giftId: any = 0;
  tableEnable: boolean = true;
  public tableRowId: any;
  public tableRowColor: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  indexExpanded: number = 0;
  public GiftData: any;
  public dataSource = new MatTableDataSource<any>();
  public EmployeeTableData: any;
  public displayedColumns = [];
  columns = [];
  public activeDeactiveValue: any;
  public getDataValue: any;
  public get2ndTipValue: any;
  public getGiftDesign: any;
  isInvalid: boolean = false;
  public modifierItemsData: any;

  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public ItemObj = new EventEmitter();
  @Output() public giftcardsessData = new EventEmitter();

  //@Input() public buttonDisable
  @ViewChild(GiftCardGeneralComponent) GiftGenComp: GiftCardGeneralComponent;
  @ViewChild(GiftCardDesignerComponent)
  GiftDesignComp: GiftCardDesignerComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  @ViewChild(GiftCardFrontComponent) GiftFront: GiftCardFrontComponent;
  @ViewChild(AppGiftCardBackComponent) GiftBack: AppGiftCardBackComponent;
  isGiftDesigner: boolean = false;

  private onDestroy$: Subject<void> = new Subject<void>();
  copyFromDropDown: any[];
  hideCopyFromInitial: boolean;
  bName: any;
  address: any;
  city: any;
  state: any;
  zipCode: any;
  matopen: boolean = false;
  matstate($event) {
    this.matopen = $event
  }
  constructor(
    private objGift: GiftcardService,
    private toaster: ToasterService,
    private helper: HelperService,
    private dialogser: DialogService
  ) { }

  async ngOnInit(): Promise<void> {
    localStorage.setItem('browserPage', 'this.GiftCard');

    this.dialogser.ClearSession();
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.tableEnable = true;
    this.employeeName = 'Gift Cards';
    this.employee = 'Gifts';
    this.employeeIcon = 'assets/components/images/payment.png';
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);

    // if(this.loginInfo.tenantId == 0){
    //   this.toaster.error("Please Select store");
    //   this.buttonDisable = true;
    // }else{
    //   this.getGiftCardList();
    // }

    await this.getGiftCardList();
    if (
      this.helper.getValue('OnDirty') == false &&
      this.helper.getValue('OnDirty2') == false
    ) {
      this.tableRowColor = 'transparent';
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  async getGiftCardList(): Promise<any> {
    return new Promise((resolve, reject) => {
      const GiftCardList: iGiftCardSelect = {
        id: this.giftId,
        tenantId: this.loginInfo.tenantId,
      };
      this.objGift
        .SelectGiftCard(GiftCardList)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          this.dataSource.data = res;

          //////////////HIDE COPY FROM IN INITIALLY/////////
          if (this.dataSource.data.length == 0) {
            this.hideCopyFromInitial = true;
          } else {
            this.hideCopyFromInitial = false;
          }
          //////////////HIDE COPY FROM IN INITIALLY/////////

          this.copyFromDropDown = this.dataSource.data;
          this.EmployeeTableData = res;
          this.dataSource.data = this.dataSource.data.filter(
            (x) => x.isActive == true
          );
          this.displayedColumns = ['name', 'isActive'];
          this.columns = [
            { columnDef: 'name', header: 'Gift Cards Name' },
            { columnDef: 'isActive', header: 'Status' },
          ];
        }),
        (err) => console.error('Error', err),
        () => console.log('HTTP request completed.');

      if (
        this.helper.getValue('OnDirty') == false &&
        this.helper.getValue('OnDirty2') == false
      ) {
        this.tableRowColor = 'transparent';
      }
      console.log(parseInt(this.helper.getValue('Sessionid_help')), 'seess15')
      if (this.helper.getValue('Sessionid_help') == undefined || this.helper.getValue('Sessionid_help') == null || this.helper.getValue('Sessionid_help') == '') {
        this.helper.setValue('Sessionid_help', 0);
      } else {

        this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
        console.log(parseInt(this.helper.getValue('Sessionid_help')), 'seess1')

      }

      resolve('ok');
    })

  }
  getGeneralDetail(Giftid) {
    //  this.getDataValue=Giftid;
    const ItemTypeList: iGiftCardSelect = {
      id: Giftid,
      tenantId: this.loginInfo.tenantId,
    };
    this.objGift
      .EditGiftCard(ItemTypeList)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res != undefined && res != null && res != '') {
          this.getDataValue = res.general;
          this.bName = this.getDataValue[0].BusinessName;
          this.address = this.getDataValue[0].Address;
          this.city = this.getDataValue[0].City;
          this.state = this.getDataValue[0].State;
          this.zipCode = this.getDataValue[0].Zip;
          this.get2ndTipValue = res.tips;
          this.getGiftDesign = res.design;
        }
      }),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
  }

  getTableIdx(event) {
    if (event != undefined && event != null && event != '') {
      if (
        event.event != undefined &&
        event.event != null &&
        event.event != ''
      ) {
        this.GiftData = event;
        this.employeeName =
          event.event.Name != undefined ? event.event.Name : name;
      }
    }
  }

  async getTableId(event) {
    //this.GiftGenComp.isFormPageDirty();
    if (
      this.helper.getValue('OnDirty') == true ||
      this.helper.getValue('OnDirty2') == true
    ) {
      var conf = await this.dialogser.confirm('GiftCard');
      if (conf == 'success') {
        // Navigate Away
        this.dialogser.ClearSession();
        this.GiftGenComp.GiftCardForm.reset();
        this.GiftDesignComp.GiftCardDesignerForm.reset();

        //General Data
        this.flipcomp.hideCopyFromGiftCardColor = false;
        this.helper.setValue('Sessionid_help', event);
        this.tableRowId = event;
        this.tableRowColor = event;
        this.getGeneralDetail(event);
        this.isInvalid = false;
        this.GiftGenComp.requiredError = false;
        this.GiftGenComp.xmlDatDisplay = '';
        this.flipcomp.rowClicked = event;
        this.GiftGenComp.mgs = '';
        this.GiftGenComp.zipCodeMsg = '';
        this.dialogser.ModifiedId = event;
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
        //Recipe Data
      } // Cancel
      else {
        //General Data
        this.GiftGenComp.GiftCardForm.patchValue(
          this.helper.getValue('GetSessionData1')
        );
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.giftcardsessData.emit({
          data: this.GiftGenComp.GiftCardForm.value,
          event: 'GiftCard',
          val: true,
          itmname: this.GiftGenComp.GiftCardForm.value.BusinessName,
        });
      }
    } else {
      this.dialogser.ModifiedId = event;
      this.flipcomp.hideCopyFromGiftCardColor = false;
      this.helper.setValue('Sessionid_help', event);
      this.tableRowId = event;
      this.tableRowColor = event;
      this.getGeneralDetail(event);
      this.isInvalid = false;
      this.GiftGenComp.xmlDatDisplay = '';
      this.GiftGenComp.requiredError = false;
      this.GiftDesignComp.changeBg(this.GiftDesignComp.GiftCardDesignerForm.value.chooseBackground);
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
    }
  }

  getTableName(name) {
    this.employeeName = name;
    //this.employee = "Gift-Card";
    //this.employeeIcon = "assets/components/images/gift-card.png"
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
  }

  expandPanel(index, e?) {

    if (index == 'Gift Card Designer') {
      this.bName = this.GiftGenComp.GiftCardForm.get('BusinessName').value;
      this.address = this.GiftGenComp.GiftCardForm.get('Address').value;
      this.city = this.GiftGenComp.GiftCardForm.get('City').value;
      this.state = this.GiftGenComp.GiftCardForm.get('State').value;
      this.zipCode = this.GiftGenComp.GiftCardForm.get('Zip').value;
    }
    this.indexExpanded = index;

  }
  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }
  }
  onChangeAccordion(e, index) {
    this.indexExpanded = index;
  }

  async addMenuItem() {
    //this.expandFirst('General', 0);
    if (
      this.helper.getValue('OnDirty') == false &&
      this.helper.getValue('OnDirty2') == false
    ) {
      this.ClearGenData();
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
    } else {
      var conf = await this.dialogser.confirm('GiftCard');
      if (conf == 'success') {
        // Navigate Away
        this.ClearGenData();
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
      } else {
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = 'transparent';
    this.GiftGenComp.errorValue = '';
    this.GiftGenComp.errorValue1 = '';
    this.GiftGenComp.errorValue2 = '';
    this.GiftGenComp.errorValue3 = '';
    this.GiftGenComp.errorValue4 = '';
    this.GiftGenComp.errorValue5 = '';

    this.bName = '';
    this.address = '';
    this.city = '';
    this.state = '';
    this.zipCode = '';

    this.isInvalid = false;
    this.employeeName = 'Gift Card';
    this.tableRowColor = 'transparent';
    this.titleName.emit(this.employeeName);
    this.GiftGenComp.GiftCardForm.reset();
    this.GiftDesignComp.GiftCardDesignerForm.reset();
    this.GiftGenComp.xmlDatDisplay = '';
    this.GiftGenComp.requiredError = false;
    this.GiftGenComp.submittedGeneral = false;
    this.GiftGenComp.hideCopyFrom = false;
    this.GiftDesignComp.GiftCardDesignerForm.controls[
      'chooseBackground'
    ].setValue('blackBg');
    this.GiftDesignComp.whiteBg = false;
    this.GiftDesignComp.clicked = false;
    this.GiftDesignComp.isHundread = false;
    this.GiftDesignComp.isTwoFifty = false;
    this.GiftDesignComp.isFiveHundread = false;
    this.GiftDesignComp.isThousand = false;
    this.GiftGenComp.mgs = '';
    this.GiftGenComp.zipCodeMsg = '';
    this.GiftGenComp.GiftCardForm.patchValue({
      id: 0
    })
    this.GiftDesignComp.clearURL();
  }

  checkValue(event) {
    this.activeDeactiveValue = event;
    let currentTarget = event.e.currentTarget.checked;
    const active: ActivatePayment = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked,
    };
    this.objGift.ActivateGiftCard(active).pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        if (currentTarget) {
          this.toaster.successPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
        }
        this.getGiftCardList();
      }),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
  }

  async OnSave() {
    var saveRes = await this.GiftGenComp.onSaveGeneral();
    this.GiftDesignComp.onSaveGiftDesign(saveRes);
    //   console.log(saveRes.id, 'saveRes.id')
    //  this.helper.setValue('Sessionid_help', saveRes.id);
    console.log('A')
    await this.getGiftCardList();
    console.log('B')
    let giftcarddata = this.helper.getValue('GetSessionData1');

    this.flipcomp.selectTableRowColor(
      saveRes.id,
      saveRes.id,
      giftcarddata.BusinessName
    );

    this.bName = giftcarddata.BusinessName;
    this.address = giftcarddata.Address;
    this.city = giftcarddata.City;
    this.state = giftcarddata.State;
    this.zipCode = giftcarddata.Zip;
    await this.dialogser.ClearSession();
    //  this.tableRowColor = parseInt(saveRes.id);


  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getgiftcardsessData(e) {
    this.giftcardsessData.emit(e);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  isGiftDesignAccordion(e, index) {
    this.isGiftDesigner = e;
    if (this.isGiftDesigner == true) {
      this.indexExpanded = index == this.indexExpanded ? 0 : index;
    }
    this.isGiftDesigner = true;
  }

  // expandPanel(index,e?) {
  //   this.indexExpanded = index == this.indexExpanded ? 0 : index;
  // }

  // expandFirst(TabName,index){
  //   if(TabName == 'General'){
  //     this.indexExpanded = index == this.indexExpanded ? -1 : index;
  //   }
  // }
  //---------------- Copy Gift Card Dropdown Start--------------------------//
  getSelectedDropDownCard(event) {
    this.helper.setValue('Sessionid_help', event);
    this.tableRowId = event;
    this.tableRowColor = event;
    this.getGeneralDetail(event);
    this.isInvalid = false;
    this.GiftGenComp.xmlDatDisplay = '';
    this.GiftGenComp.requiredError = false;
    this.GiftGenComp.GiftCardForm.value.id = 0;
  }

  HideGiftCardCopy(e) {
    this.GiftGenComp.hideCopyFrom = e;
  }

  HideCopyColor(e) {
    this.flipcomp.hideCopyFromGiftCardColor = e;
  }
  //---------------- Copy Gift Card Dropdown End--------------------------//

  DesignGeneral(e) {
    this.GiftDesignComp.getGeneralDesignDetail(e);
  }

  ngAfterViewInit(): void { }
}
