import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AnyMxRecord } from 'dns';
import { EmployeeService } from 'src/app/services/employee.service';
import { GetAllUserList } from 'src/app/interfaces/iemployee';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { IActiveUser } from 'src/app/interfaces/iadmin';
import { AdminService } from 'src/app/services/admin.service';
import { HierachyEmployeeGeneralComponent } from '../hierachy-employee-general/hierachy-employee-general.component';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';
import { browserRefresh } from 'src/app/app.component';

@Component({
  selector: 'app-hierachy-employee',
  templateUrl: './hierachy-employee.component.html',
  styleUrls: ['./hierachy-employee.component.css']
})
export class HierachyEmployeeComponent implements OnInit {
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  loginInfo: any;
  private buttonDisableStatus: boolean;
  @Input() public buttonDisable
  @Input() public dealerEmp
  @Input() public compEmp
  @Input() public isvEmp
  @Input() public dealerHierarchyHide
  @Input() public companyHierarchyHide;

  public displayedColumns = [];
  columns = [];
  public loginid: any;
  removeID: any;
  activeDataSource: any;
  submittedGeneral: any;
  isInvalid: boolean = false;
  public dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(HierachyEmployeeGeneralComponent) generalComponent: HierachyEmployeeGeneralComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  @Output() public hieemp_sessData = new EventEmitter();

  tableEnable: boolean = true;
  public tableRowId: any;
  public tableRowColor: any;
  public tableRowName: any;
  public wagesDataemit: any;
  public permissionDataemit: any;
  public roleDataemit: any;
  public permissionSaveid: any;
  public getRoleData: any;
  public permissionDropItem: any;
  public employeeForm: any;
  public jsonArrayValue: any;
  public empColor: any;
  public currentTarget: boolean;
  public activeDeactiveValue: any;
  public EmployeeTableData: any;
  clickUpdated: any
  public companyRow: any;
  public modifierItemsData: any;

  matopen:boolean = false;
  matstate($event){
    this.matopen =$event
  }
  constructor(private repoService: EmployeeService, private adminService: AdminService, private helper: HelperService,
    private changeDetector: ChangeDetectorRef, private toaster: ToasterService, private dialogser: DialogService) { }

  ngOnInit(): void {
    this.tableRowColor = 'transparent';
    this.employeeIcon = "assets/components/images/path.png"
    this.loginInfo = this.helper.getValue('LoginInfo');
    // this.tenantTypeID = this.loginInfo.tenantTypeId;
    // this.getAllOwners();

    this.adminService.HierachyValue.subscribe(data => {
      this.companyRow = data;
    });

    if (this.isvEmp == true) {
      this.employeeName = "ISV Employee";
      this.employee = "ISV";
      this.loginid = this.companyRow.isv.id;
    }
    else if (this.dealerEmp == true) {
      localStorage.setItem('browserPage', "this.dealerEmp");
      this.employeeName = "Dealer Employee Name";
      this.employee = "Dealer Employee";

      if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" ||
        localStorage.getItem('deal_ID') !== null) {
        if (localStorage.deal_ID != undefined && localStorage.deal_ID != null && localStorage.deal_ID != "") {
          this.loginid = parseInt(localStorage.getItem('deal_ID'));
        }
      }
      else {
        let DealerID
        DealerID = this.companyRow.dealer != undefined && this.companyRow.dealer != null && this.companyRow.dealer != "" ? this.companyRow.dealer : 0;
        if (DealerID == 0) {
          this.toaster.hierarchyPopUp("Please Select Dealer Before Selecting Dealer Employee");
          this.buttonDisable = true;
        } else {
          this.loginid = this.companyRow.dealer.id;
        }
      }
    }
    else if (this.compEmp == true) {
      localStorage.setItem('browserPage', "this.compEmp");
      this.employeeName = "Company Employee Name";
      this.employee = "Company Employee";

      if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" ||
        localStorage.getItem('isDealerSelect') == "" || localStorage.getItem('isDealerSelect') === null) {
        if (localStorage.comp_ID != undefined && localStorage.comp_ID != null && localStorage.comp_ID != "") {
          this.loginid = parseInt(localStorage.getItem('comp_ID'));
        }
      }
      else {
        let CompanyID
        CompanyID = this.companyRow.company != undefined && this.companyRow.company != null && this.companyRow.company != "" ? this.companyRow.company : 0;
        if (CompanyID == 0) {
          this.toaster.hierarchyPopUp("Please Select Company Before Selecting Company Employee");
          this.buttonDisable = true;
        } else {
          this.loginid = this.companyRow.company.id;
        }
      }
    }
    this.getAllOwners();
    this.helper.setValue('hierarchyValue', this.loginid);
    this.repoService.clickValues.subscribe(data => this.clickUpdated = data);

    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  getAllOwners() {
    const employee: GetAllUserList = {
      loginUserId: 0,
      tenantId: this.loginid
    }
    this.repoService.GetAllEmployees(employee)
      .subscribe(res => {
        this.dataSource.data = res;
        this.EmployeeTableData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Name' },
          // { columnDef: 'primaryRole', header: 'Primary Role' },
          { columnDef: 'isActive', header: 'Status' }
        ];
        this.helper.setValue('SaveId', this.permissionSaveid);
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  getGeneralDetail(event) {
    this.getAllOwners();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name)
  }

  async getTableIdx(event) {
    this.generalComponent.dateMgs = '';
    this.generalComponent.dateMgsHire = '';
    this.generalComponent.mgs = '';
    this.generalComponent.zipCodeMsg = '';

    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("dealerEmp");
      if (conf == 'success') { // Navigate Away
        if (event == this.dialogser.ModifiedId) {
          this.generalComponent.getEmployee(event);
        }
        this.dialogser.ClearSession();
        this.generalComponent.GeneralForm.reset();
        //General Data
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.isInvalid = false;
        this.generalComponent.requiredError = false;
        this.flipcomp.rowClicked = event;
        this.dialogser.ModifiedId = event;
      }
      else // Cancel
      {
        //General Data
        this.generalComponent.GeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        this.hieemp_sessData.emit({
          data: this.generalComponent.GeneralForm.value, event: "dealerEmp", val: true,
          itmname: this.generalComponent.GeneralForm.value.firstName + " " + this.generalComponent.GeneralForm.value.lastName
        });

        if (!(this.generalComponent.GeneralForm.invalid)) {
          this.generalComponent.dateMgs = '';
          this.generalComponent.dateMgsHire = '';
          this.generalComponent.requiredError = false;
          this.isInvalid = false;
          return;
        }
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event);
      this.tableRowId = event;
      this.tableRowColor = event;
      this.isInvalid = false;
      this.generalComponent.requiredError = false;
    }
  }

  getTableName(name) {
    this.employeeName = name;
    //this.employee = "ISV Employee";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  getWagesDetails(event) {
    this.wagesDataemit = event;
    this.getAllOwners();
  }

  getPermissionDetails(event) {
    this.permissionDataemit = event;
  }

  getRoleDetails(event) {
    this.roleDataemit = event;
  }

  getPermissionDataEmit(event) {
    this.permissionSaveid = event;
    this.getAllOwners()
  }

  getRoleItemId(event) {
    this.getRoleData = event;
  }

  getPermissonDrop(event) {
    this.permissionDropItem = event;
  }

  async addEmployee() {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    }
    else {
      var conf = await this.dialogser.confirm("dealerEmp");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
      }
      else {
        this.generalComponent.GeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        if (!(this.generalComponent.GeneralForm.invalid)) {
          this.generalComponent.dateMgs = '';
          this.generalComponent.dateMgsHire = '';
          this.generalComponent.requiredError = false;
          this.isInvalid = false;
          return;
        }
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.generalComponent.GeneralForm.reset();
    this.flipcomp.rowClicked = "transparent";
    this.generalComponent.dateMgs = '';
    this.generalComponent.dateMgsHire = '';
    this.generalComponent.mgs = '';
    this.generalComponent.zipCodeMsg = '';
    this.isInvalid = false;
    this.generalComponent.requiredError = false;
    this.generalComponent.clearForm();
    this.ngOnInit();
    this.permissionDataemit = [];
    this.tableRowColor = 'transparent';
    this.generalComponent.submittedGeneral = false;
    this.tableRowId = 0;
    this.generalComponent.GeneralForm.patchValue({
      isActive: true,
      employeeHireDate: new Date()
    });
    this.titleName.emit(this.employeeName);
  }

  public redirectToDetails = (id: string) => {
  }

  public redirectToUpdate = (id: string) => {
  }

  public redirectToDelete = (id: string) => {
  }

  checkValue(event) {
    this.activeDeactiveValue = event
    this.currentTarget = event.e.currentTarget.checked
    const active: IActiveUser = {
      userId: this.loginInfo.userId,
      tenantId: event.id,
      isActive: event.e.currentTarget.checked
    }
    this.repoService.IsActiveEmpUser(active).subscribe(
      (response) => {
        this.getAllOwners();
        if (this.currentTarget) {
          this.toaster.successPopUp("Employee is Active");
        } else {
          this.toaster.successPopUp("Employee is Deactive");
        }
      },
    )
  }

  toggleIsActive(e) {
    this.getAllOwners();
  }

  btnActiveStatus(event) {
    this.buttonDisableStatus = event;
  }

  OnSaveEmployee() {
    this.generalComponent.onSaveGeneral();
    if (this.generalComponent.GeneralForm.invalid) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  gethieempsessData(e) {
    this.hieemp_sessData.emit(e)
  }
}
