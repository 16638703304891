import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CreditCardFirstdataComponent } from '../credit-card-firstdata/credit-card-firstdata.component';
import { CreditCardTsysComponent } from '../credit-card-tsys/credit-card-tsys.component';
import { CreditCardVantivComponent } from '../credit-card-vantiv/credit-card-vantiv.component';
import { AdminService } from 'src/app/services/admin.service';
import { ISelectState } from 'src/app/interfaces/iadmin';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { CreditCardStep4Component } from '../credit-card-step4/credit-card-step4.component';

@Component({
  selector: 'app-credit-card-gateway',
  templateUrl: './credit-card-gateway.component.html',
  styleUrls: ['./credit-card-gateway.component.css']
})
export class CreditCardGatewayComponent implements OnInit {
  buttonDisable: boolean = false;
  id: any = 0;
  GatewaySelection: any;
  loginInfo: any;
  public GenSave = false;
  submittedGeneral = false;
  isSubmitted: boolean = false;
  isValidated = false;
  CreditCardForm: FormGroup;
  data: any;
  mobNumberPattern = "^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$";
  isValidFormSubmitted = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  filteredList2: any = []
  public variables: any = []
  mgs: any;
  zipCodeMsg: any;
  // public variables = ['One', 'Two', 'County', 'Three', 'Zebra', 'XiOn'];
  // public filteredList2 = this.variables.slice();

  errorValue: any;
  errorValue1: any;
  errorValue2: any;
  errorValue3: any;
  errorValue5: any;
  errorValue4: any;
  requiredError: boolean = false;

  @Output() public isInvalid = new EventEmitter();

  @ViewChild(CreditCardStep4Component) cc4Comp: CreditCardStep4Component;
  @Output() public selectvalue = new EventEmitter();
  @Output() public selectCreditCardData = new EventEmitter();
  formPatch: any;
  @Output() public emvClick = new EventEmitter();
  @Output() public nextStepButton = new EventEmitter();
  emvdata: boolean = false;
  filterNodata: any;
  @Output() public CreditCardInput = new EventEmitter();
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }
  constructor(private formBuilder: FormBuilder, private objPayment: PaymentService,
    private helper: HelperService, private toaster: ToasterService, private admin: AdminService) { }
  // @ViewChild(CreditCardComponent) CreditComp: CreditCardComponent;
  @ViewChild(CreditCardFirstdataComponent) firstdataComp: CreditCardFirstdataComponent;
  @ViewChild(CreditCardTsysComponent) tsysComp: CreditCardTsysComponent;
  @ViewChild(CreditCardVantivComponent) vantivComp: CreditCardVantivComponent;

  ngOnInit(): void {
    // this.helper.setValue('pageName','1stPage');
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.CreditCardForm = this.formBuilder.group({
      id: [0],
      // configName: [''],
      businessName: ['', Validators.required],
      phone: [null],
      contactFirstName: ['', Validators.required],
      contactLastName: ['', Validators.required],
      contactEmail: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]{2,4}$')]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],

      platformPulldown: ['', Validators.required],
      gatewaySelection: ['', Validators.required],
      swipe: [false],
      emv: [false],
      app: [false],
      olo: [false],
      kiosk: [false],
      tranCloud: [false],
      // tranCloudId:[''],
      prePaidGift: [''],
      cardPresent: [''],
      cardNotPresent: ['']
    })

    this.CreditCardInput.emit(this.CreditCardForm);
    this.onSelectState()
    //this.selectvalue.emit('tsys');

  }

  onKeypressEvent(elem): boolean {
    const charCode = (elem.which) ? elem.which : elem.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (elem.target.value.length < 3 || (elem.target.value.length > 3 || elem.target.value.length < 11)) {
      if (elem.key === "-") elem.preventDefault();
    }
    // this.GeneralDealerForm.get("zipCode").patchValue(elem.target.value.toUpperCase());
    if (elem.target.value.length <= 10) {
      if (elem.target.value.length === 5) {
        elem.target.value = `${elem.target.value}-`;
      }
      // this.zipCodeMsg = ''
    } else {
      elem.target.value = elem.target.value.slice(0, -1)
    }
    return true


  };

  //@Input() public buttonState;
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }



  @Input()
  public set MenuRowItem(tableRowData) {
    this.errorValue = "";
    this.errorValue1 = "";
    this.errorValue2 = "";
    this.errorValue3 = "";
    this.errorValue4 = "";
    this.errorValue5 = "";
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      this.formPatch = tableRowData[0]
      this.onFormPatch()
      //this.CreditCardForm.setValue({phone:tableRowData[0].phone});
    }
  }

  get GeneralData() { return this.CreditCardForm.controls; }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }




  change(event: MatButtonToggleChange) {
    this.data = event.value;
    this.CreditCardForm.value.gatewaySelection = event.value
  }

  //  async onSaveGeneral() {
  async onSaveGeneral(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.errorValue = "";
      this.errorValue1 = "";
      this.errorValue2 = "";
      this.errorValue3 = "";
      this.errorValue5 = "";
      this.submittedGeneral = true;
      let paymentserv;
      paymentserv = this.CreditCardForm.value;
      paymentserv.userID = this.loginInfo.userId;
      paymentserv.tenantId = this.loginInfo.tenantId;
      paymentserv.isActive = true;
      paymentserv.phone = this.CreditCardForm.value.phone; //this.CreditCardForm.value.phone.number;
      paymentserv.createdBy = this.loginInfo.userId;
      paymentserv.modifiedBy = this.loginInfo.userId;

      const objphone = paymentserv.phone;
      if (objphone != undefined && objphone != "" && objphone != null) {
        let trimphone = objphone.toString().match(/\d/g);
        //const isAllZero1 = trimphone.some(item => item !== "0");
        const isAllZero = trimphone.every((val, i, arr) => val === "0");
        if (parseInt(trimphone.length) != 10) {
          this.toaster.errorPopUp('Phone number must be at least 10 digit');
          return;
        }
        else if (isAllZero == true) {
          this.toaster.errorPopUp('Invalid Mobile Number');
          return;
        }
        else {
          paymentserv.phone = objphone.replace(/[^-()\d ]/g, '');
        }
      }

      if (this.CreditCardForm.invalid) {
        return;
      } else {
        if (this.GeneralData.phone.value != undefined && this.GeneralData.phone.value != null && this.GeneralData.phone.value != "") {
          if (this.GeneralData.phone.value.length <= 12) {
            this.mgs = 'Phone number must be at least 10 digit'
            return
          } else {
            this.mgs = ''
          }
        } else {
          this.mgs = ''

        }
      }
      if (this.CreditCardForm.controls.zip.value != undefined && this.CreditCardForm.controls.zip.value != null && this.CreditCardForm.controls.zip.value != "") {
        if (this.CreditCardForm.controls.zip.value.length <= 4) {
          this.zipCodeMsg = 'Please enter min 5 digits zipcode'
          return
        } else {
          this.zipCodeMsg = ''
        }
      } else {
        this.zipCodeMsg = ''
      }

      this.helper.setValue('GatewayVal', paymentserv);
      this.objPayment.SaveCreditCardGateway(paymentserv).subscribe(res => {
        if (res.id == 0) {

          this.GenSave = false;
        } else {
          this.GenSave = true;
        }

        this.isSubmitted = true;
        this.submittedGeneral = false;
        this.nextStepButton.emit(this.isSubmitted);
        this.id = res.id;
        this.selectCreditCardData.emit({ res: res, name: paymentserv.businessName });
        resolve(res);
        // console.log('A')
        //  this.helper.setValue("CreditCardID",null);
      })
    })
  }

  ngOnDestroy(): void {
    this.helper.setValue(null, "pageName");
  }

  onDropDown(e) {
    this.selectvalue.emit(e)
  }

  onSelectState() {
    const ObjState: ISelectState = {
      id: 0
    }
    this.admin.SelectStateMaster(ObjState).subscribe((response) => {
      this.filteredList2 = response
      this.variables = this.filteredList2.slice()
    })
  }

  onKey(value) {
    this.filteredList2 = this.search(value);
  }

  getSubtier(value) {
  }

  search(value: string) {
    let filteredList2 = this.variables.filter((unit) =>
      unit.id.indexOf(value.toUpperCase()) > -1);
    if (value.length == 1) {
      let filterdata = filteredList2.filter((x) =>
        x.id.substring(0, 1) == value.toUpperCase());
      return [...filterdata];
    } else if (value.length == 2) {
      let filterSecdata = filteredList2.filter((y) =>
        y.id.substring(0, 2) == value.toUpperCase());
      return [...filterSecdata];
    } else {
      this.filterNodata = true
      return [...filteredList2];
    }
  }

  numberOnly2(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (event.target.value.length <= 14) {
      if (event.target.value.length === 3) {
        event.target.value = `(${event.target.value}) `;
      }
      if (event.target.value.length === 9) {
        event.target.value = `${event.target.value}-`;
      }
      if (event.target.value.length === 11) {
        event.target.value = `${event.target.value}`;
      }
      if (event.target.value.length >= 13) {
        this.mgs = ''
      }
      // if (event.target.value.length <= 12) {
      //   this.mgs = 'Phone number must be at least 10 numbers'
      //   return true
      // } else if(event.target.value.length == 0) {
      //   this.mgs = ''
      // }
      // else  {
      //   this.mgs = ''
      // }

    } else {
      event.target.value = event.target.value.slice(0, -1)
    }
    return true;
  }

  onFormPatch() {
    this.CreditCardForm.patchValue(this.formPatch);
    this.id = this.formPatch.id;
    var platformPulldownVar = this.formPatch.platformPulldown;
    this.selectvalue.emit(platformPulldownVar);

    if (this.formPatch.platformPulldown == 'firstdata') {
      if (this.firstdataComp != undefined && this.firstdataComp != null) {
        if (this.firstdataComp.FirstDataForm != undefined && this.firstdataComp.FirstDataForm != null) {
          this.firstdataComp.FirstDataForm.patchValue(this.formPatch);
        }
      }
    }
    else if (this.formPatch.platformPulldown == 'tsys') {
      if (this.tsysComp != undefined && this.tsysComp != null) {
        if (this.tsysComp.CreditCardTsysForm != undefined && this.tsysComp.CreditCardTsysForm != null) {
          this.tsysComp.CreditCardTsysForm.patchValue(this.formPatch);
        }
      }
    }
    else if (this.formPatch.platformPulldown == 'vantiv') {
      if (this.vantivComp != undefined && this.vantivComp != null) {
        if (this.vantivComp.CreditCardVantivForm != undefined && this.vantivComp.CreditCardVantivForm != null) {
          this.vantivComp.CreditCardVantivForm.patchValue(this.formPatch);
        }
      }
    }
  }

  onEmv(e) {
    if (e.target.checked == true) {
      this.emvdata = true
    } else {
      this.emvdata = false
    }
    this.emvClick.emit(this.emvdata)
  }

  getMaxValueBName(e) {
    if (e.target.value.length == 25) {
      this.errorValue = "Max-Limit 25 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  getMaxValueFName(e) {
    if (e.target.value.length == 25) {
      this.errorValue1 = "Max-Limit 25 characters reached";
    }
    else {
      this.errorValue1 = ""
    }
  }

  getMaxValueLName(e) {
    if (e.target.value.length == 25) {
      this.errorValue2 = "Max-Limit 25 characters reached";
    }
    else {
      this.errorValue2 = ""
    }
  }

  getMaxValueEmail(e) {
    if (e.target.value.length == 100) {
      this.errorValue3 = "Max-Limit 100 characters reached";
    }
    else {
      this.errorValue3 = ""
    }
  }

  getMaxValueCity(e) {
    if (e.target.value.length == 25) {
      this.errorValue4 = "Max-Limit 25 characters reached";
    }
    else {
      this.errorValue4 = ""
    }
  }

  CreditCardValid() {
    if (this.CreditCardForm.invalid) {
      this.helper.setValue('IsValidForm',true);
     // this.CreditComp.xyz=true;
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }
    else if (this.GeneralData.phone.value != undefined && this.GeneralData.phone.value != null && this.GeneralData.phone.value != "") {
      if (this.GeneralData.phone.value.length <= 12) {
        this.mgs = 'Phone number must be at least 10 digit';
        this.requiredError = true;
        return
      }
    }
    else if (this.CreditCardForm.controls.zip.value != undefined && this.CreditCardForm.controls.zip.value != null && this.CreditCardForm.controls.zip.value != "") {
      if (this.CreditCardForm.controls.zip.value.length <= 4) {
        this.zipCodeMsg = 'Please enter min 5 digits zipcode';
        this.requiredError = true;
        return
      }
    }
    else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if ((this.CreditCardForm.value.businessName != null && this.CreditCardForm.value.businessName != "") && (this.CreditCardForm.value.contactFirstName != null && this.CreditCardForm.value.contactFirstName != "") && (this.CreditCardForm.value.contactLastName != null && this.CreditCardForm.value.contactLastName != "") && (this.CreditCardForm.value.contactEmail != null && this.CreditCardForm.value.contactEmail != "") && (this.CreditCardForm.value.address != null && this.CreditCardForm.value.address != "") && (this.CreditCardForm.value.city != null && this.CreditCardForm.value.city != "") && (this.CreditCardForm.value.zip != null && this.CreditCardForm.value.zip != "") && (this.CreditCardForm.value.gatewaySelection != null && this.CreditCardForm.value.gatewaySelection != "")) {
      this.isInvalid.emit(false);
    }
  }
}
