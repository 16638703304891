import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from '../helper/helper.service';
import { BaseService } from '../base/base.service';
import { Router } from '@angular/router';
import { SaveMenuGroup, GetMenuGroup, DeleteMenuGroup, EditMenuGroup, SaveMenuGroupLayout } from '../interfaces/imenu';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })

  export class MenuGroupService {
    //portNumber = ':8087/';
    constructor(public http: HttpClient,
      private Helper: HelperService, private base: BaseService, private router: Router) { }

      MenuGroupSave(objMenu: SaveMenuGroup) {
        return this.base.post('Menu/SaveMenuGroups', objMenu).pipe(map((response: any) => {
          return response;
        }));

    }
    MenuGroupSaveLayout(objMenuLayout: SaveMenuGroupLayout) {
      return this.base.post('Menu/SaveMenuGroupLayout', objMenuLayout).pipe(map((response: any) => {
        return response;
      }));

  }
    MenuGroupget(objGet:GetMenuGroup){
        return this.base.post('Menu/GetMenuGroups', objGet).pipe(map((response: any) => {
            return response;
        }));

    }

    MenuGroupEdit(objEdit:EditMenuGroup){
      return this.base.post('Menu/EditMenuGroups', objEdit).pipe(map((response: any) => {
          return response;
      }));

  }

    MenuGroupDelete(objdelete:DeleteMenuGroup){
        return this.base.post('Menu/DeleteMenuGroups', objdelete).pipe(map((response: any) => {
            return response;
        })); 
    }

}