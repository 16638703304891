import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetAllUserList } from 'src/app/interfaces/iemployee';
import { EmployeeService } from 'src/app/services/employee.service';
import { HelperService } from 'src/app/helper/helper.service';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/services/admin.service';
import { IActiveUser, IListInfo } from 'src/app/interfaces/iadmin';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';


@Component({
  selector: 'app-hierachy-dealer',
  templateUrl: './hierachy-dealer.component.html',
  styleUrls: ['./hierachy-dealer.component.css']
})
export class HierachyDealerComponent implements OnInit {

  GeneralDealerForm: FormGroup;
  public displayedColumns = [];
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  rowClicked;
  allempdetails = [];
  userId: any;
  submittedGeneral = false;
  generalValue: any;

  tenantId: any;
  loginInfo: any;
  getThemeId: any;
  typeDealer: any;
  tableEnable: boolean = true;
  tenantHierarchy: any = [];
  companyHierarchyInfo: any;
  dealerHierarchyInfo: any;
  isvHierarchyInfo: any;
  storeHierarchyInfo: any;
  dealerHierarchyList: any;
  companyHierarchyList: any;
  storeHierarchyList: any;
  companyHierarchy: boolean = false;
  dealerHierarchy: boolean = false;
  isvHierarchy: boolean = false;
  storeHierarchy: boolean = false;
  dealerList = [];
  companyList = [];
  storeList = [];
  isvParentData: any;
  public dealerRow: any;
  public EmployeeTableData: any;
 @Output() public dealerData = new EventEmitter();

  @Input() public dealerIdItem;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];

  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();


  constructor(private dealerDetails: AdminService, private formBuilder: FormBuilder, private toaster: ToasterService,
    private helper: HelperService, private changeDetector: ChangeDetectorRef, private router: Router) { }

  // @ViewChild(DataTableDirective)
  // dtElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject();

  ngOnInit(): void {
    this.employeeName = "Dealer Name";
    this.employee = "Dealer";
    this.employeeIcon = "assets/components/images/site2x.png"

    // this.dtOptions = {
    //   paging: false,
    //   info: false,
    //   scrollY: '400',
    // };
    this.tenantId = 0;
    this.GeneralDealerForm = this.formBuilder.group({
      tenantName: ['', Validators.required],
      contactPhone: new FormControl(undefined),
      // contactPhone: [''],
      resellerStartDate: [''],
      contactFirstName: [''],
      contactLastName: [''],
      contactEmail: [''],
      address: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      projectedVolume: [''],
      projectedVolLpm: [''],
      territory: [''],
      projected: [false],
      vested: [false],
      isActive: [true],
      isDemo: [true],
      isDeleted: [false],
    });
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.dealerDetails.hierarchydealerTransferValue.subscribe(data => {
      this.dealerList = data;
      this.dataSource.data = this.dealerList;
      this.dataSource.data = data;
      this.EmployeeTableData = data
      this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
    });
    // this.dataSource.data = this.dealerList;

    this.displayedColumns = ['name', 'isActive'];
    this.columns = [
      { columnDef: 'name', header: 'Name' },
      { columnDef: 'isActive', header: 'Status' }
    ];
    this.dealerDetails.hieRowDealer.subscribe(data => {
      this.dealerRow = data
      if (this.dealerRow.IdDealer != undefined && this.dealerRow.IdDealer != null && this.dealerRow.IdDealer != "") {
        this.getDealer(this.dealerRow.IdDealer);

        this.dealerIdItem = this.dealerRow
      }

    }
    );


    this.dealerDetails.hieDealerParentData.subscribe(data => this.isvParentData = data);
    this.helper.setValue("Companyflag", 0);
    this.helper.setValue("Dealerflag", 1);
    this.helper.setValue("Storeflag", 0);
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }
  // rerender(): void {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

  //     dtInstance.destroy();

  //     dtInstance.destroy();
  //     this.dtTrigger.next();
  //   });
  // }

  get GeneralDealerData() { return this.GeneralDealerForm.controls; }

  adddealer() {
    this.GeneralDealerForm.reset();
    this.employeeName = "Dealer Name";
    this.dealerRow = 'transparent';
    this.tenantId = 0;
    this.titleName.emit(this.employeeName)

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  changeTableRowColor(event) {
    // event.idx = this.dealerRow.IdDealer
    this.dealerRow = ""
    this.dealerRow = this.dealerRow === event.idx ? -1 : event.idx;
    this.getDealer(event.id)
  }
  getDealer(empid) {
    this.tenantId = empid
    const employee: GetAllUserList = {
      tenantId: empid
    }
    this.dealerDetails.GetAllHierachy(employee).subscribe((response) => {
      this.GeneralDealerForm.patchValue(response);
    })
  }
  getTableName(name) {
    this.employeeName = name;
    this.employee = "Dealer"
    this.employeeIcon = "assets/components/images/dealer2x.png";
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }
  checkValue(event) {
    let currentTarget = event.e.currentTarget.checked
    const active: IActiveUser = {
      userId: this.loginInfo.userId,
      tenantId: event.id,
      isActive: event.e.currentTarget.checked
    }
    this.dealerDetails.IsActiveUser(active).subscribe(
      (response) => {
        this.getDealer(event.id)
        this.getDetailedData();
        if (currentTarget) {
          this.toaster.success("Dealer is Active");
        } else {
          this.toaster.success("Dealer is Deactive");
        }
      },
    )
  }
  onSaveGeneral() {
    this.GeneralDealerForm.controls.tenantName.setValidators(Validators.required);
    this.GeneralDealerForm.controls.tenantName.updateValueAndValidity();
    if (this.typeDealer > 0) {
      this.typeDealer = this.typeDealer
    } else {
      this.typeDealer = this.isvParentData.id != undefined && this.isvParentData.id != null && this.isvParentData.id != "" ? this.isvParentData.id : 1;
    }
    this.submittedGeneral = true;

    if (this.GeneralDealerForm.invalid) {
      return;
    }
    const employee = this.GeneralDealerForm.value;
    employee.sectionId = 1;
    employee.tenantId = this.tenantId > 0 ? this.tenantId : 0;
    employee.tenantTypeId = 2;
    employee.parentId = this.tenantId > 0 ? 1 : this.typeDealer;
    const obj = employee.contactPhone
    const result = obj.internationalNumber;
    employee.contactPhone = result;

    this.dealerDetails.UserHierachy(employee).subscribe(
      (response) => {
        this.generalValue = response;
        this.tenantId = 0;
        this.getDetailedData();
        this.toaster.success("Dealer Data Added Successfully");
        this.GeneralDealerForm.controls.tenantName.setErrors(null);
        this.GeneralDealerForm.controls.tenantName.clearValidators();
        this.submittedGeneral = false;
      },
      error => {

      })

  }
  getDetailedData() {
    const login: IListInfo = {
      loginUserId: this.loginInfo.userId,
      tenantId: 0
    }
    this.dealerDetails.TenantHierarchyData(login).subscribe(
      (response) => {
        if (response.typeName == "ISV") {
          this.dealerDetails.checkDealerHierarchydata(response.childHierarchyList);
          this.dataSource.data = response.childHierarchyList;
          this.displayedColumns = ['name', 'isActive'];
          this.columns = [
            { columnDef: 'name', header: 'Name' },
            { columnDef: 'isActive', header: 'Status' }
          ];
          this.tableEnable = false;
          this.changeDetector.detectChanges();
          this.tableEnable = true;
        }

      })
  }
  gethierarchyData(e){
   this.dealerData.emit(e)
  }
}
