import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import { HelperService } from '../helper/helper.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
  menuEdit, menusave, menuItemList, SaveItemLogistics, GetItemLogistics, ActivateMenuSize,
   SaveSaleAreaGeneral, GetSaleAreaGeneral, DeleteSaleArea, SaveSaleAreaLogistic, SaveSaleAreaLayout,
  GetSaleAreaGeneralDD,
  SaveItemLayout,
  GetMenuGroup,
  GetItemtype,
  iItemModifier,
  ISalesArea,
  ISaveEventPricing,
  IGetEventPrice
} from '../interfaces/imenu';
import { IempId, ISalesdetails, IHierachydetails } from '../interfaces/iadmin';


@Injectable({
  providedIn: 'root'
})
export class MenuService {
  SalesGeneralData: any;
  SalesValue: any;
  HierachyValue: any;
  SalesID = new BehaviorSubject<IempId>({
    id: ""
  })
  SalesValues = new BehaviorSubject<ISalesdetails>({
    area: "",
    group: "",
    page: ""
  })
 
  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) {
    this.SalesGeneralData = this.SalesID.asObservable();
    this.SalesValue = this.SalesValues.asObservable();
  }

  SalesIdvalue(SalesGeneralData: any) {
    this.SalesID.next(SalesGeneralData);
  }
  SalesvalueDatas(SalesValue: ISalesdetails) {
    this.SalesValues.next(SalesValue);
  }
 
  MenuSizeSelectEdit(objMenu: menuEdit) {
    return this.base.post('Menu/MenuSizeSelectEdit', objMenu).pipe(map((response: any) => {
      return response;
    }));
  }
  MenuSizeList(objMenu: menuEdit) {
    return this.base.post('Menu/MenuSizeSelectList', objMenu).pipe(map((response: any) => {
      return response;
    }));
  }
  MenuSave(objMenusave: menusave) {
    return this.base.post('Menu/SaveMenuSize', objMenusave).pipe(map((response: any) => {
      return response;
    }));
  }


  ActivateMenu(objMenusave: ActivateMenuSize) {
    return this.base.post('Menu/MenuSizeIsActivation', objMenusave).pipe(map((response: any) => {
      return response;
    }));
  }

  ActivateMenuItem(objMenusave: ActivateMenuSize) {
    return this.base.post('Item/MenuItemIsActivation', objMenusave).pipe(map((response: any) => {
      return response;
    }));
  }

  MenuItemDetails(objMenuList: menuItemList) {
    return this.base.post('Item/GetItems', objMenuList).pipe(map((response: any) => {
      return response;
    }));

  }

  MenuItemList(objMenuList: menuItemList) {
    return this.base.post('Item/GetItemsList', objMenuList).pipe(map((response: any) => {
      return response;
    }));
  }
  MenuItemLayoutList(objMenuList: menuItemList) {
    return this.base.post('Menu/MenuItemSelect', objMenuList).pipe(map((response: any) => {
      return response;
    }));
  }

  GetItemModifier(objList: iItemModifier) {
    return this.base.post('Item/SelectItemModifier', objList).pipe(map((response: any) => {
      return response;
    }));
  }
  SaveMenuItemLayout(objList: SaveItemLayout) {
    return this.base.post('SalesArea/SalesAreaMenuItemLayout', objList).pipe(map((response: any) => {
      return response;
    }));
  }
  MenuItemListID(objMenuList: menuItemList) {
    return this.base.post('Item/GetItems', objMenuList).pipe(map((response: any) => {
      return response;
    }));

  }
  SaveItems(ObjItem: any) {
    return this.base.post('Item/SaveItem', ObjItem).pipe(map((response: any) => {
      return response;
    }));
 
  }
  getItmLogistics(e: GetItemLogistics) {
    return this.base.post('Item/SaveItemLogistics', e).pipe(map((response: any) => {
      return response;
    }));
  }

  saveItmLogistics(e: SaveItemLogistics) {
    return this.base.post('Item/SaveItemLogistics', e).pipe(map((response: any) => {
      return response;
    }));

  }

  //----------------------------SALES AREA GENERAL-----------
  GetSalesAreaTicketGeneral(e: GetSaleAreaGeneralDD) {
    return this.base.post('Admin/GetCodeMaster', e).pipe(map((response: any) => {
      return response;
    }));
  }

  GetSalesAreaGeneral(e: GetSaleAreaGeneral) {
    return this.base.post('SalesArea/EditSalesArea', e).pipe(map((response: any) => {
      return response;
    }));
  }

  saveSalesAreaGeneral(e: SaveSaleAreaGeneral) {
    return this.base.post('SalesArea/SaveSalesAreaGeneral', e).pipe(map((response: any) => {
      return response;
    }));
  }


  DeleteSalesArea(e: DeleteSaleArea) {
    return this.base.post('SalesArea/DeleteSalesAreaGeneral', e).pipe(map((response: any) => {
      return response;
    }));
  }

  getSalesAreaDetails(e: GetSaleAreaGeneral) {
    return this.base.post('SalesArea/GetSalesAreaLayout', e).pipe(map((response: any) => {
      return response;
    }));
  }
  getMenuItemPages(e: GetSaleAreaGeneral) {
    return this.base.post('SalesArea/GetItemMenuPages', e).pipe(map((response: any) => {
      return response;
    }));
  }

  getSalesArea(SalesAreaData: ISalesArea) {
    return this.base.post('SalesArea/ViewSalesArea', SalesAreaData).pipe(map((response: any) => {
      return response;
    }));
  }
  //----------------------------SALES AREA LOGISTICS-----------

  saveSalesAreaLogistics(e: SaveSaleAreaLogistic) {
    return this.base.post('SalesArea/SaveSalesAreaLogistics', e).pipe(map((response: any) => {
      return response;
    }));
  }

  DeleteSalesLogistics(e: DeleteSaleArea) {
    return this.base.post('SalesArea/SalesAreaLogistics_Delete', e).pipe(map((response: any) => {
      return response;
    }));
  }

  //-----------------------------SALES AREA LAYOUT------

  saveSalesAreaLayout(e: SaveSaleAreaLayout) {
    return this.base.post('SalesArea/SaveSalesAreaLayout', e).pipe(map((response: any) => {
      return response;
    }));
  }
  saveAreaMapping(e: SaveSaleAreaLayout) {
    return this.base.post('SalesArea/SaveAreaMapping', e).pipe(map((response: any) => {
      return response;
    }));
  }
  getSourceBuilder() {
    return this.http.get<any>('assets/showcase/data/sourceBuilder.json')
      .pipe(
        map((res: any) => {

          return res;
        })
      );
  }

  GetItemTypeList(objItemList: GetItemtype) {
    return this.base.post('Item/GetItemFromType', objItemList).pipe(map((response: any) => {
      return response;
    }));
  }

  
 
}