import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirm-box-popup',
  templateUrl: './confirm-box-popup.component.html',
  styleUrls: ['./confirm-box-popup.component.css'],
  host: { class: "first-class" }
})
export class ConfirmBoxPopupComponent implements OnInit {
  @Output() messageEvent = new EventEmitter();
  public continueSession: boolean=false;
  public pData: boolean=true;
  confrim : boolean
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }
  confirmBoxData(){
    this.confrim = true
    this.messageEvent.emit(this.confrim);
    this.bsModalRef.hide()
  }
  cancleBox(){
    this.confrim = false
    this.messageEvent.emit(this.confrim);
    this.bsModalRef.hide()
  }
}
