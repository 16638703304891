import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import {
  menuEdit,
  menuItemList,
  menuItemddl,
  sizeDetails,
  ActivateMenuSize,
} from 'src/app/interfaces/imenu';
import { MenuService } from 'src/app/services/menu.service';
import { HelperService } from 'src/app/helper/helper.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToasterService } from 'src/app/helper/toaster.service';
import { DialogService } from 'src/app/services/dialog.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-menu-size-general',
  templateUrl: './menu-size-general.component.html',
  styleUrls: ['./menu-size-general.component.css'],
})
export class MenuSizeGeneralComponent implements OnInit {
  public isActiveInactivePerm: boolean;
  buttonDisable: boolean = false;
  filteredProperties$: Observable<sizeDetails[]>;
  _properties: sizeDetails[] = [];
  public displayedColumns = [];
  searchValue: any;
  filteredItems: any;
  dynArray: any = [];
  dynFormArray: any;
  loginInfo: any = 0;
  columns = [];
  Itemarray = [];
  ItemsList: menuItemddl[];
  public dataSource = new MatTableDataSource<any>();
  @Output() public generalItem = new EventEmitter();
  MenuSizeForm: FormGroup;
  submittedGeneral = false;
  errorValue: any;
  @Input() public tableRowId;
  @Input() public currentTarget;
  requiredError: boolean = false;

  @Output() public isInvalid = new EventEmitter();
  @Output() public mnusizesessData_child = new EventEmitter();

  //@Input() public buttonState;
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set DataIdx(sID) {
    this.tableRowId = sID;
    if (
      this.tableRowId != undefined &&
      this.tableRowId != null &&
      this.tableRowId != ''
    ) {
      this.GetMenuSizeList(this.tableRowId);
    }
  }
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }
  constructor(
    private formBuilder: FormBuilder,
    private Objmenu: MenuService,
    private changeDetector: ChangeDetectorRef,
    private helper: HelperService,
    private toaster: ToasterService,
    private dialogser: DialogService,private permser: PermissionService
  ) {}

  ngOnInit(): void {
    this.isActiveInactivePerm = this.permser.isActiveInactive;
    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.MenuSizeForm = this.formBuilder.group({
      searchValue: [''],
      id: 0,
      userid: 0,
      tenantId: 0,
      name: ['', Validators.required],
      scheduleAvail: [false],
      availableloc: [false],
      isActive: [true],
      menuItemSizeList: this.formBuilder.array(
        this._properties.map((p) =>
          this.formBuilder.group({
            itemID: [p.itemID], //, { validators: [Validators.required], updateOn: 'blur' }
            price: [p.price], //, { validators: [Validators.required], updateOn: 'blur' }
            cost: [p.cost], //, { validators: [Validators.required], updateOn: 'blur' }
            costpercent: [p.costpercent], //, { validators: [Validators.required], updateOn: 'blur' }
          })
        )
      ),
      query: [''],
    });
    this.filteredProperties$ = this.MenuSizeForm.valueChanges.pipe(
      map((value) => {
        // in this map I extend the Property type with an index which I need to bind the formControl in the template. this is the index of the FormGroup in the FormArray
        if (value.query === '') {
          return value.menuItemSizeList.map((p, index) => ({ ...p, index }));
        }
        const queryLower = value.query.toLowerCase();
        return value.menuItemSizeList
          .filter(
            (p) =>
              p.cost.toLowerCase().includes(queryLower) ||
              p.price.toLowerCase().includes(queryLower)
          )
          .map((p) => ({ ...p, index: value.menuItemSizeList.indexOf(p) }));
      }),
      // valueChanges stream only starts after the first change, so use startWith to provide an initial value
      startWith(
        this.properties.value.map((p) => ({
          ...p,
          index: this.properties.value.indexOf(p),
        }))
      )
    );
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.addItemSizeMulti();
    this.GetMenuItemList();
  }

  @Input()
  public set checkTarget(currentTarget) {
    this.currentTarget = currentTarget;
    if (
      this.currentTarget != undefined &&
      this.currentTarget != null &&
      this.currentTarget != ''
    ) {
      this.checkValue(this.currentTarget);
    }
  }

  get MenuSizeData() {
    return this.MenuSizeForm.controls;
  }
  get properties(): FormArray {
    return <FormArray>this.MenuSizeForm.controls['menuItemSizeList'];
  }

  getPropertyFormGroup(index: number): FormGroup {
    return this.properties.at(index) as FormGroup;
  }

  getMenuSizeFormGroup(index: number): FormGroup {
    return this.properties.at(index) as FormGroup;
  }

  private addMenuSizeFormGroup(): FormGroup {
    return this.formBuilder.group({
      itemID: [null], //, { validators: [Validators.required], updateOn: 'blur' }
      price: [null], //, { validators: [Validators.required], updateOn: 'blur' }
      cost: [null], //, { validators: [Validators.required], updateOn: 'blur' }
      costpercent: [null], //, { validators: [Validators.required], updateOn: 'blur' }
    });
  }

  addItemSizeMulti() {
    this.dynFormArray = this.MenuSizeForm.controls[
      'menuItemSizeList'
    ] as FormArray;
    this.properties.push(this.addMenuSizeFormGroup());
  }

  DeleteItem(index: number): void {
    this.helper.setValue('OnDirty', true);
    if (!((<FormArray>this.MenuSizeForm.get('menuItemSizeList')).length == 1)) {
      (<FormArray>this.MenuSizeForm.get('menuItemSizeList')).removeAt(index);
    }
    this.helper.setValue('GetSessionData1', this.MenuSizeForm.value);
  }

  GetMenuSizeList(rowId) {
    const menuList: menuEdit = {
      sizeid: rowId,
      tenantid: this.loginInfo.tenantId,
    };
    this.Objmenu.MenuSizeSelectEdit(menuList) // select method
      .subscribe((res) => {
        let sizeVal = res[0];
        let menuItemArray = sizeVal.menuItemSizeList;
        const dynFormArray = this.MenuSizeForm.controls[
          'menuItemSizeList'
        ] as FormArray;
        dynFormArray.controls = [];
        if (
          menuItemArray != undefined &&
          menuItemArray != null &&
          menuItemArray != ''
        ) {
          if (menuItemArray.length > 0) {
            for (let j = 0; j < menuItemArray.length; j++) {
              sizeVal.menuItemSizeList[j].cost = menuItemArray[j].cost;
              // Number(
              //   menuItemArray[j].cost
              // ).toFixed(2);
              sizeVal.menuItemSizeList[j].costpercent =
                menuItemArray[j].costpercent;
              sizeVal.menuItemSizeList[j].price = menuItemArray[j].price;
              // Number(
              //   menuItemArray[j].price
              // ).toFixed(2);
              sizeVal.menuItemSizeList[j].sizeName = menuItemArray[j].sizeName;
              dynFormArray.push(this.addMenuSizeFormGroup());
            }
          }
        } else {
          dynFormArray.push(this.addMenuSizeFormGroup());
        }
        this.MenuSizeForm.patchValue(sizeVal);
      });
  }

  onSaveMenuSize() {
    this.errorValue = '';
    this.submittedGeneral = true;
    if (this.MenuSizeForm.invalid) {
      return;
    }
    let MenuGroup = this.MenuSizeForm;
    if (MenuGroup.value.menuItemSizeList[0].itemID == '') {
      MenuGroup.value.menuItemSizeList[0].itemID = 0;
      MenuGroup.value.menuItemSizeList[0].price = 0.0;
      MenuGroup.value.menuItemSizeList[0].cost = 0.0;
      MenuGroup.value.menuItemSizeList[0].costpercent = 0.0;
    }
    // MenuGroup.value.id = this.id; //To Retrive or to update this line is important
    MenuGroup.value.userid = this.loginInfo.userId;
    MenuGroup.value.tenantId = this.loginInfo.tenantId;
    MenuGroup.value.name = MenuGroup.value.name.toString().trim();
    if (
      MenuGroup.value.name != undefined &&
      MenuGroup.value.name != null &&
      MenuGroup.value.name != ''
    ) {
      this.onSaveCompleteForm(MenuGroup.value);
    } else {
      this.toaster.errorPopUp('Please Enter Menu-Size Name');
      return;
    }
  }

  GetMenuItemList() {
    const menuItem: menuItemList = {
      itemID: 0,
      tenantId: this.loginInfo.tenantId,
    };
    this.Objmenu.MenuItemList(menuItem) // select method
      .subscribe((res) => {
        // console.log(res,'ItemList')
        // this.ItemsList = res.filter(x => x.isActive == true && x.pricingStyle == true);
        this.ItemsList = res.filter(
          (x) => x.isActive == true && x.ingredient == false
        );
      });
  }

  //Dynamic control not refreshing the particular colmn chnage immediately, Ie the reason usse 2 different functions for Price and Cost
  onCostChange(cost1: any, index: any): void {
    let dynControl = this.MenuSizeForm.controls[
      'menuItemSizeList'
    ] as FormArray;
    for (let i = 0; i < dynControl.length; i++) {
      if (i == index) {
        let formGroup2 = dynControl.controls[i].value as FormGroup;
        let price = 0;
        let percentage = 0;
        let cost = 0;
        let percentageDec;
        price = formGroup2['price'];
        cost = cost1 == '' ? null : cost1;
        percentage = (Number(cost) / Number(price)) * 100;
        percentageDec = percentage == undefined ? 0 : percentage.toFixed(2);
        dynControl.controls[i]
          .get('costpercent')
          .setValue(isNaN(percentageDec) == true ? null : percentageDec);
        dynControl.controls[i].get('cost').setValue(cost1 == '' ? null : cost1);
        this.MenuSizeForm.controls['menuItemSizeList'].value[i].costpercent =
          isNaN(percentageDec) == true ? null : percentageDec;
      }
    }
  }

  onPriceChange(Price: any, index: any): void {
    let dynControl = this.MenuSizeForm.controls[
      'menuItemSizeList'
    ] as FormArray;
    for (let i = 0; i < dynControl.length; i++) {
      if (i == index) {
        let formGroup2 = dynControl.controls[i].value as FormGroup;
        let price = 0;
        let percentage = 0;
        let cost = 0;
        let percentageDec;
        price = Price == '' ? null : Price;
        cost = formGroup2['cost'];
        percentage = (Number(cost) / Number(price)) * 100;
        percentageDec = percentage == undefined ? 0 : percentage.toFixed(2);
        dynControl.controls[i]
          .get('costpercent')
          .setValue(isNaN(percentageDec) == true ? null : percentageDec);
        dynControl.controls[i]
          .get('price')
          .setValue(Price == '' ? null : Price);
        this.MenuSizeForm.controls['menuItemSizeList'].value[i].costpercent =
          isNaN(percentageDec) == true ? null : percentageDec;
      }
    }
  }

  onSaveCompleteForm(MenuItems): void {
    this.Objmenu.MenuSave(MenuItems).subscribe(async (response) => {
      this.submittedGeneral = false;
      //this.MenuSizeForm.patchValue({ isActive: true });  //This is commented because After Uncheck and save its getting check
      if (response.id == 0) {
        this.toaster.errorPopUp(response.message);
      } else {
        if (response.message == 'Item is already assigned') {
          this.toaster.errorPopUp(response.message);
        } else {
          let dynControl = this.MenuSizeForm.controls[
            'menuItemSizeList'
          ] as FormArray;
          for (let i = 0; i < dynControl.length; i++) {
            dynControl.controls[i]
              .get('price')
              .setValue(
                Number(dynControl.controls[i].get('price').value).toFixed(2)
              );
            dynControl.controls[i]
              .get('cost')
              .setValue(
                Number(dynControl.controls[i].get('cost').value).toFixed(2)
              );
          }

          this.toaster.successPopUp(response.message);
          //THE BELOW CODE IS IMPORTANT TO PATCH THE UPDATED ID
          this.MenuSizeForm.patchValue({
            id: response.id,
          });
        }
      }
      await this.dialogser.ClearSession();
      this.helper.setValue('GetSessionData1', MenuItems);
      this.generalItem.emit({ res: response, name: MenuItems.name });
      this.mnusizesessData_child.emit({
        data: MenuItems,
        event: 'MenuSizePage',
        val: false,
      });
    });
  }

  checkValue(event) {
    let currentTarget = event.e.currentTarget.checked;
    const active: ActivateMenuSize = {
      userid: this.loginInfo.userId,
      id: event.id,
      isActive: event.e.currentTarget.checked,
    };
    this.Objmenu.ActivateMenu(active).subscribe((response) => {
      this.GetMenuSizeList(this.tableRowId);
    });
  }

  keyPress(event: any) {
    const pattern = /[0-9\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  numberOnly(event) {
    // const charCode = (event.which) ? event.which : event.keyCode;
    // if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
    //   return false;
    // }
    // else {
    //   var parts = event.srcElement.value.split('.');
    //   if (parts.length > 1 && charCode == 46)
    //     return false;
    //   return true;
    // }
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  numberOnlyPercent(event) {
    const reg = /^-?\d*(\.\d{0,10})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue = '';
    }
  }

  MenuSizeValid() {
    if (this.MenuSizeForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    } else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.MenuSizeForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.MenuSizeForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.mnusizesessData_child.emit(this.MenuSizeForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.mnusizesessData_child.emit({
      data: data,
      event: 'MenuSizePage',
      val: true,
    });
  }
}
