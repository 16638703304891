<mat-accordion >
    <mat-expansion-panel *ngFor="let item of ['Layout']; first as isFirst" [expanded]="isFirst"
        class="outshadow">
        <mat-expansion-panel-header>
            <mat-panel-title> {{item}} </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="item == 'Layout'">
            <app-items-layout  [salesAreabit]="salesAreabit"></app-items-layout>
        </div>
    </mat-expansion-panel>
</mat-accordion>
