import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { AdminService } from 'src/app/services/admin.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
import { IRole } from 'src/app/interfaces/iadmin';

@Component({
  selector: 'app-error-message-frequency-config',
  templateUrl: './error-message-frequency-config.component.html',
  styleUrls: ['./error-message-frequency-config.component.css']
})
export class ErrorMessageFrequencyConfigComponent implements OnInit {
  errmsgfrqconfigForm: FormGroup;
  buttonDisable: boolean = false;
  submittedGeneral: any;
  loginInfo: any;
  errorValue: any;
  requiredError: boolean = false;
  id: any = 0;

  public employeeName: any;
  public employeeIcon: any;
  public employee: any;
  isInvalid: boolean = false;

  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public generalValueemit = new EventEmitter;
  @Output() public errmsgsessData = new EventEmitter;

  constructor(private formBuilder: FormBuilder, private helper: HelperService,
    private reportService: ReportWebSocketService, private toaster: ToasterService,
    private dialogser: DialogService, private admserv: AdminService) { }


  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.errorMessageFrequency");

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Error Message Frequency Config";
    this.employee = "Setting";
    this.employeeIcon = "assets/components/images/setting.png";
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.errmsgfrqconfigForm = this.formBuilder.group({
      id: 0,
      backendErrorFrequency: [null],
      iPadErrorFrequency: [null]
    })

    this.getErrorConfigData();
  }

  async getErrorConfigData() {
    const ItemTypeList: IRole = {
      id: 0,
      tenantId: this.loginInfo.tenantId                 // Only itemtype Ingredient true record is fetched
    }
    this.admserv.SelectErrorConfig(ItemTypeList)
      .subscribe(res => {
        this.errmsgfrqconfigForm.patchValue(res[0]);
        this.id = res[0].id;
      })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSaveGeneral() {
    this.errorValue = ""
    this.submittedGeneral = true;
    if (this.errmsgfrqconfigForm.invalid) {
      return;
    }

    let ErrorConfig;
    ErrorConfig = this.errmsgfrqconfigForm.value;
    ErrorConfig.createdBy = this.loginInfo.userId;
    ErrorConfig.tenantId = this.loginInfo.tenantId;
    ErrorConfig.id = this.id;
    this.admserv.SaveErrorConfig(ErrorConfig).subscribe(async res => {
      if (res.id == 0) {
        this.toaster.errorPopUp(res.message);
        return;
      } else {
        this.id = res.id;
        this.toaster.successPopUp(res.message);
        await this.dialogser.ClearSession();
        this.helper.setValue('GetSessionData1', ErrorConfig);
        this.errmsgsessData.emit({ data: this.errmsgfrqconfigForm.value, 
          event: "errorMessageFrequency", val: false });
      }
      this.submittedGeneral = false;
    });
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.errmsgsessData.emit({ data: data, event: "errorMessageFrequency", val: true });
  }
}