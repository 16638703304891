<div>
    <form class="loginForm" [formGroup]="CreditCardStep4Form">
        <div class="row w-100">
            <div class="col-sm-12">
                <label class="mb-2">
                    Enter the Secret Key And Public Key
                </label>
            </div>
        </div>
        <div class="row w-100">
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-4">
                            <label class="customlable mb-0">Secret Key:</label>
                        </div>
                        <div class="col-sm-8">
                            <!-- <input type="text" class="form-control input-lg inputbox_login" placeholder="Secret key" appInputRestriction
                            formControlName="secretKey" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.secretKey.errors }"/> -->
                            <input type="text" class="form-control input-lg inputbox_login" placeholder="Secret key" appInputRestriction
                            formControlName="secretKey" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-4">
                            <label class="customlable mb-0">Public Key:</label>
                        </div>
                        <div class="col-sm-8">
                            <!-- <input type="text" class="form-control input-lg inputbox_login" placeholder="Public key" appInputRestriction
                            formControlName="publicKey" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.publicKey.errors }"/>
                        -->
                        <input type="text" class="form-control input-lg inputbox_login" placeholder="Public key" appInputRestriction
                            formControlName="publicKey" />
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row w-100">
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-4">
                            <label class="customlable mb-0">TranCloud ID:</label>
                        </div>
                        <div class="col-sm-8">
                            <!-- <input type="text" class="form-control input-lg inputbox_login" placeholder="TranCloud ID" appInputRestriction
                            formControlName="tranCloudId" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.trancloudid.errors }"/>
                         -->
                         <input type="text" class="form-control input-lg inputbox_login" placeholder="TranCloud ID" appInputRestriction
                         formControlName="tranCloudId" />
                     
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-4">
                            <label class="customlable mb-0">PrePaid (Gift):</label>
                        </div>
                        <div class="col-sm-8">
                            <!-- <input type="text" class="form-control input-lg inputbox_login" placeholder="PrePaid (Gift)" appInputRestriction
                            formControlName="prePaidGift" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.prepaid.errors }"/>
                     -->
                     <input type="text" class="form-control input-lg inputbox_login" placeholder="PrePaid (Gift)" appInputRestriction
                     formControlName="prePaidGift" />
             
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row w-100">
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-4">
                            <label class="customlable mb-0">Card Present:</label>
                        </div>
                        <div class="col-sm-8">
                            <!-- <input type="text" class="form-control input-lg inputbox_login" placeholder="Card Present" appInputRestriction
                            formControlName="cardPresent" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.cardpresent.errors }"/>
                         -->
                         <input type="text" class="form-control input-lg inputbox_login" placeholder="Card Present" appInputRestriction
                         formControlName="cardPresent" />
                     
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-4">
                            <label class="customlable mb-0">Card Not Present:</label>
                        </div>
                        <div class="col-sm-8">
                            <!-- <input type="text" class="form-control input-lg inputbox_login" placeholder="Card Not Present" appInputRestriction
                            formControlName="cardNotPresent" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.cardnotpresent.errors }"/>
                       -->
                       <input type="text" class="form-control input-lg inputbox_login" placeholder="Card Not Present" appInputRestriction
                       formControlName="cardNotPresent" />
                 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="form-group">
            <button class="btn  btn-sm float-right submit_button" type="submit" (click)="onSaveGeneral()">
                     [disabled]="buttonState ? true : null" 
            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label>
            </button>
        </div> -->
    </form>
</div>
