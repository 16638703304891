<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="ClearData()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global_submit" type="submit" [disabled]="buttonStatus ?  true : null" (click)="OnSave();">
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label></button>
        </div>
    </div>
</div>
<div class="row second-sec">
    <div class="col-sm-4">
        <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" [dealerItem]="tableRowColor" [EmployeeTableData]="EmployeeTableData" (breakActive)="ActivateDeactivate($event)" [DataCom]="employee" (tableItemId)="getTableIdx($event)"
            (tableEmpName)="getTableName($event)" [employeeItem]="tableRowColor" (clearDetails)="ClearData($event)" (btnActive)="btnActiveStatus($event)"></app-fliptable>
    </div>
    <div class="col-sm-8">
        <!-- <div class="form-group global-form">
          <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global_submit" type="submit"
          [disabled]="buttonStatus ?  true : null" (click)="OnSave();">
            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label></button>
        </div> -->
        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of ['General','Cash Management','Permission']; let i = index" [expanded]="indexExpanded == i" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-expansion-panel-header (click)="expandPanel(item,index)" [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                    <mat-panel-title (click)="onChangeAccordion($event,index)" [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General'">
                    <app-role-permission-general [DataIdx]="tableRowId" [buttonState]="buttonDisable" (rolepermissId)="getResponseID($event)" (cashmgtdetails)="getcashmgtdetails($event)" (isInvalid)="isInvalidAccordion($event)" (rolepersessData_child)="getrolepermissionData($event)"
                        (matopen)="matstate($event)">
                    </app-role-permission-general>
                </div>
                <div *ngIf="item == 'Cash Management'">
                    <app-cash-management (matopen)="matstate($event)" [DataIdx]="tableRowId" [buttonState]="buttonDisable" [getRoleID]="roleItem" [getcashmgtdetailslist]="cashmgtdetailslist" (CashMgtData_child)="getrolepermissionData($event)">
                    </app-cash-management>
                </div>
                <div *ngIf="item == 'Permission'">
                    <app-role-permission-details [DataIdx]="tableRowId" [buttonState]="buttonDisable" [getRoleID]="roleItem" [DataRole]="roleDataemit" (roleperdetailsData_child)="getrolepermissionData($event)"></app-role-permission-details>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="form-group">
            <button class="btn  btn-sm float-right submit_button button-none" type="submit" [disabled]="buttonStatus ?  true : null" (click)="OnSave();">
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label></button>
        </div>
    </div>
</div>
