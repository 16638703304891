import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import { Router } from '@angular/router';
import { BaseService } from '../base/base.service';
import { ISelectReasonDetails,IEditReasonDetails, IActivateReasonDetails, IReasonDetails } from '../interfaces/ireason';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ReasonService {
  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) {
  }

  //------------------------GENERAL Reasons

  SaveReasons(e: IReasonDetails) {
    return this.base.post('Menu/SaveReasons', e).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectReasons(e: ISelectReasonDetails) {
    return this.base.post('Menu/SelectReasons', e).pipe(map((response: any) => {
      return response;
    }));
  }

  EditReasons(e: IEditReasonDetails) {
    return this.base.post('Menu/EditReasons', e).pipe(map((response: any) => {
      return response;
    }));
  }

  ActivateReasonDetails(e: IActivateReasonDetails) {
    return this.base.post('Menu/DeleteReasons', e).pipe(map((response: any) => {
      return response;
    }));
  }
}