<div class="tablet_logistic">
    <form class="loginForm" (change)="onchange(tabletLogisticForm.value)">
        <!-- [formGroup]="tabletLogisticForm" -->
        <div class="row mb-2">
            <div class="col-sm-8">
                <div class="Communication_tab">
                    <div class="row m-2">
                        <div class="col-sm-3 col-3 top_img">
                            <div>
                                    <button type="button" class="btn p-0 submit_button box_button">
                                            <img src="assets/components/images/take-screenshot.png" alt="employee"
                                            class="img-fluid img_width" (click)="onViewScreen($event)">
                                    </button>
                                    <label>Take new screenshot</label>

                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <label class="mt-3">IT MAY TAKE 10-20 SECONDS TO VIEW THE SNAPSHOT, HIT REFRESH IF
                                LONGER.</label>
                        </div>
                        <div class="col-sm-3 col-3 top_img">
                            <div>
                                    <button type="button" class="btn p-0 submit_button refresh box_button">
                                <img src="assets/components/images/refresh-button@2x.png" alt="employee"
                                    class="img-fluid img_width" (click)="onRefresh($event)">
                                    </button>
                            </div>
                        </div>
                    </div>
                    <div class="mx-5">
                        <div class="image_tab" *ngIf="imgClicked">
                            <img [src]="rowClickedImg" alt="employee" class="img-fluid img_logistics">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="Communication_tab">
                    <h6 class="text-center com_label">ScreenShots Snapchat:</h6>
                    <div *ngFor="let m of tabletdetails">
                        <div class="row screenshot_div"
                            [style.background-color]="rowClicked == m.id ? 'rgb(89 67 105)!important' : 'var(--primary) !important'"
                            [ngClass]="{'screen_frame': rowClicked == m.id}">
                            <div class="col-sm-3 col-3 img_screenshot">

                   
                                <!-- <img [src]="'data:image/jpeg;base64,'+m.Screenshot" width="70px" (click)="onChangeImage(m)" />  -->
                                
                               <img [src]="m.Screenshot" width="70px" (click)="onChangeImage(m)" />
                            </div>
                            <div class="col-sm-3 col-3 screenshot_name">
                                {{m.TableID}}
                            </div>
                            <div class="col-sm-6 col-6 time_screenshot">
                                {{m.SnapshotTime}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- <table mat-table #table [dataSource]="dataSource" matSort class="mat-elevation-z8  table dataTable"
                        style="width: 100%;">
                        <ng-container *ngFor="let item of columns " matColumnDef="{{item.columnDef}}" class="Communication_tab">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{item.header}} </th>

                            <td mat-cell *matCellDef="let element; let idx=index;">

                                <div *ngIf="item.columnDef == 'image'" class="emp-name">
                            <img [src]="element[item.columnDef]" class="img-fluid screenshot"></div>

                                <div *ngIf="item.columnDef == 'name'" class="emp-name">
                                    <img src="assets/components/images/picture-upload-2-x.png"
                                        class="img-fluid screenshot">
                                    {{ element[item.columnDef] }}
                                </div>

                                <div *ngIf="item.columnDef == 'time'" class="emp-name">
                                    {{ element[item.columnDef] }}</div>

                            </td>
                        </ng-container>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell employeenodata" colspan="4">No data matching the search</td>
                        </tr>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns" style="display: none;"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            [ngClass]="{'dropzone-placeholder': row.id == 1}" (click)="onChangeImage(row)"
                            [style.background-color]="rowClicked === row.id ? 'rgb(89 67 105)!important' : 'transparent!important'"></tr>

                    </table> -->