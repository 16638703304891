import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import { Router } from '@angular/router';
import { BaseService } from '../base/base.service';
import {
  ITagsDetails, ISelectTagDetails, IActivateTagDetails, ITagPrintDetails, ITagReports, ISaveTagsItemDetails,
  ISelectTagItemDetails, IScheduler, ITagFilter
} from '../interfaces/i-tags';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TagsService {
  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) {
  }

  //------------------------GENERAL TAG

  SaveTagDetails(e: ITagsDetails) {
    return this.base.post('Tag/SaveTagsDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectTagDetails(e: ISelectTagDetails) {
    return this.base.post('Tag/SelectTagsDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  ActivateTagDetails(e: IActivateTagDetails) {
    return this.base.post('Tag/DeleteTagsDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  //----------------------PRINTER

  SaveTagPrint(e: ITagPrintDetails) {
    return this.base.post('Tag/SaveTagsPrint', e).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectAvailableJobs(e: ISelectTagDetails) {
    return this.base.post('Tag/SelectAvaiableJobs', e).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectAvailablePrinter(e: ISelectTagDetails) {
    return this.base.post('Tag/SelectAvaiablePrinter', e).pipe(map((response: any) => {
      return response;
    }));
  }

  //-----------------------------FILTER

  SaveTagFilterDetails(e: ITagFilter) {
    return this.base.post('Tag/SaveTagsFilter', e).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectAvailableFilter(e: ISelectTagDetails) {
    return this.base.post('Tag/SelectAvailableFilter', e).pipe(map((response: any) => {
      return response;
    }));
  }

  //--------------------- Reports

  SaveTagReports(e: ITagReports) {
    return this.base.post('Tag/SaveTagsReport', e).pipe(map((response: any) => {
      return response;
    }));
  }

  //-----------------------------ITEM

  SaveTagItem(e: ISaveTagsItemDetails) {
    return this.base.post('Tag/SaveItemAssigntoTags', e).pipe(map((response: any) => {
      return response;
    }));
  }

  // SelectTagItem(e: ISelectTagItemDetails) {
  //   return this.base.post('Tag/SelectItemAssigntoTags', e).pipe(map((response: any) => {
  //     return response;
  //   }));
  // }
  SelectTagItemTable(e: ISelectTagItemDetails) {
    return this.base.post('Tag/SelectTagItemFilter', e).pipe(map((response: any) => {
      return response;
    }));
  }

  ActivateTagItemDetails(e: ISaveTagsItemDetails) {
    return this.base.post('Tag/DeleteItemAssigntoTags', e).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectTagItemFilter(e: ISelectTagItemDetails) {
    return this.base.post('Tag/SelectItemDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  //-----------------------------Scheduler

  SaveScheduler(e: IScheduler) {
    return this.base.post('Scheduler/SaveDayPartTag', e).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectDayPartDetails(e: ISelectTagDetails) {
    return this.base.post('Scheduler/SelectDayPartDetails', e).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectDayPartValidation(e: IScheduler) {
    return this.base.post('Scheduler/SelectDayPartValidation', e).pipe(map((response: any) => {
      return response;
    }));
  }

  // SelectTagFilteriPadSortOrderDetails(e: ISelectTagDetails) {
  //   return this.base.post('Tag/SelectTagsFilteriPadSortOrder', e).pipe(map((response: any) => {
  //     return response;
  //   }));
  // }

  // ActivateTagFilterDetails(e: IActivateTagDetails) {
  //   return this.base.post('Tag/DeleteTagsFilter', e).pipe(map((response: any) => {
  //     return response;
  //   }));
  // }

  // DeleteTagPrint(e: IActivateTagDetails) {
  //   return this.base.post('Tag/DeleteTagsPrint', e).pipe(map((response: any) => {
  //     return response;
  //   }));
  // }

  // SelectTagPrint(e: ISelectTagDetails) {
  //   return this.base.post('Tag/SelectTagsPrint', e).pipe(map((response: any) => {
  //     return response;
  //   }));
  // }

  // SelectTagJobPrint(e: ISelectTagDetails) {
  //   return this.base.post('Tag/SelectTagsJobPrint', e).pipe(map((response: any) => {
  //     return response;
  //   }));
  // }
}
