<div class="gift-card-front gift-card-width">
  <div class="top-header gift-front mt-1 w-100 card-outline" [ngClass]="{'is-whiteCard-bg': whiteBg == true, 'is-blackCard-bg': whiteBg == false}">
    <div class="card text-center card-ht pd-card">
      <div class="row no-gutters">
        <div class="col">
          <div class="card-body p-0">
            <div class="title-reason d-block">
              <img
                [src]="url"
                *ngIf="format == 'image' || format == 'url'"
                max-width="300"
                height="180"
              />
              <br />
            </div>
            <h6 class="card-title mb-0 ptb-3 ft-16">{{ customLogoText }}</h6>
            <p class="card-text mb-0 pt-1 custom-text">{{ giftSlogan }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="card-class">FRONT OF THE CARD</p>
</div>
