import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IListInfo, GetAllUserList, IActiveUser, ISelectState, GetTypeOfBus } from 'src/app/interfaces/iadmin';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { menuItemddl } from 'src/app/interfaces/imenu';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';
import { browserRefresh } from 'src/app/app.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})

export class CompanyComponent implements OnInit {
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public BusinessList: any;

  SizeList: menuItemddl[];
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public compData = new EventEmitter();
  loginInfo: any;
  tenantTypeID: any;
  public displayedColumns = [];
  public EmployeeTableData: any;
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  indexExpanded: number = 1;
  tableEnable: boolean = true;
  GeneralCompanyForm: FormGroup;
  submittedGeneral = false;
  ParentId: any;
  Position: any;
  userId: any;
  isInvalid: boolean = false; //Accordion Color Invalid
  requiredErrors: boolean = false; //FocusOut Accordion Invaild Color//
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  DealerID: any;
  public companyRow: any;
  @Input() public CompIdItem;
  //@Input() public buttonDisable
  // public buttonDisable: boolean = false;
  public buttonDisable: boolean;
  filteredList2: any = []
  public variables: any = []
  clickUpdated: any
  testdata: []
  mgs: any = '';
  public tableRowColor: any;
  filterNodata: any
  zipCodeMsg: any = '';
  searchField;
  mobNumberPattern = "^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$";
  isValidFormSubmitted = false;
  errorValue: any; //Company Name MAX CHARACTER LIMIT//
  errorValue1: any; //Contact First Name MAX CHARACTER LIMIT//
  errorValue2: any; //Contact Last Name MAX CHARACTER LIMIT//
  errorValue3: any; //Contact Email MAX CHARACTER LIMIT//
  errorValue5: any; //City MAX CHARACTER LIMIT//
  errorValue6: any; //State MAX CHARACTER LIMIT//
  public modifierItemsData: any;
  private onDestroy$: Subject<void> = new Subject<void>();
  @Output() public CompanySessData = new EventEmitter();
  matopen: boolean = false;
  // public variables = ['One', 'Two', 'County', 'Three', 'Zebra', 'XiOn'];
  // public filteredList2 = this.variables.slice();
  constructor(private companyDetails: AdminService, private empDetails: EmployeeService, private formBuilder: FormBuilder,
    private toaster: ToasterService, private helper: HelperService, private changeDetector: ChangeDetectorRef,
    private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.companyPage");

    this.tableRowColor = 'transparent';
    this.employeeName = "Company Name";
    this.employee = "Company";
    this.employeeIcon = "assets/components/images/site2x.png"

    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)

    this.GeneralCompanyForm = this.formBuilder.group({
      tenantId: [0],
      tenantName: [null, Validators.required],
      contactPhone: [null],
      contactFirstName: [null],
      contactLastName: [null],
      //contactEmail: [null, [Validators.email, Validators.pattern('^[a-zA-Z0-9._%#!$%^&*+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]{2,4}$')]],
      contactEmail: [null, Validators.email],
      address: [null],
      city: [null],
      state: [null],
      zipCode: [null],
      bussinessId: [null],
      isCorporate: [null],
      isActive: [true],
      isDemo: [true],
      isDeleted: [false],
    });
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.tenantTypeID = this.loginInfo.tenantType;

    // this.companyDetails.HierachyValue.subscribe(data => this.dealerValue = data);
    // this.empDetails.clickValues.subscribe(data => this.clickUpdated = data);

    //const name = localStorage.getItem('isDealerSelect');
    if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" ||
      localStorage.getItem('isDealerSelect') == "" || localStorage.getItem('isDealerSelect') === null) {
      if (localStorage.deal_ID != undefined && localStorage.deal_ID != null && localStorage.deal_ID != "") {
        this.getDetailedData(localStorage.getItem('deal_ID'));
      }

      if (localStorage.comp_ID != undefined && localStorage.comp_ID != null && localStorage.comp_ID != "") {
        this.tableRowColor = localStorage.getItem('comp_ID');
        this.GetCompanyList(localStorage.getItem('comp_ID'));
      }
    }
    else {
      this.empDetails.clickValues.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
        this.clickUpdated = data;
      });

      this.companyDetails.HierachyValue.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
        this.isInvalid = false;
        this.requiredErrors = false;
        this.companyRow = data;

        if (this.companyRow.company != undefined && this.companyRow.company != null
          && this.companyRow.company != "") {
          this.CompIdItem = this.companyRow;
          this.tableRowColor = this.companyRow;
        }
      });

      if (this.tenantTypeID == 2 && this.companyRow.dealer.id == "") {
        this.getDetailedData(0);
      } else {
        let DealerID = this.companyRow.dealer != undefined && this.companyRow.dealer != null && this.companyRow.dealer != "" ? this.companyRow.dealer : 0;
        if (DealerID == 0) {
          this.toaster.hierarchyPopUp("Please Select Dealer Before Selecting Company");
          this.buttonDisable = true;
        }
        else {
          this.buttonDisable = false;
          this.getDetailedData(this.companyRow.dealer.id);
        }
      }
    }

    this.onSelectState();
    this.getTypeofBusinessData(0);
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }
  matstate($event){
    this.matopen = $event
  }
  onKeypressEvent(elem): boolean {
    const charCode = (elem.which) ? elem.which : elem.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (elem.target.value.length < 3 || (elem.target.value.length > 3 || elem.target.value.length < 11)) {
      if (elem.key === "-") elem.preventDefault();
    }
    // this.GeneralCompanyForm.get("zipCode").patchValue(elem.target.value.toUpperCase());
    if (elem.target.value.length <= 10) {
      if (elem.target.value.length === 5) {
        elem.target.value = `${elem.target.value}-`;
      }
      this.zipCodeMsg = ''
    } else {
      elem.target.value = elem.target.value.slice(0, -1);
      this.zipCodeMsg = ''
    }
    return true;
  };
  get GenCompanyData() { return this.GeneralCompanyForm.controls; }

  clearSearchField() {
    this.searchField = ''
  }

  getDetailedData(id) {
    const login: IListInfo = {
      loginUserId: this.loginInfo.userId,
      tenantId: id
    }
    this.companyDetails.TenantHierarchyData(login).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        this.GetCompanyList(id);
        this.companyDetails.checkCompanyHierarchydata(response.childHierarchyList);
        this.dataSource.data = response.childHierarchyList;
        this.EmployeeTableData = response.childHierarchyList;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true).sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0)
        //   this.testdata = response.childHierarchyList.find(list => list.id == this.selectedPreItem)
        // this.gethierarchyData(this.testdata)   // have to check why this is using Don't delete this
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
        // this.tableEnable = false;
        // this.changeDetector.detectChanges();
        // this.tableEnable = true;
      })
    // this.GeneralCompanyForm.reset();
    // this.addCompany();
  }

  expandPanel(index, e?) {
    this.indexExpanded = index;
  }
  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? 1 : index;
    }
  }

  onChangeAccordion(e, index) {
    this.indexExpanded = index;
  }

  async addCompany() {
    this.errorValue = ""; //Company Name MAX CHARACTER LIMIT//
    this.errorValue1 = ""; //Contact First Name MAX CHARACTER LIMIT//
    this.errorValue2 = ""; //Contact Last Name MAX CHARACTER LIMIT//
    this.errorValue3 = ""; //Contact Email MAX CHARACTER LIMIT//
    this.errorValue5 = ""; //City MAX CHARACTER LIMIT//
    this.errorValue6 = ""; //State MAX CHARACTER LIMIT//
    this.isInvalid = false;
    this.requiredErrors = false;
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
      if (this.indexExpanded != undefined && this.indexExpanded != 1) {
        this.expandFirst('General', 1);
      }
    }
    else {
      var conf = await this.dialogser.confirm("companyPage");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
        if (this.indexExpanded != undefined && this.indexExpanded != 1) {
          this.expandFirst('General', 1);
        }
      }
      else {
        this.GeneralCompanyForm.patchValue(this.helper.getValue('CompanyData_Help'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  async ClearGenData() {
    localStorage.setItem('comp_ID', "");
    localStorage.setItem('comp_name', "");
    localStorage.setItem('str_ID', "");
    localStorage.setItem('str_name', "");

    localStorage.setItem('isCompanySelect', "");
    localStorage.setItem('isStoreSelect', "");

    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.zipCodeMsg = '';
    this.mgs = '';
    this.GeneralCompanyForm.reset();
    this.employeeName = "Company Name";
    this.tableRowColor = 'transparent';
    this.helper.setValue('CompanyData_Help', null);
    this.submittedGeneral = false;
    this.GeneralCompanyForm.patchValue({
      tenantId: 0,
      isActive: true,
    });
    this.titleName.emit(this.employeeName);
    this.compData.emit({ name: "CompanyName" });
    // this.clickUpdated.clickItem.id = 0
  }

  gethierarchyData(e) {
    if (e != undefined && e != null && e != "") {
      if (this.helper.getValue('OnDirty') == false) {
        this.compData.emit(e);  // is for data emitting to theme compoment
      }
    }
  }

  changeTableRowColor(event) {
    this.getCompany(event.id);
  }

  getTableName(name) {
    this.employeeName = name;
    this.employee = "Company";
    this.employeeIcon = "assets/components/images/site2x.png";
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
    if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" ||
      localStorage.getItem('isDealerSelect') == "" || localStorage.getItem('isDealerSelect') === null) {
      if (localStorage.comp_ID != undefined && localStorage.comp_ID != null && localStorage.comp_ID != "") {
        this.getTypeofBusinessData(localStorage.getItem('comp_ID'));
      }
    }
    else {
      if (this.companyRow != undefined && this.companyRow != null && this.companyRow != "") {
        this.getTypeofBusinessData(this.companyRow.company.id);
      }
    }
  }

  //---------------------------------Get Comany Details Start---------------------------------//

  async getCompany(empid) {
    this.errorValue = ""; //Company Name MAX CHARACTER LIMIT//
    this.errorValue1 = ""; //Contact First Name MAX CHARACTER LIMIT//
    this.errorValue2 = ""; //Contact Last Name MAX CHARACTER LIMIT//
    this.errorValue3 = ""; //Contact Email MAX CHARACTER LIMIT//
    this.errorValue5 = ""; //City MAX CHARACTER LIMIT//
    this.errorValue6 = ""; //State MAX CHARACTER LIMIT//

    localStorage.setItem('comp_ID', empid);
    localStorage.setItem('str_ID', "");
    localStorage.setItem('str_name', "");

    localStorage.setItem('isCompanySelect', "true");
    localStorage.setItem('isStoreSelect', "");

    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("companyPage");
      if (conf == 'success') { // Navigate Away
        this.dialogser.ClearSession();
        this.GeneralCompanyForm.reset();
        if (empid != 0) {
          this.GetCompanyList(empid);
        }
        this.flipcomp.rowClicked = empid;
        this.zipCodeMsg = '';
        this.mgs = '';
        this.dialogser.ModifiedId = empid;
        this.isInvalid = false;
        this.requiredErrors = false;
      }
      else {
        this.GeneralCompanyForm.patchValue(this.helper.getValue('CompanyData_Help'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        this.CompanySessData.emit({
          data: this.GeneralCompanyForm.value, event: "companyPage", val: false,
          itmname: this.GeneralCompanyForm.value.tenantName
        });
      }
    }
    else {
      this.dialogser.ModifiedId = empid;
      if (empid != 0) {
        this.GetCompanyList(empid);
      }
      this.requiredErrors = false;
      this.isInvalid = false;
    }
  }

  GetCompanyList(empid) {
    if (empid != undefined && empid != null && empid != "") {
      const employee: GetAllUserList = {
        tenantId: empid,
        loginUserId: this.loginInfo.userId
      }
      this.companyDetails.GetAllHierachy(employee).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
        if (this.helper.getValue('OnDirty') == false) {
          if (response.tenantTypeId == 3) {
            this.GeneralCompanyForm.patchValue(response);
            this.helper.setValue('CompanyData_Help', this.GeneralCompanyForm.value);
          }
        }
        else {
          this.GeneralCompanyForm.patchValue(this.helper.getValue('CompanyData_Help'));
        }
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
      this.onChangeBusiness(empid);
    }
  }

  //---------------------------------Get Company Details End---------------------------------//

  onChangeBusiness(empid) {
    const isTenant: IListInfo = {
      tenantId: empid,
      loginUserId: this.loginInfo.userId
    }
    this.companyDetails.GetTenantBusiness(isTenant).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      this.Position = response;
    })
  }

  checkValue(event) {
    let currentTarget = event.e.currentTarget.checked
    const active: IActiveUser = {
      userId: this.loginInfo.userId,
      tenantId: event.id,
      isActive: event.e.currentTarget.checked
    }
    this.companyDetails.IsActiveUser(active).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        this.getCompany(event.id);
        if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" ||
          localStorage.getItem('isDealerSelect') == "" || localStorage.getItem('isDealerSelect') === null) {
          this.getDetailedData(localStorage.getItem('deal_ID'));
        }
        else {
          this.getDetailedData(this.companyRow.dealer.id);
        }
        if (currentTarget) {
          this.toaster.successPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
        }
      },
      error => {
      })
  }

  //--------------------------------- SAVE  ---------------------------------//
  onSaveGeneral() {
    this.errorValue = ""; //Company Name MAX CHARACTER LIMIT//
    this.errorValue1 = ""; //Contact First Name MAX CHARACTER LIMIT//
    this.errorValue2 = ""; //Contact Last Name MAX CHARACTER LIMIT//
    this.errorValue3 = ""; //Contact Email MAX CHARACTER LIMIT//
    this.errorValue5 = ""; //City MAX CHARACTER LIMIT//
    this.errorValue6 = ""; //State MAX CHARACTER LIMIT//

    //--------------------------------- INVALID ACCORDION--------------------------------- //

    if ((this.GeneralCompanyForm.value.tenantName == null) || (this.GeneralCompanyForm.value.tenantName == "")) {
      this.isInvalid = true;
      this.requiredErrors = true;
    }
    else {
      this.isInvalid = false;
      this.requiredErrors = false;
    }
    //--------------------------------- INVALID ACCORDION--------------------------------- //

    this.GeneralCompanyForm.controls.tenantName.setValidators(Validators.required);
    this.GeneralCompanyForm.controls.tenantName.updateValueAndValidity();
    this.submittedGeneral = true;
    if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" ||
      localStorage.getItem('isDealerSelect') == "" || localStorage.getItem('isDealerSelect') === null) {
      this.DealerID = parseInt(localStorage.getItem('deal_ID'));
    }
    else {
      this.DealerID = this.companyRow.dealer.id != undefined && this.companyRow.dealer.id != null &&
        this.companyRow.dealer.id != "" ? this.companyRow.dealer.id : 0;
    }
    if (this.GeneralCompanyForm.invalid) {
      this.isInvalid = true;
      return;
    } else {
      if (this.GeneralCompanyForm.controls.contactPhone.value != undefined && this.GeneralCompanyForm.controls.contactPhone.value != null && this.GeneralCompanyForm.controls.contactPhone.value != "") {
        if (this.GeneralCompanyForm.controls.contactPhone.value.length <= 12) {
          this.mgs = 'Phone number must be at least 10 digit';
          this.isInvalid = true;
          return
        } else {
          this.mgs = ''
        }
      } else {
        this.mgs = ''
      }
    }
    if (this.GeneralCompanyForm.controls.zipCode.value != undefined && this.GeneralCompanyForm.controls.zipCode.value != null && this.GeneralCompanyForm.controls.zipCode.value != "") {
      if (this.GeneralCompanyForm.controls.zipCode.value.length === 10) {
        if (this.GeneralCompanyForm.controls.zipCode.value.slice(5, 6) != '-') {
          this.zipCodeMsg = 'Invalid Zip Code';
          return;
        }
      }
      if (this.GeneralCompanyForm.controls.zipCode.value.length <= 4) {
        this.zipCodeMsg = 'Please enter min 5 digits zipcode'
        this.isInvalid = true;
        return
      } else {
        this.zipCodeMsg = ''
      }
    } else {
      this.zipCodeMsg = ''
    }

    const company = this.GeneralCompanyForm.value;
    company.sectionId = 1;
    //company.tenantId = parseInt(this.tenantId) > 0 ? parseInt(this.tenantId) : 0;
    company.tenantTypeId = 3;
    company.parentId = this.DealerID;
    company.tenantName = company.tenantName.trim();
    if (company.tenantName == "") {
      this.toaster.errorPopUp('Please Enter the Company Name');
      return;
    }
    // const obj = company.contactPhone
    // if (obj != undefined && obj != "" && obj != null) {
    //   company.contactPhone = obj.internationalNumber;
    // }
    // else {
    //   company.contactPhone = null;
    // }

    const objphone = company.contactPhone;
    if (objphone != undefined && objphone != "" && objphone != null) {
      let trimphone = objphone.toString().match(/\d/g);
      //const isAllZero1 = trimphone.some(item => item !== "0");
      const isAllZero = trimphone.every((val, i, arr) => val === "0");
      if (parseInt(trimphone.length) != 10) {
        this.toaster.errorPopUp('Phone number must be at least 10 digit');
        return;
      }
      else if (isAllZero == true) {
        this.toaster.errorPopUp('Invalid Mobile Number');
        return;
      }
      else {
        company.contactPhone = objphone.replace(/[^-()\d ]/g, '');
      }
    }

    this.companyDetails.UserHierachy(company).pipe(takeUntil(this.onDestroy$)).subscribe(async (response) => {
      this.GeneralCompanyForm.patchValue({
        tenantId: response.id,
      });

      this.submittedGeneral = false;
      this.getDetailedData(this.DealerID);
      if (response.message == "Duplicate Tenant name!") {
        this.toaster.errorPopUp(response.message);
      } else {
        this.helper.setValue('Sessionid_help', response.id);
        if (this.helper.getValue('Sessionid_help') == undefined ||
          this.helper.getValue('Sessionid_help') == null || this.helper.getValue('Sessionid_help') == "") {
          this.helper.setValue('Sessionid_help', 0)
        }
        else {
          this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
          this.flipcomp.rowClicked = response.id;
          this.helper.setValue('CompanyData_Help', company);
          this.loginInfo.tenantId = response.id;
          this.helper.setValue('LoginInfo', this.loginInfo);
          await this.dialogser.ClearSession();
          this.toaster.successPopUp(response.message);
          localStorage.setItem('comp_ID', response.id);
          localStorage.setItem('comp_name', company.tenantName.trim());

          this.CompanySessData.emit({
            data: company, event: "companyPage", val: false, itmname: this.GeneralCompanyForm.value.tenantName
          });
        }
      }
      //this.GeneralCompanyForm.patchValue({ isActive: true });  //This is commented because After Uncheck and save its getting check
      this.GeneralCompanyForm.controls.tenantName.setErrors(null);
      this.GeneralCompanyForm.controls.tenantName.clearValidators();
      this.GeneralCompanyForm.controls.tenantName.updateValueAndValidity({ onlySelf: true });
    }), (err) => console.error('Error', err), () => console.log('HTTP request completed.');
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onSelectState() {
    const ObjState: ISelectState = {
      id: 0
    }
    this.companyDetails.SelectStateMaster(ObjState).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      this.filteredList2 = response
      this.variables = this.filteredList2.slice()
    })
  }

  onKey(value) {
    this.filteredList2 = this.search(value);
  }

  search(value: string) {
    let filteredList2 = this.variables.filter((unit) =>
      unit.id.indexOf(value.toUpperCase()) > -1);
    if (value.length == 1) {
      let filterdata = filteredList2.filter((x) =>
        x.id.substring(0, 1) == value.toUpperCase());
      return [...filterdata];
    } else if (value.length == 2) {
      let filterSecdata = filteredList2.filter((y) =>
        y.id.substring(0, 2) == value.toUpperCase());
      return [...filterSecdata];
    } else {
      this.filterNodata = true
      return [...filteredList2];
    }
  }

  numberOnly2(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (event.target.value.length <= 14) {
      if (event.target.value.length === 3) {
        event.target.value = `(${event.target.value}) `;
      }
      if (event.target.value.length === 9) {
        event.target.value = `${event.target.value}-`;
      }
      if (event.target.value.length === 11) {
        event.target.value = `${event.target.value}`;
      }
      if (event.target.value.length >= 13) {
        this.isInvalid = false;
        this.mgs = '';
      }
      // if (event.target.value.length <= 12) {
      //   this.mgs = 'Phone number must be at least 10 numbers'
      //   return true
      // } else if(event.target.value.length == 0) {
      //   this.mgs = ''
      // }
      // else  {
      //   this.mgs = ''
      // }

    } else {
      event.target.value = event.target.value.slice(0, -1)
    }
    return true;
  }

  phoneNumberCheck(event) {
    if (event.target.value.length == 0) {
      this.mgs = '';
    }
    if (event.target.value.slice(0, 1) != '(') {
      if (event.target.value.length > 7 && event.target.value.length < 12) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}-${event.target.value.slice(7, 11)}`;
      }
      else if (event.target.value.length > 10) {
        this.mgs = 'Invalid mobile number';
        return;
      }
      else if (event.target.value.length < 3 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)})`;

        // if (event.target.value.length < 15) {
        //   this.mgs = 'Invalid mobile number';
        //   return;
        // }
        // this.mgs = ''
      }

      else if (event.target.value.length < 6 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}`;
        // if (event.target.value.length < 15) {
        //   this.mgs = 'Invalid mobile number';
        //   return;
        // }
        // this.mgs = ''
      }
      else if (event.target.value.length >= 6 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}-${event.target.value.slice(6, event.target.value.length)}`;
        if (event.target.value.length < 14) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = ''
      }
    }
    else {
      if (event.target.value.length < 14) {
        this.mgs = 'Invalid mobile number';
        return;
      }
      this.mgs = '';
    }
  }


  onDeletePhone(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.mgs = ''
      }
    }
  }

  getTypeofBusinessData(idValue) {
    const login: GetTypeOfBus = {
      id: idValue,
      name: ""
    }
    this.companyDetails.GetTypeOfBusiness(login).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        this.BusinessList = response;
      })
  }

  btnActiveStatus(event) {
    if (event == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !event;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  //---------------------------------MAX CHARS VALIDATION Start--------------------------------------//
  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  getMaxValueFName(e) {
    if (e.target.value.length == 50) {
      this.errorValue1 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue1 = ""
    }
  }

  getMaxValueLName(e) {
    if (e.target.value.length == 50) {
      this.errorValue2 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue2 = ""
    }
  }

  getMaxValueEmail(e) {
    if (e.target.value.length == 100) {
      this.errorValue3 = "Max-Limit 100 characters reached";
    }
    else {
      this.errorValue3 = ""
    }
  }

  getMaxValueCity(e) {
    if (e.target.value.length == 50) {
      this.errorValue5 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue5 = ""
    }
  }

  getMaxValueTerritory(e) {
    if (e.target.value.length == 50) {
      this.errorValue6 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue6 = ""
    }
  }
  //---------------------------------MAX CHARS VALIDATION End--------------------------------------//

  //--------------------------------- FOCUS OUT VALIDATION Starts--------------------------------- //
  CompanyNameValid() {
    if ((this.GeneralCompanyForm.value.tenantName == null) || (this.GeneralCompanyForm.value.tenantName == "")) {
      this.isInvalid = true;
      this.requiredErrors = true;
      if (this.GeneralCompanyForm.invalid) {
        return;
      }
    }
    else {
      this.isInvalid = false;
      this.requiredErrors = false;
    }
  }
  //--------------------------------- FOCUS OUT VALIDATION Ends ---------------------------------//

  //---------------------------------KEYPRESS VALIDATION COLOR---------------------------------//
  Highlight() {
    if (this.GeneralCompanyForm.value.tenantName != null) {
      if (this.GeneralCompanyForm.value.tenantName.length < 0) {
        this.requiredErrors = true;
        this.isInvalid = true;
      }
      else {
        this.requiredErrors = false;
        this.isInvalid = false;
      }
    }

    else if ((this.GeneralCompanyForm.value.tenantName == null) || (this.GeneralCompanyForm.value.tenantName == '')
      && (this.GeneralCompanyForm.value.tenantName.length() == 0)) {
      this.requiredErrors = false;
      this.isInvalid = false;
    }

    else if ((this.GeneralCompanyForm.value.tenantName == null) || (this.GeneralCompanyForm.value.tenantName == '')) {
      this.requiredErrors = true;
      this.isInvalid = true;
    }
  }
  //---------------------------------KEYPRESS VALIDATION COLOR---------------------------------//

  async ngAfterViewInit() {
    //this.GeneralCompanyForm.patchValue(this.helper.getValue('CompanyData_Help'));
    //this.CompanySessData.emit({ data: this.GeneralCompanyForm.value, event: "companyPage", val: "" });
    //await this.dialogser.ClearSession();

    if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true"
      || localStorage.getItem('isDealerSelect') == "" || localStorage.getItem('isDealerSelect') === null) {
      if (localStorage.getItem('comp_ID') != undefined && localStorage.getItem('comp_ID') != null && localStorage.getItem('comp_ID') != "") {
        this.GetCompanyList(localStorage.getItem('comp_ID'));
      }
    }
    else {
      if (this.companyRow.company != undefined && this.companyRow.company != null && this.companyRow.company != "") {
        this.GetCompanyList(this.companyRow.company.id);
      }
    }
  }

  onchange(data) {
    this.helper.setValue('CompanyData_Help', data);
    this.helper.setValue('OnDirty', true);
    this.CompanySessData.emit({
      data: data, event: "companyPage", val: true,
      itmname: this.GeneralCompanyForm.value.tenantName
    });
  }

  zipCodeCheck(event) {
    if (event.target.value.length === 6) {
      if (event.target.value.slice(5, 6) == '-') {
        event.target.value = `${event.target.value.slice(0, 5)}`;
      }
    }
    else if (event.target.value.length > 5 && event.target.value.length < 10) {
      if (event.target.value.slice(5, 6) != '-') {
        event.target.value = `${event.target.value.slice(0, 5)}-${event.target.value.slice(6, event.target.value.length)}`;
        this.zipCodeMsg = "";
      }
    }
    else if (event.target.value.length === 9) {
      event.target.value = `${event.target.value.slice(0, 5)}-${event.target.value.slice(6, 9)}`;
    }

    else if (event.target.value.length === 10) {
      if (event.target.value.slice(5, 6) != '-') {
        this.zipCodeMsg = "Invalid Zip Code";
      }
      else {
        this.zipCodeMsg = "";
      }
    }
  }

  onDeleteZipCode(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.zipCodeMsg = ''
      }
    }
  }

  // isFormPageDirty() {
  //   let d = this.GeneralCompanyForm.dirty;
  //   this.helper.setValue('CompanyData_Help', this.GeneralCompanyForm.value);
  //   if (d == true) {
  //     this.helper.setValue('OnDirty', true);
  //   }
  //   return d;
  // }
}
