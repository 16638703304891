import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { EmployeeService } from 'src/app/services/employee.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { MenuService } from 'src/app/services/menu.service';
import {
  menuItemList,
  ActivateMenuSize,
  GetItemtype,
  ItemRecipe,
  iItemModifier,
} from 'src/app/interfaces/imenu';
import { MenuItemGeneralComponent } from '../menu-item-general/menu-item-general.component';
import { MenuItemLogisticsComponent } from '../menu-item-logistics/menu-item-logistics.component';
import { FormArray } from '@angular/forms';
import { NgStyle } from '@angular/common';
import { DragDropBuilderComponent } from 'src/app/elements/drag-drop-builder/drag-drop-builder.component';
import { ModifierService } from 'src/app/services/modifier.service';
import { MenuItemModifierComponent } from '../menu-item-modifier/menu-item-modifier.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FilterTablePopupComponent } from 'src/app/elements/filter-table-popup/filter-table-popup.component';
import { MatAccordion } from '@angular/material/expansion';
import { DialogService } from 'src/app/services/dialog.service';
import { FliptableItemsComponent } from 'src/app/elements/fliptable-items/fliptable-items.component';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css'],
})
export class MenuItemComponent implements OnInit {
  targetBuilderTools: any = [];
  public displayedColumns = [];
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  @ViewChild(DragDropBuilderComponent)
  DragDropComponent: DragDropBuilderComponent;
  @ViewChild(MenuItemGeneralComponent)
  generalComponent: MenuItemGeneralComponent;
  @ViewChild(MenuItemLogisticsComponent)
  LogisticComponent: MenuItemLogisticsComponent;
  @ViewChild(MenuItemModifierComponent)
  ModifierComponent: MenuItemModifierComponent;
  @ViewChild(FliptableItemsComponent) flipcomp: FliptableItemsComponent;
  @ViewChild(MatSort) sort: MatSort;
  tableEnable: boolean = true;
  rowClicked;
  loginInfo: any;
  itmId: any = 0;
  public tableRowId: any;
  public tableRowColor: any;
  public menuItem: any;
  public menuItemId: any;
  public EmployeeTableData: any;
  public activeDeactiveValue: any;
  public menuPriceSizeList: any;
  public ItemListData: any;
  public menuItemData: any;
  public ItemMatrix: boolean = true;
  dynquery: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public menuSizeRow: any;
  menuItemModifierArr: any = [];
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public mnuitmsessData = new EventEmitter();

  //@Input() public buttonDisable
  clickUpdated: any;
  modalRef: BsModalRef;
  public checkValueMain: any;
  submittedGeneral: any;
  private buttonDisable: boolean;
  isInvalid: boolean = false;
  public modifierItemsData: any;
  buttonStatus: any;
  PageName: any;
  indexExpanded: number = 0;
  //tempval: any = [];
  typeField: string;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  colorBtn: any;
  KDScolorBtn: any;
  ItemListDataComplete: any;
  menuItemsList: any[];
  dataMain: any[];
  dataSide: any[];
  dataRemarks: any[];
  dataIngredient: any[];
  indexOfType: number;
  indexOfBar: number;
  matopen: boolean = false;
  matstate($event) {
    this.matopen = $event
  }

  constructor(
    private menuService: EmployeeService,
    private changeDetector: ChangeDetectorRef,
    private ModifierSrv: ModifierService,
    private toaster: ToasterService,
    private helper: HelperService,
    private objItem: MenuService,
    private modalService: BsModalService,
    private dialogser: DialogService
  ) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', 'this.MenuItemPage');

    this.PageName = 'MenuItemPage';
    this.buttonStatus = false;
    this.employeeName = 'Menu Items';
    this.employee = 'Menu-Items';
    this.employeeIcon = 'assets/components/images/menu-item.png';
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
    this.tableEnable = true;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getMenuItemListData(this.itmId);
    this.getMenuItemList();

    this.menuService.clickValues.subscribe(
      (data) => (this.clickUpdated = data)
    );
    this.buttonDisable = false;
    if (
      this.helper.getValue('OnDirty') == false &&
      this.helper.getValue('OnDirty2') == false &&
      this.helper.getValue('OnDirty3') == false &&
      this.helper.getValue('OnDirty4') == false
    ) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
    this.typeField = '';
  }

  @Input()
  public set MenuEmit(MenuModEmit) {
    this.menuItemData = MenuModEmit;
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  expandPanel(index, e?) {
    this.indexExpanded = index;
  }
  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }
  }

  onChangeAccordion(e, index) {
    if (e.currentTarget.innerText == 'Modifiers') {
      this.ItemMatrix = true;
    }
  }

  async getTableId(event) {
    if (
      this.helper.getValue('OnDirty') == true ||
      this.helper.getValue('OnDirty2') == true ||
      this.helper.getValue('OnDirty3') == true ||
      this.helper.getValue('OnDirty4') == true
    ) {
      var conf = await this.dialogser.confirm('MenuItemPage');
      if (conf == 'success') {
        // Navigate Away
        if (event == this.dialogser.ModifiedId) {
          this.generalComponent.GetItemGeneralList(event);
        }
        this.dialogser.ClearSession();
        this.generalComponent.MenuItemForm.reset();
        this.dialogser.ModifiedId = event;
        this.helper.setValue('Sessionid_help', event);
        this.menuItem = event;
        this.tableRowId = event;
        this.flipcomp.rowClicked = event;
        this.tableRowColor = event;
        this.isInvalid = false;
        this.generalComponent.requiredError = false;
        this.generalComponent.errorValue = '';
        this.generalComponent.errorValue1 = '';
        await this.getMenuItemListData(event);
        await this.GetReceipeObjList(event);
        await this.GetItemModifier(event);

        //Recipe Data
        this.DragDropComponent.ngOnInit();
        await this.GetReceipeObjList(event);
        if (this.generalComponent.errorMsg != undefined) {
          this.generalComponent.errorMsg.value = '';
          this.generalComponent.errorMsg.index = null;
        }
        if (this.generalComponent.errorMsgPrice != undefined) {
          this.generalComponent.errorMsgPrice.value = '';
          this.generalComponent.errorMsgPrice.index = null;
        }
        this.generalComponent.addMultiPrice = true;
        this.generalComponent.addMulti = true;
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
      } // Cancel
      else {
        //General Data
        this.generalComponent.MenuItemForm.patchValue(
          this.helper.getValue('GetSessionData1')
        );
        this.colorBtn = this.LogisticComponent.btnColor(
          this.LogisticComponent.MenuItemFormlogistic.value.buttonColor
        );
        this.KDScolorBtn = this.LogisticComponent.KDSbtnColor(
          this.LogisticComponent.MenuItemFormlogistic.value.kdsColor
        );
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        this.mnuitmsessData.emit({
          data: this.generalComponent.MenuItemForm.value,
          event: 'MenuItemPage',
          val: true,
          itmname: this.generalComponent.MenuItemForm.value.name,
        });

        //Recipe Data
        var arrdata_json = this.helper.getValue('GetSessionData3');
        if (
          arrdata_json != undefined &&
          arrdata_json != null &&
          arrdata_json != '' &&
          arrdata_json != '[]'
        ) {
          var arrdata = JSON.parse(arrdata_json);
          this.DragDropComponent.targetBuilderTools = null;
          this.DragDropComponent.targetBuilderTools = arrdata;
        }
      }
    } else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event);
      this.menuItem = event;
      this.tableRowId = event;
      this.flipcomp.rowClicked = event;
      this.tableRowColor = event;
      this.isInvalid = false;
      this.generalComponent.requiredError = false;
      this.DragDropComponent.ngOnInit();
      this.generalComponent.errorValue = '';
      this.generalComponent.errorValue1 = '';
      await this.getMenuItemListData(event);
      await this.GetReceipeObjList(event);
      await this.GetItemModifier(event);
      if (this.generalComponent.errorMsg != undefined) {
        this.generalComponent.errorMsg.value = '';
        this.generalComponent.errorMsg.index = null;
      }
      if (this.generalComponent.errorMsgPrice != undefined) {
        this.generalComponent.errorMsgPrice.value = '';
        this.generalComponent.errorMsgPrice.index = null;
      }
      this.generalComponent.addMultiPrice = true;
      this.generalComponent.addMulti = true;
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
    }
  }

  getMenuItemID(event) {
    this.helper.setValue('Sessionid_help', event.id);
    this.getMenuItemList();
    this.flipcomp.selectTableRowColor(event.id, event.id, event.name);
  }

  getMenuItemList() {
    const menuItemList: menuItemList = {
      itemID: 0,
      tenantId: this.loginInfo.tenantId,
    };
    this.objItem.MenuItemList(menuItemList).subscribe((res) => {
      this.dataSource.data = res;
      this.EmployeeTableData = res;
      this.dataSource.data = this.dataSource.data.filter(
        (x) => x.isActive == true
      );
      this.menuItemsList = this.dataSource.data;
      this.displayedColumns = ['name', 'isActive'];
      this.columns = [
        { columnDef: 'name', header: 'Item Name' },
        { columnDef: 'isActive', header: 'Status' },
      ];
    });

    if (
      this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == ''
    ) {
      this.helper.setValue('Sessionid_help', 0);
    } else {
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
      this.menuSizeRow = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  getTableName(name) {
    this.employeeName = name;
    this.employee = 'Menu-Items';
    this.employeeIcon = 'assets/components/images/menu-item.png';
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
  }

  async addMenuItem() {
    if (
      this.helper.getValue('OnDirty') == false &&
      this.helper.getValue('OnDirty2') == false &&
      this.helper.getValue('OnDirty3') == false &&
      this.helper.getValue('OnDirty4') == false
    ) {
      if (this.LogisticComponent.MenuItemFormlogistic.value.buttonColor != undefined) {
        if (this.LogisticComponent.MenuItemFormlogistic.value.buttonColor.false != undefined) {
          this.colorBtn = this.LogisticComponent.btnColor(
            this.LogisticComponent.MenuItemFormlogistic.value.buttonColor.false
          );
        }

        if (this.LogisticComponent.MenuItemFormlogistic.value.kdsColor.false != undefined) {
          this.KDScolorBtn = this.LogisticComponent.KDSbtnColor(
            this.LogisticComponent.MenuItemFormlogistic.value.kdsColor.false
          );
        }

      }
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
      this.ClearGenData();
      this.LogisticComponent.imgUrl = 'assets/components/images/picture-upload-2-x.png';
      this.menuPriceSizeList = [];
    } else {
      var conf = await this.dialogser.confirm('MenuItemPage');
      if (conf == 'success') {
        // Navigate Away

        if (
          this.LogisticComponent.MenuItemFormlogistic.value.buttonColor !=
          undefined
        ) {
          this.colorBtn = this.LogisticComponent.btnColor(
            this.LogisticComponent.MenuItemFormlogistic.value.buttonColor.false
          );
          this.KDScolorBtn = this.LogisticComponent.KDSbtnColor(
            this.LogisticComponent.MenuItemFormlogistic.value.kdsColor.false
          );
        }

        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
        this.ClearGenData();
        this.LogisticComponent.imgUrl = 'assets/components/images/picture-upload-2-x.png';
        this.menuPriceSizeList = [];

      } else {

        this.generalComponent.MenuItemForm.patchValue(
          this.helper.getValue('GetSessionData1')
        );
        this.colorBtn = this.LogisticComponent.btnColor(
          this.LogisticComponent.MenuItemFormlogistic.value.buttonColor
        );
        this.KDScolorBtn = this.LogisticComponent.KDSbtnColor(
          this.LogisticComponent.MenuItemFormlogistic.value.kdsColor.false
        );
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        this.generalComponent.PriceToggle = false;
      }
    }
  }
  btnColor(btnColor: any) {
    throw new Error('Method not implemented.');
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = 'transparent';
    this.generalComponent.errorValue = '';
    this.generalComponent.errorValue1 = '';
    this.generalComponent.requiredError = false;
    this.isInvalid = false;
    this.generalComponent.PriceToggle = false;
    this.employeeName = 'Menu Items';
    this.tableRowColor = 'transparent';
    this.objItem.getSourceBuilder().subscribe((data) => {
      this.DragDropComponent.targetBuilderTools = data;
    });
    this.generalComponent.submittedGeneral = false;
    this.DragDropComponent.ngOnInit();
    this.generalComponent.taxselectedItem = [];
    this.generalComponent.MenuItemForm.reset();
    this.generalComponent.checkboxValue = true;
    this.generalComponent.MenuItemForm.controls.pricingStyle.disable();
    this.LogisticComponent.MenuItemFormlogistic.reset();
    this.LogisticComponent.MenuItemFormlogistic.value.buttonColor = '#666769';
    this.LogisticComponent.MenuItemFormlogistic.value.kdsColor = '#666769';
    this.ModifierComponent.targetBuilderTools = [];
    this.ModifierComponent.getMenuGroupListData(0);
    let dynControl = this.generalComponent.MenuItemForm.controls[
      'sizedetails'
    ] as FormArray;
    for (let i = 0; i < dynControl.length; i++) {
      if (
        !(
          (<FormArray>this.generalComponent.MenuItemForm.get('sizedetails'))
            .length == 1
        )
      ) {
        (<FormArray>(
          this.generalComponent.MenuItemForm.get('sizedetails')
        )).removeAt(i);
      }
    }
    this.tableRowId = 0;
    this.helper.setValue('GetSessionData1', null);
    this.getMenuItemListData(0);
    if (this.generalComponent.errorMsg != undefined) {
      this.generalComponent.errorMsg.value = '';
      this.generalComponent.errorMsg.index = null;
    }
    if (this.generalComponent.errorMsgPrice != undefined) {
      this.generalComponent.errorMsgPrice.value = '';
      this.generalComponent.errorMsgPrice.index = null;
    }
    this.generalComponent.addMultiPrice = true;
    this.generalComponent.addMulti = true;
    this.titleName.emit(this.employeeName);
    this.generalComponent.ngOnInit();
  }

  getMenuId(id) {
    this.menuItemId = id;
  }

  getPricingList(e) {
    this.menuPriceSizeList = e;
  }

  checkValue(event) {
    this.activeDeactiveValue = event;
    let currentTarget = event.e.currentTarget.checked;
    const active: ActivateMenuSize = {
      userid: this.loginInfo.userId,
      id: event.id,
      isActive: event.e.currentTarget.checked,
    };
    this.objItem.ActivateMenuItem(active).subscribe((response) => {
      if (currentTarget) {
        this.toaster.successPopUp(response.message);
      } else {
        this.toaster.successPopUp(response.message);
        this.ClearGenData();
      }
      this.getMenuItemList();
    });

  }

  async getMenuItemListData(_itemid) {
    const ItemTypeList: GetItemtype = {
      itemId: _itemid,
      itemType: 'recipe',
      tenantId: this.loginInfo.tenantId, // Only itemtype Ingredient true record is fetched
    };
    this.objItem.GetItemTypeList(ItemTypeList).subscribe((res) => {
      this.ItemListData = res;
    });
  }

  /////Menu Type Filter//////////

  menuFilterAssign(type) {
    if (type == 'All') {
      this.menuFilter(type);
      return;
    }
    if (this.typeField != '') {
      this.typeField = this.typeField + ' || x.' + type + " == true";
    }
    else {
      this.typeField = 'x.' + type + " == true";
    }
    this.menuFilter(this.typeField);
  }

  menuFilter(type) {
    this.dataSource.data = [];
    if (type == 'All') {
      this.dataSource.data = this.menuItemsList;
      return;
    }
    this.dataSource.data = this.menuItemsList.filter(x => eval(this.typeField));
  }

  menuFilterOut(type) {
    this.indexOfType = this.typeField.indexOf(type);
    this.indexOfBar = this.typeField.indexOf('||');
    if (this.indexOfType > this.indexOfBar && this.indexOfBar != -1) {
      this.typeField = this.typeField.replace(' || x.' + type + " == true", '');
      this.typeField = this.typeField.trim();
      if (this.typeField == '') {
        this.dataSource.data = this.menuItemsList;
      }
      else {
        this.dataSource.data = this.menuItemsList.filter(x => eval(this.typeField));
      }
    }
    else {
      if (this.indexOfBar == -1) {
        this.typeField = this.typeField.replace('x.' + type + " == true", '');
        this.typeField = this.typeField.trim();
      }
      else {
        this.typeField = this.typeField.replace('x.' + type + " == true ||", '');
        this.typeField = this.typeField.trim();
      }

      if (this.typeField == '') {
        this.dataSource.data = this.menuItemsList;
      }
      else {
        this.dataSource.data = this.menuItemsList.filter(x => eval(this.typeField));
      }
    }
  }

  /////Menu Type Filter//////////

  async onSaveItem(event): Promise<any> {
    return new Promise((resolve, reject) => {
      if (event != undefined) {
        let JsonValue = event.itemObject;
        let id = this.helper.getValue('Sessionid_help');
        const ItemRecipeList: ItemRecipe = {
          parentID: id,
          userid: this.loginInfo.userId,
          tenantId: this.loginInfo.tenantId,
          itemObject: JsonValue,
        };
        this.ModifierSrv.MenuItemRecipeSave(ItemRecipeList).subscribe(
          async (res) => {
            resolve({ res: res });
            //    alert('recipe1')
            // this.toaster.successPopUp(res.message);
          }
        );
      }
    });
  }

  //--------------------------

  async GetReceipeObjList(itemId) {
    const ItemList: menuItemList = {
      itemID: itemId,
      tenantId: this.loginInfo.tenantId,
    };
    this.objItem.MenuItemList(ItemList).subscribe((res) => {
      if (
        res[0].recipeObject != undefined &&
        res[0].recipeObject != null &&
        res[0].recipeObject != '' &&
        res[0].recipeObject != '[]'
      ) {
        var arrdata = JSON.parse(res[0].recipeObject);
        if (
          arrdata != undefined &&
          arrdata != null &&
          arrdata != '' &&
          arrdata != '[]'
        ) {
          this.DragDropComponent.targetBuilderTools = null;
          this.DragDropComponent.targetBuilderTools = arrdata;
        }
      } else {
        this.objItem.getSourceBuilder().subscribe((data) => {
          this.DragDropComponent.targetBuilderTools = data;
        });
      }
    });
  }

  async GetItemModifier(itemId) {
    const ItemNo: iItemModifier = {
      itemID: itemId,
    };
    this.objItem.GetItemModifier(ItemNo).subscribe((res) => {
      this.menuItemModifierArr = [];
      this.ModifierComponent.targetBuilderTools = [];
      for (let i = 0; i < res.length; i++) {
        var itemObj = JSON.parse(res[i].Item_Object);
        let menuItemModObj = {
          id: res[i].id,
          name: res[i].name,
          Item_Object: itemObj,
        };

        console.log(menuItemModObj, 'menuItemModObj')
        this.menuItemModifierArr.push(menuItemModObj);

        this.ModifierComponent.targetBuilderTools = this.menuItemModifierArr;
      }
    });
  }

  getmainEmit(e) {
    // console.log(e, 'Ingedient')
    this.checkValueMain = e;
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  @Input()
  public set buttonState(btnst) {
    this.buttonStatus = !btnst;
  }

  async onSave() {
    if (this.generalComponent.MenuItemForm.value.main == false && this.generalComponent.MenuItemForm.value.sideDish == false && this.generalComponent.MenuItemForm.value.remark == false && this.generalComponent.MenuItemForm.value.ingredient == false) {
      this.toaster.errorPopUp('Select atleast one Item Type');
      return;
    }
    if (this.generalComponent.MenuItemForm.invalid) {
      this.isInvalid = true;
    } else {
      this.isInvalid = false;
    }
    this.generalComponent.errorValue = '';
    this.generalComponent.errorValue1 = '';
    let respose = await this.generalComponent.onSaveMenuItem();
    //   this.ModifierComponent.CheckMainDish(respose.name);
    if (respose.id != 0) {
      this.LogisticComponent.onSubmit(respose.id);
      this.helper.setValue('Sessionid_help', respose.id);
      this.ModifierComponent.OnSaveItemModifier(respose.id);
      let dragdrop = await this.onSaveItem(
        this.DragDropComponent.OnSave(respose.id)
      );
    }
    await this.dialogser.ClearSession();
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getmnuitmsessData(e) {
    this.mnuitmsessData.emit(e);
  }

  GetMainDishStatus(e?) {
    this.ModifierComponent.CheckMainDish(e);
  }
}
