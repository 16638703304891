import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef, Input, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AdminService } from 'src/app/services/admin.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/helper/helper.service';
import { IListInfo, IActiveUser, GetAllUserList, ISelectState } from 'src/app/interfaces/iadmin';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { CompanyComponent } from '../company/company.component';
import { EmployeeService } from 'src/app/services/employee.service';
import { CurrencyPipe } from '@angular/common';
import { DialogService } from 'src/app/services/dialog.service';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { Subject } from 'rxjs';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { takeUntil } from 'rxjs/operators';
import { browserRefresh } from 'src/app/app.component';

//---------------------------------Calendar Format Begin---------------------------------//
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
//---------------------------------Calendar Format End---------------------------------//

@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.component.html',
  styleUrls: ['./dealer.component.css'],
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DealerComponent implements OnInit {
  public buttonDisable: boolean;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  arrList: any = [];
  previousEmpid: any = 0;
  @Output() public clearDetails = new EventEmitter();
  @Output() public clearPayment = new EventEmitter();
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public DealerSessData = new EventEmitter();

  @ViewChild('myDealer') _el: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  @ViewChild(CompanyComponent) companyViewChild: CompanyComponent;

  mgs: any = '';
  dateMgs: any;
  GeneralDealerForm: FormGroup;
  public displayedColumns = [];
  columns = [];
  item = { value: 10000 };
  public dataSource = new MatTableDataSource<any>();
  submittedGeneral = false;
  typeDealer: any;
  isvParentData: any;
  //tenantId: any;
  tableEnable: boolean = true;
  loginInfo: any;
  isInvalid: boolean = false; //Accordion Invaild Color//
  requiredErrors: boolean = false; //FocusOut Accordion Invaild Color//
  mobNumberPattern = '^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$';
  isValidFormSubmitted = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  @Output() public dealerData_ = new EventEmitter();
  public EmployeeTableData: any;
  @Input() public dealerIdItem;

  public dealerRow: any;
  public isvId: any;
  // public variables = ['One', 'Two', 'County', 'Three', 'Zebra', 'XiOn'];
  // public filteredList2 = this.variables.slice();
  //testdata: []
  filteredList2: any = [];
  public variables: any = [];
  clickUpdated: any;
  filterNodata: any;
  zipCodeMsg: any = '';
  FirstClick: boolean = true;
  public modifierItemsData: any;
  public tableRowColor: any;
  isOpen = 'Close';
  errorValue: any; //Dealer Name MAX CHARACTER LIMIT//
  errorValue1: any; //Contact First Name MAX CHARACTER LIMIT//
  errorValue2: any; //Contact Last Name MAX CHARACTER LIMIT//
  errorValue3: any; //Contact Email MAX CHARACTER LIMIT//
  errorValue5: any; //City MAX CHARACTER LIMIT//
  errorValue6: any; //Territory MAX CHARACTER LIMIT//
  minDate: any;
  indexExpanded: number = 1;
  matopen:boolean=false;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private dealerDetails: AdminService, private formBuilder: FormBuilder, private toaster: ToasterService,
    private helper: HelperService, private changeDetector: ChangeDetectorRef, private router: Router,
    private empService: EmployeeService, private currencyPipe: CurrencyPipe, private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', 'this.dealerPage');

    this.employeeName = 'Dealer Name';
    this.employee = 'Dealer';
    this.employeeIcon = 'assets/components/images/site2x.png';
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
    this.minDate = new Date(1900, 0, 1);
    this.GeneralDealerForm = this.formBuilder.group({
      // tenantName: [null, [Validators.required, Validators.pattern('/^\s+|\s+$/g')]],
      // contactPhone: [null],
      tenantId: [0],
      tenantName: [null, [Validators.required]],
      contactPhone: new FormControl(undefined),
      resellerStartDate: moment(new Date()),
      contactFirstName: [null],
      contactLastName: [null],
      contactEmail: [null, [Validators.email]],
      address: [null],
      city: [null],
      state: [null],
      zipCode: [null],
      projectedVolume: [null],
      projectedVolLpm: [null],
      territory: [null],
      projected: [false],
      vested: [false],
      isActive: [true],
      isDemo: [true],
      isDeleted: [false],
    });
    this.loginInfo = this.helper.getValue('LoginInfo');

    if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" || localStorage.getItem('deal_ID') !== null) {
      if (localStorage.deal_ID != undefined && localStorage.deal_ID != null && localStorage.deal_ID != "") {
        this.tableRowColor = localStorage.getItem('deal_ID');
        this.GetDealaerList(localStorage.getItem('deal_ID'));
      }
    } else {
      this.dealerDetails.hieDealerParentData.pipe(takeUntil(this.onDestroy$))
        .subscribe((data) => (this.isvParentData = data)),
        (err) => console.error('Error', err), () => console.log('HTTP request completed.');

      this.empService.clickValues.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
        this.clickUpdated = data;
      }), (err) => console.error('Error', err), () => console.log('HTTP request completed.');

      this.dealerDetails.HierachyValue.pipe(takeUntil(this.onDestroy$)).subscribe(
        (data) => {
          this.dealerRow = data;
          if (data.dealer != undefined && data.dealer != null && data.dealer != "") {
            localStorage.setItem('deal_ID', data.dealer.id);
            localStorage.setItem('deal_name', data.dealer.name);
          }

          if (data.company != undefined && data.company != null && data.company != "") {
            localStorage.setItem('comp_ID', data.company.id);
            localStorage.setItem('comp_name', data.company.name);
          }

          if (data.store != undefined && data.store != null && data.store != "") {
            localStorage.setItem('str_ID', data.store.id);
            localStorage.setItem('str_name', data.store.name);
          }

          this.isvId = data.isv.id;
          if (this.isvId != undefined && this.isvId != null && this.isvId != '' && this.isvId != 0) {
            localStorage.setItem('ISVId', this.isvId);
          }
          if (this.dealerRow.dealer != undefined && this.dealerRow.dealer != null && this.dealerRow.dealer != '') {
            // this.getDealer(this.dealerRow.dealer);
            // this.getDetailedData(this.dealerRow.isv.id)
            this.dealerIdItem = this.dealerRow;
            this.tableRowColor = this.dealerRow;
            this.isInvalid = false;
            this.requiredErrors = false;
            this.dateMgs = '';
          }
        }
      ), (err) => console.error('Error', err), () => console.log('HTTP request completed.');

      this.tableRowColor = 'transparent';
    }

    this.getDetailedData(this.isvId);
    this.onSelectState();
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }
  matstate($event){
    this.matopen = $event
  }
  get DelaerData() {
    return this.GeneralDealerForm.controls;
  }

  onKeypressEvent(elem): boolean {
    const charCode = elem.which ? elem.which : elem.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (elem.target.value.length < 3 || elem.target.value.length > 3 || elem.target.value.length < 11) {
      if (elem.key === '-') elem.preventDefault();
    }
    // this.GeneralCompanyForm.get("zipCode").patchValue(elem.target.value.toUpperCase());
    if (elem.target.value.length <= 10) {
      if (elem.target.value.length === 5) {
        elem.target.value = `${elem.target.value}-`;
      }
      this.zipCodeMsg = '';
    } else {
      elem.target.value = elem.target.value.slice(0, -1);
      this.zipCodeMsg = '';
    }
    return true;
  }

  get GeneralDealerData() {
    return this.GeneralDealerForm.controls;
  }

  //--------------------------------- SAVE ---------------------------------//
  onSaveGeneral() {
    this.errorValue = ''; //Dealer Name MAX CHARACTER LIMIT//
    this.errorValue1 = ''; //Contact First Name MAX CHARACTER LIMIT//
    this.errorValue2 = ''; //Contact Last Name MAX CHARACTER LIMIT//
    this.errorValue3 = ''; //Contact Email MAX CHARACTER LIMIT//
    this.errorValue5 = ''; //City MAX CHARACTER LIMIT//
    this.errorValue6 = ''; //Territory MAX CHARACTER LIMIT//

    //--------------------------------- INVALID ACCORDION--------------------------------- //
    if (
      this.GeneralDealerForm.value.tenantName == null ||
      this.GeneralDealerForm.value.tenantName == ''
    ) {
      this.isInvalid = true;
      this.requiredErrors = true;
    } else {
      this.isInvalid = false;
      this.requiredErrors = false;
    }
    //--------------------------------- INVALID ACCORDION ---------------------------------//

    this.GeneralDealerForm.controls.tenantName.setValidators(
      Validators.required
    );
    this.GeneralDealerForm.controls.tenantName.updateValueAndValidity();
    if (browserRefresh == false) {
      if (this.typeDealer > 0) {
        this.typeDealer = this.typeDealer;
      } else if (this.isvId != undefined && this.isvId != null && this.isvId != "") {
        this.typeDealer = this.isvId;
      }
      else if (localStorage.getItem('ISVId') != undefined && localStorage.getItem('ISVId') != null && localStorage.getItem('ISVId') != "") {
        this.typeDealer = parseInt(localStorage.getItem('ISVId'));
      }
    } else {
      this.typeDealer = parseInt(localStorage.getItem('ISVId'));
    }
    this.submittedGeneral = true;

    if (this.GeneralDealerForm.invalid) {
      this.isInvalid = true;
      if (this.GeneralDealerForm.controls.resellerStartDate.invalid) {
        this.dateMgs = 'Invalid Date';
        return;
      } else {
        this.dateMgs = '';
      }
      return;
    } else {
      if (
        this.GeneralDealerForm.controls.contactPhone.value != undefined &&
        this.GeneralDealerForm.controls.contactPhone.value != null &&
        this.GeneralDealerForm.controls.contactPhone.value != ''
      ) {
        if (this.GeneralDealerForm.controls.contactPhone.value.length <= 12) {
          this.mgs = 'Phone number must be at least 10 digit';
          return;
        } else {
          this.mgs = '';
        }
      } else {
        this.mgs = '';
      }

      if (
        this.GeneralDealerForm.controls.zipCode.value != undefined &&
        this.GeneralDealerForm.controls.zipCode.value != null &&
        this.GeneralDealerForm.controls.zipCode.value != ''
      ) {
        if (this.GeneralDealerForm.controls.zipCode.value.length === 10) {
          if (this.GeneralDealerForm.controls.zipCode.value.slice(5, 6) != '-') {
            this.zipCodeMsg = 'Invalid Zip Code';
            return;
          }
        }
        if (this.GeneralDealerForm.controls.zipCode.value.length <= 4) {
          this.zipCodeMsg = 'Please enter min 5 digits zipcode';
          return;
        } else {
          this.zipCodeMsg = '';
        }
      } else {
        this.zipCodeMsg = '';
      }
    }

    const employee = this.GeneralDealerForm.value;
    employee.sectionId = 1;
    //employee.tenantId = parseInt(this.tenantId) > 0 ? parseInt(this.tenantId) : 0;
    employee.tenantTypeId = 2;
    employee.parentId = this.typeDealer;
    employee.tenantName = employee.tenantName.trim();
    if (employee.tenantName == '') {
      this.toaster.errorPopUp('Please Enter the Dealer Name');
      return;
    }

    if (this.GeneralDealerForm.value.resellerStartDate == '' || this.GeneralDealerForm.value.resellerStartDate == null) {
      this.GeneralDealerForm.value.resellerStartDate = null;
    } else {
      this.GeneralDealerForm.value.resellerStartDate = moment(
        new Date(this.GeneralDealerForm.value.resellerStartDate)
      ).format('YYYY-MM-DD');
    }

    const objphone = employee.contactPhone;
    if (objphone != undefined && objphone != '' && objphone != null) {
      let trimphone = objphone.toString().match(/\d/g);
      //const isAllZero1 = trimphone.some(item => item !== "0");
      const isAllZero = trimphone.every((val, i, arr) => val === '0');
      if (parseInt(trimphone.length) != 10) {
        this.toaster.errorPopUp('Phone number must be at least 10 digit');
        return;
      } else if (isAllZero == true) {
        this.toaster.errorPopUp('Invalid Mobile Number');
        return;
      } else {
        employee.contactPhone = objphone.replace(/[^-()\d ]/g, '');
      }
    }
    this.dealerDetails.UserHierachy(employee).pipe(takeUntil(this.onDestroy$)).subscribe(async (response) => {
      this.GeneralDealerForm.patchValue({
        tenantId: response.id,
      });

      this.requiredErrors = false;
      this.submittedGeneral = false;
      this.getDetailedData(this.typeDealer);
      if (response.message == 'Duplicate Tenant Name!') {
        this.toaster.errorPopUp(response.message);
      } else if (response.id == 0) {
        this.toaster.errorPopUp(response.message);
      } else {
        this.helper.setValue('Sessionid_help', response.id);
        if (
          this.helper.getValue('Sessionid_help') == undefined ||
          this.helper.getValue('Sessionid_help') == null ||
          this.helper.getValue('Sessionid_help') == ''
        ) {
          this.helper.setValue('Sessionid_help', 0);
        } else {
          this.tableRowColor = parseInt(
            this.helper.getValue('Sessionid_help')
          );
          this.flipcomp.rowClicked = response.id;
          this.helper.setValue('DealerData_Help', employee);
          this.loginInfo.tenantId = response.id;
          this.helper.setValue('LoginInfo', this.loginInfo);
          await this.dialogser.ClearSession();
          this.toaster.successPopUp(response.message);
          localStorage.setItem('deal_ID', response.id);
          localStorage.setItem('deal_name', employee.tenantName.trim());

          this.DealerSessData.emit({
            data: employee, event: 'dealerPage', val: false, itmname: this.GeneralDealerForm.value.tenantName,
          });
        }
      }
      //this.GeneralDealerForm.patchValue({ isActive: true });  //This is commented because After Uncheck and save its getting check
      this.GeneralDealerForm.controls.tenantName.setErrors(null);
      this.GeneralDealerForm.controls.tenantName.clearValidators();
      this.GeneralDealerForm.controls.tenantName.updateValueAndValidity({
        onlySelf: true,
      });
    }), (err) => console.error('Error', err), () => console.log('HTTP request completed.');
  }
  //--------------------------------- SAVE Ends ---------------------------------//

  addEmployee(employee: any, hierarchy: any, area: any, payment: any) {
    this.clearDetails.emit({ 1: employee, 2: hierarchy, 3: area });
    this.clearPayment.emit({ 4: payment });
  }

  getDetailedData(id) {
    const login: IListInfo = {
      loginUserId: this.loginInfo.userId,
      tenantId: id,
    };
    this.dealerDetails.TenantHierarchyData(login).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      if (response.typeName == 'ISV') {
        this.dealerDetails.checkDealerHierarchydata(
          response.childHierarchyList
        );
        this.dataSource.data = response.childHierarchyList;
        this.EmployeeTableData = response.childHierarchyList;
        this.dataSource.data = this.dataSource.data
          .filter((x) => x.isActive == true)
          .sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0));
        //    this.testdata = response.childHierarchyList.find(list => list.id == this.selectedPreItem)
        // this.gethierarchyData(this.testdata) // have to check why this is using Don't delete this
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Name' },
          { columnDef: 'isActive', header: 'Status' },
        ];
        this.tableEnable = false;
        this.changeDetector.detectChanges();
        this.tableEnable = true;
      }
    }), (err) => console.error('Error', err), () => console.log('HTTP request completed.');
    //this.companyViewChild.GeneralCompanyForm.reset();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkValue(event) {
    let currentTarget = event.e.currentTarget.checked;
    const active: IActiveUser = {
      userId: this.loginInfo.userId,
      tenantId: event.id,
      isActive: event.e.currentTarget.checked,
    };
    this.dealerDetails.IsActiveUser(active).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      this.getDealer(event.id);
      if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" || localStorage.getItem('deal_ID') !== null) {
        this.getDetailedData(localStorage.getItem('ISVId'));
      }
      else {
        this.getDetailedData(this.isvParentData.id);
      }
      if (currentTarget) {
        this.toaster.successPopUp(response.message);
      } else {
        this.toaster.successPopUp(response.message);
      }
    }),
      (err) => console.error('Error', err), () => console.log('HTTP request completed.');
  }

  async getDealer(empid) {
    this.errorValue = ''; //Dealer Name MAX CHARACTER LIMIT//
    this.errorValue1 = ''; //Contact First Name MAX CHARACTER LIMIT//
    this.errorValue2 = ''; //Contact Last Name MAX CHARACTER LIMIT//
    this.errorValue3 = ''; //Contact Email MAX CHARACTER LIMIT//
    this.errorValue5 = ''; //City MAX CHARACTER LIMIT//
    this.errorValue6 = ''; //Territory MAX CHARACTER LIMIT//

    localStorage.setItem('deal_ID', empid);
    localStorage.setItem('comp_ID', "");
    localStorage.setItem('comp_name', "");
    localStorage.setItem('str_ID', "");
    localStorage.setItem('str_name', "");

    localStorage.setItem('isDealerSelect', "true");
    localStorage.setItem('isCompanySelect', "");
    localStorage.setItem('isStoreSelect', "");

    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm('dealerPage');
      if (conf == 'success') {
        // Navigate Away
        this.dialogser.ClearSession();
        this.GeneralDealerForm.reset();
        if (empid != 0) {
          this.GetDealaerList(empid);
        }
        this.flipcomp.rowClicked = empid;
        this.mgs = '';
        this.zipCodeMsg = '';
        this.dialogser.ModifiedId = empid;
        this.isInvalid = false;

      } else {
        this.GeneralDealerForm.patchValue(
          this.helper.getValue('DealerData_Help')
        );
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        this.DealerSessData.emit({
          data: this.GeneralDealerForm.value, event: 'dealerPage',
          val: true, itmname: this.GeneralDealerForm.value.tenantName,
        });
      }
    } else {
      this.dialogser.ModifiedId = empid;
      if (empid != 0) {
        this.GetDealaerList(empid);
      }
      this.isInvalid = false;
    }
  }

  //--------------------------------- GET DEALER ---------------------------------//

  GetDealaerList(empid) {
    if (empid != undefined && empid != null && empid != '' && empid != 0) {
      const employee: GetAllUserList = {
        tenantId: empid,
        loginUserId: this.loginInfo.userId,
      };
      this.dealerDetails.GetAllHierachy(employee).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
        if (this.helper.getValue('OnDirty') == false) {
          this.GeneralDealerForm.patchValue(response);
          this.GeneralDealerForm.patchValue({
            resellerStartDate: response.resellerStartDate == null ? '' : moment(response.resellerStartDate).format('YYYY-MM-DD'),
          });
          this.helper.setValue('DealerData_Help', this.GeneralDealerForm.value);
        } else {
          this.GeneralDealerForm.patchValue(this.helper.getValue('DealerData_Help'));
        }
      }), (err) => console.error('Error', err), () => console.log('HTTP request completed.');
    }
  }

  //--------------------------------- GET DEALER ---------------------------------//

  gethierarchyData(e) {
    if (e != undefined && e != null && e != '') {
      if (this.helper.getValue('OnDirty') == false) {
        this.dealerData_.emit(e); // is for data emitting to theme compoment
      }
    }
  }

  getTableName(name) {
    this.submittedGeneral = false;
    this.employeeName = name;
    this.employee = 'Dealer';
    this.employeeIcon = 'assets/components/images/dealer2x.png';
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
  }

  expandPanel(index, e?) {
    this.indexExpanded = index;
  }
  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? 1 : index;
    }
  }
  onChangeAccordion(e, index) {
    this.indexExpanded = index;
  }

  async adddealer() {
    this.errorValue = ''; //Dealer Name MAX CHARACTER LIMIT//
    this.errorValue1 = ''; //Contact First Name MAX CHARACTER LIMIT//
    this.errorValue2 = ''; //Contact Last Name MAX CHARACTER LIMIT//
    this.errorValue3 = ''; //Contact Email MAX CHARACTER LIMIT//
    this.errorValue5 = ''; //City MAX CHARACTER LIMIT//
    this.errorValue6 = ''; //Territory MAX CHARACTER LIMIT//

    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
      if (this.indexExpanded != undefined && this.indexExpanded != 1) {
        this.expandFirst('General', 1);
      }
    } else {
      var conf = await this.dialogser.confirm('dealerPage');
      if (conf == 'success') {
        // Navigate Away
        this.ClearGenData();
        if (this.indexExpanded != undefined && this.indexExpanded != 1) {
          this.expandFirst('General', 1);
        }
      } else {
        this.GeneralDealerForm.patchValue(this.helper.getValue('DealerData_Help'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        if (!this.GeneralDealerForm.invalid) {
          this.dateMgs = '';
          this.requiredErrors = false;
          this.isInvalid = false;
          return;
        }
      }
    }
  }

  ClearGenData() {
    localStorage.setItem('deal_ID', "");
    localStorage.setItem('deal_name', "");
    localStorage.setItem('comp_ID', "");
    localStorage.setItem('comp_name', "");
    localStorage.setItem('str_ID', "");
    localStorage.setItem('str_name', "");

    localStorage.setItem('isDealerSelect', "");
    localStorage.setItem('isCompanySelect', "");
    localStorage.setItem('isStoreSelect', "");

    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = 'transparent';
    this.mgs = '';
    this.zipCodeMsg = '';
    this.helper.setValue('DealerData_Help', null);
    this.isInvalid = false;
    //  this.arrList.push(this.GeneralDealerForm.value)
    this.requiredErrors = false;
    this.GeneralDealerForm.reset();
    this.employeeName = 'Dealer Name';
    this.tableRowColor = 'transparent';
    this.submittedGeneral = false;
    this.GeneralDealerForm.patchValue({
      isActive: true,
    });
    this.GeneralDealerForm.patchValue({ resellerStartDate: moment(new Date()) });
    this.dateMgs = ''; // Calendar message reset

    this.GeneralDealerForm.patchValue({
      tenantId: 0,
    });
    this.GeneralDealerForm.value.id = 0;
    this.titleName.emit(this.employeeName);
    this.dealerData_.emit({ name: 'DealerName' });
  }

  counter: any = 1;
  changeTableRowColor(event) {
    this.dealerRow = '';
    this.dealerRow = this.dealerRow === event.idx ? -1 : event.idx;
    if (this.counter == 2 || this.FirstClick == true) {
      this.getDealer(event.id);
      this.counter = 1;
      this.FirstClick = false;
    }
    this.counter++;
  }

  onSelectState() {
    const ObjState: ISelectState = {
      id: 0,
    };
    this.dealerDetails.SelectStateMaster(ObjState).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      this.filteredList2 = response;
      this.variables = this.filteredList2.slice();
    }), (err) => console.error('Error', err), () => console.log('HTTP request completed.');
  }

  onKey(value) {
    this.filteredList2 = this.search(value);
    //   this.filteredList2 = this.variables.filter(x=>x.name==value);
  }

  search(value: string) {
    let filteredList2 = this.variables.filter((unit) => unit.id.indexOf(value.toUpperCase()) > -1);
    if (value.length == 1) {
      let filterdata = filteredList2.filter(
        (x) => x.id.substring(0, 1) == value.toUpperCase()
      );
      return [...filterdata];
    } else if (value.length == 2) {
      let filterSecdata = filteredList2.filter(
        (y) => y.id.substring(0, 2) == value.toUpperCase()
      );
      return [...filterSecdata];
    } else {
      this.filterNodata = true;
      return [...filteredList2];
    }
  }

  numberOnly2(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (event.target.value.length == 0) {
      this.mgs = '';
    }
    if (event.target.value.length <= 14) {
      if (event.target.value.length === 3) {
        event.target.value = `(${event.target.value}) `;
      }
      if (event.target.value.length === 9) {
        event.target.value = `${event.target.value}-`;
      }
      if (event.target.value.length === 11) {
        event.target.value = `${event.target.value}`;
      }
      if (event.target.value.length >= 13) {
        this.mgs = '';
        this.isInvalid = false;
      }
    } else {
      event.target.value = event.target.value.slice(0, -1);
    }
    return true;
  }

  zipCodeCheck(event) {
    if (event.target.value.length === 6) {
      if (event.target.value.slice(5, 6) == '-') {
        event.target.value = `${event.target.value.slice(0, 5)}`;
      }
    } else if (event.target.value.length > 5 && event.target.value.length < 10) {
      if (event.target.value.slice(5, 6) != '-') {
        event.target.value = `${event.target.value.slice(0, 5)}-${event.target.value.slice(6, event.target.value.length)}`;
        this.zipCodeMsg = '';
      }
    } else if (event.target.value.length === 9) {
      event.target.value = `${event.target.value.slice(0, 5)}-${event.target.value.slice(6, 9)}`;
    } else if (event.target.value.length === 10) {
      if (event.target.value.slice(5, 6) != '-') {
        this.zipCodeMsg = 'Invalid Zip Code';
      } else {
        this.zipCodeMsg = '';
      }
    }
  }

  phoneNumberCheck(event) {
    if (event.target.value.length == 0) {
      this.mgs = '';
    }
    if (event.target.value.slice(0, 1) != '(') {
      if (event.target.value.length > 7 && event.target.value.length < 12) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}-${event.target.value.slice(
          7, 11)}`;
      } else if (event.target.value.length > 10) {
        this.mgs = 'Invalid mobile number';
        return;
      } else if (
        event.target.value.length < 3 &&
        event.target.value.length > 0
      ) {
        event.target.value = `(${event.target.value.slice(0, 3)})`;

        // if (event.target.value.length < 15) {
        //   this.mgs = 'Invalid mobile number';
        //   return;
        // }
        // this.mgs = ''
      } else if (event.target.value.length < 6 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}`;
        // if (event.target.value.length < 15) {
        //   this.mgs = 'Invalid mobile number';
        //   return;
        // }
        // this.mgs = ''
      } else if (
        event.target.value.length >= 6 &&
        event.target.value.length > 0
      ) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}-${event.target.value.slice(
          6, event.target.value.length)}`;
        if (event.target.value.length < 14) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = '';
      }
    } else {
      if (event.target.value.length < 14) {
        this.mgs = 'Invalid mobile number';
        return;
      }
      this.mgs = '';
    }
  }

  onDeletePhone(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.mgs = '';
      }
    }
  }

  onDeleteZipCode(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.zipCodeMsg = '';
      }
    }
  }

  btnActive(event) {
    this.buttonDisable = event;
    if (event == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !event;
    }
  }

  dff: boolean;
  arrVar: any = 0;

  // public GetDealerPage(): string {
  //   //this.GeneralDealerForm.patchValue(  this.helper.getValue('DealerData'));
  //   if (this.GeneralDealerForm.dirty) {
  //     //  this.arrList.push(this.GeneralDealerForm.value);
  //     this.helper.setValue('DealerData', this.GeneralDealerForm.value);
  //     this.dff = window.confirm('Changes that you made may not be saved');
  //     if (this.dff == true) {
  //       // this.helper.setValue('DealerData',null)
  //       return 'ok';
  //     }
  //     else {
  //       return 'cancel';
  //     }

  //   }
  //   // this.helper.setValue('DealerData',null)
  //   return 'Keep';
  // }

  //--------------------------------- FOCUS OUT VALIDATION Starts--------------------------------- //

  DealerNameValid() {
    if (!this.GeneralDealerForm.invalid) {
      this.requiredErrors = false;
      this.isInvalid = false;
    } else {
      this.requiredErrors = true;
      this.isInvalid = true;
    }
  }
  //--------------------------------- FOCUS OUT VALIDATION Ends ---------------------------------//

  //  Realese the memory
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  val: any = [];
  i: any = 0;
  onRedo() {
    this.val = this.helper.getValue('DealerData_Help');
    this.GeneralDealerForm.patchValue(this.val[this.i]);
    this.i++;
  }

  async ngAfterViewInit() {
    if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" ||
      localStorage.getItem('deal_ID') !== null) {
      if (localStorage.getItem('deal_ID') != undefined && localStorage.getItem('deal_ID') != null &&
        localStorage.getItem('deal_ID') != "") {
        this.GetDealaerList(localStorage.getItem('deal_ID'));
      }
    }
    else {
      if (this.dealerIdItem != undefined && this.dealerIdItem != null && this.dealerIdItem != '') {
        if (this.dealerIdItem.dealer != undefined && this.dealerIdItem.dealer != null &&
          this.dealerIdItem.dealer != '') {
          this.GetDealaerList(this.dealerIdItem.dealer.id);
        }
      }
    }
  }

  onchange(data) {
    this.helper.setValue('DealerData_Help', data);
    this.helper.setValue('OnDirty', true);
    this.DealerSessData.emit({
      data: data, event: 'dealerPage', val: true,
    });
  }

  //---------------------------------MAX CHARS VALIDATION Start--------------------------------------//
  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue = '';
    }
  }

  getMaxValueFName(e) {
    if (e.target.value.length == 50) {
      this.errorValue1 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue1 = '';
    }
  }

  getMaxValueLName(e) {
    if (e.target.value.length == 50) {
      this.errorValue2 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue2 = '';
    }
  }

  getMaxValueEmail(e) {
    if (e.target.value.length == 100) {
      this.errorValue3 = 'Max-Limit 100 characters reached';
    } else {
      this.errorValue3 = '';
    }
  }

  getMaxValueCity(e) {
    if (e.target.value.length == 50) {
      this.errorValue5 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue5 = '';
    }
  }

  getMaxValueTerritory(e) {
    if (e.target.value.length == 50) {
      this.errorValue6 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue6 = '';
    }
  }
  //---------------------------------MAX CHARS VALIDATION End--------------------------------------//

  //---------------------------------KEYPRESS VALIDATION COLOR---------------------------------//
  Highlight() {
    this.onchange(this.GeneralDealerForm.value);

    if (!this.GeneralDealerForm.invalid) {
      this.isInvalid = false;
      this.dateMgs = '';
    } else if (
      this.GeneralDealerForm.value.tenantName == null ||
      (this.GeneralDealerForm.value.tenantName == '' && this.GeneralDealerForm.value.tenantName.length == 0)
    ) {
      if (this.GeneralDealerForm.controls.resellerStartDate.invalid) {
        this.isInvalid = true;
        this.dateMgs = 'Invalid Date';
        return;
      } else {
        this.requiredErrors = false;
        this.dateMgs = '';
        this.isInvalid = false;
      }
    } else {
      if (this.GeneralDealerForm.controls.resellerStartDate.invalid) {
        this.isInvalid = true;
        this.dateMgs = 'Invalid Date';
        return;
      }
      this.isInvalid = true;
      this.dateMgs = '';
    }
  }

  //---------------------------------KEYPRESS VALIDATION COLOR---------------------------------//

  decimalNumberOnly(event) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);
    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  // isFormPageDirty() {
  //   let d = this.GeneralDealerForm.dirty;
  //   this.helper.setValue('DealerData_Help', this.GeneralDealerForm.value);
  //   if (d == true) {
  //     this.helper.setValue('OnDirty', true);
  //   }
  //   return d;
  // }
}
