import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetDevice } from 'src/app/interfaces/idevices';
import { DeviceService } from 'src/app/services/devices.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-tablet-general',
  templateUrl: './tablet-general.component.html',
  styleUrls: ['./tablet-general.component.css']
})

export class TabletGeneralComponent implements OnInit {
  tabletGeneralForm: FormGroup;
  buttonDisable: boolean = false;
  errorValue: any;
  errorValue1: any;
  errorValue2: any;
  @Output() public isInvalid = new EventEmitter();
  requiredErrors: boolean = false;
  isActive = false;
  viewScreens: boolean = false;
  loginInfo: any;
  submittedGeneral: any;
  // submittedSaveGeneral = false;
  // isSubmitted = false;
  // isValidated = false;

  @Input() public removeDisable;
  //@Input() public buttonState;

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set DataIdx(tableRowId) {
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.GetTabletDetails(tableRowId);
    }
  }

  @Output() public GeneralDetailTablet = new EventEmitter();
  @Output() public viewScreenItem = new EventEmitter();
  @Output() public tablet_general = new EventEmitter();
  @Output() public tabData_child = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private objtablet: DeviceService, private dialogser: DialogService,
    private helper: HelperService, private toaster: ToasterService) { }

  ngOnInit(): void {
    //this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.tabletGeneralForm = this.formBuilder.group({
      id: [0],
      name: ['', Validators.required],
      tabletModel: [''],
      deviceID: [''],
      pingDevice: [''],
      changeIP: [''],
      result: ['']
    })
  }
  get tabGenData() { return this.tabletGeneralForm.controls; }

  onSaveGeneral() {
    this.errorValue = "";
    this.errorValue1 = "";
    this.errorValue2 = "";
    this.submittedGeneral = true;
    if (this.tabletGeneralForm.invalid) {
      this.isInvalid.emit(true);
      return;
    }

    this.tabletGeneralForm.value.isActive = true;
    this.tabletGeneralForm.value.tenantId = this.loginInfo.tenantId;
    this.tabletGeneralForm.value.createdBy = this.loginInfo.userId;
    this.tabletGeneralForm.value.modifiedBy = this.loginInfo.userId;
    this.objtablet.TabletDeviceGeneralSave(this.tabletGeneralForm.value).subscribe(
      async (response) => {
        this.tabletGeneralForm.patchValue({
          id: response.id,
        });

        this.helper.setValue('Sessionid_help', response.id);
        this.submittedGeneral = false;
        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
          this.tabData_child.emit({ data: this.tabletGeneralForm.value, event: "tablet", val: false });
          this.helper.setValue('GetSessionData1', this.tabletGeneralForm.value);
        }
        this.GeneralDetailTablet.emit({ res: response, name: this.tabletGeneralForm.value.name });
        await this.dialogser.ClearSession();
      })
  }

  GetTabletDetails(rowId) {
    this.errorValue = "";
    this.errorValue1 = "";
    this.errorValue2 = "";
    const deviceList: GetDevice = {
      id: rowId,
      tenantId: this.loginInfo.tenantId
    }
    this.objtablet.TabletDeviceGet(deviceList)
      .subscribe(res => {
        this.tabletGeneralForm.patchValue(res[0]);
        //this.controls[name].patchValue(value[name], {onlySelf: true, emitEvent});
      })
  }

  RemoveTabletDetails() {
    this.submittedGeneral = true;
    if (this.tabletGeneralForm.invalid) {
      return;
    }
    //this.tabletGeneralForm.value.id = this.id;
    this.objtablet.RemoveTabletDeviceDetails(this.tabletGeneralForm.value).subscribe(
      (response) => {
        this.tablet_general.emit(response.id);
        this.toaster.successPopUp(response.message);
        this.submittedGeneral = false;
        this.tabletGeneralForm.reset();
      })
  }
  
  getScreenshot() {
    this.viewScreens = true
    this.viewScreenItem.emit(this.viewScreens)
  }

  getMaxValueTabName(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  getMaxValueTabModel(e) {
    if (e.target.value.length == 50) {
      this.errorValue1 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue1 = ""
    }
  }

  getMaxValueDevice(e) {
    if (e.target.value.length == 50) {
      this.errorValue2 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue2 = ""
    }
  }

  TabletNameValid() {
    if (this.tabletGeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredErrors = true;
      return;
    }
    else {
      this.isInvalid.emit(false);
      this.requiredErrors = false;
    }
  }

  Highlight() {
    if (this.tabletGeneralForm.invalid) {
      this.requiredErrors = true;
      this.isInvalid.emit(true);
    }
    else {
      this.requiredErrors = false;
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.tabletGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.tabData_child.emit(this.tabletGeneralForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.tabData_child.emit({ data: data, event: "tablet", val: true });
  }

  // isFormPageDirty() {
  //   let d = this.tabletGeneralForm.dirty;
  //   this.helper.setValue('GetSessionData1', this.tabletGeneralForm.value);
  //   if (d == true) {
  //     this.helper.setValue('OnDirty', true);
  //   }
  //   else {
  //     this.helper.setValue('OnDirty', false);
  //   }
  //   return d;
  // }
}
