import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { PaymentService } from 'src/app/services/payment.service';
import { GetSaleAreaGeneralDD } from 'src/app/interfaces/imenu';
import { ModifierService } from 'src/app/services/modifier.service';
import { ISelectState } from 'src/app/interfaces/iadmin';
import { AdminService } from 'src/app/services/admin.service';
import { stringify } from 'querystring';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DialogService } from 'src/app/services/dialog.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-house-accounts-general',
  templateUrl: './house-accounts-general.component.html',
  styleUrls: ['./house-accounts-general.component.css'],
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true },
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class HouseAccountsGeneralComponent implements OnInit {
  submittedGeneral = false;
  HouseAccountForm: FormGroup;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  loginInfo: any = 0;
  filteredList2: [];
  //  public variables = ['One','Two','County', 'Three', 'Zebra', 'XiOn'];
  //  public filteredList = this.variables.slice();
  filteredList: any = []
  public variables: any = []
  Category: any;
  mgs: any = '';
  filterNodata: any
  checkValues: any = []
  checkedListAcc: any = [];
  jsonArrayValue: any
  filterValue: any
  arryComm: any = [];
  zipCodeMsg: any = '';
  mobNumberPattern = "^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$";
  isValidFormSubmitted = false;
  buttonDisable: boolean = false;
  requiredError: boolean = false;
  maxDate = moment(new Date());
  dateMgs: any;
  minDate: any;
  dateMgsAnniversary: any;

  @Output() public isInvalid = new EventEmitter();
  @Output() public HouseAccData_child = new EventEmitter();

  errorValue: any;
  errorValue1: any;
  errorValue2: any;
  errorValue3: any;
  errorValue4: any;
  errorValue5: any;
  errorValue6: any;
  errorValue7: any;
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }


  @Output() public EmitHosueAccount = new EventEmitter();
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  constructor(private formBuilder: FormBuilder, private helper: HelperService, private dialogser: DialogService,
    private toaster: ToasterService, private objPayment: PaymentService, private objmodifier: ModifierService, private admin: AdminService) { }

  ngOnInit(): void {
    this.dialogser.ClearSession();
    this.buttonDisable = false;
    this.minDate = new Date(1900, 0, 1);
    this.HouseAccountForm = this.formBuilder.group({
      id: [0],
      name: [null, Validators.required],
      currentbalance: [null],
      creditlimits: [null, Validators.required],
      houseaccounts: [null],
      lastVisit: [null],
      contactFname: [null, Validators.required],
      contactLname: [null, Validators.required],
      businessname: [null],
      phone: [null],
      contactemail: [null, [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]{2,4}$')]],
      address: [null, Validators.required],
      city: [null, Validators.required],
      state: [null, Validators.required],
      zip: [null, Validators.required],
      dob: [null],
      anniversary: [null],
      notes: [null],
      communication: [null],
      Category: [null]
    })
    this.onSelectState();
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getCategory();
  }
  get GeneralData() { return this.HouseAccountForm.controls; }

  //@Input() public buttonState;
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set MenuRowItem(tableRowData) {
    this.errorValue = "";
    this.errorValue1 = "";;
    this.errorValue2 = "";
    this.errorValue3 = "";
    this.errorValue4 = "";
    this.errorValue5 = "";
    this.errorValue6 = "";
    this.errorValue7 = "";
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      this.HouseAccountForm.patchValue(tableRowData.General[0]);
      this.HouseAccountForm.patchValue({ anniversary: tableRowData.General[0].anniversary == null ? '' : moment(tableRowData.General[0].anniversary).format('YYYY-MM-DD') });
      this.HouseAccountForm.patchValue({ dob: tableRowData.General[0].dob == null ? '' : moment(tableRowData.General[0].dob).format('YYYY-MM-DD') });

      // this.HouseAccountForm.patchValue(tableRowData.Communication);
      this.arryComm = tableRowData.Communication
      // var days = this.arryComm.split(',');
      const map = new Map();
      this.jsonArrayValue = []
      for (const item of this.arryComm) {
        if (!map.has(item.typeId)) {
          map.set(item.typeId, true);    // set any value to Map
          this.jsonArrayValue.push({
            typeId: item.typeId,
            typeName: item.typeName,
            isSelected: item.isSelected,
          });
        }
      }
      this.filteredList2 = this.jsonArrayValue;
    }
  }

  onKeypressEvent(elem): boolean {
    const charCode = (elem.which) ? elem.which : elem.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (elem.target.value.length < 3 || (elem.target.value.length > 3 || elem.target.value.length < 11)) {
      if (elem.key === "-") elem.preventDefault();
    }
    // this.GeneralCompanyForm.get("zipCode").patchValue(elem.target.value.toUpperCase());
    if (elem.target.value.length <= 10) {
      if (elem.target.value.length === 5) {
        elem.target.value = `${elem.target.value}-`;
      }
      this.zipCodeMsg = ''
    } else {
      elem.target.value = elem.target.value.slice(0, -1);
      this.zipCodeMsg = ''
    }
    return true;
  };

  onSaveGeneral() {
    this.errorValue = "";
    this.errorValue1 = "";
    this.errorValue2 = "";
    this.errorValue3 = "";
    this.errorValue4 = "";
    this.errorValue5 = "";
    this.errorValue6 = "";
    this.errorValue7 = "";

    // this.HouseAccountForm.value.dob = this.GeneralData.dob.value == null ? '' : moment(new Date(this.GeneralData.dob.value)).format('YYYY-MM-DD');
    // this.HouseAccountForm.value.anniversary = this.GeneralData.anniversary.value == null ? '' : moment(new Date(this.GeneralData.anniversary.value)).format('YYYY-MM-DD');
    // console.log( this.HouseAccountForm.value.anniversary,"anniSave")

    this.submittedGeneral = true;
    if (this.HouseAccountForm.invalid) {
      this.isInvalid.emit(true);
      if (this.HouseAccountForm.controls.dob.invalid) {
        this.dateMgs = 'Invalid Date';
        return;
      }
      else if (this.HouseAccountForm.controls.anniversary.invalid) {
        this.dateMgsAnniversary = 'Invalid Date';
        return;
      }
      else {
        this.dateMgs = ''
        this.dateMgsAnniversary = ''
      }
      return;
    } else {
      if (this.GeneralData.phone.value != undefined && this.GeneralData.phone.value != null && this.GeneralData.phone.value != "") {
        if (this.GeneralData.phone.value.length <= 12) {
          this.mgs = 'Phone number must be at least 10 digit';
          this.isInvalid.emit(true);
          return
        } else {
          this.mgs = ''
        }
      } else {
        this.mgs = ''

      }
      if (this.HouseAccountForm.controls.zip.value != undefined && this.HouseAccountForm.controls.zip.value != null && this.HouseAccountForm.controls.zip.value != "") {
        if (this.HouseAccountForm.controls.zip.value.length === 10) {
          if (this.HouseAccountForm.controls.zip.value.slice(5, 6) != '-') {
            this.zipCodeMsg = 'Invalid Zip Code';
            return;
          }
        }
        if (this.HouseAccountForm.controls.zip.value.length <= 4) {
          this.zipCodeMsg = 'Please enter min 5 digits zipcode';
          this.isInvalid.emit(true);
          return
        } else {
          this.zipCodeMsg = ''
        }
      } else {
        this.zipCodeMsg = ''
      }
    }


    if (this.HouseAccountForm.value.anniversary == "" || this.HouseAccountForm.value.anniversary == null) {
      this.HouseAccountForm.value.anniversary = null
    }
    else {
      this.HouseAccountForm.value.anniversary = moment(new Date(this.HouseAccountForm.value.anniversary)).format('YYYY-MM-DD');
    }

    if (this.HouseAccountForm.value.dob == "" || this.HouseAccountForm.value.dob == null) {
      this.HouseAccountForm.value.dob = null
    }
    else {
      this.HouseAccountForm.value.dob = moment(new Date(this.HouseAccountForm.value.dob)).format('YYYY-MM-DD');
    }

    this.HouseAccountForm.value.createdBy = this.loginInfo.userId;
    this.HouseAccountForm.value.tenantId = this.loginInfo.tenantId;
    this.HouseAccountForm.value.communication = JSON.stringify(this.checkValues);

    const objphone = this.HouseAccountForm.value.phone;
    if (objphone != undefined && objphone != "" && objphone != null) {
      let trimphone = objphone.toString().match(/\d/g);
      //const isAllZero1 = trimphone.some(item => item !== "0");
      const isAllZero = trimphone.every((val, i, arr) => val === "0");
      if (parseInt(trimphone.length) != 10) {
        this.toaster.errorPopUp('Phone number must be at least 10 digit');
        return;
      }
      else if (isAllZero == true) {
        this.toaster.errorPopUp('Invalid Mobile Number');
        return;
      }
      else {
        this.HouseAccountForm.value.phone = objphone.replace(/[^-()\d ]/g, '');
      }
    }
    this.onSaveCompleteForm(this.HouseAccountForm.value);
  }

  onSaveCompleteForm(HouseAccount): void {
    this.objPayment.SaveHouseAccountGeneral(HouseAccount).subscribe(
      async (response) => {
        this.HouseAccountForm.patchValue
          ({
            id: response.id,
            currentbalance: Number(HouseAccount.currentbalance).toFixed(2),
            creditlimits: Number(HouseAccount.creditlimits).toFixed(2)
          });
        this.helper.setValue('Sessionid_help', response.id);
        this.helper.setValue('GetSessionData1', HouseAccount);
        this.HouseAccData_child.emit({ data: HouseAccount, event: "HouseAccounts", val: false });
        this.EmitHosueAccount.emit({ res: response, name: HouseAccount.name });
        this.submittedGeneral = false;
      })
  }

  getCategory() {
    this.errorValue = "";
    this.errorValue1 = "";
    this.errorValue2 = "";
    this.errorValue3 = "";
    this.errorValue4 = "";
    this.errorValue5 = "";
    this.errorValue6 = "";
    this.errorValue7 = "";
    const menuItemList: GetSaleAreaGeneralDD = {
      parentCode: "AC1",
    }
    this.objmodifier.ModifierGroupGetCategory(menuItemList)
      .subscribe((response) => {
        this.filteredList2 = response;
      })
  }

  onSelectState() {
    const ObjState: ISelectState = {
      id: 0
    }
    this.admin.SelectStateMaster(ObjState).subscribe((response) => {
      this.filteredList = response
      this.variables = this.filteredList.slice()
    })
  }

  onKey(value) {
    this.filteredList = this.search(value);
  }

  search(value: string) {
    let filteredList2 = this.variables.filter((unit) =>
      unit.id.indexOf(value.toUpperCase()) > -1);
    if (value.length == 1) {
      let filterdata = filteredList2.filter((x) =>
        x.id.substring(0, 1) == value.toUpperCase());
      return [...filterdata];
    } else if (value.length == 2) {
      let filterSecdata = filteredList2.filter((y) =>
        y.id.substring(0, 2) == value.toUpperCase());
      return [...filterSecdata];
    } else {
      this.filterNodata = true
      return [...filteredList2];
    }
  }

  numberOnly(event): boolean {
    // const charCode = (event.which) ? event.which : event.keyCode;
    // if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
    //   return false;
    // }
    // return true;

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberOnly2(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (event.target.value.length <= 14) {

      if (event.target.value.length === 3) {
        event.target.value = `(${event.target.value}) `;
      }
      if (event.target.value.length === 9) {
        event.target.value = `${event.target.value}-`;
      }
      if (event.target.value.length === 11) {
        event.target.value = `${event.target.value}`;
      }
      if (event.target.value.length >= 13) {
        this.isInvalid.emit(false);
        this.mgs = ''
      }
      // if (event.target.value.length <= 12) {
      //   this.mgs = 'Phone number must be at least 10 numbers'
      //   return true
      // } else if(event.target.value.length == 0) {
      //   this.mgs = ''
      // }
      // else  {
      //   this.mgs = ''
      // }

    } else {
      event.target.value = event.target.value.slice(0, -1)
    }
    return true;
  }

  phoneNumberCheck(event) {
    if (event.target.value.length == 0) {
      this.mgs = '';
    }
    if (event.target.value.slice(0, 1) != '(') {
      if (event.target.value.length > 10) {
        this.mgs = 'Invalid mobile number';
        return;
      }
      else if (event.target.value.length < 3 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)})`;
        if (event.target.value.length < 15) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = ''
      }
      else if (event.target.value.length < 6 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}-`;
        if (event.target.value.length < 15) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = ''
      }
      else if (event.target.value.length >= 6 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}-${event.target.value.slice(6, event.target.value.length)}`;
        if (event.target.value.length < 14) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = ''
      }
    }
    else {
      if (event.target.value.length < 14) {
        this.mgs = 'Invalid mobile number';
        return;
      }
      this.mgs = '';
    }
  }

  onDeletePhone(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.mgs = ''
      }
    }
  }

  onCheckboxChange(option, event) {
    if (event.target.checked) {

      this.checkedListAcc.push(option.typeId);
      this.checkValues = [...new Set(this.checkedListAcc)];
      //  this.checkValues = Array.from(this.checkedListAcc.reduce((m, t) => m.set(t.typeId, t), new Map()).values());
      //  this.checkedListAcc.push(option.typeId);

    } else {
      for (var i = 0; i < this.filteredList2.length; i++) {
        if (this.checkedListAcc[i] == option.typeId) {
          this.checkedListAcc.splice(i, 1);
          this.checkValues = [...new Set(this.checkedListAcc)];
        }
      }
    }
  }

  getMaxValueName(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  getMaxValueAccounts(e) {
    if (e.target.value.length == 50) {
      this.errorValue1 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue1 = ""
    }
  }

  getMaxValueBName(e) {
    if (e.target.value.length == 50) {
      this.errorValue2 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue2 = ""
    }
  }

  getMaxValueEmail(e) {
    if (e.target.value.length == 100) {
      this.errorValue3 = "Max-Limit 100 characters reached";
    }
    else {
      this.errorValue3 = ""
    }
  }

  getMaxValueFName(e) {
    if (e.target.value.length == 50) {
      this.errorValue4 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue4 = ""
    }
  }

  getMaxValueLName(e) {
    if (e.target.value.length == 50) {
      this.errorValue5 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue5 = ""
    }
  }

  getMaxValueCity(e) {
    if (e.target.value.length == 50) {
      this.errorValue6 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue6 = ""
    }
  }

  getMaxValueState(e) {
    if (e.target.value.length == 50) {
      this.errorValue7 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue7 = ""
    }
  }

  HouseAccountsValid() {
    {
      if (this.HouseAccountForm.invalid) {
        this.isInvalid.emit(true);
        this.requiredError = true;
        return;
      }
      else if (this.GeneralData.phone.value != undefined && this.GeneralData.phone.value != null && this.GeneralData.phone.value != "") {
        if (this.GeneralData.phone.value.length <= 12) {
          this.mgs = 'Phone number must be at least 10 digit';
          this.requiredError = true;
          this.isInvalid.emit(true);
          return
        }
      }
      else if (this.HouseAccountForm.controls.zip.value != undefined && this.HouseAccountForm.controls.zip.value != null && this.HouseAccountForm.controls.zip.value != "") {
        if (this.HouseAccountForm.controls.zip.value.length <= 4) {
          this.zipCodeMsg = 'Please enter min 5 digits zipcode';
          this.requiredError = true;
          this.isInvalid.emit(true);
          return
        }
      }
      this.requiredError = false;
    }
  }
  Highlight() {
    this.onchange(this.HouseAccountForm.value);

    if (!this.HouseAccountForm.invalid) {
      this.isInvalid.emit(false);
      this.dateMgs = '';
      this.dateMgsAnniversary = '';
    }
    else {
      if (this.HouseAccountForm.controls.dob.invalid) {
        this.isInvalid.emit(true);
        this.dateMgs = 'Invalid Date';
        if (!this.HouseAccountForm.controls.anniversary.invalid) {
          this.dateMgsAnniversary = '';
        }
        if (this.HouseAccountForm.controls.anniversary.invalid) {
          this.dateMgsAnniversary = 'Invalid Date';
        }
        return;
      }
      if (this.HouseAccountForm.controls.anniversary.invalid) {
        this.isInvalid.emit(true);
        this.dateMgsAnniversary = 'Invalid Date';
        if (!this.HouseAccountForm.controls.dob.invalid) {
          this.dateMgs = '';
        }
        if (this.HouseAccountForm.controls.dob.invalid) {
          this.dateMgs = 'Invalid Date';
        }
        return;
      }
      this.dateMgs = '';
      this.dateMgsAnniversary = '';
    }
  }

  ngAfterViewInit() {
    this.HouseAccountForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.HouseAccData_child.emit(this.HouseAccountForm.value)
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.HouseAccData_child.emit({ data: data, event: "HouseAccounts", val: true });
  }
  onDeleteZipCode(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.zipCodeMsg = ''
      }
    }
  }

  zipCodeCheck(event) {
    if (event.target.value.length === 6) {
      if (event.target.value.slice(5, 6) == '-') {
        event.target.value = `${event.target.value.slice(0, 5)}`;
      }
    }
    else if (event.target.value.length > 5 && event.target.value.length < 10) {
      if (event.target.value.slice(5, 6) != '-') {
        event.target.value = `${event.target.value.slice(0, 5)}-${event.target.value.slice(6, event.target.value.length)}`;
        this.zipCodeMsg = "";
      }
    }
    else if (event.target.value.length === 9) {
      event.target.value = `${event.target.value.slice(0, 5)}-${event.target.value.slice(6, 9)}`;
    }

    else if (event.target.value.length === 10) {
      if (event.target.value.slice(5, 6) != '-') {
        this.zipCodeMsg = "Invalid Zip Code";
      }
      else {
        this.zipCodeMsg = "";
      }
    }
  }
}
