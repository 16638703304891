<div class="row">
  <div class="col-sm-4 time-index">
    <div *ngIf="tableEnable">
      <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns"
        (hierarchy)="changeTableRowColor($event)" (hierarchyActive)="checkValue($event)" [storeItem]="storeRow"
        (clearDetails)="addStore()" (tableEmpName)="getTableName($event)" [DataCom]="employee"
        [DataStoreId]="storeIdItem" [EmployeeTableData]="EmployeeTableData"
        (hierarchyData)="gethierarchyData($event)"></app-fliptable>
    </div>
  </div>
  <div class="col-sm-8">
    <div id="accordion">
      <div class="card">
        <div class="card-header outshadow" id="heading-1">
          <h5 class="mb-0">
            <a role="button" data-toggle="collapse" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
              Dashboard
            </a>
          </h5>
        </div>
        <div id="collapse-1" class="collapse" data-parent="#accordion" aria-labelledby="heading-1">
          <div class="card-body" style="padding: 0!important;">
            <img src="assets/components/images/dashboard-graph.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header outshadow" id="heading-2">
          <h5 class="mb-0">
            <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-2" aria-expanded="true"
              aria-controls="collapse-2">
              General
            </a>
          </h5>
        </div>
        <div id="collapse-2" class="collapse show" data-parent="#accordion" aria-labelledby="heading-2">

          <div class="card-body">

            <div class="Gen_wrap">

              <form class="loginForm GeneralForm" [formGroup]="GeneralStoreForm">
                <div class="pull-right-active">
                  <span>Active</span>
                  <div class="checkbox-table accordion-checkbox" id="checkbox-box">
                    <div class="custom-control custom-checkbox custom-control-inline">
                      <input type="checkbox" class="custom-control-input" name="customCheckbox" id="2"
                        formControlName="isActive" checked="isActive">
                      <label class="custom-control-label" for="2"></label>
                    </div>
                  </div>
                </div>
                <br>
                <div class="row w-100">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Store Name</label> <label class="text-danger">*</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="tenantName"
                        placeholder="Store Name"
                        [ngClass]="{ 'is-invalid': submittedGeneral && GenStoreData.tenantName.errors }" />
                      <div *ngIf="submittedGeneral && GenStoreData.tenantName.errors" class="invalid-feedback">
                        <div *ngIf="GenStoreData.tenantName.errors.required">Store Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Phone</label>
                      <ngx-intl-tel-input [cssClass]="'form-control country-tel-code'" [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true" [preferredCountries]="preferredCountries" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10"
                        [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                        [separateDialCode]="separateDialCode" formControlName="contactPhone"
                        >
                      </ngx-intl-tel-input>
                      <!-- [ngClass]="{ 'is-invalid': submittedGeneral && GenStoreData.contactPhone.errors }"/'' -->
                      <!-- <div *ngIf="submittedGeneral && GenStoreData.contactPhone.errors" class="invalid-feedback">
                        <div *ngIf="GeneralStoreForm.controls['contactPhone'].errors">Phone number must be at least 10
                          numbers</div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Contact first Name</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="contactFirstName"
                        placeholder="Contact First Name" />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Contact Last Name</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="contactLastName"
                        placeholder="Contact Last Name" />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Contact Email</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="contactEmail"
                        placeholder="&#x2709; Example@myemailaddress.com" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">Address</label>
                      <input type="text" class="form-control" formControlName="address" placeholder="Address" />
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">City</label>
                      <input type="text" class="form-control" formControlName="city" placeholder="City" />
                    </div>
                  </div>
                  <div class="col-sm-2 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">State</label>
                      <input type="text" class="form-control" formControlName="state" placeholder="MT" />
                    </div>
                  </div>
                  <div class="col-sm-2 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">Zip Code</label>
                      <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code"
                        (keypress)="numberOnly($event)" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">Time Zone</label>
                      <input type="text" class="form-control" formControlName="timeZone" placeholder="Time Zone" />
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">Live Date</label>
                      <input type="text" placeholder="Select Date" class="form-control" formControlName="liveDate"
                        bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false }">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">SMS Messaging?</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="smsMessaging" id="smsMessaging"
                          formControlName="smsMessaging">
                        <label class="custom-control-label" for="smsMessaging"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Offline trasactions?</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="offlineTransaction"
                          id="offlineTransaction" formControlName="offlineTransaction">
                        <label class="custom-control-label" for="offlineTransaction"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Swipe Cards?</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="swipeCards" id="swipeCards"
                          formControlName="swipeCards">
                        <label class="custom-control-label" for="swipeCards"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Time Punch Prints?</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="timePunchPrints" id="timePunchPrints"
                          formControlName="timePunchPrints">
                        <label class="custom-control-label" for="timePunchPrints"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Allow Future Orders?</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="allowFutureOrders"
                          id="allowFutureOrders" formControlName="allowFutureOrders">
                        <label class="custom-control-label" for="allowFutureOrders"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Signature Capture</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="signatureCapture"
                          id="signatureCapture" formControlName="signatureCapture">
                        <label class="custom-control-label" for="signatureCapture"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Email Receipts</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="emailedReceipts" id="emailedReceipts"
                          formControlName="emailedReceipts">
                        <label class="custom-control-label" for="emailedReceipts"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Delight Savings</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="daylightSavings" id="daylightSavings"
                          formControlName="daylightSavings">
                        <label class="custom-control-label" for="daylightSavings"></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <label class="customlable mb-0">Round Tax</label>
                    <div class="checkbox-table accordion-checkbox">
                      <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="roundTax" id="roundTax"
                          formControlName="roundTax">
                        <label class="custom-control-label" for="roundTax"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button class="btn  btn-sm float-right submit_button" type="submit" (click)="onSaveGeneral();">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>