import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { TabletGeneralComponent } from '../tablet-general/tablet-general.component';
import { TabletLogisticComponent } from '../tablet-logistic/tablet-logistic.component';
import { GetDevice, DeleteDevice } from 'src/app/interfaces/idevices';
import { DeviceService } from 'src/app/services/devices.service';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-tablets',
  templateUrl: './tablets.component.html',
  styleUrls: ['./tablets.component.css']
})
export class TabletsComponent implements OnInit {
  @ViewChild(TabletGeneralComponent) gentab: TabletGeneralComponent;
  @ViewChild(TabletLogisticComponent) logtab: TabletLogisticComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;

  public menuGroupList: any = [];
  public getAreaID: any;
  public tableRowColor: any;
  public tableRowId: any;
  public displayedColumns = [];
  public getsaveid: any;
  public itemlayoutbuilder: any;
  public getDataValue: any;
  public TabletData: any;
  isInvalid: boolean = false;
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public tabletdata = new EventEmitter();
  @Output() public tabletsessData = new EventEmitter();

  public TabletName: any;
  public Tablet: any;
  public TabletIcon: any;
  loginInfo: any;
  public removetablet: any;
  viewScreenValue: boolean = false
  public tablerow: any;
  indexExpanded: number = 0;
  private buttonDisable: boolean;
  public modifierItemsData: any;
  submittedGeneral: any;
  //@Input() public buttonDisable;

  constructor(private helper: HelperService, private changeDetector: ChangeDetectorRef, private toaster: ToasterService,
    private objDevice: DeviceService, private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.tablet");

    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.TabletName = "Devices";
    this.Tablet = "Devices-Tablets";
    this.TabletIcon = "assets/components/images/tablet.png";
    this.titleName.emit(this.TabletName);
    this.title.emit(this.Tablet);
    this.titleIcon.emit(this.TabletIcon);
    this.getTabletList();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.ClearData();
    }
  }

  expandPanel(index, e?) {
    this.indexExpanded = index;
  }
  // expandPanel(index) {
  //   this.indexExpanded = index == this.indexExpanded ? 0 : index;
  // }
  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }
  }
  onChangeAccordion(e, index) {

    this.indexExpanded = index;
  }

  async ClearData() {
    //if (this.gentab.isFormPageDirty() == false) {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
    }
    else {
      var conf = await this.dialogser.confirm("tablet");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
      }
      else {
        this.gentab.tabletGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.gentab.errorValue = "";
    this.gentab.errorValue1 = "";
    this.gentab.errorValue2 = "";
    this.isInvalid = false;
    this.gentab.requiredErrors = false;
    this.gentab.tabletGeneralForm.reset();
    this.gentab.ngOnInit();
    this.TabletName = "Devices";
    this.tableRowColor = 'transparent';
    this.gentab.submittedGeneral = false;
    this.tableRowId = 0;
    this.removetablet = false;
    this.titleName.emit(this.TabletName);
  }

  getTabletList() {
    this.tableRowColor = this.helper.getValue('Sessionid_help');
    const deviceList: GetDevice = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.objDevice.TabletDeviceGet(deviceList)
      .subscribe(res => {
        this.dataSource.data = res;
        this.TabletData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true);
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Tablet Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  getGeneralDetail(event) {
    this.getsaveid = event.res.id;
    this.getTabletList();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name);
    this.tabletdata.emit({ name: event.name })
  }

  checkvaluePage(event) {
    let currentTarget = event.e.target.checked //event.target.checked
    const active: DeleteDevice = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.target.checked
    }
    this.objDevice.TabletDeviceDelete(active).subscribe(
      (response) => {
        this.ClearData();
        this.getTabletList();
        this.toaster.successPopUp(response.message);
        // if (currentTarget) {
        //   this.toaster.success(response.message);
        // } else {
        //   this.toaster.success(response.message);
        // }
      },
    )
  }

  async getTableIdx(event) {
    //this.gentab.isFormPageDirty();
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("tablet");
      if (conf == 'success') { // Navigate Away
        if (event == this.dialogser.ModifiedId) {
          this.gentab.GetTabletDetails(event);
        }
        this.dialogser.ClearSession();
        this.gentab.tabletGeneralForm.reset();

        //General Data
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.getTabletList();
        this.removetablet = true
        this.gentab.requiredErrors = false;
        this.isInvalid = false;
        this.flipcomp.rowClicked = event;
        this.dialogser.ModifiedId = event;
      }
      else // Cancel
      {
        //General Data
        this.gentab.tabletGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.tabletsessData.emit({
          data: this.gentab.tabletGeneralForm.value, event: "tablet", val: true,
          itmname: this.gentab.tabletGeneralForm.value.name
        });
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event);
      this.tableRowId = event;
      this.tableRowColor = event;
      this.getTabletList();
      this.removetablet = true;
      this.gentab.requiredErrors = false;
      this.isInvalid = false;
    }
  }

  getTableName(name) {
    this.TabletName = name;
    this.TabletIcon = "assets/components/images/path.png";
    this.titleName.emit(this.TabletName)
    this.title.emit(this.Tablet)
    this.titleIcon.emit(this.TabletIcon)
  }

  gethierarchyData(e) {
    this.tablerow = e;
    this.tabletdata.emit(e)
  }

  getLogisticScreen(e, index) {
    this.viewScreenValue = e
    if (this.viewScreenValue == true) {
      // this.indexExpanded = 0
      this.indexExpanded = index == this.indexExpanded ? 0 : index;
    }
  }



  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  OnSave() {
    this.gentab.onSaveGeneral();
    if (this.gentab.tabletGeneralForm.invalid) {
      if ((this.gentab.tabletGeneralForm.value.name == null) || (this.gentab.tabletGeneralForm.value.name == "")) {
        this.isInvalid = true;
      }
      else {
        this.isInvalid = false;
      }
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  gettabletsessData(e) {
    this.tabletsessData.emit(e)
  }
}
