import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-credit-card-firstdata',
  templateUrl: './credit-card-firstdata.component.html',
  styleUrls: ['./credit-card-firstdata.component.css']
})

export class CreditCardFirstdataComponent implements OnInit {
  FirstDataForm: FormGroup;
  id: any = 0;
  GatewaySelection: any;
  loginInfo: any;
  submittedGeneral = false;
  isSubmitted : boolean = false;
  isValidated = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  AreaStyle_retail: any;
  AreaStyle_restaurant: any;
  autoBatchTime: any;
  errorValue: any
  errorValue1: any
  errorValue2: any
  errorValue3: any

  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  @Output() public generalCredit = new EventEmitter();
  @Input() public buttonState;
  constructor(private formBuilder: FormBuilder, private objPayment: PaymentService,
    private helper: HelperService, private toaster: ToasterService) { }

  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.FirstDataForm = this.formBuilder.group({
      id: [0],
      merchantId: [null],
      totalPassword: [null],
      terminalId: [null],
      tokenIdType: [null],
      groupId: [null],
      catCode_SIC: [null],
      autoAcquire_DID: [false],
      of_Fixed: [false],
      total_Lanes: [null],
      storeValue: [0],
      autoBatchTime: [null, Validators.required],
      mobile: [false],
      isStoreandForward_EMVonly: [false],
      of_Fixed_Details: [null]
    })
  }
  get GeneralData() { return this.FirstDataForm.controls; }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getAuthCodeValue(e) {
    if (e.target.value.length < 6) {
      if (e.target.value.length == 0) {
        this.errorValue = ""
      } else {
        this.errorValue = "6 digits are required"
        return;
      }

    } else {
      this.errorValue = ""
    }
  }
  getAuthCodeValue1(e) {
    if (e.target.value.length < 6) {
      if (e.target.value.length == 0) {
        this.errorValue1 = ""
      } else {
        this.errorValue1 = "6 digits are required"
        return;
      }

    } else {
      this.errorValue1 = ""
    }
  }
  getAuthCodeValue2(e) {
    if (e.target.value.length < 8) {
      if (e.target.value.length == 0) {
        this.errorValue2 = ""
      } else {
        this.errorValue2 = "8 digits are required"
        return;
      }

    } else {
      this.errorValue2 = ""
    }
  }
  getAuthCodeValue3(e) {
    if (e.target.value.length < 13) {
      if (e.target.value.length == 0) {
        this.errorValue3 = ""
      } else if (e.target.value.length < 5) {
        this.errorValue3 = "Minimum 5 digits are required"
        return;
      }
      else {
        this.errorValue3 = ""
        // this.errorValue3 = "Maximum 13 digits are required"
        // return;
      }
      // this.errorValue3 = ""
    } else {
      this.errorValue3 = ""
    }
  }

  onSaveGeneral() {
    this.submittedGeneral = true;
    if (this.FirstDataForm.invalid) {
      return;
    }
    if (this.GeneralData.totalPassword.value.length < 6) {
      if (this.GeneralData.totalPassword.value.length == 0) {
        this.errorValue = ""
      } else {
        this.errorValue = "6 digits are required"
        return;
      }
    }
    if (this.GeneralData.tokenIdType.value.length < 6) {
      if (this.GeneralData.tokenIdType.value.length == 0) {
        this.errorValue1 = ""
      } else {
        this.errorValue1 = "6 digits are required"
        return;
      }
    }
    if (this.GeneralData.terminalId.value.length < 8) {
      if (this.GeneralData.terminalId.value.length == 0) {
        this.errorValue2 = ""
      } else {
        this.errorValue2 = "8 digits are required"
        return;
      }
    }
    if (this.GeneralData.groupId.value.length < 5) {
      if (this.GeneralData.groupId.value.length == 0) {
        this.errorValue3 = ""
      } else {
        this.errorValue3 = "Minimum 5 digits are required"
      return;
      }
    }
    let paymentserv;
    paymentserv = this.FirstDataForm.value;
    paymentserv.userID = this.loginInfo.userId;
    paymentserv.tenantId = this.loginInfo.tenantId;
    paymentserv.isActive = true;
    paymentserv.creditCardGatewayId = sessionStorage.getItem("CreditCardID");
    paymentserv.id = this.id;

    if (paymentserv.storeValue == "") {
      paymentserv.storeValue = 0;
    }
    this.objPayment.SaveCreditCardPayment(paymentserv).subscribe(res => {
      this.isSubmitted = false;
      this.submittedGeneral = false;
      this.id = res.id;
      this.generalCredit.emit(res);
      this.toaster.successPopUp(res.message);
    })
  }
  
  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.addEmployee()
    }
  }
  public addEmployee(): void {
    this.FirstDataForm.reset(); // should reset the form in child component

  }

  //  @Input()
  //  public set MenuRowItem(tableRowData) {
  //    if (tableRowData[0] != undefined && tableRowData[0] != null && tableRowData[0] != "") {
  //      this.FirstDataForm.patchValue(tableRowData[0]);
  //      this.id = tableRowData[0].id;

  //     if (tableRowData[0].of_Fixed_Details == 'retail') {
  //       this.AreaStyle_retail = true;
  //     }
  //     else if (tableRowData[0].of_Fixed_Details == 'restaurant') {
  //       this.AreaStyle_restaurant = true;
  //     }
  //   }
  // }
}
