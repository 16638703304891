import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentRoutingModule } from './payment-routing.module';
import { CustomPaymentComponent } from './custom-payment/custom-payment.component';
import { HouseAccountsComponent } from './house-accounts/house-accounts.component';
import { CustomUberEatsComponent } from './custom-uber-eats/custom-uber-eats.component';
import { HouseAccountsGeneralComponent } from './house-accounts-general/house-accounts-general.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { EmployeeModule } from '../employee/employee.module';
import { HouseAccountsLogiticsComponent } from './house-accounts-logistics/house-accounts-logitics.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { GiftCardComponent } from './gift-card/gift-card.component';
import { GiftCardGeneralComponent } from './gift-card-general/gift-card-general.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { CreditCardGatewayComponent } from './credit-card-gateway/credit-card-gateway.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { CreditCardTsysComponent } from './credit-card-tsys/credit-card-tsys.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { SubmitParametersComponent } from './submit-parameters/submit-parameters.component';
import { CreditCardFirstdataComponent } from './credit-card-firstdata/credit-card-firstdata.component';
import { CreditCardVantivComponent } from './credit-card-vantiv/credit-card-vantiv.component';
import { CreditCardStep4Component } from './credit-card-step4/credit-card-step4.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatSelectFilterModule } from 'mat-select-filter';
import { NgxCurrencyModule } from 'ngx-currency';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';
import { GiftCardDesignerComponent } from './gift-card-designer/gift-card-designer.component';
import { GiftCardFrontComponent } from './gift-card-front/gift-card-front.component';
import { AppGiftCardBackComponent } from './app-gift-card-back/app-gift-card-back.component';
import {MatTabsModule} from '@angular/material/tabs';
import { InputMaskModule } from '@ngneat/input-mask';

//import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    CustomPaymentComponent,
    HouseAccountsComponent,
    CustomUberEatsComponent,
    HouseAccountsGeneralComponent,
    HouseAccountsLogiticsComponent,
    GiftCardComponent,
    GiftCardGeneralComponent,
    CreditCardComponent,
    CreditCardGatewayComponent,
    CreditCardTsysComponent,
    SubmitParametersComponent,
    CreditCardFirstdataComponent,
    CreditCardVantivComponent,
    CreditCardStep4Component,
    GiftCardDesignerComponent,
    GiftCardFrontComponent,
    AppGiftCardBackComponent,
  ],
  imports: [
    CommonModule,
    PaymentRoutingModule,
    MatExpansionModule,
    EmployeeModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatSelectModule,
    NgxMatTimepickerModule,
    NgxIntlTelInputModule,
    MatSelectFilterModule,
    NgxCurrencyModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    MatInputModule,
    MatTabsModule,
    InputMaskModule

    // NgxSpinnerModule,
  ],

  exports: [
    CustomPaymentComponent,
    HouseAccountsComponent,
    CustomUberEatsComponent,
    HouseAccountsGeneralComponent,
    HouseAccountsLogiticsComponent,
    GiftCardComponent,
    CreditCardComponent,
  ],
})
export class PaymentModule {}
