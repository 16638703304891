<form class="loginForm" [formGroup]="TypeofBusinessForm" (change)="onchange(TypeofBusinessForm.value)">
    <div class="row">
        <div class="col-sm-4">
            <div class="form-group">
                <label>Type of Business</label><label class="text-danger">*</label>
                <input type="text" class="form-control input-lg inputbox_login" formControlName="name" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.name.errors) || (requiredError &&GeneralData.name.errors)}" maxlength="50" (input)="getMaxValue($event)"
                    (keypress)="Highlight()" (focusout)="BusinessValid()" />
                <div class="error">{{errorValue}}</div>
                <div *ngIf="(submittedGeneral && GeneralData.name.errors) || (requiredError &&GeneralData.name.errors)" class="invalid-feedback">
                    <div *ngIf="GeneralData.name.errors.required">Type of Business is required</div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group">
                <label>Company Name</label>
                <input type="text" class="form-control input-lg inputbox_login" formControlName="company" appInputRestriction readonly>
                <!-- <mat-form-field appearance="fill">
                    <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="company">
                        <mat-option value=""  selected>Company 1</mat-option>
                        <mat-option value="1">Company 2</mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
        </div>
    </div>
    <div class="form-group mt-2">
        <button class="btn btn-sm float-right submit_button button-none" [disabled]="buttonDisable ? true : null" type="submit" (click)="onSaveGeneral();">
            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label>
        </button>
    </div>
</form>
