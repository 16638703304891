import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthorizationCheckGuard } from './services/authorization-check.guard';
import { ThemeComponent } from './theme/theme.component';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: '/login'},
  // { path: 'login', component: LoginComponent},
  { path: '', component: LoginComponent, pathMatch: 'full' },
  {
    path: 'theme', canActivate: [AuthorizationCheckGuard], component: ThemeComponent,
    // children: [
    //   { path: 'employee', loadChildren: () => import(`./views/employee/employee.module`).then(m => m.EmployeeModule)},
    //   { path: 'hierachy', loadChildren: () => import(`./views/hierachy/hierachy.module`).then(m => m.HierachyModule) },
    //   { path: 'device', loadChildren: () => import(`./views/Devices/devices.module`).then(m => m.DevicesModule) },
    // ]
  },
];

@NgModule({
  imports: [BrowserModule, FormsModule, RouterModule.forRoot(routes, { useHash: true })],
  //  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  exports: [RouterModule]
})
export class AppRoutingModule { }
