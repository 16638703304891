import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import { BaseService } from '../base/base.service';
import { Router } from '@angular/router';
import {
  icustompaymentsave,
  iGiftCardSave,
  iGiftCardSelect,
  ActivatePayment,
  iCreditCardPaymentSave,
  iCreditCardGateWaySave,
  iHouseAccountGeneral,
  iSelectHouseAccountGeneral,
  iHouseAccountLogistic,
  iSelectCreditCard,
  iDeleteHouseAccount,
  iUpdateCreditCardAPIKey,
  iSaveCreditcardGateway,
} from '../interfaces/icustompayment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    public http: HttpClient,
    private Helper: HelperService,
    private base: BaseService,
    private router: Router
  ) {}

  //--------------------------CUSTOM PAYMENT---------

  SaveCustomPayment(Custom: icustompaymentsave) {
    return this.base.post('Payment/SaveCustomPayment', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  SelectCustomPayment(Custom: iGiftCardSelect) {
    return this.base.post('Payment/SelectCustomPayment', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  ActivateCustomPayment(Custom: ActivatePayment) {
    return this.base.post('Payment/DeleteCustomPayment', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  //--------------------------GIFTCARD---------

  SaveGiftCard(Custom: iGiftCardSave) {
    return this.base.post('Payment/SaveGiftCard', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  SelectGiftCard(Custom: iGiftCardSelect) {
    return this.base.post('Payment/SelectGiftCard', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  EditGiftCard(Custom: iGiftCardSelect) {
    return this.base.post('Payment/EditGiftCard', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  ActivateGiftCard(Custom: ActivatePayment) {
    return this.base.post('Payment/DeleteGiftCard', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  //--------------------------CREDIT CARD---------

  SaveCreditCardGateway(Custom: iSaveCreditcardGateway) {
    return this.base.post('Payment/SaveCreditCardGateway', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  SaveCreditCardPayment(Custom: iCreditCardPaymentSave) {
    return this.base.post('Payment/SaveCreditCardPayment', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  UpdateCreditCardPaymentAPIKey(Custom: iUpdateCreditCardAPIKey) {
    return this.base.post('Payment/UpdateCreditCardPaymentAPIKey', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  SelectCreditCard(Custom: iSelectCreditCard) {
    return this.base.post('Payment/SelectCreditCardPayment', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  EditCreditCard(Custom: iSelectCreditCard) {
    return this.base.post('Payment/EditCreditCardPayment', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  ActivateCreditCard(Custom: ActivatePayment) {
    return this.base.post('Payment/DeleteCreditCardPayment', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  // ---------------House Account-------------------------------
  SaveHouseAccountGeneral(Custom: iHouseAccountGeneral) {
    return this.base.post('Payment/SaveHouseAccountGeneral', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  SaveHouseAccountLogistic(Custom: iHouseAccountLogistic) {
    return this.base.post('Payment/SaveHouseAccountLogistics', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  SelectHouseAccountGeneral(Custom: iSelectHouseAccountGeneral) {
    return this.base.post('Payment/SelectHouseAccount', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  EditHouseAccountGeneral(Custom: iSelectHouseAccountGeneral) {
    return this.base.post('Payment/EditHouseAccount', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  DeleteHouseAccountGeneral(Custom: iDeleteHouseAccount) {
    return this.base.post('Payment/DeleteHouseAccount', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  //-----------------dataCap API -------------------------------

  DataCapGatewaySwipeTest(Custom: iSaveCreditcardGateway) {
    return this.base.post('Payment/DataCapGatewaySwipeTest', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  DataCapGatewayTrancloudTest(Custom: iSaveCreditcardGateway) {
    return this.base.post('Payment/DataCapGatewayTrancloudTest', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  DataCapGiftCardTest(Custom: iSaveCreditcardGateway) {
    return this.base.post('Payment/DataCapGiftCardTest', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
