<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="addEmployee()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true || isInvalidWage==true}" class="btn  btn-sm float-right submit_button global_submit" type="submit" (click)="onSaveGeneral();">
        <!-- [disabled]="buttonStatus ?  true : null"  -->
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label></button>
        </div>
    </div>

</div>
<div class="row second-sec">
    <div class="col-sm-4">
        <div>
            <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" [dealerItem]="tableRowColor" (tableItemId)="getTableIdx($event)" (tableEmpName)="getTableName($event)" [EmployeeTableData]="EmployeeTableData" (hierarchyActive)="checkValue($event)"
                [employeeItem]="tableRowColor" (clearDetails)="addEmployee()" [DataCom]="employee" (toggleemit)="toggleIsActive($event)" (btnActive)="getbtnActive($event)"></app-fliptable>
        </div>
    </div>
    <div class="col-sm-8">
        <!-- <div class="form-group global-form">
    <button  [ngClass]="{'submit_button_error': isInvalid==true || isInvalidWage==true}" class="btn  btn-sm float-right submit_button global_submit" type="submit"
    [disabled]="buttonStatus ?  true : null" (click)="onSaveGeneral();">
      <img src="assets/components/images/save.png" alt="employee" class="">
      <label class="label_config">Save</label></button>
  </div> -->
        <!-- <div class="row">
      <div class="col-sm-6">
        <app-title-card [Dataname]="employeeName" [DataCom]="employee" [DataIcon]="employeeIcon"></app-title-card>
      </div>
    </div> -->
        <mat-accordion>
            <!-- <mat-expansion-panel *ngFor="let item of ['General','Wages & Jobs','Permissions']; first as isFirst; let i = index"
      [expanded]="indexExpanded == i" class="outshadow"> -->
            <mat-expansion-panel *ngFor="let item of ['General','Wages & Jobs','Permissions'];  let i = index" [expanded]="indexExpanded == i" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-expansion-panel-header (click)="expandPanel(item,index)" [ngClass]="{'box_error': (isInvalid==true && item=='General') || (isInvalidWage==true && item=='Wages & Jobs')}">
                    <mat-panel-title (click)="onChangeAccordion($event,index)" [ngClass]="{'title_error':(isInvalid==true && item=='General') || (isInvalidWage==true && item=='Wages & Jobs')}">
                        {{item}} </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General'">
                    <app-employee-general (generalItem)="getGeneralDetail($event)" (isInvalid)="isInvalidAccordion($event)" [DataIdx]="tableRowId" [checkTarget]="activeDeactiveValue" [buttonState]="buttonDisabless" [companyHierarchyHide]="compHierHide" [dealerHierarchyHide]="dealHierHide"
                        (StoreempData_child)="getstoreempData($event)" (matopen)="matstate($event)"> </app-employee-general>
                </div>
                <div *ngIf="item == 'Wages & Jobs'">
                    <app-employee-wages [DataIdx]="tableRowId" (wagesItem)="getWagesDetails($event)" (permissionItem)="getPermissionDetails($event)" (roleItem)="getRoleDetails($event)" [buttonState]="buttonDisabless" (getroleItem)="getRoleItemId($event)" (getPermissionItem)="getPermissonDrop($event)"
                        (validDateWagesRole)="ValidateWagesDetails($event)" (isInvalidWage)="isInvalidWageAccordion($event)" (StoreempwageData_child)="getstoreempData($event)" (matopen)="matstate($event)">
                    </app-employee-wages>
                </div>
                <div *ngIf="item == 'Permissions'">
                    <app-employee-permission [DataIdx]="tableRowId" [DataPermission]="permissionDataemit" [DataRole]="roleDataemit" [DataWages]="wagesDataemit" (permissionItemValue)="getPermissionDataEmit($event)" [buttonState]="buttonDisabless" [DataGetRole]="getRoleData"
                        [DataDropValue]="permissionDropItem" (StoreempperData_child)="getstoreempData($event)" (matopen)="matstate($event)">
                    </app-employee-permission>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="form-group">
            <button class="btn  btn-sm float-right submit_button global_submit button-none" type="submit" [disabled]="buttonStatus ?  true : null" (click)="onSaveGeneral();">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label></button>
        </div>
    </div>
</div>
