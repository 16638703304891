import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ISelectTagDetails, ITagFilter } from 'src/app/interfaces/i-tags';
import { TagsService } from 'src/app/services/tags.service';
import { MatTable } from '@angular/material/table';
import { ToasterService } from 'src/app/helper/toaster.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.css'],
})
export class TagFilterComponent implements OnInit {
  tagsFilterForm: FormGroup = new FormGroup({
    useForTabletFilter: new FormControl(false),
  })

  initializeForm() {
    this.tagsFilterForm.setValue({
      useForTabletFilter: false,
    })
  }

  submittedGeneral: any;
  buttonDisable: boolean = false;
  public finalipadjsondata: any;
  public FinalipadList = [];
  public table_render: boolean = true;
  public displayedColumns = [];
  public columns = [];
  public dataSource = new MatTableDataSource<any>();
  @Output() public tagfiltersessData = new EventEmitter();

  @Input() tagsID;
  loginInfo: any;
  FilterList: any = [];

  @ViewChild('table') table: MatTable<any>;

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef,
    private tagService: TagsService, private toaster: ToasterService, private helper: HelperService) { }

  ngOnInit(): void {
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    // this.initializeForm();
  }

  get GeneralData() { return this.tagsFilterForm.controls; }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !btnst;
    }
  }

  drop(event) {
    // event.container.data =
    //   event.container.data == '' ? [] : event.container.data;
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        // event.previousContainer.data,
        event.previousContainer.data.filteredData,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.table_render = false;
      this.changeDetector.detectChanges();
      this.table_render = true;
    }
    this.FinalipadList = event.container.data;
    this.helper.setValue('OnDirty', true);
    this.helper.setValue('tagfilterdata', this.FinalipadList);
    this.getFilterDetail(this.FinalipadList);
  }

  getTableValue(e) {
    this.FinalipadList = e;
  }

  @Input()
  public set FilterDataListValue(FilterDataList) {
    // this.tagsFilterForm = this.formBuilder.group({
    //   useForTabletFilter: [null],
    // });

    this.tagsFilterForm.patchValue({
      useForTabletFilter: false
    });

    this.FilterList = [];
    this.finalipadjsondata = [];

    if (FilterDataList != undefined && FilterDataList != null && FilterDataList != '' && FilterDataList != '[]') {
      this.FilterList = FilterDataList.FilterList1 == '' ? [] : JSON.parse(FilterDataList.FilterList1);
      if (this.helper.getValue('OnDirty') == false) {
        this.helper.setValue('tagfilterdata', this.FilterList);
      }

      var FilterJsonValue = FilterDataList.FilterList1 == '[]' ? null : FilterDataList.FilterList1;
      if (FilterJsonValue != null) {
        this.tagsFilterForm.patchValue({
          useForTabletFilter: FilterDataList.useForTabletFilter
        });
        this.GetAvailableFilterList(FilterDataList.id);
      } else {
        this.GetAvailableFilterList(0);
      }
    } else {
      //  this.FilterList = [];
      this.GetAvailableFilterList(0);
      this.helper.setValue('tagfilterdata', this.FilterList);
    }
  }

  GetAvailableFilterList(rowId) {
    this.loginInfo = this.helper.getValue('LoginInfo');
    if (rowId == undefined || rowId == null || rowId == '') {
      rowId = 0;
    }
    const ItemTypeList: ISelectTagDetails = {
      id: rowId,
      tenantId: this.loginInfo.tenantId,
    };
    this.tagService.SelectAvailableFilter(ItemTypeList).subscribe((Filterres) => {
      this.FilterList = this.helper.getValue('tagfilterdata');
      if (rowId != 0) {
        if (Filterres != undefined && Filterres != null && Filterres != '') {
          Filterres = Filterres.filter((val) => {
            return !this.FilterList.find((val2) => {
              return val.id === val2.id;
            });
          });
          this.GetFilterList(Filterres);
        } else {
          this.dataSource.data = [];
          this.displayedColumns = ['name'];
          this.columns = [{ columnDef: 'name', header: 'Tags' }];
        }
      } else {
        this.GetFilterList(Filterres);
      }
    });
  }

  GetFilterList(filter) {
    this.dataSource.data = filter.filter((x) => x.TagCategory == 'reports' && x.IsActive == true);
    this.displayedColumns = ['name'];
    this.columns = [{ columnDef: 'name', header: 'Tags' }];
  }

  onSaveFilter(tagId, tagname) {
    // if (this.FilterList.length > 0) {
    this.getFilterDetail(this.FilterList);
    var ArraySpecif = [];
    if (this.finalipadjsondata != undefined && this.finalipadjsondata != null && this.finalipadjsondata != '') {
      if (this.finalipadjsondata.length > 0) {
        let myon = JSON.parse(this.finalipadjsondata);
        for (var i = 0, len = myon.length; i < len; i++) {
          ArraySpecif.push(myon[i].id);
        }
      }
    }

    const ItagfilterList: ITagFilter = {
      id: 0,
      tag_id: this.helper.getValue('tagIdvalue'),
      name: tagname.trim(),
      useForTabletFilter: this.tagsFilterForm.value.useForTabletFilter,
      ipadSortOrderPrintList: ArraySpecif.toString(),
      ipadSortOrderObject: this.finalipadjsondata,
      tenantId: this.loginInfo.tenantId,
      isActive: true,
      createdBy: this.loginInfo.userId,
      modifiedBy: this.loginInfo.userId,
    };
    this.helper.setValue('tabletval', false);
    this.tagService.SaveTagFilterDetails(ItagfilterList).subscribe((filterresponse) => {
      var messageFilter = filterresponse.message.split('-');
      this.submittedGeneral = false;
      if (filterresponse.id == 0) {
        this.toaster.errorPopUp(messageFilter[1]);
      } else {
        this.toaster.successPopUp(filterresponse.message);
      }
    });
  }

  getFilterDetail(event) {
    const keys_to_keep = ['id', 'name'];
    const reduxfilter = (array) =>
      array.map((o) =>
        keys_to_keep.reduce((acc, curr) => {
          acc[curr] = o[curr];
          return acc;
        }, {})
      );
    if (event != undefined && event != null && event != '') {
      this.finalipadjsondata = JSON.stringify(reduxfilter(event));
    }
    else {
      this.finalipadjsondata = "";
    }
  }

  isdragndrop(e) {
    this.tagfiltersessData.emit(e);
  }
}
