<form class="loginForm form-switch table_forms" [formGroup]="salesReconciliationForm"
  (change)="onchange(salesReconciliationForm.value)">
  <div class="row">
    <div class="col-sm-3">
      <div class="form-group">
        <input type="text" placeholder="Search to Modify" class="form-control datepicker" bsDatepicker
          formControlName="SearchDate" #dpYMD="bsDatepicker" appInputRestriction
          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false}">
      </div>
      <Section class="Communication_tab p-2">
        <div class="form-group">
          <div class="d-flex">
            <label class="flex-fill">Expected Deposit</label>
            <label class="flex-fill text-right">${{expectedDisposite}}</label>
          </div>
        </div>
        <div class="form-group">
          <div class="d-flex">
            <label class="flex-fill text-danger">Actual Deposit</label>
            <label class="col-sm-4 p-0">
              <input type="text" class="form-control" formControlName="actualAmount">
            </label>
          </div>
        </div>
        <div class="form-group">
          <div class="d-flex">
            <label class="flex-fill">Over/Short</label>
            <label class="flex-fill text-right text-danger">$6100.00</label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 form-group">
            <button class="btn  btn-sm float-right submit_button" type="submit" (click)="SaveReconsile();">
              <img src="assets/components/images/save.png" alt="employee" class="">
              <label class="label_config">Save</label>
            </button>
          </div>
        </div>
      </Section>
      <label class="text-danger p-2">The Following Days need to be reconciled</label>
      <section class="Communication_tab p-2">
        <table class="dataTable sales_table">
          <tbody>
            <tr>
              <td>
                <div class="emp_name d-flex justify-content-center" (click)="changeTableItem($event,1)"
                  [style.background-color]="reportsColor == true ? '#341a46' : 'transparent'">
                  <img src="assets/components/images/reports-icon.png" alt="sales" class="img-fluid ">
                  <label class="align-self-center">{{currentDate}}</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="emp_name d-flex justify-content-center" (click)="changeTableItem($event,2)"
                  [style.background-color]="secreportsColor == true ? '#341a46' : 'transparent'">
                  <img src="assets/components/images/reports-icon.png" alt="sales" class="img-fluid ">
                  <label class="align-self-center">{{currentDateAdd}}</label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
    <div class="col-sm-9">
      <!-- <div class="table-container">
                <table mat-table #table [dataSource]="dataSource" matSort
                  class="mat-elevation-z8 table employee-table employee dataTable full-width-table" style="width: 100%;">
                  <ng-container *ngFor="let item of columns " matColumnDef="{{item.columnDef}}">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="emp_name"> {{item.header}} </th>

                    <td mat-cell *matCellDef="let element; let idx=index;">
                      <div *ngIf="item.columnDef == 'grosssales'">{{ element[item.columnDef] }}</div>
                      <div *ngIf="item.columnDef == 'data'">{{ element[item.columnDef] | currency }}</div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef >
                      <div *ngIf="item.columnDef == 'grosssales'"></div>
                      <div *ngIf="item.columnDef == 'data'">
                        {{getTotalCost1() | currency}}
                      </div>
                    </td>

                  </ng-container>
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell employeenodata" [attr.colspan]="displayedColumns.length">No data matching the search</td>
                  </tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row
                    *matRowDef="let row; columns: displayedColumns;"></tr>
                    [style.background-color]="rowClicked == element.id ? '#341a46' : 'transparent'"
                    <div>
                      <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="footer"></tr>
                    </div>

                </table>
              </div> -->
      <div class="footer-table Communication_tab mb-2 report-table">
        <section class="d-flex justify-content-between outshadow p-1">
          <img src="assets/components/images/back-button.png" alt="Back" class="img-fluid h-100 align-self-center">
          <label class="align-self-center">{{currentDate}}</label>
          <div class="d-flex no-gutter mb-0">
            <div class="salesreconciliation"><img src="assets/components/images/gen-icon-export.png" alt="sales"
                class="img-fluid cash_icon"></div>
            <div class="salesreconciliation"><img src="assets/components/images/gen-icon-email.png" alt="sales"
                class="img-fluid cash_icon_w90"></div>
            <div class="salesreconciliation"><img src="assets/components/images/gen-icon-print.png" alt="sales"
                class="img-fluid cash_icon"></div>
            <div class="salesreconciliation"><img src="assets/components/images/gen-icon-dashboard.png" alt="sales"
                class="img-fluid cash_icon_w90"></div>
          </div>
        </section>
        <app-fliptable-footer [dataSource]="dataSource1" [displayedColumns]="displayedColumns1" [columns]="columns1">
        </app-fliptable-footer>
        <app-fliptable-footer [dataSource]="dataSource2" [displayedColumns]="displayedColumns2" [columns]="columns2">
        </app-fliptable-footer>
        <app-fliptable-footer [dataSource]="dataSource3" [displayedColumns]="displayedColumns3" [columns]="columns3">
        </app-fliptable-footer>
        <app-fliptable-footer [dataSource]="dataSource4" [displayedColumns]="displayedColumns4" [columns]="columns4"
          [footerHide]="fourthTableHide"></app-fliptable-footer>
      </div>

    </div>
  </div>
</form>