import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import { BaseService } from '../base/base.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import {
  IListInfo, IHierachyUserValue, ILogin, GetAllUserList, IActiveUser, IHierarchySubject, IRow, INameRole, IempId,
  IempRoleId, IHierachydetails, ICodeMaster, ISelectState, IDeleteState, IStateMaster, ISaveRole, IActivateDeactivateRole,
  ISavePermission, IRole, GetTypeOfBus, SaveTypeOfBus, ISaveLaborCategory, IActivateDeactivateCategory, ISelectCategory, ISaveRolePermission, ISaveDefaultRolePermission, IGetPermission, ISaveTaxMaster, SaveCurrConf
} from '../interfaces/iadmin';
import { BehaviorSubject } from 'rxjs';
import { IGetRoleID } from '../interfaces/iemployee';
import { SelectAdjustGeneral } from '../interfaces/iadjustment';
//import { IGetRoleID } from '../interfaces/iemployee';

@Injectable({
  providedIn: 'root'
})



export class AdminService {
  hieDealerParentVal = new BehaviorSubject<IHierarchySubject>({
    id: "",
    name: "",
    typeId: "",
    typeName: "",
    locationId: "",
    isActive: "",
    parentTenantId: ""
  });
  hieCompanyParentVal = new BehaviorSubject<IHierarchySubject>({
    id: "",
    name: "",
    typeId: "",
    typeName: "",
    locationId: "",
    isActive: "",
    parentTenantId: ""
  });
  hieStoreParentVal = new BehaviorSubject<IHierarchySubject>({
    id: "",
    name: "",
    typeId: "",
    typeName: "",
    locationId: "",
    isActive: "",
    parentTenantId: ""
  });
  hieRow = new BehaviorSubject<IRow>({
    dealerRow: "",
    companyRow: "",
    storeRow: "",

  });
  hieRowDealerId = new BehaviorSubject<IRow>({
    IdDealer: "",
    dealerHeaderName: "",

  })
  hieRowComapnyId = new BehaviorSubject<IRow>({
    IdComapny: "",
    companyHeaderName: "",

  })

  hieRowStoreId = new BehaviorSubject<IRow>({

    IdStore: "",
    storeHeaderName: "",
  })

  hieHeader = new BehaviorSubject<INameRole>({
    isvParentName: "",
    dealerHeaderName: "",
    companyHeaderName: "",
    storeHeaderName: "",
    dealerHierarchyHide: false,
    companyHierarchyHide: false,
    storeHierarchy: false,
  });
  hieDealerParentData: any;
  hieCompanyParentData: any;
  hieStoreParentData: any;
  hieRowData: any;
  hieHeaderData: any;
  comFlag: any;
  rowidData: any;
  hierarchyDealerSource = new BehaviorSubject<any>({});
  hierarchyCompanySource = new BehaviorSubject<any>({});
  hierarchyStoreSource = new BehaviorSubject<any>({});
  hierarchydealerTransferValue: any;
  hierarchycompanyTransferValue: any;
  hierarchystoreTransferValue: any;

  empParentData: any;
  wagesRoleData: any;
  employeeWagesSource = new BehaviorSubject<any>({});
  wagesCheckData: any;
  empIDParentVal = new BehaviorSubject<IempId>({
    id: ""
  })
  empWagesRoleId = new BehaviorSubject<IempRoleId>({
    RoleId: ""
  })
  rowval: any;
  rowId: any;
  hieRowDealer: any;
  hieRowCompany: any;
  hieRowStore: any;
  HierachyValue: any;

  HierachyValues = new BehaviorSubject<IHierachydetails>({
    isv: "",
    dealer: "",
    company: "",
    store: ""
  })
  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) {
    this.hierarchydealerTransferValue = this.hierarchyDealerSource.asObservable();
    this.hierarchycompanyTransferValue = this.hierarchyCompanySource.asObservable();
    this.hierarchystoreTransferValue = this.hierarchyStoreSource.asObservable();
    this.hieDealerParentData = this.hieDealerParentVal.asObservable();
    this.hieCompanyParentData = this.hieCompanyParentVal.asObservable();
    this.hieStoreParentData = this.hieStoreParentVal.asObservable();
    this.hieRowData = this.hieRow.asObservable();
    this.hieHeaderData = this.hieHeader.asObservable();
    this.wagesCheckData = this.employeeWagesSource.asObservable();
    this.empParentData = this.empIDParentVal.asObservable();
    this.wagesRoleData = this.empWagesRoleId.asObservable();
    this.hieRowDealer = this.hieRowDealerId.asObservable();
    this.hieRowCompany = this.hieRowComapnyId.asObservable();
    this.hieRowStore = this.hieRowStoreId.asObservable();
    this.HierachyValue = this.HierachyValues.asObservable();

  }

  checkDealerHierarchydata(hierarchydealerTransferValue: any) {
    this.hierarchyDealerSource.next(hierarchydealerTransferValue);
    this.comFlag = this.Helper.getValue('Dealerflag');
    if (this.comFlag == 1) {
      this.router.navigate(['/dealer']);
    }
  }
  checkCompanyHierarchydata(hierarchycompanyTransferValue: any) {
    this.hierarchyCompanySource.next(hierarchycompanyTransferValue);
    this.comFlag = this.Helper.getValue('Companyflag');
    if (this.comFlag == 2) {
      this.router.navigate(['/company']);
    }
  }
  checkStoreHierarchydata(hierarchystoreTransferValue: any) {
    this.hierarchyStoreSource.next(hierarchystoreTransferValue);
    this.comFlag = this.Helper.getValue('Storeflag');
    if (this.comFlag == 3) {
      this.router.navigate(['/store']);
    }
  }
  setDealerParentVal(parentValue: IHierarchySubject) {
    this.hieDealerParentVal.next(parentValue); let Dealerflag
    Dealerflag = this.Helper.getValue('Dealerflag');
    if (Dealerflag == 1) {
      this.router.navigate(['/dealer']);
    }
  }
  setCompanyParentVal(parentValue: IHierarchySubject) {
    this.hieCompanyParentVal.next(parentValue);
    let Companyflag
    Companyflag = this.Helper.getValue('Companyflag');
    if (Companyflag == 2) {
      this.router.navigate(['/company']);
    }
  }
  setStoreParentVal(parentValue: IHierarchySubject) {
    this.hieStoreParentVal.next(parentValue); let Storeflag
    Storeflag = this.Helper.getValue('Storeflag');
    if (Storeflag == 3) {
      this.router.navigate(['/store']);
    }
  }
  setRowData(rowval: IRow) {
    this.hieRow.next(rowval);
    let dealerFlag; let companyflag; let storeflag
    dealerFlag = this.Helper.getValue('Dealerflag');
    companyflag = this.Helper.getValue('Companyflag');
    storeflag = this.Helper.getValue('Storeflag');
    if (dealerFlag == 1) {
      this.router.navigate(['/dealer']);
    }
    if (companyflag == 2) {
      this.router.navigate(['/company']);
    }
    if (storeflag == 3) {
      this.router.navigate(['/store']);
    }
  }
  setRowDataDealer(rowdealer: IRow) {
    this.hieRowDealerId.next(rowdealer)
  }
  setRowDataComapny(rowComp: IRow) {
    this.hieRowComapnyId.next(rowComp)
  }
  setRowDataStore(rowStore: IRow) {
    this.hieRowStoreId.next(rowStore)
  }
  setHeaderData(headerval: INameRole) {
    this.hieHeader.next(headerval);
  }
  checkWagesValue(wagesCheckData: any) {
    this.employeeWagesSource.next(wagesCheckData);
  }
  checkIdvalue(empParentData: any) {
    this.empIDParentVal.next(empParentData);
  }
  checkRoleIdvalue(wagesRoleData: any) {
    this.empWagesRoleId.next(wagesRoleData);
  }
  HierachyDetails(HierachyValue: IHierachydetails) {
    this.HierachyValues.next(HierachyValue);
  }
  GetPermissions() {
    return this.base.post('Admin/GetAllPermissions', '').pipe(map((response: any) => {
      return response;
    }));
  }

  GetDefaultPermission(roleDetails: IGetPermission) {
    return this.base.post('Admin/GetDefaultPermission', roleDetails).pipe(map((response: any) => {
      return response;
    }));
  }

  GetMultiPartFiles(fileName) {
    // return this.base.get('Handshake/Multipartdata/'+fileName, {});
    return this.base.get('Handshake/Multipartdata/Upload/Devices/svrr.jpeg').pipe(map((response: any) => {
      return response;
    }));
  }


  GetAllRoles(value: IGetRoleID) {
    return this.base.post('Admin/GetAllRoles', value).pipe(map((response: any) => {
      return response;
    }));



    //   return this.base.post('http://107.180.105.89:8085/Admin/GetAllRoles', '').pipe(map((response: any) => {
    //     return response;
    //   }));
  }

  SaveRole(saveDetails: any) {
    return this.base.post('Admin/SaveRole', saveDetails).pipe(map((response: any) => {
      return response;
    }));
  }

  ActivateDeactivateRole(activateDeactivate: IActivateDeactivateRole) {
    return this.base.post('Admin/DeleteRole', activateDeactivate).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveCategory(saveDetails: ISaveLaborCategory) {
    return this.base.post('Admin/SaveCategory', saveDetails).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveLabourCategory(saveDetails: ISaveLaborCategory) {
    return this.base.post('Admin/SaveLabourCategory', saveDetails).pipe(map((response: any) => {
      return response;
    }));
  }

  GetAllCategories(Category: IGetRoleID) {
    return this.base.post('Admin/GetAllCategories', Category).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectCategory(catgoryDetails: ISelectCategory) {
    return this.base.post('Admin/SelectCategory', catgoryDetails).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectLabourCategory(catgoryDetails: ISelectCategory) {
    return this.base.post('Admin/SelectLabourCategory', catgoryDetails).pipe(map((response: any) => {
      return response;
    }));
  }


  ActivateDeactivateCategory(activateDeactivate: IActivateDeactivateCategory) {
    return this.base.post('Admin/DeleteCategory', activateDeactivate).pipe(map((response: any) => {
      return response;
    }));
  }
  // UserHierachy(hierachy: IHierachyUserValue) {
  //   return this.base.post('Admin/SaveTenant', hierachy).pipe(map((response: any) => {
  //     return response;
  //   }));
  // }
  // Below API is using database Level query
  UserHierachy(hierachy: IHierachyUserValue) {
    return this.base.post('Admin/SaveTenantDetails', hierachy).pipe(map((response: any) => {
      return response;
    }));
  }
  TenantHierarchy(tenantHierarchy: ILogin) {
    return this.base.post('Admin/GetTenantHierarchyList', tenantHierarchy).pipe(map((response: any) => {
      return response;
    }));
  }
  TenantHierarchyData(tenantHierarchy: IListInfo) {
    // server issue
    return this.base.post('Admin/GetHierarchyListInfo', tenantHierarchy).pipe(map((response: any) => {
      return response;
    }));
  }
  GetAllHierachy(hierachy: GetAllUserList) {
    return this.base.post('Admin/GetTenantDetails', hierachy).pipe(map((response: any) => {
      return response;
    }));
  }
  IsActiveUser(isActive: IActiveUser) {
    return this.base.post('Admin/ReActivateTenant', isActive).pipe(map((response: any) => {
      return response;
    }));
  }
  GetTenantBusiness(isTenant: IListInfo) {
    return this.base.post('Admin/GetTenantBusinessTypes', isTenant).pipe(map((response: any) => {
      return response;
    }));
  }

  GetCodeMaster(isCode: ICodeMaster) {
    return this.base.post('Admin/GetCodeMaster', isCode).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveStateMaster(ObjState: IStateMaster) {
    return this.base.post('Admin/SaveState', ObjState).pipe(map((response: any) => {
      return response;
    }));
  }
  DeleteStateMaster(ObjState: IDeleteState) {
    return this.base.post('Admin/DeleteState', ObjState).pipe(map((response: any) => {
      return response;
    }));
  }
  SelectStateMaster(ObjState: ISelectState) {
    return this.base.post('Admin/SelectState', ObjState).pipe(map((response: any) => {
      return response;
    }));
  }
  SelectTimeZone() {
    return this.base.get('Admin/GetTimeZone').pipe(map((response: any) => {
      return response;
    }));
  }
  EditStateMaster(ObjState: ISelectState) {
    return this.base.post('Admin/EditState', ObjState).pipe(map((response: any) => {
      return response;
    }));
  }

  GetRole(roleData: IRole) {
    return this.base.post('Admin/EditRole', roleData).pipe(map((response: any) => {
      return response;
    }));
  }


  GetTypeOfBusiness(id: GetTypeOfBus) {
    return this.base.post('Admin/GetTypeOfBusiness', id).pipe(map((response: any) => {
      return response;
    }));
  }
  EditTypeOfBusiness(id: IRole) {
    return this.base.post('Admin/EditTypeOfBusiness', id).pipe(map((response: any) => {
      return response;
    }));
  }
  SaveTypeOfBusiness(ObjState: SaveTypeOfBus) {
    return this.base.post('Admin/SaveTypeOfBusiness', ObjState).pipe(map((response: any) => {
      return response;
    }));
  }
  DeleteTypeOfBusiness(ObjState: IDeleteState) {
    return this.base.post('Admin/DeleteTypeOfBusiness', ObjState).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveRolePermission(employee: ISaveRolePermission) {
    return this.base.post('Admin/SaveRolePermission', employee).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveDefaultPermiission(saveDefaultRolePermission: ISaveDefaultRolePermission) {
    return this.base.post('Admin/SaveDefaultPermission', saveDefaultRolePermission).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectRole(value: SelectAdjustGeneral) {
    return this.base.post('Admin/RoleDetails', value).pipe(map((response: any) => {
      return response;
    }));
  }

  //----------------TAX MASTER--------------------

  GetTaxMaster(id: IRole) {
    return this.base.post('Admin/GetTaxMaster', id).pipe(map((response: any) => {
      return response;
    }));
  }
  SaveTaxMaster(ObjState: ISaveTaxMaster) {
    return this.base.post('Admin/SaveTaxMaster', ObjState).pipe(map((response: any) => {
      return response;
    }));
  }
  DeleteTaxMaster(ObjState: IDeleteState) {
    return this.base.post('Admin/DeleteTaxMaster', ObjState).pipe(map((response: any) => {
      return response;
    }));
  }

  //----------------TAX HISTORY REPORT--------------------//


  //----------------Currency Configuration--------------------//

  SaveCurrencyConfig(Objcurrconf: SaveCurrConf) {
    return this.base.post('Admin/SaveCurrencyConfiguration', Objcurrconf).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectCurrencyConfig(id: IRole) {
    return this.base.post('Admin/SelectCurrencyConfiguration', id).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveOrchConf(Objcurrconf: SaveCurrConf) {
    return this.base.post('Admin/SaveOrchestrationConfiguration', Objcurrconf).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectOrchConf(id: IRole) {
    return this.base.post('Admin/SelectOrchestrationConfiguration', id).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveErrorConfig(Objcurrconf: SaveCurrConf) {
    return this.base.post('Admin/SaveErrorConfiguration', Objcurrconf).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectErrorConfig(id: IRole) {
    return this.base.post('Admin/SelectErrorConfiguration', id).pipe(map((response: any) => {
      return response;
    }));
  }
}