<div class="row top-sec">
  <div class="col-sm-4">
    <div>
      <div class="flip-table" *ngIf="!modifierItemsData">
        <span class="text-box">{{employee}}</span> &nbsp;
        <button class="plus_btn outshadow" (click)="ClearData()"><i class="fa fa-plus"></i></button>
      </div>
      <hr class="linebar" *ngIf="!modifierItemsData">
    </div>
  </div>
  <div class="col-sm-8">
    <div class="form-group global-form">
      <button [ngClass]="{'submit_button_error': isInvalid==true}"
        class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="OnSave();">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label></button>
    </div>
  </div>

</div>
<div class="row second-sec">
  <div class="col-sm-4">
    <div>
      <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns"
        [dealerItem]="tableRowColor" (tableItemId)="getTableIdx($event)" (tableEmpName)="getTableName($event)"
        [employeeItem]="tableRowColor" (breakActive)="ActivateDeactivate($event)" (clearDetails)="ClearData()"
        [EmployeeTableData]="EmployeeTableData" [DataCom]="employee" (hierarchyData)="gethierarchyData($event)"
        (btnActive)="btnActive($event)"></app-fliptable>
    </div>
  </div>
  <div class="col-sm-8 max-accordian">
    <!-- <div class="form-group global-form">
      <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="OnSave();">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label></button>
    </div> -->
    <!-- <div class="row">
        <div class="col-sm-6">
          <app-title-card [Dataname]="employeeName" [DataCom]="employee" [DataIcon]="employeeIcon"></app-title-card>
        </div>
      </div> -->
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst" [expanded]="isFirst" class="outshadow">
        <mat-expansion-panel-header [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
          <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}} </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="item == 'General'">
          <app-emv-general [buttonState]="buttonDisable" [DataIdx]="tableRowId" (emv_general)="getEMVList($event)"
            (isBool)="isBool($event)" [removeDisable]="removeEmv" (emv_remove)="getRemoveList($event)"
            (isInvalid)="isInvalidAccordion($event)" (emvPageData_child)="getevmsessData($event)"></app-emv-general>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>