<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="addEmployee()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="OnSaveEmployee();">
          <img src="assets/components/images/save.png" alt="employee" class="">
          <label class="label_config">Save</label>
        </button>
        </div>
    </div>
</div>
<div class="row second-sec">
    <div class="col-sm-4">
        <div>
            <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" [dealerItem]="tableRowColor" (tableItemId)="getTableIdx($event)" (tableEmpName)="getTableName($event)" [EmployeeTableData]="EmployeeTableData" (hierarchyActive)="checkValue($event)"
                [employeeItem]="tableRowColor" (clearDetails)="addEmployee()" [DataCom]="employee" (toggleemit)="toggleIsActive($event)" (btnActive)="btnActiveStatus($event)"></app-fliptable>
        </div>
    </div>
    <div class="col-sm-8">
        <!-- <div class="form-group global-form">
        <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="OnSaveEmployee();">
          <img src="assets/components/images/save.png" alt="employee" class="">
          <label class="label_config">Save</label>
        </button>
      </div> -->
        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst" [expanded]="isFirst" class="outshadow" (matopen)="matstate($event)">
                <mat-expansion-panel-header [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                    <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}} </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General'">
                    <app-isv-general [buttonState]="buttonDisableStatus" [companyHierarchyHide]="companyHierarchyHide" [dealerEmp]="dealerEmp" [compEmp]="compEmp" [dealerHierarchyHide]="dealerHierarchyHide" [loginID]="loginid" (generalItem)="getGeneralDetail($event)" [DataIdx]="tableRowId"
                        [checkTarget]="activeDeactiveValue" (isInvalid)="isInvalidAccordion($event)" (ISVPageData_child)="getisvsessData($event)">
                    </app-isv-general>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
