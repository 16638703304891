<div class="row">
  <div class="col-sm-10 offset-sm-1">
    <div class="form-group mt-2">
      <button
        class="btn btn-sm submit_button float-right loginForm button-none"
        type="submit"
        (click)="SaveItemAssignedModifier(saveId)"
        [disabled]="buttonDisable ? true : null"
      >
        <!-- [disabled]="buttonState ? true : null" -->
        <img src="assets/components/images/save.png" alt="employee" class="" />
        <label class="label_config">Save</label>
      </button>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="item_assigned">
          <app-fliptable-layout
            [dataSource]="dataSource"
            [displayedColumns]="displayedColumns"
            [columns]="columns"
            [DatatableRender]="table_render"
            [DataModifier]="menuData"
            SalesID="layoutId"
            (searchItems)="searchMainItem($event)"
          ></app-fliptable-layout>
        </div>
      </div>
      <div class="col-sm-6">
        <div>
          <label class="item_assigned_label">Item Assigned</label>
          <div class="itemAssignedmodify menu_wrapper item_main">
            <div
              cdkDropList
              cdkDropListGroup
              id="todo"
              #todo="cdkDropList"
              [cdkDropListData]="itemAssignedList"
              [cdkDropListConnectedTo]="['layoutId']"
              (cdkDropListDropped)="drop($event)"
              cdkDragBoundary=".tablet"
              class="example-list"
            >
              <div
                class="item_assigned_box"
                *ngFor="let item of itemAssignedList; let i = index"
                cdkDrag
                cdkDragDropZone
              >
                <div [ngClass]="{ emp_name: item.id || DragId == true }">
                  <div class="outshadow">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
