<div class="p-3">
    <span class="tabhead_wrap outshadow">{{auditName}} - Audit</span>
    <div class="row mt-3">
        <div class="col-sm-8">
            <app-fliptable-center-footer [dataSource]="dataSource3" [displayedColumns]="displayedColumns3"
                [columns]="columns3" [thirdTableShow]="thirdTableShow" (tableItem)="onChangeRowValue($event)">
            </app-fliptable-center-footer>

            <div class="my-3"><span class="tabhead_wrap outshadow">Customer Details</span></div>
            <div class="Communication_tab card_wrap">
                <div class="row no-gutters row_border">
                    <div class="col-sm-2 col-4 card_img">
                        <img src="assets/components/images/employeeRoundCardCard.png" alt="sales"
                            class="mx-auto d-block">
                    </div>
                    <div class="col-sm-10 col-8 align-self-center">
                        <div class="row no-gutters">
                            <div class="col-sm-2 col-6">
                                <p><b>First Name</b></p>
                                 <p>{{CustomerDetails[0].firstName}}</p> 
                            </div>
                            <div class="col-sm-2 col-6">
                                <p><b>Last Name</b></p>
                                <p>{{CustomerDetails[0].lastName}}</p>
                            </div>
                            <div class="col-sm-2 col-6">
                                <p><b>Address</b></p>
                                <p>{{CustomerDetails[0].address}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters row_border">
                    <div class="col-sm-2 col-4 card_img">
                        <img src="assets/components/images/shape-card.png" alt="sales" class="mx-auto d-block">
                    </div>
                    <div class="col-sm-10 col-8 align-self-center">
                        <div class="row ">
                            <div class="col-sm-2 col-6">
                                <p><b>City</b></p>
                                <p>{{CustomerDetails[0].city}}</p>
                            </div>
                            <div class="col-sm-2 col-6">
                                <p><b>State</b></p>
                                <p>{{CustomerDetails[0].state}}</p>
                            </div>
                            <div class="col-sm-2 col-6">
                                <p><b>Zip</b></p>
                                <p>{{CustomerDetails[0].zip}}</p>
                            </div>
                            <div class="col-sm-2 col-6">
                                <p><b>Notes</b></p>
                                <p><b>{{CustomerDetails[0].notes}}</b></p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters row_border">
                    <div class="col-sm-2 col-4 card_img">
                        <img src="assets/components/images/icon-messaging.png" alt="sales" class="mx-auto d-block">
                    </div>
                    <div class="col-sm-10 col-8  align-self-center">
                        <div class="row no-gutters">
                            <div class="col-sm-2 col-6">
                                <p><b>Phone</b></p>
                                <p>{{CustomerDetails[0].phone}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters pb-2">
                    <div class="col-sm-2 col-4 card_img">
                        <img src="assets/components/images/email-details.png" alt="sales" class="mx-auto d-block">
                    </div>
                    <div class="col-sm-10 col-8 align-self-center">
                        <div class="row no-gutters">
                            <div class="col-sm-2 col-6">
                                <p><b>Email</b></p>
                                <p>{{CustomerDetails[0].email}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
                <div class="">
                    <div class="Communication_tab screenshot-table report-audit-font">
                        <div class="row receipt_config">
                            <div class="col-sm-4 col-4">
                                <span class="uber_para ft-14 text-left">{{auditDate}}<br></span>
                                   <span class="uber_para ft-14 text-left">{{auditEmployee}}</span>
                             </div>
                            <div class="col-sm-4 col-4">
                                <div class="text-center">
                                    <img src="assets/components/images/flip-black.png" alt="employee" class="img-fluid">
                                </div>
                            </div>
                            <div class="col-sm-4 col-4 text-center">
                                <span class="uber_para ft-14 text-right">{{auditTime}}<br></span>
                                <span class="uber_para ft-14 text-right">{{auditName}}</span>
                            </div>
                            <div class="col-sm-12 col-12 text-center">
                            <hr class="linebar">
                             </div>
                        </div>
                        <table mat-table [dataSource]="Filtertransactions" class="mat-elevation-z8 discount-row"
                            style="width: 100%;">
                            <!-- Item Column -->
                            <ng-container matColumnDef="item">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let Filtertransactions" class="tdwidth-100">
                                    <ng-container *ngIf="Filtertransactions.itemType== 'Main'">
                                        {{Filtertransactions.item}}
                                <td *ngFor="let subItem of transactions" class="noDisp pl-15">
                                    <ng-container
                                        *ngIf="subItem.itemType== 'Sub Item' && subItem.parentItemid == Filtertransactions.itemid">
                                        <span>- </span>{{subItem.item}}
                                    </ng-container>
                                </td>
                            </ng-container>
                            </td>
                            <td mat-footer-cell *matFooterCellDef> </td>
                            </ng-container>

                            <!-- Cost Column -->
                            <ng-container matColumnDef="cost">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let Filtertransactions" class="text-right" noDisp>
                                    <ng-container *ngIf="Filtertransactions.itemType== 'Main'">
                                        {{Filtertransactions.cost | currency}}
                                <td *ngFor="let subItem of transactions" class="noDisp pl-15">
                                    <ng-container
                                        *ngIf="subItem.itemType== 'Sub Item' && subItem.parentItemid == Filtertransactions.itemid">
                                        {{subItem.cost | currency}}
                                    </ng-container>
                                </td>
                            </ng-container>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="text-right table_screen">
                                <table style="width: 100%;">
                                    <thead>
                                        <th></th>
                                    </thead>
                                    <tr>
                                        <td><span>Subtotal : </span></td>
                                        <td>{{subTotal | currency}} </td>
                                    </tr>
                                    <tr>
                                        <td><span>Discount #1 : </span></td>
                                        <td>{{discountTotal | currency}} </td>
                                    </tr>
                                    <tr>
                                        <td><span>Tip Added : </span></td>
                                        <td>{{TipsAmount | currency}} </td>
                                    </tr>
                                    <tr>
                                        <td><span>Tax #1 : </span></td>
                                        <td>{{0 | currency}} </td>
                                    </tr>
                                    <tr></tr>
                                    <tr>
                                        <td>
                                            <p>Total : </p>
                                        </td>
                                        <td>
                                            <p>{{netTotal | currency}} </p>
                                        </td>
                                    </tr>
                                </table>
                                <p>Payment : VISA *5467 Tom Jones</p>
                            </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                        </table>
                    </div>
                    <!-- <div class="Sign_wrap">
                        <h6 class="heading d-flex justify-content-center align-items-center"
                            *ngIf="Filtertransactions[0].Signature != null">
                            <img class="img-fluid" alt="x"
                                src="{{'https://flippos.s3.us-west-1.amazonaws.com/' + Filtertransactions[0].Signature}}">
                        </h6>
                        <h6 class="heading d-flex justify-content-center align-items-center"
                            *ngIf="Filtertransactions[0].Signature == null">
                            <img class="img-fluid" alt="x"
                                src="https://flippos.s3.us-west-1.amazonaws.com/signature1.png">
                        </h6>
                    </div> -->
                </div>
          </div>
    </div>
</div>