<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="addMenuItem()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn btn-sm float-right submit_button global-submit" type="submit" (click)="OnSave();">
          <img src="assets/components/images/save.png" alt="employee" class="">
          <label class="label_config">Save</label>
      </button>
        </div>
    </div>

</div>
<div class="row second-sec">
    <div class="col-sm-4">
        <div *ngIf="tableEnable">
            <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" [DataCom]="employee" (menuItem)="getTableIdx($event)" (clearDetails)="addMenuItem()" [dealerItem]="tableRowColor" (tableEmpName)="getTableName($event)"
                (tableItemId)="getTableId($event)" [employeeItem]="tableRowColor" [EmployeeTableData]="EmployeeTableData" (breakActive)="checkValue($event)" (btnActive)="btnActiveStatus($event)" (hideCopyFromGiftCard)="HideGiftCardCopy($event)"></app-fliptable>
        </div>
    </div>
    <div class="col-sm-8 max-accordian">
        <!-- <div class="form-group global-form">
      <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn btn-sm float-right submit_button global-submit" type="submit" (click)="OnSave();">
          <img src="assets/components/images/save.png" alt="employee" class="">
          <label class="label_config">Save</label>
      </button>
    </div> -->
        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of ['General', 'Gift Card Designer']; let i = index" [expanded]="indexExpanded == i" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-expansion-panel-header (click)="expandPanel(item,index)" [ngClass]="{'box_error': (isInvalid==true && item == 'General')}">
                    <mat-panel-title (click)="onChangeAccordion($event,index)" [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}} </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General'">
                    <!-- [GetTipValue]="get2ndTipValue" -->
                    <app-gift-card-general (isInvalid)="isInvalidAccordion($event)" [MenuRowItem]="getDataValue" [GetTipValue]="get2ndTipValue" (giftCardDesigner)="isGiftDesignAccordion($event,1)" [buttonState]="buttonDisable" (giftcardsess_child)="getgiftcardsessData($event)"
                        [copyFromDropDown]="copyFromDropDown" [hideCopyFromInitial]="hideCopyFromInitial" (copySelectedCard)="getSelectedDropDownCard($event)" (hideCopyColor)="HideCopyColor($event)" (giftDesFuncCall)="DesignGeneral($event)" (matopen)="matstate($event)">
                    </app-gift-card-general>
                </div>
                <div *ngIf="item == 'Gift Card Designer'">
                    <app-gift-card-designer [GetDesign]="getGiftDesign" [bNameDesign]="bName" [addressDesign]="address" [cityDesign]="city" [stateDesign]="state" [zipDesign]="zipCode" (giftcard_dessess_child)="getgiftcardsessData($event)"></app-gift-card-designer>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
