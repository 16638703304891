import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-credit-card-vantiv',
  templateUrl: './credit-card-vantiv.component.html',
  styleUrls: ['./credit-card-vantiv.component.css']
})
export class CreditCardVantivComponent implements OnInit {
  CreditCardVantivForm: FormGroup;
  submittedGeneral = false;
  id: any = 0;
  GatewaySelection: any;
  loginInfo: any;
  isSubmitted = false;
  isValidated = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  autoBatchTime:any;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  @Output() public generalCredit = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private objPayment: PaymentService,
    private helper: HelperService, private toaster: ToasterService) { }

  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.CreditCardVantivForm = this.formBuilder.group({
      merchantId: [null, Validators.required],
      merchantBin: [null, Validators.required],
      terminalId: [null, Validators.required],
      autoBatchTime: [null, Validators.required],
      total_Lanes: [null, Validators.required],
      contactlessCapable: [null, Validators.required],
      verify_SSL: [null, Validators.required],
      merchantCategory: [null, Validators.required],
      duplicateChecking: [null],
      storeValue:[null],
      isStoreandForward_EMVonly: [null],
      of_Fixed: [false, Validators.required],
      mobile: [false, Validators.required]
    })
  }
  get GeneralData() { return this.CreditCardVantivForm.controls; }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  @Input() public buttonState;
  // @Input()
  // public set MenuRowItem(tableRowData) {
  //   if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
  //     this.CreditCardVantivForm.patchValue(tableRowData[0]);
  //     this.id = tableRowData[0].id;
  //   }
  // }

  onSaveGeneral() {
    this.submittedGeneral = true;
    if (this.CreditCardVantivForm.invalid) {
      return;
    }
    let paymentserv;
    paymentserv = this.CreditCardVantivForm.value;
    paymentserv.createdBy = this.loginInfo.userId;
    paymentserv.modifiedBy = this.loginInfo.userId;
    paymentserv.isActive = true;
    paymentserv.id = this.id;
    paymentserv.creditCardGatewayId = sessionStorage.getItem("CreditCardID");
      
    this.objPayment.SaveCreditCardPayment(paymentserv).subscribe(res => {
      this.isSubmitted = false;
      this.submittedGeneral = false;
      this.generalCredit.emit(res);
      this.toaster.successPopUp(res.message);
    })
  }
}
