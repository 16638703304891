<div class="row top-sec">
  <div class="col-sm-4">
    <div>
      <div class="flip-table" *ngIf="!modifierItemsData">
        <span class="text-box">{{Tablet}}</span> &nbsp;
        <button class="plus_btn outshadow" (click)="ClearData()"><i class="fa fa-plus"></i></button>
      </div>
      <hr class="linebar" *ngIf="!modifierItemsData">
    </div>
  </div>
  <div class="col-sm-8">
    <div class="form-group global-form">
      <button [ngClass]="{'submit_button_error': isInvalid==true}"
        class="btn  btn-sm  float-right submit_button mr-2 global-submit" type="submit" (click)="OnSave();">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label>
      </button>
    </div>
  </div>
</div>
<div class="row second-sec">
  <div class="col-sm-4">
    <div>
      <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns"
        [dealerItem]="tableRowColor" (tableItemId)="getTableIdx($event)" (tableEmpName)="getTableName($event)"
        [employeeItem]="tableRowColor" (breakActive)="checkvaluePage($event)" (clearDetails)="ClearData()"
        [EmployeeTableData]="TabletData" [DataCom]="Tablet" (hierarchyData)="gethierarchyData($event)"
        (btnActive)="btnActiveStatus($event)"></app-fliptable>
    </div>
  </div>
  <div class="col-sm-8">
    <!-- <div class="form-group global-form">
      <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm  float-right submit_button mr-2 global-submit" type="submit" (click)="OnSave();">
          <img src="assets/components/images/save.png" alt="employee" class="">
          <label class="label_config">Save</label>
      </button>
    </div> -->
    <!-- <div class="row">
      <div class="col-sm-6">
        <app-title-card [Dataname]="employeeName" [DataCom]="employee" [DataIcon]="employeeIcon"></app-title-card>
      </div>
    </div> -->
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of ['General','Logistics']; let i = index"
      [expanded]="indexExpanded == i" class="outshadow">
        <mat-expansion-panel-header (click)="expandPanel(item,index)"
          [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
          <mat-panel-title (click)="onChangeAccordion($event,index)" [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}} </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="item == 'General'">
          <app-tablet-general [DataIdx]="tableRowId" [buttonState]="buttonDisable"
            (GeneralDetailTablet)="getGeneralDetail($event)" [removeDisable]="removetablet"
            (viewScreenItem)="getLogisticScreen($event ,1)" (tablet_general)="getTabletList()"
            (isInvalid)="isInvalidAccordion($event)" (tabData_child)="gettabletsessData($event)"></app-tablet-general>
        </div>
        <div *ngIf="item == 'Logistics'">
          <app-tablet-logistic [DataIdx]="tablerow" [buttonState]="buttonDisable"
            (tablogData_child)="gettabletsessData($event)"></app-tablet-logistic>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>