<div class="receipt_config credit_firstData adjustment_detail">
    <form [formGroup]="configGeneralForm" class="loginForm" (change)="onchange(configGeneralForm.value)">
        <div class="row mb-2">
            <div class="col-sm-3">
                <!-- <div class="mb-3">
                    <img src="assets/components/images/back-button.png" alt="employee" class="img-fluid submit_button">
                </div> -->
                <div class="form-group">
                    <label class="customlable mb-0">Name</label><label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="name" placeholder="RECEIPT" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && configGenData.name.errors) || (requiredError && configGenData.name.errors)}"
                        maxlength="50" (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="ReceiptValid()" />
                    <div class="error">{{errorValue}}</div>
                    <div *ngIf=" (submittedGeneral && configGenData.name.errors) || (requiredError && configGenData.name.errors)" class="invalid-feedback">
                        <div *ngIf="configGenData.name.errors.required">Receipt is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Assigned Printers</label>
                    <ng-multiselect-dropdown [settings]="printSettings" [data]="PrintFilters" [(ngModel)]="selectedItems" [formControl]="assignedPrinters">
                    </ng-multiselect-dropdown>
                    <!-- <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="assignedPrinters">
                        <mat-option value="0" selected>option 1</mat-option>
                        <mat-option value="1">option 2</mat-option>
                        <mat-option value="2">option 3</mat-option>
                    </mat-select> -->
                </div>
                <div class="form-group m-2 cc-disp-none">
                    <label class="customlable">Printer Options</label>
                    <div class="row remove_tab m-3">
                        <div class="col-sm-6">
                            <div class="radiorow">
                                <label class="radiolabel">Remote</label>
                                <div class="form-group">
                                    <div class="custom-control custom-radio custom-control item">
                                        <input type="radio" formControlName="printerOption" name="printerOption" value="remote" class="custom-control-input">
                                        <label class="custom-control-label QR" for="printer_remote"></label>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="radiorow">
                                <label class="radiolabel">Shared</label>
                                <div class="form-group">
                                    <div class="custom-control custom-radio custom-control item">
                                        <input type="radio" formControlName="printerOption" name="printerOption" value="shared" class="custom-control-input">
                                        <label class="custom-control-label QR" for="printer_shared"></label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group m-2 mt-20">
                    <label class="customlable custom-color-npx">Modifier Size</label>
                    <div class="row remove_tab m-3 radio-width">
                        <div class="col-sm-6 ">
                            <div class="radiorow">
                                <label class="radiolabel">SMALL</label>
                                <div class="form-group">
                                    <div class="custom-control custom-radio custom-control item">
                                        <input type="radio" formControlName="modifierSize" id="modifier_small" [(ngModel)]="modifierSize" name="modifierSize" value="small" class="custom-control-input">
                                        <label class="custom-control-label QR" for="modifier_small"></label>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="radiorow">
                                <label class="radiolabel">LARGE</label>
                                <div class="form-group">
                                    <div class="custom-control custom-radio custom-control item">
                                        <input type="radio" formControlName="modifierSize" id="modifier_large" [(ngModel)]="modifierSize" name="modifierSize" value="large" class="custom-control-input">
                                        <label class="custom-control-label QR" for="modifier_large"></label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group m-2">
                    <label class="customlable custom-color-npx">Modifier Color</label>
                    <div class="row remove_tab m-3 radio-width">
                        <div class="col-sm-6">
                            <div class="radiorow">
                                <label class="radiolabel">BLACK</label>
                                <div class="form-group">
                                    <div class="custom-control custom-radio custom-control item">
                                        <input type="radio" formControlName="modifierColor" id="modifier_black" [(ngModel)]="modifierColor" name="modifierColor" value="black" class="custom-control-input">
                                        <label class="custom-control-label QR" for="modifier_black"></label>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="radiorow">
                                <label class="radiolabel">RED</label>
                                <div class="form-group">
                                    <div class="custom-control custom-radio custom-control item">
                                        <input type="radio" formControlName="modifierColor" id="modifier_white" [(ngModel)]="modifierColor" name="modifierColor" value="red" class="custom-control-input">
                                        <label class="custom-control-label QR" for="modifier_white"></label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group m-2">
                    <label class="customlable custom-color-npx">Main Item Size</label>
                    <div class="row remove_tab m-3 radio-width">
                        <div class="col-sm-6">
                            <div class="radiorow">
                                <label class="radiolabel">SMALL</label>
                                <div class="form-group">
                                    <div class="custom-control custom-radio custom-control item">
                                        <input type="radio" formControlName="mainItemSize" id="item_small" [(ngModel)]="mainItemSize" name="mainItemSize" value="itemsmall" class="custom-control-input">
                                        <label class="custom-control-label QR" for="item_small"></label>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="radiorow">
                                <label class="radiolabel">LARGE</label>
                                <div class="form-group">
                                    <div class="custom-control custom-radio custom-control item">
                                        <input type="radio" formControlName="mainItemSize" id="item_large" [(ngModel)]="mainItemSize" name="mainItemSize" value="itemlarge" class="custom-control-input">
                                        <label class="custom-control-label QR" for="item_large"></label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 pr-0 config_arrow">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group label_config">
                            <label class="customlable mb-0">Top Margin</label>&nbsp;
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="topmargin" #inputText placeholder="" appInputRestriction (keypress)="numberOnly($event)" (keydown)="checkInput($event, inputText)" />
                        </div>
                    </div>
                </div>
                <div class="row bottom_margin">
                    <div class="col-sm-12">
                        <div class="form-group label_config label_config1">
                            <label class="customlable mb-0">Bottom Margin</label>&nbsp;
                            <input type="text" class="form-control input-lg inputbox_login" (keydown)="checkInput($event, inputText1)" formControlName="bottommargin" placeholder="" appInputRestriction (keypress)="numberOnly($event)" #inputText1/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-7">
                <div class="Communication_tab">
                    <div class="receipt_head">
                        <div class="row receipt-pad">
                            <div class="col-sm-4 col-4">
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Date</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="date" formControlName="date" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">Date</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Employee</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="employee" formControlName="employee" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">Employee</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-3">
                                <div class="text-center">
                                    <img src="assets/components/images/flip-black.png" alt="employee" class="img-fluid">
                                </div>
                            </div>
                            <div class="col-sm-4 col-4 text-center">
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Time</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="time" formControlName="time" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">Time</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Order#</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="customerOrder" formControlName="customerOrder" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">Order#</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row middle_checkbox">
                            <div class="col-sm-4 ">
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Sales Area</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="salesArea" formControlName="salesArea" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">Sales Area</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Customer</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="customer" formControlName="customer" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">Customer</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row head_config">
                            <div class="col-sm-12 header-pad">
                                <div class="form-group ">
                                    <label class="customlable mb-0 label_config">HEADER CONFIGURABLES&nbsp;
                                        <img src="assets/components/images/arrow-right.png" alt="employee">
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="remove_tab">
                            <div class="row pl-2">
                                <div class="col-sm-5 col">
                                    <div class="form-group">
                                        <label class="customlable mb-0">Font</label>
                                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="headerFont" (change)="onchange(configGeneralForm.value)">
                                            <!-- <mat-option value="0" selected>header 1</mat-option>
                                            <mat-option value="1">header 2</mat-option>
                                            <mat-option value="2">header 3</mat-option> -->
                                            <mat-option *ngFor="let item of headerfontname" [value]="item.typeId">
                                                {{item.typeName}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div class="col-sm-3 col">
                                    <div class="form-group">
                                        <label class="mb-0">Size</label>
                                        <div class="input-box">
                                            <input type="number" class="form-control modify_gen input" placeholder="16" appInputRestriction formControlName="headerSize" max="70" min="2" maxlength="2" (keypress)="numberOnly($event)" #inputText>
                                        </div>
                                        <!-- <div class="error">{{errorMessage}}</div> -->
                                    </div>
                                </div>
                                <div class="col-sm-4 col">
                                    <div class="form-group text-center">
                                        <label class="customlable mb-0 ">Align</label>
                                        <div class="align">
                                            <button class="submit_button box_button" (click)="alignText('left')">
                                                <img src="assets/components/images/left-align.png" alt="employee">
                                            </button>
                                            <button class="submit_button box_button" (click)="alignText('center')">
                                                <img src="assets/components/images/center-align.png" alt="employee">
                                            </button>
                                            <button class="submit_button box_button" (click)="alignText('right')">
                                                <img src="assets/components/images/right-align.png"
                                                    alt="employee"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-1">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <textarea type="text" class="form-control input-lg inputbox_login customTextBox" [ngStyle]="{'text-align': selectedAlign}" formControlName="headercustomTextBox" appInputRestriction placeholder="HEADER TEXT - ADD TEXT HERE"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="receipt_body radiorow">
                        <div class="remove_tab receipt_bodytab">
                            <div class="row m-2">
                                <div class="col-sm-6 col-8">
                                    <div class="form-group">
                                        <label class="customlable mb-0">Font</label>
                                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="bodyFont" (change)="onchange(configGeneralForm.value)">
                                            <!-- <mat-option value="0" selected>body 1</mat-option>
                                            <mat-option value="1">body 2</mat-option>
                                            <mat-option value="2">body 3</mat-option> -->
                                            <mat-option *ngFor="let item of bodyfontname" [value]="item.typeId">
                                                {{item.typeName}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-4">
                                    <div class="form-group ">
                                        <label class="mb-0">Size</label>
                                        <div class="input-box">
                                            <input type="number" max="70" min="2" class="form-control modify_gen input" placeholder="16" maxlength="2" appInputRestriction formControlName="bodySize" (keypress)="numberOnly($event)" #inputText>
                                        </div>
                                        <!-- <div class="error">{{errorMessage}}</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="linebar">
                        <label class="text-center  custom-color-npx">BODY CONFIGURABLES</label>
                        <hr class="linebar">
                        <div class="row mt-2 ">
                            <div class="col-sm-6 col-6">
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Date</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="noModifiers" formControlName="noModifiers" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">No Mods</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Date</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="showModPrice" formControlName="showModPrice" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">Show Mods</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-6">
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Date</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="bySeat" formControlName="bySeat" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">By Seat</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Date</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="consolidate" formControlName="consolidate" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">Consolidate</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row head_config">
                            <div class="col-sm-12">
                                <div class="form-group ">
                                    <label class="customlable mb-0 label_config">BODY CONFIGURABLES&nbsp;
                                        <img src="assets/components/images/arrow-right.png" alt="employee">
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-6">
                                <div class="form-group">
                                    <label class="uber_para">1 ITEM NAME</label>
                                </div>
                                <div class="form-group">
                                    <label class="uber_para">1 ITEM NAME</label>
                                </div>
                                <div class="form-group">
                                    <label class="uber_para">1 ITEM NAME</label>
                                </div>
                                <div class="form-group">
                                    <label class="uber_para">1 ITEM NAME</label>
                                </div>
                                <div class="form-group">
                                    <label class="uber_para">1 ITEM NAME</label>
                                </div>
                            </div>
                            <div class="col-sm-4 col-4 text-right">
                                <div class="form-group">
                                    <label class="uber_para">PRICE</label>
                                </div>
                                <div class="form-group">
                                    <label class="uber_para">PRICE</label>
                                </div>
                                <div class="form-group">
                                    <label class="uber_para">PRICE</label>
                                </div>
                                <div class="form-group">
                                    <label class="uber_para">PRICE</label>
                                </div>
                                <div class="form-group">
                                    <label class="uber_para">PRICE</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="payment_config radiorow">
                        <hr class="linebar">
                        <label class="text-center custom-color-npx">PAYMENT CONFIGURABLES</label>
                        <hr class="linebar">
                        <div class="row mt-1">
                            <div class="col-sm-4 col">
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Point Used</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="pointUsed" formControlName="pointUsed" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">Points Used</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Account Balance</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="accountBalance" formControlName="accountBalance" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">Account
                                                Balance</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mt-5 head_config">
                                <div class="col-sm-12">
                                    <div class="form-group ">
                                        <label class="customlable mb-0 label_config ">PAYMENT CONFIGURABLES&nbsp;
                                            <img src="assets/components/images/arrow-right.png" alt="employee">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col">
                                <div class="form-group">
                                    <!-- <label class="customlable mb-0">Show Inclusive Tax</label> -->
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control item">
                                            <input type="checkbox" class="custom-control-input " name="consolidate" formControlName="consolidate" id="Allowsome">
                                            <label class="custom-control-label mb-0 label_outset">Consolidate</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col">
                                <div class="form-group">
                                    <label class="uber_para">TAX:</label>
                                </div>
                                <div class="form-group">
                                    <label class="uber_para">Discount:</label>
                                </div>
                                <div class="form-group">
                                    <label class="uber_para">Subtotal:</label>
                                </div>
                                <div class="form-group">
                                    <label class="uber_para">Total:</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="linebar">
                    <div class="footer">
                        <div class="remove_tab">
                            <div class="row pl-2">
                                <div class="col-sm-5 col">
                                    <div class="form-group">
                                        <label class="customlable mb-0">Font</label>
                                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="footerFont" (change)="onchange(configGeneralForm.value)">
                                            <!-- <mat-option value="0" selected>footer 1</mat-option>
                                            <mat-option value="1">footer 2</mat-option>
                                            <mat-option value="2">footer 3</mat-option> -->
                                            <mat-option *ngFor="let item of footerfontname" [value]="item.typeId">
                                                {{item.typeName}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div class="col-sm-3 col">
                                    <div class="form-group">
                                        <label class="mb-0">Size</label>
                                        <div class="input-box">
                                            <input type="number" class="form-control modify_gen input" placeholder="16" appInputRestriction formControlName="footerSize" max="70" min="2" maxlength="2" (keypress)="numberOnly($event)" #inputText>
                                        </div>
                                        <!-- <div class="error">{{errorMessage}}</div> -->
                                    </div>
                                </div>
                                <div class="col-sm-4 col">
                                    <div class="form-group text-center">
                                        <label class="customlable mb-0 ">Align</label>
                                        <div class="align">
                                            <button class="submit_button box_button" (click)="alignText('left')">
                                                <img src="assets/components/images/left-align.png" alt="employee">
                                            </button>
                                            <button class="submit_button box_button" (click)="alignText('center')">
                                                <img src="assets/components/images/center-align.png" alt="employee">
                                            </button>
                                            <button class="submit_button box_button" (click)="alignText('right')">
                                                <img src="assets/components/images/right-align.png"
                                                    alt="employee"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-1">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <textarea type="text" class="form-control input-lg inputbox_login customTextBox" [ngStyle]="{'text-align': selectedAlign}" formControlName="footercustomTextBox" appInputRestriction placeholder="FOOTER TEXT - ADD TEXT HERE"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="footer_body pt-2 radiorow">
                            <hr class="linebar">
                            <label class="text-center custom-color-npx">FOOTER CONFIGURABLES</label>
                            <hr class="linebar">

                            <div class="row mt-5 head_config">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label class="customlable mb-0 label_config">FOOTER CONFIGURABLES&nbsp;
                                            <img src="assets/components/images/arrow-right.png" alt="employee">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-2 middle_checkbox">
                                <div class="col-sm-4 col">
                                    <div class="form-group">
                                        <!-- <label class="customlable mb-0">QR Code</label> -->
                                        <div class="checkbox-table accordion-checkbox">
                                            <div class="custom-control custom-checkbox custom-control item">
                                                <input type="checkbox" class="custom-control-input " name="qr_Code" formControlName="qr_Code" id="Allowsome">
                                                <label class="custom-control-label mb-0 label_outset">
                                                    <img src="assets/components/images/qr-code.png" alt="employee"
                                                        class="qr_code">QR Code</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <!-- <label class="customlable mb-0">Surcharge Clause</label> -->
                                        <div class="checkbox-table accordion-checkbox">
                                            <div class="custom-control custom-checkbox custom-control item">
                                                <input type="checkbox" class="custom-control-input " name="serverName" formControlName="serverName" id="Allowsome">
                                                <label class="custom-control-label mb-0 label_outset">Server
                                                    Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <!-- <label class="customlable mb-0">Employee</label> -->
                                        <div class="checkbox-table accordion-checkbox">
                                            <div class="custom-control custom-checkbox custom-control item">
                                                <input type="checkbox" class="custom-control-input " name="surchargeClause" formControlName="surchargeClause" id="Allowsome">
                                                <label class="custom-control-label mb-0 label_outset">Surcharge
                                                    Clause</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row footer_receipt">
                                <div class="col-sm-4 col">
                                    <div class="form-group">
                                        <div class="m-2"><img src="assets/components/images/messenging.png" alt="employee"></div>
                                        <!-- <label class="customlable mb-0">Show Inclusive Tax</label> -->
                                        <div class="checkbox-table accordion-checkbox">
                                            <div class="custom-control custom-checkbox custom-control item">
                                                <input type="checkbox" class="custom-control-input " name="textReceipt" formControlName="textReceipt" id="Allowsome">
                                                <label class="custom-control-label mb-0 label_outset">Text
                                                    Receipt</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col">
                                    <div class="form-group">
                                        <div class="m-2"><img src="assets/components/images/email.png" alt="employee">
                                        </div>
                                        <!-- <label class="customlable mb-0">Show Inclusive Tax</label> -->
                                        <div class="checkbox-table accordion-checkbox">
                                            <div class="custom-control custom-checkbox custom-control item">
                                                <input type="checkbox" class="custom-control-input " name="emailReceipt" formControlName="emailReceipt" id="Allowsome">
                                                <label class="custom-control-label mb-0 label_outset">Email
                                                    Receipt</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-right">
                <div class="form-group">
                    <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral();" [disabled]="buttonDisable ? true : null">
                        <!-- [disabled]="buttonState ? true : null" -->
                        <img src="assets/components/images/save.png" alt="employee" class="">
                        <label class="label_config">Save</label>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
