import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Key } from 'protractor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { ISelectTagDetails, ISelectTagItemDetails } from 'src/app/interfaces/i-tags';
import { IshiftValue } from 'src/app/interfaces/itime';
import { AdminService } from 'src/app/services/admin.service';
import { TagsService } from 'src/app/services/tags.service';

export interface PeriodicElement {
  id: number
  name: string;
  size: string;
  price: number;
  menuPage: string;
  tagsAssign: any;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-fliptable-filter-header',
  templateUrl: './fliptable-filter-header.component.html',
  styleUrls: ['./fliptable-filter-header.component.css']
})
export class FliptableFilterHeaderComponent implements OnInit {
  [x: string]: any;
  TableFilterForm: FormGroup
  displayedColumns: string[] = ['select', 'name', 'size', 'price', 'menuPage', 'tagsAssign'];
  // columns = [
  //   // { columnDef: 'select', header: 'Name' },
  //   { columnDef: 'name', header: 'Item Name' },
  //   { columnDef: 'size', header: 'Size' },
  //   { columnDef: 'price', header: 'Price' },
  //   { columnDef: 'menuPage', header: 'Menu Page' },
  //   { columnDef: 'tagsAssign', header: 'Tag(s) Assigned' }
  // ];
  sizes: string[] = [];//['Small', 'Medium', 'Large', '4 inches', '8 inches', '12 inches'];
  names: string[] = [];//['Burger', 'Desserts', 'Drinks', 'Pizza'];
  prices: string[] = [];//['34', '65', '98', '33'];
  menuPages: string[] = [];//['Main', 'Appetizer'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  sizeFilter = new FormControl();
  nameFilter = new FormControl();
  priceFilter = new FormControl();
  menuPageFilter = new FormControl();
  tagFilter = new FormControl();
  selection = new SelectionModel(true, []);
  TableFilterValue: any = [];
  TablemultiFilterValue: any = [];
  selectTagType: any = 'reports';
  tagDropdownList: any
  multitagDropDownList: any;
  tagmulti: any = []
  tagtypemulti: any = [];
  tagListItem: any = [];
  tagtypeListItem: any = [];
  tagItemList: any = [];
  tableFilterList: any;

  // filteredValues = { select: '', size: '', name: '', price: '', menuPage: '', tagsAssign: '' };
  @Input() tagDropdownItem;
  tagFilters = [];
  tagselectedItems = [];
  tagPrinterList: any;
  getTagType: any;
  tagSettings: IDropdownSettings = {};
  tagGroup: FormControl = new FormControl('');
  loginInfo: any
  multiOption: boolean = false;
  @ViewChild('table') table: MatTable<any>;
  value: any;
  @ViewChild('dataContainer') dataContainer: ElementRef;
  MenuPageArray: any = [];
  MenumultitagArray: any = [];
  tableEnable: boolean = true;
  // TagType:any='reports';
  TableFilterItem: any
  tableArrayItem: any = [];
  TableFilterSize: any
  tableArraySize: any = [];
  TableFilterPage: any
  tableArrayPage: any = [];
  TableFilterPrice: any
  tableArrayPrice: any = [];
  isTagsChecked: boolean;
  @Output() matopen:EventEmitter<any> = new EventEmitter()

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private toaster: ToasterService, private helper: HelperService,
    private tagService: TagsService,  private ObjAdmService: AdminService,private sanitizer: DomSanitizer, private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.TableFilterForm = this.formBuilder.group({
      tagsDropdown: [0],
      tagselectedItems: [null],
      tagFilters: [null],
      tagstypeDropdown: "IAT3",
      TagType: [null],
      PrintDropdown:[0]
    })

    this.getBindPrinterGroup();
    this.getSelectTagItem();
    this.getSelectMultipleTagItem();
    this.getSelectTagItemFilter();
    this.getTagList()
    this.GetTagType('IAT1')
    this.tagSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 2,

    };
    this.isTagsChecked = false;
  }
  matstate($event){
    this.matopen.emit($event)
  }
  GetTagType(masterCode) {
    const  tagList: IshiftValue = {
      parentCode: masterCode
    }
    this.ObjAdmService.GetCodeMaster(tagList)
      .subscribe(res => {
        console.log(res,'gattype');

        this.getTagType = res;


      })
  }

  optionClick(e) {
    let tagItem = e.source.value
    let multitagItem = e.source.value
    this.multitagDropDownList = this.tagPrinterList.filter(y => y.id == tagItem);
    // console.log(this.multitagDropDownList,'multitagDropDownList');

    this.TablemultiFilterValue = []
    if (this.selection.selected.length == 0) {

      this.toaster.hierarchyPopUp("Please select Item to Assign tag")
      this.TablemultiFilterValue.tagsAssign = []
      this.tagtypemulti = []

    } else {
      this.MenumultitagArray = [];
      for (let i = 0; i < this.selection.selected.length; i++) {
        this.TablemultiFilterValue = this.selection.selected[i];
        this.MenumultitagArray.push(this.TablemultiFilterValue.id);
        this.tagtypemulti = this.selection.selected[i].tagsAssign
        if (this.TablemultiFilterValue.tagsAssign != "[]") {
          let itemtagtypemulti1 = {
            value: this.multitagDropDownList[0].id,
            label: this.multitagDropDownList[0].name,
          }
          this.tagtypemulti.push(itemtagtypemulti1)
          const tagtypemulti = Array.from(this.tagtypemulti.reduce((m, t) => m.set(t.label, t), new Map()).values());
          this.selection.selected[i].tagsAssign = tagtypemulti
        } else {
          this.tagtypeListItem = []
          let itemtagmulti2 = {
            value: this.multitagDropDownList[0].id,
            label: this.multitagDropDownList[0].name,
          }
          this.tagtypeListItem.push(itemtagmulti2)
          this.selection.selected[i].tagsAssign = this.tagtypeListItem;
        }
      }
      let _TagsitemDetails;
      _TagsitemDetails = this.TableFilterForm.value;
      _TagsitemDetails.itemId = this.MenumultitagArray.toString();
      _TagsitemDetails.tagId = e.source.value;
      _TagsitemDetails.tagtype = this.selectTagType;
      _TagsitemDetails.tenantId = this.loginInfo.tenantId;
      //   console.log(_TagsitemDetails,'_TagsitemDetails')
      this.tagService.SaveTagItem(_TagsitemDetails).pipe(takeUntil(this.onDestroy$)).subscribe(
        (response) => {
          //  console.log(response,'1response')
          if (response.id == 0) {
            this.isTagsChecked = false;
            this.toaster.errorPopUp(response.message);
            this.TableFilterForm.get('PrintDropdown').reset();
          }
          else {
            this.isTagsChecked = false;
            this.toaster.successPopUp(response.message);
            this.TableFilterForm.get('PrintDropdown').reset();
          }
          this.selection.clear();
          this.getSelectMultipleTagItem();
        }), err => console.error('Error', err), () => console.log('HTTP request completed.')
    }
  }

  getTagList() {
    const deviceList: ISelectTagDetails = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.tagService.SelectTagDetails(deviceList)
      .pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.tagDropdownItem = res.filter(x => x.isdaypart == false && x.tagCategory == 'reports' && x.isActive == true);
        this.tagPrinterList = res.filter(x => x.isdaypart == false && x.tagCategory == 'prints' && x.isActive == true);
        // this.getTagType = res.filter(x => x.visible == true);


        // this.getTagType = [...new Map(this.getTagType.map(item =>
        //   [item['tagCategory'], item])).values()];

     //    console.log(this.tagPrinterList,'tagPrinterList ')
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')

    return this.tagDropdownItem;
  }


  getBindPrinterGroup() {
    const _selecttagname: ISelectTagDetails = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.tagService.SelectTagDetails(_selecttagname).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        var jsonSalesArea = response;
        jsonSalesArea = jsonSalesArea.filter(x => x.isActive == true)
        var itemSalesArea
        var jsonToBeUsedSalesArea = [];
        for (var type in jsonSalesArea) {
          itemSalesArea = {};
          itemSalesArea.key = jsonSalesArea[type].id.toString();
          itemSalesArea.value = jsonSalesArea[type].name;
          jsonToBeUsedSalesArea.push(itemSalesArea);
        }
        this.tagFilters = jsonToBeUsedSalesArea;
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  getSelectTagItem() {
    const _selecttagname: ISelectTagItemDetails = {
      tenantId: this.loginInfo.tenantId,
      itemId: this.nameFilter.value == null ? null : this.nameFilter.value.toString(),
      sizeId: this.sizeFilter.value == null ? null : this.sizeFilter.value.toString(),
      price: this.priceFilter.value == null ? null : this.priceFilter.value.toString(),
      menupagesId: this.menuPageFilter.value == null ? null : this.menuPageFilter.value.toString(),
      tagId: this.tagFilter.value == null ? null : this.tagFilter.value.toString(),

    }

    this.tagService.SelectTagItemTable(_selecttagname).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {

        var jsonSalesArea = response;
        var itemSalesArea
        var jsonToBeUsedSalesArea = [];
        for (var type in jsonSalesArea) {
          itemSalesArea = {};
          itemSalesArea.id = jsonSalesArea[type].id;
          itemSalesArea.name = jsonSalesArea[type].name;
          itemSalesArea.size = jsonSalesArea[type].size;
          itemSalesArea.price = jsonSalesArea[type].price;
          itemSalesArea.menuPage = jsonSalesArea[type].menuPage;
          itemSalesArea.tagsAssign = JSON.parse(jsonSalesArea[type].tagsAssign);
          jsonToBeUsedSalesArea.push(itemSalesArea);
        }

        this.dataSource.data = jsonToBeUsedSalesArea;
        this.tableFilterList = jsonToBeUsedSalesArea;

        //     console.log(this.tableFilterList, 'tableFilterList');

      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  getSelectTagItemFilter() {
    const _selecttagname: ISelectTagItemDetails = {
      tenantId: this.loginInfo.tenantId
    }
    this.tagService.SelectTagItemFilter(_selecttagname).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        var jsonItemDetails = response.ItemDetails;
        var jsonMenuSizes = response.MenuSizes;
        var jsonMenuPrices = response.MenuPrices;
        var jsonMenuPages = response.MenuPages;

        var itemDetails
        var jsonToBeUsedItemDetails = [];
        for (var type in jsonItemDetails) {
          itemDetails = {
            name: jsonItemDetails[type].name,
            id: jsonItemDetails[type].id
          };

          jsonToBeUsedItemDetails.push(itemDetails);
        }
        this.names = jsonToBeUsedItemDetails;

        var MenuSizesDetails
        var jsonToBeUsedMenuSizeDetails = [];
        for (var type in jsonMenuSizes) {
          MenuSizesDetails = {
            name: jsonMenuSizes[type].name,
            id: jsonMenuSizes[type].id,
          };

          jsonToBeUsedMenuSizeDetails.push(MenuSizesDetails);
        }
        this.sizes = jsonToBeUsedMenuSizeDetails;

        var MenuPrizeDetails
        var jsonToBeUsedMenuPrizeDetails = [];
        for (var type in jsonMenuPrices) {
          MenuPrizeDetails = {
            price: jsonMenuPrices[type].price,
            id: jsonMenuPrices[type].id,

          };
          jsonToBeUsedMenuPrizeDetails.push(MenuPrizeDetails);
        }
        this.prices = jsonToBeUsedMenuPrizeDetails;

        var MenuPageDetails
        var jsonToBeUsedMenuPageDetails = [];
        for (var type in jsonMenuPages) {
          MenuPageDetails = {
            name: jsonMenuPages[type].name,
            id: jsonMenuPages[type].id

          };
          jsonToBeUsedMenuPageDetails.push(MenuPageDetails);
        }
        this.menuPages = jsonToBeUsedMenuPageDetails;

      })
  }


  getTagValues(e?) {
    let tagItem = e.value
    let multitagItem = e.value

    this.tagDropdownList = this.tagDropdownItem.filter(y => y.id == tagItem);

    this.TableFilterValue = []

    if (this.selection.selected.length == 0) {
      this.toaster.hierarchyPopUp("Please select Item to Assign tag")

      this.TableFilterValue.tagsAssign = []
      this.tagmulti = []

    } else {

      this.MenuPageArray = [];
      for (let i = 0; i < this.selection.selected.length; i++) {

        this.TableFilterValue = this.selection.selected[i];

        this.MenuPageArray.push(this.TableFilterValue.id);

        this.tagmulti = this.selection.selected[i].tagsAssign

        if (this.TableFilterValue.tagsAssign != "[]") {
          let itemtagmulti1 = {
            value: this.tagDropdownList[0].id,
            label: this.tagDropdownList[0].name,
          }
          this.tagmulti.push(itemtagmulti1)

          const itemtagmulti = Array.from(this.tagmulti.reduce((m, t) => m.set(t.label, t), new Map()).values());

          this.selection.selected[i].tagsAssign = itemtagmulti
        } else {
          this.tagListItem = []
          let itemtagmulti2 = {
            value: this.tagDropdownList[0].id,
            label: this.tagDropdownList[0].name,
          }
          this.tagListItem.push(itemtagmulti2)

          this.selection.selected[i].tagsAssign = this.tagListItem;

        }

      }

      let _TagsitemDetails;
      _TagsitemDetails = this.TableFilterForm.value;
      _TagsitemDetails.itemId = this.MenuPageArray.toString();
      _TagsitemDetails.tagId = e.value;
      _TagsitemDetails.tagtype = this.selectTagType;
      _TagsitemDetails.tenantId = this.loginInfo.tenantId;
      //  console.log(_TagsitemDetails,'_ReportTagsitemDetails')
      this.tagService.SaveTagItem(_TagsitemDetails).pipe(takeUntil(this.onDestroy$)).subscribe(
        (response) => {
          //this.id = response.id;
          //this.tags_general.emit(response.id);

          if (response.id == 0) {
            this.isTagsChecked = false;
            this.toaster.errorPopUp(response.message);
            this.TableFilterForm.get('tagsDropdown').reset();
          }
          else {
            this.isTagsChecked = false;
            this.toaster.successPopUp(response.message);
            this.TableFilterForm.get('tagsDropdown').reset();
          }
          this.selection.clear();
          this.getSelectTagItem();
        }), err => console.error('Error', err), () => console.log('HTTP request completed.')
    }
  }



  onCloseTag(index, Item) {
    // for(let i = 0; i < this.dataSource.data.length; i++){
    this.tagItemList = this.dataSource.data.filter(y => y.id == index);
    this.tagItemList[0].tagsAssign.forEach((value, i) => {
      if (value.value == Item.value) {
        this.tagItemList[0].tagsAssign.splice(i, 1);


        let _TagsitemDetails;
        _TagsitemDetails = this.TableFilterForm.value;
        _TagsitemDetails.itemId = index;
        _TagsitemDetails.tagId = Item.value;
        this.tagService.ActivateTagItemDetails(_TagsitemDetails).pipe(takeUntil(this.onDestroy$)).subscribe(
          (response) => {
            //this.id = response.id;
            //this.tags_general.emit(response.id);
            this.isTagsChecked = false;
            this.toaster.successPopUp(response.message);
            this.getSelectTagItem();
          }), err => console.error('Error', err), () => console.log('HTTP request completed.')
      }
    });

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
    // this.getTagValues()
  }

  isAllChecked(e){
    if(e.checked == true){
      this.isTagsChecked = true;
    }
    else{
      this.isTagsChecked = false;
    }
  }

  isCheckNumb(e){
    if(e.checked == true && this.selection.selected.length == 0){
      this.isTagsChecked = true;
    }
    else if(e.checked == true){
      if(this.selection.selected.length > 0){
        this.isTagsChecked = true;
    }
    else{
      this.isTagsChecked = false;
    }
  }
  else if(e.checked == false){
    if(this.selection.selected.length-1 > 0){
      this.isTagsChecked = true;
  }
  else{
    this.isTagsChecked = false;
  }
  }
}
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  tagtype(e) {

    if ( e.source.triggerValue=='prints')
    {
      this.multiOption =true //e.value;
    }
    else  if ( e.source.triggerValue=='reports')
    {
      this.multiOption =false //e.value;
    }

    this.selectTagType = e.source.triggerValue;
     console.log(e, 'this.tagstypeDropdown');
  }
  getSelectMultipleTagItem() {
    var multipletag = this.tagPrinterList;
    var multipletagitems
    var jsonToBeUsedmultipletags = [];


  }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
