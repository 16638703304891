<div class="row top-sec">
  <div class="col-sm-4">
    <div>
      <div class="flip-table" *ngIf="!modifierItemsData">
        <span class="text-box">{{employee}}</span> &nbsp;
        <button class="plus_btn outshadow" (click)="ClearData()"><i class="fa fa-plus"></i></button>
      </div>
      <hr class="linebar" *ngIf="!modifierItemsData">
    </div>
  </div>
  <div class="col-sm-8">
    <div class="form-group global-form">
      <button [ngClass]="{'submit_button_error': isInvalid==true}"
        class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="OnSave();">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label></button>
    </div>
  </div>

</div>

<div class="row second-sec" [formGroup]="TaxMasterForm">
  <div class="col-sm-4">
    <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns"
      [dealerItem]="tableRowColor" (tableItemId)="getTableIdx($event)" (tableEmpName)="getTableName($event)"
      [employeeItem]="tableRowColor" (breakActive)="checkValue($event)" [EmployeeTableData]="EmployeeTableData"
      [DataCom]="employee" (clearDetails)="ClearData($event)" (btnActive)="btnActiveStatus($event)"></app-fliptable>
  </div>
  <div class="col-sm-8">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst" [expanded]="isFirst" class="outshadow">
        <mat-expansion-panel-header>
          <mat-panel-title> {{item}} </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="item == 'General'">
          <app-tax-master-general [DataIdx]="tableRowId" (generaltax)="getGeneralDetail($event)"
            [buttonState]="buttonDisable" (taxmasterData_child)="getmnuitmsessData($event)"></app-tax-master-general>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>