<form [formGroup]="ModifierGeneralForm" class="loginForm" (change)="onchange(ModifierGeneralForm.value)">
    <div class="row">
        <div class="col-sm-4 col-12">
            <div class="form-group">
                <label class="mb-0 label-width">Name</label><label class="text-danger">*</label>
                <input type="text" formControlName="name" class="form-control input-lg inputbox_login" appInputRestriction placeholder="Name" [ngClass]="{ 'is-invalid': (submittedGeneral && SavegeneralGroup.name.errors) || (requiredError && SavegeneralGroup.name.errors)}"
                    maxlength="50" (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="MenuModifierValid()" />
                <div class="error">{{errorValue}}</div>
                <div *ngIf="(submittedGeneral && SavegeneralGroup.name.errors) || (requiredError && SavegeneralGroup.name.errors)" class="invalid-feedback">
                    <div *ngIf="SavegeneralGroup.name.errors.required">Name is required</div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-12">
            <div class="form-group">
                <label class="mb-0 label-width">Category</label><label class="text-danger">*</label>
                <!-- <select class="custom-select form-control"  formControlName="Category" >
                    <option selected>Select</option>
                    <option value="Modifier">Modifier</option>
                    <option value="Side">Side</option>
                </select> -->
                <mat-form-field appearance="fill" [ngClass]="{ 'is-invalid': (submittedGeneral && SavegeneralGroup.Category.errors) || (requiredError && SavegeneralGroup.Category.errors)}" (focusout)="MenuModifierValid()">
                    <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="Category" (selectionChange)="MenuModifierValid()">
                        <mat-option *ngFor="let item of Category" [value]="item.typeName">{{item.typeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="(submittedGeneral && SavegeneralGroup.Category.errors) || (requiredError && SavegeneralGroup.Category.errors)" class="invalid-feedback">
                    <div *ngIf="SavegeneralGroup.Category.errors.required">Category is required</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-2 col-12">
            <div class="form-group">
                <label class="mb-0 label-width">Min Choices</label>
                <!-- <div class="custom-control custom-checkbox custom-control item">
                        <input type="checkbox" class="custom-control-input " name="customCheckbox"
                            formControlName="Min_Choices" id="MinChoices">
                        <label class="custom-control-label" for="MinChoices"></label>
                    </div> -->
                <input type="text" class="form-control modify_gen" placeholder="0-99" formControlName="Min_Choices" (keypress)="numberOnly($event)" (keydown)="checkInput($event, inputText)" #inputText (input)="onChangeMinEvent($event)" (focusout)="onChangeMinEvent($event)"
                    (keypress)="Highlight()" appInputRestriction>
                <div class="error">{{errorMessage}}</div>
                <!-- <input type="number" formControlName="Min_Choices" class="form-control input-lg inputbox_login"> -->
            </div>
            <div class="form-group">
                <label class="mb-0 label-width">Max Choices</label>
                <!-- <div class="custom-control custom-checkbox custom-control item">
                        <input type="checkbox" class="custom-control-input " name="customCheckbox"
                            formControlName="Max_Choices" id="MaxChoices">
                        <label class="custom-control-label" for="MaxChoices"></label>
                    </div> -->
                <input type="text" class="form-control modify_gen" placeholder="0-99" formControlName="Max_Choices" (keypress)="numberOnly($event)" (keydown)="checkInput($event, inputText1)" #inputText1 (input)="onChangeMaxEvent($event)" (focusout)="onChangeMaxEvent($event)"
                    appInputRestriction>
                <div class="error">{{errorMessage1}}</div>
            </div>
            <div class="form-group">
                <label class="mb-0 label-width">Free Choices</label>
                <!-- <div class="custom-control custom-checkbox custom-control item">
                        <input type="checkbox" class="custom-control-input " name="customCheckbox"
                            formControlName="FreeChoices" id="FreeChoices">
                        <label class="custom-control-label" for="FreeChoices"></label>
                    </div> -->
                <input type="text" class="form-control modify_gen" placeholder="0" formControlName="FreeChoices" (keypress)="numberOnly($event)" appInputRestriction>
            </div>

        </div>
    </div>
    <div class="row mt-2">
        <div class="col-sm-4">
            <div class="form-group">
                <div class="checkbox-table accordion-checkbox">
                    <div class="custom-control custom-checkbox custom-control item">
                        <input type="checkbox" class="custom-control-input " name="customCheckbox" formControlName="IsAllow_SameItem" id="Allowsome">
                        <label class="custom-control-label mb-0 label-pad label-width">Allow same items to be Chosen</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group mt-2">
        <button class="btn btn-sm float-right submit_button button-none" type="submit" data-toggle="modal" (click)="OnSaveModifierGroup();">
                <!-- [disabled]="buttonState ? true : null" -->
            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label>
        </button>
    </div>
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="Communication_tab m-2">
                    <div class="remove_tab m-3">

                        <div class="modal-header">
                            <div class="text-right">
                                <button type="button" class="btn-close close" aria-label="Close" data-dismiss="modal">
                  <span aria-hidden="true" class="visually-hidden">&times;</span>
                </button>
                            </div>
                        </div>

                        <div class="modal-body text-center p-0">

                            <p>The min choice cannot be lesser than the item assigned.</p>
                            <div class="mt-2">
                                <label> Do you want to reset..!</label>
                            </div>
                        </div>

                        <div class="modal-footer text-center">
                            <button type="button" class="btn  btn-sm" data-dismiss="modal" [disabled]="buttonDisable ? true : null">
                <img src="assets/components/images/confirm-button.png" alt="employee" width="85%">
              </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
