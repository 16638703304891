import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';;

@Component({
  selector: 'app-gift-card-front',
  templateUrl: './gift-card-front.component.html',
  styleUrls: ['./gift-card-front.component.css']
})
export class GiftCardFrontComponent implements OnInit {

  fileInfo: string;
  @Input() public customLogoText;
  @Input() public url;
  @Input() public imgFormat;
  @Input() public giftSlogan;
  format: string;

  @Input() public whiteBg;
  @Output() public fileName = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  displayFile(logoUrl: any) {
    this.url = logoUrl;
    this.format = 'url'
  }
  onSelectFile(event) {

    var file = event.target.files && event.target.files[0];
    this.fileInfo = file;
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if (file.type.indexOf('image') > -1) {
        this.format = 'image';
      }
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
      }
    }

  }


  displayFileName(url) {
    if (url == undefined || url == null) {
      this.url = this.fileInfo;
      this.format = 'image';
    }
    else {
      this.url = url;
      this.format = 'image';
    }
  }

  @Input()
  public set DataLogoText(customLogoText) {
    this.customLogoText = customLogoText;
  }

  @Input()
  public set DataImg(url) {
    // this.url = url;
  }

  @Input()
  public set DataCustomSmallText(giftSlogan) {
    this.giftSlogan = giftSlogan;
  }
  @Input()
  public set CheckBackground(whiteBg) {
    this.whiteBg = whiteBg;
  }
}
