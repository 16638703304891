import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import { BaseService } from '../base/base.service';
import { Router } from '@angular/router';
import { GetAllUserList, IActiveUser } from '../interfaces/iadmin';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) { }

  GetAllEmployees(employee: GetAllUserList) {
    return this.base.post('Employee/GetAllEmployees', employee).pipe(map((response: any) => {
      return response;
    }));
  }
  GetAllHierachy(hierachy: GetAllUserList) {
    return this.base.post('Admin/GetTenantDetails', hierachy).pipe(map((response: any) => {
      return response;
    }));
  }
  IsActiveUser(isActive: IActiveUser) {
    return this.base.post('Admin/ReActivateTenant', isActive).pipe(map((response: any) => {
      return response;
    }));
  }
}
