<form
  class="loginForm form-switch GeneralForm"
  [formGroup]="BreakForm"
  (change)="onchange(BreakForm.value)"
>
  <div class="Gen_wrap">
    <div class="row w-100">
      <div class="col-sm-3">
        <div class="form-group">
          <label class="customlable mb-0">Name</label>
          <label class="text-danger">*</label>
          <input
            type="text"
            class="form-control input-lg inputbox_login"
            formControlName="name"
            appInputRestriction
            placeholder="Break type"
            [ngClass]="{
              'is-invalid':
                (submitted && breakData.name.errors) || requiredErrors
            }"
            maxlength="50"
            (input)="getMaxValue($event)"
            (keypress)="Highlight()"
            (focusout)="EmployeeGeneralValid()"
          />
          <div class="error">{{ errorValue }}</div>
          <div
            *ngIf="(submitted && breakData.name.errors) || requiredErrors"
            class="invalid-feedback"
          >
            <div *ngIf="breakData.name.errors.required">Name is required</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-sm-3">
        <div class="form-group">
          <div class="checkbox-table">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input active"
                name="customCheckbox"
                id="isPaidBreak"
                formControlName="isPaidBreak"
              />
              <label
                class="custom-control-label custom-break-label active label-pad"
                for="isPaidBreak"
                >is Paid Break?</label
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 cc-disp-none">
        <div class="form-group">
          <div class="checkbox-table">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input active"
                name="customCheckbox"
                id="logBook"
                formControlName="logBook"
              />
              <label
                class="custom-control-label custom-break-label active"
                for="logBook"
                >Log Book (Phase 2)</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-sm-3">
        <div class="form-group">
          <div class="checkbox-table">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input active"
                name="customCheckbox"
                id="isRequired"
                formControlName="isRequired"
              />
              <label
                class="custom-control-label custom-break-label active label-pad"
                for="isRequired"
                >is Required?</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="checkbox-table">
            <div class="custom-control custom-checkbox col-sm-3 min-time-class">
              <input
                type="checkbox"
                class="custom-control-input active"
                name="customCheckbox"
                id="minimumTime"
                formControlName="minimumTime"
              />
              <label
                class="custom-control-label custom-break-label active label-pad"
                for="minimumTime"
                >Minimum Time?</label
              >
            </div>
            <div
              class="disp-mins col-sm-5"
              *ngIf="BreakForm.get('minimumTime').value"
            >
              <input
                type="text"
                (keypress)="numberOnly($event)"
                formControlName="minutes"
                class="form-control input-lg inputbox_login cust-txt"
              />
              <span class="pl-2 span-disp">Minutes</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 cc-disp-none">
      <div class="col-sm-6">
        <div class="form-group">
          <div class="checkbox-table">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input active"
                name="customCheckbox"
                id="textWarning"
                formControlName="textWarning"
              />
              <label
                class="custom-control-label custom-break-label active"
                for="textWarning"
                >Give Warning Time Via Text? (Phase 2)</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <button
        class="btn btn-sm float-right submit_button button-none"
        type="submit"
        (click)="onSaveBreak()"
        [disabled]="buttonDisable ? true : null"
      >
        <!-- [disabled]="buttonState ? true : null" -->
        <img src="assets/components/images/save.png" alt="employee" class="" />
        <label class="label_config">Save</label>
      </button>
    </div>
  </div>
</form>
