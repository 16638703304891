import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { DragDrop } from 'src/app/interfaces/imenu';
@Component({
  selector: 'app-drag-drop-builder',
  templateUrl: './drag-drop-builder.component.html',
  styleUrls: ['./drag-drop-builder.component.css']
})


export class DragDropBuilderComponent implements OnInit {
  buttonDisable: boolean = false;
  public dataSource = new MatTableDataSource<any>();
  public dataSourceTableItems: any;
  public IngrdientItem: any = [];
  public displayedColumns = ['name'];
  public variables: any = []
  public SalesAreaItem: any;
  targetBuilderTools: any = [];
  targetBuilderTools1: any = [];
  targetBuilderTools2: any = [];
  builderJson: any;

  loginInfo: any;
  modifierid: any;
  jsonDisplayCounter: any = 6;
  tempData: any = [];
  public dropCounter: any = 0;
  public FinalDropCounter: any = 0;
  hideField: any;
  searchValue;
  sourceBuilderTools: any;
  flag: boolean = true;
  public dragdropflip: any;
  currentItem: any;
  dropItemList: any;
  dropItemListarray: any = [];
  @Output() public layoutSave = new EventEmitter();
  @Output() public menulayout_child = new EventEmitter();
  @Output() public isDropEvent = new EventEmitter();

  //filteredItemList: any = [];
  //public variablesTZ: any = [];
  //filterNodataTZ: any;
  SelectedPageVal: any = "";
  @Input() globalVal
  @Input() saveId: any;

  @ViewChild('tableItems') table: MatTable<any>;
  constructor(private builderService: MenuService, private helper: HelperService, private toaster: ToasterService) { }

  ngOnInit(): void {
    this.searchValue = ''
    this.helper.setValue('OnDirty3', false);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    if (this.flag) {
      this.builderService.getSourceBuilder().subscribe(
        (data) => {
          this.targetBuilderTools = data;
        });
    }
    this.helper.setValue('fisrtDropItem', null);;

  }



  @Input()
  public set SelectedPageInd(SelectedPage) {
    if (SelectedPage != undefined && SelectedPage != null && SelectedPage != "") {
      this.SelectedPageVal = SelectedPage;
    }
  }

  @Input()
  public set MenuRowItem(tableRowData) {
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      this.modifierid = tableRowData;
    }
  }

  @Input()
  public set ItemLayout(ItemData) {
    if (ItemData != undefined && ItemData != null && ItemData != "") {
      if (ItemData.length > 0)
        this.flag = false;
      this.targetBuilderTools = ItemData;
    }
    else {
      this.builderService.getSourceBuilder().subscribe(
        (data) => {
          this.targetBuilderTools = data;
        });
    }
  }

  @Input()
  public set salesAreabit(salesAreabit) {
    this.dragdropflip = salesAreabit;
  }

  @Input()
  public set ItemList(ItemListVal) {
    this.helper.setValue('fisrtDropItem', null);
    this.dataSourceTableItems = ItemListVal;
    if (ItemListVal != undefined && ItemListVal != null && ItemListVal != "") {
      this.variables = this.dataSourceTableItems.slice();
    }
  }

  @Input()
  public set datamatrixItem(matrix) {
    this.hideField = matrix
  }

  @Input() set dropdata(data) {
    this.dropItemList = data
    this.OnModifierDrop();
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  builderDrag(event: any) {
  }

  //remove the outside row click
  removeJson1(valueIndex, deletedIndex) {
    this.helper.setValue('OnDirty3', true);
    this.helper.setValue('SessionPageName', this.SelectedPageVal);
    let datatable = this.dataSourceTableItems
    let jsonobj = valueIndex.splice(deletedIndex, 1);
    if (jsonobj.length > 0) {
      if (jsonobj[0].children.length > 0)
        datatable.push(jsonobj[0].children[0])

      if (jsonobj[0].children1.length > 0)
        datatable.push(jsonobj[0].children1[0])
      if (jsonobj[0].children2.length > 0)
        datatable.push(jsonobj[0].children2[0])
      if (jsonobj[0].children3.length > 0)
        datatable.push(jsonobj[0].children3[0])
      if (jsonobj[0].children4.length > 0)
        datatable.push(jsonobj[0].children4[0])
      this.dataSourceTableItems = datatable;
      this.table.renderRows();
    }
  }
  // remove element click in drag and drop place holder
  removeJson(valueIndex, deletedvalue) {
    this.isDropEvent.emit(true);
    console.log(deletedvalue, 'deletedvalue')
    this.helper.setValue('OnDirty3', true);
    this.helper.setValue('SessionPageName', this.SelectedPageVal);
    valueIndex.splice(deletedvalue, 1);
    this.builderJson = deletedvalue

    if (this.dataSourceTableItems.filter(x => x.id == deletedvalue.id).length > 0) {

    }
    else {
      // this.variables 
      delete this.variables[this.variables.findIndex(item => item.id == deletedvalue.id)];

      this.dataSourceTableItems.push(this.builderJson);
      this.variables = this.dataSourceTableItems;
      this.IngrdientItem.splice(this.IngrdientItem.indexOf(deletedvalue.id), 1);
      this.table.renderRows();

    }
    this.dropCounter--;

    //console.log(this.IngrdientItem, 'this.Remove');
    this.helper.setValue('GetSessionData3', JSON.stringify(this.targetBuilderTools));
  }

  removeMenuModifierJson(valueIndex, deletedvalue) {
    this.isDropEvent.emit(true);
    console.log(deletedvalue, 'deletedvalue')
    this.helper.setValue('OnDirty3', true);
    this.helper.setValue('SessionPageName', this.SelectedPageVal);
    valueIndex.splice(deletedvalue, 1);
    this.builderJson = deletedvalue

    if (this.dataSourceTableItems.filter(x => x.id == deletedvalue.id).length > 0) {

    }
    else {
      // this.variables 
      delete this.variables[this.variables.findIndex(item => item.id == deletedvalue.id)];

      this.dataSourceTableItems.push(this.builderJson);
      this.variables = this.dataSourceTableItems;
      this.IngrdientItem.splice(this.IngrdientItem.indexOf(deletedvalue.id), 1);
      this.table.renderRows();

    }

    this.helper.setValue('GetSessionData3', JSON.stringify(this.targetBuilderTools));
  }

  addLayoutMulti(e: any) {

    this.sourceBuilderTools = {
      "children": [],
      "children1": [],
      "children2": [],
      "children3": [],
      "children4": [],
      "Display": [this.jsonDisplayCounter++]
    };
    this.targetBuilderTools.push(this.sourceBuilderTools);
  }


  drop(event) {

    this.isDropEvent.emit(true);

    if (event.length > 1) {
      //this.currentItem=event.filter(item => item.id !==  this.helper.getValue('fisrtDropItem')[0].id);

      //  console.log(this.currentItem,'DropItem')
      const dropItem: DragDrop = {
        id: event[0].id,
        name: event[0].name
      }
      this.removeMenuModifierJson(event, dropItem);
      //  console.log(dropItem,'dropItem')
      this.toaster.errorPopUp('Drop is not possible in this section.');
      //   console.log(event[0], 'Current')
      this.helper.setValue('fisrtDropItem', event[0]);
    }
    else {
      this.helper.setValue('GetSessionData3', JSON.stringify(this.targetBuilderTools));
      this.helper.setValue('OnDirty3', true);
      this.helper.setValue('SessionPageName', this.SelectedPageVal);
      this.helper.setValue('fisrtDropItem', event);;

      this.dropCounter++;

      //console.log( this.tempData,' this.tempData')
      this.dataSourceTableItems = this.dataSourceTableItems.filter(item => item.id !== event[0].id);


      this.variables = [...this.dataSourceTableItems, ...this.tempData];

      this.IngrdientItem.push(event[0].id)
      this.table.renderRows();

    }

  }


  OnModifierDrop() {
    for (let i = 0; i < this.dropItemList.length; i++) {
      this.dropItemListarray = this.dropItemList[i]
      for (let j = 0; j < this.dropItemListarray.length; j++) {
        this.targetBuilderTools1 = this.dropItemListarray[j];
        this.targetBuilderTools.push(this.dropItemListarray[j])
      }
    }
  }

  OnSave(e) {
    this.layoutSave.emit({ id: this.modifierid, itemObject: JSON.stringify(this.targetBuilderTools) });
  }

  LayoutCounterMethod(initialVal) {
    this.FinalDropCounter = initialVal + this.dropCounter;
    return this.FinalDropCounter;
  }

  onReciepSearch(e?) {

    this.dataSourceTableItems = this.searchItems(e);
    this.tempData = this.variables.filter(x => !this.dataSourceTableItems.includes(x))

  }

  searchItems(value: string) {
    let filteredListTZ = this.variables.filter((unit) =>
      unit.name.toUpperCase().includes(value.toUpperCase()));
    if (filteredListTZ.length == 0) {
      return [...filteredListTZ];
    }
    return filteredListTZ.sort((a, b) => 0 - (a.name > b.name ? -1 : 1)); // asending order
  }

  handleInput(event: KeyboardEvent): void {
    event.stopPropagation();
  }
}