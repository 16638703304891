import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/helper/toaster.service';
import { PaymentService } from 'src/app/services/payment.service';
import { HelperService } from 'src/app/helper/helper.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-house-accounts-logitics',
  templateUrl: './house-accounts-logitics.component.html',
  styleUrls: ['./house-accounts-logitics.component.css']
})
export class HouseAccountsLogiticsComponent implements OnInit {
  HouseLogisticForm: FormGroup;
  submittedGeneral = false;
  loginInfo: any = 0;
  id: any = 0;
  houseAccountGenId: any = 0;
  buttonDisable: boolean = false;

  @Output() public HouseAccLogData_child = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private dialogser: DialogService,
    private toaster: ToasterService, private objPayment: PaymentService) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData2', null);
    this.buttonDisable = false;
    this.HouseLogisticForm = this.formBuilder.group({
      id: [0],
      plu_CardNumber_Code: [],
      required: [],
      rollUpPayment: [],
      allowTipping: [],
      overrideTipApportions: [],
      requireSignature: [],
      openCashDrawer: [],
      requireReferenceCode: [],
      customPrefix: [],
      email: [],
      printAdditionalTipLine: []
    })
    this.loginInfo = this.helper.getValue('LoginInfo');
  }

  get GeneralData() { return this.HouseLogisticForm.controls; }

  //@Input() public buttonState;
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set tableRowIdx(tableRowID) {
    if (tableRowID != undefined && tableRowID != null && tableRowID != "") {
      this.houseAccountGenId = tableRowID; // regererence of gerenal table 
    }
  }

  @Input()
  public set MenuRowItem(tableRowData) {
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      this.HouseLogisticForm.patchValue(tableRowData.General[0]);
      this.houseAccountGenId = tableRowData.General[0].id; // regererence of gerenal table 
      this.id = tableRowData.General[0].houseLogisticID;   // primery ket og logistic table
    }
  }

  onSaveGeneral(houseId) {
    this.submittedGeneral = true;
    if (this.HouseLogisticForm.invalid) {
      return;
    }
    this.houseAccountGenId = houseId;
    this.HouseLogisticForm.value.id = this.id;
    this.HouseLogisticForm.value.houseAccountGenId = this.houseAccountGenId;
    this.HouseLogisticForm.value.createdBy = this.loginInfo.userId;
    this.HouseLogisticForm.value.tenantId = this.loginInfo.tenantId;
    this.onSaveCompleteForm(this.HouseLogisticForm.value);
  }

  onSaveCompleteForm(HouseAccountLogistic): void {
    this.objPayment.SaveHouseAccountLogistic(HouseAccountLogistic).subscribe(
      async (response) => {
        this.id = response.id;
        this.toaster.successPopUp(response.message);
        this.submittedGeneral = false;
        this.helper.setValue('GetSessionData2', HouseAccountLogistic);
        this.HouseAccLogData_child.emit({ data: HouseAccountLogistic, event: "HouseAccounts", val: false });
      })
  }

  ngAfterViewInit() {
    this.HouseLogisticForm.patchValue(this.helper.getValue('GetSessionData2'));
    this.HouseAccLogData_child.emit(this.HouseLogisticForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData2', data);
    this.helper.setValue('OnDirty', true);
    this.HouseAccLogData_child.emit({ data: data, event: "HouseAccounts", val: true });
  }
  toggleStyle: boolean = false;

  toggle() {
    console.log(this.toggleStyle);
    this.toggleStyle = !this.toggleStyle;
  }
}