<div class="pop-up-bg">
<div class=" text-right">
  <button type="button" class="btn-close close" aria-label="Close" (click)="bsModalRef.hide()" (click)="cancleBox()">
    <span aria-hidden="true" class="visually-hidden" style="padding-right:12px">&times;</span>
  </button>
</div>
<div class="mt-2 save-cancel-popup">
  <label class="txt-color">Changes will be lost! Do you want to navigate away from this current page?.</label>
</div>
<div class="notify-footer modal-footer text-center">
  <button md-button class="button-style navigate-btn" (click)="confirmBoxData()">Navigate Away</button>
  <button md-button class="button-style" (click)="cancleBox()">Cancel</button>
</div>
</div>