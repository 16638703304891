import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { iSelectCreditCard } from 'src/app/interfaces/icustompayment';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-submit-parameters',
  templateUrl: './submit-parameters.component.html',
  styleUrls: ['./submit-parameters.component.css'],
})
export class SubmitParametersComponent implements OnInit {
  id: any = 0;
  emvValue: any;
  loginInfo: any;
  tranCloud: any;
  submittedGeneral = false;
  SubmitParameterForm: FormGroup;
  @Output() public nextSubmitData = new EventEmitter();
  constructor(
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private objPayment: PaymentService,
    private helper: HelperService
  ) {}

  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.SubmitParameterForm = this.formBuilder.group({
      businessName: ['', Validators.required],
      phone: [''],
      contactFirstName: ['', Validators.required],
      contactLastName: ['', Validators.required],
      contactEmail: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      platformPulldown: ['', Validators.required],
      gatewaySelection: [''],
      emv: ['', Validators.required],
      olo: ['', Validators.required],
      tranCloud: ['', Validators.required],
      noofdevices: ['', Validators.required],
      totalPassword: ['', Validators.required],
      tranCloudId: [''],
      prePaidGift: [''],
      cardPresent: [''],
      cardNotPresent: [''],
    });
  }

  @Input() public buttonState;
  @Input()
  public set MenuRowItem(tableRowData) {
    if (
      tableRowData != undefined &&
      tableRowData != null &&
      tableRowData != ''
    ) {
      this.SubmitParameterForm.patchValue(tableRowData[0]);
      this.id = tableRowData[0].id;
      this.emvValue = '';
      if (tableRowData[0].swipe == true) {
        this.emvValue = this.emvValue + 'Swipe ';
      }
      if (tableRowData[0].emv == true) {
        this.emvValue = this.emvValue + 'EMV ';
      }
      if (tableRowData[0].app == true) {
        this.emvValue = this.emvValue + 'App ';
      }
      if (tableRowData[0].olo == true) {
        this.emvValue = this.emvValue + 'OLO ';
      }
      if (tableRowData[0].kiosk == true) {
        this.emvValue = this.emvValue + 'Kiosk ';
      }
    }
  }
  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.addEmployee();
    }
  }
  public addEmployee(): void {
    this.SubmitParameterForm.reset(); // should reset the form in child component
  }
  get GeneralData() {
    return this.SubmitParameterForm.controls;
  }

  onSaveGeneral() {
    this.submittedGeneral = true;
    if (this.SubmitParameterForm.invalid) {
      this.toaster.errorPopUp('Please fill out the previous step fields');
      return;
    }
    this.nextSubmitData.emit(this.submittedGeneral);
    this.toaster.successPopUp('Data is submitted successfully.');
  }

  GetCreditCardDetails(event) {
    //this.ngOnInit();
    const CreditCardList: iSelectCreditCard = {
      id: event,
      tenantId: this.loginInfo.tenantId,
    };
    this.objPayment.EditCreditCard(CreditCardList).subscribe((res) => {
      console.log(res, 'Respoanse');
      this.SubmitParameterForm.patchValue(res);
    });
  }
}
