import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { IRole } from 'src/app/interfaces/iadmin';
import { AdminService } from 'src/app/services/admin.service';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DialogService } from 'src/app/services/dialog.service';

//---------------------------------Calendar Format Beginning---------------------------------//
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
//---------------------------------Calendar Format End---------------------------------//

@Component({
  selector: 'app-tax-master-general',
  templateUrl: './tax-master-general.component.html',
  styleUrls: ['./tax-master-general.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class TaxMasterGeneralComponent implements OnInit {
  TaxMasterGenForm: FormGroup;
  errorMessage: any;
  buttonDisable: boolean = false;
  id: any = 0;
  submittedGeneral: any;
  loginInfo: any;
  isValidated = false;
  maxDate = new Date();
  @Input() public tableRowId;
  @Output() public generaltax = new EventEmitter();
  @Output() public taxmasterData_child = new EventEmitter();
  isInvalid: boolean = false;
  dateMgs: any;
  minDate: any;
  errorValue: string;

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private adminser: AdminService,
    private toaster: ToasterService, private dialogser: DialogService) { }

  ngOnInit(): void {
    this.minDate = new Date(1900, 0, 1);
    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.TaxMasterGenForm = this.formBuilder.group({
      name: ['', Validators.required],
      taxType: [''],
      rate: [''],
      Effecteddate: [''],
    })
  }

  get GeneralData() { return this.TaxMasterGenForm.controls; }

  @Input()
  public set DataIdx(tableRowId) {
    if (tableRowId != undefined && tableRowId != null) {
      this.id = tableRowId;
      if (this.id > 0) {
        this.EditTaxMaster(tableRowId);
      }
    }
  }

  numberOnly(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);
    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;

  }

  EditTaxMaster(rowId) {
    const taxList: IRole = {
      id: rowId,
      tenantId: this.loginInfo.tenantId
    }
    this.adminser.GetTaxMaster(taxList)
      .subscribe(res => {
        if (res.length > 0) {
          this.id = res[0].id == null ? 0 : res[0].id;
          this.TaxMasterGenForm.patchValue(res[0]);
          this.TaxMasterGenForm.patchValue({ Effecteddate: res[0].Effecteddate == null ? '' : moment(res[0].Effecteddate).format('YYYY-MM-DD') });

          // this.TaxMasterGenForm.patchValue({
          //   company: this.loginInfo.tenantId,
          // });
        }
      })
  }

  onSaveGeneral() {
    this.submittedGeneral = true;
    if (this.TaxMasterGenForm.value.Effecteddate == undefined || this.TaxMasterGenForm.value.Effecteddate == null || this.TaxMasterGenForm.value.Effecteddate == "") {
      this.toaster.errorPopUp("Please Select the Effective Date");
      return;
    }
    if (this.TaxMasterGenForm.invalid) {
      if (this.TaxMasterGenForm.controls.Effecteddate.invalid) {
        this.dateMgs = 'Invalid Date';
        return;
      }
      else {
        this.dateMgs = ''
      }
      return;
    }

    if (this.TaxMasterGenForm.value.Effecteddate == "" || this.TaxMasterGenForm.value.Effecteddate == null) {
      this.TaxMasterGenForm.value.Effecteddate = null
    }
    else {
      this.TaxMasterGenForm.value.Effecteddate = moment(new Date(this.TaxMasterGenForm.value.Effecteddate)).format('YYYY-MM-DD');
    }

    let adjserv;
    adjserv = this.TaxMasterGenForm.value;
    adjserv.createdBy = this.loginInfo.userId;
    adjserv.modifiedBy = this.loginInfo.userId;
    adjserv.isActive = true;
    adjserv.id = this.id;
    adjserv.tenantId = this.loginInfo.tenantId;
    adjserv.taxName = adjserv.name;
    adjserv.rate = parseFloat(adjserv.rate);

    // if (parseFloat(adjserv.rate) > parseFloat("100.00")) {
    //   this.toaster.errorPopUp("Rate should be less Than or equal to 100");
    //   return;
    // }

    this.adminser.SaveTaxMaster(adjserv).subscribe(async res => {
      this.id = res.id;
      if (res.id == 0) {
        this.toaster.errorPopUp(res.message);
      } else {
        this.toaster.successPopUp(res.message);
        await this.dialogser.ClearSession();
        this.helper.setValue('GetSessionData1', adjserv);
        this.taxmasterData_child.emit({ data: adjserv, event: "taxMaster", val: false });
      }
      this.submittedGeneral = false;
      this.generaltax.emit({ res: res, name: adjserv.name });
    })
  }

  ngAfterViewInit() {
    this.TaxMasterGenForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.taxmasterData_child.emit(this.TaxMasterGenForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.taxmasterData_child.emit({ data: data, event: "taxMaster", val: true });
  }

  Highlight() {
    this.onchange(this.TaxMasterGenForm.value);

    if (!this.TaxMasterGenForm.invalid) {
      this.isInvalid = false;
      this.dateMgs = '';
    }
    else {
      if (this.TaxMasterGenForm.controls.Effecteddate.invalid) {
        this.isInvalid = true;
        this.dateMgs = 'Invalid Date';
        return;
      }
      this.isInvalid = false;
      this.dateMgs = '';
    }
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }
}
