<div class="container loginForm">
  <h3 class="chat_label">WebSocket Communication</h3>
  <!-- <input type="text" class="mt-3 mb-3 mr-3" [(ngModel)]="text"> -->
  <div class="row">
    <div class="col-sm-3">
        <input type="text" class="form-control input-lg inputbox_login" [(ngModel)]="entityName" appInputRestriction>
    </div>
    <div class="col-sm-3">
        <input type="text" class="form-control input-lg inputbox_login" [(ngModel)]="frameId" appInputRestriction>
    </div>
    <div class="col-sm-3">
        <input type="text" class="form-control input-lg inputbox_login" [(ngModel)]="attributes" appInputRestriction>
    </div>
    <div class="col-sm-3">
        <button class="btn  btn-sm float-right submit_button" [disabled]="entityName.length==0" (click)="sendMessage()">Send Message</button>
    </div>
  </div>

  <h3 class="chat_label">List Of Messages</h3>
  <div *ngIf="ObjChat.FrontEndmessages.length==0">
  <p class="chat_label">You haven't send or received messages</p>
  </div>
  <div  *ngFor="let m of ObjChat.FrontEndmessages">
      <div class="row">
        <div class="col-sm-1 chat_label">entityName</div>
        <div class="col-sm-4 chat_label"> : {{m.entityName}}</div>
      </div>
      <div class="row">
          <div class="col-sm-1 chat_label">frameId</div>
          <div class="col-sm-4 chat_label">  :{{m.frameId }}</div>
      </div>
      <div class="row">
          <div class="col-sm-1 chat_label">attributes</div>
          <div class="col-sm-4 chat_label">: {{m.attributes}}</div>
      </div>
      <hr>
    </div>
</div>