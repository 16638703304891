import { Component, Input, OnInit, ChangeDetectorRef, EventEmitter ,Output} from '@angular/core';
import { menuItemList } from 'src/app/interfaces/imenu';
import { MenuService } from 'src/app/services/menu.service';
import { HelperService } from 'src/app/helper/helper.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {
  public salesAreabit: any;
  loginInfo : any;
  employee : any;
  @Output() public MenuNameEmit = new EventEmitter();
  constructor(private objItem: MenuService, private helper: HelperService,private changeDetector: ChangeDetectorRef) { }
  


  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');
  }
  @Input()
  public set DatasalesArea(salesArea) {
    this.salesAreabit = salesArea;
}
onMenuName(){
  this.employee ="Items";
  this.MenuNameEmit.emit(this.employee)
 }
}
