import { Injectable } from '@angular/core';
import { MessageDto } from '../interfaces/iWebSocket';
import { Observable, Subject } from 'rxjs';
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ContractService {
  private  connection: any = new HubConnectionBuilder().withUrl("http://204.236.184.97/Handshake/SendRequest")   // mapping to the chathub as in startup.cs
  .configureLogging(LogLevel.Information)
  .build();
readonly POST_URL = "http://204.236.184.97/Handshake/SendRequest"

private receivedMessageObject: MessageDto = new MessageDto();
private sharedObj = new Subject<MessageDto>();

  constructor(private http: HttpClient) { 
    this.connection.onclose(async () => {
      await this.start();
    });
   this.connection.on("ReceiveOne", (user, message) => { this.mapReceivedMessage(user, message); });
   this.start();                 
  }
  public async start() {
    try {
      await this.connection.start();
      console.log("connected");
    } catch (err) {
      console.log(err);
      setTimeout(() => this.start(), 5000);
    } 
  }

  private mapReceivedMessage(user: string, message: string): void {
    this.receivedMessageObject.user = user;
    this.receivedMessageObject.msgText = message;
    this.sharedObj.next(this.receivedMessageObject);
 }

  /* ****************************** Public Mehods **************************************** */

  // Calls the controller method
  public broadcastMessage(msgDto: any) {
   
  //     .pipe(tap(_ => console.log("message sucessfully sent to api controller")));
    this.http.post(this.POST_URL, msgDto).subscribe(data => console.log(data,"data"));
    // this.connection.invoke("SendMessage1", msgDto.user, msgDto.msgText).catch(err => console.error(err));    // This can invoke the server method named as "SendMethod1" directly.
  }

  // public sendMessageToApi(message: string) {
  //   return this.http.post(this.apiUrl, this.buildChatMessage(message))
  //     .pipe(tap(_ => console.log("message sucessfully sent to api controller")));
  // }
 
  public retrieveMappedObject(): Observable<MessageDto> {
    return this.sharedObj.asObservable();
  }

}
