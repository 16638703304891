<form [formGroup]="CurrencyconfigForm" (change)="onchange(CurrencyconfigForm.value)">
    <div class="row top-sec">
        <div class="col-sm-12">
            <div class="form-group global-form">
                <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="onSaveGeneral();" [disabled]="buttonDisable ? true : null">
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
            </button>
            </div>
        </div>
    </div>
    <div class="row second-sec setting-config overflow-panel">
        <div class="col-sm-12 setting-page">
            <mat-accordion>
                <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst" [expanded]="isFirst" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                    <mat-expansion-panel-header [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                        <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> Currency Config
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="loginForm currency-from col-8 ">
                        <div class="row">
                            <div class="col-sm-1 col-md-4">
                                <div class="emp-time">
                                    <div class="form-group mat-box">
                                        <label>Store</label>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'select store'" (click)="onchange(CurrencyconfigForm.value)" [data]="storeList" [(ngModel)]="selectedItems" [formControl]="store">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-1 col-md-4">
                                <div class="emp-time">
                                    <!-- <div class="form-group mat-box">
                                    <label>Currency</label>
                                    <input type="text" class="form-control" formControlName="currency" />
                                </div> -->
                                    <mat-form-field>
                                        <label>Currency</label>
                                        <mat-select (openedChange)="matstate($event)" [(ngModel)]="selected" class="form-control" formControlName="currency" (selectionChange)="onchange(CurrencyconfigForm.value)">
                                            <mat-option value="USD">USD</mat-option>
                                            <!-- <mat-option value="CAD">CAD</mat-option> -->
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</form>
