<div class="custom_Uber_eats">
    <form class="houseLogistic loginForm" [formGroup]="HouseLogisticForm" (change)="onchange(HouseLogisticForm.value)">
        <div class="main-item ">
            <div class="row">
                <div class="col-sm-1 radiorow">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="plu_CardNumber_Code" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-11">
                    <label class="uber_label">PLU Card Number/Code</label>
                    <p class="uber_para">This allows this tender type to be charged by swiping or manually entering the
                        associated card number or code from the 'Tenders' screen in the POS </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 radiorow">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="required" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-11">
                    <label class="uber_label">Required</label>
                    <p class="uber_para">If enabled, the tender type cannot be tendered to without associated card
                        present, either via swipe or mannual entry of the card number or access card</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 radiorow">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="rollUpPayment" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-11">
                    <label class="uber_label">Roll up payment</label>
                </div>
            </div>
            <div class="row" (click)="toggle()">
                <div class="col-sm-1 radiorow">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="allowTipping" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-11">
                    <label class="uber_label">Allow Tipping</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 radiorow">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="overrideTipApportions" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-11">
                    <label class="uber_label">Override Tip apportions</label>
                    <p class="uber_para">Override for percentage of tips to be paid on servers paycheck by default the
                        location Payout Apportions are used</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 radiorow">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="requireSignature" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-11">
                    <label class="uber_label">Require Signature</label>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-1 radiorow">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="openCashDrawer" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-11">
                    <label class="uber_label">Open Cash Drawer</label>
                    <p class="uber_para">Upon accepting payment, the cash drawer will open </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 radiorow">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="requireReferenceCode" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-11">
                    <label class="uber_label">Require Reference Code</label>
                    <p class="uber_para">Require reference Code to be appiled to use the payment type </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 radiorow">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="customPrefix" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-11">
                    <label class="uber_label">Custom Prefix</label>
                    <p class="uber_para">Allow you to define the prefix on the card for </p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 radiorow">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="email" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-11">
                    <label class="uber_label">Email</label>
                    <p class="uber_para">Enabling will email the customer based on the</p>
                </div>
            </div>
            <div class="row" [ngClass]="toggleStyle ? 'show':'hide'">
                <div class="col-sm-1 radiorow">
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="customCheckbox"
                                formControlName="printAdditionalTipLine" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-11">
                    <label class="uber_label">Print additional Tip Line</label>
                    <p class="uber_para">Place and additional line for custom tipping</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12">
                <button class="btn btn-sm float-right submit_button button-none" type="submit" >
                    <!-- (click)="onSaveGeneral()" [disabled]="buttonDisable ? true : null" -->
                    <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
                </button>
            </div>
        </div>
    </form>
</div>