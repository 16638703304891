<div class="time-body">
    <div class="loginForm form-switch table_forms">
        <app-report-labor-filter (checkItem)="isCheckBoxchecked($event)" [tableName]="laborFirstShow" (ReceivedData)="GetLaborData($event)"></app-report-labor-filter>
        <div class="footer-table Communication_tab mb-2 report-table">
                <div class="d-flex no-gutter mb-0 right_image">
                    <div class="salesreconciliation"><img src="assets/components/images/gen-icon-export.png" alt="sales"
                            class="img-fluid cash_icon"></div>
                    <div class="salesreconciliation"><img src="assets/components/images/gen-icon-dashboard.png"
                            alt="sales" class="img-fluid cash_icon_w90"></div>
                </div>
            <mat-tab-group dynamicHeight mat-align-tabs="center">
                <mat-tab label="SUMMARY" >
                     <div class="example-small-box mat-elevation-z4" *ngIf="laborFirstShow == true">
                        <app-fliptable-labor-cascade [dataSource]="dataSource1" [displayedColumns]="displayedColumns1"
                    [columns]="columns1" [_alldata]="alldata1" (timeRangeClick)="LaborCheck($event)"
                    [groupByColumns]="groupByColumns1" [tableName]="laborSecondShow"></app-fliptable-labor-cascade>
                    </div> 

                    <div class="back-img align-self-center" *ngIf="laborSecondShow == true">
                        <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(1)"
                            class="img-fluid align-self-center">
                        <p><span class="disc-ft">{{firstTableName}}</span></p>
                    </div>
                    <div class="example-small-box mat-elevation-z4" *ngIf="laborSecondShow == true">
                        <app-fliptable-labor-cascade [dataSource]="dataSource2" [displayedColumns]="displayedColumns2"
                    [columns]="columns2" [_alldata]="alldata2" (timeRangeClick)="GetEmployeeLaborDetails($event)"
                    [groupByColumns]="groupByColumns2" [tableName]="laborSecondShow"></app-fliptable-labor-cascade>
                    </div>

                    <div class="back-img align-self-center" *ngIf="laborThirdShow == true">
                        <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(2)"
                            class="img-fluid align-self-center">
                        <p><span class="disc-ft">{{firstTableName}} --> {{secondTableName}}</span></p>
                    </div>
                    <div class="example-small-box mat-elevation-z4" *ngIf="laborThirdShow == true">
                        <app-fliptable-labor-cascade [dataSource]="dataSource3" [displayedColumns]="displayedColumns3"
                    [columns]="columns3" [_alldata]="alldata3"
                    [groupByColumns]="groupByColumns3" [tableName]="laborThirdShow" [jobPosition]="secondTableName" [tableDisplayCheck] = "tableDisplay"></app-fliptable-labor-cascade>
                    </div>

                    <div class="back-img align-self-center" *ngIf="laborSecondCategoryShow == true && isChecked == true">
                        <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(3)"
                            class="img-fluid align-self-center">
                        <p><span class="disc-ft">{{firstTableName}}</span></p>
                    </div>
                     <div class="example-small-box mat-elevation-z4" *ngIf="laborSecondCategoryShow == true && isChecked == true">
                        <app-fliptable-labor-cascade [dataSource]="dataSourceLabor1" (timeRangeClick)="GetLaborCategoryDataEmployees($event)" [displayedColumns]="displayedColumnsLabor1"
                    [columns]="columnsLabor1" [_alldata]="alldataLabor1"
                    [groupByColumns]="groupByColumnsLabor1"></app-fliptable-labor-cascade>
                    </div>

                    <div class="back-img align-self-center" *ngIf="laborThirdCategoryShow == true">
                        <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(4)"
                            class="img-fluid align-self-center">
                        <p><span class="disc-ft">{{firstTableName}} --> {{secondTableName}}</span></p>
                    </div>
                     <div class="example-small-box mat-elevation-z4" *ngIf="laborThirdCategoryShow == true">
                        <app-fliptable-labor-cascade [dataSource]="dataSourceLabor2" [displayedColumns]="displayedColumnsLabor2"
                    [columns]="columnsLabor2" [_alldata]="alldataLabor2" [jobPosition]="secondTableName"
                    [groupByColumns]="groupByColumnsLabor2"></app-fliptable-labor-cascade>
                    </div>
                    </mat-tab>
                </mat-tab-group>
        </div>
    </div>
</div>