<div class="Gen_wrap">
    <form class="loginForm GeneralForm" [formGroup]="statefieldForm" (change)="onchange(statefieldForm.value)">
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label>State Code *</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="statecode" placeholder="State Code" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.statecode.errors) || (requiredError && GeneralData.statecode.errors)}"
                        maxlength="50" (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="StateValid()" />
                    <div class="error">{{errorValue}}</div>

                    <div *ngIf="(submittedGeneral && GeneralData.statecode.errors) || (requiredError && GeneralData.statecode.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.statecode.errors.required">State Code is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>State Name</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="statename" placeholder="State Name" appInputRestriction maxlength="50" (input)="getMaxValueState($event)" />
                    <div class="error">{{errorValue1}}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group ">
                    <label class="customlable mb-0">Country</label>
                    <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="country" (selectionChange)="onchange(statefieldForm.value)">
                        <!-- <mat-option value="0" selected>PrinterName 1</mat-option>
                            <mat-option value="1">PrinterName 2</mat-option>
                            <mat-option value="2">PrinterName 3</mat-option> -->
                        <mat-option [value]="0" selected disabled>Select Country</mat-option>
                        <mat-option *ngFor="let item of Country" [value]="item.name">{{item.name}}
                            <img src="assets/components/images/USA.png" alt="employee" class="state_flag_img">
                        </mat-option>
                    </mat-select>
                    <!-- <div >
                    <mat-select-country appearance="fill" class="mat-select-country" formControlName="country"
                        (onCountrySelected)="onCountrySelected($event)" #select1 placeholder="country" [(ngModel)]="countryName"
                        [value]="country">
                    </mat-select-country>
                </div> -->

                    <!-- <mat-select-country appearance="fill"  placeholder="country"
                        class="mat-select-country ">
                            <div class="mat-form-field-wrapper">
                                <div class="mat-form-field-flex ">
                                    <div class="mat-form-field-infix ">
                                        <input formcontrolname="country" aria-label="country" matinput="" type="text"
                                            class="mat-input-element mat-form-field-autofill-control mat-autocomplete-trigger  mat-select-country "
                                            name="country" placeholder="Select country">


                                    </div>
                                </div>

                            </div>
                    </mat-select-country> -->
                    <!-- <div *ngIf="id">
                        <input type="text" class="form-control input-lg inputbox_login" formControlName="country">
                    </div> -->
                    <!-- <select class="selectpicker countrypicker" data-flag="true" ></select> -->
                </div>
            </div>

        </div>
        <div class="form-group mt-2">
            <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral()" [disabled]="buttonDisable ? true : null">
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
            </button>
        </div>
    </form>
</div>
