import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../helper/helper.service';
import { BaseService } from '../base/base.service';
import { Router } from '@angular/router';
// import { IBindReportFilter, ISalesReport, iSalesDayPartReport, iDiscountReport, iSalesReconsilation, iLoborCostReport, iSelectVoidReport } from '../interfaces/i-report';
import { IBindReportFilter, ISalesReport, iSalesDayPartReport, iDiscountReport, iSalesReconsilation, iSelectVoidReport, iLoborCostReport } from '../interfaces/i-report';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { iTaxHostoryReport } from '../interfaces/iadmin';

@Injectable({
  providedIn: 'root'
})
export class ReportWebSocketService {

  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) { }


  BindFiltersFields(Custom: IBindReportFilter) {
    return this.base.post('Report/SelectFilterFields', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  getAllData(): Observable<any[]> {
    return this.http.get<any[]>('./assets/showcase/data/table-data.json');
  }
  getAllDataHour(): Observable<any[]> {
    return this.http.get<any[]>('./assets/showcase/data/table-time-data.json');
  }

  SelectSalesReport(Custom: ISalesReport) {
    return this.base.post('Report/SelectSalesReportCategory', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectSalesDayPartReport(Custom: iSalesDayPartReport) {
    return this.base.post('Report/SelectSalesDayPartDetails', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectNoSalesDetails(Custom: iSalesDayPartReport) {
    return this.base.post('Report/SelectNoSaleDetails', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectLaborCostReport(Custom: iLoborCostReport) {
    return this.base.post('Report/LaborCostSummary', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectSalesHoursReport(Custom: iSalesDayPartReport) {
    return this.base.post('Report/SelectSalesHoursDetails', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectCreditCardReport(Custom: iSalesDayPartReport) {
    return this.base.post('Report/SelectCreditBatchDetails', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectPayrollReport(Custom: iSalesDayPartReport) {
    return this.base.post('Report/SelectPayrollReports', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectDiscountReport(Custom: iDiscountReport) {
    return this.base.post('Report/SelectDiscountReport', Custom).pipe(map((response: any) => {
      return response;
    }));
  }


  SelectDiscountSummary(Custom: iDiscountReport) {
    return this.base.post('Report/SelectDiscountSummary', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectDiscountBySalesArea(Custom: iSalesDayPartReport) {
    return this.base.post('Report/SelectDiscountBySalesArea', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectDiscountByEmployee(Custom: iSalesDayPartReport) {
    return this.base.post('Report/SelectDiscountByEmployee', Custom).pipe(map((response: any) => {
      return response;
    }));
  }
  SelectDiscountByApprover(Custom: iSalesDayPartReport) {
    return this.base.post('Report/SelectDiscountByApprover', Custom).pipe(map((response: any) => {
      return response;
    }));
  }
  SelectDiscountSalesAreaByApprover(Custom: iSalesDayPartReport) {
    return this.base.post('Report/SelectDiscountBySalesAreaApprover', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectDiscountSalesAreaByEmployee(Custom: iSalesDayPartReport) {
    return this.base.post('Report/SelectDiscountBySalesAreaEmployee', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectSalesReconsilation(Custom: iSalesReconsilation) {
    return this.base.post('Report/SelectSalesReconsilation', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveSalesReconsilationData(Custom: any) {
    return this.base.post('Report/SaveSalesReconsilation', Custom).pipe(map((response: any) => {
      return response;
    }));
  }
  TaxHistoryReport(Custom: iTaxHostoryReport) {
    return this.base.post('Admin/TaxHistoryReport', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectVoidReport(Custom: iSelectVoidReport) {
    return this.base.post('Report/SelectVoidReport', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectPreVoidReport(Custom: iSelectVoidReport) {
    return this.base.post('Report/SelectPreVoidReport', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectRefundReport(Custom: iSelectVoidReport) {
    return this.base.post('Report/SelectRefundReport', Custom).pipe(map((response: any) => {
      return response;
    }));
  }
}
