import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { MenuService } from 'src/app/services/menu.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { menuItemList, menuItemddl, menuEdit } from 'src/app/interfaces/imenu';
import { TimeService } from 'src/app/services/time.service';
import { IshiftValue } from 'src/app/interfaces/itime';
import { CurrencyPipe } from '@angular/common';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { DialogService } from 'src/app/services/dialog.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { IRole } from 'src/app/interfaces/iadmin';
import { AdminService } from 'src/app/services/admin.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

interface ICountry {
  item_id: number;
  item_text: string;
  image: string;
  isDisabled?: boolean;
}
@Component({
  selector: 'app-menu-item-general',
  templateUrl: './menu-item-general.component.html',
  styleUrls: ['./menu-item-general.component.css'],
})

export class MenuItemGeneralComponent implements OnInit {
  MenuItemForm: FormGroup;
  buttonDisable: boolean = false;
  ingredientcheck: boolean = false;
  submittedGeneral: any;
  UserSizes: Boolean = false;
  clickI: any;
  PriceToggle: boolean;
  loginInfo: any;
  sizeid: any = 0;
  pricingList: any;
  taxList: any;
  SizeList: menuItemddl[];
  value: string = null;
  menuItemPricingList = [];
  remarkStyleValue: any;
  remarkStylesDefvalue = [{ value: 'Remarks' }];
  errorValue: any;
  errorValue1: any;
  requiredError: boolean = false;
  checked = true;
  disabled = false;
  // cities: Array<string> = [];
  countries: Array<ICountry> = [];
  taxselectedItem: [];
  dropdownSettings: IDropdownSettings = {};
  taxableData = [];
  taxdetails: FormControl = new FormControl('');
  checkboxValue: boolean = true;

  @Output() public isInvalid = new EventEmitter();
  @Output() public ItemIdEvent = new EventEmitter();
  @Output() public getMenuPricingList = new EventEmitter();
  @Output() public mnuitmsessData_child = new EventEmitter();
  @Output() public MainDishCheck = new EventEmitter();

  //@Input() public buttonState;
  searchField;
  @Output() public mainEmit = new EventEmitter();
  errorMsg: { value: string; index: any };
  errorMsgPrice: { value: string; index: any };
  addMulti: boolean = true;
  addMultiPrice: boolean = true;
  rowIWannaDisable: any;
  rowDisable: boolean = false;
  rowDisableId: number;

  @Input()
  public set MenuRowItem(tableRowId) {
    this.errorValue = '';
    this.errorValue1 = '';
    if (tableRowId != undefined && tableRowId != null && tableRowId != '') {
      this.GetItemGeneralList(tableRowId);
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !btnst;
    }
  }
  @Output() matopen: EventEmitter<any> = new EventEmitter()
  matstate($event) {
    this.matopen.emit($event)
  }

  constructor(
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private MenuItem: MenuService,
    private toaster: ToasterService,
    private timeEditDetails: TimeService,
    private dialogser: DialogService,
    private adminser: AdminService
  ) {
    this.MenuItemForm = this.formBuilder.group({
      menuItemSizeList: this.formBuilder.array([this.addMenuItemFormGroup()]),
    });
  }
  @ViewChild('select') select: MatSelect;

  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.MenuItemForm = this.formBuilder.group({
      id: [0],
      name: [null, Validators.required], //, Validators.required
      shortname: [null], //, Validators.required
      description: [null], //, Validators.required
      main: [false], //, Validators.required, null, { disabled: true }
      sideDish: [false], //, Validators.required, null, { disabled: true }
      ingredient: [false], //, Validators.required, null, { disabled: true }
      remark: [false], //, Validators.required, null, { disabled: true }
      price: [null],
      cost: [null],
      pizzeriaPricing: [false], //, Validators.required
      showsOnReceipt: [false], //, Validators.required
      nonSalesItem: [false],
      discountable: [false],
      tippable: [false],
      remarkStyle: [null],
      openPrice: [false],
      rollUpPrice: [false], //, Validators.required
      openName: [false],
      appetizer: [false],
      pricingStyle: [false, { disabled: true }],
      pizzeriaPricingDD: [],
      taxableDD: ['T29'],
      taxRules: [null],
      taxableData: [],
      taxdetails: new FormControl(''),
      taxselectedItem: [null],

      sizedetails: this.formBuilder.array([
        this.formBuilder.group({
          sizeName: [null], //, Validators.required
          price: [null], //, Validators.required
          cost: [null],
          costpercent: [null],
        }),
      ]),
    });
    this.GetMenuItemList();
    this.GetClockListType();
    this.MenuItemForm.controls.pricingStyle.disable();

    this.dropdownSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 2,
      selectAllText: 'Select All',
      unSelectAllText: 'Select All'
    };

    this.GetTaxData();
    this.MenuItemForm.get('taxRules').setValue(true);
    this.checkboxValue = true;
  }
  get f() { return this.MenuItemForm.controls; }

  GetTaxData() {
    const login: IRole = {
      id: 0,
      tenantId: this.loginInfo.tenantId //this.parentID
    }
    this.adminser.GetTaxMaster(login).subscribe(
      (response) => {
        var jsonTaxbaleData = response;
        var itemSalesArea
        var jsonToBeUsedSalesArea = [];
        for (var type in jsonTaxbaleData) {
          itemSalesArea = {};
          itemSalesArea.key = jsonTaxbaleData[type].id.toString();
          itemSalesArea.value = jsonTaxbaleData[type].name;
          jsonToBeUsedSalesArea.push(itemSalesArea);
        }
        this.taxableData = jsonToBeUsedSalesArea;
      });
  }

  onItemSelect(item: any) {
    //console.log('onItemSelect', item);
    //console.log('selectedItem', this.taxselectedItem);
    this.onchange(this.MenuItemForm.value);
  }

  public onDeSelect(item: any) {
    this.onchange(this.MenuItemForm.value);
  }

  public onSelectAll(items: any) {
    this.onchange(this.MenuItemForm.value);
  }

  public onDeSelectAll(items: any) {
    this.onchange(this.MenuItemForm.value);
  }

  allSelected = false;
  foods: any[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' }
  ];

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }


  OnMainCheck(e) {
    //  alert(e.target.checked)
  }

  options = {
    prefix: '$',
    thousands: ',',
    decimal: '.',
    inputMode: CurrencyMaskInputMode.NATURAL,
  };

  public onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {
      event.preventDefault();
      this.value = null;
    }
    if (event.key === 'Delete') {
      //  console.log(event);
      event.preventDefault();
      this.value = null;
    }
  }

  GetMenuItemList() {
    const menuList: menuEdit = {
      sizeid: this.sizeid,
      tenantid: this.loginInfo.tenantId,
    };

    this.MenuItem.MenuSizeList(menuList) // select method
      .subscribe((res) => {
        this.SizeList = res;
      });
  }

  toogle() {
    this.UserSizes = !this.UserSizes;
  }

  get MenuItemData() {
    return this.MenuItemForm.controls;
  }

  addItemMulti() {
    const dynFormArray = this.MenuItemForm.controls['sizedetails'] as FormArray;
    dynFormArray.push(this.addMenuItemFormGroup());
  }

  addMenuItemFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: 0,
      sizeName: [null],
      price: [null],
      cost: [null],
      costpercent: [null],
    });
  }

  async DeleteItem(index: number): Promise<void> {
    if (!((<FormArray>this.MenuItemForm.get('sizedetails')).length == 1)) {
      if (this.rowDisableId == index) {
        for (
          let i = 0;
          i < this.MenuItemForm.get('sizedetails')['controls'].length;
          i++
        ) {
          const box = document.getElementById('pricing' + i);
          this.errorMsgPrice.value = '';
          this.errorMsg.value = '';
          box.style.pointerEvents = 'auto';
          this.addMulti = true;
          this.addMultiPrice = true;
          this.rowDisableId = null;
        }
      }
      (<FormArray>this.MenuItemForm.get('sizedetails')).removeAt(index);
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  numberOnly(event) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);
    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  clearSearchField(index) {
    let dynControl = this.MenuItemForm.controls['sizedetails'] as FormArray;
    for (let i = 0; i < dynControl.length; i++) {
      if (i == index) {
        dynControl.controls[i].get('sizeName').setValue('');
        this.MenuItemForm.controls['sizedetails'].value[i].sizeName = '';
      }
    }
  }

  GetClockListType() {
    const time: IshiftValue = {
      parentCode: 'P25',
    };
    const wage: IshiftValue = {
      parentCode: 'T28',
    };
    this.timeEditDetails.GetClockListType(time).subscribe((response) => {
      this.pricingList = response;
    });
    this.timeEditDetails.GetClockListType(wage).subscribe((response) => {
      this.taxList = response;
    });
  }

  async onSaveMenuItem(): Promise<any> {
    this.submittedGeneral = true;
    if (this.MenuItemForm.invalid) {
      this.isInvalid.emit(true);
      return;
    }
    // if (this.MenuItemForm.get('pricingStyle').value == false) {
    //   this.MenuItemForm.controls.sizedetails.reset();
    // } // tempory closed

    let menuItem;
    return new Promise((resolve, reject) => {
      menuItem = this.MenuItemForm.value;
      menuItem.tenantid = this.loginInfo.tenantId;
      menuItem.userid = this.loginInfo.userId;
      menuItem.isActive = true;
      menuItem.remarkStyle = this.remarkStyleValue == undefined ? null : this.remarkStyleValue;
      if (this.MenuItemForm.controls['price'].value != null) {
        menuItem.price =
          this.MenuItemForm.controls['price'].value == ''
            ? null
            : this.MenuItemForm.controls['price'].value.toString();
      }
      if (this.MenuItemForm.controls['cost'].value != null) {
        menuItem.cost =
          this.MenuItemForm.controls['cost'].value == ''
            ? null
            : this.MenuItemForm.controls['cost'].value.toString();
      }

      // menuItem.taxdetails = JSON.stringify(this.taxselectedItem);
      if (this.MenuItemForm.get('taxRules').value == true) {
        menuItem.taxRules = 'Global';
      }
      else {
        menuItem.taxRules = 'Override';
      }

      if (this.taxselectedItem != null) {
        var Arraygroup = [];
        var clientsgroup: any = [] = this.taxselectedItem;
        for (var i = 0, len = clientsgroup.length; i < len; i++) {
          Arraygroup.push(clientsgroup[i].key);
        }
        menuItem.taxdetails = Arraygroup.toString();
      }

      this.menuItemPricingList = menuItem.sizedetails;
      //console.log(JSON.stringify(this.taxselectedItem), 'menuItem')

      this.MenuItem.SaveItems(menuItem).subscribe(async (res) => {
        if (res.id == 0) {
          this.toaster.errorPopUp(res.message);
        } else {
          if (res.message == 'Size is already assigned') {
            this.toaster.errorPopUp(res.message);
          } else {
            this.toaster.successPopUp(res.message);

            this.MenuItemForm.patchValue({
              cost: Number(menuItem.cost).toFixed(2),
              price: Number(menuItem.price).toFixed(2),
            });

            let dynControl = this.MenuItemForm.controls[
              'sizedetails'
            ] as FormArray;
            for (let i = 0; i < dynControl.length; i++) {
              dynControl.controls[i]
                .get('price')
                .setValue(
                  Number(dynControl.controls[i].get('price').value).toFixed(2)
                );
              dynControl.controls[i]
                .get('cost')
                .setValue(
                  Number(dynControl.controls[i].get('cost').value).toFixed(2)
                );
            }
          }
        }
        await this.dialogser.ClearSession();
        this.submittedGeneral = false;
        this.MenuItemForm.patchValue({
          id: res.id,
        });
        resolve({ id: res.id, name: this.MenuItemForm.value.main });
        this.helper.setValue('GetSessionData1', menuItem);
        this.getMenuPricingList.emit(this.menuItemPricingList);
        this.ItemIdEvent.emit({
          id: res.id,
          name: this.MenuItemForm.value.name,
        });
      });
    });
  }

  OnchangePrice() {
    this.PriceToggle = !this.PriceToggle;
  }
  mainclick(e?) {
    if (
      this.MenuItemForm.value.main == true || this.MenuItemForm.value.sideDish == true
    ) {
      this.MenuItemForm.controls.pricingStyle.enable();
      //   this.MenuItemForm.controls.pricingStyle.setValue(true);
    } else {
      this.MenuItemForm.controls.pricingStyle.disable();
      //this.MenuItemForm.controls.pricingStyle.setValue(false);
    }

    this.MainDishCheck.emit(e.target.checked); // this is for menu item modifier component only main dish is display there
  }

  onCostChange(cost1: any, index: any): void {
    let dynControl = this.MenuItemForm.controls['sizedetails'] as FormArray;
    let price1 = dynControl.at(index).value.price;
    for (let i = 0; i < dynControl.length; i++) {
      if (i == index) {
        let formGroup2 = dynControl.controls[i].value as FormGroup;
        let price = 0;
        let percentage = 0;
        let cost = 0;
        let percentageDec;
        price = formGroup2['price'];
        cost = cost1 == '' ? null : cost1;
        if (cost1 != 0 || cost1 != null) {
          if (parseInt(cost1) > parseInt(price1)) {
            this.errorMsgPrice = { value: '', index: index };
            this.errorMsg = {
              value: 'Cost should be less than Price',
              index: index,
            };
            this.addMulti = true;
            this.addMultiPrice = true;
            for (
              let i = 0;
              i < this.MenuItemForm.get('sizedetails')['controls'].length;
              i++
            ) {
              if (i == index) {
                this.rowDisableId = i;
                const box = document.getElementById('pricing' + i);
                box.style.pointerEvents = 'auto';
              } else {
                const box = document.getElementById('pricing' + i);
                box.style.pointerEvents = 'none';
                this.rowDisable = false;
              }
            }
          } else {
            this.errorMsg = { value: '', index: index };
            this.addMulti = true;
          }
        }

        percentage = (Number(cost) / Number(price)) * 100;
        percentageDec =
          percentageDec == undefined ? null : percentageDec.toFixed(2);
        dynControl.controls[i].get('cost').setValue(cost1 == '' ? null : cost);
        dynControl.controls[i]
          .get('costpercent')
          .setValue(isNaN(percentageDec) == true ? null : percentageDec);
        this.MenuItemForm.controls['sizedetails'].value[i].costpercent =
          isNaN(percentageDec) == true ? null : percentageDec;
      }
    }
  }

  onPriceChange(Price: any, index: any): void {
    let dynControl = this.MenuItemForm.controls['sizedetails'] as FormArray;
    let cost1 = dynControl.at(index).value.cost;

    for (let i = 0; i < dynControl.length; i++) {
      if (i == index) {
        let formGroup2 = dynControl.controls[i].value as FormGroup;
        let price = 0;
        let percentage = 0;
        let cost = 0;
        let percentageDec;
        price = Price == '' ? null : Price;
        cost = formGroup2['cost'];

        percentage = (Number(cost) / Number(price)) * 100;
        percentageDec =
          percentageDec == undefined ? null : percentageDec.toFixed(2);
        dynControl.controls[i].get('costpercent').setValue(percentageDec);
        this.MenuItemForm.controls['sizedetails'].value[i].costpercent =
          isNaN(percentageDec) == true ? null : percentageDec;

        if ((Price != 0 || Price != null) && (cost1 != 0 || cost1 != null)) {
          if (parseInt(cost1) > parseInt(Price)) {
            this.errorMsg = { value: '', index: index };
            this.errorMsgPrice = {
              value: 'Price should be greater than Cost',
              index: index,
            };
            this.addMultiPrice = false;
            this.addMulti = true;
            for (
              let i = 0;
              i < this.MenuItemForm.get('sizedetails')['controls'].length;
              i++
            ) {
              if (i == index) {
                this.rowDisableId = i;
                const box = document.getElementById('pricing' + i);
                box.style.pointerEvents = 'auto';
              } else {
                const box = document.getElementById('pricing' + i);
                box.style.pointerEvents = 'none';
                this.rowDisable = false;
              }
            }
          } else {
            this.errorMsgPrice = { value: '', index: index };
            this.addMultiPrice = true;
          }
        }
        dynControl.controls[i].get('price').setValue(Price == '' ? null : Price);
      }
    }
  }

  GetItemGeneralList(itemIdval) {
    const ItemList: menuItemList = {
      itemID: itemIdval,
      tenantId: this.loginInfo.tenantId,
    };
    this.MenuItem.MenuItemListID(ItemList).subscribe((res) => {
      let sizeVal = res[0];
      let menuItemArray = sizeVal.sizedetails;
      this.getMenuPricingList.emit(menuItemArray);
      const dynFormArray = this.MenuItemForm.controls['sizedetails'] as FormArray;
      dynFormArray.controls = [];
      if (menuItemArray != undefined && menuItemArray != null && menuItemArray != '') {
        if (menuItemArray.length > 0) {
          this.PriceToggle = true;
          for (let j = 0; j < menuItemArray.length; j++) {
            sizeVal.sizedetails[j].cost = menuItemArray[j].cost;
            sizeVal.sizedetails[j].costpercent = menuItemArray[j].costpercent;
            sizeVal.sizedetails[j].price = menuItemArray[j].price;

            // menuItemArray[j].cost;
            // ? null
            // : menuItemArray[j].cost == null
            // ? null
            // : Number(menuItemArray[j].cost).toFixed(2);

            // menuItemArray[j].price == ''
            //   ? null
            //   : menuItemArray[j].price == null
            //   ? null
            //   : Number(menuItemArray[j].price).toFixed(2);
            sizeVal.sizedetails[j].sizeName = menuItemArray[j].sizeName;
            dynFormArray.push(this.addMenuItemFormGroup());
          }
        }
      } else if (this.MenuItemForm.get('pricingStyle').value == true) {
        this.PriceToggle = true;
        dynFormArray.push(this.addMenuItemFormGroup());
      } else {
        this.PriceToggle = true;
        dynFormArray.push(this.addMenuItemFormGroup());
      }

      this.MenuItemForm.patchValue(sizeVal);
      this.MenuItemForm.controls['price'].patchValue(
        sizeVal.price == '' ? null : sizeVal.price
      );
      this.MenuItemForm.controls['cost'].patchValue(
        sizeVal.cost == '' ? null : sizeVal.cost
      );

      if (sizeVal.main == true || sizeVal.sideDish == true) {
        this.MenuItemForm.controls.pricingStyle.enable();
      } else {
        this.MenuItemForm.controls.pricingStyle.disable();
      }

      if (sizeVal.taxRules == 'Global') {
        this.MenuItemForm.get('taxRules').setValue(true);
        this.checkboxValue = true;
      }
      else {
        this.MenuItemForm.get('taxRules').setValue(false);
        this.checkboxValue = false;
      }

      var taxjsonObject = sizeVal.taxdetails;
      if (taxjsonObject != null && taxjsonObject != '') {
        this.taxselectedItem = JSON.parse(taxjsonObject);
      }
      else {
        this.taxselectedItem = [];
      }
      this.mainEmit.emit(sizeVal.ingredient);
    });
  }

  onChange($event) {
    this.remarkStyleValue = $event.value;
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue = '';
    }
  }

  getMaxValueSName(e) {
    if (e.target.value.length == 50) {
      this.errorValue1 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue1 = '';
    }
  }

  MenuItemsValid() {
    if (this.MenuItemForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    } else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.MenuItemForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.MenuItemForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.mnuitmsessData_child.emit(this.MenuItemForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.mnuitmsessData_child.emit({
      data: data,
      event: 'MenuItemPage',
      val: true,
    });
  }

  OnIngredientCheck(e?) {
    if (e.target.checked == true) {
      this.MenuItemForm.controls['pricingStyle'].patchValue(false);
    }
  }

  public toggle(event: MatSlideToggleChange) {
    this.checkboxValue = event.checked;
    this.onchange(this.MenuItemForm.value);
  }
}
