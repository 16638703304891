<div class="pop-up-bg">
    <div class=" text-right">
      <button type="button" class="btn-close close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden" style="padding-right:12px">&times;</span>
      </button>
    </div>
    <div class="mt-2 save-cancel-popup">
      <label class="txt-color">Please fill all the details in Step 2 to continue Save.</label>
    </div>
    <div class="notify-footer modal-footer text-center">
      <button md-button class="button-style" (click)="cancleBox()">OK</button>
    </div>
</div>