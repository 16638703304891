<div class="table-container">

    <table mat-table #table [dataSource]="dataSource" matSort class="mat-elevation-z8 table employee-table employee dataTable full-width-table" style="width: 100%;">
        <ng-container *ngFor="let item of columns " matColumnDef="{{item.columnDef}}">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="disc_ul"> {{item.header}} </th>

            <td mat-cell *matCellDef="let element; let idx=index;">
                <div *ngIf="item.columnDef != 'data' && item.columnDef != 'check' && item.columnDef != 'discountname' && item.columnDef != 'data1'&& item.columnDef != 'data2' && item.columnDef != 'data3'
            && item.columnDef != 'grossSales' && item.columnDef != 'netSales' && item.columnDef != 'price' && item.columnDef != 'total'">
                    {{ element[item.columnDef] }}</div>
                <div *ngIf="item.columnDef == 'discountname' && employeediscounttableShow==true">
                    {{ element[item.columnDef] }}</div>
                <div *ngIf="item.columnDef == 'data' || item.columnDef == 'data1'|| item.columnDef == 'data2' || item.columnDef == 'data3'" class="text-right">
                    {{ element[item.columnDef] | currency}}
                </div>
                <div *ngIf="item.columnDef == 'grossSales' || item.columnDef == 'netSales' || item.columnDef == 'price' ||
          item.columnDef == 'total'">
                    {{ element[item.columnDef] | currency}}
                </div>
                <span *ngIf="item.columnDef == 'discountname' && employeediscounttableShow != true" [ngClass]="element[item.columnDef] == '' ? '' : 'disc_ul'" (click)="itemClick(element[item.columnDef])">
            {{ element[item.columnDef] }} </span>

                <span *ngIf="employeediscounttableShow == true  && item.columnDef == 'check'" [ngClass]="element[item.columnDef] == '' ? '' : 'disc_ul'" (click)="auditClick(element[item.columnDef])">
            {{ element[item.columnDef] }}
          </span>
            </td>
            <td mat-footer-cell *matFooterCellDef>

                <div *ngIf="item.columnDef == 'discountname' && employeediscounttableShow == true">% of Gross
                </div>

                <div *ngIf="item.columnDef == 'discountname' && employeediscounttableShow != true">Totals
                </div>

                <div *ngIf="item.columnDef == 'employeeName'">Totals
                </div>

                <div *ngIf="item.columnDef == 'timeDate'">Gross Sales
                </div>

                <div *ngIf="item.columnDef == 'check'">$1650.23
                </div>

                <div *ngIf="item.columnDef == 'amount'">$15.95
                </div>
                <div *ngIf="item.columnDef == 'quantity'" class="footerData">
                    {{getTotalCost()}}
                </div>
                <div *ngIf="item.columnDef == 'grossSales'" class="footerData">
                    {{getTotalCost1() | currency}}
                </div>
                <div *ngIf="item.columnDef == 'netSales'" class="footerData">
                    {{getTotalCostNet() | currency}}
                </div>
                <div *ngIf="item.columnDef == 'data'" class="text-right footerData">
                    Gross Sales : {{getTotalCost2() | currency}}
                </div>
                <div *ngIf="item.columnDef == 'data1'" class="text-right footerData">
                    Net Sales : {{getTotalCost3() | currency}}
                </div>
                <div *ngIf="item.columnDef == 'data2'" class="text-right footerData">
                    Total Receipts : {{getTotalCost4() | currency}}
                </div>
                <div *ngIf="item.columnDef == 'data3'" class="text-right footerData">
                    {{getTotalCost5() | currency}}
                </div>
                <div *ngIf="item.columnDef == 'ofdiscounts'" class="footerData">
                    {{getTotalCost7() }}
                </div>
                <div *ngIf="item.columnDef == 'total'" class="footerData">
                    {{getTotalCost8() | currency}}
                </div>

            </td>

        </ng-container>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell employeenodata" [attr.colspan]="displayedColumns.length">No data matching the search</td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row (click)="changeTableRowColor(row.id,row)" [style.background-color]="rowClicked == row.id ? '#393939!important' : 'transparent'" *matRowDef="let row; columns: displayedColumns;"></tr>
        <div *ngIf="!footerHide">
            <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="footer"></tr>
        </div>

    </table>
</div>
