<div class="top-header mt-1 w-100">
    <div class="card">
      <div class="row no-gutters">
        <div class="title_img">
          <img [src]="employeeIcon" alt="" class="img-fluid">
        </div>
        <div class="col page-title">
          <div class="card-body p-0">
            <div class="title-reason">
            <p class="card-text mb-0 pt-1">{{employee}}</p>
            <p class="card-text mb-0 pt-1"
            [style.display]="titleClick == true ? 'block' : 'none'">-{{iconClick}}</p>
          </div>
            <h3 class="card-title mb-0">{{employeeName}}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
