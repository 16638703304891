import { Component, OnInit, ChangeDetectorRef, Input, ElementRef, ViewChild } from '@angular/core';
import { GetMenuGroup, GetModifier, SaveItemModifiers } from 'src/app/interfaces/imenu';
import { MenuGroupService } from 'src/app/services/menugroup.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CdkDragExit, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HelperService } from 'src/app/helper/helper.service';
import { MenuService } from 'src/app/services/menu.service';
import { ModifierService } from 'src/app/services/modifier.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-menu-item-modifier',
  templateUrl: './menu-item-modifier.component.html',
  styleUrls: ['./menu-item-modifier.component.css']
})
export class MenuItemModifierComponent implements OnInit {
  buttonDisable: boolean = false;
  menuGroupList: any = [];
  loginInfo: any;
  public dataSourceTablemodifier = new MatTableDataSource<any>();
  public displayedColumns = [];
  columns = [];
  public table_render: boolean = true;
  public matrix_render: any;
  public salesAreaflip: any;
  render: any;
  areaValue: any;
  public menuData: any;
  targetBuilderTools: any = [];
  targetBuilderToolsArray: any = []
  menuListData: any = [];
  builderJson: any;
  public menuGroupListData: any;
  //@Input() public buttonState;
  ItemId: any;
  checkedMain: any;
  SelectedPageVal: any = "";

  constructor(private objService: MenuGroupService, private menuService: MenuService, private changeDetector: ChangeDetectorRef,
    private helper: HelperService, private objItem: MenuService, private objGroup: ModifierService, private toaster: ToasterService,
    private dialogser: DialogService) { }

  ngOnInit(): void {
    this.helper.setValue('OnDirty4', false);
    this.buttonDisable = false;

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getMenuGroupListData(0)
  }

  @Input()
  public set menuItemIn(Menu) {
    this.menuData = Menu;
  }

  @Input()
  public set menuItemMatrix(e) {
    this.matrix_render = e
  }

  @Input()
  public set mainCheck(checkValueMain) {
    console.log(checkValueMain, 'checkValueMain')
    this.checkedMain = checkValueMain;
    if (this.checkedMain == false) {             // only ingedient items data should display
      this.getMenuGroupListData(this.ItemId);
    } else {
      this.dataSourceTablemodifier.data = []
    }
  }

  @Input()
  public set MenuRowItem(tableRowId) {
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.ItemId = tableRowId;
      if (this.checkedMain == false) {   // only main items data should display
        this.getMenuGroupListData(this.ItemId);
      }
      else {
        this.dataSourceTablemodifier.data = []
      }
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set SelectedPageInd(SelectedPage) {
    if (SelectedPage != undefined && SelectedPage != null && SelectedPage != "") {
      this.SelectedPageVal = SelectedPage;
    }
  }

  cdkDropListExited(event: CdkDragExit<string[]>) {
    const preview = new ElementRef<HTMLElement>(document.querySelector('.cdk-drag.cdk-drag-preview'));
    this.render.removeClass(preview.nativeElement, 'dropzone-placeholder');
  }

  CheckMainDish(checkValueMain) {
    // if (checkValueMain == false) {             // only main items data should display
    this.getMenuGroupListData(this.ItemId);
    //  }
    // else {


    //   this.dataSourceTablemodifier.data = []
    //}
  }

  drop(event) {
    this.helper.setValue('GetSessionData4', JSON.stringify(this.targetBuilderTools));
    this.helper.setValue('OnDirty4', true);
    this.helper.setValue('SessionPageName', this.SelectedPageVal);
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data.filteredData,
        event.container.data,
        event.previousIndex,
        event.currentIndex

      );
      this.table_render = false
      this.changeDetector.detectChanges();
      this.table_render = true

      for (let i = event.currentIndex; i < this.targetBuilderTools.length; i++) {
        if (this.targetBuilderTools[i].hasOwnProperty("flag")) {

        }
        else {
          this.targetBuilderTools[i].Item_Object = JSON.parse(this.targetBuilderTools[i].Item_Object)
          this.targetBuilderTools[i].flag = 1;
        }

      }

    }
  }
  dropInArray(event) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  async OnSaveItemModifier(eventid) {
    this.ItemId = eventid;
    const ItemModifier: SaveItemModifiers = {
      itemID: this.ItemId,
      modObj: JSON.stringify(this.targetBuilderTools),
      userID: this.loginInfo.userId,
      tenantId: this.loginInfo.tenantId
    }
    console.log(ItemModifier, 'ItemModifier');
    this.objGroup.SaveItemModifier(ItemModifier).subscribe(async res => {
      console.log(res, 'OnSaveItemModifier');
    });
  }

  getMenuGroupListData(ItemId?) {
    const menuModifier: GetModifier = {
      id: ItemId,
      tenantId: this.loginInfo.tenantId
    }
    this.objGroup.ModifierGroupGet(menuModifier)
      .subscribe(res => {
        this.dataSourceTablemodifier.data = res.ModifierGroupAvailable;
        this.dataSourceTablemodifier.data = this.dataSourceTablemodifier.data.filter(x => x.isActive == true)
        this.displayedColumns = ['name'];
        this.columns = [
          { columnDef: 'name', header: 'Modifier Groups Available' }
        ];
      })
  }

  removeJson(valueIndex, deletedvalue, j) {
    this.helper.setValue('OnDirty4', true);
    this.helper.setValue('SessionPageName', this.SelectedPageVal);
    valueIndex.splice(j, 1);
    // this.menuGroupListData = []
    // for (let i = 0; i < this.targetBuilderTools.length; i++) {
    //   this.menuGroupListData.push(JSON.parse(this.targetBuilderTools[i].Item_Object))
    // }
    this.builderJson = deletedvalue
    this.dataSourceTablemodifier.data.push(this.builderJson)
    this.table_render = false
    this.changeDetector.detectChanges();
    this.table_render = true;
    this.helper.setValue('GetSessionData4', JSON.stringify(this.targetBuilderTools));
  }

}