<div class="house_wrap">
    <form class="giftform loginForm" [formGroup]="GiftCardDesignerForm" (change)="onchange(GiftCardDesignerForm.value)">
        <div class="row">
            <div class="col-sm-4">
                <label class="mb-15 custom-color">FRONT</label>
                <div class="form-group">
                    <label class="customlable mb-0">Upload Logo</label
          ><label class="text-danger"></label>
                    <div class="file-upload-wrapper" (click)="onImgUpload($event)">
                        <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput class="form-control input-lg inputbox_login input-upload" formControlName="logoUpload" appInputRestriction placeholder="Insert Logo" (change)="onImgUpload($event)" />
                        <span class="upload-text">Action</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Custom Logo Text</label
          ><label class="text-danger req_label"></label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="logoText" placeholder="Custom Logo Text" [maxLength]="50" appInputRestriction />
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Custom Small Text</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="logoSmallText" placeholder="Custom Small Text" [maxLength]="50" appInputRestriction />
                </div>
                <div class="outshadow credit_firstData d-flex">
                    <div class="col-sm-6">
                        <div class="radiorow pt-10">
                            <label class="radiolabel">Black Background</label>
                            <div class="form-group">
                                <div class="custom-control custom-radio custom-control item">
                                    <input type="radio" formControlName="chooseBackground" id="isBlackbg" name="chooseBackground" value="blackBg" class="custom-control-input" (change)="changeBg($event)" />
                                    <label class="custom-control-label QR" for="isBlackbg"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="radiorow pt-10">
                            <label class="radiolabel">White Background</label>
                            <div class="form-group">
                                <div class="custom-control custom-radio custom-control item">
                                    <input type="radio" formControlName="chooseBackground" id="WhiteBackground" name="chooseBackground" value="whiteBg" class="custom-control-input" (change)="changeBg($event)" />
                                    <label class="custom-control-label QR" for="WhiteBackground"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <button class="gift-dlt-btn img-label-logistics">
          <i
            class="fa fa-trash-o dlt-i"
            aria-hidden="true"
            (click)="deleteimage()"
          ></i>
        </button>
                <div class="theme_titlecard flex-grow-1">
                    <app-gift-card-front [DataLogoText]="GiftCardDesignerForm.get('logoText').value" [DataCustomSmallText]="
              GiftCardDesignerForm.get('logoSmallText').value
            " [DataImg]="GiftCardDesignerForm.get('logoUpload').value" [CheckBackground]="whiteBg">
                    </app-gift-card-front>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4">
                <label class="mb-15 mt-20 custom-color">BACK</label>
                <div class="form-group cc-disp-none">
                    <label class="mb-0">Card #</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="cardText" placeholder="Card #" [maxLength]="17" appInputRestriction />
                </div>
                <div class="form-group">
                    <label class="mb-0">Custom Text</label>
                    <textarea type="text" class="input-cst-text form-control input-lg inputbox_login" placeholder="Custom Text" formControlName="cardCustomText" [maxLength]="100" appInputRestriction></textarea>
                </div>
            </div>

            <div class="col-sm-8">
                <div class="theme_titlecard flex-grow-1">
                    <app-gift-card-back [DataGiftSlogan]="GiftCardDesignerForm.get('cardCustomText').value" [businessNameAssign]="bName" [adrressAssign]="addrss" [cityName]="city" [stateCd]="state" [zipCode]="zip"></app-gift-card-back>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <label class="mb-15 mt-20 custom-color">ORDER CARDS (Pricing is offered by your FLIP representative, please
          contact them for current pricing and timelines.)</label
        >
        <label class="mb-0 d-block mb-10"># of Cards</label>
                <div class="form-group mat-box">
                    <div class="row">
                        <div class="col-sm-2 col-2">
                            <div class="emp-time">
                                <label class="hide_report disp-none">xxx</label>
                                <button [style.background-color]="
                    isHundread == true ? '#341a46 !important' : 'transparent'
                  " class="btn submit_button form-control timeEditBtn giftCardBtn" id="100" type="submit" (click)="cardsCount($event)">
                  100
                </button>
                            </div>
                        </div>
                        <div class="col-sm-2 col-2">
                            <div class="emp-time">
                                <label class="hide_report disp-none">xxx</label>
                                <button class="btn submit_button form-control timeEditBtn giftCardBtn" id="250" type="submit" (click)="cardsCount($event)" [style.background-color]="
                    isTwoFifty == true ? '#341a46 !important' : 'transparent'
                  ">
                  250
                </button>
                            </div>
                        </div>
                        <div class="col-sm-2 col-2">
                            <div class="emp-time">
                                <label class="hide_report disp-none">xxx</label>
                                <button class="btn submit_button form-control timeEditBtn giftCardBtn" id="500" type="submit" (click)="cardsCount($event)" [style.background-color]="
                    isFiveHundread == true
                      ? '#341a46 !important'
                      : 'transparent'
                  ">
                  500
                </button>
                            </div>
                        </div>
                        <div class="col-sm-2 col-2">
                            <div class="emp-time">
                                <label class="hide_report disp-none">xxx</label>
                                <button class="btn submit_button form-control timeEditBtn giftCardBtn" id="1000" type="submit" (click)="cardsCount($event)" [style.background-color]="
                    isThousand == true ? '#341a46 !important' : 'transparent'
                  ">
                  1000
                </button>
                            </div>
                        </div>
                        <div class="col-sm-2 col-2">
                            <div class="emp-time">
                                <label class="hide_report disp-none">xxx</label>
                                <button *ngIf="clicked == false" id="0" class="btn submit_button form-control timeEditBtn" type="submit" (click)="customOnClick($event)">
                  CUSTOM
                </button>
                                <input *ngIf="clicked == true" type="text" class="form-control input-lg inputbox_login text-center" formControlName="cardNumbers" placeholder="Custom" (keypress)="numberOnly($event)" appInputRestriction />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
