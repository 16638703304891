import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmvComponent } from './emv/emv.component';
import { ThemeComponent } from 'src/app/theme/theme.component';
import { TabletsComponent } from './tablets/tablets.component';

const routes: Routes = [
  {
    path: '', component: ThemeComponent,
    children: [
      {path:'emv', component:EmvComponent},
      {path:'tablet', component:TabletsComponent},
    ]

    
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DevicesRoutingModule { }
