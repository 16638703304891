import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReasonsRoutingModule } from './reasons-routing.module';
import { ReasonsGeneralComponent } from './reasons-general/reasons-general.component';
import { ReasonsComponent } from './reasons/reasons.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { EmployeeModule } from '../employee/employee.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    ReasonsComponent,
    ReasonsGeneralComponent
  ],
  imports: [
    CommonModule,
    ReasonsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    MatExpansionModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    EmployeeModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports:[ ReasonsComponent ]
})
export class ReasonsModule { }
