import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetDevice } from 'src/app/interfaces/idevices';
import { GetSaleAreaGeneralDD } from 'src/app/interfaces/imenu';
import { DeviceService } from 'src/app/services/devices.service';
import { MenuService } from 'src/app/services/menu.service';
import { ITagsDetails, ISelectTagDetails } from 'src/app/interfaces/i-tags';
import { TagsService } from 'src/app/services/tags.service';
import { UpperCasePipe } from '@angular/common';
import { DialogService } from 'src/app/services/dialog.service';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-printer-general',
  templateUrl: './printer-general.component.html',
  styleUrls: ['./printer-general.component.css']
})
export class PrinterGeneralComponent implements OnInit {
  ipAddressMask = createMask({ alias: 'ip' });
  iPAddress1 = new FormControl('');
  message: any;
  printerGeneralForm: FormGroup
  public SaleAreaGenDD: GetSaleAreaGeneralDD[];
  isActive = false;
  submittedGeneral: any;
  loginInfo: any;
  RadioButtonIp: any;
  printname: any;
  printtype: any;
  @Output() public isBool = new EventEmitter();
  errorValue: any;
  @Output() public isInvalid = new EventEmitter();
  requiredErrors: boolean = false;

  printerFilters = [];
  printerselectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  printerGroup: FormControl = new FormControl('');

  recieptFilters = [];
  recieptselectedItems = [];
  assignedReceipt: FormControl = new FormControl('');
  receiptpage: any;
  ipMgs: any;
  //@Input() public buttonState;
  buttonDisable: boolean = false;

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set DataIdx(tableRowId) {
    this.errorValue = "";
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.GetPrinterDetails(tableRowId);
    }
  }

  SalesSettings: IDropdownSettings = {};
  @Output() public GeneralDetailPrinter = new EventEmitter();
  @Output() public printerChangeemit = new EventEmitter();
  @Output() public pritergen_child = new EventEmitter();
  @Output() matopen:EventEmitter<any> = new EventEmitter()

  maxChars = 17;
  mgs: any;
  Macmgs: any;

  constructor(private formBuilder: FormBuilder, private objDevice: DeviceService, private dialogser: DialogService,
    private objItem: MenuService, private helper: HelperService, private toaster: ToasterService, private tagService: TagsService) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.printerGeneralForm = this.formBuilder.group({
      id: [0],
      name: ['', Validators.required],
      printerBrand: [''],
      printerType: [''],
      printerGroup: [0],//new FormControl(''),
      assignedReceipt: [0],
      RadioButtonIp: [0],
      shared_Remote: [false],
      multi_Drawer: [false],
      bell_Buzzer: [false],
      schedule: [false],
      ping: [''],
      ChangeIP: [''],
      // iPAddress1: ['',[Validators.pattern('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$')]],
      iPAddress1: ['', [Validators.pattern('\^(([1-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.(2[0-5][0-5]|1[0-9][0-9]|[1-9][0-9]|[0-9]))\$')]],
      // macAddress1: ['',Validators.pattern('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$')],
      macAddress1: [''],
      printerselectedItems: [],
      printerFilters: [null],
      recieptselectedItems: [],
      recieptFilters: [null],
    })
    this.getPrintername();
    this.getPrintertype();
    this.getBindPrinterGroup();
    this.getBindAssignedReceipt();
    this.dropdownSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 1,
      selectAllText: 'Select All',
      unSelectAllText: 'Select All'
    };
  }
  get printerGenData() { return this.printerGeneralForm.controls; }
  matstate($event){
    this.matopen.emit($event)
  }
  onKeypressIpEvent(event) {
    var ip = event.target.value.replace(/[^0-9,]+/g, "");
    if (ip == 0 && ip.length <= 1) {
      this.iPAddress1.reset();
      this.mgs = '0 Not Allowed In First'
    }
    else {
      this.mgs = ''
    }
  }
  IPValid(value) {
    var ip = value.replace(/[^0-9]+/g, "");
    var IPdot = value.replace(/[^.]+/g, "");
    if (IPdot == '..' || IPdot == '.') {
      this.message = 'Please Enter Valid Ip Address'
      this.printerGeneralForm.controls['iPAddress1'].setErrors({ 'incorrect': true });
    }
    else {
      this.message = ''
    }
  }

  GetPrinterDetails(rowId) {
    const deviceList: GetDevice = {
      id: rowId,
      tenantId: this.loginInfo.tenantId
    }
    this.objDevice.PrinterDeviceGet(deviceList)
      .subscribe(res => {
        let PrintVal = res[0];
        this.printerGeneralForm.patchValue(PrintVal);
        //------------Printer group--------

        var jsonprinterGrp = PrintVal.printerGroup;
        if (jsonprinterGrp != null && jsonprinterGrp != '') {
          this.printerselectedItems = JSON.parse(jsonprinterGrp);
        }
        else {
          this.printerselectedItems = [];
        }

        //------------Assign Recipt group--------
        var jsonReciptGrp = PrintVal.assignedReceipt;
        if (jsonReciptGrp != null && jsonReciptGrp != '') {
          this.recieptselectedItems = JSON.parse(jsonReciptGrp);
        }
        else {
          this.recieptselectedItems = [];
        }

        if (PrintVal.isIP == true) {
          this.RadioButtonIp = "radioIp";
          if (PrintVal.iPAddress != "" && PrintVal.iPAddress != null) {
            //var splitipaddress = PrintVal.iPAddress.split(".");
            this.printerGeneralForm.patchValue({
              iPAddress1: PrintVal.iPAddress
              //iPAddress1: splitipaddress[0] == null ? "" : splitipaddress[0],
            });
          }
          else {
            this.printerGeneralForm.patchValue({
              RadioButtonIp: "",
              isMACAddress: false,
              isIP: false
            });
          }
        }

        else if (PrintVal.isMACAddress == true) {
          this.RadioButtonIp = "radioMac";
          if (PrintVal.macAddress != "" && PrintVal.macAddress != null) {
            //var splitmacaddress = PrintVal.macAddress.split(".");
            this.printerGeneralForm.patchValue({
              macAddress1: PrintVal.macAddress
              // macAddress1: splitmacaddress[0] == null ? "" : splitmacaddress[0],
            });
          }
          else {
            this.printerGeneralForm.patchValue({
              RadioButtonIp: "",
              isMACAddress: false,
              isIP: false
            });
          }
        }
        else {
          this.printerGeneralForm.patchValue({
            RadioButtonIp: "",
            isMACAddress: false,
            isIP: false,
            iPAddress1: "",
            macAddress1: ""
          });
        }
      })
  }

  onSaveGeneral() {
    this.errorValue = "";
    this.submittedGeneral = true;
    if (this.printerGeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredErrors = true;
      return;
    }
    let printergen;
    printergen = this.printerGeneralForm.value;
    printergen.createdBy = this.loginInfo.userId;
    printergen.modifiedBy = this.loginInfo.userId;
    printergen.tenantId = this.loginInfo.tenantId;
    printergen.isActive = true;
    printergen.isIP = false;
    printergen.isMACAddress = false;

    if (this.printerselectedItems != null) {
      printergen.printerGroup = JSON.stringify(this.printerselectedItems);
    }
    else {
      printergen.printerGroup = "[]";
    }

    if (this.recieptselectedItems != null) {
      printergen.assignedReceipt = JSON.stringify(this.recieptselectedItems);
    }
    else {
      printergen.assignedReceipt = "[]";
    }

    const itm = this.printerGeneralForm.value;
    if (itm.RadioButtonIp == "radioIp") {
      this.printerGeneralForm.value.isIP = true;
      printergen.iPAddress = printergen.iPAddress1;
    }
    else if (itm.RadioButtonIp == "radioMac") {
      this.printerGeneralForm.value.isMACAddress = true;
      printergen.macAddress = printergen.macAddress1;
    }

    this.objDevice.PrinterDeviceGeneralSave(this.printerGeneralForm.value).subscribe(
      async (response) => {
        this.printerGeneralForm.patchValue({
          id: response.id,
        });

        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
          this.helper.setValue('GetSessionData1', this.printerGeneralForm.value);
          this.pritergen_child.emit({ data: this.printerGeneralForm.value, event: "printer", val: false });
        }
        this.submittedGeneral = false;
        this.GeneralDetailPrinter.emit({ res: response, name: this.printerGeneralForm.value.name });
        await this.dialogser.ClearSession();
      })
  }

  getPrintername() {
    const menuItemList: GetSaleAreaGeneralDD = {
      parentCode: "PN1",
    }
    this.objItem.GetSalesAreaTicketGeneral(menuItemList)
      .subscribe((response) => { this.printname = response; })
  }

  getPrintertype() {
    const menuItemList: GetSaleAreaGeneralDD = {
      parentCode: "PT1",
    }
    this.objItem.GetSalesAreaTicketGeneral(menuItemList)
      .subscribe((response) => { this.printtype = response; })
  }

  getBindPrinterGroup() {
    const _selecttagname: ISelectTagDetails = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.tagService.SelectTagDetails(_selecttagname).subscribe(
      (response) => {
        var jsonPrinter = response;
        jsonPrinter = jsonPrinter.filter(x => x.isActive == true && x.tagCategory == 'prints')
        var itemPrintergrp
        var jsonToBeUsedPrintGrp = [];
        for (var type in jsonPrinter) {
          itemPrintergrp = {};
          itemPrintergrp.key = jsonPrinter[type].id.toString();
          itemPrintergrp.value = jsonPrinter[type].name;
          jsonToBeUsedPrintGrp.push(itemPrintergrp);
        }
        this.printerFilters = jsonToBeUsedPrintGrp;
      })
  }


  getBindAssignedReceipt() {
    const _selecttagname: ISelectTagDetails = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.objDevice.ReceiptDeviceGet(_selecttagname).subscribe(
      (response) => {
        var jsonreceipt = response;
        jsonreceipt = jsonreceipt.filter(x => x.isActive == true)
        var itemreceipt
        var jsonToBeUsedreceipt = [];
        for (var type in jsonreceipt) {
          itemreceipt = {};
          itemreceipt.key = jsonreceipt[type].id.toString();
          itemreceipt.value = jsonreceipt[type].name;
          jsonToBeUsedreceipt.push(itemreceipt);
        }
        this.recieptFilters = jsonToBeUsedreceipt;
      })
  }

  onReceiptPage(e) {
    this.receiptpage = true
    this.printerChangeemit.emit(this.receiptpage)
  }

  onKeypressEvent(elem, e) {
    let uppervalue = elem.target.value.toUpperCase()
    if (elem.charCode < 103) {
      if (elem.target.value.length < 3 || (elem.target.value.length > 3 || elem.target.value.length < 11)) {
        if (elem.key === "-") elem.preventDefault();
      }
      this.printerGeneralForm.get("macAddress1").patchValue(elem.target.value.toUpperCase());
      if (elem.target.value.length >= 16) {
        this.Macmgs = '';
        this.isInvalid.emit(false);
      }
      if (elem.target.value.length <= 17) {
        if (elem.target.value.length === 2) {
          elem.target.value = `${elem.target.value}-`;
        }
        if (elem.target.value.length === 5) {
          elem.target.value = `${elem.target.value}-`;
        }
        if (elem.target.value.length === 8) {
          elem.target.value = `${elem.target.value}-`;
        }
        if (elem.target.value.length === 11) {
          elem.target.value = `${elem.target.value}-`;
        }
        if (elem.target.value.length === 14) {
          elem.target.value = `${elem.target.value}-`;
        }

      } else {
        elem.target.value = elem.target.value.slice(0, -1)
      }

      return true
    } else {
      // if (elem.target.value.length <= 17) {
      //   this.Macmgs = ''
      // }
      return false
    }

  };

  // onKeypressIpEvent(event): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   var stringToConvert = event.target.value;
  //   var numberValue = Number(stringToConvert);
  //   if (event.target.value.length <= 15) {
  //     if (event.target.value.length < 4 || (event.target.value.length > 4 || event.target.value.length < 8)) {
  //       if (event.key === ".") event.preventDefault();
  //     }
  //     if (event.target.value.length == 3) {
  //       if (numberValue <= 255) {
  //         event.target.value = `${event.target.value}.`;
  //         this.ipMgs = ''
  //       }
  //       else {
  //         this.ipMgs = 'The Value Should be Less Than 255';
  //         this.isBool.emit(true);
  //         return false
  //       }
  //     }
  //     if (event.target.value.length == 7) {
  //       const nextValue = event.target.value.substring(4);
  //       var numberValue = Number(nextValue);
  //       if (numberValue <= 255) {
  //         event.target.value = `${event.target.value}.`;
  //         this.ipMgs = ''
  //       }
  //       else {
  //         this.ipMgs = 'The Value Should be Less Than 255';
  //         this.isBool.emit(true);
  //         return false
  //       }
  //     }
  //     if (event.target.value.length == 11) {
  //       const thirdValue = event.target.value.substring(8);
  //       var numberValue = Number(thirdValue);
  //       if (numberValue <= 255) {
  //         event.target.value = `${event.target.value}.`;
  //         this.ipMgs = ''
  //       }
  //       else {
  //         this.ipMgs = 'The Value Should be Less Than 255';
  //         this.isBool.emit(true);
  //         return false
  //       }
  //     }
  //     if (event.target.value.length == 14) {
  //       const fourthValue = event.target.value.substring(12);
  //       var numberValue = Number(fourthValue);
  //       if (numberValue <= 25) {
  //         event.target.value = `${event.target.value}`;
  //         this.ipMgs = ''
  //       }
  //       else {
  //         this.ipMgs = 'The Value Should be Less Than 255';
  //         this.isBool.emit(true);
  //         return false
  //       }
  //     }
  //     if (event.target.value.length >= 14) {
  //       this.mgs = '';
  //       this.isInvalid.emit(false);
  //     }
  //   } else {
  //     event.target.value = event.target.value.slice(0, -1)
  //   }
  //   return true;
  // }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }


  PrintValid() {
    if (this.printerGeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredErrors = true;
      return;
    }
    else {
      this.isInvalid.emit(false);
      this.requiredErrors = false;
    }
  }
  Highlight() {
    if (!(this.printerGeneralForm.invalid)) {
      this.requiredErrors = false;
      this.isInvalid.emit(false);
    }
    else if ((this.printerGeneralForm.value.name == null) || (this.printerGeneralForm.value.name == '') && (this.printerGeneralForm.value.name.length == 0)) {
      this.isInvalid.emit(false);
      this.requiredErrors = false;
      return;
    }
    else {
      this.requiredErrors = true;
      this.isInvalid.emit(true);
    }
  }

  ngAfterViewInit() {
    this.pritergen_child.emit(this.printerGeneralForm.value)
    this.printerGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.pritergen_child.emit({ data: data, event: "printer", val: true });
  }

  // isFormPageDirty() {
  //   let d = this.printerGeneralForm.dirty;
  //   this.helper.setValue('GetSessionData1', this.printerGeneralForm.value);
  //   if (d == true) {
  //     this.helper.setValue('OnDirty', true);
  //   }
  //   return d;
  // }

  OnMacCheck(e?) {
    this.printerGeneralForm.controls['iPAddress1'].reset();
  }

  OnIPCheck(e?) {
    this.printerGeneralForm.controls['macAddress1'].reset();
  }

  onItemSelect(item: any) {
    this.onchange(this.printerGeneralForm.value);
  }
}
