<div class="emv_general">
    <form [formGroup]="emvGeneralForm" class="loginForm modalComp" (change)="onchange(emvGeneralForm.value)">
        <div class="row mb-2">
            <div class="col-sm-2 col-md-4 col-5">
                <div class="form-group">
                    <label class="customlable mb-0">Terminal Name</label><label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="name" placeholder="Name" [ngClass]="{'is-invalid': (submittedGeneral && emvGenData.name.errors) || (requiredError && emvGenData.name.errors)}" appInputRestriction maxlength="50"
                        (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="EMVValid()" />
                    <div class="error">{{ errorValue }}</div>
                    <div *ngIf="
              (submittedGeneral && emvGenData.name.errors) ||
              (requiredError && emvGenData.name.errors)
            " class="invalid-feedback">
                        <div *ngIf="emvGenData.name.errors.required">
                            Terminal Name is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-2 col-md-4 col-5">
                <!-- <div class="form-group">
          <label class="customlable mb-0">IP Address</label><label class="text-danger req_label">*</label>
          <input type="text" class="form-control input-lg inputbox_login" formControlName="ipAdress" [maxlength]="15"
            (keypress)="onKeypressIpEvent($event)" placeholder="IP address" appInputRestriction
            [ngClass]="{ 'is-invalid': (submittedGeneral && emvGenData.ipAdress.errors) || (requiredError && emvGenData.ipAdress.errors)}" (focusout)="EMVValid()"/>
          <div class="error">{{mgs}}</div>
          <div class="error">{{ipMgs}}</div>

          <div *ngIf="(submittedGeneral && emvGenData.ipAdress.errors) || (requiredError && emvGenData.ipAdress.errors)" class="invalid-feedback">
            <div *ngIf="emvGenData.ipAdress.errors.required">IP_address is required</div>
          </div>
        </div> -->
                <div class="form-group">
                    <label class="customlable mb-0">IP Address</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" [formControl]="ipAddress" [inputMask]="ipAddressMask" placeholder="IP Address" (keypress)="onKeypressIpEvent($event)" (input)="IPValid($event.target.value)" appInputRestriction formControlName="ipAddress"
                        required [ngClass]="{
              'is-invalid':
                (submittedGeneral && emvGenData.ipAddress.errors) ||
                (requiredError && emvGenData.ipAddress.errors)
            }" appInputRestriction (keypress)="Highlight()" (focusout)="EMVValid()" />
                    <div class="error">{{ errorValue }}</div>
                    <div class="invalid-feedback">{{message}}</div>
                    <div *ngIf=" (requiredError && emvGenData.ipAddress.errors) || (submittedGeneral && emvGenData.ipAddress.errors)" class="invalid-feedback">
                        <div *ngIf="emvGenData.ipAddress.errors.required">Ip Address is required</div>
                        <div *ngIf="emvGenData.ipAddress.errors.pattern">Please Enter Valid Ip Address </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-2 col-md-4 col-5">
                <div class="form-group">
                    <label class="radiolabel">Prompt for Tip</label>
                    <div class="checkbox-table emp-name">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input active" name="promptforTip" formControlName="promptforTip" />
                            <label class="custom-control-label active"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 col-md-6 col-7">
                <div class="form-group">
                    <label class="radiolabel">Require Customer to Confirm</label>
                    <div class="checkbox-table emp-name">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input active" name="requireCustomertoConfirm" formControlName="requireCustomertoConfirm" />
                            <label class="custom-control-label active"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 col-md-4 col-5">
                <div class="form-group">
                    <label class="radiolabel">Duplicate Checking</label>
                    <div class="checkbox-table emp-name">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input active" name="duplicateChecking" formControlName="duplicateChecking" />
                            <label class="custom-control-label active"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-2">
            <div class="">
                <button type="button" class="remove_emvtab" data-toggle="modal" data-target="#exampleModalCenter" [disabled]="removeDisable ? null : true">
          <img src="assets/components/images/remove-emv-icon.png" alt="employee" class="img-fluid" />
        </button>
            </div>
        </div>

        <div class="form-group">
            <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral()" [disabled]="buttonDisable ? true : false">
        <img src="assets/components/images/save.png" alt="employee" class="" />
        <label class="label_config">Save</label>
      </button>
        </div>
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="Communication_tab m-2">
                        <div class="remove_tab m-3">
                            <div class="modal-header">
                                <div class="text-right">
                                    <button type="button" class="btn-close close" aria-label="Close" data-dismiss="modal">
                    <span aria-hidden="true" class="visually-hidden">&times;</span>
                  </button>
                                </div>
                            </div>

                            <div class="modal-body text-center p-0">
                                <p>Remove Device</p>
                                <div>
                                    <img src="assets/components/images/Capture.JPG" alt="employee" class="img-fluid" />
                                </div>
                                <div class="mt-2">
                                    <label>Are You Sure?</label>
                                </div>
                            </div>

                            <div class="modal-footer text-center">
                                <button type="button" (click)="RemoveEMVDetails()" class="btn btn-sm" data-dismiss="modal">
                  <img src="assets/components/images/confirm-button.png" alt="employee" width="85%" />
                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>