<form class="SaleArea_general loginForm" [formGroup]="SaleAreaGeneralForm" (change)="onchange(SaleAreaGeneralForm.value)">
    <div class="row">
        <div class="col-sm-12">
            <div class="pull-right-active">
                <span class="marR5">Active</span>
                <div class="checkbox-table accordion-checkbox" id="checkbox-box">
                    <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" name="customCheckbox" id="activeForm" formControlName="isActive" checked="isActive" [attr.disabled]="isActiveInactivePerm ? true : null" />
                        <label class="custom-control-label" for="activeForm"></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4">
            <div class="form-group">
                <label>Name</label> <label class="text-danger">*</label>
                <input type="text" class="form-control input-lg inputbox_login" formControlName="name" appInputRestriction [ngClass]="{'is-invalid': (submittedSaveGeneral && SaveAreaData.name.errors) || (requiredError && SaveAreaData.name.errors)}" maxlength="50" (input)="getMaxValue($event)"
                    (keypress)="Highlight()" (focusout)="SalesAreaValid()" />
                <div class="error">{{errorValue}}</div>
                <div *ngIf="(submittedSaveGeneral && SaveAreaData.name.errors) || (requiredError && SaveAreaData.name.errors)" class="invalid-feedback">
                    <div *ngIf="SaveAreaData.name.errors.required">Name is required</div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 ">
            <div class="form-group">
                <label>Ticket View</label>
                <mat-form-field appearance="fill">
                    <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="TicketViewRef" (selectionChange)="onchange(SaleAreaGeneralForm.value)">
                        <mat-option *ngFor="let item of tickref" [value]="item.typeId">{{item.typeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <label class="custom-color">Area Style</label><label class="custom-color">*</label>
    <div class="main-item row">
        <div class="col-sm-2 col-4">
            <div class="radiorow">
                <label class="radiolabel">Bar</label>
                <div class="custom-control custom-radio custom-control item">
                    <input formControlName="RadioButton" value="Bar" type="radio" id="AreaStyle_Bar" name="RadioButton" [(ngModel)]="RadioButton" class="custom-control-input" [ngClass]="{ 'is-invalid': (submittedSaveGeneral && SaveAreaData.RadioButton.errors) || (requiredError && SaveAreaData.RadioButton.errors)}"
                        (focusout)="SalesAreaValid()" (change)="RadioChange($event)">
                    <label class="custom-control-label QR" for="AreaStyle_Bar"></label>
                </div>
            </div>
        </div>
        <div class="col-sm-2 col-4">
            <div class="radiorow">
                <label class="radiolabel">QSR</label>
                <div class="custom-control custom-radio custom-control item">
                    <input formControlName="RadioButton" value="QRS" type="radio" id="AreaStyle_QSR" name="RadioButton" [(ngModel)]="RadioButton" class="custom-control-input" [ngClass]="{ 'is-invalid': (submittedSaveGeneral && SaveAreaData.RadioButton.errors) || (requiredError && SaveAreaData.RadioButton.errors)}"
                        (focusout)="SalesAreaValid()" (change)="RadioChange($event)">
                    <label class="custom-control-label QR" for="AreaStyle_QSR"></label>
                </div>
            </div>
        </div>
        <div class="col-sm-2 col-md-4 col-4">
            <div class="radiorow">
                <label class="radiolabel">Drive Thru</label>
                <div class="custom-control custom-radio custom-control item">
                    <input formControlName="RadioButton" value="DriveThru" type="radio" id="AreaStyle_DriveThru" name="RadioButton" [(ngModel)]="RadioButton" class="custom-control-input" [ngClass]="{ 'is-invalid': (submittedSaveGeneral && SaveAreaData.RadioButton.errors) || (requiredError && SaveAreaData.RadioButton.errors)}"
                        (focusout)="SalesAreaValid()" (change)="RadioChange($event)">
                    <label class="custom-control-label QR" for="AreaStyle_DriveThru"></label>
                </div>
            </div>
        </div>
        <!-- <div class="col-sm-2 col-4"></div> -->
    </div>
    <div *ngIf="(submittedSaveGeneral && SaveAreaData.RadioButton.errors) || (requiredError && SaveAreaData.RadioButton.errors)" class="invalid-feedback">
        <div *ngIf="SaveAreaData.RadioButton.errors.required">Please select Area Style value</div>
    </div>



    <label class="mt-3 custom-color">Ticket Behavior</label>
    <div class="row">
        <div class="col-sm-4 col-4">
            <div class="form-group">
                <label>On Payment</label>
                <mat-form-field appearance="fill">
                    <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="OnPaymentRef" (selectionChange)="onchange(SaleAreaGeneralForm.value)">
                        <mat-option *ngFor="let item of payref" [value]="item.typeId">{{item.typeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="col-sm-4 col-4">
            <div class="form-group">
                <label>On Sending</label>
                <mat-form-field appearance="fill">
                    <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="OnSendingRef" (selectionChange)="onchange(SaleAreaGeneralForm.value)">
                        <mat-option *ngFor="let item of sendref" [value]="item.typeId">{{item.typeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <label class="mt-3 custom-color">Receipt & Pricing</label>
    <div class="row">
        <div class="col-sm-2 col-md-9 col-8">
            <div class="form-group Taxable-width">
                <!-- <label>Taxable</label>
                <mat-form-field appearance="fill">
                    <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="TaxableRef" (selectionChange)="onchange(SaleAreaGeneralForm.value)">
                        <mat-option *ngFor="let item of taxref" [value]="item.typeId">{{item.typeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
                <div class="tax-slide">
                    <span class="customlable-sales" *ngIf="checkboxValue == true">Taxable</span>
                    <span class="customlable-sales" *ngIf="checkboxValue == false">Non-Taxable</span>
                    <mat-slide-toggle class="example-margin" (change)="toggle($event)" formControlName="TaxableRef" [disabled]="disabled">
                    </mat-slide-toggle>
                </div>
                <div class="tax-select dropdown-up" *ngIf="SaleAreaGeneralForm.get('TaxableRef').value == true">
                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="taxdetailsdata" (click)="onchange(SaleAreaGeneralForm.value)" [(ngModel)]="selectedItem" (onSelect)="onItemSelect($event)" (onDeSelect)="onDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                        (onDeSelectAll)="onDeSelectAll($event)" [formControl]="TaxableRefDetails">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-sm-2 col-md-4 col-7 ">
            <div class="form-group">
                <label>Cash Receipt #</label>
                <input type="text" class="form-control" placeholder="0-60" formControlName="Cash_Receipt" appInputRestriction (keypress)="numberOnly($event)" (keydown)="checkInput($event, inputText)" #inputText />
            </div>
        </div>
        <div class="col-sm-2 col-md-4 col-7 ">
            <div class="form-group">
                <label>Credit Receipt #</label>
                <input type="text" class="form-control" placeholder="0-60" formControlName="CC_Receipt" appInputRestriction (keypress)="numberOnly($event)" (keydown)="checkInput($event, inputText1)" #inputText1 />
            </div>
        </div>

        <!-- <div class="col-sm-2">
            <div class="radiorow">
                <label class="radiolabel">Auto Adjustment</label>
                <div class="form-group">
                    <div class="custom-control custom-radio custom-control item">
                        <input type="radio" formControlName="RadioButton1" id="Auto_Adjustment" name="RadioButton1"
                        value="AutoAdj" [(ngModel)]="RadioButton1" class="custom-control-input">
                        <label class="custom-control-label QR" for="Auto_Adjustment"></label>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-sm-2 col-4">
            <div class="radiorow">
                <label class="radiolabel">ItemSize Credit</label>
                <div class="form-group">
                    <div class="custom-control custom-radio custom-control item">
                        <input formControlName="RadioButton1" type="radio" id="ItemSize_Credit" name="RadioButton1"
                        value="itmsizecredit" [(ngModel)]="RadioButton1" class="custom-control-input">
                        <label class="custom-control-label QR" for="ItemSize_Credit"></label>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="col-sm-2 col-md-4">
            <div class="radiorow">
                <div class="form-group">
                    <div class="checkbox-table accordion-checkbox">
                        <label class="radiolabel">Auto Adjustment</label>
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" formControlName="Auto_Adjustment" id="Auto_Adjustment" name="Auto_Adjustment" value="AutoAdj" [(ngModel)]="RadioButton1" class="custom-control-input">
                            <label class="custom-control-label QR" for="Auto_Adjustment"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tax-select menu-sel" *ngIf="SaleAreaGeneralForm.get('Auto_Adjustment').value == true">
                <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="Adjustment_ID" (selectionChange)="onchange(SaleAreaGeneralForm.value)">
                    <input class="form-control input-lg inputbox_login " placeholder="Search here" (keyup)="search($event.target.value,i)" appInputRestriction />
                    <mat-option *ngFor="let item of adjustData" [value]="item.key">{{item.value}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="col-sm-2 col-md-4">
            <div class="radiorow">
                <div class="form-group">
                    <div class="checkbox-table accordion-checkbox">
                        <label class="radiolabel">Itemized Credit</label>
                        <div class="custom-control custom-checkbox custom-control item">
                            <input formControlName="ItemSize_Credit" type="checkbox" id="ItemSize_Credit" name="ItemSize_Credit" value="itmsizecredit" class="custom-control-input">
                            <label class="custom-control-label QR" for="ItemSize_Credit"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="form-group">
        <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveSaleGeneral();"
        [disabled]="buttonDisable ? true : null" >

            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label>
        </button>
    </div> -->
</form>
