<div class="house_wrap">
    <form class="giftform loginForm" [formGroup]="GiftCardForm" (change)="onchange(GiftCardForm.value)">
        <div class="row">
            <div class="col-sm-2">
                <div class="d-block d-sm-block d-md-none">
                    <div class="form-group gft-crd-gen">
                        <img src="assets/components/images/gift-card-temp.jpg" alt="employee" class="gift-icon" (click)="editGiftCard($event)" />
                        <i class="fa fa-pencil-square-o edit-icon-align" aria-hidden="true" (click)="editGiftCard($event)"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4" class="disp-none">
                <div class="form-group">
                    <label class="customlable mb-0">Name</label
          ><label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="name" appInputRestriction placeholder="Name" [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.name.errors) ||
                (requiredError && GeneralData.name.errors)
            }" maxlength="50" (input)="getMaxValueName($event)" (keypress)="Highlight()" (focusout)="GiftValid()" />
                    <div class="error">{{ errorValue }}</div>
                    <div *ngIf="
              (submittedGeneral && GeneralData.name.errors) ||
              (requiredError && GeneralData.name.errors)
            " class="invalid-feedback">
                        <div *ngIf="GeneralData.name.errors.required">Name is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Business Name</label
          ><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="BusinessName" placeholder="Business Name" appInputRestriction [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.BusinessName.errors) ||
                (requiredError && GeneralData.BusinessName.errors)
            }" maxlength="50" (input)="getMaxValueBName($event)" (keypress)="Highlight()" (focusout)="GiftValid()" />
                    <div class="error">{{ errorValue1 }}</div>
                    <div *ngIf="
              (submittedGeneral && GeneralData.BusinessName.errors) ||
              (requiredError && GeneralData.BusinessName.errors)
            " class="invalid-feedback">
                        <div *ngIf="GeneralData.BusinessName.errors.required">
                            Business Name is required
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 col-4" *ngIf="hideCopyFrom == false">
                <div class="form-group">
                    <label class="customlable mb-0">Copy From</label>
                    <mat-form-field appearance="fill">
                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="copyFrom" placeholder="Select Gift Card" (selectionChange)="onCategorySelected($event)">
                            <mat-option *ngFor="let item of copyFromDropDown" [value]="item.id">{{ item.name }}
                            </mat-option>
                            <!-- <mat-option [value]="" selected disabled>Select store</mat-option> -->
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Phone</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                <img
                  src="assets/components/images/USA.png"
                  alt="employee"
                  class="flag_img"
                />+1
              </span>
                        </div>
                        <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="phone" placeholder="(xxx) xxx-xxxx" maxLength="14" (keypress)="numberOnly2($event)" (keydown)="onDeletePhone($event)" (focusout)="phoneNumberCheck($event)" [ngClass]="{
                'is-invalid':
                  (GiftCardForm.controls['phone'].errors &&
                    submittedGeneral &&
                    !isValidFormSubmitted) ||
                  mgs != ''
              }" appInputRestriction />
                    </div>
                    <div class="error">{{ mgs }}</div>
                    <div *ngIf="
              GiftCardForm.controls['phone'].errors &&
              submittedGeneral &&
              !isValidFormSubmitted
            " [ngClass]="'error'">
                        <div *ngIf="GiftCardForm.controls['phone'].errors.pattern && mgs == ''">
                            Mobile Number not valid.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Contact Email</label
          ><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="ContactEmail" placeholder="&#x2709; Example@myemailaddress.com" appInputRestriction [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.ContactEmail.errors) ||
                (requiredError && GeneralData.ContactEmail.errors)
            }" maxlength="100" (input)="getMaxValueEmail($event)" (keypress)="Highlight()" (focusout)="GiftValid()" />
                    <div class="error">{{ errorValue4 }}</div>
                    <div *ngIf="
              (submittedGeneral && GeneralData.ContactEmail.errors) ||
              (requiredError && GeneralData.ContactEmail.errors)
            " class="invalid-feedback">
                        <!-- <div *ngIf="f.email.errors.required">Email is required</div> -->
                        <div *ngIf="GeneralData.ContactEmail.errors.email">
                            Email must be a valid email address
                        </div>
                        <div *ngIf="GeneralData.ContactEmail.errors.required">
                            Email is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="d-none d-md-block d-lg-block d-xl-block d-sm-none">

                    <div class="form-group gift-icon">
                        <img src="assets/components/images/gift-card-temp.jpg" alt="employee" class="gift-icon" (click)="editGiftCard($event)" />
                        <i class="fa fa-pencil-square-o edit-icon-align" aria-hidden="true" (click)="editGiftCard($event)"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <!-- <div class="form-group">
                    <label class="customlable mb-0">First Name</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="ContactFirstName"
                        placeholder="First Name" appInputRestriction
                        [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.ContactFirstName.errors }" />
                    <div *ngIf="submittedGeneral && GeneralData.ContactFirstName.errors" class="invalid-feedback">
                        <div *ngIf="GeneralData.ContactFirstName.errors.required">First Name is required</div>
                    </div>
                </div> -->
                <div class="form-group">
                    <label class="customlable mb-0">First Name</label>
                    <label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="ContactFirstName" appInputRestriction placeholder="First Name" [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.ContactFirstName.errors) ||
                (requiredError && GeneralData.ContactFirstName.errors)
            }" maxlength="50" (input)="getMaxValueFName($event)" (keypress)="Highlight()" (focusout)="GiftValid()" />
                    <div class="error">{{ errorValue2 }}</div>
                    <div *ngIf="
              (submittedGeneral && GeneralData.ContactFirstName.errors) ||
              (requiredError && GeneralData.ContactFirstName.errors)
            " class="invalid-feedback">
                        <div *ngIf="GeneralData.ContactFirstName.errors.required">
                            First Name is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Last Name</label
          ><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="ContactLastName" placeholder="Last Name" appInputRestriction [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.ContactLastName.errors) ||
                (requiredError && GeneralData.ContactLastName.errors)
            }" maxlength="50" (input)="getMaxValueLName($event)" (keypress)="Highlight()" (focusout)="GiftValid()" />
                    <div class="error">{{ errorValue3 }}</div>
                    <div *ngIf="
              (submittedGeneral && GeneralData.ContactLastName.errors) ||
              (requiredError && GeneralData.ContactLastName.errors)
            " class="invalid-feedback">
                        <div *ngIf="GeneralData.ContactLastName.errors.required">
                            Last Name is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Address</label
          ><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="Address" placeholder="Address" appInputRestriction [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.Address.errors) ||
                (requiredError && GeneralData.Address.errors)
            }" (keypress)="Highlight()" (focusout)="GiftValid()" maxlength="100" />
                    <div *ngIf="
              (submittedGeneral && GeneralData.Address.errors) ||
              (requiredError && GeneralData.Address.errors)
            " class="invalid-feedback">
                        <div *ngIf="GeneralData.Address.errors.required">
                            Address is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">City</label
              ><label class="text-danger req_label">*</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="City" placeholder="City" appInputRestriction [ngClass]="{
                  'is-invalid':
                    (submittedGeneral && GeneralData.City.errors) ||
                    (requiredError && GeneralData.City.errors)
                }" maxlength="50" (input)="getMaxValueCity($event)" (keypress)="Highlight()" (focusout)="GiftValid()" />
                            <div class="error">{{ errorValue5 }}</div>
                            <div *ngIf="
                  (submittedGeneral && GeneralData.City.errors) ||
                  (requiredError && GeneralData.City.errors)
                " class="invalid-feedback">
                                <div *ngIf="GeneralData.City.errors.required">
                                    City is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">State</label
              ><label class="text-danger req_label">*</label>
                            <!-- <input type="text" class="form-control input-lg inputbox_login" formControlName="State"  placeholder="MT"
                                [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.State.errors }" /> -->
                            <div class="state-placeholder" (focusout)="GiftValid()">
                                <mat-select (openedChange)="matstate($event)" class="form-control" [disableOptionCentering]="true" #select1 formControlName="State" placeholder="Select State" (selectionChange)="Highlight()" [ngClass]="{
                    'is-invalid':
                      (submittedGeneral && GeneralData.State.errors) ||
                      (requiredError && GeneralData.State.errors)
                  }">
                                    <input class="form-control input-lg inputbox_login" placeholder="Search here" (keyup)="onKey($event.target.value)" />

                                    <mat-option *ngFor="let item of filteredList2" [value]="item.id">
                                        {{ item.id }}
                                    </mat-option>
                                    <mat-option *ngIf="filterNodata" [value]="">No data Found</mat-option>
                                </mat-select>
                                <div *ngIf="
                    (submittedGeneral && GeneralData.State.errors) ||
                    (requiredError && GeneralData.State.errors)
                  " class="invalid-feedback">
                                    <div *ngIf="GeneralData.State.errors.required">
                                        State is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">Zip</label
              ><label class="text-danger req_label">*</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="Zip" placeholder="Zip" [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" [ngClass]="{
                  'is-invalid':
                    (submittedGeneral && GeneralData.Zip.errors) ||
                    (requiredError && GeneralData.Zip.errors) ||
                    zipCodeMsg != ''
                }" (keypress)="Highlight()" (focusout)="GiftValid()" (focusout)="zipCodeCheck($event)" (keydown)="onDeleteZipCode($event)" />
                            <div class="error">{{ zipCodeMsg }}</div>
                            <div *ngIf="
                  (submittedGeneral && GeneralData.Zip.errors) ||
                  (requiredError && GeneralData.Zip.errors)
                " class="invalid-feedback">
                                <div *ngIf="GeneralData.Zip.errors.required">
                                    Zip is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-groupcard-header border-bottom-0 bg-transparent">
                    <label class="customlable mb-0">GateWay</label>
                    <!-- <mat-button-toggle-group name="fontStyle" aria-label="Font Style" value="Remarktoggle">
                        <mat-button-toggle class="card-header form-control input-lg inputbox_login"
                            forControlName="remarkStyle" value="Remarktoggle">Flip Gift
                        </mat-button-toggle>
                        <mat-button-toggle class="card-header form-control input-lg inputbox_login"
                            forControlName="remarkStyle" value="Commenttoggle">
                            Valuetec</mat-button-toggle>
                    </mat-button-toggle-group> -->
                    <mat-form-field appearance="fill">
                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="GatewaySelection" (selectionChange)="SelectGateway($event)">
                            <mat-option *ngFor="let item of gatewayList" [value]="item.typeId">{{ item.typeName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-sm-4" *ngIf="GiftCardForm.get('GatewaySelection').value == 'GTW3'">
                <!-- <div class="form-group">
                    <label class="customlable mb-0">Secret key</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="SecretKey"
                        placeholder="Secret key" appInputRestriction
                        [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.SecretKey.errors) || (requiredError && GeneralData.SecretKey.errors)}"  (keypress)="Highlight()" (focusout)="GiftValid()"/>
                    <div *ngIf="(submittedGeneral && GeneralData.SecretKey.errors) || (requiredError && GeneralData.SecretKey.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.SecretKey.errors.required">Secret key is required</div>
                    </div>
                </div> -->
                <div class="form-group">
                    <label class="customlable mb-0">PrePaid Gift MID</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="prepaidGiftMID" placeholder="PrePaid Gift MID" appInputRestriction (keypress)="Highlight()" (focusout)="GiftValid()" readonly />
                </div>
            </div>
            <div class="col-sm-4" *ngIf="GiftCardForm.get('GatewaySelection').value == 'GTW3'">
                <!-- <div class="form-group">
                    <label class="customlable mb-0">Public key</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="PublicKey"
                        placeholder="Public key" appInputRestriction
                        [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.PublicKey.errors) || (requiredError && GeneralData.PublicKey.errors)}"  (keypress)="Highlight()" (focusout)="GiftValid()"/>
                    <div *ngIf="(submittedGeneral && GeneralData.PublicKey.errors) || (requiredError && GeneralData.PublicKey.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.PublicKey.errors.required">Public key is required</div>
                    </div>
                </div> -->
                <div class="form-group">
                    <label class="customlable mb-0">PrePaid Token Key</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="prepaidGiftTokenKey" placeholder="PrePaid Token Key" appInputRestriction (keypress)="Highlight()" (focusout)="GiftValid()" readonly />
                </div>
            </div>
        </div>
        <div class="row gft-gen-resp">
            <div class="col-sm-8 col-8 mt-2" *ngIf="GiftCardForm.get('GatewaySelection').value">
                <div class="checkbox-table accordion-checkbox">
                    <div class="custom-control custom-checkbox custom-control item">
                        <input type="checkbox" class="custom-control-input" name="customCheckbox" formControlName="MultiStore" id="MultiStore" value="multiStore" />
                        <label class="custom-control-label mb-0">Multi Locations</label>
                        <span class="store-pre-text">(Enable if this store shares gift cards with other stores)</span
            >
          </div>
        </div>
        <div class="checkbox-table accordion-checkbox">
          <div class="custom-control custom-checkbox custom-control item">
            <input
              type="checkbox"
              class="custom-control-input"
              name="customCheckbox"
              formControlName="AllowTipping"
              id="AllowTipping"
              value="allowTip"
            />
            <label class="custom-control-label mb-0">Allow Tipping</label>
            <span class="store-pre-text"
              >(Allows tips to be added similar to a credit card)</span
            >
          </div>
        </div>
      </div>
      <div
        class="col-sm-4 col-4"
        *ngIf="this.GiftCardForm.get('MultiStore').value"
      >
        <div class="form-group">
          <label class="customlable mb-0">Originating Store</label>
          <mat-form-field appearance="fill">
            <mat-select (openedChange)="matstate($event)"
              type="text"
              class="form-control"
              formControlName="primaryStore"
              placeholder="Select store"
            >
              <mat-option *ngFor="let item of primarystoreSel" [value]="item.id"
                >{{ item.name }}
              </mat-option>
              <!-- <mat-option [value]="" selected disabled>Select store</mat-option> -->
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div
        class="col-sm-12 col-12 mt-2"
        *ngIf="GiftCardForm.get('GatewaySelection').value"
      >
        <div class="checkbox-table accordion-checkbox">
          <div class="custom-control custom-checkbox custom-control item">
            <input
              type="checkbox"
              class="custom-control-input"
              name="customCheckbox"
              formControlName="Add2ndTipLine"
              id="Add2ndTipLine"
              value="tipLine"
              [checked]="isTip2Checked"
              (change)="tiptoggleIsActive(isTip2Checked)"
            />
            <label class="custom-control-label mb-0">Add 2ND Tip Line</label>
            <span class="store-pre-text"
              >(Prints an additional tip line for donations.)</span
            >
          </div>
        </div>

        <div
          class="col-sm-12 col-12"
          *ngIf="GiftCardForm.get('Add2ndTipLine').value"
        >
          <div formArrayName="secondTip">
            <div class="row no-gutters">
              <!-- <div class="col-sm-1 col-1 disp-none">
                            <button class="plus_btn inshadow mb-10" (click)="addTip2Multi()"><i class="fa fa-plus"></i></button>
                        </div> -->
              <div class="col-sm-3 col-3">
                <label class="customlable mb-0 pd-bottom5"
                  >Text on receipt</label
                >
              </div>
              <div class="col-sm-7 col-7 pl-15">
                <label class="Labels-txt mb-0 pd-bottom5"
                  >Is Gratuity?<span class="store-pre-text"
                    >(Enabling this will treat the 2nd tip line as gratuity.<br />
                    If not it will be treated as additional cash received to the
                    house.)</span
                  ></label
                >
              </div>
            </div>
            <div class="inline-form-group">
              <div
                class="row no-gutters"
                *ngFor="
                  let $secondTip of GiftCardForm.get('secondTip')['controls'];
                  let i = index
                "
                [formGroup]="$secondTip"
                autocomplete="off"
              >
                <!-- <div class="col-sm-1 col-1"></div>  -->
                <div class="col-sm-3 col-3">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control input-lg inputbox_login custom-tipInp"
                      formControlName="textonReceipt"
                      placeholder="Tip 2"
                    />
                  </div>
                </div>
                <div class="col-sm-1 col-1 pl-15">
                  <div class="checkbox-table custom-check accordion-checkbox">
                    <div
                      class="custom-control custom-checkbox custom-control item"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        name="customCheckbox"
                        formControlName="gratuity"
                      />
                      <label
                        class="custom-control-label disp-blck mb-10 top-grat"
                      ></label>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-sm-1 col-1  disp-none">
                                <i class="fa fa-remove" aria-hidden="true" (click)="DeleteTip(i)"></i>
                            </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="checkbox-table accordion-checkbox">
          <div class="custom-control custom-checkbox custom-control item">
            <input
              type="checkbox"
              class="custom-control-input"
              name="customCheckbox"
              formControlName="E_GIFT"
              id="E_GIFT"
              value="e-Gift"
            />
            <label class="custom-control-label mb-0">E-Gift</label>
            <span class="store-pre-text"
              >(Allows for electronic ordering of gift cards, by app,
              Apple/Google Wallet. Email, online ordering or Text message)</span
            >
          </div>
        </div>
        <!-- <div
          class="col-sm-2 col-2 pl-0"
          *ngIf="GiftCardForm.get('GatewaySelection').value == 'GTW3'"
        >
          <div class="emp-time">
            <label class="hide_report disp-none">xxx</label>
            <button
              id="0"
              class="btn submit_button form-control timeEditBtn"
              type="submit"
              (click)="onApiCall()"
            >
              SUBMIT
            </button>
          </div>
        </div> -->
        <div class="form-group">
          <p class="custom-ft disp-none">DataCap Response - BoardMerchant</p>
          <p
            class="mb-0 mt-15"
            [innerHTML]="xmlDatDisplay"
            [ngClass]="{
              'success-green': successval == true,
              'fail-red': successval == false
            }"
          ></p>
        </div>
      </div>

      <div
        class="col-sm-8 col-8 mt-2"
        *ngIf="GiftCardForm.get('GatewaySelection').value == 'GTW2'"
      >
        <div class="checkbox-table accordion-checkbox">
          <div class="custom-control custom-checkbox custom-control item">
            <input
              type="checkbox"
              class="custom-control-input"
              name="customCheckbox"
              formControlName="offLineMode"
              id="offLineMode"
              value="offLineMode"
            />

            <label class="custom-control-label mb-0">Offline Mode</label>
            <span class="store-pre-text"
              >(Store and forward Gift card transactions during internet
              outage)</span
            >
          </div>
        </div>
      </div>
      <div
        class="col-sm-8 col-8 offlie-alert-msg"
        *ngIf="
          GiftCardForm.get('offLineMode').value &&
          GiftCardForm.get('GatewaySelection').value == 'GTW2'
        "
      >
        <div class="alert-msg">
          <span class="store-pre-text"
            ><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            (Enabling this will put the business at a risk of taking a gift card
            that is bad or does not have the funds available to cover the
            expense)</span
          >
        </div>
      </div>
    </div>
    <!-- <div class="row">
            <div class="col-sm-12 col-12 mt-1">
                <label>Allows for remote ordering of Gift Cards, by app, email or online ordering</label>
            </div>
        </div> -->
    <div class="row">
      <div
        class="col-sm-12 col-12 mt-1"
        *ngIf="
          GiftCardForm.get('offLineMode').value &&
          GiftCardForm.get('GatewaySelection').value == 'GTW2'
        "
      >
        <div class="checkbox-table accordion-checkbox pl-4">
          <div class="custom-control custom-checkbox custom-control item">
            <input
              type="checkbox"
              class="custom-control-input"
              name="customCheckbox"
              formControlName="autometicFailover"
              id="autometicFailover"
              value="autometicFailover"
            />
            <label class="custom-control-label mb-0">Automatic Failover</label>
            <span class="store-pre-text"
              >(Requires no user intervention to accept gift card offline)</span
            >
          </div>
        </div>
      </div>
      <div
        class="col-sm-12 col-12 mt-1"
        *ngIf="
          GiftCardForm.get('offLineMode').value &&
          GiftCardForm.get('GatewaySelection').value == 'GTW2'
        "
      >
        <div class="pl-4 ml-4">
          <div class="checkbox-table accordion-checkbox">
            <div class="custom-control custom-checkbox custom-control item">
              <input
                type="checkbox"
                class="custom-control-input"
                name="customCheckbox"
                formControlName="riskRestriction"
                id="riskRestriction"
                value="riskRestriction"
              />
              <label class="custom-control-label mb-0">Risk Restrictions</label>
              <span class="store-pre-text"
                >(Sets limits on liability during an offline event)</span
              >
            </div>
          </div>
        </div>
        <div
          class="pl-4 ml-4"
          *ngIf="
            GiftCardForm.get('riskRestriction').value &&
            GiftCardForm.get('offLineMode').value &&
            GiftCardForm.get('GatewaySelection').value == 'GTW2'
          "
        >
          <div class="row m-0 gft-rr-gen">
            <div class="col-sm-2 col-2 mt-1 p-0">
              <div class="form-group">
                <label class="customlable mb-0 pd-bottom5"
                  >No of Transactions
                </label>
                <input
                  type="number"
                  (keypress)="numberOnly($event)"
                  class="form-control input-lg inputbox_login"
                  placeholder="# of Transactions"
                  formControlName="noOfTranscation"
                />
              </div>
            </div>
            <div class="col-sm-2 col-2 mt-1 p-0 ml-3">
              <div class="form-group">
                <label class="customlable mb-0 pd-bottom5">Amount</label>
                <input
                  type="text"
                  class="form-control input-lg inputbox_login"
                  placeholder="$ Amount"
                  formControlName="amount"
                  appDecimalLimit
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <button
          class="btn btn-sm float-right submit_button button-none"
          type="submit"
          (click)="onSaveGeneral()"
          [disabled]="buttonDisable ? true : null"
        >
          <!-- [disabled]="buttonState ? true : null" -->
          <img
            src="assets/components/images/save.png"
            alt="employee"
            class=""
          />
          <label class="label_config">Save</label>
        </button>
      </div>
    </div>
  </form>
  <div
    class="d-flex justify-content-center ccgateway-preloader"
    *ngIf="isLoader == true"
  >
    <div class="spinner-border text-white" role="status"></div>
  </div>
</div>
<!-- ngx-intl -->
<!-- <ngx-intl-tel-input [cssClass]="'form-control country-tel-code'" [enableAutoCountrySelect]="true"
[enablePlaceholder]="true" [preferredCountries]="preferredCountries" [searchCountryFlag]="true"
[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
[selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10"
[tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
[separateDialCode]="separateDialCode" formControlName="phone">
</ngx-intl-tel-input> -->
<!-- ngx-intl -->