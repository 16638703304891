import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  Input,
} from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { EmployeeService } from 'src/app/services/employee.service';
import {
  FormBuilder,
  Validators,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import {
  IActiveUser,
  GetAllUserList,
  IListInfo,
  IHierarchySubject,
  ISelectState,
} from 'src/app/interfaces/iadmin';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from 'ngx-intl-tel-input';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';
import { browserRefresh } from 'src/app/app.component';
import { GiftcardService } from 'src/app/services/giftcard.service';
import { EnvService } from 'src/app/env.service';

//---------------------------------Calendar Format Beginning---------------------------------//
const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
//---------------------------------Calendar Format End---------------------------------//

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css'],
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class StoreComponent implements OnInit {
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public StoreSessData = new EventEmitter();
  @Output() public storeData = new EventEmitter();
  indexExpanded: number = 1;
  public displayedColumns = [];
  public EmployeeTableData: any;
  columns = [];
  selectedPreItem: any;
  GeneralStoreForm: FormGroup;
  LiveDate: Date;
  submittedGeneral = false;
  allstoredetails = [];
  format = 'h:mm:ss a';
  userId: any;
  checked = [false];
  storeList = [];
  loginInfo: any;
  public dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  tableEnable: boolean = true;
  tenantTypeID: any;
  CompId: any;
  eodTime = new Date();
  separateDialCode = true;
  isInvalid: boolean = false; //Accordion Invaild Color//
  requiredErrors: boolean = false; //FocusOut Accordion Invaild Color//
  errorValue: any; //Store Name MAX CHARACTER LIMIT//
  errorValue1: any; //Contact First Name MAX CHARACTER LIMIT//
  errorValue2: any; //Contact Last Name MAX CHARACTER LIMIT//
  errorValue3: any; //Contact Email MAX CHARACTER LIMIT//
  errorValue5: any; //City MAX CHARACTER LIMIT//
  errorValue6: any; //State MAX CHARACTER LIMIT//
  dateMgs: any;
  minDate: any;
  img: boolean = true;
  private onDestroy$: Subject<void> = new Subject<void>();

  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  companyValue: any;
  public storeRow: any;
  @Input() public storeIdItem;
  @Input() public buttonDisable;
  filteredList2: any = [];
  filteredTimeZoneList: any = [];
  public variables: any = [];
  public variablesTZ: any = [];
  clickUpdated: any;
  testdata: [];
  mgs: any = '';
  public tableRowColor: any;
  filterNodata: any;
  filterNodataTZ: any;
  zipCodeMsg: any = '';
  mobNumberPattern = '^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$';
  isValidFormSubmitted = false;
  public modifierItemsData: any;
  imgUrl: any;
  imageUrl: any = 'assets/components/images/picture-upload-2-x.png';
  ImageFile: any;
  matopen:boolean=false;
  matstate($event){
    this.matopen = $event
  }

  constructor(
    private storeDetails: AdminService,
    private empDetails: EmployeeService,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private toaster: ToasterService,
    private helper: HelperService,
    private dialogser: DialogService,
    private objImageUpload: GiftcardService,
    private env: EnvService
  ) { }

  get GeneralData() {
    return this.GeneralStoreForm.controls;
  }

  ngOnInit(): void {
    if (this.imageUrl) {
      this.format = 'image';
    }
    localStorage.setItem('browserPage', 'this.storePage');
    this.imgUrl = 'assets/components/images/180_180.png';
    this.tableRowColor = 'transparent';
    this.employeeName = 'Store Name';
    this.employee = 'Store';
    this.employeeIcon = 'assets/components/images/home3x.png';
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
    this.minDate = new Date(1900, 0, 1);

    this.GeneralStoreForm = this.formBuilder.group({
      tenantId: [0],
      tenantName: [null, Validators.required],
      contactPhone: new FormControl(undefined),
      contactFirstName: [null],
      contactLastName: [null],
      //contactEmail: [null, [Validators.email, Validators.pattern('^[a-zA-Z0-9._%#!$%^&*+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]{2,4}$')]],
      contactEmail: ['', Validators.email],
      address: [null],
      city: [null],
      state: [null],
      zipCode: [null],
      timeZone: [null],
      liveDate: new FormControl(moment().toDate()),
      eodTime: [null],
      smsMessaging: [false],
      offlineTransaction: [false],
      swipeCards: [false],
      timePunchPrints: [false],
      allowFutureOrders: [false],
      signatureCapture: [false],
      emailedReceipts: [false],
      daylightSavings: [false],
      storeLogo: [],
      roundTax: [false],
      isActive: [true],
      isDemo: [true],
      isDeleted: [false],
    });
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.tenantTypeID = this.loginInfo.tenantType;

    if (
      browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" ||
      localStorage.getItem('isCompanySelect') == '' ||
      localStorage.getItem('isCompanySelect') === null
    ) {
      if (
        localStorage.comp_ID != undefined &&
        localStorage.comp_ID != null &&
        localStorage.comp_ID != ''
      ) {
        this.getDetailedData(localStorage.getItem('comp_ID'));
      }

      if (
        localStorage.str_ID != undefined &&
        localStorage.str_ID != null &&
        localStorage.str_ID != ''
      ) {
        this.tableRowColor = localStorage.getItem('str_ID');
        this.storeRow = localStorage.getItem('str_ID');
        this.GetStoreList(localStorage.getItem('str_ID'));
      }
    } else {
      // this.storeDetails.HierachyValue.subscribe(data => this.companyValue = data);
      // this.empDetails.clickValues.subscribe(data => this.clickUpdated = data);
      this.empDetails.clickValues
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((data) => {
          this.clickUpdated = data;
          if (
            this.clickUpdated != undefined &&
            this.clickUpdated != null &&
            this.clickUpdated != ''
          ) {
            if (
              this.clickUpdated.clickItem != undefined &&
              this.clickUpdated.clickItem != null &&
              this.clickUpdated.clickItem != ''
            ) {
              this.selectedPreItem = this.clickUpdated.clickItem.id;
            }
          }
        });

      this.storeDetails.HierachyValue.pipe(
        takeUntil(this.onDestroy$)
      ).subscribe((data) => {
        this.isInvalid = false;
        this.requiredErrors = false;
        this.dateMgs = '';
        this.storeRow = data;
        if (
          this.storeRow.store != undefined &&
          this.storeRow.store != null &&
          this.storeRow.store != ''
        ) {
          this.storeIdItem = this.storeRow;
          this.tableRowColor = this.storeRow;
        }
      });

      if (this.tenantTypeID == 2) {
        this.getDetailedData(this.storeRow.company.id);
      } else {
        let CompanyID =
          this.storeRow.company != undefined &&
            this.storeRow.company != null &&
            this.storeRow.company != ''
            ? this.storeRow.company
            : 0;
        if (CompanyID == 0) {
          this.toaster.hierarchyPopUp(
            'Please Select Company Before Selecting Store'
          );
          this.buttonDisable = true;
        } else {
          this.buttonDisable = false;
          this.getDetailedData(this.storeRow.company.id);
        }
      }
    }

    this.onSelectState();
    this.onSelectTimeZone();
    //  this.getDetailedData(this.loginInfo.tenantId);
    // this.getDetailedData(this.storeRow.company.id);
  }

  deleteimage() {
    this.ImageFile = null;
    this.img = false;
    this.imgUrl = 'assets/components/images/180_180.png';
    this.onchange(this.GeneralStoreForm.value);
  }

  onSelectFile(event: any) {
    console.log(event.target.files);
    if (event.target.files.length != 0) {
      this.imgUrl = null;
      this.ImageFile = event.target.files && event.target.files[0];
      // this.fileInfo = file;

      if (this.ImageFile) {
        var reader = new FileReader();
        reader.readAsDataURL(this.ImageFile);
        if (this.ImageFile.type.indexOf('image') > -1) {
          this.format = 'image';
        }
        reader.onload = (event) => {
          this.imgUrl = (<FileReader>event.target).result;
        };
        this.img = true
      }
    }
    else {

      this.imageUrl = 'assets/components/images/180_180.png'
    }
  }

  get GenStoreData() {
    return this.GeneralStoreForm.controls;
  }
  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  //---------------------------------ADD NEW STORE START---------------------------------//

  expandPanel(index, e?) {
    this.indexExpanded = index;
  }
  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? 1 : index;
    }
  }
  onChangeAccordion(e, index) {
    this.indexExpanded = index;
  }

  async addStore() {
    this.errorValue = ''; //Store Name MAX CHARACTER LIMIT//
    this.errorValue1 = ''; //Contact First Name MAX CHARACTER LIMIT//
    this.errorValue2 = ''; //Contact Last Name MAX CHARACTER LIMIT//
    this.errorValue3 = ''; //Contact Email MAX CHARACTER LIMIT//
    this.errorValue5 = ''; //City MAX CHARACTER LIMIT//
    this.errorValue6 = ''; //State MAX CHARACTER LIMIT//
    this.isInvalid = false; //Accordion Invaild Color//
    this.requiredErrors = false; //FocusOut Accordion Invaild Color//

    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
      if (this.indexExpanded != undefined && this.indexExpanded != 1) {
        this.expandFirst('General', 1);
      }
    }
    // this.clickUpdated.clickItem.id = 0
    else {
      var conf = await this.dialogser.confirm('storePage');
      if (conf == 'success') {
        // Navigate Away
        this.ClearGenData();
        if (this.indexExpanded != undefined && this.indexExpanded != 1) {
          this.expandFirst('General', 1);
        }
      } else {
        this.GeneralStoreForm.patchValue(
          this.helper.getValue('StoreData_Help')
        );
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        if (!this.GeneralStoreForm.invalid) {
          this.dateMgs = '';
          this.requiredErrors = false;
          this.isInvalid = false;
          return;
        }
      }
    }
  }

  ClearGenData() {
    localStorage.setItem('str_ID', '');
    localStorage.setItem('str_name', '');
    localStorage.setItem('isStoreSelect', '');

    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = 'transparent';
    this.requiredErrors = false;
    this.isInvalid = false;
    this.mgs = '';
    this.GeneralStoreForm.reset();
    this.employeeName = 'Store Name';
    this.tableRowColor = 'transparent';
    this.helper.setValue('StoreData_Help', null);
    this.submittedGeneral = false;
    this.GeneralStoreForm.patchValue({ isActive: true, tenantId: 0 });
    this.GeneralStoreForm.patchValue({ liveDate: moment(new Date()) });
    this.selectedPreItem = 0;
    this.zipCodeMsg = '';
    this.dateMgs = ''; // Calendar message reset
    this.titleName.emit(this.employeeName);
    this.storeData.emit({ name: 'StoreName' });
    this.imgUrl = 'assets/components/images/180_180.png';
  }

  //---------------------------------ADD NEW STORE END---------------------------------//

  getTableName(name) {
    this.employeeName = name;
    this.employee = 'Store';
    this.employeeIcon = 'assets/components/images/home3x.png';
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
  }

  onKeypressEvent(elem): boolean {
    const charCode = elem.which ? elem.which : elem.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (
      elem.target.value.length < 3 ||
      elem.target.value.length > 3 ||
      elem.target.value.length < 11
    ) {
      if (elem.key === '-') elem.preventDefault();
    }
    // this.GeneralCompanyForm.get("zipCode").patchValue(elem.target.value.toUpperCase());
    if (elem.target.value.length <= 10) {
      if (elem.target.value.length === 5) {
        elem.target.value = `${elem.target.value}-`;
      }
      this.zipCodeMsg = '';
    } else {
      elem.target.value = elem.target.value.slice(0, -1);
      this.zipCodeMsg = '';
    }
    return true;
  }

  //--------------------------------- SAVE  ---------------------------------//
  onSaveGeneral() {
    this.errorValue = ''; //Store Name MAX CHARACTER LIMIT//
    this.errorValue1 = ''; //Contact First Name MAX CHARACTER LIMIT//
    this.errorValue2 = ''; //Contact Last Name MAX CHARACTER LIMIT//
    this.errorValue3 = ''; //Contact Email MAX CHARACTER LIMIT//
    this.errorValue5 = ''; //City MAX CHARACTER LIMIT//
    this.errorValue6 = ''; //State MAX CHARACTER LIMIT//
    if (
      this.GeneralStoreForm.value.tenantName == null ||
      this.GeneralStoreForm.value.tenantName == ''
    ) {
      this.isInvalid = true;
      this.requiredErrors = true;
    } else {
      this.isInvalid = false;
      this.requiredErrors = false;
    }

    this.GeneralStoreForm.controls.tenantName.setValidators(
      Validators.required
    );
    this.GeneralStoreForm.controls.tenantName.updateValueAndValidity();
    this.submittedGeneral = true;
    if (
      browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" ||
      localStorage.getItem('isCompanySelect') == '' ||
      localStorage.getItem('isCompanySelect') === null
    ) {
      this.GeneralStoreForm.patchValue({
        tenantId: localStorage.getItem('str_ID'),
      });
      this.CompId = localStorage.getItem('comp_ID');
    } else {
      this.CompId =
        this.storeRow.company.id != undefined &&
          this.storeRow.company.id != null &&
          this.storeRow.company.id != ''
          ? this.storeRow.company.id
          : 0;
    }

    if (this.GeneralStoreForm.invalid) {
      this.isInvalid = true;
      if (this.GeneralStoreForm.controls.liveDate.invalid) {
        this.dateMgs = 'Invalid Date';
        return;
      } else {
        this.dateMgs = '';
      }
      return;
    } else {
      if (
        this.GeneralStoreForm.controls.contactPhone.value != undefined &&
        this.GeneralStoreForm.controls.contactPhone.value != null &&
        this.GeneralStoreForm.controls.contactPhone.value != ''
      ) {
        if (this.GeneralStoreForm.controls.contactPhone.value.length <= 12) {
          this.mgs = 'Phone number must be at least 10 digit';
          return;
        } else {
          this.mgs = '';
        }
      } else {
        this.mgs = '';
      }
    }
    if (
      this.GeneralStoreForm.controls.zipCode.value != undefined &&
      this.GeneralStoreForm.controls.zipCode.value != null &&
      this.GeneralStoreForm.controls.zipCode.value != ''
    ) {
      if (this.GeneralStoreForm.controls.zipCode.value.length === 10) {
        if (this.GeneralStoreForm.controls.zipCode.value.slice(5, 6) != '-') {
          this.zipCodeMsg = 'Invalid Zip Code';
          return;
        }
      }
      if (this.GeneralStoreForm.controls.zipCode.value.length <= 4) {
        this.zipCodeMsg = 'Please enter min 5 digits zipcode';
        return;
      } else {
        this.zipCodeMsg = '';
      }
    } else {
      this.zipCodeMsg = '';
    }
    const store = this.GeneralStoreForm.value;
    store.sectionId = 1;
    //store.tenantId = parseInt(this.tenantId) > 0 ? parseInt(this.tenantId) : 0;
    store.tenantTypeId = 4;
    store.parentId = this.CompId;

    store.tenantName = store.tenantName.trim();
    if (store.tenantName == '') {
      this.toaster.errorPopUp('Please Enter the Store Name');
      return;
    }

    const objphone = store.contactPhone;
    if (objphone != undefined && objphone != '' && objphone != null) {
      let trimphone = objphone.toString().match(/\d/g);
      //const isAllZero1 = trimphone.some(item => item !== "0");
      const isAllZero = trimphone.every((val, i, arr) => val === '0');
      if (parseInt(trimphone.length) != 10) {
        this.toaster.errorPopUp('Phone number must be at least 10 digit');
        return;
      } else if (isAllZero == true) {
        this.toaster.errorPopUp('Invalid Mobile Number');
        return;
      } else {
        store.contactPhone = objphone.replace(/[^-()\d ]/g, '');
      }
    }

    if (
      this.GeneralData.liveDate.value == '' ||
      this.GeneralData.liveDate.value == null
    ) {
      this.GeneralStoreForm.value.liveDate = null;
    } else {
      this.GeneralStoreForm.value.liveDate = moment(
        new Date(this.GeneralStoreForm.value.liveDate)
      ).format('YYYY-MM-DD');
    }
    store.storeLogo = this.ImageFile == undefined ? '' : this.ImageFile.name;

    if (this.ImageFile != undefined) {
      const formData = new FormData();
      formData.append('image', this.ImageFile, this.ImageFile.name);
      formData.append('folderName', 'StoreLogo');

      this.objImageUpload.LogoAWSUpload(formData).subscribe((imgres) => {
        console.log(imgres, 'AWS S3 StoreLogo');
      }),
        (err) => console.error('Error', err),
        () => console.log('HTTP request completed.');
    }
    //console.log(store, 'dealer Logo')
    this.storeDetails
      .UserHierachy(store)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        async (response) => {
          //this.tenantId = response.id;
          this.GeneralStoreForm.patchValue({
            tenantId: response.id,
          });
          this.getDetailedData(this.CompId);
          this.submittedGeneral = false;
          if (response.id == 0) {
            this.toaster.errorPopUp(response.message);
          } else {
            this.isInvalid = false;
            this.requiredErrors = false;
            this.helper.setValue('Sessionid_help', response.id);
            if (
              this.helper.getValue('Sessionid_help') == undefined ||
              this.helper.getValue('Sessionid_help') == null ||
              this.helper.getValue('Sessionid_help') == ''
            ) {
              this.helper.setValue('Sessionid_help', 0);
            } else {
              this.tableRowColor = parseInt(
                this.helper.getValue('Sessionid_help')
              );
              this.flipcomp.rowClicked = response.id;
              this.helper.setValue('StoreData_Help', store);
              this.loginInfo.tenantId = response.id;
              this.helper.setValue('LoginInfo', this.loginInfo);
              await this.dialogser.ClearSession();
              this.toaster.successPopUp(response.message);
              localStorage.setItem('str_ID', response.id);
              localStorage.setItem('str_name', store.tenantName.trim());

              this.StoreSessData.emit({
                data: store,
                event: 'storePage',
                val: false,
                itmname: this.GeneralStoreForm.value.tenantName,
              });
            }
          }
          //this.GeneralStoreForm.patchValue({ isActive: true });  //This is commented because After Uncheck and save its getting check
          this.GeneralStoreForm.controls.tenantName.setErrors(null);
          this.GeneralStoreForm.controls.tenantName.clearValidators();
          this.GeneralStoreForm.controls.tenantName.updateValueAndValidity({
            onlySelf: true,
          });
        },
        (error) => { }
      );
  }

  checkValue(event) {
    let currentTarget = event.e.currentTarget.checked;
    const active: IActiveUser = {
      userId: this.loginInfo.userId,
      tenantId: event.id,
      isActive: event.e.currentTarget.checked,
    };
    this.storeDetails
      .IsActiveUser(active)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response) => {
          this.getStore(event.id);
          if (
            browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" ||
            localStorage.getItem('isCompanySelect') == '' ||
            localStorage.getItem('isCompanySelect') === null
          ) {
            this.getDetailedData(localStorage.getItem('comp_ID'));
          } else {
            this.getDetailedData(this.storeRow.company.id);
          }
          if (currentTarget) {
            this.toaster.successPopUp(response.message);
          } else {
            this.toaster.successPopUp(response.message);
          }
        },
        (error) => { }
      );
  }

  changeTableRowColor(event) {
    this.getStore(event.id);
  }

  //--------------------------------- GET STORE Start ---------------------------------//

  async getStore(empid) {
    this.errorValue = ''; //Store Name MAX CHARACTER LIMIT//
    this.errorValue1 = ''; //Contact First Name MAX CHARACTER LIMIT//
    this.errorValue2 = ''; //Contact Last Name MAX CHARACTER LIMIT//
    this.errorValue3 = ''; //Contact Email MAX CHARACTER LIMIT//
    this.errorValue5 = ''; //City MAX CHARACTER LIMIT//
    this.errorValue6 = ''; //State MAX CHARACTER LIMIT//

    localStorage.setItem('str_ID', empid);
    localStorage.setItem('isStoreSelect', 'true');

    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm('storePage');
      if (conf == 'success') {
        // Navigate Away
        this.dialogser.ClearSession();
        this.GeneralStoreForm.reset();

        if (empid != 0) {
          this.GetStoreList(empid);
        }
        this.flipcomp.rowClicked = empid;
        this.zipCodeMsg = '';
        this.mgs = '';
        this.dialogser.ModifiedId = empid;
        this.requiredErrors = false;
        this.isInvalid = false;
      } else {
        this.GeneralStoreForm.patchValue(
          this.helper.getValue('StoreData_Help')
        );
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        this.StoreSessData.emit({
          data: this.GeneralStoreForm.value,
          event: 'storePage',
          val: true,
          itmname: this.GeneralStoreForm.value.tenantName,
        });
      }
    } else {
      this.dialogser.ModifiedId = empid;
      if (empid != 0) {
        this.GetStoreList(empid);
      }
      this.requiredErrors = false;
      this.isInvalid = false;
    }
  }

  GetStoreList(empid) {
    if (empid != undefined && empid != null && empid != '' && empid != 0) {
      const employee: GetAllUserList = {
        tenantId: parseInt(empid),
        loginUserId: this.loginInfo.userId,
      };
      this.storeDetails
        .GetAllHierachy(employee)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((response) => {
          if (this.helper.getValue('OnDirty') == false) {
            this.GeneralStoreForm.patchValue(response);
            this.imgUrl =
              response.storeLogo == null
                ? 'assets/components/images/picture-upload-2-x.png'
                : response.storeLogo == ''
                  ? 'assets/components/images/picture-upload-2-x.png'
                  : this.env.awsS3_environment +
                  'StoreLogo/' +
                  response.storeLogo;

            this.GeneralStoreForm.patchValue({
              liveDate:
                response.liveDate == null
                  ? ''
                  : moment(response.liveDate).format('YYYY-MM-DD'),
            });
            this.helper.setValue('StoreData_Help', this.GeneralStoreForm.value);
          } else {
            this.GeneralStoreForm.patchValue(
              this.helper.getValue('StoreData_Help')
            );
          }
        }),
        (err) => console.error('Error', err),
        () => console.log('HTTP request completed.');
    }
  }

  //--------------------------------- GET STORE End ---------------------------------//

  getAllStoreData() {
    const company: GetAllUserList = {
      tenantId: 0,
    };
    this.allstoredetails = [];

    this.empDetails
      .GetAllEmployees(company)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response) => {
          this.allstoredetails = response;
          // this.dtTrigger.next();
        },
        (error) => {
          // this.dtTrigger.next();
        }
      );
  }

  getDetailedData(id) {
    if (id != undefined && id != null && id != '' && id != 0) {
      const login: IListInfo = {
        loginUserId: this.loginInfo.userId,
        tenantId: id,
      };
      this.storeDetails
        .TenantHierarchyData(login)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((response) => {
          this.storeDetails.checkStoreHierarchydata(
            response.childHierarchyList
          );
          this.dataSource.data = response.childHierarchyList;
          this.EmployeeTableData = response.childHierarchyList;
          this.dataSource.data = this.dataSource.data
            .filter((x) => x.isActive == true)
            .sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0));
          this.testdata = response.childHierarchyList.find(
            (list) => list.id == this.selectedPreItem
          );
          this.gethierarchyData(this.testdata);

          this.displayedColumns = ['name', 'isActive'];
          this.columns = [
            { columnDef: 'name', header: 'Name' },
            { columnDef: 'isActive', header: 'Status' },
          ];
        });
    }
  }

  gethierarchyData(e) {
    if (e != undefined && e != null && e != '') {
      if (this.helper.getValue('OnDirty') == false) {
        this.storeData.emit(e); // is for data emitting to theme compoment
      }
    }
  }

  onSelectState() {
    const ObjState: ISelectState = {
      id: 0,
    };
    this.storeDetails
      .SelectStateMaster(ObjState)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        this.filteredList2 = response;
        this.variables = this.filteredList2.slice();
      });
  }

  onSelectTimeZone() {
    this.storeDetails
      .SelectTimeZone()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        this.filteredTimeZoneList = response.sort(
          (first, second) =>
            0 - (first.daylightName > second.daylightName ? -1 : 1)
        );
        this.variablesTZ = this.filteredTimeZoneList.slice();
      });
  }

  onKey(value) {
    this.filteredList2 = this.search(value);
  }

  search(value: string) {
    let filteredListState = this.variables.filter(
      (unit) => unit.id.indexOf(value.toUpperCase()) > -1
    );
    if (value.length == 1) {
      let filterdata = filteredListState.filter(
        (x) => x.id.substring(0, 1) == value.toUpperCase()
      );
      return [...filterdata];
    } else if (value.length == 2) {
      let filterSecdata = filteredListState.filter(
        (y) => y.id.substring(0, 2) == value.toUpperCase()
      );
      return [...filterSecdata];
    } else {
      this.filterNodata = true;
      return [...filteredListState];
    }
  }

  onTimeZoneKey(value) {
    this.filteredTimeZoneList = this.searchTimeZone(value);
  }

  searchTimeZone(value: string) {
    let filteredListTZ = this.variablesTZ.filter((unit) =>
      unit.displayName.toUpperCase().includes(value.toUpperCase())
    );

    if (filteredListTZ.length == 0) {
      this.filterNodataTZ = true;
      return [...filteredListTZ];
    }

    return filteredListTZ;
  }

  numberOnly2(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (event.target.value.length <= 14) {
      if (event.target.value.length === 3) {
        event.target.value = `(${event.target.value}) `;
      }
      if (event.target.value.length === 9) {
        event.target.value = `${event.target.value}-`;
      }
      if (event.target.value.length === 11) {
        event.target.value = `${event.target.value}`;
      }
      if (event.target.value.length >= 13) {
        this.mgs = '';
        this.isInvalid = false;
      }
      // if (event.target.value.length <= 12) {
      //   this.mgs = 'Phone number must be at least 10 numbers'
      //   return true
      // } else if(event.target.value.length == 0) {
      //   this.mgs = ''
      // }
      // else  {
      //   this.mgs = ''
      // }
    } else {
      event.target.value = event.target.value.slice(0, -1);
    }
    return true;
  }

  phoneNumberCheck(event) {
    if (event.target.value.length == 0) {
      this.mgs = '';
    }
    if (event.target.value.slice(0, 1) != '(') {
      if (event.target.value.length > 10) {
        this.mgs = 'Invalid mobile number';
        return;
      } else if (
        event.target.value.length < 3 &&
        event.target.value.length > 0
      ) {
        event.target.value = `(${event.target.value.slice(0, 3)})`;
        if (event.target.value.length < 15) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = '';
      } else if (
        event.target.value.length < 6 &&
        event.target.value.length > 0
      ) {
        event.target.value = `(${event.target.value.slice(
          0,
          3
        )}) ${event.target.value.slice(3, 6)}-`;
        if (event.target.value.length < 15) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = '';
      } else if (
        event.target.value.length >= 6 &&
        event.target.value.length > 0
      ) {
        event.target.value = `(${event.target.value.slice(
          0,
          3
        )}) ${event.target.value.slice(3, 6)}-${event.target.value.slice(
          6,
          event.target.value.length
        )}`;
        if (event.target.value.length < 14) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = '';
      }
    } else {
      if (event.target.value.length < 14) {
        this.mgs = 'Invalid mobile number';
        return;
      }
      this.mgs = '';
    }
  }

  onDeletePhone(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.mgs = '';
      }
    }
  }

  btnActiveStatus(event) {
    if (event == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !event;
    }
  }

  // getDetailedData(id) {
  //   const login: IListInfo = {
  //     loginUserId: this.loginInfo.userId,
  //     tenantId: id
  //   }
  //   this.admin.TenantHierarchyData(login).subscribe(
  //     (response) => {
  //       this.primarystoreSel = response.parentHierarchyInfo;
  //     })
  // }
  //---------------------------------MAX CHARS VALIDATION START--------------------------------------//

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue = '';
    }
  }

  getMaxValueFName(e) {
    if (e.target.value.length == 50) {
      this.errorValue1 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue1 = '';
    }
  }

  getMaxValueLName(e) {
    if (e.target.value.length == 50) {
      this.errorValue2 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue2 = '';
    }
  }

  getMaxValueEmail(e) {
    if (e.target.value.length == 100) {
      this.errorValue3 = 'Max-Limit 100 characters reached';
    } else {
      this.errorValue3 = '';
    }
  }

  getMaxValueCity(e) {
    if (e.target.value.length == 50) {
      this.errorValue5 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue5 = '';
    }
  }

  getMaxValueTerritory(e) {
    if (e.target.value.length == 50) {
      this.errorValue6 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue6 = '';
    }
  }
  //---------------------------------MAX CHARS VALIDATION End--------------------------------------//

  //--------------------------------- FOCUS OUT VALIDATION Start ---------------------------------//
  StoreNameValid() {
    if (!this.GeneralStoreForm.invalid) {
      this.requiredErrors = false;
      this.isInvalid = false;
    } else {
      this.requiredErrors = true;
      this.isInvalid = true;
    }
  }

  //--------------------------------- FOCUS OUT VALIDATION Ends ---------------------------------//

  //---------------------------------KEYPRESS VALIDATION COLOR---------------------------------//

  Highlight() {
    if (!this.GeneralStoreForm.invalid) {
      //this.requiredErrors = false;
      this.isInvalid = false;
      this.dateMgs = '';
    } else if (
      this.GeneralStoreForm.value.tenantName == null ||
      (this.GeneralStoreForm.value.tenantName == '' &&
        this.GeneralStoreForm.value.tenantName.length == 0)
    ) {
      if (this.GeneralStoreForm.controls.liveDate.invalid) {
        //  this.requiredErrors = true;
        this.isInvalid = true;
        this.dateMgs = 'Invalid Date';
        return;
      } else {
        this.requiredErrors = false;
        this.dateMgs = '';
        this.isInvalid = false;
      }
    } else {
      if (this.GeneralStoreForm.controls.liveDate.invalid) {
        //  this.requiredErrors = true;
        this.isInvalid = true;
        this.dateMgs = 'Invalid Date';
        return;
      }
      //  this.requiredErrors = true;
      this.isInvalid = true;
      this.dateMgs = '';
    }
  }
  //---------------------------------KEYPRESS VALIDATION COLOR---------------------------------//

  async ngAfterViewInit() {
    if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true" ||
      localStorage.getItem('isCompanySelect') == '' || localStorage.getItem('isCompanySelect') === null
    ) {
      if (localStorage.getItem('str_ID') != undefined &&
        localStorage.getItem('str_ID') != null && localStorage.getItem('str_ID') != '') {
        this.GetStoreList(localStorage.getItem('str_ID'));
      }
    } else {
      if (this.storeIdItem != undefined) {
        if (
          this.storeIdItem.store != undefined &&
          this.storeIdItem.store != null &&
          this.storeIdItem.store != ''
        ) {
          this.GetStoreList(this.storeIdItem.store.id);
        }
      }
    }
  }

  onchange(data) {
    this.helper.setValue('StoreData_Help', data);
    this.helper.setValue('OnDirty', true);
    this.StoreSessData.emit({ data: data, event: 'storePage', val: true });
  }

  onDeleteZipCode(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.zipCodeMsg = '';
      }
    }
  }

  zipCodeCheck(event) {
    if (event.target.value.length === 6) {
      if (event.target.value.slice(5, 6) == '-') {
        event.target.value = `${event.target.value.slice(0, 5)}`;
      }
    } else if (
      event.target.value.length > 5 &&
      event.target.value.length < 10
    ) {
      if (event.target.value.slice(5, 6) != '-') {
        event.target.value = `${event.target.value.slice(
          0,
          5
        )}-${event.target.value.slice(6, event.target.value.length)}`;
        this.zipCodeMsg = '';
      }
    } else if (event.target.value.length === 9) {
      event.target.value = `${event.target.value.slice(
        0,
        5
      )}-${event.target.value.slice(6, 9)}`;
    } else if (event.target.value.length === 10) {
      if (event.target.value.slice(5, 6) != '-') {
        this.zipCodeMsg = 'Invalid Zip Code';
      } else {
        this.zipCodeMsg = '';
      }
    }
  }
}
