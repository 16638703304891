<div class="Permission_wrap">
  <form
    class="carouse_wrapper loginForm"
    [formGroup]="RolePermissionForm"
    (change)="onchange(RolePermissionForm.value)"
  >
    <div
      id="carousel-2"
      class="carousel slide mt-2"
      data-ride="carousel"
      data-interval="0"
    >
      <div class="row w-100 inside_wrap">
        <div class="col-sm-3">
          <ol
            class="carousel-indicators"
            [ngClass]="{ 'no-pointers': isDisabled == true }"
          >
            <li
              data-target="#carousel-2"
              [attr.data-slide-to]="i"
              *ngFor="let item of jsonArrayValue; let i = index"
              [ngClass]="{ active: i == 0 }"
              (click)="onIndicatorChange(item.categoryId, i)"
            >
              <div class="row">
                <div class="col-sm-4">
                  <img
                    src="assets/components/images/admin-icon.png"
                    alt=""
                    class="img-fluid"
                    width="45px"
                  />
                </div>
                <div class="col-sm-8">
                  <h6 class="mb-0" (click)="clickEvent('Check')">
                    {{ item.categoryName }}
                  </h6>
                </div>
              </div>
            </li>
          </ol>
          <div>
            <div class="checkbox-table perm-all">
              <div class="custom-control custom-checkbox custom-control-inline">
                <input
                  (change)="onCheckAllChange($event, 1, 'Role')"
                  type="checkbox"
                  class="custom-control-input"
                  [checked]="AllCheckBox"
                  value="AllCheckBox"
                  formControlName="allPermission"
                  name="1"
                  id="1"
                  [disabled]="isDisabled"
                />
                <label class="custom-control-label permision-label-pad" for="1"
                  >All Permissions</label
                >
              </div>
              <!-- <input [checked]="accepted"  (change)="onCheckAllChange($event, 1,'Role')" type="checkbox" class="custom-
                            control-input" id="save-info" > -->
              <!-- {{AllCheckBox}} -->
            </div>
            <div class="checkbox-table perm-all">
              <div class="custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="timeClock"
                  id="timeClock"
                  formControlName="timeClockOnly"
                  checked="timeClockCheckBox"
                  (change)="onCheckTimeOnly($event)"
                />
                <label class="custom-control-label permision-label-pad"
                  >Time Clock Only</label
                >
              </div>
            </div>
            <!-- for checking purpose commented -->
            <!-- <div class="checkbox-table perm-all" *ngFor="let item of allpermission;">
                            <div class="checkbox-table">
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input (change)="onCheckAllChange($event, 1,'Role')"
                                        type="checkbox" [checked]="AllCheckBox"
                                        class="custom-control-input" value="{{item.id}}"
                                        name="{{item.id}}" id="{{item.id}}">
                                   [disabled]="!RolePermissionForm.get('OverrideCurrent').value" 
                                    <label class="custom-control-label"
                                        for="{{item.id}}">{{item.name}}</label>
                                </div>
                            </div>
                        </div> -->
          </div>
        </div>
        <div class="col-sm-9">
          <div class="carousel-inner row w-100 mx-auto" role="listbox">
            <div
              class="carousel-item col-12 col-sm-12"
              *ngFor="let item of jsonArrayValue; let k = index"
              [ngClass]="{ active: k == 0 }"
            >
              <div class="card" *ngIf="item.categoryId">
                <div class="card-body">
                  <div class="row">
                    <div
                      class="acclabel_wrap col-sm-3"
                      (click)="clickEvent('Check')"
                      [ngClass]="{ 'selected-card': isShownC == true }"
                    >
                      <img
                        src="assets/components/images/admin-icon.png"
                        alt=""
                        class="img-fluid"
                      />
                      <p class="card-title mb-0 pl-2">
                        {{ item.categoryName }}
                      </p>
                    </div>
                    <div
                      class="acclabel_wrap col-sm-3"
                      (click)="clickEvent('Adjust')"
                      *ngIf="item.categoryName == 'Check'"
                      [ngClass]="{ 'selected-card': isShownA == true }"
                      style="margin-left: 15px"
                    >
                      <span class="card-title permision-label-pad"
                        >All Adjustments'</span
                      >
                    </div>
                  </div>
                  <div class="row mt-2" *ngIf="isShownC">
                    <div class="col-sm-12" *ngIf="item.categoryName == 'Admin'">
                      <!-- <div *ngFor="let item of timeCLockData">
                                            <div class="checkbox-table checkbox-table-time">
                                                <div class="custom-control custom-checkbox custom-control-inline" >
                                                    <input (change)="onCheckChange($event, item.permissionId,'Role')"
                                                        type="checkbox"
                                                        [checked]="item.isSelected == true ? true : false"
                                                        class="custom-control-input" value="{{item.permissionId}}"
                                                        name="{{item.permissionId}}" id="{{item.permissionId}}">
                                                    <label class="custom-control-label permision-label-pad"
                                                        for="{{item.permissionId}}">{{item.permissionName}}</label>
                                                </div>
                                                <hr class="solid">
                                            </div>
                                        </div> -->
                    </div>
                    <div class="col-sm-6" *ngFor="let item of permissionValues">
                      <div class="checkbox-table">
                        <div
                          class="custom-control custom-checkbox custom-control-inline"
                        >
                          <input
                            (change)="
                              onCheckChange($event, item.permissionId, 'Role')
                            "
                            type="checkbox"
                            [checked]="item.isSelected == true ? true : false"
                            class="custom-control-input"
                            value="{{ item.permissionId }}"
                            name="{{ item.permissionId }}"
                            id="{{ item.permissionId }}"
                            [disabled]="isPermission"
                          />
                          <!-- [disabled]="!RolePermissionForm.get('OverrideCurrent').value" -->
                          <label
                            class="custom-control-label permision-label-pad"
                            for="{{ item.permissionId }}"
                            >{{ item.permissionName }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2" *ngIf="isShownA">
                    <div class="col-sm-6" *ngFor="let item of adjustmentList">
                      <div class="checkbox-table">
                        <div
                          class="custom-control custom-checkbox custom-control-inline"
                        >
                          <input
                            (change)="onCheckChange($event, item.id, 'adjust')"
                            type="checkbox"
                            [checked]="item.isSelected == true ? true : false"
                            class="custom-control-input"
                            value="{{ item.id }}"
                            name="{{ item.id }}"
                            id="{{ item.id }}"
                          />
                          <!-- [disabled]="!RolePermissionForm.get('OverrideCurrent').value" -->
                          <label
                            class="custom-control-label permision-label-pad"
                            for="{{ item.id }}"
                            >{{ item.name }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- *ngIf="RolePermissionForm.get('OverrideCurrent').value" -->
    <div class="row">
      <div class="col-sm-12 text-right">
        <div class="form-group mt-2">
          <button
            class="btn btn-sm float-right submit_button button-none"
            type="submit"
            (click)="onSavePermission($event)"
            [disabled]="buttonDisable ? true : null"
          >
            <img
              src="assets/components/images/save.png"
              alt="employee"
              class=""
            />
            <label class="label_config">Save</label>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
