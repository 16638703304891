import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { ISelectTagDetails, IActivateTagDetails, } from 'src/app/interfaces/i-tags';
import { TagsService } from 'src/app/services/tags.service';
import { MatTableDataSource } from '@angular/material/table';
import { TagsGeneralComponent } from '../tags-general/tags-general.component';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-tags-main',
  templateUrl: './tags-main.component.html',
  styleUrls: ['./tags-main.component.css'],
})
export class TagsMainComponent implements OnInit {
  loginInfo: any;
  public filterDataList: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public tableRowColor: any;
  public tableRowId: any;
  public displayedColumns = [];
  public TabletData: any;
  public EmployeeTableData: any;
  columns = [];
  public activeDeactiveValue: any;
  public dayPartSchedule: boolean = true;
  public dataSource = new MatTableDataSource<any>();
  public buttonDisable: boolean;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public tagmainsessData = new EventEmitter();

  public titleCardItem: any;
  public refreshjobprintval: any = '';

  @ViewChild(TagsGeneralComponent) tagGeneral: TagsGeneralComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;

  dayPartValue: boolean = false;
  filterPartValue: boolean = false;
  indexExpanded: number = 0;
  isInvalid: boolean = false;
  IconIdInd: any;
  public filterList: any;
  public ipadLabel = 'iPad Sort Order';

  public generalFilterDataemit: any;
  public finalipadjsondata: any;
  public tabletfilterval: any;
  public modifierItemsData: any;
  private onDestroy$: Subject<void> = new Subject<void>();
  constructor(
    private helper: HelperService,
    private toaster: ToasterService,
    private tagService: TagsService,
    private dialogser: DialogService
  ) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', 'this.tagsPage');

    this.helper.setValue('tagIdvalue', 0);
    this.refreshjobprintval = '';
    this.buttonDisable = false;
    this.employeeName = 'Tags Name';
    this.employee = 'Tags';
    this.employeeIcon = 'assets/components/images/tag.png';
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.dayPartValue = false;
    this.filterPartValue = false;
    this.getTagList();
    if (this.helper.getValue('OnDirty') == false) {
      this.helper.setValue('OnSaveTagData', "");
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  getTagList() {
    const deviceList: ISelectTagDetails = {
      id: 0,
      tenantId: this.loginInfo.tenantId,
    };
    this.tagService.SelectTagDetails(deviceList).pipe(takeUntil(this.onDestroy$)).subscribe((res) => {
      this.dataSource.data = res;
      this.EmployeeTableData = res;
      this.TabletData = res;
      this.dataSource.data = this.dataSource.data.filter(
        (x) => x.isActive == true
      );
      this.displayedColumns = ['name', 'tagCategory', 'isActive'];
      this.columns = [
        { columnDef: 'name', header: 'Tags Name' },
        { columnDef: 'tagCategory', header: 'Type' },
        { columnDef: 'isActive', header: 'Status' },
      ];
    }),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');

    if (
      this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == ''
    ) {
      this.helper.setValue('Sessionid_help', 0);
    } else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  async getTableIdx(event) {
    this.helper.setValue('OnSaveTagData', "");
    this.helper.setValue('tagIdvalue', event);
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm('MenuItemPage');
      if (conf == 'success') {
        // Navigate Away
        if (event == this.dialogser.ModifiedId) {
          this.tagGeneral.getTagtList(event);
        }
        this.dialogser.ClearSession();
        this.tagGeneral.tagsGeneralForm.reset();

        //General Data
        this.helper.setValue('Sessionid_help', event);
        this.refreshjobprintval = '';
        this.tagGeneral.errorValue = '';
        this.tableRowId = event;
        this.tableRowColor = event;
        this.isInvalid = false;
        this.tagGeneral.requiredErrors = false;
        this.flipcomp.rowClicked = event;
        this.dialogser.ModifiedId = event;
      } // Cancel
      else {
        //General Data
        this.tagGeneral.tagsGeneralForm.patchValue(
          this.helper.getValue('GetSessionData1')
        );
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    } else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event);
      this.refreshjobprintval = '';
      this.tagGeneral.errorValue = '';
      this.tableRowId = event;
      this.tableRowColor = event;
      this.isInvalid = false;
      this.tagGeneral.requiredErrors = false;
    }
  }

  expandPanel(index) {
    this.indexExpanded = index == this.indexExpanded ? 0 : index;
  }

  getrefreshpage(e) {
    this.dialogser.ClearSession();
    this.getTagList();
    this.flipcomp.selectTableRowColor(e.res.id, e.res.id, e.name);
  }

  async addMenuItem() {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    } else {
      var conf = await this.dialogser.confirm('tagsPage');
      if (conf == 'success') {
        // Navigate Away
        this.ClearGenData();
      } else {
        this.tagGeneral.tagsGeneralForm.patchValue(
          this.helper.getValue('GetSessionData1')
        );
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = 'transparent';
    this.tagGeneral.errorValue = '';
    this.isInvalid = false;
    this.tagGeneral.requiredErrors = false;
    this.employeeName = 'Tags Name';
    //this.tableRowColor = 'transparent';
    this.flipcomp.selectTableRowColor(0, 0, 'Tags Name');
    this.tagGeneral.tagsGeneralForm.reset();
    this.tagGeneral.filterDataList = [];
    this.tagGeneral.ngOnInit();
    this.tableRowId = 0;
    this.tagGeneral.isclicked = false;
    this.tagGeneral.submittedGeneral = false;
    this.dayPartValue = false;
    this.filterPartValue = false;
    this.tagGeneral.filterSec = false;
    this.tagGeneral.reportSec = false;
    this.tagGeneral.printSec = false;
    this.tagGeneral.dayPartSec = false;
    this.tagGeneral.printers_Assigned_Object = [];
    this.tagGeneral.visibledaypart = false;

    if (
      this.tagGeneral.filterdatalistval != undefined &&
      this.tagGeneral.filterdatalistval != null &&
      this.tagGeneral.filterdatalistval != ''
    ) {
      this.tagGeneral.filterdatalistval.filterid = 0;
    }
    //this.tagGeneral.FilterList = [];
    this.tagGeneral.tag_id = 0;
    this.tagGeneral.onIconId = '';
    this.helper.setValue('tagIdvalue', 0);
    this.helper.setValue('tag_daypartIdvalue', 0);
    this.helper.setValue('onIconId_Ind', '');
    this.tagGeneral.ClearTagGenData();
    this.indexExpanded = 0;
    this.refreshjobprintval = 'refreshjp';
    this.titleName.emit(this.employeeName);
  }

  getTableName(name) {
    this.employeeName = name;
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
  }

  ActivateDeactivate(event) {
    this.activeDeactiveValue = event;
    let currentTarget = event.e.currentTarget.checked;
    const active: IActivateTagDetails = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked,
    };
    this.tagService.ActivateTagDetails(active).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      if (currentTarget) {
        this.toaster.successPopUp(response.message);
      } else {
        this.toaster.successPopUp(response.message);
      }
      this.getTagList();
    }), (err) => console.error('Error', err), () => console.log('HTTP request completed.');
  }

  saveFilterDaypart(event) {
    this.titleCardItem = event;
    this.IconIdInd = this.helper.getValue('onIconId_Ind');
    if (event.id != 0) {
      if (this.IconIdInd == 'DayPart') {
        this.tagGeneral.saveDaypart(event);
      }
    }
  }

  getFilterDetail(event) {
    const keys_to_keep = ['id', 'name'];
    const reduxfilter = (array) =>
      array.map((o) =>
        keys_to_keep.reduce((acc, curr) => {
          acc[curr] = o[curr];
          return acc;
        }, {})
      );
    if (event != undefined && event != null && event != '') {
      this.generalFilterDataemit = reduxfilter(event);
      this.finalipadjsondata = JSON.stringify(this.generalFilterDataemit);
    }
  }

  getfilterdatalist(event) {
    this.filterDataList = event;
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  OnSave() {
    if (this.tagGeneral.tagsGeneralForm.invalid) {
      if (
        this.tagGeneral.tagsGeneralForm.value.name == null ||
        this.tagGeneral.tagsGeneralForm.value.name == ''
      ) {
        this.isInvalid = true;
        this.tagGeneral.requiredErrors = true;
        return;
      } else {
        this.isInvalid = false;
      }
    } else {
      this.isInvalid = false;
      this.helper.setValue("OnSaveTagData", "true");
      this.IconIdInd = this.helper.getValue('onIconId_Ind');
      this.tagGeneral.onSaveGeneral();
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  //  Realese the memory
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  gettagsPageData(e) {
    this.tagmainsessData.emit(e);
  }
}
