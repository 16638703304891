import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { Group, iDiscountReport } from 'src/app/interfaces/i-report';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
import * as moment from 'moment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { CoordinatesModule } from 'ngx-color';
import { FlipTableGroupbyComponent } from 'src/app/elements/flip-table-groupby/flip-table-groupby.component';
import { FlipTableApproverComponent } from 'src/app/elements/flip-table-approver/flip-table-approver.component';
import { DiscountTableCollapseComponent } from 'src/app/elements/discount-table-collapse/discount-table-collapse.component';
import { Interface } from 'readline';
export interface PeriodicElement {
  id?: number;
  name?: any;
  quantity?: any;
  amount?: any;
  trasType?: any;
  check?: any;
  employee?: any;
  timeDate?: any;
  item?: any;
  move?: any;
  total?: any;
  discountname?: any;
  ofdiscounts?: any;
  category?: any;
  groupHeader?: any;
  grossSales?: any;
  netSales?: any;
  categorySale?: any;
  employeeName?: any;
  sales?: any;
  totalCheck?: any
  Address?: any;
  City?: any;
  Email?: any;
  FirstName?: any;
  LastName?: any;
  Notes?: any;
  Phone?: any;
  State?: any;
  Zip?: any;
}
export interface Transaction {
  item: string;
  cost: number;
}
export interface Totals {
  subTotal: number;
  discount: number;
  tax: number;
  total: number;
}
export interface ICustomer {

  address: any;
  city: any;
  email: any;
  firstName: any;
  lastName: any;
  notes: any;
  phone: any;
  state: any;
  zip: any;
}

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.css']
})
export class DiscountsComponent implements OnInit {
  loginInfo: any
  @Output() public _EmitbySalesAreaEmployee = new EventEmitter();
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public backSalesItem = new EventEmitter();
  @Input() public discountsReport
  public employeeName: any;
  public employee: any;
  public employeeIcon: any
  public tabName: any = 'Summary'
  public discountTotal: any;
  public subTotal: any;
  public netTotal: any;
  public Summary = new MatTableDataSource<any | Group>([]);
  public salesbyarea = new MatTableDataSource<any | Group>([]);
  public dataSource1 = new MatTableDataSource<any | Group>([]);
  public alldata1: any[];
  public dataSourcefil: any;
  employeediscounttableSource3: any;


  public dataSource3 = new MatTableDataSource<any | Group>([]);
  public dataSource4 = new MatTableDataSource<any | Group>([]);
  public columns1: any[];
  public displayedColumns1: string[];
  public groupByColumns1: string[] = [];
  public displayedColumns2 = [];
  public displayedColumns3 = [];
  public displayedColumns4 = [];
  columns2 = [];
  columns3 = [];
  columns4 = [];
  closedData = [];

  public dataSource2 = new MatTableDataSource<any | Group>([]);

  public firstTableShow: boolean = false
  public secondTableShow: boolean = false
  public auditShow: boolean = false
  public dropdown: boolean = false


  public backToSales: boolean = false
  public auditName: any
  public auditEmployee: any;
  public auditDate: any;
  public auditTime: any;
  firstTableName: any;
  salesName: any;
  empName: any;
  approveName: any;
  public summaryCustomer: any = [];
  public summaryAudit: any = [];
  public transactions: any = [];
  public Filtertransactions: any = [];
  public totals: any = [];
  public summartItems: any = [];
  public SalesAreaCustomer: any = [];
  public alldata2: any[];
  public EmpData: any[];
  public ApprovData: any[];
  public DiscSummary: any[];
  public groupByColumns2: string[] = [];
  public alldata3: any[];
  public groupByColumns3: string[] = [];
  public groupByColumns4: string[] = [];
  employeeData = [];
  public DiscountDetails: any = [];
  public SalesAreaDiscDetails: any = [];
  public ApproveDiscDetails: any = [];
  public EmployeeDiscDetails: any = [];
  public SalesEmployeeDiscDetails: any = [];
  public SalesApproverDiscDetails: any = [];
  public bySalesAreaEmployeeSummary: any = [];
  public bySalesAreaApproverSummary: any = [];
  public employeediscounttable: any;
  public employeediscounttableSource: any;
  employeedisccolumns2 = [];
  summaryTableShow: boolean;
  employeediscounttableShow: boolean = false;
  salesAreaByEmployee: boolean = false;
  salesTableShow: boolean = false;
  approverTableShow: boolean = false;
  salesAreaApproverTableShow: boolean = false;

  CustomerDetails = [];
  public CustomerList: ICustomer[] = [
    { firstName: '', lastName: '', city: '', email: '', address: '', phone: '', notes: '', state: '', zip: '' }
  ]

  public displayedColumns: string[] = ['item', 'cost'];
  // public CustomerList: ICustomer[] = [
  //  {firstName:'',lastName:'',city:'',email:'',address:'',phone:'',notes:'',state:'',zip:''}
  // ];

  // public totals: Totals[] = [
  //    {subTotal: 31.98, discount: 0.00, tax: 0.00, total: 31.98}
  //   ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(DiscountTableCollapseComponent) tablecollapse: DiscountTableCollapseComponent
  @ViewChild(FlipTableGroupbyComponent) tableGrp: FlipTableGroupbyComponent
  @ViewChild(FlipTableApproverComponent) tableApprve: FlipTableApproverComponent
  thirdTableShow: boolean;
  summaryTab: boolean;
  approverTab: boolean;
  salesTab: boolean;
  employeeTab: boolean;
  employeeTableShow: boolean;
  auditTable: string[];
  auditdataSource: PeriodicElement[];
  auditcolumns: { columnDef: string; header: string; }[];
  auditMove: any;
  itemClicked: any;
  checkClickAudit: any;
  TipsAmount: any;
  constructor(private formBuilder: FormBuilder, private helper: HelperService, private reportService: ReportWebSocketService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.discountsReport");

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Discounts";
    this.employee = "Reports";
    this.employeeIcon = "assets/components/images/reports-icon.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.summaryTableShow = true;
    //console.log(this.tablecollapse,"this.tablecollapse");

    //  this.GetDiscountSummary()
    //this.getSecondTable()
  }

  GetDiscountSummary(event) {
    this.firstTableShow = true;
    this.summaryTableShow = true;
    this.employeediscounttableShow = false;
    this.auditShow = false;
    this.reportService.SelectDiscountSummary(event)
      .subscribe(
        (data: any) => {


          //this.DiscSummary = data.Summary;

          console.log(data, 'Summary')

          this.Summary.data = data.Summary;
          this.DiscountDetails = data.DiscountDetails;
          this.summaryCustomer = data.Customer;
          this.summaryAudit = data.Audit;
          this.summartItems = data.Items;
          // console.log(this.summaryAudit, 'Summary')
          // console.log(data, 'Data')
        }
      );

    // this.Summary.data = ELEMENT_DATA1;
    this.displayedColumns1 = ['discountname', 'ofdiscounts', 'total'];
    this.columns1 = [
      { columnDef: 'discountname', header: 'Discount Name' },
      { columnDef: 'ofdiscounts', header: '# of Discounts' },
      { columnDef: 'total', header: 'Total $' }
    ];


  }


  FinalReceivedData(event) {
    this.summartItems = [];
    if (this.tabName == 'Summary') {
      this.GetDiscountSummary(event);
    }
    else if (this.tabName == 'By Sales Area') {
      this.GetDiscountBySalesArea(event);
    }
    else if (this.tabName == 'By Approver') {
      this.GetDiscountByApprover(event);
    }
    else if (this.tabName == 'By Employee') {
      this.GetDiscountByEmployee(event);
    }

    else if (this.tabName == 'Sales Area - Employee') {
      this.GetDiscountSalesAreaByEmployee(event);

    }

    else if (this.tabName == 'Sales Area - Approver') {
      this.GetDiscountSalesAreaByApprover(event);

    }


  }

  GetDiscountBySalesArea(event) {
    this.firstTableShow = true;
    this.salesTableShow = true;
    this.employeediscounttableShow = false;
    this.auditShow = false;

    this.reportService.SelectDiscountBySalesArea(event).subscribe(
      (data: any) => {
        this.alldata2 = data.BySalesArea;
        this.SalesAreaDiscDetails = data.DiscountDetails;
        this.SalesAreaCustomer = data.Customer;
        this.summaryAudit = data.Audit;
        this.summartItems = data.Items;
        this.dataSource2.data = this.tablecollapse.addGroups(this.alldata2, this.groupByColumns2);
      }
    );
    this.columns2 = [{
      field: 'category', header: 'Discount Name'
    }, {
      field: 'quantity', header: '# of Discount'
    }, {
      field: 'totalDiscount', header: 'Total $'
    }];
    this.displayedColumns2 = this.columns2.map(column => column.field);
    this.groupByColumns2 = ['groupHeader'];

  }

  GetDiscountByEmployee(event) {
    //console.log(event,'event')
    this.firstTableShow = true;
    this.employeeTableShow = true;
    this.employeediscounttableShow = false;
    this.auditShow = false;

    this.reportService.SelectDiscountByEmployee(event).subscribe(
      (data: any) => {
        this.EmpData = data.ByEmployee;
        this.EmployeeDiscDetails = data.DiscountDetails;
        this.SalesAreaCustomer = data.Customer;
        this.summaryAudit = data.Audit;
        this.summartItems = data.Items;
        this.dataSource4.data = this.tablecollapse.addGroups(this.EmpData, this.groupByColumns4);
      }
    );
    this.columns4 = [{
      field: 'category', header: 'Discount Name'
    }, {
      field: 'quantity', header: '# of Discount'
    }, {
      field: 'totalDiscount', header: 'Total $'
    }];
    this.displayedColumns4 = this.columns4.map(column => column.field);
    this.groupByColumns4 = ['groupHeader'];

  }

  GetDiscountByApprover(event) {
    this.firstTableShow = true;
    this.approverTableShow = true;
    this.employeediscounttableShow = false;
    this.auditShow = false;

    this.reportService.SelectDiscountByApprover(event).subscribe(
      (data: any) => {
        this.ApprovData = data.ByApprover;
        this.ApproveDiscDetails = data.DiscountDetails;
        this.SalesAreaCustomer = data.Customer;
        this.summaryAudit = data.Audit;
        this.summartItems = data.Items;

        this.dataSource3.data = this.tablecollapse.addGroups(this.ApprovData, this.groupByColumns3);
      }
    );
    this.columns3 = [{
      field: 'category', header: 'Discount Name'
    }, {
      field: 'quantity', header: '# of Discount'
    }, {
      field: 'totalDiscount', header: 'Total $'
    }];
    this.displayedColumns3 = this.columns3.map(column => column.field);
    this.groupByColumns3 = ['groupHeader'];
    }

  onBackClick(x) {
    if (x == 1) {
      this.firstTableShow = true;
      this.secondTableShow = false;
    } else if (x == 2) {
      this.firstTableShow = false;
      this.secondTableShow = true;
      this.employeeTableShow = true;
      this.employeediscounttableShow = false;
      // this.getSecondTable()
    }
    else if (x == 3) {
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.summaryTableShow = true;
      this.employeediscounttableShow = false;
    }
    else if (x == 4) {
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.salesTableShow = true;
      this.employeediscounttableShow = false;
    }
    else if (x == 5) {
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.approverTableShow = true;
      this.employeediscounttableShow = false;
    }
    else if (x == 6) {
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.employeediscounttableShow = true;
      this.auditShow = false;
    }
    else if (x == 7) {
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.employeediscounttableShow = true;
      this.auditShow = false;
    }
    else if (x == 8) {
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.employeediscounttableShow = true;
      this.auditShow = false;
    }
    else if (x == 9) {
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.employeediscounttableShow = true;
      this.auditShow = false;
    }
    else if (x == 10) {
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.salesAreaByEmployee = true;
      this.employeediscounttableShow = false;
    }
    else if (x == 11) {
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.employeediscounttableShow = true;
      this.auditShow = false;
    }
    else if (x == 12) {
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.salesAreaApproverTableShow = true;
      this.employeediscounttableShow = false;
    }
    else if (x == 13) {
      this.firstTableShow = false;
      this.secondTableShow = false;
      this.employeediscounttableShow = true;
      this.auditShow = false;
    }
  }


  tabClick(event: MatTabChangeEvent) {
    //  alert(event.tab.textLabel);
    this.tabName = event.tab.textLabel
    //this.DiscountDetails=[];
    if (this.tabName == 'Summary') {
      this.GetDiscountSummary(event);
    }
    else if (this.tabName == 'By Sales Area') {
      this.GetDiscountBySalesArea(event);
    }
    else if (this.tabName == 'By Approver') {
      this.GetDiscountByApprover(event);
    }
    else if (this.tabName == 'By Employee') {
      this.GetDiscountByEmployee(event);
    }

    else if (this.tabName == 'Sales Area - Employee') {
      this.GetDiscountSalesAreaByEmployee(event);
    }

    else if (this.tabName == 'Sales Area - Approver') {
      this.GetDiscountSalesAreaByApprover(event);
    }
  }

  GetDiscountSalesAreaByEmployee(event) {
    this.firstTableShow = true;
    this.salesAreaByEmployee = true;
    this.employeediscounttableShow = false;
    this.auditShow = false;

    this.reportService.SelectDiscountSalesAreaByEmployee(event).subscribe(
      (data: any) => {
        this.bySalesAreaEmployeeSummary = data.EmployeeSummary[0].JSONEmployee;
        this.tableGrp.GetLoad(this.bySalesAreaEmployeeSummary);

        this.SalesEmployeeDiscDetails = data.DiscountDetails;
        this.SalesAreaCustomer = data.Customer;
        this.summaryAudit = data.Audit;
        this.summartItems = data.Items;

        //    console.log(data,'GetDiscountSalesAreaByEmployee')

        //  this.dataSource3.data = this.tablecollapse.addGroups(this.ApprovData, this.groupByColumns3);
      }
    );
  }

  GetDiscountSalesAreaByApprover(event) {
    this.firstTableShow = true;
    this.salesAreaApproverTableShow = true;
    this.employeediscounttableShow = false;
    this.auditShow = false;

    this.reportService.SelectDiscountSalesAreaByApprover(event).subscribe(
      (data: any) => {
        this.bySalesAreaApproverSummary = data.ApproverSummary[0].JSONApprover;
        this.tableApprve.GetLoad(this.bySalesAreaApproverSummary);


        this.SalesApproverDiscDetails = data.DiscountDetails;
        this.SalesAreaCustomer = data.Customer;
        this.summaryAudit = data.Audit;
        this.summartItems = data.Items;

        //  this.dataSource3.data = this.tablecollapse.addGroups(this.ApprovData, this.groupByColumns3);
      }
    );
  }
  getEmployeeDiscountTable(e?, FilterType?) {
    this.itemClicked = 'disabled';
    this.employeediscounttable = ['employeeName', 'timeDate', 'check', 'sales', 'discountname', 'amount', 'totalCheck'];

    if (FilterType == 'Summary') {
      this.employeediscounttableSource = this.DiscountDetails;
      this.employeediscounttableSource = this.employeediscounttableSource.filter(x => x.discountname == e.discountname)

    }

    else if (FilterType == 'SalesArea') {
      console.log(e, 'event')
      this.employeediscounttableSource = this.SalesAreaDiscDetails;
      this.employeediscounttableSource = this.employeediscounttableSource.filter(x => x.discountname == e.category && x.sales == e.groupHeader)

    }
    else if (FilterType == 'Approver') {
      this.employeediscounttableSource = this.ApproveDiscDetails;
      this.employeediscounttableSource = this.employeediscounttableSource.filter(x => x.discountname == e.category)

    }
    else if (FilterType == 'Employee') {
      this.employeediscounttableSource = this.EmployeeDiscDetails;
      this.employeediscounttableSource = this.employeediscounttableSource.filter(x => x.discountname == e.category && x.employeeName == e.groupHeader)

    }

    else if (FilterType == 'Sales Area - Employee') {

      this.employeediscounttableSource = this.SalesEmployeeDiscDetails;

      this.employeediscounttableSource = this.employeediscounttableSource.filter(x => x.discountname == e.discountName && x.sales == this.salesName && x.employeeName == this.empName)
    }


    else if (FilterType == 'Sales Area - Approver') {
      this.employeediscounttableSource = this.SalesApproverDiscDetails;
      this.employeediscounttableSource = this.employeediscounttableSource.filter(x => x.discountname == e.discountName && x.sales == this.salesName && x.employeeName == this.empName)
    }

    this.employeedisccolumns2 = [
      { columnDef: 'employeeName', header: "Employee Name" },
      { columnDef: 'timeDate', header: "Date/Time" },
      { columnDef: 'check', header: "Check #" },
      { columnDef: 'sales', header: "Sales Area" },
      { columnDef: 'discountname', header: "Discount Name" },
      { columnDef: 'amount', header: "Amount" },
      { columnDef: 'totalCheck', header: "Total Check" }
    ]
  }

  getauditTable(e?) {
    this.checkClickAudit = 'disabled';
    this.auditShow = true;
    this.auditTable = ['employee', 'timeDate', 'item', 'move', 'total'];
    // this.auditdataSource = this.summaryAudit.filter(x=>x.ticketid==e.ticketid);

    if (e.sessionid == 2) {
      this.employeediscounttableSource3 = this.summaryAudit;
      this.employeediscounttableSource3 = this.summaryAudit.filter(x => x.ticketid == e.ticketid && x.move != 'closed').sort((a, b) => a.timeDate < b.timeDate ? -1 : a.timeDate > b.timeDate ? 1 : 0);
      this.closedData = this.summaryAudit.filter(x => x.ticketid == e.ticketid && x.move == 'closed')

      this.transactions = [...this.employeediscounttableSource3, ...this.closedData];
      this.auditdataSource = this.transactions;

      //  console.log(this.employeediscounttableSource,"this.dataSourcefil")
    }


    this.auditcolumns = [
      { columnDef: 'employee', header: "Employee" },
      { columnDef: 'timeDate', header: "Time/Date" },
      { columnDef: 'item', header: "Item" },
      { columnDef: 'move', header: "Move" },
      { columnDef: 'total', header: "Total" },
    ]
  }

  GetSummaryItems(e?) {
    this.transactions = this.summartItems.filter(x => x.ticketid == e.ticketid)

    //  console.log(this.summartItems.filter(x=>x.ticketid==e.ticketid),'ddd')

    this.discountTotal = this.summartItems.filter(x => x.ticketid == e.ticketid).reduce((sum, current) => sum + current.discount, 0);
    this.subTotal = this.summartItems.filter(x => x.ticketid == e.ticketid).reduce((sum, current) => sum + Number(current.cost), 0);
    this.netTotal = Number(this.subTotal) - Number(this.discountTotal);
    this.TipsAmount = this.summartItems[0].TipsAmount;
    // console.log(this.discountTotal,'discountTotal')
    // console.log(this.subTotal,'subTotal')
    // console.log(this.netTotal,'netTotal')
    this.Filtertransactions = this.transactions.filter(y => y.itemType == 'Main');
  }

  onCheckItem(event) {
    this.itemClicked = event;
  }
  onChangeRow(event) {
    if (this.tabName == 'Summary' && this.itemClicked == event.discountname) {
      this.firstTableName = event.discountname;
      this.summaryTableShow = false;
      this.employeediscounttableShow = true;
      this.getEmployeeDiscountTable(event, 'Summary');
      //   console.log(event,"discount");
    }
    else if (this.tabName == 'By Sales Area' && this.itemClicked == event.category && this.itemClicked != 'Total') {
      this.firstTableName = event.category;
      this.salesName = event.groupHeader;
      this.salesTableShow = false;
      this.employeediscounttableShow = true;
      this.getEmployeeDiscountTable(event, 'SalesArea');
    }
    else if (this.tabName == 'By Approver' && this.itemClicked == event.category && this.itemClicked != 'Total') {
      this.firstTableName = event.category;
      this.approveName = event.groupHeader;
      this.approverTableShow = false;
      this.employeediscounttableShow = true;
      this.getEmployeeDiscountTable(event, 'Approver');
    }
    else if (this.tabName == 'By Employee' && this.itemClicked == event.category && this.itemClicked != 'Total') {
      this.firstTableName = event.category;
      this.empName = event.groupHeader;
      this.employeeTableShow = false;
      this.employeediscounttableShow = true;
      this.getEmployeeDiscountTable(event, 'Employee');
    }

    else if (this.tabName == 'Sales Area - Employee' && this.itemClicked != null) {
      if (this.itemClicked == event.discountName) {
        this.firstTableName = event.discountName;
        this.salesAreaByEmployee = false;
        this.employeediscounttableShow = true;
        this.getEmployeeDiscountTable(event, 'Sales Area - Employee');
      }
      else if (this.itemClicked == event.groupHeader) {
        this.salesName = event.groupHeader;
      }
      else if (this.itemClicked == event.employee) {
        this.empName = event.employee;
      }
    }
    else if (this.tabName == 'Sales Area - Approver' && this.itemClicked != null) {
      if (this.itemClicked == event.discountName) {
        this.firstTableName = event.discountName;
        this.salesAreaApproverTableShow = false;
        this.employeediscounttableShow = true;
        this.getEmployeeDiscountTable(event, 'Sales Area - Approver');
      }
      else if (this.itemClicked == event.groupHeader) {
        this.salesName = event.groupHeader;
      }
      else if (this.itemClicked == event.employee) {
        this.empName = event.employee;
      }
    }
  }

  onCheckAudit(event) {
    this.checkClickAudit = event;
  }
  onShowAuditRow(event) {
    if (this.tabName == 'Summary' && this.employeediscounttableShow == true && this.checkClickAudit == event.check) {
      this.auditName = event.check;
      this.auditEmployee = event.employeeName;

      let dateAssign = event.timeDate.substring(0, 8);
      let timeAssign = event.timeDate.substring(9, event.timeDate.length);

      this.auditDate = dateAssign;
      this.auditTime = timeAssign;
      this.auditMove = event.move;

      this.employeediscounttableShow = false;
      this.auditShow = true;
      this.GetCustomerDetails(event)
      this.getauditTable(event);
      this.GetSummaryItems(event);
    }
    else if (this.tabName == 'By Sales Area' && this.employeediscounttableShow == true && this.checkClickAudit == event.check) {
      this.auditName = event.check;
      this.auditEmployee = event.employeeName;

      let dateAssign = event.timeDate.substring(0, 8);
      let timeAssign = event.timeDate.substring(9, event.timeDate.length);

      this.auditDate = dateAssign;
      this.auditTime = timeAssign;
      this.auditMove = event.move;


      this.employeediscounttableShow = false;
      this.auditShow = true;
      this.GetCustomerDetails(event)
      this.getauditTable(event);
      this.GetSummaryItems(event);
    }
    else if (this.tabName == 'By Approver' && this.employeediscounttableShow == true && this.checkClickAudit == event.check) {
      this.auditName = event.check;
      this.auditEmployee = event.employeeName;

      let dateAssign = event.timeDate.substring(0, 8);
      let timeAssign = event.timeDate.substring(9, event.timeDate.length);

      this.auditDate = dateAssign;
      this.auditTime = timeAssign;
      this.auditMove = event.move;

      this.employeediscounttableShow = false;
      this.auditShow = true;
      this.GetCustomerDetails(event)
      this.getauditTable(event);
      this.GetSummaryItems(event);
    }
    else if (this.tabName == 'By Employee' && this.employeediscounttableShow == true && this.checkClickAudit == event.check) {
      this.auditName = event.check;
      this.auditEmployee = event.employeeName;

      let dateAssign = event.timeDate.substring(0, 8);
      let timeAssign = event.timeDate.substring(9, event.timeDate.length);

      this.auditDate = dateAssign;
      this.auditTime = timeAssign;
      this.auditMove = event.move;

      this.employeediscounttableShow = false;
      this.auditShow = true;
      this.GetCustomerDetails(event)
      this.getauditTable(event);
      this.GetSummaryItems(event);
    }
    else if (this.tabName == 'Sales Area - Employee' && this.employeediscounttableShow == true && this.checkClickAudit == event.check) {
      this.auditName = event.check;
      this.auditEmployee = event.employeeName;

      let dateAssign = event.timeDate.substring(0, 8);
      let timeAssign = event.timeDate.substring(9, event.timeDate.length);

      this.auditDate = dateAssign;
      this.auditTime = timeAssign;
      this.auditMove = event.move;

      this.employeediscounttableShow = false;
      this.auditShow = true;
      this.GetCustomerDetails(event)
      this.getauditTable(event);
      this.GetSummaryItems(event);
    }

    else if (this.tabName == 'Sales Area - Approver' && this.employeediscounttableShow == true && this.checkClickAudit == event.check) {
      this.auditName = event.check;
      this.auditEmployee = event.employeeName;

      let dateAssign = event.timeDate.substring(0, 8);
      let timeAssign = event.timeDate.substring(9, event.timeDate.length);

      this.auditDate = dateAssign;
      this.auditTime = timeAssign;
      this.auditMove = event.move;

      this.employeediscounttableShow = false;
      this.auditShow = true;
      this.GetCustomerDetails(event)
      this.getauditTable(event);
      this.GetSummaryItems(event);
    }
  }

  GetCustomerDetails(e?) {
    if (this.summaryCustomer.filter(x => x.ticketid == e.ticketid).length != 0) {
      this.CustomerDetails = this.summaryCustomer.filter(x => x.ticketid == e.ticketid);
    }
    else {
      this.CustomerDetails = this.CustomerList;
    }

  }
}
