import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { IBindReportFilter, ISalesReport } from 'src/app/interfaces/i-report';
import { HelperService } from 'src/app/helper/helper.service';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
//import * as moment from 'moment'
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export interface PeriodicElement {
  id?: number;
  sessionId?: number;
  name?: string;
  quantity?: number;
  size?: string;
  price?: number;
  grossSales?: number;
  netSales?: string;
  date?: string;
  ticket?: string;
  timeopened?: string;
  timeclosed?: string;
  employee?: string;
  salesarea?: string;
  total?: number;
  startdatetime?: string;
  move?: string;
  tabletid?: string;


}

// const ELEMENT_DATA: PeriodicElement[] = [
//   { id: 1, sessionId: 1, name: 'Beer', quantity: 350, grossSales: 1250.00, netSales: '' },
//   { id: 2, sessionId: 1, name: 'Food', quantity: 1315, grossSales: 13324.68, netSales: '' },
//   { id: 3, sessionId: 1, name: 'Lithium', quantity: 376, grossSales: 2350.00, netSales: '' },
//   { id: 4, sessionId: 1, name: 'Beryllium', quantity: 150, grossSales: 450.00, netSales: '' },
//   { id: 5, sessionId: 1, name: 'Boron', quantity: 63, grossSales: 819.00, netSales: '' },
// ];

// const ELEMENT_DATA1: PeriodicElement[] = [
//   { id: 6, sessionId: 2, name: 'Baked Potato', quantity: 350, grossSales: 1250.00, netSales: '' },
//   { id: 7, sessionId: 2, name: 'Caesar Salad', quantity: 1315, grossSales: 13324.68, netSales: '' },
//   { id: 8, sessionId: 2, name: 'Crispy Chicken Salad', quantity: 376, grossSales: 2350.00, netSales: '' },
//   { id: 9, sessionId: 2, name: 'French Fries', quantity: 150, grossSales: 450.00, netSales: '' },
//   { id: 10, sessionId: 2, name: 'Hamburger', quantity: 63, grossSales: 819.00, netSales: '' },
//   { id: 11, sessionId: 2, name: 'Tomato Burger', quantity: 150, grossSales: 450.00, netSales: '' },
//   { id: 12, sessionId: 2, name: 'Turkey Club', quantity: 63, grossSales: 819.00, netSales: '' },
// ];
// const ELEMENT_DATA2: PeriodicElement[] = [
//   { id: 13, sessionId: 3, name: 'Hamburger', quantity: 70, size: 'Single', price: 6, grossSales: 455.00, netSales: '' },
//   { id:14, sessionId: 3, name: 'Hamburger', quantity: 45, size: 'Single', price: 7, grossSales: 337.00, netSales: '' },
//   { id: 15, sessionId: 3, name: 'Hamburger', quantity: 112, size: 'Double', price: 8, grossSales: 896.00, netSales: '' },
//   { id: 16, sessionId: 3, name: 'Hamburger', quantity: 47, size: 'Double', price: 10, grossSales: 516.00, netSales: '' },
//   { id: 17, sessionId: 3, name: 'Hamburger', quantity: 32, size: 'Double', price: 13, grossSales: 432.00, netSales: '' },
//   { id: 18, sessionId: 3, name: 'Hamburger', quantity: 56, size: 'Triple', price: 11, grossSales: 616.00, netSales: '' },
// ];
// const ELEMENT_DATA3: PeriodicElement[] = [
//   { id: 19, sessionId: 4, date: "7-10-2021", name: 'DIN05', timeopened: "11:05:00", timeclosed: "11:32:00", employee: 'John', salesarea: "Dining Room", total: 26.5 },
//   { id: 20, sessionId: 4, date: "7-10-2021", name: 'DIN09', timeopened: "11:27:00", timeclosed: "11:43:00", employee: 'John', salesarea: "Dining Room", total: 26.5 },
//   { id: 21, sessionId: 4, date: "7-10-2021", name: 'DIN11', timeopened: "11:05:00", timeclosed: "12:33:00", employee: 'John', salesarea: "Dining Room", total: 26.5 },
//   { id: 22, sessionId: 4, date: "7-10-2021", name: 'DIN11', timeopened: "11:05:00", timeclosed: "12:33:00", employee: 'John', salesarea: "Dining Room", total: 26.5 },
//   { id: 23, sessionId: 4, date: "7-10-2021", name: 'DIN11', timeopened: "11:05:00", timeclosed: "12:33:00", employee: 'John', salesarea: "Dining Room", total: 26.5 },
//   { id: 24, sessionId: 4, date: "7-10-2021", name: 'DIN11', timeopened: "11:05:00", timeclosed: "12:33:00", employee: 'John', salesarea: "Dining Room", total: 26.5 },
//   { id: 25, sessionId: 4, date: "7-10-2021", name: 'DIN11', timeopened: "11:05:00", timeclosed: "12:33:00", employee: 'John', salesarea: "Dining Room", total: 26.5 },
// ];
// const ELEMENT_DATA4: PeriodicElement[] = [
//   { id: 26, employee: 'John', startdatetime: "07-10-2021  11:27:00", name: "Hamburger Triple", move: "Added", size: "Triple", price: 11, tabletid: "WS01", total: 0 },
//   { id: 27, employee: 'John', startdatetime: "07-10-2021  11:27:00", name: "French Fries", move: "Added", size: "", price: 6, tabletid: "WS01", total: 0 },
//   { id: 28, employee: 'John', startdatetime: "07-10-2021  11:27:00", name: "Soda", move: "Added", size: "", price: 6, tabletid: "WS01", total: 0 },
//   { id: 29, employee: 'John', startdatetime: "07-10-2021  11:27:00", name: "", move: "Sent", size: "", price: 6, tabletid: "WS01", total: 0 },
//   { id: 30, employee: 'John', startdatetime: "07-10-2021  11:27:00", name: "VISA", move: "Tendered", size: "", price: 6, tabletid: "WS01", total: 0 },
//   { id: 31, employee: 'John', startdatetime: "07-10-2021  11:27:00", name: "", move: "Tip Add", size: "", price: 6, tabletid: "WS01", total: 0 },
// ];

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ReportComponent implements OnInit {
  reportForm: FormGroup;
  submittedGeneral = false;
  data = false;
  isFirst = true
  public displayedColumns = [];
  public displayedSecColumns = [];
  public displayedThirdColumns = [];
  public displayedFourthColumns = [];
  public displayedFifthColumns = [];
  columns = [];
  public dataSource: any
  public dataSourceSecTable: any
  public dataSourceSThirdTable: any
  public dataSourceSFourthTable: any
  public dataSourceSFifthTable: any
  tableSecName: any
  tableThirdName: any
  tableFourthName: any
  tableFifthName: any;
  secondTableHide = false;
  thirdTableHide = false;
  fourthTableHide = false;
  fifthTableHide = false;
  JobPosition: any = [];
  EmployeeList: any = [];
  Menugroup: any = [];
  SalesArea: any = [];
  ItemTags: any = [];
  loginInfo: any;
  ELEMENT_DATA4: any = [];
  ELEMENT_DATA3: any = [];
  ELEMENT_DATA2: any = [];
  ELEMENT_DATA1: any = [];
  ELEMENT_DATA: any = [];
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  public employeeName: any;
  public employee: any;
  public employeeIcon: any
  public tableFirstColor: any;
  public tableSecColor: any;
  public tableThirdColor: any;
  public tableFourthColor: any;
  public SummaryTable2: any = [];
  reportSettings: IDropdownSettings = {};
  employeeAreaFilters = [];
  employeeArr: any;
  employeeselectedItems = [];
  // employeeAreaObj: FormControl = new FormControl('');
  jobFilters = [];
  jobArr: any;
  salesArr: any;
  menuArr: any;
  reportArr: any;
  jobselectedItems = [];
  jobAreaObj: FormControl = new FormControl('');
  salesFilters = [];
  saleselectedItems = [];
  salesAreaObj: FormControl = new FormControl('');
  menuFilters = [];
  menuselectedItems = [];
  menuAreaObj: FormControl = new FormControl('');
  reporcategoryFilters = [];
  reporcategoryselectedItems = [];
  reporcategoryAreaObj: FormControl = new FormControl('');
  public dataex = [];
  @Output() public GeneralDetailEventPrice = new EventEmitter();
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private reportService: ReportWebSocketService) { }


  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.report");

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Sales Reports";
    this.employee = "Reports";
    this.employeeIcon = "assets/components/images/reports-icon.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.reportForm = this.formBuilder.group({
      fromDate: moment(new Date()),
      toDate: moment(new Date()),
    })
    this.reportSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 1,
      selectAllText: 'Select All',
      unSelectAllText: 'Select All'
    };

    this.getFirstTable(null);
    this.bindFilterFields();
  }

  get f() {
    return this.reportForm.controls;
  }

  setForms() {
    this.reportForm = this.formBuilder.group({
      fromDate: moment(new Date()),
      toDate: moment(new Date()),
      employeeId: [null],
      jobPositionId: [null],
      salesarea: [null],
      menugroup: [null],
      reportCategory: [null],
      employeeAreaObj: new FormControl(this.employeeAreaFilters),
      employeeselectedItems: [null],
      employeeAreaFilters: [null],
      jobFilters: [null],
      jobselectedItems: [null],
      jobAreaObj: new FormControl(this.jobFilters),
      salesFilters: [null],
      saleselectedItems: [null],
      salesAreaObj: new FormControl(this.salesFilters),
      menuFilters: [null],
      menuselectedItems: [null],
      menuAreaObj: new FormControl(this.menuFilters),
      reporcategoryFilters: [null],
      reporcategoryselectedItems: [null],
      reporcategoryAreaObj: new FormControl(this.reporcategoryFilters),
    });
  }

  onReportSubmit() {
    this.ELEMENT_DATA = [];
    this.ELEMENT_DATA1 = [];
    this.ELEMENT_DATA2 = [];
    this.ELEMENT_DATA3 = [];
    this.ELEMENT_DATA4 = [];

    this.submittedGeneral = true;
    if (this.reportForm.invalid) {
      return;
    }

    this.employeeArr = this.reportForm.value.employeeAreaObj.map(i => i.key);
    this.jobArr = this.reportForm.value.jobAreaObj.map(i => i.key);
    this.salesArr = this.reportForm.value.salesAreaObj.map(i => i.key);
    this.menuArr = this.reportForm.value.menuAreaObj.map(i => i.key);
    this.reportArr = this.reportForm.value.reporcategoryAreaObj.map(i => i.key);


    const itemFilter: ISalesReport = {
      employee: this.employeeArr.toString() == "" ? null : this.employeeArr.toString(),
      jobPosition: this.jobArr.toString() == "" ? null : this.jobArr.toString(),
      salesArea: this.salesArr.toString() == "" ? null : this.salesArr.toString(),
      menuGroup: this.menuArr.toString() == "" ? null : this.menuArr.toString(),
      reportCategory: this.reportArr.toString() == "" ? null : this.reportArr.toString(),
      fromDate: moment(new Date(this.f.fromDate.value)).format('YYYY-MM-DD'),
      toDate: moment(new Date(this.f.toDate.value)).format('YYYY-MM-DD'),
      tenantId: this.loginInfo.tenantId
    }

    //console.log(itemFilter,'kaja444l')
    this.reportService.SelectSalesReport(itemFilter).subscribe((response) => {
      this.dataSource = response.ReportSummary;
      this.dataSourceSecTable = response.ItemDetails;
      this.dataSourceSThirdTable = response.ItemSizeDetails;
      this.dataSourceSFourthTable = response.TicketDetails;
      this.dataSourceSFifthTable = response.ItemMove;

      this.ELEMENT_DATA = response.ReportSummary;
      this.ELEMENT_DATA1 = response.ItemDetails;
      this.ELEMENT_DATA2 = response.ItemSizeDetails;
      this.ELEMENT_DATA3 = response.TicketDetails;
      this.ELEMENT_DATA4 = response.ItemMove;
    })
  }

  bindFilterFields() {
    const itemFilter: IBindReportFilter = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.reportService.BindFiltersFields(itemFilter).subscribe((response) => {
      this.EmployeeList = response.Employee;
      this.JobPosition = response.JobPosition;
      this.SalesArea = response.SalesArea;
      this.Menugroup = response.MenuGroup;
      this.ItemTags = response.TagItems;


      //----------------BINDING MULTI SELECT----------------------

      var itemEmp
      var jsonToBeUsedEmp = [];
      // console.log("bindFilterFields : ", response);
      for (var emptype in this.EmployeeList) {
        itemEmp = {};
        itemEmp.key = this.EmployeeList[emptype].id.toString();
        itemEmp.value = this.EmployeeList[emptype].name;
        jsonToBeUsedEmp.push(itemEmp);
      }
      this.employeeAreaFilters = jsonToBeUsedEmp;


      var itemJob
      var jsonToBeUsedJob = [];
      for (var jobtype in this.JobPosition) {
        itemJob = {};
        itemJob.key = this.JobPosition[jobtype].id.toString();
        itemJob.value = this.JobPosition[jobtype].name;
        jsonToBeUsedJob.push(itemJob);
      }
      this.jobFilters = jsonToBeUsedJob;


      var itemSales
      var jsonToBeUsedSales = [];
      for (var salestype in this.SalesArea) {
        itemSales = {};
        itemSales.key = this.SalesArea[salestype].id.toString();
        itemSales.value = this.SalesArea[salestype].name;
        jsonToBeUsedSales.push(itemSales);
      }
      this.salesFilters = jsonToBeUsedSales;


      var itemGrp
      var jsonToBeUsedGrp = [];
      for (var grptype in this.Menugroup) {
        itemGrp = {};
        itemGrp.key = this.Menugroup[grptype].id.toString();
        itemGrp.value = this.Menugroup[grptype].name;
        jsonToBeUsedGrp.push(itemGrp);
      }
      this.menuFilters = jsonToBeUsedGrp;


      var itemItmTag
      var jsonToBeUsedItmTag = [];
      for (var itmtagtype in this.ItemTags) {
        itemItmTag = {};
        itemItmTag.key = this.ItemTags[itmtagtype].id.toString();
        itemItmTag.value = this.ItemTags[itmtagtype].name;
        jsonToBeUsedItmTag.push(itemItmTag);
      }
      this.reporcategoryFilters = jsonToBeUsedItmTag;
      this.setForms();
      this.onReportSubmit();
    })
  }

  getTableIdx(e) {

    if (e.sessionId == 1) {
      this.getFirstTable(e);
      this.getSecondTable(e);
      this.tableSecName = e.name
      this.secondTableHide = true
      this.tableFirstColor = e.id
    } else if (e.sessionId == 2) {
      this.getSecondTable(e);
      this.getThirdTable(e);
      this.tableThirdName = e.name
      this.secondTableHide = true
      this.thirdTableHide = true
      this.tableSecColor = e.id
    } else if (e.sessionId == 3) {
      this.getThirdTable(e);
      this.getFourthTable(e);
      this.tableFourthName = e.size
      this.secondTableHide = true
      this.thirdTableHide = true
      this.fourthTableHide = true;
      this.tableThirdColor = e.id
    } else if (e.sessionId == 4) {
      this.getFifthTable(e);
      this.tableFifthName = e.name
      this.secondTableHide = true
      this.thirdTableHide = true
      this.fourthTableHide = true;
      this.fifthTableHide = true;
      this.tableFourthColor = e.id
    }
    else {
      // this.dataSourceSecTable = []
      // this.dataSourceSThirdTable = []
      // this.dataSourceSFourthTable = []
      // this.dataSourceSFifthTable = []
    }
  }
  backClick(e) {
    // if (e == 1) {
    //   this.dataSourceSecTable = []
    //   this.dataSourceSThirdTable = []
    //   this.dataSourceSFourthTable = []
    //   this.dataSourceSFifthTable = []
    //   this.tableSecName = ""
    //   this.dataSource =  this.ELEMENT_DATA;
    //   this.secondTableHide = false
    //   this.thirdTableHide = false
    //   this.fourthTableHide = false
    //   this.fifthTableHide = false;
    //   this.tableFirstColor = 'transparent'

    // }
    if (e == 2) {
      this.dataSourceSecTable = []
      this.dataSourceSThirdTable = []
      this.dataSourceSFourthTable = []
      this.dataSourceSFifthTable = []
      this.tableSecName = ""
      this.dataSource = this.ELEMENT_DATA;
      this.secondTableHide = false
      this.thirdTableHide = false
      this.fourthTableHide = false
      this.fifthTableHide = false;
      this.tableFirstColor = 'transparent'

    }
    if (e == 3) {
      this.dataSourceSThirdTable = []
      this.dataSourceSFourthTable = []
      this.dataSourceSFifthTable = []
      this.tableThirdName = ""
      this.dataSourceSecTable = this.ELEMENT_DATA1;
      this.thirdTableHide = false
      this.fourthTableHide = false
      this.fifthTableHide = false;
      this.tableSecColor = 'transparent'
    }
    if (e == 4) {
      this.dataSourceSFourthTable = []
      this.dataSourceSFifthTable = []
      this.tableFourthName = ""
      this.dataSourceSThirdTable = this.ELEMENT_DATA2;
      this.fourthTableHide = false
      this.fifthTableHide = false;
      this.tableThirdColor = 'transparent'
    }
    if (e == 5) {
      this.dataSourceSFifthTable = []
      this.tableFifthName = ""
      this.dataSourceSFourthTable = this.ELEMENT_DATA3;
      this.fifthTableHide = false;
      this.tableFourthColor = 'transparent'
    }
  }

  getFirstTable(e?) {
    this.displayedColumns = ['name', 'quantity', 'grossSales', 'netSales'];
    if (e == null) {
      this.dataSource = this.ELEMENT_DATA;
    } else {
      this.dataSource = this.dataSource.filter(x => x.name == e.name)
    }

    //  this.dataSource = this.dataSource.filter(x => x.name == e.name)
    this.columns = [
      { columnDef: 'name', header: "Report Category" },
      { columnDef: 'quantity', header: "QTY" },
      { columnDef: 'grossSales', header: "Gross Sales" },
      { columnDef: 'netSales', header: "Net Sales" },
    ]
  }

  getSecondTable(e?) {
    this.displayedSecColumns = ['name', 'quantity', 'price', 'cost', 'grossSales', 'grossCost', 'totalDiscounts', 'netSales',
      'ofCategory', 'grossSalesper', 'grossCostper', 'foodCostper'];
    if (e == null) {
      this.dataSourceSecTable = this.ELEMENT_DATA1;
    } else {
      if (e.sessionId == 1) {
        this.dataSourceSecTable = this.ELEMENT_DATA1;
        this.dataSourceSecTable = this.dataSourceSecTable.filter(x => x.ReportCategory == e.name)
      }
      else {
        this.dataSourceSecTable = this.dataSourceSecTable.filter(x => x.name == e.name)
      }
      var TotalGross = this.dataSourceSecTable.reduce((sum, current) => sum + Number(current.grossSales), 0);
      var TotalNet = this.dataSourceSecTable.reduce((sum, current) => sum + Number(current.netSales), 0);
      var TotalCost = this.dataSourceSecTable.reduce((sum, current) => sum + Number(current.grossCost), 0);
      console.log(TotalCost, 'TotalNet');

      this.dataSourceSecTable.forEach((keys: any, vals: any) => {

        // keys.grossSalesper= ((Number(keys.grossSales)/Number(TotalGross))*100).toFixed(2),
        // keys.ofCategory=keys.ofCategory.toFixed(2)
        keys.grossSalesper = ((Number(keys.grossSales) / Number(TotalGross)) * 100).toFixed(2),
          keys.ofCategory = ((Number(keys.netSales) / Number(TotalNet)) * 100).toFixed(2),
          keys.grossCostper = Number(TotalCost) == 0 ? 0 : ((Number(keys.grossCost) / Number(TotalCost)) * 100).toFixed(2)

      })



      // console.log( this.SummaryTable2,' this.SummaryTable2')

    }


    this.columns = [
      { columnDef: 'name', header: "Category" },
      { columnDef: 'quantity', header: "QTY" },
      { columnDef: 'price', header: "Weighted Avg Price" },
      { columnDef: 'cost', header: "Weighted Avg Cost" },
      { columnDef: 'grossSales', header: "Gross Sales" },
      { columnDef: 'grossCost', header: "Gross Cost" },
      { columnDef: 'totalDiscounts', header: "Total Discounts" },
      { columnDef: 'netSales', header: "Net Sales" },
      { columnDef: 'ofCategory', header: "% of Category" },
      { columnDef: 'grossSalesper', header: "Gross Sales %" },
      { columnDef: 'grossCostper', header: "Gross Cost %" },
      { columnDef: 'foodCostper', header: "Food Cost %" },
    ]

  }
  getThirdTable(e?) {
    //this.displayedThirdColumns = ['name', 'quantity', 'size', 'price', 'grossSales', 'netSales', ];
    this.displayedThirdColumns = ['name', 'quantity', 'size', 'price', 'cost', 'grossSales', 'grossCost', 'totalDiscounts', 'netSales',
      'ofCategory', 'grossSalesper', 'grossCostper', 'foodCostper'];
    if (e == null) {
      this.dataSourceSThirdTable = this.ELEMENT_DATA2;
    } else {
      if (e.sessionId == 2) {
        this.dataSourceSThirdTable = this.ELEMENT_DATA2;
        this.dataSourceSThirdTable = this.dataSourceSThirdTable.filter(x => x.ReportCategory == e.ReportCategory && x.name == e.name)

      }
      else {
        this.dataSourceSThirdTable = this.dataSourceSThirdTable.filter(x => x.size == e.size && x.ReportCategory == e.ReportCategory && x.name == e.name)

      }
      var TotalGross = this.dataSourceSThirdTable.reduce((sum, current) => sum + Number(current.grossSales), 0);
      var TotalNet = this.dataSourceSThirdTable.reduce((sum, current) => sum + Number(current.netSales), 0);
      var TotalCost = this.dataSourceSThirdTable.reduce((sum, current) => sum + Number(current.grossCost), 0);
      console.log(TotalCost, 'TotalNet');

      this.dataSourceSThirdTable.forEach((keys: any, vals: any) => {

        // keys.grossSalesper= ((Number(keys.grossSales)/Number(TotalGross))*100).toFixed(2),
        // keys.ofCategory=keys.ofCategory.toFixed(2)
        keys.grossSalesper = ((Number(keys.grossSales) / Number(TotalGross)) * 100).toFixed(2),
          keys.ofCategory = ((Number(keys.netSales) / Number(TotalNet)) * 100).toFixed(2),
          keys.grossCostper = Number(TotalCost) == 0 ? 0 : ((Number(keys.grossCost) / Number(TotalCost)) * 100).toFixed(2)

      });
    }
    // this.columns = [
    //   { columnDef: 'name', header: "Item Name" },
    //   { columnDef: 'quantity', header: "Count of Items" },
    //   { columnDef: 'size', header: "Size" },
    //   { columnDef: 'price', header: "Price" },
    //   { columnDef: 'grossSales', header: "Gross Sales" },
    //   { columnDef: 'netSales', header: "Net Sales" },
    // ]
    this.columns = [
      { columnDef: 'name', header: "Item Name" },
      { columnDef: 'quantity', header: "Count of Items" },
      { columnDef: 'size', header: "Size" },
      { columnDef: 'price', header: "Unit Price" },
      { columnDef: 'cost', header: "Unit Cost" },
      { columnDef: 'grossSales', header: "Gross Sales" },
      { columnDef: 'grossCost', header: "Gross Cost" },
      { columnDef: 'totalDiscounts', header: "Total Discounts" },
      { columnDef: 'netSales', header: "Net Sales" },
      { columnDef: 'ofCategory', header: "% of Category" },
      { columnDef: 'grossSalesper', header: "Gross Sales %" },
      { columnDef: 'grossCostper', header: "Gross Cost %" },
      { columnDef: 'foodCostper', header: "Food Cost %" },
    ]
  }
  getFourthTable(e?) {
    this.displayedFourthColumns = ['date', 'name', 'timeopened', 'timeclosed', 'employee', 'salesarea', 'total'];
    // this.dataSourceSFourthTable = this.ELEMENT_DATA3;
    if (e == null) {
      this.dataSourceSFourthTable = this.ELEMENT_DATA3;
    } else {
      if (e.sessionId == 3) {
        this.dataSourceSFourthTable = this.ELEMENT_DATA3;
        this.dataSourceSFourthTable = this.dataSourceSFourthTable.filter(x => x.ReportCategory == e.ReportCategory && x.itemName == e.name)

      }
      else {
        this.dataSourceSFourthTable = this.dataSourceSFourthTable.filter(x => x.ReportCategory == e.ReportCategory && x.itemName == e.name)

      }
    }
    this.columns = [
      { columnDef: 'date', header: "Date" },
      { columnDef: 'name', header: "Tickets" },
      { columnDef: 'timeopened', header: "Time Opened" },
      { columnDef: 'timeclosed', header: "Time Closed" },
      { columnDef: 'employee', header: "Employee" },
      { columnDef: 'salesarea', header: "Sales Area" },
      { columnDef: 'total', header: "Total" },
    ]
  }


  getFifthTable(e?) {
    this.displayedFifthColumns = ['employee', 'startdatetime', 'name', 'move', 'size', 'price', 'tabletid', 'total'];
    if (e == null) {
      this.dataSourceSFifthTable = this.ELEMENT_DATA4;
    } else {
      if (e.sessionId == 4) {
        this.dataSourceSFifthTable = this.ELEMENT_DATA4;
        this.dataSourceSFifthTable = this.dataSourceSFifthTable.filter(x => x.employee == e.employee)

      }
      else {
        this.dataSourceSFifthTable = this.dataSourceSFifthTable.filter(x => x.employee == e.employee && x.name == e.itemName)

      }
    }
    this.columns = [
      { columnDef: 'employee', header: "Employee" },
      { columnDef: 'startdatetime', header: "Start Date Time" },
      { columnDef: 'name', header: "Items" },
      { columnDef: 'move', header: "Move" },
      { columnDef: 'size', header: "Size" },
      { columnDef: 'price', header: "Price" },
      { columnDef: 'tabletid', header: "Total ID" },
      { columnDef: 'total', header: "Total" }
    ]
  }
  // onChangeAccordion(e) {

  //   if(e.currentTarget.innerText == "Personal data"){
  //     this.data = false
  //   }else
  //   if(e.currentTarget.innerText == "Day"){
  //     this.data = false
  //   }else
  //   if(e.currentTarget.innerText == "Day of the trip"){
  //     this.data = true
  //   }

  // }

}
