<div class="time-body">
    <form class="loginForm form-switch table_forms report-form summary-report-form" [formGroup]="reportForm">
        <div class="row no-gutters">
            <div class="col-sm-1 col-6">
                <div class="emp-time">
                    <div class="form-group">
                        <label>From Date</label>
                        <mat-form-field appearance="fill" class="custom-date">
                            <input matInput [matDatepicker]="picker" placeholder="From Date" class="form-control datepicker" appInputRestriction formControlName="fromDate" (click)="picker.open()">
                            <mat-datepicker #picker class="example-header"></mat-datepicker>
                        </mat-form-field>
                        <!-- <input type="text" placeholder="From Date" class="form-control datepicker" bsDatepicker
                            formControlName="fromDate" #dpYMD="bsDatepicker" appInputRestriction
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"> -->
                    </div>
                </div>
            </div>
            <div class="col-sm-1 col-6">
                <div class="emp-time">
                    <div class="form-group">
                        <label>To Date</label>
                        <mat-form-field appearance="fill" class="custom-date">
                            <input matInput [matDatepicker]="pickerA" placeholder="To Date" class="form-control datepicker" appInputRestriction formControlName="toDate" (click)="pickerA.open()">
                            <mat-datepicker #pickerA class="example-header"></mat-datepicker>
                        </mat-form-field>
                        <!-- <input type="text" placeholder="To Date" class="form-control datepicker report-datepicker" bsDatepicker
                            formControlName="toDate" #dpYMD="bsDatepicker" appInputRestriction
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }">  -->
                    </div>
                </div>
            </div>
            <div class="col-sm-1 col-6">
                <div class="emp-time">
                    <div class="form-group mat-box">
                        <label>Employee</label>
                        <!-- <mat-form-field appearance="fill">
                            <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="employeeId">
                                <mat-option [value]="null" selected disabled>All</mat-option>
                                  <mat-option *ngFor="let item of EmployeeList" [value]="item.id">{{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <!-- <ng-multiselect-dropdown [settings]="reportSettings" [data]="employeeAreaFilters"
                            [(ngModel)]="employeeselectedItems" [formControl]="employeeAreaObj">
                        </ng-multiselect-dropdown> -->
                        <ng-multiselect-dropdown [settings]="reportSettings" [data]="employeeAreaFilters" [formControl]="f.employeeAreaObj">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-sm-1 col-6">
                <div class="emp-time">
                    <div class="form-group mat-box">
                        <label>Job Position</label>
                        <!-- <mat-form-field appearance="fill">
                            <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="jobPositionId">
                                <mat-option [value]="null" selected disabled>All</mat-option>
                                <mat-option *ngFor="let item of JobPosition" [value]="item.id">{{item.name}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <!-- <ng-multiselect-dropdown [settings]="reportSettings" [data]="jobFilters"
                            [(ngModel)]="jobselectedItems" [formControl]="jobAreaObj">
                        </ng-multiselect-dropdown> -->
                        <ng-multiselect-dropdown [settings]="reportSettings" [data]="jobFilters" [formControl]="f.jobAreaObj">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-sm-1 col-6">
                <div class="emp-time">
                    <div class="form-group">
                        <label>Sales Area</label>
                        <!-- <mat-form-field appearance="fill">
                            <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="salesarea">
                                <mat-option [value]="null" selected disabled>All</mat-option>
                                <mat-option *ngFor="let item of SalesArea" [value]="item.id">{{item.name}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <!-- <ng-multiselect-dropdown [settings]="reportSettings" [data]="salesFilters"
                            [(ngModel)]="saleselectedItems" [formControl]="salesAreaObj">
                        </ng-multiselect-dropdown> -->
                        <ng-multiselect-dropdown [settings]="reportSettings" [data]="salesFilters" [formControl]="f.salesAreaObj">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-sm-1 col-6">
                <div class="emp-time">
                    <div class="form-group">
                        <label>Menu Group</label>
                        <!-- <mat-form-field appearance="fill">
                            <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="menugroup">
                                <mat-option [value]="" selected disabled>All</mat-option>
                                <mat-option *ngFor="let item of Menugroup" [value]="item.id">{{item.name}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <!-- <ng-multiselect-dropdown [settings]="reportSettings" [data]="menuFilters"
                            [(ngModel)]="menuselectedItems" [formControl]="menuAreaObj">
                        </ng-multiselect-dropdown> -->
                        <ng-multiselect-dropdown [settings]="reportSettings" [data]="menuFilters" [formControl]="f.menuAreaObj">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-sm-1 col-6">
                <div class="emp-time">
                    <div class="form-group mat-box">
                        <label>Report Category</label>
                        <!-- <mat-form-field appearance="fill">
                            <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="reportCategory">
                                <mat-option [value]="null" selected disabled>All</mat-option>
                                <mat-option *ngFor="let item of ItemTags" [value]="item.id">{{item.name}}
                                    </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <!-- <ng-multiselect-dropdown [settings]="reportSettings" [data]="reporcategoryFilters"
                            [(ngModel)]="reporcategoryselectedItems" [formControl]="reporcategoryAreaObj">
                        </ng-multiselect-dropdown> -->
                        <ng-multiselect-dropdown [settings]="reportSettings" [data]="reporcategoryFilters" [formControl]="f.reporcategoryAreaObj">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-sm-1 col-6">
                <div class="emp-time">
                    <div class="form-group mat-box">
                        <label class="hide_report">xxx</label>
                        <button class="btn submit_button form-control timeEditBtn" type="submit" (click)="onReportSubmit();">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row loginForm report-table">
        <div class="col-sm col" [ngClass]="{'col-sm-6': fourthTableHide == true}">
            <div class="table_wrap">
                <div class="table_header outshadow">
                    <!-- <label class="table_title"></label> -->
                    <div class="row">
                        <div class="col-auto mr-auto"></div>
                        <div class="col-auto"><a (click)="backClick(1)"><i class="fa fa-long-arrow-left"></i></a></div>
                    </div>
                    <!-- <span><i class="fa fa-long-arrow-left"></i></span> -->
                </div>
                <div class="table_content">
                    <app-fliptable-footer [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" (tableItem)="getTableIdx($event)" [employeeItem]="tableFirstColor"></app-fliptable-footer>
                </div>
            </div>
            <div class="table_wrap summary-section">
                <div class="table_header outshadow">
                    <div class="row" *ngIf="secondTableHide">
                        <div class="col-auto mr-auto"><label class="table_title">{{tableSecName}}</label></div>
                        <div class="col-auto"><a (click)="backClick(2)"><i class="fa fa-long-arrow-left"></i></a></div>
                    </div>
                </div>
                <div class="table_content" *ngIf="secondTableHide">
                    <app-fliptable-footer [dataSource]="dataSourceSecTable" [displayedColumns]="displayedSecColumns" [columns]="columns" (tableItem)="getTableIdx($event)" [employeeItem]="tableSecColor"></app-fliptable-footer>
                </div>
            </div>
            <div class="table_wrap summary-section">
                <div class="table_header outshadow">
                    <div class="row" *ngIf="secondTableHide">
                        <div class="col-auto mr-auto"><label class="table_title" *ngIf="thirdTableHide">{{tableSecName}}
                                <span>>></span> {{tableThirdName}}</label></div>
                        <div class="col-auto"><a (click)="backClick(3)"><i class="fa fa-long-arrow-left"></i></a></div>
                    </div>
                </div>
                <div class="table_content" *ngIf="thirdTableHide">
                    <app-fliptable-footer [dataSource]="dataSourceSThirdTable" [displayedColumns]="displayedThirdColumns" [columns]="columns" (tableItem)="getTableIdx($event)" [employeeItem]="tableThirdColor"></app-fliptable-footer>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-12" *ngIf="fourthTableHide || fifthTableHide">
            <div class="table_wrap">
                <div class="table_header outshadow">
                    <div class="row" *ngIf="fourthTableHide">
                        <div class="col-auto mr-auto"><label class="table_title" *ngIf="fourthTableHide">{{tableSecName}} <span>>></span> {{tableThirdName}}
                                <span>>></span> {{tableFourthName}} </label></div>
                        <div class="col-auto"><a (click)="backClick(4)"><i class="fa fa-long-arrow-left"></i></a></div>
                    </div>
                </div>
                <div class="table_content extend_height " *ngIf="fourthTableHide" [ngClass]="{'compress_height' : fifthTableHide == true}">
                    <app-fliptable-footer [dataSource]="dataSourceSFourthTable" [displayedColumns]="displayedFourthColumns" [columns]="columns" (tableItem)="getTableIdx($event)" [footerHide]="fourthTableHide" [employeeItem]="tableFourthColor"></app-fliptable-footer>
                </div>
            </div>
            <div class="table_wrap">
                <div class="table_header outshadow">
                    <div class="row" *ngIf="fifthTableHide">
                        <div class="col-auto mr-auto"><label class="table_title" *ngIf="fifthTableHide">{{tableSecName}} <span>>></span> {{tableThirdName}} <span>>></span> {{tableFourthName}} <span>>></span> {{tableFifthName}} </label></div>
                        <div class="col-auto"><a (click)="backClick(5)"><i class="fa fa-long-arrow-left"></i></a></div>
                    </div>
                </div>
                <div class="table_content" *ngIf="fifthTableHide" [ngClass]="{'compress_height' : fifthTableHide == true}">
                    <app-fliptable-footer [dataSource]="dataSourceSFifthTable" [displayedColumns]="displayedFifthColumns" [columns]="columns" (tableItem)="getTableIdx($event)" [footerHide]="fourthTableHide"></app-fliptable-footer>
                </div>
            </div>
        </div>
    </div>
</div>
