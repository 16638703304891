import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import { HelperService } from '../helper/helper.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import {
  GetAllUserList, GetEmployee, IUservalue, IRolePermission, IPayrollSave, ISelectPayrollDetails,
  IActivatePayrollDetails, ICashMgtSave,iGetIngnorePrintGroup
} from '../interfaces/iemployee';
import { BehaviorSubject } from 'rxjs';
import { IempId, IempRoleId, IActiveUser, IrowUpdate } from '../interfaces/iadmin';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  empParentData: any;
  wagesRoleData: any;

  wagesCheckData: any;
  clickValues: any;

  employeeWagesSource = new BehaviorSubject<any>({});

  empIDParentVal = new BehaviorSubject<IempId>({
    id: ""
  })
  empWagesRoleId = new BehaviorSubject<IempRoleId>({
    RoleId: ""
  })
  clickValue = new BehaviorSubject<IrowUpdate>({
    clickItem: "",
  })


  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) {
    this.wagesCheckData = this.employeeWagesSource.asObservable();
    this.empParentData = this.empIDParentVal.asObservable();
    this.wagesRoleData = this.empWagesRoleId.asObservable();
    this.clickValues = this.clickValue.asObservable();

  }

  checkWagesValue(wagesCheckData: any) {
    this.employeeWagesSource.next(wagesCheckData);
  }
  checkIdvalue(empParentData: any) {
    this.empIDParentVal.next(empParentData);
  }
  checkRoleIdvalue(wagesRoleData: any) {
    this.empWagesRoleId.next(wagesRoleData);
  }
  clickUpdate(clickValues: IrowUpdate) {
    this.clickValue.next(clickValues);
  }

  UserEmployee(employee: IUservalue) {
    return this.base.post('Employee/SaveEmployee', employee).pipe(map((response: any) => {
      return response;
    }));
  }

  EmployeeRoleValidation(employee: IUservalue) {
    return this.base.post('Employee/EmployeeRoleValidation', employee).pipe(map((response: any) => {
      return response;
    }));
  }
  GetEmployee(employee: GetEmployee) {
    return this.base.post('Employee/GetEmployee', employee).pipe(map((response: any) => {
      return response;
    }));
  }

  GetHierarchyEmployee(employee: GetEmployee) {
    return this.base.post('Employee/GetHierarchyEmployee', employee).pipe(map((response: any) => {
      return response;
    }));
  }
  GetAllEmployees(employee: GetAllUserList) {
    return this.base.post('Employee/GetAllEmployees', employee).pipe(map((response: any) => {
      return response;
    }));
  }
  GetRolePermissionDefault(employee: IRolePermission) {
    return this.base.post('Employee/GetRolePermissionDefault', employee).pipe(map((response: any) => {
      return response;
    }));
  }
  ValidateRolePermission(employee: IRolePermission) {
    return this.base.post('Admin/ValidateRolePermission', employee).pipe(map((response: any) => {
      return response;
    }));
  }
  IsActiveEmpUser(isActive: IActiveUser) {
    return this.base.post('Employee/ActivateDeActivateEmployee', isActive).pipe(map((response: any) => {
      return response;
    }));
  }

  SavePayroll(payrollDetailsSave: IPayrollSave) {
    return this.base.post('Employee/SavePayrollGeneralDetails', payrollDetailsSave).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectPayrollDetails(payrollDetailsSelect: ISelectPayrollDetails) {
    return this.base.post('Employee/SelectPayrollGeneralDetails', payrollDetailsSelect).pipe(map((response: any) => {
      return response;
    }));
  }

  ActivatePayrollDetails(payrollDetailsActivate: IActivatePayrollDetails) {
    return this.base.post('Employee/DeletePayrollGeneralDetails', payrollDetailsActivate).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveCashManagement(CashMgtSave: ICashMgtSave) {
    return this.base.post('Employee/SaveCashManagement', CashMgtSave).pipe(map((response: any) => {
      return response;
    }));
  }

  GetIngnorePrintGroup(GetIPG: iGetIngnorePrintGroup) {
    return this.base.post('Employee/GetIngnorePrintGroup', GetIPG).pipe(map((response: any) => {
      return response;
    }));
  }
}
