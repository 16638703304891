import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
export interface PeriodicElement {
  id?: number;
  fName?: any;
  lName?: any;
  jobPosition?: any;
  regHours?: any;
  regRate?: any;
  regPay?: any;
  otHours?: any;
  otRate?: any;
  otPay?: any;
  totalHours?: any;
  totalPay?: any;
  ccTip?: any;
  tipReceive?: any;
  tipOuts?: any;
  serviceCharge?: any;
  netSales?: any;
  charges?: any;
  report?: any;
  totalTip?: any;
  tipPer?: any;
}
// const ELEMENT_DATA1: PeriodicElement[] = [
//   { id: 1, fName:'Jason ', lName:'Lehman', jobPosition:'Owner', regHours:32.899, regRate:14.00, regPay: 460.59, otHours: 0, 
//   otRate: 21.00, otPay: 0.00, totalHours: 32.899  , totalPay: 460.59, ccTip: 676.56, tipReceive: 0.00, tipOuts: 0.00, serviceCharge: 0.00,
//   netSales: 3561.49, charges: 0.0, report: 776.56, totalTip: 676.56, tipPer: 21.80},
//   { id: 2, fName:'Garrett H', lName:'Heywood', jobPosition:'Server', regHours:32.899, regRate:14.00, regPay: 460.59, otHours: 0, 
//   otRate: 21.00, otPay: 0.00, totalHours: 32.899  , totalPay: 460.59, ccTip: 676.56, tipReceive: 0.00, tipOuts: 0.00, serviceCharge: 0.00,
//   netSales: 3561.49, charges: 0.0, report: 776.56, totalTip: 676.56, tipPer: 21.80},
// ];

@Component({
  selector: 'app-payroll-report',
  templateUrl: './payroll-report.component.html',
  styleUrls: ['./payroll-report.component.css']
})
export class PayrollReportComponent implements OnInit {
  loginInfo: any
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public backSalesItem = new EventEmitter
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public displayedColumns1 = [];
  columns1 = [];
  ELEMENT_DATA1 = []; // For Payroll data
  public dataSource1: any;
  public firstTableShow: boolean = false
  public secondTableShow: boolean = false
  public backToSales: boolean = false

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private reportService: ReportWebSocketService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.payrollReport");

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Payroll Reports";
    this.employee = "Reports";
    this.employeeIcon = "assets/components/images/reports-icon.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.getFirstTable()
  }

  GetCreditCardDetails(e?) {
    this.reportService.SelectPayrollReport(e).subscribe(res => {
      this.ELEMENT_DATA1 = res.summary;
      this.dataSource1 = this.ELEMENT_DATA1;
    });

    this.firstTableShow = true;
    this.displayedColumns1 = ['fName', 'jobPosition', 'regHours', 'regRate', 'regPay', 'otHours', 'otRate', 'otPay', 'totalHours',
      'totalPay', 'ccTip', 'cashTip', 'netSales', 'totalTip',
    ];
    //this.dataSource1 = [];
    this.columns1 = [
      { columnDef: 'fName', header: "Employee first Name" },
      //   { columnDef: 'lName', header: "Employee Last Name" },
      { columnDef: 'jobPosition', header: "Job Position" },
      { columnDef: 'regHours', header: "Regular Hours" },
      { columnDef: 'regRate', header: "Regular Rate" },
      { columnDef: 'regPay', header: "Reg Pay" },
      { columnDef: 'otHours', header: "OT Hours" },
      { columnDef: 'otRate', header: "OT Rate" },
      { columnDef: 'otPay', header: "OT Pay" },
      { columnDef: 'totalHours', header: "Total Hours" },
      { columnDef: 'totalPay', header: "Total Pay" },
      { columnDef: 'ccTip', header: "CC Tips" },
      { columnDef: 'cashTip', header: "Cash Tip" },
      // { columnDef: 'tipOuts', header: "Tip Outs" },
      //  { columnDef: 'serviceCharge', header: "Service Charge / Gratuity" },
      { columnDef: 'netSales', header: "Net Sales" },
      // { columnDef: 'charges', header: "Charges" },
      // { columnDef: 'report', header: "Reported" },
      { columnDef: 'totalTip', header: "Total Tips " },
      // { columnDef: 'tipPer', header: "Tip %" },
    ]

  }

  getFirstTable() {
    this.firstTableShow = true;
    this.displayedColumns1 = ['fName', 'jobPosition', 'regHours', 'regRate', 'regPay', 'otHours', 'otRate', 'otPay', 'totalHours',
      'totalPay', 'ccTip', 'cashTip', 'netSales', 'totalTip',
    ];
    this.dataSource1 = [];
    this.columns1 = [
      { columnDef: 'fName', header: "Employee first Name" },
      //{ columnDef: 'lName', header: "Employee Last Name" },
      { columnDef: 'jobPosition', header: "Job Position" },
      { columnDef: 'regHours', header: "Regular Hours" },
      { columnDef: 'regRate', header: "Regular Rate" },
      { columnDef: 'regPay', header: "Reg Pay" },
      { columnDef: 'otHours', header: "OT Hours" },
      { columnDef: 'otRate', header: "OT Rate" },
      { columnDef: 'otPay', header: "OT Pay" },
      { columnDef: 'totalHours', header: "Total Hours" },
      { columnDef: 'totalPay', header: "Total Pay" },
      { columnDef: 'ccTip', header: "CC Tips" },
      { columnDef: 'cashTip', header: "Cash Tip" },
      // { columnDef: 'tipReceive', header: "Tip Out Received" },
      // { columnDef: 'tipOuts', header: "Tip Outs" },
      //  { columnDef: 'serviceCharge', header: "Service Charge / Gratuity" },
      { columnDef: 'netSales', header: "Net Sales" },
      //  { columnDef: 'charges', header: "Charges" },
      //  { columnDef: 'report', header: "Reported" },
      { columnDef: 'totalTip', header: "Total Tips " },
      //  { columnDef: 'tipPer', header: "Tip %" },
    ]
  }

}
