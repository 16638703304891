import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { HttpClient } from '@angular/common/http';
import { iTimeClockSocket, iFrontEndMsg } from '../interfaces/iWebSocket';
import { from } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperService } from '../helper/helper.service';
import { iSaveTimeClock } from '../interfaces/itime';
import { TimeService } from './time.service';
import * as moment from 'moment'
@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  webSocketdata1:any;
  private hubConnection: HubConnection
 
 public PunchList: iTimeClockSocket[] = [];
  public TimeClockMsg: iFrontEndMsg[] = [];
  public connectionId : string;
  loginInfo:any;
  public i=1;
  public sum=0;
  private connectionUrl = 'http://204.236.184.97/Handshake/FlipSocketRequest';
  private apiUrl = 'http://204.236.184.97/Handshake/FlipSocketRequest';

  
  
  constructor(private http: HttpClient,private Sanitizer: DomSanitizer,private helper: HelperService,
    private timeEditDetails: TimeService) { }

  public connect = () => {
   // this.loginInfo = this.helper.getValue('LoginInfo');
    
    this.startConnection();
    this.addListeners();
  }
  private getConnection(): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(this.connectionUrl)
    //  .withHubProtocol(new MessagePackHubProtocol())  
       // .configureLogging(LogLevel.Trace)
        //.withAutomaticReconnect([2])
      .build();
  }

  private startConnection() {
    this.hubConnection = this.getConnection();

    this.hubConnection.start()
      .then(() => console.log('connection started'))
      .then(() => this.getConnectionId())
      .catch((err) => console.log('error while establishing signalr connection: ' + err))
  }

  private addListeners() {
    this.loginInfo = this.helper.getValue('LoginInfo');
   
    this.hubConnection.on("messageReceivedFromApi", (data: iFrontEndMsg) => {
   //   console.log(this.loginInfo.tenantId,"loginInfo")
      if(data.entityType=='TimePunch' && this.loginInfo.tenantId==data.tenantId)
      {
         this.sum=this.sum+this.i;
        console.log(this.sum,"Time Clock message received from API Controller")
      
         this.buildTimeClockdDetails(data);
         
      }
    })

      this.hubConnection.on("MessageReceivedFromFLipHub", (data: iFrontEndMsg) => {
        if(data.entityType=='TimePunch'&& this.loginInfo.tenantId==data.tenantId)
        {
          console.log(" Time Clock message received from Hub")
         
           this.buildTimeClockdDetails(data);
        }
      })
    this.hubConnection.on("newUserConnected", _ => {
     // this.PunchList=[];
      console.log("new user connected")
    })
  }
    
  public getConnectionId = () => {
    this.hubConnection.invoke('getconnectionid').then(
      (data) => {
        console.log(data,'ConnectionID');
          this.connectionId = data;
        }
    ); 
  }
  public sendMessageToHub(TimeClockMsg) {
    var promise = this.hubConnection.invoke("BroadcastAsync",TimeClockMsg)
      .then(() => { console.log('message sent successfully to hub'); })
      .catch((err) => console.log('error while sending a message to hub: ' + err));

    return from(promise);
  }

  private buildTimeClockdDetails(EntityVal:iFrontEndMsg): iTimeClockSocket {
  
     if(EntityVal.entityType=='TimePunch')
     {
      //this.PunchList.push(
          this.webSocketdata1 = JSON.parse(EntityVal.attributes)
          for (var i = 0; i <= this.webSocketdata1.breakRefs.length; i++)
          {
            console.log(this.webSocketdata1.breakRefs.length,'BreakLenght')
   
       //   this.onSaveTimeEdit('',this.webSocketdata1.jobRef,this.webSocketdata1.inDate,this.webSocketdata1.outDate,this.webSocketdata1.inTime,
        //  this.webSocketdata1.outTime,this.webSocketdata1.breakRefs[i].breakId,this.webSocketdata1.cashTips,this.webSocketdata1.clockInImage,
        //  this.webSocketdata1.clockOutImage);

            const punchData:iTimeClockSocket= {
                employeeName:this.webSocketdata1.employee,
                roleName:this.webSocketdata1.jobName,
                punchIn:this.webSocketdata1.inDate,
                punchOut:this.webSocketdata1.outDate,
                timeInTime:this.webSocketdata1.inTime,
                timeOutTime:this.webSocketdata1.outTime,
                wage:"",
                typeName:"",//this.webSocketdata1.breakRefs[i].breakName,
                tips:this.webSocketdata1.cashTips,
               // clockInImage:this.Sanitizer.bypassSecurityTrustUrl("data:Image/*;base64,"+this.webSocketdata1.clockInImage),
               clockInImage:""
            }
           
            this.PunchList.push(punchData);
          }
      }
      // else
      // {
      //   return null;
      // }
      return null;
   }

   onSaveTimeEdit(empId,roleId,punchInDate,punchOutDate,timeIn,timeout,typeId,tips,punchInImage,punchOutImage) {
      
    var dd= moment(timeIn);
console.log(moment(timeIn))
    const timePunch : iSaveTimeClock={
        
      timeClockId: 0,
      employeeId: 4,
      roleId: roleId,
      tenantId: this.loginInfo.tenantId,
      punchIn:  moment(new Date(punchInDate)).format('YYYY-MM-DD'),
      punchOut: moment(new Date(punchOutDate)).format('YYYY-MM-DD'),
      timeIn: timeIn,
      timeOut: timeout,
      typeId: typeId,
      hours: 0,
      rate: 0,
      regPay: 0,
      otHours: 0,
      otPay: 0,
      tips: tips,
      punchInImage: punchInImage,
      punchOutImage: punchOutImage,
      isDeleted: false,
      userId: this.loginInfo.userId,
      isCheckOutCompleted: false,
      wageid: "",
      breaktypeID: 0,
      jobPositionID:roleId

    }
    this.timeEditDetails.SaveTimeClockOrchetrtionDetails(timePunch).subscribe((response) => {
     
    })
 
    
  }

}
