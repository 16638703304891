import { Component, OnInit } from '@angular/core';
import { ContractService } from 'src/app/services/contract.service';
import { MessageDto } from 'src/app/interfaces/iWebSocket';
import { HelperService } from 'src/app/helper/helper.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit {
  loginInfo:any;
  UserFname :any;
  constructor(private ObjContract: ContractService,private helper: HelperService) { }
  msgDto: MessageDto = new MessageDto();
  msgInboxArray: MessageDto[] = [];

  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.msgDto.user = this.loginInfo.userFname;
    
    this.ObjContract.retrieveMappedObject().subscribe( 
      (receivedObj: MessageDto) => { this.addToInbox(receivedObj);});  // calls the service method to get the new messages sent
                                                     
  }


  send(): void {
    if(this.msgDto) {
      if(this.msgDto.user.length == 0 || this.msgDto.user.length == 0){
        window.alert("Both fields are required.");
        return;
      } else {
        this.ObjContract.broadcastMessage(this.msgDto);                   // Send the message via a service
      }
    }
  }

  addToInbox(obj: MessageDto) {
    let newObj = new MessageDto();
    newObj.user = obj.user;
    newObj.msgText = obj.msgText;
    this.msgInboxArray.push(newObj);

  }

}
