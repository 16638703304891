<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="addCompany()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="onSaveGeneral();" [disabled]="buttonDisable ? true : null">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label>
      </button>
        </div>
    </div>

</div>
<div class="row second-sec">
    <div class="col-sm-4 time-index">
        <div>
            <app-fliptable [DataCom]="employee" [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" (hierarchy)="changeTableRowColor($event)" (hierarchyActive)="checkValue($event)" (clearDetails)="addCompany()" (tableEmpName)="getTableName($event)"
                [DataCom]="employee" [companyItem]="companyRow" [CompanyColour]="tableRowColor" [EmployeeTableData]="EmployeeTableData" (hierarchyData)="gethierarchyData($event)" [DataCompId]="CompIdItem" (btnActive)="btnActiveStatus($event)">
            </app-fliptable>
        </div>
    </div>
    <div class="col-sm-8">
        <mat-accordion class="dashboard-img">
            <mat-expansion-panel *ngFor="let item of ['Dashboard', 'General']; let i = index" [expanded]="indexExpanded == i" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-expansion-panel-header (click)="expandPanel(item,index)" [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                    <mat-panel-title (click)="onChangeAccordion($event,index)" [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="item == 'Dashboard'">
                    <img src="assets/components/images/dashboard-graph.png" alt="" class="img-fluid">
                </div>
                <div *ngIf="item == 'General'">
                    <form class="loginForm GeneralForm" [formGroup]="GeneralCompanyForm" (change)="onchange(GeneralCompanyForm.value)">
                        <div class="d-flex justify-content-end">
                            <span>Active</span>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" name="customCheckbox" id="2" formControlName="isActive" checked="isActive">
                                    <label class="custom-control-label" for="2"></label>
                                </div>
                            </div>
                        </div>

                        <!-- <br> -->
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Company Name</label> <label class="text-danger">*</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="tenantName" placeholder="Company Name" appInputRestriction [ngClass]="{'is-invalid': (submittedGeneral && GenCompanyData.tenantName.errors) || requiredErrors}" (keypress)="Highlight()"
                                        (focusout)="CompanyNameValid()" maxlength="50" (input)="getMaxValue($event)" />
                                    <div class="error">{{errorValue}}</div>
                                    <div *ngIf="(submittedGeneral && GenCompanyData.tenantName.errors) || requiredErrors" class="invalid-feedback">
                                        <div *ngIf="requiredErrors || GenCompanyData.tenantName.errors.required">Company Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Phone</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                        <img src="assets/components/images/USA.png" alt="employee" class="flag_img">+1
                      </span>
                                        </div>
                                        <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="contactPhone" placeholder="(xxx) xxx-xxxx" maxLength="14" (keypress)="numberOnly2($event)" (keydown)="onDeletePhone($event)" (focusout)="phoneNumberCheck($event)" [ngClass]="{'is-invalid': GeneralCompanyForm.controls['contactPhone'].errors && submittedGeneral && !isValidFormSubmitted || mgs!=''}"
                                            appInputRestriction />
                                    </div>
                                    <div class="error">{{mgs}}</div>
                                    <div *ngIf="GeneralCompanyForm.controls['contactPhone'].errors && submittedGeneral && !isValidFormSubmitted" [ngClass]="'error'">
                                        <div *ngIf="GeneralCompanyForm.controls['contactPhone'].errors.pattern && mgs==''">
                                            Mobile Number not valid.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Contact First Name</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactFirstName" placeholder="Contact First Name" appInputRestriction maxlength="50" (input)="getMaxValueFName($event)" />
                                    <div class="error">{{errorValue1}}</div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Contact Last Name</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactLastName" placeholder="Contact Last Name" appInputRestriction maxlength="50" (input)="getMaxValueLName($event)" />
                                    <div class="error">{{errorValue2}}</div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Contact Email</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactEmail" placeholder="&#x2709; Example@myemailaddress.com" appInputRestriction [ngClass]="{ 'is-invalid': submittedGeneral && GenCompanyData.contactEmail.errors }" maxlength="100"
                                        (input)="getMaxValueEmail($event)" />
                                    <div class="error">{{errorValue3}}</div>
                                    <div *ngIf="submittedGeneral && GenCompanyData.contactEmail.errors" class="invalid-feedback">
                                        <!-- <div *ngIf="f.email.errors.required">Email is required</div> -->
                                        <div *ngIf="GenCompanyData.contactEmail.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Address</label>
                                    <input type="text" class="form-control" formControlName="address" placeholder="Address" appInputRestriction />
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">City</label>
                                    <input type="text" class="form-control" formControlName="city" placeholder="City" appInputRestriction maxlength="50" (input)="getMaxValueCity($event)" />
                                    <div class="error">{{errorValue5}}</div>
                                </div>
                            </div>
                            <div class="col-sm-2 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">State</label>
                                    <!-- <input type="text" class="form-control" formControlName="state" placeholder="MT" /> -->
                                    <div class="StateWrap">
                                        <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" #select1 formControlName="state" (selectionChange)="onchange(GeneralCompanyForm.value)" placeholder="Select State">
                                            <input class="form-control input-lg inputbox_login" placeholder="Search here" (keyup)="onKey($event.target.value)" maxlength="50" (input)="getMaxValueTerritory($event)" />
                                            <div class="error">{{errorValue6}}</div>
                                            <mat-option *ngFor="let item of filteredList2" [value]="item.id">
                                                {{item.id}}
                                            </mat-option>
                                            <!-- <mat-option *ngIf="filterNodata" [value]="">No data Found</mat-option> -->
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Zip Code</label>
                                    <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code" [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" (focusout)="zipCodeCheck($event)" (keydown)="onDeleteZipCode($event)" [ngClass]="{ 'is-invalid': zipCodeMsg!=''}"
                                    />
                                    <div class="error">{{zipCodeMsg}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-12">
                                <div class="form-group ">
                                    <label class="customlable mb-0">Type of Business</label>
                                    <!-- <mat-form-field appearance="fill">
                      <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="bussinessId">
                        <mat-option [value]="0" disabled>Select Business Type</mat-option>
                        <mat-option *ngFor="let item of Position" [value]="item.id">{{item.name}} </mat-option>
                      </mat-select>
                    </mat-form-field> -->
                                    <div class="input-group">
                                        <input class="form-control" type="text" formControlName="bussinessId" [(ngModel)]="searchField" list="dataType" appInputRestriction />
                                        <datalist id="dataType">
                      <option [value]=""></option>
                      <option *ngFor="let Businessval of BusinessList" [value]="Businessval.name"></option>
                    </datalist>
                                        <div class="input-group-append">
                                            <i class="fa fa-close close_Filter" (click)="clearSearchField()" *ngIf="searchField"></i>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Is Corporate?</label>
                                    <div class="checkbox-table accordion-checkbox">
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" name="isCorporate" id="isCorporate" formControlName="isCorporate">
                                            <label class="custom-control-label" for="isCorporate"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div id="accordion">
            <!-- <div class="card">
        <div class="card-header outshadow" id="heading-1">
          <h5 class="mb-0">
            <a class="collapsed" role="button" data-toggle="collapse" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
              Dashboard
            </a>
          </h5>
        </div>
        <div id="collapse-1" class="collapse" data-parent="#accordion" aria-labelledby="heading-1">
          <div class="card-body" style="padding: 0!important;">
            <img src="assets/components/images/dashboard-graph.png" alt="" class="img-fluid">
          </div>
        </div>
      </div> -->
            <!-- <div class="card">
        <div class="card-header outshadow" id="heading-2" [ngClass]="{'box_error': (isInvalid==true)}">
          <h5 class="mb-0">
            <a [ngClass]="{'title_error':(isInvalid==true)}" class="collapsed" role="button" data-toggle="collapse" href="#collapse-2" aria-expanded="true"
              aria-controls="collapse-2">
              General
            </a>
          </h5>
        </div>
        <div id="collapse-2" class="collapse show" data-parent="#accordion" aria-labelledby="heading-2">

          <div class="card-body">

            <div class="Gen_wrap">
              <form class="loginForm GeneralForm" [formGroup]="GeneralCompanyForm" (change)="onchange(GeneralCompanyForm.value)">
                <div class="d-flex justify-content-end">
                  <span>Active</span>
                  <div class="checkbox-table accordion-checkbox">
                    <div class="custom-control custom-checkbox custom-control-inline">
                      <input type="checkbox" class="custom-control-input" name="customCheckbox" id="2"
                        formControlName="isActive" checked="isActive">
                      <label class="custom-control-label" for="2"></label>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Company Name</label> <label class="text-danger">*</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="tenantName"
                        placeholder="Company Name" appInputRestriction
                        [ngClass]="{'is-invalid': (submittedGeneral && GenCompanyData.tenantName.errors) || requiredErrors}" (keypress)="Highlight()" (focusout)="CompanyNameValid()"  maxlength="50" (input)="getMaxValue($event)"/>
                        <div class="error">{{errorValue}}</div>
                      <div *ngIf="(submittedGeneral && GenCompanyData.tenantName.errors) || requiredErrors" class="invalid-feedback">
                        <div *ngIf="requiredErrors || GenCompanyData.tenantName.errors.required">Company Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Phone</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            <img src="assets/components/images/USA.png" alt="employee" class="flag_img">+1
                          </span>
                        </div>
                        <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="contactPhone"
                       placeholder="(xxx) xxx-xxxx" maxLength="14" (keypress)="numberOnly2($event)" (keydown)="onDeletePhone($event)" (focusout)= "phoneNumberCheck($event)" [ngClass]="{'is-invalid': GeneralCompanyForm.controls['contactPhone'].errors && submittedGeneral && !isValidFormSubmitted || mgs!=''}" appInputRestriction/>
                      </div>
                      <div class="error">{{mgs}}</div>
                      <div *ngIf="GeneralCompanyForm.controls['contactPhone'].errors && submittedGeneral && !isValidFormSubmitted" [ngClass] = "'error'">
                        <div *ngIf="GeneralCompanyForm.controls['contactPhone'].errors.pattern && mgs==''">
                          Mobile Number not valid.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Contact First Name</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="contactFirstName"
                        placeholder="Contact First Name" appInputRestriction maxlength="50" (input)="getMaxValueFName($event)"/>
                        <div class="error">{{errorValue1}}</div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Contact Last Name</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="contactLastName"
                        placeholder="Contact Last Name" appInputRestriction maxlength="50" (input)="getMaxValueLName($event)"/>
                        <div class="error">{{errorValue2}}</div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="customlable mb-0">Contact Email</label>
                      <input type="text" class="form-control input-lg inputbox_login" formControlName="contactEmail"
                        placeholder="&#x2709; Example@myemailaddress.com" appInputRestriction
                        [ngClass]="{ 'is-invalid': submittedGeneral && GenCompanyData.contactEmail.errors }" maxlength="100" (input)="getMaxValueEmail($event)"/>
                        <div class="error">{{errorValue3}}</div>
                      <div *ngIf="submittedGeneral && GenCompanyData.contactEmail.errors" class="invalid-feedback">
                        <div *ngIf="GenCompanyData.contactEmail.errors.email">Email must be a valid email address</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">Address</label>
                      <input type="text" class="form-control" formControlName="address" placeholder="Address"
                        appInputRestriction/>
                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">City</label>
                      <input type="text" class="form-control" formControlName="city" placeholder="City"
                        appInputRestriction maxlength="50" (input)="getMaxValueCity($event)"/>
                        <div class="error">{{errorValue5}}</div>
                    </div>
                  </div>
                  <div class="col-sm-2 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">State</label>
                      <div class="StateWrap">
                        <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" #select1 formControlName="state"
                          placeholder="Select State">
                          <input class="form-control input-lg inputbox_login" placeholder="Search here" (keyup)="onKey($event.target.value)" maxlength="50" (input)="getMaxValueTerritory($event)"/>
                          <div class="error">{{errorValue6}}</div>
                          <mat-option *ngFor="let item of filteredList2" [value]="item.id">
                            {{item.id}}
                          </mat-option>
                        </mat-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">Zip Code</label>
                      <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code"
                      [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" (focusout)= "zipCodeCheck($event)" (keydown)="onDeleteZipCode($event)" [ngClass]="{ 'is-invalid': zipCodeMsg!=''}"/>
                      <div class="error">{{zipCodeMsg}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 col-12">
                    <div class="form-group ">
                      <label class="customlable mb-0">Type of Business</label>
                      <div class="input-group">
                        <input class="form-control" type="text" formControlName="bussinessId" [(ngModel)]="searchField" list="dataType"
                        appInputRestriction />
                      <datalist id="dataType">
                        <option [value]=""></option>
                        <option *ngFor="let Businessval of BusinessList" [value]="Businessval.name"></option>
                      </datalist>
                      <div class="input-group-append">
                        <i class="fa fa-close close_Filter" (click)="clearSearchField()" *ngIf="searchField"></i>
                      </div>

                      </div>

                    </div>
                  </div>
                  <div class="col-sm-4 col-12">
                    <div class="form-group">
                      <label class="customlable mb-0">Is Corporate?</label>
                      <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control-inline">
                          <input type="checkbox" class="custom-control-input" name="isCorporate" id="isCorporate"
                            formControlName="isCorporate">
                          <label class="custom-control-label" for="isCorporate"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> -->
        </div>
    </div>
</div>
