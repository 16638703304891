<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="addEmployee(); scrollToTop()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true || minBool==true}" class="btn  btn-sm float-right submit_button global_submit" type="submit" [disabled]="buttonStatus ?  true : null" (click)="OnSave();">
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label></button>
        </div>
    </div>
</div>
<div class="row second-sec">
    <div class="col-sm-4">
        <div *ngIf="tableEnable">
            <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" [dealerItem]="tableRowColor" (tableItemId)="getTableIdx($event)" (tableEmpName)="getTableName($event)" [employeeItem]="tableRowColor" (breakActive)="checkvaluePage($event)"
                (clearDetails)="addEmployee($event)" [EmployeeTableData]="SalesTableData" (dataEmitArea)="getDataArea($event)" [DataCom]="employee" (btnActive)="btnActiveStatus($event)">
            </app-fliptable>
        </div>
    </div>
    <div class="col-sm-8 menu-modifier-search" #scroll>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of ['General','Layout Builder','Items Assigned to Modifier Group','Pricing']; let i = index" [expanded]="indexExpanded == i" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-expansion-panel-header (click)="expandPanel(item,index)" [ngClass]="{'box_error': (isInvalid==true && item=='General') || (minBool==true && item=='Layout Builder')}">
                    <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')|| (minBool==true && item=='Layout Builder')}" (click)="onChangeAccordion($event)">
                        <span class="mt-1">{{item}}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General' ">
                    <app-menu-modifier-general [MenuRowItem]="getDataValue" (generalItem)="getGeneralDetail($event)" [modifierData]="modifierData" [buttonState]="buttonDisable" (isInvalid)="isInvalidAccordion($event)" (ModifierData_child)="getmnumodsessData($event)" (matopen)="matstate($event)">
                    </app-menu-modifier-general>
                </div>
                <div *ngIf="item == 'Layout Builder'">
                    <app-drag-drop-builder [MenuRowItem]="getDataValue" [dataSource]="dataSourceItems" [SelectedPageInd]="PageName" [ItemList]="ItemList" (generalItem)="getGeneralDetail($event)" (ItemObj)="Item_obj" (layoutSave)="onSaveItem($event)" (saveemit)="getdragSave($event)"
                        saveId="layoutId" [buttonState]="buttonDisable">
                    </app-drag-drop-builder>
                </div>
                <div *ngIf="item == 'Items Assigned to Modifier Group'">
                    <app-items-assigned-modifier [MenuRowItem]="getDataValue" [buttonState]="buttonDisable" (ModifierItmData_child)="getmnumodsessData($event)">
                    </app-items-assigned-modifier>
                </div>
                <div *ngIf="item == 'Pricing'">
                    <app-dynamic-editable-table [MenuRowItem]="getDataValue" [GetSaveId]="tableRowId" saveId="Pricing" (layoutSave)="onSavePrice($event)" [buttonState]="buttonDisable" (DynItmData_child)="getmnumodsessData($event)">
                    </app-dynamic-editable-table>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="form-group">
            <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="OnSave();">
                <!-- [disabled]="buttonStatus ?  true : null" -->
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
            </button>
        </div>
    </div>
</div>
