<div class="table-container">
    <table mat-table #table [dataSource]="dataSource" matSort (matSortChange)="onSortData($event)"
    class="mat-elevation-z8 table employee-table employee dataTable full-width-table table_center" style="width: 100%;">

    <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header }}
        </th>

        <td mat-cell *matCellDef="let row">
            <div *ngIf="column.field != 'grossSales' && column.field != 'netSales' && column.field != 'categorySale'
            && column.field != 'amount' && column.field != 'category' && column.field != 'byGross' && column.field != 'discountname'" >
                {{ row[column.field]}}
              </div>
              <div *ngIf="column.field == 'category'" (click)="itemClick(row[column.field])">
                {{ row[column.field]}}
              </div>
            <div *ngIf="column.field == 'grossSales' || column.field == 'netSales' || column.field == 'amount' 
            ">
                {{ row[column.field] | currency}}
            </div>
            <div *ngIf="column.field == 'categorySale' || column.field == 'byGross'">
                {{ row[column.field] / 100 | percent : '2.2' }}
            </div>
           
        </td>
        <td mat-footer-cell *matFooterCellDef>
            <div *ngIf="column.field == 'category' || column.field == 'employeeName'">Report Totals
            </div>

            <div *ngIf="column.field == 'quantity'" class="footerData">
                <h6>Total QTY</h6>
                {{getTotalQuntity()}}
            </div>
            <div *ngIf="column.field == 'grossSales'" class="footerData">
                <h6>Gross sales</h6>
                {{getTotalGross() | currency}}
            </div>
            <div *ngIf="column.field == 'netSales'" class="footerData">
                <h6>Net Sales</h6>
                {{getTotalNet() | currency}}
            </div>
            <div *ngIf="column.field == 'amount'" class="footerData">
                <h6>Total Amount</h6>
                {{getTotalAmount() | currency}}
            </div>
            <div *ngIf="column.field == 'byGross'" class="footerData">
                <h6>%</h6>
                {{getTotalPercentage() / 100 | percent : '2.2' }}
            </div>
            <div *ngIf="column.field == 'check'" class="footerData">
                <h6>QTY</h6>
                {{getTotalCost6()}}
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight_row': row.category == 'Total'}"
    (click)="changeTableRowColor(row.id,row)"></tr>

    <!-- Group header -->
    <ng-container matColumnDef="groupHeader">
        <td mat-cell colspan="999" *matCellDef="let group" class="highlight_td">
            <p>{{group[groupByColumns[group.level-1]]}}</p>
        </td>

    </ng-container>

    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup;" (click)="groupHeaderClick(row)">
    </tr>
    <div *ngIf="!noReportshow">
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="footer"
        [attr.colspan]="displayedColumns.length" ></tr>
    </div>
</table>
</div>