import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MenuService } from 'src/app/services/menu.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { GetSaleAreaGeneral, GetMenuGroup } from 'src/app/interfaces/imenu';
import { MenuItemGeneralComponent } from '../menu-item-general/menu-item-general.component';
import { MenuGroupService } from 'src/app/services/menugroup.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-sale-area-logistic',
  templateUrl: './sale-area-logistic.component.html',
  styleUrls: ['./sale-area-logistic.component.css']
})
export class SaleAreaLogisticComponent implements OnInit {
  buttonDisable: boolean = false;
  SelectionType: boolean = false;
  submittedGeneral = true;
  SelectedTypeDefault: [{ value: "$$" }];
  SaleAreaLogistic: FormGroup;
  TipOnTablet: Boolean;
  SuggestTip: any;
  loginInfo: any;
  defaultValue: any;
  generalDataemit: any;
  placeholderIcon: any;
  //@Input() public buttonDis;
  areaValue = [];
  AreaBuildId: any;
  salesarealogdata: any;
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }
  constructor(
    private objItem: MenuService,
    private formBuilder: FormBuilder,
    private ItemLog: MenuService,
    private toaster: ToasterService,
    private helper: HelperService,
    private objGroup: MenuGroupService,
  ) { }

  @Input() public menuPriceSizeList
  @ViewChild(MenuItemGeneralComponent) MenuItemGeneralComponent: MenuItemGeneralComponent;
  @Output() public salearealogData_subchild = new EventEmitter();

  ngOnInit(): void {
    this.SelectedTypeDefault = [{ value: '$$' }]
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.SaleAreaLogistic = this.formBuilder.group({
      lid: [0],
      PrintInfoOnKitchenTicket: [null],
      TiponTablet: [false],
      tipType: [null],
      userid: [0],
      IsCustomerNameRequire: [false],
      IsPhoneNoRequire: [false],
      IsIdRequire: [false],
      IsReceiptInfoRequire: [false],
      IsPrintInfoOnKitTic: [false],
      IsSuggestTips: [false],
      IsPercent: [false],
      Data: [null],
      Data1: [null],
      Data2: [null],
      IsOnScreenTip: [false],
      PrintSalesArea: [false],
      MenuGroupRef: [0],
      General: [0]
    })
    this.getDefaultMenuGrp();
    this.ItemLog.SalesGeneralData.subscribe(data =>
      this.AreaBuildId = data
    );
    this.ItemLog.SalesValue.subscribe(data => this.areaValue = data);
    this.OnClickToggle(1)
  }

  @Input()
  public set MenuRowItem(tableRowData) {
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      this.AreaBuildId = tableRowData.id;
      this.getSalesArea();
      // this.SaleAreaLogistic.patchValue(tableRowData);
    }
  }
  @Input()
  public set SalesArea(generalDataemit) {
    this.generalDataemit = generalDataemit
  }
  @Input()
  public set buttonDis(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = btnst;
    }
  }
  numberOnly(event): boolean {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    const charCode = (event.which) ? event.which : event.keyCode;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }

    if (this.placeholderIcon == '$') {
      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }

    }
    if (this.placeholderIcon == '%') {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      if (event.target.value.length > 1) {
        event.target.value = event.target.value.slice(0, 1);
      }
    }
    return true;
  }

  OnchangePrice() {
    this.TipOnTablet = !this.TipOnTablet;
  }

  OnClickToggle(e) {
    if (e == 1) {
      this.placeholderIcon = "$"
      this.SelectionType = false;
      if (this.salesarealogdata != undefined && this.salesarealogdata != null && this.salesarealogdata != "") {
        if (this.salesarealogdata.IsPercent == true) {
          this.SaleAreaLogistic.controls.Data.reset()
          this.SaleAreaLogistic.controls.Data1.reset()
          this.SaleAreaLogistic.controls.Data2.reset()
        }
        else {
          this.SaleAreaLogistic.patchValue({
            Data: this.salesarealogdata.Data,
            Data1: this.salesarealogdata.Data1,
            Data2: this.salesarealogdata.Data2,
          });
        }
      }
      else {
        this.SaleAreaLogistic.controls.Data.reset()
        this.SaleAreaLogistic.controls.Data1.reset()
        this.SaleAreaLogistic.controls.Data2.reset()
      }
    }

    if (e == 2) {
      this.placeholderIcon = "%"
      this.SelectionType = true;
      if (this.salesarealogdata != undefined && this.salesarealogdata != null && this.salesarealogdata != "") {
        if (this.salesarealogdata.IsPercent == false) {
          this.SaleAreaLogistic.controls.Data.reset()
          this.SaleAreaLogistic.controls.Data1.reset()
          this.SaleAreaLogistic.controls.Data2.reset()
        }
        else {
          this.SaleAreaLogistic.patchValue({
            Data: this.salesarealogdata.Data,
            Data1: this.salesarealogdata.Data1,
            Data2: this.salesarealogdata.Data2,
          });
        }
      }
      else {
        this.SaleAreaLogistic.controls.Data.reset()
        this.SaleAreaLogistic.controls.Data1.reset()
        this.SaleAreaLogistic.controls.Data2.reset()
      }
    }
  }


  // if (this.salesarealogdata.Data == undefined || this.salesarealogdata.Data == null || this.salesarealogdata.Data == "") {
  //   this.SaleAreaLogistic.controls.Data.reset()
  // }
  // if (this.salesarealogdata.Data1 == undefined || this.salesarealogdata.Data1 == null || this.salesarealogdata.Data1 == "") {
  //   this.SaleAreaLogistic.controls.Data1.reset()
  // }
  // if (this.salesarealogdata.Data2 == undefined || this.salesarealogdata.Data2 == null || this.salesarealogdata.Data2 == "") {
  //   this.SaleAreaLogistic.controls.Data2.reset()
  // }

  // percentRoundUp(e){
  //   const value = Math.round((parseInt(e) + Number.EPSILON) * 100) / 100;
  //   this.SaleAreaLogistic.controls.Data.setValue(value.toFixed(2))
  // }

  async onSaveSalesAreaLogistic(salesareaid): Promise<any> {
    return new Promise((resolve, reject) => {
      this.submittedGeneral = true;
      if (this.SaleAreaLogistic.invalid) {
        return;
      }

      let menuItem;
      this.AreaBuildId = salesareaid;
      menuItem = this.SaleAreaLogistic.value;
      if (menuItem.lid == undefined || menuItem.lid == null || menuItem.lid == "") {
        menuItem.lid = 0;
      }

      menuItem.id = this.AreaBuildId > 0 ? this.AreaBuildId : this.generalDataemit;
      menuItem.tenantid = this.loginInfo.tenantId;
      menuItem.userid = this.loginInfo.userId;
      menuItem.isActive = true;
      menuItem.IsPercent = this.SelectionType;
      this.SaleAreaLogistic.value.General = this.AreaBuildId > 0 ? this.AreaBuildId : this.generalDataemit;
      //console.log(this.SaleAreaLogistic.value, 'this.SaleAreaLogistic.value')
      this.ItemLog.saveSalesAreaLogistics(this.SaleAreaLogistic.value).subscribe(
        async (response) => {
          if (response.id == 0) {
            this.toaster.errorPopUp(response.message);
            return;
          } else {
            this.submittedGeneral = false;
          }
        })
    });
  }

  getSalesArea() {
    const Active: GetSaleAreaGeneral = {
      id: this.AreaBuildId,
      tenantId: this.loginInfo.tenantId
    }
    this.objItem.GetSalesAreaGeneral(Active).subscribe(
      (response) => {
        this.salesarealogdata = response[0];
        this.SaleAreaLogistic.patchValue(response[0]);
        let PerVal: any;
        PerVal = response[0].IsPercent == true ? '%%' : '$$';
        this.SelectedTypeDefault = [{ value: PerVal }];
      })
  }

  getDefaultMenuGrp() {
    const menuGroupList: GetMenuGroup = {
      tenantId: this.loginInfo.tenantId
    }
    this.objGroup.MenuGroupget(menuGroupList)
      .subscribe((response) => {
        this.defaultValue = response.filter(x => x.isActive == true)
      })
  }

  ngAfterViewInit() {
    this.SaleAreaLogistic.patchValue(this.helper.getValue('SalesAreaLogPageData'));
    this.salearealogData_subchild.emit(this.SaleAreaLogistic.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData2', data);
    this.helper.setValue('OnDirty2', true);
    this.salearealogData_subchild.emit({ data: data, event: "SalesAreaPage", val: true });
  }

  get f() { return this.SaleAreaLogistic.controls; }
}
