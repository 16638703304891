import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { icustompaymentsave } from 'src/app/interfaces/icustompayment';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CustomPaymentComponent } from '../custom-payment/custom-payment.component';
import { DialogService } from 'src/app/services/dialog.service';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-custom-uber-eats',
  templateUrl: './custom-uber-eats.component.html',
  styleUrls: ['./custom-uber-eats.component.css'],
})
export class CustomUberEatsComponent implements OnInit {
  ipAddressMask = createMask({ alias: 'ip' });
  PMS_ServerIP = new FormControl('');
  CustomPaymentForm: FormGroup;
  submittedGeneral = false;
  isSubmitted = false;
  loginInfo: any;
  buttonDisable: boolean = false;
  @Output() public custom_uber = new EventEmitter();
  @Output() public isInvalid = new EventEmitter();
  @Output() public isBool = new EventEmitter();
  @Output() public CustomPaymentsData_child = new EventEmitter();
  requiredErrors: boolean = false;
  errorValue: any;
  message: any;
  mgs: any;
  ipMgs: any;
  mesage: string;

  //@Input() public buttonState;
  //@ViewChild(CustomPaymentComponent) CustPayComp: CustomPaymentComponent;

  constructor(
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private helper: HelperService,
    private toaster: ToasterService,
    private dialogser: DialogService
  ) { }

  ngOnInit(): void {
    this.dialogser.ClearSession();
    //this.helper.setValue('GetSessionData1', null);
    this.errorValue = '';
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.CustomPaymentForm = this.formBuilder.group({
      id: [0],
      name: ['', Validators.required],
      PLU_CardNumber_Code: [false],
      isCurrency: [false],
      RollUpPayment: [false],
      OverrideTipApportions: [false],
      RequireSignature: [false],
      AllowTipping: [false],
      OpenCashDrawer: [false],
      RequireMarketingCode: [false],
      HotelRoomPayment: [false],
      PMS_ServerIP: ['', [Validators.pattern('\^(([1-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.(2[0-5][0-5]|1[0-9][0-9]|[1-9][0-9]|[0-9]))\$')]],
      isActive: [false],
    });
  }
  get GeneralData() { return this.CustomPaymentForm.controls; }

  IPValid(value) {
    var ip = value.replace(/[^0-9]+/g, "");
    var IPdot = value.replace(/[^.]+/g, "");
    if (IPdot == '..' || IPdot == '.') {
      this.mesage = 'Please Enter Valid Ip Address'
      this.CustomPaymentForm.controls['PMS_ServerIP'].setErrors({ 'incorrect': true });
    }
    else {
      this.mesage = ''
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set MenuRowItem(tableRowData) {
    this.errorValue = '';
    if (
      tableRowData != undefined &&
      tableRowData != null &&
      tableRowData != ''
    ) {
      this.CustomPaymentForm.patchValue(tableRowData[0]);
    }
  }

  onSaveGeneral() {
    this.errorValue = '';
    this.submittedGeneral = true;

    if (this.GeneralData.HotelRoomPayment.value == true) {
      this.CustomPaymentForm.controls['PMS_ServerIP'].setValidators([Validators.required, Validators.pattern('\^(([1-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.(2[0-5][0-5]|1[0-9][0-9]|[1-9][0-9]|[0-9]))\$')]);
      this.CustomPaymentForm.controls['PMS_ServerIP'].updateValueAndValidity({ onlySelf: true });
    } else {
      this.CustomPaymentForm.get('PMS_ServerIP').clearValidators();
      this.CustomPaymentForm.get('PMS_ServerIP').updateValueAndValidity({
        onlySelf: true,
      });
      this.CustomPaymentForm.get('PMS_ServerIP').reset();
    }

    if (this.CustomPaymentForm.controls['PMS_ServerIP'].invalid) {
      this.isInvalid.emit(true);
      return;
    }

    if (this.CustomPaymentForm.invalid) {
      this.isInvalid.emit(true);
      return;
    }




    // this.onCheckPayment()

    // if (
    //   this.GeneralData.PMS_ServerIP.value != undefined &&
    //   this.GeneralData.PMS_ServerIP.value != null &&
    //   this.GeneralData.PMS_ServerIP.value != ''
    // )
    //  {
    //   if (this.GeneralData.PMS_ServerIP.value.length <= 14) {
    //     this.mgs = 'Please enter a valid IP address';
    //     this.isBool.emit(true);
    //     this.isInvalid.emit(true);
    //     return;
    //   } else {
    //     const fourthValue = this.GeneralData.PMS_ServerIP.value.substring(12);
    //     if (fourthValue > 255) {
    //       this.ipMgs = 'The Value Should be Less Than 255';
    //       this.isBool.emit(true);
    //       this.isInvalid.emit(true);
    //       return;
    //     } else {
    //       this.ipMgs = '';
    //     }
    //     this.mgs = '';
    //   }
    // } else {
    //   this.mgs = '';
    // }

    const getdata: icustompaymentsave = {
      id: this.CustomPaymentForm.value.id,
      name: this.CustomPaymentForm.value.name,
      PLU_CardNumber_Code: this.CustomPaymentForm.value.PLU_CardNumber_Code,
      isCurrency: this.CustomPaymentForm.value.isCurrency,
      RollUpPayment: this.CustomPaymentForm.value.RollUpPayment,
      OverrideTipApportions: this.CustomPaymentForm.value.OverrideTipApportions,
      RequireSignature: this.CustomPaymentForm.value.RequireSignature,
      AllowTipping: this.CustomPaymentForm.value.AllowTipping,
      OpenCashDrawer: this.CustomPaymentForm.value.OpenCashDrawer,
      RequireMarketingCode: this.CustomPaymentForm.value.RequireMarketingCode,
      HotelRoomPayment: this.CustomPaymentForm.value.HotelRoomPayment,
      PMS_ServerIP: this.CustomPaymentForm.value.PMS_ServerIP,
      isActive: true,
      CreatedBy: this.loginInfo.userId,
      ModifiedBy: this.loginInfo.userId,
      tenantId: this.loginInfo.tenantId,
    };
    this.paymentService.SaveCustomPayment(getdata).subscribe(
      async (response) => {
        this.CustomPaymentForm.patchValue({
          id: response.id,
        });
        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
          this.helper.setValue('GetSessionData1', getdata);
          this.CustomPaymentsData_child.emit({
            data: getdata,
            event: 'CustomPayments',
            val: false,
          });
          this.custom_uber.emit({ res: response, name: getdata.name });
        }
        this.isSubmitted = false;
        await this.dialogser.ClearSession();
      },
      (error) => {
        //this.toaster.error("Some error occurred.Please try again", "Error");
        this.submittedGeneral = false;
        // this.myModal.hide();
      }
    );
  }

  // onKeypressIpEvent(event): boolean {
  //   const charCode = event.which ? event.which : event.keyCode;
  //   if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   var stringToConvert = event.target.value;
  //   var numberValue = Number(stringToConvert);
  //   if (event.target.value.length <= 15) {
  //     if (
  //       event.target.value.length < 4 ||
  //       event.target.value.length > 4 ||
  //       event.target.value.length < 8
  //     ) {
  //       if (event.key === '.') event.preventDefault();
  //     }
  //     if (event.target.value.length == 3) {
  //       if (numberValue <= 255) {
  //         event.target.value = `${event.target.value}.`;
  //         this.ipMgs = '';
  //       } else {
  //         this.ipMgs = 'The Value Should be Less Than 255';
  //         this.isBool.emit(true);
  //         return false;
  //       }
  //     }
  //     if (event.target.value.length == 7) {
  //       const nextValue = event.target.value.substring(4);
  //       var numberValue = Number(nextValue);
  //       if (numberValue <= 255) {
  //         event.target.value = `${event.target.value}.`;
  //         this.ipMgs = '';
  //       } else {
  //         this.ipMgs = 'The Value Should be Less Than 255';
  //         this.isBool.emit(true);
  //         return false;
  //       }
  //     }
  //     if (event.target.value.length == 11) {
  //       const thirdValue = event.target.value.substring(8);
  //       var numberValue = Number(thirdValue);
  //       if (numberValue <= 255) {
  //         event.target.value = `${event.target.value}.`;
  //         this.ipMgs = '';
  //       } else {
  //         this.ipMgs = 'The Value Should be Less Than 255';
  //         this.isBool.emit(true);
  //         return false;
  //       }
  //     }
  //     if (event.target.value.length == 14) {
  //       const fourthValue = event.target.value.substring(12);
  //       var numberValue = Number(fourthValue);
  //       if (numberValue <= 25) {
  //         event.target.value = `${event.target.value}`;
  //         this.ipMgs = '';
  //       } else {
  //         this.ipMgs = 'The Value Should be Less Than 255';
  //         this.isBool.emit(true);
  //         return false;
  //       }
  //     }
  //     if (event.target.value.length >= 14) {
  //       this.mgs = '';
  //     }
  //   } else {
  //     event.target.value = event.target.value.slice(0, -1);
  //   }
  //   return true;
  // }

  onKeypressIpEvent(event) {
    var ip = event.target.value.replace(/[^0-9,]+/g, "");
    if (ip == 0 && ip.length <= 1) {
      this.mesage = '';
      this.CustomPaymentForm.get('PMS_ServerIP').reset();
    }
    else {
      this.mesage = ''
    }

  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue = '';
    }
  }
  CustomNameValid() {
    if (
      this.CustomPaymentForm.value.name == null ||
      this.CustomPaymentForm.value.name == ''
    ) {
      this.isInvalid.emit(true);
      this.requiredErrors = true;
      if (this.CustomPaymentForm.invalid) {
        this.isInvalid.emit(true);
        return;
      }
    } else {
      this.isInvalid.emit(false);
      this.requiredErrors = false;
    }
  }

  CustomNameValidIp() {
    if (this.CustomPaymentForm.invalid) {
      this.isInvalid.emit(true);
      return;
    }
  }


  Highlight() {
    if (this.CustomPaymentForm.value.name != null) {
      if (this.CustomPaymentForm.value.name.length < 0) {
        this.requiredErrors = true;
        this.isInvalid.emit(true);
      } else {
        this.requiredErrors = false;
        this.isInvalid.emit(false);
      }
    } else if (
      this.CustomPaymentForm.value.name == null ||
      (this.CustomPaymentForm.value.name == '' &&
        this.CustomPaymentForm.value.name.length() == 0)
    ) {
      this.requiredErrors = false;
      this.isInvalid.emit(false);
    } else if (
      this.CustomPaymentForm.value.name == null ||
      this.CustomPaymentForm.value.name == ''
    ) {
      this.requiredErrors = true;
      this.isInvalid.emit(true);
    }
  }

  ngAfterViewInit() {
    this.CustomPaymentForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.CustomPaymentsData_child.emit(this.CustomPaymentForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.CustomPaymentsData_child.emit({
      data: data,
      event: 'CustomPayments',
      val: true,
    });
  }

  // isFormPageDirty() {
  //   let d = this.CustomPaymentForm.dirty;
  //   this.helper.setValue('GetSessionData1', this.CustomPaymentForm.value);
  //   if (d == true) {
  //     this.helper.setValue('OnDirty', true);
  //   }
  //   return d;
  // }
}
