import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { iSelectCreditCard } from 'src/app/interfaces/icustompayment';

@Component({
  selector: 'app-credit-card-tsys',
  templateUrl: './credit-card-tsys.component.html',
  styleUrls: ['./credit-card-tsys.component.css']
})

export class CreditCardTsysComponent implements OnInit {
  CreditCardTsysForm: FormGroup;
  submittedGeneral = false;
  id: any = 0;
  GatewaySelection: any;
  loginInfo: any;
  isSubmitted: boolean = false;
  isValidated = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  errorValue: any
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  @Output() public generalCredit = new EventEmitter();
  @Input() public emvClicked;
  @Input() public onSavebit
  @Output() public nextfirstStepButton = new EventEmitter();
  @Output() public ccData_child = new EventEmitter();
  requiredError: boolean = false;

  @Output() public isInvalid = new EventEmitter();
  isSave: boolean = false
  constructor(private formBuilder: FormBuilder, private objPayment: PaymentService,
    private helper: HelperService, private toaster: ToasterService) { }

  ngOnDestroy(): void {
    this.helper.setValue(null, "pageName");
  }

  ngOnInit(): void {
    this.helper.setValue('ccDataval', null);
    //  this.helper.setValue('pageName','1stPage');
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.CreditCardTsysForm = this.formBuilder.group({
      totalPassword: [null, Validators.required],
      hostCapture_POSID: [null, Validators.required],
      autoBatchTime: [null, Validators.required],
      of_Fixed: [false],
      mobile: [false],
      total_Lanes: [null],
      isStoreandForward_EMVonly: [false],
      storeValue: [null],
      wired_Devices: [null],
      wireless_Devices: [null],
    })
  }
  get GeneralData() { return this.CreditCardTsysForm.controls; }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getAuthCodeValue(e) {
    if (e.target.value.length < 15) {
      this.errorValue = "Authentication code should be 15 digits"
      return;
    } else {
      this.errorValue = ""
    }
  }

  @Input() public buttonState;
  // @Input()
  //  public set MenuRowItem(tableRowData) {
  //    if (tableRowData[0] != undefined && tableRowData[0] != null && tableRowData[0] != "") {
  //      this.CreditCardTsysForm.patchValue(tableRowData[0]);
  //    this.id = tableRowData[0].id;
  //    }
  //  }

  onSaveGeneral(id) {
    this.submittedGeneral = true;
    if (this.CreditCardTsysForm.invalid) {
      return;
    }
    if (this.GeneralData.totalPassword.value.length < 15) {
      return
    }
    let paymentserv;
    paymentserv = this.CreditCardTsysForm.value;
    paymentserv.userID = this.loginInfo.userId;
    paymentserv.createdBy = this.loginInfo.userId;
    paymentserv.tenantId = this.loginInfo.tenantId;
    paymentserv.isActive = true;
    paymentserv.id = this.id;
    paymentserv.creditCardGatewayId = sessionStorage.getItem("CreditCardID") == null ? id : sessionStorage.getItem("CreditCardID");
    paymentserv.wired_Devices = this.GeneralData.wired_Devices.value == "" ? 0 : this.GeneralData.wired_Devices.value
    paymentserv.wireless_Devices = this.GeneralData.wireless_Devices.value == "" ? 0 : this.GeneralData.wireless_Devices.value
    paymentserv.storeValue = this.GeneralData.storeValue.value == "" ? 0 : this.GeneralData.storeValue.value

    this.helper.setValue('tsysVal', paymentserv);
    //  console.log(paymentserv,'paymentserv')
    this.objPayment.SaveCreditCardPayment(paymentserv).subscribe(res => {
      this.isSubmitted = false;
      this.isSave = true;
      this.submittedGeneral = false;
      this.ccData_child.emit({ data: paymentserv, event: "CreditCard", val: false });
      this.helper.setValue('ccDataval', paymentserv);

      //console.log(res,'tsys')
      // this.nextfirstStepButton.emit(this.isSave);
      // this.generalCredit.emit(res);
      //this.toaster.successPopUp(res.message);
    })
  }

  checkInput(e, input) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];
    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue)) {
      e.preventDefault();
      return;
    }

    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if (+newValue > 100 || newValue.length > 3) {
      e.preventDefault();
    }
  }
  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }

  onSum() {
    let x = this.GeneralData.wired_Devices.value;
    let y = this.GeneralData.wireless_Devices.value;
    let sum = Number(x) + Number(y)
    this.GeneralData.total_Lanes.setValue(sum)
    // this.GeneralData.total_Lanes = sum;
  }

  CreditCardValid() {
    if (this.CreditCardTsysForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }
    else if (this.GeneralData.totalPassword.value.length < 15) {
      this.requiredError = true;
      return
    }
    else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.CreditCardTsysForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.CreditCardTsysForm.patchValue(this.helper.getValue('ccDataval'));
    this.ccData_child.emit(this.CreditCardTsysForm.value);
  }

  onchange(data) {
    this.helper.setValue('ccDataval', data);
    this.ccData_child.emit({ data: data, event: "CreditCard", val: true });
  }
}
