<div class="row">
    <!-- <div class="col-sm-4">
      <div>
        <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns"
        [EmployeeTableData]="EmployeeTableData" (tableItemId)="getTableIdx($event)" (breakActive)="ActivateDeactivate($event)"
        (clearDetails)="addPayroll()" [employeeItem]="tableRowColor"  [DataCom]="employee"></app-fliptable>
      </div>
    </div> -->
    <div class="col-sm-12 max-accordian">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global_submit" type="submit" (click)="OnSave();">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label></button>
        </div>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst" [expanded]="isFirst" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-expansion-panel-header [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                    <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}} </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General'">
                    <app-payroll-general [buttonState]="buttonDisable" (isInvalid)="isInvalidAccordion($event)" (payroll_child)="getpayrollsessData($event)" (matopen)="matstate($event)"></app-payroll-general>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
