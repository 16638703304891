import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { IGetRoleID, IRolePermission } from 'src/app/interfaces/iemployee';
import { EmployeeService } from 'src/app/services/employee.service';
import { GetRole, IActivateDeactivateRole, IRole } from 'src/app/interfaces/iadmin';
import { AdminService } from 'src/app/services/admin.service';
import { MatTableDataSource } from '@angular/material/table';
import { RolePermissionGeneralComponent } from '../role-permission-general/role-permission-general.component';
import { CashManagementComponent } from '../cash-management/cash-management.component';
import { RolePermissionDetailsComponent } from '../role-permission-details/role-permission-details.component';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';


@Component({
  selector: 'app-role-and-permission',
  templateUrl: './role-and-permission.component.html',
  styleUrls: ['./role-and-permission.component.css']
})
export class RoleAndPermissionComponent implements OnInit {
  private buttonDisable: boolean;
  @ViewChild(RolePermissionGeneralComponent) genRole: RolePermissionGeneralComponent;
  @ViewChild(CashManagementComponent) Cashform: CashManagementComponent;
  @ViewChild(RolePermissionDetailsComponent) PermForm: RolePermissionDetailsComponent;

  PermissionForm: FormGroup;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public rolepermissionsessData = new EventEmitter();

  roleId: any;
  loginInfo: any;
  public tableRowId: any;
  public tableRowColor: any;
  public dataSource = new MatTableDataSource<any>();
  public EmployeeTableData: any;
  public displayedColumns = [];
  public roleItem: any
  columns = [];
  public activeDeactiveValue: any;
  deleteRole: boolean;
  defaultIndicator: any;
  @Input() public roleDataemit;
  //@Input() public buttonDisable;
  roleJobPositionList: any;
  submittedGeneral: boolean;
  cashmgtdetailslist: any;
  isInvalid: boolean = false;
  public modifierItemsData: any;
  indexExpanded: number = 0;
  private onDestroy$: Subject<void> = new Subject<void>();
  matopen: boolean = false;
  matstate($event) {
    this.matopen = $event
  }
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  constructor(private toaster: ToasterService, private helper: HelperService, private adminService: AdminService,
    private dialogser: DialogService) { }

  buttonStatus: any;
  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.RolepermissionPage");

    this.buttonStatus = false;
    this.buttonDisable = false;
    this.employeeName = "Job Position";
    this.employee = "Job Position";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getRoleList();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
    //  this.PermForm.count = 1;
    //  this.PermForm.ngOnInit();
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  get PermissionData() { return this.PermissionForm.controls; }

  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.ClearData(clearForm);
    }
  }

  expandPanel(index, e?) {
    this.indexExpanded = index;
  }
  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }
  }
  onChangeAccordion(e, index) {

    this.indexExpanded = index;
  }
  index(e: (e: any) => void, index: any) {
    throw new Error('Method not implemented.');
  }
  e(e: any) {
    throw new Error('Method not implemented.');
  }

  async ClearData(event?) {
    if (this.helper.getValue('OnDirty') == false && this.helper.getValue('OnDirty2') == false
      && this.helper.getValue('OnDirty3') == false) {
      this.ResetRolePer();
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
    }
    else {
      var conf = await this.dialogser.confirm("jobPosition");
      if (conf == 'success') { // Navigate Away
        this.ResetRolePer();
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
        this.dialogser.ClearSession();
        this.flipcomp.rowClicked = "transparent";
      }
      else {
        this.genRole.roleGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ResetRolePer() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.genRole.errorValue = "";
    this.isInvalid = false;
    this.genRole.requiredError = false;
    this.employeeName = "Job Position";
    this.tableRowColor = 'transparent';
    this.Cashform.selectedItems = null;
    this.genRole.submittedGeneral = false;
    this.genRole.roleId = 0;
    this.genRole.roleGeneralForm.reset();
    this.genRole.ngOnInit();
    this.Cashform.CashManagementForm.reset();
    this.Cashform.ngOnInit();
    this.Cashform.id = 0;
    this.PermForm.RolePermissionForm.reset();
    this.PermForm.AllCheckBox = false;
    this.PermForm.isDisabled = false;
    this.PermForm.isPermission = false;
    this.PermForm.RolePermissionForm.controls['allPermission'].enable();
    this.PermForm.addClick = 1; // for uncheck all check box in permission component
    this.PermForm.count = 1;
    this.PermForm.ngOnInit();
    this.genRole.roleGeneralForm.patchValue({ isActive: true });
    this.tableRowId = 0;
    this.titleName.emit(this.employeeName);
  }

  async getResponseID(e) {
    this.roleItem = e.res.id;
    this.helper.setValue("roleId", e.res.id);
    // if (e.res.id != 0) {
    //   this.Cashform.onSaveDetails();
    //   this.PermForm.onSavePermission();
    //   this.toaster.successPopUp("Data is added successfully");
    //   await this.dialogser.ClearSession();

    // }
    this.getRoleList();
    this.tableRowId = e.res.id;
    this.flipcomp.selectTableRowColor(e.res.id, e.res.id, e.name)
  }

  getRoleDetails(event) {
    this.roleDataemit = event;
  }

  getRoleList() {
    const roleList: IGetRoleID = {
      id: this.roleId == undefined ? 0 : this.roleId,
      tenantId: this.loginInfo.tenantId
    }
    this.adminService.GetAllRoles(roleList)
      .pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.dataSource.data = res;
        this.EmployeeTableData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true);
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Role Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  async getTableIdx(event) {
    //if (this.genRole.isFormPageDirty() || (this.helper.getValue('OnDirty') == true)) {
    if (this.helper.getValue('OnDirty') == true || this.helper.getValue('OnDirty2') == true
      || this.helper.getValue('OnDirty3') == true) {
      var conf = await this.dialogser.confirm("jobPosition");
      if (conf == 'success') { // Navigate Away
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
        if (event == this.dialogser.ModifiedId) {
          this.genRole.getRole(event);
        }
        this.dialogser.ClearSession();
        this.genRole.roleGeneralForm.reset();

        //General Data
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.deleteRole = true
        this.isInvalid = false;
        this.genRole.requiredError = false;
        this.genRole.errorValue = "";
        this.flipcomp.rowClicked = event;
        this.dialogser.ModifiedId = event;
      }
      else // Cancel
      {
        //General Data
        this.genRole.roleGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.rolepermissionsessData.emit({
          data: this.genRole.roleGeneralForm.value, event: "jobPosition", val: true,
          itmname: this.genRole.roleGeneralForm.value.roleName
        });
      }
    }
    else {
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event)
      this.tableRowId = event;
      this.tableRowColor = event;
      this.deleteRole = true;
      this.isInvalid = false;
      this.genRole.requiredError = false;
      this.genRole.errorValue = "";
    }
    //  this.genRole.getRole(this.tableRowId)
  }

  getTableName(name) {
    this.employeeName = name;
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  ActivateDeactivate(event) {
    this.activeDeactiveValue = event
    let currentTarget = event.e.currentTarget.checked
    const active: IActivateDeactivateRole = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked
    }
    this.adminService.ActivateDeactivateRole(active).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        //this.ClearData(0);
        if (currentTarget) {
          this.toaster.successPopUp("Role is Active");
        } else {
          this.toaster.successPopUp("Role is Deactive");
        }
        this.getRoleList();
      },
    )
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  getcashmgtdetails(e) {
    this.cashmgtdetailslist = e
  }

  @Input()
  public set buttonState(btnst) {
    this.buttonStatus = !btnst;
  }

  async OnSave() {

    let response = await this.genRole.onSaveGeneral();

    if (response.res.id != 0) {
      if (response.res.message == "Employee is already assigned") {
        this.toaster.errorPopUp("Can't allow same Role multiple to same Employee.");
      }
      else {
        this.Cashform.onSaveDetails(response.res.id);
        this.PermForm.onSavePermission(response.res.id);
        this.toaster.successPopUp("Data is added successfully");
        await this.dialogser.ClearSession();
      }

    }

    if (this.genRole.roleGeneralForm.invalid) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  getrolepermissionData(e) {
    this.rolepermissionsessData.emit(e);
  }
}
