import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, ViewChild } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AutotimerService } from 'src/app/services/autotimer.service';
import { DialogService } from './services/dialog.service';
import { ChangeDetectorRef } from '@angular/core';
import { PermissionService } from './services/permission.service';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  subscription: Subscription;
  title = 'Flip-POS';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(private router: Router, private dialogser: DialogService, private cdRef: ChangeDetectorRef,
    private idle: Idle, private keepalive: Keepalive, private autotimerser: AutotimerService,
    private permser: PermissionService,) {

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });

    // sets an idle timeout of 1800 seconds, for testing purposes.
    idle.setIdle(1800);
    // sets a timeout period of 10 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      //console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      //localStorage.clear();
      //this.dialogser.ClearSession();

      localStorage.setItem('sessiontimeout', "true");
      this.childModal.hide();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      //console.log(this.idleState);
      this.router.navigate(['/']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      //console.log(this.idleState);
      this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'The Application will Log out in ' + countdown + ' seconds!'
      //console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.autotimerser.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    })

    // this.reset();
  }

  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  logout() {
    localStorage.clear();
    this.permser.RefreshPermission();
    this.dialogser.ClearSession();
    this.childModal.hide();
    this.autotimerser.setUserLoggedIn(false);
    this.router.navigate(['/']);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
