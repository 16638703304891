<div class="cash-management">
    <form class="loginForm GeneralForm" [formGroup]="CashManagementForm" (change)="onchange(CashManagementForm.value)">
        <div class="row w-100 cash-management-sec">
            <div class="col-sm-6">
                <div class="col-sm-12">
                    <!-- <label class="main-title">Cash Transactions</label> -->
                    <div class="form-group d-flex">
                        <label><span class="cash-btn-pos">Cash Transactions</span></label>
                        <mat-form-field>
                            <mat-select (openedChange)="matstate($event)" class="form-control" (selectionChange)="GetCashTransList($event.value)" formControlName="cashTransaction">
                                <mat-option *ngFor="let cash of cashTransaction" [value]="cash.typeId">
                                    {{cash.typeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12" *ngIf="CashManagementForm.get('cashTransaction').value">
                    <div class="form-group d-flex" *ngIf="isShown">
                        <label>No Sale Transactions</label>
                        <mat-form-field>
                            <mat-select (openedChange)="matstate($event)" class="form-control" formControlName="noSaleTransaction" (selectionChange)="onchange(GeneralForm.value)">
                                <mat-option *ngFor="let cash of noSaleTransaction" [value]="cash.typeId">
                                    {{cash.typeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12" *ngIf="CashManagementForm.get('cashTransaction').value">
                    <div class="form-group d-flex" *ngIf="isShown">
                        <label>Paid Ins</label>
                        <mat-form-field>
                            <mat-select (openedChange)="matstate($event)" class="form-control" formControlName="paidIn" (selectionChange)="onchange(GeneralForm.value)">
                                <mat-option *ngFor="let cash of paidIn" [value]="cash.typeId">
                                    {{cash.typeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12" *ngIf="CashManagementForm.get('cashTransaction').value">
                    <div class="form-group d-flex" *ngIf="isShown">
                        <label>Paid Outs</label>
                        <mat-form-field>
                            <mat-select (openedChange)="matstate($event)" class="form-control" formControlName="paidOut" (selectionChange)="onchange(GeneralForm.value)">
                                <mat-option *ngFor="let cash of paidOut" [value]="cash.typeId">
                                    {{cash.typeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-12" *ngIf="CashManagementForm.get('cashTransaction').value">
                    <div class="form-group d-flex" *ngIf="isShown">
                        <label>Cash Refunds</label>
                        <mat-form-field>
                            <mat-select (openedChange)="matstate($event)" class="form-control" formControlName="cashRefund" (selectionChange)="onchange(GeneralForm.value)">
                                <mat-option *ngFor="let cash of cashRefund" [value]="cash.typeId">
                                    {{cash.typeName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="col-sm-12">
                    <div class="form-group d-flex">
                        <label><span class="cash-btn-pos ">Ignore Print Groups</span></label>
                        <ng-multiselect-dropdown class="dropdown-ng" [data]="dropdownList" (click)="onchange(CashManagementForm.value)" [(ngModel)]="selectedItems" [settings]="dropdownSettings" (onSelectAll)="onSelectAll($event)" [formControl]="ignorePrintGrp" (onDeSelect)="onItemDeSelect($event)">
                            <!-- [ngModelOptions]="{standalone: true}" -->
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-right">
                <div class="form-group mt-2">
                    <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="onSaveDetails($event);" [disabled]="buttonDisable ? true : null">
                        <img src="assets/components/images/save.png" alt="employee" class="">
                        <label class="label_config">Save</label>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
