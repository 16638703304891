<div class="tablet_gen">
    <form [formGroup]="tabletGeneralForm" class="loginForm modalComp" (change)="onchange(tabletGeneralForm.value)">
        <div class="row  mb-2">
            <div class="col-sm-6 col-md-7">
                <div class="row  mb-2">
                    <div class="col-sm-5">
                        <div class="form-group">
                            <label class="customlable mb-0">Tablet Name</label><label class="text-danger">*</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="name"
                                placeholder="Tablet Name" appInputRestriction
                                [ngClass]="{ 'is-invalid': (submittedGeneral && tabGenData.name.errors) || requiredErrors}" maxlength="50" (input)="getMaxValueTabName($event)" (keypress)="Highlight()" (focusout)="TabletNameValid()"/>
                                <div class="error">{{errorValue}}</div>
                            <div *ngIf="(submittedGeneral && tabGenData.name.errors) || requiredErrors" class="invalid-feedback">
                                <div *ngIf="tabGenData.name.errors.required">Tablet Name is required</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="customlable mb-0">Tablet Model</label>
                            <input type="text" class="form-control input-lg inputbox_login" appInputRestriction
                                formControlName="tabletModel" placeholder="Tablet Model" maxlength="50" (input)="getMaxValueTabModel($event)"/>
                                <div class="error">{{errorValue1}}</div>
                        </div>

                        <div class="form-group">
                            <label class="customlable mb-0">Device ID</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="deviceID"
                                placeholder="Device ID" appInputRestriction maxlength="50" (input)="getMaxValueDevice($event)"/>
                                <div class="error">{{errorValue2}}</div>
                        </div>

                        <div class="row  empty_row"></div>
                        <div class="row">
                            <div class="col-sm-4 col-4 mb-2">
                                <button type="button" class="btn p-0 submit_button box_button" data-toggle="modal"
                                    data-target="#exampleModalCenter" [disabled]="removeDisable ? null : true">
                                    <img src="assets/components/images/remove-tablet-complete@2x.png" alt="">
                                </button>

                            </div>
                        </div>
                    </div>
                    <div class="col-sm-7">
                        <div class="row ">
                            <div class="col-sm-4 col-4">
                                <button type="button" class="btn p-0 submit_button box_button" (click)="getScreenshot()">
                                    <img src="assets/components/images/take-screenshot.png" alt="take-screenshot" class="img_width">
                                </button>
                            </div>
                            <div class="col-sm-4 col-4">
                                <button type="button" class="btn p-0 submit_button box_button">
                                    <img src="assets/components/images/troubleshoot-icon@2x.png" alt="troubleshoot" class="img_width">
                                </button>
                            </div>
                            <div class="col-sm-4 col-4">
                                <button type="button" class="btn p-0 submit_button box_button">
                                    <img src="assets/components/images/diagnostics@2x.png" alt="diagnostics" class="img_width">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group tablet_save">
                        <button class="btn  btn-sm  float-right submit_button mr-2 button-none" type="submit"
                            (click)="onSaveGeneral();" [disabled]="buttonDisable ? true : null">
                            <!-- [disabled]="buttonState ? true : null" -->
                            <img src="assets/components/images/save.png" alt="employee" class="">
                            <label class="label_config">Save</label>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 Communication_tab p-2">
                <div class="text-center com_label custom-color">Status Snapchat</div>
                <div class="row  ">
                    <div class="col-sm-5 col-5">
                        <div class="form-group">
                            <button class="btn  btn-sm  submit_button" type="submit"><label>Ping</label></button>
                        </div>
                    </div>
                    <div class="col-sm-7 col-7">
                        <div class="row  no-gutters">
                            <div class="col-sm-6 col-6">
                                <div class="form-group">
                                    <label>Result :</label>
                                </div>
                            </div>
                            <div class="col-sm-6 col-6">
                                <div class="form-group">
                                    <label class="text-success">Good</label>
                                </div>
                            </div>
                            <!-- <div class="col-sm-5 col-5">
                                <div class="form-group">
                                    <input type="text" class="form-control input-lg inputbox_login text-success"
                                        formControlName="result" placeholder="4/0" readonly/>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="row  ">
                    <div class="col-sm-5 col-5">
                        <div class="form-group">
                            <button class="btn  btn-sm submit_button" type="submit"><label>Change IP</label></button>
                        </div>
                    </div>
                    <div class="col-sm-7 col-7 ip-responsive">
                        <div class="row  no-gutters">
                            <div class="col-sm-8 col-6">
                                <div class="form-group">
                                    <label>CurrentIP :</label>
                                </div>
                            </div>
                            <div class="col-sm-8 col-6">
                                <div class="form-group">
                                    <label>192.168.86.241</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row   no-gutters">
                    <div class="col-sm-7 col-7">
                        <div class="form-group">
                            <label>Error : 0</label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>Connectivity :</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label class="text-success">
                                <img src="assets/components/images/icons-wifi.png" alt="">
                                Good</label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>ISP Speed :</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label class="text-success">Good</label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>Logs :</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label class="text-success"></label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>Last Sync :</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label>12/28/2020 3:58 p.m</label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>SSID : POIS</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label class="text-success"></label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>Version :</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label>0.01</label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>BT Status :</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label class="text-success">Good</label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>OLO Primary :</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label>No</label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>OLO Secondary :</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label>No</label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>Failed Print Jobs :</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label>0</label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>IOS Version :</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label>15.6</label>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6 col-6">
                        <div class="form-group">
                            <label>Time Zone :</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6 text-right">
                        <div class="form-group">
                            <label></label>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="Communication_tab m-2">
            <div class="remove_tab m-3">

              <div class="modal-header">
                <div class="text-right">
                  <button type="button" class="btn-close close" aria-label="Close" data-dismiss="modal">
                    <span aria-hidden="true" class="visually-hidden">&times;</span>
                  </button>
                </div>
              </div>

              <div class="modal-body text-center p-0">
                <p>Remove Device</p>
                <div>
                  <img src="assets/components/images/Capture.JPG" alt="employee" class="img-fluid">
                </div>
                <div class="mt-2">
                  <label>Are You Sure?</label>
                </div>
              </div>

              <div class="modal-footer text-center">
                <button type="button" (click)="RemoveTabletDetails()" class="btn  btn-sm" data-dismiss="modal" >
                  <img src="assets/components/images/confirm-button.png" alt="employee" width="85%">
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </form>
</div>