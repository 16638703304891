import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { ToasterService } from 'src/app/helper/toaster.service';
import { AdminService } from 'src/app/services/admin.service';
import { DialogService } from 'src/app/services/dialog.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IListInfo, IRole } from 'src/app/interfaces/iadmin';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-currency-config',
  templateUrl: './currency-config.component.html',
  styleUrls: ['./currency-config.component.css']
})

export class CurrencyConfigComponent implements OnInit {
  selected = 'USD';
  CurrencyconfigForm: FormGroup;
  id: any = 0;
  public employeeName: any;
  public employeeIcon: any;
  public employee: any;
  loginInfo: any;
  disabled = false;
  showFilter = false;
  limitSelection = false;
  storeList: any = [];
  selectedItems: any = [];
  dropdownSettings: IDropdownSettings = {};
  isInvalid: boolean = false;
  submittedGeneral: any;
  buttonDisable: boolean = false;
  requiredError: boolean = false;
  errorValue: any;
  store: FormControl = new FormControl('');
  public dataSource = new MatTableDataSource<any>();
  hirarcyvalue: any;

  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public generalValueemit = new EventEmitter;
  @Output() public currconfigsessData = new EventEmitter;

  private onDestroy$: Subject<void> = new Subject<void>();
  matopen:boolean=false;
  matstate($event){
    this.matopen = $event
  }
  constructor(private formBuilder: FormBuilder, private helper: HelperService,
    private reportService: ReportWebSocketService, private toaster: ToasterService,
    private admserv: AdminService, private dialogser: DialogService) { }


  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.currencyConfig");

    this.CurrencyconfigForm = this.formBuilder.group({
      id: [0],
      store: [null],
      currency: [null]

    })

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Currency Config";
    this.employee = "Setting";
    this.employeeIcon = "assets/components/images/setting.png";
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)


    this.admserv.HierachyValue.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.hirarcyvalue = data
      if (this.hirarcyvalue.company != undefined && this.hirarcyvalue.company != null && this.hirarcyvalue.company != "") {
        this.getPrimaryStoreData(this.hirarcyvalue.company.id);
      }
    }), err => console.error('Error', err), () => console.log('HTTP request completed.');

    this.dropdownSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 1,
      selectAllText: 'Select All',
      unSelectAllText: 'Select All'
    };
  }

  getPrimaryStoreData(id) {
    const login: IListInfo = {
      loginUserId: this.loginInfo.userId,
      tenantId: id
    }
    this.admserv.TenantHierarchyData(login).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        //this.storeList = response.childHierarchyList;
        var storeDetails
        var jsonToBeUsedStore = [];
        for (var type in response.childHierarchyList) {
          storeDetails = {};
          storeDetails.key = response.childHierarchyList[type].id.toString();
          storeDetails.value = response.childHierarchyList[type].name;
          jsonToBeUsedStore.push(storeDetails);
        }
        this.storeList = jsonToBeUsedStore;
        this.PatchCurrencyConfig();

      }), err => console.error('Error', err), () => console.log('HTTP request completed.');
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }

  onSelectAll(item: any) {
    console.log('onnpm', item);
  }

  btnActive(event) {
    this.buttonDisable = event;
    if (event == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !event;
    }
  }
  PatchCurrencyConfig() {
    const currency: IRole = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.admserv.SelectCurrencyConfig(currency).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        var stoeJsonBoject = "";
        if (response[0] != undefined && response[0] != null && response[0] != "") {
          stoeJsonBoject = response[0].store;
        }

        if (stoeJsonBoject != null && stoeJsonBoject != '') {
          this.selectedItems = JSON.parse(stoeJsonBoject);
        }
        else {
          this.selectedItems = [];
        }
        this.CurrencyconfigForm.patchValue(response[0]);
      }), err => console.error('Error', err), () => console.log('HTTP request completed.');
  }

  onSaveGeneral() {
    if (this.CurrencyconfigForm.invalid) {
      return;
    }

    this.CurrencyconfigForm.value.id = this.id;
    this.CurrencyconfigForm.value.isActive = true;
    this.CurrencyconfigForm.value.tenantId = this.loginInfo.tenantId;
    this.CurrencyconfigForm.value.createdBy = this.loginInfo.userId;
    this.CurrencyconfigForm.value.modifiedBy = this.loginInfo.userId;

    if (this.selectedItems != null) {
      var storeArray = [];
      var selstore = this.selectedItems;
      for (var a = 0, len = selstore.length; a < len; a++) {
        storeArray.push(Number(selstore[a].key));
      }
      this.CurrencyconfigForm.value.store = JSON.stringify(storeArray).toString();
    }

    this.admserv.SaveCurrencyConfig(this.CurrencyconfigForm.value).subscribe(
      async (response) => {
        this.id = response.id;
        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
        } else {
          this.helper.setValue('Sessionid_help', response.id);
          if (this.helper.getValue('Sessionid_help') == undefined ||
            this.helper.getValue('Sessionid_help') == null ||
            this.helper.getValue('Sessionid_help') == "") {
            this.helper.setValue('Sessionid_help', 0)
          }
          else {
            await this.dialogser.ClearSession();
            this.helper.setValue('GetSessionData1', this.CurrencyconfigForm.value);
            this.toaster.successPopUp(response.message);
            this.currconfigsessData.emit({
              data: this.CurrencyconfigForm.value,
              event: "currencyConfig", val: false
            });
          }
        }
        this.submittedGeneral = false;
      })
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.currconfigsessData.emit({ data: data, event: "currencyConfig", val: true });
  }
}
