import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener,} from '@angular/material/tree';

interface FoodNode {
  name?: string;
  count?: number;
  children?: FoodNode[];
  groupHeader?: string;
  grouptotal?: string;
  employee?: string;
  discountName?: string;
  quantity?: number;
  discountTotal?: number;
  id?:number;
  reporttotal?: any;
  employeetotal?: any;
  totalamount?:any;
}

// const APPROVER_DATA: FoodNode[] = [
//   {
//     groupHeader: "Dinein",
//     children: [
//       {
//         employee: "Jason",
//         children: [
//           {
//             discountName: "Redeem Discount",
//             discountTotal: 200,
//             quantity: 1
//           },
//           {
//             discountName: "Totals",
//             discountTotal: 200
//           }
//         ]
//       },
//       {
//         grouptotal: "Dinein Totals",
//         discountTotal: 200
//       }
//     ]
//   },
//   {
//     groupHeader: "Dineout ",
//     children: [
//       {
//         employee: "Jason",
//         children: [
//           {
//             discountName: "Redeem Discount",
//             discountTotal: 400,
//             quantity: 2
//           },
//           {
//             discountName: "Totals",
//             discountTotal: 400
//           }
//         ]
//       },
//       {
//         grouptotal: "Dineout  Totals",
//         discountTotal: 400
//       }
//     ],
//     reporttotal: "REPORT TOTALS",
//     totalamount: 600
//   }
// ];
interface ExampleFlatNode {
  expandable: boolean;
  groupHeader: string;
  employee: string;
  discountName: string;
  quantity: number;
  discountTotal: number;
  level: number;
  grouptotal:any
  reporttotal:any;
  employeetotal:any;
  totalamount:any;
}


@Component({
  selector: 'app-flip-table-approver',
  templateUrl: './flip-table-approver.component.html',
  styleUrls: ['./flip-table-approver.component.css']
})
export class FlipTableApproverComponent  {

  displayedColumns: string[] = ['groupHeader', 'employee', 'discountName', 'quantity', 'discountTotal'];
  
  rowClicked : any;
  @Output() public tableItem = new EventEmitter();
  @Output() public checkItem = new EventEmitter();

  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      count: node.count,
      level: level,
      groupHeader: node.groupHeader,
      employee: node.employee,
      discountName: node.discountName,
      quantity: node.quantity,
      discountTotal: node.discountTotal,
      grouptotal:node.grouptotal,
      reporttotal:node.reporttotal,
      employeetotal:node.employeetotal,
      totalamount:node.totalamount
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  Approver = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor() {
   // this.Approver.data = APPROVER_DATA;
  }
  public GetLoad(Value?)
  {

    const APPROVER_DATA:FoodNode[] =JSON.parse(Value);

   // console.log(EMPLOYEE_DATA1,'EMPLOYEE_DATA')
    
    this.Approver.data = APPROVER_DATA;
  }

  ngonint() {
    this.treeControl = new FlatTreeControl<ExampleFlatNode>(
      (node) => node.level,
      (node) => node.expandable,
    );    
    console.log(this.Approver,'treeControl');
    
    //this.hide(this.treeControl )
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  changeTableRowColor(id?: any, row?: any) {
    this.rowClicked = ""
    this.rowClicked = this.rowClicked === id? -1 : id;
    this.tableItem.emit(row)
  }
  itemClick(e){
    this.checkItem.emit(e);
  } 

}
