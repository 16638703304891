<div class="row menuPage_layoyt layout">
    <div class="col-sm-3 text-center">
        <div class="drop-container">
            <app-fliptable-layout [dataSource]="dataSourceTablePage" [displayedColumns]="displayedColumns"
                [columns]="columns" [salesAreaflip]="salesAreaflip" [DatatableRender]="table_render"
                [SalesID]="layoutId"  (dataSessionId)="GetSessionId($event)"></app-fliptable-layout>
        </div>
    </div>
    <div class="col-sm-9">
        <div class="salesAreaLayout menu_wrapper layout_wrapper">
            <div cdkDropListOrientation="horizontal" cdkDropList id="menuPage" #todo="cdkDropList"
                [cdkDropListData]="menuPageLayout" [cdkDropListConnectedTo]="layoutId"
                (cdkDropListDropped)="drop($event)" cdkDragBoundary=".tablet"
                (cdkDropListExited)="cdkDropListExited($event)" class="example-list">
                <div class="example-box" *ngFor="let item of menuPageLayout; let i=index" cdkDrag cdkDragDropZone>
                    <div [ngClass]="{ 'dropzone-placeholder': item.id || DragId == true}" (click)="onClickItem(item)"
                    [style.background-color]="rowClicked == item.id ? '#524261' : 'transparent'">
                        {{item.name}}
                    </div>
                </div>
            </div>

        </div>
        <div class="form-group mt-3">
            <button class="btn  btn-sm float-right submit_button loginForm button-none" type="submit" (click)="OnSavepageGroup()">
                    <!-- [disabled]="buttonState ? true : null" -->
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label> 
            </button>
        </div>
    </div>
</div>