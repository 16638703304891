<!-- <p>DateTime as Milliseconds : {{todayNumber}} 
  datepipe:{{todayNumber | date}}</p>
  <p>DateTime as object : {{todayDate}} 
  datepipe:{{todayDate | date}}</p>
  <p>DateTime as string : {{todayString}} 
  datepipe:{{todayString | date}}</p>
  <p>DateTime as iso string : {{todayISOString}} 
  datepipe:{{todayISOString | date}}</p> -->

<form class="SaleArea_general scheduleForm loginForm" [formGroup]="ScheduleDayForm" (change)="onchange(ScheduleDayForm.value)">
  <div formArrayName="daypartList">
    <button class="plus_btn inshadow" (click)="addNewDayPartItems()"><i class="fa fa-plus"></i></button>
    <div class="job_wrap">
      <div *ngFor="let item of daypartList().controls; let i=index" [formGroupName]="i" class="my-3">
        <div class="row">
          <div class="col-sm-11 col-11">
            <div class="multiItem_wrap">

              <div class="checkbox_wrap justify-content-between mb-3">
                <div class="form-group m-0 align-self-center">
                  <h6 class="radiolabel custom-color">Select Day(s)</h6>
                </div>
                <div class="form-group d-inline-block m-0 align-self-center" *ngFor="let item of weekDaysArray[i]">
                  <div class="checkbox-table Week_wrap">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input"
                        [checked]="item.isSelected == true ? true : false" name="{{item.name}}"
                        (change)="onCheckChange($event, item.id,i)" id="{{item.id}}" value="{{item.name}}">
                      <label class="custom-control-label" for="{{item.id}}">{{item.name}}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-2">
                  <h6 class="radiolabel mr-2 custom-color">Time</h6>
                </div>
                <div class="col-sm-5">

                  <div class="form-group">
                    <label class="radiolabel mr-2">Start Time</label>
                  
                    <ngx-mat-timepicker-field [controlOnly]="true" formControlName="startTime" class="ngxMatTime" [defaultTime]="'00:00 AM'">
                    </ngx-mat-timepicker-field>
                    <!-- <ngx-timepicker-field [controlOnly]="true" formControlName="startTime" ngDefaultControl></ngx-timepicker-field> -->
                    <!-- <timepicker name="myTime" id="myTime" formControlName="startTime"></timepicker> -->
                  
                  <!-- <ngx-mat-timepicker-field color="warn" formControlName="startTime"
                          [defaultTime]="'11:11 AM'"></ngx-mat-timepicker-field> -->
                        </div>
                </div>
                <div class="col-sm-5">

                  <div class="form-group">
                    <label class="radiolabel mr-2">End Time</label>
                     <ngx-mat-timepicker-field [controlOnly]="true" formControlName="endTime" 
                     class="ngxMatTime" [defaultTime]="'00:00 AM'"></ngx-mat-timepicker-field> 
                     <!-- <timepicker name="myTime" id="myTime" formControlName="endTime"></timepicker> -->
                     <!-- <ngx-mat-timepicker-field color="warn" formControlName="endTime"
                     [defaultTime]="'11:11 AM'"></ngx-mat-timepicker-field> -->
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="col-sm-1 col-1 p-0">
            <i class="fa fa-remove" aria-hidden="true" style="font-size: 19px;" (click)="removeNewDayPartItems(i)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-sm-12 text-right">
      <div class="form-group">
        <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="onSave();" 
        [disabled]="buttonDisable ? true : null" >
          <!-- [disabled]="buttonState ? true : null" -->
          <img src="assets/components/images/save.png" alt="employee" class="">
          <label class="label_config">Save</label>
        </button>
      </div>
    </div>
  </div>
</form>