import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { GetEmployee, IGetRoleID } from 'src/app/interfaces/iemployee';
import { unescapeIdentifier } from '@angular/compiler';
import { AdminService } from 'src/app/services/admin.service';
import { CurrencyPipe } from '@angular/common';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TraceLoggerService } from 'src/app/services/trace-logger.service';
import { DialogService } from 'src/app/services/dialog.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-employee-wages',
  templateUrl: './employee-wages.component.html',
  styleUrls: ['./employee-wages.component.css'],
})
export class EmployeeWagesComponent implements OnInit {
  WagesForm: FormGroup;
  public roleList = [];
  empJobPositionList = [];
  pemissionRoleValue: any = [];
  checkPositionValue: any;
  defaultroleId: any;
  roleId: any;
  rolePosition: any;
  public currentTarget: boolean;
  PermissionForm: FormGroup;
  submittedWages = false;
  wagesUserId: any;
  defaultIndicator: any;
  userId: any;
  loginInfo: any;
  wagesValue: any;
  value: null;
  value1: null;
  value2: null;
  myValue: any;
  value3: null;
  permissionData: any;
  tempRoleList: any = [];
  tempRoleListSelect: any = [];
  permissionValues = [];
  RolePermissionList: any = [];
  jsonArrayValue: any = [];
  wagesID: any;
  empValue: any;
  jobPositionarray: any = [];
  salaried: boolean;
  @Input() public generalDataemit;
  @Input() public tableRowId;
  @Output() public wagesItem = new EventEmitter();
  @Output() public permissionItem = new EventEmitter();
  @Output() public roleItem = new EventEmitter();
  @Output() public getroleItem = new EventEmitter();
  @Output() public validDateWagesRole = new EventEmitter();
  @Output() public getPermissionItem = new EventEmitter();
  salariedvalue: boolean;
  requiredError: boolean = false;
  public isWagesPermission: boolean;

  @Output() public isInvalidWage = new EventEmitter();
  @Output() public StoreempwageData_child = new EventEmitter();

  //@Input() public buttonState;
  @Input() public wagesDataEmit;
  buttonStatus: any;
  private onDestroy$: Subject<void> = new Subject<void>();
  mainRole: any;
  throwError: boolean = true;

  roleListSelect: any[];
  msgFound: { value: string; index: number };
  RemovePermissionAdd: { id: any; name: {}; index: any };
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }

  constructor(
    private wagesDetails: EmployeeService,
    private adminDetails: AdminService,
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private helper: HelperService,
    private LoggerService: TraceLoggerService,
    private dialogser: DialogService,
    private permser: PermissionService
  ) {
    this.WagesForm = this.formBuilder.group({
      employeeJobPositionList: this.formBuilder.array([
        this.addUserFormGroup(),
      ]),
    });
  }

  ngOnInit(): void {

    this.isWagesPermission = this.permser.isWagesPermission;

    this.helper.setValue('GetSessionData2', null);
    this.buttonStatus = false;
    this.WagesForm = this.formBuilder.group({
      employeePayrollId: [null],
      employeeSwipeCard: [null],
      employeeTextMessaging: [false],
      salaried: [false],
      employeeJobPositionList: this.formBuilder.array([
        this.formBuilder.group({
          id: 0,
          empPositionId: [null, Validators.required],
          empWage: [null, Validators.required],
          empOtCharges: [null, Validators.required],
          primaryPosition: [true, Validators.required],
          isDeleted: false,
        }),
      ]),

      employeeAnnualSalary: [null, { disabled: true }],
      employeeFixedCost: [null, { disabled: true }],
      employeeLabourPercent: [false],
    });
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.onChangeRolePosition();
    this.roleListSelect = [];
    this.wagesDetails.empParentData
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => (this.empValue = data)),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');


  }

  options = {
    prefix: '$',
    thousands: ',',
    decimal: '.',
    inputMode: CurrencyMaskInputMode.NATURAL,
  };
  options1 = {
    prefix: '$',
    thousands: ',',
    decimal: '.',
    inputMode: CurrencyMaskInputMode.NATURAL,
  };

  public onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {
      event.preventDefault();
      this.value = null;
    }
    if (event.key === 'Delete') {
      //console.log(event);
      event.preventDefault();
      this.value = null;
    }
  }

  public onKeyDownMulti(event: KeyboardEvent, index): void {
    //console.log(event.key);
    if (event.key === 'Backspace') {
      event.preventDefault();
      this.WagesForm.controls['employeeJobPositionList']['controls'][
        index
      ].controls.empWage.reset();
    }
    if (event.key === 'Delete') {
      //console.log(event);
      event.preventDefault();
      this.WagesForm.controls['employeeJobPositionList']['controls'][
        index
      ].controls.empWage.reset();
    }
  }

  get WagesData() {
    return this.WagesForm.controls;
  }

  keyPress(event: any) {
    const pattern = /[0-9\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  decimalNumberOnly(event) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  onBlur(evt) {
    if (evt.target.valueAsNumber) {
      this.myValue = evt.target.valueAsNumber.toFixed(2);
    }
  }

  @Input()
  public set buttonState(btnst) {
    this.buttonStatus = !btnst;
  }

  @Input()
  public set DataIdx(tableRowId) {
    this.tableRowId = tableRowId;
    this.generalDataemit = tableRowId;
    if (
      this.tableRowId != undefined &&
      this.tableRowId != null &&
      this.tableRowId != ''
    ) {
      this.getEmployee(this.tableRowId);
    }
  }

  addUserFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: 0,
      empPositionId: [null, Validators.required],
      empWage: [null, Validators.required],
      empOtCharges: [null, Validators.required],
      primaryPosition: [''],
      isDeleted: false,
    });
  }

  preventKey(e) {
    if (this.throwError == true) {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    }
  }

  addUserMulti(e) {
    this.roleList = [];
    const dynFormArray = this.WagesForm.controls[
      'employeeJobPositionList'
    ] as FormArray;
    if (!this.WagesForm.invalid) {
      this.msgFound = { value: '', index: null };
    }
    dynFormArray.push(this.addUserFormGroup());
    if (!this.WagesForm.invalid) {
      this.WagesForm.controls['employeeJobPositionList']['controls'][
        dynFormArray.length - 1
      ].controls.empWage.clearValidators();
      this.WagesForm.controls['employeeJobPositionList']['controls'][
        dynFormArray.length - 1
      ].controls.empWage.updateValueAndValidity({ onlySelf: true });
      this.WagesForm.controls['employeeJobPositionList']['controls'][
        dynFormArray.length - 1
      ].controls.empWage.setValidators([Validators.required]);
      this.WagesForm.controls['employeeJobPositionList']['controls'][
        dynFormArray.length - 1
      ].controls.empWage.updateValueAndValidity({ onlySelf: true });
    }
    for (let i = 0; i < dynFormArray.length; i++) {
      let formGroup2 = dynFormArray.controls[i].value as FormGroup;
      /////////For Disabling Multi when unfilled rows/////////////
      if (
        (formGroup2['empPositionId'] != null &&
          formGroup2['empWage'] != null &&
          formGroup2['empOtCharges'] != null) ||
        (formGroup2['empPositionId'] != null &&
          this.WagesForm.get('salaried').value != false)
      ) {
        this.throwError = false;
      } else {
        this.throwError = true;
        return;
      }
      /////////For Disabling Multi when unfilled rows/////////////

      ////////////////On new row add remiove required validation////////
      if (this.WagesForm.get('salaried').value == true) {
        for (let i = 0; i < dynFormArray.length; i++) {
          this.WagesForm.controls['employeeJobPositionList']['controls'][
            i
          ].controls.empWage.setValue(0);
          this.WagesForm.controls['employeeJobPositionList']['controls'][
            i
          ].controls.empWage.clearValidators();
          this.WagesForm.controls['employeeJobPositionList']['controls'][
            i
          ].controls.empOtCharges.setValue(0);
          this.WagesForm.controls['employeeJobPositionList']['controls'][
            i
          ].controls.empOtCharges.clearValidators();
        }
      }

      ////////////////On new row add remiove required validation////////

      let rolename;
      rolename = this.rolePosition.find(
        (x) => x.roleId == formGroup2['empPositionId']
      );
      if (rolename != undefined && rolename != null && rolename != '') {
        let name = {};
        name = rolename.roleName;
        let rolePermission = {
          value: formGroup2['empPositionId'],
          label: name,
          primaryPosition:
            formGroup2['primaryPosition'] == ''
              ? false
              : formGroup2['primaryPosition'],
        };
        this.roleList.push(rolePermission);

        let RemovePermission = {
          id: rolePermission.value,
          name: rolePermission.label,
        };
        this.tempRoleList.push(RemovePermission);
        // console.log(this.tempRoleList, 'RemovePermission')
        //    this.rolePosition= this.rolePosition.filter(val => !this.tempRoleList.includes(val));
        const result2 = this.tempRoleList;
        const getUniquePageList = this.rolePosition.filter(function (obj) {
          return !result2.some(function (obj2) {
            return obj.id == obj2.id;
          });
        });
        //  this.rolePosition=getUniquePageList;
        // console.log(getUniquePageList, 'temprolelist')
      }
    }
    this.getSalaried(e.isTrusted);
  }

  onseleccheck(e, ind) {
    let rolePermission;
    let len = this.tempRoleListSelect.length;
    for (let m = 0; m < len; m++) {
      if (this.tempRoleListSelect[m].index == ind) {
        this.tempRoleListSelect = this.tempRoleListSelect.filter(
          (x) => x.index != ind
        );
        break;
      }
    }
    let wagesGroup = this.WagesForm;
    let dynFormArray = wagesGroup.controls[
      'employeeJobPositionList'
    ] as FormArray;
    for (let i = 0; i < dynFormArray.length; i++) {
      let formGroup2 = dynFormArray.controls[i].value as FormGroup;
      /////////For Disabling Multi when unfilled rows/////////////

      let rolename;
      rolename = this.rolePosition.find(
        (x) => x.roleId == formGroup2['empPositionId']
      );
      if (rolename != undefined && rolename != null && rolename != '') {
        let name = {};
        name = rolename.roleName;

        if (e.value == formGroup2['empPositionId']) {
          rolePermission = {
            value: formGroup2['empPositionId'],
            label: name,
            primaryPosition:
              formGroup2['primaryPosition'] == ''
                ? false
                : formGroup2['primaryPosition'],
          };
        }

        console.log(i, 'iii');

        if (
          this.tempRoleListSelect[i] != undefined &&
          this.tempRoleListSelect[i].id == e.value
        ) {
          this.isInvalidWage.emit(true);
          this.msgFound = {
            value:
              'The Job Position for ' +
              this.tempRoleListSelect[i].name +
              ' is already assigned',
            index: ind,
          };
          this.throwError = true;
          return;
        } else {
          this.msgFound = { value: '', index: null };
          this.isInvalidWage.emit(false);
        }
      }
    }
    this.RemovePermissionAdd = {
      id: rolePermission.value,
      name: rolePermission.label,
      index: ind,
    };
    this.tempRoleListSelect.push(this.RemovePermissionAdd);

    this.onchange(this.WagesForm.value);
  }

  /////////For Disabling Multi when unfilled rows/////////////
  fieldsFill() {
    const dynFormArray = this.WagesForm.controls[
      'employeeJobPositionList'
    ] as FormArray;
    for (let i = 0; i < dynFormArray.length; i++) {
      let formGroup2 = dynFormArray.controls[i].value as FormGroup;
      if (
        ((formGroup2['empPositionId'] != null &&
          formGroup2['empWage'] != null &&
          formGroup2['empOtCharges'] != null) ||
          (formGroup2['empPositionId'] != null &&
            this.WagesForm.get('salaried').value != false)) &&
        (this.msgFound == undefined || this.msgFound.index == null)
      ) {
        this.throwError = false;
      } else {
        this.throwError = true;
        return;
      }
      console.log(dynFormArray, 'dynFormArray');
    }
  }
  /////////For Disabling Multi when unfilled rows/////////////

  DeleteItem(index: number): void {
    // if (this.isWagesPermission == false) {
    //   return;
    // }
    if (
      this.WagesForm.controls['employeeJobPositionList'].value[index]
        .primaryPosition == true
    ) {
      return;
    } else if (
      !((<FormArray>this.WagesForm.get('employeeJobPositionList')).length == 1)
    ) {
      (<FormArray>this.WagesForm.get('employeeJobPositionList')).removeAt(
        index
      );

      if (!this.WagesForm.invalid) {
        this.msgFound = { value: '', index: null };
        this.isInvalidWage.emit(false);
      }
    }
    if (index !== -1) {
      this.roleList.splice(index, 1);
      const dynFormArray = this.WagesForm.controls[
        'employeeJobPositionList'
      ] as FormArray;
      for (let i = 0; i < dynFormArray.length; i++) {
        let formGroup2 = dynFormArray.controls[i].value as FormGroup;
        /////////For Disabling Multi when unfilled rows/////////////
        if (
          (formGroup2['empPositionId'] != null &&
            formGroup2['empWage'] != null &&
            formGroup2['empOtCharges'] != null) ||
          (formGroup2['empPositionId'] != null &&
            this.WagesForm.get('salaried').value != false)
        ) {
          this.throwError = false;
        } else {
          this.throwError = true;
          break;
        }
      }
    }
  }

  getSalaried(event) {
    this.salariedvalue = event;
    this.currentTarget = event;
    if (this.currentTarget == true) {
      let wagesGroup = this.WagesForm;
      let dynControl = wagesGroup.controls[
        'employeeJobPositionList'
      ] as FormArray;
      for (let i = 0; i < dynControl.length; i++) {
        // this.WagesForm.controls['employeeJobPositionList']['controls'][i].controls.empWage.reset();
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empWage.clearValidators();
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empWage.updateValueAndValidity({ onlySelf: true });
        // this.WagesForm.controls['employeeJobPositionList']['controls'][i].controls.empOtCharges.reset();
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empOtCharges.clearValidators();
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empOtCharges.updateValueAndValidity({ onlySelf: true });
      }
    } else if (this.currentTarget == false) {
      let wagesGroup = this.WagesForm;
      let dynControl = wagesGroup.controls[
        'employeeJobPositionList'
      ] as FormArray;
      for (let i = 0; i < dynControl.length; i++) {
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empWage.setValidators([Validators.required]);
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empWage.updateValueAndValidity({ onlySelf: true });
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empOtCharges.setValidators([Validators.required]);
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empOtCharges.updateValueAndValidity({ onlySelf: true });
      }
      this.WagesForm.controls.employeeAnnualSalary.reset();
      this.WagesForm.controls.employeeFixedCost.reset();
      // this.addUserMulti()
    } else {
      this.WagesForm.controls.employeeAnnualSalary.reset();
      this.WagesForm.controls.employeeFixedCost.reset();
    }
    if (
      this.salariedvalue == true &&
      this.WagesForm.controls.salaried.value == true
    ) {
      let wagesGroup = this.WagesForm;
      let dynWagesControl = wagesGroup.controls[
        'employeeJobPositionList'
      ] as FormArray;
      for (let i = 0; i < dynWagesControl.length; i++) {
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empWage.setValue(0);
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empWage.clearValidators();
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empOtCharges.setValue(0);
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empOtCharges.clearValidators();
      }
    } else if (this.WagesForm.controls.salaried.value == false) {
      let wagesGroup = this.WagesForm;
      let dynWagesControl = wagesGroup.controls[
        'employeeJobPositionList'
      ] as FormArray;
      for (let i = 0; i < dynWagesControl.length; i++) {
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empWage.setValidators([Validators.required]);
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empWage.updateValueAndValidity({ onlySelf: true });
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empOtCharges.setValidators([Validators.required]);
        this.WagesForm.controls['employeeJobPositionList']['controls'][
          i
        ].controls.empOtCharges.updateValueAndValidity({ onlySelf: true });
      }
    }
  }

  async onSaveCompleteForm(employee, sectionId: any, userId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.generalDataemit = userId;
      //console.log(this.generalDataemit, ' this.generalDataemit');
      if (this.generalDataemit == undefined || this.generalDataemit == null) {
        this.generalDataemit = '';
      }
      if (sectionId == 2) {
        employee.userId = this.generalDataemit
          ? this.generalDataemit
          : this.tableRowId;
        this.wagesUserId = employee.userId;
        employee.sectionId = 2;
        employee.roleId = this.roleId > 0 ? this.roleId : 0;
        employee.createdby = this.loginInfo.userId; //login userid
        employee.tenantId = this.loginInfo.tenantId;
        employee.employeeAnnualSalary = employee.employeeAnnualSalary
          ? employee.employeeAnnualSalary
          : null;
        employee.employeeFixedCost = employee.employeeFixedCost
          ? employee.employeeFixedCost
          : null;
        employee.primaryPosition = employee.primaryPosition
          ? employee.primaryPosition
          : null;

        if (
          employee.userId == undefined ||
          employee.userId == null ||
          employee.userId == 0
        ) {
          this.toaster.error('Please add General data');
          return;
        }
      }

      this.wagesDetails.UserEmployee(employee).subscribe(async (response) => {
        if (sectionId == 2) {
          this.wagesValue = response;
          this.userId = response.id;
          // if (response.id == 0) {
          //   this.toaster.errorPopUp("Please Select Primary Role...");
          // }
          // else {
          //   if (response.message == 'primaryrole') {
          //     this.toaster.errorPopUp("Please Select Primary Role...");

          //   } else {

          //     // this.helper.setValue('GetSessionData2', employee);
          //     // await this.dialogser.ClearSession();
          //     // this.StoreempwageData_child.emit({ data: employee, event: "GeneralPage", val: false });
          //   }
          // }
          this.submittedWages = false;
        }
        resolve({ res: response });
        //  this.wagesItem.emit(this.wagesValue.id);
      }),
        (err) => console.error('Error', err),
        () => console.log('HTTP request completed.');
    });
  }

  async onSaveWages(userId) {
    this.empJobPositionList = [];
    this.pemissionRoleValue = [];
    this.submittedWages = true;

    if (this.WagesForm.invalid) {
      return;
    }
    let wagesGroup = this.WagesForm;
    let dynControl = wagesGroup.controls[
      'employeeJobPositionList'
    ] as FormArray;
    this.roleList = [];
    for (let i = 0; i < dynControl.length; i++) {
      let formGroup2 = dynControl.controls[i].value as FormGroup;
      if (formGroup2['primaryPosition'] == '') {
        this.WagesForm.controls['employeeJobPositionList'].value[
          i
        ].primaryPosition = false;
      } else {
        this.WagesForm.controls['employeeJobPositionList'].value[
          i
        ].primaryPosition = true;
      }
      let PrimaryRadio = {
        value: formGroup2['empPositionId'],
        primaryPosition:
          this.WagesForm.controls['employeeJobPositionList'].value[i]
            .primaryPosition,
      };
      this.roleList.push(PrimaryRadio);
      this.checkPositionValue = this.roleList[i].primaryPosition;
      if (this.checkPositionValue == true) {
        this.defaultroleId = formGroup2['empPositionId'];
        this.roleId = this.defaultroleId;
      }
      let rolename;
      rolename = this.rolePosition.find(
        (x) => x.roleId == formGroup2['empPositionId']
      );
      if (rolename != undefined && rolename != null && rolename != '') {
        let name = {};
        name = rolename.roleName;
        let rolePermission = {
          value: formGroup2['empPositionId'],
          label: name,
          primaryPosition:
            this.WagesForm.controls['employeeJobPositionList'].value[i]
              .primaryPosition,
        };
        this.pemissionRoleValue.push(rolePermission);
      }
    }
    let roleAlldata = this.roleList.filter((x) => x.primaryPosition == true);
    if (roleAlldata.length == 1) {
      this.roleId = this.defaultroleId;
    } else {
      this.roleId = 0;
    }
    let GenData =
      this.generalDataemit == '' ? this.wagesUserId : this.generalDataemit;
    if (GenData > 0 && GenData != undefined) {
      this.permissionItem.emit(this.pemissionRoleValue);
      this.roleItem.emit(this.roleId);
    }

    // this.LoggerService.SaveLoggerInformation(this.loginInfo.tenantId, 'info', 'emp-wages', JSON.stringify(this.WagesForm.value), this.loginInfo.userId)

    let response = await this.onSaveCompleteForm(
      this.WagesForm.value,
      2,
      userId
    );

    return response;
  }

  async onValidateRole() {
    this.empJobPositionList = [];
    this.pemissionRoleValue = [];
    this.submittedWages = true;

    if (this.WagesForm.invalid) {
      return;
    }
    let wagesGroup = this.WagesForm;
    let dynControl = wagesGroup.controls[
      'employeeJobPositionList'
    ] as FormArray;
    this.roleList = [];
    for (let i = 0; i < dynControl.length; i++) {
      let formGroup2 = dynControl.controls[i].value as FormGroup;
      if (formGroup2['primaryPosition'] == '') {
        this.WagesForm.controls['employeeJobPositionList'].value[
          i
        ].primaryPosition = false;
      } else {
        this.WagesForm.controls['employeeJobPositionList'].value[
          i
        ].primaryPosition = true;
      }
      let PrimaryRadio = {
        value: formGroup2['empPositionId'],
        primaryPosition:
          this.WagesForm.controls['employeeJobPositionList'].value[i]
            .primaryPosition,
      };
      this.roleList.push(PrimaryRadio);
      this.checkPositionValue = this.roleList[i].primaryPosition;
      if (this.checkPositionValue == true) {
        this.defaultroleId = formGroup2['empPositionId'];
        this.roleId = this.defaultroleId;
      }
      let rolename;
      rolename = this.rolePosition.find(
        (x) => x.roleId == formGroup2['empPositionId']
      );
      if (rolename != undefined && rolename != null && rolename != '') {
        let name = {};
        name = rolename.roleName;
        let rolePermission = {
          value: formGroup2['empPositionId'],
          label: name,
          primaryPosition:
            this.WagesForm.controls['employeeJobPositionList'].value[i]
              .primaryPosition,
        };
        this.pemissionRoleValue.push(rolePermission);
      }
    }
    let roleAlldata = this.roleList.filter((x) => x.primaryPosition == true);
    if (roleAlldata.length == 1) {
      this.roleId = this.defaultroleId;
    } else {
      this.roleId = 0;
    }
    let GenData =
      this.generalDataemit == '' ? this.wagesUserId : this.generalDataemit;
    if (GenData > 0 && GenData != undefined) {
      this.permissionItem.emit(this.pemissionRoleValue);
      this.roleItem.emit(this.roleId);
    }

    let response = await this.onJobValidation(this.WagesForm.value, 2);

    return response;
  }

  async onJobValidation(employee, sectionId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.generalDataemit = this.helper.getValue('GenVal');

      if (this.generalDataemit == undefined || this.generalDataemit == null) {
        this.generalDataemit = '';
      }
      if (sectionId == 2) {
        employee.userId = this.generalDataemit
          ? this.generalDataemit
          : this.tableRowId;
        this.wagesUserId = employee.userId;
        employee.sectionId = 2;
        employee.roleId = this.roleId > 0 ? this.roleId : 0;
        employee.createdby = this.loginInfo.userId; //login userid
        employee.tenantId = this.loginInfo.tenantId;
        employee.employeeAnnualSalary = employee.employeeAnnualSalary
          ? employee.employeeAnnualSalary
          : null;
        employee.employeeFixedCost = employee.employeeFixedCost
          ? employee.employeeFixedCost
          : null;
        employee.primaryPosition = employee.primaryPosition
          ? employee.primaryPosition
          : null;

        // if (employee.userId == undefined || employee.userId == null || employee.userId == 0) {
        //   this.toaster.error("Please add General data");
        //   return
        // }
      }
      this.wagesDetails
        .EmployeeRoleValidation(employee)
        .subscribe(async (res) => {
          resolve({ res: res });
        });
    });
  }

  wagesValidation() {
    this.empJobPositionList = [];
    this.pemissionRoleValue = [];
    this.submittedWages = true;

    if (this.WagesForm.invalid) {
      return;
    }
    let wagesGroup = this.WagesForm;
    let dynControl = wagesGroup.controls[
      'employeeJobPositionList'
    ] as FormArray;
    this.roleList = [];
    for (let i = 0; i < dynControl.length; i++) {
      let formGroup2 = dynControl.controls[i].value as FormGroup;
      if (formGroup2['primaryPosition'] == '') {
        this.WagesForm.controls['employeeJobPositionList'].value[
          i
        ].primaryPosition = false;
      } else {
        this.WagesForm.controls['employeeJobPositionList'].value[
          i
        ].primaryPosition = true;
      }
      let PrimaryRadio = {
        value: formGroup2['empPositionId'],
        primaryPosition:
          this.WagesForm.controls['employeeJobPositionList'].value[i]
            .primaryPosition,
      };
      this.roleList.push(PrimaryRadio);
      this.checkPositionValue = this.roleList[i].primaryPosition;
      if (this.checkPositionValue == true) {
        this.defaultroleId = formGroup2['empPositionId'];
        this.roleId = this.defaultroleId;
      }
      let rolename;
      rolename = this.rolePosition.find(
        (x) => x.roleId == formGroup2['empPositionId']
      );
      if (rolename != undefined && rolename != null && rolename != '') {
        let name = {};
        name = rolename.roleName;
        let rolePermission = {
          value: formGroup2['empPositionId'],
          label: name,
          primaryPosition:
            this.WagesForm.controls['employeeJobPositionList'].value[i]
              .primaryPosition,
        };
        this.pemissionRoleValue.push(rolePermission);
      }
    }
    let roleAlldata = this.roleList.filter((x) => x.primaryPosition == true);
    if (roleAlldata.length == 1) {
      this.roleId = this.defaultroleId;
    } else {
      this.roleId = 0;
    }
    let GenData =
      this.generalDataemit == '' ? this.wagesUserId : this.generalDataemit;
    if (GenData > 0 && GenData != undefined) {
      this.permissionItem.emit(this.pemissionRoleValue);
      this.roleItem.emit(this.roleId);
    }
  }

  onItemChange($event, index) {
    let wagesGroup = this.WagesForm;
    let dynControl = wagesGroup.controls[
      'employeeJobPositionList'
    ] as FormArray;
    this.roleList = [];
    for (let i = 0; i < dynControl.length; i++) {
      let formGroup2 = dynControl.controls[i].value as FormGroup;

      let rolename;
      rolename = this.rolePosition.find(
        (x) => x.roleId == formGroup2['empPositionId']
      );
      if (rolename != undefined && rolename != null && rolename != '') {
        let name = {};
        name = rolename.roleName;

        if (i == index) {
          let rolePermission = {
            value: formGroup2['empPositionId'],
            label: name,
            primaryPosition: $event.target.checked,
          };
          this.roleList.push(rolePermission);
          this.WagesForm.controls['employeeJobPositionList'].value[
            i
          ].primaryPosition = $event.target.checked;
        } else {
          let rolePermission = {
            value: formGroup2['empPositionId'],
            label: name,
            primaryPosition: false,
          };
          this.WagesForm.controls['employeeJobPositionList'].value[
            i
          ].primaryPosition = false;
          this.roleList.push(rolePermission);
        }
      }
    }
  }

  onChangeRolePosition() {
    const employee: IGetRoleID = {
      id: 0,
      tenantId: this.loginInfo.tenantId,
    };
    this.adminDetails
      .GetAllRoles(employee)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        this.rolePosition = response.filter((x) => x.isActive == true);
      }),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');
  }

  getEmployee(empid) {
    this.pemissionRoleValue = [];
    this.tempRoleListSelect = [];
    this.defaultIndicator = 1;
    this.userId = empid;
    const employee: GetEmployee = {
      employeeId: empid,
    };
    this.wagesDetails
      .GetEmployee(employee)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        if (
          response[0] != undefined &&
          response[0] != null &&
          response[0] != ''
        ) {
          this.jobPositionarray = response[0].employeeJobPositionList;
          this.defaultroleId = response[0].primaryRoleId;
          this.roleId = response[0].primaryRoleId;
          const dynFormArray = this.WagesForm.controls[
            'employeeJobPositionList'
          ] as FormArray;
          dynFormArray.controls = [];
          if (
            this.jobPositionarray != undefined &&
            this.jobPositionarray != null &&
            this.jobPositionarray != '' &&
            this.jobPositionarray != '[]'
          ) {
            if (this.jobPositionarray.length > 0) {
              for (let j = 0; j < this.jobPositionarray.length; j++) {
                dynFormArray.push(this.addUserFormGroup());
                let rolename;
                rolename = this.rolePosition.find(
                  (x) => x.roleId == this.jobPositionarray[j].empPositionId
                );
                if (
                  rolename != undefined &&
                  rolename != null &&
                  rolename != ''
                ) {
                  let name = {};
                  name = rolename.roleName;
                  let rolePermission = {
                    value: this.jobPositionarray[j].empPositionId,
                    label: name,
                    primaryPosition: this.jobPositionarray[j].primaryPosition,
                  };
                  this.pemissionRoleValue.push(rolePermission);
                  let RemovePermissionAdd = {
                    id: rolePermission.value,
                    name: rolePermission.label,
                    index: j,
                  };
                  this.tempRoleListSelect.push(RemovePermissionAdd);
                }
              }
              this.getPermissionItem.emit(this.pemissionRoleValue);
              this.getroleItem.emit(this.roleId);
            }
          } else {
            dynFormArray.push(this.addUserFormGroup());
            this.getPermissionItem.emit(this.pemissionRoleValue);
            this.getroleItem.emit(this.roleId);
          }
          console.log(this.tempRoleListSelect, 'this.tempRoleListSelect');

          this.WagesForm.patchValue(response[0]);

          this.validDateWagesRole.emit(
            response[0].employeeJobPositionList.length
          );

          console.log(this.isWagesPermission, 'this.isWagesPermission ')
          if (this.isWagesPermission == true) {
            this.WagesForm.patchValue({
              employeeAnnualSalary: "****",
              employeeFixedCost: "****",
            });

            this.WagesForm.controls['salaried'].disable();
            const dynFormArray = this.WagesForm.controls[
              'employeeJobPositionList'
            ] as FormArray;
            for (let i = 0; i < dynFormArray.length; i++) {
              dynFormArray.controls[i].patchValue({
                empWage: "****",
                empOtCharges: "****",
              });
            }
          }
        }
      }),
      (err) => console.error('Error', err),
      () => console.log('HTTP request completed.');

    this.throwError = false;
  }

  onDDLClickItem(eve, ind) {
    //console.log("onDDLClickItem_eve",eve);
    //console.log("onDDLClickItem_ind",ind);
    this.roleList = [];
    const dynFormArray = this.WagesForm.controls[
      'employeeJobPositionList'
    ] as FormArray;
    for (let i = 0; i < dynFormArray.length; i++) {
      let formGroup2 = dynFormArray.controls[i].value as FormGroup;
      this.mainRole = this.rolePosition.find(
        (x) => x.roleId == formGroup2['empPositionId']
      );
      let rolename;
      rolename = this.rolePosition.find(
        (x) => x.roleId == formGroup2['empPositionId']
      );
      if (rolename != undefined && rolename != null && rolename != '') {
        let name = {};
        name = rolename.roleName;
        let rolePermission = {
          value: formGroup2['empPositionId'],
          label: name,
          primaryPosition:
            formGroup2['primaryPosition'] == ''
              ? false
              : formGroup2['primaryPosition'],
        };
        this.roleList.push(rolePermission);
      }
    }
  }
  EmpWageValid() {
    if (this.WagesForm.invalid) {
      this.isInvalidWage.emit(true);
      this.requiredError = true;
      return;
    } else if (this.msgFound !== undefined && this.msgFound.index != null) {
      this.isInvalidWage.emit(true);
      return;
    } else {
      this.requiredError = false;
      this.isInvalidWage.emit(false);
    }
  }

  Highlight() {
    if (this.WagesForm.invalid) {
      this.isInvalidWage.emit(true);
    } else if (this.msgFound !== undefined && this.msgFound.index != null) {
      this.isInvalidWage.emit(true);
    } else {
      this.isInvalidWage.emit(false);
    }
  }

  Highlight2(e) {
    if (this.WagesForm.invalid) {
      if (e == true) {
        this.isInvalidWage.emit(false);
      } else {
        this.isInvalidWage.emit(true);
      }
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.WagesForm.patchValue(this.helper.getValue('GetSessionData2'));
    this.StoreempwageData_child.emit(this.WagesForm.value);


  }

  onchange(data) {
    this.helper.setValue('GetSessionData2', data);
    this.helper.setValue('OnDirty2', true);
    this.StoreempwageData_child.emit({
      data: data,
      event: 'GeneralPage',
      val: true,
    });
  }
}
