<div class="row top-sec">
  <div class="col-sm-4">
    <div>
      <div class="flip-table" *ngIf="!modifierItemsData">
        <span class="text-box">{{employee}}</span> &nbsp;
        <button class="plus_btn outshadow" (click)="addEmployee()"><i class="fa fa-plus"></i></button>
      </div>
      <hr class="linebar" *ngIf="!modifierItemsData">
    </div>
  </div>
  <div class="col-sm-8">
    <div class="form-group global-form">
      <button [ngClass]="{'submit_button_error': isInvalid==true}"
        class="btn  btn-sm float-right submit_button global_submit" type="submit" (click)="OnSave();">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label></button>
    </div>
  </div>
</div>
<div class="row second-sec">
  <div class="col-sm-4">
    <div *ngIf="tableEnable">
      <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns"
        [dealerItem]="tableRowColor" (tableItemId)="getTableIdx($event)" [DataCom]="employee"
        (tableEmpName)="getTableName($event)" [storeColour]="tableRowColor" (clearDetails)="addEmployee()"
        (breakActive)="checkValue($event)" (btnActive)="btnActiveStatus($event)"
        [EmployeeTableData]="EmployeeTableData">
      </app-fliptable>
    </div>
  </div>
  <div class="col-sm-8">
    <!-- <div class="form-group global-form">
      <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global_submit" type="submit" (click)="OnSave();">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label></button>
    </div> -->
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst" [expanded]="isFirst" class="outshadow">
        <mat-expansion-panel-header [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
          <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}} </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="item == 'General'">
          <app-employee-general-break (isInvalid)="isInvalidAccordion($event)" (breakItem)="getbreakDetail($event)"
            [DataIdx]="tableRowId" [checkTarget]="activeDeBreakValue" [buttonState]="buttonDisable"
            (empgenbrk_child)="getbrksessData($event)">
          </app-employee-general-break>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>