import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetDevice } from 'src/app/interfaces/idevices';
import { GetSaleAreaGeneralDD } from 'src/app/interfaces/imenu';
import { DeviceService } from 'src/app/services/devices.service';
import { DialogService } from 'src/app/services/dialog.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-receipt-config-general',
  templateUrl: './receipt-config-general.component.html',
  styleUrls: ['./receipt-config-general.component.css']
})
export class ReceiptConfigGeneralComponent implements OnInit {
  configGeneralForm: FormGroup;
  buttonDisable: boolean = false;
  public SaleAreaGenDD: GetSaleAreaGeneralDD[];
  id: any = 0;
  isActive = false;
  loginInfo: any;
  submittedGeneral: any;
  modifierSize: any;
  modifierColor: any;
  mainItemSize: any;
  headerfontname: any;
  bodyfontname: any;
  footerfontname: any;
  printSettings: IDropdownSettings = {};
  errorValue: any;
  requiredError: boolean = false;
  @Output() public isInvalid = new EventEmitter();
  @Output() public receiptData_child = new EventEmitter();
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }
  selectedItems = [];
  PrintFilters = [];
  assignedPrinters: FormControl = new FormControl('');
  selectedAlign: any;

  //@Input() public buttonState;
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set DataIdx(tableRowId) {
    this.errorValue = "";
    tableRowId = tableRowId == null ? 0 : tableRowId;
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.id = tableRowId;
      this.GetReceiptDetails(tableRowId);
    }
  }

  @Output() public GeneralDetailReceipt = new EventEmitter();
  constructor(private formBuilder: FormBuilder, private obj: DeviceService, private objPrint: DeviceService,
    private objItem: MenuService, private helper: HelperService, private toaster: ToasterService, private dialogser: DialogService) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.configGeneralForm = this.formBuilder.group({
      name: ['', Validators.required],
      modifierSize: [''],
      modifierColor: [''],
      mainItemSize: [''],
      date: [false],
      employee: [false],
      salesArea: [false],
      time: [false],
      customer: [false],
      customerAddress: [false],
      customerPhone: [false],
      customerOrder: [false],
      customerCheck: [false],
      customerTable: [false],
      promiseTime: [false],
      headercustomTextBox: [''],
      footercustomTextBox: [''],
      headerFont: [''],
      headerSize: [''],
      footerFont: [''],
      footerSize: [''],
      bodyFont: [''],
      bodySize: [''],
      pointUsed: [false],
      accountBalance: [false],
      showInclusiveTax: [false],
      qr_Code: [false],
      surchargeClause: [false],
      textReceipt: [false],
      emailReceipt: [false],
      noModifiers: [false],
      bySeat: [false],
      showModPrice: [false],
      consolidate: [false],
      serverName: [false],
      topmargin: [null],
      bottommargin: [null],

      PrintFilters: [null],
      selectedItems: [null],
      printerOption:[''],
      assignedPrinters: new FormControl(''),
    })
    this.getFontName();
    this.getAssignedPrintername();
    this.printSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 1,
      selectAllText: 'Select All',
      unSelectAllText: 'Select All'
    };
    this.selectedAlign = 'center';
    this.configGeneralForm.value.footerSize=11;
    this.configGeneralForm.value.headerSize=11;
    this.configGeneralForm.patchValue({footerSize: 11,});
    this.configGeneralForm.patchValue({headerSize: 11,});
    this.configGeneralForm.patchValue({bodySize: 11,});
  }

  get configGenData() { return this.configGeneralForm.controls; }

  getFontName() {
    const menuItemList: GetSaleAreaGeneralDD = {
      parentCode: "FT1",
    }
    this.objItem.GetSalesAreaTicketGeneral(menuItemList)
      .subscribe((response) => { this.headerfontname = response; this.bodyfontname = response; this.footerfontname = response; })
  }

  checkInput(e, input) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];

    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue)) {
      e.preventDefault();
      return;
    }

    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if (+newValue > 100 || newValue.length > 3) {
      e.preventDefault();
    }
  }

  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }

  onSaveGeneral() {
    this.errorValue = "";
    this.submittedGeneral = true;
    if (this.configGeneralForm.invalid) {
      return;
    }

    this.configGeneralForm.value.id = this.id;
    this.configGeneralForm.value.isActive = true;
    this.configGeneralForm.value.tenantId = this.loginInfo.tenantId;
    this.configGeneralForm.value.createdBy = this.loginInfo.userId;
    this.configGeneralForm.value.modifiedBy = this.loginInfo.userId;
    this.configGeneralForm.value.bodySize = this.configGeneralForm.value.bodySize.toString();
    this.configGeneralForm.value.headerSize = this.configGeneralForm.value.headerSize.toString();

    this.configGeneralForm.value.footerSize = this.configGeneralForm.value.footerSize.toString();
    //this.configGeneralForm.value.headerSize = this.configGeneralForm.value.headerSize.toString();
    //const itm = this.configGeneralForm.value;
    // if (itm.printReceipt == true) {
    //   this.configGeneralForm.value.printReceipt = "true";
    // }
    // else if (itm.printReceipt == false){
    //   this.configGeneralForm.value.printReceipt = "false";
    // }

    // if (this.selectedItems != null) {
    //   var ArrayPrinter = [];
    //   var clientsSalesArea = this.selectedItems;
    //   for (var a = 0, len = clientsSalesArea.length; a < len; a++) {
    //     ArrayPrinter.push(clientsSalesArea[a].key);
    //   }
    //   this.configGeneralForm.value.assignedPrinters = ArrayPrinter.toString();
    // }
    // this.configGeneralForm.value.assignedPrinters = JSON.stringify(this.selectedItems);

    if (this.selectedItems == null) {
      this.configGeneralForm.value.assignedPrinters = "[]"
    } else {
      this.configGeneralForm.value.assignedPrinters = JSON.stringify(this.selectedItems);
    }

    this.obj.ReceiptDeviceGeneralSave(this.configGeneralForm.value).subscribe(
      async (response) => {
        this.id = response.id;
        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
          this.helper.setValue('GetSessionData1', this.configGeneralForm.value);
          this.receiptData_child.emit({ data: this.configGeneralForm.value, event: "receipt", val: false });
        }
        this.submittedGeneral = false;
        this.GeneralDetailReceipt.emit({ res: response, name: this.configGeneralForm.value.name });
        await this.dialogser.ClearSession();
      })
  }

  GetReceiptDetails(rowId) {
    const deviceList: GetDevice = {
      id: rowId,
      tenantId: this.loginInfo.tenantId
    }
    this.obj.ReceiptDeviceGet(deviceList)
      .subscribe(res => {
        let recVal = res[0];
        this.configGeneralForm.patchValue(recVal);

        if (recVal.ModifierSize == "small") {
          this.modifierSize = "small";
        }
        else if (recVal.ModifierSize == "large") {
          this.modifierSize = "large";
        }

        if (recVal.ModifierColor == "white") {
          this.modifierColor = "white";
        }
        else if (recVal.ModifierColor == "black") {
          this.modifierColor = "black";
        }

        if (recVal.MainItemSize == "itemsmall") {
          this.mainItemSize = "itemsmall";
        }
        else if (recVal.MainItemSize == "itemlarge") {
          this.mainItemSize = "itemlarge";
        }

        //  var salesAreastr = res[0].assignedPrinters;
        // if (salesAreastr != null && salesAreastr != '') {
        //   var salesAreatemp = new Array();
        //   salesAreatemp = salesAreastr.split(",");
        //   this.assignedPrinters = new FormControl(salesAreatemp);
        // }
        // else {
        //   this.assignedPrinters = new FormControl('');
        // }
        //------------Assign Printer group--------

        var jsonPrinter = res[0].assignedPrinters;
        if (jsonPrinter != null && jsonPrinter != '') {
          this.selectedItems = JSON.parse(jsonPrinter);
        }
        else {
          this.selectedItems = [];
        }
      })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAssignedPrintername() {
    const Active: GetDevice = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.objPrint.PrinterDeviceGet(Active).subscribe(
      (response) => {
        var jsonPrinter = response;
        var itemPrinter
        var jsonToBeUsedPrinter = [];
        for (var type in jsonPrinter) {
          itemPrinter = {};
          itemPrinter.key = jsonPrinter[type].id.toString();
          itemPrinter.value = jsonPrinter[type].name;
          jsonToBeUsedPrinter.push(itemPrinter);
        }
        this.PrintFilters = jsonToBeUsedPrinter;
      })
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  ReceiptValid() {
    if (this.configGeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }
    else {
      this.requiredError = false;
    }
  }


  Highlight() {
    if (!this.configGeneralForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.receiptData_child.emit(this.configGeneralForm.value)
    this.configGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.receiptData_child.emit({ data: data, event: "receipt", val: true });
  }

  alignText(e) {
    this.selectedAlign = e;
  }

  // isFormPageDirty() {
  //   let d = this.configGeneralForm.dirty;
  //   this.helper.setValue('GetSessionData1', this.configGeneralForm.value);
  //   if (d == true) {
  //     this.helper.setValue('OnDirty', true);
  //   }
  //   return d;
  // }
}
