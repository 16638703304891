import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { GetDevice } from 'src/app/interfaces/idevices';
import { TagsService } from 'src/app/services/tags.service';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-tag-print-printer',
  templateUrl: './tag-print-printer.component.html',
  styleUrls: ['./tag-print-printer.component.css']
})
export class TagPrintPrinterComponent implements OnInit {
  public table_render: boolean = true;
  public displayedColumns = [];
  public columns = [];
  public dataSource = new MatTableDataSource<any>();
  @Input() tagsID
  loginInfo: any;
  PrinterListData: any = [];
  public FinalPrinterAssigList = [];
  tags_id: any = 0;
  // @Input() PrinterListData
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>;
  @Output() public generalItem = new EventEmitter();
  constructor(private changeDetector: ChangeDetectorRef, private tagService: TagsService, private helper: HelperService) { }

  ngOnInit(): void {
    this.loginInfo = this.helper.getValue('LoginInfo');
  }

  // @Input()
  // public set tag_id(tableRowId) {
  //   if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
  //     this.tags_id = tableRowId;
  //     // this.getPrinterList(this.tags_id)
  //   }
  // }

  drop(event) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        // event.previousContainer.data,
        event.previousContainer.data.filteredData,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.table_render = false
      this.changeDetector.detectChanges();
      this.table_render = true
    }
    this.FinalPrinterAssigList = event.container.data;
    this.generalItem.emit(this.FinalPrinterAssigList);
  }
  getTableValue(e) {
    this.FinalPrinterAssigList = e
    this.generalItem.emit(this.FinalPrinterAssigList);
  }

  // getPrinterList(tableRowId) {
  //   const printerList: GetDevice = {
  //     id: tableRowId,
  //     tenantId: this.loginInfo.tenantId
  //   }
  //   this.tagService.SelectAvailablePrinter(printerList)
  //     .subscribe(res => {
  //       this.dataSource.data = res;
  //       this.displayedColumns = ['name'];
  //       this.columns = [
  //         { columnDef: 'name', header: 'Printers Available' }
  //       ];
  //     })
  // }
  @Input()
  public set DataPrinterList(PrinterList) {
    this.PrinterListData = PrinterList;
  }

  @Input()
  public set getrefreshprintval(e) {
    if (e == "refreshjp") {
      this.PrinterListData = [];
    }
  }
}
