<div class="form-group global-form">
    <button [ngClass]="{'submit_button_error': isInvalid==true}"
        class="btn btn-sm float-right submit_button global-submit" type="submit" (click)="OnSave();">
        <!-- [disabled]="buttonDis ? true : null" -->
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label>
    </button>
</div>
<mat-accordion>
    <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst" [expanded]="isFirst" class="outshadow">
        <mat-expansion-panel-header [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
            <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}}
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="item == 'General'">
            <app-menu-group-general [GroupGeneral]="Groupdata" [EditGroupdata]="GroupEdit"
                (generalItem)="getGeneralDetail($event)" [buttonDis]="buttonDisableVal"
                (isInvalid)="isInvalidAccordion($event)" (mnugrpData_subchild)="getmnugrpsessData($event)">
            </app-menu-group-general>
        </div>
        <!-- <div *ngIf="item == 'Layout'">
            <app-menu-group-layout [GroupGeneral]="Groupdata" [salesAreabit]="salesAreabit"></app-menu-group-layout>
        </div> -->
    </mat-expansion-panel>
</mat-accordion>