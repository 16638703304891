import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { iSalesReconsilation } from 'src/app/interfaces/i-report';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
export interface PeriodicElement {
  id?: number;
  grosssales?: any;
  data?: any;
  data1?: any;
  data2?: any;
  data3?: any;
  adjustmenttogross?: any;
  additionalincome?: any;
  cashdetail?: any;
}
const ELEMENT_DATA1: PeriodicElement[] = [
  { id: 1, grosssales: 'Beer', data: 425 },
  { id: 2, grosssales: 'Liquor', data: 425 },
  { id: 3, grosssales: 'Merchandise', data: 425 },
  { id: 4, grosssales: 'Gift Card Sold', data: 425 },
  { id: 5, grosssales: 'Positive Adjustments', data: 425 },
  { id: 5, grosssales: 'Donations Received', data: 425 },
];
const ELEMENT_DATA2: PeriodicElement[] = [
  { id: 1, adjustmenttogross: 'Auto Adjustments', data1: 425 },
  { id: 2, adjustmenttogross: 'Donations Received', data1: 425 },
  { id: 3, adjustmenttogross: 'Customer Loyalty', data1: 425 },
];
const ELEMENT_DATA3: PeriodicElement[] = [
  { id: 1, additionalincome: 'Room Fees', data2: 0 },
  { id: 2, additionalincome: 'Service Charges', data2: 0 },
  { id: 3, additionalincome: 'Inclusive Taxes', data2: 0 },
  { id: 4, additionalincome: 'Non Inclusive taxes', data2: 0 },
  { id: 5, additionalincome: 'Tips In', data2: 0 },
];
const ELEMENT_DATA4: PeriodicElement[] = [
  { id: 1, cashdetail: 'Cash Paid In', data3: 0 },
  { id: 2, cashdetail: 'Cash Paid Out', data3: 0 },
  { id: 3, cashdetail: 'Cash Server Banks', data3: 0 },
  { id: 4, cashdetail: 'Void', data3: 0 },
  { id: 5, cashdetail: 'Tills', data3: 0 },
  { id: 5, cashdetail: 'Tips Out', data3: 0 },
];

@Component({
  selector: 'app-sales-reconciliation',
  templateUrl: './sales-reconciliation.component.html',
  styleUrls: ['./sales-reconciliation.component.css'],
})
export class SalesReconciliationComponent implements OnInit {
  salesReconciliationForm: FormGroup;
  public employeeName: any;
  public employee: any;
  loginInfo: any;
  public employeeIcon: any;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  public displayedColumns1 = [];
  public displayedColumns2 = [];
  public displayedColumns3 = [];
  public displayedColumns4 = [];
  currentDate: any;

  grossSaledata: any = [];
  cashSaledata: any = [];
  adjustmentToGross = [];
  cashDrawerDetails = [];
  columns1 = [];
  columns2 = [];
  columns3 = [];
  columns4 = [];
  public dataSource1: any;
  expectedDisposite: any;
  public dataSource2: any;
  currentDateAdd: any;
  public dataSource3: any;
  public dataSource4: any;
  fourthTableHide = false;
  reportsColor: boolean = false;
  secreportsColor: boolean = false;
  @Output() public salrecsessData = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private toaster: ToasterService,
    private reportService: ReportWebSocketService,
    private helper: HelperService
  ) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', 'this.salesReconcial');
    let date: Date = new Date();
    this.currentDate = formatDate(
      date.setDate(date.getDate() - 1),
      'MM/dd/yyyy',
      'en'
    );

    this.currentDateAdd = formatDate(
      date.setDate(date.getDate() + 1),
      'MM/dd/yyyy',
      'en'
    );
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = 'Sales Reconciliation';
    this.employee = 'Reports - Sales';
    this.employeeIcon = 'assets/components/images/reports-icon.png';
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
    this.salesReconciliationForm = this.formBuilder.group({
      SearchDate: [''],
      actualAmount: [null],
      reconcileDate: [null],

      reconsileData: this.formBuilder.array([
        this.formBuilder.group({
          category: [null],
          reconcileParameter: [null],
          reconcileAmount: [null],
        }),
      ]),
    });
    this.GetGrossSale(this.currentDate);

    this.getThirdTable();

  }

  SaveReconsile() {
    console.log(
      this.salesReconciliationForm.value,
      ' this.salesReconciliationForm'
    );
    let salesData;
    salesData = this.salesReconciliationForm.value;
    salesData.expostedDeposit = 877;
    salesData.overShort = 9888;
    salesData.reconcileDate = '2023-03-04';
    salesData.tenantid = this.loginInfo.tenantId;
    salesData.createdBy = this.loginInfo.userId;

    console.log(salesData, ' this.salesReconciliationForm');
    this.reportService.SaveSalesReconsilationData(salesData).subscribe((res) => {
      this.toaster.successPopUp(res.message);
    });
  }

  GetGrossSale(reconsileDate?) {
    const _salesData: iSalesReconsilation = {
      fromDate: reconsileDate,
      toDate: null,
      tenantId: this.loginInfo.tenantId,
    };

    //console.log(_salesData, '_salesData');
    this.reportService.SelectSalesReconsilation(_salesData).subscribe((res) => {
      this.grossSaledata = res.GrossSales;
      this.cashSaledata = ELEMENT_DATA4;
      this.adjustmentToGross = res.AdjustmentToGross;
      this.cashDrawerDetails = res.CashDrawerDetail;
      //  console.log(res, 'res');

      let TotalGrossSale: number = 0;
      let dynControl = this.salesReconciliationForm.controls[
        'reconsileData'
      ] as FormArray;

      console.log(this.grossSaledata, 'grossSaledata')
      for (let i = 0; i < this.grossSaledata.length; i++) {
        dynControl.push(
          this.addFormGroup(
            'Gross Sales',
            this.grossSaledata[i].grosssales,
            this.grossSaledata[i].data
          )
        );
        TotalGrossSale = TotalGrossSale + Number(this.grossSaledata[i].data)
      }
      let TotalAdjustGrossSale: number = 0;
      for (let i = 0; i < this.adjustmentToGross.length; i++) {
        dynControl.push(
          this.addFormGroup(
            'Adjustment To Gross',
            this.adjustmentToGross[i].adjustmenttogross,
            this.adjustmentToGross[i].data1
          )
        );
        TotalAdjustGrossSale = TotalAdjustGrossSale + Number(this.adjustmentToGross[i].data1)
      }
      let TotalAdjustNetsSale: number = 0;
      for (let i = 0; i < this.cashSaledata.length; i++) {
        dynControl.push(
          this.addFormGroup(
            'Adjustment To Net',
            this.cashSaledata[i].cashdetail,
            this.cashSaledata[i].data3
          )
        );
        TotalAdjustNetsSale = TotalAdjustNetsSale + Number(this.cashSaledata[i].data3)
      }
      let TotalCashDrawer: number = 0;
      for (let i = 0; i < this.cashDrawerDetails.length; i++) {
        dynControl.push(
          this.addFormGroup(
            'Cash Drawer Detail',
            this.cashDrawerDetails[i].name,
            this.cashDrawerDetails[i].data
          )
        );
        TotalCashDrawer = TotalCashDrawer + Number(this.cashDrawerDetails[i].data)
      }

      this.expectedDisposite = TotalCashDrawer + TotalAdjustNetsSale + TotalAdjustGrossSale + TotalGrossSale;

      this.dataSource1 = this.grossSaledata;
      this.GetCashSale();
      this.getAdjustmentToGross();
    });
    this.displayedColumns1 = ['grosssales', 'data'];

    this.columns1 = [
      { columnDef: 'grosssales', header: 'Gross Sales' },
      { columnDef: 'data', header: '' },
    ];
  }

  addFormGroup(categoryVal, reconcileParameterVal, amount): FormGroup {
    return this.formBuilder.group({
      category: categoryVal,
      reconcileParameter: reconcileParameterVal,
      reconcileAmount: amount.toString()

    });
  }

  getAdjustmentToGross() {
    this.displayedColumns2 = ['adjustmenttogross', 'data1'];
    this.dataSource2 = this.adjustmentToGross;
    this.columns2 = [
      { columnDef: 'adjustmenttogross', header: 'Adjustment To Gross' },
      { columnDef: 'data1', header: '' },
    ];
  }

  getThirdTable() {
    this.displayedColumns3 = ['additionalincome', 'data2'];
    this.dataSource3 = ELEMENT_DATA3;
    this.columns3 = [
      { columnDef: 'additionalincome', header: 'Adjustment To Net' },
      { columnDef: 'data2', header: '' },
    ];
  }

  GetCashSale() {
    this.fourthTableHide = true;
    this.displayedColumns4 = ['name', 'data'];
    this.dataSource4 = this.cashDrawerDetails;
    this.columns4 = [
      { columnDef: 'name', header: 'Cash Drawer Detail' },
      { columnDef: 'data', header: '' },
    ];
  }

  changeTableItem(e, i) {
    let date: Date = new Date();
    if (i == 1) {
      this.reportsColor = true;
      this.secreportsColor = false;


    } else {
      this.secreportsColor = true;
      this.reportsColor = false;

      this.GetGrossSale(formatDate(date.setDate(date.getDate() + 1),
        'yyyy-MM-dd',
        'en'
      ));
      this.currentDate = null;
      this.currentDate = formatDate(
        date.setDate(date.getDate() + 1),
        'MM/dd/yyyy',
        'en'
      );
      this.getThirdTable();
    }
  }

  ngAfterViewInit() {
    this.salesReconciliationForm.patchValue(
      this.helper.getValue('GetSessionData1')
    );
    this.salrecsessData.emit(this.salesReconciliationForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.salrecsessData.emit({
      data: data,
      event: 'salesReconcial',
      val: true,
    });
  }
}
