import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { ISelectReasonDetails, IActivateReasonDetails } from 'src/app/interfaces/ireason';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { ReasonsGeneralComponent } from '../reasons-general/reasons-general.component';
import { ReasonService } from 'src/app/services/reason';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-reasons',
  templateUrl: './reasons.component.html',
  styleUrls: ['./reasons.component.css']
})
export class ReasonsComponent implements OnInit {
  private buttonDisable: boolean;
  loginInfo: any;
  public displayedColumns = [];
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  public EmployeeTableData: any;
  public TabletData: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public activeDeactiveValue: any;
  isInvalid: boolean = false;
  public modifierItemsData: any;

  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public ReasonsessData = new EventEmitter();

  //@Input() public buttonDisable;
  public tableRowColor: any;
  public tableRowId: any;
  iconevent: any;
  @Output() public clickIcon = new EventEmitter();
  @ViewChild(ReasonsGeneralComponent) reasonsGeneral: ReasonsGeneralComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  constructor(private helper: HelperService, private toaster: ToasterService, private reason: ReasonService,
    private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.reasons");

    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Store-Reasons";
    this.employee = "Store-Reasons";
    this.employeeIcon = "assets/components/images/tag.png";
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon);
    if (this.loginInfo.tenantId == 0) {
      this.toaster.error("Please Select store");
      this.buttonDisable = true;
    } else {
      this.getReasonList()
    }
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  getReasonList() {
    const deviceList: ISelectReasonDetails = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.reason.SelectReasons(deviceList)
      .subscribe(res => {
        this.dataSource.data = res;
        this.EmployeeTableData = res;
        this.TabletData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Reason Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  async getTableIdx(event) {
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("reasons");
      if (conf == 'success') { // Navigate Away
        this.dialogser.ClearSession();
        this.reasonsGeneral.reasonsGeneralForm.reset();

        //General Data
        this.helper.setValue('Sessionid_help', event)
        this.reasonsGeneral.errorValue = "";
        this.tableRowId = event;
        this.tableRowColor = event;
        this.isInvalid = false;
        this.reasonsGeneral.requiredErrors = false;
        this.flipcomp.rowClicked = event;
        this.dialogser.ModifiedId = event;
      }
      else // Cancel
      {
        //General Data
        this.reasonsGeneral.reasonsGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.ReasonsessData.emit({
          data: this.reasonsGeneral.reasonsGeneralForm.value, event: "reasons", val: true,
          itmname: this.reasonsGeneral.reasonsGeneralForm.value.name
        });
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event)
      this.reasonsGeneral.errorValue = "";
      this.tableRowId = event;
      this.tableRowColor = event;
      this.isInvalid = false;
      this.reasonsGeneral.requiredErrors = false;
      //  this.getTagtList(event);
    }
  }

  getTableName(name) {
    this.employeeName = name;
    //this.employee = "Gift-Card";
    //this.employeeIcon = "assets/components/images/gift-card.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  async addReasons() {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    }
    else {
      var conf = await this.dialogser.confirm("reasons");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
      }
      else {
        this.reasonsGeneral.reasonsGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.reasonsGeneral.errorValue = "";
    this.reasonsGeneral.requiredErrors = false;
    this.isInvalid = false;
    this.employeeName = "Store-Reasons";
    this.tableRowColor = 'transparent';
    this.flipcomp.selectTableRowColor(0, 0, this.employeeName);
    this.reasonsGeneral.reasonsGeneralForm.reset();
    this.reasonsGeneral.ngOnInit();
    this.reasonsGeneral.paidIn = false;
    this.reasonsGeneral.paidOut = false;
    this.reasonsGeneral.refund = false;
    this.reasonsGeneral.voids = false;
    this.reasonsGeneral.voidrefundFilters = [];
    this.reasonsGeneral.securityFilters = [];
    this.reasonsGeneral.voidrefundItems = [];
    this.reasonsGeneral.securityItems = [];
    this.reasonsGeneral.submittedGeneral = false;
    this.iconevent = ""
    //  this.getIcon(this.iconevent);
    this.tableRowId = 0;
    this.reasonsGeneral.onImgChange();
    this.titleName.emit(this.employeeName)
  }

  // getIcon(e){
  //   // this.iconevent = e
  //  this.clickIcon.emit(e)
  // }

  getGeneralDetail(event) {
    this.tableRowId = event.res.id;
    this.getReasonList();
    //this.addReasons()
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name)
  }

  ActivateDeactivate(event) {
    this.activeDeactiveValue = event
    let currentTarget = event.e.currentTarget.checked
    const active: IActivateReasonDetails = {
      userId: this.loginInfo.userId,
      id: event.id,
      isActive: event.e.currentTarget.checked
    }
    this.reason.ActivateReasonDetails(active).subscribe(
      (response) => {
        this.addReasons();
        if (currentTarget) {
          this.toaster.successPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
        }
        this.getReasonList();
      },
    )
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  onSaveGeneral() {
    this.reasonsGeneral.onSaveGeneral();
    if (this.reasonsGeneral.reasonsGeneralForm.invalid) {
      if ((this.reasonsGeneral.reasonsGeneralForm.value.name == null) || (this.reasonsGeneral.reasonsGeneralForm.value.name == "")) {
        this.isInvalid = true;
      }
      else {
        this.isInvalid = false;
      }
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getReasonsessData(e) {
    this.ReasonsessData.emit(e)
  }
}
