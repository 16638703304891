import { Component, OnInit } from '@angular/core';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { iTimeClockSocket } from 'src/app/interfaces/iWebSocket';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-time-clock-websocket',
  templateUrl: './time-clock-websocket.component.html',
  styleUrls: ['./time-clock-websocket.component.css']
})
export class TimeClockWebsocketComponent implements OnInit {
  employeeName:any;
  roleName:any;
  punchIn:any;
  punchOut:any;
  timeInTime:any;
  timeOutTime:any;
  typeName:any;
  wage:any;
  tips:any;
  imagePath:any;
  clockInImage:any;
  constructor(public ObjService:WebSocketService,private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.ObjService.connect();
   
  }
  // SendInformation(): void {

  //   const TimeClockData: iTimeClockSocket = {
  //     employeeName:"",
  //     roleName:"",
  //     punchIn:"",
  //     punchOut:"any",
  //     timeInTime:"any",
  //     timeOutTime:"any",
  //     typeName:"any",
  //     wage:"any",
  //     tips:"any",
      
  //   }
  //   this.ObjService.sendMessageToHub(TimeClockData).subscribe({
  //     next: _ => this.employeeName = '',
  //     error: (err) => console.error(err)
  //   });
  // }
}
