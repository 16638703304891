<div class="credit_firstData">
    <form [formGroup]="CreditCardVantivForm" class="loginForm">
        <div class="row  mb-2">
            <div class="col-sm-4">
                <div class="card  credit-header ">
                    <div class="card-body p-2">
                        <h6 class="card-text mb-0 ">Vantiv</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Merchant ID</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="merchantId"
                        placeholder="1-16 digits" maxlength="16" (keypress)="numberOnly($event)" appInputRestriction
                        [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.merchantId.errors }"/>
                        <div *ngIf="submittedGeneral && GeneralData.merchantId.errors" class="invalid-feedback">
                            <div *ngIf="GeneralData.merchantId.errors.required">Merchant ID is required</div>
                        </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Terminal ID</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="terminalId" maxlength="6"
                        placeholder="6 digits" [maxLength]="6" (keypress)="numberOnly($event)" appInputRestriction
                        [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.terminalId.errors }"/>
                        <div *ngIf="submittedGeneral && GeneralData.terminalId.errors" class="invalid-feedback">
                            <div *ngIf="GeneralData.terminalId.errors.required">Terminal ID is required</div>
                        </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Merchant BIN</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="merchantBin" 
                    maxlength="8" appInputRestriction
                        placeholder="8 characters/Digits" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.merchantBin.errors }" />
                        <div *ngIf="submittedGeneral && GeneralData.merchantBin.errors" class="invalid-feedback">
                            <div *ngIf="GeneralData.merchantBin.errors.required">Merchant BIN is required</div>
                        </div>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Auto Batch Time</label><label class="text-danger">*</label>
                    <ngx-mat-timepicker-field [controlOnly]="true" formControlName="autoBatchTime" class="ngxMatTime"
                    [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.autoBatchTime.errors }">
                    <!-- <div *ngIf="submittedGeneral && GeneralData.autoBatchTime.errors" class="invalid-feedback">
                        <div *ngIf="GeneralData.autoBatchTime.errors.required">timeIn is required</div>
                    </div> -->
                    </ngx-mat-timepicker-field>
                    <div *ngIf="submittedGeneral && GeneralData.autoBatchTime.errors" class="invalid-feedback">
                        <div *ngIf="GeneralData.autoBatchTime.errors.required">Auto Batch Time is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Store and Forward(EMV only)</label>
                    <div class="checkbox-table accordion-checkbox">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input " name="isStoreandForward_EMVonly"
                                formControlName="isStoreandForward_EMVonly" id="Allowsome">
                            <label class="custom-control-label mb-0"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2 mt-3">
                <div class="form-group">
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="storeValue"
                        placeholder="$0-00" appInputRestriction/>
                </div>
            </div>


        </div>
        <div class="row ">
            <div class="col-sm-6">
                <label class="customlable m-0">#Of</label> <label class="text-danger  req_label">*</label>
                <div class="row">
                    <div class="col-sm-2 col">
                        <div class="form-group">
                            <label class="customlable m-0">fixed</label>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input " name="of_Fixed"
                                        formControlName="of_Fixed" id="Allowsome">
                                    <label class="custom-control-label mb-0"></label>
                                </div>
                            </div>
                            <div *ngIf="submittedGeneral && GeneralData.of_Fixed.errors" class="invalid-feedback">
                                <div *ngIf="GeneralData.of_Fixed.errors.required">fixed is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col">
                        <div class="form-group">
                            <label class="customlable m-0">Mobile</label>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control item">
                                    <input type="checkbox" class="custom-control-input " name="mobile"
                                        formControlName="mobile" id="Allowsome">
                                    <label class="custom-control-label mb-0"></label>
                                </div>
                            </div>
                            <div *ngIf="submittedGeneral && GeneralData.mobile.errors" class="invalid-feedback">
                                <div *ngIf="GeneralData.mobile.errors.required">Mobile is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <label class="customlable m-0">Total Lanes</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="total_Lanes"
                            [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.total_Lanes.errors }" appInputRestriction/>
                            <div *ngIf="submittedGeneral && GeneralData.total_Lanes.errors" class="invalid-feedback">
                                <div *ngIf="GeneralData.total_Lanes.errors.required">Total Lanes is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row ">
            <div class="col-sm-4 col">
                <label class="customlable m-0">Contactless Capable</label><label
                    class="text-danger  req_label">*</label>
                <div class="form-group Communication_Form">
                    <div class="custom-control custom-radio custom-control item">
                        <input formControlName="contactlessCapable" value="NO" type="radio" id="AreaStyle_capable" name="contactlessCapable"
                            class="custom-control-input" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.contactlessCapable.errors }">
                        <label class="custom-control-label QR" for="AreaStyle_capable">NO</label>
                    </div>
                </div>
                <div class="form-group Communication_Form">
                    <div class="Communication_Form">
                        <div class="custom-control custom-radio custom-control item">
                            <input formControlName="contactlessCapable" value="Yes" type="radio" id="AreaStyle_Contactless"
                                name="contactlessCapable" class="custom-control-input" 
                                [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.contactlessCapable.errors }">
                            <label class="custom-control-label QR" for="AreaStyle_Contactless">YES</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="submittedGeneral && GeneralData.contactlessCapable.errors" class="invalid-feedback">
                    <div *ngIf="GeneralData.contactlessCapable.errors.required">Contactless Capable is required</div>
                </div>
            </div>
            <div class="col-sm-4 col">
                <label class="customlable m-0">Verify SSL</label><label class="text-danger  req_label">*</label>
                <div class="form-group Communication_Form">
                    <div class="custom-control custom-radio custom-control item">
                        <input formControlName="verify_SSL" value="Yes" type="radio" id="AreaStyle_ssl" name="verify_SSL"
                            class="custom-control-input" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.verify_SSL.errors }">
                        <label class="custom-control-label QR" for="AreaStyle_ssl">YES</label>
                    </div>
                </div>
                <div class="form-group Communication_Form">
                    <div class="Communication_Form">
                        <div class="custom-control custom-radio custom-control item">
                            <input formControlName="verify_SSL" value="No" type="radio" id="AreaStyle_ssl2"
                                name="verify_SSL" class="custom-control-input" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.verify_SSL.errors }">
                            <label class="custom-control-label QR" for="AreaStyle_ssl2">NO</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="submittedGeneral && GeneralData.verify_SSL.errors" class="invalid-feedback">
                    <div *ngIf="GeneralData.verify_SSL.errors.required">Verify SSL is required</div>
                </div>
            </div>
            <div class="col-sm-4 col">
                <label class="customlable m-0">Merchant Category</label><label class="text-danger  req_label">*</label>
                <div class="form-group Communication_Form">
                    <div class="custom-control custom-radio custom-control item">
                        <input formControlName="merchantCategory" value="merchant" type="radio" id="AreaStyle_merchant" name="merchantCategory"
                            class="custom-control-input" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.merchantCategory.errors }">
                        <label class="custom-control-label QR" for="AreaStyle_merchant">Retail/Grocery</label>
                    </div>
                </div>
                <div class="form-group Communication_Form">
                    <div class="Communication_Form">
                        <div class="custom-control custom-radio custom-control item">
                            <input formControlName="merchantCategory" value="merchant2" type="radio" id="AreaStyle_merchant2"
                                name="merchantCategory" class="custom-control-input">
                            <label class="custom-control-label QR" for="AreaStyle_merchant2">Restaurant</label>
                        </div>
                    </div>
                </div>
                <div class="form-group Communication_Form">
                    <div class="Communication_Form">
                        <div class="custom-control custom-radio custom-control item">
                            <input formControlName="merchantCategory" value="merchant3" type="radio" id="AreaStyle_merchant3"
                                name="merchantCategory" class="custom-control-input" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.merchantCategory.errors }">
                            <label class="custom-control-label QR" for="AreaStyle_merchant3">Marketiing</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="submittedGeneral && GeneralData.merchantCategory.errors" class="invalid-feedback">
                    <div *ngIf="GeneralData.merchantCategory.errors.required">Merchant Category is required</div>
                </div>
            </div>
        </div>
        <div class="row ">

            <div class="col-sm-6">
                <label class="customlable m-0">Duplicate Checking</label>
                <div class="form-group Communication_Form">
                    <div class="custom-control custom-radio custom-control item">
                        <input formControlName="duplicateChecking" value="Diasabled" type="radio" id="AreaStyle_Diasabled" name="duplicateChecking"
                            class="custom-control-input" >
                        <label class="custom-control-label QR" for="AreaStyle_Diasabled">Diasabled</label>
                    </div>
                </div>
                <div class="form-group Communication_Form">
                    <div class="Communication_Form">
                        <div class="custom-control custom-radio custom-control item">
                            <input formControlName="duplicateChecking" value="Match" type="radio" id="AreaStyle_Match"
                                name="duplicateChecking" class="custom-control-input" >
                            <label class="custom-control-label QR" for="AreaStyle_Match">Match Amount and
                                Acct</label>
                        </div>
                    </div>
                </div>
                <div class="form-group Communication_Form">
                    <div class="Communication_Form">
                        <div class="custom-control custom-radio custom-control item">
                            <input formControlName="duplicateChecking" value="invoice" type="radio" id="AreaStyle_invoice"
                                name="duplicateChecking" class="custom-control-input" >
                            <label class="custom-control-label QR" for="AreaStyle_invoice">Match Amount and Acct, &
                                invoice</label>
                        </div>
                    </div>
                </div>
                <div class="form-group Communication_Form">
                    <div class="Communication_Form">
                        <div class="custom-control custom-radio custom-control item">
                            <input formControlName="duplicateChecking" value="invoiceReturn" type="radio" id="AreaStyle_invoiceReturn"
                                name="duplicateChecking" class="custom-control-input" >
                            <label class="custom-control-label QR" for="AreaStyle_invoiceReturn">Match Amount and Acct, &
                                invoice- Return 200 on match</label>
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="submittedGeneral && GeneralData.duplicateChecking.errors" class="invalid-feedback">
                    <div *ngIf="GeneralData.duplicateChecking.errors.required">Duplicate Checking is required</div>
                </div> -->
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12">
                <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral()">
                        <!-- [disabled]="buttonState ? true : null" -->
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
                </button>
            </div>
        </div>
    </form>
</div>