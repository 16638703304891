<div class="tab-view">
    <div class="row" *ngIf="!modifierItemsData">
        <div class="col-sm-7 col-7 fliptable-search" fxLayout>
            <label class="matemployeelabel">
        Search: </label>
            <mat-form-field>
                <input [(ngModel)]="searchField" #filter matInput type="text" class="employeefilter" (keyup)="applyFilter($event.target.value)" class="matemployeeinput">
                <i class="fa fa-close close_Filter" (click)="clearSearchField()" *ngIf="searchField"></i>
            </mat-form-field>

        </div>
        <div class="col-sm-5 col-5">
            <div class="form-group employee_togglebutton">
                <span class="Employee_Toggle" *ngIf="checkboxValue == true">Active</span>
                <span class="Employee_Toggle" *ngIf="checkboxValue == false">Inactive</span>
                <label class="switch">
          <input type="checkbox" [checked]="checkboxValue" [(ngModel)]="checkboxValue"
            (change)="toggleIsActive(checkboxValue)">
          <span class="slider round"></span>
          <div class="toggle-button-text outshadow employee_onoff">
            <div class="toggle-button-text-on"></div>
            <div class="toggle-button-text-off"></div>
          </div>
        </label>
            </div>
        </div>
    </div>
</div>

<div class="table-container">
    <div *ngIf="layout_Data">
        <table mat-table #table [dataSource]="dataSource" matSort class="mat-elevation-z8  table employee-table employee dataTable table-width-safari" style="width: 100%;">
            <span class="fa fa-ellipsis-h "></span>
            <ng-container *ngFor="let item of columns " matColumnDef="{{item.columnDef}}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="emp_name" disableClear [ngClass]="{'arrow-disp': item.header == 'Name' || item.header =='Break Type' || item.header =='Role Name' || item.header =='Item Name' || item.header =='Size Name' || item.header =='Modifier Group' || item.header =='Adjustment Name' || item.header =='Event Pricing Name' || item.header =='Labor Name' || item.header =='State Name'  || item.header == 'Gift Cards Name' || item.header == 'Credit Card Name' || item.header == 'EMV Name' || item.header == 'Tablet Name' || item.header == 'Printer Name' || item.header == 'Receipt Name' || item.header == 'Tags Name' || item.header == 'Reason Name'}">
                    {{item.header}}
                    <div *ngIf="item.columnDef == 'isActive' && menuItemData" class="dot_Icon">
                        <img _ngcontent-typ-c482="" src="assets/components/images/iconTag.png" alt="Tags" class="img-fluid topHieImage tags-icon" (click)="openModalWithComponent()" tooltip="Text">

                    </div>
                </th>
                <td mat-cell *matCellDef="let element; let idx=index;">
                    <div class="emp_name" [ngClass]="{'hideColorGiftCard': hideCopyFromGiftCardColor == true}" [style.background-color]="rowClicked == element.id ? '#341a46' : 'transparent'" *ngIf="item.columnDef == 'name' || item.columnDef == 'breakType'" cdkDragPlaceholder>
                        {{ element[item.columnDef] }}</div>

                    <div *ngIf="item.columnDef != 'name' && item.columnDef != 'isActive'" class="emp-name tags">
                        <span class="ellipsis">  {{ element[item.columnDef] }}</span></div>

                    <div class="checkbox-table emp-name" *ngIf="item.columnDef == 'isActive'">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input active" name="customCheckbox" [checked]="element[item.columnDef]" (change)="checkValue($event,element.id);" [attr.disabled]="isActiveInactivePerm ? true : null" />
                            <label class="custom-control-label active"></label>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell employeenodata" colspan="4">No data matching the search</td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row (click)="changeTableRowColor(row.id,row.id,row.name,row)" *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight': selectedRowIndex == row.id}"></tr>
        </table>
    </div>
</div>
