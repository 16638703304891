<div class="payroll_data">
    <form [formGroup]="payrollGeneralForm" class="loginForm" (change)="onchange(payrollGeneralForm.value)">
        <div class="row">
            <div class="col-sm-3">
                <div class="form-group">
                    <label class="customlable mb-0">Start day for the Payroll Week</label><label class="text-danger">*</label>
                    <mat-form-field appearance="fill">
                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="startDay" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.startDay.errors }" (selectionChange)="checkVal($event.value)">
                            <mat-option value='Sunday' selected>Sunday</mat-option>
                            <mat-option value='Monday'>Monday</mat-option>
                            <mat-option value='Tuesday'>Tuesday</mat-option>
                            <mat-option value='Wednesday'>Wednesday</mat-option>
                            <mat-option value='Thursday'>Thursday</mat-option>
                            <mat-option value='Friday'>Friday</mat-option>
                            <mat-option value='Saturday'>Saturday</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="submittedGeneral && GeneralData.startDay.errors" class="invalid-feedback">
                        <mat-error *ngIf="GeneralData.startDay.errors.required">Start day is required</mat-error>
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <div class="form-group">
                    <label class="customlable mb-0">Daily Hours before OT</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="daillyHoursBefore_OT" placeholder="0-24" (keydown)="checkInput($event, inputText)" #inputText appInputRestriction/>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <label class="customlable mb-0 label-width">Weekly Hours before OT</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="weeklyHoursBefore_OT" placeholder="0-168" (keydown)="checkInputs($event, inputText1)" #inputText1 appInputRestriction/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-right">
                <div class="form-group">
                    <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral();">
                            <!-- [disabled]="buttonState ? true : null" -->
                        <img src="assets/components/images/save.png" alt="employee" class="">
                        <label class="label_config">Save</label>
                    </button>
                </div>
            </div>

        </div>
    </form>
</div>