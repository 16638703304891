import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { DeviceService } from 'src/app/services/devices.service';
import { GetDevice, iEMVDeviceDetailsRemove } from 'src/app/interfaces/idevices';
import { DialogService } from 'src/app/services/dialog.service';
import { createMask } from '@ngneat/input-mask';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-emv-general',
  templateUrl: './emv-general.component.html',
  styleUrls: ['./emv-general.component.css']
})

export class EmvGeneralComponent implements OnInit {
  ipAddressMask = createMask({ alias: 'ip' });
  ipAddress = new FormControl('');
  emvGeneralForm: FormGroup
  submittedGeneral: any;
  loginInfo: any;
  isActive: any;
  message: any;
  buttonDisable: boolean = false;
  errorValue: any;
  requiredError: boolean = false;
  @Output() public isInvalid = new EventEmitter();

  @Input() public removeDisable;
  @Output() public isBool = new EventEmitter();
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  @Input()
  public set DataIdx(tableRowId) {
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.GetEMVDetails(tableRowId);
    }
  }

  @Output() public emv_general = new EventEmitter();
  @Output() public emv_remove = new EventEmitter();
  @Output() public emvPageData_child = new EventEmitter();
  mgs: any;
  ipMgs: any;
  selectedDot: boolean = false;
  constructor(private formBuilder: FormBuilder, private deviceService: DeviceService,
    private helper: HelperService, private toaster: ToasterService, private dialogser: DialogService) { }

  ngOnInit(): void {
    //this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.emvGeneralForm = this.formBuilder.group({
      id: [0],
      name: ['', Validators.required],
      ipAddress: ['', [Validators.required, Validators.pattern('\^(([1-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-5][0-5])\.(2[0-5][0-5]|1[0-9][0-9]|[1-9][0-9]|[0-9]))\$')]],
      promptforTip: [false],
      requireCustomertoConfirm: [false],
      duplicateChecking: [false],
      isActive: [false],
      createdBy: [0],
      modifiedBy: [0]
    })
    this.emvGeneralForm.reset();
  }
  get emvGenData() { return this.emvGeneralForm.controls; }

  onKeypressIpEvent(event) {
    var ip = event.target.value.replace(/[^0-9,]+/g, "");
    if (ip == 0 && ip.length <= 1) {
      this.ipAddress.reset();
      this.mgs = '0 Not Aloowed In First'
    }
    else {
      this.mgs = ''
    }
  }
  IPValid(value){
    var ip = value.replace(/[^0-9]+/g, "");
      var IPdot = value.replace(/[^.]+/g, "");
      if(IPdot =='..' || IPdot == '.' ){
        this.message ='Please Enter Valid Ip Address'
        this.emvGeneralForm.controls['ipAddress'].setErrors({'incorrect': true});
      }
      else{
        this.message = ''
      }
  }



  onSaveGeneral() {
    this.errorValue = "";
    this.submittedGeneral = true;
    if (this.emvGeneralForm.invalid) {
      return;
    }
    this.emvGeneralForm.value.isActive = true;
    this.emvGeneralForm.value.tenantId = this.loginInfo.tenantId;
    this.emvGeneralForm.value.createdBy = this.loginInfo.userId;
    this.emvGeneralForm.value.modifiedBy = this.loginInfo.userId;

    this.deviceService.SaveEMVDeviceDetails(this.emvGeneralForm.value).subscribe(
      async (response) => {
        this.emvGeneralForm.patchValue({
          id: response.id,
        });

        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
        } else {
          this.helper.setValue('Sessionid_help', response.id);
          if (this.helper.getValue('Sessionid_help') == undefined ||
            this.helper.getValue('Sessionid_help') == null ||
            this.helper.getValue('Sessionid_help') == "") {
            this.helper.setValue('Sessionid_help', 0)
          }
          else {
            this.helper.setValue('GetSessionData1', this.emvGeneralForm.value);
            this.toaster.successPopUp(response.message);
            this.emvPageData_child.emit({ data: this.emvGeneralForm.value, event: "emvPage", val: false });
            await this.dialogser.ClearSession();
          }
        }
        this.submittedGeneral = false;
        this.emv_general.emit({ res: response, name: this.emvGeneralForm.value.name });
      })
  }

  GetEMVDetails(rowId) {
    this.errorValue = "";
    const deviceList: GetDevice = {
      id: rowId,
      tenantId: this.loginInfo.tenantId
    }
    this.deviceService.SelectEMVDeviceDetails(deviceList)
      .subscribe(res => {
        this.emvGeneralForm.patchValue(res[0]);
        this.emvGeneralForm.controls["ipAddress"].setValue(res[0].ipAddress)
        //this.controls[name].patchValue(value[name], {onlySelf: true, emitEvent});
      })
  }

  RemoveEMVDetails() {
    this.deviceService.RemoveEMVDeviceDetails(this.emvGeneralForm.value).subscribe(
      (response) => {
        this.emv_remove.emit(response.id);
        this.removeDisable = false
        this.toaster.successPopUp(response.message);
        this.submittedGeneral = false;
        this.emvGeneralForm.reset();
      })
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  EMVValid() {
    if (this.emvGeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }
  }

  Highlight() {
    if (!this.emvGeneralForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.emvGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.emvPageData_child.emit(this.emvGeneralForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', this.emvGeneralForm.value);
    this.helper.setValue('OnDirty', true);
    this.emvPageData_child.emit({ data: data, event: "emvPage", val: true });
  }

  // isFormPageDirty() {
  //   let d = this.emvGeneralForm.dirty;
  //   this.helper.setValue('GetSessionData1', this.emvGeneralForm.value);
  //   if (d == true) {
  //     this.helper.setValue('OnDirty', true);
  //   }
  //   else {
  //     this.helper.setValue('OnDirty', false);
  //   }
  //   return d;
  // }
}