<div class="submit_parameter">
    <form class="parameterForm loginForm" [formGroup]="SubmitParameterForm" >
        <div class="row">
            <div class="col-sm-12 text-center">
                <div class="form-group">
                    <label>Submit Parameters</label>
                    <p>Please Double Check Your Fields For Accuracy</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-4 col-4">
                        <div class="form-group">
                            <label>Business Name</label><label class="text-danger  req_label">*</label>
                        </div>
                    </div>
                    <div class="col-sm-8 col-8">
                        <div class="form-group">
                            <label class="center-align" for="businessName">: {{GeneralData.businessName.value}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-4">
                        <div class="form-group">
                            <label>Contact Name</label><label class="text-danger  req_label">*</label>
                        </div>
                    </div>
                    <div class="col-sm-8 col-8">
                        <div class="form-group">
                            <label class="center-align" for="contactFirstName">: {{GeneralData.contactFirstName.value}} 
                                {{GeneralData.contactLastName.value}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-4">
                        <div class="form-group">
                            <label>Contact Email</label><label class="text-danger  req_label">*</label>
                        </div>
                    </div>
                    <div class="col-sm-8 col-8">
                        <div class="form-group">
                            <label class="center-align" for="contactEmail">: {{GeneralData.contactEmail.value}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-4">
                        <div class="form-group">
                            <label>Address</label><label class="text-danger  req_label">*</label>
                        </div>
                    </div>
                    <div class="col-sm-8 col-8">
                        <div class="form-group">
                            <label class="center-align" for="address">: {{GeneralData.address.value}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-4">
                        <div class="form-group">
                            <label>City</label><label class="text-danger  req_label">*</label>
                        </div>
                    </div>
                    <div class="col-sm-8 col-8">
                        <div class="form-group">
                            <label class="center-align" for="city">: {{GeneralData.city.value}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-4">
                        <div class="form-group">
                            <label>State</label><label class="text-danger  req_label">*</label>
                        </div>
                    </div>
                    <div class="col-sm-8 col-8">
                        <div class="form-group">
                            <label class="center-align" for="state">: {{GeneralData.state.value}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-4">
                        <div class="form-group">
                            <label>Zip Code</label><label class="text-danger  req_label">*</label>
                        </div>
                    </div>
                    <div class="col-sm-8 col-8">
                        <div class="form-group">
                            <label class="center-align">: {{GeneralData.zip.value}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-4">
                        <div class="form-group">
                            <label>Phone</label><label class="text-danger  req_label"></label>
                        </div>
                    </div>
                    <div class="col-sm-8 col-8">
                        <div class="form-group">
                              <label class="center-align">: {{GeneralData.phone.value}}</label> 
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-5 col-5">
                        <div class="form-group">
                            <label>Online Ordering</label><label class="text-danger  req_label">*</label>
                        </div>
                    </div>
                    <div class="col-sm-7 col-7">
                        <div class="form-group">
                            <label class="center-align" for="emv">:
                                {{GeneralData.olo.value}} 
                           </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5 col-5">
                        <div class="form-group">
                            <label>EMV</label><label class="text-danger  req_label">*</label>
                        </div>
                    </div>
                    <div class="col-sm-7 col-7">
                        <div class="form-group">
                            <label class="center-align" for="emv">:  {{emvValue}} </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5 col-5">
                        <div class="form-group">
                            <label>TranCloud</label><label class="text-danger  req_label">*</label>
                        </div>
                    </div>
                    <div class="col-sm-7 col-7">
                        <div class="form-group">
                            <label class="center-align" for="tranCloud">: {{GeneralData.tranCloud.value}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5 col-5">
                        <div class="form-group">
                            <label>#of Devices</label><label class="text-danger  req_label">*</label>
                        </div>
                    </div>
                    <div class="col-sm-7 col-7">
                        <div class="form-group">
                            <label class="center-align" for="noofdevices">: {{GeneralData.noofdevices.value}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5 col-5">
                        <div class="form-group">
                            <label>Authentication Code*</label>
                        </div>
                    </div>
                    <div class="col-sm-7 col-7">
                        <div class="form-group">
                            <label class="center-align" for="totalPassword">: {{GeneralData.totalPassword.value}}</label>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-sm-5 col-5">
                        <div class="form-group">
                            <label>TranCloud ID*</label>
                        </div>
                    </div>
                    <div class="col-sm-7 col-7">
                        <div class="form-group">
                            <label class="center-align" for="tranCloudId">: {{GeneralData.tranCloudId.value}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5 col-5">
                        <div class="form-group">
                            <label>PrePaid (Gift)</label>
                        </div>
                    </div>
                    <div class="col-sm-7 col-7">
                        <div class="form-group">
                            <label class="center-align" for="prePaidGift">: {{GeneralData.prePaidGift.value}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5 col-5">
                        <div class="form-group">
                            <label>Card Present</label>
                        </div>
                    </div>
                    <div class="col-sm-7 col-7">
                        <div class="form-group">
                            <label class="center-align" for="cardPresent">: {{GeneralData.cardPresent.value}}</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5 col-5">
                        <div class="form-group">
                            <label>Card Not Present</label>
                        </div>
                    </div>
                    <div class="col-sm-7 col-7">
                        <div class="form-group">
                            <label class="center-align" for="cardNotPresent">: {{GeneralData.cardNotPresent.value}}</label>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12">
                <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral()">
                <!-- [disabled]="buttonState ? true : null" -->
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Submit</label>
                </button>
            </div>
        </div>
    </form>
</div>