import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/helper/toaster.service';
 
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
 constructor(private authenticationService: AuthenticationService, private router: Router,private toaster: ToasterService) { }
 
 intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {
 
 return newRequest.handle(request).pipe(catchError(err =>{
    console.log(err,"err");
    
        if (err.status === 400) {
            this.toaster.errorPopUp("Error...");
            
        }
        else if (err.status === 401) {
        //if 401 response returned from api, logout from application & redirect to login page.
        this.authenticationService.logout();
        }else if (err.status === 0){
            this.toaster.errorPopUp("Server is down .! Please try after sometime.");
        }
 
 const error = err.error.message || err.statusText;
 return throwError(error.message || error);
 }));
 }
}