import { Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { TagsService } from 'src/app/services/tags.service';
import { EventPricingGeneralComponent } from 'src/app/views/menu/event-pricing-general/event-pricing-general.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SchedulerSelect } from 'src/app/interfaces/imenu';
import { EventPricingService } from 'src/app/services/event-pricing.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-scheduling-widget',
  templateUrl: './scheduling-widget.component.html',
  styleUrls: ['./scheduling-widget.component.css'],
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true },
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class SchedulingWidgetComponent implements OnInit {
  ScheduleForm: FormGroup
  today = new Date();
  buttonDisable: boolean = false;
  WeekAltList = [];
  WeekDaysList = [];
  id: any = 0;
  loginInfo: any;
  isActive: any;
  SalesSettings: IDropdownSettings = {};
  specificselectedItems = [];
  specificdaysOfTheWeek = [];
  @Input() repeatedWeeks;
  daysOfTheWeek: FormControl = new FormControl('');
  repeatWeeks: FormControl = new FormControl('');
  submittedGeneral: any;
  dateMgs: any;
  scheduleList = [
    { id: 1, name: 'Daily', value: 'optDaily' },
    { id: 2, name: 'Weekly', value: 'optWeekly' },
    { id: 3, name: 'Monthly', value: 'optMonthly' },
    { id: 4, name: 'Yearly', value: 'optYearly' },
    { id: 5, name: 'custom', value: 'optCustom' },
  ];

  weekDays = [
    { id: 1, name: 'MON', isSelected: false },
    { id: 2, name: 'TUE', isSelected: false },
    { id: 3, name: 'WED', isSelected: false },
    { id: 4, name: 'THU', isSelected: false },
    { id: 5, name: 'FRI', isSelected: false },
    { id: 6, name: 'SAT', isSelected: false },
    { id: 7, name: 'SUN', isSelected: false }
  ];

  yearMonth = [
    { id: 1, name: 'JAN', isSelected: false },
    { id: 2, name: 'FEB', isSelected: false },
    { id: 3, name: 'MAR', isSelected: false },
    { id: 4, name: 'APR', isSelected: false },
    { id: 5, name: 'MAY', isSelected: false },
    { id: 6, name: 'JUN', isSelected: false },
    { id: 7, name: 'JUL', isSelected: false },
    { id: 8, name: 'AUG', isSelected: false },
    { id: 9, name: 'SEP', isSelected: false },
    { id: 10, name: 'OCT', isSelected: false },
    { id: 11, name: 'NOV', isSelected: false },
    { id: 12, name: 'DEC', isSelected: false },
  ];
  //calenderDates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]

  calenderDates = [
    { ID: 1, isSelected: false },
    { ID: 2, isSelected: false },
    { ID: 3, isSelected: false },
    { ID: 4, isSelected: false },
    { ID: 5, isSelected: false },
    { ID: 6, isSelected: false },
    { ID: 7, isSelected: false },
    { ID: 8, isSelected: false },
    { ID: 9, isSelected: false },
    { ID: 10, isSelected: false },
    { ID: 11, isSelected: false },
    { ID: 12, isSelected: false },
    { ID: 13, isSelected: false },
    { ID: 14, isSelected: false },
    { ID: 15, isSelected: false },
    { ID: 16, isSelected: false },
    { ID: 17, isSelected: false },
    { ID: 18, isSelected: false },
    { ID: 19, isSelected: false },
    { ID: 20, isSelected: false },
    { ID: 21, isSelected: false },
    { ID: 22, isSelected: false },
    { ID: 23, isSelected: false },
    { ID: 24, isSelected: false },
    { ID: 25, isSelected: false },
    { ID: 26, isSelected: false },
    { ID: 27, isSelected: false },
    { ID: 28, isSelected: false },
    { ID: 29, isSelected: false },
    { ID: 30, isSelected: false },
    { ID: 31, isSelected: false },
  ];
  @Input() dayPartSchedule
  @Output() public schedu_general = new EventEmitter();
  @Output() public event_schedule = new EventEmitter();
  @Output() saveItemValue = new EventEmitter();
  @Output() public schwidgetData_child = new EventEmitter();
  scheduleYearMonth: any;
  scheduleMonthDays: any;
  scheduleWeekDays: any;
  jsonArrayValue: any;
  @ViewChild(EventPricingGeneralComponent) eventGeneralComponet: EventPricingGeneralComponent;

  checkedMonthDaysList = [];
  checkedWeekDaysList = [];
  checkedRepeatList = [];
  checkedRepeatList1 = [];
  checkedDaysOfTheWeekList = [];
  checkedMonthList = [];
  checkValues: any = [];
  checkValuesWeek: any = []
  checkedInfo: any;
  selectedDaysTemp: any = [];
  //id: any;
  leftId: any;
  errorMessage: any;
  @Input()
  public set DataIdx(tableRowId) {
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.leftId = tableRowId;
      this.GetSchedulerDetails(this.leftId);

    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  constructor(private formBuilder: FormBuilder, private tagser: TagsService, private helper: HelperService,
    private toaster: ToasterService, private eventService: EventPricingService) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData2', null);
    // this.specificselectedItems = this.repeatedWeeks;
    this.errorMessage = '';
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.ScheduleForm = this.formBuilder.group({
      scheduleRadio: ['optDaily'],
      frequency: [null],
      startDate: [moment(new Date()), Validators.required],
      endDate: [null],
      startTime: [null],
      endTime: [null],
      timeDays: ['wholeDay'],
      dateRadio: ['noEnd'],
      endAfter: [null],
      repeatDays: [null],
      weekDates: ['specificMonth'],
      repeatWeeks: new FormControl(''),
      daysOfTheWeek: new FormControl(''),
      //daysOfTheWeek: [null],
      //daysOfTheMonth: [null],
      repeatMonths: [null],
      specificselectedItems: [null],
      WeekAltList: [null],
      WeekDaysList: [null],
      specificdaysOfTheWeek: [null]
    })
    this.SalesSettings = {
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 2,

    };
    // this.addCheckboxes();
    this.WeekAltList = [
      { id: 1, name: 'First' },
      { id: 2, name: 'Second' },
      { id: 3, name: 'Third' },
      { id: 4, name: 'Fourth' },
      { id: 5, name: 'Fifth' },
      { id: 6, name: 'Last' },
    ];

    this.WeekDaysList = [
      { id: 1, name: 'Monday' },
      { id: 2, name: 'Tuesday' },
      { id: 3, name: 'Wednesday' },
      { id: 4, name: 'Thursday' },
      { id: 5, name: 'Friday' },
      { id: 6, name: 'Saturday' },
      { id: 7, name: 'Sunday' },
    ];
    this.ScheduleForm.controls['frequency'].setValue(1);

  }

  // @Input()
  // public set specificselectedItemsFunction(repeatWeeks) {
  //   this.specificselectedItems = repeatWeeks;

  // }
  get GeneralData() { return this.ScheduleForm.controls; }
  // get weekFormArray() {
  //   return this.ScheduleForm.controls.weekDays as FormArray;
  // }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // private addCheckboxes() {
  //   this.weekDays.forEach(() => this.weekFormArray.push(new FormControl(false)));
  // }

  onSaveScheduler() {
    this.submittedGeneral = true;
    if (this.GeneralData.startDate.value != undefined && this.GeneralData.startDate.value != null
      && this.GeneralData.startDate.value != "") {
      //this.ScheduleForm.value.startDate="01-01-2022";
      if (this.ScheduleForm.invalid) {
        this.toaster.errorPopUp("Error in Scheduling Widget");
        return;
      }
      const startDate = moment(new Date(this.GeneralData.startDate.value), 'DD-MM-YYYY').format('YYYY-MM-DD');
      const endDate = moment(new Date(this.GeneralData.endDate.value), 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (this.ScheduleForm.value.dateRadio == "EndDate") {
        if (this.GeneralData.endDate.value != "" && this.GeneralData.endDate.value != null
          && this.GeneralData.endDate.value != undefined) {
          if (startDate > endDate) {
            this.errorMessage = 'Start Date of Schedule should be lesser than End date';
            return;
          } else {
            this.errorMessage = '';
          }
        }
        else {
          this.errorMessage = 'Please Select End date';
          return;
        }
      }
      else {
        this.errorMessage = '';
      }

      this.ScheduleForm.value.daysOfTheMonth = this.checkedMonthDaysList.toString();
      this.ScheduleForm.value.repeatDays = this.checkedWeekDaysList.toString();
      this.ScheduleForm.value.repeatMonths = this.checkedMonthList.toString();
      this.ScheduleForm.value.daysOfTheWeek = JSON.stringify(this.checkedDaysOfTheWeekList);
      this.ScheduleForm.value.repeatWeeks = JSON.stringify(this.checkedRepeatList);
      if (this.specificselectedItems == null) {
        this.ScheduleForm.value.repeatWeeks = "[]"
      } else {
        this.ScheduleForm.value.repeatWeeks = JSON.stringify(this.specificselectedItems);
      }

      if (this.specificdaysOfTheWeek == null) {
        this.ScheduleForm.value.daysOfTheWeek = "[]"
      } else {
        this.ScheduleForm.value.daysOfTheWeek = JSON.stringify(this.specificdaysOfTheWeek);
      }
      //this.ScheduleForm.value.startDate = moment(new Date(this.ScheduleForm.value.startDate)).format('YYYY-MM-DD');
      this.helper.setValue('GetSessionData2', this.ScheduleForm.value)
      this.event_schedule.emit(this.ScheduleForm.value);
      this.schwidgetData_child.emit({ data: this.ScheduleForm.value, event: "eventPage", val: false });

    }
    else {

      this.errorMessage = "Please Select Start Date";
      return;
    }
  }

  onMonthDaysChange(option, event) {
    if (event.target.checked) {
      if (this.checkedMonthDaysList != undefined && this.checkedMonthDaysList != null) {
        if (this.checkedMonthDaysList[0] != undefined && this.checkedMonthDaysList[0] != null) {
          if (this.checkedMonthDaysList[0].length == 0) {
            this.checkedMonthDaysList = [];
          }
        }
      }
      this.checkedMonthDaysList.push(option.ID);
    } else {
      for (var i = 0; i < this.checkedMonthDaysList.length; i++) {
        if (this.checkedMonthDaysList[i] == option.ID) {
          this.checkedMonthDaysList.splice(i, 1);
        }
      }
    }
  }

  onWeekDaysChange(option, event) {
    if (event.target.checked) {
      if (this.checkedWeekDaysList != undefined && this.checkedWeekDaysList != null) {
        if (this.checkedWeekDaysList[0] != undefined && this.checkedWeekDaysList[0] != null) {
          if (this.checkedWeekDaysList[0].length == 0) {
            this.checkedWeekDaysList = [];
          }
        }
      }
      this.checkedWeekDaysList.push(option.id);
    } else {
      for (var i = 0; i < this.checkedWeekDaysList.length; i++) {
        if (this.checkedWeekDaysList[i] == option.id) {
          this.checkedWeekDaysList.splice(i, 1);
        }
      }
    }
  }

  onMonthChange(option, event) {
    this.checkedRepeatList = [];
    this.checkedDaysOfTheWeekList = [];
    if (event.target.checked) {
      if (this.checkedMonthList != undefined && this.checkedMonthList != null) {
        if (this.checkedMonthList[0] != undefined && this.checkedMonthList[0] != null) {
          if (this.checkedMonthList[0].length == 0) {
            this.checkedMonthList = [];
          }
        }
      }
      this.checkedMonthList.push(option.id);
    } else {
      for (var i = 0; i < this.checkedMonthList.length; i++) {
        if (this.checkedMonthList[i] == option.id) {
          this.checkedMonthList.splice(i, 1);
        }
      }
    }
  }

  onRepeatsEveryDayChange(option) {
    this.checkedRepeatList.push(option);
    //this.checkValues = [...new Set(this.checkedRepeatList)];
  }

  onRepeatsEveryDayChangeDeselect(option) {
    for (var i = 0; i <= this.checkedRepeatList.length; i++) {
      if (this.checkedRepeatList[i].id == option.id) {
        this.checkedRepeatList.splice(i, 1);
      }
    }
  }

  onDaysOfTheWeek(option) {
    this.checkedDaysOfTheWeekList.push(option);
    //this.checkValuesWeek = [...new Set(this.checkedDaysOfTheWeekList)];
  }

  onDaysOfTheWeekDeselect(option) {
    for (var i = 0; i <= this.checkedDaysOfTheWeekList.length; i++) {
      if (this.checkedDaysOfTheWeekList[i].id == option.id) {
        this.checkedDaysOfTheWeekList.splice(i, 1);
      }
    }
  }

  clearOtherSchedule(option, event) {
    //let todaydate = this.datepipe.transform(this.today, 'MM-dd-YYYY');

    if (option.value == 'optDaily') {
      this.checkedMonthDaysList = [];
      this.checkedWeekDaysList = [];
      this.checkedMonthList = [];
      this.checkedRepeatList = [];
      this.checkedDaysOfTheWeekList = [];
      this.scheduleYearMonth = [];
      this.ScheduleForm.controls['frequency'].setValue(1);
      //this.ScheduleForm.controls['startDate'].setValue(null);
    }

    if (option.value == 'optWeekly') {
      this.checkedMonthDaysList = [];
      this.checkedMonthList = [];
      this.checkedRepeatList = [];
      this.checkedDaysOfTheWeekList = [];
      this.scheduleYearMonth = []
      this.specificselectedItems = [];
      this.specificdaysOfTheWeek = [];
      this.ScheduleForm.controls['frequency'].setValue(1);
      //this.ScheduleForm.controls['startDate'].setValue(null);
    }

    if (option.value == 'optMonthly') {
      this.checkedWeekDaysList = [];
      this.checkedMonthList = [];
      this.checkedRepeatList = [];
      this.scheduleYearMonth = [];
      this.ScheduleForm.controls['frequency'].setValue(1);
      //  this.ScheduleForm.controls['startDate'].setValue(null);
    }

    if (option.value == 'optYearly') {
      this.checkedMonthDaysList = [];
      this.checkedWeekDaysList = [];
      this.checkedRepeatList = [];
      this.checkedDaysOfTheWeekList = [];
      //this.GetSchedulerDetails(this.leftId);
      this.ScheduleForm.controls['frequency'].setValue(1);
      // this.ScheduleForm.controls['startDate'].setValue(null);
    }
    if (option.value == 'optCustom') {
      this.checkedWeekDaysList = [];
      this.ScheduleForm.controls['frequency'].setValue(1);
      //  this.ScheduleForm.controls['startDate'].setValue(null);

      // const map2 = new Map();
      //   this.jsonArrayValue = [];
      //   for (const item of this.checkedMonthList) {
      //     if (!map2.has(item.id)) {
      //       map2.set(item.id, true);    // set any value to Map
      //       this.jsonArrayValue.push({
      //         id: item.id,
      //         name: item.name,
      //         isSelected: item.isSelected,
      //       });
      //     }
      //   }
      // this.yearMonth =jsonArrayValue;
      //  for(var i=0;i<this.checkedMonthList.length;i++){
      //    this.yearMonth[this.checkedMonthList[i]].isSelected = false;
      //    this.checkedMonthList[i] = [false];
      //  }
      this.checkedMonthList = [];
      this.checkedRepeatList = [];
      this.scheduleYearMonth = [];
      //console.log(this.yearMonth,'this.yearMonth')
      //  this.yearMonth=this.checkedMonthList;
      this.repeatWeeks.reset();
      this.daysOfTheWeek.reset();
      //this.GetSchedulerDetails();
    }

  }

  clearEndDate() {
    this.ScheduleForm.controls['endDate'].setValue(null);
    this.ScheduleForm.controls['endAfter'].setValue(null);
  }

  GetSchedulerDetails(rowId?) {
    //this.id = rowId;
    const eventData: SchedulerSelect = {
      id: rowId,
      tenantId: this.loginInfo.tenantId
    }
    this.eventService.SelectSchedulerWidget(eventData)
      .subscribe(res => {
        if (res.General.length > 0) {
          this.checkedMonthDaysList = [];
          this.checkedWeekDaysList = [];
          this.checkedMonthList = [];
          this.ScheduleForm.patchValue(res.General[0]);

          this.ScheduleForm.patchValue({ startDate: res.General[0].startDate == null ? '' : moment(res.General[0].startDate).format('YYYY-MM-DD') });

          this.ScheduleForm.patchValue({ endDate: res.General[0].endDate == null ? '' : moment(res.General[0].endDate).format('YYYY-MM-DD') });


          //this.checkedMonthDaysList = res.General[0].daysOfTheMonth;
          //this.checkedWeekDaysList = res.General[0].repeatWeeks;
          //this.checkedMonthList = res.General[0].repeatMonths;
          //this.scheduleData = res[0];
          this.id = res.General[0].id;
          this.saveItemValue.emit(this.id)

          // this.ScheduleForm.controls.startDate.setValue(formatDate(res.General[0].startDate, 'MM-dd-YYYY', 'en-US'));
          //this.ScheduleForm.controls.endDate.setValue(formatDate(res.General[0].endDate, 'MM-dd-YYYY', 'en-US'));

          var repeatweekjson = res.General[0].repeatWeeks;
          if (repeatweekjson != null && repeatweekjson != '') {
            this.specificselectedItems = JSON.parse(repeatweekjson);
            //this.checkedWeekDaysList.push(JSON.parse(repeatweekjson));
            if (res.RepeatDays != undefined && res.RepeatDays != null) {
              for (var t = 0; t < res.RepeatDays.length; t++) {
                if (res.RepeatDays[t].isSelected == true) {
                  this.checkedWeekDaysList.push(res.RepeatDays[t].id);
                }
              }
            }
          }
          else {
            this.specificselectedItems = [];
          }

          var specifjsondayOftheWeek = res.General[0].daysOfTheWeek;
          if (specifjsondayOftheWeek != null && specifjsondayOftheWeek != '') {
            this.specificdaysOfTheWeek = JSON.parse(specifjsondayOftheWeek);
          }
          else {
            this.specificdaysOfTheWeek = [];
          }

          var specifjsondayOftheMonth = res.General[0].daysOfTheMonth;
          if (specifjsondayOftheMonth != null && specifjsondayOftheMonth != '') {
            //this.checkedMonthDaysList.push(JSON.parse(specifjsondayOftheMonth));

            var splitdaymon = specifjsondayOftheMonth.split(',');
            for (var t1 = 0; t1 < splitdaymon.length; t1++) {
              var dayofmon = splitdaymon[t1].replace(/[^\d ]/g, '');
              if (dayofmon != undefined && dayofmon != null && dayofmon != "") {
                var dayofmonconvtoint = parseInt(dayofmon);
                if (dayofmonconvtoint != undefined && dayofmonconvtoint != null && dayofmonconvtoint != 0) {
                  this.checkedMonthDaysList.push(dayofmonconvtoint);
                }
              }
            }
          }
          else {
            this.checkedMonthDaysList = [];
          }

          var specifjsonMonth = res.General[0].repeatMonths;
          if (specifjsonMonth != null && specifjsonMonth != '') {
            //this.checkedMonthList.push(JSON.parse(specifjsonMonth));
            var splitmon = specifjsonMonth.split(',');
            for (var t2 = 0; t2 < splitmon.length; t2++) {
              var mon = splitmon[t2].replace(/[^\d ]/g, '');
              if (mon != undefined && mon != null && mon != "") {
                var monconvtoint = parseInt(mon);
                if (monconvtoint != undefined && monconvtoint != null && monconvtoint != 0) {
                  this.checkedMonthList.push(monconvtoint);
                }
              }
            }
          }
          else {
            this.checkedMonthList = [];
          }

          //this.ScheduleForm.controls.repeatWeeks.setValue(this.selectedItems);
          this.scheduleWeekDays = res.RepeatDays;
          // var days = this.arryComm.split(',');
          const map = new Map();
          this.jsonArrayValue = [];
          for (const item of this.scheduleWeekDays) {
            if (!map.has(item.id)) {
              map.set(item.id, true);    // set any value to Map
              this.jsonArrayValue.push({
                id: item.id,
                name: item.name,
                isSelected: item.isSelected,
              });
            }
          }
          this.weekDays = this.jsonArrayValue;

          this.scheduleMonthDays = res.Table2;
          // var days = this.arryComm.split(',');
          const map1 = new Map();
          this.jsonArrayValue = [];
          for (const item of this.scheduleMonthDays) {
            if (!map1.has(item.id)) {
              map1.set(item.id, true);    // set any value to Map
              this.jsonArrayValue.push({
                ID: item.id,
                isSelected: item.isSelected,
              });
            }
          }
          this.calenderDates = this.jsonArrayValue;

          this.scheduleYearMonth = res.Table3
          // var days = this.arryComm.split(',');
          const map2 = new Map();
          this.jsonArrayValue = [];
          for (const item of this.scheduleYearMonth) {
            if (!map2.has(item.id)) {
              map2.set(item.id, true);    // set any value to Map
              this.jsonArrayValue.push({
                id: item.id,
                name: item.name,
                isSelected: item.isSelected,
              });
            }
          }
          this.yearMonth = this.jsonArrayValue;

        } else {
          this.ngOnInit()
          this.id = 0;
          this.saveItemValue.emit(this.id)
        }
      })
  }

  ngAfterViewInit() {
    this.schwidgetData_child.emit(this.ScheduleForm.value)
    this.ScheduleForm.patchValue(this.helper.getValue('GetSessionData2'));
  }

  onchange(data) {
    this.helper.setValue('GetSessionData2', data);
    this.helper.setValue('OnDirty', true);
    this.schwidgetData_child.emit({ data: data, event: "eventPage", val: true });
  }
}
