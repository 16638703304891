import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToasterService } from 'src/app/helper/toaster.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { HelperService } from 'src/app/helper/helper.service';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { MatTableDataSource } from '@angular/material/table';
import { IGetPermission } from 'src/app/interfaces/iadmin';
import { SelectAdjustGeneral } from 'src/app/interfaces/iadjustment';
import { adjustmentService } from 'src/app/services/adjustment.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PermissionsModalComponent } from 'src/app/elements/permissions-modal/permissions-modal.component';

export class Element {
  id: string;
  name: string;
  statusVal: boolean;
}

export interface AllAdjustment {
  id: any;
  isActive: any;
  name: any;
}

export interface AllPermission {
  id: any;
  isActive: any;
  name: any;
}

const All_Data: AllAdjustment = { id: 0, name: 'All Adjustment', isActive: false };

@Component({
  selector: 'app-role-permission-details',
  templateUrl: './role-permission-details.component.html',
  styleUrls: ['./role-permission-details.component.css']
})

export class RolePermissionDetailsComponent implements OnInit {
  RolePermissionForm: FormGroup
  buttonDisable: boolean = false;
  count: any = 1;
  submittedPermission = false;
  AllCheckboxValid: boolean = false;
  loginInfo: any;
  roleId: any;
  userId: any;
  isShownA: boolean = false;
  isShownC: boolean = false;
  isPermission: boolean = false;
  AllCheckBox = false;
  timeClockCheckBox = false;
  RolePermissionList: any = [];
  AdjustmentPermissionList: any = [];
  permissionData: any;
  timeCLockData: any;
  adjustmentData: any;
  addClick: any = 0;
  permissionValues = [];
  // pemissionRoleValue = [];
  jsonArrayValue: any = [];
  Permissionactive = false;
  rolePosition: any;
  checkPositionValue: any;
  isDisabled: boolean = false;
  defaultIndicator: any;
  jobPositionarray: any = [];
  defaultroleId: any;
  WagesForm: FormGroup;
  wagesMultiList = [];
  empValue: any;
  empRoleID: any;
  permissionValue: any;
  OverrideCurrent: any;
  disabled: boolean;
  rolePosID: any;
  roleData: any;
  admin: boolean = false;
  finance: boolean = false;
  tableRowId: any = 0;
  dataIdxItem: any = 0;
  permissionadjustment: boolean = true;
  accepted: Boolean = true;
  isSelectedCount: any;
  isNotSelectedCount: any;
  //isTimeClock: boolean = false;
  @Output() public roleperdetailsData_child = new EventEmitter();
  bsModalRef?: BsModalRef;


  private onDestroy$: Subject<void> = new Subject<void>();
  permissionId = [
    { permissionId: 1, permissionName: 'MON' },
  ];

  allpermission = [
    { id: 1, name: 'All Permission', isSelected: false },
  ];

  @Input() public permissionDataemit = [];
  @Input() public roleDataemit;
  @Input() public permissionDropItem;

  public dataSource = new MatTableDataSource<any>();
  public EmployeeTableData: any;
  public displayedColumns = [];
  columns = [];
  categoryInput: any;
  PermissionInput: any;
  checkedPermission: any = [];
  adjustmentList: any = []
  dff: boolean;
  // insertedID: any;
  // newPermission :any;
  // newPermissionss: FormControl = new FormControl('');
  //@Input() public buttonState;
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }
  id: any;
  submittedGeneral: boolean;

  constructor(private formBuilder: FormBuilder, private adjust: adjustmentService, private dialogser: DialogService,
    private toaster: ToasterService, private helper: HelperService, private adminService: AdminService, private modalService: BsModalService) {
  }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData3', null);
    this.buttonDisable = false;
    this.defaultIndicator = 1;
    this.RolePermissionForm = this.formBuilder.group({
      primaryRoleId: [0],
      primaryRolePermissionList: [0],
      OverrideCurrent: [false],
      newCategory: [null],
      timeClockOnly: [false],
      allPermission: [false]
    })
    this.loginInfo = this.helper.getValue('LoginInfo')
    this.dataIdxItem = 0;
    this.tableRowId = 0;
    this.admin = true;
    this.addClick = 1;
    //this.getPermissionList();
    this.OnRolePositionValue();
    this.GetAdjustmentListData();

  }

  @Input()
  public set DataIdx(tableRowId) {
    this.tableRowId = tableRowId;
    this.dataIdxItem = tableRowId;
    this.AllCheckBox = false;
    //this.getPermission(tableRowId)
    if (this.tableRowId != undefined && this.tableRowId != null && this.tableRowId != "") {
      this.OnRolePositionValue();
      this.isShownA = false;
      this.isShownC = true;
      // console.log(this.permissionData,'this.permissionData')
      // if (this.RolePermissionList.length==49)
      // {
      //   this.AllCheckBox = true;
      // }
    }
  }

  @Input()
  public set getRoleID(ID) {
    this.dataIdxItem = ID;
  }


  OnRolePositionValue() {
    this.count = 1;
    this.permissionData = [];
    this.AdjustmentPermissionList = [];
    this.permissionValues = [];
    this.adjustmentData = [];
    this.RolePermissionList = [];
    this.jsonArrayValue = [];
    if (this.tableRowId == undefined) {
      this.tableRowId = 0;
    }
    const role: IGetPermission = {
      id: this.dataIdxItem > 0 ? this.dataIdxItem : this.tableRowId,
      tenantId: this.loginInfo.tenantId
    }
    this.adminService.GetDefaultPermission(role).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      this.RolePermissionForm.patchValue(response.timeClockOnly[0]);
      if (response.timeClockOnly.length > 0) {
        if (response.timeClockOnly[0].timeClockOnly == true) {
          this.isDisabled = true
          this.isPermission = true
        }
        else {
          this.isDisabled = false
          this.isPermission = false
        }
      }
      this.permissionData = response.permission;
      this.adjustmentData = response.adjPermission;

      // console.log(this.permissionData.filter(x=>x.isSelected==true).length,'permissionData');
      this.isSelectedCount = this.permissionData.filter(x => x.isSelected == true).length;
      this.isNotSelectedCount = this.permissionData.filter(x => x.isSelected == false).length;
      //this.permissionValues = this.permissionData;
      const map = new Map();
      this.jsonArrayValue = []


      for (const item of this.permissionData) {
        if (!map.has(item.categoryId)) {
          map.set(item.categoryId, true);    // set any value to Map
          this.jsonArrayValue.push({
            categoryId: item.categoryId,
            categoryName: item.categoryName,
            //categoryImage: item.categoryImage,
            permissionId: item.permissionId,
            permissionName: item.permissionName,
            isSelected: item.isSelected,
          });
        }

        if (item.isSelected) {
          this.RolePermissionList.push(item.permissionId);
          // console.log( this.RolePermissionList.length,' this.RolePermissionList')
        }
        if (item.isSelected == false) {

          this.checkedPermission.push(item)
        }
      }

      this.permissionValues = this.permissionData;

      if (this.defaultIndicator == 1) {
        this.onIndicatorChange(1, 0);
      }
    }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  onIndicatorChange(e, index) {
    if (this.isSelectedCount == 52) {
      this.AllCheckBox = true;
    }
    else if (this.isSelectedCount == 0) {
      if (this.addClick == 0) {
        this.AllCheckBox = true;
      }
    }
    else {
      this.AllCheckBox = false;
    }
    this.permissionValues = this.permissionData.filter(x => x.categoryId == e);
    this.defaultIndicator = 1;

  }

  onSavePermission(RoleID) {
    this.submittedPermission = true;
    // stop here if form is invalid
    if (this.RolePermissionForm.invalid) {
      return;
    }
    this.dataIdxItem = RoleID// this.helper.getValue('roleId');

    let roledet;
    roledet = this.RolePermissionForm.value;
    roledet.roleId = this.dataIdxItem ? this.dataIdxItem : this.tableRowId;
    roledet.tenantId = this.loginInfo.tenantId;

    roledet.permissionRef = this.RolePermissionList.toString();

    roledet.adjustPermissionRef = this.AdjustmentPermissionList.toString();

    //  console.log(roledet, 'roledet')
    this.adminService.SaveDefaultPermiission(roledet).pipe(takeUntil(this.onDestroy$)).subscribe(
      async (response) => {
        this.permissionValue = response
        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
        } else {
          await this.dialogser.ClearSession();
          this.helper.setValue('GetSessionData3', roledet);
          this.roleperdetailsData_child.emit({ data: roledet, event: "RolepermissionPage", val: false });

        }
        this.submittedPermission = false;

      }), err => console.error('Error', err), () => console.log('HTTP request completed.')

  }


  onCheckAllChange(event, id, flag) {
    if (flag == 'Role') {
      if ((id == 1) && (event.target.checked == true)) {
        this.count = 1;
        this.AllCheckboxValid = true;
        this.AllCheckBox = true;
        this.isNotSelectedCount = 0;
        for (let i = 0; i < this.permissionData.length; i++) {
          this.permissionData[i].isSelected = true;
          if (this.permissionData[i].isSelected == true) {
            this.RolePermissionList.push(this.permissionData[i].permissionId);
            this.isSelectedCount = this.count++;
          }
        }
      }
      else if ((id == 1) && (event.target.checked == false)) {
        this.AllCheckboxValid = false;
        this.AllCheckBox = false;
        this.isNotSelectedCount = this.permissionData.filter(x => x.isSelected == false).length;
        for (let i = 0; i < this.permissionData.length; i++) {
          this.permissionData[i].isSelected = false;
          this.RolePermissionList = []
          this.isSelectedCount = 0;
        }
      }
    }
  }

  onCheckChange(event, id, flag) {
    // var count=0;
    // if (id != 52) {
    if (flag == 'Role') {
      // this.count=1;
      if (event.target.checked) {
        this.RolePermissionList.push(Number(event.target.value));
        let a = this.permissionValues.find(x => x.permissionId == event.target.value)
        a.isSelected = true;
        this.count--;
        this.AllCheckboxValid = true;
        this.isSelectedCount++;
        this.isNotSelectedCount--;
      } else {
        this.isNotSelectedCount++;
        this.isSelectedCount--;
        this.RolePermissionList.forEach((value, index) => {
          if (value == event.target.value) this.RolePermissionList.splice(index, 1);
        });
        let a = this.permissionValues.find(x => x.permissionId == event.target.value)
        a.isSelected = false;
        this.count++;
        this.AllCheckboxValid = false;
      }

      if ((id == 1) && (event.target.checked == true)) {
        for (let i = 0; i < this.permissionData.length; i++) {
          this.permissionData[i].isSelected = true;
          if (this.permissionData[i].isSelected == true) {
            this.RolePermissionList.push(this.permissionData[i].permissionId)
          }
        }
      }
      else if ((id == 1) && (event.target.checked == false)) {
        for (let i = 0; i < this.permissionData.length; i++) {
          this.permissionData[i].isSelected = false;
          this.RolePermissionList = []
        }
      }
    }
    else if (flag == 'adjust') {
      if (event.target.checked) {
        this.adjustmentList[0].isSelected = false;
        if (Number(event.target.value) > 0) {
          this.AdjustmentPermissionList.push(Number(event.target.value));
          this.AdjustmentPermissionList = [...new Set(this.AdjustmentPermissionList)]
          let a = this.adjustmentList.find(x => x.id == event.target.value)
          a.isSelected = true;
        }
      }
      else {
        if (Number(event.target.value) > 0) {
          this.adjustmentList[0].isSelected = false;
          this.AdjustmentPermissionList = [...new Set(this.AdjustmentPermissionList)]
          this.AdjustmentPermissionList.forEach((value, index) => {
            //    console.log(value, 'value');
            if (value == event.target.value) this.AdjustmentPermissionList.splice(index, 1);
          });
          let a = this.adjustmentList.find(x => x.id == event.target.value)
          a.isSelected = false;
        }
      }

      if ((id == 0) && (event.target.checked == true)) {
        for (let i = 0; i < this.adjustmentList.length; i++) {
          this.adjustmentList[i].isSelected = true;
          if (this.adjustmentList[i].isSelected == true) {
            this.AdjustmentPermissionList.push(this.adjustmentList[i].id)
          }
        }
      }
      else if ((id == 0) && (event.target.checked == false)) {
        for (let i = 0; i < this.adjustmentList.length; i++) {
          this.adjustmentList[i].isSelected = false;
          this.AdjustmentPermissionList = []
        }
      }
    }

    //console.log(this.count,'this.count')
    // console.log(this.isNotSelectedCount,'this.isNotSelectedCount')
    if (this.AllCheckboxValid == true && (this.count == 1 || this.isNotSelectedCount == 0)) {
      if (this.isNotSelectedCount > 0) {
        this.AllCheckBox = false;
      }
      else {
        this.AllCheckBox = true;
      }
    } else if (this.AllCheckboxValid == false && (this.count > 1 || this.isNotSelectedCount > 0)) {
      this.AllCheckBox = false;
    }
    //}
    // else {
    //   if (event.target.checked) {
    //     this.RolePermissionList.push(Number(event.target.value));
    //   }
    //   else {
    //     this.RolePermissionList = this.RolePermissionList.filter(id => id != 52);
    //   }
    //}
  }

  clickEvent(e) {
    if (e == 'Adjust') {
      this.isShownA = true;
      this.isShownC = false;

      this.adjustmentList[0].isSelected = false;
      for (let i = 0; i < this.adjustmentData.length; i++) {
        this.adjustmentList[i + 1].isSelected = Boolean(this.adjustmentData[i].isSelected);

        if (Boolean(this.adjustmentData[i].isSelected) == true) {
          this.AdjustmentPermissionList.push(this.adjustmentData[i].permissionId)
        }
      }
    }
    else {
      this.isShownA = false;
      this.isShownC = true;
    }
  }

  GetAdjustmentListData() {
    const adjustList: SelectAdjustGeneral = {
      id: 0,
      tenantid: this.loginInfo.tenantId,
    }
    this.adjust.SelectAdjustmentGeneral(adjustList) // select method
      .pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.adjustmentList = res.filter(x => x.isActive == true)
        this.adjustmentList.push(All_Data);
        this.adjustmentList.sort((a, b) => (a.id < b.id ? -1 : 1));
        //  console.log(this.adjustmentList,"adjst");
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  //  Realese the memory
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.RolePermissionForm.patchValue(this.helper.getValue('GetSessionData3'));
    this.roleperdetailsData_child.emit(this.RolePermissionForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData3', this.RolePermissionForm.value);
    this.helper.setValue('OnDirty3', true);
    this.roleperdetailsData_child.emit({ data: data, event: "RolepermissionPage", val: true });
  }

  onCheckTimeOnly(e) {
    // this.isTimeClock = e;
    if (e.currentTarget.checked == true) {
      this.openModal();
    }
    else {
      this.isDisabled = false;
      this.isPermission = false;
      this.RolePermissionForm.controls['allPermission'].enable();
    }
  }

  openModal() {
    this.bsModalRef = this.modalService.show(PermissionsModalComponent, {
      class: 'modalComp modal-popUp modal-l',
      backdrop: 'static',
      keyboard: true,
    });
    this.bsModalRef.content.messageEvent.subscribe((data) => {
      this.dff = data;
      if (this.dff == false) {
        this.isDisabled = false;
        this.isPermission = false;
        this.RolePermissionForm.controls['timeClockOnly'].setValue(false);
      }
      else {
        this.AllCheckboxValid = false;
        this.AllCheckBox = false;
        this.isNotSelectedCount = this.permissionData.filter(x => x.isSelected == false).length;
        for (let i = 0; i < this.permissionData.length; i++) {
          this.permissionData[i].isSelected = false;
          this.RolePermissionList = [];
          this.isSelectedCount = 0;
        }
        this.isDisabled = true;
        this.isPermission = true;
        this.RolePermissionForm.controls['allPermission'].disable();
      }
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}