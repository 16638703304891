import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-discount-fliptable-footer',
  templateUrl: './discount-fliptable-footer.component.html',
  styleUrls: ['./discount-fliptable-footer.component.css']
})
export class DiscountFliptableFooterComponent implements OnInit {
  @Output() public checkAudit = new EventEmitter();
  @Input() displayedColumns;
  @Input() dataSource;
  @Input() columns;
  @Input() footerHide;
  @Input() tableFooDisplay;
  @Input() employeediscounttableShow;
  @ViewChild(MatSort) sort: MatSort;
  rowClicked : any;
  @Output() public tableItem = new EventEmitter();
  @Output() public checkItem = new EventEmitter();
  constructor() { }

  ngOnInit(): void {

  }
  @Input()
  public set employeeItem(tableRowIdcolor) {
      this.rowClicked = tableRowIdcolor;
  }
  getTotalCost() {
    return this.dataSource.map(t => t.quantity).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost1() {
    return this.dataSource.map(t => t.grossSales).reduce((acc, value) => acc + value, 0);
  }
  getTotalCostNet() {
    return this.dataSource.map(t => t.netSales).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost2() {
    return this.dataSource.map(t => t.data).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost3() {
    return this.dataSource.map(t => t.data1).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost4() {
    return this.dataSource.map(t => t.data2).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost5() {
    return this.dataSource.map(t => t.data3).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost6(){
    return this.dataSource.map(t => t.amount).reduce((acc, value) => acc + value, 0);
  }
  getTotalCost7(){
    return this.dataSource.filteredData.map(t => t.ofdiscounts).reduce((acc, value) => acc + value, 0);
  }8
  getTotalCost8(){
    return this.dataSource.filteredData.map(t => t.total).reduce((acc, value) => acc + value, 0);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  changeTableRowColor(id?: any, row?: any) {
    this.rowClicked = ""
    this.rowClicked = this.rowClicked === id? -1 : id;
    this.tableItem.emit(row)
  }
  itemClick(e){
    this.checkItem.emit(e);
  }
  
  auditClick(e){
    this.checkAudit.emit(e);
  }
}
