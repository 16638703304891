<div class="Gen_wrap">
    <form class="loginForm GeneralForm" [formGroup]="GeneralForm" (change)="onchange(GeneralForm.value)">
        <div class="pull-right-active">
            <span>Active</span>
            <div class="checkbox-table accordion-checkbox" id="checkbox-box">
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" class="custom-control-input" name="customCheckbox" id="activeForm" formControlName="isActive" checked="isActive">
                    <label class="custom-control-label" for="activeForm"></label>
                </div>
            </div>
        </div>
        <br>
        <!-- <label *ngIf="!(dealerEmp || compEmp )">Employee of ISV</label>
    <div class="row w-100" *ngIf="dealerEmp">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="customlable mb-0">Employee of Dealer</label>
          <mat-form-field appearance="fill"
            [ngClass]="{ 'is-invalid': submittedGeneral && SavegeneralGroup.dealer.errors }">
            <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="dealer">
              <mat-option *ngFor="let item of dealer" [value]="item.id">{{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row w-100" *ngIf="compEmp">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="customlable mb-0">Employee of Company</label>
          <mat-form-field appearance="fill">
          <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="company">
            <mat-option *ngFor="let item of comp" [value]="item.id">{{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div>
      </div>
    </div> -->
        <label class="custom-color" *ngIf="!(dealerEmp || compEmp )">Employee of ISV</label>
        <div class="row w-100">
            <div class="col-sm-4">
                <!-- <div class="form-group" *ngIf="dealerEmp" [style.display]="dealerHierarchyHide ? 'none' : 'block'">
          <label class="customlable mb-0">Employee of Dealer</label>
                 <mat-form-field appearance="fill">
            <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="dealer" [disabled]="isDisabled ? true : false" >
              <mat-option *ngFor="let item of dealer" [value]="item.id">{{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
                <!-- <div class="form-group" *ngIf="compEmp" [style.display]="companyHierarchyHide ? 'none' : 'block'">
          <label class="customlable mb-0">Employee of Company</label>
               <mat-form-field appearance="fill">
          <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="company" [disabled]="isDisabled ? true : false" >
            <mat-option *ngFor="let item of comp" [value]="item.id">{{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div> -->
                <div class="form-group">
                    <label class="customlable mb-0">First Name</label> <label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="firstName" appInputRestriction placeholder="First Name" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.firstName.errors) || (requiredError && GeneralData.firstName.errors)}"
                        maxlength="25" (input)="getMaxValueFName($event)" (keypress)="Highlight()" (focusout)="EmpGenValid()" />
                    <div class="error">{{errorValue1}}</div>
                    <div *ngIf="(submittedGeneral && GeneralData.firstName.errors) || (requiredError && GeneralData.firstName.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.firstName.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Last Name</label><label class="text-danger">*</label>
                    <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.lastName.errors) || (requiredError && GeneralData.lastName.errors)}" maxlength="25"
                        (input)="getMaxValueLName($event)" (keypress)="Highlight()" (focusout)="EmpGenValid()" />
                    <div class="error">{{errorValue2}}</div>
                    <div *ngIf="(submittedGeneral && GeneralData.lastName.errors) || (requiredError && GeneralData.lastName.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.lastName.errors.required">Last Name is required</div>
                    </div>
                </div>
                <div class="form-group" *ngIf="!dealerHierarchyHide">
                    <label class="customlable mb-0">POS Name</label>
                    <input type="text" class="form-control" formControlName="posName" placeholder="POS Name" appInputRestriction maxlength="25" (input)="getMaxValue($event)" />
                    <div class="error">{{errorValue}}</div>
                </div>

                <div class="form-group">
                    <label class="customlable mb-0">Login ID</label><label class="text-danger">*</label>
                    <input type="text" class="form-control" formControlName="loginId" placeholder="Login ID" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.loginId.errors) || (requiredError && GeneralData.loginId.errors)}" maxlength="7" (keypress)="numberOnly($event) &&  Highlight()"
                        (focusout)="EmpGenValid()" />
                    <div *ngIf="(submittedGeneral && GeneralData.loginId.errors) || (requiredError && GeneralData.loginId.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.loginId.errors.required">Login ID is required</div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="customlable mb-0">Hire Date</label><label class="text-danger" *ngIf="!dealerHierarchyHide">*</label>
                    <mat-form-field appearance="fill" class="custom-date">
                        <input matInput [matDatepicker]="picker" placeholder="Hire Date" class="form-control datepicker appInputRestriction" formControlName="employeeHireDate" (click)="picker.open()" (dateChange)="Highlight()" [min]="minDate">
                        <mat-datepicker #picker class="example-header"></mat-datepicker>
                    </mat-form-field>
                    <div class="error">{{dateMgsHire}}</div>

                    <div *ngIf="(submittedGeneral && GeneralData.employeeHireDate.errors) || (requiredError && GeneralData.employeeHireDate.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.employeeHireDate.errors.required">Hire Date is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Date Of Birth</label>
                    <mat-form-field appearance="fill" class="custom-date">
                        <input matInput [matDatepicker]="pickerA" placeholder="MM-DD-YYYY" class="form-control datepicker appInputRestriction" formControlName="employeeBirthday" (click)="pickerA.open()" [max]="maxDate" (dateChange)="Highlight()" [min]="minDate">
                        <mat-datepicker #pickerA class="example-header"></mat-datepicker>
                    </mat-form-field>
                    <div class="error">{{dateMgs}}</div>
                    <!-- <input type="text" placeholder="MM-DD-YYYY" class="form-control" formControlName="employeeBirthday" [maxDate]="maxDate"
            bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" appInputRestriction> -->
                    <div class="error">{{errorMessage}}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Phone</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                <img src="assets/components/images/USA.png" alt="employee" class="flag_img">+1
              </span>
                        </div>
                        <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="phone" placeholder="(xxx) xxx-xxxx" maxLength="14" (keypress)="numberOnly2($event)" appInputRestriction (keydown)="onDeletePhone($event)" (focusout)="phoneNumberCheck($event)"
                            [ngClass]="{ 'is-invalid': GeneralForm.controls['phone'].errors && submittedGeneral && !isValidFormSubmitted || mgs!=''}" />
                    </div>
                    <div class="error">{{mgs}}</div>
                    <div *ngIf="GeneralForm.controls['phone'].errors && submittedGeneral && !isValidFormSubmitted" [ngClass]="'error'">
                        <div *ngIf="GeneralForm.controls['phone'].errors.pattern && mgs==''">
                            Mobile Number not valid.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="customlable mb-0">Address</label>
                    <input type="text" class="form-control" formControlName="address" placeholder="Address" appInputRestriction/>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">City</label>
                    <input type="text" class="form-control" formControlName="city" placeholder="City" appInputRestriction maxlength="25" (input)="getMaxValueCity($event)" />
                    <div class="error">{{errorValue5}}</div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">State</label>

                    <div class="StateWrap">
                        <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" #select1 formControlName="state" placeholder="Select State" (selectionChange)="getSubtier($event.value)">

                            <input class="form-control input-lg inputbox_login" placeholder="Search here" (keyup)="onKey($event.target.value)" maxlength="25" (input)="getMaxValueTerritory($event)" />

                            <mat-option *ngFor="let item of filteredList2" [value]="item.id">
                                {{item.id}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Zip Code</label>
                    <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code" [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" (focusout)="zipCodeCheck($event)" (keydown)="onDeleteZipCode($event)" [ngClass]="{ 'is-invalid': zipCodeMsg!=''}"
                    />
                    <div class="error">{{zipCodeMsg}}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <!-- <div class="form-group">
          <span>Back Office Access</span><br>
          <div class="switchToggle">
            <input type="checkbox" id="backofficeAccess" formControlName="backofficeAccess"
              (change)="togglebackoffice(GeneralForm.get('backofficeAccess').value)">
            <label class="toggle-button-switch" for="backofficeAccess"></label>
            <div class="toggle-button-text outshadow">
              <div class="toggle-button-text-on">ON</div>
              <div class="toggle-button-text-off">OFF</div>
            </div>
          </div>
        </div> -->
                <div class="form-group">
                    <label>Back Office Access</label><br>
                    <label class="switch">
            <input type="checkbox" formControlName="backofficeAccess"
            (change)="togglebackoffice(GeneralForm.get('backofficeAccess').value)">
            <span class="slider round"></span>
            <div class="toggle-button-text outshadow employee_onoff">
              <div class="toggle-button-text-on"></div>
              <div class="toggle-button-text-off"></div>
            </div>
          </label>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Email</label><label class="text-danger" *ngIf="GeneralForm.get('backofficeAccess').value">*</label>
                    <input type="text" class="form-control" formControlName="emailId" appInputRestriction placeholder="&#x2709; Example@myemailaddress.com" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.emailId.errors) || (requiredError && GeneralData.emailId.errors)}"
                        maxlength="100" (input)="getMaxValueEmail($event)" (keypress)="Highlight()" (focusout)="EmpGenValid()" />
                    <div class="error">{{errorValue3}}</div>

                    <div *ngIf="(submittedGeneral && GeneralData.emailId.errors) || (requiredError && GeneralData.emailId.errors)" class="invalid-feedback">
                        <!-- <div *ngIf="f.email.errors.required">Email is required</div> -->
                        <div *ngIf="GeneralData.emailId.errors.email">Email must be a valid email address</div>
                        <div *ngIf="GeneralForm.get('backofficeAccess').value">
                            <div *ngIf="GeneralData.emailId.errors.required">Email is required</div>
                        </div>
                    </div>
                </div>

                <div class="form-group" *ngIf="GeneralForm.get('backofficeAccess').value">
                    <label class="customlable mb-0">Password</label><label class="text-danger">*</label>
                    <input type="password" class="form-control" formControlName="password" placeholder="Password" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.password.errors) || (requiredError && GeneralData.password.errors)}" (keypress)="Highlight()"
                        (focusout)="EmpGenValid()" />
                    <strength-checker barLabel="Strength: " [passwordToVerify]="GeneralForm.value.password" (passwordStrength)="passwordValid($event)">
                    </strength-checker>
                    <div *ngIf="(submittedGeneral && GeneralData.password.errors) || (requiredError && GeneralData.password.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.password.errors.required">Password is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-right">
                <div class="form-group">
                    <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral();" [disabled]="buttonDisable ? true : null">
            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label>
          </button>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- ngx-intel -->
<!-- <ngx-intl-tel-input [cssClass]="'form-control country-tel-code'" [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true" [preferredCountries]="preferredCountries" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [tooltipField]="TooltipLabel.Name"
            [phoneValidation]="false" [separateDialCode]="separateDialCode" formControlName="phone">
          </ngx-intl-tel-input> -->
<!-- [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.phone.errors }" -->
<!-- <div *ngIf="submittedGeneral && GeneralData.phone.errors" class="invalid-feedback">
              <div *ngIf="GeneralForm.controls['phone'].errors">Phone number must be at least 10 numbers</div>
            </div> -->
<!-- ngx-intl -->
