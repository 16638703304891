import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
import { ICustomer } from '../discounts/discounts.component';
export interface PeriodicElement {
  id?: number;
  store?:any;
  cardno?:any;
  purchase?:any;
  amount?:any;
  employee?:any;
  promo?:any;
  lastused?:any;
  redeem?:any;
  balance?:any;
  total?:any;
  transaction?: any;
  checkno?: any;
  timeDate?:any;
  item?:any;
  move?:any;
}
export interface Transaction {
  item: string;
  cost: number;
}
const ELEMENT_DATA1: PeriodicElement[] = [
  { id: 1, store: 'Chiptole NY', cardno: 9907467214223535, purchase: '11/1/2021', amount:50.00, employee: 'Mike J', promo: 10.00, lastused: '6/25/2000', redeem: 10.00, balance: 40.00},
  { id: 2, store: 'Chiptole NY', cardno: 9907467214223536, purchase: '11/1/2021', amount:50.00, employee: 'Mike J', promo: 10.00, lastused: '6/25/2000', redeem: 10.00, balance: 40.00},
  { id: 3, store: 'Chiptole NY', cardno: 9907467214223537, purchase: '11/1/2021', amount:50.00, employee: 'Mike J', promo: 10.00, lastused: '6/25/2000', redeem: 10.00, balance: 40.00}
];

const ELEMENT_DATA2: PeriodicElement[] = [
  { id: 1, store: 'Chiptole NY', cardno: 9907467214223535, purchase: '11/1/2021', amount:50.00, employee: 'Mike J', transaction: 'Used', checkno: 'OLO10', balance: 40.00},
  { id: 1, store: 'Chiptole NY', cardno: 9907467214223535, purchase: '11/1/2021', amount:50.00, employee: 'Mike J', transaction: 'Used', checkno: 'OLO10', balance: 40.00},
  { id: 1, store: 'Chiptole NY', cardno: 9907467214223535, purchase: '11/1/2021', amount:50.00, employee: 'Mike J', transaction: 'Used', checkno: 'OLO10', balance: 40.00},
];

const ELEMENT_DATA3: PeriodicElement[] = [
  { id: 4, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: 'Diet Pepsi', move: 'Added', total: '$3.50'},
  { id: 5, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: '', move: 'Order Sent', total: ''},
  { id: 6, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: 'Cheeseburger', move: 'Added', total: '$13.99'},
  { id: 7, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: 'French Dip', move: 'Added', total: '$10.99'},
  { id: 8, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: 'Mountain Dew', move: 'Added', total: '$3.50'},
  { id: 9, employee: 'Garrett H', timeDate: '1-20-22 11:15 AM', item: '', move: 'Order Sent', total: ''},
];


@Component({
  selector: 'app-giftcard-liability-report',
  templateUrl: './giftcard-liability-report.component.html',
  styleUrls: ['./giftcard-liability-report.component.css']
})
export class GiftcardLiabilityReportComponent implements OnInit {

  loginInfo:any
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public backSalesItem = new EventEmitter
  public employeeName: any;
  public employee: any;
  public employeeIcon: any
  public Filtertransactions:any=[];
  ELEMENT_DATA1=[];
  ELEMENT_DATA2=[];
  ELEMENT_DATA3=[];
  transactions=[];
  CustomerDetails=[];
  closedData=[];
  public discountTotal:any;
  public subTotal:any;
  public netTotal:any;

  public CustomerList: ICustomer[] = [
    {firstName:'',lastName:'',city:'',email:'',address:'',phone:'',notes:'',state:'',zip:''}
  ]

  public displayedColumns1 = [];
  columns1 = [];
  public dataSource1: any
  public displayedColumns2 = [];
  columns2 = [];
  public dataSource2: any
  public displayedColumns3 = [];
  columns3 = [];
  public dataSource3: any

  public firstTableShow:boolean = false
  public secondTableShow:boolean = false
  public thirdTableShow:boolean = false
  public auditShow:boolean = false
  public backToSales:boolean = false
  signature: any;
  public auditName:any
  public auditDate:any
  public auditTime:any
  public auditEmployee:any
  firstTableName:any;
  public summartItems:any=[];

  public displayedColumns: string[] = ['item', 'cost'];
  TipsAmount: any;
  // public transactions: Transaction[] = [
  //   {item: 'Diet Pepsi', cost: 3.50},
  //   {item: 'Cheeseburger', cost: 13.99},
  //   {item: 'French Dip', cost: 10.99},
  //   {item: 'Mountain Dew', cost: 3.50},
  // ];

  constructor(private formBuilder: FormBuilder,private helper: HelperService,private reportService:ReportWebSocketService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.giftCardReport");
    
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Gift Card Liability";
    this.employee = "Reports";
    this.employeeIcon = "assets/components/images/reports-icon.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    //this.getFirstTable()
  }
 
  GetCreditCardDetails(e?)
  {   
    this.dataSource1 = ELEMENT_DATA1;
    this.firstTableShow = true;
    this.secondTableShow = false;
    this.thirdTableShow = false;
    this.displayedColumns1 = ['store','cardno', 'purchase','amount','employee','promo','lastused','redeem','balance'];
  
    this.columns1 = [
      { columnDef: 'store', header: "Store" },
      { columnDef: 'cardno', header: "Card#" },
      { columnDef: 'purchase', header: "Purchase Date" },
      { columnDef: 'amount', header: "Amount" },
      { columnDef: 'employee', header: "Employee" },
      { columnDef: 'promo', header: "Promo Amount" },
      { columnDef: 'lastused', header: "Last Used" },
      { columnDef: 'redeem', header: "Total Redeemed" },
      { columnDef: 'balance', header: "Balance" },
    ]
  
  }

  getSecondTable(e?) {
    this.firstTableShow = false;
    this.secondTableShow = true;
    this.thirdTableShow = false;
    this.firstTableName = e;
    this.displayedColumns2 = ['store','cardno', 'purchase','amount', 'employee','transaction','checkno','balance'];
    //this.dataSource2 = this.ELEMENT_DATA2;
      this.dataSource2 =  ELEMENT_DATA2;
     this.columns2 = [
      { columnDef: 'store', header: "Store" },
      { columnDef: 'cardno', header: "Card #" },
      { columnDef: 'purchase', header: "Purchase Date" },
      { columnDef: 'amount', header: "Amount" },
      { columnDef: 'employee', header: "Employee" },
      { columnDef: 'transaction', header: "Transaction" },
      { columnDef: 'checkno', header: "Check #" },
      { columnDef: 'balance', header: "Balance" },
    ]
  }

  getthirdTable(e?) {
    this.auditShow = true;
    this.secondTableShow = false;
    this.thirdTableShow = true
    this.displayedColumns3 = ['employee','timeDate', 'item','move', 'total'];
    this.auditName = e;
  //  this.dataSource3 = ELEMENT_DATA3;

    this.dataSource3 = ELEMENT_DATA3;
    this.dataSource3 = this.dataSource3.filter(x => x.ticketid==e.TicketID && x.move!='closed').sort((a, b) => a.timeDate < b.timeDate ? -1 : a.timeDate > b.timeDate ? 1 : 0)
    this.closedData=this.ELEMENT_DATA3.filter(x => x.ticketid==e.TicketID && x.move=='closed')
    this.transactions=[ ...this.dataSource3, ...this.closedData];
    this.dataSource3=this.transactions;
    this.columns3 = [
      { columnDef: 'employee', header: "Employee" },
      { columnDef: 'timeDate', header: "Time/Date" },
      { columnDef: 'item', header: "Item" },
      { columnDef: 'move', header: "Move" },
      { columnDef: 'total', header: "Total" },
      //{ columnDef: 'ticketid', header: "ticketid" },
    ]
  }
  onClickCheck(e){
    this.getthirdTable(e);
  }

  onClickCard(e){
    this.getSecondTable(e);
  }

  onChangeRow(e){
    // if(e.sessionid == 1){
    //   this.firstTableName = e.name
    //   this.firstTableShow = false;
    //   this.secondTableShow = true;
    //   this.getSecondTable(e)
    // }else if(e.sessionid == 2){
    //   this.firstTableShow = false;
    //   this.secondTableShow = false;
    //   this.thirdTableShow = true;
    //   this.auditName = e.check;
    //   this.auditEmployee = e.employee;

    //   let dateAssign= e.timeDate.substring(0, 8);
    //   let timeAssign= e.timeDate.substring(9, e.timeDate.length);

    //   this.auditDate= dateAssign;
    //   this.auditTime = timeAssign;

    //   if (this.CustomerDetails.filter(x=>x.ticketid==e.TicketID).length!=0)
    //   {
    //   this.CustomerDetails= this.CustomerDetails.filter(x=>x.ticketid==e.TicketID)
    //   }
    //   else

    //   {
    //   this.CustomerDetails = this.CustomerList;
    //   }
      
    //   this.getthirdTable(e);
    //   this.GetSummaryItems(e);

    // }
  }

  GetSummaryItems(e?)
  {   
    this.transactions=this.summartItems.filter(x=>x.ticketid==e.TicketID)
    console.log(e,'this.transactions')
 
    this.discountTotal=this.summartItems.filter(x=>x.ticketid==e.TicketID).reduce((sum, current) => sum + current.discount, 0);
    this.subTotal=this.summartItems.filter(x=>x.ticketid==e.TicketID).reduce((sum, current) => sum + Number(current.cost), 0);
    this.TipsAmount=this.summartItems[0].TipsAmount;

    this.netTotal=Number(this.subTotal)+Number(this.TipsAmount)-Number(this.discountTotal);
    this.Filtertransactions=this.transactions.filter(y=>y.itemType=='Main');  
  }


  onBackClick(x){
    if(x == 1){
      this.backToSales = true
      // this.backSalesItem.emit(this.backToSales)
    }else 
    if(x == 2){
      this.firstTableShow = true;
      this.secondTableShow = false;
      this.thirdTableShow = false;
    }else if(x == 3){
      this.firstTableShow = false;
      this.thirdTableShow = false;
      this.secondTableShow = true;
      this.auditShow = false;
      
    }
  }



}
