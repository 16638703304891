import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
  Host,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { HelperService } from 'src/app/helper/helper.service';
import { EmployeeService } from 'src/app/services/employee.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TimeEditComponent } from 'src/app/views/employee/time-edit/time-edit.component';
import { Observable, of } from 'rxjs';
import { TimeEditModelComponent } from 'src/app/views/employee/time-edit-model/time-edit-model.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-fliptable-accordion',
  templateUrl: './fliptable-accordion.component.html',
  styleUrls: ['./fliptable-accordion.component.css'],

  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class FliptableAccordionComponent implements OnInit {
  public tableValues: any;
  public modelValueTotable: any;
  public fromdate: any;
  public todate: any;
  @Input() displayedColumns;
  @Input() dataSource;
  @Input() columns;
  @Input() rowColor;
  @Input() punchInOut: boolean = false;
  @Input() timeEditModal: boolean = true;
  @Input() timeEditAccordion: boolean = true;
  @Input() employeePositionHide: boolean = false;
  @Input() EmpByJobID;
  @ViewChild(MatSort) sort: MatSort;
  jobModelID: any;
  //rowClicked;
  rowClicked: any;
  empTimeID: any;
  empTimeJobID: any;
  timeModelEvent: any;
  @Output() public timeEdit = new EventEmitter();
  @Output() public timeGetID = new EventEmitter();
  @Output() public modelValues = new EventEmitter();
  @Output() public tableToMainValue = new EventEmitter();
  @Output() public timeeditsessData = new EventEmitter();

  isPressAddButton: boolean = false;

  @Input() public buttonState;
  @Input() public OnSaveBit;
  @ViewChild(TimeEditModelComponent) timeedit_Comp: TimeEditModelComponent;
  // @ViewChild(TimeEditComponent)  timeComp: TimeEditComponent;

  isExpansionDetailRow = (i: number, row: Object) =>
    row.hasOwnProperty('detailRow');
  expandedElement: any;
  counter: any;

  constructor(
    private tableDetails: EmployeeService,
    private ref: ChangeDetectorRef,
    private helper: HelperService,
    private dialogser: DialogService
  ) {
    //console.log(this.OnSaveBit, 'FilterTable')
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  @Input()
  public set jobPositionItem(id) {
    this.EmpByJobID = id;
    this.jobModelID = this.EmpByJobID;
  }

  openModalEvent(e) {
    this.timeModelEvent = e;
    this.timeEditModal = this.timeModelEvent;
    console.log(this.timeEditModal, 'inopenModal');
    this.addTimeEditAddpunchModal();
  }

  async changeTableRowColor(idx?: any, empid?: any, name?: any) {
    if (
      this.helper.getValue('OnDirty') == false &&
      this.helper.getValue('OnDirty2') == false &&
      this.helper.getValue('OnDirty3') == false &&
      this.helper.getValue('OnDirty4') == false
    ) {
      this.timeEditModal = true;
      this.timeEditAccordion = true;
      this.rowClicked = 0;
      this.rowClicked = this.rowClicked === idx ? -1 : idx;
      this.empTimeID = this.rowClicked;
      this.empTimeJobID = empid;
      console.log(
        this.helper.getValue('GetSessionData1'),
        "this.helper.getValue('GetSessionData1')"
      );
    } else {
      if (idx != this.rowClicked) {
        var conf = await this.dialogser.confirm('TimePage');
        if (conf == 'success') {
          // Navigate Away
          this.timeEditModal = true;
          this.timeEditAccordion = true;
          this.rowClicked = 0;
          this.rowClicked = this.rowClicked === idx ? -1 : idx;
          this.empTimeID = this.rowClicked;
          this.empTimeJobID = empid;
          this.helper.setValue('OnDirty', false);
          this.counter = 0;
        } else {
          this.timeedit_Comp.TimeEditDetailsForm.patchValue(
            this.helper.getValue('GetSessionData1')
          );
          //this.helper.setValue('OnDirty',false);
        }
      } else {
      }
    }
  }

  async addTimeEditAddpunchModal() {
    if (
      this.helper.getValue('OnDirty') == false &&
      this.helper.getValue('OnDirty2') == false &&
      this.helper.getValue('OnDirty3') == false &&
      this.helper.getValue('OnDirty4') == false
    ) {
      if (this.empTimeID == 0 || this.empTimeID == null) {
        this.helper.setValue('PrimaryId', null);
        this.helper.setValue('IsSave', true);
        this.punchInOut = !this.punchInOut;
        this.timeEditModal = !this.timeEditModal;
        this.empTimeID = 0;
        this.rowClicked = 0;
        // this.timeedit_Comp.TimeEditDetailsForm.reset();
      } else if (
        this.timeEditModal == true &&
        this.empTimeID != 0 &&
        this.empTimeID != null
      ) {
        this.helper.setValue('PrimaryId', null);
        this.helper.setValue('IsSave', true);
        this.punchInOut = !this.punchInOut;
        this.timeEditModal = !this.timeEditModal;
        this.empTimeID = 0;
        this.rowClicked = 0;
        this.timeedit_Comp.TimeEditDetailsForm.reset();
      } else {
        this.timeEditModal = !this.timeEditModal;
      }
    } else {
      var conf = await this.dialogser.confirm('TimePage');
      if (conf == 'success') {
        // Navigate Away
        if (this.empTimeID == 0 || this.empTimeID == null) {
          this.helper.setValue('PrimaryId', null);
          this.helper.setValue('IsSave', true);
          this.punchInOut = !this.punchInOut;
          console.log(this.timeEditModal, 'addTimeEditAddpunchModal');
          this.empTimeID = 0;
          this.rowClicked = 0;
          this.timeedit_Comp.TimeEditDetailsForm.reset();
          this.helper.setValue('OnDirty', false);

        } else if (
          this.timeEditModal == true &&
          this.empTimeID != 0 &&
          this.empTimeID != null
        ) {
          this.helper.setValue('PrimaryId', null);
          this.helper.setValue('IsSave', true);
          this.punchInOut = !this.punchInOut;
          console.log(this.timeEditModal, 'addTimeEditAddpunchModal');
          this.timeEditModal = !this.timeEditModal;
          this.empTimeID = 0;
          this.rowClicked = 0;
          this.timeedit_Comp.TimeEditDetailsForm.reset();

          ////////// Setting to false to avoid looping of acoordion open close events/////////
          this.helper.setValue('OnDirty', false);
          ////////// Setting to false to avoid looping of acoordion open close events/////////
        } else {
          this.timeEditModal = !this.timeEditModal;
        }
      } else {
        this.timeedit_Comp.TimeEditDetailsForm.patchValue(
          this.helper.getValue('GetSessionData1')
        );
      }
    }
  }

  getEditTableDetail(event) {
    this.modelValueTotable = event;
    this.tableToMainValue.emit(this.modelValueTotable);
  }

  onsavetimeclock() {
    this.timeedit_Comp.onSaveTimeEdit();
  }

  gettimeeditData(e) {
    this.timeeditsessData.emit(e);
  }
}
