import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SignalRoutingModule } from './signal-routing.module';
import { ReceiverComponent } from './receiver/receiver.component';
import { ChatComponent } from './chat/chat.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ContractComponent } from './contract/contract.component';
import { TimeClockWebsocketComponent } from './time-clock-websocket/time-clock-websocket.component';



@NgModule({
  declarations: [
    ReceiverComponent,
    ChatComponent,
    ContractComponent,
    TimeClockWebsocketComponent
    
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SignalRoutingModule,
    MatInputModule,
    FormsModule
  ],
  exports:[
    ReceiverComponent,
    ChatComponent,
    ContractComponent,
    TimeClockWebsocketComponent

  ]
})
export class SignalModule { }
