import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { EmployeeGeneralComponent } from 'src/app/views/employee/employee-general/employee-general.component';

@Component({
  selector: 'app-filter-table-popup',
  templateUrl: './filter-table-popup.component.html',
  styleUrls: ['./filter-table-popup.component.css']
})
export class FilterTablePopupComponent implements OnInit {

@Input() roleItem
@Input() public  detailItem

@ViewChild(EmployeeGeneralComponent) generalComponent: EmployeeGeneralComponent;
  readonlyVal: boolean;
constructor(public bsModalRef: BsModalRef) { }


  ngOnInit(): void {
    //this.generalComponent.GeneralForm.patchValue(this.detailItem);
    this.readonlyVal = true;
  }

}
