import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ChangeDetectorRef, } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { TagsService } from 'src/app/services/tags.service';
import { IScheduler, isdaypartlis, ISelectTagDetails, } from 'src/app/interfaces/i-tags';
import { TagFilterComponent } from '../tag-filter/tag-filter.component';
import { GetDevice } from 'src/app/interfaces/idevices';
import { TagPrintJobComponent } from '../tag-print-job/tag-print-job.component';
import { TagPrintPrinterComponent } from '../tag-print-printer/tag-print-printer.component';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DayPartScheduleComponent } from '../day-part-schedule/day-part-schedule.component';
import { DialogService } from 'src/app/services/dialog.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tags-general',
  templateUrl: './tags-general.component.html',
  styleUrls: ['./tags-general.component.css'],
})
export class TagsGeneralComponent implements OnInit {
  tagsGeneralForm: FormGroup;
  @ViewChild(TagPrintJobComponent) JobComp: TagPrintJobComponent;
  @ViewChild(TagPrintPrinterComponent) PrintComp: TagPrintPrinterComponent;
  @ViewChild(TagFilterComponent) FiltComp: TagFilterComponent;
  @ViewChild(DayPartScheduleComponent) daypartcomp: DayPartScheduleComponent;

  visibledaypart: any = false;
  tag_id: any = 0;
  submittedGeneral: any;
  loginInfo: any;
  public reportSec: boolean = false;
  public printSec: boolean = false;
  public filterSec: boolean = false;
  public dayPartSec: boolean = false;
  conv_daypartList: Array<isdaypartlis> = [];
  public iconShowSec: any;
  onIconId: any;
  errorValue: any;
  public table_render: boolean = true;
  //FilterList: any;
  public filterdatalistval: any;
  public FinalipadList = [];
  dayPartValue: boolean = false;
  public dataSource = new MatTableDataSource<any>();
  public displayedColumns = [];
  public columns = [];
  @Input() tagsID;

  public generalPrintDataemit: any;
  public generalJobDataemit: any;
  public tabletFilter: any;
  public JobList: any = [];
  public PrinterList: any = [];
  buttonDisable: boolean = false;
  public refreshjobprintval: any;
  indexExpanded: number = 0;
  filterPartValue: boolean = false;

  @ViewChild('table') table: MatTable<any>;
  @Output() public tags_refresh = new EventEmitter();
  @Output() public isInvalid = new EventEmitter();
  @Output() public tagsGenPageData_child = new EventEmitter();

  private onDestroy$: Subject<void> = new Subject<void>();

  requiredErrors: boolean = false;
  filterDataList: any;
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !btnst;
    }
  }

  jobs_Ignored_Object: any;
  printers_Assigned_Object: any;
  isclicked: boolean = false;
  public tagcatvalue: any;

  constructor(
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private toaster: ToasterService,
    private tagService: TagsService,
    private changeDetector: ChangeDetectorRef,
    private dialogser: DialogService
  ) { }

  ngOnInit(): void {
    this.dialogser.ClearSession();
    this.tag_id = 0;
    this.helper.setValue('tagfilterdata', []);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.tagsGeneralForm = this.formBuilder.group({
      PrintComp: [],
      JobComp: [],
      FiltComp: [],
      id: [0],
      filerid: [0],
      reportid: [0],
      printid: [0],
      isActive: [true],
      createdBy: [],
      modifiedBy: [],
      name: ['', Validators.required],
      tabletFilter: [false],
      useForReportFilter: [false],
    });
    this.dayPartValue = false;
  }

  get GeneralData() {
    return this.tagsGeneralForm.controls;
  }

  @Input()
  public set employeeItem(tableRowIdcolor) {
    if (tableRowIdcolor == 'transparent') {
      this.iconShowSec = false;
      this.reportSec = false;
      this.filterSec = false;
      this.printSec = false;
      this.dayPartSec = false;
      this.onIconId = undefined;
    }
  }

  @Input()
  public set DataIdx(tableRowId) {
    this.helper.setValue('OnSaveTagData', "");
    this.errorValue = '';
    if (tableRowId != undefined && tableRowId != null && tableRowId != '') {
      this.tag_id = tableRowId;
      this.iconShowSec = true;
      this.getTagtList(tableRowId);
    }
  }

  ClearTagGenData() {
    this.daypartcomp.ScheduleDayForm.reset();
    this.daypartcomp.ngOnInit();
    this.daypartcomp.weekDaysArray.push(this.daypartcomp.weekDays);
    this.daypartcomp.submittedGeneral = false;
    this.daypartcomp.FinalArray = [];
    this.daypartcomp.FinalRemoveArray = [];
    this.daypartcomp.FinalSaveArray = [];
    this.visibledaypart = false;
  }

  drop(event) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        // event.previousContainer.data,
        event.previousContainer.data.filteredData,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.table_render = false;
      this.changeDetector.detectChanges();
      this.table_render = true;
    }
    this.FinalipadList = event.container.data;
  }

  getTableValue(e) {
    this.FinalipadList = e;
  }

  getdayPartEvent(e, index) {
    this.dayPartValue = e;
    if (this.dayPartValue == true) {
      this.indexExpanded = 0;
      this.indexExpanded = index == this.indexExpanded ? 0 : index;
    } else if (this.dayPartValue == false && this.filterPartValue == false) {
      this.indexExpanded = 0;
    } else {
      this.indexExpanded = 0;
    }
  }

  ValidateDayPart(tableRowId) {
    //  this.SerializeTheDaypartData();
    const isch: IScheduler = {
      id: tableRowId == undefined ? 0 : tableRowId,
      dayPartID: tableRowId == undefined ? 0 : tableRowId,
      tenantId: this.loginInfo.tenantId,
      isActive: true,
      createdBy: this.loginInfo.userId,
      modifiedBy: this.loginInfo.userId,
      daypartList: this.conv_daypartList,
    };
    if (this.conv_daypartList.length > 0) {
      this.tagService.SelectDayPartValidation(isch).pipe(takeUntil(this.onDestroy$)).subscribe((daypartvalidres) => {
        if (daypartvalidres.message.trim() == 'Success') {
          //this.onSaveGeneral();
          this.saveDaypart(tableRowId == undefined ? 0 : tableRowId);
        } else {
          this.toaster.errorPopUp('Unable to Save Tag Details');
          return;
        }
      }), (err) => console.error('Error', err), () => console.log('HTTP request completed.');
    }
  }

  onSaveGeneral() {
    this.errorValue = '';
    this.submittedGeneral = true;
    if (this.tagsGeneralForm.invalid) {
      return;
    }
    let _TagsDetails;
    _TagsDetails = this.tagsGeneralForm.value;
    _TagsDetails.id = parseInt(this.helper.getValue('tagIdvalue'));
    _TagsDetails.createdBy = this.loginInfo.userId;
    _TagsDetails.tenantId = this.loginInfo.tenantId;
    _TagsDetails.isActive = true;
    _TagsDetails.isdaypart = this.onIconId == 'DayPart' ? true : false;
    _TagsDetails.tagCategory =
      this.onIconId != undefined && this.onIconId != '' && this.onIconId != null
        ? this.onIconId
        : null;

    if (
      _TagsDetails.id == undefined &&
      _TagsDetails.id == null &&
      _TagsDetails.id == ''
    ) {
      _TagsDetails.id = 0;
    }
    this.tagcatvalue = this.onIconId;
    this.helper.setValue('tabletval', _TagsDetails.tabletFilter);

    if (this.onIconId == 'reports') {
      this.SaveMainTag(_TagsDetails);
    } else if (this.onIconId == 'prints') {
      this.SaveMainTag(_TagsDetails);
    } else if (this.onIconId == 'filter') {
      _TagsDetails.useForReportFilter = _TagsDetails.tabletFilter;

      this.SaveMainTag(_TagsDetails);
    } else if (this.onIconId == 'DayPart') {
      this.SerializeTheDaypartData();

      // if (this.daypartcomp.FinalSaveArray.length == 0) {
      //   this.toaster.errorPopUp('Kindly fill the all details correctly..!');
      // } 
      // else {
      this.SaveMainTag(_TagsDetails);
      //}

    }
    else {
      this.SaveMainTag(_TagsDetails);
    }
  }

  SaveMainTag(_TagsDetails) {
    this.tagService.SaveTagDetails(_TagsDetails).subscribe((response) => {
      var messages = response.message.split('-');
      this.submittedGeneral = false;
      this.tag_id = response.id;
      this.helper.setValue('tagIdvalue', response.id);
      if (response.id == 0) {
        this.toaster.errorPopUp(messages[1]);
        this.dialogser.ClearSession();
      } else {
        this.helper.setValue('onIconId_Ind', _TagsDetails.tagCategory);
        if (
          _TagsDetails.tagCategory == undefined ||
          _TagsDetails.tagCategory == null ||
          _TagsDetails.tagCategory == ''
        ) {
          this.toaster.successPopUp(messages[1]);
        } else if (_TagsDetails.tagCategory == 'filter') {
          this.FiltComp.onSaveFilter(
            response.id,
            this.tagsGeneralForm.value.name
          );
        } else if (_TagsDetails.tagCategory == 'DayPart') {
          this.ValidateDayPart(response.id);
        } else if (
          _TagsDetails.tagCategory == 'prints' ||
          _TagsDetails.tagCategory == 'reports'
        ) {
          this.SaveIconData(response);
        }
        this.tags_refresh.emit({ res: response, name: _TagsDetails.name });
      }
    });
  }
  //--------------------------------GET LIST DATA

  SaveIconData(TagId?) {
    if (this.onIconId == 'prints') {
      if (this.tag_id > 0) {
        this.isclicked = true;
        var ArrayJob = [];
        if (this.jobs_Ignored_Object != undefined) {
          if (this.jobs_Ignored_Object.length > 0) {
            let jobon = JSON.parse(this.jobs_Ignored_Object);
            for (var i = 0, len = jobon.length; i < len; i++) {
              ArrayJob.push(jobon[i].id);
            }
          }
        }

        var ArrayPrinter = [];
        if (this.printers_Assigned_Object != undefined) {
          if (this.printers_Assigned_Object.length > 0) {
            let printon = JSON.parse(this.printers_Assigned_Object);
            for (var i = 0, len = printon.length; i < len; i++) {
              ArrayPrinter.push(printon[i].id);
            }
          }
        }

        let _TagsDetails;
        _TagsDetails = this.tagsGeneralForm.value;
        _TagsDetails.id = _TagsDetails.printid;
        _TagsDetails.tagDetails_Id = this.tag_id;
        _TagsDetails.jobs_Ignored_Object = this.jobs_Ignored_Object;
        _TagsDetails.printers_Assigned_Object =
          this.printers_Assigned_Object == undefined
            ? null
            : this.printers_Assigned_Object.length == 0
              ? null
              : this.printers_Assigned_Object;
        _TagsDetails.createdBy = this.loginInfo.userId;
        _TagsDetails.tenantId = this.loginInfo.tenantId;
        _TagsDetails.isActive = true;
        _TagsDetails.jobs_Ignored_Id = ArrayJob.toString();
        _TagsDetails.printers_Assigned_Id = ArrayPrinter.toString();

        this.tagService
          .SaveTagPrint(_TagsDetails)
          .subscribe((printresponse) => {
            var messagePrint = printresponse.message.split('-');
            this.submittedGeneral = false;
            if (printresponse.id == 0) {
              this.toaster.errorPopUp(messagePrint[1]);
            } else {
              this.tagsGeneralForm.value.id = TagId.id;
              this.toaster.successPopUp(printresponse.message);
              this.dialogser.ClearSession();
              this.helper.setValue('GetSessionData1', _TagsDetails);
              this.tagsGenPageData_child.emit({
                data: _TagsDetails,
                event: 'tagsPage',
                val: false,
              });
            }
          });
      }
    } else if (this.onIconId == 'reports') {
      if (this.tag_id > 0) {
        this.isclicked = true;
        let _Tagsreports;
        _Tagsreports = this.tagsGeneralForm.value;
        _Tagsreports.id = _Tagsreports.reportid;
        _Tagsreports.tag_id = this.tag_id;
        _Tagsreports.useForReportFilter = _Tagsreports.useForReportFilter;
        _Tagsreports.createdBy = this.loginInfo.userId;
        _Tagsreports.tenantId = this.loginInfo.tenantId;
        this.tagService
          .SaveTagReports(_Tagsreports)
          .subscribe((reportresponse) => {
            var messageReport = reportresponse.message.split('-');
            this.submittedGeneral = false;
            if (reportresponse.id == 0) {
              this.toaster.errorPopUp(messageReport[1]);
            } else {
              this.toaster.successPopUp(reportresponse.message);
              this.dialogser.ClearSession();
              this.helper.setValue('GetSessionData1', _Tagsreports);
              this.tagsGenPageData_child.emit({
                data: _Tagsreports,
                event: 'tagsPage',
                val: false,
              });
            }
          });
      }
    }
  }

  getTagtList(rowId?) {
    if (rowId == undefined || rowId == null || rowId == '') {
      rowId = 0;
    }
    const tagList: ISelectTagDetails = {
      id: rowId,
      tenantId: this.loginInfo.tenantId,
    };
    this.tagService.SelectTagDetails(tagList).subscribe((res) => {
      if (res[0] != undefined && res[0] != null && res[0] != '') {
        this.onIconId = res[0].tagCategory;
        this.tagcatvalue = res[0].tagCategory;
        this.tagsGeneralForm.patchValue(res[0]);
        if (this.tagcatvalue != null && this.tagcatvalue != undefined) {
          if (this.tagcatvalue == 'prints') {
            this.visibledaypart = false;
            this.printSec = true;
            this.reportSec = false;
            this.filterSec = false;
            this.dayPartSec = false;
            this.getAvailableRolelist(this.tag_id);
            this.getAvailablePrinterList(this.tag_id);
            //----------------------------JOB
            if (
              res[0].JobList1 != undefined &&
              res[0].JobList1 != null &&
              res[0].JobList1 != '' &&
              res[0].JobList1 != '[]'
            ) {
              this.JobList = [];
              this.JobList = JSON.parse(res[0].JobList1);
            } else {
              this.JobList = [];
            }
            //----------------------------PRINTER
            if (
              res[0].PrinterListData1 != undefined &&
              res[0].PrinterListData1 != null &&
              res[0].PrinterListData1 != '' &&
              res[0].PrinterListData1 != '[]'
            ) {
              this.PrinterList = [];
              this.PrinterList = JSON.parse(res[0].PrinterListData1);
            } else {
              this.PrinterList = [];
            }
          } else if (this.tagcatvalue == 'filter') {
            this.visibledaypart = false;
            this.filterSec = true;
            this.reportSec = false;
            this.printSec = false;
            this.dayPartSec = false;

            if (
              res[0].FilterList1 != undefined &&
              res[0].FilterList1 != null &&
              res[0].FilterList1 != '' &&
              res[0].FilterList1 != '[]'
            ) {
              this.filterDataList = res[0];
            } else {
              this.filterDataList = [];
            }
          } else if (this.tagcatvalue == 'reports') {
            this.visibledaypart = false;
            this.reportSec = true;
            this.printSec = false;
            this.filterSec = false;
            this.dayPartSec = false;
          } else if (this.tagcatvalue == 'DayPart') {
            this.visibledaypart = true;
            this.dayPartSec = true;
            this.filterSec = false;
            this.reportSec = false;
            this.printSec = false;
            this.getdayPartEvent(this.dayPartSec, 1);
          } else if (this.tagcatvalue == '') {
            this.visibledaypart = false;
            this.filterSec = false;
            this.reportSec = false;
            this.printSec = false;
            this.dayPartSec = false;
          }
        } else {
          this.filterSec = false;
          this.reportSec = false;
          this.printSec = false;
          this.dayPartSec = false;
        }
      }
    });
  }

  //-------------------------------  Get Tag Print Printer

  getAvailablePrinterList(tableRowId) {
    if (tableRowId == undefined || tableRowId == null || tableRowId == '') {
      tableRowId = 0;
    }
    const printerList: GetDevice = {
      id: tableRowId,
      tenantId: this.loginInfo.tenantId,
    };
    this.tagService
      .SelectAvailablePrinter(printerList)
      .subscribe((Printres) => {
        if (Printres != undefined && Printres != null && Printres != '') {
          Printres = Printres.filter((val) => {
            return !this.PrintComp.PrinterListData.find((val2) => {
              //  console.log({valueID:val.id+":"+val2.id});
              return val.id === val2.id;
            });
          });

          this.PrintComp.dataSource.data = Printres;
          this.PrintComp.displayedColumns = ['name'];
          this.PrintComp.columns = [
            { columnDef: 'name', header: 'Printers Available' },
          ];
        } else {
          this.PrintComp.dataSource.data = [];
          this.PrintComp.displayedColumns = ['name'];
          this.PrintComp.columns = [
            { columnDef: 'name', header: 'Printers Available' },
          ];
        }
      });
  }

  getAvailableRolelist(rowId) {
    if (rowId == undefined || rowId == null || rowId == '') {
      rowId = 0;
    }
    const ItemTypeList: ISelectTagDetails = {
      id: rowId,
      tenantId: this.loginInfo.tenantId,
    };
    this.tagService.SelectAvailableJobs(ItemTypeList).subscribe((Jobres) => {
      if (Jobres != undefined && Jobres != null && Jobres != '') {
        Jobres = Jobres.filter((val) => {
          return !this.JobComp.JobList.find((val2) => {
            //  console.log({valueID:val.id+":"+val2.id});
            return val.id === val2.id;
          });
        });

        this.JobComp.dataSource.data = Jobres;
        this.JobComp.displayedColumns = ['name'];
        this.JobComp.columns = [{ columnDef: 'name', header: 'Jobs' }];
      } else {
        this.JobComp.dataSource.data = [];
        this.JobComp.displayedColumns = ['name'];
        this.JobComp.columns = [{ columnDef: 'name', header: 'Jobs' }];
      }
    });
  }

  //-----------------------------------

  onImgChange(e?) {
    if (this.tag_id == undefined || this.tag_id == null || this.tag_id == '') {
      this.tag_id = 0;
    }
    this.onIconId = e;
    this.helper.setValue('onIconId_Ind', e);
    if (e == 'reports') {
      this.visibledaypart = false;
      this.reportSec = true;
      this.printSec = false;
      this.filterSec = false;
      this.dayPartSec = false;
    } else if (e == 'prints') {
      this.visibledaypart = false;
      this.printSec = true;
      this.reportSec = false;
      this.filterSec = false;
      this.dayPartSec = false;
      this.getAvailableRolelist(this.tag_id);
      this.getAvailablePrinterList(this.tag_id);
    } else if (e == 'filter') {
      this.visibledaypart = false;
      this.filterSec = true;
      this.reportSec = false;
      this.printSec = false;
      this.dayPartSec = false;
    } else if (e == 'DayPart') {
      this.visibledaypart = true;
      this.dayPartSec = true;
      this.filterSec = false;
      this.reportSec = false;
      this.printSec = false;
      this.getdayPartEvent(this.dayPartSec, 1);
    }
  }

  //-----------------------------------------------

  getGeneralJobsDetail(event) {
    const keys_to_keep = ['id', 'name'];
    const reduxjob = (array) =>
      array.map((o) =>
        keys_to_keep.reduce((acc, curr) => {
          acc[curr] = o[curr];
          return acc;
        }, {})
      );
    if (event != undefined && event != null && event != '') {
      this.generalJobDataemit = reduxjob(event);
      this.jobs_Ignored_Object = JSON.stringify(this.generalJobDataemit);
    } else if (event.length == 0) {
      this.jobs_Ignored_Object = '';
    }
  }

  getGeneralPrinterDetail(event) {
    const keys_to_keep = ['id', 'name'];
    const reduxprint = (array) =>
      array.map((o) =>
        keys_to_keep.reduce((acc, curr) => {
          acc[curr] = o[curr];
          return acc;
        }, {})
      );
    if (event != undefined && event != null && event != '') {
      this.generalPrintDataemit = reduxprint(event);
      this.printers_Assigned_Object = JSON.stringify(this.generalPrintDataemit);
    } else if (event.length == 0) {
      this.printers_Assigned_Object = '';
    }
  }

  focusOutFunction() {
    if (this.tagsGeneralForm.invalid) {
      this.iconShowSec = false;
      this.requiredErrors = true;
      this.isInvalid.emit(true);
    } else {
      this.iconShowSec = true;
      this.requiredErrors = false;
      this.isInvalid.emit(false);
    }
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue = '';
    }
  }

  TagNameValid() {
    if (
      this.tagsGeneralForm.value.name != null &&
      this.tagsGeneralForm.value.name != ''
    ) {
      if (this.tagsGeneralForm.value.name.length < 0) {
        this.requiredErrors = true;
        this.isInvalid.emit(true);
      } else {
        this.requiredErrors = false;
        this.isInvalid.emit(false);
      }
    } else if (
      this.tagsGeneralForm.value.name == null ||
      (this.tagsGeneralForm.value.name == '' &&
        this.tagsGeneralForm.value.name.length == 0)
    ) {
      this.requiredErrors = false;
      this.isInvalid.emit(false);
    } else if (
      this.tagsGeneralForm.value.name == null ||
      this.tagsGeneralForm.value.name == ''
    ) {
      this.requiredErrors = true;
      this.isInvalid.emit(true);
    }
  }

  @Input()
  public set getrefreshjobprintval(ev) {
    this.refreshjobprintval = ev;
  }

  ngAfterViewInit() {
    this.tagsGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.tagsGenPageData_child.emit(this.tagsGeneralForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.tagsGenPageData_child.emit({ data: data, event: 'tagsPage', val: true });
  }

  SerializeTheDaypartData() {
    this.conv_daypartList = [];
    for (
      var a = 0,
      len = this.daypartcomp.ScheduleDayForm.value.daypartList.length;
      a < len;
      a++
    ) {
      if (
        this.daypartcomp.ScheduleDayForm.value.daypartList[a].daysObj != null &&
        this.daypartcomp.ScheduleDayForm.value.daypartList[a].daysObj != ''
      ) {
        if (
          this.daypartcomp.ScheduleDayForm.value.daypartList[a].startTime !=
          null
        ) {
          if (
            this.daypartcomp.ScheduleDayForm.value.daypartList[a].endTime !=
            null
          ) {
            this.conv_daypartList.push({
              daysObj:
                this.daypartcomp.ScheduleDayForm.value.daypartList[
                  a
                ].daysObj.toString(),
              endTime:
                this.daypartcomp.ScheduleDayForm.value.daypartList[a].endTime,
              startTime:
                this.daypartcomp.ScheduleDayForm.value.daypartList[a].startTime,
            });
          }
        }
      }
    }
  }

  saveDaypart(eventid) {
    if (eventid != 0) {
      var schdayvalue = this.daypartcomp.ScheduleDayForm.value;
      if (
        schdayvalue.daypartList[0].daysObj == undefined ||
        schdayvalue.daypartList[0].daysObj == null ||
        schdayvalue.daypartList[0].daysObj.length == 0
      ) {
        this.toaster.errorPopUp('Please Select the Days');
        return;
      }
      if (
        schdayvalue.daypartList[0].startTime == undefined ||
        schdayvalue.daypartList[0].startTime == null ||
        schdayvalue.daypartList[0].startTime == ''
      ) {
        this.toaster.errorPopUp('Please Select the Start Time');
        return;
      }
      if (
        schdayvalue.daypartList[0].endTime == undefined ||
        schdayvalue.daypartList[0].endTime == null ||
        schdayvalue.daypartList[0].endTime == ''
      ) {
        this.toaster.errorPopUp('Please Select the End Time');
        return;
      }
      this.daypartcomp.onSave(schdayvalue);
    }
  }

  //  Realese the memory
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  istagfilterdragndrop(e) {
    this.helper.setValue('OnDirty', true);
    this.tagsGenPageData_child.emit({ event: 'tagsPage', val: true });
  }

  gettagsPageData(e) {
    this.tagsGenPageData_child.emit(e);
  }
}
