import { CdkDragDrop, CdkDragExit, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MenuService } from 'src/app/services/menu.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { INFERRED_TYPE } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-sales-area-layout',
  templateUrl: './sales-area-layout.component.html',
  styleUrls: ['./sales-area-layout.component.css']
})
export class SalesAreaLayoutComponent implements OnInit {
  buttonDisable: boolean = false;
  SalesAreaLayId: any;
  submittedGeneral: any;
  salesarealayoutdata: any;
  loginInfo: any;
  public dataSourceAreaTable = new MatTableDataSource<any>();
  public displayedColumns = [];
  public columns = [];
  dataSourceTable: any;
  salesList: any;

  rowClicked: any;
  @Output() public rowDataEmit = new EventEmitter();
  @Output() public salesAreaDrop = new EventEmitter();
  @Output() public Popupmsg = new EventEmitter();

  public table_render: boolean = true;
  @Input() SalesFlipTable: any;
  public salesAreaflip: any;
  @Input('connectedTo')
  connectedTo: string[]
  salesAreaList: any = [];
  salesAreaLayout: any = [];
  toDoListArea: any = [];
  @Input() public todoList;
  @Input() public DragId;
  @Input() dataSource;
  render: any;
  areaValue = [];
  menuItem: any = {
    tenantId: 0,
    salesAreaId: 0,
    menuGroupId: 0,
    menuPageId: 0,
    areaObject: "",
  };
  @Input() layoutId;
  //@Input() public buttonState;
  constructor(
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private ItemLog: MenuService,
    private toaster: ToasterService,
    private helper: HelperService
  ) {
  }

  @Input()
  public set DragDrop(dragDataItem) {
    this.DragId = dragDataItem
  }

  @Input()
  public set DataLayoutAccordian(LayoutAccordian) {
    this.salesAreaflip = LayoutAccordian;
  }

  @Input()
  public set DataSourceInput(tableRowData) {
    if (tableRowData != undefined) {
      this.SalesAreaLayId = 0;
    }
  }

  @Input()
  public set MenuRowItem(tableRowData) {
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      this.SalesAreaLayId = tableRowData.id;
    }
  }

  @Input()
  public set SalesAreaList(list) {
    this.salesAreaList = list;
    this.dataSourceAreaTable.data = list;
    this.displayedColumns = ['name'];
    this.columns = [
      { columnDef: 'name', header: 'Sales Area Name' }
    ];
  }

  @Input()
  public set SalesAreaLayout(list) {
    this.salesAreaLayout = list
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  ngOnInit(): void {
    this.helper.setValue('OnDirty1', false);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
  }

  drop(event) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data.filteredData,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.table_render = false;
    this.changeDetector.detectChanges();
    this.table_render = true;
    this.helper.setValue('GetSessionData1', JSON.stringify(event.container.data));
    this.Popupmsg.emit(event);
  }


  onSaveSaleLayout() {
    this.submittedGeneral = true;
    this.menuItem.tenantId = this.loginInfo.tenantId;
    this.menuItem.salesAreaId = 0;
    this.menuItem.menuGroupId = 0;
    this.menuItem.menuPageId = 0;
    let menuObject = JSON.stringify(this.salesAreaLayout);
    this.menuItem.areaObject = menuObject

    console.log(this.menuItem, 'SaveSalesAreaLayout');
    if (this.salesAreaLayout != '[]') {
      this.ItemLog.saveAreaMapping(this.menuItem).subscribe(
        (response) => {
          //this.toaster.successPopUp(response.message);
        })
    }
  }

  onClickItem(e) {
    this.helper.setValue('OnDirty', true);
    this.helper.setValue('SalesLayout_ID', e.id);
    this.helper.setValue('SessionPageName', "layoutPage");
    this.rowClicked = e.id;
    this.rowDataEmit.emit(e);
  }

  GetSessionId(e) {
    this.salesAreaDrop.emit(e);
    this.Popupmsg.emit(e);
    //console.log(e,'seeiosnid')
  }
}