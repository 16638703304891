import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatFooterRowDef } from '@angular/material/table';
import { Group, itableReport } from 'src/app/interfaces/i-report';

@Component({
  selector: 'app-discount-table-collapse',
  templateUrl: './discount-table-collapse.component.html',
  styleUrls: ['./discount-table-collapse.component.css']
})
export class DiscountTableCollapseComponent implements OnInit {

  @Input() displayedColumns;
	@Input() dataSource;
	@Input() columns;
	@Input() _alldata;
	@Input() groupByColumns;
	@Input() noReportshow
	rowClicked: any;
	@Output() public checkItem = new EventEmitter();
	@Output() public tableItem = new EventEmitter();
	@ViewChild(MatFooterRowDef, { static: true }) footerDef: MatFooterRowDef;
	constructor() { }

	ngOnInit(): void {
		// if(this._alldata != undefined){
		// this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
		this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
		this.dataSource.filter = performance.now().toString();
		

	}

	groupBy(event, column) {	
		
		console.log(event,"tttttttttttt"); 
		event.stopPropagation();
		this.checkGroupByColumn(column.field, true);
		this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
		this.dataSource.filter = performance.now().toString();
	}

	checkGroupByColumn(field, add) {
		let found = null;
		for (const column of this.groupByColumns) {
			if (column === field) {
				found = this.groupByColumns.indexOf(column, 0);
			}
		}
		if (found != null && found >= 0) {
			if (!add) {
				this.groupByColumns.splice(found, 1);
			}
		} else {
			if (add) {
				this.groupByColumns.push(field);
			}
		}
	}

	unGroupBy(event, column) {
		event.stopPropagation();
		this.checkGroupByColumn(column.field, false);
		this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
		this.dataSource.filter = performance.now().toString();
	}

	// below is for grid row grouping
	customFilterPredicate(data: any | Group, filter: string): boolean {
		return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
	}

	getDataRowVisible(data: any): boolean {
		const groupRows = this.dataSource.data.filter(
			row => {
				if (!(row instanceof Group)) {
					return false;
				}
				let match = true;
				this.groupByColumns.forEach(column => {
					if (!row[column] || !data[column] || row[column] !== data[column]) {
						match = false;
					}
				});
				return match;
			}
		);

		if (groupRows.length === 0) {
			return true;
		}
		const parent = groupRows[0] as Group;
		return parent.visible && parent.expanded;
	}

	groupHeaderClick(row) {
		row.expanded = !row.expanded;
		this.dataSource.filter = performance.now().toString();  // bug here need to fix
	}

	addGroups(data: any[], groupByColumns: string[]): any[] {
		const rootGroup = new Group();
		rootGroup.expanded = true;
		return this.getSublevel(data, 0, groupByColumns, rootGroup);
	}

	getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
		if (level >= groupByColumns.length) {
			return data;
		}
		const groups = this.uniqueBy(
			data.map(
				row => {
					const result = new Group();
					result.level = level + 1;
					result.parent = parent;
					for (let i = 0; i <= level; i++) {
						result[groupByColumns[i]] = row[groupByColumns[i]];
					}
					return result;
				}
			),
			JSON.stringify);

		const currentColumn = groupByColumns[level];
		let subGroups = [];
		groups.forEach(group => {
			const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
			group.totalCounts = rowsInGroup.length;
			const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
			subGroup.unshift(group);
			subGroups = subGroups.concat(subGroup);
		});
		return subGroups;
	}

	uniqueBy(a, key) {
		const seen = {};
		return a.filter((item) => {
			const k = key(item);
			return seen.hasOwnProperty(k) ? false : (seen[k] = true);
		});
	}

	isGroup(index, item): boolean {
		return item.level;
	}

	onSortData(sort: MatSort) {
		let data = this._alldata;
		const index = data.findIndex(x => x['level'] == 1);
		if (sort.active && sort.direction !== '') {
			if (index > -1) {
				data.splice(index, 1);
			}

			data = data.sort((a: itableReport, b: itableReport) => {
				const isAsc = sort.direction === 'asc';
				switch (sort.active) {
					case 'quantity':
						return this.compare(a.quantity, b.quantity, isAsc);
					case 'category':
						return this.compare(a.category, b.category, isAsc);
					case 'groupHeader':
						return this.compare(a.groupHeader, b.groupHeader, isAsc);
					case 'grossSales':
						return this.compare(a.grossSales, b.grossSales, isAsc);
					case 'netSales':
						return this.compare(a.netSales, b.netSales, isAsc);
					case 'categorySale':
						return this.compare(a.categorySale, b.categorySale, isAsc);
					case 'name':
						return this.compare(a.name, b.name, isAsc);
					case 'time':
						return this.compare(a.time, b.time, isAsc);
					case 'terminal':
						return this.compare(a.terminal, b.terminal, isAsc);
					case 'reason':
						return this.compare(a.reason, b.reason, isAsc);
					case 'employeeName':
						return this.compare(a.employeeName, b.employeeName, isAsc);
					case 'dateTime':
						return this.compare(a.dateTime, b.dateTime, isAsc);
					case 'check':
						return this.compare(a.check, b.check, isAsc);
					case 'station':
						return this.compare(a.station, b.station, isAsc);
					case 'approvedBy':
						return this.compare(a.approvedBy, b.approvedBy, isAsc);
					case 'amount':
						return this.compare(a.amount, b.amount, isAsc);
					case 'byGross':
						return this.compare(a.byGross, b.byGross, isAsc);
					default:
						return 0;
				}
			});
		}
		this.dataSource.data = this.addGroups(data, this.groupByColumns);
	}

	private compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	getTotalQuntity() {

	     return this.dataSource.filteredData.map(t => t.quantity ).filter(x => x !== undefined ).reduce((acc, value) => acc + value, 0);
	}
	getTotalGross() {
		//console.log(this.dataSource.filteredData,'2');
		return this.dataSource.filteredData.map(t => t.grossSales).filter(x => x !== undefined).reduce((acc, value) => acc + value, 0);
	}
	getTotalNet() {
	//	console.log(this.dataSource.filteredData,'3');
		return this.dataSource.filteredData.map(t => t.netSales).filter(x => x !== undefined).reduce((acc, value) => acc + value, 0);
	}
	getTotalAmount() {
		//console.log(this.dataSource.filteredData,'4');
		return this.dataSource.filteredData.map(t => t.amount).filter(x => x !== undefined).reduce((acc, value) => acc + value, 0);
	}
	getTotalPercentage() {
		//console.log(this.dataSource.filteredData,'5');
		return this.dataSource.filteredData.map(t => t.byGross).filter(x => x !== undefined).reduce((acc, value) => acc + value, 0);
	}
	getTotalCost6() {
		//console.log(this.dataSource.filteredData,'6');
		let value = this.dataSource.filteredData.map(t => t.check).filter(x => x !== undefined).reduce((acc, value) => acc + value, 0);
		return value.length
	}
	changeTableRowColor(id?: any, row?: any) {

		this.rowClicked = ""
		this.rowClicked = this.rowClicked === id ? -1 : id;
		this.tableItem.emit(row)
				
		console.log(row,"tttttttttttt"); 
	}
	itemClick(e){
		this.checkItem.emit(e);	
	  }
}
