import {
  CdkDragDrop,
  CdkDragEnd,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';

@Component({
  selector: 'app-fliptable-layout',
  templateUrl: './fliptable-layout.component.html',
  styleUrls: ['./fliptable-layout.component.css'],
})
export class FliptableLayoutComponent implements OnInit {
  @Input() salesArealayout: any;
  @Input() dataSource;
  @Input() detector;
  @Input() DataModifier;
  @Input() displayedColumns;
  @ViewChild('table') table: MatTable<any>;
  @Input() layoutId: any;
  // public displayedColumns = ['Items'];
  // public displayedModColumn = ['Modifier Groups Available'];
  @Input() columns;
  searchValue;
  @ViewChild(MatSort) sort: MatSort;
  @Input() SalesID: any;
  changeDrop: boolean = false;
  dropping: boolean = false;
  @Output() public dragItememit = new EventEmitter();
  @Output() public modifierItem = new EventEmitter();
  @Output() public tableValueItem = new EventEmitter();
  @Output() public searchItems = new EventEmitter();
  @Output() public dataSessionId = new EventEmitter();
  // @Output() searchItems : EventEmitter<string> = new EventEmitter();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private helper: HelperService
  ) { }

  ngOnInit(): void {
    this.searchValue = '';
  }

  // ngAfterViewInit(): void {
  //   this.dataSource.sort = this.sort;
  // }

  // @Input() displayedModColumns(e){
  //   this.displayedModColumn = e
  // }

  drop(event) {
    if (event.container.data.filteredData.length != 0) {
      this.dataSessionId.emit(event.container.data.filteredData[0].SessionId);
    } else if (event.container.data.filteredData.length == 0) {
      this.dataSessionId.emit(1);
    }
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(
        event.container.data.filteredData,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data.filteredData,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.table.renderRows();
    this.tableValueItem.emit(event.previousContainer.data);
    this.helper.setValue(
      'GetSessionData1',
      JSON.stringify(event.container.data.filteredData)
    );
    this.helper.setValue('OnDirty', true);
  }


  public onDragEnd(event: CdkDragEnd<string[]>) {
    this.dropping = true;
    this.dragItememit.emit(this.dropping);
    this.changeDetector.detectChanges();
  }

  @Input()
  public set salesAreaflip(salesAreaflip) {
    this.salesArealayout = salesAreaflip;
    //console.log(this.salesArealayout, 'modifier')
  }

  @Input()
  public set DatatableRender(Sales_Area) {
    this.detector = Sales_Area;
  }

  builderDrag(e: any) {
    e.value.fieldId = e.value.fieldName + '_' + new Date().getTime();
  }

  changeTableRowColor(event?: any) {
    this.modifierItem.emit(event);
  }

  onMainSearch(e?) {
    this.searchItems.emit(e);
  }
}
