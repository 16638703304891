<div class="tags_gen">
  <form [formGroup]="tagsGeneralForm" class="loginForm" (change)="onchange(tagsGeneralForm.value)">
    <div class="row mb-4">
      <div class="col-sm-4 col-12">
        <div class="form-group">
          <label>Name</label> <label class="text-danger">*</label>
          <input type="text" formControlName="name" (keypress)="TagNameValid()"
            class="form-control input-lg inputbox_login" appInputRestriction placeholder="Name" [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.name.errors) || requiredErrors
            }" maxlength="50" (input)="getMaxValue($event)" (focusout)="focusOutFunction()" />
          <div class="error">{{ errorValue }}</div>
          <div *ngIf="
              (submittedGeneral && GeneralData.name.errors) || requiredErrors
            " class="invalid-feedback">
            <div *ngIf="GeneralData.name.errors.required">Name is required</div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-8">
        <div class="row" [style.display]="iconShowSec ? 'none' : 'flex'">
          <div class="col-sm-3 col">
            <a class="tags_img">
              <img src="assets/components/images/report-unselected.png" alt="Reports" class="img-fluid" />
            </a>
          </div>
          <div class="col-sm-3 col">
            <a class="tags_img">
              <img src="assets/components/images/print-unselected.png" alt="print" class="img-fluid" />
            </a>
          </div>
          <div class="col-sm-3 col">
            <a class="tags_img">
              <img src="assets/components/images/filter-unselected.png" alt="filter" class="img-fluid" />
            </a>
          </div>
          <div class="col-sm-3 col">
            <a class="tags_img">
              <img src="assets/components/images/gen-icon-daypart-unselected.png" alt="filter"
                class="img-fluid img-day-resp" />
            </a>
          </div>
        </div>
        <div class="row" [style.display]="iconShowSec ? 'flex' : 'none'">
          <div class="col-sm-3 col">
            <a class="tags_img" (click)="onImgChange('reports')" [ngClass]="{ img_purple: reportSec == true }">
              <img src="assets/components/images/report-selected.png" alt="Reports"
                class="img-fluid img_gray outshadow" />
              <img src="assets/components/images/report-active.png" alt="Reports" class="img-fluid img_purple" />
            </a>
          </div>
          <div class="col-sm-3 col">
            <a class="tags_img" (click)="onImgChange('prints')" [ngClass]="{ img_purple: printSec == true }">
              <img src="assets/components/images/print-selected.png" alt="Prints"
                class="img-fluid img_gray outshadow" />
              <img src="assets/components/images/print-active.png" alt="Prints" class="img-fluid img_purple" />
            </a>
          </div>
          <div class="col-sm-3 col">
            <a class="tags_img" (click)="onImgChange('filter')" [ngClass]="{ img_purple: filterSec == true }">
              <img src="assets/components/images/filter-selected.png" alt="Filter"
                class="img-fluid img_gray outshadow" />
              <img src="assets/components/images/filter-active.png" alt="Filter" class="img-fluid img_purple" />
            </a>
          </div>
          <div class="col-sm-3 col">
            <a class="tags_img" (click)="onImgChange('DayPart')" [ngClass]="{ img_purple: dayPartSec == true }">
              <img src="assets/components/images/gen-icon-daypart-selected.png" alt="dayPart"
                class="img-fluid img_gray outshadow" />
              <img src="assets/components/images/gen-icon-daypart-active.png" alt="dayPart"
                class="img-fluid img_purple" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-2 col-4 text-right">
        <div class="form-group">
          <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral()"
            [disabled]="buttonDisable ? true : null">
            <img src="assets/components/images/save.png" alt="employee" class="" />
            <label class="label_config">Save</label>
          </button>
        </div>
      </div>
    </div>

    <div class="tags_Print" *ngIf="reportSec">
      <div class="row">
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label>Use for Report filter?</label>
            <div class="checkbox-table accordion-checkbox">
              <div class="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" class="custom-control-input" name="useForReportFilter" id="check"
                  formControlName="useForReportFilter" />
                <label class="custom-control-label" for="check"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tags_Print" *ngIf="printSec">
      <div class="row">
        <div class="col-sm-6">
          <app-tag-print-job (generalItem)="getGeneralJobsDetail($event)" tagsID="jobsId" [DataJobList]="JobList"
            [getrefreshjobval]="refreshjobprintval">
          </app-tag-print-job>
        </div>
        <div class="col-sm-6">
          <app-tag-print-printer (generalItem)="getGeneralPrinterDetail($event)" tagsID="printerId"
            [DataPrinterList]="PrinterList" [getrefreshprintval]="refreshjobprintval">
          </app-tag-print-printer>
        </div>
      </div>
    </div>

    <div class="tags_Print" *ngIf="filterSec">
      <div class="row">
        <div class="col-sm-4 col-12"></div>
        <div class="col-12">
          <app-tag-filter [FilterDataListValue]="filterDataList" (tagfiltersessData)="istagfilterdragndrop($event)"
            tagsID="ipadFilterId" [buttonState]="buttonDisable">
          </app-tag-filter>
          <!-- <div class="form-group">
                        <label>Use for Tablet filter?</label>
                        <div class="checkbox-table accordion-checkbox">
                            <div class="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" class="custom-control-input" name="tabletFilter" id="check"
                                    formControlName="tabletFilter">
                                <label class="custom-control-label" for="check"></label>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>

    <!-- <div class="tags_Print" *ngIf="dayPartSec"> -->
    <div class="tags_Print" [style.visibility]="visibledaypart == true ? 'visible' : 'hidden'">
      <app-day-part-schedule [tagIdEmit]="tag_id" (tagsDaypartPageData_child)="gettagsPageData($event)"
        [buttonState]="buttonDisable"></app-day-part-schedule>
    </div>
  </form>
</div>