import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
export interface PeriodicElement {
  id?: number;
  name?:any;
  quantity?:any;
  amount?:any;
  trasType?:any;
  check?:any;
  employee?:any;
  timeDate?:any;
  item?:any;
  move?:any;
  total?:any;
  discountname?:any;
  ofdiscounts?:any;
  category?:any;
  groupHeader?:any;
  grossSales?:any;
  netSales?:any;
  categorySale?:any;
  employeeName?: any;
  sales?: any;
  totalCheck?:any
  Address?: any;
  City?: any;
  Email?: any;
  FirstName?: any;
  LastName?: any;
  Notes?: any;
  Phone?: any;
  State?: any;
  Zip?: any;
}
export interface Transaction {
  item: string;
  cost: number;
}
//const ELEMENT_DATA1: PeriodicElement[] = [
//  { discountname: 'EMV1', ofdiscounts: 20, total: 853.23},
//  { discountname: 'EMV2', ofdiscounts: 15, total: 465.36},
 // { discountname: 'Swipes', ofdiscounts: 140, total: 2564},
//];

// const ELEMENT_DATA2: PeriodicElement[] = [
//   { id: 4, employeeName: 'GARRETT HEYWOOD', timeDate: '1/18/2021 09:23 AM', check: 'iPad (2)15-1', sales: 'Dining', discountname:'Happy Hour Draft Beer 1', amount:'410', totalCheck: '80'},
//   {id: 5, employeeName: 'GARRETT HEYWOOD', timeDate: '1/18/2021 09:23 AM', check: 'iPad (2)15-1', sales: 'BBQ', discountname:'Happy Hour Draft Beer 1', amount:'510', totalCheck: '99' },
//   { id: 6, employeeName: 'GARRETT HEYWOOD',  timeDate: '1/18/2021 09:23 AM', check: 'iPad (2)15-1',  sales: 'BBQ', discountname:'Morning Discount', amount:'670', totalCheck: '70'},
// ];



@Component({
  selector: 'app-discounts-audit',
  templateUrl: './discounts-audit.component.html',
  styleUrls: ['./discounts-audit.component.css']
})
export class DiscountsAuditComponent implements OnInit {
  @Input() displayedColumns;
  @Input() transactions;
  @Input() Filtertransactions;
  @Input() discountTotal;
  @Input() subTotal;
  @Input() netTotal;
  @Input() dataSource3;
  @Input() displayedColumns3;
  @Input() columns3;
  @Input() auditName;
   @Input() CustomerDetails;
   @Input() auditEmployee;
   @Input() auditDate;
   @Input() auditTime;
  @Input() thirdTableShow;
  @Input() TipsAmount;
  @Input() auditMove;
  public TransID: any;
  @Output() public tableRowvalue = new EventEmitter;
  dyn_img : any = 'signature1.png'
  discountValue :any = 0.00;
  taxValue : any = 0.00;
  constructor() { }
  //CustomerDetails: PeriodicElement[] = [
    //{ Address: '137 N Corneria Blvd', City: 'Kichina', Email: 'FalcoisaButt@aqua.ucom', FirstName: 'Fox', LastName: 'McCloud', Notes: 'kjkjk', Phone: '505-334-855', State: 'UN', Zip: '54', check: '0.8F04F6AB-DA4B'}
  //  ];
  ngOnInit(): void {

  }
  
  /** Gets the total cost of all transactions. */
 // getTotalCost() {
 //   return this.transactions.map(t => t.cost).reduce((acc, value) => acc + value, 0);
 // }
 // getTotalCost1() {
 //   let value =  this.transactions.map(t => t.cost).reduce((acc, value) => acc + value, 0) - (this.discountValue + this.taxValue);
 //   return value
 // }
  onChangeRowValue(e) {
    this.tableRowvalue.emit(e)
  }

}
