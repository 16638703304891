<form class="SaleArea_general loginForm">
  <div class="row salesArea_layout layout">
    <div class="col-sm-3 col text-center">
      <div class="drop-container">
        <app-fliptable-layout [dataSource]="dataSourceAreaTable" [displayedColumns]="displayedColumns"
          [columns]="columns" [salesAreaflip]="salesAreaflip" [DatatableRender]="table_render" [SalesID]="layoutId"
          (dataSessionId)="GetSessionId($event)">
        </app-fliptable-layout>

      </div>
    </div>
    <div class="col-sm-9 col-12">
      <div class="salesAreaLayout menu_wrapper layout_wrapper">
        <div cdkDropListOrientation="horizontal" cdkDropList cdkDropListGroup id="todo" #todo="cdkDropList"
          [cdkDropListData]="salesAreaLayout" [cdkDropListConnectedTo]="layoutId" (cdkDropListDropped)="drop($event)"
          class="example-list">
          <div class="example-box" *ngFor="let item of salesAreaLayout; let i=index" cdkDrag cdkDragDropZone>
            <div [ngClass]="{ 'dropzone-placeholder': item.id || DragId == true}" (click)="onClickItem(item)"
            [style.background-color]="rowClicked == item.id ? '#524261' : 'transparent'">
              {{item.name}}
            </div>
          </div>
        </div>

      </div>
      <div class="form-group mt-3">
        <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveSaleLayout()"
        [disabled]="buttonDisable ? true : null" >
            <!-- [disabled]="buttonState ? true : null" -->
          <img src="assets/components/images/save.png" alt="employee" class="">
          <label class="label_config">Save</label> 
        </button>
      </div>
    </div>
  </div>
</form>