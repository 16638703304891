<div class="row">
    <div class="col-sm-6">
        <div *ngIf="table_render" class="layout_container">
            <table mat-table #table [dataSource]="dataSource" matSort
              class="mat-elevation-z8  table employee-table employee dataTable" style="width: 100%;" cdkDropList id="{{SalesID}}"
              #done="cdkDropList" [cdkDropListData]="dataSource" [cdkDropListConnectedTo]="['jobs','printer','ipadFilter']"
              (cdkDropListDropped)="drop($event)">
              <ng-container *ngFor="let item of columns" matColumnDef="{{item.columnDef}}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="emp_name"> {{item.header}} </th>
        
                <td mat-cell *matCellDef="let element; let idx=index;">
        
                  <div class="emp_name" [style.background-color]="rowClicked == element.id ? '#341a46' : 'transparent'"
                    *ngIf="item.columnDef == 'name'" cdkDragPlaceholder>
                    {{ element[item.columnDef] }}</div>
        
                </td>
              </ng-container>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell employeenodata" colspan="4">No data matching the search</td>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{'highlight': selectedRowIndex == row.id}" (cdkDragEnded)="onDragEnd($event)" cdkDrag
                [cdkDragData]="row" cdkDragPlaceholder></tr>
        
            </table>
          </div>
          
    </div>
    <div class="col-sm-6">
        <label class="item_assigned_label">{{label_name}}</label>
        <div class="itemAssignedmodify menu_wrapper item_main">
            <div cdkDropList cdkDropListGroup id="jobs" #todo="cdkDropList" [cdkDropListData]="JobList"
                [cdkDropListConnectedTo]="['jobsId']" (cdkDropListDropped)="drop($event)" cdkDragBoundary=".tablet"
                class="example-list" *ngIf="label_name =='Jobs Ignored'">
                <div class="item_assigned_box" *ngFor="let item of JobList; let i=index" cdkDrag
                    cdkDragDropZone>
                    <div [ngClass]="{ 'emp_name': item.id || DragId == true}">
                        <div class="outshadow"> {{item.name}}</div>
                    </div>
                </div>
            </div>
           
            <div cdkDropList cdkDropListGroup id="printer" #todo="cdkDropList" [cdkDropListData]="PrinterListData"
                [cdkDropListConnectedTo]="['printerId']" (cdkDropListDropped)="drop($event)" cdkDragBoundary=".tablet"
                class="example-list" *ngIf="label_name =='Printers Assigned'">
                <div class="item_assigned_box" *ngFor="let item of PrinterListData; let i=index" cdkDrag
                    cdkDragDropZone>
                    <div [ngClass]="{ 'emp_name': item.id || DragId == true}">
                        <div class="outshadow"> {{item.name}}</div>
                    </div>
                </div>
            </div>

            <div cdkDropList cdkDropListGroup id="ipadFilter" #todo="cdkDropList" [cdkDropListData]="FilterList"
                [cdkDropListConnectedTo]="['ipadFilterId']" (cdkDropListDropped)="drop($event)" cdkDragBoundary=".tablet"
                class="example-list" *ngIf="label_name =='iPad Sort Order'">
                <div class="item_assigned_box" *ngFor="let item of FilterList; let i=index" cdkDrag
                    cdkDragDropZone>
                    <div [ngClass]="{ 'emp_name': item.id || DragId == true}">
                        <div class="outshadow"> {{item.name}}</div>
                    </div>
                </div>
            </div>
            

        </div>
    </div>
</div>