import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { SignalRService } from 'src/app/services/signal-r.service';


@Component({
  selector: 'app-receiver',
  templateUrl: './receiver.component.html',
  styleUrls: ['./receiver.component.css'],
  providers: [SignalRService],
  encapsulation: ViewEncapsulation.None
})
export class ReceiverComponent implements OnInit {
  informationMessage = 'Waiting...🤨';

  constructor(private signalRService: SignalRService) {

  }

  ngOnInit(): void {
    this.signalRService.information.subscribe(message => {
      this.informationMessage = message;
    });
  }

}
