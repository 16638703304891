import { Component, OnInit, ViewChild, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { EmployeeGeneralBreakComponent } from '../employee-general-break/employee-general-break.component';
import { BreakService } from 'src/app/services/break.service';
import { IGetBreak } from 'src/app/interfaces/iemployee';
import { IActiveBreak } from 'src/app/interfaces/itime';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-break-type',
  templateUrl: './break-type.component.html',
  styleUrls: ['./break-type.component.css']
})
export class BreakTypeComponent implements OnInit {
  private buttonDisable: boolean;
  public tableRowColor: any;
  public displayedColumns = [];
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  tableEnable: boolean = true;
  loginInfo: any;
  public empColor: any;
  public tableRowId: any;
  public currentTarget: boolean;
  public activeDeBreakValue: any;
  public EmployeeTableData: any;
  @ViewChild(EmployeeGeneralBreakComponent) breakComp: EmployeeGeneralBreakComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;

  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  isInvalid: boolean = false;
  public modifierItemsData: any;
  //@Input() public buttonDisable
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public brksessData = new EventEmitter();

  constructor(private breakService: BreakService, private helper: HelperService, private changeDetector: ChangeDetectorRef,
    private toaster: ToasterService, private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.BreakPage");

    this.buttonDisable = false;
    this.employeeName = "Breaks";
    this.employee = "Employee Time";
    this.employeeIcon = "assets/components/images/path.png"
    this.tableEnable = true;
    this.loginInfo = this.helper.getValue('LoginInfo');
    // this.getAllOwners();
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
    this.getAllOwners();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  getAllOwners() {
    const employee: IGetBreak = {
      breakid: 0,
      tenantid: this.loginInfo.tenantId,
    }
    this.breakService.GetBreakList(employee)
      .subscribe(res => {
        this.dataSource.data = res;
        this.EmployeeTableData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Break Type' },
          { columnDef: 'isActive', header: 'Paid' }
        ];
        this.tableEnable = false;
        this.tableEnable = true;
        this.changeDetector.detectChanges();

        if (this.helper.getValue('Sessionid_help') == undefined ||
          this.helper.getValue('Sessionid_help') == null ||
          this.helper.getValue('Sessionid_help') == "") {
          this.helper.setValue('Sessionid_help', 0)
        }
        else {
          //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
          this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
        }
      })
  }

  getTableName(name) {
    this.employeeName = name;
    this.employee;
    this.employeeIcon;
    this.titleName.emit(this.employeeName)
  }

  async addEmployee() {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    }
    else {
      var conf = await this.dialogser.confirm("emvPage");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
      }
      else {
        this.breakComp.BreakForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.tableRowColor = 'transparent';
    this.breakComp.errorValue = "";
    this.isInvalid = false;
    this.breakComp.requiredErrors = false;
    this.breakComp.BreakForm.reset(); // should reset the form in child component
    this.employeeName = "Breaks";
    this.empColor = 'transparent';
    this.tableRowId = 0;
    this.breakComp.breakValueID = 0;
    this.titleName.emit(this.employeeName);
  }

  getbreakDetail(event) {
    this.getAllOwners();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name)
  }

  async getTableIdx(event) {
    //this.gentab.isFormPageDirty();
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("emvPage");
      if (conf == 'success') { // Navigate Away
        if (event == this.dialogser.ModifiedId) {
          this.breakComp.getEmployee(event);
        }
        this.dialogser.ClearSession();
        this.breakComp.BreakForm.reset();

        //General Data
        this.dialogser.ModifiedId = event;
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.isInvalid = false;
        this.breakComp.requiredErrors = false;
        this.breakComp.errorValue = "";
        this.flipcomp.rowClicked = event;
      }
      else // Cancel
      {
        //General Data
        this.breakComp.BreakForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event)
      this.tableRowId = event;
      this.tableRowColor = event;
      this.isInvalid = false;
      this.breakComp.requiredErrors = false;
      this.breakComp.errorValue = "";
    }
  }

  checkValue(event) {
    this.activeDeBreakValue = event
    this.currentTarget = event.e.currentTarget.checked
    const active: IActiveBreak = {
      userId: this.loginInfo.userId,
      breakId: event.id,
      isActive: event.e.currentTarget.checked
    }
    this.breakService.IsActiveBreakUser(active).subscribe(
      (response) => {
        if (this.currentTarget) {
          this.toaster.successPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
        }
        this.getAllOwners();
      },
    )
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  OnSave() {
    this.breakComp.errorValue = "";
    this.breakComp.onSaveBreak();
    if (this.breakComp.BreakForm.invalid) {
      if ((this.breakComp.BreakForm.value.name == null) || (this.breakComp.BreakForm.value.name == "")) {
        this.isInvalid = true;
      }
      else {
        this.isInvalid = false;
      }
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getbrksessData(e) {
    this.brksessData.emit(e)
  }
}
