import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetDevice, DeleteDevice } from 'src/app/interfaces/idevices';
import { DeviceService } from 'src/app/services/devices.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ReceiptConfigGeneralComponent } from '../receipt-config-general/receipt-config-general.component';

@Component({
  selector: 'app-receipt-config',
  templateUrl: './receipt-config.component.html',
  styleUrls: ['./receipt-config.component.css']
})
export class ReceiptConfigComponent implements OnInit {
  @ViewChild(ReceiptConfigGeneralComponent) gentab: ReceiptConfigGeneralComponent;
  private buttonDisable: boolean;
  public displayedColumns = [];
  columns = [];
  public menuGroupList: any = [];
  public getAreaID: any;
  public tableRowColor: any;
  public tableRowId: any;
  public getsaveid: any;
  public itemlayoutbuilder: any;
  public getDataValue: any;
  public TabletData: any;
  isInvalid: boolean = false;
  public modifierItemsData: any;
  matopen:boolean = false;
  matstate($event){
    this.matopen =$event
  }
  public dataSource = new MatTableDataSource<any>();
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public recconfsessData = new EventEmitter();

  public receiptName: any;
  public receipt: any;
  public receiptIcon: any;

  loginInfo: any;
  submittedGeneral: any;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;

  //@Input() public buttonDisable
  constructor(private helper: HelperService, private changeDetector: ChangeDetectorRef, private toaster: ToasterService,
    private objDevice: DeviceService, private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.receipt");

    this.buttonDisable = false;
    this.receiptName = "Receipt Config";
    this.receipt = "Device-Receipt";
    this.receiptIcon = "assets/components/images/config.png"
    this.titleName.emit(this.receiptName);
    this.title.emit(this.receipt);
    this.titleIcon.emit(this.receiptIcon);
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getReceiptList();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.ClearData(clearForm)
    }
  }

  async ClearData(event?) {
    //if (this.gentab.isFormPageDirty() == false) {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    }
    else {
      var conf = await this.dialogser.confirm("receipt");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
      }
      else {
        this.gentab.configGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.gentab.errorValue = "";
    this.isInvalid = false;
    this.gentab.requiredError = false;
    this.gentab.selectedItems = null;
    this.gentab.configGeneralForm.reset();
    this.gentab.ngOnInit();
    this.receiptName = "Device-Receipt";
    this.tableRowColor = 'transparent';
    this.flipcomp.rowClicked = "transparent";
    this.titleName.emit(this.receiptName);
    this.submittedGeneral = false;
    this.tableRowId = 0;
    this.gentab.id = 0;
    this.flipcomp.selectTableRowColor(0, 0, this.receiptName);
  }

  getReceiptList() {
    const deviceList: GetDevice = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.objDevice.ReceiptDeviceGet(deviceList)
      .subscribe(res => {
        this.dataSource.data = res;
        this.TabletData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Receipt Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  getGeneralDetail(event) {
    this.getsaveid = event.res.id;
    this.getReceiptList();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name)
  }

  checkvaluePage(event) {
    let currentTarget = event.e.target.checked //event.target.checked
    const active: DeleteDevice = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.target.checked
    }
    this.objDevice.ReceiptDeviceDelete(active).subscribe(
      (response) => {
        this.getReceiptList();
        this.ClearData(0);
        this.toaster.successPopUp(response.message);
      }
    )
  }

  async getTableIdx(event) {
    this.gentab.id = event;
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("receipt");
      if (conf == 'success') { // Navigate Away
        if (event == this.dialogser.ModifiedId) {
          this.gentab.GetReceiptDetails(event);
        }
        this.dialogser.ClearSession();
        this.gentab.configGeneralForm.reset();

        //General Data
        this.dialogser.ModifiedId = event;
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.getReceiptList();
        this.isInvalid = false;
        this.gentab.requiredError = false;
      }
      else // Cancel
      {
        //General Data
        this.gentab.configGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.recconfsessData.emit({
          data: this.gentab.configGeneralForm.value, event: "receipt", val: true,
          itmname: this.gentab.configGeneralForm.value.name
        });
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event)
      this.tableRowId = event;
      this.tableRowColor = event;
      this.getReceiptList();
      this.isInvalid = false;
      this.gentab.requiredError = false;
    }
  }

  getTableName(name) {
    this.receiptName = name;
    this.receipt = "Device-Receipt";
    this.receiptIcon = "assets/components/images/path.png";
    this.titleName.emit(this.receiptName)
    this.title.emit(this.receipt)
    this.titleIcon.emit(this.receiptIcon)
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  OnSave() {
    this.gentab.onSaveGeneral();
    if (this.gentab.configGeneralForm.invalid) {
      if ((this.gentab.configGeneralForm.value.name == null) || (this.gentab.configGeneralForm.value.name == "")) {
        this.isInvalid = true;
      }
      else {
        this.isInvalid = false;
      }
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getreceiptsessData(e) {
    this.recconfsessData.emit(e)
  }
}
