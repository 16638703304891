import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MasterRoutingModule } from './master-routing.module';
import { RoleAndPermissionComponent } from './role-and-permission/role-and-permission.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RolePermissionGeneralComponent } from './role-permission-general/role-permission-general.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { EmployeeModule } from '../employee/employee.module';
import { TypeOfBusinessComponent } from './type-of-business/type-of-business.component';
import { TypeOfBusinessGeneralComponent } from './type-of-business-general/type-of-business-general.component';
import { MatSelectModule } from '@angular/material/select';
import { StateFieldsComponent } from './state-fields/state-fields.component';
import { StateFieldsGeneralComponent } from './state-fields-general/state-fields-general.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { MatFormFieldModule } from '@angular/material/form-field';
import { RolePermissionDetailsComponent } from './role-permission-details/role-permission-details.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LaborCategoryComponent } from './labor-category/labor-category.component';
import { LaborCategoryGeneralComponent } from './labor-category-general/labor-category-general.component';
import { CashManagementComponent } from './cash-management/cash-management.component';
import { TaxMasterComponent } from './tax-master/tax-master.component';
import { TaxMasterGeneralComponent } from './tax-master-general/tax-master-general.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { BsDatepickerModule, TabsModule } from 'ngx-bootstrap';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';
import { IsvComponent } from './isv/isv.component';
import { IsvGeneralComponent } from './isv-general/isv-general.component';


@NgModule({
  declarations: [
    RoleAndPermissionComponent,
    RolePermissionGeneralComponent,
    TypeOfBusinessComponent,
    TypeOfBusinessGeneralComponent,
    StateFieldsComponent,
    StateFieldsGeneralComponent,
    RolePermissionDetailsComponent,
    LaborCategoryComponent,
    LaborCategoryGeneralComponent,
    CashManagementComponent,
    TaxMasterComponent,
    TaxMasterGeneralComponent,
    IsvComponent,
    IsvGeneralComponent
  ],
  imports: [
    BsDatepickerModule,
    CommonModule,
    MasterRoutingModule,
    ReactiveFormsModule,
    MatExpansionModule,
    EmployeeModule,
    MatSelectModule,
    MatSelectFilterModule,
    MatFormFieldModule,
    FormsModule,
    NgMultiSelectDropDownModule,
    MatSelectCountryModule.forRoot('en'),
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    MatInputModule,
  ],
  exports:[
    RoleAndPermissionComponent,
    TypeOfBusinessComponent,
    StateFieldsComponent,
    RolePermissionGeneralComponent,
    RolePermissionDetailsComponent,
    LaborCategoryComponent,
    TaxMasterComponent,
    TaxMasterGeneralComponent,
    IsvComponent,
    IsvGeneralComponent
  ]

})
export class MasterModule { }
