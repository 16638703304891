import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { Country } from 'ngx-intl-tel-input/lib/model/country.model';
import { AdminService } from 'src/app/services/admin.service';
import { ISelectState } from 'src/app/interfaces/iadmin';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-state-fields-general',
  templateUrl: './state-fields-general.component.html',
  styleUrls: ['./state-fields-general.component.css']
})
export class StateFieldsGeneralComponent implements OnInit {
  statefieldForm: FormGroup;
  buttonDisable: boolean = false;
  country = new FormControl();
  loginInfo: any;
  id: any = 0;
  submittedGeneral: any;
  selectedCountryCode = '';
  countryCodes = ['us', 'lu', 'de', 'bs', 'br', 'pt'];
  customLabels = ['Belgium', 'Germany', 'India', 'USA'];
  public variables = ['One', 'Two', 'County', 'Three', 'Zebra', 'XiOn'];
  public filteredList2 = this.variables.slice();
  countryValue: any;
  value: any;
  countryName: [];
  Country = [{
    id: 1,
    name: "United States Of America"
  }];
  errorValue: any;
  errorValue1: any;

  @Output() public EmitStateList = new EventEmitter(); requiredError: boolean = false;
  @Output() public isInvalid = new EventEmitter();
  @Output() public StateData_child = new EventEmitter();
  @Output() matopen: EventEmitter<any> = new EventEmitter()
  matstate($event) {
    this.matopen.emit($event)
  }

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private dialogser: DialogService,
    private toaster: ToasterService, private objAdmin: AdminService) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.statefieldForm = this.formBuilder.group({
      id: [0],
      statecode: ['', Validators.required],
      statename: [''],
      country: [0],
      countryName: [null],
    })

  }
  onCountrySelected($event: Country) {
  }
  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
  }
  get GeneralData() { return this.statefieldForm.controls; }


  @Input()
  public set MenuRowItem(tableRowData) {
    // this.statefieldForm.patchValue(tableRowData[0]);
    // this.id=tableRowData[0].id;
    this.errorValue = "";
    this.errorValue1 = "";
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      // this.countryValue = JSON.parse(tableRowData[0].country)
      // this.statefieldForm.patchValue(tableRowData[0]);
      this.id = tableRowData;
      this.getField(this.id);
      //  if(this.statefieldForm.value.country != null ||this.statefieldForm.value.country != undefined || this.statefieldForm.value.country != "" ){
      // }
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  onSaveGeneral() {
    this.errorValue = "";
    this.errorValue1 = "";
    this.submittedGeneral = true;
    if (this.statefieldForm.invalid) {
      return;
    }

    let Statedata = this.statefieldForm.value;
    // Statedata.id = this.id;
    Statedata.createdBy = this.loginInfo.userId;
    Statedata.isActive = true;
    Statedata.modifiedBy = this.loginInfo.userId;

    if (Statedata.statecode != "") {
      if (Statedata.statename != "") {
        console.log(Statedata, 'Statedata')
        this.onSaveCompleteForm(Statedata);
      }
      else {
        this.toaster.successPopUp("PLease Enter State Name");
      }
    }
    else {
      this.toaster.successPopUp("Please Enter State Code");
    }
  }

  onSaveCompleteForm(StateDetails) {
    this.objAdmin.SaveStateMaster(StateDetails).subscribe(
      async (response) => {
        this.id = response.id;
        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
          await this.dialogser.ClearSession();
          StateDetails.id = response.id;
          this.statefieldForm.patchValue(StateDetails)
          this.helper.setValue('GetSessionData1', StateDetails);
          this.StateData_child.emit({ data: StateDetails, event: "statefield", val: false });
        }
        this.submittedGeneral = false;
        this.EmitStateList.emit({ res: StateDetails.statecode, name: StateDetails.statename });
        //this.statefieldForm.reset();
      })
  }

  getField(idval) {
    const ObjState: ISelectState = {
      id: idval
    }
    this.objAdmin.EditStateMaster(ObjState).subscribe((response) => {
      // this.value = response[0].country
      if (response.length > 0) {
        this.statefieldForm.patchValue(response[0]);
        this.statefieldForm.patchValue({
          country: response[0].country,
          id: response[0].id
        });
        this.statefieldForm.get('country').valueChanges
          .subscribe(country => console
            .log('this.countryFormGroup.get("country").valueChanges', country));

        this.statefieldForm.valueChanges
          .subscribe(country => console
            .log('this.countryFormControl.valueChanges', country));
      }
    })
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  getMaxValueState(e) {
    if (e.target.value.length == 50) {
      this.errorValue1 = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue1 = ""
    }
  }

  StateValid() {
    if (this.statefieldForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }
    else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.statefieldForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.statefieldForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.StateData_child.emit(this.statefieldForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.StateData_child.emit({ data: data, event: "statefield", val: true });
  }
}
