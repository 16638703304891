<div class="table-container">
  <table mat-table #table [dataSource]="dataSource" matSort (matSortChange)="onSortData($event)"
  class="mat-elevation-z8 table employee-table employee dataTable full-width-table table_center" style="width: 100%;">

  <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header }}
      </th>

      <td mat-cell *matCellDef="let row">
        <div *ngIf="column.field != 'regWage' && column.field != 'overtimeWage' && column.field != 'sales' && column.field != 'laborCost' && column.field != 'category' && column.field != 'jobPosition'">
              {{ row[column.field]}}
            </div>
            <div *ngIf="column.field == 'regWage' || column.field == 'overtimeWage' || column.field == 'sales' 
            ">
                {{ row[column.field] | currency}}
            </div>
            <div *ngIf="column.field == 'laborCost'">
                {{ row[column.field]}}%
            </div>
            <div *ngIf="column.field == 'category'"   [ngClass]="{'labor-header': row[column.field] != 'Totals', 'labor-header-no': row[column.field] == 'Totals'}">
                <p (click)="categoryClick(row)"> {{ row[column.field]}}</p>
               </div>
               <div *ngIf="column.field == 'jobPosition'"   [ngClass]="{'labor-header': row[column.field] != 'Totals', 'labor-header-no': row[column.field] == 'Totals'}">
                 <p (click)="positionClick(row)"> {{ row[column.field]}}</p>
                </div>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <div *ngIf="column.field == 'category' || column.field == 'jobPosition'" class="footerData" >          
            Report Totals
          </div>
          <div *ngIf="column.field == 'employee'" class="footerData" >          
            Report Totals
          </div>
          <div *ngIf="column.field == 'regHours'" class="footerData">          
            {{getTotalregHours()}}
          </div>
          <div *ngIf="column.field == 'regWage'" class="footerData">
              {{getTotalregWage() | currency}}
          </div>
          <div *ngIf="column.field == 'overtimeHour'" class="footerData">
              {{getTotalotHours()}}
          </div>
          <div *ngIf="column.field == 'overtimeWage'" class="footerData">
              {{getTotalotWage() | currency}}
          </div>
          <div *ngIf="column.field == 'sales'" class="footerData">
              {{getTotalSales() | currency }}
          </div>
          <div *ngIf="column.field == 'laborCost'" class="footerData">
              {{getTotallaborCost()}}%
          </div>
      </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight_row': row.category == 'Total'}"
  (click)="changeTableRowColor(row.id,row)"></tr>

  <!-- Group header -->
  <ng-container matColumnDef="groupHeader">
    <td mat-cell colspan="999" *matCellDef="let group" class="highlight_td">        
        <p><span class="labor-header-span" [ngClass]="{'disp-none': tableSecondShow == true}">{{jobPosition}}</span><span [ngClass]="{'labor-header': tableSecondShow == false}">{{group[groupByColumns[group.level-1]]}}</span></p>
    </td>
  </ng-container>

 
   <!-- <div *ngIf="tableSecondShow == true">
          <tr mat-row *matRowDef="let row; columns: ['category']; when: isGroup;">
          </tr>
      </div> -->
      <div *ngIf="tableSecondShow == true">
        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup;">
        </tr>
    </div>

    <div *ngIf="tableSecondShow != true">
        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup;" (click)="groupHeaderClick(row)">
        </tr>
    </div>
 
  <div *ngIf="!noReportshow ">
  <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="footer"
      [attr.colspan]="displayedColumns.length" ></tr>
  </div>
</table>
</div>