<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="clearData()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true || isInvalidSchedule==true}" class="btn  btn-sm float-right submit_button global_submit" type="submit" (click)="onSaveGeneral();">
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label></button>
            <!-- [disabled]="buttonStatus ?  true : null" -->
        </div>
    </div>
</div>
<div class="row second-sec">
    <div class="col-sm-4">
        <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" [dealerItem]="tableRowColor" [EmployeeTableData]="EmployeeTableData" (breakActive)="checkValue($event)" [DataCom]="employee" (tableItemId)="getTableIdx($event)"
            (tableEmpName)="getTableName($event)" [employeeItem]="tableRowColor" (clearDetails)="clearData($event)" (btnActive)="btnActiveStatus($event)">
        </app-fliptable>
    </div>
    <div class="col-sm-8 event-pricing-section">

        <mat-accordion>
            <mat-expansion-panel [ngClass]="matopen ? 'mat-scroll-safari':''" *ngFor="let item of ['General','Schedule','Event Pricing Builder']; let i = index" [expanded]="indexExpanded == i" class="outshadow" [ngClass]="{ 'event-pricing': item == 'Event Pricing Builder', 'general-overflow': item == 'General',  'conditional-z-index-genPricing': item == 'General' && dropDownClicked == true, 'conditional-z-index-EventPricing': dropDownClicked == false}">
                <mat-expansion-panel-header (click)="expandPanel(item,index)" [ngClass]="{'box_error': ((isInvalid==true && item=='General') || (isInvalidSchedule==true && item=='Schedule'))}" (click)="DetectPanel($event)">
                    <mat-panel-title (click)="onChangeAccordion($event,index)" [ngClass]="{'title_error':((isInvalid==true && item=='General') || (isInvalidSchedule==true && item=='Schedule'))}">
                        {{item}} </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General'">
                    <app-event-pricing-general [DataIdx]="tableRowId" [buttonState]="buttonDisable" (GeneralDetailEventPrice)="getGeneralDetail($event)" (isInvalid)="isInvalidAccordion($event)" (eventpriceData_child)="geteventprisessData($event)" (dropDownClicked)="DropDownClicked($event)"></app-event-pricing-general>
                    <!-- <app-adjustment-general [DataIdx]="tableRowId" (generalAdjust)="getGeneralDetail($event)"
                        [checkTarget]="activeDeactiveValue" [buttonState]="buttonDisable"></app-adjustment-general> -->
                </div>
                <div *ngIf="item == 'Schedule'">
                    <app-scheduling-widget (event_schedule)="SaveScheduler($event)" [DataIdx]="tableRowId" (saveItemValue)="saveIdValue($event)" [buttonState]="buttonDisable" (schwidgetData_child)="geteventprisessData($event)"></app-scheduling-widget>
                </div>
                <div *ngIf="item == 'Event Pricing Builder'">
                    <app-pricing-builder (pricingBuilderItem)="SavePricingBuilder($event)" [DataIdx]="tableRowId" (saveItemValue)="saveIdValue($event)" [buttonState]="buttonDisable" (pricebuilderData_child)="geteventprisessData($event)" (matopen)="matstate($event)"></app-pricing-builder>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="form-group">
            <button class="btn  btn-sm float-right submit_button button-none" type="submit">
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label></button>
            <!-- [disabled]="buttonStatus ?  true : null" -->
        </div>
    </div>
</div>