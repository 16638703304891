<mat-accordion>
    <mat-expansion-panel *ngFor="let item of ['General', 'Logistics']; let i = index" [expanded]="indexExpanded == i" class="outshadow mat-scroll" [ngClass]="matopen ? 'mat-scroll-safari':''">
        <mat-expansion-panel-header (click)="expandPanel(item,index)" [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
            <mat-panel-title (click)="onChangeAccordion($event,index)" [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}}
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="item == 'General'">
            <app-sales-area-general [MenuRowItem]="getDataValue" (generalItem)="getGeneralDetail($event)" [buttonDis]="buttonDisableVal" (isInvalid)="isInvalidAccordion($event)" (saleareagenData_subchild)="getsaleareasessData($event)" (matopen)="matstate($event)"></app-sales-area-general>
        </div>
        <div *ngIf="item == 'Logistics'">
            <app-sale-area-logistic [SalesArea]="generalDataemit" [MenuRowItem]="getDataValue" [buttonDis]="buttonDisableVal" (salearealogData_subchild)="getsaleareasessData($event)" (matopen)="matstate($event)">
            </app-sale-area-logistic>
        </div>
        <!-- <div *ngIf="item == 'Layout'">
            <app-sales-area-layout [DataSourceInput]="getDataValue" [DragDrop]="dragDataItem"
                [salesAreabit]="salesAreabit"></app-sales-area-layout>
        </div> -->

    </mat-expansion-panel>
</mat-accordion>
<div class="form-group">
    <button class="btn  btn-sm float-right submit_button button-none" type="submit">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label></button>
</div>
