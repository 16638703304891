import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { iGiftCardSelect, ActivatePayment } from 'src/app/interfaces/icustompayment';
import { PaymentService } from 'src/app/services/payment.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { CustomUberEatsComponent } from '../custom-uber-eats/custom-uber-eats.component';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-custom-payment',
  templateUrl: './custom-payment.component.html',
  styleUrls: ['./custom-payment.component.css']
})
export class CustomPaymentComponent implements OnInit {
  loginInfo: any;
  giftId: any = 0;
  tableEnable: boolean = true;
  public tableRowId: any;
  public tableRowColor: any;

  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public generalDataemit: any;
  isInvalid: boolean = false;

  public CustData: any;
  public dataSource = new MatTableDataSource<any>();
  public EmployeeTableData: any;
  public displayedColumns = [];
  columns = [];
  public activeDeactiveValue: any;
  public getDataValue: any;
  private buttonDisable: boolean;
  public modifierItemsData: any;

  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public custom_uber = new EventEmitter();
  @Output() public CustomPaymentsessData = new EventEmitter();

  //@Input() public buttonDisable
  @ViewChild(CustomUberEatsComponent) CustPayComp: CustomUberEatsComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  isValidError: any;

  constructor(private objPayment: PaymentService, private toaster: ToasterService, private helper: HelperService,
    private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.CustomPayments");

    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.tableEnable = true;
    this.employeeName = "Custom Payments";
    this.employee = "Custom Payments";
    this.employeeIcon = "assets/components/images/payment.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)

    // if(this.loginInfo.tenantId == 0){
    //   this.toaster.error("Please Select store");
    //   this.buttonDisable = true;
    // }else{
    //   this.getCustomPaymentList();
    // }

    this.getCustomPaymentList();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  getCustomPaymentList() {
    const menuItemList: iGiftCardSelect = {
      id: this.giftId,
      tenantId: this.loginInfo.tenantId
    }
    this.objPayment.SelectCustomPayment(menuItemList)
      .subscribe(res => {
        this.dataSource.data = res;
        this.EmployeeTableData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true);
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('OnDirty') == true) {
      if (this.helper.getValue('Sessionid_help') == undefined ||
        this.helper.getValue('Sessionid_help') == null ||
        this.helper.getValue('Sessionid_help') == "") {
        this.helper.setValue('Sessionid_help', 0)
      }
      else {
        //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
        this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
      }
    }
  }

  getTableIdx(event) {
    if (event.event != undefined && event.event != null && event.event != "") {
      this.CustData = event;
      this.employeeName = event.event.Name != undefined ? event.event.Name : name;
    }
  }

  async getTableId(event) {
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("CustomPayments");
      if (conf == 'success') { // Navigate Away
        this.dialogser.ClearSession();
        this.CustPayComp.CustomPaymentForm.reset();

        //General Data
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.getGeneralDetail(event);
        this.isInvalid = false;
        this.flipcomp.rowClicked = event;
        this.dialogser.ModifiedId = event;
      }
      else // Cancel
      {
        //General Data
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        this.CustomPaymentsessData.emit({
          data: this.CustPayComp.CustomPaymentForm.value, event: "CustomPayments", val: true,
          itmname: this.CustPayComp.CustomPaymentForm.value.name
        });
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event)
      this.tableRowId = event;
      this.tableRowColor = event;
      this.getGeneralDetail(event);
      this.isInvalid = false;
      this.CustPayComp.requiredErrors = false;
    }
  }

  getTableName(name) {
    this.employeeName = name;
    //this.employee = "Gift-Card";
    //this.employeeIcon = "assets/components/images/gift-card.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  getGeneralDetail(PayId) {
    const ItemTypeList: iGiftCardSelect = {
      id: PayId,
      tenantId: this.loginInfo.tenantId
    }
    this.objPayment.SelectCustomPayment(ItemTypeList)
      .subscribe(res => {
        if (res != undefined && res != null && res != "") {
          this.getDataValue = res;
        }
      })
  }

  async addMenuItem() {
    //if (this.CustPayComp.isFormPageDirty() == false) {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    }
    else {
      var conf = await this.dialogser.confirm("CustomPayments");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
      }
      else {
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.CustPayComp.errorValue = "";
    this.isInvalid = false;
    this.CustPayComp.requiredErrors = false;
    this.employeeName = "Custom Payment";
    this.tableRowColor = 'transparent';
    this.CustPayComp.CustomPaymentForm.reset();
    this.CustPayComp.submittedGeneral = false
    this.titleName.emit(this.employeeName);
  }

  checkValue(event) {
    this.activeDeactiveValue = event
    let currentTarget = event.e.currentTarget.checked
    const active: ActivatePayment = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked
    }
    this.objPayment.ActivateCustomPayment(active).subscribe(
      (response) => {
        if (currentTarget) {
          this.toaster.successPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
        }
        this.getCustomPaymentList();
      },
    )
  }

  getCustomList(event) {
    this.generalDataemit = event.res.id;
    this.getCustomPaymentList();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name)
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  isBool(event) {
    this.isValidError = event;
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  OnSave() {
    this.CustPayComp.onSaveGeneral();
    if(this.CustPayComp.CustomPaymentForm.controls['PMS_ServerIP'].invalid){
      this.isInvalid = true;
      return;
      }
    if (this.CustPayComp.CustomPaymentForm.invalid) {
        this.isInvalid = true;
      }
    else if ((this.isValidError == true)) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }
  }

  getCustomPaymentsData(e) {
    this.CustomPaymentsessData.emit(e)
  }
}
