<form class="SaleArea_general" [formGroup]="SaleAreaLogistic" (change)="onchange(SaleAreaLogistic.value)">
    <!-- SaleareaLogistic -->
    <h6>Customer Requirements</h6>
    <label class="custom-color">Required Options</label>
    <div class="main-item row form-group">
        <div class="col-sm-2 col-4">
            <div class="checkbox-table accordion-checkbox">
                <label class="customlable mb-0">Name</label>
                <div class="custom-control custom-checkbox custom-control item">
                    <input type="checkbox" class="custom-control-input " name="customCheckbox" formControlName="IsCustomerNameRequire" id="Nameid">
                    <label class="custom-control-label" for="Nameid"></label>
                </div>
            </div>
        </div>
        <div class="col-sm-2  col-4">
            <div class="checkbox-table accordion-checkbox">
                <label class="customlable mb-0">Phone</label>
                <div class="custom-control custom-checkbox custom-control item">
                    <input type="checkbox" class="custom-control-input " name="customCheckbox" formControlName="IsPhoneNoRequire" id="Phone">
                    <label class="custom-control-label" for="Phone"></label>
                </div>
            </div>
        </div>
        <div class="col-sm-2 col-4">
            <div class="checkbox-table accordion-checkbox">
                <label class="customlable mb-0">ID</label>
                <div class="custom-control custom-checkbox custom-control item">
                    <input type="checkbox" class="custom-control-input " name="customCheckbox" formControlName="IsIdRequire" id="IsIdRequire">
                    <label class="custom-control-label" for="IsIdRequire"></label>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-4">
            <div class="checkbox-table accordion-checkbox">
                <label class="customlable mb-0">Info On Receipt</label>
                <div class="custom-control custom-checkbox custom-control item">
                    <input type="checkbox" class="custom-control-input " name="customCheckbox" formControlName="IsReceiptInfoRequire" id="InfoOnReceipt">
                    <label class="custom-control-label" for="InfoOnReceipt"></label>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-6">
            <div class="checkbox-table accordion-checkbox">
                <label class="customlable mb-0">Print Info On Kitchen Ticket</label>
                <div class="custom-control custom-checkbox custom-control item">
                    <input type="checkbox" class="custom-control-input " name="customCheckbox" formControlName="IsPrintInfoOnKitTic" id="PrintInfoOnKitchenTicket">
                    <label class="custom-control-label" for="PrintInfoOnKitchenTicket"></label>
                </div>
            </div>
        </div>
    </div>



    <label class="mt-2 custom-color">Tip Behavior</label>
    <div class="row logisticradio">
        <div class="col-sm-4 col-4">
            <div class="radiorow">
                <label class="radiolabel">Suggest Tip</label>
                <!-- <div class="form-group">
                    <div class="switchToggle">
                        <input type="checkbox" id="IsSuggestTips" formControlName="IsSuggestTips">
                        <label class="toggle-button-switch" for="IsSuggestTips"></label>
                        <div class="toggle-button-text outshadow">
                            <div class="toggle-button-text-on">ON</div>
                            <div class="toggle-button-text-off">OFF</div>
                        </div>
                    </div>
                </div> -->
                <div class="form-group">
                    <label class="switch switch-width">
                        <input type="checkbox" formControlName="IsSuggestTips">
                        <span class="slider round"></span>
                        <div class="toggle-button-text outshadow employee_onoff">
                            <div class="toggle-button-text-on"></div>
                            <div class="toggle-button-text-off"></div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-sm-5 col-9 outshadow" [style.display]="SaleAreaLogistic.get('IsSuggestTips').value ? 'flex' : 'none'">
            <div class="row">
                <div class="col-sm-4 col-4">
                    <label>Type</label>
                    <div class="logisticToggle">
                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="SelectedTypeDefault[0].value" class="logisticshadowtoggle">
                            <mat-button-toggle class="card-header form-control input-lg inputbox_login" value="$$" (change)="OnClickToggle(1)" forControlName="IsPercent">$$</mat-button-toggle>
                            <mat-button-toggle class="card-header form-control input-lg inputbox_login" value="%%" (change)="OnClickToggle(2)" forControlName="IsPercent">%</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="row">
                        <div class="col-sm-4 col-4 tipToggle">
                            <div class="form-group">
                                <label class="pricepadding">#1</label>
                                <input type="text" class="form-control" formControlName="Data" (keypress)="numberOnly($event)" [placeholder]="placeholderIcon" appInputRestriction />
                            </div>
                        </div>
                        <div class="col-sm-4 col-4 tipToggle">
                            <div>
                                <div class="form-group">
                                    <label class="pricepadding">#2</label>
                                    <input type="text" class="form-control" formControlName="Data1" (keypress)="numberOnly($event)" [placeholder]="placeholderIcon" appInputRestriction />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-4 tipToggle">
                            <div>
                                <div class="form-group">
                                    <label class="pricepadding">#3</label>
                                    <input type="text" class="form-control" formControlName="Data2" (keypress)="numberOnly($event)" [placeholder]="placeholderIcon" appInputRestriction />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-4">
            <div class="radiorow">
                <label class="radiolabel">Tip On Tablet</label>
                <!-- <div class="form-group">
                    <div class="switchToggle">
                        <input type="checkbox" id="TiponTablet" formControlName="TiponTablet">
                        <label class="toggle-button-switch" for="TiponTablet"></label>
                        <div class="toggle-button-text outshadow">
                            <div class="toggle-button-text-on">ON</div>
                            <div class="toggle-button-text-off">OFF</div>
                        </div>
                    </div>
                </div> -->
                <div class="form-group">
                    <label class="switch switch-width">
                        <input type="checkbox" formControlName="TiponTablet">
                        <span class="slider round"></span>
                        <div class="toggle-button-text outshadow employee_onoff">
                            <div class="toggle-button-text-on"></div>
                            <div class="toggle-button-text-off"></div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-sm-3 col-3 outshadow" *ngIf="SaleAreaLogistic.get('TiponTablet').value">
            <div class="row">
                <div class="col-sm-5 col-4">
                    <div class="radiorow">
                        <label class="radiolabel">No Tip</label>
                        <div class="form-group">
                            <div class="custom-control custom-radio custom-control item">
                                <input id="No Tip" type="radio" class="custom-control-input" value="No Tip" name="tipType" formControlName="tipType">
                                <label class="custom-control-label" for="No Tip"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-7 col-4">
                    <div class="radiorow">
                        <label class="radiolabel">Custom Tip</label>
                        <div class="form-group">
                            <div class="custom-control custom-radio custom-control item">
                                <input id="Custom Tip" type="radio" class="custom-control-input" value="Custom Tip" name="tipType" formControlName="tipType">
                                <label class="custom-control-label" for="Custom Tip"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-sm-6 col-4">
            <div class="checkbox-table accordion-checkbox">
                <label class="customlable mb-0">Print Sales Area In Red</label>
                <div class="custom-control custom-checkbox custom-control item">
                    <input type="checkbox" class="custom-control-input " name="customCheckbox" formControlName="PrintSalesArea" id="PrintSalesArea">
                    <label class="custom-control-label" for="PrintSalesArea"></label>
                </div>
            </div>
        </div>
        <div class="col-sm-4 col-4">
            <div class="form-group">
                <label class="customlable mb-0">Default Menu Group</label>
                <div class="StateWrap">
                    <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" formControlName="MenuGroupRef" (selectionChange)="onchange(SaleAreaLogistic.value)">
                        <mat-option *ngFor="let item of defaultValue" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group mt-2 loginForm">
        <button class="btn btn-sm float-right submit_button button-none" type="submit" [disabled]="buttonDisable ? true : null">
            <!-- [disabled]="buttonDis ? true : null" -->
            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label>
        </button>
    </div>

</form>