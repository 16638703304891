import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { BreakService } from 'src/app/services/break.service';
import { IGetBreak } from 'src/app/interfaces/iemployee';
import { IActiveBreak } from 'src/app/interfaces/itime';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-employee-general-break',
  templateUrl: './employee-general-break.component.html',
  styleUrls: ['./employee-general-break.component.css'],
})
export class EmployeeGeneralBreakComponent implements OnInit {
  BreakForm: FormGroup;
  buttonDisable: boolean = false;
  loginInfo: any;
  submitted: any;
  breakValueID: any;
  breakGetList: any = [];
  @Input() public tableRowId;
  @Input() public currentTarget;
  @Output() public breakItem = new EventEmitter();
  @Output() public isInvalid = new EventEmitter();
  @Output() public empgenbrk_child = new EventEmitter();
  requiredErrors: boolean = false;
  errorValue: any;
  //@Input() public buttonState;

  constructor(
    private breakService: BreakService,
    private formBuilder: FormBuilder,
    private dialogser: DialogService,
    private toaster: ToasterService,
    private helper: HelperService
  ) {}

  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.BreakForm = this.formBuilder.group({
      name: ['', Validators.required],
      isPaidBreak: [false],
      isRequired: [false],
      minimumTime: [false],
      textWarning: [false],
      logBook: [false],
      minutes: null,
    });
    this.loginInfo = this.helper.getValue('LoginInfo');
  }

  get breakData() {
    return this.BreakForm.controls;
  }

  @Input()
  public set DataIdx(tableRowId) {
    this.errorValue = '';
    this.breakValueID = tableRowId;
    if (tableRowId != undefined && tableRowId != null && tableRowId != '') {
      this.getEmployee(tableRowId);
    }
  }
  @Input()
  public set checkTarget(currentTarget) {
    this.currentTarget = currentTarget;
    if (
      this.currentTarget != undefined &&
      this.currentTarget != null &&
      this.currentTarget != ''
    ) {
      this.checkValue(this.currentTarget);
    }
  }
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !btnst;
    }
  }

  onSaveBreak() {
    this.submitted = true;
    if (this.BreakForm.invalid) {
      return;
    }
    this.onSaveCompleteForm(this.BreakForm.value);
  }

  onSaveCompleteForm(employee): void {
    employee.id = this.breakValueID > 0 ? this.breakValueID : 0;
    employee.tenantId = this.loginInfo.tenantId;
    employee.createdBy = this.loginInfo.userId;
    this.breakService
      .UserBreakEmployee(employee)
      .subscribe(async (response) => {
        this.breakValueID = response.id;
        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
          await this.dialogser.ClearSession();
          this.helper.setValue('GetSessionData1', employee);
          this.empgenbrk_child.emit({
            data: employee,
            event: 'BreakPage',
            val: false,
          });
        }
        this.breakItem.emit({ res: response, name: employee.name });
        //this.BreakForm.reset();
        this.submitted = false;
      });
  }

  getEmployee(empid) {
    const employee: IGetBreak = {
      breakid: empid,
      tenantid: 0,
    };
    this.breakService.GetBreakList(employee).subscribe((response) => {
      this.breakGetList = response;
      this.BreakForm.patchValue(this.breakGetList[0]);
    });
  }

  checkValue(e?: any) {
    let currentTarget = e.e.currentTarget.checked;
    const active: IActiveBreak = {
      userId: this.loginInfo.userId,
      breakId: e.id,
      isActive: currentTarget,
    };
    this.breakService.IsActiveBreakUser(active).subscribe((response) => {
      this.getEmployee(this.breakValueID);
    });
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue = '';
    }
  }

  EmployeeGeneralValid() {
    if (this.BreakForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredErrors = true;
      return;
    } else {
      this.isInvalid.emit(false);
      this.requiredErrors = false;
    }
  }

  Highlight() {
    if (this.BreakForm.value.name != null && this.BreakForm.value.name != '') {
      if (this.BreakForm.value.name.length < 0) {
        this.requiredErrors = true;
        this.isInvalid.emit(true);
      } else {
        this.requiredErrors = false;
        this.isInvalid.emit(false);
      }
    } else if (
      this.BreakForm.value.name == null ||
      (this.BreakForm.value.name == '' && this.BreakForm.value.name.length == 0)
    ) {
      this.requiredErrors = false;
      this.isInvalid.emit(false);
    } else if (
      this.BreakForm.value.name == null ||
      this.BreakForm.value.name == ''
    ) {
      this.requiredErrors = true;
      this.isInvalid.emit(true);
    }
  }

  ngAfterViewInit() {
    this.BreakForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.empgenbrk_child.emit(this.BreakForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.empgenbrk_child.emit({ data: data, event: 'BreakPage', val: true });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
