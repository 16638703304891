import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { MatOption } from '@angular/material/core';
import { adjustmentService } from 'src/app/services/adjustment.service';
import { GetAdjustment, SelectAdjustGeneral } from 'src/app/interfaces/iadjustment';
import { GetSaleAreaGeneral } from 'src/app/interfaces/imenu';
import { MenuService } from 'src/app/services/menu.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-adjustment-details',
  templateUrl: './adjustment-details.component.html',
  styleUrls: ['./adjustment-details.component.css']
})

export class AdjustmentDetailsComponent implements OnInit {
  buttonDisable: boolean = false;
  public generalDataemit: any;
  adjustmentDetailForm: FormGroup;
  detailsId: any = 0;
  adjustmentID: any = 0;
  submittedSaveGeneral = false;
  isSubmitted = false;
  isValidated = false;
  loginInfo: any;
  prevMinVal: any;
  errorMessage: any;
  errorMessage1: any;
  minValue: any;
  maxValue: any;
  isActive = false;

  SalesAreaFilters = [];
  menuGroupFilters = [];
  menuPageFilters = [];
  specificItemsFilters = [];

  SalesAreaLimitValue = [];

  salesselectedItems = [];
  groupselectedItems = [];
  pageselectedItems = [];
  specificselectedItems = [];

  menuGroupLimit: FormControl = new FormControl('');
  menuPageLimit: FormControl = new FormControl('');
  specificItems: FormControl = new FormControl('');
  salesAreaLimit: FormControl = new FormControl('');
  selectedItems = [];

  // @Input() public buttonState;
  @Input()
  public set DataIdx(tableRowId) {
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.adjustmentID = tableRowId;
      this.GetAdjustmentDetailsList(tableRowId);
    }
  }

  @ViewChild('allSelected') private allSelected: MatOption;
  SalesSettings: IDropdownSettings = {};

  @ViewChild('groupSelected') private groupSelected: MatOption;
  @ViewChild('pageSelected') private pageSelected: MatOption;
  @ViewChild('itemSelected') private itemSelected: MatOption;
  @ViewChild('areaSelected') private areaSelected: MatOption;
  @Output() public adjdetData_child = new EventEmitter();

  constructor(private objItem: MenuService, private formBuilder: FormBuilder, private helper: HelperService,
    private objAdj: adjustmentService, private toaster: ToasterService, private dialogser: DialogService) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData2', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.adjustmentDetailForm = this.formBuilder.group({
      detailsId: [0],
      adjustmentID: [0],
      isActive: [false],
      isAfterTax: [null],
      isSideDish_Modifier: [null],
      isAllowtips: [null],
      minAmount: [null],
      maxAmount: [null],
      isGuestRequired: [null],
      itemCount: [null],

      SalesAreaFilters: [null],
      menuGroupFilters: [null],
      menuPageFilters: [null],
      specificItemsFilters: [null],

      menuGroupLimit: new FormControl(''),
      menuPageLimit: new FormControl(''),
      specificItems: new FormControl(''),
      salesAreaLimit: new FormControl(''),

      salesselectedItems: [null],
      groupselectedItems: [null],
      pageselectedItems: [null],
      specificselectedItems: [null],

    })
    this.getBindDropDownList();
    this.SalesSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 2,
      selectAllText: 'Select All',
      unSelectAllText: 'Select All'
    };
  }
  get SavegeneralGroup() { return this.adjustmentDetailForm.controls; }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkInput(e, input) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];

    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue) && e.keyCode != 9) {
      e.preventDefault();
      return;
    }

    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if ((+newValue > 100 || newValue.length > 2) && e.keyCode != 9) {
      e.preventDefault();
    }
  }
  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }

  onChangeMaxEvent(event: any) {
    this.maxValue = parseInt(event.target.value)
    if (this.maxValue < this.minValue) {
      this.errorMessage1 = 'Max value should be greater than Min value';
    }
    else {
      this.errorMessage = '';
      this.errorMessage1 = ''
    }
  }

  onChangeMinEvent(event: any) {
    this.minValue = parseInt(event.target.value)
    if (this.minValue > parseInt(this.SavegeneralGroup.minAmount.value)) {
      this.errorMessage = 'Min value should be lesser than Max value';
    }
    else {
      this.errorMessage1 = '';
      this.errorMessage = '';
    }
  }

  togglesalesAllSelection() {
    if (this.areaSelected.selected) {
      for (let i = 0; i < this.SalesAreaFilters.length; i++) {
        this.SalesAreaLimitValue.push(this.SalesAreaFilters[i].key)
        this.adjustmentDetailForm.controls.salesAreaLimit.patchValue(this.SalesAreaLimitValue)
      }
      this.adjustmentDetailForm.controls.salesAreaLimit
        .patchValue([...this.SalesAreaFilters.map(item => item.key), 0]);

    } else {
      this.adjustmentDetailForm.controls.salesAreaLimit.patchValue([]);
    }
  }

  tosslesalesPerOne(all) {
    if (this.areaSelected.selected) {
      this.areaSelected.deselect();
      return;
    }
    if (this.adjustmentDetailForm.controls.salesAreaLimit.value.length == this.SalesAreaFilters.length)
      this.areaSelected.select();
  }

  togglegroupAllSelection() {
    if (this.groupSelected.selected) {
      this.adjustmentDetailForm.controls.menuGroupLimit
        .patchValue([...this.menuGroupFilters.map(item => item.key), 0]);

      this.adjustmentDetailForm.controls.menuGroupLimit.value.splice(this.adjustmentDetailForm.controls.menuGroupLimit.value.length - 1, 1)
    } else {
      this.adjustmentDetailForm.controls.menuGroupLimit.patchValue([]);
    }
  }

  tosslegroupPerOne(all) {
    if (this.groupSelected.selected) {
      this.groupSelected.deselect();
      return;
    }
    if (this.adjustmentDetailForm.controls.menuGroupLimit.value.length == this.menuGroupFilters.length)
      this.groupSelected.select();
  }

  togglepageAllSelection() {
    if (this.pageSelected.selected) {
      this.adjustmentDetailForm.controls.menuPageLimit
        .patchValue([...this.menuPageFilters.map(item => item.key), 0]);
    } else {
      this.adjustmentDetailForm.controls.menuPageLimit.patchValue([]);
    }
  }

  tosslepagePerOne(all) {
    if (this.pageSelected.selected) {
      this.pageSelected.deselect();
      return;
    }
    if (this.adjustmentDetailForm.controls.menuPageLimit.value.length == this.menuPageFilters.length)
      this.pageSelected.select();
  }

  toggleitemAllSelection() {
    if (this.itemSelected.selected) {
      this.adjustmentDetailForm.controls.specificItems
        .patchValue([...this.specificItemsFilters.map(item => item.key), 0]);
    } else {
      this.adjustmentDetailForm.controls.specificItems.patchValue([]);
    }
  }

  tossleitemPerOne(all) {
    if (this.itemSelected.selected) {
      this.itemSelected.deselect();
      return;
    }
    if (this.adjustmentDetailForm.controls.specificItems.value.length == this.specificItemsFilters.length)
      this.itemSelected.select();
  }

  getBindDropDownList() {
    const Active: GetSaleAreaGeneral = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.objItem.getSalesAreaDetails(Active).subscribe(
      (response) => {
        var jsonSalesArea = response.SalesAreaGeneral;
        var jsonMenuGrp = response.MenuGroups;
        var jsonMenuPage = response.MenuPages;
        var jsonSpecificTag = response.ItemDetails;

        var itemSalesArea
        var jsonToBeUsedSalesArea = [];
        for (var type in jsonSalesArea) {
          itemSalesArea = {};
          itemSalesArea.key = jsonSalesArea[type].id.toString();
          itemSalesArea.value = jsonSalesArea[type].name;
          jsonToBeUsedSalesArea.push(itemSalesArea);
        }
        this.SalesAreaFilters = jsonToBeUsedSalesArea;

        //------------MENUGROUP--------

        var itemMenuGrp
        var jsonToBeUsedMenuGrp = [];
        for (var type in jsonMenuGrp) {
          itemMenuGrp = {};
          itemMenuGrp.key = jsonMenuGrp[type].id.toString();
          itemMenuGrp.value = jsonMenuGrp[type].name;
          jsonToBeUsedMenuGrp.push(itemMenuGrp);
        }
        this.menuGroupFilters = jsonToBeUsedMenuGrp;

        //------------MENUPAGE-----------

        var itemMenuPage
        var jsonToBeUsedMenuPage = [];
        for (var type in jsonMenuPage) {
          itemMenuPage = {};
          itemMenuPage.key = jsonMenuPage[type].id.toString();
          itemMenuPage.value = jsonMenuPage[type].name;
          jsonToBeUsedMenuPage.push(itemMenuPage);
        }
        this.menuPageFilters = jsonToBeUsedMenuPage;

        //--------------SpecificTag-----

        var itemSpecificTag
        var jsonToBeUsedSpecificTag = [];

        for (var type in jsonSpecificTag) {
          itemSpecificTag = {};
          itemSpecificTag.key = jsonSpecificTag[type].id.toString();
          itemSpecificTag.value = jsonSpecificTag[type].name;
          jsonToBeUsedSpecificTag.push(itemSpecificTag);
        }
        this.specificItemsFilters = jsonToBeUsedSpecificTag;
      })
  }

  onSaveDetails(id?) {
    this.submittedSaveGeneral = true;
    this.isValidated = true;
    this.isSubmitted = true;
    this.isActive = true;
    this.adjustmentID = id;
    this.adjustmentDetailForm.value.detailsId = this.detailsId;
    this.adjustmentDetailForm.value.adjustmentID = this.adjustmentID;
    this.adjustmentDetailForm.value.tenantId = this.loginInfo.tenantId;
    this.adjustmentDetailForm.value.createdBy = this.loginInfo.userId;
    this.adjustmentDetailForm.value.modifiedBy = this.loginInfo.userId;

    if (this.salesselectedItems != null) {

      var ArraySalesArea = [];
      var clientsSalesArea = this.salesselectedItems;
      for (var a = 0, len = clientsSalesArea.length; a < len; a++) {
        ArraySalesArea.push(clientsSalesArea[a].key);
      }
      this.adjustmentDetailForm.value.salesAreaLimit = ArraySalesArea.toString();
    }

    if (this.groupselectedItems != null) {

      var Arraygroup = [];
      var clientsgroup = this.groupselectedItems;
      for (var i = 0, len = clientsgroup.length; i < len; i++) {
        Arraygroup.push(clientsgroup[i].key);
      }
      this.adjustmentDetailForm.value.menuGroupLimit = Arraygroup.toString();
    }

    if (this.pageselectedItems != null) {

      var Arraypage = [];
      var clientspage = this.pageselectedItems;
      for (var i = 0, len = clientspage.length; i < len; i++) {
        Arraypage.push(clientspage[i].key);
      }
      this.adjustmentDetailForm.value.menuPageLimit = Arraypage.toString();
    }

    if (this.specificselectedItems != null) {

      var ArraySpecif = [];
      var clientsSpecif = this.specificselectedItems;
      for (var i = 0, len = clientsSpecif.length; i < len; i++) {
        ArraySpecif.push(clientsSpecif[i].key);
      }
      this.adjustmentDetailForm.value.specificItems = ArraySpecif.toString();
    }

    //console.log(this.adjustmentDetailForm.value,'this.adjustmentDetailForm.value')

    this.objAdj.SaveAdjustmentDetails(this.adjustmentDetailForm.value).subscribe(
      async (response) => {
        this.isSubmitted = false;
        this.detailsId = response.id;
        this.toaster.successPopUp(response.message);
        await this.dialogser.ClearSession();
        this.helper.setValue('GetSessionData2', this.adjustmentDetailForm.value);
        this.adjdetData_child.emit({ data: this.adjustmentDetailForm.value, event: "adjustmentPage", val: false });
      })
  }

  getGeneralDetail(event) {
    this.generalDataemit = event;
  }

  GetAdjustmentDetailsList(rowId) {
    const adjustList: SelectAdjustGeneral = {
      id: rowId,
      tenantid: this.loginInfo.tenantId,
    }
    this.objAdj.EditAdjustmentGeneral(adjustList)
      .subscribe(res => {
        this.detailsId = res.AdjustmentDetails[0].Detailsid == null ? 0 : res.AdjustmentDetails[0].Detailsid;
        let adjustVal = res.AdjustmentDetails[0];

        this.adjustmentDetailForm.patchValue({
          isAfterTax: adjustVal.isAfterTax,
          isSideDish_Modifier: adjustVal.isSideDish_Modifier,
          isAllowtips: adjustVal.isAllowtips,
          minAmount: adjustVal.minAmount,
          maxAmount: adjustVal.maxAmount,
          isGuestRequired: adjustVal.isGuestRequired,
          itemCount: adjustVal.itemCount
        });

        var salesjsonObject = res.AdjustmentSALESAREA[0].salesAreaLimit;
        if (salesjsonObject != null && salesjsonObject != '') {
          this.salesselectedItems = JSON.parse(salesjsonObject);
        }
        else {
          this.salesselectedItems = [];
        }
        var groupjsonObject = res.AdjustmentMENUGROUP[0].menuGroupLimit;
        if (groupjsonObject != null && groupjsonObject != '') {
          this.groupselectedItems = JSON.parse(groupjsonObject);
        }
        else {
          this.groupselectedItems = [];
        }

        var pagejsonObject = res.AdjustmentMENUPAGE[0].menuPageLimit;
        if (pagejsonObject != null && pagejsonObject != '') {
          this.pageselectedItems = JSON.parse(pagejsonObject);
        }
        else {
          this.pageselectedItems = [];
        }

        var specifjsonObject = res.AdjustmentSPECIFICTAG[0].specificItems;
        if (specifjsonObject != null && specifjsonObject != '') {
          this.specificselectedItems = JSON.parse(specifjsonObject);
        }
        else {
          this.specificselectedItems = [];
        }
      })
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  ngAfterViewInit() {
    this.adjustmentDetailForm.patchValue(this.helper.getValue('GetSessionData2'));
    this.adjdetData_child.emit(this.adjustmentDetailForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData2', data);
    this.helper.setValue('OnDirty2', true);
    this.adjdetData_child.emit({ data: data, event: "adjustmentPage", val: true });
  }
}