import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { ISelectTagDetails } from 'src/app/interfaces/i-tags';
import { MenuPageService } from 'src/app/services/menupage.service';
import { TagsService } from 'src/app/services/tags.service';
import { MenuGroupService } from 'src/app/services/menugroup.service';
import { GetMenuGroup, GetModifier, GetSaleAreaGeneralDD, IEventBuilder, SchedulerSelect } from 'src/app/interfaces/imenu';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EventPricingService } from 'src/app/services/event-pricing.service';

export interface User {
  id: number;
  name: string;
}
export class ArryList {
  varName: number;
}

@Component({
  selector: 'app-pricing-builder',
  templateUrl: './pricing-builder.component.html',
  styleUrls: ['./pricing-builder.component.css']
})

export class PricingBuilderComponent implements OnInit {
  pricingForm: FormGroup
  buttonDisable: boolean = false;
  ShowFilter = false;
  limitSelection = false; boolean
  submittedGeneral: any;
  loginInfo: any
  dropdownSettings: IDropdownSettings = {};
  ItemSettings: IDropdownSettings = {};
  multiGroupFilters = [];
  multiPageFilters = [];
  multiTagsFilters = [];
  multiSpecificItemsFilters = [];
  boxBool: boolean = false;
  boxBoolGet: boolean = false;
  boxBoolAdd: boolean = false;
  errorVal: boolean = false;
  valueFilter: any
  multiValue: FormControl = new FormControl('');
  multiData: FormControl = new FormControl('');

  multiValuePage: FormControl = new FormControl('');
  multiValueGroup: FormControl = new FormControl('');
  multiValueTag: FormControl = new FormControl('');
  // multiValueItem: FormControl = new FormControl('');
  multiSpecificItem: FormControl = new FormControl('');

  eventType: any
  appliesTo: any;
  roundUp: any;
  roundDown: any;
  searchField
  options = [];
  ItemVal
  filteredOptions: Observable<any>;
  @Output() pricingBuilderItem = new EventEmitter;
  @Output() public pricebuilderData_child = new EventEmitter();

  indexdata: number
  selectedQuarterList: string[] = [];
  SpecificData: boolean
  seedData = [];
  specificItemData = [];
  itemArr: Array<ArryList> = [];
  pricingbuilderarray: any;
  tagItems: any;
  datas: any = [];
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }
  @Input()
  public set DataIdx(tableRowId) {
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.PatchPriceBuilder(tableRowId);
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private toaster: ToasterService, private objGroup: MenuGroupService,
    private tagService: TagsService, private objPage: MenuPageService, private objEventPricing: EventPricingService) { }

  ngOnInit() {
    this.helper.setValue('GetSessionData3', null);
    this.buttonDisable = false;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'key',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'Select All',
      itemsShowLimit: 1,
      allowSearchFilter: this.ShowFilter
    };
    this.ItemSettings = {
      singleSelection: false,
      idField: 'key',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'Select All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    }
    this.pricingForm = this.formBuilder.group({
      filters: this.formBuilder.array([this.createFilterGroup()]),
    });

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getdropdownitems()
    // this.getspecificItem();
    // Uncomment the line below If you want to seed the Form with some data
    this.seedFiltersFormArray();
    this.getMenuPageList();
    this.getMenuGroupList();
    this.getTagList();
    this.getItemsList();

  }

  get filtersFormArray() {
    return <FormArray>this.pricingForm.get('filters');
  }

  // getspecificItem() {
  // this.filteredOptions = this.multiSpecificItem.valueChanges.pipe(
  //    startWith(''),
  //     map(value => (typeof value === 'string' ? value : value.name)),
  //     map(name => (name ? this._filter(name) : this.options.slice())),
  //   );
  // }

  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    this.valueFilter = this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    if (this.valueFilter.length > 0) {
      this.errorVal = false
      return this.valueFilter;
    } else {
      this.errorVal = true
      return null
    }
  }

  i: any = 0;
  onSelectionChange(event, idx) {
    let index = this.options.findIndex((element) => element.id == event.option.value.id);
    this.options.splice(index, 1);
    this.specificItemData.push(event.option.value)
    let cc = new ArryList;
    this.itemArr.push(cc.varName = event.option.value)
  }

  removeFilterFromspecificItem(index) {
    console.log(index, "index")
    for (let i = 0; i < this.pricingForm.get('filters').value.length; i++) {
      if (this.pricingForm.get('filters').value[i].multiSpecificItem != undefined || this.pricingForm.get('filters').value[i].multiSpecificItem != null) {
        for (let j = 0; j < this.pricingForm.get('filters').value[i].multiSpecificItem.length; i++) {
          this.onItemDeSelect(index, this.pricingForm.get('filters').value[i].multiSpecificItem.splice(index, 1))
        }
      }
    }


    // this.options.push(Item)
    // this.getspecificItem();
    // this.filteredOptions = this.options
  }

  seedFiltersFormArray() {
    this.seedData.forEach((seedDatum) => {
      const formGroup = this.createFilterGroup();
      if (seedDatum.apiType) {
        formGroup.addControl('offValue', this.getFormControl());
      } else if (seedDatum.appliesTo) {
        formGroup.addControl('multiValue', this.getFormControl());
      }
      formGroup.patchValue(seedDatum);
      this.filtersFormArray.push(formGroup);
    });
  }

  createFilterGroup() {
    return this.formBuilder.group({
      apiType: [null],
      appliesTo: [null],
      multiData: [null],
      multiValue: [null],
      offValue: [null],
      multiValuePage: [null],
      multiValueGroup: [null],
      multiValueTag: [null],
      //  multiValueItem: [null],
      multiSpecificItem: [null],
    });
  }

  addFilterToFiltersFormArray() {
    this.filtersFormArray.push(this.createFilterGroup());
    // if (this.multiSpecificItem.value == '') {

    //   this.boxBool = false;
    // }
    /// this.boxBoolGet=false;
  }

  removeFilterFromFiltersFormArray(index) {
    this.filtersFormArray.removeAt(index);
  }

  selectedAPIChanged(i) {
    this.onchange(this.pricingForm.value);

    this.helper.setValue('OnDirty', true);
    this.pricebuilderData_child.emit({ data: i, event: "eventPage", val: true });

    this.getFilterGroupAtIndex(i).addControl('offValue', this.getFormControl());
    console.log(this.getFilterGroupAtIndex(i).controls["apiType"].value, "EventType");

    // this.filtersFormArray.get('apiType').value
  }

  onChangeApplies(i, elem) {
    this.onchange(this.pricingForm.value);

    if (elem == "Menu Page") {
      this.getFilterGroupAtIndex(i).addControl('multiValuePage', this.getFormControl());
    } else {
      this.getFilterGroupAtIndex(i).addControl('multiValue', this.getFormControl());
      // if(i != this.indexdata){
      //   this.datas = []
      // }
    }
  }

  clearSearchField() {
    this.searchField = ''
  }

  getFormControl() {
    return this.formBuilder.control(null);
  }

  getFilterGroupAtIndex(index) {
    return <FormGroup>this.filtersFormArray.at(index);
  }

  getTagList() {
    const deviceList: ISelectTagDetails = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.tagService.SelectTagDetails(deviceList)
      .subscribe(res => {
        this.tagItems = res.filter(x => x.isdaypart == false && x.tagCategory == 'reports')
        var jsonTagValue = res.filter(x => x.isdaypart == false && x.tagCategory == 'reports')
        var itemMultiTagValue
        var jsonToBeUsedMultiTag = [];
        for (var type in jsonTagValue) {
          itemMultiTagValue = {};
          itemMultiTagValue.key = jsonTagValue[type].id.toString();
          itemMultiTagValue.value = jsonTagValue[type].name;
          jsonToBeUsedMultiTag.push(itemMultiTagValue);
        }
        this.multiTagsFilters = jsonToBeUsedMultiTag;
      })
  }

  getItemsList() {
    const iData: GetModifier = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.objEventPricing.SelectSpecificItems(iData)
      .subscribe(res => {
        var jsonItemsValue = res;
        var itemMultiSpecificValue;
        var jsonToBeUsedMultiSpecific = [];
        for (var type in res) {
          itemMultiSpecificValue = {};
          itemMultiSpecificValue.key = jsonItemsValue[type].tranId.toString();
          itemMultiSpecificValue.value = jsonItemsValue[type].itemName;
          jsonToBeUsedMultiSpecific.push(itemMultiSpecificValue);
        }
        this.multiSpecificItemsFilters = jsonToBeUsedMultiSpecific;
      })
  }

  getMenuGroupList() {
    const menuGroupList: GetMenuGroup = {
      tenantId: this.loginInfo.tenantId
    }
    this.objGroup.MenuGroupget(menuGroupList)
      .subscribe(res => {
        var jsonGroupValue = res;
        var itemMultiGroupValue;
        var jsonToBeUsedMultiGroup = [];
        for (var type in res) {
          itemMultiGroupValue = {};
          itemMultiGroupValue.key = jsonGroupValue[type].id.toString();
          itemMultiGroupValue.value = jsonGroupValue[type].name;
          jsonToBeUsedMultiGroup.push(itemMultiGroupValue);
        }
        this.multiGroupFilters = jsonToBeUsedMultiGroup;
      })
  }

  getMenuPageList() {
    const menuPageList: GetMenuGroup = {
      tenantId: this.loginInfo.tenantId
    }
    this.objPage.MenuPageGet(menuPageList)
      .subscribe(res => {
        var jsonPageValue = res;
        var itemMultiPageValue;
        var jsonToBeUsedMultiPage = [];
        for (var type in res) {
          itemMultiPageValue = {};
          itemMultiPageValue.key = jsonPageValue[type].id.toString();
          itemMultiPageValue.value = jsonPageValue[type].name;
          jsonToBeUsedMultiPage.push(itemMultiPageValue);
        }
        this.multiPageFilters = jsonToBeUsedMultiPage;
      })
  }

  savePricingBuilder() {
    this.submittedGeneral = true;
    if (this.pricingForm.invalid) {
      return;
    }

    var filterArr = this.pricingForm.value.filters;

    var EventVal: IEventBuilder[] = [];
    this.pricingForm.reset();

    for (var n = 0; n < filterArr.length; n++) {
      var arrMenuPages = []; var arrMenuGrp = []; var arrTags = [];
      var arrvalMenuPages = []; var arrvalMenuGrp = []; var arrvalTags = []; var arrSpecificItem = []; var arrvalSpecificItem = [];

      if (filterArr[n].multiValuePage != null) {
        for (var a1 = 0, len1 = filterArr[n].multiValuePage.length; a1 < len1; a1++) {
          arrMenuPages.push(filterArr[n].multiValuePage[a1].key);
          arrvalMenuPages.push(filterArr[n].multiValuePage[a1]);
        }
      }

      if (filterArr[n].multiValueGroup != null) {
        for (var a2 = 0, len2 = filterArr[n].multiValueGroup.length; a2 < len2; a2++) {
          arrMenuGrp.push(filterArr[n].multiValueGroup[a2].key);
          arrvalMenuGrp.push(filterArr[n].multiValueGroup[a2]);
        }
      }

      if (filterArr[n].multiValueTag != null) {
        for (var a3 = 0, len3 = filterArr[n].multiValueTag.length; a3 < len3; a3++) {
          arrTags.push(filterArr[n].multiValueTag[a3].key);
          arrvalTags.push(filterArr[n].multiValueTag[a3]);
        }
      }

      if (filterArr[n].multiSpecificItem != null) {
        for (var a3 = 0, len3 = filterArr[n].multiSpecificItem.length; a3 < len3; a3++) {
          arrSpecificItem.push(filterArr[n].multiSpecificItem[a3].key);
          arrvalSpecificItem.push(filterArr[n].multiSpecificItem[a3]);
        }
      }

      const EventData: IEventBuilder = {
        apiType: filterArr[n].apiType,
        appliesTo: filterArr[n].appliesTo,
        offValue: filterArr[n].offValue,
        multiValue: filterArr[n].appliesTo == 'Tags' ? arrTags.toString() : filterArr[n].appliesTo == 'Menu Group' ? arrMenuGrp.toString() : arrSpecificItem.toString(),
        multiData: filterArr[n].appliesTo == 'Tags' ? JSON.stringify(arrvalTags) : filterArr[n].appliesTo == 'Menu Group' ? JSON.stringify(arrvalMenuGrp) : filterArr[n].appliesTo == 'Menu Page' ? JSON.stringify(arrvalMenuPages) : filterArr[n].appliesTo == 'Specific Items' ? JSON.stringify(arrvalSpecificItem) : "",
        multiValuePage: filterArr[n].appliesTo == 'Menu Page' ? arrMenuPages.toString() : "",
        multiValueGroup: filterArr[n].appliesTo == 'Menu Group' ? arrMenuGrp.toString() : "",
        multiValueTag: filterArr[n].appliesTo == 'Tags' ? arrTags.toString() : "",
        multiSpecificItem: filterArr[n].appliesTo == 'Specific Items' ? arrSpecificItem.toString() : ""
      }
      EventVal.push(EventData);
    }

    //------------------------*************--------

    this.pricingbuilderarray = EventVal;
    if (this.pricingbuilderarray.length > 0) {
      const dynFormArray = this.pricingForm.controls["filters"] as FormArray;
      dynFormArray.controls = [];
      this.specificItemData = [];

      if (this.pricingbuilderarray != undefined && this.pricingbuilderarray != null && this.pricingbuilderarray != "") {
        if (this.pricingbuilderarray.length > 0) {
          for (let q = 0; q < this.pricingbuilderarray.length; q++) {
            dynFormArray.push(this.createFilterGroup());
            var jsonMultivalue = EventVal;
            if (jsonMultivalue != null) {
              let rowscontrol = this.pricingForm.get('filters') as FormArray;
              rowscontrol.controls[q].patchValue({
                apiType: EventVal[q].apiType,
                appliesTo: EventVal[q].appliesTo,
                multiData: EventVal[q].multiData,
                multiValue: EventVal[q].multiValue,
                offValue: EventVal[q].offValue
              }
              );
              rowscontrol.controls[q].patchValue({ multiValuePage: EventVal[q].multiValuePage });
              rowscontrol.controls[q].patchValue({ multiValueGroup: EventVal[q].multiValueGroup });
              rowscontrol.controls[q].patchValue({ multiValueTag: EventVal[q].multiValueTag });
              rowscontrol.controls[q].patchValue({ multiSpecificItem: EventVal[q].multiSpecificItem });


            }
          }
        }
      }
    }
    this.pricingBuilderItem.emit(this.pricingForm.value)
    this.pricebuilderData_child.emit({ data: this.pricingForm.value, event: "eventPage", val: false });
    this.helper.setValue('GetSessionData3', this.pricingForm.value)
  }

  getdropdownitems() {
    const iDropdown: GetSaleAreaGeneralDD = {
      parentCode: "ET1"
    }
    const iDropdown1: GetSaleAreaGeneralDD = {
      parentCode: "RU1"
    }
    const iDropdown2: GetSaleAreaGeneralDD = {
      parentCode: "RD1"
    }
    const iDropdown3: GetSaleAreaGeneralDD = {
      parentCode: "AT1"
    }
    this.objEventPricing.GetCodeMaster(iDropdown)
      .subscribe(res => {
        this.eventType = res;
      })
    this.objEventPricing.GetCodeMaster(iDropdown1)
      .subscribe(res => {
        this.roundUp = res;
      })
    this.objEventPricing.GetCodeMaster(iDropdown2)
      .subscribe(res => {
        this.roundDown = res;
      })
    this.objEventPricing.GetCodeMaster(iDropdown3)
      .subscribe(res => {
        this.appliesTo = res;
      })
  }

  PatchPriceBuilder(e) {
    const BuilderParam: SchedulerSelect = {
      id: e,
      tenantId: this.loginInfo.tenantId
    }

    this.objEventPricing.SelectPricingBuilder(BuilderParam).subscribe((response) => {
      this.pricingbuilderarray = response.filters;
      if (this.pricingbuilderarray.length > 0) {
        const dynFormArray = this.pricingForm.controls["filters"] as FormArray;
        dynFormArray.controls = [];
        this.specificItemData = [];

        if (this.pricingbuilderarray != undefined && this.pricingbuilderarray != null && this.pricingbuilderarray != "") {
          if (this.pricingbuilderarray.length > 0) {
            for (let k = 0; k < this.pricingbuilderarray.length; k++) {
              dynFormArray.push(this.createFilterGroup());
              var jsonMultivalue = response.filters[k].multiValue;
              if (jsonMultivalue != null && jsonMultivalue != '') {

                let rowscontrol = this.pricingForm.get('filters') as FormArray;
                rowscontrol.controls[k].patchValue({
                  apiType: response.filters[k].apiType,
                  TenantID: response.filters[k].TenantID,
                  appliesTo: response.filters[k].appliesTo,
                  eventPricingID: response.filters[k].eventPricingID,
                  id: response.filters[k].id,
                  multiValue: response.filters[k].multiValue,
                  multiSpecificItem: response.filters[k].multiSpecificItem,
                  offValue: response.filters[k].offValue
                }
                );

                if (response.filters[k].appliesTo == 'Menu Page') {
                  rowscontrol.controls[k].patchValue({ multiValuePage: JSON.parse(jsonMultivalue) });
                } else
                  if (response.filters[k].appliesTo == 'Menu Group') {
                    rowscontrol.controls[k].patchValue({ multiValueGroup: JSON.parse(jsonMultivalue) });
                  } else
                    if (response.filters[k].appliesTo == 'Tags') {
                      rowscontrol.controls[k].patchValue({ multiValueTag: JSON.parse(jsonMultivalue) });
                    } else
                      if (response.filters[k].appliesTo == 'Specific Items') {
                        // const array1 = this.pricingForm.get('filters') as FormArray;
                        // console.log(array1.at(2).value.multiSpecificItem ,"pricingForm.get('filters').controls[i].controls.multiSpecificItem")

                        if (jsonMultivalue.length <= 2) {
                          this.boxBool = false;
                        }
                        else {
                          rowscontrol.controls[k].patchValue({ multiSpecificItem: JSON.parse(jsonMultivalue) });

                          this.boxBool = true
                        }
                      } else {
                        //this.pricingForm.reset();
                        this.specificItemData = [];
                      }
              }
            }
            this.boxBoolGet = true;
          }
        }
      } else {
        const dynFormArray = this.pricingForm.controls["filters"] as FormArray;
        dynFormArray.controls = [];
        dynFormArray.push(this.createFilterGroup());
      }
    })
  }

  onItemSelect(item: any, index) {
    this.helper.setValue('OnDirty', true);
    this.pricebuilderData_child.emit({ data: item, event: "eventPage", val: true });
    var itemValuefiltered = "";
    var itemSelectedRemValue = this.options
    var itemTagSelected = this.tagItems.filter(x => x.id == item.key)
    if (itemTagSelected != undefined && itemTagSelected != null && itemTagSelected != "") {
      var itemLists = itemTagSelected[0].tagItems;
      if (itemLists != undefined && itemLists != null && itemLists != "") {
        if (itemLists.length > 1) {
          itemValuefiltered = itemLists.split(',');
        }
        else {
          itemValuefiltered = itemLists;
        }
        for (var i = 0; i < itemValuefiltered.length; i++) {
          itemSelectedRemValue = itemSelectedRemValue.filter(x => x.id != itemValuefiltered[i])
          // itemSelectedRemValue.push(itemSelected)
        }
        this.options = itemSelectedRemValue;
      }
    }
  }

  onSpecificItemSelect(item: any, index) {
    console.log(item.value, "item")
    this.indexdata = index
    if (item.value != null || item.value != '') {
      this.boxBool = true;
    }
  }

  onSelectAll(items: any) {
    // console.log('onSelectAll', items);
  }

  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }

  public onFilterChange(item: any) {
    //console.log(item);
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }

  onItemDeSelect(i, deselectedSID: any): void {
    if (deselectedSID == "Menu Page") {
      this.getFilterGroupAtIndex(i).addControl('multiValuePage', this.getFormControl());
    } else {
      this.getFilterGroupAtIndex(i).addControl('multiValue', this.getFormControl());
    }
  }

  ngAfterViewInit() {
    this.pricebuilderData_child.emit(this.pricingForm.value)
    this.pricingForm.patchValue(this.helper.getValue('GetSessionData3'));
  }

  onchange(data) {
    this.helper.setValue('GetSessionData3', this.pricingForm.value);
    this.helper.setValue('OnDirty', true);
    this.pricebuilderData_child.emit({ data: data, event: "eventPage", val: true });
  }
}
