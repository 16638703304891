<form class="loginForm" [formGroup]="TaxMasterGenForm" (change)="onchange(TaxMasterGenForm.value)">
    <div class="row">
        <div class="col-sm-4">
            <div class="form-group">
                <label>Tax Name</label><label class="text-danger">*</label>
                <input type="text" class="form-control input-lg inputbox_login" formControlName="name"
                    appInputRestriction placeholder="Tax Name" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.name.errors }"  (keypress)="Highlight()" (focusout)="Highlight()" maxlength="50"  (input)="getMaxValue($event)">
                    <div class="error">{{errorValue}}</div>
                    <div *ngIf="submittedGeneral && GeneralData.name.errors" class="invalid-feedback">
                        <div *ngIf="GeneralData.name.errors.required">Tax Name is required</div>
                      </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group">
                <label>Tax Type</label>
                <input type="text" class="form-control input-lg inputbox_login" formControlName="taxType"
                    appInputRestriction placeholder="Tax Type">
            </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group">
                <label>Rate</label>
                <input type="text" class="form-control input-lg inputbox_login" formControlName="rate"
                    appInputRestriction placeholder="Rate" appDecimalLimit>
            </div>
        </div>
          <div class="col-sm-4">
            <div class="form-group">
                <label class="customlable mb-0">Effective Date*</label>
                <mat-form-field appearance="fill" class="custom-date">
                        <input matInput [matDatepicker]="picker" placeholder="MM-DD-YYYY" class="form-control datepicker appInputRestriction"
                        formControlName="Effecteddate" (click)="picker.open()" requiredField="false" (dateChange)="Highlight()" [min]="minDate">
                    <mat-datepicker #picker class="example-header" ></mat-datepicker>                       
                </mat-form-field>
                <!-- <input type="text" placeholder="MM-DD-YYYY" class="form-control" formControlName="Effecteddate"  
                  bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" appInputRestriction> -->
                  <div class="error">{{dateMgs}}</div>
                <div class="error">{{errorMessage}}</div>
              </div>
        </div>
    </div>
    <div class="form-group mt-2">
        <!-- <button class="btn btn-sm float-right submit_button" type="submit" (click)="onSaveGeneral();"
        [disabled]="buttonDisable ? true : null" >
            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label>
        </button> -->
        <button class="btn btn-sm float-right submit_button button-none" [disabled]="buttonDisable ? true : null" 
         type="submit" (click)="onSaveGeneral();">
            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label>
        </button>
    </div>
</form>
