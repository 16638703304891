import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThemeComponent } from 'src/app/theme/theme.component';
import { TimeEditComponent } from './time-edit/time-edit.component';
import { EmployeeUserComponent } from './employee-user/employee-user.component';
import { BreakTypeComponent } from './break-type/break-type.component';

const routes: Routes = [
  {
    path: '', component: ThemeComponent,
    children: [
      { path: 'time', component: TimeEditComponent},
      { path: 'employeeUser', component: EmployeeUserComponent},
      { path: 'break', component: BreakTypeComponent},

    ]
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeRoutingModule { }
