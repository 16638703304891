import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-toaster-popup',
  templateUrl: './toaster-popup.component.html',
  styleUrls: ['./toaster-popup.component.css']
})
export class ToasterPopupComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.onhide()
  }

  onhide(e?: any): void {
    setTimeout(() => {
      this.bsModalRef.hide();
    }, 3000);
  }
}
