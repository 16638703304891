<div class="house_wrap">
    <form class="houseForm loginForm" [formGroup]="HouseAccountForm" (change)="onchange(HouseAccountForm.value)">
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Name</label
          ><label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="name" appInputRestriction placeholder="Name" [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.name.errors) ||
                (requiredError && GeneralData.name.errors)
            }" maxlength="50" (input)="getMaxValueName($event)" (keypress)="Highlight()" (focusout)="HouseAccountsValid()" />
                    <div class="error">{{ errorValue }}</div>
                    <div *ngIf="
              (submittedGeneral && GeneralData.name.errors) ||
              (requiredError && GeneralData.name.errors)
            " class="invalid-feedback">
                        <div *ngIf="GeneralData.name.errors.required">Name is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <div class="form-group">
                    <label class="customlable mb-0">Current Balance</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="currentbalance" placeholder="Current Balance" appDecimalLimit />
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group projected-icon projected-icon-30">
                    <label class="customlable mb-0">Credit Limit</label
          ><label class="text-danger req_label">*</label>
                    <i class="fa fa-usd" aria-hidden="true"></i>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="creditlimits" appDecimalLimit appInputRestriction [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.creditlimits.errors) ||
                (requiredError && GeneralData.creditlimits.errors)
            }" (keypress)="Highlight()" (focusout)="HouseAccountsValid()" />
                    <!-- <input type="text" class="form-control input-lg inputbox_login" formControlName="creditlimits" placeholder="$0.00" appInputRestriction appDecimalLimit
                        [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.creditlimits.errors) || (requiredError && GeneralData.creditlimits.errors)}" (keypress)="Highlight()" (focusout)="HouseAccountsValid()"/> -->
                    <div *ngIf="
              (submittedGeneral && GeneralData.creditlimits.errors) ||
              (requiredError && GeneralData.creditlimits.errors)
            " class="invalid-feedback">
                        <div *ngIf="GeneralData.creditlimits.errors.required">
                            Credit Limit is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <label class="customlable mb-0">House Accounts</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="houseaccounts" placeholder="House Accounts" appInputRestriction maxlength="50" (input)="getMaxValueAccounts($event)" />
                    <!-- <input type="text" class="form-control input-lg inputbox_login" formControlName="houseaccounts"
                        placeholder="House Accounts" appInputRestriction
                        [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.houseaccounts.errors) || (requiredError && GeneralData.houseaccounts.errors)}" maxlength="25" (input)="getMaxValueAccounts($event)" (keypress)="Highlight()" (focusout)="HouseAccountsValid()"/>
                        <div class="error">{{errorValue1}}</div> -->
                    <!-- <div *ngIf="(submittedGeneral && GeneralData.houseaccounts.errors) || (requiredError && GeneralData.houseaccounts.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.houseaccounts.errors.required">House Accounts is required</div>
                    </div> -->
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <label class="customlable mb-0">Last Visit</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="lastVisit" placeholder="Last Visit" readonly />
                    <!-- <input type="text" placeholder="MM-DD-YYYY" class="form-control" formControlName="lastVisit" appInputRestriction
                        bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" placeholder="Last Visit"> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Business Name</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="businessname" placeholder="Businessname" appInputRestriction maxlength="50" (input)="getMaxValueBName($event)" />
                    <div class="error">{{ errorValue2 }}</div>
                    <!-- <div *ngIf="submittedGeneral && GeneralData.businessname.errors" class="invalid-feedback">
                        <div *ngIf="GeneralData.businessname.errors.required">Business Name is required</div>
                    </div> -->
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Phone</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                <img
                  src="assets/components/images/USA.png"
                  alt="employee"
                  class="flag_img"
                />+1
              </span>
                        </div>
                        <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="phone" placeholder="(xxx) xxx-xxxx" maxLength="14" (keypress)="numberOnly2($event)" (keydown)="onDeletePhone($event)" (focusout)="phoneNumberCheck($event)" [ngClass]="{
                'is-invalid':
                  (HouseAccountForm.controls['phone'].errors &&
                    submittedGeneral &&
                    !isValidFormSubmitted) ||
                  mgs != ''
              }" appInputRestriction />
                    </div>
                    <div class="error">{{ mgs }}</div>
                    <div *ngIf="
              HouseAccountForm.controls['phone'].errors &&
              submittedGeneral &&
              !isValidFormSubmitted
            " [ngClass]="'error'">
                        <div *ngIf="
                HouseAccountForm.controls['phone'].errors.pattern && mgs == ''
              ">
                            Mobile Number not valid.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Contact Email</label
          ><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactemail" placeholder="&#x2709; Example@myemailaddress.com" appInputRestriction [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.contactemail.errors) ||
                (requiredError && GeneralData.contactemail.errors)
            }" maxlength="100" (input)="getMaxValueEmail($event)" (keypress)="Highlight()" (focusout)="HouseAccountsValid()" />
                    <div class="error">{{ errorValue3 }}</div>
                    <div *ngIf="
              (submittedGeneral && GeneralData.contactemail.errors) ||
              (requiredError && GeneralData.contactemail.errors)
            " class="invalid-feedback">
                        <div *ngIf="GeneralData.contactemail.errors.required">
                            Contact Email is required
                        </div>
                        <div *ngIf="GeneralData.contactemail.errors.email">
                            Email must be a valid email address
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">First Name</label
          ><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactFname" placeholder="First Name" appInputRestriction [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.contactFname.errors) ||
                (requiredError && GeneralData.contactFname.errors)
            }" maxlength="50" (input)="getMaxValueFName($event)" (keypress)="Highlight()" (focusout)="HouseAccountsValid()" />
                    <div class="error">{{ errorValue4 }}</div>
                    <div *ngIf="
              (submittedGeneral && GeneralData.contactFname.errors) ||
              (requiredError && GeneralData.contactFname.errors)
            " class="invalid-feedback">
                        <div *ngIf="GeneralData.contactFname.errors.required">
                            First Name is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Last Name</label
          ><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactLname" placeholder="Last Name" appInputRestriction [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.contactLname.errors) ||
                (requiredError && GeneralData.contactLname.errors)
            }" maxlength="50" (input)="getMaxValueLName($event)" (keypress)="Highlight()" (focusout)="HouseAccountsValid()" />
                    <div class="error">{{ errorValue5 }}</div>
                    <div *ngIf="
              (submittedGeneral && GeneralData.contactLname.errors) ||
              (requiredError && GeneralData.contactLname.errors)
            " class="invalid-feedback">
                        <div *ngIf="GeneralData.contactLname.errors.required">
                            Last Name is required
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Address</label
          ><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="address" placeholder="Address" appInputRestriction [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.address.errors) ||
                (requiredError && GeneralData.address.errors)
            }" (keypress)="Highlight()" (focusout)="HouseAccountsValid()" />
                    <div *ngIf="
              (submittedGeneral && GeneralData.address.errors) ||
              (requiredError && GeneralData.address.errors)
            " class="invalid-feedback">
                        <div *ngIf="GeneralData.address.errors.required">
                            Address is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8 col">
                <!-- <div class="col-sm-4"> -->
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">City</label
              ><label class="text-danger req_label">*</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="city" placeholder="City" appInputRestriction [ngClass]="{
                  'is-invalid':
                    (submittedGeneral && GeneralData.city.errors) ||
                    (requiredError && GeneralData.city.errors)
                }" maxlength="50" (input)="getMaxValueCity($event)" (keypress)="Highlight()" (focusout)="HouseAccountsValid()" />
                            <div class="error">{{ errorValue6 }}</div>
                            <div *ngIf="
                  (submittedGeneral && GeneralData.city.errors) ||
                  (requiredError && GeneralData.city.errors)
                " class="invalid-feedback">
                                <div *ngIf="GeneralData.city.errors.required">
                                    City is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">State</label
              ><label class="text-danger req_label">*</label>
                            <!-- <input type="text" class="form-control input-lg inputbox_login" formControlName="state" placeholder="MT"
                                    [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.state.errors }" /> -->
                            <div class="state-placeholder" (focusout)="HouseAccountsValid()">
                                <mat-select (openedChange)="matstate($event)" class="form-control" [disableOptionCentering]="true" #select1 formControlName="state" placeholder="Select State" (selectionChange)="Highlight()" [ngClass]="{
                    'is-invalid':
                      (submittedGeneral && GeneralData.state.errors) ||
                      (requiredError && GeneralData.state.errors)
                  }">
                                    <input class="form-control input-lg inputbox_login" placeholder="Search here" (keyup)="onKey($event.target.value)" maxlength="50" (input)="getMaxValueState($event)" />
                                    <!-- <div class="error">{{errorValue7}}</div> -->
                                    <mat-option *ngFor="let item of filteredList" [value]="item.id">
                                        {{ item.id }}
                                    </mat-option>
                                    <!-- <mat-option *ngIf="filterNodata" [value]="">No data Found</mat-option> -->
                                </mat-select>
                                <div *ngIf="
                    (submittedGeneral && GeneralData.state.errors) ||
                    (requiredError && GeneralData.state.errors)
                  " class="invalid-feedback">
                                    <div *ngIf="GeneralData.state.errors.required">
                                        State is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">Zip</label
              ><label class="text-danger req_label">*</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="zip" placeholder="Zip" [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" [ngClass]="{
                  'is-invalid':
                    (submittedGeneral && GeneralData.zip.errors) ||
                    (requiredError && GeneralData.zip.errors)
                }" (keypress)="Highlight()" (focusout)="HouseAccountsValid()" (focusout)="zipCodeCheck($event)" (keydown)="onDeleteZipCode($event)" [ngClass]="{ 'is-invalid': zipCodeMsg != '' }" />
                            <div class="error">{{ zipCodeMsg }}</div>
                            <div *ngIf="
                  (submittedGeneral && GeneralData.zip.errors) ||
                  (requiredError && GeneralData.zip.errors)
                " class="invalid-feedback">
                                <div *ngIf="GeneralData.zip.errors.required">
                                    Zip is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">D.O.B</label>
                            <mat-form-field appearance="fill" class="custom-date">
                                <input matInput [matDatepicker]="picker" placeholder="D.O.B" class="form-control datepicker appInputRestriction" formControlName="dob" (click)="picker.open()" [max]="maxDate" (dateChange)="Highlight()" [min]="minDate" />
                                <mat-datepicker #picker class="example-header"></mat-datepicker>
                            </mat-form-field>
                            <div class="error">{{ dateMgs }}</div>
                            <!-- <input type="text" class="form-control input-lg inputbox_login" formControlName="dob"
                                placeholder="D.O.B" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false}" appInputRestriction/> -->
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">Anniversary</label>
                            <mat-form-field appearance="fill" class="custom-date">
                                <input matInput [matDatepicker]="pickerA" placeholder="Anniversary" class="form-control datepicker appInputRestriction" formControlName="anniversary" (click)="pickerA.open()" (dateChange)="Highlight()" [min]="minDate" />
                                <mat-datepicker #pickerA class="example-header"></mat-datepicker>
                            </mat-form-field>
                            <div class="error">{{ dateMgsAnniversary }}</div>
                            <!-- <input type="text" class="form-control input-lg inputbox_login" placeholder="Anniversary"
                                    formControlName="anniversary" bsDatepicker appInputRestriction
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false}" /> -->
                        </div>
                    </div>
                    <div class="col-sm-10">
                        <div class="form-group">
                            <label class="mb-0">Notes</label>
                            <textarea type="text" class="form-control input-lg inputbox_login" formControlName="notes" appInputRestriction></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 col">
                <div class="custom_Uber_eats Communication_tab">
                    <div class="text-center com_label">Communication</div>
                    <!-- <div class="form-group Communication_Form" *ngFor="let item of filteredList2">
                        <div class="custom-control custom-radio custom-control item">
                            <input formControlName="communication" value="{{item.typeId}}" type="radio"
                                id="{{item.typeId}}" name="communication" class="custom-control-input">
                            <label class="custom-control-label QR uber_label"
                                for="{{item.typeId}}">{{item.typeName}}</label>
                        </div>
                    </div> -->
                    <div class="checkbox-table accordion-checkbox" *ngFor="let item of filteredList2">
                        <div class="custom-control custom-checkbox custom-control item">
                            <input type="checkbox" class="custom-control-input" name="{{ item.typeId }}" [checked]="item.isSelected == true ? true : false" id="{{ item.typeId }}" (change)="onCheckboxChange(item, $event)" />
                            <label class="custom-control-label mb-0" for="{{ item.typeId }}">{{ item.typeName }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 mt-2">
        <button
          class="btn btn-sm float-right submit_button button-none"
          type="submit"
          (click)="onSaveGeneral()"
          [disabled]="buttonDisable ? true : null"
        >
          <!-- [disabled]="buttonState ? true : null" -->
          <img
            src="assets/components/images/save.png"
            alt="employee"
            class=""
          />
          <label class="label_config">Save</label>
                            </button>
                        </div>
                    </div>
    </form>
    </div>
    <!-- ngx-intl -->
    <!-- <ngx-intl-tel-input [cssClass]="'form-control country-tel-code'" [enableAutoCountrySelect]="true"
    [enablePlaceholder]="true" [preferredCountries]="preferredCountries" [searchCountryFlag]="true"
    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
    [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10" [tooltipField]="TooltipLabel.Name"
    [phoneValidation]="false" [separateDialCode]="separateDialCode" formControlName="phone">
</ngx-intl-tel-input> -->
    <!-- ngx-intl -->
