<div class="row">
    <div class="col-sm-3">
        <div class="item_assigned">
            <!-- <app-fliptable [dataSource]="dataSourceTablemodifier" [displayedColumns]="displayedColumns"
              [columns]="columns" ></app-fliptable> -->
            <app-fliptable-layout [dataSource]="dataSourceTablemodifier" [displayedColumns]="displayedColumns"
                [columns]="columns" [salesAreaflip]="salesAreaflip" [DatatableRender]="table_render"
                [DataModifier]="menuData">
            </app-fliptable-layout>
        </div>
    </div>
    <div class="col-sm-9 pr-0">
        <div class="itemAssignedmodify modifierDD  menu_modifier_sec">
            <div cdkDropList cdkDropListGroup id="todo" #todo="cdkDropList" [cdkDropListConnectedTo]="['modifier']"
                [cdkDropListData]="targetBuilderTools" (cdkDropListDropped)="drop($event)"
                cdkDragBoundary=".tablet" class="example-list">
                <!-- <div *ngFor="let targetBuilderTools"> -->
                    <div *ngFor="let dropItem of targetBuilderTools; let i=index" cdkDragDropZone>

                        <div class="icon_wrap text-right">
                            <i class="fa fa-close deleteicon" (click)="removeJson(targetBuilderTools,dropItem,i)"></i>
                            <!-- <span class="pull-left">{{dropItem.name}}</span> -->
                        </div>

                        <div *ngIf="table_render" class="item_assigned_box mb-2" >
                            <div class="menu_wrapper">
                              
                                <mat-accordion >
                                    <mat-expansion-panel [expanded]="isFirst"
                                        class="outshadow" >
                                        <mat-expansion-panel-header cdkDrag >
                                            <mat-panel-title> {{dropItem.name}} </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <div id="content" class="builder-target" >
         
                                                <section ngxDroppable dropZone="builder-target" class="items_layout_tablet menu-readonly"
                                                    [model]="dropItem.Item_Object" [removeOnSpill]="false">
                                                        <div *ngFor="let item of dropItem.Item_Object;" ngxDraggable [model]="item">
                                                     
                                                            <div class="row no-gutters">
                                                                <div class="col-sm-2 column6width">
                                                                    <section ngxDroppable dropZone="builder-target" class="drop-container drop_wrapper"
                                                                        [model]="item.children" [removeOnSpill]="false" (drop)="dropevent(item.children)">
                                                                        <div *ngFor="let items of item.children" ngxDraggable [model]="items">
                                                                            <div class="dropzone-placeholder">{{items.name}}
                                                                                <!-- <i (click)="removeJson(item.children,items)"
                                                                                    class="fa fa-close deleteItem"></i> -->
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                    
                                    
                                                                <div class="col-sm-2 column6width">
                                                                    <section ngxDroppable dropZone="builder-target" class="drop-container drop_wrapper"
                                                                        [model]="item.children1" [removeOnSpill]="false" (drop)="dropevent(item.children1,item)">
                                                                        <div *ngFor="let items of item.children1" ngxDraggable [model]="items">
                                                                            <div class="dropzone-placeholder">{{items.name}}
                                                                                <!-- <i (click)="removeJson(item.children1,items)"
                                                                                    class="fa fa-close deleteItem"></i> -->
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                    
                                                                <div class="col-sm-2 column6width">
                                                                    <section ngxDroppable dropZone="builder-target" class="drop-container drop_wrapper"
                                                                        [model]="item.children2" [removeOnSpill]="false" (drop)="dropevent(item.children2,item)">
                                                                        <div *ngFor="let items of item.children2" ngxDraggable [model]="items">
                                                                            <div class="dropzone-placeholder">{{items.name}}
                                                                                <!-- <i (click)="removeJson(item.children2,items)"
                                                                                    class="fa fa-close deleteItem"></i> -->
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                    
                                                                <div class="col-sm-2 column6width">
                                                                    <section ngxDroppable dropZone="builder-target" class="drop-container drop_wrapper"
                                                                        [model]="item.children3" [removeOnSpill]="false" (drop)="dropevent(item.children3,item)">
                                                                        <div *ngFor="let items of item.children3" ngxDraggable [model]="items">
                                                                            <div class="dropzone-placeholder">{{items.name}}
                                                                                <!-- <i (click)="removeJson(item.children3,items)"
                                                                                    class="fa fa-close deleteItem"></i> -->
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                    
                                                                <div class="col-sm-2 column6width">
                                                                    <section ngxDroppable dropZone="builder-target" class="drop-container drop_wrapper"
                                                                        [model]="item.children4" [removeOnSpill]="false" (drop)="dropevent(item.children4,item)">
                                                                        <div *ngFor="let items of item.children4" ngxDraggable [model]="items">
                                                                            <div class="dropzone-placeholder">{{items.name}}
                                                                                <!-- <i (click)="removeJson(item.children4,items)"
                                                                                    class="fa fa-close deleteItem"></i> -->
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                    
                                    
                                                                <div class="col-sm-1 col" style="margin: auto;">
                                    
                                                                    <i (click)="removeJson1(targetBuilderTools,i)" class="fa fa-close deleteicon"></i>
                                                                </div>
                                    
                                                            </div>
                                                            <!-- </div> -->
                                                        </div>
                                                    <!-- </div> -->
                                                </section>
                                            </div>
                                            <!-- <app-drag-drop-accordion-builder [datamatrixItem]="matrix_render"
                                                [dropdata]="menuGroupListData"></app-drag-drop-accordion-builder> -->
                                            <!-- <app-menu-item-modifier [menuItemIn]="menuItemData" [menuItemMatrix]="ItemMatrix"></app-menu-item-modifier> -->
                                       
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
        </div>
        <div class="form-group mt-2">
            <button class="btn  btn-sm float-right submit_button loginForm button-none" type="submit" 
            [disabled]="buttonDisable ? true : null" >
                    <!-- (click)="OnSaveItemModifier()" [disabled]="buttonState ? true : null" -->
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label> 
            </button>
        </div>
        <!-- <pre style="color: yellow;">{{targetBuilderTools | json}}</pre> -->
    </div>
</div>