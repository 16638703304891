import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportRoutingModule } from './report-routing.module';
import { ReportComponent } from './report/report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { EmployeeModule } from '../employee/employee.module';
import { MatSelectModule } from '@angular/material/select';
import { FliptableFooterComponent } from 'src/app/elements/fliptable-footer/fliptable-footer.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SalesReconciliationComponent } from './sales-reconciliation/sales-reconciliation.component';
import { CreditBatchComponent } from './credit-batch/credit-batch.component';
import { FliptableCenterFooterComponent } from 'src/app/elements/fliptable-center-footer/fliptable-center-footer.component';
import { ReportFilterComponent } from 'src/app/elements/report-filter/report-filter.component';
import { NoSalesComponent } from './no-sales/no-sales.component';
import { PayrollReportComponent } from './payroll-report/payroll-report.component';
import { SalesDaypartComponent } from './sales-daypart/sales-daypart.component';
import { TaxReportComponent } from './tax-report/tax-report.component';
import { TableCollapseComponent } from 'src/app/elements/table-collapse/table-collapse.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SalesByHourComponent } from './sales-by-hour/sales-by-hour.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ReportAuditComponent } from 'src/app/elements/report-audit/report-audit.component';
import { DiscountsComponent } from './discounts/discounts.component';
import { FlipTableGroupbyComponent } from 'src/app/elements/flip-table-groupby/flip-table-groupby.component';
import {MatTreeModule} from '@angular/material/tree';
import { DiscountsAuditComponent } from 'src/app/elements/discounts-audit/discounts-audit.component';
import { FlipTableApproverComponent } from 'src/app/elements/flip-table-approver/flip-table-approver.component';
import { DiscountFliptableFooterComponent } from 'src/app/elements/discount-fliptable-footer/discount-fliptable-footer.component';
import { DiscountTableCollapseComponent } from 'src/app/elements/discount-table-collapse/discount-table-collapse.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { TaxHistoryReportComponent } from './tax-history-report/tax-history-report.component';
import { TaxHistoryFilterComponent } from 'src/app/elements/tax-history-filter/tax-history-filter.component';
import { DiscountsFliptableCenterFooterComponent } from 'src/app/elements/discounts-fliptable-center-footer/discounts-fliptable-center-footer.component';
import { LaborSummaryComponent } from './labor-summary/labor-summary.component';
import { ReportLaborFilterComponent } from 'src/app/elements/report-labor-filter/report-labor-filter.component';
import { FliptableLaborCascadeComponent } from 'src/app/elements/fliptable-labor-cascade/fliptable-labor-cascade.component';
import { GiftcardLiabilityReportComponent } from './giftcard-liability-report/giftcard-liability-report.component';
import { GiftcardFilterComponent } from 'src/app/elements/giftcard-filter/giftcard-filter.component';
import { GiftCardCenterFooterComponent } from 'src/app/elements/gift-card-center-footer/gift-card-center-footer.component';
import { GiftcardAuditReportComponent } from 'src/app/elements/report-audit/giftcard-audit-report/giftcard-audit-report.component';
import { VoidsReportComponent } from './no-sales/voids-report/voids-report.component';
import { AuditVoidCollapseComponent } from 'src/app/elements/audit-void-collapse/audit-void-collapse.component';
import { VoidAuditReportComponent } from 'src/app/elements/void-audit-report/void-audit-report.component';
import { PreVoidsReportComponent } from './pre-voids-report/pre-voids-report.component';
import { RefundReportComponent } from './refund-report/refund-report.component';
import { AuditRefundCollapseComponent } from 'src/app/elements/audit-refund-collapse/audit-refund-collapse.component';

@NgModule({
  declarations: [
    ReportComponent,
    FliptableFooterComponent,
    SalesReconciliationComponent,
    CreditBatchComponent,
    FliptableCenterFooterComponent,
    ReportFilterComponent, 
    TaxHistoryFilterComponent,
    NoSalesComponent,
    PayrollReportComponent,
    SalesDaypartComponent,
    TaxReportComponent,
    TableCollapseComponent,
    SalesByHourComponent,
    ReportAuditComponent,
    DiscountsComponent,
    FlipTableGroupbyComponent,
    DiscountsAuditComponent,
    FlipTableApproverComponent,
    DiscountFliptableFooterComponent,
    DiscountTableCollapseComponent,
    TaxHistoryReportComponent,
    DiscountsFliptableCenterFooterComponent,
    LaborSummaryComponent,
    ReportLaborFilterComponent,
    FliptableLaborCascadeComponent,
    GiftcardLiabilityReportComponent,
    GiftcardFilterComponent,
    GiftCardCenterFooterComponent,
    GiftcardAuditReportComponent,
    VoidsReportComponent,
    AuditVoidCollapseComponent,
    VoidAuditReportComponent    ,
    PreVoidsReportComponent,
    RefundReportComponent,
    AuditRefundCollapseComponent
  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    MatExpansionModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatTabsModule,
    EmployeeModule,
    MatTreeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    MatInputModule,
    MatFormFieldModule
  ],
  exports:[ ReportComponent, FliptableFooterComponent, SalesReconciliationComponent, CreditBatchComponent, GiftcardLiabilityReportComponent, LaborSummaryComponent,
    FliptableCenterFooterComponent, ReportFilterComponent, ReportLaborFilterComponent, GiftcardFilterComponent, RefundReportComponent,  VoidsReportComponent, PreVoidsReportComponent, NoSalesComponent, PayrollReportComponent, SalesDaypartComponent, 
    TaxHistoryReportComponent, TaxReportComponent, SalesByHourComponent, ReportAuditComponent, DiscountsComponent, 
    DiscountsAuditComponent, TaxHistoryFilterComponent,DiscountsFliptableCenterFooterComponent]
})
export class ReportModule { }
