import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, Output,EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { EmployeeService } from 'src/app/services/employee.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetAllUserList } from 'src/app/interfaces/iemployee';
import { HelperService } from 'src/app/helper/helper.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/services/admin.service';
import { IActiveUser, IListInfo, IHierarchySubject } from 'src/app/interfaces/iadmin';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-hierachy-company',
  templateUrl: './hierachy-company.component.html',
  styleUrls: ['./hierachy-company.component.css']
})
export class HierachyCompanyComponent implements OnInit {
  GeneralCompanyForm: FormGroup;
  generalValue: any;
  submittedGeneral = false;
  allcompanydetails = [];
  rowClicked;
  userId: any;
  tenantId: any;
  companyList = [];
  loginInfo: any;
  ParentId: any;
  checked = [false];
  ActivePermission:any;
  isActive: [true]
  Position: any;
  BusinessID: any;
  DealerID: any;
  CompanyID: any;
  hierarchyTransfer: any;
  dealerParentData: any;
  isvParentData: any;
  public displayedColumns = [];
  public EmployeeTableData: any;
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  tableEnable: boolean = true;
  tenantTypeID: any;
  public companyRow: any;
  @Input() public CompIdItem;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public compData = new EventEmitter();
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }


  constructor(private companyDetails: AdminService, private empDetails: EmployeeService, private formBuilder: FormBuilder,
    private toaster: ToasterService, private helper: HelperService,private changeDetector: ChangeDetectorRef
  ) { }

  // @ViewChild(DataTableDirective)
  // dtElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject();

  ngOnInit(): void {

    this.employeeName = "Company Name";
    this.employee = "Company";
    this.employeeIcon = "assets/components/images/site2x.png"
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.ActivePermission=this.loginInfo.primaryRolePermissionListInfo.filter(x=>x.id==8);
    this.GeneralCompanyForm = this.formBuilder.group({
      tenantName: ['', Validators.required],
      contactPhone: new FormControl(undefined),
      // contactPhone: [''],
      contactFirstName: [''],
      contactLastName: [''],
      contactEmail: [''],
      address: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      bussinessId: [0],
      isCorporate: [''],
      isActive: [true],
      isDemo: [true],
      isDeleted: [false],
    });


    // if(this.loginInfo.tenantType==4)
    // {
    //   if (this.ActivePermission.length!=0 )
    //   {
    //       if (this.ActivePermission[0].id==8 ) // Active /Inactive Permission
    //         {

    //           this.GeneralCompanyForm.patchValue(isActive == true)
    //         }
    //   }
    //   else
    //   {
    //       this.isDisabled = true
    //   }
    // }
    this.getAllCompanyData();

    this.tenantTypeID = this.loginInfo.tenantTypeId;
    this.companyDetails.hierarchycompanyTransferValue.subscribe(data => {
      this.companyList = data;
      this.dataSource.data = data;
      // this.dataSource.data = data;
      this.EmployeeTableData = data
      this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
      this.displayedColumns = ['name', 'isActive'];
      this.columns = [
        { columnDef: 'name', header: 'Name' },
        { columnDef: 'isActive', header: 'Status' }
      ];
    });
    this.companyDetails.hieRowCompany.subscribe(data => {
    this.companyRow = data
      if (this.companyRow.IdComapny != undefined && this.companyRow.IdComapny != null && this.companyRow.IdComapny != "") {
        this.getCompany(this.companyRow.IdComapny);

        this.CompIdItem = this.companyRow

      }
    }
    );

    this.companyDetails.hieCompanyParentData.subscribe(data => this.dealerParentData = data);
    this.companyDetails.hieRowData.subscribe(data => this.companyRow = data);
    this.companyDetails.hieCompanyParentData.subscribe(data => this.dealerParentData = data);
    this.helper.setValue("Companyflag", 2);
    this.helper.setValue("Dealerflag", 0);
    this.helper.setValue("Storeflag", 0);
    if (this.tenantTypeID == 2 && this.dealerParentData.id == "") {
      this.getDetailedData(0);
    } else {
      let DealerID
      DealerID = this.dealerParentData.id != undefined && this.dealerParentData.id != null && this.dealerParentData.id != "" ? this.dealerParentData.id : 0;
      if (DealerID == 0) {
        this.toaster.error("Please Select Dealer");
      } else {
        this.onChangeBusiness(DealerID);
      }
    }
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)



  }

  // rerender(): void {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }



  get GenCompanyData() { return this.GeneralCompanyForm.controls; }
  addCompany() {
    this.GeneralCompanyForm.reset();
    this.employeeName = "Company Name";
    this.companyRow = 'transparent'
    this.tenantId = 0;
    this.titleName.emit(this.employeeName)

  }
  onSaveGeneral() {
    this.GeneralCompanyForm.controls.tenantName.setValidators(Validators.required);
    this.GeneralCompanyForm.controls.tenantName.updateValueAndValidity();
    this.submittedGeneral = true;
    this.DealerID = this.dealerParentData.id != undefined && this.dealerParentData.id != null && this.dealerParentData.id != "" ? this.dealerParentData.id : 0;

    if (this.GeneralCompanyForm.invalid) {
      return;
    }
    const company = this.GeneralCompanyForm.value;
    company.sectionId = 1;
    company.tenantId = this.tenantId > 0 ? this.tenantId : 0;
    company.tenantTypeId = 3;
    company.parentId = this.DealerID;
    const obj = company.contactPhone
    const result = obj.internationalNumber;
    company.contactPhone = result;

    this.companyDetails.UserHierachy(company).subscribe(
      (response) => {
        this.generalValue = response;
        this.getDetailedData(this.DealerID);
        this.toaster.success("Company Data Added Successfully");
        this.GeneralCompanyForm.controls.tenantName.setErrors(null);
        this.GeneralCompanyForm.controls.tenantName.clearValidators();
        this.submittedGeneral = false;
      },
      error => {
      })

  }
  checkValue(event) {
    let currentTarget = event.e.currentTarget.checked
    const active: IActiveUser = {
      userId: this.loginInfo.userId,
      tenantId: event.id,
      isActive: event.e.currentTarget.checked
    }
    this.companyDetails.IsActiveUser(active).subscribe(
      (response) => {
        this.getCompany(event.id)
        // this.getDetailedData(0)
        if (currentTarget) {
          this.toaster.success("Company is Active");
        } else {
          this.toaster.success("Company is Deactive");
        }
      },
      error => {
      })
  }
  changeTableRowColor(event) {
    this.companyRow = ""
    this.companyRow = this.companyRow === event.idx ? -1 : event.idx;
    this.getCompany(event.id)
  }
  getTableName(name) {
    this.employeeName = name;
    this.employee = "Company";
    this.employeeIcon = "assets/components/images/site2x.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  getCompany(empid) {
    this.userId = empid;
    this.tenantId = empid;
    const employee: GetAllUserList = {
      tenantId: empid
    }
    this.companyDetails.GetAllHierachy(employee).subscribe((response) => {
      this.GeneralCompanyForm.patchValue(response);
    })
    this.onChangeBusiness(empid);
  }

  getAllCompanyData() {
    const company: GetAllUserList = {
      tenantId: 0
    }
    this.allcompanydetails = [];
    this.empDetails.GetAllEmployees(company).subscribe((response) => {
      this.allcompanydetails = response;
      // this.dtTrigger.next();
    },
      error => {
        // this.dtTrigger.next();
      });
  }
  onChangeBusiness(empid) {
    const isTenant: IListInfo = {
      tenantId: empid,
      loginUserId: this.loginInfo.userId
    }
    this.companyDetails.GetTenantBusiness(isTenant).subscribe((response) => {
      this.Position = response;
    })
  }
  getDetailedData(ParentID) {
    const login: IListInfo = {
      loginUserId: this.loginInfo.userId,
      tenantId: ParentID
    }
    this.companyDetails.TenantHierarchyData(login).subscribe(
      (response) => {
        if (response.typeId == 2 && ParentID == 0) {
          const parentValue: IHierarchySubject = {
            id: response.id,
            name: response.name,
            typeId: response.typeId,
            typeName: response.typeName,
            isActive: response.isActive,
            parentTenantId: response.parentTenantId
          }
          this.companyDetails.setCompanyParentVal(parentValue);
        }
        this.companyDetails.checkCompanyHierarchydata(response.childHierarchyList);
        this.dataSource.data = response.childHierarchyList;
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
        // this.tableEnable = false;
        // this.changeDetector.detectChanges();
        // this.tableEnable = true;
      })
    this.addCompany();
  }
  gethierarchyData(e){
    this.compData.emit(e)
   }
}

