import { CdkDragEnd, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild ,Output, EventEmitter} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-vertical-drag-drop',
  templateUrl: './vertical-drag-drop.component.html',
  styleUrls: ['./vertical-drag-drop.component.css']
})

export class VerticalDragDropComponent implements OnInit {
  public JobList: any = [];
  public PrinterListData: any = [];
  public FilterList: any = [];
  public FinalipadList = [];
  @Input() label_name;
  @Input() dataSource;
  @Input() displayedColumns
  @Input() columns;
  @Input() SalesID: any;
  public table_render: boolean = true;
  dropping: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>;
  @Output() public generalItem = new EventEmitter();

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    //this.itemAssignedList = null;
    //this.itemAssignedList = this.SalesID;
  }

  drop(event) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.table_render = false
      this.changeDetector.detectChanges();
      this.table_render = true
    }
    this.FinalipadList = event.container.data;
    this.generalItem.emit(this.FinalipadList);
    this.table.renderRows();
  }

  public onDragEnd(event: CdkDragEnd<string[]>) {
    this.dropping = true;
    this.changeDetector.detectChanges();
  }
}
