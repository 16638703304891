<div class="loginForm">
    <div class="row web_head">
        <div class="col">Employee name</div>
        <div class="col">Position</div>
        <div class="col">In Date</div>
        <div class="col">Out Date</div>
        <div class="col">Time In</div>
        <div class="col">Time Out</div>
        <div class="col">Type</div>
        <div class="col">Wages</div>
        <div class="col">Tip</div>
        <div class="col">clockInImage</div>
    </div>
    <div *ngIf="ObjService.PunchList">   
    <div  *ngFor="let m of ObjService.PunchList">
           
    <div class="row web_body">
            <div class="col">
                {{m.employeeName}}
            </div>
            <div class="col" >
                {{m.roleName}}
            </div>
            <div class="col" >
                {{m.punchIn}}
            </div>
            <div class="col" >
                {{m.punchOut}}
            </div>
            <div class="col" >
                {{m.timeInTime}}
            </div>
            <div class="col" >
                {{m.timeOutTime}}
            </div>
            <div class="col">
                {{m.typeName}}
            </div>
            <div class="col" >
                {{m.wage}}
            </div>
            <div class="col" >
                {{m.tips}}
            </div>
            <div class="col">
              
                <!-- <img [src]="'data:image/jpg;base64,'+m.clockInImage" width="42%"/>  -->
                <img [src]="m.clockInImage" class="web_img"/>
            </div>
    </div>
       
    </div>
    </div>
</div>
