<div class="time-body">
    <div class="loginForm form-switch table_forms">
        <app-report-filter (ReceivedData)="GetCreditCardDetails($event)"></app-report-filter>
        <div class="footer-table Communication_tab mb-2">
            <section class="d-flex justify-content-between outshadow p-1">
                <div class="back-img">
                    <!-- <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(2)"
                        class="img-fluid align-self-center"> -->
                        <!-- <p><span>{{firstTableName}}</span></p> -->
                </div>

                <h6 class="align-self-center mb-0">
                    <span>SUMMARY</span>
                    <!-- <span *ngIf="secondTableShow == true">DETAILS</span> -->
                </h6>
                <div class="d-flex no-gutter mb-0">
                    <div class="salesreconciliation"><img src="assets/components/images/gen-icon-export.png" alt="sales"
                            class="img-fluid cash_icon"></div>
                    <div class="salesreconciliation"><img src="assets/components/images/gen-icon-dashboard.png"
                            alt="sales" class="img-fluid cash_icon_w90"></div>
                </div>
            </section>
            <!-- <div *ngIf="firstTableShow == true "> -->
                <app-fliptable-center-footer [dataSource]="dataSource1" [displayedColumns]="displayedColumns1"
                    [columns]="columns1" [firstTableShow]="firstTableShow">
                </app-fliptable-center-footer>
            <!-- </div> -->
            
        </div>
    </div>
</div>