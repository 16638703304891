import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { MenuGroupService } from 'src/app/services/menugroup.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetMenuGroup, EditMenuGroup } from 'src/app/interfaces/imenu';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { DialogService } from 'src/app/services/dialog.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-menu-group-general',
  templateUrl: './menu-group-general.component.html',
  styleUrls: ['./menu-group-general.component.css']
})
export class MenuGroupGeneralComponent implements OnInit {
  MenuGroupGeneralForm: FormGroup
  buttonDisable: boolean = false;
  submittedGeneral: any;
  loginInfo: any;
  public isActiveInactivePerm: boolean;

  @Output() public generalItem = new EventEmitter();
  errorValue: any;
  requiredError: boolean = false;
  AreaBuildmnugrpId: any = 0;

  @Output() public isInvalid = new EventEmitter();
  @Output() public mnugrpData_subchild = new EventEmitter();
  //@Input() public buttonDis;

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private objgroup: MenuGroupService,
    private toaster: ToasterService, private dialogser: DialogService, private permser: PermissionService) {
    this.MenuGroupGeneralForm = this.formBuilder.group({})
  }

  ngOnInit(): void {
    this.isActiveInactivePerm = this.permser.isActiveInactive;

    this.helper.setValue('GetSessionData2', null);
    this.AreaBuildmnugrpId = 0;
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.MenuGroupGeneralForm = this.formBuilder.group({
      id: 0,
      name: ['', Validators.required],
      isActive: [true]
    })
  }

  get GeneralData() { return this.MenuGroupGeneralForm.controls; }

  @Input()
  public set GroupGeneral(tableRowData) {
    this.errorValue = ""
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      if (tableRowData.id != undefined && tableRowData.id != null && tableRowData.id != "") {
        this.AreaBuildmnugrpId = tableRowData.id;
        this.MenuGroupGeneralForm.patchValue(tableRowData)
      }
    }
  }

  @Input()
  public set buttonDis(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = btnst;
    }
  }

  OnSaveMenuGroup() {
    this.errorValue = ""
    this.submittedGeneral = true;
    if (this.MenuGroupGeneralForm.invalid) {
      return;
    }

    let menuGroup;
    menuGroup = this.MenuGroupGeneralForm.value;
    menuGroup.userID = this.loginInfo.userId;
    menuGroup.tenantId = this.loginInfo.tenantId;
    menuGroup.id = this.AreaBuildmnugrpId;
    menuGroup.isActive = true;
    this.objgroup.MenuGroupSave(menuGroup).subscribe(async res => {
      if (res.id == 0) {
        this.toaster.errorPopUp(res.message);
        return;
      } else {
        this.AreaBuildmnugrpId = res.id;
        this.toaster.successPopUp(res.message);
        await this.dialogser.ClearSession();
        this.helper.setValue('GetSessionData2', menuGroup);
        this.mnugrpData_subchild.emit({ data: menuGroup, event: "SalesAreaPage", val: false });
      }
      //this.MenuGroupGeneralForm.reset();
      this.submittedGeneral = false;
      this.generalItem.emit({ res: res, name: menuGroup.name });
      //this.MenuGroupGeneralForm.patchValue({ isActive: true });  //This is commented because After Uncheck and save its getting check
    });
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  MenuGroupValid() {
    if (this.MenuGroupGeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }
    else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.MenuGroupGeneralForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.MenuGroupGeneralForm.patchValue(this.helper.getValue('GetSessionData2'));
    this.mnugrpData_subchild.emit(this.MenuGroupGeneralForm.value)
  }

  onchange(data) {
    this.helper.setValue('GetSessionData2', data);
    this.helper.setValue('OnDirty2', true);
    this.mnugrpData_subchild.emit({ data: data, event: "SalesAreaPage", val: true });
  }
}