import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { DeleteAdjust, SelectAdjustGeneral } from 'src/app/interfaces/iadjustment';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { adjustmentService } from 'src/app/services/adjustment.service';
import { MatTableDataSource } from '@angular/material/table';
import { MenuService } from 'src/app/services/menu.service';
import { AdjustmentDetailsComponent } from '../adjustment-details/adjustment-details.component';
import { AdjustmentGeneralComponent } from '../adjustment-general/adjustment-general.component';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-adjustment',
  templateUrl: './adjustment.component.html',
  styleUrls: ['./adjustment.component.css']
})
export class AdjustmentComponent implements OnInit {
  private buttonDisable: boolean;
  public activeDeactiveValue: any;
  public displayedColumns = [];
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  public AdjustmentTableData: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public tableRowId: any;
  public tableRowColor: any;
  loginInfo: any;
  isInvalid: boolean = false;
  public modifierItemsData: any;
  indexExpanded: number = 0;

  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public generalValueemit = new EventEmitter
  @Output() public adjsessData = new EventEmitter
  //@Input() public buttonDisable;

  @ViewChild(AdjustmentDetailsComponent) adjdetComp: AdjustmentDetailsComponent;
  @ViewChild(AdjustmentGeneralComponent) adjgenComp: AdjustmentGeneralComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;

  submittedGeneral: any;
  constructor(private helper: HelperService, private toaster: ToasterService, private dialogser: DialogService,
    private adjust: adjustmentService, private objMenu: MenuService) { }

  buttonStatus: any;
  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.adjustmentPage");

    this.buttonStatus = false;
    this.buttonDisable = false;
    this.employeeName = "Adjustments";
    this.employee = "Adjustments";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.GetAdjustmentList();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  GetAdjustmentList() {
    const adjustList: SelectAdjustGeneral = {
      id: 0,
      tenantid: this.loginInfo.tenantId,
    }
    this.adjust.SelectAdjustmentGeneral(adjustList) // select method
      .subscribe(res => {
        this.dataSource.data = res;
        this.AdjustmentTableData = res
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Adjustment Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  async getTableIdx(event) {
    //this.gentab.isFormPageDirty();
    if (this.helper.getValue('OnDirty') == true || this.helper.getValue('OnDirty2') == true) {
      var conf = await this.dialogser.confirm("adjustmentPage");
      if (conf == 'success') { // Navigate Away
        this.dialogser.ClearSession();
        this.adjgenComp.adjustmentGeneralForm.reset();
        this.adjdetComp.adjustmentDetailForm.reset();

        //General Data
        this.dialogser.ModifiedId = event;
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.isInvalid = false;
        this.adjgenComp.requiredError = false;
        this.adjgenComp.errorValue = "";
        this.flipcomp.rowClicked = event;
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
      }
      else // Cancel
      {
        //General Data
        this.adjgenComp.adjustmentGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.adjdetComp.adjustmentDetailForm.patchValue(this.helper.getValue('GetSessionData2'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.adjsessData.emit({
          data: this.adjgenComp.adjustmentGeneralForm.value, event: "adjustmentPage", val: true,
          itmname: this.adjgenComp.adjustmentGeneralForm.value.name
        });
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event)
      this.tableRowId = event;
      this.tableRowColor = event;
      this.isInvalid = false;
      this.adjgenComp.requiredError = false;
      this.adjgenComp.errorValue = "";
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
    }
  }

  getTableName(name) {
    this.employeeName = name;
    this.employee = "Adjustments";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  checkValue(event) {
    this.activeDeactiveValue = event
    let currentTarget = event.e.currentTarget.checked
    const active: DeleteAdjust = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked
    }
    this.adjust.DeleteAdjustmentGeneral(active).subscribe(
      (response) => {
        if (currentTarget) {
          this.toaster.successPopUp("Adjustment is Active");
        } else {
          this.toaster.successPopUp("Adjustment is Deactive");
        }
        this.GetAdjustmentList();
      },
    )
  }

  getGeneralDetail(event) {
    this.tableRowId = event.res.id;
    this.generalValueemit.emit(event.res);
    this.helper.setValue('AdjustId', this.tableRowId);
    // this.adjdetComp.onSaveDetails();
    // this.addEmployee(0);
    this.GetAdjustmentList();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name)
  }

  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.addEmployee(clearForm);
    }
  }
  @Input()
  public set buttonState(btnst) {
    this.buttonStatus = !btnst;
  }

  expandPanel(index, e?) {
    this.indexExpanded = index;
  }
  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }
  }
  onChangeAccordion(e, index) {
    this.indexExpanded = index;
  }

  async addEmployee(event) {
    if (this.helper.getValue('OnDirty') == false && this.helper.getValue('OnDirty2') == false) {
      this.cleargendata();
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
    }
    else {
      var conf = await this.dialogser.confirm("adjustmentPage");
      if (conf == 'success') { // Navigate Away
        this.cleargendata();
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
      }
      else {
        this.adjgenComp.adjustmentGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.adjdetComp.adjustmentDetailForm.patchValue(this.helper.getValue('GetSessionData2'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  cleargendata() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.adjgenComp.errorValue = "";
    this.adjgenComp.requiredError = false;
    this.isInvalid = false;
    this.adjgenComp.adjustmentGeneralForm.reset();
    this.adjgenComp.ngOnInit();
    this.adjdetComp.salesselectedItems = null;
    this.adjdetComp.groupselectedItems = null;
    this.adjdetComp.pageselectedItems = null;
    this.adjdetComp.specificselectedItems = null;
    this.adjdetComp.adjustmentDetailForm.reset();
    this.adjdetComp.ngOnInit();

    this.employeeName = "Adjustments";
    this.tableRowColor = 'transparent';
    this.adjgenComp.submittedGeneral = false;
    this.titleName.emit(this.employeeName);
    this.tableRowId = 0;
  }

  async onSave() {
    let response = await this.adjgenComp.onSaveGeneral();
    if (response.res.id != 0) {
      this.adjdetComp.onSaveDetails(response.res.id);
    }
    else {
      this.toaster.errorPopUp(response.res.message)
    }
    if (this.adjgenComp.adjustmentGeneralForm.invalid) {
      if ((this.adjgenComp.adjustmentGeneralForm.value.name == null) || (this.adjgenComp.adjustmentGeneralForm.value.name == "")) {
        this.isInvalid = true;
      }
      else if ((this.adjgenComp.adjustmentGeneralForm.value.codeMaster == null) || (this.adjgenComp.adjustmentGeneralForm.value.codeMaster == "")) {
        this.isInvalid = true;
      }
      else if ((this.adjgenComp.adjustmentGeneralForm.value.adjustmentType == null) || (this.adjgenComp.adjustmentGeneralForm.value.adjustmentType == "")) {
        this.isInvalid = true;
      }
      else if ((this.adjgenComp.adjustmentGeneralForm.value.behaviorType == null) || (this.adjgenComp.adjustmentGeneralForm.value.behaviorType == "")) {
        this.isInvalid = true;
      }
      else if ((this.adjgenComp.adjustmentGeneralForm.value.valueType == null) || (this.adjgenComp.adjustmentGeneralForm.value.valueType == "")) {
        this.isInvalid = true;
      }
      else {
        this.isInvalid = false;
      }
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getadjsessData(e) {
    this.adjsessData.emit(e)
  }
}
