<div class="modal-body">
    <div class=" text-right">
        <button type="button" class="btn-close close" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div *ngIf="!message"  class="loginForm">
        <div class="remove_tab m-2 p-2">
            <label style="font-size: 18px;">Please Select in Sequence, 'Dealer > Company > Store' </label>
            <label style="font-size: 16px;"> Before Selecting {{title}}</label>
        </div>
    </div>
    <div *ngIf="message" class="loginForm">
            <div class="remove_tab m-2 p-2">
                    <label style="font-size: 18px;"> {{message}}</label>
            </div>
        </div>
</div>