import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { TableCollapseComponent } from 'src/app/elements/table-collapse/table-collapse.component';
import { HelperService } from 'src/app/helper/helper.service';
import { Group } from 'src/app/interfaces/i-report';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
export interface PeriodicElement {
  id?: number;
  name?:any;
  quantity?:any;
  amount?:any;
  time?:any;
  terminal?:any;
  reason?:any;

}
const ELEMENT_DATA1: PeriodicElement[] = [
  { id: 1, name: 'Garrett H', quantity: 5},
  { id: 2, name: 'Jeff C', quantity: 12},
  { id: 3, name: 'Johnny B', quantity: 3},
];

const ELEMENT_DATA2: PeriodicElement[] = [
  { id: 4, name: 'Garrett H', quantity: 1, time: '10:42:00', terminal: 'WS01', reason: 'Change'},
  { id: 5, name: '', quantity: 1, time: '10:50:00', terminal: 'WS01', reason: 'JukeBox'},
  { id: 6, name: '', quantity: 1, time: '11:22:00', terminal: 'WS01', reason: 'Change'},
  { id: 7, name: '', quantity: 1, time: '11:37:00', terminal: 'WS01', reason: 'None'},
  { id: 8, name: '', quantity: 1, time: '11:22:00', terminal: 'WS01', reason: 'None'},
];
@Component({
  selector: 'app-no-sales',
  templateUrl: './no-sales.component.html',
  styleUrls: ['./no-sales.component.css']
})
export class NoSalesComponent implements OnInit {

  loginInfo:any
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public backSalesItem = new EventEmitter
  public employeeName: any;
  public employee: any;
  public employeeIcon: any
  @Input() public noSales
  firstTableName:any

  public displayedColumns1 = [];
  columns1 = [];
  public dataSource1: any
  public displayedColumns2 = [];
	columns2 = [];
	public dataSource2 = new MatTableDataSource<any | Group>([]);
  public alldata1: any[];
	public alldata2: any[];
  public groupByColumns2: string[] = [];
  public firstTableShow:boolean = false
  public secondTableShow:boolean = false
  public backToSales:boolean = false
  public noReportshow:boolean = false
  @ViewChild(TableCollapseComponent) tablecollapse: TableCollapseComponent

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private reportService: ReportWebSocketService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.noSales");

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "No Sales Reports";
    this.employee = "Reports";
    this.employeeIcon = "assets/components/images/reports-icon.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    // this.getFirstTable()
    // this.getSecondTable()
 //   this.FinalReceivedData(event)
  }

  FinalReceivedData(event?) {
			
		this.reportService.SelectNoSalesDetails(event).subscribe(res => {
			this.alldata1 = res.summary;
			this.alldata2=res.details;
			// this.dataSource1.data = this.tablecollapse.addGroups(this.alldata1, this.groupByColumns1);
			// this.dataSource2.data = this.tablecollapse.addGroups(this.alldata2, this.groupByColumns2);
		});
    this.getFirstTable(	this.alldata1);
    this.getSecondTable(this.alldata2);
	}

  getFirstTable(data) {
    this.firstTableShow = true;
    this.displayedColumns1 = ['name','quantity'];
    this.dataSource1 = data;
   
    this.columns1 = [
      { columnDef: 'name', header: "Employee Name" },
      { columnDef: 'quantity', header: "QTY" },
    ]
  }

  getSecondTable(details) {
    this.noReportshow = true
		this.reportService.getAllData()
		.subscribe(
			(data: any) => {
			
				this.alldata2 = data.data;
     //   console.log(data.data,'data.data')
				this.dataSource2.data = this.tablecollapse.addGroups(details, this.groupByColumns2);
			}
		);

		this.columns2 = [{
			field: 'employeeName', header: 'Employee Name'
		}, {
			field: 'quantity', header: 'QTY'
		}, {
			field: 'time', header: 'Time'
		},{
			field: 'terminal', header: 'Terminal'
		}, {
			field: 'reason', header: 'Reason'
		}];
		this.displayedColumns2 = this.columns2.map(column => column.field);
		this.groupByColumns2 = ['groupHeader'];
  }


}
