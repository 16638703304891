import { Directive, ElementRef, HostListener } from '@angular/core';
import { Key } from 'protractor';

@Directive({
  selector: '[appDecimalLimit]'
})
export class DecimalLimitDirective {
 private regex: RegExp = new RegExp(/^\d+[.]?\d{0,2}$/g);// user can put . or , char.
// input also cannot start from , or .
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.includes(event.key)) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (current=='' || (current.slice(0,1)=='.' && position<=2))
    {

      if ((event.keyCode >= 65 && event.keyCode <= 90) )
      {
         event.preventDefault();
      }
      else if ((event.keyCode >= 58 && event.keyCode <= 63)|| 
      (event.keyCode >= 106 && event.keyCode <= 109 && event.keyCode==111) || (event.keyCode >= 160 && event.keyCode <= 226))
      {
        if(current=='.' && position<=2 && event.keyCode==190)
        {
          event.preventDefault();
        }
        else if(event.keyCode==189)
        {
          event.preventDefault();
        }
      }
      else if (current=='.' && position<=2 && event.keyCode ==110)
      {
        event.preventDefault();
      }
      else if (event.keyCode==16)
      {
        event.preventDefault();
      }
     
    
    }
    
    else
    {
      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }
  }
}
