import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TableCollapseComponent } from 'src/app/elements/table-collapse/table-collapse.component';
import { HelperService } from 'src/app/helper/helper.service';
import { Group, iSalesDayPartReport } from 'src/app/interfaces/i-report';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';

@Component({
	selector: 'app-sales-daypart',
	templateUrl: './sales-daypart.component.html',
	styleUrls: ['./sales-daypart.component.css']
})
export class SalesDaypartComponent implements OnInit {
	loginInfo: any
	@Output() public titleName = new EventEmitter();
	@Output() public title = new EventEmitter();
	@Output() public titleIcon = new EventEmitter();
	@Output() public backSalesItem = new EventEmitter
	public employeeName: any;
	public employee: any;
	public employeeIcon: any
	@Input() public noSales
	firstTableName: any

	public firstTableShow: boolean = false
	public secondTableShow: boolean = false
	public backToSales: boolean = false
	public dataSource1 = new MatTableDataSource<any | Group>([]);
	public alldata1: any[];
	public columns1: any[];
	public displayedColumns1: string[];
	public groupByColumns1: string[] = [];
	public displayedColumns2 = [];
	columns2 = [];
	public dataSource2 = new MatTableDataSource<any | Group>([]);
	public alldata2: any[];
	public groupByColumns2: string[] = [];


	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(TableCollapseComponent) tablecollapse: TableCollapseComponent

	constructor(private formBuilder: FormBuilder, private helper: HelperService, private reportService: ReportWebSocketService) {

	}

	ngOnInit(): void {
		localStorage.setItem('browserPage', "this.salesByDaypart");

		this.loginInfo = this.helper.getValue('LoginInfo');
		this.employeeName = "Sales By Daypart";
		this.employee = "Reports";
		this.employeeIcon = "assets/components/images/reports-icon.png";
		this.titleName.emit(this.employeeName);
		this.title.emit(this.employee);
		this.titleIcon.emit(this.employeeIcon);
		// this.getFirstTable();
		// this.getSecondTable();
	}

	FinalReceivedData(event?) {

		this.reportService.SelectSalesDayPartReport(event).subscribe(res => {
			this.alldata1 = res.data;
			this.alldata2 = res.ItemData;
			// console.log(this.alldata1,'	API Data1')
			console.log(this.alldata2, '	APIData2')
			this.dataSource1.data = this.tablecollapse.addGroups(this.alldata1, this.groupByColumns1);
			this.dataSource2.data = this.tablecollapse.addGroups(this.alldata2, this.groupByColumns2);
			this.dataSource2.data.forEach((keys: any, vals: any) => {


				keys.itemCost = Number(keys.itemCost).toFixed(2),
					keys.itemCost = Number(keys.itemCost).toFixed(2),
					keys.grossCost = Number(keys.grossCost).toFixed(2),
					keys.totalDiscounts = Number(keys.totalDiscounts).toFixed(2)
				//  keys.grossCostper = Number(TotalCost) == 0 ? 0 : ((Number(keys.grossCost) / Number(TotalCost)) * 100).toFixed(2)

			});
		});


		this.columns1 = [{
			field: 'category', header: 'Daypart'
		}, {
			field: 'quantity', header: 'QTY'
		}, {
			field: 'grossSales', header: 'Gross sales'
		}, {
			field: 'netSales', header: 'Net sales'
		}, {
			field: 'categorySale', header: '% of Daypart Sales'
		}];
		this.displayedColumns1 = this.columns1.map(column => column.field);
		this.groupByColumns1 = ['groupHeader'];

		// ******************For Item*****************************************************

		this.columns2 = [{
			field: 'category', header: 'Daypart'
		}, {
			field: 'quantity', header: 'QTY'
		}, {
			field: 'itemPrice', header: 'Price'
		}, {
			field: 'itemCost', header: 'Cost'
		}, {
			field: 'grossSales', header: 'Gross sales'
		}, {
			field: 'grossCost', header: 'Gross Cost'
		}, {
			field: 'totalDiscounts', header: 'Total Discounts'
		}, {
			field: 'netSales', header: 'Net sales'
		}, {
			field: 'categorySale', header: '% of Daypart'
		}, {
			field: 'grossSalesPercent', header: 'Gross Sales %'
		}, {
			field: 'grossCostPercent', header: 'Gross Cost %'
		}, {
			field: 'foodCostPercent', header: 'Food Cost %'
		}];
		this.displayedColumns2 = this.columns2.map(column => column.field);
		this.groupByColumns2 = ['groupHeader'];
	}
	getFirstTable() {
		this.firstTableShow = true;
		this.reportService.getAllDataHour()
			.subscribe(
				(data: any) => {

					this.alldata1 = [];
					//console.log(this.alldata1,'Json Data1')
					this.dataSource1.data = this.tablecollapse.addGroups(this.alldata1, this.groupByColumns1);
				}
			);

		this.columns1 = [{
			field: 'category', header: 'Category'
		}, {
			field: 'quantity', header: 'QTY'
		}, {
			field: 'grossSales', header: 'Gross sales'
		}, {
			field: 'netSales', header: 'Net sales'
		}, {
			field: 'categorySale', header: '% of Category Sales'
		}];
		this.displayedColumns1 = this.columns1.map(column => column.field);
		this.groupByColumns1 = ['groupHeader'];
	}
	getSecondTable() {
		this.reportService.getAllDataHour()
			.subscribe(
				(data: any) => {

					this.alldata2 = [];
					//	console.log(this.alldata1,'JSon Data2')
					this.dataSource2.data = this.tablecollapse.addGroups(this.alldata2, this.groupByColumns2);


				}
			);

		this.columns2 = [{
			field: 'category', header: 'Item'
		}, {
			field: 'quantity', header: 'QTY'
		}, {
			field: 'grossSales', header: 'Gross sales'
		}, {
			field: 'netSales', header: 'Net sales'
		}, {
			field: 'categorySale', header: '% of Total Sales'
		}];
		this.displayedColumns2 = this.columns2.map(column => column.field);
		this.groupByColumns2 = ['groupHeader'];
	}

}