<div class="Gen_wrap">
    <form class="loginForm GeneralForm" [formGroup]="GeneralForm" (change)="onchange(GeneralForm.value)">
        <div class="pull-right-active">
            <span>Active</span>
            <div class="checkbox-table accordion-checkbox" id="checkbox-box">
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" class="custom-control-input" name="customCheckbox" id="activeForm" formControlName="isActive" checked="isActive" [attr.disabled]="isActiveInactivePerm ? true : null" />
                    <label class="custom-control-label" for="activeForm"></label>
                </div>
            </div>
        </div>
        <br>

        <label class="custom-color" *ngIf="!(dealerEmp || compEmp )">Employee of ISV</label>
        <div class="row w-100">
            <div class="col-sm-4">

                <div class="form-group">
                    <label class="customlable mb-0">First Name</label> <label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="firstName" appInputRestriction placeholder="First Name" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.firstName.errors) || (requiredError && GeneralData.firstName.errors)}"
                        maxlength="50" (input)="getMaxValueFName($event)" (keypress)="Highlight()" (focusout)="EmpGenValid()" />
                    <div class="error">{{errorValue1}}</div>
                    <div *ngIf="(submittedGeneral && GeneralData.firstName.errors) || (requiredError && GeneralData.firstName.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.firstName.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Last Name</label><label class="text-danger">*</label>
                    <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.lastName.errors) || (requiredError && GeneralData.lastName.errors)}" maxlength="50"
                        (input)="getMaxValueLName($event)" (keypress)="Highlight()" (focusout)="EmpGenValid()" />
                    <div class="error">{{errorValue2}}</div>
                    <div *ngIf="(submittedGeneral && GeneralData.lastName.errors) || (requiredError && GeneralData.lastName.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.lastName.errors.required">Last Name is required</div>
                    </div>
                </div>
                <div class="form-group" *ngIf="!dealerHierarchyHide">
                    <label class="customlable mb-0">POS Name</label>
                    <input type="text" class="form-control" formControlName="posName" placeholder="POS Name" appInputRestriction maxlength="50" (input)="getMaxValue($event)" />
                    <div class="error">{{errorValue}}</div>
                </div>

                <div class="form-group">
                    <label class="customlable mb-0">Login ID</label><label class="text-danger">*</label>
                    <input type="text" class="form-control" formControlName="loginId" placeholder="Login ID" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.loginId.errors) || (requiredError && GeneralData.loginId.errors)}" maxlength="7" (keypress)="numberOnly($event) &&  Highlight()"
                        (focusout)="EmpGenValid()" />
                    <div *ngIf="(submittedGeneral && GeneralData.loginId.errors) || (requiredError && GeneralData.loginId.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.loginId.errors.required">Login ID is required</div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="customlable mb-0">Hire Date</label><label class="text-danger" *ngIf="!dealerHierarchyHide">*</label>
                    <mat-form-field appearance="fill" class="custom-date">
                        <input matInput [matDatepicker]="picker" placeholder="Hire Date" class="form-control datepicker appInputRestriction" formControlName="employeeHireDate" (click)="picker.open()" (dateChange)="Highlight()" [min]="minDate">
                        <mat-datepicker #picker class="example-header"></mat-datepicker>
                    </mat-form-field>
                    <div class="error">{{dateMgsHire}}</div>

                    <!-- <input type="text" placeholder="Hire Date" class="form-control" formControlName="employeeHireDate"
              bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" appInputRestriction
              [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.employeeHireDate.errors) || (requiredError && GeneralData.employeeHireDate.errors)}"  (keypress)="Highlight()" (focusout)="EmpGenValid()"> -->
                    <div *ngIf="(submittedGeneral && GeneralData.employeeHireDate.errors) || (requiredError && GeneralData.employeeHireDate.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.employeeHireDate.errors.required">Hire Date is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Date Of Birth</label>
                    <mat-form-field appearance="fill" class="custom-date">
                        <input matInput [matDatepicker]="pickerA" placeholder="MM-DD-YYYY" class="form-control datepicker appInputRestriction" formControlName="employeeBirthday" (click)="pickerA.open()" [max]="maxDate" (dateChange)="Highlight()" [min]="minDate">
                        <mat-datepicker #pickerA class="example-header"></mat-datepicker>
                    </mat-form-field>
                    <!-- <input type="text" placeholder="MM-DD-YYYY" class="form-control" formControlName="employeeBirthday" [maxDate]="maxDate"
              bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" appInputRestriction> -->
                    <div class="error">{{dateMgs}}</div>
                    <div class="error">{{errorMessage}}</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Phone</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                  <img src="assets/components/images/USA.png" alt="employee" class="flag_img">+1
                </span>
                        </div>
                        <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="phone" placeholder="(xxx) xxx-xxxx" maxLength="14" (keypress)="numberOnly2($event)" (keydown)="onDeletePhone($event)" (focusout)="phoneNumberCheck($event)" appInputRestriction
                            [ngClass]="{ 'is-invalid': GeneralForm.controls['phone'].errors && submittedGeneral && !isValidFormSubmitted || mgs!=''}" />
                    </div>
                    <div class="error">{{mgs}}</div>
                    <div *ngIf="GeneralForm.controls['phone'].errors && submittedGeneral && !isValidFormSubmitted" [ngClass]="'error'">
                        <div *ngIf="GeneralForm.controls['phone'].errors.pattern && mgs==''">
                            Mobile Number not valid.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="customlable mb-0">Address</label>
                    <input type="text" class="form-control" formControlName="address" placeholder="Address" appInputRestriction/>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">City</label>
                    <input type="text" class="form-control" formControlName="city" placeholder="City" appInputRestriction maxlength="50" (input)="getMaxValueCity($event)" />
                    <div class="error">{{errorValue5}}</div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">State</label>
                    <!-- <input type="text" class="form-control" formControlName="state" placeholder="MT"/> -->
                    <mat-form-field class="StateWrap">
                        <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" #select1 formControlName="state" placeholder="Select State" (selectionChange)="getSubtier($event.value)" (selectionChange)="onchange(GeneralForm.value)">
                            <!-- <mat-select-filter (keyup)="onKey($event.target.value)"> </mat-select-filter> -->
                            <input class="form-control input-lg inputbox_login" (keyup)="onKey($event.target.value)" maxlength="50" (input)="getMaxValueTerritory($event)" />
                            <div class="error">{{errorValue6}}</div>
                            <mat-option *ngFor="let item of filteredList2" [value]="item.id">
                                {{item.id}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Zip Code</label>
                    <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code" [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" (focusout)="zipCodeCheck($event)" (keydown)="onDeleteZipCode($event)" [ngClass]="{ 'is-invalid': zipCodeMsg!=''}"
                    />
                    <div class="error">{{zipCodeMsg}}</div>
                </div>
            </div>
            <div class="col-sm-4">

                <div class="form-group">
                    <label>Back Office Access</label><br>
                    <label class="switch">
              <input type="checkbox" formControlName="backofficeAccess"
              (change)="togglebackoffice(GeneralForm.get('backofficeAccess').value)">
              <span class="slider round"></span>
              <div class="toggle-button-text outshadow employee_onoff">
                <div class="toggle-button-text-on"></div>
                <div class="toggle-button-text-off"></div>
              </div>
            </label>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Email</label><label class="text-danger" *ngIf="GeneralForm.get('backofficeAccess').value">*</label>
                    <input type="text" class="form-control" formControlName="emailId" appInputRestriction placeholder="&#x2709; Example@myemailaddress.com" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.emailId.errors)}" maxlength="100" (input)="getMaxValueEmail($event)"
                        (keypress)="Highlight()" (focusout)="EmpGenValid()" />
                    <div class="error">{{errorValue3}}</div>

                    <div *ngIf="(submittedGeneral && GeneralData.emailId.errors)" class="invalid-feedback">
                        <!-- <div *ngIf="f.email.errors.required">Email is required</div> -->
                        <div *ngIf="GeneralData.emailId.errors.email">Email must be a valid email address</div>
                        <div *ngIf="GeneralForm.get('backofficeAccess').value">
                            <div *ngIf="GeneralData.emailId.errors.required">Email is required</div>
                        </div>
                    </div>
                </div>

                <div class="form-group" *ngIf="GeneralForm.get('backofficeAccess').value">
                    <label class="customlable mb-0">Password</label><label class="text-danger">*</label>
                    <input type="password" class="form-control" formControlName="password" placeholder="Password" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.password.errors) || (requiredError && GeneralData.password.errors)}" (keypress)="Highlight()"
                        (focusout)="EmpGenValid()" />
                    <strength-checker barLabel="Strength: " [passwordToVerify]="GeneralForm.value.password" (passwordStrength)="passwordValid($event)">
                    </strength-checker>
                    <div *ngIf="(submittedGeneral && GeneralData.password.errors) || (requiredError && GeneralData.password.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.password.errors.required">Password is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-right">
                <div class="form-group">
                    <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral();" [disabled]="buttonDisable ? true : null">
              <img src="assets/components/images/save.png" alt="employee" class="">
              <label class="label_config">Save</label>
            </button>
                </div>
            </div>
        </div>
    </form>
</div>
