<div class="gift-card-back gift-card-width" >
  <div class="top-header mt-1 w-100 card-outline back-card">
    <div class="card text-center card-ht pd-card">
      <div class="row no-gutters upper-div">
        <div class="col">
          <div class="card-body p-0">
          </div>
        </div>
      </div>
      <div class="row no-gutters magic-stripe">
        <div class="col">
          <div class="card-body p-0">
          </div>
        </div>
      </div>
      <div class="row no-gutters card-number">
        <div class="col">
          <div class="card-body p-0">
            <p class="card-title mb-0 back-ft-color">{{businessName}}</p>
          </div>
        </div>
      </div>
      <div class="row no-gutters card-number">
        <div class="col">
          <div class="card-body p-0">
            <p class="card-title mb-0 back-ft-color">{{address}}</p>
          </div>
        </div>
      </div>
      <div class="row no-gutters card-number">
        <div class="col">
          <div class="card-body p-0">
            <p class="card-title mb-0 back-ft-color">{{cityname}} {{stateCode}} {{zipCd}}</p>
          </div>
        </div>
      </div>
      <div class="row no-gutters message-div text-center">
        <div class="col">
          <div class="card-body p-0">
            <h6 class="card-title mb-0 back-ft-color">{{cardBackTest}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="card-class">BACK OF THE CARD</p>
</div>