<div class="form-group global-form">
    <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn btn-sm float-right submit_button global-submit" type="submit" (click)="OnSave();">
            <!-- [disabled]="buttonDis ? true : null" -->
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label> 
    </button>
</div>
<mat-accordion>
    <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst" [expanded]="isFirst"
        class="outshadow">
        <mat-expansion-panel-header [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
            <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}} </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="item == 'General'">
            <app-menu-page-general [pageGeneral]="PageData" [EditPagedata]="PageEdit" (generalItem)="getGeneralDetail($event)"
            [buttonDis]="buttonState" (isInvalid)="isInvalidAccordion($event)" (mnupageData_subchild)="getmnupagesessData($event)"></app-menu-page-general>
        </div>
        <!-- <div *ngIf="item == 'Layout'">
            <app-menu-page-layout [EditPagedata]="PageEdit"></app-menu-page-layout>
        </div> -->
    </mat-expansion-panel>
</mat-accordion>