import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import { HelperService } from '../helper/helper.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { deleteEventData, getEventData, ISaveEventPricing, IGetEventPrice, Scheduler, SchedulerSelect, GetModifier, iSaveEventBuilder, GetSaleAreaGeneralDD } from '../interfaces/imenu';

@Injectable({
  providedIn: 'root'
})
export class EventPricingService {

  constructor(public http: HttpClient,private Helper: HelperService, private base: BaseService, private router: Router) { }

  GetAllData(employee: getEventData) {
    return this.base.post('', employee).pipe(map((response: any) => {
      return response;
    }));
  }

  saveEventPricing(saveEventPricingData: ISaveEventPricing) {
    return this.base.post('Scheduler/SaveEventPricing', saveEventPricingData).pipe(map((response: any) => {
      return response;
    }));
  }

  selectEventPricing(getEventPricingData: IGetEventPrice) {
    return this.base.post('Scheduler/SelectEventPricing', getEventPricingData).pipe(map((response: any) => {
      return response;
    }));
  }
  activateDeactivate(employee: deleteEventData) {
    return this.base.post('Scheduler/DeleteEventPricing', employee).pipe(map((response: any) => {
      return response;
    }));
  }

  saveScheduler(saveScheduler: Scheduler) {
    return this.base.post('Scheduler/SaveScheduler', saveScheduler).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectSchedulerWidget(getSchedulerDetails: SchedulerSelect) {
    return this.base.post('Scheduler/SelectSchedulerWidget', getSchedulerDetails).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectSpecificItems(getParam: GetModifier) {
    return this.base.post('Scheduler/SelectSpecificItem', getParam).pipe(map((response: any) => {
      return response;
    }));
  }

  
  SaveEventBuilder(SaveBuilder: iSaveEventBuilder) {
    return this.base.post('Scheduler/SaveEventPricingBuilder', SaveBuilder).pipe(map((response: any) => {
      return response;
    }));
  }

  GetCodeMaster(dropdown: GetSaleAreaGeneralDD) {
    return this.base.post('Admin/GetCodeMaster', dropdown).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectPricingBuilder(objData: SchedulerSelect) {
    return this.base.post('Scheduler/SelectEventPriceBuilder', objData).pipe(map((response: any) => {
      return response;
    }));
  }
}
