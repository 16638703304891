import { Component, Input, OnInit, TemplateRef, ViewChild, Output, EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfirmBoxPopupComponent } from '../elements/confirm-box-popup/confirm-box-popup.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { HelperService } from '../helper/helper.service';
import { ToasterService } from '../helper/toaster.service';

@Injectable({
  providedIn: 'root'
})

export class DialogService {
  modalRef: BsModalRef;
  bsModalRef?: BsModalRef;
  dff: boolean;
  checkdata: boolean;
  selected_img = false;
  master_img = false;
  menu_img = false;
  home_img = false;
  site_img = false;
  dealers_img = false;
  push_img = false;
  ModifiedId: any = 0;

  constructor(private modalService: BsModalService, private helper: HelperService,
    private toaster: ToasterService) {
  }

  async confirm(pageind): Promise<any> {
    // const confirmation = window.confirm(message || 'Are you sure?');
    //let confrmObj = new ConfirmBoxPopupComponent(this.bsModalRef);
    return new Promise<string>((resolve, reject) => {
      this.bsModalRef = this.modalService.show(ConfirmBoxPopupComponent, {
        class: 'modalComp modal-popUp modal-l', backdrop: 'static',
        keyboard: true
      });
      this.bsModalRef.content.messageEvent.subscribe(data => {
        this.dff = data
        if (this.dff == true) {
          this.helper.setValue('NewSession', "this." + pageind);
          this.checkdata = this.helper.getValue('NewSession');
          let a = '' + this.checkdata + '' + "=true";
          let s = "this." + pageind;
          s = eval(a);
          setTimeout(() => {
            resolve('success');
          }, 300)
        } else {
          reject('fail');
        }
      });
      this.bsModalRef.content.closeBtnName = 'Close';
      //return resolve;
    }).catch(e => {
      console.log("Confirm Issue : ", e);
      return 'fail';
    });
  }

  getThemeData(e) {
    let a = '' + e + '' + "=true";
    let s = "this." + e;
    s = eval(a);
    this.MenuImageSet(e);

    // this.helper.getValue('empGenData')
    // this.helper.getValue('empWageData')
    // this.helper.getValue('empPermissionData')
    // this.helper.getValue("dragedModifer")
    // this.helper.getValue("dragedTable")
  }

  MenuImageSet(menuName) {
    if (menuName == 'this.dealerPage' || menuName == 'this.dealerEmp') {
      this.dealers_img = true;
    }
    else {
      this.dealers_img = false;
    }

    if (menuName == 'this.companyPage' || menuName == 'this.compEmp') {
      this.site_img = true;
    }
    else {
      this.site_img = false;
    }

    if (menuName == 'this.storePage' || menuName == 'this.CustomPayments' || menuName == 'this.HouseAccounts'
      || menuName == 'this.GiftCard' || menuName == 'this.CreditCard' || menuName == 'this.emvPage' || menuName == 'this.tablet'
      || menuName == 'this.printer' || menuName == 'this.receipt' || menuName == 'this.tagsPage' || menuName == 'this.reasons') {
      this.menu_img = true;
    }
    else {
      this.menu_img = false;
    }

    if (menuName == 'this.GeneralPage' || menuName == 'this.TimePage' || menuName == 'this.BreakPage'
      || menuName == 'this.payroll' || menuName == 'this.jobPosition') {
      this.selected_img = true;
    }
    else {
      this.selected_img = false;
    }

    if (menuName == 'this.MenuSizePage' || menuName == 'this.MenuItemPage' || menuName == 'this.MenuModifierPage' ||
      menuName == 'this.SalesAreaPage' || menuName == 'this.layoutPage' || menuName == "this.adjustmentPage" ||
      menuName == 'this.eventPage') {
      this.home_img = true;
    }
    else {
      this.home_img = false;
    }

    if (menuName == 'this.IsvPage' || menuName == 'this.typeofbusinessPage' || menuName == 'this.RolepermissionPage' ||
      menuName == 'this.statefield' || menuName == 'this.signal' || menuName == "this.chatPage" ||
      menuName == 'this.contractPage' || menuName == 'this.socket' || menuName == 'this.labor' || menuName == "this.taxMaster") {
      this.master_img = true;
    }
    else {
      this.master_img = false;
    }
    //this.report_img = menuName == 'this.dealerPage' ? true : false;
    //this.push_img = false;
  }

  confirmsure(message?: string): boolean {
    const confirmation = window.confirm(message || 'Are you sure?');
    return (confirmation);
  };

  async ClearSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ModifiedId = 0;
      this.helper.setValue('OnDirty', false);
      this.helper.setValue('OnDirty2', false);
      this.helper.setValue('OnDirty3', false);
      this.helper.setValue('OnDirty4', false);
      this.helper.setValue('GetSessionData1', "");
      this.helper.setValue('GetSessionData2', "");
      this.helper.setValue('GetSessionData3', "");
      this.helper.setValue('GetSessionData4', "");
      this.helper.setValue('SessionPageName', "");
      this.helper.setValue('Sessionid_help', 0);

      this.helper.setValue('SalesLayout_ID', "");
      this.helper.setValue('MnuGrpLayout_ID', "");
      this.helper.setValue('MnuPageLayout_ID', "");
      this.helper.setValue('OnLayoutSelected', "");
      resolve("success");
    }).catch(e => {
      console.log(e);
    });
  }
}
