<div class="adjustment_gen">
    <form class="SaleArea_general adjustmentForm loginForm" [formGroup]="adjustmentGeneralForm"
    (change)="onchange(adjustmentGeneralForm.value)">
    
        <div class="row w-100">                    
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Name</label><label>*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="name"
                        [ngClass]="{ 'is-invalid': (submittedGeneral && adjustmentData.name.errors) || (requiredError && adjustmentData.name.errors)}"
                        appInputRestriction maxlength="50" (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="AdjGenValid()"/>
                        <div class="error">{{errorValue}}</div>
                </div>
                <div *ngIf="(submittedGeneral && adjustmentData.name.errors) || (requiredError && adjustmentData.name.errors)" class="invalid-feedback">
                    <div *ngIf="adjustmentData.name.errors.required">Name is required</div>
                </div>
            </div>
            <div class="col-sm-8">
            <div class="pull-right-active">
                <span>Auto Apply</span>
                <div class="checkbox-table accordion-checkbox adj-chck" id="checkbox-box">
                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="customCheckbox" formControlName="autoAdjust"
                      id="activeForm"/>
                    <label class="custom-control-label"></label>
                  </div>
                </div>
              </div> 
            </div>   
        </div>
        <div class="main-item row form-group">
            <div class="RadioForm col-sm-3 col" *ngFor="let item of CodeMaster">
                <div class="">
                    <label class="radiolabel">{{item.typeName}}</label>
                    <div class="custom-control custom-radio custom-control item">
                        <input type="radio" formControlName="codeMaster" value="{{item.typeId}}" id="{{item.typeId}}"
                            class="custom-control-input" (change)="OnClickToggle()" name="codeMaster"
                            [ngClass]="{ 'is-invalid': (submittedGeneral && adjustmentData.codeMaster.errors) || (requiredError && adjustmentData.codeMaster.errors)}" (focusout)="AdjGenValid()">
                        <label class="custom-control-label QR" for="{{item.typeId}}"></label>
                    </div>
                </div>
            </div>
            <div *ngIf="(submittedGeneral && adjustmentData.codeMaster.errors) || (requiredError && adjustmentData.codeMaster.errors)" class="invalid-feedback ml-2">
                <div *ngIf="adjustmentData.codeMaster.errors.required">Adjustment is required</div>
            </div>
        </div>
        <label class="toplabel custom-color">Adjustment Type</label><label class="custom-color">*</label>
        <div class="main-item row form-group">
            <div class="RadioForm1 col-sm-2 col-6" *ngFor="let item of AdjustmentType">
                <div class="">
                    <label class="radiolabel">{{item.typeName}}</label>
                    <div class="custom-control custom-radio custom-control item">
                        <input type="radio" formControlName="adjustmentType" value="{{item.typeId}}"
                            id="{{item.typeId}}" class="custom-control-input" (change)="OnClickToggle()"
                            name="adjustmentType"
                            [ngClass]="{ 'is-invalid': (submittedGeneral && adjustmentData.adjustmentType.errors) || (requiredError && adjustmentData.adjustmentType.errors)}" (focusout)="AdjGenValid()">
                        <label class="custom-control-label QR" for="{{item.typeId}}"></label>
                    </div>
                </div>
            </div>
            <div *ngIf="(submittedGeneral && adjustmentData.adjustmentType.errors) || (requiredError && adjustmentData.adjustmentType.errors)" class="invalid-feedback ml-2">
                <div *ngIf="adjustmentData.adjustmentType.errors.required">Adjustment Type is required</div>
            </div>
        </div>
        <div *ngIf="behaviorHide">
            <label class="toplabel custom-color">Behavior Type</label><label class="custom-color">*</label>
            <div class="main-item row form-group" #behavior>
                <div class="col-sm-2 col" *ngFor="let item of BehaviorType">
                    <div *ngIf="(item.typeName != 'Round it up') && (item.typeName != 'Total Amount')  ">
                        <label class="radiolabel">{{item.typeName}}</label>
                        <div class="custom-control custom-radio custom-control item">
                            <input type="radio" formControlName="behaviorType" value="{{item.typeId}}"
                                id="{{item.typeId}}" class="custom-control-input" (change)="OnClickToggle()"
                                name="behaviorType" [checked]="item.typeId"
                                [ngClass]="{ 'is-invalid': (submittedGeneral && adjustmentData.behaviorType.errors) || (requiredError && adjustmentData.behaviorType.errors)}" (keypress)="Highlight()" (focusout)="AdjGenValid()">
                            <label class="custom-control-label QR" for="{{item.typeId}}"></label>
                        </div>
                    </div>
                    <div *ngIf="item.typeName == 'Total Amount' && !gratuity"
                        [style.display]="(totalamount) == true?  'block' : 'none'">
                        <label class="radiolabel">{{item.typeName}}</label>
                        <div class="custom-control custom-radio custom-control item">
                            <input type="radio" formControlName="behaviorType" value="{{item.typeId}}"
                                id="{{item.typeId}}" class="custom-control-input" (change)="OnClickToggle()"
                                name="behaviorType"
                                [ngClass]="{'is-invalid': (submittedGeneral && adjustmentData.behaviorType.errors) || (requiredError && adjustmentData.behaviorType.errors)}" (keypress)="Highlight()" (focusout)="AdjGenValid()">
                            <label class="custom-control-label QR" for="{{item.typeId}}"></label>
                        </div>
                    </div>
                    <div *ngIf="item.typeName == 'Round it up' && !gratuity"
                        [style.display]="(roundup) == false?  'none' : 'block'">
                        <label class="radiolabel">{{item.typeName}}</label>
                        <div class="custom-control custom-radio custom-control item">
                            <input type="radio" formControlName="behaviorType" value="{{item.typeId}}"
                                id="{{item.typeId}}" class="custom-control-input" (change)="OnClickToggle()"
                                name="behaviorType"
                                [ngClass]="{ 'is-invalid': (submittedGeneral && adjustmentData.behaviorType.errors) || (requiredError && adjustmentData.behaviorType.errors)}" (keypress)="Highlight()" (focusout)="AdjGenValid()">
                            <label class="custom-control-label QR" for="{{item.typeId}}"></label>
                        </div>
                    </div>
                </div>
                <div *ngIf="(submittedGeneral && adjustmentData.behaviorType.errors) || (requiredError && adjustmentData.behaviorType.errors)" class="invalid-feedback ml-2">
                    <div *ngIf="adjustmentData.behaviorType.errors.required">Behavior Type is required</div>
                </div>
            </div>

            <div *ngIf="!ItemFixedHide">
                <label class="toplabel custom-color">Value Type</label><label class="custom-color" *ngIf="!(fixedHide || totalHide)">*</label>
            </div>

            <div *ngIf="!(roundupdown || fixedHide || ItemFixedHide)">
                <div class="main-item row form-group">
                    <div class="RadioForm3 col-sm-2 col" *ngFor="let item of ValueType"
                        [style.display]="(totalHide) == false?  'block' : 'none'">
                        <div class="">
                            <label class="radiolabel">{{item.typeName}}</label>
                            <div class="custom-control custom-radio custom-control item">
                                <input class="" type="radio" formControlName="valueType" value="{{item.typeId}}"
                                    id="{{item.typeId}}" class="custom-control-input" (change)="OnClickToggle()"
                                    name="valueType"
                                    [ngClass]="{ 'is-invalid': (submittedGeneral && adjustmentData.valueType.errors) || (requiredError && adjustmentData.valueType.errors)}" (keypress)="Highlight()" (focusout)="AdjGenValid()">
                                <label class="custom-control-label QR" for="{{item.typeId}}"></label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-sm-2 " *ngIf="(dollar)">

                        <!-- <input currencyMask class="form-control input-lg inputbox_login currencyMask" formControlName="behaviourValue"
                            [options]="{ decimal: '.', suffix: ''}" [placeholder]="placeholderIcon" appInputRestriction [(ngModel)]="value"/> -->

                        <!-- <input currencyMask class="form-control input-lg inputbox_login currencyMask"
                            formControlName="behaviourValue" [options]="optionsval" (keydown)="onKeyDown($event)"
                            [placeholder]="placeholderIcon" appInputRestriction /> -->
                            <div class="price-textbox">
                                <label></label>
                                <i class="fa fa-usd" aria-hidden="true"></i>
                                <input class="form-control" type="text" placeholder="0.00" formControlName="price"
                                formControlName="behaviourValue" appDecimalLimit list="dataType" appInputRestriction #searchField />
                            </div>
                    </div>
                    <div class="form-group col-sm-2" *ngIf="percentage">
                        <!-- <label></label>
                        <input  currencyMask class="form-control input-lg inputbox_login currencyMask" formControlName="behaviourValue"
                        [placeholder]="placeholderIcon" appInputRestriction [options]="{
                            prefix: '',
                            suffix: ' %',
                            thousands: ',',
                            decimal: '.',
                            align: 'left'
                            }" >
                        <input  currencyMask class="form-control input-lg inputbox_login currencyMask" formControlName="behaviourValue"
                        [placeholder]="placeholderIcon" appInputRestriction [(ngModel)]="value" [options]="options1" (keydown)="onKeyDown($event)" > -->
                        <div class="price-textbox percent-input">
                            <label></label>
                            <i class="fa fa-percent" aria-hidden="true"></i>
                            <input class="form-control" type="text" placeholder="0.00" formControlName="price"
                            formControlName="behaviourValue" appDecimalLimit list="dataType" appInputRestriction #searchField />
                        </div>
                    </div>

                    <div *ngIf="submittedGeneral && adjustmentData.valueType.errors" class="invalid-feedback ml-2">
                        <div *ngIf="adjustmentData.valueType.errors.required">Value Type is required</div>
                    </div>
                </div>
            </div>

            <div class="main-item row form-group" *ngIf="(roundupdown)">
                <!-- *ngIf="(charity && roundup && !(checkAdjust && surcharge))" -->
                <div class="RadioForm4 col-sm-2 col" *ngFor="let item of RounditupvalueArray">
                    <div class="">
                        <label class="radiolabel">{{item.typeName}}</label>
                        <div class="custom-control custom-radio custom-control item">
                            <input type="radio" formControlName="roundItupValue" value="{{item.typeId}}"
                                id="{{item.typeId}}" class="custom-control-input" (change)="OnClickToggle()"
                                name="roundItupValue"
                                [ngClass]="{ 'is-invalid': (submittedGeneral && adjustmentData.roundItupValue.errors) || (requiredError && adjustmentData.roundItupValue.errors) }" (keypress)="Highlight()" (focusout)="AdjGenValid()">
                            <label class="custom-control-label QR" for="{{item.typeId}}"></label>
                        </div>
                    </div>
                </div>
                <div *ngIf="(submittedGeneral && adjustmentData.roundItupValue.errors) || (requiredError && adjustmentData.roundItupValue.errors)" class="invalid-feedback ml-2">
                    <div *ngIf="adjustmentData.roundItupValue.errors.required">Value Type is required</div>
                </div>
            </div>
        </div>
        <div class="main-item row" *ngIf="fixedHide">
            <div class="col-sm-2 col-4">
                <div class="form-group">
                    <label></label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="dollar"
                        placeholder="$0.00" appDecimalLimit appInputRestriction>
                </div>
            </div>
            <div class="col-sm-2 col-4">
                <div class="form-group">
                    <label></label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="percentage"
                        placeholder="%0.00" appDecimalLimit appInputRestriction>
                </div>
            </div>
        </div>
        <div class="main-item row" *ngIf="!behaviorHide">
            <div class="col-sm-2 col-4">
                <div class="form-group">
                    <label class="radiolabel">Credit only?</label>
                    <div class="checkbox-table emp-name">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input active" name="customCheckbox"
                                formControlName="creditCheckbox">
                            <label class="custom-control-label active"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8" *ngIf="adjustmentGeneralForm.get('creditCheckbox').value">
                <div class="row">
                    <div class="col-sm-3 col-6">
                        <div class="form-group">
                            <label>Print Disclaminer? </label>
                            <div class="checkbox-table emp-name">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input active" name="customCheckbox"
                                        formControlName="printCheckbox">
                                    <label class="custom-control-label active"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-6">
                        <div class="form-group">
                            <label></label>
                            <input currencyMask class="form-control input-lg inputbox_login" appInputRestriction
                                formControlName="creditamount" [(ngModel)]="value" [options]="optionsval"
                                (keydown)="onKeyDown($event)" [placeholder]="placeholderIcon">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-12">
                <button class="btn  btn-sm float-right submit_button button-none" type="submit"
                    (click)="onSaveGeneral();" [disabled]="buttonDisable ? true : null">
                    <!-- [disabled]="buttonState ? true : null" -->
                    <img src="assets/components/images/save.png" alt="employee" class="">
                    <label class="label_config">Save</label>
                </button>
            </div>
        </div>
    </form>
</div>
