<div class="pop-up-bg">
    <div class=" text-right">
      <button type="button" class="btn-close close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden" style="padding-right:12px">&times;</span>
      </button>
    </div>
    <div class="mt-2 save-cancel-popup" style="padding: 10px 94px 0 !important;">
      <label class="txt-color" style="display:flex; justify-content:center !important;">Please Upload Image With Dimensions<br>180W * 180H</label>
    </div>
    <div class="notify-footer modal-footer text-center">
      <button md-button class="button-style" (click)="cancleBox()">OK</button>
    </div>
  </div>
  