<div class="wages_wrap">
    <form class="loginForm form-switch" [formGroup]="WagesForm" (change)="onchange(WagesForm.value)">
        <div class="row">
            <div class="col-sm-7 separate-blocks">
                <div class="job_wrap">
                    <!-- <button [disabled]="isWagesPermission ? true : null" [ngClass]="{
              'no-pointers': throwError == true,
              'allow-pointer': throwError == false
            }" class="plus_btn inshadow" (click)="addUserMulti($event)" (keydown)="preventKey($event)">
            <i class="fa fa-plus"></i>
          </button> -->
                    <button [ngClass]="{
            'no-pointers': throwError == true,
            'allow-pointer': throwError == false
          }" class="plus_btn inshadow" (click)="addUserMulti($event)" (keydown)="preventKey($event)">
            <i class="fa fa-plus"></i>
          </button>
                    <label>
            <h6>Job Position</h6> </label
          ><label class="text-danger">*</label>
                    <div formArrayName="employeeJobPositionList" class="scroll-pos">
                        <div class="row no-gutters">
                            <div class="col-sm-4 col-4">
                                <label>Position</label>
                            </div>
                            <div class="col-sm-3 col-3">
                                <label>Wages</label>
                            </div>
                            <div class="col-sm-3 col-3">
                                <label>OT Rate</label>
                            </div>
                            <div class="col-sm-1 col-1">
                                <label>Primary</label>
                            </div>
                            <div class="col-sm-1 col-1"></div>
                        </div>
                        <div class="inline-form-group">
                            <div class="row no-gutters employee-wages" *ngFor="
                  let $employeeJobPositionList of WagesForm.get(
                    'employeeJobPositionList'
                  )['controls'];
                  let i = index
                " [formGroup]="$employeeJobPositionList" autocomplete="off">
                                <div class="col-sm-4 col-4">
                                    <mat-form-field appearance="fill">
                                        <!-- <mat-select [disabled]="isWagesPermission ? true : null" type="text" class="form-control" -->
                                        <mat-select type="text" class="form-control" formControlName="empPositionId" (selectionChange)="onseleccheck($event, i)" placeholder="Select Position" [ngClass]="{
                          'is-invalid':
                            (submittedWages &&
                              $employeeJobPositionList.controls.empPositionId
                                .errors) ||
                            (requiredError &&
                              $employeeJobPositionList.controls.empPositionId
                                .errors) ||
                            (msgFound !== undefined && i === msgFound.index)
                        }" (selectionChange)="Highlight()" (selectionChange)="fieldsFill()" (focusout)="EmpWageValid()">
                                            <!-- <mat-option [value]="" selected disabled>Select Position</mat-option> -->
                                            <mat-option *ngFor="let item of rolePosition" [value]="item.roleId" (click)="onDDLClickItem($event, i)">{{ item.roleName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error *ngIf="
                      (submittedWages &&
                        $employeeJobPositionList.controls.empPositionId
                          .errors) ||
                      (requiredError &&
                        $employeeJobPositionList.controls.empPositionId.errors)
                    " class="invalid-feedback">
                                        <mat-error *ngIf="
                        $employeeJobPositionList.controls.empPositionId.errors
                          .required
                      ">Position is required
                                        </mat-error>
                                    </mat-error>

                                    <mat-error *ngIf="msgFound !== undefined && i === msgFound.index" class="invalid-feedback">
                                        {{ msgFound.value }}</mat-error>
                                </div>
                                <div class="col-sm-3 col-3 price-textbox">
                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                    <!-- <input type="text" class="form-control" placeholder="0.00" formControlName="empWage"
                  appInputRestriction appDecimalLimit [attr.disabled]="
                   (WagesForm.get('salaried').value ? '' : null) || isWagesPermission ? true : null" [ngClass]="{
                    'is-invalid':
                      (submittedWages &&
                        $employeeJobPositionList.controls.empWage.errors) ||
                      (requiredError &&
                        $employeeJobPositionList.controls.empWage.errors &&
                        $employeeJobPositionList.controls.empOtCharges
                          .errors &&
                        WagesForm.get('salaried').value == false)
                  }" (focusout)="EmpWageValid()" (keypress)="Highlight()" (focusout)="fieldsFill()"
                  (keypress)="fieldsFill()" (keypress)="numberOnly($event)" /> -->
                                    <input type="text" class="form-control" placeholder="0.00" formControlName="empWage" appInputRestriction appDecimalLimit [attr.disabled]="
                     (WagesForm.get('salaried').value ? '' : null)" [ngClass]="{
                      'is-invalid':
                        (submittedWages &&
                          $employeeJobPositionList.controls.empWage.errors) ||
                        (requiredError &&
                          $employeeJobPositionList.controls.empWage.errors &&
                          $employeeJobPositionList.controls.empOtCharges
                            .errors &&
                          WagesForm.get('salaried').value == false)
                    }" (focusout)="EmpWageValid()" (keypress)="Highlight()" (focusout)="fieldsFill()" (keypress)="fieldsFill()" (keypress)="numberOnly($event)" />
                                    <div *ngIf="
                      (submittedWages &&
                        $employeeJobPositionList.controls.empWage.errors) ||
                      (requiredError &&
                        $employeeJobPositionList.controls.empWage.errors &&
                        WagesForm.get('salaried').value == false)
                    " class="invalid-feedback">
                                        <div *ngIf="
                        $employeeJobPositionList.controls.empWage.errors
                          .required
                      ">
                                            Wage is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-3 price-textbox">
                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                    <!-- <input type="text" class="form-control" placeholder="0.00" appDecimalLimit appInputRestriction
                    formControlName="empOtCharges" (keypress)="Highlight()" [attr.disabled]="
                     (WagesForm.get('salaried').value ? '' : null) || isWagesPermission ? true : null" [ngClass]="{ -->
                                    <input type="text" class="form-control" placeholder="0.00" appDecimalLimit appInputRestriction formControlName="empOtCharges" (keypress)="Highlight()" [attr.disabled]="
                         (WagesForm.get('salaried').value ? '' : null)" [ngClass]="{
                          'is-invalid':
                            (submittedWages &&
                              $employeeJobPositionList.controls.empOtCharges
                                .errors) ||
                            (requiredError &&
                              $employeeJobPositionList.controls.empOtCharges
                                .errors &&
                              $employeeJobPositionList.controls.empOtCharges
                                .errors &&
                              WagesForm.get('salaried').value == false)
                        }" (focusout)="EmpWageValid()" (keypress)="numberOnly($event)" (focusout)="fieldsFill()" (keypress)="fieldsFill()" />

                                    <div *ngIf="
                      (submittedWages &&
                        $employeeJobPositionList.controls.empOtCharges
                          .errors) ||
                      (requiredError &&
                        $employeeJobPositionList.controls.empOtCharges.errors &&
                        WagesForm.get('salaried').value == false)
                    " class="invalid-feedback">
                                        <div *ngIf="
                        $employeeJobPositionList.controls.empOtCharges.errors
                          .required
                      ">
                                            OT Rate is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1 col-1 pl-2">
                                    <!-- <input type="radio" formControlName="primaryPosition" name="primaryPosition" [value]="true"
                    (change)="onItemChange($event, i)" [attr.disabled]="isWagesPermission ? true : null" [ngClass]="{ -->
                                    <input type="radio" formControlName="primaryPosition" name="primaryPosition" [value]="true" (change)="onItemChange($event, i)" [ngClass]="{
                          'is-invalid':
                            (submittedWages &&
                              $employeeJobPositionList.controls.primaryPosition
                                .errors) ||
                            $employeeJobPositionList.controls.primaryPosition.errors
                        }" />
                                    <div class="radio_lost_wrap"></div>
                                    <div *ngIf="
                      (submittedWages &&
                        $employeeJobPositionList.controls.primaryPosition
                          .errors) ||
                      $employeeJobPositionList.controls.primaryPosition.errors
                    " class="invalid-feedback">
                                        <div *ngIf="
                        $employeeJobPositionList.controls.primaryPosition.errors
                          .required
                      ">
                                            Primary is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1 col-1 pl-15">
                                    <i class="fa fa-remove" aria-hidden="true" style="font-size: 19px" (click)="DeleteItem(i)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row annual-salaried-value mt-25">
                    <div class="col-sm-2 col-2">
                        <label class="customlable mb-0">Salaried?</label>
                        <div class="checkbox-table accordion-checkbox">
                            <div class="custom-control custom-checkbox custom-control-inline">
                                <!-- <input type="checkbox" class="custom-control-input" name="customCheckbox" formControlName="salaried"
                (change)="getSalaried($event.currentTarget.checked)" (change)="fieldsFill()" (click)="fieldsFill()"
                (click)="Highlight2($event.currentTarget.checked)" [attr.disabled]="isWagesPermission ? true : null" /> -->
                                <input type="checkbox" class="custom-control-input" name="customCheckbox" formControlName="salaried" (change)="getSalaried($event.currentTarget.checked)" (change)="fieldsFill()" (click)="fieldsFill()" (click)="Highlight2($event.currentTarget.checked)"
                                />
                                <label class="custom-control-label"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-4">
                        <div class="form-group price-textbox">
                            <label>Annual Salary</label>
                            <i class="fa fa-usd" aria-hidden="true"></i>
                            <!-- <input type="text" class="form-control" placeholder="0.00"
                [attr.disabled]="(WagesForm.get('salaried').value ? null : '') || isWagesPermission ? true : null"
                formControlName="employeeAnnualSalary" appInputRestriction appDecimalLimit (focusout)="EmpWageValid()"
                (keypress)="numberOnly($event)" (keypress)="Highlight()" /> -->
                            <input type="text" class="form-control" placeholder="0.00" [attr.disabled]="(WagesForm.get('salaried').value ? null : '')" formControlName="employeeAnnualSalary" appInputRestriction appDecimalLimit (focusout)="EmpWageValid()" (keypress)="numberOnly($event)"
                                (keypress)="Highlight()" />
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-4">
                        <div class="form-group price-textbox">
                            <label class="label-width">Hourly Fixed Cost</label>
                            <i class="fa fa-usd" aria-hidden="true"></i>
                            <!-- <input type="text" class="form-control" placeholder="0.00" appInputRestriction
                [attr.disabled]="(WagesForm.get('salaried').value ? null : '') || isWagesPermission ? true : null"
                formControlName="employeeFixedCost" [(ngModel)]="value1" (keypress)="numberOnly($event)"
                (keydown)="onKeyDown($event)" appDecimalLimit /> -->
                            <input type="text" class="form-control" placeholder="0.00" appInputRestriction [attr.disabled]="(WagesForm.get('salaried').value ? null : '')" formControlName="employeeFixedCost" [(ngModel)]="value1" (keypress)="numberOnly($event)" (keydown)="onKeyDown($event)"
                                appDecimalLimit />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-2 col-md-4 col-5">
                <div class="form-group">
                    <label class="customlable mb-0">Payroll ID</label>
                    <input type="text" class="form-control" formControlName="employeePayrollId" placeholder="1-16 digits" appInputRestriction maxlength="16" />
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Swipe Card #</label>
                    <input type="text" class="form-control" formControlName="employeeSwipeCard" placeholder="1-16 digits" appInputRestriction maxlength="16" />
                </div>
                <div class="row str-emp-wj">
                    <div class="col-sm-4">
                        <label class="customlable mb-0 label-width">Text Messaging</label>
                        <div class="checkbox-table accordion-checkbox">
                            <div class="custom-control custom-checkbox custom-control-inline">
                                <input type="checkbox" class="custom-control-input" name="customCheckbox" id="textMsg" formControlName="employeeTextMessaging" />
                                <label class="custom-control-label" for="textMsg"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="label-width">Contributes to Labor%</label>
                            <div class="checkbox-table">
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" name="customCheckbox" id="ContributeLabour" formControlName="employeeLabourPercent" />
                                    <label class="custom-control-label" for="ContributeLabour"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <button class="btn btn-sm float-right submit_button button-none" type="submit" [disabled]="buttonStatus ? true : null">
        <img src="assets/components/images/save.png" alt="employee" class="" />
        <label class="label_config">Save</label>
      </button>
        </div>
    </form>
</div>