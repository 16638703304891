import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiUrl = '';

  // Whether or not to enable debug mode
  public enableDebug = true;

  //public awsS3_environment = 'https://flippos.s3.us-west-1.amazonaws.com/Dev/'; // Development(3 server)
  //public awsS3_environment = 'https://flippos.s3.us-west-1.amazonaws.com/Production/'; // Production(LIVE Server)
  public awsS3_environment = 'https://flippos.s3.us-west-1.amazonaws.com/Testing/'; // Testing(54 server)
  constructor() {
  }
}
