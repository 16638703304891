<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="addEmployee()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}"
                class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="OnSave();">
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
            </button>
        </div>
    </div>
</div>
<div class="row second-sec">
    <div class="col-sm-4">
        <app-fliptable (hierarchy)="changeTableRowColor($event)" 
            [dealerItem]="tableRowColor" [dataSource]="dataSource" [displayedColumns]="displayedColumns"
            [columns]="columns" (tableItemId)="getTableIdx($event)" (tableEmpName)="getTableName($event)"
            [employeeItem]="tableRowColor" (breakActive)="ActivateDeactivate($event)"
            [EmployeeTableData]="EmployeeTableData" (clearDetails)="addEmployee($event)" [DataCom]="employee"
            (btnActive)="btnActiveStatus($event)">
        </app-fliptable>
    </div>
    <div class="col-sm-8">
        <!-- <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="OnSave();">
              <img src="assets/components/images/save.png" alt="employee" class="">
              <label class="label_config">Save</label>
            </button>
          </div> -->
        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst" [expanded]="isFirst"
                class="outshadow">
                <mat-expansion-panel-header [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                    <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="item == 'General'">
                    <app-labor-category-general [DataIdx]="tableRowId" (category_general)="getGeneralDetail($event)"
                        [buttonState]="buttonDisable" (isInvalid)="isInvalidAccordion($event)"
                        (LaborcatData_child)="getlabcatsessData($event)"></app-labor-category-general>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>