import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  iGiftCardSelect,
  ActivatePayment,
  iSelectCreditCard,
  iSaveCreditcardGateway,
} from 'src/app/interfaces/icustompayment';
import { PaymentService } from 'src/app/services/payment.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';

import { CreditCardFirstdataComponent } from '../credit-card-firstdata/credit-card-firstdata.component';
import { CreditCardTsysComponent } from '../credit-card-tsys/credit-card-tsys.component';
import { CreditCardVantivComponent } from '../credit-card-vantiv/credit-card-vantiv.component';
import { SubmitParametersComponent } from '../submit-parameters/submit-parameters.component';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { AdminService } from 'src/app/services/admin.service';
import { ISelectState } from 'src/app/interfaces/iadmin';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from 'ngx-intl-tel-input';
import { MatStepper } from '@angular/material/stepper';
import { DialogService } from 'src/app/services/dialog.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CcgatewayModalComponent } from 'src/app/elements/ccgateway-modal/ccgateway-modal.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CcgatewaySubmitModalComponent } from 'src/app/elements/ccgateway-submit-modal/ccgateway-submit-modal.component';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.css'],
})
export class CreditCardComponent implements OnInit {
  buttonDisable: boolean = false;
  loginInfo: any = 0;
  CCId: any = 0;
  tableEnable: boolean = true;
  public tableRowId: any;
  public tableRowColor: any;
  matstep: boolean = true;
  public generalDataemit: any;
  msgBox: any;
  public selectData: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public dataSource = new MatTableDataSource<any>();
  isSelectedPlatform: any;
  isLoader: boolean = false;
  public EmployeeTableData: any;
  public displayedColumns = [];
  columns = [];
  public activeDeactiveValue: any;
  public getDataValue: any;
  checkData: boolean = false;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public generalValueEmit = new EventEmitter();
  requiredErrors: boolean = false; //FocusOuts//
  bsModalRef?: BsModalRef;

  isInvaliderr: boolean = false;
  public modifierItemsData: any;
  xyz: any;
  card: boolean;
  @Output() public nextSubmitData = new EventEmitter();
  @ViewChild(CreditCardFirstdataComponent)
  firstdataComp: CreditCardFirstdataComponent;
  @ViewChild(CreditCardTsysComponent) tsysComp: CreditCardTsysComponent;
  @ViewChild(CreditCardVantivComponent) vantivComp: CreditCardVantivComponent;
  @ViewChild(SubmitParametersComponent) subparComp: SubmitParametersComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  @ViewChild(CcgatewaySubmitModalComponent)
  submitComp: CcgatewaySubmitModalComponent;

  @ViewChild('stepper') stepper: MatStepper;

  public emvClicked: any;
  public onSave: any;
  public onSaveTsys: any;
  public onSavesubmit: any;
  onSavebit: boolean = false;
  submittedGeneral: any;
  id: any = 0;
  GatewaySelection: any;
  public GenSave = false;
  isSubmitted: boolean = false;
  isValidated = false;
  CreditCardForm: FormGroup;
  SubmitParameterForm: FormGroup;
  CreditCardTsysForm: FormGroup;
  CreditCardStep4Form: FormGroup;
  FirstDataForm: FormGroup;
  CreditCardVantivForm: FormGroup;
  data: any;
  mobNumberPattern = '^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$';
  isValidFormSubmitted = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  filteredList2: any = [];
  public variables: any = [];
  mgs: any = '';
  zipCodeMsg: any = '';
  emvValue: any;
  tranCloud: any;
  AreaStyle_retail: any;
  AreaStyle_restaurant: any;
  autoBatchTime: any;
  errorValue: any = '';
  errorValue1: any;
  errorValue2: any;
  btnstate: boolean = true;
  errorValue3: any;
  errorValueFirst: any;
  errorValueFirst1: any;
  errorValueFirst2: any;
  errorValueFirst3: any;
  @Output() public generalCredit = new EventEmitter();
  @Output() public ccsessData = new EventEmitter();
  stepControl: string;
  dff: any;
  xmlData: any;
  xmlDatDisplay: any;
  jsonDatDisplay: any;
  successval: boolean;
  ccStep4: boolean;
  matopen:boolean=false;
  errorValueName: string;
  matstate($event){
    this.matopen = $event
  }

  constructor(
    private objPayment: PaymentService,
    private toaster: ToasterService,
    private helper: HelperService,
    private formBuilder: FormBuilder,
    private admin: AdminService,
    private dialogser: DialogService,
    private modalService: BsModalService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', 'this.CreditCard');

    this.helper.setValue('pageName', '1stPage');
    //this.buttonDisable = true;
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = 'Credit Card';
    this.employee = 'Credit Card Gateway';
    this.employeeIcon = 'assets/components/images/payment.png';
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
    this.onSavebit = true;
    this.getCreditCardList();
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.CreditCardForm = this.formBuilder.group({
      id: [0],
      businessName: ['', Validators.required],
      phone: ['', Validators.required],
      contactFirstName: ['', Validators.required],
      contactLastName: ['', Validators.required],
      //contactEmail: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]{2,4}$')]],
      // contactEmail: [
      //   '',
      //   [
      //     Validators.required,
      //     Validators.email,
      //     Validators.pattern('^(.+)@(.+).(.+)$'),
      //   ],
      // ],
      contactEmail: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      platformPulldown: ['', Validators.required],
      gatewaySelection: ['', Validators.required],
      swipe: [false],
      emv: [false],
      app: [false],
      olo: [false],
      kiosk: [false],
      tranCloud: [false],
      prePaidGift: [''],
      cardPresent: [''],
      cardNotPresent: [''],
    });

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.CreditCardTsysForm = this.formBuilder.group({
      totalPassword: [null, [Validators.required]],
      hostCapture_POSID: ['', Validators.required],
      autoBatchTime: [null, Validators.required],
      of_Fixed: [false],
      mobile: [false],
      total_Lanes: [null],
      isStoreandForward_EMVonly: [false],
      storeValue: [null],
      wired_Devices: [null],
      wireless_Devices: [null],
    });

    this.CreditCardInput.emit(this.CreditCardForm);
    this.onSelectState();
    this.FirstDataForm = this.formBuilder.group({
      id: [0],
      merchantId: [null],
      totalPassword: [null],
      terminalId: [null],
      tokenIdType: [null],
      groupId: [null],
      catCode_SIC: [null],
      autoAcquire_DID: [false],
      of_Fixed: [false],
      total_Lanes: [null],
      storeValue: [0],
      autoBatchTime: [null, Validators.required],
      mobile: [false],
      isStoreandForward_EMVonly: [false],
      of_Fixed_Details: [null],
    });

    this.SubmitParameterForm = this.formBuilder.group({
      businessName: ['', Validators.required],
      phone: [''],
      contactFirstName: ['', Validators.required],
      contactLastName: ['', Validators.required],
      contactEmail: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      platformPulldown: ['', Validators.required],
      gatewaySelection: ['', Validators.required],
      emv: ['', Validators.required],
      olo: ['', Validators.required],
      tranCloud: ['', Validators.required],
      noofdevices: ['', Validators.required],
      totalPassword: ['', Validators.required],
      tranCloudId: [''],
      prePaidGift: [''],
      cardPresent: [''],
      cardNotPresent: [''],
    });

    this.CreditCardStep4Form = this.formBuilder.group({
      swipeMID: [''],
      swipeTokenKey: [''],
      trancloudMID: [''],
      trancloudTokenKey: [''],
      tranCloudId: [''],
      prepaidGiftMID: [''],
      prepaidGiftTokenKey: [''],
    });

    this.CreditCardVantivForm = this.formBuilder.group({
      merchantId: [null, Validators.required],
      merchantBin: [null, Validators.required],
      terminalId: [null, Validators.required],
      autoBatchTime: [null, Validators.required],
      total_Lanes: [null, Validators.required],
      contactlessCapable: [null, Validators.required],
      verify_SSL: [null, Validators.required],
      merchantCategory: [null, Validators.required],
      duplicateChecking: [null],
      storeValue: [null],
      isStoreandForward_EMVonly: [null],
      of_Fixed: [false, Validators.required],
      mobile: [false, Validators.required],
    });
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
    }
    this.ccStep4 = false;
  }

  omit_special_char(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  @Input()
  onNextPage(data?) {
    this.ccStep4 = false;
    this.btnstate = true;
  }

  getdropData(e) {
    this.selectData = e;
    this.checkData = true;
    this.onNextPage(this.selectData);
  }

  getCreditCardList() {
    const CreditCardList: iSelectCreditCard = {
      id: 0,
      tenantId: this.loginInfo.tenantId,
    };
    this.objPayment.SelectCreditCard(CreditCardList).subscribe((res) => {
      this.dataSource.data = res;
      this.EmployeeTableData = res;
      this.dataSource.data = this.dataSource.data.filter(
        (x) => x.isActive == true
      );
      this.displayedColumns = ['name', 'isActive'];
      this.columns = [
        { columnDef: 'name', header: 'Credit Card Name' },
        { columnDef: 'isActive', header: 'Status' },
      ];
    });


  }

  getTableIdx(event) {
    if (event.id != undefined && event.id != null && event.id != '') {
      this.employeeName = event.name != undefined ? event.name : '';
    }
  }

  EditCreditCardDetail(event) {
    const CreditCardList: iSelectCreditCard = {
      id: event,
      tenantId: this.loginInfo.tenantId,
    };
    this.objPayment.EditCreditCard(CreditCardList).subscribe((res) => {
      if (res != undefined && res != null && res != '') {
        this.id = res[0].id;
        this.getDataValue = res;
        this.isSelectedPlatform = res[0].platformPulldown;
        //console.log(res[0], 'isSelectedPlatform')
        sessionStorage.setItem('CreditCardID', res[0].id);
        //console.log(res[0], "res[0]")
        this.CreditCardForm.patchValue(res[0]);
        this.CreditCardStep4Form.patchValue(res[0]);
        if (res[0].platformPulldown == 'firstdata') {
          this.FirstDataForm.patchValue(res[0]);
        } else if (res[0].platformPulldown == 'tsys') {
          if (
            this.CreditCardTsysForm != undefined &&
            this.CreditCardTsysForm != null
          ) {
            this.CreditCardTsysForm.patchValue(res[0]);
          }
        } else if (res[0].platformPulldown == 'vantiv') {
          if (
            this.CreditCardVantivForm != undefined &&
            this.CreditCardVantivForm != null
          ) {
            this.CreditCardVantivForm.patchValue(res[0]);
          }
        }
        this.isSelectedPlatform = res[0].platformPulldown;
      }
    });
  }

  async getTableId(event) {
    if (
      this.helper.getValue('OnDirty') == true ||
      this.helper.getValue('OnDirty2') == true ||
      this.helper.getValue('OnDirty3') == true
    ) {
      var conf = await this.dialogser.confirm('CreditCard');
      if (conf == 'success') {
        // Navigate Away
        this.errorValueName = '';
        this.errorValue = '';
        this.dialogser.ClearSession();
        this.CreditCardForm.reset();
        this.CreditCardTsysForm.reset();
        this.mgs = '';
        this.zipCodeMsg = '';
        //General Data
        this.helper.setValue('Sessionid_help', event);
        this.tableRowId = event;
        this.tableRowColor = event;
        this.checkData = true;
        this.onSave = true;
        this.EditCreditCardDetail(event);
        this.isInvaliderr = false;
        this.tsysComp.requiredError = false;
        this.flipcomp.rowClicked = event;
        this.dialogser.ModifiedId = event;
      } // Cancel
      else {
        //General Data
        this.CreditCardForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.CreditCardTsysForm.patchValue(
          this.helper.getValue('GetSessionData2')
        );
        this.CreditCardStep4Form.patchValue(
          this.helper.getValue('GetSessionData3')
        );
        this.flipcomp.rowClicked = parseInt(
          this.helper.getValue('Sessionid_help')
        );

        this.ccsessData.emit({
          data: this.CreditCardForm.value,
          event: 'CreditCard',
          val: true,
          itmname: this.CreditCardForm.value.businessName,
        });
      }
    } else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event);
      this.tableRowId = event;
      this.tableRowColor = event;
      this.checkData = true;
      this.onSave = true;
      this.EditCreditCardDetail(event);
      this.isInvaliderr = false;
      this.tsysComp.requiredError = false;
    }
  }

  getTableName(name) {
    this.employeeName = name;
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
  }

  checkValue(event) {
    this.activeDeactiveValue = event;
    let currentTarget = event.e.currentTarget.checked;
    const active: ActivatePayment = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked,
    };
    this.objPayment.ActivateCreditCard(active).subscribe((response) => {
      if (currentTarget) {
        this.toaster.successPopUp(response.message);
      } else {
        this.toaster.successPopUp(response.message);
      }
      this.getCreditCardList();
    });
  }

  async addMenuItem(stepper: MatStepper) {
    this.matstep = true;
    this.ccStep4 = false;
    this.btnstate = true;
    if (
      this.helper.getValue('OnDirty') == false &&
      this.helper.getValue('OnDirty2') == false &&
      this.helper.getValue('OnDirty3') == false
    ) {
      // stepper.previous();
      this.ClearGenData();
      this.errorValueName = '';
      this.errorValue = '';
    } else {
      var conf = await this.dialogser.confirm('CreditCard');
      if (conf == 'success') {
        // Navigate Away
        this.ClearGenData();
        this.errorValueName = '';
        this.errorValue = '';
      } else {
        this.CreditCardForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.CreditCardTsysForm.patchValue(
          this.helper.getValue('GetSessionData2')
        );
        this.CreditCardStep4Form.patchValue(
          this.helper.getValue('GetSessionData3')
        );
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = 'transparent';
    this.mgs = '';
    this.zipCodeMsg = '';
    this.errorValueName = '';
    this.errorValue = '';
    this.employeeName = 'Credit Card';
    this.tableRowColor = 'transparent';
    this.flipcomp.rowClicked = 0;
    this.checkData = false;
    this.onSave = false;
    this.selectData = false;
    this.onSaveTsys = false;
    this.onSavesubmit = false;
    this.submittedGeneral = false;
    this.stepControl = 'CreditCardForm';
    this.requiredError = false;
    this.CreditCardForm.reset();
    this.SubmitParameterForm.reset();
    this.CreditCardStep4Form.reset();
    this.CreditCardTsysForm.reset();
    this.FirstDataForm.reset();
    this.CreditCardVantivForm.reset();
    this.getDataValue[0] = null;
    this.tableRowId = 0;
    this.id = 0;
    this.titleName.emit(this.employeeName);
  }

  getEmv(e) {
    this.emvClicked = e;
  }

  getTsysSaveData(e) {
    this.onSaveTsys = e;
    if (this.onSaveTsys == true) {
      this.onNextPage();
    }
  }

  getSubmitData(e) {
    this.onSavesubmit = e;
    if (this.onSavesubmit == true) {
      this.onNextPage();
    }
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  onSaveGeneral(e) {
    this.onSaveCreditCard(this.id, 'no');
  }

  //  async onSaveGeneral()
  async onSaveCreditCard(id, dataCapSubmit?) {
    if (this.CreditCardTsysForm.invalid && !this.CreditCardForm.invalid) {
      this.openModal();
    }
    this.errorValue = '';
    this.errorValue1 = '';
    this.errorValue2 = '';
    this.errorValue3 = '';
    this.errorValue5 = '';
    this.errorValueFirst = '';
    this.errorValueFirst1 = '';
    this.errorValueFirst2 = '';
    this.errorValueName = '';
    this.errorValueFirst3 = '';
    this.submittedGeneral = true;
    let paymentserv;
    paymentserv = this.CreditCardForm.value;

    const objphone = paymentserv.phone;
    if (objphone != undefined && objphone != '' && objphone != null) {
      let trimphone = objphone.toString().match(/\d/g);
      //const isAllZero1 = trimphone.some(item => item !== "0");
      const isAllZero = trimphone.every((val, i, arr) => val === '0');
      if (parseInt(trimphone.length) != 10) {
        this.toaster.errorPopUp('Phone number must be at least 10 digit');
        return;
      } else if (isAllZero == true) {
        this.toaster.errorPopUp('Invalid Mobile Number...');
        return;
      } else {
        paymentserv.phone = objphone.replace(/[^-()\d ]/g, '');
      }
    }
    if (this.CreditCardTsysForm.value.totalPassword.length > 10) {
      this.errorValue = 'Authentication code should be 1-10 char';
      return;
    }

    if (this.CreditCardForm.invalid) {
      return;
    } else {
      if (
        this.GeneralData.phone.value != undefined &&
        this.GeneralData.phone.value != null &&
        this.GeneralData.phone.value != ''
      ) {
        if (this.GeneralData.phone.value.length <= 1) {
          this.mgs = 'Phone number must be at least 1-10 char';
          return;
        } else {
          this.mgs = '';
        }
      } else {
        this.mgs = '';
      }
    }
    if (
      this.CreditCardForm.controls.zip.value != undefined &&
      this.CreditCardForm.controls.zip.value != null &&
      this.CreditCardForm.controls.zip.value != ''
    ) {
      if (this.CreditCardForm.controls.zip.value.length === 10) {
        if (this.CreditCardForm.controls.zip.value.slice(5, 6) != '-') {
          this.zipCodeMsg = 'Invalid Zip Code';
          return;
        }
      }
      if (this.CreditCardForm.controls.zip.value.length <= 4) {
        this.zipCodeMsg = 'Please enter min 5 digits zipcode';
        return;
      } else {
        this.zipCodeMsg = '';
      }
    } else {
      this.zipCodeMsg = '';
    }

    this.helper.setValue('GatewayVal', paymentserv);

    // console.log(paymentserv,'paymentserv');
    // console.log(paytsys, 'paytsys');
    // console.log(CreditCardStep4, 'CreditCardStep4');

    const ObjSave: iSaveCreditcardGateway = {
      id: this.id,
      configName: '',
      businessName: this.CreditCardForm.value.businessName,
      contactFirstName: this.CreditCardForm.value.contactFirstName,
      contactLastName: this.CreditCardForm.value.contactLastName,
      contactEmail: this.CreditCardForm.value.contactEmail,
      phone: this.CreditCardForm.value.phone,
      address: this.CreditCardForm.value.address,
      city: this.CreditCardForm.value.city,
      state: this.CreditCardForm.value.state,
      zip: this.CreditCardForm.value.zip,
      gatewaySelection: this.CreditCardForm.value.gatewaySelection,
      swipe: this.CreditCardForm.value.swipe,
      emv: this.CreditCardForm.value.emv,
      app: this.CreditCardForm.value.app,
      olo: this.CreditCardForm.value.olo,
      kiosk: this.CreditCardForm.value.kiosk,
      tranCloud: this.CreditCardForm.value.tranCloud,
      platformPulldown: this.CreditCardForm.value.platformPulldown,
      hostCapture_POSID: this.CreditCardTsysForm.value.hostCapture_POSID,
      // mobile:false,

      // storeValue: 0,
      total_Lanes:
        this.isSelectedPlatform == 'tsys'
          ? this.CreditCardTsysForm.value.total_Lanes
          : this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.total_Lanes
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.total_Lanes
              : 0,

      //hostCapture_POSID: this.isSelectedPlatform == 'tsys' ? this.CreditCardTsysForm.value.hostCapture_POSID : "",
      wired_Devices:
        this.isSelectedPlatform == 'tsys'
          ? this.CreditCardTsysForm.value.wired_Devices
          : 0,
      wireless_Devices:
        this.isSelectedPlatform == 'tsys'
          ? this.CreditCardTsysForm.value.wireless_Devices
          : 0,

      swipeMID: this.CreditCardStep4Form.value.swipeMID,
      swipeTokenKey: this.CreditCardStep4Form.value.swipeTokenKey,
      trancloudMID: this.CreditCardStep4Form.value.trancloudMID,
      trancloudTokenKey: this.CreditCardStep4Form.value.trancloudTokenKey,
      min: 0,
      max: 0,
      isActive: true,
      createdBy: this.loginInfo.userId,
      modifiedBy: this.loginInfo.userId,
      tenantId: this.loginInfo.tenantId,
      phoneCode: '',
      tranCloudId: this.CreditCardStep4Form.value.tranCloudId,
      prepaidGiftMID: this.CreditCardStep4Form.value.prepaidGiftMID,
      prepaidGiftTokenKey: this.CreditCardStep4Form.value.prepaidGiftTokenKey,
      cardPresent: this.CreditCardStep4Form.value.cardPresent,
      cardNotPresent: this.CreditCardStep4Form.value.cardNotPresent,

      totalPassword:
        this.isSelectedPlatform == 'tsys'
          ? this.CreditCardTsysForm.value.totalPassword
          : this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.totalPassword
            : '',

      storeValue:
        this.isSelectedPlatform == 'firstdata'
          ? this.FirstDataForm.value.storeValue
          : this.isSelectedPlatform == 'tsys'
            ? 0
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.storeValue
              : 0,

      mobile:
        this.isSelectedPlatform == 'firstdata'
          ? this.FirstDataForm.value.mobile
          : this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.mobile
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.mobile
              : false,

      merchantId:
        this.isSelectedPlatform == 'firstdata'
          ? this.FirstDataForm.value.merchantId
          : this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.merchantId
            : '',

      terminalId:
        this.isSelectedPlatform == 'firstdata'
          ? this.FirstDataForm.value.terminalId
          : this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.terminalId
            : '',

      tokenIdType:
        this.isSelectedPlatform == 'firstdata'
          ? this.FirstDataForm.value.tokenIdType
          : '',

      groupId:
        this.isSelectedPlatform == 'firstdata'
          ? this.FirstDataForm.value.groupId
          : '',

      catCode_SIC:
        this.isSelectedPlatform == 'firstdata'
          ? this.FirstDataForm.value.catCode_SIC
          : '',

      autoBatchTime:
        this.isSelectedPlatform == 'firstdata'
          ? this.FirstDataForm.value.autoBatchTime
          : this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.autoBatchTime
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.autoBatchTime
              : '',

      autoAcquire_DID:
        this.isSelectedPlatform == 'firstdata'
          ? this.FirstDataForm.value.autoAcquire_DID
          : false,

      isStoreandForward_EMVonly:
        this.isSelectedPlatform == 'tsys'
          ? this.CreditCardTsysForm.value.isStoreandForward_EMVonly
          : this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.isStoreandForward_EMVonly
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.isStoreandForward_EMVonly
              : false,

      of_Fixed:
        this.isSelectedPlatform == 'tsys'
          ? false
          : this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.of_Fixed
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.of_Fixed
              : false,
      of_Fixed_Details:
        this.isSelectedPlatform == 'firstdata'
          ? this.FirstDataForm.value.of_Fixed_Details
          : '',
      merchantBin:
        this.isSelectedPlatform == 'vantiv'
          ? this.CreditCardVantivForm.value.merchantBin
          : '',
      merchantCategory:
        this.isSelectedPlatform == 'vantiv'
          ? this.CreditCardVantivForm.value.merchantCategory
          : '',
      duplicateChecking:
        this.isSelectedPlatform == 'vantiv'
          ? this.CreditCardVantivForm.value.duplicateChecking
          : '',
      contactlessCapable:
        this.isSelectedPlatform == 'vantiv'
          ? this.CreditCardVantivForm.value.contactlessCapable
          : '',
      verify_SSL:
        this.isSelectedPlatform == 'vantiv'
          ? this.CreditCardVantivForm.value.verify_SSL
          : '',
      businessType: null,
    };

    if (this.CreditCardForm.controls.platformPulldown.value == 'firstdata') {
      if (this.FirstDataForm.invalid) {
        return;
      }
      if (this.GeneralFirstDataFormData.totalPassword.value.length < 6) {
        if (this.GeneralFirstDataFormData.totalPassword.value.length == 0) {
          this.errorValue = '';
        } else {
          this.errorValue = '6 digits are required';
          return;
        }
      }
      if (this.GeneralFirstDataFormData.tokenIdType.value.length < 6) {
        if (this.GeneralFirstDataFormData.tokenIdType.value.length == 0) {
          this.errorValue1 = '';
        } else {
          this.errorValue1 = '6 digits are required';
          return;
        }
      }
      if (this.GeneralFirstDataFormData.terminalId.value.length < 8) {
        if (this.GeneralFirstDataFormData.terminalId.value.length == 0) {
          this.errorValue2 = '';
        } else {
          this.errorValue2 = '8 digits are required';
          return;
        }
      }
      if (this.GeneralFirstDataFormData.groupId.value.length < 5) {
        if (this.GeneralFirstDataFormData.groupId.value.length == 0) {
          this.errorValue3 = '';
        } else {
          this.errorValue3 = 'Minimum 5 digits are required';
          return;
        }
      }
    }

    if (this.CreditCardForm.controls.platformPulldown.value == 'vantiv') {
      if (this.CreditCardVantivForm.invalid) {
        return;
      }
    }

    if (this.CreditCardForm.controls.platformPulldown.value == 'tsys') {
      if (this.CreditCardTsysForm.value.totalPassword.length < 1) {
        this.errorValue = '';
        return;
      }
      if (this.CreditCardTsysForm.invalid) {
        return;
      }
    }

    this.objPayment.SaveCreditCardGateway(ObjSave).subscribe(async (res) => {
      this.id = res.id;
      this.isSubmitted = false;
      this.submittedGeneral = false;
      this.helper.setValue('Sessionid_help', res.id);
      if (
        this.helper.getValue('Sessionid_help') == undefined ||
        this.helper.getValue('Sessionid_help') == null ||
        this.helper.getValue('Sessionid_help') == ''
      ) {
        this.helper.setValue('Sessionid_help', 0);
      } else {
        this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
        this.flipcomp.rowClicked = parseInt(
          this.helper.getValue('Sessionid_help')
        );
      }

      this.getCreditCardList();
      this.flipcomp.selectTableRowColor(
        res.id,
        res.id,
        this.CreditCardForm.value.businessName
      );
      await this.dialogser.ClearSession();
      if (dataCapSubmit == 'no') {
        this.toaster.successPopUp(res.message);
      }
    });
  }
  async goForward(stepper: MatStepper) {
    stepper.next();
    this.xmlDatDisplay = '';
    this.jsonDatDisplay = '';
    this.DataCapFieldRest();
    this.btnstate = false;
    this.ccStep4 = false;
    this.isLoader = true;
    var ResSwipe = await this.GetDataCapSwipeAPIData();

    //  this.openModalonSubmit(Res.res.xmlData);
    const dataxml = JSON.parse(ResSwipe.res.jsonData);

    if (dataxml.Response.Status == 'Error') {
      this.CreditCardStep4Form.patchValue({
        swipeMID: null,
        swipeTokenKey: null,
      });
      this.xmlDatDisplay =
        `Status: ` +
        dataxml.Response.Status +
        `<br/> Description: ` +
        dataxml.Response.ErrorList.Error.ErrorDescription;
      this.successval = false;
      this.isLoader = false;
      return;
    } else {
      this.CreditCardStep4Form.patchValue({
        swipeMID:
          dataxml.Response.HostedIntegration.MerchantID__Credit__eCommerce,
        swipeTokenKey:
          dataxml.Response.HostedIntegration.TokenKey__Credit__eCommerce,
      });
      this.successval = true;
    }
    // var ResGift = await this.GetDataCapGiftCardAPIData();
    // const Giftxml = JSON.parse(ResGift.res.jsonData);
    // this.CreditCardStep4Form.patchValue({
    //   prepaidGiftMID:
    //     Giftxml.Response.HostedIntegration.MerchantID__PrePaid__InStore,
    //   prepaidGiftTokenKey:
    //     Giftxml.Response.HostedIntegration.TokenKey__PrePaid__InStore,
    // });
    if (this.CreditCardForm.value.tranCloud == false) {
      this.xmlDatDisplay =
        'Congratulations! Datacap account is successfully setup.';
    }

    if (this.CreditCardForm.value.tranCloud == true) {
      var ResTranCloud = await this.GetDataCapTranCloudAPIData();
      const dataxml1 = JSON.parse(ResTranCloud.res.jsonData);
      this.CreditCardStep4Form.patchValue({
        trancloudMID:
          dataxml1.Response.HostedIntegration.MerchantID__Credit__InStore,
        trancloudTokenKey:
          dataxml1.Response.HostedIntegration.TokenKey__Credit__InStore,
      });
      this.xmlDatDisplay =
        'Congratulations! Datacap account is successfully setup.';
    } else {
      this.CreditCardStep4Form.patchValue({
        trancloudMID: null,
        trancloudTokenKey: null,
      });
    }

    this.onSaveCreditCard(this.id, 'yes');
    this.isLoader = false;

  }

  On2ndMoveNext() {
    if (this.CreditCardTsysForm.invalid) {
      this.matstep = true;
      this.requiredError = true;
      return;
    } else {
      this.requiredError = false;
    }
    this.helper.setValue('pageName', '3rdPage');
    this.matstep = false;
    if (this.subparComp != undefined) {
      this.subparComp.emvValue = '';
      if (sessionStorage.getItem('CreditCardID') != 'null') {
        this.EditCreditCardDetail(sessionStorage.getItem('CreditCardID'));
      }
    }
    if (this.CreditCardTsysForm.value.totalPassword.length > 10) {
      this.errorValue = 'Authentication code should be 1-10 char';
    }
    this.ccStep4 = true;
    this.btnstate = false;
  }

  stepperChange(event: StepperSelectionEvent) {
    let stepLabel = event.selectedStep.label;
    if (stepLabel == 'CreditCardStep4Form') {
      this.ccStep4 = false;
      this.btnstate = true;
    }
  }

  On4ndMoveNext() {
    this.ccStep4 = true;
    this.btnstate = false;
  }

  DataCapFieldRest() {
    this.CreditCardStep4Form.patchValue({
      swipeMID: null,
      swipeTokenKey: null,
      trancloudMID: null,
      trancloudTokenKey: null,
      prepaidGiftMID: null,
      prepaidGiftTokenKey: null,
    });
  }
  async On3ndMoveNext() {

  }
  On2of4ndMoveNext() {
    this.btnstate = true;
    this.ccStep4 = false;
    this.helper.setValue('pageName', '2ndPage');
  }
  On1of4ndMoveNext() {
    this.helper.setValue('pageName', '1stPage');
    this.ccStep4 = false;
    this.errorValue = '';
  }

  getCreditCard(e) {
    this.xyz = e;
  }

  errorValue5: any;
  errorValue4: any;

  @Output() public nextfirstStepButton = new EventEmitter();
  requiredError: boolean = false;
  @Output() public isInvalid = new EventEmitter();
  isSave: boolean = false;
  @Output() public selectvalue = new EventEmitter();
  @Output() public selectCreditCardData = new EventEmitter();
  formPatch: any;
  @Output() public emvClick = new EventEmitter();
  @Output() public nextStepButton = new EventEmitter();
  emvdata: boolean = false;
  filterNodata: any;
  @Output() public CreditCardInput = new EventEmitter();

  ngOnDestroy(): void {
    this.helper.setValue(null, 'pageName');
  }

  public set MenuRowItem(tableRowData) {
    this.errorValue = '';
    this.errorValue1 = '';
    this.errorValue2 = '';
    this.errorValue3 = '';
    this.errorValue4 = '';
    this.errorValue5 = '';
    this.errorValueName = '';

    this.errorValueFirst = '';
    this.errorValueFirst1 = '';
    this.errorValueFirst2 = '';
    this.errorValueFirst3 = '';
    if (
      tableRowData != undefined &&
      tableRowData != null &&
      tableRowData != ''
    ) {
      this.formPatch = tableRowData[0];
      this.onFormPatch();
      //this.CreditCardForm.setValue({phone:tableRowData[0].phone});
    }

    if (
      tableRowData != undefined &&
      tableRowData != null &&
      tableRowData != ''
    ) {
      this.SubmitParameterForm.patchValue(tableRowData[0]);
      this.id = tableRowData[0].id;
      this.emvValue = '';
      if (tableRowData[0].swipe == true) {
        this.emvValue = this.emvValue + 'Swipe ';
      }
      if (tableRowData[0].emv == true) {
        this.emvValue = this.emvValue + 'EMV ';
      }
      if (tableRowData[0].app == true) {
        this.emvValue = this.emvValue + 'App ';
      }
      if (tableRowData[0].olo == true) {
        this.emvValue = this.emvValue + 'OLO ';
      }
      if (tableRowData[0].kiosk == true) {
        this.emvValue = this.emvValue + 'Kiosk ';
      }
    }

    if (
      tableRowData != undefined &&
      tableRowData != null &&
      tableRowData != ''
    ) {
      this.CreditCardStep4Form.patchValue(tableRowData[0]);
      this.id = tableRowData[0].id;
    }
  }

  get GeneralData() {
    return this.CreditCardForm.controls;
  }

  get GeneralTsysData() {
    return this.CreditCardTsysForm.controls;
  }

  get GeneralFirstDataFormData() {
    return this.FirstDataForm.controls;
  }

  get GeneralVantivData() {
    return this.CreditCardVantivForm.controls;
  }

  get SubmitparameterData() {
    return this.SubmitParameterForm.controls;
  }
  get CreditCardStep4Data() {
    return this.CreditCardStep4Form.controls;
  }

  onSum() {
    let x = this.GeneralTsysData.wired_Devices.value;
    let y = this.GeneralTsysData.wireless_Devices.value;
    let sum = Number(x) + Number(y);
    this.GeneralTsysData.total_Lanes.setValue(sum);
    // this.GeneralData.total_Lanes = sum;
  }

  checkInput(e, input) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];
    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue) && e.keyCode != 9) {
      e.preventDefault();
      return;
    }

    const hasSelection =
      input.selectionStart !== input.selectionEnd &&
      input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key);
    } else {
      newValue = input.value + keyValue.toString();
    }

    if ((+newValue > 100 || newValue.length > 3) && e.keyCode != 9) {
      e.preventDefault();
    }
  }

  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }

  getAuthCodeValue(e) {
    if (e.target.value.length > 10) {
      this.errorValue = 'Authentication code should be 1-10 char';
      return;
    } else {
      this.errorValue = '';
    }
  }

  getAuthCodeValueFirstData(e) {
    if (e.target.value.length < 6) {
      if (e.target.value.length == 0) {
        this.errorValueFirst = '';
      } else {
        this.errorValueFirst = '6 digits are required';
        return;
      }
    } else {
      this.errorValueFirst = '';
    }
  }

  getAuthCodeValue1(e) {
    if (e.target.value.length < 6) {
      if (e.target.value.length == 0) {
        this.errorValueFirst1 = '';
      } else {
        this.errorValueFirst1 = '6 digits are required';
        return;
      }
    } else {
      this.errorValueFirst1 = '';
    }
  }

  getAuthCodeValue2(e) {
    if (e.target.value.length < 8) {
      if (e.target.value.length == 0) {
        this.errorValueFirst2 = '';
      } else {
        this.errorValueFirst2 = '8 digits are required';
        return;
      }
    } else {
      this.errorValueFirst2 = '';
    }
  }

  getAuthCodeValue3(e) {
    if (e.target.value.length < 13) {
      if (e.target.value.length == 0) {
        this.errorValueFirst3 = '';
      } else if (e.target.value.length < 5) {
        this.errorValueFirst3 = 'Minimum 5 digits are required';
        return;
      } else {
        this.errorValueFirst3 = '';
        // this.errorValue3 = "Maximum 13 digits are required"
        // return;
      }
      // this.errorValue3 = ""
    } else {
      this.errorValueFirst3 = '';
    }
  }
  onKeypressEvent(elem): boolean {
    const charCode = elem.which ? elem.which : elem.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (
      elem.target.value.length < 3 ||
      elem.target.value.length > 3 ||
      elem.target.value.length < 11
    ) {
      if (elem.key === '-') elem.preventDefault();
    }
    // this.GeneralCompanyForm.get("zipCode").patchValue(elem.target.value.toUpperCase());
    if (elem.target.value.length <= 10) {
      if (elem.target.value.length === 5) {
        elem.target.value = `${elem.target.value}-`;
      }
      this.zipCodeMsg = '';
    } else {
      elem.target.value = elem.target.value.slice(0, -1);
      this.zipCodeMsg = '';
    }
    return true;
  }

  //@Input() public buttonState;
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !btnst;
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public addEmployee(): void {
    this.CreditCardForm.reset();
    this.SubmitParameterForm.reset();
    this.CreditCardStep4Form.reset();
    this.CreditCardTsysForm.reset();
    this.FirstDataForm.reset();
    this.CreditCardVantivForm.reset();
    this.errorValue = '';
  }

  change(event: MatButtonToggleChange) {
    this.data = event.value;
    this.CreditCardForm.value.gatewaySelection = event.value;
  }

  onDropDown(e) {
    this.isSelectedPlatform = e;
    //this.selectvalue.emit(e)
  }

  onSelectState() {
    const ObjState: ISelectState = {
      id: 0,
    };
    this.admin.SelectStateMaster(ObjState).subscribe((response) => {
      this.filteredList2 = response;
      this.variables = this.filteredList2.slice();
    });
  }

  onKey(value) {
    this.filteredList2 = this.search(value);
  }

  getSubtier(value) { }

  search(value: string) {
    let filteredList2 = this.variables.filter(
      (unit) => unit.id.indexOf(value.toUpperCase()) > -1
    );
    if (value.length == 1) {
      let filterdata = filteredList2.filter(
        (x) => x.id.substring(0, 1) == value.toUpperCase()
      );
      return [...filterdata];
    } else if (value.length == 2) {
      let filterSecdata = filteredList2.filter(
        (y) => y.id.substring(0, 2) == value.toUpperCase()
      );
      return [...filterSecdata];
    } else {
      this.filterNodata = true;
      return [...filteredList2];
    }
  }

  numberOnly2(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (event.target.value.length <= 14) {
      if (event.target.value.length === 3) {
        event.target.value = `(${event.target.value}) `;
      }
      if (event.target.value.length === 9) {
        event.target.value = `${event.target.value}-`;
      }
      if (event.target.value.length === 11) {
        event.target.value = `${event.target.value}`;
      }
      if (event.target.value.length >= 13) {
        this.mgs = '';
      }
      // if (event.target.value.length <= 12) {
      //   this.mgs = 'Phone number must be at least 10 numbers'
      //   return true
      // } else if(event.target.value.length == 0) {
      //   this.mgs = ''
      // }
      // else  {
      //   this.mgs = ''
      // }
    } else {
      event.target.value = event.target.value.slice(0, -1);
    }
    return true;
  }

  onFormPatch() {
    this.CreditCardForm.patchValue(this.formPatch);
    this.id = this.formPatch.id;
    var platformPulldownVar = this.formPatch.platformPulldown;
    this.selectvalue.emit(platformPulldownVar);

    if (this.formPatch.platformPulldown == 'firstdata') {
      if (this.firstdataComp != undefined && this.firstdataComp != null) {
        if (this.FirstDataForm != undefined && this.FirstDataForm != null) {
          this.FirstDataForm.patchValue(this.formPatch);
        }
      }
    } else if (this.formPatch.platformPulldown == 'tsys') {
      if (this.tsysComp != undefined && this.tsysComp != null) {
        if (
          this.tsysComp.CreditCardTsysForm != undefined &&
          this.tsysComp.CreditCardTsysForm != null
        ) {
          this.tsysComp.CreditCardTsysForm.patchValue(this.formPatch);
        }
      }
    } else if (this.formPatch.platformPulldown == 'vantiv') {
      if (this.vantivComp != undefined && this.vantivComp != null) {
        if (
          this.CreditCardVantivForm != undefined &&
          this.CreditCardVantivForm != null
        ) {
          this.CreditCardVantivForm.patchValue(this.formPatch);
        }
      }
    }
  }

  onEmv(e) {
    if (e.target.checked == true) {
      this.emvdata = true;
    } else {
      this.emvdata = false;
    }
    this.emvClick.emit(this.emvdata);
  }

  getMaxValueBName(e) {
    if (e.target.value.length == 50) {
      this.errorValueName = 'Max-Limit 50 characters reached';
    } else {
      this.errorValueName = '';
    }
  }

  getMaxValueFName(e) {
    if (e.target.value.length == 50) {
      this.errorValue1 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue1 = '';
    }
  }

  getMaxValueLName(e) {
    if (e.target.value.length == 50) {
      this.errorValue2 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue2 = '';
    }
  }

  getMaxValueEmail(e) {
    if (e.target.value.length == 100) {
      this.errorValue3 = 'Max-Limit 100 characters reached';
    } else {
      this.errorValue3 = '';
    }
  }

  getMaxValueCity(e) {
    if (e.target.value.length == 50) {
      this.errorValue4 = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue4 = '';
    }
  }

  CreditCardValid() {
    if (this.CreditCardForm.invalid) {
      this.helper.setValue('IsValidForm', true);
      // this.CreditComp.xyz=true;
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    } else if (
      this.GeneralData.phone.value != undefined &&
      this.GeneralData.phone.value != null &&
      this.GeneralData.phone.value != ''
    ) {
      if (this.GeneralData.phone.value.length <= 12) {
        this.mgs = 'Phone number must be at least 10 digit';
        this.requiredError = true;
        return;
      }
    } else if (
      this.CreditCardForm.controls.zip.value != undefined &&
      this.CreditCardForm.controls.zip.value != null &&
      this.CreditCardForm.controls.zip.value != ''
    ) {
      if (this.CreditCardForm.controls.zip.value.length <= 4) {
        this.zipCodeMsg = 'Please enter min 5 digits zipcode';
        this.requiredError = true;
        return;
      }
    } else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.CreditCardForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ValidateCreditCard() {
    //this.btnstate = false;
    if (this.CreditCardForm.invalid) {
      this.requiredError = true;
    } else {
      this.requiredError = false;
    }
  }

  GetCreditCardDetails(event) {
    //this.ngOnInit();
    const CreditCardList: iSelectCreditCard = {
      id: event,
      tenantId: this.loginInfo.tenantId,
    };
    this.objPayment.EditCreditCard(CreditCardList).subscribe((res) => {
      this.SubmitParameterForm.patchValue(res);
    });
  }

  ngAfterViewInit() {
    this.CreditCardForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.CreditCardTsysForm.patchValue(this.helper.getValue('GetSessionData2'));
    this.CreditCardStep4Form.patchValue(
      this.helper.getValue('GetSessionData3')
    );
    this.ccsessData.emit(this.CreditCardForm.value);
  }

  onchangeGeneral(data) {
    this.BindCCData();
    this.helper.setValue('OnDirty', true);
    this.ccsessData.emit({ data: data, event: 'CreditCard', val: true });
  }

  onchangeTSYS(data) {
    this.BindCCData();
    this.helper.setValue('OnDirty2', true);
    this.ccsessData.emit({ data: data, event: 'CreditCard', val: true });
  }

  onchangeStep(data) {
    this.BindCCData();
    this.helper.setValue('OnDirty3', true);
    this.ccsessData.emit({ data: data, event: 'CreditCard', val: true });
  }

  onchangeFirstData(data) {
    alert('firs');
  }

  onchangeVantiv(data) {
    alert('vant');
  }

  onchangeParam(data) {
    alert('param');
  }

  BindCCData() {
    this.helper.setValue('GetSessionData1', this.CreditCardForm.value);
    this.helper.setValue('GetSessionData2', this.CreditCardTsysForm.value);
    this.helper.setValue('GetSessionData3', this.CreditCardStep4Form.value);
  }

  openModal() {
    this.bsModalRef = this.modalService.show(CcgatewayModalComponent, {
      class: 'modalComp modal-popUp modal-l',
      backdrop: 'static',
      keyboard: true,
    });
    this.bsModalRef.content.messageEvent.subscribe((data) => {
      this.dff = data;
      if (this.dff == true) {
        this.stepper.selectedIndex = 1;
      }
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openModalonSubmit(data) {
    const initialState = {
      list: [data],
    };

    this.bsModalRef = this.modalService.show(CcgatewaySubmitModalComponent, {
      initialState,
      class: 'modalComp modal-popUp modal-l',
      backdrop: 'static',
      keyboard: true,
    });
    this.bsModalRef.content.messageEvent.subscribe((data) => {
      this.dff = data;
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  onDeleteZipCode(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.zipCodeMsg = '';
      }
    }
  }

  zipCodeCheck(event) {
    if (event.target.value.length === 6) {
      if (event.target.value.slice(5, 6) == '-') {
        event.target.value = `${event.target.value.slice(0, 5)}`;
      }
    } else if (
      event.target.value.length > 5 &&
      event.target.value.length < 10
    ) {
      if (event.target.value.slice(5, 6) != '-') {
        event.target.value = `${event.target.value.slice(
          0,
          5
        )}-${event.target.value.slice(6, event.target.value.length)}`;
        this.zipCodeMsg = '';
      }
    } else if (event.target.value.length === 9) {
      event.target.value = `${event.target.value.slice(
        0,
        5
      )}-${event.target.value.slice(6, 9)}`;
    } else if (event.target.value.length === 10) {
      if (event.target.value.slice(5, 6) != '-') {
        this.zipCodeMsg = 'Invalid Zip Code';
      } else {
        this.zipCodeMsg = '';
      }
    }
  }

  onDeletePhone(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.mgs = '';
      }
    }
  }
  phoneNumberCheck(event) {
    if (event.target.value.length == 0) {
      this.mgs = '';
    }
    if (event.target.value.slice(0, 1) != '(') {
      if (event.target.value.length > 7 && event.target.value.length < 12) {
        event.target.value = `(${event.target.value.slice(
          0,
          3
        )}) ${event.target.value.slice(3, 6)}-${event.target.value.slice(
          7,
          11
        )}`;
      } else if (event.target.value.length > 10) {
        this.mgs = 'Invalid Mobile Number...';
        return;
      } else if (
        event.target.value.length < 3 &&
        event.target.value.length > 0
      ) {
        event.target.value = `(${event.target.value.slice(0, 3)})`;

        // if (event.target.value.length < 15) {
        //   this.mgs = 'Invalid mobile number';
        //   return;
        // }
        // this.mgs = ''
      } else if (
        event.target.value.length < 6 &&
        event.target.value.length > 0
      ) {
        event.target.value = `(${event.target.value.slice(
          0,
          3
        )}) ${event.target.value.slice(3, 6)}`;
        // if (event.target.value.length < 15) {
        //   this.mgs = 'Invalid mobile number';
        //   return;
        // }
        // this.mgs = ''
      } else if (
        event.target.value.length >= 6 &&
        event.target.value.length > 0
      ) {
        event.target.value = `(${event.target.value.slice(
          0,
          3
        )}) ${event.target.value.slice(3, 6)}-${event.target.value.slice(
          6,
          event.target.value.length
        )}`;
        if (event.target.value.length < 14) {
          this.mgs = 'Invalid Mobile Number...';
          return;
        }
        this.mgs = '';
      }
    } else {
      if (event.target.value.length < 14) {
        this.mgs = 'Invalid Mobile Number...';
        return;
      }
      this.mgs = '';
    }
  }

  async GetDataCapSwipeAPIData(): Promise<any> {
    return new Promise((resolve, reject) => {
      const CreditCardParameter: iSaveCreditcardGateway = {
        id: this.id,
        configName: '',
        businessName: this.CreditCardForm.value.businessName,
        contactFirstName: this.CreditCardForm.value.contactFirstName,
        contactLastName: this.CreditCardForm.value.contactLastName,
        contactEmail: this.CreditCardForm.value.contactEmail,
        phone: this.CreditCardForm.value.phone,
        address: this.CreditCardForm.value.address,
        city: this.CreditCardForm.value.city,
        state: this.CreditCardForm.value.state,
        zip: this.CreditCardForm.value.zip,
        gatewaySelection: this.CreditCardForm.value.gatewaySelection,
        swipe: this.CreditCardForm.value.swipe,
        emv: this.CreditCardForm.value.emv,
        app: this.CreditCardForm.value.app,
        olo: this.CreditCardForm.value.olo,
        kiosk: this.CreditCardForm.value.kiosk,
        tranCloud: this.CreditCardForm.value.tranCloud,
        platformPulldown: this.CreditCardForm.value.platformPulldown,
        hostCapture_POSID: this.CreditCardTsysForm.value.hostCapture_POSID,
        // mobile:false,

        // storeValue: 0,
        total_Lanes:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.total_Lanes
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.total_Lanes
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.total_Lanes
                : 0,

        //hostCapture_POSID: this.isSelectedPlatform == 'tsys' ? this.CreditCardTsysForm.value.hostCapture_POSID : "",
        wired_Devices:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.wired_Devices
            : 0,
        wireless_Devices:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.wireless_Devices
            : 0,

        swipeMID: this.CreditCardStep4Form.value.swipeMID,
        swipeTokenKey: this.CreditCardStep4Form.value.swipeTokenKey,
        trancloudMID: this.CreditCardStep4Form.value.trancloudMID,
        trancloudTokenKey: this.CreditCardStep4Form.value.trancloudTokenKey,
        min: 0,
        max: 0,
        isActive: true,
        createdBy: this.loginInfo.userId,
        modifiedBy: this.loginInfo.userId,
        tenantId: this.loginInfo.tenantId,
        phoneCode: '',
        tranCloudId: this.CreditCardStep4Form.value.tranCloudId,
        prepaidGiftMID: this.CreditCardStep4Form.value.prepaidGiftMID,
        prepaidGiftTokenKey: this.CreditCardStep4Form.value.prepaidGiftTokenKey,
        cardPresent: this.CreditCardStep4Form.value.cardPresent,
        cardNotPresent: this.CreditCardStep4Form.value.cardNotPresent,

        totalPassword:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.totalPassword
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.totalPassword
              : '',

        storeValue:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.storeValue
            : this.isSelectedPlatform == 'tsys'
              ? 0
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.storeValue
                : 0,

        mobile:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.mobile
            : this.isSelectedPlatform == 'tsys'
              ? this.CreditCardTsysForm.value.mobile
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.mobile
                : false,

        merchantId:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.merchantId
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.merchantId
              : '',

        terminalId:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.terminalId
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.terminalId
              : '',

        tokenIdType:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.tokenIdType
            : '',

        groupId:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.groupId
            : '',

        catCode_SIC:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.catCode_SIC
            : '',

        autoBatchTime:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.autoBatchTime
            : this.isSelectedPlatform == 'tsys'
              ? this.CreditCardTsysForm.value.autoBatchTime
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.autoBatchTime
                : '',

        autoAcquire_DID:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.autoAcquire_DID
            : false,

        isStoreandForward_EMVonly:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.isStoreandForward_EMVonly
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.isStoreandForward_EMVonly
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.isStoreandForward_EMVonly
                : false,

        of_Fixed:
          this.isSelectedPlatform == 'tsys'
            ? false
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.of_Fixed
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.of_Fixed
                : false,
        of_Fixed_Details:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.of_Fixed_Details
            : '',
        merchantBin:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.merchantBin
            : '',
        merchantCategory:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.merchantCategory
            : '',
        duplicateChecking:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.duplicateChecking
            : '',
        contactlessCapable:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.contactlessCapable
            : '',
        verify_SSL:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.verify_SSL
            : '',
        businessType: null,
      };
      this.objPayment
        .DataCapGatewaySwipeTest(CreditCardParameter)
        .subscribe((res) => {
          console.log(res, 'datacapSwipe');

          resolve({ res: res });
        });
    });
  }

  async GetDataCapTranCloudAPIData(): Promise<any> {
    return new Promise((resolve, reject) => {
      const CreditCardParameter: iSaveCreditcardGateway = {
        id: this.id,
        configName: '',
        businessName: this.CreditCardForm.value.businessName,
        contactFirstName: this.CreditCardForm.value.contactFirstName,
        contactLastName: this.CreditCardForm.value.contactLastName,
        contactEmail: this.CreditCardForm.value.contactEmail,
        phone: this.CreditCardForm.value.phone,
        address: this.CreditCardForm.value.address,
        city: this.CreditCardForm.value.city,
        state: this.CreditCardForm.value.state,
        zip: this.CreditCardForm.value.zip,
        gatewaySelection: this.CreditCardForm.value.gatewaySelection,
        swipe: this.CreditCardForm.value.swipe,
        emv: this.CreditCardForm.value.emv,
        app: this.CreditCardForm.value.app,
        olo: this.CreditCardForm.value.olo,
        kiosk: this.CreditCardForm.value.kiosk,
        tranCloud: this.CreditCardForm.value.tranCloud,
        platformPulldown: this.CreditCardForm.value.platformPulldown,
        hostCapture_POSID: this.CreditCardTsysForm.value.hostCapture_POSID,
        // mobile:false,

        // storeValue: 0,
        total_Lanes:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.total_Lanes
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.total_Lanes
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.total_Lanes
                : 0,

        //hostCapture_POSID: this.isSelectedPlatform == 'tsys' ? this.CreditCardTsysForm.value.hostCapture_POSID : "",
        wired_Devices:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.wired_Devices
            : 0,
        wireless_Devices:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.wireless_Devices
            : 0,

        swipeMID: this.CreditCardStep4Form.value.swipeMID,
        swipeTokenKey: this.CreditCardStep4Form.value.swipeTokenKey,
        trancloudMID: this.CreditCardStep4Form.value.trancloudMID,
        trancloudTokenKey: this.CreditCardStep4Form.value.trancloudTokenKey,
        min: 0,
        max: 0,
        isActive: true,
        createdBy: this.loginInfo.userId,
        modifiedBy: this.loginInfo.userId,
        tenantId: this.loginInfo.tenantId,
        phoneCode: '',
        tranCloudId: this.CreditCardStep4Form.value.tranCloudId,
        prepaidGiftMID: this.CreditCardStep4Form.value.prepaidGiftMID,
        prepaidGiftTokenKey: this.CreditCardStep4Form.value.prepaidGiftTokenKey,
        cardPresent: this.CreditCardStep4Form.value.cardPresent,
        cardNotPresent: this.CreditCardStep4Form.value.cardNotPresent,

        totalPassword:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.totalPassword
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.totalPassword
              : '',

        storeValue:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.storeValue
            : this.isSelectedPlatform == 'tsys'
              ? 0
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.storeValue
                : 0,

        mobile:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.mobile
            : this.isSelectedPlatform == 'tsys'
              ? this.CreditCardTsysForm.value.mobile
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.mobile
                : false,

        merchantId:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.merchantId
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.merchantId
              : '',

        terminalId:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.terminalId
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.terminalId
              : '',

        tokenIdType:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.tokenIdType
            : '',

        groupId:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.groupId
            : '',

        catCode_SIC:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.catCode_SIC
            : '',

        autoBatchTime:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.autoBatchTime
            : this.isSelectedPlatform == 'tsys'
              ? this.CreditCardTsysForm.value.autoBatchTime
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.autoBatchTime
                : '',

        autoAcquire_DID:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.autoAcquire_DID
            : false,

        isStoreandForward_EMVonly:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.isStoreandForward_EMVonly
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.isStoreandForward_EMVonly
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.isStoreandForward_EMVonly
                : false,

        of_Fixed:
          this.isSelectedPlatform == 'tsys'
            ? false
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.of_Fixed
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.of_Fixed
                : false,
        of_Fixed_Details:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.of_Fixed_Details
            : '',
        merchantBin:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.merchantBin
            : '',
        merchantCategory:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.merchantCategory
            : '',
        duplicateChecking:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.duplicateChecking
            : '',
        contactlessCapable:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.contactlessCapable
            : '',
        verify_SSL:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.verify_SSL
            : '',
        businessType: null,
      };
      this.objPayment
        .DataCapGatewayTrancloudTest(CreditCardParameter)
        .subscribe((res) => {
          console.log(res, 'datacapTRancloud');

          resolve({ res: res });
        });
    });
  }

  async GetDataCapGiftCardAPIData(): Promise<any> {
    return new Promise((resolve, reject) => {
      const CreditCardParameter: iSaveCreditcardGateway = {
        id: this.id,
        configName: '',
        businessName: this.CreditCardForm.value.businessName,
        contactFirstName: this.CreditCardForm.value.contactFirstName,
        contactLastName: this.CreditCardForm.value.contactLastName,
        contactEmail: this.CreditCardForm.value.contactEmail,
        phone: this.CreditCardForm.value.phone,
        address: this.CreditCardForm.value.address,
        city: this.CreditCardForm.value.city,
        state: this.CreditCardForm.value.state,
        zip: this.CreditCardForm.value.zip,
        gatewaySelection: this.CreditCardForm.value.gatewaySelection,
        swipe: this.CreditCardForm.value.swipe,
        emv: this.CreditCardForm.value.emv,
        app: this.CreditCardForm.value.app,
        olo: this.CreditCardForm.value.olo,
        kiosk: this.CreditCardForm.value.kiosk,
        tranCloud: this.CreditCardForm.value.tranCloud,
        platformPulldown: this.CreditCardForm.value.platformPulldown,
        hostCapture_POSID: this.CreditCardTsysForm.value.hostCapture_POSID,
        // mobile:false,

        // storeValue: 0,
        total_Lanes:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.total_Lanes
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.total_Lanes
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.total_Lanes
                : 0,

        //hostCapture_POSID: this.isSelectedPlatform == 'tsys' ? this.CreditCardTsysForm.value.hostCapture_POSID : "",
        wired_Devices:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.wired_Devices
            : 0,
        wireless_Devices:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.wireless_Devices
            : 0,

        swipeMID: this.CreditCardStep4Form.value.swipeMID,
        swipeTokenKey: this.CreditCardStep4Form.value.swipeTokenKey,
        trancloudMID: this.CreditCardStep4Form.value.trancloudMID,
        trancloudTokenKey: this.CreditCardStep4Form.value.trancloudTokenKey,
        min: 0,
        max: 0,
        isActive: true,
        createdBy: this.loginInfo.userId,
        modifiedBy: this.loginInfo.userId,
        tenantId: this.loginInfo.tenantId,
        phoneCode: '',
        tranCloudId: this.CreditCardStep4Form.value.tranCloudId,
        prepaidGiftMID: this.CreditCardStep4Form.value.prepaidGiftMID,
        prepaidGiftTokenKey: this.CreditCardStep4Form.value.prepaidGiftTokenKey,
        cardPresent: this.CreditCardStep4Form.value.cardPresent,
        cardNotPresent: this.CreditCardStep4Form.value.cardNotPresent,

        totalPassword:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.totalPassword
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.totalPassword
              : '',

        storeValue:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.storeValue
            : this.isSelectedPlatform == 'tsys'
              ? 0
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.storeValue
                : 0,

        mobile:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.mobile
            : this.isSelectedPlatform == 'tsys'
              ? this.CreditCardTsysForm.value.mobile
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.mobile
                : false,

        merchantId:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.merchantId
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.merchantId
              : '',

        terminalId:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.terminalId
            : this.isSelectedPlatform == 'vantiv'
              ? this.CreditCardVantivForm.value.terminalId
              : '',

        tokenIdType:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.tokenIdType
            : '',

        groupId:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.groupId
            : '',

        catCode_SIC:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.catCode_SIC
            : '',

        autoBatchTime:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.autoBatchTime
            : this.isSelectedPlatform == 'tsys'
              ? this.CreditCardTsysForm.value.autoBatchTime
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.autoBatchTime
                : '',

        autoAcquire_DID:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.autoAcquire_DID
            : false,

        isStoreandForward_EMVonly:
          this.isSelectedPlatform == 'tsys'
            ? this.CreditCardTsysForm.value.isStoreandForward_EMVonly
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.isStoreandForward_EMVonly
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.isStoreandForward_EMVonly
                : false,

        of_Fixed:
          this.isSelectedPlatform == 'tsys'
            ? false
            : this.isSelectedPlatform == 'firstdata'
              ? this.FirstDataForm.value.of_Fixed
              : this.isSelectedPlatform == 'vantiv'
                ? this.CreditCardVantivForm.value.of_Fixed
                : false,
        of_Fixed_Details:
          this.isSelectedPlatform == 'firstdata'
            ? this.FirstDataForm.value.of_Fixed_Details
            : '',
        merchantBin:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.merchantBin
            : '',
        merchantCategory:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.merchantCategory
            : '',
        duplicateChecking:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.duplicateChecking
            : '',
        contactlessCapable:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.contactlessCapable
            : '',
        verify_SSL:
          this.isSelectedPlatform == 'vantiv'
            ? this.CreditCardVantivForm.value.verify_SSL
            : '',
        businessType: null,
      };
      this.objPayment
        .DataCapGiftCardTest(CreditCardParameter)
        .subscribe((res) => {
          console.log(res, 'datacapTRancloud');

          resolve({ res: res });
        });
    });
  }
}
