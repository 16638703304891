<div class="bg_image">
  <form>
    <div class="body-sign">
      <div class="center-sign">
        <div class="container">
          <div class="user_card">
            <div class="loginheader">
              <h4 class="text-center"> <img src="assets/components/images/flip.png" class="img-fluid"></h4>

              <div class="card-header border-bottom-0 bg-transparent">
                <ul class="nav nav-tabs justify-content-center" id="pills-tab" role="tablist"></ul>
              </div>
              <div class="card-body pb-1" style="padding-top: 10px">
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-login" role="tabpanel"
                    aria-labelledby="pills-login-tab">
                    <form class="loginForm" [formGroup]="LoginForm">
                      <div class="form-group">
                        <label class="customlable">Your Email</label>
                        <div class="input-group">
                          <input formControlName="email" type="text" placeholder="&#x2709; Example@myemailaddress.com"
                            class="form-control input-lg inputbox_login"
                            [ngClass]="{ 'is-invalid': submittedLogin && LoginData.email.errors }" />
                          <div *ngIf="submittedLogin && LoginData.email.errors" class="invalid-feedback">
                            <div *ngIf="LoginData.email.errors.required">Email is required</div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="customlable">Password</label>
                        <div class="input-group">
                          <input formControlName="password" [type]="fieldTextType ? 'text' : 'password'"
                            placeholder="&#xf023; Password" class="form-control input-lg inputbox_login"
                            [ngClass]="{ 'is-invalid': submittedLogin && LoginData.password.errors }" />
                          <div *ngIf="submittedLogin && LoginData.password.errors" class="invalid-feedback">
                            <div *ngIf="LoginData.password.errors.required">Password is required</div>
                          </div>
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="fa" [ngClass]="{
                                                    'fa-eye-slash': !fieldTextType,
                                                    'fa-eye': fieldTextType
                                                  }" (click)="toggleFieldTextType()"></i>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="radio_lost_wrap">
                        <div class="custom-control custom-checkbox  custom-control-inline">
                          <input type="checkbox" class="custom-control-input" name="customCheck" id="customCheck1">
                          <label class="custom-control-label" for="customCheck1">Remember Me</label>
                        </div>
                        <a [routerLink]="['/']">Forgot Password?</a>
                      </div>


                      <div class="sign-wrap text-center pt-4 ">
                        <button type="submit" class="btn btnLogin" (click)="onLogin();">Sign In</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="privacy_msg">We'll never share your details. See our <a
            href="https://flippos.com/privacy-policy">Privacy Policy</a></p>
      </div>
    </div>
  </form>
</div>