<div class="row top-sec">
  <div class="col-sm-4">
    <div>
      <div class="flip-table" *ngIf="!modifierItemsData">
        <span class="text-box">{{employee}}</span> &nbsp;
        <button class="plus_btn outshadow" (click)="addReasons()"><i class="fa fa-plus"></i></button>
      </div>
      <hr class="linebar" *ngIf="!modifierItemsData">
    </div>
  </div>
  <div class="col-sm-8">    
    <div class="form-group global-form">
      <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global-submit" type="submit"   (click)="onSaveGeneral();">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label>
      </button>
    </div>
  </div>

</div>
<div class="row second-sec">
    <div class="col-sm-4">
      <div>
        <app-fliptable [dataSource]="dataSource" (tableItemId)="getTableIdx($event)" [DataCom]="employee" [dealerItem]="tableRowColor"
        [displayedColumns]="displayedColumns" [columns]="columns" (tableEmpName)="getTableName($event)" 
        (clearDetails)="addReasons()" [employeeItem]="tableRowColor" (breakActive)="ActivateDeactivate($event)"
        [EmployeeTableData]="EmployeeTableData" (btnActive)="btnActiveStatus($event)"></app-fliptable>
      </div>
    </div>
    <div class="col-sm-8 max-accordian">
    <!-- <div class="form-group global-form">
      <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global-submit" type="submit"   (click)="onSaveGeneral();">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label>
      </button>
    </div> -->
      <mat-accordion>
        <mat-expansion-panel *ngFor="let item of ['General']; first as isFirst"
          [expanded]="isFirst" class="outshadow">
          <mat-expansion-panel-header [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
            <mat-panel-title [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}} </mat-panel-title>
          </mat-expansion-panel-header>
  
          <div *ngIf="item == 'General'">
            <app-reasons-general [buttonState]="buttonDisable" [DataIdx]="tableRowId" 
            (generalReason)="getGeneralDetail($event)" 
            [employeeItem]="tableRowColor" [employeeName]="employeeName" (isInvalid)="isInvalidAccordion($event)" 
            (ReasonData_child)="getReasonsessData($event)">
            <!-- (onIconIdemit)="getIcon($event)" -->
          </app-reasons-general>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
    
