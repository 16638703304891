<div class="time-body">
    <div class="loginForm form-switch table_forms discount-page">
        <app-report-filter [auditShow]="auditShow" [dropdown]="dropdown" [discountsItem]="discountsReport" (ReceivedData)="FinalReceivedData($event)"></app-report-filter>
        
        <div class="footer-table Communication_tab mb-2 report-table">
            <mat-tab-group dynamicHeight mat-align-tabs="center" (selectedTabChange)="tabClick($event)">
                <mat-tab label="Summary">
                    <div class="example-small-box mat-elevation-z4 summery-tab" *ngIf="summaryTableShow == true">
                      
                        <app-discount-fliptable-footer [dataSource]="Summary" [displayedColumns]="displayedColumns1"
                        [columns]="columns1" (tableItem)="onChangeRow($event)" (checkItem)="onCheckItem($event)"></app-discount-fliptable-footer>
                      
                    </div>
                    
                    <div *ngIf="employeediscounttableShow == true">
                        <div class="back-img align-self-center">
                            <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(3)"
                                class="img-fluid align-self-center">
                            <p><span class="disc-ft">{{firstTableName}}</span></p>
                        </div>
                        <div class="example-small-box mat-elevation-z4 summery-tab">
                       
                            <app-discount-fliptable-footer [dataSource]="employeediscounttableSource" [displayedColumns]="employeediscounttable"
                                [columns]="employeedisccolumns2" (tableItem)="onShowAuditRow($event)" (checkAudit)="onCheckAudit($event)" [employeediscounttableShow]="employeediscounttableShow"></app-discount-fliptable-footer>
                        </div>
                    </div>

                    <div *ngIf="auditShow == true">
                        <div class="back-img align-self-center">
                        <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(6)"
                            class="img-fluid align-self-center">
                        <p><span class="disc-ft">{{firstTableName}} --> {{auditName}}</span></p>
                    </div>
                                <app-discounts-audit [transactions]="transactions" [totals]="totals" [displayedColumns]="displayedColumns" [netTotal]="netTotal" [subTotal]="subTotal" [discountTotal]="discountTotal"
    this.TipsAmount=this.summartItems[0].TipsAmount;
                        [columns3]="auditcolumns" [dataSource3]="auditdataSource" [displayedColumns3]="auditTable" [auditName]="auditName" [auditEmployee]="auditEmployee" [auditDate]="auditDate" [TipsAmount]="TipsAmount" [auditTime]="auditTime" [thirdTableShow]="auditShow" [CustomerDetails]="CustomerDetails" [auditMove]="auditMove" [Filtertransactions]="Filtertransactions"></app-discounts-audit>                
                    </div>
                </mat-tab>
                <mat-tab label="By Sales Area">
                    <div class="example-large-box mat-elevation-z4" *ngIf="salesTableShow == true">
                    
                        <app-discount-table-collapse [dataSource]="dataSource2" [displayedColumns]="displayedColumns2" [columns]="columns2" 
                        [_alldata]="alldata2" [groupByColumns]="groupByColumns2" (tableItem)="onChangeRow($event)"  (checkItem)="onCheckItem($event)"></app-discount-table-collapse>
                    </div>
                    <div *ngIf="employeediscounttableShow == true">
                        <div class="back-img align-self-center">
                            <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(4)"
                                class="img-fluid align-self-center">
                            <p><span class="disc-ft">{{salesName}} --> {{firstTableName}}</span></p>
                        </div>
                        <div class="example-small-box mat-elevation-z4 summery-tab">
                        

                            <app-discount-fliptable-footer [dataSource]="employeediscounttableSource" [displayedColumns]="employeediscounttable"
                            [columns]="employeedisccolumns2" (tableItem)="onShowAuditRow($event)"   (checkAudit)="onCheckAudit($event)" [employeediscounttableShow]="employeediscounttableShow"></app-discount-fliptable-footer>
                        </div>
                    </div>
                    <div *ngIf="auditShow == true">
                        <div class="back-img align-self-center">
                        <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(7)"
                            class="img-fluid align-self-center">
                        <p><span class="disc-ft">{{salesName}} --> {{firstTableName}} --> {{auditName}}</span></p>
                        </div>
                        <app-discounts-audit [transactions]="transactions" [totals]="totals" [displayedColumns]="displayedColumns" [netTotal]="netTotal" [subTotal]="subTotal" [discountTotal]="discountTotal"
                        [columns3]="auditcolumns" [dataSource3]="auditdataSource" [displayedColumns3]="auditTable" [auditName]="auditName" [auditEmployee]="auditEmployee" [auditDate]="auditDate" [TipsAmount]="TipsAmount" [auditTime]="auditTime" [thirdTableShow]="auditShow" [CustomerDetails]="CustomerDetails"  [auditMove]="auditMove" [Filtertransactions]="Filtertransactions"></app-discounts-audit>                
                    </div>
                </mat-tab>
                <mat-tab label="By Employee">
                    <div class="example-large-box mat-elevation-z4" *ngIf="employeeTableShow == true">
                        <app-discount-table-collapse [dataSource]="dataSource4" [displayedColumns]="displayedColumns4" [columns]="columns4" 
                        [_alldata]="ApprovData" [groupByColumns]="groupByColumns4" (tableItem)="onChangeRow($event)" (checkItem)="onCheckItem($event)"></app-discount-table-collapse>
                    </div>
                    <div *ngIf="employeediscounttableShow == true">
                        <div class="back-img align-self-center">
                            <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(2)"
                                class="img-fluid align-self-center">
                            <p><span class="disc-ft">{{empName}} --> {{firstTableName}}</span></p>
                        </div>
                        <div class="example-large-box mat-elevation-z4">
                        
                            <app-discount-fliptable-footer [dataSource]="employeediscounttableSource" [displayedColumns]="employeediscounttable"
                            [columns]="employeedisccolumns2" (tableItem)="onShowAuditRow($event)"  (checkAudit)="onCheckAudit($event)" [employeediscounttableShow]="employeediscounttableShow"></app-discount-fliptable-footer>
                          
                        </div>
                    </div>
                    <div *ngIf="auditShow == true">
                        <div class="back-img align-self-center">
                        <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(9)"
                            class="img-fluid align-self-center">
                        <p><span class="disc-ft">{{empName}} --> {{firstTableName}} --> {{auditName}}</span></p>
                        </div>
                        <app-discounts-audit [transactions]="transactions" [totals]="totals" [displayedColumns]="displayedColumns" [netTotal]="netTotal" [subTotal]="subTotal" [discountTotal]="discountTotal"
                        [columns3]="auditcolumns" [dataSource3]="auditdataSource" [displayedColumns3]="auditTable" [auditName]="auditName" [auditEmployee]="auditEmployee" [auditDate]="auditDate" [TipsAmount]="TipsAmount" [auditTime]="auditTime" [thirdTableShow]="auditShow" [CustomerDetails]="CustomerDetails"  [auditMove]="auditMove" [Filtertransactions]="Filtertransactions"></app-discounts-audit>                
                    </div>
                </mat-tab>
                <mat-tab label="By Approver">
                    <div class="example-large-box mat-elevation-z4" *ngIf="approverTableShow == true">
                        <app-discount-table-collapse [dataSource]="dataSource3" [displayedColumns]="displayedColumns3" [columns]="columns3" 
                        [_alldata]="EmpData" [groupByColumns]="groupByColumns3" (tableItem)="onChangeRow($event)" (checkItem)="onCheckItem($event)"></app-discount-table-collapse>
                    </div>
                    <div *ngIf="employeediscounttableShow == true">
                        <div class="back-img align-self-center">
                            <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(5)"
                                class="img-fluid align-self-center">
                            <p><span class="disc-ft">{{approveName}} --> {{firstTableName}}</span></p>
                        </div>
                        <div class="example-small-box mat-elevation-z4 summery-tab">
                       
                            <app-discount-fliptable-footer [dataSource]="employeediscounttableSource" [displayedColumns]="employeediscounttable"
                            [columns]="employeedisccolumns2" (tableItem)="onShowAuditRow($event)"  (checkAudit)="onCheckAudit($event)" [employeediscounttableShow]="employeediscounttableShow"></app-discount-fliptable-footer>
                        </div>
                    </div>
                    <div *ngIf="auditShow == true">
                        <div class="back-img align-self-center">
                        <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(8)"
                            class="img-fluid align-self-center">
                        <p><span class="disc-ft">{{approveName}} --> {{firstTableName}} --> {{auditName}}</span></p>
                        </div>
                        <app-discounts-audit [transactions]="transactions" [netTotal]="netTotal" [subTotal]="subTotal" [discountTotal]="discountTotal" [displayedColumns]="displayedColumns"
                        [columns3]="auditcolumns" [dataSource3]="auditdataSource" [displayedColumns3]="auditTable" [auditName]="auditName" [auditEmployee]="auditEmployee" [auditMove]="auditMove" [TipsAmount]="TipsAmount" [auditDate]="auditDate" [auditTime]="auditTime" [thirdTableShow]="auditShow" [CustomerDetails]="CustomerDetails" [Filtertransactions]="Filtertransactions"></app-discounts-audit>                
                    </div>
                </mat-tab>
                <mat-tab label="Sales Area - Employee">
                    <div *ngIf="salesAreaByEmployee==true">
                        <app-flip-table-groupby (tableItem)="onChangeRow($event)" (checkItem)="onCheckItem($event)"></app-flip-table-groupby>
                     </div>
                    <div *ngIf="employeediscounttableShow == true">
                        <div class="back-img align-self-center">
                            <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(10)"
                                class="img-fluid align-self-center">
                            <p><span class="disc-ft">{{salesName}} --> {{empName}} --> {{firstTableName}}</span></p>
                        </div>
                        <div class="example-small-box mat-elevation-z4 summery-tab">
                        
                            <app-discount-fliptable-footer [dataSource]="employeediscounttableSource" [displayedColumns]="employeediscounttable"
                            [columns]="employeedisccolumns2" (tableItem)="onShowAuditRow($event)"  (checkAudit)="onCheckAudit($event)" [employeediscounttableShow]="employeediscounttableShow"></app-discount-fliptable-footer>
                        </div>
                    </div>
                    <div *ngIf="auditShow == true">
                        <div class="back-img align-self-center">
                        <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(11)"
                            class="img-fluid align-self-center">
                        <p><span class="disc-ft">{{salesName}} --> {{empName}} --> {{firstTableName}} --> {{auditName}}</span></p>
                        </div>
                        <app-discounts-audit [transactions]="transactions" [netTotal]="netTotal" [subTotal]="subTotal" [discountTotal]="discountTotal" [displayedColumns]="displayedColumns"
                        [columns3]="auditcolumns" [dataSource3]="auditdataSource" [displayedColumns3]="auditTable" [auditName]="auditName" [auditEmployee]="auditEmployee" [auditMove]="auditMove" [auditDate]="auditDate" [TipsAmount]="TipsAmount" [auditTime]="auditTime" [thirdTableShow]="auditShow" [CustomerDetails]="CustomerDetails" [Filtertransactions]="Filtertransactions"></app-discounts-audit>                
                    </div>
                </mat-tab>
                <mat-tab label="Sales Area - Approver">
                    <div *ngIf ="salesAreaApproverTableShow==true">
                    <app-flip-table-approver (tableItem)="onChangeRow($event)" (checkItem)="onCheckItem($event)"></app-flip-table-approver>
                </div>
                    <div *ngIf="employeediscounttableShow == true">
                        <div class="back-img align-self-center">
                            <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(12)"
                                class="img-fluid align-self-center">
                            <p><span class="disc-ft">{{salesName}} --> {{empName}} --> {{firstTableName}}</span></p>
                        </div>
                        <div class="example-small-box mat-elevation-z4 summery-tab">
                         
                            <app-discount-fliptable-footer [dataSource]="employeediscounttableSource" [displayedColumns]="employeediscounttable"
                            [columns]="employeedisccolumns2" (tableItem)="onShowAuditRow($event)"  (checkAudit)="onCheckAudit($event)" [employeediscounttableShow]="employeediscounttableShow"></app-discount-fliptable-footer>
                        </div>
                    </div>
                    <div *ngIf="auditShow == true">
                        <div class="back-img align-self-center">
                        <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(13)"
                            class="img-fluid align-self-center">
                        <p><span class="disc-ft">{{salesName}} --> {{empName}} --> {{firstTableName}} --> {{auditName}}</span></p>
                        </div>
                        <app-discounts-audit [transactions]="transactions" [netTotal]="netTotal" [subTotal]="subTotal" [discountTotal]="discountTotal" [displayedColumns]="displayedColumns"
                        [columns3]="auditcolumns" [dataSource3]="auditdataSource" [displayedColumns3]="auditTable" [auditName]="auditName" [auditEmployee]="auditEmployee" [auditMove]="auditMove" [auditDate]="auditDate" [TipsAmount]="TipsAmount" [auditTime]="auditTime" [thirdTableShow]="auditShow" [CustomerDetails]="CustomerDetails" [Filtertransactions]="Filtertransactions"></app-discounts-audit>                
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
