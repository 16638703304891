import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingRoutingModule } from './setting-routing.module';
import { CurrencyConfigComponent } from './currency-config/currency-config.component';
import { OrchestrationSyncComponent } from './orchestration-sync/orchestration-sync.component';
import { ErrorMessageFrequencyConfigComponent } from './error-message-frequency-config/error-message-frequency-config.component';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    CurrencyConfigComponent,
    OrchestrationSyncComponent,
    ErrorMessageFrequencyConfigComponent,
  ],
  imports: [
    CommonModule,
    SettingRoutingModule,
    MatExpansionModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports:[CurrencyConfigComponent, OrchestrationSyncComponent, ErrorMessageFrequencyConfigComponent]
})
export class SettingModule { }
