<div class="adjustment_detail">
    <form class="SaleArea_general loginForm" [formGroup]="pricingForm" (change)="onchange(pricingForm.value)">
        <div formArrayName="filters">
            <div *ngFor="let filter of filtersFormArray.controls; let i = index;">
                <div [formGroupName]="i" class="row no-gutters event-pricing-sec">
                    <div class="col-sm-10 offset-sm-1 col-10 outshadow multiPricing_wrap ">
                        <div class="row no-gutters">
                            <div class="col-sm-3 event-pricing-select col-6">
                                <div class="form-group">
                                    <label>Event Type</label>
                                    <!-- <select class="form-control" data-role="select-dropdown" type="text" (selectionChange)="selectedAPIChanged(i)" placeholder=""
                                        #eventField formControlName="apiType">
                                        <option *ngFor="let apiType of eventType" [value]="apiType.typeName">
                                            {{ apiType.typeName }}
                                        </option>
                                    </select> -->

                                    <mat-select (openedChange)="matstate($event)" type="text" (selectionChange)="selectedAPIChanged(i)" placeholder="" #eventField formControlName="apiType" class="form-control">
                                        <mat-option *ngFor="let apiType of eventType" [value]="apiType.typeName">
                                            {{ apiType.typeName }}
                                        </mat-option>
                                    </mat-select>

                                </div>
                            </div>
                            <div *ngIf="filter.get('apiType').value == 'Dollar off' || filter.get('apiType').value == 'Percentage off'
                                || filter.get('apiType').value == 'Fixed Price'" class="col-sm-3 col-6">
                                <div class="form-group">
                                    <label>{{eventField.value}}</label>
                                    <input type="text" formControlName="offValue" [placeholder]="eventField.value" class="form-control">
                                </div>
                            </div>

                            <div *ngIf="filter.get('apiType').value == 'Round Up'" class="col-sm-3 col-6">
                                <div class="form-group">
                                    <label>{{eventField.value}}</label>
                                    <mat-form-field appearance="fill">
                                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="offValue" (selectionChange)="onchange(pricingForm.value)">
                                            <mat-option *ngFor="let item of roundUp" [value]="item.typeId">
                                                {{ item.typeName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="filter.get('apiType').value == 'Round Down'" class="col-sm-3 col-6">
                                <div class="form-group">
                                    <label>{{eventField.value}}</label>
                                    <mat-form-field appearance="fill">
                                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="offValue" (selectionChange)="onchange(pricingForm.value)">
                                            <mat-option *ngFor="let item of roundDown" [value]="item.typeId">
                                                {{ item.typeName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-sm-3 col-6">
                                <div class="form-group">
                                    <label>Applies To</label>
                                    <!-- <mat-form-field appearance="fill" [ngClass]="{ 'no-pointers':getFilterGroupAtIndex(i).controls['apiType'].value == null || getFilterGroupAtIndex(i).controls['apiType'].value == ''}">
                                        <mat-select type="text" class="form-control" formControlName="appliesTo"
                                            #appliesField placeholder="Applies To"
                                            (selectionChange)="onChangeApplies(i,filter.get('appliesTo').value)">

                                            <mat-option *ngFor="let x of appliesTo" [value]="x.typeName">
                                                {{x.typeName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field> -->
                                    <div appearance="fill" [ngClass]="{ 'no-pointers':getFilterGroupAtIndex(i).controls['apiType'].value == null || getFilterGroupAtIndex(i).controls['apiType'].value == ''}">
                                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="appliesTo" #appliesField placeholder="Applies To" (selectionChange)="onChangeApplies(i,filter.get('appliesTo').value)">

                                            <mat-option *ngFor="let x of appliesTo" [value]="x.typeName">
                                                {{x.typeName}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3 col-6">
                                <div *ngIf="filter.get('appliesTo').value == 'Menu Page'">
                                    <label>{{appliesField.value}}</label>
                                    <ng-multiselect-dropdown [placeholder]="'Select Page'" [settings]="dropdownSettings" [data]="multiPageFilters" (onSelect)="onItemSelect($event)" [allowClear]="true" (onDeSelect)="onItemDeSelect(i,$event)" (onSelectAll)="onSelectAll($event)" formControlName="multiValuePage"
                                        class="specific-items">
                                    </ng-multiselect-dropdown>

                                </div>
                                <div *ngIf="filter.get('appliesTo').value == 'Menu Group'">
                                    <label>{{appliesField.value}}</label>
                                    <ng-multiselect-dropdown [placeholder]="'Select Group'" [allowClear]="true" [settings]="dropdownSettings" [data]="multiGroupFilters" (onDeSelect)="onItemDeSelect(i,$event)" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" formControlName="multiValueGroup"
                                        class="specific-items">
                                    </ng-multiselect-dropdown>
                                </div>
                                <div *ngIf="filter.get('appliesTo').value == 'Tags'">
                                    <label>{{appliesField.value}}</label>
                                    <ng-multiselect-dropdown [placeholder]="'Select Page'" [settings]="dropdownSettings" [data]="multiTagsFilters" (onSelect)="onItemSelect($event)" [allowClear]="true" (onDeSelect)="onItemDeSelect(i,$event)" (onSelectAll)="onSelectAll($event)" formControlName="multiValueTag"
                                        class="specific-items">
                                    </ng-multiselect-dropdown>
                                </div>
                                <div *ngIf="filter.get('appliesTo').value == 'Specific Items'">
                                    <label>{{appliesField.value}}</label>

                                    <ng-multiselect-dropdown [placeholder]="'Select Items'" class="specific-items" [allowClear]="true" [settings]="ItemSettings" [data]="multiSpecificItemsFilters" (onDeSelect)="onItemDeSelect(i,$event)" (onSelect)="onSpecificItemSelect($event,i)" (onSelectAll)="onSelectAll($event)"
                                        formControlName="multiSpecificItem" (onFilterChange)="onFilterChange($event)">
                                        {{multiSpecificItem}}
                                    </ng-multiselect-dropdown>
                                    <div [ngClass]="{'outshadow': pricingForm.get('filters').controls[i].value.multiSpecificItem != undefined , 'builder_wrap': pricingForm.get('filters').controls[i].value.multiSpecificItem != undefined} ">
                                        <div class="row no-gutters" *ngFor="let v of pricingForm.get('filters').controls[i].controls.multiSpecificItem.value; let j=index;">
                                            <div class="col-sm-11">
                                                {{v.value}}
                                            </div>
                                            <div class="col-sm-1">
                                                <span>
                                                    <i class="fa fa-remove" aria-hidden="true"
                                                        (click)="removeFilterFromspecificItem(j)"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1 col-1 align-self-center">
                        <span class="minus_wrap inshadow">
                            <i class="fa fa-remove" aria-hidden="true"
                                (click)="removeFilterFromFiltersFormArray(i)"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <button class="plus_btn inshadow mr-2" (click)="addFilterToFiltersFormArray()"><i
                class="fa fa-plus"></i></button>
        <div class="row">
            <div class="col-sm-12 text-right">
                <div class="form-group">
                    <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="savePricingBuilder()" [disabled]="buttonDisable ? true : null">
                        <img src="assets/components/images/save.png" alt="employee" class="">
                        <label class="label_config">Save</label>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
