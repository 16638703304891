import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { HttpClient } from '@angular/common/http';
import { iFrontEndMsg, iDeviceTablet } from '../interfaces/iWebSocket';
import { from } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperService } from '../helper/helper.service';
import { DeviceService } from './devices.service';
import { ToasterService } from '../helper/toaster.service';
import { SaveTabletSnapShot } from '../interfaces/idevices';

@Injectable({
  providedIn: 'root'
})
export class WebSocketdeviceService {

  webSocketdata: any;
  private hubConnection: HubConnection;
  mydate: any;
  loginInfo: any;
  public connectionId: string;

  public deviceList: iDeviceTablet[] = [];
  public rawData: iFrontEndMsg[] = [];

  private connectionUrl = 'http://204.236.184.97/Handshake/FlipSocketRequest';
  private apiUrl = 'http://204.236.184.97/Handshake/FlipSocketRequest';


  constructor(private http: HttpClient, private Sanitizer: DomSanitizer,
    private helper: HelperService, private deviceService: DeviceService, private toaster: ToasterService) { }

  public connect = () => {
    this.startConnection();
    this.addListeners();
  }
  private getConnection(): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(this.connectionUrl)
      // .withHubProtocol(new MessagePackHubProtocol())
      //  .withAutomaticReconnect([2])
      //   .configureLogging(LogLevel.Trace)
      .build();
  }

  private startConnection() {
    this.hubConnection = this.getConnection();

    this.hubConnection.start()
      .then(() => console.log('connection started'))
      .then(() => this.getConnectionId())

      .catch((err) => console.log('error while establishing signalr connection: ' + err))
  }

  private addListeners() {
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.hubConnection.on("messageReceivedFromApi", (data: iFrontEndMsg) => {

      if (data.entityType == 'TabletScreenshot' && this.loginInfo.tenantId == data.tenantId) {

        console.log("Devices message received from API Controller")
        this.deviceList.push(this.buildTimeClockdDetails(data));
      }
      if (data.entityType == 'requestScreenshot') {
        console.log("Devices requested message received from API Controller")
      }
    })
    this.hubConnection.on("MessageReceivedFromFLipHub", (data: iFrontEndMsg) => {
      if (data.entityType == 'TabletScreenshot' && this.loginInfo.tenantId == data.tenantId) {
        console.log("Devices message received from Hub")

        this.deviceList.push(this.buildTimeClockdDetails(data));
      }
      if (data.entityType == 'requestScreenshot') {
        console.log("Devices requested message received from Hub")
      }
    })
    this.hubConnection.on("newUserConnected", _ => {
      this.deviceList = [];
      console.log("new user connected")
    })
  }


  public getConnectionId = () => {
    this.hubConnection.invoke('getconnectionid').then(
      (data) => {
        console.log(data, 'ConnectionID');
        this.connectionId = data;
      }
    );
  }
  public sendMessageToHub(rawData) {
    var promise = this.hubConnection.invoke("BroadcastAsync", rawData)
      .then(() => { console.log('message sent successfully to hub'); })
      .catch((err) => console.log('error while sending a message to hub: ' + err));

    return from(promise);
  }

  private buildTimeClockdDetails(EntityVal: iFrontEndMsg): iDeviceTablet {
    // for(let i=0; i< this.webSocketdata.length; i++ ){
    this.webSocketdata = JSON.parse(EntityVal.attributes)

    // console.log(atob(this.webSocketdata.clockInImage),"Images")
    //  console.log(this.webSocketdata.tabletImage,"websocketDevice");
    if (EntityVal.entityType == 'TabletScreenshot') {
      //   this.SaveTabletScreens(this.webSocketdata.tabletId,this.webSocketdata.screenshotData,EntityVal.timestamp);

      return {
        frameId: EntityVal.frameId,
        deviceImage: this.Sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + this.webSocketdata.screenshotData),
        DeviceName: this.webSocketdata.tabletId,
        connectionId: this.connectionId,
        captureTimestamp: EntityVal.timestamp
        // captureTimestamp: new Date().toLocaleDateString() + ' '+ new Date().toLocaleTimeString()

      };
    }
    else {
      return null;
    }

  }


  private SaveTabletScreens(TabletId, TabletImg, capTime) {

    const tabletIddata: SaveTabletSnapShot = {
      id: 0,
      tableId: TabletId,
      screenshot: TabletImg,
      snapshotTime: capTime,
      isActive: true,
      createdBy: this.loginInfo.userId,
      modifiedBy: this.loginInfo.userId

    }
    this.deviceService.SaveTabletSnapshotDetails(tabletIddata).subscribe(
      (response) => {
        // this.id = response.id;

        // this.toaster.success(response.message);

      })

  }

}