import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WebSocketdeviceService } from 'src/app/services/web-socketdevice.service';
import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GetDevice } from 'src/app/interfaces/idevices';
import { HelperService } from 'src/app/helper/helper.service';
import { DeviceService } from 'src/app/services/devices.service';
import { iFrontEndMsg } from 'src/app/interfaces/iWebSocket';
import { HttpClient } from '@angular/common/http';
import { AdminService } from 'src/app/services/admin.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-tablet-logistic',
  templateUrl: './tablet-logistic.component.html',
  styleUrls: ['./tablet-logistic.component.css']
})

export class TabletLogisticComponent implements OnInit {
  tabletLogisticForm: FormGroup
  loginInfo: any;
  screenShotImage: any;
  rowClicked: any;
  rowClickedImg: any;
  imgClicked: boolean;
  imageData: any;
  tableid: any;
  tabletdetails: any;
  deviceImage: any;

  @Output() public tablogData_child = new EventEmitter();
  constructor(public ObjService: WebSocketdeviceService, private sanitizer: DomSanitizer,
    private helper: HelperService, private objtablet: DeviceService, private objAdmin: AdminService) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData2', null);
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.ObjService.connect();
    this.imgClicked = false;
    this.gettabletData()
  }

  @Input()
  public set DataIdx(tableRowId) {
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.tableid = tableRowId;
    }
  }

  onChangeImage(e) {
    this.rowClickedImg = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + e.Screenshot)
    this.rowClicked = e.id
    this.imgClicked = true
  }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  onRefresh(e) {
    // this.ObjService.deviceList = [];
    this.gettabletData()
    this.imgClicked = false
  }
  onViewScreen(e) {
    const fondEndcls: iFrontEndMsg = {
      entityType: "requestScreenshot",
      frameId: "12588",
      tenantId: this.loginInfo.tenantId.toString(),
      attributes: "{\n\"tabletId\": \"" + this.tableid.id + "\",\n\"tabletname\":\"" + this.tableid.name + "\"\n}",
      timestamp: Date,
      connectionId: ''

    }
    this.ObjService.sendMessageToHub(fondEndcls).subscribe({
      // next: _ => this.entityType = '',
      error: (err) => console.error(err)
    });
  }
  gettabletData() {
    const getTablet: GetDevice = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.objtablet.selectTablet(getTablet).subscribe(res => {
      this.tabletdetails = res
      //this.deviceImage = this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64,"+this.tabletdetails.Screenshot)

      //  const environment = {
      //   production: false,
      //   baseUrl: 'http://204.236.184.97/'
      // };         

    })
  }

  ngAfterViewInit() {
    this.tablogData_child.emit(this.tabletLogisticForm.value)
    this.tabletLogisticForm.patchValue(this.helper.getValue('GetSessionData2'));
  }

  onchange(data) {
    this.helper.setValue('GetSessionData2', data);
    this.helper.setValue('OnDirty2', true);
    this.tablogData_child.emit({ data: data, event: "tablet", val: true });
  }
}