import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { TimeService } from 'src/app/services/time.service';
import {
  GetEmployeeClockDetails,
  IshiftValue,
  iGetNonSalEmployee,
} from 'src/app/interfaces/itime';
import { ToasterService } from 'src/app/helper/toaster.service';
import {
  IGetBreak,
  GetEmployee,
  IGetRoleID,
} from 'src/app/interfaces/iemployee';
import { BreakService } from 'src/app/services/break.service';
import { HelperService } from 'src/app/helper/helper.service';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { EnvService } from 'src/app/env.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-time-edit-model',
  templateUrl: './time-edit-model.component.html',
  styleUrls: ['./time-edit-model.component.css'],
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TimeEditModelComponent implements OnInit {
  TimeEditDetailsForm: FormGroup;

  submittedGeneral = false;
  timeAddPunchPosition: any;
  getDate: any;
  public addModalTable: boolean;
  timeEditModal: boolean = true;
  punchInOut: boolean;
  timeEditAccordion: boolean; //= true;
  employeePositionHide: boolean; //= false;
  time: Date = new Date();
  eodTime = new Date();
  isMeridian: boolean = true;
  mytime: Date = new Date();
  showSpinners = false;
  isDateVisible: boolean = true;
  valid = true;
  minTime: Date = new Date();
  maxTime: Date = new Date();
  rolePosition: any;
  firstName: any;
  LastName: any;
  public getAllValues: any;
  ClockList: any;
  WageList: any;
  BreakList: any;
  EmpByJobID: any;
  EmpRateID: any;
  EmpJobName: any = [];
  positionArrayList: any = [];
  jobPositionarray: any = [];
  punchInImage: any;
  punchOutImage: any;
  // jobRateArray: any = [];
  JobPositionList: any;
  getEmployeeDetails: any;
  @Input() empTimeID: any;
  @Input() empTimeJobID: any;
  @Input() jobModelID: any;
  PrimaryId: any;
  minDate: any;

  loginInfo: any;
  @Output() public editModelItem = new EventEmitter();
  @Output() public timemodelEvent = new EventEmitter();
  @Output() public timeeditData_child = new EventEmitter();
  @Input() public buttonDis;
  @Input() public SaveStatus;
  // @Input() public isPressAdd;
  tipsVal: string;
  dateMgs: string;
  dateMgsHire: string;
  constructor(
    private formBuilder: FormBuilder,
    private empService: EmployeeService,
    private BreakService: BreakService,
    private timeEditDetails: TimeService,
    private timeEditRole: AdminService,
    private toaster: ToasterService,
    private helper: HelperService,
    private env: EnvService
  ) {
    this.minTime.setHours(0);
    this.minTime.setMinutes(0);
    this.maxTime.setHours(12);
    this.maxTime.setMinutes(59);
  }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    //console.log(this.SaveStatus, 'SaveStatus')
    this.TimeEditDetailsForm = this.formBuilder.group({
      employeeId: [null, Validators.required],
      punchIn: [moment(new Date()), Validators.required],
      punchOut: [moment(new Date()), Validators.required],
      jobPositionID: [null, Validators.required],
      timeIn: [null, Validators.required],
      timeOut: [null, Validators.required],
      typeId: ['S02', Validators.required],
      breaktypeID: [0],
      wageid: ['N06'],
      tips: [null],
    });
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.onChangeRolePosition();
    this.onChangeEmployeePosition();
    this.GetClockListType();
    this.GetBreakList();
    this.minDate = new Date(1900, 0, 1);
  }
  @Output() matopen: EventEmitter<any> = new EventEmitter()
  matstate($event) {
    this.matopen.emit($event)
  }
  get GeneralDealerData() {
    return this.TimeEditDetailsForm.controls;
  }

  @Input()
  public set dataTimeModal(timeEditModal) {
    this.timeEditModal = timeEditModal;
    this.employeePositionHide = true;
    this.hideModal();
  }

  @Input()
  public set dataTimeAccordion(timeEditAccordion) {
    this.timeEditAccordion = true;
    this.timeEditAccordion = timeEditAccordion;
    this.punchInOut = true;
  }

  @Input()
  set clickRowIndex(empTimeID) {
    this.empTimeID = empTimeID;
    if (
      this.empTimeID != undefined &&
      this.empTimeID != null &&
      this.empTimeID != ''
    ) {
      this.getEmployee(this.empTimeID);
    }
  }

  @Input()
  set clickRowUserIndex(empTimeJobID) {
    this.empTimeJobID = empTimeJobID;
    if (
      this.empTimeJobID != undefined &&
      this.empTimeJobID != null &&
      this.empTimeJobID != ''
    ) {
      this.getEmployeeJob(this.empTimeJobID);
    }
  }

  @Input()
  public set jobModelIndex(id) {
    this.jobModelID = id;
    if (this.jobModelID != undefined) {
      this.getJobByEmp(this.jobModelID);
    }
  }

  GetClockListType() {
    const time: IshiftValue = {
      parentCode: 'T01',
    };
    const wage: IshiftValue = {
      parentCode: 'W04',
    };
    this.timeEditDetails.GetClockListType(time).subscribe((response) => {
      this.ClockList = response;
    });
    this.timeEditDetails.GetClockListType(wage).subscribe((response) => {
      this.WageList = response;
    });
  }

  GetBreakList() {
    const employee: IGetBreak = {
      breakid: 0,
      tenantid: this.loginInfo.tenantId,
    };
    this.BreakService.GetBreakList(employee).subscribe((res) => {
      this.BreakList = res;
    });
  }

  onChangeEmployeePosition() {
    const tenantIDList: iGetNonSalEmployee = {
      tenantId: this.loginInfo.tenantId,
    };
    this.timeEditDetails
      .GetAllEmployeeList(tenantIDList)
      .subscribe((response) => {
        this.EmpJobName = response;
      });
  }

  hideModal() {
    this.timeEditModal = !this.timeEditModal;
    this.timemodelEvent.emit(this.timeEditModal);
  }

  isValid(event: boolean): void {
    this.valid = event;
  }

  onChangeRolePosition() {
    const roleList: IGetRoleID = {
      id: 0,
      tenantId: this.loginInfo.tenantId,
    };
    this.timeEditRole.GetAllRoles(roleList).subscribe((response) => {
      this.rolePosition = response;
      this.JobPositionList = this.rolePosition;
    });
  }

  getEmployee(empid) {
    this.JobPositionList = [];
    const employee: GetEmployeeClockDetails = {
      timeClockId: empid,
    };
    this.timeEditDetails.GetClockDetails(employee).subscribe((response) => {
      this.getEmployeeDetails = response;

      this.TimeEditDetailsForm.patchValue(this.getEmployeeDetails[0]);
      this.timeEditModal = true;

      this.helper.setValue('PrimaryId', this.getEmployeeDetails[0].timeClockId);
      this.TimeEditDetailsForm.patchValue({
        punchIn:
          response[0].punchIn == null
            ? ''
            : moment(response[0].punchIn).format('YYYY-MM-DD'),
      });
      this.TimeEditDetailsForm.patchValue({
        punchOut:
          response[0].punchOut == null
            ? ''
            : moment(response[0].punchOut).format('YYYY-MM-DD'),
      });

      this.PrimaryId = this.getEmployeeDetails[0].timeClockId;
      this.firstName = this.getEmployeeDetails[0].employeeFirstName;
      this.LastName = this.getEmployeeDetails[0].employeeLastName;

      this.punchInImage =
        this.getEmployeeDetails[0].punchInImage == null
          ? 'assets/components/images/picture-upload-2-x.png'
          : this.getEmployeeDetails[0].punchInImage == ''
            ? 'assets/components/images/picture-upload-2-x.png'
            : this.env.awsS3_environment +
            'TimePunch/' +
            this.getEmployeeDetails[0].punchInImage;

      this.punchOutImage =
        this.getEmployeeDetails[0].punchOutImage == null
          ? 'assets/components/images/picture-upload-2-x.png'
          : this.getEmployeeDetails[0].punchOutImage == ''
            ? 'assets/components/images/picture-upload-2-x.png'
            : this.env.awsS3_environment +
            'TimePunch/' +
            this.getEmployeeDetails[0].punchOutImage;


      this.getDate = this.getEmployeeDetails[0].punchIn;
      this.TimeEditDetailsForm.patchValue({
        employeeHireDate:
          response[0].employeeHireDate == null
            ? ''
            : moment(response[0].employeeHireDate).format('YYYY-MM-DD'),
      });

      this.helper.setValue('TimeFormValue', this.TimeEditDetailsForm.value);
      this.helper.setValue('IsSave', false);
    });
    this.timemodelEvent.emit(this.timeEditModal);

  }

  public onSaveTimeEdit() {
    if (this.PrimaryId > 0) {
      this.TimeEditDetailsForm.controls['employeeId'].clearValidators();
      this.TimeEditDetailsForm.controls['employeeId'].updateValueAndValidity();
    } else {
      this.TimeEditDetailsForm.addControl(
        'employeeId',
        new FormControl(null, Validators.required)
      );
    }
    this.submittedGeneral = true;
    if (this.TimeEditDetailsForm.invalid) {
      return;
    }

    let meridianValIn = this.TimeEditDetailsForm.value.timeIn.substring(
      this.TimeEditDetailsForm.value.timeIn.length - 2,
      this.TimeEditDetailsForm.value.timeIn.length
    );

    let meridianValOut = this.TimeEditDetailsForm.value.timeOut.substring(
      this.TimeEditDetailsForm.value.timeOut.length - 2,
      this.TimeEditDetailsForm.value.timeOut.length
    );

    if (meridianValIn === 'PM' && meridianValOut === 'AM') {
      this.toaster.errorPopUp('Error! Log Out time is ahead of Log In time');
      return;
    }

    if (meridianValIn === meridianValOut) {
      let primeValin = this.TimeEditDetailsForm.value.timeIn.substring(
        0,
        this.TimeEditDetailsForm.value.timeIn.indexOf(':')
      );
      let primeValout = this.TimeEditDetailsForm.value.timeOut.substring(
        0,
        this.TimeEditDetailsForm.value.timeOut.indexOf(':')
      );


      if (Number(primeValin) > Number(primeValout)) {
        this.toaster.errorPopUp('Error! Log Out time is ahead of Log In time');
        return;
      }

      if (Number(primeValin) == Number(primeValout)) {
        let minValin = this.TimeEditDetailsForm.value.timeIn.substring(
          this.TimeEditDetailsForm.value.timeIn.indexOf(':') + 1,
          this.TimeEditDetailsForm.value.timeIn.length - 2
        );
        let minValout = this.TimeEditDetailsForm.value.timeOut.substring(
          this.TimeEditDetailsForm.value.timeOut.indexOf(':') + 1,
          this.TimeEditDetailsForm.value.timeOut.length - 2
        );
        if (Number(minValin) > Number(minValout)) {
          this.toaster.errorPopUp(
            'Error! Log Out time is ahead of Log In time'
          );
          return;
        }
      }
    }

    const employee = this.TimeEditDetailsForm.value;

    employee.punchIn = moment(
      new Date(this.GeneralDealerData.punchIn.value),
      'DD-MM-YYYY'
    ).format('YYYY-MM-DD');
    employee.punchOut = moment(
      new Date(this.GeneralDealerData.punchOut.value),
      'DD-MM-YYYY'
    ).format('YYYY-MM-DD');

    let date1 = new Date(
      employee.punchIn + ' ' + this.GeneralDealerData.timeIn.value
    );
    let date2 = new Date(
      employee.punchOut + ' ' + this.GeneralDealerData.timeOut.value
    );
    let differenceInHours = 0;
    differenceInHours = Math.abs(
      (date1.getTime() - date2.getTime()) / 1000 / 60 / 60
    );

    this.TimeEditDetailsForm.value.employeeId =
      this.TimeEditDetailsForm.value.employeeId == ''
        ? 0
        : this.TimeEditDetailsForm.value.employeeId;
    (employee.timeClockId = this.PrimaryId > 0 ? this.PrimaryId : 0),
      (employee.userId = this.TimeEditDetailsForm.value.employeeId),
      (employee.tenantId = this.loginInfo.tenantId),
      (employee.punchInImage = null),
      (employee.punchOutImage = null),
      (employee.roleId = this.GeneralDealerData.jobPositionID.value),
      (employee.tips =
        this.TimeEditDetailsForm.value.tips == ''
          ? null
          : Number(this.TimeEditDetailsForm.value.tips)),

      this.timeEditDetails
        .SaveTimeClockDetails(employee)
        .subscribe((response) => {
          this.getAllValues = response;
          this.toaster.successPopUp(response.message);
          this.helper.setValue('OnDirty', false);
          this.editModelItem.emit({
            id: this.getAllValues.id,
            FromDate: employee.punchIn,
            ToDate: employee.punchOut,
          });
          this.TimeEditDetailsForm.reset();
          this.timeEditModal = false;
          this.submittedGeneral = false;
          this.TimeEditDetailsForm.patchValue({
            punchIn: new Date(),
            punchOut: new Date(),
          });
        });
  }

  timeIn(timeIn: any, arg1: string) {
    throw new Error('Method not implemented.');
  }

  getJobByEmp(value) {
    this.TimeEditDetailsForm.controls['employeeId'].setValue(value);
    this.EmpByJobID = value;
    this.getEmployeeJob(value);
  }

  getEmployeeJob(empid) {
    this.JobPositionList = [];
    const employee: GetEmployee = {
      employeeId: empid,
    };
    this.empService.GetEmployee(employee).subscribe((response) => {
      if (
        response[0].employeeJobPositionList != undefined &&
        response[0].employeeJobPositionList != null &&
        response[0].employeeJobPositionList != ''
      ) {
        this.jobPositionarray = response[0].employeeJobPositionList;
        this.positionArrayList = [];
        if (
          this.jobPositionarray != undefined &&
          this.jobPositionarray != null &&
          this.jobPositionarray != ''
        ) {
          if (this.jobPositionarray.length > 0) {
            for (let j = 0; j < this.jobPositionarray.length; j++) {
              let rolename;
              rolename = this.rolePosition.find(
                (x) => x.roleId == this.jobPositionarray[j].empPositionId
              );
              let name = {};
              name = rolename.roleName;
              let rolePermission = {
                roleId: this.jobPositionarray[j].empPositionId,
                roleName: name,
              };
              this.positionArrayList.push(rolePermission);
              this.JobPositionList = this.positionArrayList;
            }
          }
        }
      }
    });
  }

  ngAfterViewInit() {
    this.TimeEditDetailsForm.patchValue(
      this.helper.getValue('GetSessionData1')
    );
    this.timeeditData_child.emit(this.TimeEditDetailsForm.value);
  }

  onchange(data) {
    this.tipsVal = data.tips;
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('TimeFormValue', this.TimeEditDetailsForm.value);
    this.helper.setValue('OnDirty', true);
    this.helper.setValue('SessionPageName', 'TimePage');
    this.timeeditData_child.emit({ data: data, event: 'TimePage', val: true });
  }
}
