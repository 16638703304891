import { Component, OnInit, ViewChild, ChangeDetectorRef, Output,EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { EmployeeService } from 'src/app/services/employee.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetAllUserList } from 'src/app/interfaces/iemployee';
import { HelperService } from 'src/app/helper/helper.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Input } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { IActiveUser, IListInfo, IHierarchySubject } from 'src/app/interfaces/iadmin';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
@Component({
  selector: 'app-hierachy-store',
  templateUrl: './hierachy-store.component.html',
  styleUrls: ['./hierachy-store.component.css']
})
export class HierachyStoreComponent implements OnInit {

  GeneralStoreForm: FormGroup;
  generalValue: any;
  LiveDate: Date;
  submittedGeneral = false;
  allstoredetails = [];
  rowClicked;
  format = 'h:mm:ss a';
  userId: any;
  tenantId: any;
  checked = [false];
  storeList = [];
  loginInfo: any;

  time: Date;
  date: Date;
  timepickerVisible = false;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  isActive: [true];
  ParentId: any;
  CompId: any;
  eodTime = new Date();
  companyParentData: any;
  isvParentData: any;
  public displayedColumns = [];
  public EmployeeTableData: any;
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  tableEnable: boolean = true;
  tenantTypeID: any;
  public storeRow: any;
  @Input() public storeIdItem;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];


  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public storeData = new EventEmitter();

  constructor(private storeDetails: AdminService, private empDetails: EmployeeService, private formBuilder: FormBuilder, 
    private changeDetector: ChangeDetectorRef, private toaster: ToasterService, private helper: HelperService) { }

  // @ViewChild(DataTableDirective)
  // dtElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject();

  ngOnInit(): void {

    this.employeeName = "Store Name";
    this.employee = "Store";
    this.employeeIcon = "assets/components/images/home3x.png"

    // this.dtOptions = {
    //   paging: false,
    //   info: false,
    //   scrollY: '400',
    // };
    this.GeneralStoreForm = this.formBuilder.group({
      tenantName: ['', Validators.required],
      // contactPhone: [''],
      contactPhone: new FormControl(undefined),
      contactFirstName: [''],
      contactLastName: [''],
      contactEmail: [''],
      address: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      timeZone: [''],
      liveDate: [''],

      smsMessaging: [false],
      offlineTransaction: [false],
      swipeCards: [false],
      timePunchPrints: [false],
      allowFutureOrders: [false],
      signatureCapture: [false],
      emailedReceipts: [false],
      daylightSavings: [false],
      roundTax: [false],
      isActive: [true],
      isDemo: [true],
      isDeleted: [false],
    });
    this.getAllStoreData();
    this.loginInfo = this.helper.getValue('LoginInfo');

    console.log( this.loginInfo,'  this.loginInfo')
    
    this.tenantTypeID = this.loginInfo.tenantTypeId;
    this.helper.setValue("Companyflag", 0);
    this.helper.setValue("Dealerflag", 0);
    this.helper.setValue("Storeflag", 3);
    this.storeDetails.hierarchystoreTransferValue.subscribe(data => {
      this.storeList = data;
      this.dataSource.data = data;
      this.EmployeeTableData = data;
      this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
      this.displayedColumns = ['name', 'isActive'];
      this.columns = [
        { columnDef: 'name', header: 'Name' },
        { columnDef: 'isActive', header: 'Status' }
      ];
    });
    this.storeDetails.hieRowStore.subscribe(data => {
    this.storeRow = data
      if (this.storeRow.IdStore != undefined && this.storeRow.IdStore != null && this.storeRow.IdStore != "") {
        this.getStore(this.storeRow.IdStore);

        this.storeIdItem = this.storeRow
      }

    });

    this.storeDetails.hieStoreParentVal.subscribe(data => this.companyParentData = data);
    this.storeDetails.hieDealerParentData.subscribe(data => this.isvParentData = data);
    if (this.tenantTypeID == 2 && this.companyParentData.id == "") {
      this.getDetailedData(0);
    } else {
      let CompanyID
      CompanyID = this.companyParentData.id != undefined && this.companyParentData.id != null && this.companyParentData.id != "" ? this.companyParentData.id : 0;
      if (CompanyID == 0) {
        this.toaster.error("Please Select Company");
      }
    }
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  // rerender(): void {
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

  //     dtInstance.destroy();

  //     this.dtTrigger.next();
  //   });
  // }
  get GenStoreData() { return this.GeneralStoreForm.controls; }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  addStore() {
    this.GeneralStoreForm.reset();
    this.employeeName = "Store Name";
    this.storeRow = 'transparent';
    this.tenantId = 0;
    this.titleName.emit(this.employeeName)

  }
  getTableName(name) {
    this.employeeName = name;
    this.employee = "Store";
    this.employeeIcon = "assets/components/images/home3x.png";
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)

  }

  onSaveGeneral() {
    this.GeneralStoreForm.controls.tenantName.setValidators(Validators.required);
    this.GeneralStoreForm.controls.tenantName.updateValueAndValidity();
    this.submittedGeneral = true;

    this.CompId = this.companyParentData.id != undefined && this.companyParentData.id != null && this.companyParentData.id != "" ? this.companyParentData.id : 0;

    if (this.GeneralStoreForm.invalid) {
      return;
    }
    const store = this.GeneralStoreForm.value;
    store.sectionId = 1;
    store.tenantId = this.tenantId > 0 ? this.tenantId : 0;
    store.tenantTypeId = 4;
    store.parentId = this.CompId;
    const obj = store.contactPhone
    const result = obj.internationalNumber;
    store.contactPhone = result;

    this.storeDetails.UserHierachy(store).subscribe(
      (response) => {
        this.generalValue = response;
        this.getDetailedData(this.CompId);
        this.toaster.success("Store Data Added Successfully");
        this.GeneralStoreForm.controls.tenantName.setErrors(null);
        this.GeneralStoreForm.controls.tenantName.clearValidators();
        this.submittedGeneral = false;
      },
      error => {

      })

  }

  checkValue(event) {
    let currentTarget = event.e.currentTarget.checked
    const active: IActiveUser = {
      userId: this.loginInfo.userId,
      tenantId: event.id,
      isActive: event.e.currentTarget.checked
    }
    this.storeDetails.IsActiveUser(active).subscribe(
      (response) => {
        this.getStore(event.id)
        // this.getDetailedData(null)
        if (currentTarget) {
          this.toaster.success("Store is Active");
        } else {
          this.toaster.success("Store is Deactive");
        }
      },
      error => {

      })
  }

  changeTableRowColor(event) {
    this.storeRow = ""
    this.storeRow = this.storeRow === event.idx ? -1 : event.idx;
    this.getStore(event.id)
  }

  getStore(empid) {
    this.userId = empid;
    this.tenantId = empid;
    const employee: GetAllUserList = {
      tenantId: empid
    }
    this.storeDetails.GetAllHierachy(employee).subscribe((response) => {
      this.GeneralStoreForm.patchValue(response);
    })
  }

  getAllStoreData() {
    const company: GetAllUserList = {
      tenantId: 0
    }
    this.allstoredetails = [];

    this.empDetails.GetAllEmployees(company).subscribe((response) => {
      this.allstoredetails = response;

      // this.dtTrigger.next();
    },
      error => {
        // this.dtTrigger.next();

      });
  }
  getDetailedData(parentID) {
    const login: IListInfo = {
      loginUserId: this.loginInfo.userId,
      tenantId: parentID
    }
    this.storeDetails.TenantHierarchyData(login).subscribe(
      (response) => {
        if (response.typeId == 3 && parentID == 0) {
          const parentValue: IHierarchySubject = {
            id: response.id,
            name: response.name,
            typeId: response.typeId,
            typeName: response.typeName,

            isActive: response.isActive,
            parentTenantId: response.parentTenantId
          }
          this.storeDetails.setStoreParentVal(parentValue);
        }
        this.storeDetails.checkStoreHierarchydata(response.childHierarchyList);
        this.dataSource.data = response.childHierarchyList;
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
        // this.tableEnable = false;
        //   this.changeDetector.detectChanges();
        //   this.tableEnable = true;
      })
    this.addStore()
  }
  gethierarchyData(e){
    this.storeData.emit(e)
   }
}
