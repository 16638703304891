import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BaseService } from '../base/base.service';
import { HelperService } from '../helper/helper.service';
import { ActivatePayment, iAWSFile, iDataCapAPI, iGiftCardDesign, iGiftCardSave, iGiftCardSelect } from '../interfaces/icustompayment';

@Injectable({
  providedIn: 'root'
})
export class GiftcardService {

  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) { }

  //--------------------------GIFTCARD---------

  SaveGiftCard(Custom: iGiftCardSave) {
    return this.base.post('Gift/SaveGiftCard', Custom).pipe(map((response: any) => {
      return response;
    }));
  }
  SaveGiftCardDesign(Custom: iGiftCardDesign) {
    return this.base.post('Gift/SaveGiftCardDesign', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectGiftCard(Custom: iGiftCardSelect) {
    return this.base.post('Gift/SelectGiftCard', Custom).pipe(map((response: any) => {
      return response;
    }));
  }
  EditGiftCard(Custom: iGiftCardSelect) {
    return this.base.post('Gift/EditGiftCard', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  ActivateGiftCard(Custom: ActivatePayment) {
    return this.base.post('Gift/DeleteGiftCard', Custom).pipe(map((response: any) => {
      return response;
    }));
  }

  LogoAWSUpload(Custom: FormData) {

    return this.base.postFiles('AWSImage/ImgaeS3Upload/', Custom)
      .pipe(map((response: any) => {
        return response;
      }
      ));
  }

  DataCapGiftCardTest(Custom: iDataCapAPI) {
    return this.base.post('Gift/DataCapGiftCardTest', Custom).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

}
