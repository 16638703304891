import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AnyMxRecord } from 'dns';
import { EmployeeService } from 'src/app/services/employee.service';
import { GetAllUserList } from 'src/app/interfaces/iemployee';
import { EmployeeGeneralComponent } from '../employee-general/employee-general.component';
import { EmployeePermissionComponent } from '../employee-permission/employee-permission.component';
import { EmployeeWagesComponent } from '../employee-wages/employee-wages.component';
import { FormArray } from '@angular/forms';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { IActiveUser } from 'src/app/interfaces/iadmin';
import { AdminService } from 'src/app/services/admin.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';
import { DragDrop } from '@angular/cdk/drag-drop';
import { DIR_DOCUMENT } from '@angular/cdk/bidi';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-employee-user',
  templateUrl: './employee-user.component.html',
  styleUrls: ['./employee-user.component.css']
})

export class EmployeeUserComponent implements OnInit {
  public displayedColumns = [];
  traceEditEvent: boolean = false;
  columns = [];
  removeID: any;
  activeDataSource: any;

  public dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(EmployeeGeneralComponent) generalComponent: EmployeeGeneralComponent;
  @ViewChild(EmployeeWagesComponent) wagesComponent: EmployeeWagesComponent;
  @ViewChild(EmployeePermissionComponent) permissionComponent: EmployeePermissionComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  @Output() public storeempsessData = new EventEmitter();

  tableEnable: boolean = true;
  public SeesionVal: any;
  public tableRowId: any;
  public tableRowColor: any;
  public GetGeneralVal: any;
  public tableRowName: any;
  public wagesDataemit: any;
  public permissionDataemit: any;
  roleDuplicate: any;
  public roleDataemit: any;
  public permissionSaveid: any;
  public getRoleData: any;
  public permissionDropItem: any;
  public employeeForm: any;
  public jsonArrayValue: any;
  loginInfo: any;
  public empColor: any;
  public currentTarget: boolean;
  public activeDeactiveValue: any;
  public EmployeeTableData: any;
  public employeeName: any;
  public ActivePermission: any;
  public employee: any;
  public employeeIcon: any;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Input() public buttonDisable;
  @Input() public compHierHide;
  @Input() public dealHierHide;
  clickUpdated: any
  submittedGeneral: any;
  submittedWages: any;
  buttonDisabless: any;
  isInvalid: boolean = false;
  isInvalidWage: boolean = false;
  buttonStatus: any;
  public modifierItemsData: any;
  private onDestroy$: Subject<void> = new Subject<void>();
  General: boolean = false;
  indexExpanded: number = 0;
  primaryId: any;
  public isWagesPermission: boolean;
  matopen:boolean = false;
  matstate($event){
    this.matopen =$event
  }
  constructor(private repoService: EmployeeService, private adminService: AdminService, private helper: HelperService,
    private changeDetector: ChangeDetectorRef, private toaster: ToasterService, private dialogser: DialogService, private permser: PermissionService) { }


  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.GeneralPage");
    this.isWagesPermission = this.permser.isWagesPermission;
    console.log(this.isWagesPermission, 'this.isWagesPermission ')
    this.dialogser.ClearSession();
    this.tableRowColor = 'transparent';
    this.buttonStatus = false;
    this.employeeName = "Store Employee Name";
    this.employee = "Store Employee";
    this.employeeIcon = "assets/components/images/path.png"
    this.tableEnable = true;
    this.loginInfo = this.helper.getValue('LoginInfo');

    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    // console.log(this.loginInfo.primaryRolePermissionListInfo,'Permission');
    this.ActivePermission = this.loginInfo.primaryRolePermissionListInfo.filter(x => x.id == 8);
    this.getAllOwners();
    this.repoService.clickValues.pipe(takeUntil(this.onDestroy$)).subscribe(data => this.clickUpdated = data),
      err => console.error('Error', err), () => console.log('HTTP request completed.');
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  getAllOwners() {
    const employee: GetAllUserList = {
      loginUserId: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.repoService.GetAllEmployees(employee).pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        if (this.loginInfo.tenantType == 4) {
          this.dataSource.data = res.filter(x => x.isActive == true && (this.loginInfo.tenantType == 4 && x.id != this.loginInfo.userId));
        }
        else {
          this.dataSource.data = res.filter(x => x.isActive == true);
        }

        this.EmployeeTableData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
        this.displayedColumns = ['name', 'primaryRole', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Name' },
          { columnDef: 'primaryRole', header: 'Primary Role' },
          { columnDef: 'isActive', header: 'Status' }
        ];
        this.helper.setValue('SaveId', this.permissionSaveid);
        if (this.helper.getValue('Sessionid_help') == undefined ||
          this.helper.getValue('Sessionid_help') == null ||
          this.helper.getValue('Sessionid_help') == "") {
          this.helper.setValue('Sessionid_help', 0)
        }
        else {
          //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
          this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
        }
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  async getGeneralDetail(event) {
    this.getAllOwners();
    this.helper.setValue('GenVal', event.res);

  }

  expandPanel(index, e?) {

    if (index == 'Permissions') //&& this.traceEditEvent == false
    {
      if (this.wagesComponent.roleList.length > 0) {
        this.permissionComponent.onGetdropdownUsingAccordian(this.wagesComponent.roleList);
        if (this.tableRowId == 0) {
          this.permissionComponent.GetRoleUsingAccordian(this.tableRowId);
        }
        else {
          this.permissionComponent.GetRoleUsingAccordian(this.primaryId);
        }
      }

    }
    this.indexExpanded = index;
  }

  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }
  }

  onChangeAccordion(e, index) {
    if (index == "Permissions") {
    }
    this.indexExpanded = e;
  }

  // index(e: (e: any) => void, index: any) {
  //   throw new Error('Method not implemented.');
  // }
  // e(e: any) {
  //   throw new Error('Method not implemented.');
  // }

  async getTableIdx(event) {
    //  this.addEmployee();
    this.generalComponent.errorValue = "";
    this.generalComponent.errorValue1 = "";
    this.generalComponent.errorValue2 = "";
    this.generalComponent.errorValue3 = "";
    this.generalComponent.errorValue4 = "";
    this.generalComponent.dateMgs = '';
    this.generalComponent.dateMgsHire = '';
    this.generalComponent.mgs = '';
    this.generalComponent.zipCodeMsg = '';

    //if (this.generalComponent.isFormPageDirty() || (this.helper.getValue('OnDirty') == true)) {
    if (this.helper.getValue('OnDirty') == true || this.helper.getValue('OnDirty2') == true ||
      this.helper.getValue('OnDirty3') == true) {
      var conf = await this.dialogser.confirm("GeneralPage");
      if (conf == 'success') { // Navigate Away
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
        if (event == this.dialogser.ModifiedId) {
          this.generalComponent.getEmployee(event);
        }
        this.dialogser.ClearSession();
        this.generalComponent.GeneralForm.reset();

        //General Data
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.isInvalid = false;
        this.wagesComponent.requiredError = false
        this.generalComponent.requiredError = false;
        this.isInvalidWage = false;
        this.traceEditEvent = true;  // for trace the edit events
        this.flipcomp.rowClicked = event;
        this.dialogser.ModifiedId = event;
        this.wagesComponent.tempRoleListSelect = [];
        this.wagesComponent.msgFound = { value: '', index: null };
      }
      else // Cancel
      {
        //General Data
        this.generalComponent.GeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.wagesComponent.WagesForm.patchValue(this.helper.getValue('GetSessionData2'));
        this.permissionComponent.PermissionForm.patchValue(this.helper.getValue('GetSessionData3'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.storeempsessData.emit({
          data: this.generalComponent.GeneralForm.value, event: "GeneralPage", val: true,
          itmname: this.generalComponent.GeneralForm.value.firstName + " " +
            this.generalComponent.GeneralForm.value.lastName
        });

        if (!(this.generalComponent.GeneralForm.invalid)) {
          this.generalComponent.dateMgs = '';
          this.generalComponent.dateMgsHire = '';
          this.generalComponent.requiredError = false;
          this.isInvalid = false;
          return;
        }
      }
    }
    else {
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
      this.helper.setValue('Sessionid_help', event);
      this.dialogser.ModifiedId = event;
      this.tableRowId = event;
      this.tableRowColor = event;
      this.isInvalid = false;
      this.wagesComponent.requiredError = false
      this.generalComponent.requiredError = false;
      this.isInvalidWage = false;
      this.traceEditEvent = true;  // for trace the edit events
      this.generalComponent.dateMgs = '';
      this.generalComponent.dateMgsHire = '';
      this.wagesComponent.tempRoleListSelect = [];
      this.wagesComponent.tempRoleListSelect = [];
      this.wagesComponent.msgFound = { value: '', index: null };
    }
  }

  ValidateWagesDetails(e) {
    if (e == 0) {
      this.traceEditEvent = false;
      this.permissionComponent.PermissionForm.value.permissionDataemit = [];
      this.permissionComponent.PermissionForm.reset();
    }
    else {
      this.traceEditEvent = true;
    }
  }

  getTableName(name) {
    this.employeeName = name;
    this.employee = "Store Employee";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  getWagesDetails(event) {
    this.wagesDataemit = event;
    this.getAllOwners();
    // this.addEmployee();
  }

  getPermissionDetails(event) {
    this.permissionDataemit = event;
  }

  getRoleDetails(event) {
    this.roleDataemit = event;
  }

  getPermissionDataEmit(event) {
    this.permissionSaveid = event;
    this.getAllOwners()
    // this.addEmployee();
  }

  getRoleItemId(event) {
    this.getRoleData = event;
    console.log(this.getRoleData, "this.getRoleDatadsad");
    this.primaryId = event;
  }

  getPermissonDrop(event) {
    this.permissionDropItem = event;
    this.wagesComponent.roleList = event;
    //console.log(this.permissionDropItem, "User_this.permissionDropItem");
  }

  public redirectToDetails = (id: string) => {
  }

  async addEmployee() {
    // if (this.generalComponent.isFormPageDirty() == false) {

    if (this.helper.getValue('OnDirty') == false && this.helper.getValue('OnDirty2') == false
      && this.helper.getValue('OnDirty3') == false) {
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
      this.Cleargendata();
      this.wagesComponent.roleListSelect = []
      this.wagesComponent.msgFound = { value: '', index: null };
    }
    else {
      var conf = await this.dialogser.confirm("GeneralPage");
      if (conf == 'success') { // Navigate Away
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
        this.Cleargendata();
        this.wagesComponent.roleListSelect = []
        this.wagesComponent.msgFound = { value: '', index: null };
      }
      else {
        this.generalComponent.GeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.wagesComponent.WagesForm.patchValue(this.helper.getValue('GetSessionData2'));
        this.permissionComponent.PermissionForm.patchValue(this.helper.getValue('GetSessionData3'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        if (!(this.generalComponent.GeneralForm.invalid)) {
          this.generalComponent.dateMgs = '';
          this.generalComponent.dateMgsHire = '';
          this.generalComponent.requiredError = false;
          this.isInvalid = false;
          return;
        }
      }
    }

  }

  Cleargendata() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.generalComponent.mgs = '';
    this.generalComponent.zipCodeMsg = '';
    this.generalComponent.errorValue = "";
    this.generalComponent.errorValue1 = "";
    this.generalComponent.errorValue2 = "";
    this.generalComponent.errorValue3 = "";
    this.generalComponent.errorValue4 = "";
    this.generalComponent.requiredError = false;
    this.wagesComponent.requiredError = false;
    this.isInvalid = false;
    this.isInvalidWage = false;
    this.generalComponent.dateMgs = '';
    this.generalComponent.dateMgsHire = '';
    // this.wagesComponent.userId = 0
    // this.wagesComponent.roleId = 0
    // this.generalComponent.GeneralForm.patchValue(isActive == true)
    this.generalComponent.GeneralForm.reset();
    this.generalComponent.submittedGeneral = false;
    this.wagesComponent.submittedWages = false;
    this.wagesComponent.WagesForm.reset();
    this.permissionComponent.PermissionForm.reset();
    this.permissionComponent.count = 1;
    this.permissionComponent.addClick = 1;
    this.employeeName = "Store Employee Name";
    this.permissionDataemit = [];
    this.permissionComponent.jsonArrayValue = [];
    this.permissionComponent.pDataemit = [];
    this.getRoleData = 0;
    this.tableRowColor = 'transparent';
    this.flipcomp.selectTableRowColor(0, 0, this.employeeName);
    let wagesGroup = this.wagesComponent.WagesForm;
    let dynControl = wagesGroup.controls['employeeJobPositionList'] as FormArray;
    (this.wagesComponent.WagesForm.controls['employeeJobPositionList'] as FormArray).clear();
    this.wagesComponent.ngOnInit();
    this.generalComponent.GeneralForm.patchValue({
      isActive: true,
      employeeHireDate: new Date()
    });
    this.tableRowId = 0;
    this.traceEditEvent = false;
    this.wagesComponent.roleId = 0;
    this.roleDataemit = 0;
    this.wagesDataemit = 0;
    this.wagesComponent.roleList = [];
    this.titleName.emit(this.employeeName);
  }

  public redirectToUpdate = (id: string) => {
  }

  public redirectToDelete = (id: string) => {
  }

  checkValue(event) {
    this.activeDeactiveValue = event
    this.currentTarget = event.e.currentTarget.checked
    const active: IActiveUser = {
      userId: this.loginInfo.userId,
      tenantId: event.id,
      isActive: event.e.currentTarget.checked
    }

    if (this.loginInfo.tenantType == 4) {
      if (this.ActivePermission.length != 0) {
        if (this.ActivePermission[0].id == 8) // Active /Inactive Permission
        {
          //console.log(this.ActivePermission[0].id,'isActive');
          this.ActivateDecative(active);
        }
      }
    }
    else {
      this.ActivateDecative(active);
    }
  }

  ActivateDecative(active: IActiveUser) {
    this.repoService.IsActiveEmpUser(active).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        this.getAllOwners();
        if (this.currentTarget) {
          this.toaster.successPopUp("Employee is Active");
        } else {
          this.toaster.successPopUp("Employee is Deactive");
        }
      }), err => console.error('Error', err), () => console.log('HTTP request completed.');
  }

  toggleIsActive(e) {
    this.getAllOwners();
  }

  @Input()
  public set buttonState(btnst) {
    this.buttonStatus = !btnst;
  }

  getbtnActive(event) {
    this.buttonDisabless = event;
  }

  async onSaveGeneral() {


    if (this.isWagesPermission == true && this.loginInfo.tenantType == 4) {
      this.toaster.errorPopUp("You don't have a permission to save !")
    }
    else {
      if (this.generalComponent.GeneralForm.invalid) {
        this.generalComponent.GenValidation();
        this.isInvalid = true;
      }
      else if (this.wagesComponent.WagesForm.invalid) {
        this.wagesComponent.wagesValidation();
        this.isInvalid = false;
        this.isInvalidWage = true;
      }
      else {
        this.traceEditEvent = false;
        this.isInvalidWage = false;
        this.isInvalid = false;
        this.roleDuplicate = await this.wagesComponent.onValidateRole();

        if (this.roleDuplicate.res.message == 'Job is already assigned') {
          this.toaster.errorPopUp("Duplicate job is assigned.! Please change the Job.")
        }
        else {
          let response = await this.generalComponent.onSaveGeneral();

          if (this.roleDuplicate.res.message != 'Job is already assigned') {
            let wagesResponse = await this.wagesComponent.onSaveWages(response.res.id);
            if (wagesResponse.res.message == 'primaryrole') {
              this.toaster.errorPopUp("Please Select Primary Role...!");
            }
            else {
              this.permissionComponent.onSavePermission(response.res.id);
              if (response.res.id != 0) {
                this.toaster.successPopUp("Data is Saved Successfully");
                this.flipcomp.selectTableRowColor(response.res.id, response.res.id, response.name)
                this.getAllOwners();
                await this.dialogser.ClearSession();

              }
            }
          }
        }
      }
    }

  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  isInvalidWageAccordion(event) {
    this.isInvalidWage = event;
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  getstoreempData(e) {
    this.storeempsessData.emit(e)
  }
}
