<!-- <div class="row top-sec">
  <div class="col-sm-12">
    <div class="form-group global-form">
      <button class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="onSaveTimeEdit()">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label>
      </button>
    </div>
  </div>
</div> -->
<div class="row">
    <!-- <div class="col-sm-3" style="z-index: -1;">  </div> -->
    <div class="col-sm-12">
        <!-- <div class="row">
      <div class="col-sm-4">
        <app-title-card [Dataname]="employeeName" [DataCom]="employee" [DataIcon]="employeeIcon"></app-title-card>
      </div>
    </div> -->
        <div class="time-body">
            <form class="loginForm form-switch" [formGroup]="TimeForm" (change)="onchange(TimeForm.value)">
                <div class="row">
                    <div class="col-sm-2">
                        <div class="form-group mat-box">
                            <mat-form-field appearance="fill">
                                <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="employeeId" (selectionChange)="getJobByEmp($event.value)">
                                    <mat-option [value]="0" selected>All Employee</mat-option>
                                    <mat-option *ngFor="let item of EmpJobName" [value]="item.UserId">{{item.UserFname}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="emp-time">
                            <div class="form-group">
                                <mat-form-field appearance="fill" class="custom-date">
                                    <input matInput [matDatepicker]="picker" placeholder="From" class="form-control datepicker appInputRestriction" formControlName="fromDate" (click)="picker.open()">
                                    <mat-datepicker #picker class="example-header"></mat-datepicker>
                                </mat-form-field>
                                <!-- <input type="text" placeholder="From" class="form-control" bsDatepicker formControlName="fromDate"
                #dpYMD="bsDatepicker" [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" appInputRestriction> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="emp-time">
                            <div class="form-group">
                                <!-- <input type="text" placeholder="To" class="form-control" bsDatepicker formControlName="toDate"
                #dpYMD="bsDatepicker" [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" appInputRestriction> -->
                                <mat-form-field appearance="fill" class="custom-date">
                                    <input matInput [matDatepicker]="pickerA" placeholder="To" (dateChange)="onchange(TimeForm.value)" class="form-control datepicker appInputRestriction" formControlName="toDate" (click)="pickerA.open()">
                                    <mat-datepicker #pickerA class="example-header"></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-group">
                            <mat-form-field appearance="fill">
                                <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="clockType">
                                    <mat-option [value]="0" selected>All Shift</mat-option>
                                    <mat-option *ngFor="let item of ClockList" [value]="item.typeId">{{item.typeName}}
                                    </mat-option>
                                    <!-- <mat-option value="S02">Shift</mat-option>
                  <mat-option value="B03">Break</mat-option> -->
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-group">
                            <mat-form-field appearance="fill">
                                <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="jobPositionId">
                                    <mat-option [value]="0" selected>All Job Position</mat-option>
                                    <mat-option *ngFor="let item of JobPositionList" [value]="item.roleId">{{item.roleName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="emp-time">
                            <div class="form-group">
                                <button class="btn submit_button form-control timeEditBtn" type="submit" (click)="onSaveTimeFilter();">Submit</button>

                                <!-- [disabled]="buttonDisable ? true : null" -->
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-sm-12 hover-table time-edit-table" id="time-table">
                    <app-fliptable-accordion [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" (tableToMainValue)="getModelDetails($event)" [jobPositionItem]="EmpByJobID" [buttonState]="buttonDisable" [OnSaveBit]="IsSave" (timeeditsessData)="gettimeeditsessData($event)">
                        <!-- (modelValues)="tableValueBind($event)"  -->
                    </app-fliptable-accordion>
                </div>
            </div>
        </div>
    </div>
</div>