<div class="credit_firstData adjustment_detail">
    <form [formGroup]="printerGeneralForm" class="loginForm" (change)="onchange(printerGeneralForm.value)">
        <div class="row mb-2">
            <div class="col-sm-8">
                <div class="row mb-2">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">Printer Name</label><label class="text-danger">*</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="name" placeholder="Printer Name" appInputRestriction [ngClass]="{
                  'is-invalid':
                    (submittedGeneral && printerGenData.name.errors) ||
                    requiredErrors
                }" maxlength="50" (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="PrintValid()" />

                            <div class="error">{{ errorValue }}</div>
                            <div *ngIf="
                  (submittedGeneral && printerGenData.name.errors) ||
                  requiredErrors
                " class="invalid-feedback">
                                <div *ngIf="printerGenData.name.errors.required">
                                    Printer Name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <button class="btn btn-sm float-right submit_button" type="submit" (click)="onReceiptPage($event)">
                <label>Configure Receipt</label>
              </button>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">Printer Brand</label>
                            <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="printerBrand" (change)="onchange(printerGeneralForm.value)">
                                <!-- <mat-option value="0" selected>PrinterName 1</mat-option>
                                <mat-option value="1">PrinterName 2</mat-option>
                                <mat-option value="2">PrinterName 3</mat-option> -->
                                <mat-option *ngFor="let item of printname" [value]="item.typeId">{{ item.typeName }}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">Printer Type</label>
                            <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="printerType" (change)="onchange(printerGeneralForm.value)">
                                <!-- <mat-option value="0" selected>PrinterType 1</mat-option>
                                <mat-option value="1">PrinterType 2</mat-option>
                                <mat-option value="2">PrinterType 3</mat-option> -->
                                <mat-option *ngFor="let item of printtype" [value]="item.typeId">{{ item.typeName }}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">Printer Group</label>
                            <!-- <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="printerGroup">
                                <mat-option value="0" selected>PrinterGroup 1</mat-option>
                                <mat-option value="1">PrinterGroup 2</mat-option>
                                <mat-option value="2">PrinterGroup 3</mat-option>
                            </mat-select> -->
                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="printerFilters" (onSelect)="onItemSelect($event)" [(ngModel)]="printerselectedItems" [formControl]="printerGroup">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">Assigned Reciept</label>
                            <!-- <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="assignedReceipt">
                                <mat-option value="0" selected>Assigned 1</mat-option>
                                <mat-option value="1">Assigned 2</mat-option>
                                <mat-option value="2">Assigned 3</mat-option>
                            </mat-select> -->
                            <ng-multiselect-dropdown [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" [data]="recieptFilters" [(ngModel)]="recieptselectedItems" [formControl]="assignedReceipt">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-10">
                        <div class="form-group">
                            <label class="customlable mb-0 custom-color">Connection type</label>
                            <div class="row outshadow">
                                <div class="col-sm-2">
                                    <div class="radiorow">
                                        <label class="radiolabel">IP</label>
                                        <div class="form-group">
                                            <div class="custom-control custom-radio custom-control item">
                                                <input type="radio" formControlName="RadioButtonIp" id="isIP" [(ngModel)]="RadioButtonIp" name="RadioButtonIp" value="radioIp" class="custom-control-input" (change)="OnIPCheck($event)" />
                                                <label class="custom-control-label QR" for="isIP"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-4">
                                    <div class="radiorow">
                                        <label class="radiolabel">Mac Address</label>
                                        <div class="form-group">
                                            <div class="custom-control custom-radio custom-control item">
                                                <input type="radio" formControlName="RadioButtonIp" id="isMACAddress" [(ngModel)]="RadioButtonIp" name="RadioButtonIp" value="radioMac" class="custom-control-input" (change)="OnMacCheck($event)" />
                                                <label class="custom-control-label QR" for="isMACAddress"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6" *ngIf="
                    printerGeneralForm.get('RadioButtonIp').value == 'radioIp'
                  ">
                                    <!-- <div class="form-group">
                                        <label class="customlable mb-0">Enter IP Address</label>
                                        <input type="text" class="form-control input-lg inputbox_login"
                                            formControlName="iPAddress1" [maxlength]="15"
                                            (keypress)="onKeypressIpEvent($event)" appInputRestriction />
                                            <div class="error">{{mgs}}</div>
                                            <div class="error">{{ipMgs}}</div>
                                    </div> -->
                                    <!-- <div class="form-group">
                                        <label class="customlable mb-0">Enter IP Address</label>
                                        <input type="text" class="form-control input-lg inputbox_login"  [inputMask]="ipAddressMask"
                                        placeholder="IP Address" (keypress)="onKeypressIpEvent($event)" (input)="IPValid($event.target.value)" appInputRestriction
                                        formControlName="iPAddress1" required [ngClass]="{ 'is-invalid': (submittedGeneral && emvGenData.ipAddress.errors) || (requiredError && emvGenData.ipAddress.errors)}" appInputRestriction  (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="EMVValid()"/>
                                        <div class="error">{{errorValue}}</div>
                                        <div *ngIf="(requiredError && emvGenData.ipAddress.errors) || (submittedGeneral && emvGenData.ipAddress.errors)" class="invalid-feedback">
                                          <div *ngIf ="emvGenData.ipAddress.errors.required">Ip Address is required</div>
                                          <div *ngIf="emvGenData.ipAddress.errors.pattern">Please Enter Valid Ip Address</div>
                                        </div>
                                        <div class="invalid-feedback">{{mgs}}</div>
                                      </div> -->
                                    <div class="form-group">
                                        <label class="customlable mb-0">IP Address</label><label class="text-danger req_label">*</label>
                                        <input type="text" class="form-control input-lg inputbox_login" [formControl]="iPAddress1" [inputMask]="ipAddressMask" placeholder="IP Address" (keypress)="onKeypressIpEvent($event)" (input)="IPValid($event.target.value)" appInputRestriction formControlName="iPAddress1"
                                            [ngClass]="{
                        'is-invalid': printerGenData.iPAddress1.errors
                      }" appInputRestriction (focusout)="PrintValid()" />
                                        <div class="error">{{ errorValue }}</div>
                                        <div class="invalid-feedback">{{ message }}</div>
                                        <div *ngIf="printerGenData.iPAddress1.errors" class="invalid-feedback">
                                            <div *ngIf="printerGenData.iPAddress1.errors.pattern">
                                                Please Enter Valid Ip Address
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6" *ngIf="
                    printerGeneralForm.get('RadioButtonIp').value == 'radioMac'
                  ">
                                    <div class="form-group">
                                        <label class="customlable mb-0">Enter MAC Address</label>
                                        <input type="text" class="form-control input-lg inputbox_login" formControlName="macAddress1" [maxlength]="17" appInputRestriction (keypress)="
                        onKeypressEvent(
                          $event,
                          $event.target.value.toUpperCase()
                        )
                      " />
                                        <div class="error">{{ Macmgs }}</div>
                                    </div>
                                    <!-- <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control input-lg inputbox_login"
                                                formControlName="macAddress2" placeholder="000" />
                                        </div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control input-lg inputbox_login"
                                                formControlName="macAddress3" placeholder="000" />
                                        </div>
                                        <div class="col-sm-3 form-group">
                                            <input type="text" class="form-control input-lg inputbox_login"
                                                formControlName="macAddress4" placeholder="000" />
                                        </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-3 col-md-4 col-4">
                        <div class="form-group">
                            <label class="radiolabel">Shared/Remote</label>
                            <div class="checkbox-table emp-name">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input active" name="shared_Remote" formControlName="shared_Remote" />
                                    <label class="custom-control-label active"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-4 col-4">
                        <div class="form-group">
                            <label class="radiolabel">Multi-Drawer</label>
                            <div class="checkbox-table emp-name">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input active" name="multi_Drawer" formControlName="multi_Drawer" />
                                    <label class="custom-control-label active"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-4">
                        <div class="form-group">
                            <label class="radiolabel">Bell/Buzzer</label>
                            <div class="checkbox-table emp-name">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input active" name="bell_Buzzer" formControlName="bell_Buzzer" />
                                    <label class="custom-control-label active"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3 col-4">
                        <div class="form-group">
                            <label class="radiolabel">Schedule</label>
                            <div class="checkbox-table emp-name">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input active" name="schedule" formControlName="schedule" />
                                    <label class="custom-control-label active"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 Communication_tab p-2">
                <div class="text-center com_label custom-color">Status:</div>
                <div class="col-sm-10 ml-3 text-center">
                    <div class="form-group">
                        <button class="btn btn-sm submit_button" type="submit">
              <label>Ping</label>
            </button>
                    </div>
                </div>
                <div class="row mb-2 ipad-width">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <button class="btn btn-sm submit_button" type="submit">
                <label>Change IP</label>
              </button>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>192.168.86.241</label>
                        </div>
                    </div>
                </div>
                <div class="row ml-4 no-gutters">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Error</label>
                            <label>:</label>
                            <label>0</label>
                        </div>
                    </div>
                </div>
                <div class="row ml-4 no-gutters">
                    <div class="col-sm-8">
                        <div class="form-group">
                            <label>Connectivity</label>
                            <label>:</label>
                            <label class="text-success">Good</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-right">
                <div class="form-group">
                    <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral()" [disabled]="buttonDisable ? true : null">
            <!-- [disabled]="buttonState ? true : null"  -->
            <img src="assets/components/images/save.png" alt="employee" class="" />
            <label class="label_config">Save</label>
          </button>
                </div>
            </div>
        </div>
    </form>
</div>
