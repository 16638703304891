import { Component, OnInit, Output, EventEmitter, ViewChild, Input, ElementRef } from '@angular/core';
import { GiftCardGeneralComponent } from '../gift-card-general/gift-card-general.component';
import { iGiftCardSelect, ActivatePayment, iSelectHouseAccountGeneral, iDeleteHouseAccount } from 'src/app/interfaces/icustompayment';
import { PaymentService } from 'src/app/services/payment.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToasterService } from 'src/app/helper/toaster.service';
import { HelperService } from 'src/app/helper/helper.service';
import { HouseAccountsGeneralComponent } from '../house-accounts-general/house-accounts-general.component';
import { EmployeeService } from 'src/app/services/employee.service';
import { HouseAccountsLogiticsComponent } from '../house-accounts-logistics/house-accounts-logitics.component';
import moment from 'moment';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-house-accounts',
  templateUrl: './house-accounts.component.html',
  styleUrls: ['./house-accounts.component.css']
})
export class HouseAccountsComponent implements OnInit {
  public employeeName: any;
  public tableRowId: any;
  public tableRowColor: any;
  public employee: any;
  public menuItem: any;
  public employeeIcon: any;
  loginInfo: any = 0;
  public dataSource = new MatTableDataSource<any>();
  public displayedColumns = [];
  public EmployeeTableData: any;
  public getDataValue: any;
  isInvalid: boolean = false;
  columns = [];
  indexExpanded: number = 0;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public houseaccsessData = new EventEmitter();

  @ViewChild(HouseAccountsGeneralComponent) HosueAccount: HouseAccountsGeneralComponent;
  @ViewChild(HouseAccountsLogiticsComponent) Hosuelogistics: HouseAccountsLogiticsComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;

  //@Input() public buttonDisable
  private buttonDisable: boolean;
  public modifierItemsData: any;
  clickUpdated: any
  matopen:boolean = false;
  matstate($event){
    this.matopen =$event
  }

  constructor(private helper: HelperService,
    private toaster: ToasterService, private objPayment: PaymentService, private menuService: EmployeeService,
    private dialogser: DialogService) { }

  buttonStatus: any;
  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.HouseAccounts");

    this.buttonStatus = false;
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "House Accounts";
    this.employee = "House Accounts";
    this.employeeIcon = "assets/components/images/payment.png";
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);

    // if(this.loginInfo.tenantId == 0){
    //   this.toaster.error("Please Select store");
    //   this.buttonDisable = true;
    // }else{
    //   this.GetHouseAccountDetails();
    // }

    this.GetHouseAccountDetails();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
    }
    this.menuService.clickValues.subscribe(data => this.clickUpdated = data);
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  getTableName(name) {
    this.employeeName = name;
    //this.employee = "Gift-Card";
    //this.employeeIcon = "assets/components/images/gift-card.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  async getTableId(event) {
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("HouseAccounts");
      if (conf == 'success') { // Navigate Away
        this.dialogser.ClearSession();
        this.HosueAccount.HouseAccountForm.reset();

        //General Data
        this.helper.setValue('Sessionid_help', event)
        this.isInvalid = false;
        this.HosueAccount.dateMgs = '';
        this.HosueAccount.dateMgsAnniversary = '';
        this.HosueAccount.zipCodeMsg = '';
        this.HosueAccount.requiredError = false;
        this.tableRowId = event;
        this.tableRowColor = event;
        if (event != undefined && event != null && event != "") {
          //  this.menuItem = event;
          // this.employeeName = event.event.name != undefined ? event.event.name : name;
          this.EditHouseAccount(event);
        }
        this.flipcomp.rowClicked = event;
        this.HosueAccount.HouseAccountForm.patchValue({ liveDate: moment(new Date()) });
        //Recipe Data
        //this.DragDropComponent.ngOnInit();
        //this.GetReceipeObjList(event);
        this.HosueAccount.mgs = '';
        this.dialogser.ModifiedId = event;
      }
      else // Cancel
      {
        //General Data
        this.HosueAccount.HouseAccountForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.houseaccsessData.emit({
          data: this.HosueAccount.HouseAccountForm.value, event: "HouseAccounts", val: true,
          itmname: this.HosueAccount.HouseAccountForm.value.name
        });

        if (!this.HosueAccount.HouseAccountForm.invalid) {
          this.HosueAccount.dateMgs = '';
          this.HosueAccount.dateMgsAnniversary = '';
          this.HosueAccount.requiredError = false;
          this.isInvalid = false;
          return;
        }
        //Recipe Data
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event)
      this.isInvalid = false;
      this.HosueAccount.requiredError = false;
      this.tableRowId = event;
      this.tableRowColor = event;
      if (event != undefined && event != null && event != "") {
        //  this.menuItem = event;
        // this.employeeName = event.event.name != undefined ? event.event.name : name;
        this.EditHouseAccount(event);
      }
    }
  }

  GetHouseAccountDetails() {
    if (this.helper.getValue('Sessionid_help') == undefined || this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    this.tableRowColor = this.helper.getValue('Sessionid_help');
    const HouseAccountList: iSelectHouseAccountGeneral = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }

    this.objPayment.SelectHouseAccountGeneral(HouseAccountList)
      .subscribe(res => {
        this.dataSource.data = res;
        this.EmployeeTableData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true);
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'House Account Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('OnDirty') == true) {
      if (this.helper.getValue('Sessionid_help') == undefined ||
        this.helper.getValue('Sessionid_help') == null ||
        this.helper.getValue('Sessionid_help') == "") {
        this.helper.setValue('Sessionid_help', 0)
      }
      else {
        this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
        this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
      }
    }
  }

  EditHouseAccount(Houseid) {
    const EditHouseList: iSelectHouseAccountGeneral = {
      id: Houseid,
      tenantId: this.loginInfo.tenantId
    }
    this.objPayment.EditHouseAccountGeneral(EditHouseList)
      .subscribe(res => {
        if (res != undefined && res != null && res != "") {
          this.getDataValue = res;
        }
      })
  }

  HouseList(event) {
    this.tableRowId = event.res.id;
    this.Hosuelogistics.onSaveGeneral(event.res.id);
    this.GetHouseAccountDetails();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name);
    this.dialogser.ClearSession();
  }

  checkValue(event) {
    //this.activeDeactiveValue = event
    let currentTarget = event.e.currentTarget.checked
    const active: iDeleteHouseAccount = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked
    }
    this.objPayment.DeleteHouseAccountGeneral(active).subscribe(
      (response) => {
        if (currentTarget) {
          this.toaster.successPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
        }
        this.GetHouseAccountDetails();
      },
    )
  }

  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;
  public scrollToTop() {
    this.scroll.nativeElement.scrollTop = 0;
  }

  expandPanel(index, e?) {
    this.indexExpanded = index;
  }
  expandFirst(e, index) {
    if (e == 'General') {
      this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }
  }
  onChangeAccordion(e, index) {

    this.indexExpanded = index;
  }

  async ReSetForm() {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
      if (this.indexExpanded != undefined && this.indexExpanded != 0) {
        this.expandFirst('General', 0);
      }
    }
    else {
      var conf = await this.dialogser.confirm("HouseAccounts");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
        if (this.indexExpanded != undefined && this.indexExpanded != 0) {
          this.expandFirst('General', 0);
        }
      }
      else {
        this.HosueAccount.HouseAccountForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
        if (!this.HosueAccount.HouseAccountForm.invalid) {
          this.isInvalid = false;
          this.HosueAccount.dateMgs = '';
          this.HosueAccount.dateMgsAnniversary = '';
          this.HosueAccount.requiredError = false;
          return;
        }
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.HosueAccount.mgs = '';
    this.HosueAccount.zipCodeMsg = '';
    this.isInvalid = false;
    this.HosueAccount.dateMgs = '';
    this.HosueAccount.dateMgsAnniversary = '';
    this.HosueAccount.requiredError = false;
    this.employeeName = "House Accounts";
    this.HosueAccount.HouseAccountForm.reset();
    this.Hosuelogistics.HouseLogisticForm.reset();
    this.HosueAccount.submittedGeneral = false;
    this.HosueAccount.ngOnInit()
    this.tableRowColor = 'transparent';
    this.getDataValue = 0;
    this.titleName.emit(this.employeeName);
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  @Input()
  public set buttonState(btnst) {
    this.buttonStatus = !btnst;
  }

  OnSave() {
    this.HosueAccount.onSaveGeneral();
    if (this.HosueAccount.HouseAccountForm.invalid) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  gethouseaccsessData(e) {
    this.houseaccsessData.emit(e)
  }

  getTableRowid(event) {
    this.tableRowId = event.idx;
  }
}
