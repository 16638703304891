
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { menuItemList } from 'src/app/interfaces/imenu';
import { MenuService } from 'src/app/services/menu.service';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
@Component({
  selector: 'app-items-layout',
  templateUrl: './items-layout.component.html',
  styleUrls: ['./items-layout.component.css']
})
export class ItemsLayoutComponent implements OnInit {

  public displayedColumns = [];
  public columns = [];
  submittedLayout: boolean=false;
  public salesAreaflip: any;
  
  tableEnable: boolean = true;
  loginInfo: any;
  render: any;
  public ItemList: any;
  public sales_item: boolean = true;
  @Input() ParentId;
 itemList: any = [];
 areaValue:any;
 menuItem: any = {
  tenantId: 0,
  userid: 0,
  parentID: 0,
  menuPageObject: ""
};
  constructor(private objItem: MenuService, private helper: HelperService,private toaster: ToasterService) {

  }



  ngOnInit(): void {
    this.objItem.SalesValue.subscribe(data => this.areaValue = data);
    if( this.areaValue.page=='')
    {
 
    }
    else
    {
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getMenuItemList();
   
    if (this.helper.getValue(this.areaValue.page.id + "_Item") == ''
    || this.helper.getValue(this.areaValue.page.id + "_Item")== undefined) {

  }
  else {
    let Item = JSON.parse(this.helper.getValue(this.areaValue.page.id + "_Item"));
    this.itemList = Item.itemlayout
   }
  
   }
  }


  @Input()
  public set salesAreabit(salesAreabit) {
    this.salesAreaflip = salesAreabit;

  }

  getMenuItemList() {
    const menuItemList: menuItemList = {
      itemID: 0,
      tenantId: this.loginInfo.tenantId,
    }
    this.objItem.MenuItemLayoutList(menuItemList)
      .subscribe(res => {
        this.ItemList = res;

      })

  }
  onSaveItem(event){
   
    let itemlistevent=JSON.stringify(event)
    this.menuItem.tenantId = this.loginInfo.tenantId;
    this.menuItem.userid = this.loginInfo.userId;
    this.menuItem.parentID =  this.areaValue.group.id;
    
    this.menuItem.menuItemObject=JSON.stringify(itemlistevent);
    this.helper.setValue(this.areaValue.page.id+ "_Item",  JSON.stringify({ itemlayout: event}));
    this.objItem.SaveMenuItemLayout(this.menuItem).subscribe(res => {
      this.toaster.successPopUp(res.message);
     

    });


    
  }
}
