import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TagsService } from 'src/app/services/tags.service';
import { adjustmentService } from 'src/app/services/adjustment.service';
import { SelectAdjustGeneral } from 'src/app/interfaces/iadjustment';
import { ReasonService } from 'src/app/services/reason';
import { IEditReasonDetails } from 'src/app/interfaces/ireason';
import { AdminService } from 'src/app/services/admin.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-reasons-general',
  templateUrl: './reasons-general.component.html',
  styleUrls: ['./reasons-general.component.css']
})
export class ReasonsGeneralComponent implements OnInit {
  reasonsGeneralForm: FormGroup;
  buttonDisable: boolean = false;
  submittedGeneral: any;
  loginInfo: any;
  public iconShowSec: any;
  //@Input() public buttonState;
  onIconId: any;
  paidIn: boolean = false;
  paidOut: boolean = false;
  refund: boolean = false;
  voids: boolean = false;
  voidSettings: IDropdownSettings = {};
  voidrefundFilters: [];
  securityFilters: [];
  @Output() public isInvalid = new EventEmitter();
  requiredErrors: boolean = false;
  voidrefundItems = [];
  securityItems = [];
  errorValue: any;
  voidrefundLimit: FormControl = new FormControl('');
  securityLimit: FormControl = new FormControl('');
  data: any;
  @Input() employeeName;

  @Output() public onIconIdemit = new EventEmitter();
  @Output() public generalReason = new EventEmitter();
  @Output() public ReasonData_child = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private helper: HelperService, private toaster: ToasterService,
    private tagService: TagsService, private adjust: adjustmentService, private reason: ReasonService,
    private adminservice: AdminService, private dialogser: DialogService) { }

  ngOnInit(): void {
    //this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;

    this.reasonsGeneralForm = this.formBuilder.group({
      id: [0],
      name: ['', Validators.required],
      printToKitchen: [false],
      restoreStockCount: [false],
      opensCashDrawer: [false],
      allowCustomReason: [false],
      fastAcess: [false],

      voidrefundFilters: [null],
      securityFilters: [null],

      voidrefundItems: [null],
      securityItems: [null],

      voidrefundLimit: new FormControl(''),
      securityLimit: new FormControl(''),
    });

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getVoidRefundlist();
    this.getRolelist();
    this.voidSettings = {
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 2,    
      selectAllText: 'Select All',
      unSelectAllText: 'Select All'
    };


  }

  get GeneralData() { return this.reasonsGeneralForm.controls; }

  getVoidRefundlist() {
    const refundList: SelectAdjustGeneral = {
      id: 0,
      tenantid: this.loginInfo.tenantId
    }
    this.adjust.SelectAdjustmentGeneral(refundList)
      .subscribe(res => {
        this.voidrefundFilters = res;
      })
  }

  getRolelist() {
    const roleList: SelectAdjustGeneral = {
      id: 0,
      tenantid: this.loginInfo.tenantId
    }
    this.adminservice.SelectRole(roleList)
      .subscribe(res => {
        this.securityFilters = res;
      })
  }

  onSaveGeneral() {
    this.errorValue = "";
    this.submittedGeneral = true;
    if (this.reasonsGeneralForm.invalid) {
      return;
    }
    this.onImgChange(this.onIconId);
    this.submittedGeneral = true;
    //if (this.onIconId != undefined) {

    let reasonDetails;
    reasonDetails = this.reasonsGeneralForm.value;
    reasonDetails.createdBy = this.loginInfo.userId;
    reasonDetails.tenantId = this.loginInfo.tenantId;
    reasonDetails.isActive = true;
    reasonDetails.voids = this.voids;
    reasonDetails.paidIn = this.paidIn;
    reasonDetails.paidOut = this.paidOut;
    reasonDetails.refund = this.refund;

    if (reasonDetails.printToKitchen == null) {
      reasonDetails.printToKitchen = false;
    }

    if (reasonDetails.restoreStockCount == null) {
      reasonDetails.restoreStockCount = false;
    }

    if (reasonDetails.opensCashDrawer == null) {
      reasonDetails.opensCashDrawer = false;
    }

    if (reasonDetails.allowCustomReason == null) {
      reasonDetails.allowCustomReason = false;
    }

    if (reasonDetails.fastAcess == null) {
      reasonDetails.fastAcess = false;
    }

    if (this.voidrefundItems != null) {
      var Arrayrefund = [];
      var clientsvoid = this.voidrefundItems;
      for (var a = 0, len = clientsvoid.length; a < len; a++) {
        Arrayrefund.push(clientsvoid[a].id);
      }
      reasonDetails.voidrefundLimit = Arrayrefund.toString();
      reasonDetails.refundCategory = Arrayrefund.toString();
    }

    if (this.securityItems != null) {
      var Arraysecurity = [];
      var clientsecurity = this.securityItems;
      for (var a = 0, len = clientsecurity.length; a < len; a++) {
        Arraysecurity.push(clientsecurity[a].id);
      }
      reasonDetails.securityLimit = Arraysecurity.toString();
      reasonDetails.securityLevel = Arraysecurity.toString();
    }

    this.reason.SaveReasons(reasonDetails).subscribe(
      async (response) => {
        this.reasonsGeneralForm.patchValue({
          id: response.id,
        });
        // this.onIconIdemit.emit(response.id);

        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
          await this.dialogser.ClearSession();
          this.helper.setValue('GetSessionData1', reasonDetails);
          this.ReasonData_child.emit({ data: reasonDetails, event: "reasons", val: false });
        }
        //this.reasonsGeneralForm.reset();
        // this.HideTab();
        // this.voidrefundItems = [];
        // this.securityItems = [];
        this.submittedGeneral = false;
        this.generalReason.emit({ res: response, name: reasonDetails.name });
      })


    //}
  }

  onImgChange(e?) {
    this.onIconId = e
    if (e == "voids") {
      this.paidIn = false
      this.paidOut = false
      this.refund = false
      this.voids = true
    } else if (e == "Paid-in") {
      this.paidIn = true
      this.paidOut = false
      this.refund = false
      this.voids = false
    } else if (e == "Paid-out") {
      this.paidIn = false
      this.paidOut = true
      this.refund = false
      this.voids = false
    }
    else if (e == "Refund") {
      this.paidIn = false
      this.paidOut = false
      this.refund = true
      this.voids = false
    }
    this.onIconIdemit.emit(this.onIconId)
  }

  @Input()
  public set DataIdx(tableRowId) {
    this.errorValue = "";
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.GetReasonGeneralList(tableRowId);
      this.iconShowSec = true
      // this.tagsGeneralForm.controls['name'].disable();
      //this.GetFilterList(tableRowId);     
      // this.getPrinterList(tableRowId);
      // this.BindPrintList(tableRowId);
      // this.BindFilteriPadSortOrderList(tableRowId);
      //this.getRolelist(tableRowId);
    }
  }

  GetReasonGeneralList(rowId) {
    const reasonList: IEditReasonDetails = {
      id: rowId,
      voids: true,
      paidIn: true,
      paidOut: true,
      refund: true,
      tenantId: this.loginInfo.tenantId
    }
    if (rowId > 0) {
      this.reason.EditReasons(reasonList) // select method
        .subscribe(res => {
          let reasonVal = res[0];
          this.reasonsGeneralForm.patchValue(reasonVal);
          this.paidIn = reasonVal.paidIn,
            this.paidOut = reasonVal.paidOut,
            this.refund = reasonVal.refund,
            this.voids = reasonVal.voids
          var salesjsonObject = reasonVal.refundCategory;
          if (salesjsonObject != null && salesjsonObject != '') {
            this.voidrefundItems = JSON.parse(salesjsonObject);
          }
          else {
            this.voidrefundItems = [];
          }

          var groupjsonObject = reasonVal.securityLevel;
          if (groupjsonObject != null && groupjsonObject != '') {
            this.securityItems = JSON.parse(groupjsonObject);
          }
          else {
            this.securityItems = [];
          }
        })
    }
  }

  @Input()
  public set employeeItem(tableRowIdcolor) {
    this.data = tableRowIdcolor;
    if (this.data == 'transparent') {
      this.iconShowSec = false
      this.paidIn = false
      this.paidOut = false
      this.refund = false
      this.voids = false
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  focusOutFunction() {
    if (this.reasonsGeneralForm.value.name != null) {
      if (this.reasonsGeneralForm.value.name.length < 0) {
        this.iconShowSec = false
        this.isInvalid.emit(true);
        this.requiredErrors = true;
        if (this.reasonsGeneralForm.invalid) {
          this.isInvalid.emit(true);
          return;
        }
      }
      else {
        this.iconShowSec = true;
        this.isInvalid.emit(false);
        this.requiredErrors = false;
      }
    }
    else if (this.reasonsGeneralForm.value.name == '' || this.reasonsGeneralForm.value.name == null) {
      this.iconShowSec = true;
      this.isInvalid.emit(true);
      this.requiredErrors = true;
    }

    // this.paidIn = true
    // this.paidOut = true
    // this.refund = true
    // this.voids = true
  }

  HideTab() {
    this.iconShowSec = false
    this.paidIn = false
    this.paidOut = false
    this.refund = false
    this.voids = false
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }
  ReasonsValid() {
    if ((this.reasonsGeneralForm.value.name == null) || (this.reasonsGeneralForm.value.name == "")) {
      this.isInvalid.emit(true);
      this.requiredErrors = true;
      if (this.reasonsGeneralForm.invalid) {
        this.isInvalid.emit(true);
        return;
      }
    }
    else {
      this.isInvalid.emit(false);
      this.requiredErrors = false;
    }
  }

  ngAfterViewInit() {
    this.reasonsGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.ReasonData_child.emit(this.reasonsGeneralForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.ReasonData_child.emit({ data: data, event: "reasons", val: true });
  }

  // isFormPageDirty() {
  //   let d = this.reasonsGeneralForm.dirty;
  //   this.helper.setValue('GetSessionData1', this.reasonsGeneralForm.value);
  //   if (d == true) {
  //     this.helper.setValue('OnDirty', true);
  //   }
  //   return d;
  // }
}