import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetDevice, DeleteDevice } from 'src/app/interfaces/idevices';
import { DeviceService } from 'src/app/services/devices.service';
import { DialogService } from 'src/app/services/dialog.service';
import { PrinterGeneralComponent } from '../printer-general/printer-general.component';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css']
})
export class PrinterComponent implements OnInit {
  @ViewChild(PrinterGeneralComponent) genprinter: PrinterGeneralComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;

  public tableRowId: any;
  public PName: any;
  public Printer: any;
  public PrinterIcon: any;
  public tableRowColor: any;
  public displayedColumns = [];
  columns = [];
  public dataSource = new MatTableDataSource<any>();
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public printerDataEmit = new EventEmitter();
  @Output() public pritergensessData = new EventEmitter();

  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  loginInfo: any;
  public getDataValue: any;
  public PrinterData: any;
  public getsaveid: any;
  isInvalid: boolean = false;
  matopen:boolean = false;
  @Output() public printerName = new EventEmitter();

  //@Input() public buttonDisable
  private buttonDisable: boolean;
  public modifierItemsData: any;
  submittedGeneral: any;
  isValidError: any;
  constructor(private helper: HelperService, private changeDetector: ChangeDetectorRef, private toaster: ToasterService,
    private objDevice: DeviceService, private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.printer");

    this.buttonDisable = false;
    this.employeeName = "Devices-Printer";
    this.employee = "Devices-Printer";
    this.employeeIcon = "assets/components/images/print.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getPrinterList();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }
  matstate($event){
    this.matopen =$event
  }
  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.ClearData(clearForm)
    }
  }
  async ClearData(event?) {
    //if (this.genprinter.isFormPageDirty() == false) {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    }
    else {
      var conf = await this.dialogser.confirm("printer");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
      }
      else {
        this.genprinter.printerGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.genprinter.errorValue = "";
    this.isInvalid = false;
    this.genprinter.requiredErrors = false;
    this.genprinter.printerGeneralForm.reset();
    this.genprinter.ngOnInit();
    this.PName = "Devices-Printer";
    this.tableRowColor = 'transparent';
    this.genprinter.recieptselectedItems = null;
    this.genprinter.printerselectedItems = null;
    this.genprinter.submittedGeneral = false;
    this.getDataValue = 0;
    this.titleName.emit(this.PName);
  }

  @Input()
  public set getDataSource(event) {
    this.getDataValue = event;
  }

  getPrinterList() {
    const deviceList: GetDevice = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.objDevice.PrinterDeviceGet(deviceList)
      .subscribe(res => {
        this.dataSource.data = res;
        this.PrinterData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true)
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Printer Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  getGeneralDetail(event) {
    this.getsaveid = event.res.id;
    this.getPrinterList();
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name);
    this.printerName.emit({ name: event.name });
  }

  checkvaluePage(event) {
    let currentTarget = event.e.target.checked //event.target.checked
    const active: DeleteDevice = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.target.checked
    }
    this.objDevice.PrinterDeviceDelete(active).subscribe(
      (response) => {
        this.getPrinterList();
        this.ClearData(0);
        this.toaster.successPopUp(response.message);
      }
    )
  }

  async getTableIdx(event) {
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("printer");
      if (conf == 'success') { // Navigate Away
        if (event == this.dialogser.ModifiedId) {
          this.genprinter.GetPrinterDetails(event);
        }
        this.dialogser.ClearSession();
        this.genprinter.printerGeneralForm.reset();

        //General Data
        this.dialogser.ModifiedId = event;
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.getDataValue = event;
        this.getPrinterList();
        this.genprinter.requiredErrors = false;
        this.isInvalid = false;
        this.flipcomp.rowClicked = event;
      }
      else // Cancel
      {
        //General Data
        this.genprinter.printerGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.pritergensessData.emit({
          data: this.genprinter.printerGeneralForm.value, event: "printer", val: true,
          itmname: this.genprinter.printerGeneralForm.value.name
        });
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event)
      this.tableRowId = event;
      this.tableRowColor = event;
      this.getDataValue = event;
      this.getPrinterList();
      this.genprinter.requiredErrors = false;
      this.isInvalid = false;
    }
  }

  getTableName(name) {
    this.PName = name;
    this.Printer = "Devices-Printer";
    this.PrinterIcon = "assets/components/images/path.png";
    this.titleName.emit(this.PName)
    this.title.emit(this.Printer)
    this.titleIcon.emit(this.PrinterIcon)
  }
  gethierarchyData(e) {
    this.printerName.emit(e);
  }
  getReceiptData(e) {
    this.printerDataEmit.emit(e)
  }
  btnActiveStatus(event) {
    this.buttonDisable = event;
  }
  isBool(event) {
    this.isValidError = event;
  }
  OnSave() {
    this.genprinter.onSaveGeneral();
    if (this.genprinter.printerGeneralForm.invalid) {
      if ((this.genprinter.printerGeneralForm.value.name == null) || (this.genprinter.printerGeneralForm.value.name == "")) {
        this.isInvalid = true;
      }
      else {
        this.isInvalid = false;
      }
    }
    else if ((this.isValidError == true)) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }
  }
  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getpritergensessData(e) {
    this.pritergensessData.emit(e)
  }
}
