import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener,} from '@angular/material/tree';
import { DiscountsComponent } from 'src/app/views/Report/discounts/discounts.component';

interface FoodNode {
  name?: string;
  count?: number;
  children?: FoodNode[];
  groupHeader?: string;
  grouptotal?: string;
  employee?: string;
  discountName?: string;
  quantity?: number;
  discountTotal?: number;
  id?:number;
  reporttotal?: any;
  employeetotal?: any;
  totalamount?:any;
}

// const EMPLOYEE_DATA1: FoodNode[] =[
//   {
//    groupHeader: "Dinein",
//    children: [
//     {
//      employee: "Jason",
//      children: [
//       {
//        employee: null,
//        discountTotal: 200,
//        discountName: "Redeem Discount",
//        quantity: 1
//       },
//       {
//        employee: null,
//        discountTotal: 200,
//        employeetotal: "Jason Totals",
//        discountName:null,
//        quantity: null
//       }
//      ],
//      totalamount: 200,
//      grouptotal: null
//     },
//     {
//      employee: null,
//      children: [
//       {
//        employee: null,
//        discountTotal: 200,
//        discountName: null,
//        quantity: null
//       }
//      ],
//      totalamount: 200,
//      grouptotal: "Dinein Totals"
//     }
//    ],
//    reporttotal: null,
//    totalamount: null
//   },
//   {
//    groupHeader: "Dineout ",
//    children: [
//     {
//      employee: "Jason",
//      children: [
//       {
//        employee: null,
//        discountTotal: 400,
//        discountName: "Redeem Discount",
//        quantity: 2
//       },
//       {
//        employee: null,
//        discountTotal: 400,
//        discountName: null,
//        employeetotal: "Jason Totals",
//        quantity: null
//       }
//      ],
//      totalamount: 400,
//      grouptotal: null
//     },
//     {
//      employee: null,
//      children: [
//       {
//        employee: null,
//        discountTotal: 400,
//        discountName: null,
//        quantity: null
//       }
//      ],
//      totalamount: 400,
//      grouptotal: "Dineout  Totals"
//     }
//    ],
//    reporttotal: null,
//    totalamount: null
//   },
//   {
//    groupHeader: null,
//    children: [
//     {
//      employee: null,
//      children: [
//       {
//        employee: null,
//        discountTotal: null,
//        discountName: null,
//        quantity: null
//       }
//      ],
//      totalamount: null,
//      grouptotal: null
//     }
//    ],
//    reporttotal: "Report Totals",
//    totalamount: 1200
//   }
//  ];


interface ExampleFlatNode {
  expandable: boolean;
  groupHeader: string;
  employee: string;
  discountName: string;
  quantity: number;
  discountTotal: number;
  level: number;
  grouptotal:any
  reporttotal:any;
  employeetotal:any;
  totalamount:any;
}

@Component({
  selector: 'app-flip-table-groupby',
  templateUrl: './flip-table-groupby.component.html',
  styleUrls: ['./flip-table-groupby.component.css']
})


export class FlipTableGroupbyComponent  {
  displayedColumns: string[] = ['groupHeader', 'employee', 'discountName', 'quantity', 'discountTotal'];
  rowClicked : any;
  @Output() public tableItem = new EventEmitter();
  @Output() public checkItem = new EventEmitter();

  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      count: node.count,
      level: level,
      groupHeader: node.groupHeader,
      employee: node.employee,
      discountName: node.discountName,
      quantity: node.quantity,
      discountTotal: node.discountTotal,
      grouptotal:node.grouptotal,
      reporttotal:node.reporttotal,
      employeetotal:node.employeetotal,
      totalamount:node.totalamount
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  
  

  employee = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor() {
    // console.log(this.objDiscount.bySalesAreaEmployeeSummary,'bySalesAreaEmployeeSummary')
   
   //   this.employee.data = EMPLOYEE_DATA1;
  }

  public GetLoad(Value?)
  {

    const EMPLOYEE_DATA:FoodNode[] =JSON.parse(Value);
    this.employee.data = EMPLOYEE_DATA;
  }
  ngonint() {
  //  this.employee.data = EMPLOYEE_DATA;
 //   console.log(this.bySalesAreaEmployeeSummary,'bySalesAreaEmployeeSummary')
    this.treeControl = new FlatTreeControl<ExampleFlatNode>(
      (node) => node.level,
      (node) => node.expandable,
    );    
    console.log(this.employee,'treeControl');
    
    //this.hide(this.treeControl )
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  changeTableRowColor(id?: any, row?: any) {
    this.rowClicked = ""
    this.rowClicked = this.rowClicked === id? -1 : id;
    this.tableItem.emit(row)
  }
  itemClick(e){
    this.checkItem.emit(e);
  } 
  
}
