import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-credit-card-step4',
  templateUrl: './credit-card-step4.component.html',
  styleUrls: ['./credit-card-step4.component.css']
})

export class CreditCardStep4Component implements OnInit {
  submittedGeneral = false;
  isSubmitted = false;
  isValidated = false;
  CreditCardStep4Form: FormGroup;
  @Output() public selectCreditCardData = new EventEmitter();
  id: any = 0;
  constructor(private formBuilder: FormBuilder, private objPayment: PaymentService,
    private helper: HelperService, private toaster: ToasterService) { }
    @Output() public generalCreditCardStep4 = new EventEmitter();

  ngOnInit(): void {
    this.helper.setValue('ConfirmPage',"pageName");
    this.CreditCardStep4Form = this.formBuilder.group({
      secretKey: ['', Validators.required],
      publicKey: ['', Validators.required],
      tranCloudId: [''],
      prePaidGift: [''],
      cardNotPresent: [''],
      cardPresent: ['']
    })
  }

  ngOnDestroy():void
  {
    this.helper.setValue(null,"pageName");
  }

  get GeneralData() {
    return this.CreditCardStep4Form.controls;
  }
  

  @Input() public buttonState;
  @Input()
  public set MenuRowItem(tableRowData) {
    if (tableRowData != undefined && tableRowData != null && tableRowData != "") {
      this.CreditCardStep4Form.patchValue(tableRowData[0]);
      this.id = tableRowData[0].id;
    }
  }

  onSaveGeneral(id?) {

 
     //   this.helper.getValue('tsysVal');
        this.submittedGeneral = true;
        let paymentserv;
        paymentserv = this.CreditCardStep4Form.value;
        paymentserv.id = sessionStorage.getItem("CreditCardID")==null?id:sessionStorage.getItem("CreditCardID");
        if (this.CreditCardStep4Form.invalid) {
          return;
        }
   
        console.log(paymentserv,'Last');

        this.objPayment.UpdateCreditCardPaymentAPIKey(paymentserv).subscribe(res => {
          this.isSubmitted = false;
          this.submittedGeneral = false;
     
          //this.toaster.successPopUp(res.message);
        })
  }


}
