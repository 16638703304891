<form class="loginForm" [formGroup]="LaborcategoryForm" (change)="onchange(LaborcategoryForm.value)">
    <div class="row">
        <div class="col-sm-4">
            <div class="form-group">
                <label>Category Name</label><label class="text-danger">*</label>
                <input type="text" class="form-control input-lg inputbox_login" formControlName="categoryName"
                    appInputRestriction placeholder="Category Name"
                    [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.categoryName.errors) || (requiredError && GeneralData.categoryName.errors)}" maxlength="50"  (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="LaborCategoryValid()"/>
                    <div class="error">{{errorValue}}</div>
                <div *ngIf="(submittedGeneral && GeneralData.categoryName.errors) || (requiredError && GeneralData.categoryName.errors)" class="invalid-feedback">
                    <div *ngIf="GeneralData.categoryName.errors.required">Category Name is required</div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group">
                <label>Store Name</label>
                <input type="text" class="form-control input-lg inputbox_login" formControlName="storename"
                    appInputRestriction placeholder="Store Name" readonly>
            </div>
        </div>
    </div>
    <div class="form-group mt-2">
        <button class="btn btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral();"
        [disabled]="buttonDisable ? true : null" >
            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label>
        </button>
    </div>
</form>