import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { HelperService } from 'src/app/helper/helper.service';
import { ISelectState, IDeleteState } from 'src/app/interfaces/iadmin';
import { ToasterService } from 'src/app/helper/toaster.service';
import { AdminService } from 'src/app/services/admin.service';
import { MatTableDataSource } from '@angular/material/table';
import { StateFieldsGeneralComponent } from '../state-fields-general/state-fields-general.component';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-state-fields',
  templateUrl: './state-fields.component.html',
  styleUrls: ['./state-fields.component.css']
})

export class StateFieldsComponent implements OnInit {
  public employeeName: any;
  private buttonDisable: boolean;
  public employee: any;
  public employeeIcon: any;
  public Statedata: any;
  public dataSource = new MatTableDataSource<any>();
  public displayedColumns = [];
  public EmployeeTableData: any;
  public getDataValue: any;
  public tableRowColor: any;
  public tableRowId: any;
  isInvalid: boolean = false;
  columns = [];
  loginInfo: any = 0;
  public modifierItemsData: any;
  ModifiedId: any = "";

  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public statesessData = new EventEmitter();

  @ViewChild(StateFieldsGeneralComponent) Statechild: StateFieldsGeneralComponent
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;
  matopen:boolean = false;
  matstate($event){
    this.matopen =$event
  }
  constructor(private helper: HelperService, private toaster: ToasterService, private dialogser: DialogService,
    private objAdmin: AdminService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.statefield");

    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "State Fields";
    this.employee = "State Fields";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.GetStateDetails();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  getTableName(name) {
    this.employeeName = name;
    //this.employee = "Gift-Card";
    //this.employeeIcon = "assets/components/images/gift-card.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  async getTableId(event) {
    //this.gentab.isFormPageDirty();
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("statefield");
      if (conf == 'success') { // Navigate Away
        this.dialogser.ClearSession();
        this.Statechild.statefieldForm.reset();

        //General Data
        this.ModifiedId = event;
        this.Statechild.errorValue = "";
        this.Statechild.errorValue1 = "";
        this.isInvalid = false;
        this.Statechild.requiredError = false;
        if (event != undefined && event != null && event != "") {
          this.helper.setValue('Sessionid_help', event);
          this.Statedata = event;
          this.tableRowId = event;
          this.tableRowColor = event;
          this.flipcomp.rowClicked = event;
        }
      }
      else // Cancel
      {
        //General Data
        this.Statechild.statefieldForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.ModifiedId;

        this.statesessData.emit({
          data: this.Statechild.statefieldForm.value, event: "statefield", val: true,
          itmname: this.Statechild.statefieldForm.value.statename
        });
      }
    }
    else {
      this.tableRowId = event;
      this.tableRowColor = event;
      this.ModifiedId = event;
      this.Statechild.errorValue = "";
      this.Statechild.errorValue1 = "";
      this.isInvalid = false;
      this.Statechild.requiredError = false;
      if (event != undefined && event != null && event != "") {
        this.helper.setValue('Sessionid_help', event)
        this.Statedata = event;
        // this.employeeName = event.event.name != undefined ? event.event.name : name;
        // this.EditState(event);
      }
    }
  }

  GetStateDetails() {
    const StatetList: ISelectState = {
      id: 0
    }
    this.objAdmin.SelectStateMaster(StatetList)
      .subscribe(res => {
        this.dataSource.data = res;
        this.EmployeeTableData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true);
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'State Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  checkValue(event) {
    //this.activeDeactiveValue = event
    let currentTarget = event.e.currentTarget.checked
    const active: IDeleteState = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked
    }
    this.objAdmin.DeleteStateMaster(active).subscribe(
      (response) => {
        if (currentTarget) {
          this.toaster.successPopUp(response.message);
        } else {
          this.toaster.successPopUp(response.message);
        }
        this.GetStateDetails();
      },
    )
  }

  async ReSetForm() {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    }
    else {
      var conf = await this.dialogser.confirm("statefield");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
      }
      else {
        this.Statechild.statefieldForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.ModifiedId = "";
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.Statechild.errorValue = "";
    this.Statechild.errorValue1 = "";
    this.isInvalid = false;
    this.Statechild.requiredError = false;
    this.employeeName = "state fields";
    this.tableRowColor = 'transparent';
    this.Statechild.statefieldForm.reset();
    this.titleName.emit(this.employeeName);
  }

  RefreshStateList(event) {
    //this.generalDataemit = event;
    this.tableRowId = event.res;
    this.tableRowColor = event.res;
    this.GetStateDetails();
    this.flipcomp.selectTableRowColor(event.res, event.res, event.name)
    //this.ReSetForm()
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  OnSave() {
    this.Statechild.onSaveGeneral();
    if (this.Statechild.statefieldForm.invalid) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getstatesessData(e) {
    this.statesessData.emit(e)
  }
}
