import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { ActivateEMVDevice, GetDevice } from 'src/app/interfaces/idevices';
import { EmvGeneralComponent } from '../emv-general/emv-general.component';
import { DeviceService } from 'src/app/services/devices.service';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-emv',
  templateUrl: './emv.component.html',
  styleUrls: ['./emv.component.css']
})

export class EmvComponent implements OnInit {
  @ViewChild(EmvGeneralComponent) gentab: EmvGeneralComponent;
  @ViewChild(EmvComponent) logtab: EmvComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;

  loginInfo: any;
  public tableRowId: any;
  public tableRowColor: any;

  public employeeName: any;
  public employee: any;
  public employeeIcon: any;

  public dataSource = new MatTableDataSource<any>();
  public EmployeeTableData: any;
  public displayedColumns = [];
  columns = [];
  public activeDeactiveValue: any;
  public getDataValue: any;
  public removeEmv: any;
  isInvalid: boolean = false;
  public modifierItemsData: any;
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();

  //@Input() public buttonDisable
  private buttonDisable: boolean;
  @ViewChild(EmvGeneralComponent) EMVComp: EmvGeneralComponent;
  @Output() public emvData = new EventEmitter();
  @Output() public evmsessData = new EventEmitter();

  submittedGeneral: any;
  breadcrumbsData: any
  isValidError: any;
  constructor(private objDevice: DeviceService, private helper: HelperService,
    private changeDetector: ChangeDetectorRef, private toaster: ToasterService, private dialogser: DialogService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.emvPage");

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Devices";
    this.employee = "Devices-EMV";
    this.employeeIcon = "assets/components/images/tablet.png";
    this.titleName.emit(this.employeeName);
    this.title.emit(this.employee);
    this.titleIcon.emit(this.employeeIcon);
    this.emvData.emit({ name: "" });
    this.getEMVDeviceList();
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.ClearData();
    }
  }

  async ClearData() {
    //if (this.gentab.isFormPageDirty() == false) {
    if (this.helper.getValue('OnDirty') == false) {
      this.ClearGenData();
    }
    else {
      var conf = await this.dialogser.confirm("emvPage");
      if (conf == 'success') { // Navigate Away
        this.ClearGenData();
      }
      else {
        this.gentab.emvGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;
      }
    }
  }

  ClearGenData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.gentab.errorValue = "";
    this.isInvalid = false;
    this.gentab.emvGeneralForm.reset();
    this.EMVComp.requiredError = false;
    this.gentab.ngOnInit();
    this.employeeName = "EMV Devices";
    this.tableRowColor = 'transparent';
    this.removeEmv = false;
    this.gentab.submittedGeneral = false;
    this.tableRowId = 0;
    this.gethierarchyData(this.employeeName);
    this.gentab.ipMgs = '';
    this.titleName.emit(this.employeeName);
  }

  getEMVDeviceList() {
    const emvList: GetDevice = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.objDevice.SelectEMVDeviceDetails(emvList)
      .subscribe(res => {
        this.dataSource.data = res;
        this.EmployeeTableData = res;
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true);
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'EMV Name' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      })

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  async getTableIdx(event) {
    //this.gentab.isFormPageDirty();
    if (this.helper.getValue('OnDirty') == true) {
      var conf = await this.dialogser.confirm("emvPage");
      if (conf == 'success') { // Navigate Away
        this.dialogser.ClearSession();
        this.EMVComp.emvGeneralForm.reset();

        //General Data
        this.helper.setValue('Sessionid_help', event)
        this.tableRowId = event;
        this.tableRowColor = event;
        this.getEMVDeviceList();
        this.removeEmv = true;
        this.isInvalid = false;
        this.EMVComp.requiredError = false;
        this.gentab.ipMgs = '';
        this.flipcomp.rowClicked = event;
        this.dialogser.ModifiedId = event;
      }
      else // Cancel
      {
        //General Data
        this.EMVComp.emvGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.dialogser.ModifiedId;

        this.evmsessData.emit({
          data: this.EMVComp.emvGeneralForm.value, event: "emvPage", val: true,
          itmname: this.EMVComp.emvGeneralForm.value.name
        });
      }
    }
    else {
      this.dialogser.ModifiedId = event;
      this.helper.setValue('Sessionid_help', event)
      this.tableRowId = event;
      this.tableRowColor = event;
      this.getEMVDeviceList();
      this.removeEmv = true;
      this.isInvalid = false;
      this.EMVComp.requiredError = false;
      this.gentab.ipMgs = '';
    }
  }

  getTableName(name) {
    this.employeeName = name;
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  ActivateDeactivate(event) {
    this.activeDeactiveValue = event
    let currentTarget = event.e.currentTarget.checked
    const active: ActivateEMVDevice = {
      userId: this.loginInfo.userId,
      id: event.id,
      status: event.e.currentTarget.checked
    }
    this.objDevice.ActivateEMVDeviceDetails(active).subscribe(
      (response) => {
        this.ClearData();
        if (currentTarget) {
          this.toaster.successPopUp("EMV Device is Active");
        } else {
          this.toaster.successPopUp("EMV Device is Deactive");
        }
        this.getEMVDeviceList();
      },
    )
  }

  getEMVList(event) {
    this.getEMVDeviceList();
    //this.flipcomp.rowClicked = event.res.id;
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name);
    this.emvData.emit({ name: event.name });
  }

  getRemoveList(e) {
    this.getEMVDeviceList();
    this.ClearData()
  }

  gethierarchyData(e) {
    this.emvData.emit(e);
    this.breadcrumbsData = true;
  }

  btnActive(event) {
    this.buttonDisable = event;
  }

  isBool(event) {
    this.isValidError = event;
  }

  OnSave() {
    this.EMVComp.onSaveGeneral();
    if (this.gentab.emvGeneralForm.invalid) {
      this.isInvalid = true;
    }
    else {
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
      this.flipcomp.rowClicked = parseInt(this.helper.getValue('Sessionid_help'));
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getevmsessData(e) {
    this.evmsessData.emit(e)
  }
}