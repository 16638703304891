<div class="row top-sec">
  <div class="col-sm-4">
    <div>
      <div class="flip-table" *ngIf="!modifierItemsData">
        <span class="text-box">{{ employee }}</span> &nbsp;
        <button class="plus_btn outshadow" (click)="addMenuItem()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <hr class="linebar" *ngIf="!modifierItemsData" />
    </div>
  </div>
  <div class="col-sm-8">
    <div class="form-group global-form">
      <button
        [ngClass]="{ submit_button_error: isInvalid == true }"
        class="btn btn-sm float-right submit_button global-submit"
        type="submit"
        (click)="OnSave()"
      >
        <img src="assets/components/images/save.png" alt="employee" class="" />
        <label class="label_config">Save</label>
      </button>
    </div>
  </div>
</div>
<div class="row second-sec">
  <div class="col-sm-4">
    <div>
      <app-fliptable
        [DataCom]="employee"
        [dataSource]="dataSource"
        (clearDetails)="addMenuItem()"
        [dealerItem]="tableRowColor"
        [displayedColumns]="displayedColumns"
        [columns]="columns"
        (tableItemId)="getTableIdx($event)"
        [employeeItem]="tableRowColor"
        (tableEmpName)="getTableName($event)"
        (breakActive)="ActivateDeactivate($event)"
        [EmployeeTableData]="EmployeeTableData"
        (btnActive)="btnActiveStatus($event)"
      >
      </app-fliptable>
    </div>
  </div>
  <div class="col-sm-8">
    <mat-accordion>
      <mat-expansion-panel
        *ngFor="let item of ['General']; let i = index"
        [expanded]="indexExpanded == i"
        class="outshadow"
      >
        <mat-expansion-panel-header
          [ngClass]="{ box_error: isInvalid == true && item == 'General' }"
        >
          <mat-panel-title
            [ngClass]="{ title_error: isInvalid == true && item == 'General' }"
          >
            {{ item }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel-header
          *ngIf="item == 'DayPart Schedule' && dayPartValue"
          (click)="expandPanel(i)"
        >
          <mat-panel-title> {{ item }} </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="item == 'General'">
          <app-tags-general
            [buttonState]="buttonDisable"
            [DataIdx]="tableRowId"
            (tags_general)="saveFilterDaypart($event)"
            [employeeItem]="tableRowColor"
            (filterDataList)="getfilterdatalist($event)"
            (isInvalid)="isInvalidAccordion($event)"
            (tags_refresh)="getrefreshpage($event)"
            [getrefreshjobprintval]="refreshjobprintval"
            (tagsGenPageData_child)="gettagsPageData($event)"
          >
          </app-tags-general>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
