<div class="loginForm GeneralForm">
  <form [formGroup]="tagsFilterForm" class="loginForm">
    <div class="row">
      <div class="col-sm-6 tagAlign">
        <app-fliptable-layout [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns"
          [DatatableRender]="table_render" [SalesID]="tagsID" (tableValueItem)="getTableValue($event)"
          (dragItememit)="isdragndrop($event)">
        </app-fliptable-layout>
        <div class="form-group mt-20">
          <label>Use for Tablet filter?</label>
          <div class="checkbox-table accordion-checkbox">
            <div class="custom-control custom-checkbox custom-control-inline">
              <input type="checkbox" class="custom-control-input" name="useForTabletFilter" id="check"
                formControlName="useForTabletFilter" />
              <label class="custom-control-label" for="check"></label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div>
          <label class="item_assigned_label">iPad Sort Order</label>
          <div class="itemAssignedmodify menu_wrapper item_main">
            <div cdkDropList cdkDropListGroup id="ipadFilter" #todo="cdkDropList" [cdkDropListData]="FilterList"
              [cdkDropListConnectedTo]="tagsID" (cdkDropListDropped)="drop($event)" cdkDragBoundary=".tablet"
              class="example-list">
              <div class="item_assigned_box" *ngFor="let item of FilterList; let i = index" cdkDrag cdkDragDropZone>
                <div [ngClass]="{ emp_name: item.id || DragId == true }">
                  <div class="outshadow">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-sm-12 text-right">
        <div class="form-group">
          <!-- (click)="onSaveFilter()" -->
          <button class="btn btn-sm float-right submit_button button-none" type="submit"
            [disabled]="buttonDisable ? true : null">
            <img src="assets/components/images/save.png" alt="employee" class="img-fluid" />
            <label class="label_config">Save</label>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>