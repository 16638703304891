<div class="credit_firstData adjustment_detail">
    <form class="carouse_wrapper loginForm" [formGroup]="TableFilterForm">
        <div class="row justify-content-start" [ngClass]="{'disp-none': isTagsChecked == false}">
            <div class="col-sm-12">
                <h6 class="custom-color">Tag Assignment</h6>
            </div>
            <div class="col-sm-3">
                <mat-form-field>
                    <!-- <mat-select (openedChange)="matstate($event)" placeholder="Tag Type" formControlName="tagstypeDropdown" class="form-control"
                    (selectionChange)="tagtype($event)"> -->
                    <mat-select (openedChange)="matstate($event)" formControlName="tagstypeDropdown" class="form-control" (selectionChange)="tagtype($event)">
                        <mat-option *ngFor="let item of getTagType" [value]="item.typeId">{{item.typeName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-3" *ngIf="multiOption==false">
                <mat-form-field>
                    <mat-select (openedChange)="matstate($event)" #mySelCategory placeholder="Tags" formControlName="tagsDropdown" class="form-control" (selectionChange)="getTagValues($event)">
                        <mat-option [value]="">Select Tags</mat-option>
                        <mat-option *ngFor="let item of tagDropdownItem" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-3" *ngIf="multiOption==true">
                <mat-form-field>
                    <!-- <mat-select (openedChange)="matstate($event)" #select class="form-control" formControlName="PrintDropdown" placeholder="Print" >
                        <mat-option *ngFor="let _item of tagPrinterList" (onSelectionChange)="optionClick($event)"
                            [value]="_item.id">{{_item.name}}
                        </mat-option>
                    </mat-select> -->

                    <mat-select (openedChange)="matstate($event)" #mySelCategory placeholder="Print" formControlName="PrintDropdown" class="form-control" (selectionChange)="optionClick($event)">
                        <mat-option [value]="">Select Print</mat-option>
                        <mat-option *ngFor="let _item of tagPrinterList" [value]="_item.id">{{_item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="time-body form-switch table_forms">
            <div class="row mt-2 no-gutters" [ngClass]="{'disp-none': isTagsChecked == true}">
                <div class="col-sm-2 col-6">
                    <div class="emp-time">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-select (openedChange)="matstate($event)" placeholder="Item name" [formControl]="nameFilter" multiple class="form-control" panelClass="leftmost-panel">
                                    <mat-option *ngFor="let position of names" [value]="position.id">{{position.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 col-6">
                    <div class="emp-time">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-select (openedChange)="matstate($event)" placeholder="Size" [formControl]="sizeFilter" panelClass="leftmost-panel-color" multiple class="form-control">
                                    <mat-option *ngFor="let position of sizes" [value]="position.id">{{position.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 col-6">
                    <div class="emp-time">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-select (openedChange)="matstate($event)" placeholder="Price" [formControl]="priceFilter" panelClass="leftmost-panel-color" multiple class="form-control">
                                    <mat-option *ngFor="let position of prices" [value]="position.id">{{position.price}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 col-6">
                    <div class="emp-time">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-select (openedChange)="matstate($event)" placeholder="Menu Page" panelClass="leftmost-panel-color" [formControl]="menuPageFilter" multiple class="form-control">
                                    <mat-option *ngFor="let position of menuPages" [value]="position.id">
                                        {{position.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 col-6">
                    <div class="emp-time">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-select (openedChange)="matstate($event)" placeholder="Tag(s) Assigned" [formControl]="tagFilter" multiple class="form-control" panelClass="leftmost-panel-color">
                                    <mat-option *ngFor="let item of tagDropdownItem" [value]="item.id">{{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 col-6 px-2">
                    <div class="emp-time">
                        <div class="form-group mat-box">
                            <button class="btn submit_button form-control timeEditBtn" type="submit" (click)="getSelectTagItem();">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-container tableFilter mt-1">
            <table mat-table [dataSource]="dataSource" class="dataTable filterHeaderTable" style="width: 100%;">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef class="tab-width">
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" (change)="isAllChecked($event)" [indeterminate]="selection.hasValue() && !isAllSelected()" class="chck-mr">
                        </mat-checkbox>
                        Tag Assignment
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="isCheckNumb($event)" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Item name</th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <ng-container matColumnDef="size">
                    <th mat-header-cell *matHeaderCellDef> Size</th>
                    <td mat-cell *matCellDef="let element"> {{element.size}} </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Price</th>
                    <td mat-cell *matCellDef="let element"> {{element.price}} </td>
                </ng-container>
                <ng-container matColumnDef="menuPage">
                    <th mat-header-cell *matHeaderCellDef>Menu Page</th>
                    <td mat-cell *matCellDef="let element"> {{element.menuPage}} </td>
                </ng-container>
                <ng-container matColumnDef="tagsAssign">
                    <th mat-header-cell *matHeaderCellDef>Tag(s) Assigned
                    </th>
                    <td mat-cell *matCellDef="let element" class="tdWidth">
                        <span *ngFor="let item of element.tagsAssign" class="emp_name">
                            {{item.label}} <i class="fa fa-close" (click)="onCloseTag(element.id,item)"></i>
                        </span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </form>
</div>
