import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ILoginuser } from '../interfaces/ilogin';
import { AuthenticationService } from '../services/authentication.service';
import { HelperService } from '../helper/helper.service';
import { Router } from '@angular/router';
import { IListInfo, INameRole, IHierarchySubject, IHierachydetails } from '../interfaces/iadmin';
import { Subject, Subscription } from 'rxjs';
import { AdminService } from '../services/admin.service';
import { exists } from 'fs';
import { ToasterService } from '../helper/toaster.service';
import { exit } from 'process';
import { DialogService } from '../services/dialog.service';
import { PermissionService } from '../services/permission.service';
import { browserRefresh } from '../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  LoginForm: FormGroup;
  RegisterForm: FormGroup;
  submittedLogin = false;
  submittedReg = false;
  fieldTextType: boolean;
  fieldTextTypeReg: boolean;
  fieldTextTypeConfReg: boolean;
  submitClick = false;
  error = false;
  loginservice: any;
  TenandId: number;
  LanguageId: number;
  userId: any;
  tenantTypeId: any;
  isv: any;
  dealer: any;
  company: any;
  store: any;
  dealerHeaderName: any;
  companyHeaderName: any;
  storeHeaderName: any;

  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private dialogser: DialogService, private permser: PermissionService,
    private helper: HelperService, private router: Router,
    private HieDetails: AdminService, private toaster: ToasterService) {
  }

  ngOnInit(): void {
    //this.helper.clearStorage();

    this.LoginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.RegisterForm = this.formBuilder.group({
      username: ['', Validators.required],
      emailReg: ['', Validators.required],
      passwordReg: ['', Validators.required],
      ConfirmPassword: ['', Validators.required],
    });
  }
  get LoginData() { return this.LoginForm.controls; }
  get RegisterData() { return this.RegisterForm.controls; }


  onLogin() {
    //localStorage.clear();
    //this.dialogser.ClearSession();

    this.submittedLogin = true;
    if (this.LoginForm.invalid) {
      return;
    }
    this.submitClick = true;
    const login: ILoginuser = {
      emailId: this.LoginData.email.value,
      password: this.LoginData.password.value,
    };
    this.authenticationService.login(login).subscribe(
      response => {
        if (response.responseMsg == true) {
          this.userId = response.userId;
          this.tenantTypeId = response.tenantTypeId;
          if (browserRefresh == true || localStorage.getItem('sessiontimeout') == "true") {
            if (localStorage.getItem('deal_ID') != undefined && localStorage.getItem('deal_ID') != "" &&
              localStorage.getItem('deal_ID') != null) {
              response.tenantId = parseInt(localStorage.getItem('deal_ID'));
            }
            if (localStorage.getItem('comp_ID') != undefined && localStorage.getItem('comp_ID') != "" &&
              localStorage.getItem('comp_ID') != null) {
              response.tenantId = parseInt(localStorage.getItem('comp_ID'));
            }
            if (localStorage.getItem('str_ID') != undefined && localStorage.getItem('str_ID') != "" &&
              localStorage.getItem('str_ID') != null) {
              response.tenantId = parseInt(localStorage.getItem('str_ID'));
            }
          }
          this.helper.setValue('LoginInfo', response);
          this.getDetailedData();
          this.permser.InitialPermission(response);

          // if (response.tenantType == 4) {
          //   if (this.ActivePermission.length != 0) {
          //     if (this.ActivePermission[0].id == 8) {
          //       // Active /Inactive Permission
          //       this.isDisabled = false;
          //     }
          //   } else {
          //     this.isDisabled = true;
          //   }
          // } else {
          //   this.isDisabled = false;
          // }
        }
        else {
          this.toaster.errorPopUp('Invalid Email Id or Password!');
        }
      },
      (error) => {
        this.toaster.errorPopUp(error);
      });
  }

  onRegister() {
    this.submittedReg = true;
    if (this.RegisterForm.invalid) {
      return;
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextTypeReg() {
    this.fieldTextTypeReg = !this.fieldTextTypeReg;
  }
  toggleFieldTextTypeConReg() {
    this.fieldTextTypeConfReg = !this.fieldTextTypeConfReg;
  }
  getDetailedData() {
    const login: IListInfo = {
      loginUserId: this.userId,//this.loginInfo.userId,
      tenantId: 0
    }
    this.HieDetails.TenantHierarchyData(login).subscribe(
      (response) => {
        if (response.typeName == "ISV" || this.tenantTypeId == 1) { //|| this.tenantTypeId == response.typeId

          this.isv = response
          const HierachyValue: IHierachydetails = {
            isv: this.isv,
            dealer: this.dealer,
            company: this.company,
            store: this.store
          }
          this.HieDetails.HierachyDetails(HierachyValue);
          // this.HieDetails.checkDealerHierarchydata(response.childHierarchyList);
          // this.HieDetails.checkCompanyHierarchydata([]);
          // this.HieDetails.checkStoreHierarchydata([]);
        } else if (response.typeName == "Dealer" || this.tenantTypeId == 2) {
          // const parentValue: IHierarchySubject = {
          //   id: response.id,
          //   name: response.name,
          //   typeId: response.typeId,
          //   typeName: response.typeName,
          //   isActive: response.isActive,
          //   parentTenantId: response.parentTenantId
          // }
          // this.HieDetails.setCompanyParentVal(parentValue);
          // this.HieDetails.setDealerParentVal(parentValue);
          // const header: INameRole = {
          //   isvParentName: "",
          //   dealerHeaderName: response.name,
          //   companyHeaderName: "",
          //   storeHeaderName: "",
          //   dealerHierarchyHide: true,
          //   companyHierarchyHide: false,
          //   storeHierarchy: false,
          //   breakTypeHide: true
          // }
          // this.HieDetails.setHeaderData(header);

          this.dealerHeaderName = response.name
          this.companyHeaderName = ""
          this.storeHeaderName = ""
          this.dealer = response
          const HierachyValue: IHierachydetails = {
            isv: this.isv,
            dealer: this.dealer,
            company: this.company,
            store: this.store

          }
          this.HieDetails.HierachyDetails(HierachyValue);

          // this.HieDetails.checkCompanyHierarchydata(response.childHierarchyList);
          // this.HieDetails.checkStoreHierarchydata([]);
        } else if (response.typeName == "Company" || this.tenantTypeId == 3) {
          // const parentValue: IHierarchySubject = {
          //   id: response.id,
          //   name: response.name,
          //   typeId: response.typeId,
          //   typeName: response.typeName,
          //   isActive: response.isActive,
          //   parentTenantId: response.parentTenantId
          // }
          // this.HieDetails.setStoreParentVal(parentValue);
          // this.HieDetails.setDealerParentVal(parentValue);
          this.companyHeaderName = response.name
          this.storeHeaderName = ""
          this.company = response
          const HierachyValue: IHierachydetails = {
            isv: this.isv,
            dealer: this.dealer,
            company: this.company,
            store: this.store

          }
          this.HieDetails.HierachyDetails(HierachyValue);

          let dealerHeaderName = ""; let companyHeaderName = "";
          if (response.parentHierarchyInfo[0].typeName == "Dealer") {
            dealerHeaderName = response.parentHierarchyInfo[0].name;
          }
          // if (response.parentHierarchyInfo[1].typeName == "Dealer") {
          //   dealerHeaderName = response.parentHierarchyInfo[1].name;
          // }
          // const header: INameRole = {
          //   isvParentName: "",
          //   dealerHeaderName: dealerHeaderName,
          //   companyHeaderName: response.name,
          //   storeHeaderName: "",
          //   dealerHierarchyHide: true,
          //   companyHierarchyHide: true,
          //   storeHierarchy: false,
          //   breakTypeHide: true
          // }
          // this.HieDetails.setHeaderData(header);
          // this.HieDetails.checkStoreHierarchydata(response.childHierarchyList);
        } else if (response.typeName == "Store" || this.tenantTypeId == 4) {
          let dealerHeaderName = ""; let companyHeaderName = "";
          ///
          if (response.parentHierarchyInfo[0].typeName == "Dealer") {
            dealerHeaderName = response.parentHierarchyInfo[0].name;
          } if (response.parentHierarchyInfo[0].typeName == "Company") {
            companyHeaderName = response.parentHierarchyInfo[0].name;
          }
          if (response.parentHierarchyInfo[1].typeName == "Dealer") {
            dealerHeaderName = response.parentHierarchyInfo[1].name;
          } else if (response.parentHierarchyInfo[1].typeName == "Company") {
            companyHeaderName = response.parentHierarchyInfo[1].name;
          }
          ////
          // const header: INameRole = {
          //   isvParentName: "",
          //   dealerHeaderName: dealerHeaderName,
          //   companyHeaderName: companyHeaderName,
          //   storeHeaderName: response.name,
          //   dealerHierarchyHide: true,
          //   companyHierarchyHide: true,
          //   storeHierarchy: true,
          //   breakTypeHide: false
          // }
          // this.HieDetails.setHeaderData(header);
          this.storeHeaderName = response.name
          this.store = response
          const HierachyValue: IHierachydetails = {
            isv: this.isv,
            dealer: this.dealer,
            company: this.company,
            store: this.store

          }
          // this.loginInfo.tenantId = this.hierachy.id
          this.HieDetails.HierachyDetails(HierachyValue);
        }
        //localStorage.clear();
        //this.dialogser.ClearSession();
        this.router.navigate(['/theme']);
      })
  }
}