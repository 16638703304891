<div class="time-body">
    <div class="loginForm form-switch table_forms">
        <app-giftcard-filter (ReceivedData)="GetCreditCardDetails($event)"></app-giftcard-filter>
        <div class="footer-table Communication_tab mb-2">
            <section class="d-flex justify-content-between outshadow p-1">
                <div class="back-img" *ngIf="firstTableShow == true">
                    <!-- <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(1)"
                        class="img-fluid align-self-center"> -->
                    <!-- <span>{{firstTableName}}</span> >> <span>{{auditName}}</span> -->
                </div>
                <div class="back-img align-self-center" *ngIf="secondTableShow == true">
                    <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(2)"
                        class="img-fluid align-self-center">
                    <p><span>{{firstTableName}}</span></p>
                </div>
                <div class="back-img align-self-center" *ngIf="thirdTableShow == true">
                    <img src="assets/components/images/back-button.png" alt="Back" (click)="onBackClick(3)"
                        class="img-fluid align-self-center">
                    <p><span>{{firstTableName}}</span> <span class="fa fa-angle-double-right"></span>
                        <span>{{auditName}}</span></p>
                </div>
                <h6 class="mb-0 align-self-center">
                    <span *ngIf="firstTableShow == true">SUMMARY</span>
                    <span *ngIf="secondTableShow == true">DETAILS</span>
                    <span *ngIf="thirdTableShow == true">AUDIT</span>
                </h6>

                <div class="d-flex no-gutter mb-0">
                    <div class="salesreconciliation"><img src="assets/components/images/gen-icon-export.png" alt="sales"
                            class="img-fluid cash_icon"></div>
                    <div class="salesreconciliation"><img src="assets/components/images/gen-icon-dashboard.png"
                            alt="sales" class="img-fluid cash_icon_w90"></div>
                </div>
            </section>
            <div *ngIf="firstTableShow == true ">
                <app-gift-card-center-footer [dataSource]="dataSource1" [displayedColumns]="displayedColumns1"
                    [columns]="columns1" [firstTableShow]="firstTableShow" (cardnumber)="onClickCard($event)" (tableItem)="onChangeRow($event)">
                </app-gift-card-center-footer>
            </div>
            <div *ngIf="secondTableShow == true">
                <app-gift-card-center-footer [dataSource]="dataSource2" [displayedColumns]="displayedColumns2"
                    [columns]="columns2" [secondTableShow]="secondTableShow" (cardnumber)="onClickCheck($event)" (tableItem)="onChangeRow($event)">
                </app-gift-card-center-footer>
            </div>
            <div *ngIf="thirdTableShow == true">
                <app-giftcard-audit-report [transactions]="transactions" [displayedColumns]="displayedColumns"
                [columns3]="columns3" [dataSource3]="dataSource3" [displayedColumns3]="displayedColumns3" [auditName]="auditName" [Filtertransactions]="Filtertransactions" [netTotal]="netTotal" [subTotal]="subTotal" [TipsAmount] = "TipsAmount" [discountTotal]="discountTotal" (tableRowvalue)="onChangeRow($event)" [thirdTableShow]="thirdTableShow" [CustomerDetails]="CustomerDetails" [auditEmployee]="auditEmployee" [auditDate]="auditDate" [auditTime]="auditTime"></app-giftcard-audit-report>
                
            </div>
        </div>
    </div>
</div>