import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Subject, Observable, interval, BehaviorSubject } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

const STORE_KEY = 'userLastAction';

@Injectable({
  providedIn: 'root'
})
export class AutotimerService {
  private userLoggedIn = new Subject<boolean>();

  constructor() {
    this.userLoggedIn.next(false);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }
}