import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import {
  GetModifier, DeleteModifier, GetItemtype,
  ModifierBuilderLayout, EditModifierPrice, ModifierPrice, IPreModifiersPricing
} from 'src/app/interfaces/imenu';
import { EmployeeService } from 'src/app/services/employee.service';
import { MenuService } from 'src/app/services/menu.service';
import { ModifierService } from 'src/app/services/modifier.service';
import { MenuModifierGeneralComponent } from '../menu-modifier-general/menu-modifier-general.component';
import { DragDropBuilderComponent } from 'src/app/elements/drag-drop-builder/drag-drop-builder.component';
import { DynamicEditableTableComponent } from 'src/app/elements/dynamic-editable-table/dynamic-editable-table.component';
import { ItemsAssignedModifierComponent } from '../items-assigned-modifier/items-assigned-modifier.component';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-menu-modifier',
  templateUrl: './menu-modifier.component.html',
  styleUrls: ['./menu-modifier.component.css']
})

export class MenuModifierComponent implements OnInit {
  private buttonDisable: boolean;
  @ViewChild(DragDropBuilderComponent) DragDropComp: DragDropBuilderComponent;
  @ViewChild(ItemsAssignedModifierComponent) ItmAssigComp: ItemsAssignedModifierComponent;
  @ViewChild(MenuModifierGeneralComponent) ModgenComp: MenuModifierGeneralComponent;
  @ViewChild(DynamicEditableTableComponent) DynEditComp: DynamicEditableTableComponent;
  @ViewChild(FliptableComponent) flipcomp: FliptableComponent;

  public tableRowColor: any;
  public tableRowId: any;
  public displayedColumns = [];
  public dataSource = new MatTableDataSource<any>();
  public ModLayoutObjCount: any = 0;
  public menuName: any;
  ModifierName: any;
  @Input('connectedTo')
  connectedTo: string[];
  public dragDataItem: any;
  columns = [];
  indexExpanded: number = 0;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>;
  @Output() public generalValueemit = new EventEmitter
  @Output() public areaIdEmit = new EventEmitter()

  tableEnable: boolean = true;
  public ItemList: any;
  public Item_obj: any;
  loginInfo: any;
  tenantId: any;
  data = [];
  columnNames: any;
  public isFirst: any = 0;
  public getAreaID: any;
  public menuModifierRow: any;
  public getDataValue: any;
  public modifierItemData: any;
  public SalesTableData: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public mainItemList: any = [];
  isInvalid: boolean = false;
  buttonStatus: any;
  PageName: any;

  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public ModifierData = new EventEmitter();
  @Output() public mnumodsessData = new EventEmitter();

  //@Input() public buttonDisable;
  clickUpdated: any
  submittedGeneral: any;
  modifiergeneralData: any;
  minBool: boolean = false;
  public modifierItemsData: any;
  private onDestroy$: Subject<void> = new Subject<void>();
  matopen:boolean = false;
  matstate($event){
    this.matopen =$event
  }
  constructor(private Helper: HelperService, private menuService: EmployeeService,
    private changeDetector: ChangeDetectorRef, private dialogser: DialogService, private toaster: ToasterService,
    private helper: HelperService, private objItem: MenuService, private objGroup: ModifierService) { }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.MenuModifierPage");

    this.PageName = "MenuModifierPage";
    this.buttonStatus = false;
    this.buttonDisable = false;
    this.employeeName = "Modifier Name";
    this.employee = "Modifier";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.tableEnable = true;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.getDataArea(this.isFirst);
    this.getMenuItemList();
    this.getAllOwners();
    this.menuService.clickValues.pipe(takeUntil(this.onDestroy$)).subscribe(data => this.clickUpdated = data), err => console.error('Error', err),
      () => console.log('HTTP request completed.');
    if (this.helper.getValue('OnDirty') == false && this.helper.getValue('OnDirty2') == false
      && this.helper.getValue('OnDirty3') == false && this.helper.getValue('OnDirty4') == false) {
      this.tableRowColor = 'transparent';
      this.dialogser.ClearSession();
    }
  }
  @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef<any>;
  public scrollToTop() {
    this.scroll.nativeElement.scrollTop = 0;
  }

  @Input()
  public set modifierScreenItem(modifierScreenItemData) {
    this.modifierItemsData = modifierScreenItemData;
  }

  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.addEmployee();
    }
  }

  ClearPageData() {
    this.dialogser.ClearSession();
    this.flipcomp.rowClicked = "transparent";
    this.ModgenComp.errorMessage = '';
    this.ModgenComp.errorMessage1 = '';
    this.DragDropComp.IngrdientItem = [];
    this.ItmAssigComp.FinalItemList = [];
    this.tableRowId = 0;
    this.ModgenComp.errorValue = "";
    this.isInvalid = false;
    this.ModgenComp.requiredError = false;
    this.minBool = false;

    this.ModgenComp.ModifierGeneralForm.reset(); // should reset the form in child component
    this.DragDropComp.ngOnInit();
    //this.ngOnInit();
    this.employeeName = "Menu Builder";
    // this.menuModifierRow = 'transparent';
    this.tableRowColor = 'transparent';
    this.titleName.emit(this.employeeName)
    this.ItmAssigComp.CleanSeachBox();
    this.objItem.getSourceBuilder().pipe(takeUntil(this.onDestroy$)).subscribe(
      (data) => {
        this.DragDropComp.targetBuilderTools = data
      }), err => console.error('Error', err), () => console.log('HTTP request completed.');
    this.ModgenComp.submittedGeneral = false;
    this.ItmAssigComp.itemAssignedList = null;
    this.ItmAssigComp.getMenuItemList(0);
    this.ItmAssigComp.GetItemAssignedList(0);
    this.DynEditComp.data = null;
    this.DynEditComp.columnNames = null;
    this.getDataValue = 0;
    this.ModgenComp.menuId = 0;
    this.DragDropComp.dropCounter = 0;
    this.ModLayoutObjCount = 0;
    this.getMenuItemList();
  }

  async addEmployee() {
    if (this.helper.getValue('OnDirty') == false && this.helper.getValue('OnDirty2') == false
      && this.helper.getValue('OnDirty3') == false && this.helper.getValue('OnDirty4') == false) {
      this.ClearPageData();
      if(this.indexExpanded != undefined && this.indexExpanded !=0){
        this.expandFirst('General',0);
      }

    }
    else {
      var conf = await this.dialogser.confirm("MenuModifierPage");
      if (conf == 'success') { // Navigate Away
        if(this.indexExpanded != undefined && this.indexExpanded !=0){
          this.expandFirst('General',0);
        }
        this.ClearPageData();
      }
      else {
        this.ModgenComp.ModifierGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.tableRowId;
      }
    }
  }

  @Input()
  public set MenuEmit(MenuModEmit) {
    this.modifierItemData = MenuModEmit;
  }

  @Input()
  public set modifierData(modifierData) {
    this.modifiergeneralData = modifierData;
  }

  @Input()
  public set getDataSource(event) {
    this.getDataValue = event;
  }

  getDragItem(event) {
    this.dragDataItem = event;
  }

  getDataArea(event) {
    this.getAreaID = event;
    this.Item_obj = event;
    this.areaIdEmit.emit(this.getAreaID);
  }

  async getTableIdx(event) {
    //this.gentab.isFormPageDirty();
    if (this.helper.getValue('OnDirty') == true || this.helper.getValue('OnDirty2') == true
      || this.helper.getValue('OnDirty3') == true || this.helper.getValue('OnDirty4') == true) {
      var conf = await this.dialogser.confirm("MenuModifierPage");
      if (conf == 'success') { // Navigate Away
        if (event == this.dialogser.ModifiedId) {
          this.ModgenComp.GetModifierGeneralEditList(event);
        }
        this.dialogser.ClearSession();
        this.ModgenComp.ModifierGeneralForm.reset();
        this.DragDropComp.ngOnInit();
        //General Data
        this.dialogser.ModifiedId = event;
        this.Helper.setValue('Sessionid_help', event);
        this.tableRowId = event;
        this.tableRowColor = event;
        this.getDataValue = event;
        this.ItmAssigComp.CleanSeachBox();
        this.getMenuItemList();
        this.ItemObj(event);
        this.ModgenComp.requiredError = false;
        this.isInvalid = false;
        this.minBool = false;
        this.ModgenComp.errorValue = "";
        this.flipcomp.rowClicked = event;
        this.ModgenComp.errorMessage = '';
        this.ModgenComp.errorMessage1 = '';
        if(this.indexExpanded != undefined && this.indexExpanded !=0){
          this.expandFirst('General',0);
        }
      }
      else // Cancel
      {
        //General Data
        this.ModgenComp.ModifierGeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
        this.flipcomp.rowClicked = this.tableRowId;

        this.mnumodsessData.emit({
          data: this.ModgenComp.ModifierGeneralForm.value, event: "MenuModifierPage", val: true,
          itmname: this.ModgenComp.ModifierGeneralForm.value.name
        });
      }
    }
    else {
      if(this.indexExpanded != undefined && this.indexExpanded !=0){
        this.expandFirst('General',0);
      }
      this.dialogser.ModifiedId = event;
      this.Helper.setValue('Sessionid_help', event);
      this.tableRowId = event;
      this.tableRowColor = event;
      this.getDataValue = event;
      // this.DragDropComp.IngrdientItem=[];
      // this.ItmAssigComp.FinalItemList=[];
      this.ItmAssigComp.CleanSeachBox();
      this.getMenuItemList();
      this.ItmAssigComp.getMenuItemList(0);
      this.ItemObj(event);
      this.ModgenComp.requiredError = false;
      this.isInvalid = false;
      this.minBool = false;
      this.DragDropComp.dropCounter = 0;
      // this.getmodifierPrice(event);
      // this.PreModifierPrice(event,this.DragDropComp.IngrdientItem)
      this.ModgenComp.errorValue = "";
      this.ModgenComp.errorMessage = '';
      this.ModgenComp.errorMessage1 = '';
    }
  }

  getTableName(name) {
    this.employeeName = name;
    this.employee = "Modifier";
    this.employeeIcon = "assets/components/images/path.png";
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }

  getAllOwners() {
    const menuGroupList: GetModifier = {
      id: 0,
      tenantId: this.loginInfo.tenantId
    }
    this.objGroup.ModifierGroupGet(menuGroupList)
      .pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.dataSource.data = res['ModifierGroup'];
        this.SalesTableData = res['ModifierGroup'];
        this.dataSource.data = this.dataSource.data.filter(x => x.isActive == true).sort((a, b) => (a.id > b.id ? -1 : 1)); // decending order
        this.displayedColumns = ['name', 'isActive'];
        this.columns = [
          { columnDef: 'name', header: 'Modifier Group' },
          { columnDef: 'isActive', header: 'Status' }
        ];
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')

    if (this.helper.getValue('Sessionid_help') == undefined ||
      this.helper.getValue('Sessionid_help') == null ||
      this.helper.getValue('Sessionid_help') == "") {
      this.helper.setValue('Sessionid_help', 0)
    }
    else {
      //this.flipcomp.rowClicked = this.helper.getValue('Sessionid_help');
      this.tableRowColor = parseInt(this.helper.getValue('Sessionid_help'));
    }
  }

  getMenuItemList() {
    //  if (this.tableRowId != undefined && this.tableRowId != null) {
    const ItemTypeList: GetItemtype = {
      itemId: this.tableRowId == undefined ? 0 : this.tableRowId == null ? 0 : this.tableRowId,
      itemType: 'LayoutBuilder',
      tenantId: this.loginInfo.tenantId,
    }
    this.objItem.GetItemTypeList(ItemTypeList)
      .pipe(takeUntil(this.onDestroy$)).subscribe(res => {
        this.ItemList = res;
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  async onSaveItem(event): Promise<any> {
    return new Promise((resolve, reject) => {
      if (event !== undefined) {
        let JsonValue = event.itemObject;
        let id = this.helper.getValue('Sessionid_help');
        function countOccurences(string, word) {
          return string.split(word).length - 1;
        }
        var strVal = JSON.stringify(JsonValue);
        var count = countOccurences(strVal.toString(), '"id');
        var _minChoice = this.ModgenComp.ModifierGeneralForm.controls.Min_Choices.value;
        var _maxChoice = this.ModgenComp.ModifierGeneralForm.controls.Max_Choices.value;

        if ((count >= parseInt(_minChoice) || _minChoice == 0)) {
          const ModifierlayoutList: ModifierBuilderLayout = {
            parentID: id,
            userid: this.loginInfo.userId,
            tenantId: this.loginInfo.tenantId,
            itemObject: JsonValue,
          }
          this.objGroup.ModifierLayoutBuilderSave(ModifierlayoutList)
            .pipe(takeUntil(this.onDestroy$)).subscribe(async res => {
              // this.toaster.successPopUp(res.message);
              // this.getmodifierPrice(this.helper.getValue('Sessionid_help'));

              this.onSavePrice(this.DynEditComp.OnSave(this.helper.getValue('Sessionid_help')));
              this.getAllOwners();

              await this.dialogser.ClearSession();
              resolve({ res: res });
            }), err => console.error('Error', err), () => console.log('HTTP request completed.');
        }
      }

      if (_minChoice != 0) {
        if (count < parseInt(_minChoice)) {
          this.minBool = true;
          this.toaster.errorPopUp('Error! The number of items is less than the minimum. Please correct.');
        }
        else {
          this.minBool = false;
          this.getAllOwners();
        }
      }
    });
  }

  onSavePrice(event) {
    if (event != undefined) {
      let JsonValue = event.itemObject;
      const ModifierlayoutList: EditModifierPrice = {
        parentID: this.helper.getValue('Sessionid_help'),
        userid: this.loginInfo.userId,
        sizeObject: JsonValue,
        tenantId: this.loginInfo.tenantId
      }
      this.objGroup.ModifierPricingEdit(ModifierlayoutList).pipe(takeUntil(this.onDestroy$)).
        subscribe(res => {
          //   this.toaster.successPopUp(res.message);
        }), err => console.error('Error', err), () => console.log('HTTP request completed.');
    }
  }

  async getGeneralDetail(event) {
    this.tableRowId = event.res.id;
    let SaveItem = await this.ItmAssigComp.SaveItemAssignedModifier(this.helper.getValue('Sessionid_help'));
    this.flipcomp.selectTableRowColor(event.res.id, event.res.id, event.name);
    let dragDrop = await this.onSaveItem(this.DragDropComp.OnSave(''));
  }

  checkvaluePage(event) {
    let currentTarget = event.e.target.checked //event.target.checked
    const active: DeleteModifier = {
      userid: this.loginInfo.userId,
      id: event.id,
      status: event.e.target.checked
    }
    this.objGroup.ModifierGroupDelete(active).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        this.getAllOwners();
          this.addEmployee();
        this.toaster.successPopUp(response.message);
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  expandPanel(index,e?) {
    this.indexExpanded = index;
  }
  expandFirst(e,index){
    if(e == 'General'){
      this.indexExpanded = index == this.indexExpanded ? -1 : index;
    }
  }
  // onChangeAccordion(e, index) {
  //   this.indexExpanded = index;
  // }

  onChangeAccordion(e) {
    if (e.currentTarget.innerText == "General") {
      this.ModifierName = ('General');
      this.menuName = e.currentTarget.innerText
      this.onSavePrice(this.DynEditComp.OnSave(this.helper.getValue('Sessionid_help')));
    }
    if (e.currentTarget.innerText == "Layout Builder") {
      this.ModifierName = ('LayoutBuilder');
      this.menuName = e.currentTarget.innerText
      this.onSavePrice(this.DynEditComp.OnSave(this.helper.getValue('Sessionid_help')));
    }
    if (e.currentTarget.innerText == "Items Assigned to Modifier Group") {
      this.ModifierName = ('ItemsAssigned');
      this.menuName = e.currentTarget.innerText
      this.onSavePrice(this.DynEditComp.OnSave(this.helper.getValue('Sessionid_help')));
    }
    if (e.currentTarget.innerText == "Pricing") {
      this.ModifierName = ('Pricing');
      this.menuName = e.currentTarget.innerText;
      //console.log(this.DragDropComp.IngrdientItem.toString(), 'IngrdientItem');
      //console.log(this.ItmAssigComp.FinalItemList, 'FinalItemList');
      this.mainItemList = (this.ItmAssigComp.FinalItemList.map(x => x.id));
      //console.log(this.mainItemList[0], 'this.mainItemList')
      if (this.mainItemList[0] == undefined) {
        this.PreModifierPrice(e, this.DragDropComp.IngrdientItem.toString(), this.ItmAssigComp.FinalItemList.toString());
      }
      else {
        this.PreModifierPrice(e, this.DragDropComp.IngrdientItem.toString(), this.mainItemList.toString());
      }
      this.onSavePrice(this.DynEditComp.OnSave(this.helper.getValue('Sessionid_help')));
      //console.log(this.mainItemList.toString(),'Main Items')
    }
  }

  //-------------------------------

  ItemObj(itemId) {
    const ItemList: GetModifier = {
      id: itemId,
      tenantId: this.loginInfo.tenantId,
    }
    this.objGroup.ModifierGroupGet(ItemList).pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        if (res["ModifierGroup"][0].Item_Object != undefined && res["ModifierGroup"][0].Item_Object != null
          && res["ModifierGroup"][0].Item_Object != "" && res["ModifierGroup"][0].Item_Object != "[]" && res["ModifierGroup"][0].Item_Object != "[]") {
          var arrdata = JSON.parse(res["ModifierGroup"][0].Item_Object);
          this.DragDropComp.targetBuilderTools = null;
          this.DragDropComp.targetBuilderTools = arrdata;
          this.DragDropComp.IngrdientItem = res.ModifierItemTransactions.length == 0 ? '' : res.ModifierItemTransactions.map(x => x.ItemID);
          this.ItmAssigComp.FinalItemList = res.ModifierAssignedItem.length == 0 ? '' : res.ModifierAssignedItem.map(x => x.itemID);
          this.PreModifierPrice(0, this.DragDropComp.IngrdientItem.toString(), this.ItmAssigComp.FinalItemList.toString());
        }
        else {
          this.objItem.getSourceBuilder().pipe(takeUntil(this.onDestroy$)).subscribe(
            (data) => {
              this.DragDropComp.targetBuilderTools = data;
            });
        }
        this.ModLayoutObjCount = res.ModifierItemTransactions.length;
      }
      ), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  getmodifierPrice(event?) {
    if (event != undefined) {
      this.tableRowId = event
    }
    else {
      this.tableRowId = this.helper.getValue('Sessionid_help');
    }
    const modifierPriceInput: ModifierPrice = {
      id: this.tableRowId,
      tenantId: this.loginInfo.tenantId,
      userid: this.loginInfo.userId,
    }
    this.objGroup.ModifierPricingSelect(modifierPriceInput).pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        if (res != undefined && res != null && res != "") {
          this.DynEditComp.data = res;
          this.DynEditComp.columnNames = Object.keys(res[0]);
        }
        else {
          this.DynEditComp.data = null;
          this.DynEditComp.columnNames = null;
        }
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  PreModifierPrice(event?, itemList?, mainItem?) {
    // if (event != undefined) {
    //   this.tableRowId = event
    // }
    // else {
    //   this.tableRowId = this.helper.getValue('Sessionid_help');
    // }

    const modifierPriceInput: IPreModifiersPricing = {
      modifierid: this.tableRowId,
      tenantId: this.loginInfo.tenantId,
      mainItemID: mainItem,
      itemId: itemList,
    }

    this.objGroup.SelectPreModifierPricing(modifierPriceInput).pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        if (res != undefined && res != null && res != "") {
          this.DynEditComp.data = res;
          this.DynEditComp.columnNames = Object.keys(res[0]);
        }
        else {
          this.DynEditComp.data = null;
          this.DynEditComp.columnNames = null;
        }
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  btnActiveStatus(event) {
    this.buttonDisable = event;
  }

  @Input()
  public set buttonState(btnst) {
    this.buttonStatus = !btnst;
  }

  OnSave() {
    if (this.ModgenComp.ModifierGeneralForm.invalid) {
      this.isInvalid = true;
    }
    else if ((this.ModgenComp.ModifierGeneralForm.value.Category == null) ||
      (this.ModgenComp.ModifierGeneralForm.value.Category == "")) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }
    var _minChoice = this.ModgenComp.ModifierGeneralForm.controls.Min_Choices.value;
    var _maxChoice = this.ModgenComp.ModifierGeneralForm.controls.Max_Choices.value;

    var _minChoiceIntVal = _minChoice == null ? 0 : _minChoice;
    var _maxChoiceIntVal = _maxChoice == null ? 0 : _maxChoice;
    var _LayoutCount = this.DragDropComp.LayoutCounterMethod(this.ModLayoutObjCount);

    if (_minChoice == null || _maxChoice == null || parseInt(_minChoice) == 0 || parseInt(_maxChoice) == 0) {
      this.minBool = false;
      this.ModgenComp.OnSaveModifierGroup();
      this.tableRowId = this.ModgenComp.menuId;
    }
    else {
      if (parseInt(_minChoiceIntVal) > 0) {
        if (_LayoutCount >= parseInt(_minChoiceIntVal)) {
          this.minBool = false;
          this.ModgenComp.OnSaveModifierGroup();
          this.tableRowId = this.ModgenComp.menuId;
        }
        else {
          this.minBool = true;
          this.toaster.errorPopUp('Error! The number of items is less than the minimum. Please correct.');
        }
      }
    }
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.DragDropComp.targetBuilderTools = null;
    this.DragDropComp.targetBuilderTools = this.helper.getValue("dragedModifer")
    this.ItemList = this.helper.getValue("dragedTable")
    this.ModifierData.emit({ data: this.DragDropComp.targetBuilderTools, event: "MenuModifierPage", val: false })
  }

  getmnumodsessData(e) {
    this.mnumodsessData.emit(e)
  }
}
