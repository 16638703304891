<div>
    <div>
      <button class="plus_btn outshadow" (click) = "addTimeEditAddpunchModal()" ><i class="fa fa-plus"></i></button>
    </div>
    <hr class="linebar"> 
    <app-time-edit-model [dataTimeModal]="timeEditModal" [jobModelIndex]="jobModelID" (editModelItem)="getEditTableDetail($event)"
    (timemodelEvent)="openModalEvent($event)" [buttonDis]="buttonState" [SaveStatus]="OnSaveBit" ></app-time-edit-model>
</div>

<div class="table-container flipAccordion time-edit-flip">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort
        class="mat-elevation-z8 table employee-table employee dataTable">
        <ng-container matColumnDef="{{item.columnDef}}" *ngFor="let item of columns ">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="emp_name"> {{item.header}} </th>
            <td mat-cell *matCellDef="let element; let idx=index;">
                <div class="emp_name" [style.background-color]="rowClicked == element.timeClockId ? '#341a46' : 'transparent'"
                    *ngIf="item.columnDef == 'name' || item.columnDef == 'employeeName'"> {{ element[item.columnDef] }}</div>

                <div *ngIf="item.columnDef != 'name' && item.columnDef != 'isActive' && item.columnDef != 'employeeName'" class="emp-name">
                    {{ element[item.columnDef] }}</div>
                <div class="checkbox-table emp-name" *ngIf="item.columnDef == 'isActive'">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input active" name="customCheckbox"
                            [checked]="element[item.columnDef]" (change)="checkValue($event,element.id)">
                        <label class="custom-control-label active"></label>
                    </div>
                </div>
            </td>
        </ng-container>         
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let detail" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail" *ngIf="empTimeID == detail.timeClockId"
                    [@detailExpand]="detail == expandedElement ? 'expanded' : 'collapsed'">                    
                    <div class="example-element-description">
                            <app-time-edit-model [clickRowIndex]="empTimeID" [clickRowUserIndex]="empTimeJobID" [dataTimeAccordion]="timeEditAccordion"
                            (editModelItem)="getEditTableDetail($event)" (timeeditData_child)="gettimeeditData($event)"></app-time-edit-model>
                        </div>
                </div>
            </td>
        </ng-container>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell employeenodata" colspan="12">No data matching the search</td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
        (click)="changeTableRowColor(row.timeClockId,row.employeeId,row.employeeFirstName); 
        expandedElement = expandedElement === row ? row : row"
            [ngClass]="{'highlight': selectedRowIndex == row.timeClockId}"
            [class.example-expanded-row]="expandedElement === row"
            ></tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>
</div>