<form class="SaleArea_general scheduleForm loginForm adjustment_detail" [formGroup]="ScheduleForm" (change)="onchange(ScheduleForm.value)">
    <div class="border_sec row">
        <div class="col-sm-2 col-4" *ngFor="let item of scheduleList">
            <div class="form-group d-flex">
                <label class="radiolabel">{{item.name}}</label>
                <div class="custom-control custom-radio custom-control item">
                    <input type="radio" (click)="clearOtherSchedule(item,$event)" (change)=clearEndDate() value="{{item.value}}" id="{{item.name}}" class="custom-control-input" formControlName="scheduleRadio" name="scheduleRadio">
                    <label class="custom-control-label QR" for="{{item.name}}"></label>
                </div>
            </div>
        </div>
    </div>
    <div class="border_sec row py-2">
        <div class="col-sm-12" *ngIf="this.ScheduleForm.get('scheduleRadio').value != 'optCustom'">
            <div class="form-group d-flex mt-2">
                <label class="radiolabel">Frequency Every</label><label class="text-danger">*</label>
                <div class="col-sm-2">
                    <input type="text" class="form-control" formControlName="frequency" appInputRestriction>
                </div>
                <label *ngIf="this.ScheduleForm.get('scheduleRadio').value == 'optDaily'">Day(s)</label>
                <label *ngIf="this.ScheduleForm.get('scheduleRadio').value == 'optWeekly'">Week(s)</label>
                <label *ngIf="this.ScheduleForm.get('scheduleRadio').value == 'optMonthly'">Month(s)</label>
                <label *ngIf="this.ScheduleForm.get('scheduleRadio').value == 'optYearly'">Year(s)</label>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="row py-2">
                <div class="col-sm-4 col-md-5 col-6">
                    <div class="form-group" style="width:70%">
                        <label class="customlable mb-0">Start Date</label><label class="text-danger">*</label>
                        <mat-form-field appearance="fill" class="custom-date">
                            <input matInput [matDatepicker]="picker" placeholder="Start Date" (dateChange)="onchange(ScheduleForm.value)" class="form-control datepicker appInputRestriction" formControlName="startDate" (click)="picker.open()" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.startDate.errors }">
                            <mat-datepicker #picker class="example-header"></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="submittedGeneral && GeneralData.startDate.errors" class="invalid-feedback">
                            <div *ngIf="GeneralData.startDate.errors.required">start Date is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group d-flex">
                                <label class="radiolabel">No End</label>
                                <div class="custom-control custom-radio custom-control item">
                                    <input type="radio" (change)=clearEndDate() value="noEnd" id="noEnd" class="custom-control-input" formControlName="dateRadio" name="dateRadio">
                                    <label class="custom-control-label QR" for="noEnd"></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-6">
                            <div class="form-group d-flex">
                                <label class="radiolabel">End Date</label>
                                <div class="custom-control custom-radio custom-control item">
                                    <input type="radio" value="EndDate" id="EndDate" class="custom-control-input" (change)=clearEndDate() formControlName="dateRadio" name="dateRadio">
                                    <label class="custom-control-label QR" for="EndDate"></label>
                                </div>
                            </div>
                            <mat-form-field appearance="fill" class="custom-date endDate" *ngIf="this.ScheduleForm.get('dateRadio').value == 'EndDate'">
                                <input placeholder="End Date" matInput [matDatepicker]="pickerA" (dateChange)="onchange(ScheduleForm.value)" class="form-control appInputRestriction" formControlName="endDate" formControlName="endDate" name="dateRadio" (click)="pickerA.open()">
                                <mat-datepicker #pickerA class="example-header"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-4 col-6">
                            <div class="form-group d-flex">
                                <label class="radiolabel">End After</label>
                                <div class="custom-control custom-radio custom-control item">
                                    <input type="radio" (change)=clearEndDate() value="EndAfter" id="EndAfter" class="custom-control-input" formControlName="dateRadio" name="dateRadio">
                                    <label class="custom-control-label QR" for="EndAfter"></label>
                                </div>
                            </div>
                            <div class="form-group d-flex" *ngIf="this.ScheduleForm.get('dateRadio').value == 'EndAfter'">
                                <input type="text" class="form-control" formControlName="endAfter" appInputRestriction (keypress)="numberOnly($event)">
                                <label class="radiolabel ml-2">Occurances</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="error" *ngIf="this.ScheduleForm.get('dateRadio').value == 'EndDate'">{{errorMessage}}</div>
        </div>
    </div>

    <div class="border_sec py-2 row" *ngIf="this.ScheduleForm.get('scheduleRadio').value == 'optWeekly'">
        <div class="col-sm-12">
            <div class="checkbox_wrap justify-content-between">
                <div class="form-group m-0 align-self-center">
                    <label class="radiolabel f-16">Repeats Every</label><label class="text-danger">*</label>
                </div>
                <div class="form-group d-inline-block m-0 align-self-center" *ngFor="let item of weekDays">
                    <div class="checkbox-table Week_wrap">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" (change)="onWeekDaysChange(item,$event)" value="{{item.id}}" id="{{item.name}}" [checked]="item.isSelected == true ? true : false">
                            <label class="custom-control-label" for="{{item.name}}">{{item.name}}</label>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="border_sec py-2 row" *ngIf="(this.ScheduleForm.get('scheduleRadio').value == 'optMonthly' ||
  this.ScheduleForm.get('scheduleRadio').value == 'optCustom')">
        <div class="col-sm-4">
            <div class="form-group d-flex">
                <label class="radiolabel">Specific day(s) of the month</label>
                <div class="custom-control custom-radio custom-control item">
                    <input type="radio" value="specificMonth" id="specificMonth" class="custom-control-input" formControlName="weekDates" name="weekDates">
                    <label class="custom-control-label QR" for="specificMonth"></label>
                </div>
            </div>
            <div class="calederDates_wrap" *ngIf="this.ScheduleForm.get('weekDates').value == 'specificMonth'">
                <div class="form-group date_wrap" *ngFor="let x of calenderDates">
                    <input type="checkbox" [checked]="x.isSelected == true ? true : false" (change)="onMonthDaysChange(x,$event)" value="{{x.ID}}" id="{{x.ID}}" class="form-check list-group-items ">
                    <label class="radiolabel" for="{{x.ID}}">{{x.ID}}</label>
                </div>
            </div>
        </div>
        <div class="col-sm-8">
            <div class="form-group d-flex">
                <label class="radiolabel f-16">Repeats Every</label>
                <div class="custom-control custom-radio custom-control item">
                    <input type="radio" value="repeatsEvery" id="repeatsEvery" class="custom-control-input" formControlName="weekDates" name="weekDates">
                    <label class="custom-control-label QR" for="repeatsEvery"></label>
                </div>
            </div>
            <div class="row" *ngIf="this.ScheduleForm.get('weekDates').value == 'repeatsEvery'">
                <div class="col-sm-4">

                    <ng-multiselect-dropdown [settings]="SalesSettings" [data]="WeekAltList" [(ngModel)]="specificselectedItems" [formControl]="repeatWeeks">
                    </ng-multiselect-dropdown>
                </div>
                <div class="col-sm-4">

                    <ng-multiselect-dropdown [settings]="SalesSettings" [data]="WeekDaysList" [(ngModel)]="specificdaysOfTheWeek" [formControl]="daysOfTheWeek">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
    </div>

    <div class="border_sec py-2 row" *ngIf="(this.ScheduleForm.get('scheduleRadio').value == 'optYearly')">
        <div class="col-sm-12">
            <div class="checkbox_wrap selectWeekdays justify-content-between">
                <div class="form-group m-0 align-self-center">
                    <label class="radiolabel f-16">Repeats Every</label><label class="text-danger pr-3">*</label>
                </div>
                <div class="form-group m-0 align-self-center d-inline-block" *ngFor="let items of yearMonth">
                    <input type="checkbox" (change)="onMonthChange(items,$event)" id="{{items.name}}" value="{{items.id}}" class="form-check list-group-items " [checked]="items.isSelected == true ? true : false">
                    <label class="radiolabel" for="{{items.name}}">{{items.name}}</label>
                </div>
            </div>
        </div>

    </div>

    <div class="border_sec py-2 row" *ngIf="(this.ScheduleForm.get('scheduleRadio').value == 'optCustom')">
        <div class="col-sm-12">
            <div class="checkbox_wrap selectWeekdays justify-content-between">
                <div class="form-group m-0 align-self-center">
                    <label class="radiolabel f-16">Repeats Every</label><label class="text-danger pr-3">*</label>
                </div>
                <div class="form-group m-0 align-self-center d-inline-block" *ngFor="let items of yearMonth">
                    <input type="checkbox" (change)="onMonthChange(items,$event)" id="{{items.name}}" value="{{items.id}}" class="form-check list-group-items " [checked]="items.isSelected == true ? true : false">
                    <label class="radiolabel" for="{{items.name}}">{{items.name}}</label>
                </div>

            </div>
        </div>

    </div>
    <div class="row border_sec py-2">
        <div class="col-sm-12">
            <div class="form-group d-flex">
                <label class="radiolabel">Whole Day</label>
                <div class="custom-control custom-radio custom-control item">
                    <input type="radio" value="wholeDay" id="wholeDay" class="custom-control-input" formControlName="timeDays" name="timeDays">
                    <label class="custom-control-label QR" for="wholeDay"></label>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-2">
                    <div class="form-group d-flex">
                        <label class="radiolabel">Specific Time</label>
                        <div class="custom-control custom-radio custom-control item">
                            <input type="radio" value="specificTime" id="specificTime" class="custom-control-input" formControlName="timeDays" name="timeDays">
                            <label class="custom-control-label QR" for="specificTime"></label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-10" [style.display]="this.ScheduleForm.get('timeDays').value == 'specificTime'?'block':'none'">
                    <div class="row">
                        <div class="col-sm-5">
                            <div class="form-group d-flex">
                                <label class="radiolabel mr-2">Start Time</label>
                                <ngx-mat-timepicker-field [controlOnly]="true" formControlName="startTime" class="ngxMatTime">
                                </ngx-mat-timepicker-field>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <div class="form-group d-flex">
                                <label class="radiolabel mr-2">End Time</label>
                                <ngx-mat-timepicker-field [controlOnly]="true" formControlName="endTime" class="ngxMatTime">
                                </ngx-mat-timepicker-field>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-sm-12 text-right">
            <div class="form-group">
                <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="onSaveScheduler();" [disabled]="buttonDisable ? true : null">
          <img src="assets/components/images/save.png" alt="employee" class="">
          <label class="label_config">Save</label>
        </button>
            </div>
        </div>
    </div>
</form>