<div class="tax-history-report">
    <div class="loginForm">
        <app-tax-history-filter (ReceivedData)="GetTaxHistoryDetails($event)"></app-tax-history-filter>
        <div class="footer-table disc-ft Communication_tab mb-2">
            <section class="d-flex justify-content-between outshadow p-1 min-height-head">
                <h6 class="mb-0 align-self-center">
                </h6>              
            </section>
              <app-fliptable-center-footer [dataSource]="dataSource1" [displayedColumns]="displayedColumns1"
                    [columns]="columns1" [firstTableShow]="firstTableShow">
                </app-fliptable-center-footer>
         </div>
    </div>
</div>
