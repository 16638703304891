<form [formGroup]="TimeEditDetailsForm" class="loginForm" (change)="onchange(TimeEditDetailsForm.value)">

    <div class="timeEditModal time-hierachy-tab " [style.display]="timeEditModal ? 'block' : 'none'">

        <div class="modal-dialog">
            <div class="modal-content">
                <button type="button" class="close " data-dismiss="modal" (click)="hideModal()">&times;</button>
                <div class="modal-body">
                    <div class="mat-elevation-z8 time-punch-table">
                        <div class="form-group">
                            <button class="btn btn-sm float-right submit_button" type="submit" (click)="onSaveTimeEdit()">
                <img src="assets/components/images/save.png" alt="employee" class="">
                <label class="label_config">Save</label>
              </button>
                        </div>
                        <div class="text-center " *ngIf="!employeePositionHide">
                            <h3 class="card-title mb-0">{{firstName}} {{LastName}}</h3>
                            <p class="card-text mb-0 pt-1">Date: {{getDate | date:"MM-dd-YYYY"}}</p>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-sm-8">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row" *ngIf="employeePositionHide">
                                            <div class="form-group col-sm-6">
                                                <label class="customlable mb-0">Employee Name</label><label class="text-danger">*</label>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <div class="form-group mat-box">
                                                    <mat-form-field appearance="fill">
                                                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="employeeId" (selectionChange)="getJobByEmp($event.value)" (selectionChange)="onchange(TimeEditDetailsForm.value)" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.employeeId.errors }"
                                                            placeholder="Select Employee">
                                                            <mat-option [value]="" selected disabled>Select Employee</mat-option>
                                                            <mat-option *ngFor="let item of EmpJobName" [value]="item.UserId">{{item.UserFname}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-error *ngIf="submittedGeneral && GeneralDealerData.employeeId.errors" class="invalid-feedback">
                                                        <mat-error *ngIf="GeneralDealerData.employeeId.errors.required">Employee Name is required
                                                        </mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="form-group col-sm-6">
                                                <label class="customlable mb-0">Shift Start Date</label><label class="text-danger">*</label>
                                            </div>
                                            <div class="form-group col-sm-6">

                                                <mat-form-field appearance="fill" class="custom-date">
                                                    <input matInput [matDatepicker]="pickerA" placeholder="Start Date" class="form-control datepicker appInputRestriction" formControlName="punchIn" (click)="pickerA.open()" [min]="minDate" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.punchIn.errors }"
                                                        (dateChange)="onchange(TimeEditDetailsForm.value)">
                                                    <mat-datepicker #pickerA class="example-header"></mat-datepicker>
                                                </mat-form-field>
                                                <div class="error">{{dateMgs}}</div>

                                                <!-- <input type="text" placeholder="Start Date" class="form-control" bsDatepicker
                          formControlName="punchIn" [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" #dpYMD="bsDatepicker"
                          appInputRestriction
                          [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.punchIn.errors }" (dateChange)="onchange(TimeEditDetailsForm.value)"/> -->
                                                <div *ngIf="submittedGeneral && GeneralDealerData.punchIn.errors" class="invalid-feedback">
                                                    <div *ngIf="GeneralDealerData.punchIn.errors.required">Shift Start Date is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="form-group col-sm-6">
                                                <label class="customlable mb-0">Shift End Date</label><label class="text-danger">*</label>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <mat-form-field appearance="fill" class="custom-date">
                                                    <input matInput [matDatepicker]="picker" placeholder="End Date" class="form-control datepicker appInputRestriction" formControlName="punchOut" (click)="picker.open()" [min]="minDate" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.punchOut.errors }"
                                                        (dateChange)="onchange(TimeEditDetailsForm.value)">
                                                    <mat-datepicker #picker class="example-header"></mat-datepicker>
                                                </mat-form-field>
                                                <div class="error">{{dateMgsHire}}</div>

                                                <!-- <input type="text" placeholder="End Date" class="form-control" bsDatepicker
                          formControlName="punchOut"
                          [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" #dpYMD="bsDatepicker"
                          appInputRestriction
                          [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.punchOut.errors }" /> -->
                                                <div *ngIf="submittedGeneral && GeneralDealerData.punchOut.errors" class="invalid-feedback">
                                                    <div *ngIf="GeneralDealerData.punchOut.errors.required">Shift End Date is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="form-group col-sm-6">
                                                <label class="customlable ">Time In</label><label class="text-danger">*</label>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <ngx-mat-timepicker-field [controlOnly]="true" formControlName="timeIn" class="ngxMatTime" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.timeIn.errors }" (focusout)="onchange(TimeEditDetailsForm.value)">
                                                </ngx-mat-timepicker-field>
                                                <div *ngIf="submittedGeneral && GeneralDealerData.timeIn.errors" class="invalid-feedback">
                                                    <div *ngIf="GeneralDealerData.timeIn.errors.required">Time In is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="form-group col-sm-6">
                                                <label class="customlable ">Time Out</label><label class="text-danger">*</label>
                                            </div>
                                            <div class="form-group col-sm-6  ">
                                                <ngx-mat-timepicker-field [controlOnly]="true" formControlName="timeOut" class="ngxMatTime" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.timeOut.errors }" (focusout)="onchange(TimeEditDetailsForm.value)">
                                                </ngx-mat-timepicker-field>
                                                <div *ngIf="submittedGeneral && GeneralDealerData.timeOut.errors" class="invalid-feedback">
                                                    <div *ngIf="GeneralDealerData.timeOut.errors.required">Time Out is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="form-group col-sm-6">
                                                <label class="customlable mb-0">Type</label><label class="text-danger">*</label>
                                            </div>
                                            <div class="form-group col-6">
                                                <mat-form-field appearance="fill">
                                                    <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="typeId" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.typeId.errors }" (selectionChange)="onchange(TimeEditDetailsForm.value)">
                                                        <mat-option value="" disabled>Select Type</mat-option>
                                                        <mat-option *ngFor="let item of ClockList" [value]="item.typeId">{{item.typeName}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <div *ngIf="submittedGeneral && GeneralDealerData.typeId.errors" class="invalid-feedback">
                                                    <div *ngIf="GeneralDealerData.typeId.errors.required">Shift Start Date is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row" [style.display]="TimeEditDetailsForm.get('typeId').value == 'B03' ? 'flex' : 'none'">
                                            <div class="form-group col-sm-6">
                                                <label class="customlable mb-0">Break</label>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <mat-form-field appearance="fill">
                                                    <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="breaktypeID" (selectionChange)="onchange(TimeEditDetailsForm.value)">
                                                        <mat-option [value]="0" disabled selected>Select Break</mat-option>
                                                        <mat-option *ngFor="let item of BreakList" [value]="item.id">{{item.name}}
                                                        </mat-option>

                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="form-group col-sm-6">
                                                <label class="customlable mb-0">Job Position</label><label class="text-danger">*</label>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <mat-form-field appearance="fill">
                                                    <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="jobPositionID" [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.jobPositionID.errors }" (selectionChange)="onchange(TimeEditDetailsForm.value)">
                                                        <mat-option [value]="" selected disabled>Job Position</mat-option>
                                                        <mat-option *ngFor="let item of JobPositionList" [value]="item.roleId">{{item.roleName}}
                                                        </mat-option>
                                                    </mat-select>

                                                </mat-form-field>
                                                <mat-error *ngIf="submittedGeneral && GeneralDealerData.jobPositionID.errors" class="invalid-feedback">
                                                    <mat-error *ngIf="GeneralDealerData.jobPositionID.errors.required">Job Position is required
                                                    </mat-error>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="form-group col-sm-6">
                                                <label class="customlable mb-0">Wages</label>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <mat-form-field appearance="fill">
                                                    <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="wageid" (selectionChange)="onchange(TimeEditDetailsForm.value)">
                                                        <mat-option [value]="" disabled>Select Wage</mat-option>
                                                        <mat-option *ngFor="let item of WageList" [value]="item.typeId">{{item.typeName}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="form-group col-sm-6">
                                                <label class="customlable mb-0">Tips</label>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <input type="text" class="form-control input-lg inputbox_login" placeholder="" formControlName="tips" appInputRestriction maxlength="500" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">

                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 text-center " [style.display]="punchInOut ? 'block' : 'none'">
                                <h6 class="">Punch In Verification</h6>
                                <div class="updateTime punchInVerification">
                                    <a target="_blank" href="{{punchInImage}}"> <img src="{{punchInImage}}" alt="" class="img-fluid"> </a>
                                    <!-- <img src="assets/components/images/picture-upload-2-x.png" alt="" class="img-fluid"> -->
                                </div>
                                <h6 class="">Punch Out Verification</h6>
                                <div class="updateTime punchOutVerification">
                                    <a target="_blank" href="{{punchOutImage}}"> <img src="{{punchOutImage}}" alt="" class="img-fluid"> </a>
                                    <!-- <img src="{{punchOutImage}}" alt="" class="img-fluid"> -->
                                    <!-- <img src="assets/components/images/picture-upload-2-x.png" alt="" class="img-fluid"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>