import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { MatTableDataSource } from '@angular/material/table';
import { ISelectPayrollDetails, IActivatePayrollDetails } from 'src/app/interfaces/iemployee';
import { EmployeeService } from 'src/app/services/employee.service';
import { PayrollGeneralComponent } from '../payroll-general/payroll-general.component';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.css']
})
export class PayrollComponent implements OnInit {
  @Input() public buttonDisable;
  loginInfo: any;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any;
  public EmployeeTableData: any;
  public tableRowId: any;
  public TabletData: any;
  public tableRowColor: any;
  public displayedColumns = [];
  public columns = [];
  public activeDeactiveValue: any;
  public getDataValue: any;
  public generalDataemit: any;
  public getsaveid: any;
  isInvalid: boolean = false;
  public dataSource = new MatTableDataSource<any>();
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public generalValueemit = new EventEmitter;
  @Output() public payrollsessData = new EventEmitter;

  @ViewChild(PayrollGeneralComponent) payrollGeneral: PayrollGeneralComponent;
  matopen:boolean = false;
  matstate($event){
    this.matopen =$event
  }
  constructor(private helper: HelperService, private toaster: ToasterService, private empService: EmployeeService) { }

  ngOnInit(): void {
    this.employeeName = "Payroll";
    this.employee = "Payroll";
    this.employeeIcon = "assets/components/images/path.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
    this.loginInfo = this.helper.getValue('LoginInfo');
    if (this.helper.getValue('OnDirty') == false) {
      this.tableRowColor = 'transparent';
    }
  }

  OnSave() {
    this.payrollGeneral.onSaveGeneral();
    if (this.payrollGeneral.payrollGeneralForm.invalid) {
      if ((this.payrollGeneral.payrollGeneralForm.value.startDay == null) || (this.payrollGeneral.payrollGeneralForm.value.startDay == "")) {
        this.isInvalid = true;
      }
      else {
        this.isInvalid = false;
      }
    }
    else {
      this.isInvalid = false;
    }
  }

  isInvalidAccordion(e) {
    this.isInvalid = e;
  }

  getpayrollsessData(e) {
    this.payrollsessData.emit(e)
  }
}
