<router-outlet></router-outlet>

<div class="pop-up-bg" style="position:absolute">
  <div class=" text-right">
    <div bsModal #childModal="bs-modal" class="modal" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
      <div class="modal-dialog modal-md">
        <div class="modal-content" style="background-color: #707172;">
          <div class="modal-header">
            <h4 id="dialog-child-name" class="modal-title pull-left" style="color: #fff;">You Have Been Idle!</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="color: #fff; text-align: center;">
            {{idleState}}
            <div class="notify-footer modal-footer text-center" style="border-top: 0; justify-content: center;">
              <button md-button class="button-style" (click)="logout()">Logout</button>
              <button md-button class="button-style navigate-btn" (click)="stay()">Continue</button>
            </div>

            <!-- <div class="col-md-6">
               <button type="button" (click)="logout()" class="btn btn-danger">Logout</button> 
          </div>
          <div class="col-md-6">
               <button type="button" (click)="stay()" class="btn btn-success">Stay</button> 
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>