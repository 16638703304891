import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AuditVoidCollapseComponent } from 'src/app/elements/audit-void-collapse/audit-void-collapse.component';
import { HelperService } from 'src/app/helper/helper.service';
import { Group, iDiscountReport, iSelectVoidReport } from 'src/app/interfaces/i-report';
import { ReportWebSocketService } from 'src/app/services/report-web-socket.service';
export interface PeriodicElement {
  employee?:any;
  timeDate?:any;
  checkno?: any;
  item?:any;
  reason?:any;
  approved?:any;
  amount?:any;
  ofsales?:any;
}

export interface ICustomer {

  address: any;
  city: any;
  email: any;
  firstName: any;
  lastName: any;
  notes: any;
  phone: any;
  state: any;
  zip: any;
}

@Component({
  selector: 'app-voids-report',
  templateUrl: './voids-report.component.html',
  styleUrls: ['./voids-report.component.css']
})
export class VoidsReportComponent implements OnInit {
  loginInfo:any
  @Output() public titleName = new EventEmitter();
  @Output() public title = new EventEmitter();
  @Output() public titleIcon = new EventEmitter();
  @Output() public backSalesItem = new EventEmitter;
  @Input() public voids;
  public employeeName: any;
  public employee: any;
  public employeeIcon: any
  public Filtertransactions:any=[];
  ELEMENT_DATA1=[];
  ELEMENT_DATA2=[];
  ELEMENT_DATA3=[];
  transactions=[];
  transactions1=[];
  closedData=[];
  public discountTotal:any;
  public subTotal:any;
  public netTotal:any;
  public groupByColumns1: string[] = [];
  public displayedColumns1 = [];
  columns1 = [];
  public dataSource1 = new MatTableDataSource<any | Group>([]);
  auditTable: string[];

  public displayedColumns2 = [];
  columns2 = [];
  public dataSource2: any
  public displayedColumns3 = [];
  columns3 = [];
  public dataSource3: any
  public summaryCustomer: any = [];

  public firstTableShow:boolean = false
  public secondTableShow:boolean = false
  public thirdTableShow:boolean = false
  public auditShow:boolean = false
  public backToSales:boolean = false
  signature: any;
  public auditName:any
  public auditDate:any
  public auditTime:any
  public auditEmployee:any
  firstTableName:any;
  public summartItems:any=[];
  @ViewChild(AuditVoidCollapseComponent) tablecollapse: AuditVoidCollapseComponent
  public summaryAudit: any = [];
  public SalesAreaCustomer: any = [];
  CustomerDetails = [];
  public CustomerList: ICustomer[] = [
    { firstName: '', lastName: '', city: '', email: '', address: '', phone: '', notes: '', state: '', zip: '' }
  ]
  public alldata1: any[];

  public displayedColumns: string[] = ['item', 'cost'];
  TipsAmount: any;
  auditdataSource: PeriodicElement[];
  auditcolumns: { columnDef: string; header: string; }[];
  auditMove: any;

  // public transactions: Transaction[] = [
  //   {item: 'Diet Pepsi', cost: 3.50},
  //   {item: 'Cheeseburger', cost: 13.99},
  //   {item: 'French Dip', cost: 10.99},
  //   {item: 'Mountain Dew', cost: 3.50},
  // ];

  constructor(private formBuilder: FormBuilder,private helper: HelperService,private reportService:ReportWebSocketService) { }

  ngOnInit(): void {
    localStorage.setItem('browserPage', "this.voids");
    
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.employeeName = "Voids Report";
    this.employee = "Reports";
    this.employeeIcon = "assets/components/images/reports-icon.png"
    this.titleName.emit(this.employeeName)
    this.title.emit(this.employee)
    this.titleIcon.emit(this.employeeIcon)
  }
 
  GetVoidDetails(event?)
  {   
    this.firstTableShow = true;
    this.secondTableShow = false;
    // this.displayedColumns1 = ['employee','timeDate', 'checkno','item','reason','approved','amount','ofsales'];

  
    this.reportService.SelectVoidReport(event).subscribe(
      
      (data: any) => {
        console.log(data,"datadata");
        
        this.alldata1 = data.Events;      
        this.summaryAudit = data.Items;
        this.summaryCustomer = data.Customer;
        this.summartItems = data.Audit;
        this.columns1 = [
          { field: ' ', header: "Employee Name" },
           { field: 'dateTime', header: "Date/Time" },
          { field: 'checkNum', header: "Check #" },
          { field: 'itemName', header: "Item" },
          { field: 'reason', header: "Reason" },
          { field: 'approvedBy', header: "Approved By" },
          { field: 'amount', header: "Amount" },
          { field: 'salesCent', header: "% Of Sales" },
        ]
        this.displayedColumns1 = this.columns1.map(column => column.field);
        this.groupByColumns1 = ['empName'];
        this.dataSource1.data = this.tablecollapse.addGroups(this.alldata1, this.groupByColumns1);
        }
      );

    
   }

   onBackClick() {
      this.firstTableShow = true;
      this.secondTableShow = false;
  }
  
  getAuditTable(e?) {
    this.firstTableShow = false;
    this.secondTableShow = true;
    this.auditName = e.checkNum;

    this.auditTable = ['employee', 'timeDate', 'item', 'move', 'total'];

          this.dataSource3 = this.summaryAudit.filter(x => x.ticketid == e.ticketid && x.move != 'closed').sort((a, b) => a.timeDate < b.timeDate ? -1 : a.timeDate > b.timeDate ? 1 : 0);
      this.closedData = this.summaryAudit.filter(x => x.ticketid == e.ticketid && x.move == 'void')

      this.transactions = [...this.dataSource3, ...this.closedData];
      this.transactions1 = this.summartItems.filter(x => x.ticketid == e.ticketid)
      this.Filtertransactions = this.transactions1.filter(y => y.itemType == 'Main');

      this.auditdataSource = this.transactions;

      this.auditcolumns = [
        { columnDef: 'employee', header: "Employee" },
        { columnDef: 'timeDate', header: "Time/Date" },
        { columnDef: 'item', header: "Item" },
        { columnDef: 'move', header: "Move" },
        { columnDef: 'total', header: "Total" },
      ]

      if (this.summaryCustomer.filter(x => x.ticketid == e.ticketid).length != 0) {
        this.CustomerDetails = this.summaryCustomer.filter(x => x.ticketid == e.ticketid);
      }
      else {
        this.CustomerDetails = this.CustomerList;
      }

      let dateAssign = e.dateTime.substring(0, 10);
      let timeAssign = e.dateTime.substring(12, e.dateTime.length);

      this.auditDate = dateAssign;
      this.auditTime = timeAssign;
      this.auditMove = e.checkNum;
  }
}
