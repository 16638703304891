import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { MenuGroupService } from 'src/app/services/menugroup.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { MenuGroupGeneralComponent } from '../menu-group-general/menu-group-general.component';

@Component({
  selector: 'app-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.css']
})

export class MenuGroupComponent implements OnInit {
  buttonDisableVal: boolean = false;
  public Groupdata: any;
  isInvalid: boolean = false;
  @Input() clearItemForm: any;
  @Output() public generalgrpemit = new EventEmitter();
  @Output() public MenuNameEmit = new EventEmitter();
  @Output() public mnugrpsessData = new EventEmitter();

  @ViewChild(MenuGroupGeneralComponent) menuGrpgenComp: MenuGroupGeneralComponent;
  employee: any;
  public getDataValue: any;
  //@Input() public buttonState;

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisableVal = false;
    }
    else {
      this.buttonDisableVal = !btnst;
    }
  }

  submittedGeneral: any;
  constructor(private helper: HelperService) { }

  ngOnInit(): void {
    this.buttonDisableVal = false;
  }

  getGeneralDetail(event) {
    this.generalgrpemit.emit({ res: event.res.id, name: event.name });
  }

  // @Input()
  // public set MenuRowItem(tableRowData) {
  // this.Groupdata=tableRowData;
  // }

  @Input()
  public set getdatagroup(e) {
    this.Groupdata = e;
    this.isInvalid = false;
    //this.menuGrpgenComp.requiredError = false;
    //this.menuGrpgenComp.errorValue = ""
  }

  public salesAreabit: any;

  @Input()
  public set DatasalesArea(salesArea) {
    this.salesAreabit = salesArea;
  }

  @Input()
  public set DataClearForm(clearForm) {
    if (clearForm != undefined && clearForm != null) {
      this.addEmployee();
    }
  }

  public addEmployee(): void {
    this.menuGrpgenComp.AreaBuildmnugrpId = 0;
    this.menuGrpgenComp.errorValue = "";
    this.menuGrpgenComp.submittedGeneral = false;
    this.menuGrpgenComp.MenuGroupGeneralForm.reset(); // should reset the form in child component
    this.menuGrpgenComp.ngOnInit();
    this.menuGrpgenComp.MenuGroupGeneralForm.patchValue({
      isActive: true,
    });
  }

  onMenuName() {
    this.employee = "Menu Group";
    this.MenuNameEmit.emit(this.employee)
  }

  OnSave() {
    this.menuGrpgenComp.OnSaveMenuGroup();
    if (this.menuGrpgenComp.MenuGroupGeneralForm.invalid) {
      this.isInvalid = true;
    }
    else {
      this.isInvalid = false;
    }
  }

  OnFormReset() {
    this.menuGrpgenComp.MenuGroupGeneralForm.reset();
  }

  isInvalidAccordion(event) {
    this.isInvalid = event;
  }

  getmnugrpsessData(e) {
    this.mnugrpsessData.emit(e);
  }

  DataBindonNavigate(pageid) {
    this.menuGrpgenComp.MenuGroupGeneralForm.patchValue(pageid);
  }

  DataBindonCancel() {
    this.menuGrpgenComp.MenuGroupGeneralForm.patchValue(this.helper.getValue('GetSessionData2'));
  }
}
