import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmployeeService } from 'src/app/services/employee.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { GetEmployee } from 'src/app/interfaces/iemployee';
import { HelperService } from 'src/app/helper/helper.service';
import { IempId, IActiveUser, ISelectState, GetAllUserList } from 'src/app/interfaces/iadmin';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
//import * as moment from 'moment'
import { AdminService } from 'src/app/services/admin.service';
import { CompanyComponent } from '../../hierachy/company/company.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-hierachy-employee-general',
  templateUrl: './hierachy-employee-general.component.html',
  styleUrls: ['./hierachy-employee-general.component.css'],
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true },
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class HierachyEmployeeGeneralComponent implements OnInit {
  @ViewChild(CompanyComponent) Comp: CompanyComponent;
  buttonDisable: boolean = false;
  dealer: any;
  comp: any;
  tenant_id: any;
  submittedGeneral = false;
  GeneralForm: FormGroup;
  loginInfo: any;
  private isDisabled: boolean;
  hierarchyVal: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  // public variables = ['One','Two','County', 'Three', 'Zebra', 'XiOn'];
  // public filteredList2 = this.variables.slice();
  filteredList2: any = []
  GeneralPatchValue: any;
  public variables: any = []

  errorValue: any;
  errorValue1: any;
  errorValue2: any;
  errorValue3: any;
  errorValue5: any;
  errorValue6: any;
  dateMgs: any;
  dateMgsHire: any;
  minDate: any;

  passwordIsValid = false;
  //@Input() public buttonState;
  errorMessage: any;
  @Input() public dealerEmp
  @Input() public compEmp
  @Input() public isvEmp
  @Input() public dealerHierarchyHide
  @Input() public companyHierarchyHide;
  @Input() public tableRowId;
  @Input() public currentTarget;
  // @Input() public loginId
  requiredError: boolean = false;

  @Output() public isInvalid = new EventEmitter();
  @Output() public HieEmpPageData_child = new EventEmitter();

  generalValue: any;
  zipCodeMsg: any = '';
  @Output() public generalItem = new EventEmitter();
  mgs: any = '';
  filterNodata: any
  maxDate = moment(new Date());
  mobNumberPattern = "^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$";
  isValidFormSubmitted = false;

  private onDestroy$: Subject<void> = new Subject<void>();
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }

  constructor(private employeeDetails: EmployeeService, private toaster: ToasterService, private dialogser: DialogService,
    private formBuilder: FormBuilder, private helper: HelperService, private admin: AdminService) { }

  ngOnInit(): void {
    this.GeneralForm = this.formBuilder.group({
      userId: [0],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      posName: [null],
      loginId: [null, Validators.required],
      password: [null],
      phone: [null],
      //emailId: [null, [Validators.email, Validators.pattern('^[a-zA-Z0-9._%#!$%^&*+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-]{2,4}$')]],
      emailId: [null, [Validators.email]],
      address: [null],
      city: [null],
      state: [null],
      zipCode: [null],
      employeeHireDate: [moment(new Date()), Validators.required],
      employeeBirthday: [null],
      isActive: [true],
      backofficeAccess: [false],
      dealer: [0],
      company: [0]
    });

    this.GeneralForm.reset(); // needed or not needed ?

    this.loginInfo = this.helper.getValue('LoginInfo');
    this.hierarchyVal = this.helper.getValue('hierarchyValue');
    // this.GeneralPatchValue = this.helper.getValue('idx');
    this.onSelectState()
    if (this.dealerHierarchyHide == true) {
      this.GeneralForm.controls.employeeHireDate.clearValidators()
      this.GeneralForm.controls.employeeHireDate.updateValueAndValidity()
    }
    this.getDealer();
    this.getCompany();

    this.GeneralForm.patchValue(
      {
        company: this.hierarchyVal,
        dealer: this.hierarchyVal
      });
    if (this.loginInfo.tenantType != 1) {
      this.isDisabled = true;
    }
    else {
      this.isDisabled = false;
    }
    this.minDate = new Date(1900, 0, 1);
    this.clearForm();
    this.GeneralForm.patchValue({
      isActive: true,
      employeeHireDate: moment(new Date())
    })
  }
  get GeneralData() { return this.GeneralForm.controls; }

  getDealer() {
    const menuItemList: GetAllUserList = {
      tenantId: 0,
      loginUserId: this.loginInfo.userId,
    }
    this.admin.TenantHierarchyData(menuItemList)
      .pipe(takeUntil(this.onDestroy$)).subscribe((response) => {

        this.dealer = response.childHierarchyList.filter(x => x.isActive == true);
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  getCompany() {
    const menuItemList: GetAllUserList = {
      tenantId: 0,
      loginUserId: this.loginInfo.userId,
    }
    this.admin.TenantHierarchyData(menuItemList)
      .pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
        this.comp = response.childHierarchyList.filter(x => x.isActive == true);
      })
  }

  keyPress(event: any) {
    const pattern = /[0-9\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    else if (charCode < 58 || charCode > 64) {
      if (charCode == 46) {
        return false;
      }
      return true;

    }

    else if (charCode < 106 || charCode > 111) {
      return true;
    }
    else if (charCode < 160 || charCode > 226) {
      return false;
    }
    return true;
  }

  @Input()
  public set DataIdx(tId) {
    this.errorValue = "";
    this.errorValue1 = "";
    this.errorValue2 = "";
    this.errorValue3 = "";
    this.errorValue5 = "";
    this.errorValue6 = "";
    if (tId != undefined && tId != null && tId != "") {
      this.tableRowId = tId;
      this.getEmployee(this.tableRowId);
    }
  }

  @Input()
  public set checkTarget(currentTarget) {
    this.currentTarget = currentTarget;
    if (this.currentTarget != undefined && this.currentTarget != null && this.currentTarget != "") {
      this.checkValue(this.currentTarget)
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  onSaveCompleteForm(employee, sectionId: any): void {
    if (sectionId == 1) {
      employee.sectionId = sectionId;
      if (this.dealerEmp == true) {
        employee.tenantId = this.helper.getValue('hierarchyValue');
      } else if (this.compEmp == true) {
        employee.tenantId = this.helper.getValue('hierarchyValue');
      }
      else {
        employee.tenantId = this.loginInfo.tenantId
      }

      employee.createdby = this.loginInfo.userId;
      employee.isemployee = true;
      employee.userImg = null;

      if (this.GeneralData.employeeBirthday.value == "" || this.GeneralData.employeeBirthday.value == null) {
        employee.employeeBirthday = null
      }

      const objphone = employee.phone;
      if (objphone != undefined && objphone != "" && objphone != null) {
        let trimphone = objphone.toString().match(/\d/g);
        //const isAllZero1 = trimphone.some(item => item !== "0");
        const isAllZero = trimphone.every((val, i, arr) => val === "0");
        if (parseInt(trimphone.length) != 10) {
          this.toaster.errorPopUp('Phone number must be at least 10 digit');
          return;
        }
        else if (isAllZero == true) {
          this.toaster.errorPopUp('Invalid Mobile Number');
          return;
        }
        else {
          employee.phone = objphone.replace(/[^-()\d ]/g, '');
        }
      }
    }

    this.employeeDetails.UserEmployee(employee).pipe(takeUntil(this.onDestroy$)).subscribe(
      async (response) => {
        if (sectionId == 1) {
          this.generalValue = response;
          this.GeneralForm.patchValue({
            userId: response.id,
          });

          if (response.message == " Email ID Already Exists.") {
            this.toaster.errorPopUp(response.message);
            return;
          }
          else {
            this.toaster.successPopUp(response.message);
            await this.dialogser.ClearSession();
            this.HieEmpPageData_child.emit({ data: this.GeneralForm.value, event: "dealerEmp", val: false });
          }
          this.onGeneralData(this.GeneralForm.value.userId);
          this.submittedGeneral = false;
        }
        //this.GeneralForm.patchValue({ isActive: true });  //This is commented because After Uncheck and save its getting check
        this.GeneralForm.patchValue({ company: this.hierarchyVal });
        this.generalItem.emit({ res: this.generalValue, name: employee.firstName });
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  clearForm() {
    this.errorValue = "";
    this.errorValue1 = "";
    this.errorValue2 = "";
    this.errorValue3 = "";
    this.errorValue5 = "";
    this.errorValue6 = "";
    this.GeneralForm.get('userId').reset();
    this.GeneralForm.get('firstName').reset();
    this.GeneralForm.get('lastName').reset();
    this.GeneralForm.get('posName').reset();
    this.GeneralForm.get('password').reset();
    this.GeneralForm.get('phone').reset();
    this.GeneralForm.get('emailId').reset();
    this.GeneralForm.get('loginId').reset();
    this.GeneralForm.get('address').reset();
    this.GeneralForm.get('city').reset();
    this.GeneralForm.get('state').reset();
    this.GeneralForm.get('zipCode').reset();
    this.GeneralForm.get('loginId').reset();
    this.GeneralForm.get('employeeHireDate').reset();
    this.GeneralForm.get('employeeBirthday').reset();
  }

  onGeneralData(userIdval) {
    const parentValue: IempId = {
      id: userIdval,
    }
    this.employeeDetails.checkIdvalue(parentValue);
  }

  onKeypressEvent(elem): boolean {
    const charCode = (elem.which) ? elem.which : elem.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (elem.target.value.length < 3 || (elem.target.value.length > 3 || elem.target.value.length < 11)) {
      if (elem.key === "-") elem.preventDefault();
    }
    // this.GeneralCompanyForm.get("zipCode").patchValue(elem.target.value.toUpperCase());
    if (elem.target.value.length <= 10) {
      if (elem.target.value.length === 5) {
        elem.target.value = `${elem.target.value}-`;
      }
      this.zipCodeMsg = ''
    } else {
      elem.target.value = elem.target.value.slice(0, -1);
      this.zipCodeMsg = ''
    }
    return true;
  };

  onSaveGeneral() {
    this.errorValue = "";
    this.errorValue1 = "";
    this.errorValue2 = "";
    this.errorValue3 = "";
    this.errorValue5 = "";
    this.errorValue6 = "";
    this.submittedGeneral = true;

    if (this.GeneralForm.value.employeeBirthday == "" || this.GeneralForm.value.employeeBirthday == null) {
      this.GeneralForm.value.employeeBirthday = null
    }
    else {
      this.GeneralForm.value.employeeBirthday = moment(new Date(this.GeneralForm.value.employeeBirthday)).format('YYYY-MM-DD');
    }

    if (this.GeneralForm.value.employeeHireDate == "" || this.GeneralForm.value.employeeHireDate == null) {
      this.GeneralForm.value.employeeHireDate = null
    }
    else {
      this.GeneralForm.value.employeeHireDate = moment(new Date(this.GeneralForm.value.employeeHireDate)).format('YYYY-MM-DD');
    }

    if (this.GeneralForm.value.employeeHireDate == undefined || this.GeneralForm.value.employeeHireDate == null || this.GeneralForm.value.employeeHireDate == "") {
      this.toaster.errorPopUp("Please Select the Hire Date");
      return;
    }

    if (this.GeneralForm.invalid) {
      this.isInvalid.emit(true);
      if (this.GeneralForm.controls.employeeBirthday.invalid) {
        this.dateMgs = 'Invalid Date';
        return;
      }
      else if (this.GeneralForm.controls.employeeHireDate.invalid) {
        this.dateMgsHire = 'Invalid Date';
        return;
      }
      else {
        this.dateMgs = ''
        this.dateMgsHire = ''
      }
      return;
    } else {
      const HireDate = moment(new Date(this.GeneralData.employeeHireDate.value), 'DD-MM-YYYY').format('YYYY-MM-DD');
      const Birthday = moment(new Date(this.GeneralData.employeeBirthday.value), 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (this.GeneralData.employeeBirthday.value != "" && this.GeneralData.employeeBirthday.value != null && this.GeneralData.employeeBirthday.value != undefined) {
        if (Birthday > HireDate) {
          this.errorMessage = 'Date of Birth should be lesser than Hire date';
          return
        } else {
          this.errorMessage = '';
        }
      }
      if (this.GeneralData.phone.value != undefined && this.GeneralData.phone.value != null && this.GeneralData.phone.value != "") {
        if (this.GeneralData.phone.value.length <= 12) {
          this.mgs = 'Phone number must be at least 10 digit'
          return
        } else {
          this.mgs = ''
        }
      } else {
        this.mgs = ''

      }
      if (this.GeneralForm.controls.zipCode.value != undefined && this.GeneralForm.controls.zipCode.value != null && this.GeneralForm.controls.zipCode.value != "") {
        if (this.GeneralForm.controls.zipCode.value.length === 10) {
          if (this.GeneralForm.controls.zipCode.value.slice(5, 6) != '-') {
            this.zipCodeMsg = 'Invalid Zip Code';
            return;
          }
        }
        if (this.GeneralForm.controls.zipCode.value.length <= 4) {
          this.zipCodeMsg = 'Please enter min 5 digits zipcode'
          return
        } else {
          this.zipCodeMsg = ''
        }
      } else {
        this.zipCodeMsg = ''
      }
    }
    this.onSaveCompleteForm(this.GeneralForm.value, 1);
  }

  onSelectState() {
    const ObjState: ISelectState = {
      id: 0
    }
    this.admin.SelectStateMaster(ObjState).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      this.filteredList2 = response
      this.variables = this.filteredList2
    }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  onKey(value) {
    this.filteredList2 = this.search(value);
  }

  getSubtier(value) {
    this.onchange(this.GeneralForm.value);
  }

  search(value: string) {
    let filteredList2 = this.variables.filter((unit) =>
      unit.id.indexOf(value.toUpperCase()) > -1);
    if (value.length == 1) {
      let filterdata = filteredList2.filter((x) =>
        x.id.substring(0, 1) == value.toUpperCase());
      return [...filterdata];
    } else if (value.length == 2) {
      let filterSecdata = filteredList2.filter((y) =>
        y.id.substring(0, 2) == value.toUpperCase());
      return [...filterSecdata];
    } else {
      this.filterNodata = true
      return [...filteredList2];
    }

  }

  togglebackoffice(e) {
    if (e == false) {
      this.GeneralForm.get('emailId').clearValidators();
      this.GeneralForm.get('emailId').updateValueAndValidity({ onlySelf: true });
      this.GeneralForm.get('password').clearValidators();
      this.GeneralForm.get('password').updateValueAndValidity({ onlySelf: true });
      this.GeneralForm.get('password').reset();
      if (!(this.GeneralForm.invalid)) {
        this.isInvalid.emit(false);
      }
    } else {
      this.GeneralForm.controls['emailId'].setValidators([Validators.required, Validators.email]);
      this.GeneralForm.controls['emailId'].updateValueAndValidity({ onlySelf: true });
      this.GeneralForm.controls['password'].setValidators([Validators.required]);
      this.GeneralForm.controls['password'].updateValueAndValidity({ onlySelf: true });
      this.isInvalid.emit(true);
    }
  }

  checkValue(e?: any) {
    let currentTarget = e.e.currentTarget.checked
    const active: IActiveUser = {
      userId: this.loginInfo.userId,
      employeeId: e.id,
      isActive: currentTarget,
    }
    this.employeeDetails.IsActiveEmpUser(active).pipe(takeUntil(this.onDestroy$)).subscribe(
      (response) => {
        this.getEmployee(this.tableRowId)
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')
  }

  getEmployee(empid) {
    if (empid != undefined && empid != null && empid != "") {
      const employee: GetEmployee = {
        employeeId: empid
      }
      this.employeeDetails.GetHierarchyEmployee(employee).pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
        this.GeneralForm.patchValue(response[0]);
        this.tenant_id = response[0].tenantId;
        this.GeneralForm.patchValue({ employeeHireDate: response[0].employeeHireDate == null ? '' : moment(response[0].employeeHireDate).format('YYYY-MM-DD') });
        this.GeneralForm.patchValue({ employeeBirthday: response[0].employeeBirthday == null ? '' : moment(response[0].employeeBirthday).format('YYYY-MM-DD') });
      }), err => console.error('Error', err), () => console.log('HTTP request completed.')

      // this.GeneralForm.patchValue({
      //   company: 100,
      //   dealer: 84
      // });
    }
  }

  numberOnly2(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    if (event.target.value.length <= 14) {

      if (event.target.value.length === 3) {
        event.target.value = `(${event.target.value}) `;
      }
      if (event.target.value.length === 9) {
        event.target.value = `${event.target.value}-`;
      }
      if (event.target.value.length === 11) {
        event.target.value = `${event.target.value}`;
      }
      if (event.target.value.length >= 13) {
        this.mgs = ''
      }
      // if (event.target.value.length <= 12) {
      //   this.mgs = 'Phone number must be at least 10 numbers'
      //   return true
      // } else if(event.target.value.length == 0) {
      //   this.mgs = ''
      // }
      // else  {
      //   this.mgs = ''
      // }

    } else {
      event.target.value = event.target.value.slice(0, -1)
    }
    return true;
  }

  phoneNumberCheck(event) {
    if (event.target.value.length == 0) {
      this.mgs = '';
    }
    if (event.target.value.slice(0, 1) != '(') {
      if (event.target.value.length > 10) {
        this.mgs = 'Invalid mobile number';
        return;
      }
      else if (event.target.value.length < 3 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)})`;
        if (event.target.value.length < 15) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = ''
      }
      else if (event.target.value.length < 6 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}-`;
        if (event.target.value.length < 15) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = ''
      }
      else if (event.target.value.length >= 6 && event.target.value.length > 0) {
        event.target.value = `(${event.target.value.slice(0, 3)}) ${event.target.value.slice(3, 6)}-${event.target.value.slice(6, event.target.value.length)}`;
        if (event.target.value.length < 14) {
          this.mgs = 'Invalid mobile number';
          return;
        }
        this.mgs = ''
      }
    }
    else {
      if (event.target.value.length < 14) {
        this.mgs = 'Invalid mobile number';
        return;
      }
      this.mgs = '';
    }
  }

  onDeletePhone(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.mgs = ''
      }
    }
  }

  passwordValid(event) {
    this.passwordIsValid = event;
  }

  getMaxValue(e) {
    if (e.target.value.length == 25) {
      this.errorValue = "Max-Limit 25 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  getMaxValueFName(e) {
    if (e.target.value.length == 25) {
      this.errorValue1 = "Max-Limit 25 characters reached";
    }
    else {
      this.errorValue1 = ""
    }
  }

  getMaxValueLName(e) {
    if (e.target.value.length == 25) {
      this.errorValue2 = "Max-Limit 25 characters reached";
    }
    else {
      this.errorValue2 = ""
    }
  }

  getMaxValueEmail(e) {
    if (e.target.value.length == 100) {
      this.errorValue3 = "Max-Limit 100 characters reached";
    }
    else {
      this.errorValue3 = ""
    }
  }

  getMaxValueCity(e) {
    if (e.target.value.length == 25) {
      this.errorValue5 = "Max-Limit 25 characters reached";
    }
    else {
      this.errorValue5 = ""
    }
  }

  getMaxValueTerritory(e) {
    if (e.target.value.length == 25) {
      this.errorValue6 = "Max-Limit 25 characters reached";
    }
    else {
      this.errorValue6 = ""
    }
  }

  EmpGenValid() {
    if (this.GeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    } else {
      const HireDate = moment(new Date(this.GeneralData.employeeHireDate.value), 'DD-MM-YYYY').format('YYYY-MM-DD');
      const Birthday = moment(new Date(this.GeneralData.employeeBirthday.value), 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (this.GeneralData.employeeBirthday.value != "" && this.GeneralData.employeeBirthday.value != null && this.GeneralData.employeeBirthday.value != undefined) {
        if (Birthday > HireDate) {
          this.errorMessage = 'Date of Birth should be lesser than Hire date';
          this.requiredError = true;
          return
        }
      }
      if (this.GeneralData.phone.value != undefined && this.GeneralData.phone.value != null && this.GeneralData.phone.value != "") {
        if (this.GeneralData.phone.value.length <= 12) {
          this.mgs = 'Phone number must be at least 10 digit'
          this.requiredError = true;
          return
        }
      }
      if (this.GeneralForm.controls.zipCode.value != undefined && this.GeneralForm.controls.zipCode.value != null && this.GeneralForm.controls.zipCode.value != "") {
        if (this.GeneralForm.controls.zipCode.value.length <= 4) {
          this.zipCodeMsg = 'Please enter min 5 digits zipcode'
          this.requiredError = true;
          return
        }
      }
      else {
        this.requiredError = false;
      }
    }
  }

  Highlight() {
    this.onchange(this.GeneralForm.value);

    if (!(this.GeneralForm.invalid)) {
      this.isInvalid.emit(false);
      this.dateMgs = '';
      this.dateMgsHire = '';
    }
    else {
      if (this.GeneralForm.controls.employeeBirthday.invalid) {
        this.isInvalid.emit(true);
        this.dateMgs = 'Invalid Date';
        if (!this.GeneralForm.controls.employeeHireDate.invalid) {
          this.dateMgsHire = '';
        }
        if (this.GeneralForm.controls.employeeHireDate.invalid) {
          this.dateMgsHire = 'Invalid Date';
        }
        return;
      }
      if (this.GeneralForm.controls.employeeHireDate.invalid) {
        this.isInvalid.emit(true);
        this.dateMgsHire = 'Invalid Date';
        if (!this.GeneralForm.controls.employeeHireDate.invalid) {
          this.dateMgs = '';
        }
        if (this.GeneralForm.controls.employeeBirthday.invalid) {
          this.dateMgs = 'Invalid Date';
        }
        return;
      }
    }
  }
  //  Realese the memory
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.GeneralForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.HieEmpPageData_child.emit(this.GeneralForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.HieEmpPageData_child.emit({ data: data, event: "dealerEmp", val: true });
  }

  zipCodeCheck(event) {
    if (event.target.value.length === 6) {
      if (event.target.value.slice(5, 6) == '-') {
        event.target.value = `${event.target.value.slice(0, 5)}`;
      }
    }
    else if (event.target.value.length > 5 && event.target.value.length < 10) {
      if (event.target.value.slice(5, 6) != '-') {
        event.target.value = `${event.target.value.slice(0, 5)}-${event.target.value.slice(6, event.target.value.length)}`;
        this.zipCodeMsg = "";
      }
    }
    else if (event.target.value.length === 9) {
      event.target.value = `${event.target.value.slice(0, 5)}-${event.target.value.slice(6, 9)}`;
    }

    else if (event.target.value.length === 10) {
      if (event.target.value.slice(5, 6) != '-') {
        this.zipCodeMsg = "Invalid Zip Code";
      }
      else {
        this.zipCodeMsg = "";
      }
    }
  }

  onDeleteZipCode(event) {
    if (event.key === 'Backspace') {
      if (event.target.value.length == 1) {
        this.zipCodeMsg = ''
      }
    }
  }
}
