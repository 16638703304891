<div class="Gen_wrap adjustment_detail">
    <form class="loginForm GeneralForm" [formGroup]="roleGeneralForm" (change)="onchange(roleGeneralForm.value)">
        <div class="pull-right-active">
            <span>Active</span>
            <div class="checkbox-table accordion-checkbox" id="checkbox-box">
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" class="custom-control-input" name="customCheckbox" id="activeForm" formControlName="isActive" checked="isActive" [attr.disabled]="isActiveInactivePerm ? true : null" />
                    <label class="custom-control-label" for="activeForm"></label>
                </div>
            </div>
        </div>
        <br>
        <div class="row w-100">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Name</label> <label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="roleName" appInputRestriction placeholder="Name" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.roleName.errors) || (requiredError && GeneralData.roleName.errors)}"
                        maxlength="50" (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="RolePermValid()" />
                    <div class="error">{{errorValue}}</div>
                    <div *ngIf="(submittedGeneral && GeneralData.roleName.errors) || (requiredError && GeneralData.roleName.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.roleName.errors.required">Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="customlable mb-0">Labor Category</label>


                    <div class="input-group">
                        <input class="form-control" type="text" formControlName="categoryid" [(ngModel)]="searchField" list="dataType" appInputRestriction />
                        <datalist id="dataType">
              <option [value]=""></option>
              <option *ngFor="let categoryItem of category" [value]="categoryItem.name"></option>
            </datalist>
                        <div class="input-group-append">
                            <i class="fa fa-close close_Filter" (click)="clearSearchField()" *ngIf="searchField"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="job_wrap">
                    <button class="plus_btn inshadow mr-2" (click)="addUserMulti($event)"><i class="fa fa-plus"></i></button>
                    <label>
            <h6>Assigned Employees </h6>
          </label>
                    <div formArrayName="rolePermission">
                        <div class="row no-gutters">
                            <div class="col-sm-3 col-3">
                                <label>Name</label>
                            </div>
                            <div class="col-sm-3 col-3">
                                <label>Wages</label>
                            </div>
                            <div class="col-sm-3 col-3">
                                <label>OT Rate</label>
                            </div>
                            <div class="col-sm-2 col-2 text-center">
                                <label>View</label>
                            </div>
                            <div class="col-sm-1 col-1">
                            </div>
                        </div>
                        <div class="inline-form-group">
                            <div class="row no-gutters" *ngFor="let $rolePermission of roleGeneralForm.get('rolePermission')['controls']; let i=index " [formGroup]="$rolePermission" autocomplete="off">
                                <div class="col-sm-3 col-3">

                                    <mat-form-field appearance="fill">
                                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" (selectionChange)="onselectemp($event.value)" formControlName="userid" placeholder="Select Name" #userid>
                                            <input class="form-control input-lg inputbox_login" placeholder="Search here" (keyup)="onKey($event.target.value,i)" (keydown)="handleInput($event)" appInputRestriction />
                                            <mat-option *ngFor="let item of dataAll.arr[i]" [value]="item.id" (onSelectionChange)="onChangeEmp($event,item)">{{item.name}}</mat-option>
                                            <div *ngIf="dataAll.arr[i]!= undefined">
                                                <mat-option *ngIf="dataAll.arr[i].length === 0">No Result</mat-option>
                                            </div>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                                <div class="col-sm-3 col-3 job-doller-icon">
                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                    <input type="text" class="form-control" placeholder="0.00" formControlName="empWage" appInputRestriction appDecimalLimit [attr.disabled]="salValue == true ? '': null" />

                                </div>
                                <div class="col-sm-3 col-3 job-doller-icon">
                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                    <input type="text" class="form-control" placeholder="0.00" appDecimalLimit appInputRestriction formControlName="empOtCharges" [attr.disabled]="salValue == true ? '': null" />

                                </div>
                                <div class="col-sm-2 col-2 text-center">
                                    <i class="fa fa-eye" aria-hidden="true" (click)="openModalWithComponent(userid.value)"></i>

                                </div>
                                <div class="col-sm-1 col-1">
                                    <i class="fa fa-remove" aria-hidden="true" (click)="DeleteItem(i)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-right">
                <div class="form-group">
                    <button class="btn  btn-sm float-right submit_button button-none" type="submit" (click)="onSaveGeneral();" [disabled]="buttonDisable ? true : null">
            <!-- [disabled]="buttonState ? true : null" -->
            <img src="assets/components/images/save.png" alt="employee" class="">
            <label class="label_config">Save</label>
          </button>
                </div>
            </div>
        </div>

        <!-- <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered popUpViewModal" role="document">
        <div class="modal-content">
          <div class="remove_tab">
            <div class="">
              <div class="text-right">
                <button type="button" class="btn-close close" aria-label="Close" data-dismiss="modal">
                  <span aria-hidden="true" class="visually-hidden">&times;</span>
                </button>
              </div>
            </div>

            <div class="modal-body text-center p-0">

            </div>
          </div>
        </div>
      </div>
    </div> -->

    </form>
</div>
