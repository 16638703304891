import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { PaymentService } from 'src/app/services/payment.service';
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from 'ngx-intl-tel-input';
import { adjustmentService } from 'src/app/services/adjustment.service';
import {
  GetAdjustment,
  SelectAdjustGeneral,
} from 'src/app/interfaces/iadjustment';
import { CurrencyPipe } from '@angular/common';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-adjustment-general',
  templateUrl: './adjustment-general.component.html',
  styleUrls: ['./adjustment-general.component.css'],
})
export class AdjustmentGeneralComponent implements OnInit {
  buttonDisable: boolean = false;
  CodeMaster = [];
  AdjustmentType = [];
  BehaviorType = [];
  ValueType = [];
  RounditupvalueArray = [];
  adjustmentGeneralForm: FormGroup;
  submittedGeneral: any;
  loginInfo: any;
  placeholderIcon: any;
  GatewaySelection: any;
  isSubmitted = false;
  value = null;
  isValidated = false;
  requiredError: boolean = false;

  @Output() public isInvalid = new EventEmitter();
  @Output() public adjgenData_child = new EventEmitter();
  @Output() public generalAdjust = new EventEmitter();

  optionsval = {
    prefix: '$',
    thousands: ',',
    decimal: '.',
    inputMode: CurrencyMaskInputMode.NATURAL,
  };
  option_doll = {
    prefix: '$',
    thousands: ',',
    decimal: '.',
    inputMode: CurrencyMaskInputMode.NATURAL,
  };
  option_per = {
    prefix: ' ',
    suffix: '%',
    thousands: ',',
    decimal: '.',
    inputMode: CurrencyMaskInputMode.NATURAL,
  };

  itemAdjust: boolean = false;
  checkAdjust: boolean = false;
  discount: boolean = false;
  open: boolean = true;
  dollar: boolean = false;
  totalamount: boolean = true;
  charity: boolean = false;
  roundup: boolean = false;
  fixed: boolean = true;
  surcharge: boolean = false;
  gratuity: boolean = false;
  percentage: boolean = false;
  behaviorHide: boolean = true;
  roundupdown: boolean = false;
  totalHide: boolean = false;
  fixedHide: boolean = false;
  ItemFixedHide: boolean = false;
  errorValue: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];

  //@Input() public buttonState;
  @ViewChild('behavior') behavior: ElementRef;

  @Input() public currentTarget;
  @Input()
  public set DataIdx(tableRowId) {
    this.errorValue = '';
    if (tableRowId != undefined && tableRowId != null && tableRowId != '') {
      this.GetAdjustmentGeneralList(tableRowId);
    }
  }
  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = !btnst;
    }
  }
  constructor(
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private objAdj: adjustmentService,
    private toaster: ToasterService,
    private currencyPipe: CurrencyPipe,
    private dialogser: DialogService
  ) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    this.optionsval = this.option_per;
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.adjustmentGeneralForm = this.formBuilder.group({
      id: [0],
      name: [null, Validators.required],
      codeMaster: [null, Validators.required],
      adjustmentType: [null, Validators.required],
      behaviorType: [null, Validators.required],
      valueType: [null, Validators.required],
      totalamount: [null],
      roundItupValue: ['RD1'],
      dollar: [false],
      percentage: [false],
      creditCheckbox: [false],
      printCheckbox: [false],
      creditamount: [null],
      behaviourValue: [null],
      autoAdjust:[false]
    });
    this.getdefaultValues();
  }

  public onKeyDown(event: KeyboardEvent): void {
    // console.log(event);
    if (event.key === 'Backspace') {
      // 46 - Delete key
      // console.log(event);
      event.preventDefault();
      this.value = null;
    }
    if (event.key === 'Delete') {
      // 46 - Delete key
      console.log(event);
      event.preventDefault();
      this.value = null;
    }
  }

  get adjustmentData() {
    return this.adjustmentGeneralForm.controls;
  }

  OnClickToggle() {
    if (
      this.adjustmentGeneralForm.value.name != null &&
      this.adjustmentGeneralForm.value.name != '' &&
      this.adjustmentData.codeMaster.value != null &&
      this.adjustmentData.codeMaster.value != '' &&
      this.adjustmentData.adjustmentType.value != null &&
      this.adjustmentData.adjustmentType.value != '' &&
      this.adjustmentData.valueType.value != null &&
      this.adjustmentData.valueType.value != '' &&
      this.adjustmentData.behaviorType.value != null &&
      this.adjustmentData.behaviorType.value != ''
    ) {
      this.isInvalid.emit(false);
    }

    // adjustment
    if (
      this.adjustmentData.codeMaster.value == 'AD2' &&
      this.adjustmentData.adjustmentType.value == 'A65'
    ) {
      this.totalamount = true;
      this.roundup = false;
      this.gratuity = false;
      this.discount = true;
      this.behaviorHide = true;
      this.ItemFixedHide = false;
      if (
        this.adjustmentData.behaviorType.value == 'BT2' ||
        this.adjustmentData.behaviorType.value == 'BT3'
      ) {
        this.totalHide = false;
        this.adjustmentGeneralForm.controls.valueType.setValidators([
          Validators.required,
        ]);
        this.adjustmentGeneralForm.controls.valueType.updateValueAndValidity();
      } else if (this.adjustmentData.behaviorType.value == 'BT4') {
        this.totalamount = true;
        this.percentage = false;
        this.placeholderIcon = '$0.00';
        this.totalHide = true;
        this.adjustmentGeneralForm.get('valueType').setValue(null);
        this.adjustmentGeneralForm.controls.valueType.setValidators(null);
        this.adjustmentGeneralForm.controls.valueType.updateValueAndValidity();
      }
    } else if (
      this.adjustmentData.codeMaster.value == 'AD3' &&
      this.adjustmentData.adjustmentType.value == 'A66'
    ) {
      this.roundup = true;
      this.totalamount = false;
      this.gratuity = false;
      this.ItemFixedHide = false;
      this.behaviorHide = true;
      if (
        this.adjustmentData.behaviorType.value == 'BT2' ||
        this.adjustmentData.behaviorType.value == 'BT3'
      ) {
        this.totalHide = false;
        this.roundupdown = false;
        this.adjustmentGeneralForm.controls.valueType.setValidators([
          Validators.required,
        ]);
        this.adjustmentGeneralForm.controls.valueType.updateValueAndValidity();
        this.adjustmentGeneralForm.controls.roundItupValue.setValidators(null);
        this.adjustmentGeneralForm.controls.roundItupValue.updateValueAndValidity();
        this.adjustmentGeneralForm.get('roundItupValue').setValue(null);
      } else if (this.adjustmentData.behaviorType.value == 'BT5') {
        this.roundupdown = true;
        this.adjustmentGeneralForm.controls.valueType.setValidators(null);
        this.adjustmentGeneralForm.controls.valueType.updateValueAndValidity();
        this.adjustmentGeneralForm.controls.roundItupValue.setValidators([
          Validators.required,
        ]);
        this.adjustmentGeneralForm.controls.roundItupValue.updateValueAndValidity();
        this.adjustmentGeneralForm.get('valueType').setValue(null);
        this.adjustmentGeneralForm.get('totalamount').setValue(null);
      }
    } else if (
      this.adjustmentData.codeMaster.value == 'AD3' &&
      this.adjustmentData.adjustmentType.value == 'A65'
    ) {
      this.roundup = false;
      this.totalamount = false;
      this.behaviorHide = true;
      this.totalHide = false;
      if (this.adjustmentData.behaviorType.value == 'BT4') {
        this.adjustmentGeneralForm.get('behaviorType').setValue(null);
      }
      // this.adjustmentGeneralForm.get('behaviorType').setValue(null);
      this.adjustmentGeneralForm.get('totalamount').setValue(null);
    } else if (
      this.adjustmentData.codeMaster.value == 'AD2' &&
      this.adjustmentData.adjustmentType.value == 'A66'
    ) {
      this.roundup = false;
      this.totalamount = false;
      this.behaviorHide = true;
      this.totalHide = false;
      this.roundupdown = false;
      if (this.adjustmentData.behaviorType.value == 'BT5') {
        this.adjustmentGeneralForm.get('behaviorType').setValue(null);
      }
      // this.adjustmentGeneralForm.controls['behaviorType'].setValue = null;
      this.adjustmentGeneralForm.get('roundItupValue').setValue(null);

      if (
        this.adjustmentData.behaviorType.value == 'BT3' ||
        this.adjustmentData.behaviorType.value == null
      ) {
        this.totalHide = false;
        this.ItemFixedHide = true;
        // this.adjustmentGeneralForm.get('behaviorType').setValue("");
        // this.adjustmentGeneralForm.controls['behaviorType'].patchValue('BT2');
        // this.adjustmentGeneralForm.controls['behaviorType'].patchValue('BT3');
        this.adjustmentGeneralForm.controls.valueType.setValidators(null);
        this.adjustmentGeneralForm.controls.valueType.updateValueAndValidity();
        this.adjustmentGeneralForm.controls.roundItupValue.setValidators(null);
        this.adjustmentGeneralForm.controls.roundItupValue.updateValueAndValidity();
      }
    } else if (
      this.adjustmentData.codeMaster.value == 'AD2' ||
      this.adjustmentData.codeMaster.value == 'AD3'
    ) {
      if (this.adjustmentData.adjustmentType.value == 'A68') {
        this.behaviorHide = false;
        this.roundupdown = false;
        this.placeholderIcon = '$0.00';
        this.adjustmentGeneralForm.controls.valueType.setValidators(null);
        this.adjustmentGeneralForm.controls.valueType.updateValueAndValidity();
        this.adjustmentGeneralForm.controls.behaviorType.setValidators(null);
        this.adjustmentGeneralForm.controls.behaviorType.updateValueAndValidity();
        this.adjustmentGeneralForm.get('behaviorType').setValue(null);
        this.adjustmentGeneralForm.get('valueType').setValue(null);
        this.adjustmentGeneralForm.get('roundItupValue').setValue(null);
        this.adjustmentGeneralForm.get('totalamount').setValue(null);
      } else if (this.adjustmentData.adjustmentType.value == 'A67') {
        this.gratuity = true;
        this.roundupdown = false;
        this.behaviorHide = true;
        if (this.adjustmentData.behaviorType.value == 'BT5') {
          this.adjustmentGeneralForm.get('behaviorType').setValue(null);
        }
        // this.adjustmentData.behaviorType.reset();
        // this.adjustmentGeneralForm.get('behaviorType').setValue(null);
        this.adjustmentGeneralForm.get('roundItupValue').setValue(null);
        // this.adjustmentData.behaviorType = null
      } else if (
        this.adjustmentData.adjustmentType.value == 'A65' ||
        this.adjustmentData.adjustmentType.value == 'A66'
      ) {
        this.behaviorHide = true;
      }
      this.adjustmentGeneralForm.controls.roundItupValue.setValidators(null);
      this.adjustmentGeneralForm.controls.roundItupValue.updateValueAndValidity();
      // this.adjustmentData.behaviorType.reset();
      // this.adjustmentData.valueType.reset();
    } else {
      this.fixedHide = false;
      this.ItemFixedHide = false;
      this.gratuity = false;
      this.behaviorHide = true;
      this.roundupdown = false;
      this.totalHide = false;
      this.totalamount = true;
      this.roundup = false;
      this.adjustmentGeneralForm.controls.valueType.setValidators([
        Validators.required,
      ]);
      this.adjustmentGeneralForm.controls.valueType.updateValueAndValidity();
      this.adjustmentGeneralForm.controls.roundItupValue.setValidators([
        Validators.required,
      ]);
      this.adjustmentGeneralForm.controls.roundItupValue.updateValueAndValidity();
      this.adjustmentGeneralForm.controls.behaviorType.setValidators([
        Validators.required,
      ]);
      this.adjustmentGeneralForm.controls.behaviorType.updateValueAndValidity();
    }

    // value type
    if (this.adjustmentData.valueType.value == 'VT2') {
      this.dollar = true;
      this.placeholderIcon = '$0.00';
      this.percentage = false;
      this.optionsval = this.option_doll;
      this.adjustmentGeneralForm.controls.totalamount.reset();
      // this.totalHide = false
    } else if (this.adjustmentData.valueType.value == 'VT3') {
      this.percentage = true;
      this.placeholderIcon = '%0.00';
      this.dollar = false;
      this.optionsval = this.option_per;
      this.adjustmentGeneralForm.controls.totalamount.reset();
      // this.totalHide = false
    } else {
      this.dollar = false;
      this.percentage = false;
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      var parts = event.srcElement.value.split('.');
      if (parts.length > 1 && charCode == 46) return false;
      return true;
    }
    return true;
  }

  async onSaveGeneral(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.errorValue = '';

      this.submittedGeneral = true;
      if (this.adjustmentGeneralForm.invalid) {
        return;
      }

      this.adjustmentGeneralForm.value.isItemAdjustment = false;
      this.adjustmentGeneralForm.value.isCheckAdjustment = false;
      this.adjustmentGeneralForm.value.isDiscount = false;
      this.adjustmentGeneralForm.value.isCharity = false;
      this.adjustmentGeneralForm.value.isGratuity = false;
      this.adjustmentGeneralForm.value.isSurcharge = false;
      this.adjustmentGeneralForm.value.isDiscountOpen = false;
      this.adjustmentGeneralForm.value.isDiscountFixed = false;
      this.adjustmentGeneralForm.value.isTotalAmount = false;
      this.adjustmentGeneralForm.value.isAmount = false;
      this.adjustmentGeneralForm.value.isPercent = false;
      this.adjustmentGeneralForm.value.roundfrist = false;
      this.adjustmentGeneralForm.value.roundupsecond = false;
      this.adjustmentGeneralForm.value.roundupthird = false;

      const itm = this.adjustmentGeneralForm.value;

      if (this.itemAdjust == true) {
        this.adjustmentGeneralForm.value.isItemAdjustment = true;
      } else if (itm.codeMaster == 'Chk') {
        this.adjustmentGeneralForm.value.isCheckAdjustment = true;
      }

      if (itm.adjustmentType == 'Discount') {
        this.adjustmentGeneralForm.value.isDiscount = true;
      } else if (itm.adjustmentType == 'Charity') {
        this.adjustmentGeneralForm.value.isCharity = true;
      } else if (itm.adjustmentType == 'Gratuity') {
        this.adjustmentGeneralForm.value.isGratuity = true;
      } else if (itm.adjustmentType == 'Surcharge') {
        this.adjustmentGeneralForm.value.isSurcharge = true;
      }

      if (itm.behaviorType == 'open') {
        this.adjustmentGeneralForm.value.isDiscountOpen = true;
      } else if (itm.behaviorType == 'fixed') {
        this.adjustmentGeneralForm.value.isDiscountFixed = true;
      } else if (itm.behaviorType == 'totalamount') {
        this.adjustmentGeneralForm.value.isTotalAmount = true;
      }

      if (itm.valueType == '$') {
        this.adjustmentGeneralForm.value.isAmount = true;
      } else if (itm.valueType == '%') {
        this.adjustmentGeneralForm.value.isPercent = true;
      }

      if (itm.roundItupValue == 'roundfrist') {
        this.adjustmentGeneralForm.value.roundfrist = true;
      } else if (itm.roundItupValue == 'roundupsecond') {
        this.adjustmentGeneralForm.value.roundupsecond = true;
      } else if (itm.roundItupValue == 'roundupthird') {
        this.adjustmentGeneralForm.value.roundupthird = true;
      }

      let adjserv;
      adjserv = this.adjustmentGeneralForm.value;
      adjserv.createdBy = this.loginInfo.userId;
      adjserv.modifiedBy = this.loginInfo.userId;
      adjserv.tenantId = this.loginInfo.tenantId;
      adjserv.isActive = true;

      adjserv.adjustment = this.adjustmentGeneralForm.value.codeMaster;
      adjserv.behaviour = this.adjustmentGeneralForm.value.behaviorType;
      adjserv.behaviourValue = parseFloat(
        this.adjustmentGeneralForm.value.behaviourValue
      );
      adjserv.iscredit = this.adjustmentGeneralForm.value.creditCheckbox;
      adjserv.isprint = this.adjustmentGeneralForm.value.printCheckbox;
      adjserv.surchargePercent = this.adjustmentGeneralForm.value.creditamount;

      this.objAdj.SaveAdjustmentGeneral(adjserv).subscribe(async (res) => {
        this.isSubmitted = false;
        this.submittedGeneral = false;
        this.adjustmentGeneralForm.patchValue({
          id: res.id,
          behaviourValue: Number(adjserv.behaviourValue).toFixed(2),
        });
        await this.dialogser.ClearSession();

        this.helper.setValue('GetSessionData1', adjserv);
        this.generalAdjust.emit({ res: res, name: adjserv.name });
        this.adjgenData_child.emit({
          data: adjserv,
          event: 'adjustmentPage',
          val: false,
        });
        resolve({ res: res });
      });
    });
  }

  getdefaultValues() {
    const getdata: GetAdjustment = {
      CodeMaster: 'AD1',
      AdjustmentType: 'A64',
      BehaviorType: 'BT1',
      ValueType: 'VT1',
      Rounditupvalue: 'RD1',
    };

    this.objAdj.GetAdjustmentGeneral(getdata).subscribe(
      (response) => {
        this.CodeMaster = response.filter(
          (x) => x.parentCode === getdata.CodeMaster
        );
        this.AdjustmentType = response.filter(
          (x) => x.parentCode === getdata.AdjustmentType
        );
        this.BehaviorType = response.filter(
          (x) => x.parentCode === getdata.BehaviorType
        );
        this.ValueType = response.filter(
          (x) => x.parentCode === getdata.ValueType
        );
        this.RounditupvalueArray = response.filter(
          (x) => x.parentCode === getdata.Rounditupvalue
        );
      },
      (error) => { }
    );
  }

  GetAdjustmentGeneralList(rowId) {
    const adjustList: SelectAdjustGeneral = {
      id: rowId,
      tenantid: this.loginInfo.tenantId,
    };

    this.objAdj
      .EditAdjustmentGeneral(adjustList) // select method
      .subscribe((res) => {
        let adjustVal = res.AdjustmentDetails[0];
        //let menuItemArray = adjustVal.menuItemSizeList;
        this.adjustmentGeneralForm.patchValue(adjustVal);
        //this.adjustmentGeneralForm.patchValue({
        //totalamount: adjustVal.totalamount
        //});

        this.OnClickToggle();
      });
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = 'Max-Limit 50 characters reached';
    } else {
      this.errorValue = '';
    }
  }

  AdjGenValid() {
    if (this.adjustmentGeneralForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    } else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.adjustmentGeneralForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.adjustmentGeneralForm.patchValue(
      this.helper.getValue('GetSessionData1')
    );
    this.adjgenData_child.emit(this.adjustmentGeneralForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.adjgenData_child.emit({
      data: data,
      event: 'adjustmentPage',
      val: true,
    });
  }
}
