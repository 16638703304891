import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-report-audit',
  templateUrl: './report-audit.component.html',
  styleUrls: ['./report-audit.component.css']
})
export class ReportAuditComponent implements OnInit {
  @Input() displayedColumns;
  @Input() Filtertransactions;
  @Input() transactions;
  @Input() dataSource3;
  @Input() displayedColumns3;
  @Input() columns3;
  @Input() auditName;
  @Input() thirdTableShow;
  @Input() CustomerDetails;
  @Input() subTotal;
  @Input() TipsAmount;
  @Input() discountTotal;
  @Input() netTotal;
  @Input() auditEmployee;
  @Input() auditDate;
  @Input() auditTime;

  @Output() public tableRowvalue = new EventEmitter;
  dyn_img : any = 'signature1.png'
  discountValue :any = 0.00;
  taxValue : any = 0.00;
  constructor() { }

  ngOnInit(): void {
   // console.log(this.CustomerDetails,"CustomerDetails");
   // this.CustomerDetails = this.CustomerDetails.filter(x => x.check == this.auditName)
     // console.log(this.CustomerDetails,"bbbb");

    
  }
  /** Gets the total cost of all transactions. */
  // getTotalCost() {
  //   return this.transactions.map(t => t.cost).reduce((acc, value) => acc + value, 0);
  // }
  // getTotalCost1() {
  //   let value =  this.transactions.map(t => t.cost).reduce((acc, value) => acc + value, 0) - (this.discountValue + this.taxValue);
  //   return value
  // }
  onChangeRowValue(e) {
    this.tableRowvalue.emit(e)
  }

}
