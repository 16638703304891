<div class="Gen_wrap adjustment_detail">
  <form
    class="loginForm SaleArea_general"
    [formGroup]="eventPricingForm"
    (change)="onchange(eventPricingForm.value)"
  >
    <div class="pull-right-active">
      <span>Active</span>
      <div class="checkbox-table accordion-checkbox" id="checkbox-box">
        <div class="custom-control custom-checkbox custom-control-inline">
          <input
            type="checkbox"
            class="custom-control-input"
            name="customCheckbox"
            id="activeForm"
            formControlName="isActive"
            checked="isActive" [attr.disabled]="isActiveInactivePerm ? true : null"/>
          <label class="custom-control-label" for="activeForm"></label>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0">
      <div class="col-sm-4">
        <div class="form-group">
          <label class="customlable mb-0">Name</label>
          <label class="text-danger">*</label>
          <input
            type="text"
            class="form-control input-lg inputbox_login"
            formControlName="name"
            appInputRestriction
            placeholder="Name"
            [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.name.errors) ||
                (requiredError && GeneralData.name.errors)
            }"
            maxlength="50"
            (input)="getMaxValue($event)"
            (keypress)="Highlight()"
            (focusout)="EventPricingValid()"
          />
          <div class="error">{{ errorValue }}</div>
          <div
            *ngIf="
              (submittedGeneral && GeneralData.name.errors) ||
              (requiredError && GeneralData.name.errors)
            "
            class="invalid-feedback"
          >
            <div *ngIf="GeneralData.name.errors.required">Name is required</div>
          </div>
        </div>
        <div class="form-group">
          <label class="customlable mb-0">iPad Display Name</label>
          <label class="text-danger">*</label>
          <input
            type="text"
            class="form-control input-lg inputbox_login"
            formControlName="displayName"
            appInputRestriction
            placeholder="iPad Display Name"
            maxlength="2"
            [ngClass]="{
              'is-invalid':
                (submittedGeneral && GeneralData.displayName.errors) ||
                (requiredError && GeneralData.displayName.errors)
            }"
            (keypress)="Highlight()"
            (focusout)="EventPricingValid()"
          />
          <div
            *ngIf="
              (submittedGeneral && GeneralData.displayName.errors) ||
              (requiredError && GeneralData.displayName.errors)
            "
            class="invalid-feedback"
          >
            <div *ngIf="GeneralData.displayName.errors.required">
              iPad Display Name is required
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0">
      <div class="col-sm-4">
        <div class="form-group">
          <label>Limit to Sales Area(s)?</label>
          <div class="dropdown-up">
          <ng-multiselect-dropdown
            class="specific-items items-dropdown"
            [settings]="SalesSettings"
            [data]="SalesAreaFilters"
            [(ngModel)]="salesselectedItems"
            (click)="OnDropDownClick($event)"
            [formControl]="salesAreaObj"
          >
          </ng-multiselect-dropdown>
        </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label>Delayed End Time ?</label>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group d-flex">
                <label class="radiolabel align-self-center">Yes</label>
                <div class="custom-control custom-radio custom-control item">
                  <input
                    type="radio"
                    value="true"
                    id="yes"
                    class="custom-control-input"
                    formControlName="delayedEndTime"
                    name="delayedEndTime"
                  />
                  <label class="custom-control-label QR" for="yes"></label>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group d-flex">
                <label class="radiolabel align-self-center">No</label>
                <div class="custom-control custom-radio custom-control item">
                  <input
                    type="radio"
                    value="false"
                    id="no"
                    class="custom-control-input"
                    formControlName="delayedEndTime"
                    name="delayedEndTime"
                  />
                  <label class="custom-control-label QR" for="no"></label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row no-gutters"
            *ngIf="this.eventPricingForm.get('delayedEndTime').value == 'true'"
          >
            <div class="col-sm-3">
              <input
                type="text"
                class="form-control input-lg inputbox_login"
                formControlName="minuteValue"
                #inputText
                appInputRestriction
                placeholder="1-59"
                (keypress)="numberOnly($event)"
                (keydown)="checkInput($event, inputText)"
              />
            </div>
            <div class="col-sm-8">
              <label class="align-self-center px-2">Minutes</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <div class="form-group">
          <button
            class="btn btn-sm float-right submit_button button-none"
            type="submit"
            (click)="onSaveGeneral()"
            [disabled]="buttonDisable ? true : null"
          >
            <!-- [disabled]="buttonState ? true : null" -->
            <img
              src="assets/components/images/save.png"
              alt="eventPricing"
              class="img-fluid"
            />
            <label class="label_config">Save</label>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
