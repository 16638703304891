import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MenuService } from 'src/app/services/menu.service';
import { ISalesArea, IGetEventPrice, SchedulerSelect } from 'src/app/interfaces/imenu';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { EventPricingService } from 'src/app/services/event-pricing.service';
import { DialogService } from 'src/app/services/dialog.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-event-pricing-general',
  templateUrl: './event-pricing-general.component.html',
  styleUrls: ['./event-pricing-general.component.css']
})

export class EventPricingGeneralComponent implements OnInit {
  eventPricingForm: FormGroup
  buttonDisable: boolean = false;
  submittedGeneral: any
  //@Input() public buttonState;
  SalesSettings: IDropdownSettings = {};
  SalesAreaFilters = [];
  salesselectedItems = [];
  salesAreaObj: FormControl = new FormControl('');
  loginInfo: any;
  errorValue: any;
  requiredError: boolean = false;
  public isActiveInactivePerm: boolean;

  @Output() public isInvalid = new EventEmitter();
  @Output() public GeneralDetailEventPrice = new EventEmitter();
  @Output() public event_to_schedule = new EventEmitter();
  @Output() public eventpriceData_child = new EventEmitter();
  @Output() public dropDownClicked = new EventEmitter();

  @Input()
  public set DataIdx(tableRowId) {
    this.errorValue = "";
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.GetEventDetails(tableRowId);
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  constructor(private formBuilder: FormBuilder, private menuService: MenuService, private eventService: EventPricingService,
    private helper: HelperService, private toaster: ToasterService, private dialogser: DialogService,
    private permser: PermissionService) { }

  ngOnInit(): void {
    this.isActiveInactivePerm = this.permser.isActiveInactive;

    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.eventPricingForm = this.formBuilder.group({
      id: [0],
      name: [null, Validators.required],
      displayName: [null, Validators.required],
      isActive: [true],
      salesAreaObj: new FormControl(''),
      salesselectedItems: [null],
      SalesAreaFilters: [null],
      delayedEndTime: ['false'],
      minuteValue: [null],

    });
    this.loginInfo = this.helper.getValue('LoginInfo');

    this.SalesSettings = {
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 2,    
      selectAllText: 'Select All',
      unSelectAllText: 'Select All'
    };
    this.getSalesArea(0);
  }

  get GeneralData() { return this.eventPricingForm.controls; }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkInput(e, input) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];

    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue)) {
      e.preventDefault();
      return;
    }

    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if (+newValue > 59 || newValue.length > 2) {
      e.preventDefault();
    }
  }

  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }

  onSaveGeneral() {
    this.submittedGeneral = true;
    if (this.eventPricingForm.invalid) {
      return;
    }

    const itm = this.eventPricingForm.value;
    // this.eventPricingForm.value.isActive = true;
    itm.tenantId = this.loginInfo.tenantId;
    itm.createdBy = this.loginInfo.userId;
    itm.modifiedBy = this.loginInfo.userId;
    itm.delayedEndTime = itm.delayedEndTime == 'true' ? true : false;

    if (itm.delayedEndTime == true) {
      itm.minuteValue = itm.minuteValue == null ? 0 : itm.minuteValue == "" ? 0 : itm.minuteValue;
    }
    else {
      itm.minuteValue = null;
    }
    if (this.salesselectedItems == null) {
      itm.salesAreaObj = "[]"
    } else {
      itm.salesAreaObj = JSON.stringify(this.salesselectedItems);
    }

    console.log(itm, 'event')

    this.eventService.saveEventPricing(itm).subscribe(
      async (response) => {
        this.eventPricingForm.patchValue({
          id: response.id,
        });
        if (response.id == 0) {
          this.toaster.errorPopUp(response.message);
          return;
        } else {
          //this.toaster.successPopUp(response.message);
          //await this.dialogser.ClearSession();
          this.helper.setValue('GetSessionData1', itm);
          this.eventpriceData_child.emit({ data: itm, event: "eventPage", val: false }); // for theme for save cancellation
        }
        //this.eventPricingForm.patchValue({ isActive: true }); //This is commented because After Uncheck and save its getting check
        this.submittedGeneral = false;
        this.GeneralDetailEventPrice.emit({ res: response, name: itm.name });
      })
  }

  getSalesArea(rowId) {
    const viewSalesArea: ISalesArea = {
      tenantId: this.loginInfo.tenantId
    }
    this.menuService.getSalesArea(viewSalesArea).subscribe(
      (response) => {
        var jsonSalesArea = response;
        var itemSalesArea
        var jsonToBeUsedSalesArea = [];
        for (var type in jsonSalesArea.Table) {
          itemSalesArea = {};
          itemSalesArea.key = jsonSalesArea.Table[type].id.toString();
          itemSalesArea.value = jsonSalesArea.Table[type].name;
          jsonToBeUsedSalesArea.push(itemSalesArea);
        }

        this.SalesAreaFilters = jsonToBeUsedSalesArea;
      })
  }

  GetEventDetails(rowId) {
    const eventData: IGetEventPrice = {
      id: rowId,
      tenantId: this.loginInfo.tenantId
    }
    this.eventService.selectEventPricing(eventData)
      .subscribe(res => {
        this.eventPricingForm.patchValue(res[0]);
        var jsonSalesArea = res[0].salesAreaObj;
        if (jsonSalesArea != null && jsonSalesArea != '') {
          this.salesselectedItems = JSON.parse(jsonSalesArea);
        }
        else {
          this.salesselectedItems = [];
        }
        //this.eventPricingForm.value.delayedEndTime = this.eventPricingForm.value.delayedEndTime == true ? 'true' : 'false';
        this.eventPricingForm.patchValue({
          delayedEndTime: res[0].delayedEndTime == true ? 'true' : 'false'
        });
        //   this.eventPricingForm.value.minuteValue = this.eventPricingForm.value.delayedEndTime;
        // }
      })
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  EventPricingValid() {
    if (this.eventPricingForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }
  }

  Highlight() {
    if (!this.eventPricingForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.eventPricingForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.eventpriceData_child.emit(this.eventPricingForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.eventpriceData_child.emit({ data: data, event: "eventPage", val: true });
  }

  OnDropDownClick(e) {
    this.helper.setValue('OnDirty', true);
    this.dropDownClicked.emit(true);
    this.eventpriceData_child.emit({ event: "eventPage", val: true });
  }
}