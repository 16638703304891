<div class="row">
  <div class="col-sm-12">
    <div class="form-group global-form sales-area-layout">
      <button class="btn  btn-sm float-right submit_button global-submit" type="submit"
        [disabled]="buttonDisable ? true : null" (click)="OnGlobalSave();">
        <!-- [disabled]="buttonState ? true : null" -->
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label>
      </button>
    </div>
    <div class="sales-area-accordion menu-layout-bulider">
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let item of ['Sales Area Layout','Menu Group Layout','Menu Page Layout','Items Layout']; first as isFirst"
          [expanded]="isFirst" class="outshadow">
          <mat-expansion-panel-header (click)="onChangeAccordion($event)">
            <mat-panel-title> {{item}} </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngIf="item == 'Sales Area Layout'">
            <app-sales-area-layout (rowDataEmit)="getRowData($event)" [SalesAreaList]="SalesAreaList"
              [SalesAreaLayout]="SalesAreaLayout" [salesAreabit]="salesAreabit" layoutId="salesId"
              [buttonState]="buttonDisable" (salesAreaDrop)="AccordianDropValue($event)" 
              (Popupmsg)="getPopupmsg($event)"></app-sales-area-layout>
          </div>
          <div *ngIf="item == 'Menu Group Layout'">
            <app-menu-group-layout (rowDataEmit)="getRowData($event)" [MenuGroupList]="MenuGroupList"
              [MenuGroupLayout]="MenuGroupLayout" [ParentID]="ParentID" [SalesAreaID]="SalesAreaParentID"
              layoutId="groupId" [ParentGroup]="SalesAreaMenuGroup" [buttonState]="buttonDisable"
              (menuGroupDrop)="AccordianDropValue($event)"></app-menu-group-layout>
          </div>
          <div *ngIf="item == 'Menu Page Layout'">
            <app-menu-page-layout (rowDataEmit)="getRowData($event)" [MenuPageList]="MenuPageList"
              [MenuPageLayout]="MenuPageLayout" [ParentID]="ParentID" layoutId="pageId"
              [SalesAreaID]="SalesAreaParentID" [MenuGroupID]="MenuGroupParentID" [ParentGroup]="MenuGroupMenuPage"
              [buttonState]="buttonDisable" (menuPagesDrop)="AccordianDropValue($event)"></app-menu-page-layout>
          </div>
          <div *ngIf="item == 'Items Layout'" class="item-layout-dragdrop">
            <app-drag-drop-builder [salesAreabit]="salesAreaflip" [ItemList]="ItemList" [ItemLayout]="ItemLayout"
              (layoutSave)="onSaveItem($event)" [SelectedPageInd]="PageName" [buttonState]="buttonDisable"
              [globalVal]="layoutBtn" (isDropEvent)="GetDropEvent($event)"></app-drag-drop-builder>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>