import { Injectable } from '@angular/core';
import { HelperService } from '../helper/helper.service';
import { BaseService } from '../base/base.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { SavemodifierGeneral, GetModifier, DeleteModifier,ModifierPrice ,
  EditModifierPrice,GetSaleAreaGeneralDD, ModifierBuilderLayout, 
  ItemRecipe,SaveModifierAssigned,SelectModifierAssigned, SaveItemModifiers, IPreModifiersPricing} from '../interfaces/imenu';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class ModifierService {
  //portNumber = ':8087/'
  constructor(public http: HttpClient,
    private Helper: HelperService, private base: BaseService, private router: Router) { }

  //----------------Modifier---------------------

  ModifierGroupGetCategory(e: GetSaleAreaGeneralDD) {
    return this.base.post('Admin/GetCodeMaster', e).pipe(map((response: any) => {
      return response;
    }));

  }

  ModifierGeneralSave(objModifierSave: SavemodifierGeneral) {
    return this.base.post('Modifier/SaveModifierGroup', objModifierSave).pipe(map((response: any) => {
      return response;
    }));

  }

  ModifierGroupGet(objGet: GetModifier) {
    return this.base.post('Modifier/SelectModifierGroup', objGet).pipe(map((response: any) => {
      return response;
    }));
  }

  ModifierGroupEdit(ObjModifierEdit: GetModifier) {
    return this.base.post('Modifier/EditModifierGroup', ObjModifierEdit).pipe(map((response: any) => {
      return response;
    }));

  }

  ModifierGroupDelete(objGet: DeleteModifier) {
    return this.base.post('Modifier/DeleteModifierGroup', objGet).pipe(map((response: any) => {
      return response;
    }));
  }

  ModifierPricingSelect(GetObj: ModifierPrice) {
    return this.base.post('Modifier/SelectModifierPricing', GetObj).pipe(map((response: any) => {
      return response;
    }));
  }

  ModifierPricingEdit(GetObj: EditModifierPrice) {
    return this.base.post('Modifier/EditModifiersPricing', GetObj).pipe(map((response: any) => {
      return response;
    }));
  }

  ModifierLayoutBuilderSave(GetObj: ModifierBuilderLayout) {
    return this.base.post('Modifier/SaveModifiersLayout', GetObj).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveModifierAssigned(objItemList: SaveModifierAssigned) {
    return this.base.post('Modifier/SaveItemAssigned', objItemList).pipe(map((response: any) => {
      return response;
    }));
  }
  
  SelectModifierAssigned(objItemList: SelectModifierAssigned) {
    return this.base.post('Modifier/SelectItemAssigned', objItemList).pipe(map((response: any) => {
      return response;
    }));
  }

  MenuItemRecipeSave(GetObj: ItemRecipe) {
    return this.base.post('Menu/SaveMenuItemRecipe', GetObj).pipe(map((response: any) => {
      return response;
    }));
  }

  //----------------MODIFIER LAYOUT------------

  ModifierLayoutGroupGetItem(e: GetSaleAreaGeneralDD) {
    return this.base.post('Item/GetItemFromType', e).pipe(map((response: any) => {
      return response;
    }));
  }

  SaveItemModifier(e: SaveItemModifiers) {
    return this.base.post('Item/SaveItemModifier', e).pipe(map((response: any) => {
      return response;
    }));
  }

  SelectPreModifierPricing(e: IPreModifiersPricing) {
    return this.base.post('Modifier/SelectPreModifierPricing', e).pipe(map((response: any) => {
      return response;
    }));
  }
}