<div class="row top-sec">
    <div class="col-sm-4">
        <div>
            <div class="flip-table" *ngIf="!modifierItemsData">
                <span class="text-box">{{employee}}</span> &nbsp;
                <button class="plus_btn outshadow" (click)="adddealer()"><i class="fa fa-plus"></i></button>
            </div>
            <hr class="linebar" *ngIf="!modifierItemsData">
        </div>
    </div>
    <div class="col-sm-8">
        <div class="form-group global-form">
            <button [ngClass]="{'submit_button_error': isInvalid==true}" class="btn  btn-sm float-right submit_button global-submit" type="submit" (click)="onSaveGeneral();" [disabled]="buttonDisable ? true : null">
        <img src="assets/components/images/save.png" alt="employee" class="">
        <label class="label_config">Save</label>
      </button>
        </div>
    </div>
</div>
<div class="row second-sec">
    <div class="col-sm-4 time-index">
        <div>
            <app-fliptable [dataSource]="dataSource" [displayedColumns]="displayedColumns" [columns]="columns" (hierarchy)="changeTableRowColor($event)" (hierarchyActive)="checkValue($event)" [dealerItem]="dealerRow" [dealerColour]="tableRowColor" (clearDetails)="adddealer()"
                (tableEmpName)="getTableName($event)" [DataCom]="employee" [EmployeeTableData]="EmployeeTableData" [DataDealerId]="dealerIdItem" (hierarchyData)="gethierarchyData($event)" (btnActive)="btnActive($event)">
            </app-fliptable>
        </div>
    </div>
    <div class="col-sm-8">
        <mat-accordion class="dashboard-img">
            <mat-expansion-panel *ngFor="let item of ['Dashboard', 'General']; let i = index" [expanded]="indexExpanded == i" class="outshadow" [ngClass]="matopen ? 'mat-scroll-safari':''">
                <mat-expansion-panel-header (click)="expandPanel(item,index)" [ngClass]="{'box_error': (isInvalid==true && item=='General')}">
                    <mat-panel-title (click)="onChangeAccordion($event,index)" [ngClass]="{'title_error':(isInvalid==true && item=='General')}"> {{item}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="item == 'Dashboard'">
                    <img src="assets/components/images/dashboard-graph.png" alt="" class="img-fluid">
                </div>
                <div *ngIf="item == 'General'">
                    <form class="loginForm" [formGroup]="GeneralDealerForm" (change)="onchange(GeneralDealerForm.value)">
                        <div class="d-flex justify-content-end">
                            <span>Active</span>
                            <div class="checkbox-table accordion-checkbox">
                                <div class="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" name="customCheckbox" id="ActiveHie" formControlName="isActive" checked="isActive">
                                    <label class="custom-control-label" for="ActiveHie"></label>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Dealer Name</label> <label class="text-danger">*</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="tenantName" #myDealer placeholder="Dealer Name" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralDealerData.tenantName.errors) || (requiredErrors && GeneralDealerData.tenantName.errors)}"
                                        maxlength="50" (input)="getMaxValue($event)" (keypress)="Highlight()" (focusout)="DealerNameValid()" />
                                    <div class="error">{{errorValue}}</div>
                                    <div *ngIf="(submittedGeneral && GeneralDealerData.tenantName.errors) || (requiredErrors && GeneralDealerData.tenantName.errors)" class="invalid-feedback">
                                        <div *ngIf="requiredErrors || GeneralDealerData.tenantName.errors.required">Dealer Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label class="customlable mb-0">Phone</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                        <img src="assets/components/images/USA.png" alt="employee" class="flag_img">+1
                      </span>
                                        </div>
                                        <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="contactPhone" placeholder="(xxx) xxx-xxxx" maxLength="14" (keypress)="numberOnly2($event)" (keydown)="onDeletePhone($event)" (focusout)="phoneNumberCheck($event)" [ngClass]="{ 'is-invalid': GeneralDealerForm.controls['contactPhone'].errors && submittedGeneral && !isValidFormSubmitted || mgs!=''}"
                                            appInputRestriction />
                                    </div>
                                    <div class="error">{{mgs}}</div>
                                    <div *ngIf="GeneralDealerForm.controls['contactPhone'].errors && submittedGeneral && !isValidFormSubmitted" [ngClass]="'error'">
                                        <div *ngIf="GeneralDealerForm.controls['contactPhone'].errors.pattern && mgs==''">
                                            Mobile Number not valid.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Contact First Name</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactFirstName" placeholder="Contact First Name" appInputRestriction maxlength="50" (input)="getMaxValueFName($event)" />
                                    <div class="error">{{errorValue1}}</div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Contact Last Name</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactLastName" placeholder="Contact Last Name" appInputRestriction maxlength="50" (input)="getMaxValueLName($event)" />
                                    <div class="error">{{errorValue2}}</div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Contact Email</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactEmail" placeholder="&#x2709; Example@myemailaddress.com" appInputRestriction [ngClass]="{ 'is-invalid': submittedGeneral && GeneralDealerData.contactEmail.errors }"
                                        maxlength="100" (input)="getMaxValueEmail($event)" />
                                    <div class="error">{{errorValue3}}</div>
                                    <div *ngIf="submittedGeneral && GeneralDealerData.contactEmail.errors" class="invalid-feedback">
                                        <!-- <div *ngIf="f.email.errors.required">Email is required</div> -->
                                        <div *ngIf="GeneralDealerData.contactEmail.errors.email">Email must be a valid email address
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Address</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="address" placeholder="Address" appInputRestriction />
                                    <!-- <div class="error">{{errorValue4}}</div> -->
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">City</label>
                                    <input type="text" class="form-control" formControlName="city" placeholder="City" appInputRestriction maxlength="50" (input)="getMaxValueCity($event)" />
                                    <div class="error">{{errorValue5}}</div>
                                </div>
                            </div>
                            <div class="col-sm-2 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">State</label>
                                    <!-- <input type="text" class="form-control" formControlName="state" placeholder="MT" /> -->
                                    <div class="StateWrap">
                                        <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" #select1 formControlName="state" (selectionChange)="onchange(GeneralDealerForm.value)" placeholder="Select State">
                                            <input class="form-control input-lg inputbox_login " placeholder="Search here" (keyup)="onKey($event.target.value)" />
                                            <mat-option *ngFor="let item of filteredList2" [value]="item.name">
                                                {{item.id}}
                                            </mat-option>
                                            <!-- <mat-option *ngIf="filterNodata" [value]="">No data Found</mat-option> -->

                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Zip Code</label>
                                    <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code" [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" (focusout)="zipCodeCheck($event)" (keydown)="onDeleteZipCode($event)" [ngClass]="{ 'is-invalid': zipCodeMsg!=''}"
                                    />
                                    <div class="error">{{zipCodeMsg}}</div>
                                    <!-- <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip Code"
                      (keypress)="numberOnly($event)" maxlength="7" appInputRestriction/> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Reseller Start Date</label>
                                    <mat-form-field appearance="fill" class="custom-date">
                                        <input matInput [matDatepicker]="picker" placeholder="MM-DD-YYYY" class="form-control datepicker appInputRestriction" formControlName="resellerStartDate" (click)="picker.open()" requiredField="false" (dateChange)="Highlight()" [min]="minDate">
                                        <mat-datepicker #picker class="example-header"></mat-datepicker>
                                    </mat-form-field>

                                    <div class="error">{{dateMgs}}</div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group projected-icon">
                                    <label class="customlable mb-0"> Projected Volume Monthly</label>
                                    <!-- <input currencyMask class="form-control input-lg inputbox_login currencyMask"
                      formControlName="projectedVolume" [options]="{ decimal: '.', suffix: ''}"
                      placeholder="Projected Volume Monthly" appInputRestriction /> -->
                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                    <input type="text" class="form-control" formControlName="projectedVolume" appDecimalLimit appInputRestriction />
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group projected-icon">
                                    <label class="customlable mb-0">Projected Volume LPM</label>
                                    <i class="fa fa-usd" aria-hidden="true"></i>
                                    <input type="text" class="form-control" formControlName="projectedVolLpm" appDecimalLimit appInputRestriction />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Territory</label>
                                    <input type="text" class="form-control input-lg inputbox_login" formControlName="territory" placeholder="" appInputRestriction maxlength="50" (input)="getMaxValueTerritory($event)" />
                                    <div class="error">{{errorValue6}}</div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Protected?</label>
                                    <div class="checkbox-table">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input active" name="projected" id="protected" formControlName="projected" appInputRestriction>
                                            <label class="custom-control-label active" for="protected"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="customlable mb-0">Vested?</label>
                                    <div class="checkbox-table">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input active" name="vested" id="vested" formControlName="vested" appInputRestriction>
                                            <label class="custom-control-label active" for="vested"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <!-- <div *ngIf="item == 'Schedule'">
              <app-scheduling-widget></app-scheduling-widget>
          </div> -->
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="modalComp notify-all">
        <div class="modal" [ngClass]="isOpen=='Open' ? 'notify' : isOpen=='Close' ? 'no-notify' : 'NotCaled'" id="notifyModal" tabindex="-1" role="dialog" aria-labelledby="notifyModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="Communication_tab m-2">
                        <div class="remove_tab m-3">

                            <div class="modal-header">
                                <div class="text-right">
                                    <button type="button" class="btn-close close" aria-label="Close" data-dismiss="modal">
                    <span aria-hidden="true" class="visually-hidden">&times;</span>
                  </button>
                                </div>
                            </div>

                            <div class="modal-body text-center p-0">
                                <div class="mt-2">
                                    <label class="txt-color">Changes that you made may not be saved.</label>
                                </div>
                            </div>

                            <div class="notify-footer modal-footer text-center">
                                <button type="button" class="notify-btns btn  btn-sm" data-dismiss="modal">
                  <img src="assets/components/images/continue.png" alt="employee" width="85%">
                </button>
                                <button type="button" class="notify-btns btn  btn-sm" data-dismiss="modal">
                  <img src="assets/components/images/cancel.png" alt="employee" width="85%">
                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #template>
        <div class=" text-right">
            <button type="button" class="btn-close close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
        </div>
        <!-- <div class="mt-2">
    <label class="txt-color">Changes will be lost! Do you want to navigate away from this current page?.</label>
  </div> -->
        <div class="notify-footer modal-footer text-center">
