import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DevicesRoutingModule } from './devices-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmvComponent } from './emv/emv.component';
import { RouterModule, Routes } from '@angular/router';
import { TabletsComponent } from './tablets/tablets.component';
import { EmvGeneralComponent } from './emv-general/emv-general.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { FliptableComponent } from 'src/app/elements/fliptable/fliptable.component';
import { EmployeeModule } from '../employee/employee.module';
import { TabletGeneralComponent } from './tablet-general/tablet-general.component';
import { PrinterComponent } from './printer/printer.component';
import { PrinterGeneralComponent } from './printer-general/printer-general.component';
import { MatSelectModule } from '@angular/material/select';
import { TabletLogisticComponent } from './tablet-logistic/tablet-logistic.component';
import { ReceiptConfigComponent } from './receipt-config/receipt-config.component';
import { ReceiptConfigGeneralComponent } from './receipt-config-general/receipt-config-general.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown'
import { InputMaskModule } from '@ngneat/input-mask';
const appRoutes: Routes = []

@NgModule({
  declarations: [
    EmvComponent,
    TabletsComponent,
    EmvGeneralComponent,
    TabletGeneralComponent,
    PrinterComponent,
    PrinterGeneralComponent,
    TabletLogisticComponent,
    ReceiptConfigComponent,
    ReceiptConfigGeneralComponent

  ],
  imports: [
    CommonModule,
    DevicesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    BrowserModule,
    EmployeeModule,
    MatSelectModule,
    RouterModule.forChild(appRoutes),
    NgMultiSelectDropDownModule,
    InputMaskModule

  ],
  exports:[
    EmvComponent,
    TabletsComponent,
    PrinterComponent,
    ReceiptConfigComponent
  ]
})
export class DevicesModule { }
