<div class="house_wrap">
    <form class="creditForm loginForm" [formGroup]="CreditCardForm" formControlName="firstCtrl">
        <!-- <div class="row ">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Config Name</label><label class="text-danger">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="configName" placeholder="Name"
                    appInputRestriction
                    [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.configName.errors }"/>
                    <div *ngIf="submittedGeneral && GeneralData.configName.errors" class="invalid-feedback">
                        <div *ngIf="GeneralData.configName.errors.required">Name is required</div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row ">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Business Name</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="businessName" placeholder="Business Name" appInputRestriction [ngClass]="{'is-invalid': (submittedGeneral && GeneralData.businessName.errors) || (requiredError && GeneralData.businessName.errors)}"
                        maxlength="25" (input)="getMaxValueBName($event)" (keypress)="Highlight()" (focusout)="CreditCardValid()" />
                    <div class="error">{{errorValue}}</div>
                    <div *ngIf="(submittedGeneral && GeneralData.businessName.errors) || (requiredError && GeneralData.businessName.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.businessName.errors.required">Business Name is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Phone</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                                <img src="assets/components/images/USA.png" alt="employee" class="flag_img">+1
                            </span>
                        </div>
                        <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="phone" placeholder="(xxx) xxx-xxxx" maxLength="14" (keypress)="numberOnly2($event)" appInputRestriction [ngClass]="{'is-invalid': CreditCardForm.controls['phone'].errors && submittedGeneral && !isValidFormSubmitted}"
                        />
                    </div>
                    <div class="error">{{mgs}}</div>
                    <div *ngIf="CreditCardForm.controls['phone'].errors && submittedGeneral && !isValidFormSubmitted" [ngClass]="'error'">
                        <div *ngIf="CreditCardForm.controls['phone'].errors.pattern && mgs==''">
                            Mobile Number not valid.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">First Name</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactFirstName" placeholder="First Name" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.contactFirstName.errors) || (requiredError && GeneralData.contactFirstName.errors)}"
                        maxlength="25" (input)="getMaxValueFName($event)" (keypress)="Highlight()" (focusout)="CreditCardValid()" />
                    <div class="error">{{errorValue1}}</div>
                    <div *ngIf="(submittedGeneral && GeneralData.contactFirstName.errors) || (requiredError && GeneralData.contactFirstName.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.contactFirstName.errors.required">First Name is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Last Name</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactLastName" placeholder="Last Name" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.contactLastName.errors) || (requiredError && GeneralData.contactLastName.errors)}"
                        maxlength="25" (input)="getMaxValueLName($event)" (keypress)="Highlight()" (focusout)="CreditCardValid()" />
                    <div class="error">{{errorValue2}}</div>
                    <div *ngIf="(submittedGeneral && GeneralData.contactLastName.errors) || (requiredError && GeneralData.contactLastName.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.contactLastName.errors.required">Last Name is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Contact Email</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="contactEmail" placeholder="&#x2709; Example@myemailaddress.com" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.contactEmail.errors) || (requiredError && GeneralData.contactEmail.errors)}"
                        maxlength="100" (input)="getMaxValueEmail($event)" (keypress)="Highlight()" (focusout)="CreditCardValid()" />
                    <div class="error">{{errorValue3}}</div>

                    <div *ngIf="(submittedGeneral && GeneralData.contactEmail.errors) || (requiredError && GeneralData.contactEmail.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.contactEmail.errors.required">Contact Email is required</div>
                        <div *ngIf="GeneralData.contactEmail.errors.email">Email must be a valid email address</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label class="customlable mb-0">Address</label><label class="text-danger req_label">*</label>
                    <input type="text" class="form-control input-lg inputbox_login" formControlName="address" placeholder="Address" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.address.errors) || (requiredError && GeneralData.address.errors)}"
                        (keypress)="Highlight()" (focusout)="CreditCardValid()" />
                    <div *ngIf="(submittedGeneral && GeneralData.address.errors) || (requiredError && GeneralData.address.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.address.errors.required">Address is required</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">City</label><label class="text-danger req_label">*</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="city" placeholder="City" appInputRestriction [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.city.errors) || (requiredError && GeneralData.city.errors)}" maxlength="25"
                                (input)="getMaxValueCity($event)" (keypress)="Highlight()" (focusout)="CreditCardValid()" />
                            <div class="error">{{errorValue4}}</div>
                            <div *ngIf="(submittedGeneral && GeneralData.city.errors) || (requiredError && GeneralData.city.errors)" class="invalid-feedback">
                                <div *ngIf="GeneralData.city.errors.required">City is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">State</label><label class="text-danger req_label">*</label>
                            <!-- <input type="text" class="form-control input-lg inputbox_login" formControlName="state" placeholder="MT"
                                [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.state.errors }" /> -->
                            <mat-form-field class="StateWrap" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.state.errors) || (requiredError && GeneralData.state.errors)}" (keypress)="Highlight()" (focusout)="CreditCardValid()">
                                <mat-select (openedChange)="matstate($event)" [disableOptionCentering]="true" #select1 formControlName="state" placeholder="Select State">
                                    <input class="form-control input-lg inputbox_login" placeholder="Search here" (keyup)="onKey($event.target.value)" maxlength="25" />
                                    <mat-option *ngFor="let item of filteredList2" [value]="item.id">
                                        {{item.id}}
                                    </mat-option>
                                    <mat-option *ngIf="filterNodata" [value]="">No data Found</mat-option>

                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="(submittedGeneral && GeneralData.state.errors) || (requiredError && GeneralData.state.errors)" class="invalid-feedback">
                                <div *ngIf="GeneralData.state.errors.required">State is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="customlable mb-0">Zip Code</label><label class="text-danger req_label">*</label>
                            <input type="text" class="form-control input-lg inputbox_login" formControlName="zip" placeholder="Zip" [maxlength]="10" appInputRestriction (keypress)="onKeypressEvent($event)" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.zip.errors) || (requiredError && GeneralData.zip.errors)}"
                                (keypress)="numberOnly($event)" (focusout)="CreditCardValid()" />
                            <div class="error">{{zipCodeMsg}}</div>
                            <div *ngIf="(submittedGeneral && GeneralData.zip.errors) || (requiredError && GeneralData.zip.errors)" class="invalid-feedback">
                                <div *ngIf="GeneralData.zip.errors.required">Zip is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 p-2">
                <div class="form-groupcard-header border-bottom-0 bg-transparent">
                    <label class="customlable mb-0">GateWay Selection</label><label class="text-danger req_label">*</label>
                    <mat-form-field appearance="fill">
                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="gatewaySelection" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.gatewaySelection.errors) || (requiredError && GeneralData.gatewaySelection.errors)}"
                            (keypress)="Highlight()" (focusout)="CreditCardValid()">
                            <mat-option value="true" id="1" selected>DataCap</mat-option>
                            <mat-option value="false" id="2">BridgePay</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="(submittedGeneral && GeneralData.gatewaySelection.errors) || (requiredError && GeneralData.gatewaySelection.errors)" class="invalid-feedback">
                        <mat-error *ngIf="(submittedGeneral && GeneralData.gatewaySelection.errors) || (requiredError && GeneralData.gatewaySelection.errors)">GateWay Selection is required
                        </mat-error>
                    </mat-error>
                </div>

            </div>
        </div>
        <div class="row p-2" *ngIf="CreditCardForm.get('gatewaySelection').value">
            <div class="col-sm-8 credit_toggle">
                <div class="row">
                    <div class="col-sm-2">
                        <label>Swipe</label>
                        <div class="checkbox-table accordion-checkbox">
                            <div class="custom-control custom-checkbox custom-control item">
                                <input type="checkbox" class="custom-control-input " name="swipe" formControlName="swipe" id="Allowsome">
                                <label class="custom-control-label mb-0"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <label>EMV</label>
                        <div class="checkbox-table accordion-checkbox" (change)="onEmv($event)">
                            <div class="custom-control custom-checkbox custom-control item">
                                <input type="checkbox" class="custom-control-input " name="emv" formControlName="emv" id="Allowsome">
                                <label class="custom-control-label mb-0"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <label>App</label>
                        <div class="checkbox-table accordion-checkbox">
                            <div class="custom-control custom-checkbox custom-control item">
                                <input type="checkbox" class="custom-control-input " name="app" formControlName="app" id="Allowsome">
                                <label class="custom-control-label mb-0"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <label>OLO</label>
                        <div class="checkbox-table accordion-checkbox">
                            <div class="custom-control custom-checkbox custom-control item">
                                <input type="checkbox" class="custom-control-input " name="olo" formControlName="olo" id="Allowsome">
                                <label class="custom-control-label mb-0"></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <label>Kiosk</label>
                        <div class="checkbox-table accordion-checkbox">
                            <div class="custom-control custom-checkbox custom-control item">
                                <input type="checkbox" class="custom-control-input " name="kiosk" formControlName="kiosk" id="Allowsome">
                                <label class="custom-control-label mb-0"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-sm-12">
                        <label style="font-size: 16px;">EMV</label>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-sm-2">
                        <label>TranCloud</label>
                        <div class="checkbox-table accordion-checkbox">
                            <div class="custom-control custom-checkbox custom-control item">
                                <input type="checkbox" class="custom-control-input" name="tranCloud" formControlName="tranCloud" id="Allowsome" #tranCheckBox>
                                <label class="custom-control-label mb-0"></label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-4" *ngIf="tranCheckBox.checked">
                        <label>TranCloud ID</label><label class="text-danger">*</label>
                        <input type="text" class="form-control" formControlName="tranCloudId" placeholder="TranCloud ID"
                        appInputRestriction [ngClass]="{ 'is-invalid': submittedGeneral && GeneralData.tranCloudId.errors }"/>
                        <div *ngIf="submittedGeneral && GeneralData.tranCloudId.errors" class="invalid-feedback">
                            <div *ngIf="GeneralData.tranCloudId.errors.required">TranCloud ID is required</div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Platform</label><label class="text-danger req_label">*</label>
                    <mat-form-field appearance="fill" [ngClass]="{ 'is-invalid': (submittedGeneral && GeneralData.platformPulldown.errors)}" (keypress)="Highlight()" (focusout)="CreditCardValid()">
                        <mat-select (openedChange)="matstate($event)" type="text" class="form-control" formControlName="platformPulldown" (selectionChange)="onDropDown($event.value)">
                            <mat-option value="tsys" selected>TSYS</mat-option>
                            <mat-option value="firstdata" selected disabled>FirstData</mat-option>
                            <mat-option value="vantiv" selected disabled>Vantiv</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="(submittedGeneral && GeneralData.platformPulldown.errors)" class="invalid-feedback">
                        <div *ngIf="GeneralData.platformPulldown.errors.required">Platform Pulldown is required</div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-8 tranCloud-giftcard">
                <div class="col-sm-12 p-0">
                    <div class="form-group d-flex pt-2">
                        <label>PrePaid (Gift)</label>
                        <input type="text" class="form-control" formControlName="prePaidGift" placeholder="PrePaid (Gift)"
                        appInputRestriction/>
                    </div>
                </div>
                <div class="col-sm-12 p-0 ">
                    <div class="form-group d-flex">
                        <label>Card Present</label>
                        <input type="text" class="form-control" formControlName="cardPresent" placeholder="Card Present"
                        appInputRestriction/>
                    </div>
                </div>
                <div class="col-sm-12 p-0 ">
                    <div class="form-group d-flex">
                        <label>Card Not Present</label>
                        <input type="text" class="form-control" formControlName="cardNotPresent" placeholder="Card Not Present"
                        appInputRestriction/>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- <div class="row">
            <div class="form-group col-sm-12">
                <button class="btn btn-sm float-right submit_button" type="submit"
                (click)="onSaveGeneral()" [disabled]="buttonDisable ? true : null">

                    <img src="assets/components/images/save.png" alt="employee" class="">
                    <label class="label_config">Save</label>
                </button>
            </div>
        </div> -->
    </form>
</div>
<!-- ngx-intl
    <ngx-intl-tel-input [cssClass]="'form-control country-tel-code'" [enableAutoCountrySelect]="true"
    [enablePlaceholder]="true" [preferredCountries]="preferredCountries" [searchCountryFlag]="true"
    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="10"
    [tooltipField]="TooltipLabel.Name" [phoneValidation]="false"
    [separateDialCode]="separateDialCode" formControlName="phone"
    >
  </ngx-intl-tel-input>
ngx-intl -->
