import { Injectable } from '@angular/core';
import { MessageDto, chatMesage, iFrontEndMsg } from '../interfaces/iWebSocket';
import { LogLevel, HubConnectionBuilder, HubConnection } from '@aspnet/signalr';
import { Subject, Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
// import { MessagePackHubProtocol } from '@aspnet/signalr-protocol-msgpack';  

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private hubConnection: HubConnection
 // public messages: chatMesage[] = [];

  public FrontEndmessages: iFrontEndMsg[] = [];
  private connectionUrl = 'http://204.236.184.97/Handshake/SendMessage';
  private apiUrl = 'http://204.236.184.97/Handshake/SendMessage';

  //   private connectionUrl = 'https://localhost:44327/Handshake/SendMessage';
  // private apiUrl = 'https://localhost:44327/Handshake/SendMessage';
  constructor(private http: HttpClient) { }

  public connect = () => {
    this.startConnection();
    this.addListeners();
  }

  // public sendMessageToApi(message: string) {
  //   return this.http.post(this.apiUrl, this.buildChatMessage(message))
  //     .pipe(tap(_data=> console.log("message sucessfully sent to api controller")));
  // }

  // public sendMessageToHub(message: string) {
  //   var promise = this.hubConnection.invoke("BroadcastAsync", this.buildChatMessage(message))
  //     .then(() => { console.log('message sent successfully to hub'); })
  //     .catch((err) => console.log('error while sending a message to hub: ' + err));

  //   return from(promise);
  // }
  
  public sendMessageToHub(FrontEndmessages) {
    var promise = this.hubConnection.invoke("BroadcastAsync1", FrontEndmessages)
      .then(() => { console.log('message sent successfully to hub'); })
      .catch((err) => console.log('error while sending a message to hub: ' + err));

    return from(promise);
  }

  private getConnection(): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(this.connectionUrl)
    //  .withHubProtocol(new MessagePackHubProtocol())  
        .configureLogging(LogLevel.Trace)
      .build();
  }

  private buildChatMessage(message: string): chatMesage {
   // console.log(this.hubConnection,'HuubConnection');
    return {
      connectionId: '1',
      text: message,
      dateTime: new Date()
    };
  }

  private startConnection() {
    this.hubConnection = this.getConnection();

    this.hubConnection.start()
      .then(() => console.log('connection started'))
      .catch((err) => console.log('error while establishing signalr connection: ' + err))
  }

  private addListeners() {
    this.hubConnection.on("messageReceivedFromApi", (data: iFrontEndMsg) => {
      console.log("message received from API Controller")
      this.FrontEndmessages.push(data);
    })
    this.hubConnection.on("messageReceivedFromHub", (data: iFrontEndMsg) => {
      console.log("message received from Hub")
      this.FrontEndmessages.push(data);
    })
    this.hubConnection.on("newUserConnected", _ => {
      console.log("new user connected")
    })
  }
}