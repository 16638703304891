import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { AdminService } from 'src/app/services/admin.service';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { IRole } from 'src/app/interfaces/iadmin';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-type-of-business-general',
  templateUrl: './type-of-business-general.component.html',
  styleUrls: ['./type-of-business-general.component.css']
})

export class TypeOfBusinessGeneralComponent implements OnInit {
  TypeofBusinessForm: FormGroup;
  buttonDisable: boolean = false;
  submittedGeneral: any;
  loginInfo: any;
  isValidated = false;
  hirarcyvalue: any;
  errorValue: any;

  @Input() public tableRowId;
  @Output() public generalBusiness = new EventEmitter();
  @Output() public typeofbusinessPageData_child = new EventEmitter();

  requiredError: boolean = false;
  @Output() matopen:EventEmitter<any> = new EventEmitter()
  matstate($event){
    this.matopen.emit($event)
  }

  @Output() public isInvalid = new EventEmitter();
  constructor(private formBuilder: FormBuilder, private helper: HelperService, private adminser: AdminService,
    private toaster: ToasterService, private dialogser: DialogService) { }

  ngOnInit(): void {
    this.helper.setValue('GetSessionData1', null);
    this.buttonDisable = false;
    this.loginInfo = this.helper.getValue('LoginInfo');
    this.TypeofBusinessForm = this.formBuilder.group({
      id: [0],
      name: ['', Validators.required],
      company: ['']
    })
    this.adminser.HierachyValue.subscribe(data => {
      this.hirarcyvalue = data;
      if (this.hirarcyvalue.company != undefined && this.hirarcyvalue.company != null &&
        this.hirarcyvalue.company != "") {
        this.TypeofBusinessForm.patchValue({
          company: this.hirarcyvalue.company.name,
          name: "",
        });
      }
      else {
        if (localStorage.getItem('comp_ID') !== undefined && localStorage.getItem('comp_ID') !== null &&
          localStorage.getItem('comp_ID') !== "") {
          this.TypeofBusinessForm.patchValue({
            company: localStorage.getItem('comp_name'),
            name: "",
          });
        }
      }
    });
  }

  get GeneralData() { return this.TypeofBusinessForm.controls; }

  @Input()
  public set DataIdx(tableRowId) {
    this.errorValue = "";
    if (tableRowId != undefined && tableRowId != null) {
      this.adminser.HierachyValue.subscribe(data => {
        this.hirarcyvalue = data;
        if (this.hirarcyvalue.company != undefined && this.hirarcyvalue.company != null &&
          this.hirarcyvalue.company != "") {
          if (tableRowId > 0) {
            this.EditTypeofBusinessDetails(tableRowId);
          }
        }
        else {
          if (localStorage.getItem('comp_ID') !== undefined && localStorage.getItem('comp_ID') !== null &&
            localStorage.getItem('comp_ID') !== "") {
            if (tableRowId > 0) {
              this.EditTypeofBusinessDetails(tableRowId);
            }
          }
        }
      });
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  EditTypeofBusinessDetails(rowId) {
    const bussList: IRole = {
      id: rowId,
      tenantId: //this.hirarcyvalue.company.id
        (localStorage.getItem('comp_ID') == "" || localStorage.getItem('comp_ID') == undefined) ?
          0 : parseInt(localStorage.getItem('comp_ID'))
    }
    this.adminser.EditTypeOfBusiness(bussList)
      .subscribe(res => {
        if (res.length > 0) {
          this.TypeofBusinessForm.patchValue(res[0]);
          this.TypeofBusinessForm.patchValue({
            company: //this.hirarcyvalue.company.name,
              localStorage.getItem('comp_name')
          });
        }
      })
  }

  onSaveGeneral() {
    this.errorValue = "";
    this.submittedGeneral = true;
    if (this.TypeofBusinessForm.invalid) {
      return;
    }

    let adjserv;
    adjserv = this.TypeofBusinessForm.value;
    adjserv.createdBy = this.loginInfo.userId;
    adjserv.modifiedBy = this.loginInfo.userId;
    adjserv.isdeleted = true;
    adjserv.name = this.TypeofBusinessForm.value.name;
    adjserv.tenantId = //this.hirarcyvalue.company.id;
      (localStorage.getItem('comp_ID') == "" || localStorage.getItem('comp_ID') == undefined) ?
        0 : parseInt(localStorage.getItem('comp_ID'))

    this.adminser.SaveTypeOfBusiness(adjserv).subscribe(async res => {
      this.TypeofBusinessForm.patchValue({
        id: res.id,
      });
      if (res.id == 0) {
        this.toaster.errorPopUp(res.message);
      } else {
        this.toaster.successPopUp(res.message);
        await this.dialogser.ClearSession();
        this.helper.setValue('GetSessionData1', adjserv);
        this.typeofbusinessPageData_child.emit({ data: adjserv, event: "typeofbusinessPage", val: false });
      }
      this.submittedGeneral = false;
      this.generalBusiness.emit({ res: res, name: adjserv.name });
    })
  }

  getMaxValue(e) {
    if (e.target.value.length == 50) {
      this.errorValue = "Max-Limit 50 characters reached";
    }
    else {
      this.errorValue = ""
    }
  }

  BusinessValid() {
    if (this.TypeofBusinessForm.invalid) {
      this.isInvalid.emit(true);
      this.requiredError = true;
      return;
    }
    else {
      this.requiredError = false;
    }
  }

  Highlight() {
    if (!this.TypeofBusinessForm.invalid) {
      this.isInvalid.emit(false);
    }
  }

  ngAfterViewInit() {
    this.TypeofBusinessForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.typeofbusinessPageData_child.emit(this.TypeofBusinessForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.typeofbusinessPageData_child.emit({ data: data, event: "typeofbusinessPage", val: true });
  }
}
