import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/helper/helper.service';
import { ToasterService } from 'src/app/helper/toaster.service';
import { TagsService } from 'src/app/services/tags.service';
import { ISelectTagDetails } from 'src/app/interfaces/i-tags';
import { exit } from 'process';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-day-part-schedule',
  templateUrl: './day-part-schedule.component.html',
  styleUrls: ['./day-part-schedule.component.css']
})

export class DayPartScheduleComponent implements OnInit {
  ScheduleDayForm: FormGroup
  buttonDisable: boolean = false;
  //id: any = 0;
  loginInfo: any;
  isActive: any;
  dayPartID: any = 0;
  jobPositionarray: any = [];
  submittedGeneral: any;
  weekDays = [
    { id: 1, name: 'MON', isSelected: false },
    { id: 2, name: 'TUE', isSelected: false },
    { id: 3, name: 'WED', isSelected: false },
    { id: 4, name: 'THU', isSelected: false },
    { id: 5, name: 'FRI', isSelected: false },
    { id: 6, name: 'SAT', isSelected: false },
    { id: 7, name: 'SUN', isSelected: false }
  ];

  FullweekDays = [];
  DaysNotSel: any = [];
  jsonArrayValue: any = [];
  roleEmployeearray: any = [];
  selectedday: any = "";
  weekDaysArray: any = [];
  FinalArray: any = [];
  FinalSaveArray: any = [];
  FinalRemoveArray: any = [];
  isDayObj = false;
  isStartTime = false;
  isEndTime = false;
  private onDestroy$: Subject<void> = new Subject<void>();

  // todayNumber: number = Date.now();
  // todayDate : Date = new Date();
  // todayString : string = new Date().toDateString();
  // todayISOString : string = new Date().toISOString();

  @Output() public tagsDaypartPageData_child = new EventEmitter();
  constructor(private formBuilder: FormBuilder, private tagser: TagsService,
    private helper: HelperService, private toaster: ToasterService, private changeDetector: ChangeDetectorRef) { }

  @Input()
  public set tagIdEmit(tableRowId) {
    if (tableRowId != undefined && tableRowId != null && tableRowId != "") {
      this.loginInfo = this.helper.getValue('LoginInfo');
      this.dayPartID = tableRowId;
      if (this.helper.getValue('OnSaveTagData') == "") {
        this.GetDayPartDetails(tableRowId);
      }
    }
  }

  @Input()
  public set buttonState(btnst) {
    if (btnst == undefined) {
      this.buttonDisable = false;
    }
    else {
      this.buttonDisable = !btnst;
    }
  }

  ngOnInit(): void {
    this.helper.setValue('OnSaveTagData', "");
    this.weekDaysArray = [];
    this.weekDaysArray.push(this.weekDays)
    this.buttonDisable = false;
    this.ScheduleDayForm = this.formBuilder.group({
      daypartList: this.formBuilder.array([this.newDayPartItems()]),
    })
    this.FullweekDays = this.weekDays;
    this.loginInfo = this.helper.getValue('LoginInfo');
  }

  get scheduleData() {
    return this.ScheduleDayForm.get("daypartList") as FormArray
  }

  daypartList(): FormArray {
    return this.ScheduleDayForm.get("daypartList") as FormArray
  }

  newDayPartItems(): FormGroup {
    return this.formBuilder.group({
      //daypartObj: [null],
      daysObj: [[]],
      startTime: [null],
      endTime: [null],
    })
  }

  addNewDayPartItems() {
    this.daypartList().push(this.newDayPartItems());
    this.weekDaysArray.push(this.weekDays);
  }

  // removeNewDayPartItems(i: number) {
  //   this.daypartList().removeAt(i);
  //   this.FinalSaveArray = this.ScheduleDayForm.value.daypartList;
  //   console.log("removeNewDayPartItems",this.FinalSaveArray);
  //   //   this.weekDaysArray.forEach((element,i)=>{
  //   //    this.weekDaysArray.splice(i,1);
  //   //  });
  //   // delete this.weekDaysArray[i]
  // }

  removeNewDayPartItems(index: number): void {
    //if (!((<FormArray>this.ScheduleDayForm.get('daypartList')).length == 1)) {
    (<FormArray>this.ScheduleDayForm.get('daypartList')).removeAt(index);
    //}

    //this.isremove = false;
    //this.changeDetector.detectChanges();
    //this.isremove = true;
    //const dynFormArray = this.ScheduleDayForm.controls["daypartList"] as FormArray;
    //dynFormArray.controls = [];

    this.FinalRemoveArray = this.ScheduleDayForm.value;
    this.FinalSaveArray = this.ScheduleDayForm.value.daypartList;
    this.roleEmployeearray = this.FinalSaveArray;
    if (this.roleEmployeearray.length > 0) {
      const dynFormArray = this.ScheduleDayForm.controls["daypartList"] as FormArray;
      dynFormArray.controls = [];
      this.weekDaysArray = [];
      this.FinalArray = [];

      if (this.roleEmployeearray != undefined && this.roleEmployeearray != null &&
        this.roleEmployeearray != "" && this.roleEmployeearray != []) {
        if (this.roleEmployeearray.length > 0) {
          for (let j = 0; j < this.roleEmployeearray.length; j++) {
            var dayobjlen = this.roleEmployeearray[j].daysObj.length;
            var dayobjtostring = this.roleEmployeearray[j].daysObj.toString();
            dynFormArray.push(this.newDayPartItems());
            if (typeof dayobjtostring == 'string' && dayobjlen > 0) {
              var StringtoIntArr = dayobjtostring.split(',').map(function (item) {
                return parseInt(item, 10);
              });

              const sortnum = StringtoIntArr.sort();
              const map = new Map();
              this.jsonArrayValue = [];
              for (const item of sortnum) {
                if (!map.has(item)) {
                  map.set(item, true);    // set any value to Map
                  switch (item) {
                    case 1:
                      this.selectedday = 'MON';
                      break;
                    case 2:
                      this.selectedday = 'TUE';
                      break;
                    case 3:
                      this.selectedday = 'WED';
                      break;
                    case 4:
                      this.selectedday = 'THU';
                      break;
                    case 5:
                      this.selectedday = 'FRI';
                      break;
                    case 6:
                      this.selectedday = 'SAT';
                      break;
                    case 7:
                      this.selectedday = 'SUN';
                      break;
                    default:
                      this.selectedday = '';
                      break;
                  }

                  this.jsonArrayValue.push({
                    id: item,
                    name: this.selectedday,
                    isSelected: true,
                  });
                }
              }

              this.DaysNotSel = this.FullweekDays.filter(o1 => !this.jsonArrayValue.some(o2 => o1.name === o2.name));
              for (const itemnotsel of this.DaysNotSel) {
                if (!map.has(itemnotsel)) {
                  map.set(itemnotsel, true);
                  this.jsonArrayValue.push({
                    id: itemnotsel.id,
                    name: itemnotsel.name,
                    isSelected: false,
                  });
                }
              }
            }
            else {
              this.jsonArrayValue = [];
              this.DaysNotSel = this.FullweekDays.filter(o1 => !this.jsonArrayValue.some(o2 => o1.name === o2.name));
              const map = new Map();
              for (const itemnotsel of this.DaysNotSel) {
                if (!map.has(itemnotsel)) {
                  map.set(itemnotsel, true);
                  this.jsonArrayValue.push({
                    id: itemnotsel.id,
                    name: itemnotsel.name,
                    isSelected: false,
                  });
                }
              }
            }

            let sortedDays = this.jsonArrayValue.sort((first, second) => 0 - (first.id > second.id ? -1 : 1));
            this.FinalArray.push(sortedDays);
            this.weekDaysArray.push(this.FinalArray[j]);
          }
        }
      }
    }
    this.ScheduleDayForm.patchValue(this.FinalRemoveArray);
  }

  onCheckChange(event, id, index) {
    if (event.target.checked == true) {
      let daysObjlen = this.ScheduleDayForm.value.daypartList[index].daysObj;
      if (typeof daysObjlen == 'string') {
        let value = this.ScheduleDayForm.value.daypartList[index].daysObj;
        let myArray = value.split(',');
        myArray.push(event.target.id.toString());
        this.ScheduleDayForm.value.daypartList[index].daysObj = myArray.toString();
      }
      else {
        this.ScheduleDayForm.value.daypartList[index].daysObj.splice(0, 0, event.target.id);
      }
    } else {
      // this.ScheduleDayForm.value.daypartList[index].daysObj.splice(0, 0, event.target.id);
      // this.ScheduleDayForm.value.daypartList[index].daysObj.replace(new RegExp(event.target.id + ',?'), '')
      let value1 = this.ScheduleDayForm.value.daypartList[index].daysObj;
      if (typeof value1 == 'string') {
        value1 = value1.split(',');
      }
      value1.splice(value1.indexOf(event.target.id), 1);
      this.ScheduleDayForm.value.daypartList[index].daysObj = value1.join(',');
    }
  }

  GetDayPartDetails(rowId?) {
    this.FinalArray = [];
    this.FinalRemoveArray = [];
    this.FinalSaveArray = [];
    const tagList: ISelectTagDetails = {
      id: rowId,
      tenantId: this.loginInfo.tenantId
    }
    this.tagser.SelectDayPartDetails(tagList).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      this.FinalSaveArray = [];
      this.FinalSaveArray = res.daypartList;
      this.roleEmployeearray = res.daypartList;
      if (this.roleEmployeearray.length > 0) {
        const dynFormArray = this.ScheduleDayForm.controls["daypartList"] as FormArray;
        dynFormArray.controls = [];
        this.weekDaysArray = [];
        this.FinalArray = [];

        if (this.roleEmployeearray != undefined && this.roleEmployeearray != null &&
          this.roleEmployeearray != "" && this.roleEmployeearray != []) {
          if (this.roleEmployeearray.length > 0) {
            for (let j = 0; j < this.roleEmployeearray.length; j++) {
              this.helper.setValue("tag_daypartIdvalue", this.roleEmployeearray[j].id);
              dynFormArray.push(this.newDayPartItems());
              if (typeof this.roleEmployeearray[j].daysObj == 'string') {
                var StringtoIntArr = this.roleEmployeearray[j].daysObj.split(',').map(function (item) {
                  return parseInt(item, 10);
                });

                const sortnum = StringtoIntArr.sort();
                const map = new Map();
                this.jsonArrayValue = []
                for (const item of sortnum) {
                  if (!map.has(item)) {
                    map.set(item, true);    // set any value to Map
                    switch (item) {
                      case 1:
                        this.selectedday = 'MON';
                        break;
                      case 2:
                        this.selectedday = 'TUE';
                        break;
                      case 3:
                        this.selectedday = 'WED';
                        break;
                      case 4:
                        this.selectedday = 'THU';
                        break;
                      case 5:
                        this.selectedday = 'FRI';
                        break;
                      case 6:
                        this.selectedday = 'SAT';
                        break;
                      case 7:
                        this.selectedday = 'SUN';
                        break;
                      default:
                        this.selectedday = '';
                        break;
                    }
                    this.jsonArrayValue.push({ id: item, name: this.selectedday, isSelected: true });
                  }
                }

                this.DaysNotSel = this.FullweekDays.filter(o1 => !this.jsonArrayValue.some(o2 => o1.name === o2.name));
                for (const itemnotsel of this.DaysNotSel) {
                  if (!map.has(itemnotsel)) {
                    map.set(itemnotsel, true);
                    this.jsonArrayValue.push({
                      id: itemnotsel.id,
                      name: itemnotsel.name,
                      isSelected: false,
                    });
                  }
                }
                let sortedDays = this.jsonArrayValue.sort((first, second) => 0 - (first.id > second.id ? -1 : 1));
                this.FinalArray.push(sortedDays);
                this.weekDaysArray.push(this.FinalArray[j]);
              }
            }
          }
        }
        this.ScheduleDayForm.patchValue(res);
      } else {
        const dynFormArray = this.ScheduleDayForm.controls["daypartList"] as FormArray;
        dynFormArray.controls = [];
        dynFormArray.push(this.newDayPartItems());
        this.weekDaysArray = [];
        this.weekDaysArray.push(this.weekDays);
      }
    }), err => console.error('Error', err), () => console.log('HTTP request completed.');
  }

  onSave(schdayvalue?) {
    this.submittedGeneral = true;
    const FinalDayPartData = this.ScheduleDayForm.value;
    if (this.ScheduleDayForm != undefined && this.ScheduleDayForm != null) {
      if (this.ScheduleDayForm.invalid != undefined && this.ScheduleDayForm.invalid != null) {
        if (this.ScheduleDayForm.invalid) {
          return;
        }

        this.ScheduleDayForm.value.isActive = true;
        this.ScheduleDayForm.value.id = this.helper.getValue('tag_daypartIdvalue') == '' ? 0 :
          this.helper.getValue('tag_daypartIdvalue');
        this.ScheduleDayForm.value.dayPartID = this.helper.getValue('tagIdvalue');
        this.ScheduleDayForm.value.tenantId = this.loginInfo.tenantId;
        this.ScheduleDayForm.value.createdBy = this.loginInfo.userId;
        this.ScheduleDayForm.value.modifiedBy = this.loginInfo.userId;
        // this.ScheduleDayForm.value.daypartList[a].endTime
        for (var a = 0, len = this.ScheduleDayForm.value.daypartList.length; a < len; a++) {
          if (this.ScheduleDayForm.value.daypartList[a].daysObj != null &&
            this.ScheduleDayForm.value.daypartList.daysObj != "") {
            if (this.ScheduleDayForm.value.daypartList[a].startTime != null) {
              if (this.ScheduleDayForm.value.daypartList[a].endTime != null) {
                this.ScheduleDayForm.value.daypartList[a].daysObj =
                  this.ScheduleDayForm.value.daypartList[a].daysObj.toString();
                this.isDayObj = true;
                this.isStartTime = true;
                this.isEndTime = true;
              }
              else {
                this.isEndTime = false;
                this.toaster.errorPopUp("Please Select End Time");
              }
            }
            else {
              this.isStartTime = false;
              this.toaster.errorPopUp("Please Select Start Time");
            }
          }
          else {
            this.isDayObj = false;
            this.toaster.errorPopUp("Please Select the Day");
          }
        }
        if (this.isDayObj == true && this.isStartTime == true && this.isEndTime == true) {
          this.tagser.SaveScheduler(this.ScheduleDayForm.value).pipe(takeUntil(this.onDestroy$)).subscribe(
            (daypartresponse) => {
              var messages = daypartresponse.message.split('-');
              if (daypartresponse.id == 0) {
                this.toaster.errorPopUp(messages[1]);
              }
              else {
                this.toaster.successPopUp(daypartresponse.message);
                this.helper.setValue('GetSessionData', FinalDayPartData);
                this.tagsDaypartPageData_child.emit({
                  data: FinalDayPartData, event: "tagsPage", val: false
                });
              }
              this.submittedGeneral = false;
            }), err => console.error('Error', err), () => console.log('HTTP request completed.');
        }
      }
    }
  }

  //  Realese the memory
  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.ScheduleDayForm.patchValue(this.helper.getValue('GetSessionData1'));
    this.tagsDaypartPageData_child.emit(this.ScheduleDayForm.value);
  }

  onchange(data) {
    this.helper.setValue('GetSessionData1', data);
    this.helper.setValue('OnDirty', true);
    this.tagsDaypartPageData_child.emit({ data: data, event: "tagsPage", val: true });
  }
}